import React, { useState } from 'react';
import ProfileDetailSection from 'containers/Centres/components/CentreTabs/EducatorStaffProfilesTab/components/StaffProfileDetail/ProfileDetailSection';
import {
  AwardCircleIcon,
  AwardFillIcon,
  AwardWithoutCircleIcon,
} from 'components/common/SVGIcon';
import { StaffProfileModel } from 'types/staffProfiles';
import ContentCard from 'components/CardItemProfile';
import AwardItem from 'containers/Centres/components/CentreTabs/EducatorStaffProfilesTab/Create/Steps/Awards/AwardItem';
import i18next from 'i18next';
import { Form } from 'antd';
import { useEditStaffProfilesMutation } from 'redux/@rtkQuery/educatorStaffProfile';
import { formatDate } from 'utils/tools';
import { isNumber, sortBy } from 'lodash-es';
import moment from 'moment';
import EmptyContainer from 'containers/Centres/components/CentreTabs/EducatorStaffProfilesTab/components/EmptyContainer';
import AwardFromModal from '../../Create/Steps/Awards/AwardFromModal';

interface Props {
  staffProfile: StaffProfileModel;
  disable?: boolean;
}

const AwardDetail = ({ staffProfile, disable }: Props) => {
  const [visible, setVisible] = useState(false);
  const [formModalStep4] = Form.useForm();
  const [editIndexCurrent, setEditIndexCurrent] = useState(null);
  const [editStaffProfile] = useEditStaffProfilesMutation();
  const awards = staffProfile?.awards ?? [];
  const defaultDataUpdate = {
    id: staffProfile?.id,
    centreId: staffProfile?.centreId,
    isDraft: staffProfile?.isDraft,
    currentStep: staffProfile?.currentStep,
  };

  const onDelete = (index) => {
    const payloadList = [...awards];
    payloadList.splice(index, 1);
    editStaffProfile({
      ...defaultDataUpdate,
      awards: payloadList,
    });
  };

  const onSave = async () => {
    formModalStep4.submit();
    const values = await formModalStep4.validateFields();
    const formNewValue = { ...values, year: formatDate(values?.year, 'YYYY') };
    let payloadList = [...awards];

    if (isNumber(editIndexCurrent)) {
      if (payloadList[editIndexCurrent]?.year === formNewValue?.year) {
        payloadList[editIndexCurrent] = formNewValue;
      } else {
        payloadList.splice(editIndexCurrent, 1);
        payloadList = [...payloadList, formNewValue];
        payloadList = sortBy(payloadList, 'year').reverse();
      }
    } else {
      payloadList = [...payloadList, formNewValue];
      payloadList = sortBy(payloadList, 'year').reverse();
    }

    editStaffProfile({
      ...defaultDataUpdate,
      awards: payloadList,
    });
    setVisible(false);
  };

  const onSaveAddAnother = () => {
    formModalStep4.validateFields().then((values) => {
      let payloadList = [
        ...awards,
        {
          ...values,
          year: formatDate(values.year, 'YYYY'),
        },
      ];

      payloadList = sortBy(payloadList, 'year').reverse();
      editStaffProfile({
        ...defaultDataUpdate,
        awards: payloadList,
      });
      formModalStep4.resetFields();
    });
  };

  const openEditModal = (data, index) => {
    formModalStep4.setFieldsValue({ ...data, year: moment(data.year) });
    setVisible(true);
    setEditIndexCurrent(index);
  };

  const openAddModal = () => {
    setVisible(true);
    formModalStep4.resetFields();
    setEditIndexCurrent(null);
  };

  return (
    <ProfileDetailSection
      icon={<AwardFillIcon className="text-20 text-primary" />}
      title={i18next.t('educatorStaffProfile.awards')}
      onAdd={openAddModal}
      disabled={disable}
    >
      <div className="flex flex-col gap-24">
        {staffProfile.awards?.length ? (
          staffProfile.awards.map((item, index) => (
            <ContentCard
              key={`${staffProfile.id}-awards-${index}`}
              Icon={<AwardCircleIcon className="text-40" />}
              onEdit={() => openEditModal(item, index)}
              onDelete={() => onDelete(index)}
              deleteConfirmTitle="educatorStaffProfile.deleteAdAwardTitle"
              deleteConfirmContent="educatorStaffProfile.deleteAdAwardContent"
              disabled={disable}
            >
              <AwardItem {...item} />
            </ContentCard>
          ))
        ) : (
          <EmptyContainer
            Icon={AwardWithoutCircleIcon}
            description={'educatorStaffProfile.noDataDescriptionAwards'}
            buttonTitle="educatorStaffProfile.addAwards"
            onAdd={openAddModal}
            disabled={disable}
          />
        )}
      </div>

      <AwardFromModal
        formModalStep4={formModalStep4}
        visible={visible}
        title="educatorStaffProfile.awards"
        editIndexCurrent={editIndexCurrent}
        setVisible={setVisible}
        onSave={onSave}
        onSaveAddAnother={onSaveAddAnother}
      />
    </ProfileDetailSection>
  );
};

export default AwardDetail;

import styled from 'styled-components';
import { Layout, Drawer } from 'antd';

export const DrawerStyles = styled(Drawer)`
  .ant-drawer-body {
    padding: 0;
  }
`;

const HeaderWrapper = styled(Layout.Header)`
  .btn {
    margin-right: 10px;
  }
  .notification-section {
    margin-right: 20px;
    .ant-btn {
      padding: 0;
      border: none;
      .anticon {
        font-size: 20px;
      }
    }
  }
  .user-role {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-right: 10px;
    justify-content: center;
    & > div {
      line-height: normal;
    }
    .name {
      font-size: 16px;
      font-weight: 500;
    }
  }
  .trigger {
    color: ${({ theme }) => theme.text.primary};
    font-size: 24px;
    transition: 300ms ease all;
  }
  .reverse-trigger {
    transform: rotate(180deg);
  }

  .leftHeader {
    display: flex;
    align-items: center;
    justify-content: center;
    @media only screen and (max-width: 640px) {
      width: 100%;
      display: inherit;
      padding-right: 45px;
    }
  }
  .rightHeader {
    display: flex;
    align-items: center;
    justify-content: center;
    @media only screen and (max-width: 640px) {
      display: none;
    }
  }
  .localeSelect {
    padding: 5px;
    font-weight: bold;
    cursor: pointer;
    color: ${({ theme }) => theme.text.disabled};
    &.actife {
      color: ${({ theme }) => theme.palette.primary};
    }
  }
  &.withoutSidebar {
    .leftHeader {
      .ant-page-header {
        padding: 0px;
      }
    }
  }

  .div-user-info {
    cursor: pointer;
    display: flex;
    .role {
      text-transform: capitalize;
    }
  }
  .userInfo {
    display: inline-flex;
    flex-direction: column;
    line-height: 20px;
    vertical-align: middle;
    margin-right: 15px;
    margin-left: 10px;
    text-align: right;
    max-width: 250px;
    max-height: 64px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    strong,
    .role {
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
`;

export default HeaderWrapper;

export const SearchInputStyles = styled.div`
  margin: auto;
  display: flex;
  .search-header {
    .anticon-search {
      color: ${({ theme }) => theme.palette.primary};
    }

    .ant-input-affix-wrapper {
      border-radius: 8px !important;
      background: ${({ theme }) => theme.background.content};
      border: 1px solid transparent !important;
    }
    .ant-input-affix-wrapper:focus,
    .ant-input-affix-wrapper-focused {
      border: 1px solid ${({ theme }) => theme.palette.primary} !important;
    }
    .ant-input-group-addon {
      display: none;
    }
  }
  @media only screen and (max-width: 425px) {
    .search-header {
      display: none;
    }
  }
`;

export const CentreDropdownStyles = styled.div`
  .ant-select-selector {
    border-color: transparent !important;
  }
  .ant-select-focused .ant-select-selector {
    border-color: ${({ theme }) => theme.palette.primary} !important;
  }
  @media only screen and (max-width: 425px) {
    display: none;
  }
`;

import React, { useMemo, useCallback } from 'react';
import i18next from 'i18next';
import PropTypes from 'prop-types';
import { GENDERS } from 'configs/localData';
import { formatBirthday, getAge } from 'utils/textUtils';
import { InformationWrapper } from './styles';

const ChildInfo = ({ record }) => {
  const genderText = useMemo(() => {
    const genderItem = GENDERS.find((item) => item.value === record.gender);
    return genderItem?.text ? i18next.t(genderItem.text) : record.gender;
  }, [record.gender]);

  const getYesNoAndText = useCallback((value) => {
    const text = value?.trim();
    return {
      yesOrNo: text ? i18next.t('text.yes') : i18next.t('text.no'),
      text: text || i18next.t('text.NA'),
      className: text ? 'yes-value' : 'no-value',
    };
  }, []);

  const allergiesAndNeedObj = useMemo(
    () => ({
      allergies: getYesNoAndText(record.allergies),
      specialNeeds: getYesNoAndText(record.specialNeeds),
    }),
    [getYesNoAndText, record.allergies, record.specialNeeds],
  );

  return (
    <InformationWrapper>
      <div className="title-info">
        {i18next.t('applications.childInformation')}
      </div>
      <div className="content-info">
        <div className="info-item">
          <span className="title-info-item">{i18next.t('users.name')}</span>
          <span className="value-info-item">
            {`${record.firstName || ''} ${record.lastName || ''}`}
          </span>
        </div>

        <div className="info-item">
          <span className="title-info-item">{i18next.t('users.DOB')}</span>
          <span className="value-info-item">
            {formatBirthday(record.dateOfBirth)}
          </span>
        </div>

        <div className="info-item">
          <span className="title-info-item">{i18next.t('users.age')}</span>
          <span className="value-info-item">{getAge(record.dateOfBirth)}</span>
        </div>

        <div className="info-item">
          <span className="title-info-item">{i18next.t('users.gender')}</span>
          <span className="value-info-item">{genderText}</span>
        </div>

        <div className="info-item">
          <span className="title-info-item">
            {i18next.t('users.centreLinkCRN')}
          </span>
          <span className="value-info-item">{record.centrelinkCRN}</span>
        </div>

        <div className="info-item">
          <span className="title-info-item">
            {i18next.t('children.allergies')}
          </span>
          <span
            className={`value-info-item ${allergiesAndNeedObj.allergies.className}`}
          >
            {allergiesAndNeedObj.allergies.yesOrNo}
          </span>
        </div>

        <div className="info-item">
          <span className="title-info-item">
            {i18next.t('children.allergiesDetail')}
          </span>
          <span className="value-info-item">
            {allergiesAndNeedObj.allergies.text}
          </span>
        </div>

        <div className="info-item">
          <span className="title-info-item">
            {i18next.t('children.specialNeeds')}
          </span>
          <span
            className={`value-info-item ${allergiesAndNeedObj.specialNeeds.className}`}
          >
            {allergiesAndNeedObj.specialNeeds.yesOrNo}
          </span>
        </div>

        <div className="info-item">
          <span className="title-info-item">
            {i18next.t('children.specialNeedsDetail')}
          </span>
          <span className="value-info-item">
            {allergiesAndNeedObj.specialNeeds.text}
          </span>
        </div>
      </div>
    </InformationWrapper>
  );
};

ChildInfo.propTypes = {
  record: PropTypes.object,
};

ChildInfo.defaultProps = {
  record: {},
};

export default ChildInfo;

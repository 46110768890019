import React from 'react';

import { SOCIAL_LINKS } from 'configs/localData';
import footerLogo from 'assets/images/footer-logo.png';
import FooterDefault from 'containers/Footer/FooterDefault';
import { Col, Row } from 'antd';
import { PublicFooterStyles } from './styles';

const PublicFooter = () => (
  <PublicFooterStyles>
    <Row gutter={[20, 20]} className="public-footer__row">
      <Col xl={5} lg={5} md={24} xs={24} className="public-footer__col-left">
        <div className="public-footer__logo">
          <img src={footerLogo} alt="" />
        </div>
        <div className="public-footer__social">
          {SOCIAL_LINKS.map(({ className, Icon, href, key }) => (
            <div key={key} className={`social-icon ${className}`}>
              <a href={href} target="_blank" rel="noopener noreferrer">
                <Icon />
              </a>
            </div>
          ))}
        </div>
      </Col>

      <Col xl={19} lg={19} md={24} xs={24} className="public-footer__col-right">
        <FooterDefault />
      </Col>
    </Row>
  </PublicFooterStyles>
);

export default PublicFooter;

import { CHANNEL_OPTIONS } from 'configs/localData';
import moment from 'moment';

export const DEFAULT_FILTER = {
  channel: CHANNEL_OPTIONS.all.value,
  clickThroughDate: [
    moment().subtract(7, 'days').startOf('day'),
    moment().subtract(1, 'days').endOf('day'),
  ],
};

export const FILTER_SEARCH_KEYS = {
  userName: 'userName',
  userEmail: 'userEmail',
  userPhone: 'userPhone',
  centreName: 'centreName',
  providerName: 'providerName',
  brandName: 'brandName',
  PRNumber: 'PRNumber',
  SENumber: 'SENumber',
};

export const DEFAULT_SORT_LEAD_ACTIVITIES = '-clickThroughDate';

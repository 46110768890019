import styled from 'styled-components';

export const TableStyle = styled.div`
  .summary-row,
  .summary-row:hover > *,
  .summary-row > .ant-table-cell-fix-right,
  .summary-row > .ant-table-cell-fix-left,
  .summary-row > .ant-table-column-sort {
    font-size: 14px;
    font-weight: bold;
    background: ${({ theme }) => theme.color.lightBlue} !important;
  }

  .selected-row,
  .selected-row:hover > *,
  .selected-row > .ant-table-cell-fix-right,
  .selected-row > .ant-table-cell-fix-left,
  .selected-row > .ant-table-column-sort {
    font-size: 14px;
    background: ${({ theme }) => theme.color.lightPink} !important;
  }

  .ant-progress-line {
    width: 90%;
  }
`;

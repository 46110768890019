import styled from 'styled-components';

export default styled.div`
  padding: 20px;

  .detail-col {
    & > div {
      border-radius: 20px;
      .thumbnail-centre {
        border-radius: 20px 20px 0 0;
      }
    }
  }
`;

export const CentreInfoStyles = styled.div`
  --font-size: 16px;

  background: ${({ theme }) => theme.background.content};
  border-radius: 20px;

  box-shadow: 0px 0px 1px rgba(12, 26, 75, 0.1),
    0px 4px 20px -2px rgba(50, 50, 71, 0.08);

  .image-header {
    position: relative;
  }

  .thumbnail-centre {
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    aspect-ratio: 3/2;
    height: auto;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    .anticon {
      font-size: 40px;
    }
  }
  .info-content {
    padding: 15px;
    .name-centre {
      font-size: 20px;
      font-weight: bold;
      margin-bottom: 15px;
      color: #2d1f21;
    }
    .item-section {
      display: flex;
      align-items: center;
      margin-bottom: 24px;
      .title-item {
        color: ${({ theme }) => theme.palette.primary};
      }
      .value-item {
        color: #38465c;
        font-size: var(--font-size);

        margin-left: 10px;
      }
      .anticon {
        font-size: var(--font-size);
      }
    }
    .time-block {
      display: inline-block;
      margin-top: 10px;
      width: 80%;
    }
    .website-value {
      text-decoration: underline;
    }
  }

  @media only screen and (min-width: 2000px) {
    --font-size: calc(16px + 0.390625vw);

    .value-item {
      margin-left: 18px !important;
    }

    .name-centre {
      margin-top: 12px;
      margin-bottom: 24px;
      font-size: calc(20px + 0.45vw) !important;
    }
    .value-item {
      margin-left: 18px !important;
    }
  }
`;

export const KCRatingImage = styled.img`
  position: absolute;
  top: 16px;
  left: 16px;
  z-index: 5;

  @media (min-width: 1920px) {
    width: 72px;
  }

  @media (min-width: 2600px) {
    width: 100px;
  }
`;

export const BrandLogo = styled.img`
  position: absolute;
  bottom: 16px;
  left: 16px;
  width: 56px;
  border-radius: 50%;
  object-fit: cover;
  z-index: 5;

  @media (min-width: 1920px) {
    width: 80px;
  }

  @media (min-width: 2600px) {
    width: 120px;
  }
`;

export const NQSRatingImage = styled.img`
  position: absolute;
  bottom: 16px;
  right: 16px;
  width: 72px;
  height: 40px;
  background: #ffffff;
  border-radius: 6px;
  z-index: 5;

  @media (min-width: 1920px) {
    width: 120px;
    height: 70px;
  }

  @media (min-width: 2600px) {
    width: 160px;
    height: 90px;
  }
`;

import {
  getAllCustomFail,
  getAllCustomPending,
  INITIAL_STATE_CUSTOM,
} from 'redux/crudCreatorCustom/utils';
import { createSlice } from '@reduxjs/toolkit';
import {
  getAllCompanyBenchmarking,
  getUserCompanyBenchmarkingChart,
  getCompanyBenchmarkingSummary,
  getCompanyBenchmarkingCompareRatingData,
} from 'redux/companyBenchmarking/actions';
import { uniqBy, uniq } from 'lodash-es';

const initialState = {
  ...INITIAL_STATE_CUSTOM,
  userCompanyBenchmarkingChart: {},
  summary: {},
  compareRatingChart: {},
};

const getCompanyBenchmarkingSuccess = (
  state,
  { payload: { data, options } },
) => {
  state.loading = false;

  if (options.isSort) {
    state.data = [];
    state.ids = [];
  }

  state.ids = uniq([...(state.ids || []), ...data.ids]);
  state.data = uniqBy([...state?.data, ...Object.values(data?.data)], 'id');

  state.resourceData.total = data?.total;
  state.resourceData.numberOfPages = data?.numberOfPages;
  state.averageAllCompany = data?.additionData?.averageAllCompany;
};

const getUserCompanyBenchmarkingChartPending = (state) => {
  state.userCompanyBenchmarkingChart.loading = true;
};

const getUserCompanyBenchmarkingChartSuccess = (
  state,
  { payload: { data } },
) => {
  state.userCompanyBenchmarkingChart = data;
  state.userCompanyBenchmarkingChart.loading = false;
};

const getUserCompanyBenchmarkingChartFail = (state) => {
  state.userCompanyBenchmarkingChart.loading = false;
};

const getCompanyBenchmarkingCompareRatingPending = (state) => {
  state.compareRatingChart.loading = true;
};

const getCompareRatingDataSuccess = (state, { payload: { data } }) => {
  state.compareRatingChart = data;
  state.compareRatingChart.loading = false;
};

const getCompanyBenchmarkingCompareRatingFail = (state) => {
  state.compareRatingChart.loading = false;
};

const getCompanyBenchmarkingSummaryPending = (state) => {
  state.summary.loading = true;
};

const getCompanyBenchmarkingSummarySuccess = (state, { payload: { data } }) => {
  state.summary = data;
  state.summary.loading = false;
};

const getCompanyBenchmarkingSummaryFail = (state) => {
  state.summary.loading = false;
};

const { reducer, actions } = createSlice({
  name: 'companyBenchmarking',
  initialState,
  reducers: {
    resetResourceFilterCompanyBenchMarking: (state) => {
      state.ids = [];
      state.data = [];
      state.total = 0;
      state.resourceData.outsideFilter = {};
      state.userCompanyBenchmarkingChart = {};
      state.averageAllCompany = {};
    },
  },
  extraReducers: {
    [getAllCompanyBenchmarking.pending]: getAllCustomPending,
    [getAllCompanyBenchmarking.fulfilled]: getCompanyBenchmarkingSuccess,
    [getAllCompanyBenchmarking.rejected]: getAllCustomFail,

    [getUserCompanyBenchmarkingChart.pending]:
      getUserCompanyBenchmarkingChartPending,
    [getUserCompanyBenchmarkingChart.fulfilled]:
      getUserCompanyBenchmarkingChartSuccess,
    [getUserCompanyBenchmarkingChart.rejected]:
      getUserCompanyBenchmarkingChartFail,

    [getCompanyBenchmarkingSummary.pending]:
      getCompanyBenchmarkingSummaryPending,
    [getCompanyBenchmarkingSummary.fulfilled]:
      getCompanyBenchmarkingSummarySuccess,
    [getCompanyBenchmarkingSummary.rejected]: getCompanyBenchmarkingSummaryFail,

    [getCompanyBenchmarkingCompareRatingData.pending]:
      getCompanyBenchmarkingCompareRatingPending,
    [getCompanyBenchmarkingCompareRatingData.fulfilled]:
      getCompareRatingDataSuccess,
    [getCompanyBenchmarkingCompareRatingData.rejected]:
      getCompanyBenchmarkingCompareRatingFail,
  },
});

export const { resetResourceFilterCompanyBenchMarking } = actions;

export default reducer;

import React from 'react';
import { getTasksOverdueApi } from 'api/dashboard';
import CardCommon from '../CardCommon';
import ListTask from './ListTasks';

const OverdueTasks = () => (
  <CardCommon title="task.overdueTasks" isLightBackground={false}>
    <ListTask isOverdue action={getTasksOverdueApi} />
  </CardCommon>
);

export default OverdueTasks;

import styled from 'styled-components';

const EditCustomBtnStyles = styled.div`
  .edit-custom-btn {
    height: 32px;
    padding: 0px;
    width: 32px;
    min-width: 32px !important;
    .anticon {
      font-size: 15px;
      color: #f7f8f9;
    }
  }
  .edit-action-wrapper {
    position: relative;
  }

  .action-feature-icon {
    position: absolute;
    top: -10px;
    right: -8px;
    font-size: 20px;
    color: ${({ theme }) => theme.subscriptions.colorIcon};
  }
`;

export default EditCustomBtnStyles;

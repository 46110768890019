import { useEffect } from 'react';

const useWindowAutoScroll = (options) => {
  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.scrollTo({
        top: 0,
        ...options,
      });
    }
  }, [options]);
};

export default useWindowAutoScroll;

import React, { useState } from 'react';
import { Button, DatePicker } from 'antd';
import { exportExcel } from 'api/crud';
import { ExcelIcon } from 'components/common/SVGIcon';
import { ExportExcelWrapper } from './styles';

const ExportExcel = () => {
  const { RangePicker } = DatePicker;
  const [dates, setDates] = useState();

  const handleClick = () => {
    const query = {
      startTime: dates[0].startOf('day').toISOString(),
      endTime: dates[1].endOf('day').toISOString(),
    };
    exportExcel('contacts', query);
  };

  return (
    <ExportExcelWrapper>
      <RangePicker value={dates} onChange={(value) => setDates(value)} />
      <Button disabled={!dates} icon={<ExcelIcon />} onClick={handleClick} />
    </ExportExcelWrapper>
  );
};

export default ExportExcel;

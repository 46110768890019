import React from 'react';
import PropTypes from 'prop-types';
import FilterCustom from 'components/common/FilterCustom';
import {
  LIMIT_WORKPLACES_DEFAULT,
  WORKPLACE_TYPES,
} from 'containers/JobListings/constants';
import { Col, Row } from 'antd';
import RestInputItem from 'components/RestInput/RestInputItem';
import { WorkplaceFilterWrapperStyles } from 'containers/JobListings/components/filters/WorkplaceFilter/styles';
import RestSelect from 'components/RestInput/RestSelect';
import { PREFIX_URL } from 'configs/localData/constant';
import ReferenceInput from 'containers/rest/ReferenceInput';
import { useSelector } from 'react-redux';
import i18next from 'i18next';
import { useHistory } from 'react-router';
import qs from 'qs';
import { pickBy } from 'lodash-es';

const WorkplaceFilter = ({ retrieveList }) => {
  const { push, location } = useHistory();

  const { id, filter } = qs.parse(location.search, {
    ignoreQueryPrefix: true,
  });

  const stateOfAustralia = useSelector(
    (state) => state.config.data?.stateOfAustralia,
  );

  const onSubmitFilter = (values) => {
    const validValues = pickBy(values);

    push({
      search: qs.stringify(pickBy({ id, filter: validValues })),
    });

    retrieveList({
      data: {
        filter: validValues,
      },
      isRefresh: true,
    });
  };

  const onClearFilter = () => {
    push({
      search: qs.stringify(pickBy({ id })),
    });

    retrieveList({
      filter: {
        page: 1,
        size: LIMIT_WORKPLACES_DEFAULT,
      },
      isRefresh: true,
    });
  };

  return (
    <WorkplaceFilterWrapperStyles>
      <FilterCustom
        onSubmitFilter={onSubmitFilter}
        onClearFilter={onClearFilter}
        initialValues={filter}
        responsiveFilter={{
          xxl: 20,
          xl: 18,
          lg: 24,
          md: 24,
          xs: 24,
        }}
        responsiveAction={{
          xxl: 4,
          xl: 6,
          lg: 24,
          md: 24,
          xs: 24,
        }}
      >
        <Row gutter={16}>
          <Col lg={6} md={12} xs={24}>
            <ReferenceInput
              prefixUrl={PREFIX_URL.admin}
              resource="users/me/companies/basic-info"
              source="companyId"
              searchKey="name"
              notLikeFilter
              initialFilter={{ orderBy: 'name' }}
            >
              <RestSelect
                titleProp="name"
                valueProp="id"
                placeholder="centres.companyTitle"
                isFilterOption={false}
                isShowTooltip
              />
            </ReferenceInput>
          </Col>
          <Col lg={6} md={12} xs={24}>
            <ReferenceInput
              prefixUrl={PREFIX_URL.admin}
              source="brandId"
              resource="brands"
              searchKey="brand.name"
              initialFilter={{ orderBy: 'name' }}
            >
              <RestSelect
                titleProp="name"
                valueProp="id"
                placeholder="centres.brandTitle"
                isFilterOption={false}
                isShowTooltip
              />
            </ReferenceInput>
          </Col>
          <Col lg={6} md={12} xs={24}>
            <RestInputItem
              source="centre"
              placeholder="centres.centre"
              isShowTooltip
            />
          </Col>
          <Col lg={6} md={12} xs={24}>
            <RestInputItem
              source="SENumber"
              placeholder="centres.SENumber"
              isShowTooltip
            />
          </Col>
          <Col lg={6} md={12} xs={24}>
            <RestSelect
              source="state"
              placeholder="centres.state"
              resourceData={stateOfAustralia || []}
              isShowTooltip
            />
          </Col>
          <Col lg={6} md={12} xs={24}>
            <ReferenceInput
              source="suburb"
              resource="cities"
              searchKey="city"
              filterKey="city"
              mappedBy="city"
              initialFilter={{ orderBy: 'city' }}
            >
              <RestSelect
                titleProp="city"
                valueProp="city"
                placeholder="input.suburb.name"
                isFilterOption={false}
                isShowTooltip
              />
            </ReferenceInput>
          </Col>
          <Col lg={6} md={12} xs={24}>
            <RestInputItem
              source="postCode"
              placeholder="Postcode"
              isShowTooltip
            />
          </Col>
          <Col lg={6} md={12} xs={24}>
            <RestSelect
              source="workplaceType"
              resourceData={WORKPLACE_TYPES}
              formatText={(data) => i18next.t(data)}
              valueProp="value"
              titleProp="text"
              placeholder="jobListings.workplaceType"
              isShowTooltip
            />
          </Col>
        </Row>
      </FilterCustom>
    </WorkplaceFilterWrapperStyles>
  );
};

WorkplaceFilter.propTypes = {
  retrieveList: PropTypes.func,
};

export default WorkplaceFilter;

import React from 'react';
import { Col, Row } from 'antd';

import { useSelector } from 'react-redux';

import Activities from './Activities';
import ChildInfo from './ChildInfo';
import ParentInfo from './ParentInfo';
import { SummaryTabStyles } from './styles';
import SubsidyInfo from './SubsidyInfo';

const SummaryTab = () => {
  const record = useSelector((state) => state.applications.currentData) || {};

  const child = record.careOption || {};

  return (
    <SummaryTabStyles>
      <Row gutter={20}>
        <Col md={12} xs={24}>
          <Activities
            id={record?.id}
            applicationStatuses={record.applicationStatuses}
          />
        </Col>
        <Col md={12} xs={24}>
          <div className="child-parent-info">
            <ChildInfo record={child} />
            <SubsidyInfo record={record.subsidy} />
            <ParentInfo
              additionalParentInfo={record.additionalParentInfo}
              parentInfo={record.sender}
            />
          </div>
        </Col>
      </Row>
    </SummaryTabStyles>
  );
};

export default SummaryTab;

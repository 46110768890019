import React, {
  useContext,
  createContext,
  useState,
  useEffect,
  useCallback,
  useMemo,
} from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { isEmpty, partition } from 'lodash-es';
import {
  countApplicationsSelector,
  getDataApplicationArr,
} from 'redux/applications/selectors';
import { useParams } from 'react-router';
import { getFilterFromUrl } from 'utils/tools';
import { sortTime } from 'utils/sortUtils';
import {
  conditionStatus,
  conditionService,
  conditionTime,
  conditionChildName,
  formatBoardData,
  getSummaries,
  conditionAgeGroup,
  conditionArchivedReason,
} from './utils';
import { TABS_MODE_APPLICATION } from '.';

const initialValue = {
  filter: {},
  setFilter: () => {},
  applications: {
    table: [],
    board: {},
    summaryStatus: {},
    summaries: {},
  },
  setApplications: () => {},
};

const ApplicationListContext = createContext(initialValue);

export const ApplicationListProvider = ({ children }) => {
  const history = useHistory();
  const { location } = history;
  const { model } = useParams();
  const isArchivedViewMode = model === TABS_MODE_APPLICATION.archived.key;

  const applicationList = useSelector(getDataApplicationArr);
  const { countArchived, countWithoutArchived } = useSelector(
    countApplicationsSelector,
  );

  const { archivedApplications, withoutArchivedApplications } = useMemo(() => {
    const [archivedApplications, withoutArchivedApplications] = partition(
      applicationList,
      (application) => !!application?.archivedReason,
    );

    return {
      archivedApplications: archivedApplications.sort((a, b) =>
        sortTime(b, a, 'archivedAt'),
      ),
      withoutArchivedApplications,
    };
  }, [applicationList]);

  const arrApplication = useMemo(
    () =>
      isArchivedViewMode ? archivedApplications : withoutArchivedApplications,
    [isArchivedViewMode, archivedApplications, withoutArchivedApplications],
  );

  const boardData = formatBoardData(withoutArchivedApplications);

  const [applications, setApplications] = useState({
    table: arrApplication,
    board: boardData.data,
    summaryStatus: boardData.summaryStatus,
    summaries: getSummaries(arrApplication),
  });

  const [filter, setFilter] = useState(
    getFilterFromUrl(location.search)?.filter,
  );

  const countApplicationPerTab = useMemo(() => {
    const count = {
      [TABS_MODE_APPLICATION.list.key]: countWithoutArchived,
      [TABS_MODE_APPLICATION.board.key]: countWithoutArchived,
      [TABS_MODE_APPLICATION.archived.key]: countArchived,
    };

    if (
      model === TABS_MODE_APPLICATION.list.key ||
      model === TABS_MODE_APPLICATION.board.key
    ) {
      count[TABS_MODE_APPLICATION.list.key] = applications.table.length;
      count[TABS_MODE_APPLICATION.board.key] = applications.table.length;
    } else {
      count[model] = applications.table.length;
    }

    return count;
  }, [model, applications.table.length, countArchived, countWithoutArchived]);

  const handleFilter = useCallback(
    (filter = {}) => {
      if (isEmpty(filter)) {
        setApplications({
          table: arrApplication,
          board: boardData.data,
          summaryStatus: boardData.summaryStatus,
          summaries: getSummaries(arrApplication),
        });
        return;
      }

      const {
        child,
        serviceTypeId,
        submittedAt,
        startDay,
        serviceAgeGroupId,
        status,
        archivedReason,
      } = filter;

      const dataArr = [...arrApplication];

      const restArr = dataArr.filter(
        (item) =>
          conditionStatus(status, item.status) &&
          conditionService(serviceTypeId, item.careOption) &&
          conditionTime(submittedAt, item.submittedAt) &&
          conditionTime(startDay, item.careOption?.startDay) &&
          conditionChildName(child, item.careOption) &&
          conditionAgeGroup(
            serviceAgeGroupId,
            item.careOption?.centreServices,
          ) &&
          conditionArchivedReason(archivedReason, item?.archivedReason),
      );

      const dataBoard = formatBoardData(restArr);

      setApplications({
        table: restArr,
        board: dataBoard.data,
        summaryStatus: dataBoard.summaryStatus,
        summaries: getSummaries(restArr),
      });
    },
    [arrApplication, boardData], // eslint-disable-line
  );

  useEffect(() => {
    handleFilter(filter);
  }, [arrApplication, filter]); // eslint-disable-line

  const value = {
    applications,
    filter,
    countApplicationPerTab,
    setFilter,
    handleFilter,
  };

  return (
    <ApplicationListContext.Provider value={value}>
      {children}
    </ApplicationListContext.Provider>
  );
};

ApplicationListProvider.propTypes = {
  children: PropTypes.node,
};

export const useApplicationList = () => {
  const {
    applications,
    handleFilter,
    filter,
    setFilter,
    countApplicationPerTab,
  } = useContext(ApplicationListContext);

  return {
    filter,
    setFilter,
    tableData: applications.table,
    boardData: applications.board,
    summaryStatus: applications.summaryStatus,
    summaries: applications.summaries,
    handleFilter,
    countApplicationPerTab,
  };
};

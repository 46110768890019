import React from 'react';
import { Col, Row } from 'antd';
import { useLocation } from 'react-router';
import { getFilterFromUrl } from 'utils/tools';
import PropTypes from 'prop-types';
import ServiceApprovalBarChart from './ServiceApprovalBarChart';
import NQSRatingPieChart from './NQSRatingPieChart';
import KindicareRatingPieChart from './KindicareRatingPieChart';

const UserCompanyBenchmarkingChart = ({ ownedCompanies }) => {
  const { search } = useLocation();
  const { filter } = getFilterFromUrl(search);
  const userCompanySelectedName = ownedCompanies?.find(
    (item) => item?.id === filter?.companyId,
  )?.name;

  return (
    <Row gutter={[24, 24]} className="mt-32">
      <Col xl={8} sm={24} xs={24}>
        <NQSRatingPieChart userCompanySelectedName={userCompanySelectedName} />
      </Col>
      <Col xl={8} sm={24} xs={24}>
        <KindicareRatingPieChart
          userCompanySelectedName={userCompanySelectedName}
        />
      </Col>
      <Col xl={8} sm={24} xs={24}>
        <ServiceApprovalBarChart
          userCompanySelectedName={userCompanySelectedName}
        />
      </Col>
    </Row>
  );
};

UserCompanyBenchmarkingChart.propTypes = {
  ownedCompanies: PropTypes.array,
};

export default UserCompanyBenchmarkingChart;

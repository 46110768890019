import React from 'react';
import { Col, Row } from 'antd';
import PropTypes from 'prop-types';
import AboutTheJob from './AboutTheJob';
import AboutYou from './AboutYou';
import JobHighlightsBenefits from './JobHighlightsBenefits';
import WhatYouWillBeDoing from './WhatYouWillBeDoing';

const GeneralInformationTab = ({ currentData, disabledAction }) => {
  const aboutTheJobData = currentData?.aboutTheJob ?? '';
  const candidateResponsibilities =
    currentData?.candidateResponsibilities ?? '';
  const aboutCandidateData = currentData?.aboutCandidate ?? '';
  const benefits = currentData?.benefits ?? [];

  return (
    <>
      <Row gutter={[24, 24]}>
        <Col xl={12} xs={24}>
          <JobHighlightsBenefits
            benefits={benefits}
            disabledAction={disabledAction}
          />
        </Col>
        <Col xl={12} xs={24}>
          <AboutTheJob
            aboutTheJob={aboutTheJobData}
            disabledAction={disabledAction}
          />
        </Col>
      </Row>
      <Row gutter={[24, 24]} className="mt-24">
        <Col xl={12} xs={24}>
          <WhatYouWillBeDoing
            candidateResponsibilities={candidateResponsibilities}
            disabledAction={disabledAction}
          />
        </Col>
        <Col xl={12} xs={24}>
          <AboutYou
            aboutCandidate={aboutCandidateData}
            disabledAction={disabledAction}
          />
        </Col>
      </Row>
    </>
  );
};

GeneralInformationTab.propTypes = {
  currentData: PropTypes.object,
  disabledAction: PropTypes.bool,
};

export default GeneralInformationTab;

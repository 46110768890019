import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  createJobTemplateApi,
  getAllJobTemplateApi,
  getJobTemplateConfigApi,
  getJobTemplatesDetailApi,
  editJobTemplateApi,
} from 'api/jobTemplates';
import i18next from 'i18next';
import { apiWrapper } from 'utils/reduxUtils';

export const MODEL_NAME = 'jobTemplates';

export const getAllJobTemplates = createAsyncThunk(
  `${MODEL_NAME}/getAllJobTemplates`,
  async (payload = {}, thunkApi) => {
    try {
      const { size, page, filter, sorts, total } =
        thunkApi.getState().jobTemplates?.resourceData || {};

      const response = await apiWrapper({}, getAllJobTemplateApi, {
        size,
        total,
        page,
        filter,
        sorts,
        ...payload.data,
      });

      return {
        data: {
          page: Math.ceil(response.total / size),
          ...response,
        },
        options: payload.options,
      };
    } catch (error) {
      return thunkApi.rejectWithValue({ id: payload.data.id, data: error });
    }
  },
);

export const createJobTemplate = createAsyncThunk(
  `${MODEL_NAME}/createJobTemplate`,
  async (payload, thunkApi) => {
    try {
      const response = await apiWrapper(
        {
          isShowSuccessNoti: true,
          successDescription: i18next.t(
            'jobs.createJobTemplateStep.createSuccess',
          ),
        },
        createJobTemplateApi,
        payload,
      );
      return response;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  },
);

export const getJobTemplateConfig = createAsyncThunk(
  `${MODEL_NAME}/getJobTemplateConfig`,
  async (payload, thunkApi) => {
    try {
      const response = await apiWrapper(null, getJobTemplateConfigApi);
      return response;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  },
);

export const getJobTemplatesDetail = createAsyncThunk(
  `${MODEL_NAME}/getJobTemplatesDetail`,
  async (payload = {}, thunkAPI) => {
    try {
      const { id } = payload;
      return await apiWrapper({}, getJobTemplatesDetailApi, id);
    } catch (error) {
      return thunkAPI.rejectWithValue({ id: payload });
    }
  },
);

export const editJobTemplate = createAsyncThunk(
  `${MODEL_NAME}/editJobTemplate`,
  async (payload = {}, thunkApi) => {
    try {
      const { data, id } = payload;

      const response = await apiWrapper(
        { isShowSuccessNoti: true },
        editJobTemplateApi,
        id,
        data,
      );

      return { data: response };
    } catch (error) {
      return thunkApi.rejectWithValue({ data: error, id: payload });
    }
  },
);

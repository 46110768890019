import React from 'react';
import PropTypes from 'prop-types';
import i18next from 'i18next';
import RestInputItem from 'components/RestInput/RestInputItem';
import ModalCustom from 'components/common/ModalCustom';
import { Form } from 'antd';
import { DEFAULT_LINKS } from 'configs/localData/constant';

const RecruitmentVideoModal = ({
  visible,
  onCancel,
  onFinish,
  loading,
  initialValues,
}) => {
  const [form] = Form.useForm();

  return (
    <ModalCustom
      title={i18next.t(
        initialValues?.url
          ? 'jobs.companyProfile.editRecruitmentVideo'
          : 'jobs.companyProfile.addRecruitmentVideo',
      )}
      visible={visible}
      onCancel={onCancel}
      onOk={form.submit}
    >
      <Form
        form={form}
        layout="vertical"
        scrollToFirstError
        onFinish={onFinish}
        loading={loading}
        initialValues={initialValues}
      >
        <RestInputItem
          required
          source="url"
          placeholder={i18next.t(
            'jobs.companyProfile.recruitmentVideo.videoLinkPlaceHolder',
          )}
          header={i18next.t('jobs.companyProfile.recruitmentVideo.videoLink')}
          rules={[
            () => ({
              validator(_, value) {
                const isValidUrl = DEFAULT_LINKS.some((link) =>
                  value.startsWith(link),
                );
                if (isValidUrl || !value) {
                  return Promise.resolve();
                }
                return Promise.reject(
                  new Error(i18next.t('jobs.companyProfile.invalidUrl')),
                );
              },
            }),
          ]}
        />
      </Form>
    </ModalCustom>
  );
};

RecruitmentVideoModal.propTypes = {
  visible: PropTypes.bool,
  onCancel: PropTypes.func,
  onFinish: PropTypes.func,
  loading: PropTypes.bool,
  initialValues: PropTypes.shape({ url: PropTypes.string }),
};

export default RecruitmentVideoModal;

import React from 'react';
import { useBrandsQuery } from 'redux/@rtkQuery/brands';
import { DEFAULT_LOCATION } from 'configs/localData/constant';
import BrandItem from 'containers/ClaimCentres/components/SearchCentres/Brands/BrandItem';
import { Carousel, Skeleton } from 'antd';
import Arrow from 'containers/ClaimCentres/components/SearchCentres/Brands/ArrowCustom';
import { Settings } from '@ant-design/react-slick';
import { CarouselStyle } from 'containers/ClaimCentres/styles';

export const MEDIUM_CARD_CAROUSEL_SETTINGS: Settings = {
  slidesToShow: 10,
  slidesToScroll: 10,
  responsive: [
    {
      breakpoint: 3900,
      settings: {
        slidesToShow: 9,
        slidesToScroll: 9,
      },
    },
    {
      breakpoint: 3000,
      settings: {
        slidesToShow: 10,
        slidesToScroll: 10,
      },
    },
    {
      breakpoint: 2561,
      settings: {
        slidesToShow: 10,
        slidesToScroll: 10,
      },
    },
    {
      breakpoint: 2399,
      settings: {
        slidesToShow: 8,
        slidesToScroll: 8,
      },
    },
    {
      breakpoint: 2000,
      settings: {
        slidesToShow: 11,
        slidesToScroll: 11,
      },
    },
    {
      breakpoint: 1640,
      settings: {
        slidesToShow: 10,
        slidesToScroll: 10,
      },
    },
    {
      breakpoint: 1440,
      settings: {
        slidesToShow: 9,
        slidesToScroll: 9,
      },
    },
    {
      breakpoint: 1240,
      settings: {
        slidesToShow: 8,
        slidesToScroll: 8,
      },
    },
    {
      breakpoint: 860,
      settings: {
        slidesToShow: 7,
        slidesToScroll: 7,
      },
    },
    {
      breakpoint: 640,
      settings: {
        slidesToShow: 6,
        slidesToScroll: 6,
      },
    },
  ],
};

const Brands = () => {
  const { data, isLoading } = useBrandsQuery({
    offset: 0,
    limit: 200,
    latitude: DEFAULT_LOCATION.latitude,
    longitude: DEFAULT_LOCATION.longitude,
  });

  return (
    <CarouselStyle>
      <Skeleton loading={isLoading} active>
        <Carousel
          infinite={false}
          autoplay={false}
          variableWidth={false}
          className="p-12"
          arrows
          dots={null}
          prevArrow={<Arrow type="left" />}
          nextArrow={<Arrow type="right" />}
          {...MEDIUM_CARD_CAROUSEL_SETTINGS}
        >
          {data?.results?.map((brand) => (
            <BrandItem brand={brand} key={brand.id} />
          ))}
        </Carousel>
      </Skeleton>
    </CarouselStyle>
  );
};

export default Brands;

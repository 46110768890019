import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import FilterCustom from 'components/common/FilterCustom';
import EnquiriesFilterForm from './FilterForm';

const EnquiriesFilterCustom = ({ getListAndSummary }) => {
  const formatTimeSubmit = useCallback(
    (timeArr) =>
      timeArr
        ? {
            $gt: timeArr?.[0]
              ? timeArr[0].startOf('date').toISOString()
              : undefined,
            $lt: timeArr?.[1]
              ? timeArr[1].endOf('date').toISOString()
              : undefined,
          }
        : undefined,
    [],
  );

  const onSubmitFilter = ({ updatedAt, enquiryTypeId, ...values }) => {
    getListAndSummary({
      filter: {
        ...values,
        enquiryTypeId: enquiryTypeId || {
          $exists: true,
        },
        updatedAt: formatTimeSubmit(updatedAt),
      },
    });
  };

  const onClearFilter = () => {
    getListAndSummary({
      filter: {
        enquiryTypeId: {
          $exists: true,
        },
        updatedAt: undefined,
      },
    });
  };

  return (
    <FilterCustom onSubmitFilter={onSubmitFilter} onClearFilter={onClearFilter}>
      <EnquiriesFilterForm />
    </FilterCustom>
  );
};

EnquiriesFilterCustom.propTypes = {
  getListAndSummary: PropTypes.func,
};

EnquiriesFilterCustom.defaultProps = {
  getListAndSummary: () => null,
};

export default EnquiriesFilterCustom;

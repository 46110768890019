import styled from 'styled-components';

export const ExistingJobTemplateGridStyles = styled.div`
  margin-top: 50px;
  .template-row {
    display: flex;
    align-items: stretch;
    flex-wrap: wrap;
  }
  .no-template {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    &-item {
      text-align: center;
      &:nth-child(2) > h3 {
        font-weight: 700;
        margin-top: 25px;
      }
      &:last-child {
        margin-top: 30px;
      }
    }
  }

  .search-input {
    position: fixed;
    top: 88px;
    width: 69%;
    padding: 40px 47px 25px 65px;
    z-index: 2;
    background: ${({ theme }) => theme.background.content};
    margin-left: -64px;
  }

  .loading-template {
    display: flex;
    justify-content: center;
    margin-top: 35px;
  }

  .ant-spin-dot-item {
    background-color: ${({ theme }) => theme.icons.pastelGreen};
  }

  .ant-spin {
    color: ${({ theme }) => theme.icons.pastelGreen};
  }

  .select-one-error {
    color: ${({ theme }) => theme.background.error};
    margin-top: 15px;
    display: inline-block;
  }

  @media only screen and (max-width: 992px) {
    .search-input {
      width: 89%;
      padding: 40px 5px 10px 30px;
      margin-left: -31px;
    }
  }
`;

import { MAX_LIMIT_MAP } from 'configs/localData/constant';

export const handleLoadMapViewData = (
  offset,
  restFilter,
  totalCentres,
  retrieveList,
) => {
  if (offset > totalCentres) {
    return;
  }
  retrieveList({
    filter: {
      outsideFilter: {
        ...restFilter,
        limit: MAX_LIMIT_MAP,
        offset,
        orderBy: 'centreName',
      },
    },
    isRefresh: offset === 0,
  }).then(({ payload }) => {
    if (payload?.data?.ids) {
      handleLoadMapViewData(
        offset + MAX_LIMIT_MAP,
        restFilter,
        payload?.data?.total,
        retrieveList,
      );
    }
  });
};

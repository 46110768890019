import React, { useCallback } from 'react';
import HeaderTitle from 'containers/Centres/components/CentreTabs/EducatorStaffProfilesTab/components/HeaderTitle';
import BoardView from 'containers/Centres/components/CentreTabs/EducatorStaffProfilesTab/components/BoardView';
import { useHistory } from 'react-router-dom';
import { getSearchUrl } from 'utils/tools';
import { StaffProfileFilterFields } from 'types/staffProfiles';
import Filter from './components/Filter';

export interface StaffProfileQueryParams {
  filter?: StaffProfileFilterFields;
  page?: number;
  size?: number;
}

const EducatorStaffProfileTab = () => {
  const { location, push } = useHistory();
  const pathName = location?.pathname;

  const pushQuery = useCallback(
    (query: StaffProfileQueryParams) => {
      push(`${pathName}?${getSearchUrl(query)}`);
    },
    [pathName, push],
  );

  return (
    <div className="flex flex-col gap-24 ">
      <HeaderTitle />
      <Filter pushQuery={pushQuery} />
      <BoardView pushQuery={pushQuery} />
    </div>
  );
};

export default EducatorStaffProfileTab;

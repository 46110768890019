import React from 'react';
import RestShow from 'containers/rest/Show';
import { usersSelectors } from 'redux/users/selectors';
import { useSelector } from 'react-redux';
import i18next from 'i18next';
import UserDetail from '../components/UserDetail';

const UsersShow = (props) => {
  const currentData = useSelector(usersSelectors.getCurrentData);

  const customBreadcrumb = [
    {
      title: i18next.t('sideBar.config'),
      path: '/config',
    },
    {
      title: i18next.t('users.header'),
      path: '/config/users',
    },
    {
      title: `${currentData?.firstName || ''} ${currentData?.lastName || ''}`,
      path: '#',
    },
  ];

  return (
    <RestShow
      {...props}
      resource="users"
      isResetStyle
      header="users.titleDetail"
      customBreadcrumb={customBreadcrumb}
    >
      <UserDetail />
    </RestShow>
  );
};

export default UsersShow;

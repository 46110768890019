import {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
  createApi,
  fetchBaseQuery,
} from '@reduxjs/toolkit/query/react';
import { getCentreHeader, stringifyObjectWithBrackets } from 'api/utils';
import { getClientTimeZone, isRequestFailed } from 'utils/tools';
import { TIME_ZONE_HEADER_KEY } from 'configs/localData/constant';
import { notification } from 'antd';
import tagTypes from 'redux/@rtkQuery/tagTypes';
import i18next from 'i18next';
import {
  CheckCircleIcon,
  CloseCircleIcon,
  CloseXIcon,
} from 'components/common/SVGIcon';
import React from 'react';
import { getImpersonateSessionToken, getSessionToken } from 'utils/token';
import { logoutAction, refreshAccessToken } from 'utils/apiUtils';

interface ExtraOptions {
  isShowSuccess?: boolean;
  isShowError?: boolean;
}

interface MessageError {
  message: string;
}

const baseQuery = fetchBaseQuery({
  baseUrl: `${process.env.REACT_APP_BASE_API_URL}`,
  prepareHeaders: (headers) => {
    headers.set('Content-Type', 'application/json; charset=UTF-8');
    headers.set(TIME_ZONE_HEADER_KEY, getClientTimeZone());

    const token = getImpersonateSessionToken() || getSessionToken();
    if (token) {
      headers.set('Authorization', `Bearer ${token}`);
    }

    const [centreIdKey, centreIdValue] = Object.entries(getCentreHeader())[0];
    if (centreIdValue) {
      headers.set(centreIdKey, centreIdValue);
    }

    return headers;
  },
  paramsSerializer: (params) => stringifyObjectWithBrackets(params),
});

const baseQueryHandler: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (
  args,
  api,
  extraOptions: ExtraOptions = {
    isShowSuccess: false,
    isShowError: true,
  },
) => {
  const { isShowSuccess, isShowError, ...restExtraOptions } = extraOptions;
  let result = await baseQuery(args, api, restExtraOptions);

  if (result?.error?.status === 401) {
    const token = await refreshAccessToken();
    if (token) {
      result = await baseQuery(args, api, restExtraOptions);
    } else {
      logoutAction();
    }
  }

  if (isRequestFailed(result)) {
    const data = result?.error?.data as MessageError;
    isShowError &&
      notification.error({
        message: i18next.t('error.title'),
        description: data?.message || i18next.t('error.description'),
        icon: <CloseCircleIcon />,
        closeIcon: <CloseXIcon />,
      });
  } else {
    isShowSuccess &&
      notification.success({
        message: i18next.t('success.title'),
        description: i18next.t('success.description'),
        icon: <CheckCircleIcon />,
        closeIcon: <CloseXIcon />,
      });
  }
  return result;
};

export const baseApi = createApi({
  baseQuery: baseQueryHandler,
  tagTypes,
  endpoints: () => ({}),
});

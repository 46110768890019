import styled from 'styled-components';

export const EnquiriesSummaryStyles = styled.div``;

export const AverageEnquiriesStyles = styled.div`
  width: 220px;
  max-width: 100%;
  padding: 5px 15px;
  background: #eef2ff;
  border-radius: 4px;
  color: #316dde;
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  margin: auto;
`;

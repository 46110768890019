import { get, put } from './utils';

export const getAllAutomatedResponsesApi = (centreId) =>
  get(`/admin/centres/${centreId}/automated-responses`);

export const updateAppSettingsApi = (centreId, key, data) =>
  put(`/admin/centres/${centreId}/app-settings/${key}`, data);

export const getAllAutomatedResponsesAllCentresApi = () =>
  get(`/admin/centres/automated-responses`);

export const updateAppSettingsAllCentresApi = (key, data) =>
  put(`/admin/centres/app-settings/${key}`, data);

export const getByKeyAppSettingsOfCentreApi = (centreId, key) =>
  get(`/admin/centres/${centreId}/centre-app-settings/${key}`);

export const updateAppSettingsOfCentreApi = (centreId, key, data) =>
  put(`/admin/centres/${centreId}/centre-app-settings/${key}`, data);

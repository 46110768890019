import React, { useMemo } from 'react';
import moment from 'moment';
import i18next from 'i18next';
import FilterCustom from 'components/common/FilterCustom';
import GridCards from 'components/GridCards';
import RestSelect from 'components/RestInput/RestSelect';
import { CHANNEL_OPTIONS } from 'configs/localData';
import {
  useGetLeadActivitiesConfigQuery,
  useGetLeadActivitiesEmailsQuery,
  useGetLeadActivitiesPRNumberQuery,
  useGetLeadActivitiesPhonesQuery,
  useGetLeadActivitiesSENumberQuery,
  useGetLeadActivitiesUsersQuery,
} from 'redux/@rtkQuery/leadActivities';
import InfiniteAutoComplete from 'components/InfiniteFormItem/InfiniteAutoComplete';
import { RestInputContext } from 'components/RestInput/RestInputContext';
import { AutoComplete, DatePicker, Empty, Form, Skeleton, Tooltip } from 'antd';
import {
  DEFAULT_CURRENT_PAGE,
  DEFAULT_LIMIT_ITEM,
  PREFIX_URL,
  START_DATE_TRACKING_EVENT,
} from 'configs/localData/constant';
import { Store } from 'antd/lib/form/interface';
import { useHistory } from 'react-router-dom';
import { convertSearchUrl, getDateMoment, getQueryParams } from 'utils/tools';
import { LeadActivitiesPayload } from 'types/leadActivities';
import { isEmpty } from 'lodash-es';
import { formatFromToTimeFilterSubmit } from 'utils/dataUtils';
import {
  DEFAULT_FILTER,
  FILTER_SEARCH_KEYS,
} from 'containers/LeadActivities/constants';
import FormSelectCheckbox from 'components/form/FormSelectCheckbox';
import { useSelector } from 'react-redux';
import { getCentresOfUser } from 'redux/auth/selectors';
import ReferenceInput from 'containers/rest/ReferenceInput';
import { FilterContainer } from './styles';

const { RangePicker } = DatePicker;

const Filter = () => {
  const { push, location } = useHistory();

  const { filter, size } = useMemo<LeadActivitiesPayload>(
    () => getQueryParams(location.search) || {},
    [location.search],
  );

  const centresData = useSelector(getCentresOfUser);
  const { data: filterConfig, isLoading } = useGetLeadActivitiesConfigQuery({});

  const initialValues = {
    channel: DEFAULT_FILTER.channel,
    ...filter,
    ...(!isEmpty(filter?.clickThroughDate) && {
      clickThroughDate: [
        getDateMoment(filter?.clickThroughDate?.from),
        getDateMoment(filter?.clickThroughDate?.to),
      ],
    }),
  };

  const disabledClickThroughDate = (current) => {
    if (!current) {
      return false;
    }
    const isAfterToday = current > moment().endOf('day');
    const isBeforeTrackingTime =
      current < moment(START_DATE_TRACKING_EVENT).startOf('day');

    return isAfterToday || isBeforeTrackingTime;
  };

  const onSubmitFilter = (values: Store) => {
    const clickThroughDate = formatFromToTimeFilterSubmit(
      values?.clickThroughDate,
    );

    push({
      search: `?${convertSearchUrl({
        filter: {
          ...values,
          clickThroughDate,
        },
        page: DEFAULT_CURRENT_PAGE,
        size: size || DEFAULT_LIMIT_ITEM,
      })}`,
    });
  };

  const onClearFilter = () => {
    push({
      search: `?${convertSearchUrl({
        filter: {
          channel: DEFAULT_FILTER.channel,
        },
      })}`,
    });
  };

  const filterSelectProps = {
    valueProp: 'label',
    titleProp: 'label',
    format: (config) =>
      Object.entries(config || {}).map(([key, value]) => ({
        value: key,
        label: value,
      })),
  };

  const getInfiniteFormItemProps = (propKey: string) => ({
    searchKey: propKey,
    labelProp: propKey,
    valueProp: propKey,
  });

  if (isLoading) {
    return <Skeleton active className="mb-32" />;
  }

  return (
    <FilterContainer className="mb-32">
      <FilterCustom
        onSubmitFilter={onSubmitFilter}
        onClearFilter={onClearFilter}
        initialValues={initialValues}
        responsiveFilter={{
          xxl: 20,
          xl: 20,
          lg: 24,
          md: 24,
          xs: 24,
        }}
        responsiveAction={{
          xxl: 4,
          xl: 4,
          lg: 24,
          md: 24,
          xs: 24,
        }}
      >
        <RestInputContext.Consumer>
          {() => (
            <GridCards
              gap="8px"
              size={[8, 10]}
              columns={{ sm: 1, md: 2, lg: 2, xxl: 7 }}
            >
              <RestSelect
                source="channel"
                placeholder={i18next.t('marketingMetrics.channel')}
                valueProp="value"
                titleProp="label"
                resourceData={Object.values(CHANNEL_OPTIONS) || []}
                isShowTooltip
                autoComplete="off"
              />
              <RestSelect
                source="loginStatus"
                placeholder={i18next.t('placeholder.loginStatus')}
                resourceData={filterConfig?.loginStatus}
                isShowTooltip
                autoComplete="off"
                {...filterSelectProps}
                titleProp="value"
              />
              <InfiniteAutoComplete
                name="userName"
                showSearch
                filterOption={false}
                useQuery={useGetLeadActivitiesUsersQuery}
                placeholder={i18next.t('placeholder.userName')}
                {...getInfiniteFormItemProps(FILTER_SEARCH_KEYS.userName)}
              />
              <InfiniteAutoComplete
                name="userEmail"
                showSearch
                filterOption={false}
                useQuery={useGetLeadActivitiesEmailsQuery}
                placeholder={i18next.t('placeholder.userEmail')}
                {...getInfiniteFormItemProps(FILTER_SEARCH_KEYS.userEmail)}
              />
              <InfiniteAutoComplete
                name="userPhone"
                showSearch
                filterOption={false}
                useQuery={useGetLeadActivitiesPhonesQuery}
                placeholder={i18next.t('placeholder.userPhone')}
                {...getInfiniteFormItemProps(FILTER_SEARCH_KEYS.userPhone)}
              />
              <FormSelectCheckbox
                name="types"
                valueProp="label"
                labelProp="label"
                placeholder={i18next.t('placeholder.clickThroughType')}
                data={filterSelectProps.format(filterConfig?.clickThroughType)}
                dropdownMatchSelectWidth={false}
                className="form-select-checkbox"
              />
              <RestSelect
                SelectElement={AutoComplete}
                source="centreName"
                placeholder="centres.centreName"
                valueProp="name"
                titleProp="name"
                resourceData={centresData || []}
                isShowTooltip
                autoComplete="off"
                notFoundContent={<Empty />}
              />

              <ReferenceInput
                prefixUrl={PREFIX_URL.admin}
                resource="users/me/providers/basic-info"
                source="providerName"
                searchKey="name"
                notLikeFilter
                initialFilter={{ orderBy: 'name' }}
                autoComplete="off"
              >
                <RestSelect
                  SelectElement={AutoComplete}
                  titleProp="name"
                  valueProp="name"
                  placeholder="centres.providerName"
                  isFilterOption={false}
                  autoComplete="off"
                  isShowTooltip
                  notFoundContent={<Empty />}
                />
              </ReferenceInput>
              <RestSelect
                source="state"
                placeholder="centres.state"
                resourceData={filterConfig?.state}
                isShowTooltip
                autoComplete="off"
                {...filterSelectProps}
              />
              <ReferenceInput
                prefixUrl={PREFIX_URL.admin}
                source="brandName"
                resource="brands"
                searchKey="brand.name"
                initialFilter={{ orderBy: 'name' }}
                autoComplete="off"
              >
                <RestSelect
                  SelectElement={AutoComplete}
                  titleProp="name"
                  valueProp="name"
                  placeholder="input.brand.name"
                  isFilterOption={false}
                  autoComplete="off"
                  isShowTooltip
                  notFoundContent={<Empty />}
                />
              </ReferenceInput>
              <RestSelect
                source="subscription"
                placeholder={'centres.subscription'}
                resourceData={filterConfig?.subscription}
                isShowTooltip
                autoComplete="off"
                {...filterSelectProps}
              />

              <InfiniteAutoComplete
                name="PRNumber"
                showSearch
                filterOption={false}
                useQuery={useGetLeadActivitiesPRNumberQuery}
                placeholder={i18next.t('centres.PRNumber')}
                {...getInfiniteFormItemProps(FILTER_SEARCH_KEYS.PRNumber)}
              />
              <InfiniteAutoComplete
                name="SENumber"
                showSearch
                filterOption={false}
                useQuery={useGetLeadActivitiesSENumberQuery}
                placeholder={i18next.t('centres.SENumber')}
                {...getInfiniteFormItemProps(FILTER_SEARCH_KEYS.SENumber)}
              />
              <Tooltip
                title={i18next.t('placeholder.clickThroughDate')}
                placement="right"
              >
                <Form.Item name="clickThroughDate">
                  <RangePicker
                    disabledDate={disabledClickThroughDate}
                    format="DD MMM YYYY"
                    ranges={{
                      [i18next.t('dateTimePickerText.today')]: [
                        moment().startOf('day'),
                        moment().endOf('day'),
                      ],
                      [i18next.t('dateTimePickerText.yesterday')]: [
                        moment().subtract(1, 'days').startOf('day'),
                        moment().subtract(1, 'days').endOf('day'),
                      ],
                      [i18next.t('dateTimePickerText.last7Days')]: [
                        moment().subtract(7, 'days').startOf('day'),
                        moment().subtract(1, 'days').endOf('day'),
                      ],
                      [i18next.t('dateTimePickerText.last30Days')]: [
                        moment().subtract(30, 'days').startOf('day'),
                        moment().subtract(1, 'days').endOf('day'),
                      ],
                      [i18next.t('dateTimePickerText.last12Months')]: [
                        moment()
                          .subtract(1, 'days')
                          .subtract(12, 'months')
                          .startOf('day'),
                        moment().subtract(1, 'days').endOf('day'),
                      ],
                    }}
                  />
                </Form.Item>
              </Tooltip>
            </GridCards>
          )}
        </RestInputContext.Consumer>
      </FilterCustom>
    </FilterContainer>
  );
};

export default Filter;

import React, { useState, useContext, useEffect } from 'react';

import i18next from 'i18next';
import { useSelector } from 'react-redux';
import { Col, Row } from 'antd';
import { getRolesObjKey } from 'redux/config/selectors';

import { ROLES_CONST } from 'configs/localData/permissions';
import RestSelect from 'components/RestInput/RestSelect';
import { RestInputContext } from 'components/RestInput/RestInputContext';

import useRolesData from '../../hooks/useRolesData';
import CentreForm from './CentreForm';
import ProviderForm from './ProviderForm';
import CompanyForm from './CompanyForm';

const RoleForm = () => {
  const rolesObjKey = useSelector(getRolesObjKey);

  const rolesDefault = useRolesData();

  const { record } = useContext(RestInputContext);

  const [isSelectCentres, setIsSelectCentres] = useState(false);

  const [isSelectProviders, setIsSelectProviders] = useState(false);

  const [isSelectCompanies, setIsSelectCompanies] = useState(false);

  const handleOnChangeRole = (value) => {
    const nameRole = rolesObjKey?.[value]?.name;
    if (
      nameRole === ROLES_CONST.companyAdmin ||
      nameRole === ROLES_CONST.companyStaff
    ) {
      setIsSelectProviders(false);
      setIsSelectCentres(false);
      setIsSelectCompanies(true);
    } else if (
      nameRole === ROLES_CONST.providerAdmin ||
      nameRole === ROLES_CONST.providerStaff
    ) {
      setIsSelectProviders(true);
      setIsSelectCentres(false);
      setIsSelectCompanies(false);
    } else if (
      nameRole === ROLES_CONST.centreAdmin ||
      nameRole === ROLES_CONST.centreStaff
    ) {
      setIsSelectProviders(false);
      setIsSelectCentres(true);
      setIsSelectCompanies(false);
    } else {
      setIsSelectProviders(false);
      setIsSelectCentres(false);
      setIsSelectCompanies(false);
    }
  };

  useEffect(() => {
    record?.role?.id && handleOnChangeRole(record?.role?.id);
  }, []); // eslint-disable-line

  return (
    <div>
      <div className="title-session">{i18next.t('users.selectRole')}</div>
      <Row>
        <Col lg={12} md={12} xs={24}>
          <RestSelect
            source="roleId"
            required
            resourceData={rolesDefault}
            valueProp="id"
            titleProp="description"
            onChange={handleOnChangeRole}
            defaultValue={record?.role?.id}
          />
        </Col>
      </Row>
      {isSelectCentres && <CentreForm />}
      {isSelectProviders && <ProviderForm />}
      {isSelectCompanies && <CompanyForm />}
    </div>
  );
};

export default RoleForm;

import React from 'react';
import i18next from 'i18next';
import { Link } from 'react-router-dom';
import { Button } from 'antd';
import { CheckCircleOutlined } from '@ant-design/icons';
import Text from 'components/common/Text';
import AuthContact from 'components/common/AuthContact';
import ResetPasswordStyleWrapper from './styles';

const ResetPasswordSuccess = () => (
  <ResetPasswordStyleWrapper className="isoSignInPage">
    <div className="isoLoginContentWrapper">
      <div className="isoLoginContent">
        <div className="success-icon-div">
          <CheckCircleOutlined />
        </div>
        <Text type="h3" align="center">
          {i18next.t('resetPassword.success.title')}
        </Text>
        <Text align="center" className="txtDescription">
          {i18next.t('resetPassword.success.description')}
        </Text>
        <br />
        <Button type="primary">
          <Link to="/login">{i18next.t('login.loginBtn')}</Link>
        </Button>
        <AuthContact
          className="mt-20"
          text="auth.contactText.l1ResetPassword"
        />
      </div>
    </div>
  </ResetPasswordStyleWrapper>
);

export default ResetPasswordSuccess;

import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Table } from 'antd';
import useFormatFnc from './useFormat';

const AverageAllCompanies = ({ averageAllCompany }) => {
  const useFormat = useFormatFnc();

  const averageCompanyData = useMemo(
    () => [
      {
        value: '',
      },
      {
        value: useFormat.formatName(averageAllCompany),
      },
      {
        value: useFormat.formatColumnData(
          averageAllCompany?.averageServiceApprovalAge,
        ),
      },
      {
        value: useFormat.formatColumnData(averageAllCompany?.totalCentres),
      },
      {
        value: useFormat.formatColumnData(averageAllCompany?.approvedPlaces),
      },
      {
        value: useFormat.formatColumnData(
          averageAllCompany?.averageApprovedPlaces,
        ),
      },
      {
        value: useFormat.formatMarketShare(averageAllCompany?.marketShare),
      },
      {
        value: useFormat.formatRevenuePotential(
          averageAllCompany?.revenuePotential,
        ),
      },
      {
        value: useFormat.formatKindiRating(
          averageAllCompany?.averageKindiCareRating,
        ),
      },
      {
        value: useFormat.formatReviewScore(
          averageAllCompany?.averageReviewScore,
        ),
      },
      {
        value: useFormat.formatAverageNumberOfReviews(
          averageAllCompany?.averageNumberOfReviews,
        ),
      },
      {
        value: useFormat.formatPercentColumnData(
          averageAllCompany?.provisional,
        ),
      },
      {
        value: useFormat.formatPercentColumnData(
          averageAllCompany?.significantImprovementRequired,
        ),
      },
      {
        value: useFormat.formatPercentColumnData(
          averageAllCompany?.workingTowardsNQS,
        ),
      },
      {
        value: useFormat.formatPercentColumnData(averageAllCompany?.meetingNQS),
      },
      {
        value: useFormat.formatPercentColumnData(
          averageAllCompany?.exceedingNQS,
        ),
      },
      {
        value: useFormat.formatPercentColumnData(averageAllCompany?.excellent),
      },
      {
        value: useFormat.formatColumnData(
          averageAllCompany?.averageAgeNQSRating,
        ),
      },
      {
        value: useFormat.formatMarketShare(
          averageAllCompany?.averageListingsCompleteness,
        ),
      },
    ],
    [averageAllCompany, useFormat],
  );

  return (
    <Table.Summary fixed>
      <Table.Summary.Row className="summary-row">
        {averageCompanyData?.map((item, index) => (
          <Table.Summary.Cell index={index} key={Number(index)}>
            {item?.value}
          </Table.Summary.Cell>
        ))}
      </Table.Summary.Row>
    </Table.Summary>
  );
};
AverageAllCompanies.propTypes = {
  averageAllCompany: PropTypes.object,
};
export default AverageAllCompanies;

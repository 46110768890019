import React from 'react';
import PropTypes from 'prop-types';
import CardCustom from 'components/common/CardCustom';
import { HealthIcon, ShieldTickIcon } from 'components/common/SVGIcon';
import CardCustomContent from '../../CardContentCustom';
import ImmunisationsContent from '../../CardContentCustom/ImmunisationsContent';

const MyImmunisations = ({ immunisations }) => (
  <div className="mb-32">
    <CardCustom
      title="jobApplications.generalInfo.myImmunisations"
      Icon={ShieldTickIcon}
    >
      <div>
        {immunisations?.map((item, index) => (
          <CardCustomContent Icon={HealthIcon} key={Number(index)}>
            <ImmunisationsContent item={item} />
          </CardCustomContent>
        ))}
      </div>
    </CardCustom>
  </div>
);

MyImmunisations.propTypes = {
  immunisations: PropTypes.array,
};

export default MyImmunisations;

import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import i18next from 'i18next';
import TableCustom from 'components/common/TableCustom';
import {
  DEFAULT_CURRENT_PAGE,
  DEFAULT_SORT_ADVERTISEMENT,
  LIMIT_ITEM,
} from 'containers/Advertisement/constants';
import { RetrieveList } from 'containers/Advertisement/List';
import { formatNullString, getFilterFromUrl } from 'utils/tools';
import { useLocation } from 'react-router-dom';
import { Modal, Space, Typography } from 'antd';
import EditButton from 'components/RestActions/EditButton';
import DeleteButton from 'components/RestActions/DeleteButton';
import DuplicateButton from 'components/RestActions/DuplicateButton';
import {
  ORDER_BY_TYPE,
  SORT_ASCEND_VALUE,
  SORT_DESCEND_VALUE,
} from 'configs/localData/constant';
import { useHistory } from 'react-router';
import useModifyAdsPermission from 'containers/Advertisement/hooks/useModifyAdsPermission';
import { getAdsResourceDataSelector } from 'redux/advertisement/selectors';
import { AdsItemResponse } from 'api/advertisement/type';
import { getAdDetail, deleteAd } from 'redux/advertisement/actions';
import { gotoFirstStep, setCreateAdPayload } from 'redux/advertisement/slice';
import { ThunkDispatch } from '@reduxjs/toolkit';
import { RootState } from 'redux/store';
import { ExclamationCircleFilled } from '@ant-design/icons';
import useFormatFnc from './useFormat';
import { PushQuery } from '../Filter';

const { confirm } = Modal;
const { Text } = Typography;

interface Props {
  advertisements?: AdsItemResponse[];
  loading: boolean;
  retrieveList?: (params: RetrieveList) => void;
  pushQuery?: (params: PushQuery) => void;
}

const AdvertisementList = ({
  advertisements,
  loading,
  pushQuery,
  retrieveList,
}: Props) => {
  const dispatch = useDispatch<ThunkDispatch<RootState, void, any>>();
  const useFormat = useFormatFnc();
  const { search } = useLocation();
  const { filter } = getFilterFromUrl(search);
  const { push } = useHistory();
  const { canModifyAds } = useModifyAdsPermission();
  const resourceData = useSelector(getAdsResourceDataSelector);

  const getSorterOrder = useCallback(
    (source) => {
      if (filter.sorts?.[0] === source) return SORT_ASCEND_VALUE;
      if (filter.sorts?.[0] === `-${source}`) return SORT_DESCEND_VALUE;
      return '';
    },
    [filter],
  );

  const onChange = (e, _, sorter) => {
    const formatSort =
      sorter && sorter.field && sorter.order
        ? {
            orderByKey: sorter.field,
            orderByType:
              sorter.order === SORT_DESCEND_VALUE
                ? ORDER_BY_TYPE.DESC
                : ORDER_BY_TYPE.ASC,
          }
        : null;

    const restFilter = {
      size: e.pageSize,
      page: e.current,
      sorts: [formatSort || DEFAULT_SORT_ADVERTISEMENT],
      filter: { ...filter.filter },
    };
    retrieveList({
      filter: restFilter,
      isRefresh: true,
      keepFilter: true,
    });

    pushQuery({
      'sorts[]': restFilter.sorts,
      ...restFilter,
    });
  };

  const goToAdDetail = (id) => {
    push(`/advertisement/${id}/show`);
  };

  const gotoDuplicate = (id) => {
    confirm({
      title: i18next.t('advertisement.duplicateConfirmMessage'),
      okText: i18next.t('button.create'),
      icon: <ExclamationCircleFilled />,
      onOk() {
        dispatch(getAdDetail(id)).then(({ payload }) => {
          dispatch(gotoFirstStep());
          dispatch(setCreateAdPayload(useFormat.useConvertCreateData(payload)));
          push(`/advertisement/create/steps`);
        });
      },
    });
  };

  const handleDeleteAd = (id: string) => {
    Modal.confirm({
      title: i18next.t('advertisement.deleteAdvertisementTitle'),
      content: i18next.t('advertisement.deleteAdvertisementConfirm'),
      onOk() {
        dispatch(deleteAd(id));
      },
      okText: i18next.t('button.delete'),
    });
  };

  const columns = [
    {
      title: i18next.t('advertisement.thumbnail'),
      dataIndex: 'postMedias',
      render: (value) => useFormat.viewThumbnail(value?.[0] ?? {}),
      fixed: 'left',
    },
    {
      title: i18next.t('advertisement.headline'),
      dataIndex: 'headline',
      sorter: true,
      defaultSortOrder: getSorterOrder('headline'),
      render: (value) => <div className="fw-bold">{value ?? '-'}</div>,
      fixed: 'left',
      width: 200,
    },
    {
      title: i18next.t('advertisement.deliveryStatus'),
      dataIndex: 'deliveryStatus',
      sorter: true,
      defaultSortOrder: getSorterOrder('deliveryStatus'),
      render: (value) => useFormat.StatusButton(value),
    },
    {
      title: i18next.t('advertisement.start'),
      dataIndex: 'startTime',
      sorter: true,
      width: 200,
      defaultSortOrder: getSorterOrder('startTime'),
      render: (value) => (value ? useFormat.formatAvailableStart(value) : '-'),
    },
    {
      title: i18next.t('advertisement.end'),
      dataIndex: 'endTime',
      sorter: true,
      width: 200,
      defaultSortOrder: getSorterOrder('startTime'),
      render: (value) => (value ? useFormat.formatAvailableStart(value) : '-'),
    },
    {
      title: i18next.t('advertisement.impressions'),
      dataIndex: 'impressionCount',
      sorter: true,
      defaultSortOrder: getSorterOrder('impressionCount'),
      render: (value) => <Text strong>{formatNullString(value)}</Text>,
    },
    {
      title: i18next.t('advertisement.clicks'),
      dataIndex: 'clickCount',
      sorter: true,
      defaultSortOrder: getSorterOrder('clickCount'),
      render: (value) => <Text strong>{formatNullString(value)}</Text>,
    },
    {
      title: i18next.t('advertisement.shares'),
      dataIndex: 'shareCount',
      sorter: true,
      defaultSortOrder: getSorterOrder('shareCount'),
      render: (value) => <Text strong>{formatNullString(value)}</Text>,
    },
    {
      title: i18next.t('advertisement.identity'),
      dataIndex: 'identities',
      width: 200,
      render: (value) => (
        <>
          {value
            ? value?.map((item) => (
                <div className="mb-8">{useFormat.StatusButton(item)}</div>
              ))
            : '-'}
        </>
      ),
    },
    {
      title: i18next.t('advertisement.createdAt'),
      dataIndex: 'createdAt',
      width: 200,
      sorter: true,
      defaultSortOrder: getSorterOrder('createdAt'),
      render: (value) => useFormat.formatAvailableStart(value),
    },
    {
      title: i18next.t('advertisement.lastUpdated'),
      dataIndex: 'updatedAt',
      width: 200,
      sorter: true,
      defaultSortOrder: getSorterOrder('updatedAt'),
      render: (value) => useFormat.formatAvailableStart(value),
    },
    {
      title: i18next.t('advertisement.action'),
      dataIndex: 'id',
      fixed: 'right',
      width: 150,
      render: (id, record) => (
        <Space>
          <EditButton
            isView
            action="isView"
            onClickCustom={() => {
              goToAdDetail(id);
            }}
          />
          <DuplicateButton
            action="isView"
            disabled={!canModifyAds}
            onClickCustom={() => {
              gotoDuplicate(id);
            }}
          />
          <DeleteButton
            action="isView"
            disabled={!canModifyAds}
            record={record}
            onClickCustom={() => {
              handleDeleteAd(id);
            }}
          />
        </Space>
      ),
    },
  ];
  return (
    <div>
      <TableCustom
        xScroll={2200}
        columns={columns}
        data={advertisements}
        onChange={onChange}
        loading={loading}
        pagination={{
          pageSize: filter?.size || LIMIT_ITEM,
          current: filter?.page || DEFAULT_CURRENT_PAGE,
          total: resourceData?.total,
        }}
      />
    </div>
  );
};
export default AdvertisementList;

import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { CompareRatingBarChartWrapper } from 'containers/CompanyBenchmarking/components/CompareRatingBarChart/styles';
import { Col, Divider, Row } from 'antd';
import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { useSelector } from 'react-redux';
import { getCompanyBenchmarkingCompareRatingSelector } from 'redux/companyBenchmarking/selectors';
import {
  ALL_COMPANIES_COLOR,
  ALL_COMPANIES_DATA_KEY,
  AVG_POSITION_LINE,
  CHART_DATE_FORMAT,
  DEFAULT_BAR_SIZE,
  DEFAULT_COMPARISON_WIDTH_CHART,
  DEFAULT_HOVER_COLOR,
  DEFAULT_LEGEND_TYPE,
  DEFAULT_MARGIN_CHART,
  DEFAULT_RADIUS_STYLE,
  SELECTED_COMPANY_COLOR,
  SELECTED_COMPANY_DATA_KEY,
  TICK_COLOR,
} from 'containers/CompanyBenchmarking/localData/constants';
import i18next from 'i18next';
import { useLocation } from 'react-router';
import { getFilterFromUrl } from 'utils/tools';
import moment from 'moment';
import { isEmpty } from 'lodash-es';
import CustomTooltip from 'containers/CompanyBenchmarking/components/CompareRatingBarChart/CustomTooltip';
import {
  calculateChartWidthByDataLength,
  renderAverageLine,
} from 'containers/CompanyBenchmarking/utils/dataUtils';
import LegendLabel from 'containers/CompanyBenchmarking/components/CompareRatingBarChart/LegendLabel';
import PropTypes from 'prop-types';

const CompareRatingBarChart = ({ handleScroll, ownedCompanies }, ref) => {
  const barChartRef = useRef();
  const { search } = useLocation();
  const { filter } = getFilterFromUrl(search);
  const currentDate = moment().format(CHART_DATE_FORMAT);
  const allCompaniesLabel = i18next.t(
    'companyBenchmarking.compareChart.allCompaniesLabel',
  );
  const [isEmptyCompany, setIsEmptyCompany] = useState(false);
  const [verticalRangeChart, setVerticalRangeChart] = useState([0, 0]);
  const [selectedCompanyName, setSelectedCompanyName] = useState('');
  const [widthChart, setWidthChart] = useState(DEFAULT_COMPARISON_WIDTH_CHART);
  const [avgAllCompanyVerticalLinePoint, setAvgAllCompanyVerticalLinePoint] =
    useState({});
  const [avgUserCompanyVerticalLinePoint, setAvgUserCompanyVerticalLinePoint] =
    useState({});
  const selectedNationalState = filter?.state;
  const {
    charts: chartData,
    maxVerticalValue,
    userCompaniesRatingAvg,
    allCompaniesRatingAvg,
  } = useSelector(getCompanyBenchmarkingCompareRatingSelector);

  useImperativeHandle(ref, () => ({
    handleImperativeScroll() {
      barChartRef.current.scrollIntoView({ behavior: 'smooth' });
    },
  }));

  useEffect(() => {
    const dataLength = chartData?.length;
    if (dataLength > 0) {
      const widthChart = calculateChartWidthByDataLength(dataLength);
      setVerticalRangeChart([0, Math.ceil(maxVerticalValue)]);
      setWidthChart(widthChart);

      userCompaniesRatingAvg === 'NaN'
        ? setIsEmptyCompany(true)
        : setIsEmptyCompany(false);

      const avgAllVerticalPoint = renderAverageLine(
        chartData,
        allCompaniesRatingAvg,
      );

      const avgUserVerticalPoint = renderAverageLine(
        chartData,
        userCompaniesRatingAvg,
      );

      setAvgAllCompanyVerticalLinePoint(avgAllVerticalPoint);
      setAvgUserCompanyVerticalLinePoint(avgUserVerticalPoint);

      handleScroll();
    }
  }, [
    chartData,
    maxVerticalValue,
    selectedNationalState,
    userCompaniesRatingAvg,
    allCompaniesRatingAvg,
    handleScroll,
  ]);

  useEffect(() => {
    if (ownedCompanies.length > 0 && !isEmpty(filter) && !isEmptyCompany) {
      ownedCompanies.forEach((company) => {
        if (company?.id === filter?.companyId) {
          setSelectedCompanyName(company?.name);
        }
      });
    }
  }, [filter, ownedCompanies, isEmptyCompany]);

  return (
    <>
      {chartData?.length ? (
        <CompareRatingBarChartWrapper ref={barChartRef}>
          <Row>
            <Col
              xl={24}
              lg={24}
              md={24}
              xs={24}
              justify="center"
              align="center"
              className="chart-header"
            >
              <span className="chart-title">
                {!isEmptyCompany
                  ? i18next.t('companyBenchmarking.compareChart.title', {
                      selectedCompanyName,
                      selectedNationalState,
                    })
                  : i18next.t('companyBenchmarking.compareChart.defaultTitle', {
                      selectedNationalState,
                    })}
              </span>
              <span className="current-date">{currentDate}</span>
            </Col>
          </Row>
          <Divider />
          <Row className="custom-row">
            <Col
              xl={24}
              lg={24}
              md={24}
              xs={24}
              justify="start"
              align="center"
              className="chart-body"
            >
              <ResponsiveContainer
                width={widthChart}
                minWidth={widthChart - 1}
                minHeight={360}
              >
                <BarChart
                  data={chartData}
                  margin={DEFAULT_MARGIN_CHART}
                  barCategoryGap={12}
                  barGap={4}
                >
                  <Tooltip
                    animationDuration={300}
                    animationEasing="ease-out"
                    cursor={{ fill: DEFAULT_HOVER_COLOR }}
                    content={<CustomTooltip isEmptyCompany={isEmptyCompany} />}
                    wrapperStyle={{
                      top: -20,
                      left: -130,
                    }}
                  />
                  <CartesianGrid
                    vertical={false}
                    stroke={DEFAULT_HOVER_COLOR}
                  />
                  <XAxis
                    dataKey="rating"
                    tickLine={false}
                    axisLine={false}
                    tick={{ fill: TICK_COLOR }}
                    type="category"
                    interval={0}
                  />
                  <YAxis
                    domain={verticalRangeChart}
                    tickLine={false}
                    axisLine={false}
                    tick={{ fill: TICK_COLOR }}
                    tickFormatter={(item) => `${item.toFixed(2)}%`}
                    width={70}
                  />
                  <Legend
                    wrapperStyle={{ paddingTop: '15px' }}
                    content={
                      <LegendLabel
                        isEmptyCompany={isEmptyCompany}
                        userCompaniesRatingAvg={userCompaniesRatingAvg}
                        allCompaniesRatingAvg={allCompaniesRatingAvg}
                      />
                    }
                  />
                  <Bar
                    barSize={DEFAULT_BAR_SIZE}
                    name={allCompaniesLabel}
                    dataKey={ALL_COMPANIES_DATA_KEY}
                    fill={ALL_COMPANIES_COLOR}
                    radius={DEFAULT_RADIUS_STYLE}
                    legendType={DEFAULT_LEGEND_TYPE}
                  />
                  <Bar
                    barSize={DEFAULT_BAR_SIZE}
                    name={selectedCompanyName}
                    dataKey={SELECTED_COMPANY_DATA_KEY}
                    fill={SELECTED_COMPANY_COLOR}
                    radius={DEFAULT_RADIUS_STYLE}
                    legendType={isEmptyCompany ? 'none' : DEFAULT_LEGEND_TYPE}
                  />
                  <ReferenceLine
                    x={avgAllCompanyVerticalLinePoint?.rating ?? ''}
                    stroke={ALL_COMPANIES_COLOR}
                    strokeDasharray="3 3"
                    strokeWidth={2}
                    position={
                      avgAllCompanyVerticalLinePoint?.position ||
                      AVG_POSITION_LINE.START
                    }
                  />
                  <ReferenceLine
                    x={avgUserCompanyVerticalLinePoint?.rating ?? ''}
                    stroke={SELECTED_COMPANY_COLOR}
                    strokeDasharray="3 3"
                    strokeWidth={2}
                    position={
                      avgUserCompanyVerticalLinePoint?.position ||
                      AVG_POSITION_LINE.START
                    }
                  />
                </BarChart>
              </ResponsiveContainer>
            </Col>
          </Row>
        </CompareRatingBarChartWrapper>
      ) : null}
    </>
  );
};

CompareRatingBarChart.propTypes = {
  handleScroll: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.any }),
  ]),
  ownedCompanies: PropTypes.array,
};

export default forwardRef(CompareRatingBarChart);

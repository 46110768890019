import React, {
  forwardRef,
  useImperativeHandle,
  useState,
  useRef,
} from 'react';
import PropTypes from 'prop-types';
import RestEditor from 'components/RestInput/RestEditor';
import JobTemplateModal from 'containers/JobTemplates/components/Modals';

const CandidateResponsibilitiesModal = forwardRef(
  ({ candidateResponsibilities }, ref) => {
    const [visible, setVisible] = useState(false);
    const editorRef = useRef();

    useImperativeHandle(
      ref,
      () => ({
        toggleModal: () => {
          setVisible(true);
        },
      }),
      [],
    );

    const onCancel = () => {
      setVisible(false);
    };

    return (
      <JobTemplateModal
        title="jobs.moreAboutTheJob.whatYouWillBeDoing"
        visible={visible}
        onCancel={onCancel}
        width={1000}
      >
        <RestEditor
          defaultValue={candidateResponsibilities}
          editorRef={editorRef}
          source="candidateResponsibilities"
          header="jobs.moreAboutTheJob.whatYouWillBeDoing"
          height={600}
          required
        />
      </JobTemplateModal>
    );
  },
);

CandidateResponsibilitiesModal.propTypes = {
  candidateResponsibilities: PropTypes.string,
};

export default CandidateResponsibilitiesModal;

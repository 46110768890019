import React, { memo } from 'react';
import i18next from 'i18next';
import { formatDate } from 'utils/tools';
import { FormInstance, Typography } from 'antd';
import { useSelector } from 'react-redux';
import {
  compareOptionSelector,
  dateOptionSelector,
  isCompareSelector,
} from 'redux/marketingMetrics/selector';
import {
  COMPARE_OPTIONS,
  RANGE_PICKER_OPTION_CUSTOM,
} from 'containers/MarketingMetrics/constants';

const { Text } = Typography;
interface Props {
  form: FormInstance;
  rangeDate: moment.Moment[];
}

const TopDatePicker = ({ form, rangeDate }: Props) => {
  const dateOption = useSelector(dateOptionSelector);
  const isCompare = useSelector(isCompareSelector);
  const compareOption = useSelector(compareOptionSelector);
  const compareDate = form?.getFieldValue('compareDate');

  return (
    <div className="h-76 flex flex-center-start pl-12 pr-12">
      <div className="flex flex-direction-column">
        <Text className="text-12 text-gray-500">
          {i18next
            .t(RANGE_PICKER_OPTION_CUSTOM[dateOption]?.label)
            ?.toUpperCase()}
        </Text>
        <Text className="text-primary pt-10">
          {formatDate(rangeDate?.[0], 'DD MMM YYYY')} -{' '}
          {formatDate(rangeDate?.[1], 'DD MMM YYYY')}
        </Text>
      </div>
      {isCompare && (
        <>
          <Text className="fw-bold pl-24 pr-24">
            {i18next.t('marketingMetrics.vs')}.
          </Text>
          <div className="flex flex-direction-column">
            <div>
              <Text className="text-12 text-gray-500">
                {i18next.t('marketingMetrics.compare').toUpperCase()}:{' '}
              </Text>
              {compareOption === COMPARE_OPTIONS.precedingPeriod ? (
                <Text className="text-12 text-gray-500">
                  {i18next.t('marketingMetrics.precedingPeriod').toUpperCase()}
                </Text>
              ) : (
                <Text className="text-12 text-gray-500">
                  {i18next
                    .t('marketingMetrics.samePeriodLastYear')
                    .toUpperCase()}
                </Text>
              )}
            </div>

            <Text className="text-secondary-blue pt-10">
              {formatDate(compareDate?.[0], 'DD MMM YYYY')} -{' '}
              {formatDate(compareDate?.[1], 'DD MMM YYYY')}
            </Text>
          </div>
        </>
      )}
    </div>
  );
};

export default memo(TopDatePicker);

import styled from 'styled-components';

export const GoogleMapStyles = styled.div`
  margin-bottom: 24px;

  .google-map-content {
    position: relative;
    height: 320px;
    width: 100%;

    .ant-select-selector {
      background: #fff !important;
      box-shadow: 0 1px 16px rgb(0 0 0 / 0.1);
    }
  }

  .error-overlay {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.6);
    color: white;
    justify-content: center;
    align-items: center;
    font-size: 26px;
  }

  .geolocation-form-item {
    margin-bottom: 0 !important;

    .ant-form-item-control-input {
      display: none;
    }

    .ant-form-item-explain {
      margin-bottom: 10px !important;
    }

    .ant-form-item-required {
      font-weight: bold;
    }
  }
`;

export const MarkerStyles = styled.div`
  .pointer-marker {
    width: 27px;
    height: 27px;

    border: 4px solid #ffffff;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.25);
    background: ${({ theme }) => theme.palette.primary};
    border-radius: 50%;
  }
`;

export const ContentMarkerStyles = styled.div`
  width: 260px;

  .marker-name {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 5px;
  }
`;

export const GoogleAutocompleteInputStyles = styled.div`
  position: absolute;
  z-index: 10;
  top: 10px;
  left: 10px;
  width: 400px;
  max-width: calc(100% - 70px);

  .ant-select {
    width: 100%;
  }
`;

export const MarkerIconStyles = styled.div`
  .ant-image-img {
    width: 32px;
    height: 32px;
    border-radius: 50px;
    border: 1px solid ${({ borderColor }) => `${borderColor}`};
    object-fit: cover;
    position: relative;
    top: -5px;
    left: -5px;
  }

  .point-market {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    user-select: none;
    margin-top: 8px;
    cursor: pointer;
    border: 4px solid #ffffff;
    background-color: #db147f;
    box-shadow: 0px 1px 4px rgba(39, 40, 51, 0.2);
    display: inline-block;

    &:hover {
      z-index: 1;
    }
  }

  .pin-map {
    font-size: 48px;
    color: var(--primary);
  }

  .market {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .value {
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.02em;
    color: ${({ color }) => color};
    margin-top: 5px;
    text-align: center;
    margin-left: 10px;
    position: relative;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 122px;
    overflow: hidden;
    padding: ${({ brandLogoImage }) =>
      `${brandLogoImage ? '0 10px' : '0 20px'}`};
    top: ${({ brandLogoImage }) => `${brandLogoImage ? '-37px' : '-6px'}`};
    left: ${({ brandLogoImage }) => `${brandLogoImage ? '10px' : '-18px'}`};
    width: ${({ brandLogoImage }) => `${brandLogoImage ? '' : '122px'}`};
    transform: ${({ brandLogoImage }) =>
      `${brandLogoImage ? '' : 'translate(-11%, 0%)'}`};
    text-shadow: none;
  }

  .info-popup:hover .value,
  .market:hover .value {
    color: white;
  }

  .info-wrapper {
    display: flex;
    position: absolute;
    width: 24px;
    height: 24px;
  }

  .info-wrapper .info-popup:before {
    transition: all 0.2s ease-in-out;
    content: '';
    display: block;
    position: absolute;
    margin-left: -10px;
    left: 48.7%;
    top: 96%;
    width: 0;
    height: 0;
    border: 11px solid;
    border-color: ${({ backgroundColor }) => `${backgroundColor}`} transparent
      transparent;
  }

  .info-wrapper .info-popup:after {
    content: '';
    display: block;
    position: absolute;
    margin-left: -10px;
    left: 49%;
    top: 100%;
    width: 0;
    height: 0;
    border: 11px solid;
    border-color: ${({ color }) => `${color}`} transparent transparent;
    z-index: -1;
  }

  .info-wrapper .info-popup:hover:before,
  .market:hover .info-wrapper .info-popup:before {
    border-color: ${({ color }) => `${color}`} transparent transparent;
    color: white;
  }

  .info-wrapper .info-popup {
    transition: all 0.2s ease-in-out;
    width: ${({ brandLogoImage }) => `${brandLogoImage ? '122px' : '100px'}`};
    height: 43px;
    border-radius: 100px;
    display: block;
    position: relative;
    padding: 10px;
    border: 1px solid ${({ color }) => `${color}`};
    text-shadow: -1px -1px 1px rgba(0, 0, 0, 0.1);
    background-color: ${({ backgroundColor }) => `${backgroundColor}`};
    transform: translate(-40%, -110%);
    box-shadow: rgba(0, 0, 0, 0.25) 0 14px 28px, rgba(0, 0, 0, 0.22) 0 10px 10px;
    top: ${({ hasSelectedCentre, equalLocation }) =>
      `${hasSelectedCentre || equalLocation ? '-25%' : '-5%'}`};
    left: ${({ hasSelectedCentre, equalLocation }) =>
      `${hasSelectedCentre || equalLocation ? '60%' : '4%'}`};
  }

  .info-wrapper .info-popup:hover,
  .market:hover .info-wrapper .info-popup {
    background-color: ${({ color }) => `${color}`};
  }
  .selected .info-popup {
    background-color: ${({ color }) => `${color}`};
  }
  .selected .info-wrapper .info-popup:before,
  .selected .info-wrapper .value {
    border-color: ${({ color }) => `${color}`} transparent transparent;
    color: white !important;
  }
`;

import React, { useEffect, useContext } from 'react';
import Row from 'antd/lib/row';
import Col from 'antd/lib/col';
import { getFilterFromUrl } from 'utils/tools';
import { useLocation } from 'react-router';
import moment from 'moment';
import { ENQUIRY_TYPES_CONST } from 'configs/localData';
import i18next from 'i18next';
import { useSelector } from 'react-redux';

import RestSelect from 'components/RestInput/RestSelect';
import { RestInputContext } from 'components/RestInput/RestInputContext';
import RestRangePickerInput from 'components/RestInput/RestRangePickerInput';

const EnquiriesFilterForm = () => {
  const { search } = useLocation();
  const { form } = useContext(RestInputContext);
  const enquiryTypes = useSelector((state) => state.config.data?.EnquiryType);

  useEffect(() => {
    const filter = getFilterFromUrl(search) || {};

    const { updatedAt, ...restFilter } = filter?.filter || {};

    form.setFieldsValue({
      ...restFilter,
      updatedAt: [
        updatedAt?.$gt ? moment(updatedAt?.$gt) : null,
        updatedAt?.$lt ? moment(updatedAt?.$lt) : null,
      ],
    });
  }, [form, search]);

  return (
    <Row gutter={16} type="flex">
      <Col lg={12} md={12} xs={12}>
        <RestSelect
          source="enquiryTypeId"
          placeholder="enquiries.enquiryType"
          resourceData={enquiryTypes}
          valueProp="id"
          titleProp="name"
          isShowTooltip
          formatText={(value) =>
            ENQUIRY_TYPES_CONST[value]?.text
              ? i18next.t(ENQUIRY_TYPES_CONST[value]?.text)
              : value
          }
        />
      </Col>
      <Col lg={12} md={12} xs={12}>
        <RestRangePickerInput
          sourceGt={['updatedAt', '$gt']}
          sourceLt={['updatedAt', '$lt']}
          source="updatedAt"
          placeholder="enquiries.updatedAt"
          isShowTooltip
        />
      </Col>
    </Row>
  );
};

export default EnquiriesFilterForm;

import React from 'react';
import { Space } from 'antd';
import { SummaryMetricItem } from 'redux/@rtkQuery/marketingMetris/type';
import ComparePercentage from './ComparePercentage';

const MetricTooltipContent = ({
  tooltips,
  percentChange,
  name,
}: Partial<SummaryMetricItem>) => (
  <Space
    direction="vertical"
    size={8}
    className="text-black-base text-14 px-12 py-4"
  >
    <div>{tooltips?.comparison?.filtered}</div>
    {!!tooltips?.comparison?.compared && (
      <div>{tooltips.comparison.compared}</div>
    )}
    <div className="flex-center-between">
      <div>{name}</div>
      <div className="ml-40">
        <strong>{tooltips?.comparison?.value}</strong>
        {!!percentChange?.text && (
          <ComparePercentage
            {...percentChange}
            hasDashBorder={false}
            className="ml-8"
          />
        )}
      </div>
    </div>
    {!!tooltips?.comparison?.message && (
      <p className="text-gray-500 max-w-360">{tooltips.comparison.message}</p>
    )}
  </Space>
);

export default MetricTooltipContent;

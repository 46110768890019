import React, { useEffect, useCallback } from 'react';
import { useParams, useHistory } from 'react-router';
import { Result, Button, Space } from 'antd';
import i18next from 'i18next';
import { useDispatch, useSelector } from 'react-redux';
import { refreshToken, getCurrentUser } from 'redux/auth/actions';
import { callbackStripeCheckoutSessionApi } from 'api/subscriptions';
import { getCentreId } from 'redux/auth/selectors';
import { DEFAULT_INBOX_ID } from 'configs/localData';
import ResultSignUpStyles from './styles';

const ResultSignUp = () => {
  const { model, id } = useParams();
  const query = useSelector((state) => state.router?.location?.query);
  const centreId = useSelector(getCentreId);

  const { push } = useHistory();

  const dispatch = useDispatch();

  const refreshAction = useCallback(() => {
    dispatch(getCurrentUser());
    dispatch(refreshToken({ token: localStorage.getItem('refreshToken') }));
  }, [dispatch]);

  useEffect(() => {
    if (id && query['session-id']) {
      callbackStripeCheckoutSessionApi(id, {
        stripeCheckoutSessionId: query['session-id'],
      }).then(() => {
        refreshAction();
      });
    } else {
      refreshAction();
    }
  }, [id, query, refreshAction]);

  const handleClick = useCallback(() => {
    push('/subscriptions');
  }, [push]);

  const redirectCentreDetail = useCallback(() => {
    centreId && push(`/centres/${centreId}/show/summary`);
  }, [centreId, push]);

  const redirectEnquiries = useCallback(() => {
    push(`/enquiries/all/${DEFAULT_INBOX_ID}`);
  }, [push]);

  return (
    <ResultSignUpStyles>
      {model === 'success' ? (
        <Result
          status="success"
          title={i18next.t('subscriptions.signUpSuccess')}
          subTitle={i18next.t('subscriptions.signUpSuccessDescription')}
          extra={
            <Space direction="vertical" size={15}>
              <Button onClick={redirectCentreDetail} type="primary">
                {i18next.t('button.updateYourCentre')}
              </Button>
              <Button onClick={redirectEnquiries} type="primary">
                {i18next.t('button.viewYourEnquiries')}
              </Button>
              <Button onClick={handleClick} type="primary">
                {i18next.t('button.goToSubscriptions')}
              </Button>
            </Space>
          }
        />
      ) : (
        <Result
          status="warning"
          title={i18next.t('subscriptions.signUpCancel')}
          extra={
            <Button onClick={handleClick} type="primary">
              {i18next.t('button.goToSubscriptions')}
            </Button>
          }
        />
      )}
    </ResultSignUpStyles>
  );
};

export default ResultSignUp;

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Input } from 'antd';
import { CloseCircleFilled, SearchOutlined } from '@ant-design/icons';
import i18next from 'i18next';
import { FilterHeaderStyles } from './styles';

let timeoutId = null;

const SearchInput = ({ getListAndSummary }) => {
  const [searchValue, setSearchValue] = useState('');

  const onChange = (e) => {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    const { value } = e.target;
    setSearchValue(value);
    timeoutId = setTimeout(() => {
      getListAndSummary({ q: value });
    }, 500);
  };

  const handleClear = () => {
    setSearchValue('');
    getListAndSummary({ q: '' });
  };

  return (
    <FilterHeaderStyles className="pl-10 pr-10">
      <Input
        value={searchValue}
        onChange={onChange}
        suffix={<SearchOutlined />}
        placeholder={i18next.t('enquiries.searchEnquiries')}
        {...(searchValue?.trim() && {
          suffix: <CloseCircleFilled onClick={handleClear} />,
        })}
      />
    </FilterHeaderStyles>
  );
};

SearchInput.propTypes = {
  getListAndSummary: PropTypes.func,
};

SearchInput.defaultProps = {
  getListAndSummary: () => null,
};

export default SearchInput;

import { createSelector } from 'reselect';

const getRawEULAdata = (state) => state?.legalDocuments?.EULA?.data;

const getIsLoadingEULA = (state) => state?.legalDocuments?.EULA?.isLoading;

export const getEULADataSelector = createSelector(
  [getRawEULAdata, getIsLoadingEULA],
  (data, isLoading) => ({
    data: data?.value,
    isLoading,
  }),
);

import styled from 'styled-components';

export const TabContentContainerStyle = styled.div`
  .column-items {
    column-count: 1;
    column-gap: 24px;
  }
  @media only screen and (min-width: 1200px) {
    .column-items {
      column-count: 2;
      column-gap: 24px;
    }
  }
`;

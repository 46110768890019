import styled from 'styled-components';

const ShowStyles = styled.div`
  height: 100%;
  .box-detail-show {
    background: none;
    padding: 0;
  }
  .group-action {
    & > .ant-btn:not(:first-child) {
      margin-left: 15px;
    }

    .button-approve {
      background: ${({ theme }) => theme.color.green};
      border: 1px solid ${({ theme }) => theme.color.green};
      color: #fff;
    }

    .button-confirm {
      background: ${({ theme }) => theme.color.blue};
      border: 1px solid ${({ theme }) => theme.color.blue};
      color: #fff;
    }
  }
`;

export default ShowStyles;

import React from 'react';
import { Row, Col } from 'antd';
import CompanyGeneralInfo from '../CompanyGeneralInfo';
import CompanyRestInfo from '../CompanyRestInfo';

const CompanyDetails = () => (
  <div>
    <Row gutter={24}>
      <Col xxl={6} xl={6} lg={8} md={24} xs={24}>
        <CompanyGeneralInfo />
      </Col>

      <Col xxl={18} xl={18} lg={16} md={24} xs={24}>
        <CompanyRestInfo />
      </Col>
    </Row>
  </div>
);

export default CompanyDetails;

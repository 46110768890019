import React, { useCallback } from 'react';
import i18next from 'i18next';
import PropTypes from 'prop-types';
import { useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { getAllTasks } from 'redux/applications/actions';
import { ACTIVITY_TYPES } from 'configs/localData';
import TableCustom from 'components/common/TableCustom';
import {
  getResourceDataTasksSelector,
  getTasksSelector,
} from 'redux/applications/selectors';
import { formatDateTime } from 'utils/textUtils';

const TaskTable = ({ showTaskDetail }) => {
  const dispatch = useDispatch();
  const tasks = useSelector(getTasksSelector);
  const { id } = useParams();
  const [rowId, setRowId] = React.useState(null);
  const resourceFilter = useSelector(getResourceDataTasksSelector) || {};

  const handleRowClick = (id) => {
    showTaskDetail(id);
    setRowId(id);
  };

  const retrieveList = React.useCallback(
    (data, isRefresh) => {
      id &&
        dispatch(
          getAllTasks({
            id,
            data: { orderBy: '-createdAt', ...data },
            options: { isRefresh },
          }),
        );
    },
    [id], // eslint-disable-line
  );

  const onChange = useCallback(
    (e) => {
      retrieveList(
        {
          offset: (e.current - 1) * e.pageSize,
          limit: e.pageSize,
        },
        true,
      );
    },
    [retrieveList],
  );

  React.useEffect(() => {
    retrieveList({}, true);
  }, [id]); // eslint-disable-line

  const columns = [
    {
      title: i18next.t('task.taskSubject'),
      dataIndex: 'subject',
      ellipsis: true,
    },
    {
      title: i18next.t('task.type'),
      dataIndex: 'type',
      width: '25%',
      render: (text) => {
        const type = ACTIVITY_TYPES.find((element) => element.value === text);
        return (
          <span style={{ color: type.color }}>{i18next.t(type.text)}</span>
        );
      },
    },
    {
      title: i18next.t('task.taskDate'),
      dataIndex: 'date',
      width: '25%',
      render: (text) => formatDateTime(text),
    },
  ];

  return (
    <TableCustom
      xScroll={500}
      data={tasks}
      columns={columns}
      rowClassName={(record) => (record.id === rowId ? 'selected-row' : '')}
      onChange={onChange}
      onRow={(record) => ({
        onClick: () => handleRowClick(record.id),
      })}
      pagination={{
        pageSize: resourceFilter.limit,
        total: resourceFilter.total,
      }}
    />
  );
};

TaskTable.propTypes = {
  showTaskDetail: PropTypes.func,
};

export default TaskTable;

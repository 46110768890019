import React from 'react';
import { Col, Row, Space } from 'antd';
import { useTranslation } from 'react-i18next';
import { EMAIL_CONTACT } from 'configs/localData/constant';
import { FooterMenuStyles } from './styles';

const RW_URL = process.env.REACT_APP_RW_URL || 'https://kindicare.com';

const FOOTER_MENUS = [
  {
    title: 'menu.aboutKindiCare',
    subMenus: [
      {
        title: 'menu.home',
        href: `${RW_URL}`,
      },
      {
        title: 'menu.aboutUs',
        href: `${RW_URL}/about-us`,
      },
      {
        title: 'menu.ourPledge',
        href: `${RW_URL}/our-pledge`,
      },
      {
        title: 'menu.contactUs',
        href: `${RW_URL}/contact-us`,
      },
      {
        title: 'menu.media',
        href: `${RW_URL}/media`,
      },
    ],
  },
  {
    title: 'menu.helpfulLinks',
    subMenus: [
      {
        title: 'menu.support',
      },
      {
        title: 'menu.theKindiCareRating',
        href: `${RW_URL}/the-kindicare-rating`,
      },
      {
        title: 'menu.theKindiCareSubsidyCalculator',
        href: `${RW_URL}/child-care-subsidy-calculator`,
      },
      {
        title: 'menu.governmentChildcareSubsidy',
        href: `${RW_URL}/australian-government-childcare-subsidy`,
      },
      {
        title: 'menu.valueForMoneyRatingExplained',
        href: `${RW_URL}/value-for-money-rating-explained`,
      },
      {
        title: 'menu.FAQs',
        href: `${RW_URL}/faq`,
      },
      {
        title: 'menu.termsConditions',
        href: `${RW_URL}/terms-and-conditions`,
      },
      {
        title: 'menu.privacyPolicy',
        href: `${RW_URL}/privacy-policy`,
      },
      {
        title: 'menu.endUserLicenceAgreement',
        href: `/eula`,
      },
    ],
  },
  {
    mainMenu: [
      {
        title: 'menu.ourSolutions',
        subMenus: [
          {
            title: 'menu.forParentsFamilies',
            href: `${RW_URL}`,
          },
          {
            title: 'menu.forChildcareProviders',
            href: `${RW_URL}/for-childcare-providers`,
          },
          {
            title: 'menu.forJobSeekers',
            href: `${RW_URL}/early-childhood-and-childcare-jobs`,
          },
          {
            title: 'menu.forAdvertisers',
            href: `${RW_URL}/for-childcare-providers`,
          },
          {
            title: 'menu.forMedia',
            href: `${RW_URL}/for-childcare-providers`,
          },
        ],
      },
      // {
      //   title: 'menu.ourProducts',
      //   subMenus: [
      //     {
      //       title: 'menu.childcareMarketplaceListings',
      //       href: `${RW_URL}/for-childcare-providers`,
      //     },
      //     {
      //       title: 'menu.earlyLearningRecruitment',
      //       href: `${RW_URL}/for-childcare-providers`,
      //     },
      //     {
      //       title: 'menu.advertisingAndArticles',
      //       href: `${RW_URL}/for-childcare-providers`,
      //     },
      //     {
      //       title: 'menu.dataInsightsAndAnalytics',
      //       href: `${RW_URL}/for-childcare-providers`,
      //     },
      //     {
      //       title: 'menu.kindiCareIndices',
      //       href: `${RW_URL}/for-childcare-providers`,
      //     },
      //   ],
      // },
      {
        title: 'menu.furtherInformation',
        subMenus: [
          {
            title: 'menu.subscriptionsAndPricing',
            href: `${RW_URL}/subscription-pricing`,
          },
          {
            title: 'menu.claimYourCentre',
            href: `${RW_URL}/claim-centres`,
          },
          {
            title: 'menu.bookADemo',
            href: `${RW_URL}/contact-us`,
          },
          {
            title: 'menu.contactCustomerCare',
            href: `${RW_URL}/contact-us`,
          },
        ],
      },
    ],
  },
  {
    title: 'menu.awards',
    subMenus: [
      {
        title: 'menu.award2021',
        href: `${RW_URL}/australia-childcare-centre-awards`,
      },
      {
        title: 'menu.award2022',
        href: `${RW_URL}/australia-childcare-centre-awards-2022`,
      },
    ],
  },
];

const FooterDefault = () => {
  const { t } = useTranslation();

  const FooterItem = ({ footerItem }) => (
    <Space direction="vertical" size={10} className="mt-30">
      <span className="fw-bold mt-20">{t(footerItem?.title)}</span>
      {footerItem?.subMenus.map(({ title, href }) => (
        <a
          key={title}
          className="text-gray-700 fw-400"
          href={href ?? `mailto:${EMAIL_CONTACT}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          {t(title)}
        </a>
      ))}
    </Space>
  );

  return (
    <FooterMenuStyles>
      <div className="footer-desktop">
        <Row gutter={[15, 20]} className="footer-desktop__row">
          {FOOTER_MENUS.map((footerItem, index) => (
            <Col
              key={footerItem.title + index}
              className="footer-menu-col"
              md={6}
              lg={6}
              sm={12}
              xs={24}
            >
              {footerItem?.subMenus ? (
                <FooterItem footerItem={footerItem} />
              ) : (
                <div className="flex flex-direction-column">
                  {footerItem?.mainMenu?.map((item) => (
                    <FooterItem footerItem={item} key={item.title} />
                  ))}
                </div>
              )}
            </Col>
          ))}
        </Row>
      </div>
    </FooterMenuStyles>
  );
};

export default FooterDefault;

import React, { useEffect } from 'react';
import i18next from 'i18next';
import PageTitle from 'components/common/PageTitle';
import { useDispatch, useSelector } from 'react-redux';
import { getCompanyProfile } from 'redux/jobs/companyProfile/actions';
import { Spin } from 'antd';
import { getCompanyProfileSelector } from 'redux/jobs/companyProfile/selectors';
import { getActiveOnJobModulePermission } from 'redux/auth/selectors';
import JobModuleAccessWarning from 'components/common/JobModuleAccessWarning';
import CompanyDetails from '../components/CompanyDetails';

const CompanyProfileShow = () => {
  const dispatch = useDispatch();
  const { loading } = useSelector(getCompanyProfileSelector);
  const activeOnJobModulePermission = useSelector(
    getActiveOnJobModulePermission,
  );

  useEffect(() => {
    if (activeOnJobModulePermission) {
      dispatch(getCompanyProfile());
    }
  }, [dispatch, activeOnJobModulePermission]);

  return activeOnJobModulePermission ? (
    <div>
      {loading ? (
        <Spin className="flex-center" />
      ) : (
        <div>
          <PageTitle>
            {i18next.t('jobs.companyProfile.companyDetails')}
          </PageTitle>
          <CompanyDetails />
        </div>
      )}
    </div>
  ) : (
    <JobModuleAccessWarning title="jobs.sideBar.companyProfile" />
  );
};

export default CompanyProfileShow;

import styled from 'styled-components';
import { Layout, Button } from 'antd';

interface StepSiderProps {
  $backgroundSider?: string;
}

export const Wrapper = styled.div`
  --header-height: 88px;
  --sider-width: 450px;

  .ant-layout {
    min-height: 100vh;
  }

  @media only screen and (max-width: 1300px) {
    --sider-width: 300px;
  }

  @media only screen and (max-width: 992px) {
    --sider-width: 80px;
  }
`;

export const StepContent = styled(Layout.Content)`
  position: absolute;
  top: var(--header-height);
  left: var(--sider-width);
  right: 0;
  bottom: 0;
  padding: 64px;
  min-height: 80%;
  background-color: white;

  > div {
    padding-bottom: calc(var(--header-height) + 64px);
  }

  @media only screen and (max-width: 992px) {
    padding: 32px;
  }
`;

export const StepSider = styled(Layout.Sider)<StepSiderProps>`
  position: fixed;
  background: ${({ $backgroundSider }) => $backgroundSider || '#fff'};
  height: 100vh;
  z-index: 0;
  overflow: hidden;
  padding: var(--header-height) 48px;
  min-width: 450px !important;

  .ant-layout-sider-children {
    &::-webkit-scrollbar {
      border-radius: 10px;
      width: 4px;
    }
    ::-webkit-scrollbar-track {
      background: #fff !important;
    }
    ::-webkit-scrollbar-thumb {
      background: #fff !important;
    }

    &:hover {
      ::-webkit-scrollbar-thumb {
        background: ${({ theme }) => theme.scrollbar.thumb} !important;
      }
    }
  }

  @media only screen and (max-width: 1300px) {
    min-width: 300px !important;
  }
  @media only screen and (max-width: 992px) {
    padding: var(--header-height) 22px;
    min-width: 80px !important;

    .ant-steps-item-title {
      display: none;
    }
  }
`;

export const StepHeader = styled(Layout.Header)`
  position: fixed;
  top: 0;
  left: var(--sider-width);
  right: 0;
  z-index: 10;
  border-bottom: 1px solid ${({ theme }) => theme.border.lightGray};
  height: var(--header-height);
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
`;

export const StepFooter = styled(Layout.Footer)`
  position: fixed;
  left: var(--sider-width);
  right: 0;
  bottom: 0;
  padding: 24px 40px;
  z-index: 10;
  border-top: 1px solid ${({ theme }) => theme.border.lightGray};
  background-color: white;
`;

export const ExitButton = styled(Button)`
  position: absolute;
  right: 64px;
  top: 50%;
  transform: translateY(-50%);
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  span {
    text-decoration: underline !important;
  }

  &:hover,
  &:focus,
  &:active {
    color: ${({ theme }) => theme.palette.primary};
    background-color: ${({ theme }) => theme.background.content};
  }

  @media only screen and (max-width: 992px) {
    right: 10px;
  }
`;

export const OptionButton = styled(Button)`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  min-height: 72px;
  border: 1px solid #ebecf0;
  border-radius: 12px;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: ${({ theme }) => theme.text.blackNeutral800};
  transition: all 0.3s;

  .anticon {
    color: #38465c;
  }

  .arrow-icon {
    margin-left: auto !important;
  }

  &:hover .anticon {
    color: ${({ theme }) => theme.palette.primary};
  }
`;

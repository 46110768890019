import styled from 'styled-components';

export const WorkplaceFilterWrapperStyles = styled.div`
  .row-filter .ant-form-item-control-input-content > input,
  .row-filter .ant-select-selector,
  .row-filter .ant-picker,
  .row-filter .ant-input-affix-wrapper {
    border: 1px solid #d0d5dd;
  }
`;

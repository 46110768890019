import React, { useState } from 'react';
import TagCustom from 'components/common/TagCustom';
import EditCustomBtn from 'components/RestActions/EditCustomBtn';
import { Row } from 'antd';
import useModifyAdsPermission from 'containers/Advertisement/hooks/useModifyAdsPermission';
import AdCreativeModal from 'containers/Advertisement/components/Modals/AdCreativeModal';
import AdsThumbnailCard from '../AdsThumbnailCard';

const AdDetailInfo = ({ currentData }) => {
  const { canModifyAds, canModifyAdStatus } = useModifyAdsPermission();
  const [isEditSnapshot, setIsEditSnapshot] = useState<boolean>(false);

  return (
    <div className="bg-white p-24 rounded-8">
      <Row justify="end" className="mb-12">
        <EditCustomBtn
          disabled={!canModifyAds || !canModifyAdStatus}
          onClickButton={() => setIsEditSnapshot(true)}
        />
      </Row>
      <AdsThumbnailCard
        mediaItems={currentData?.mediaList}
        callingButton={currentData?.callingButton?.value}
        isShowThumbnail
        title={currentData?.headline}
        description={currentData?.description}
        isShowTopAction={false}
        isStyleCard={false}
      />
      <TagCustom
        className="fw-bold mr-10 mt-12"
        color={currentData?.deliveryStatus?.color}
        backgroundColor={currentData?.deliveryStatus?.backgroundColor}
        text={currentData?.deliveryStatus?.value}
        isBorder={false}
      />
      <AdCreativeModal
        visible={isEditSnapshot}
        onClose={() => setIsEditSnapshot(false)}
        adDetailValues={currentData}
      />
    </div>
  );
};

export default AdDetailInfo;

import { TickedIcon } from 'components/common/SVGIcon';

export const PRICE_DETAIL_BLOCK = [
  {
    title: 'KindiCare Basic',
    price: 'Free',
    unit: '',
    accessibleTitle: 'KindiCare Basic includes:',
    accessibleList: [
      {
        icon: TickedIcon,
        content: 'Free basic listing on the KindiCare marketplace.',
      },
      {
        icon: TickedIcon,
        content: 'Up to 3 Enquiries.',
      },
      {
        icon: TickedIcon,
        content: 'KindiCare Realtime Mailbox Chat.',
      },
    ],
    mainColor: '#3EBD5C',
    isRecommend: false,
    buttonText: 'Signed Up',
  },
  {
    title: 'KindiCare Essentials',
    price: '75',
    unit: 'per month',
    accessibleTitle: 'Everything in Basic, plus:',
    accessibleList: [
      {
        icon: TickedIcon,
        content:
          'Fully customisable childcare marketplace listing on KindiCare for your service.',
      },
      {
        icon: TickedIcon,
        content: 'Unlimited Parent Enquiries.',
      },
      {
        icon: TickedIcon,
        content: {
          subTitle: 'KindiCare Analytics - Centre Level including:',
          subItem: ['Marketing Metrics.', 'Lead Activities.'],
        },
      },
      {
        icon: TickedIcon,
        content: 'Unlimited Photos and Videos.',
      },
      {
        icon: TickedIcon,
        content: 'Virtual Tours.',
      },
      {
        icon: TickedIcon,
        content: 'Social Media and Website Links.',
      },
      {
        icon: TickedIcon,
        content: 'Respond to and Dispute Parent Reviews.',
      },
    ],
    mainColor: '#32A4FC',
    secondColor: '#87C9FB',
    isRecommend: false,
    buttonText: 'Sign up',
  },
  {
    title: 'KindiCare Premium',
    price: '88',
    unit: 'per month',
    accessibleTitle: 'Everything in Essentials, plus:',
    accessibleList: [
      {
        icon: TickedIcon,
        content: 'Enhanced Parent Enquiries.',
      },
      {
        icon: TickedIcon,
        content: 'Unlimited Enrolment Applications.',
      },
      {
        icon: TickedIcon,
        content: 'Enrolment Pipeline Management.',
      },
      {
        icon: TickedIcon,
        content: 'KindiCare Provider Mobile App.',
      },
      {
        icon: TickedIcon,
        content: 'Access to KindiCare Jobs.',
      },
      {
        icon: TickedIcon,
        content: 'Access to KindiCare Ads.',
      },
      {
        icon: TickedIcon,
        content: 'Customisable Automated Parent Enquiry Responses.',
      },
      {
        icon: TickedIcon,
        content: 'Parent Contact Management.',
      },
      {
        icon: TickedIcon,
        content: 'Job Seeker Contact Management.',
      },
      {
        icon: TickedIcon,
        content: 'Access to Featured Listings & Special Offers.',
      },
    ],
    mainColor: '#DB147F',
    isRecommend: true,
    buttonText: '30-Day Free Trial',
  },
  {
    title: 'KindiCare Enterprise',
    price: '120',
    unit: 'per month',
    accessibleTitle: 'Everything in Premium, plus:',
    accessibleList: [
      {
        icon: TickedIcon,
        content: 'Childcare Centre Portfolio Management.',
      },
      {
        icon: TickedIcon,
        content: {
          subTitle: 'KindiCare Analytics - Portfolio Level including:',
          subItem: ['Marketing Metrics.', 'Lead Activities.'],
        },
      },
      {
        icon: TickedIcon,
        content: {
          subTitle: 'KindiCare Insights including:',
          subItem: [
            'Price Benchmarking.',
            'Company Benchmarking.',
            'Brand Benchmarking.',
          ],
        },
      },
      {
        icon: TickedIcon,
        content: 'Ability to List Coming Soon Centres.',
      },
      {
        icon: TickedIcon,
        content: 'Your brand featured on Well Known Brands Carousel.',
      },
      {
        icon: TickedIcon,
        content: 'Your brand featured on Search by Brand Carousel.',
      },
    ],
    mainColor: '#F98437',
    isRecommend: false,
    buttonText: 'Sign up',
  },
];

import { PREFIX_API_VER_2 } from 'configs/localData/constant';
import {
  LeadActivitiesPayload,
  LeadActivitiesResponse,
} from 'types/leadActivities';
import { baseApi } from 'redux/@rtkQuery/baseApi';
import {
  ContactsPayload,
  ApplicationsResponse,
  EnquiriesResponse,
} from './types';

export const contactApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getApplications: build.query<ApplicationsResponse, ContactsPayload>({
      query: (params) => ({
        url: `${PREFIX_API_VER_2}/crm/contacts/applications`,
        params,
      }),
    }),
    getEnquiries: build.query<EnquiriesResponse, ContactsPayload>({
      query: (params) => ({
        url: `${PREFIX_API_VER_2}/crm/contacts/enquiries`,
        params,
      }),
    }),
    getLeadActivitiesContact: build.query<
      LeadActivitiesResponse,
      LeadActivitiesPayload
    >({
      query: (params) => ({
        url: `/${PREFIX_API_VER_2}/crm/contacts/lead-activities`,
        params,
      }),
    }),
  }),
});

export const {
  useGetApplicationsQuery,
  useGetEnquiriesQuery,
  useGetLeadActivitiesContactQuery,
} = contactApi;

import { stringifyObjectWithBrackets } from 'api/utils';
import {
  DEFAULT_CURRENT_PAGE,
  PREFIX_API_VER_2,
} from 'configs/localData/constant';
import { baseApi } from 'redux/@rtkQuery/baseApi';
import {
  TitlePayload,
  TitleResponse,
} from 'redux/@rtkQuery/advertisement/type';
import { ADVERTISEMENT_TAG } from '../tagTypes';

const LIMIT_ITEM = 8;

export const advertisementApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getTitleAdvertisement: builder.query<TitleResponse, TitlePayload>({
      query: (params) =>
        `/${PREFIX_API_VER_2}/crm/ads/posts/title?${stringifyObjectWithBrackets(
          {
            page: params.page || DEFAULT_CURRENT_PAGE,
            size: params.size || LIMIT_ITEM,
            filter: params.filter,
          },
        )}`,
      providesTags: [ADVERTISEMENT_TAG],
    }),
  }),
});

export const { useGetTitleAdvertisementQuery } = advertisementApi;

import styled from 'styled-components';

export const CentreAddressDistanceStyles = styled.div`
  max-width: 100%;
  &.centre-address-distance__ellipsis {
    overflow: hidden;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    .centre-address-distance__value {
      white-space: nowrap;
    }
    .centre-address-distance__address {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  .environment-icon {
    margin-right: 5px;
    color: ${({ theme }) => theme.palette.primary};
  }

  .center-address {
    margin-right: 5px;
    color: ${({ theme }) => theme.palette.primary};
  }

  @media only screen and (max-width: 440px) {
    .centre-address-distance__value {
      font-size: 12px !important;
    }
    .centre-address-distance__address {
      font-size: 12px !important;
    }
  }
`;

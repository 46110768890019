import React, { useEffect } from 'react';
import { Col, Row } from 'antd';
import PropTypes from 'prop-types';
import { clearCurrentApplications } from 'redux/applications/actions';
import { useDispatch } from 'react-redux';

import ApplicationInfo from './ApplicationInfo';
import ApplicationTabs from '../ApplicationTabs';
import DetailApplicationStyles from './styles';

const DetailApplication = ({ record, handleUpdateStatus }) => {
  const dispatch = useDispatch();

  useEffect(
    () => () => {
      dispatch(clearCurrentApplications());
    },
    [dispatch],
  );

  return (
    <DetailApplicationStyles>
      <Row gutter={20} className="row-detail-application">
        <Col xxl={6} xl={7} lg={8} md={24} xs={24} className="col-left">
          <ApplicationInfo
            record={record}
            handleUpdateStatus={handleUpdateStatus}
          />
        </Col>
        <Col xxl={18} xl={17} lg={16} md={24} xs={24} className="col-right">
          <ApplicationTabs inboxId={record.inboxId} />
        </Col>
      </Row>
    </DetailApplicationStyles>
  );
};

DetailApplication.propTypes = {
  record: PropTypes.object,
  handleUpdateStatus: PropTypes.func,
};

export default DetailApplication;

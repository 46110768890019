import React, { useState } from 'react';
import { Form } from 'antd';
import i18next from 'i18next';
import { TargetedAudience } from 'api/advertisement/type';
import AudienceTabsForm from 'containers/Advertisement/Create/Steps/TargetedAudience/AudienceTabsForm';
import {
  NEW_AUDIENCE_TAB_KEY,
  EXISTING_AUDIENCE_TAB_KEY,
} from 'containers/Advertisement/constants';
import { getAudienceValidateFields } from 'utils/dataUtils';
import { useDispatch } from 'react-redux';
import { editAd, getAdDetail } from 'redux/advertisement/actions';
import ModalCustom from 'components/common/ModalCustom';
import { useParams } from 'react-router';

interface Props {
  visible?: boolean;
  onClose?: () => void;
  initialValues: TargetedAudience & {
    userLocationValue: string[];
    isShared: boolean;
  };
}

const TargetedAudienceModal: React.FC<Props> = ({
  visible,
  onClose,
  initialValues,
}) => {
  const dispatch = useDispatch();
  const defaultActiveTab = initialValues?.isShared
    ? EXISTING_AUDIENCE_TAB_KEY
    : NEW_AUDIENCE_TAB_KEY;

  const { id: adsPostId } = useParams();
  const [form] = Form.useForm();
  const [activeTab, setActiveTab] = useState(defaultActiveTab);
  const [loading, setLoading] = useState(false);

  const onCancel = () => {
    onClose();
    form.resetFields();
  };

  const onSave = () => {
    const validateFields = getAudienceValidateFields(activeTab);

    form.validateFields(validateFields).then(async ({ id, ...values }) => {
      const payload = id ? { id } : { ...values };
      setLoading(true);
      await dispatch(
        editAd({
          adsPostId,
          targetedAudience: payload,
        }),
      );

      await dispatch(getAdDetail(adsPostId));
      setLoading(false);
      onCancel();
    });
  };

  const onActiveTabChange = (activeKey: string) => {
    setActiveTab(activeKey);
  };

  return (
    <ModalCustom
      visible={visible}
      title={i18next.t('advertisement.targetedAudience')}
      onCancel={onCancel}
      width={710}
      isScrollY
      onOk={onSave}
      loading={loading}
    >
      <AudienceTabsForm
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        form={form}
        initialValues={{
          ...initialValues,
          userLocationValues: initialValues?.userLocationValue,
        }}
        onActiveTabChange={onActiveTabChange}
      />
    </ModalCustom>
  );
};

export default TargetedAudienceModal;

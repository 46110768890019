import React from 'react';
import RestInputItem from 'components/RestInput/RestInputItem';

const TransactionsForm = (props) => (
  <div {...props}>
    <RestInputItem source="applicationId" header="transactions.applicationId" />
  </div>
);

export default TransactionsForm;

import { EyeOutlined } from '@ant-design/icons';
import { Button, Tooltip } from 'antd';
import { EditIcon } from 'components/common/SVGIcon';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  isView?: boolean;
  handleOnClick?: () => void;
  disabled?: boolean;
  isShowText?: boolean;
  title?: string;
  iconCustom?: JSX.Element;
}

const EditButtonWrapper = ({
  isView,
  handleOnClick,
  disabled,
  isShowText,
  title,
  iconCustom = <EditIcon />,
}: Props) => {
  const { t } = useTranslation();
  const textBtn = t(`button.${isView ? 'view' : 'edit'}`);

  return (
    <Tooltip title={t(title) || (disabled ? '' : textBtn)}>
      <div className="edit-action-wrapper">
        <Button
          icon={isView ? <EyeOutlined /> : iconCustom}
          onClick={handleOnClick}
          disabled={disabled}
        >
          {isShowText ? textBtn : null}
        </Button>
      </div>
    </Tooltip>
  );
};

export default EditButtonWrapper;

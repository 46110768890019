import React, { useRef } from 'react';
import CardSection from 'components/common/CardSection';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash-es';
import { Button, Empty } from 'antd';
import i18next from 'i18next';
import iconEmpty from 'assets/images/shape-icon-empty.png';
import ReadMore from 'components/common/ReadMore';
import AboutYouModal from '../../Modals/AboutYouModal';

const AboutYou = ({ aboutCandidate, disabledAction }) => {
  const aboutCandidateModalRef = useRef();

  const aboutCandidateModal = () => {
    aboutCandidateModalRef?.current &&
      aboutCandidateModalRef.current.toggleModal();
  };

  return (
    <>
      <CardSection
        isEdit
        onClickButton={aboutCandidateModal}
        title="jobs.moreAboutTheJob.aboutYou"
        action="isEdit"
        disabled={disabledAction}
      >
        {!isEmpty(aboutCandidate) ? (
          <ReadMore
            text={aboutCandidate}
            readMoreCharacterLimit={500}
            showLessButton
            isCheckInsertLink
          />
        ) : (
          <Empty image={iconEmpty}>
            <Button
              type="primary"
              onClick={aboutCandidateModal}
              disabled={disabledAction}
            >
              {i18next.t('jobs.moreAboutTheJob.addAboutYou')}
            </Button>
          </Empty>
        )}
      </CardSection>
      <AboutYouModal
        ref={aboutCandidateModalRef}
        aboutCandidate={aboutCandidate}
      />
    </>
  );
};

AboutYou.propTypes = {
  aboutCandidate: PropTypes.string,
  disabledAction: PropTypes.bool,
};

export default AboutYou;

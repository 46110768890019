import React from 'react';
import PropTypes from 'prop-types';
import { Empty } from 'antd';

const EmptyData = ({ description, className }) => (
  <Empty className={`w-full ${className}`} description={description} />
);

EmptyData.propTypes = {
  className: PropTypes.string,
  description: PropTypes.string,
};
export default EmptyData;

import styled from 'styled-components';

const ListStyles = styled.div`
  .hidden-tab {
    display: none;
  }
  .ant-progress-status-success .ant-progress-bg {
    background-color: #1890ff;
  }
  .ant-progress-status-success .ant-progress-text {
    color: black;
  }
`;

export default ListStyles;

export const HeaderFilterStyles = styled.div`
  .title-tab {
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    font-size: 16px;
  }
`;

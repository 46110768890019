import React from 'react';
import { Col, Row } from 'antd';
import OverdueTasks from './OverdueTasks';
import UpcomingTasks from './UpcomingTasks';

const TasksSection = () => (
  <Row gutter={[0, 24]}>
    <Col span={24}>
      <OverdueTasks />
    </Col>
    <Col span={24}>
      <UpcomingTasks />
    </Col>
  </Row>
);

export default TasksSection;

import React from 'react';
import PropTypes from 'prop-types';
import { formatMoney } from 'utils/tools';
import { Col, Row } from 'antd';
import i18next from 'i18next';
import TooltipStyles from './styles';

const TooltipBarChart = ({ active, payload }) =>
  active && (
    <TooltipStyles>
      <div className="title-tooltip mb-8 text-left">
        {payload?.[0]?.payload?.year}
      </div>
      <div className="leading-loose">
        <Row justify="space-between">
          <Col>
            <strong>{i18next.t('insight.summaries.totalCentres')}</strong>
          </Col>
          <Col>
            <strong>{formatMoney(payload?.[0]?.payload?.totalCentres)}</strong>
          </Col>
        </Row>
        {payload?.map((item, i) => (
          <div key={Number(i)}>
            <Row justify="space-between">
              <Col>
                <div className="flex items-center">
                  <div
                    className="w-8 h-8 r-2 mr-10"
                    style={{
                      backgroundColor: item?.payload?.fill || item?.color,
                    }}
                  />
                  <div>{item?.name}</div>
                </div>
              </Col>
              <Col>
                <strong>{formatMoney(item?.value)}</strong>
              </Col>
            </Row>
          </div>
        ))}
      </div>
    </TooltipStyles>
  );

TooltipBarChart.propTypes = {
  active: PropTypes.bool,
  payload: PropTypes.array,
};

export default TooltipBarChart;

import React, { useMemo } from 'react';
import i18next from 'i18next';
import { Divider } from 'antd';
import PropTypes from 'prop-types';

import EditCustomBtn from 'components/RestActions/EditCustomBtn';
import { useSelector } from 'react-redux';
import CardSectionStyles from './styles';

const CardSection = ({
  title,
  children,
  isEdit,
  onClickButton,
  permissionKey,
  action,
  disabled,
}) => {
  const currentCentre = useSelector((state) => state.centres.currentData);

  const editBtn = useMemo(
    () =>
      isEdit && (
        <EditCustomBtn
          packageName={currentCentre?.CRMPackage?.name}
          permissionKey={permissionKey}
          action={action}
          onClickButton={onClickButton}
          disabled={disabled}
        />
      ),
    [isEdit, permissionKey, onClickButton, action, currentCentre, disabled],
  );

  return (
    <CardSectionStyles className="wrapper-info">
      <div className="title-card">
        <span className="name-title">{i18next.t(title)}</span>
        {editBtn}
      </div>
      <Divider />
      <div className="content-card-info">{children}</div>
    </CardSectionStyles>
  );
};

CardSection.propTypes = {
  title: PropTypes.string,
  children: PropTypes.any,
  isEdit: PropTypes.bool,
  onClickButton: PropTypes.func,
  permissionKey: PropTypes.string,
  action: PropTypes.string,
  disabled: PropTypes.bool,
};

export default CardSection;

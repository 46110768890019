import React from 'react';
import PropTypes from 'prop-types';
import RestShow from 'containers/rest/Show';
import MessageDetail from '../components/MessageDetail';

const EnquiriesShow = ({
  inboxId,
  canGetListMessages,
  canSendMessage,
  canReadMessage,
  ...props
}) => (
  <RestShow
    {...props}
    resource="enquiries"
    defaultOptions={{ customApiResource: 'inboxes' }}
  >
    <MessageDetail
      inboxId={inboxId}
      canGetListMessages={canGetListMessages}
      canReadMessage={canReadMessage}
      canSendMessage={canSendMessage}
    />
  </RestShow>
);

EnquiriesShow.propTypes = {
  inboxId: PropTypes.string,
  canGetListMessages: PropTypes.bool,
  canReadMessage: PropTypes.bool,
  canSendMessage: PropTypes.bool,
};

export default EnquiriesShow;

import React, {
  useState,
  useCallback,
  useMemo,
  forwardRef,
  useImperativeHandle,
} from 'react';
import ModalCustom from 'components/common/ModalCustom';
import i18next from 'i18next';
import { useSelector } from 'react-redux';
import { loadStripe } from '@stripe/stripe-js';
import { useHistory } from 'react-router';
import {
  stripeCheckoutSessionApi,
  updateCentreSubscriptionPackageApi,
} from 'api/subscriptions';
import { getCentreLevelCRMPackageSelected } from 'redux/auth/selectors';
import { notification } from 'antd';

import EULAContent from 'pages/EULA/EULAContent';
import { EULAModalStyles } from './styles';

const EULAModal = forwardRef((_props, ref) => {
  const packageSelected = useSelector(getCentreLevelCRMPackageSelected) || {};

  const [visible, setVisible] = useState(false);

  const [record, setRecord] = useState({});

  const [loading, setLoading] = useState(false);

  useImperativeHandle(
    ref,
    () => ({
      openModal: (data) => {
        setVisible(true);
        setRecord(data);
      },
    }),
    [],
  );

  const onCancel = () => setVisible(false);

  const { push } = useHistory();

  const showError = useCallback((message) => {
    setLoading(false);
    notification.error({
      message: i18next.t('error.title'),
      description: message || i18next.t('error.description'),
    });
  }, []);

  const handleRedirectSuccess = useCallback(() => {
    setLoading(false);
    push(`/subscriptions/${record.id}/sign-up-results/success`);
  }, [record, push]);

  const stripeAction = useMemo(
    () =>
      packageSelected.priceId
        ? updateCentreSubscriptionPackageApi
        : stripeCheckoutSessionApi,
    [packageSelected.priceId],
  );

  const handleSignUp = () => {
    if (record?.id) {
      setLoading(true);
      stripeAction(record.id, {
        successUrl: `${window.location.origin}/subscriptions/${record.id}/sign-up-results/success`,
        cancelUrl: `${window.location.origin}/subscriptions`,
        coupon: record?.promotion?.couponId,
      })
        .then(async (response) => {
          if (response?.id) {
            const stripe = await loadStripe(
              process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY,
            );

            stripe
              .redirectToCheckout({
                sessionId: response.id,
              })
              .then((result) => {
                setLoading(false);
                if (result.error) {
                  notification.error({
                    message: i18next('error.title'),
                    description: i18next('error.description'),
                  });
                }
              });
          } else if (response?.success) {
            handleRedirectSuccess();
          } else {
            showError(response?.message);
          }
        })
        .catch((response) => {
          showError(response?.message);
        });
    }
  };

  return (
    <ModalCustom
      visible={visible}
      title={i18next.t('subscriptions.EULAModalTitle')}
      onCancel={onCancel}
      loading={loading}
      onOk={handleSignUp}
      okText="button.accept"
      cancelText="button.decline"
      width={800}
      isScrollY
    >
      <EULAModalStyles>
        <EULAContent />
      </EULAModalStyles>
    </ModalCustom>
  );
});

export default EULAModal;

import React, {
  forwardRef,
  useImperativeHandle,
  useState,
  useEffect,
} from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import { editJobTemplate } from 'redux/jobs/jobTemplates/actions';
import { Form } from 'antd';
import { NO_EXPERIENCE_REQUIRED_NAME } from 'containers/JobTemplates/constants';
import JobTemplateModal from '.';
import BasicInformationGeneralForm from '../BasicInformationGeneralForm';

const BasicInformationGeneralModal = forwardRef(({ record }, ref) => {
  const [form] = Form.useForm();
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const { id } = useParams();
  const onCancel = () => setVisible(false);

  useImperativeHandle(
    ref,
    () => ({
      toggleModal: (initialValue) => {
        setVisible(true);
        form.setFieldsValue({
          ...initialValue,
          languages: initialValue?.languages?.map((item) => item?.id),
          eligibility: initialValue?.eligibility?.ids,
          jobType: initialValue?.jobType?.id,
          jobRole: initialValue?.jobRole?.id,
          experience: initialValue?.experience?.id,
          qualification: initialValue?.qualification?.id,
          discount: {
            typeId: initialValue?.discount?.type?.id,
            value: initialValue?.discount?.value,
          },
          salary: {
            payTypeId: initialValue?.salary?.payType?.id,
            payRateId: initialValue?.salary?.payRate?.id,
            ...initialValue?.salary,
          },
        });
      },
    }),
    [form],
  );

  const onOk = () => {
    form.validateFields().then((values) => {
      setLoading(true);
      dispatch(
        editJobTemplate({
          id,
          data: {
            ...values,
            discount: values?.discount
              ? {
                  ...values?.discount,
                  typeId: values?.discount?.typeId,
                  value: values?.discount?.value ?? 0,
                }
              : undefined,
            experience:
              values?.experience &&
              values?.experience !== NO_EXPERIENCE_REQUIRED_NAME
                ? {
                    id: values?.experience,
                    isPreferred: record?.experience?.isPreferred ?? true,
                    isRequired: record?.experience?.isRequired ?? false,
                  }
                : null,

            languages: values?.languages?.map((id) => ({
              id,
              isPreferred:
                record?.languages?.find((item) => item.id === id)
                  ?.isPreferred ?? true,
              isRequired:
                record?.languages?.find((item) => item.id === id)?.isRequired ??
                false,
            })),

            qualification: values?.qualification
              ? {
                  id: values?.qualification,
                  isPreferred: record?.qualification?.isPreferred ?? true,
                  isRequired: record?.qualification?.isRequired ?? false,
                }
              : null,

            eligibility: values?.eligibility
              ? {
                  ids: values?.eligibility,
                  isPreferred: record?.eligibility?.isPreferred ?? true,
                  isRequired: record?.eligibility?.isRequired ?? false,
                }
              : undefined,
          },
        }),
      )
        .then(({ payload }) => {
          if (payload?.data?.result?.id) {
            onCancel();
          }
        })
        .finally(() => {
          setLoading(false);
        });
    });
  };

  useEffect(() => {
    form.setFieldsValue({
      ...record,
      languages: record?.languages?.map((item) => item?.id),
      eligibility: record?.eligibility?.ids,
      jobType: record?.jobType?.id,
      jobRole: record?.jobRole?.id,
      experience: record?.experience?.id,
      qualification: record?.qualification?.id,
      discount: {
        typeId: record?.discount?.type?.id,
        value: record?.discount?.value,
      },
      salary: {
        payTypeId: record?.salary?.payType?.id,
        payRateId: record?.salary?.payRate?.id,
        ...record?.salary,
      },
    });
  }, [record, form]);

  return (
    <JobTemplateModal
      title="jobs.basicInformation.editBasicInformation"
      visible={visible}
      onCancel={onCancel}
      record={record}
      loading={loading}
      onOk={onOk}
      width={1100}
      initialValues={{
        ...record,
        languages: record?.languages?.map((item) => item?.id),
        eligibility: record?.eligibility?.ids,
        experience: record?.experience?.id,
        qualification: record?.qualification?.id,
        jobType: record?.jobType?.id,
        jobRole: record?.jobRole?.id,
        discount: {
          typeId: record?.discount?.type?.id,
          value: record?.discount?.value,
        },
        salary: {
          payTypeId: record?.salary?.payType?.id,
          payRateId: record?.salary?.payRate?.id,
          ...record?.salary,
        },
      }}
    >
      <Form form={form}>
        <BasicInformationGeneralForm form={form} record={record} />
      </Form>
    </JobTemplateModal>
  );
});

BasicInformationGeneralModal.propTypes = {
  record: PropTypes.object,
};

export default BasicInformationGeneralModal;

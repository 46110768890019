import React, { useMemo, useEffect, useCallback } from 'react';
import i18next from 'i18next';

import { useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { getAllDocuments } from 'redux/applications/actions';
import {
  getDocumentsSelector,
  getDocumentsLoadingSelector,
  getResourceDataDocumentsSelector,
} from 'redux/applications/selectors';

import TableCustom from 'components/common/TableCustom';
import ViewDocumentModal from './ViewFileModal';
import useFormatFnc from './useFormat';
import { ViewFileModalProvider } from './ViewFileModal/context';

const DocumentsTable = () => {
  const dispatch = useDispatch();

  const useFormat = useFormatFnc();

  const data = useSelector(getDocumentsSelector);

  const { id } = useParams();

  const resourceFilter = useSelector(getResourceDataDocumentsSelector) || {};

  const loading = useSelector(getDocumentsLoadingSelector);

  const retrieveList = useCallback(
    (data, isRefresh) => {
      id &&
        dispatch(
          getAllDocuments({
            data: { id, orderBy: '-createdAt', ...data },
            options: { isRefresh },
          }),
        );
    },
    [id], // eslint-disable-line
  );

  const onChange = useCallback(
    (e) => {
      retrieveList(
        {
          offset: (e.current - 1) * e.pageSize,
          limit: e.pageSize,
        },
        true,
      );
    },
    [retrieveList],
  );

  useEffect(() => {
    retrieveList({}, true);
  }, [id]); // eslint-disable-line

  const columns = useMemo(
    () => [
      {
        title: i18next.t('documents.documentName'),
        dataIndex: 'name',
        render: useFormat.formatDocumentName,
      },
      {
        title: i18next.t('documents.dateReceived'),
        dataIndex: 'createdAt',
        render: useFormat.formatTime,
        width: '25%',
      },
      {
        title: i18next.t('documents.file'),
        dataIndex: ['file', 'name'],
        render: useFormat.formatFileType,
        width: '15%',
      },
      {
        title: i18next.t('documents.actions'),
        dataIndex: 'id',
        render: useFormat.formatAction,
        width: 90,
        fixed: 'right',
      },
    ],
    [useFormat],
  );

  return (
    <ViewFileModalProvider>
      <TableCustom
        xScroll={600}
        data={data}
        columns={columns}
        loading={loading}
        onChange={onChange}
        pagination={{
          pageSize: resourceFilter.limit,
          total: resourceFilter.total,
        }}
      />
      <ViewDocumentModal />
    </ViewFileModalProvider>
  );
};

export default DocumentsTable;

import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import i18next from 'i18next';
import { CloseCircleOutlined } from '@ant-design/icons';
import { formatMoney } from 'utils/tools';

import { Tooltip } from 'antd';
import { SummaryCardStyles } from './styles';

const SummaryCard = ({
  IconComponent,
  title,
  count,
  isMoney,
  isPerAnnum,
  isTitleTooltip,
}) => {
  const countValue = useMemo(() => {
    if (isPerAnnum)
      return `${i18next.t('currencyUnit.sign')} ${formatMoney(
        count,
      )} ${i18next.t('text.perAnnum')}`;

    if (isMoney)
      return `${i18next.t('currencyUnit.sign')} ${formatMoney(count)}`;

    return formatMoney(count);
  }, [count, isMoney, isPerAnnum]);

  return (
    <SummaryCardStyles>
      <div className="wrapper-icon">
        <IconComponent />
      </div>
      <div className="wrapper-content">
        {isTitleTooltip ? (
          <Tooltip title={i18next.t(title)}>
            <div className="title">{i18next.t(title)}</div>
          </Tooltip>
        ) : (
          <div className="title">{i18next.t(title)}</div>
        )}
        <div className="count-value">{countValue}</div>
      </div>
    </SummaryCardStyles>
  );
};

SummaryCard.propTypes = {
  IconComponent: PropTypes.any,
  title: PropTypes.string,
  count: PropTypes.number,
  isMoney: PropTypes.bool,
  isPerAnnum: PropTypes.bool,
  isTitleTooltip: PropTypes.bool,
};

SummaryCard.defaultProps = {
  IconComponent: CloseCircleOutlined,
};

export default SummaryCard;

import React, { useCallback } from 'react';
import FilterCustom from 'components/common/FilterCustom';
import { DEFAULT_CURRENT_PAGE } from 'containers/JobListings/constants';
import { useLocation, useParams } from 'react-router';
import { getFilterFromUrl } from 'utils/tools';
import { formatFromToTimeFilterSubmit } from 'utils/dataUtils';
import PropTypes from 'prop-types';
import { DEFAULT_SORT_JOB_APPLICATIONS } from 'containers/JobApplications/constants';
import FilterForm from './FilterForm';

const Filter = ({ retrieveList, pushQuery, setFilterData }) => {
  const { search } = useLocation();
  const { filter } = getFilterFromUrl(search);
  const { id } = useParams();

  const onSubmitFilter = ({ lastUpdated, ...value }) => {
    const restFilter = {
      size: filter.size,
      page: DEFAULT_CURRENT_PAGE,
      sorts: filter.sorts,
      filter: {
        jobPostId: id,
        lastUpdated: formatFromToTimeFilterSubmit(lastUpdated),
        ...value,
      },
    };
    retrieveList({
      filter: restFilter,
      isRefresh: true,
    });
    setFilterData({ ...restFilter });
    pushQuery({
      'sorts[]': filter.sorts,
      ...restFilter,
    });
  };

  const onClearFilter = useCallback(() => {
    retrieveList({
      filter: {
        filter: { jobPostId: id },
        size: filter.size,
        page: filter.page || DEFAULT_CURRENT_PAGE,
        sorts: filter.sorts || [DEFAULT_SORT_JOB_APPLICATIONS],
      },
      isRefresh: true,
    });
    setFilterData({});
    pushQuery({});
  }, [retrieveList, filter, pushQuery, id, setFilterData]);

  return (
    <FilterCustom
      onSubmitFilter={onSubmitFilter}
      onClearFilter={onClearFilter}
      responsiveFilter={{ xxl: 19, xl: 16, lg: 14, xs: 24 }}
      responsiveAction={{
        xxl: 5,
        xl: 8,
        lg: 10,
        xs: 24,
      }}
    >
      <FilterForm applicantId={id} />
    </FilterCustom>
  );
};

Filter.propTypes = {
  pushQuery: PropTypes.func,
  retrieveList: PropTypes.func,
  setFilterData: PropTypes.func,
};

export default Filter;

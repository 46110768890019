import React from 'react';
import PropTypes from 'prop-types';
import AlphabetBar from '../AlphabetBar';
import GridList from './GridList';
import GridViewStyles from './styles';

const GridView = ({ retrieveList, resourceFilter }) => (
  <GridViewStyles className="grid-view">
    <AlphabetBar resourceFilter={resourceFilter} />
    <GridList retrieveList={retrieveList} />
  </GridViewStyles>
);

GridView.propTypes = {
  retrieveList: PropTypes.func,
  resourceFilter: PropTypes.object,
};

export default GridView;

import styled from 'styled-components';

const CardSectionStyles = styled.div`
  background: #fff;
  padding: 15px;
  border-radius: 8px;
  height: 100%;
  iframe {
    max-width: 100%;
  }
  .title-card {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    .icon-info {
      color: #acb2b8;
      font-size: 20px;
    }
    .name-title {
      font-weight: bold;
      font-size: 20px;
      line-height: 25px;
      word-break: break-word;
      color: ${({ theme }) => theme.palette.primary};
    }
    .edit-action-wrapper {
      width: 32px;
      position: relative;
      .edit-wrapper {
        position: absolute;
        right: -15px;
        top: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }

  .ant-divider.ant-divider-horizontal {
    margin: 15px 0 !important;
  }
`;

export default CardSectionStyles;

import { makeCRUDSlice } from 'redux/crudCreator';
import { MODEL_NAME, rolesActions, getPermissions } from './actions';

const slice = makeCRUDSlice(MODEL_NAME, rolesActions, {
  [getPermissions.fulfilled]: (state, { payload }) => {
    state.permissions = payload;
  },
  [getPermissions.rejected]: (state, { payload }) => {
    state.error = payload;
  },
});

export default slice.reducer;

import styled from 'styled-components';

const DocumentsTabStyles = styled.div`
  .header-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    .title-name {
      font-weight: bold;
      font-size: 20px;
      line-height: 25px;
    }
  }
`;

export default DocumentsTabStyles;

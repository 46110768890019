import React from 'react';
import i18next from 'i18next';
import { Form, FormInstance } from 'antd';
import moment from 'moment/moment';
import { RestInputContext } from 'components/RestInput/RestInputContext';
import { useDispatch, useSelector } from 'react-redux';
import {
  DEFAULT_DEADLINE_DAYS_DURATION,
  DEFAULT_DEADLINE_HOURS_DURATION,
} from 'configs/localData/constant';
import {
  resetCreateAdPayload,
  setCreateAdPayload,
} from 'redux/advertisement/slice';
import {
  getAdDurationValues,
  getCreateAdPayload,
} from 'redux/advertisement/selectors';
import { createAd } from 'redux/advertisement/actions';
import { useHistory } from 'react-router-dom';
import { omit } from 'lodash-es';
import { AdDurationSummary } from 'api/advertisement/type';
import { StepTitle } from '../styles';
import Footer from './Footer';
import DateTimeDurationForm from './DateTimeDurationForm';

interface DurationFormProps {
  form: FormInstance;
}

const Duration = ({ form }: DurationFormProps) => {
  const today = moment().startOf('day');
  const dispatch = useDispatch();
  const createAdFormValues = useSelector(getCreateAdPayload);
  const { push } = useHistory();
  const durationValues: AdDurationSummary = useSelector(getAdDurationValues);

  const { durationDays, durationHours, to } = durationValues;

  const nextToDays =
    moment().get('hour') + durationHours >= 24
      ? durationDays + 1
      : durationDays;

  const initialValues = {
    durationDays: durationDays ?? DEFAULT_DEADLINE_DAYS_DURATION,
    durationHours: durationHours ?? DEFAULT_DEADLINE_HOURS_DURATION,
    startTime: moment(),
    endTime: durationHours ? moment().add(durationHours, 'hours') : moment(),
    from: moment(today),
    to: to
      ? moment(today).add(nextToDays, 'days')
      : moment(today).add(DEFAULT_DEADLINE_DAYS_DURATION, 'days'),
  };

  const onNext = () => {
    form
      .validateFields([
        'durationHours',
        'durationDays',
        'from',
        'to',
        'fromTime',
        'endTime',
      ])
      .then(async (formValues) => {
        const { from, to, endTime, fromTime } = formValues;
        if (from && to) {
          from.hour(fromTime.get('hour')).minute(fromTime.get('minute'));
          to.hour(endTime.get('hour')).minute(endTime.get('minute'));
        }

        await dispatch(
          createAd({
            ...omit(createAdFormValues, ['audienceActiveTab']),
            duration: {
              from,
              to,
            },
          }),
        );
        dispatch(resetCreateAdPayload());
        push('/advertisement');
      });
  };

  const onFinish = () => {
    form
      .validateFields([
        'durationHours',
        'durationDays',
        'from',
        'to',
        'fromTime',
        'endTime',
      ])
      .then((formValues) => {
        dispatch(
          setCreateAdPayload({
            duration: { ...formValues },
          }),
        );
      });
  };

  return (
    <div>
      <StepTitle>{i18next.t('advertisement.steps.step5')}</StepTitle>
      <Form form={form} onFinish={onFinish} initialValues={initialValues}>
        <RestInputContext.Provider value={{ form, record: {} }}>
          <DateTimeDurationForm form={form} initialValues={initialValues} />
        </RestInputContext.Provider>
      </Form>
      <Footer onNext={onNext} onBack={onFinish} />
    </div>
  );
};

export default Duration;

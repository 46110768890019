import React, { useState } from 'react';
import CardSection from 'components/common/CardSection';
import { Col, Row } from 'antd';
import ReadMore from 'components/common/ReadMore';
import AboutUsEditModal from 'containers/CompanyProfile/components/Modals/AboutUsEditModal';
import { useSelector } from 'react-redux';
import { getCompanyProfileAboutUsCardSelector } from 'redux/jobs/companyProfile/selectors';
import { NoDataIcon } from 'components/common/SVGIcon';
import i18next from 'i18next';
import useRolesCompanyProfile from 'containers/CompanyProfile/hooks/useRolesCompanyProfile';

const AboutUsCard = () => {
  const aboutUsCardData = useSelector(getCompanyProfileAboutUsCardSelector);
  const [isShowModal, setIsShowModal] = useState(false);
  const disabledAction = useRolesCompanyProfile();
  const onOpenModal = () => {
    setIsShowModal(true);
  };

  const onCloseModal = () => {
    setIsShowModal(false);
  };

  return (
    <div className="text-16">
      <CardSection
        onClickButton={onOpenModal}
        isEdit
        title="jobs.companyProfile.card.aboutUs"
        disabled={disabledAction}
      >
        <Row className="mb-32">
          <Col xxl={24} xl={24} sm={24}>
            {aboutUsCardData ? (
              <ReadMore
                text={aboutUsCardData}
                showLessButton
                readMoreCharacterLimit={500}
              />
            ) : (
              <div className="flex-direction-column flex-center">
                <NoDataIcon />
                <h5>
                  {i18next.t('jobs.companyProfile.modal.aboutUs.noDescription')}
                </h5>
              </div>
            )}
          </Col>
        </Row>
      </CardSection>
      <AboutUsEditModal
        visible={isShowModal}
        onCancel={onCloseModal}
        aboutUsCardData={aboutUsCardData}
        record={aboutUsCardData}
      />
    </div>
  );
};

export default AboutUsCard;

import React from 'react';
import i18next from 'i18next';
import { Row, Col } from 'antd';
import PageTitle from 'components/common/PageTitle';
import HomeWrapper from './styles';
import SummaryRow from './components/SummaryRow';
import TasksSection from './components/TasksSection';

const Home = () => (
  <HomeWrapper>
    <PageTitle>{i18next.t('dashboard.title')}</PageTitle>
    <Row gutter={[24, 24]}>
      <Col xl={18} lg={16} md={24} xs={24}>
        <SummaryRow />
      </Col>
      <Col xl={6} lg={8} md={24} xs={24}>
        <TasksSection />
      </Col>
    </Row>
  </HomeWrapper>
);

export default Home;

import React, { useEffect, useCallback } from 'react';
import RestInputItem from 'components/RestInput/RestInputItem';
import { Col, Row } from 'antd';
import RestSelect from 'components/RestInput/RestSelect';
import RestFormDateTimePicker from 'components/RestInput/RestDateTimePicker';
import { useDispatch, useSelector } from 'react-redux';
import { getCampaignsSelector } from 'redux/jobs/jobListings/selectors';
import { getCampaigns } from 'redux/jobs/jobListings/actions';
import {
  DEFAULT_CURRENT_PAGE,
  LIMIT_CAMPAIGN_DEFAULT,
} from 'containers/JobListings/constants';
import PropTypes from 'prop-types';
import moment from 'moment';
import { JOB_STATUS } from 'configs/localData/constant';
import { JobListingDetailFormStyles } from './styles';

const JobListingDetailForm = ({ record }) => {
  const dispatch = useDispatch();

  const { data } = useSelector(getCampaignsSelector);

  const getListCampaign = useCallback(
    ({ data, isRefresh }) =>
      dispatch(
        getCampaigns({
          data: {
            page: DEFAULT_CURRENT_PAGE,
            size: LIMIT_CAMPAIGN_DEFAULT,
            ...data,
          },
          options: {
            isRefresh,
          },
        }),
      ),
    [dispatch],
  );

  useEffect(() => {
    getListCampaign({});
  }, [getListCampaign]);

  const today = moment().startOf('day');
  const disabledDate = (current) => {
    if (current < moment(today) || current >= moment(today).add(365, 'days')) {
      return true;
    }
    return false;
  };

  return (
    <JobListingDetailFormStyles>
      <Row gutter={[16, 16]}>
        <Col md={12} xs={24}>
          <RestInputItem
            source="jobTitle"
            header="jobListings.jobTitle"
            disabled
          />
        </Col>
        <Col md={12} xs={24}>
          <RestInputItem
            source="templateName"
            header="jobListings.jobTemplate"
            disabled
          />
        </Col>
      </Row>
      <Row gutter={[16, 16]}>
        <Col md={12} xs={24}>
          <RestSelect
            source="workplace"
            header="jobListings.workplace"
            disabled
          />
        </Col>
        <Col md={12} xs={24}>
          <RestInputItem
            source="jobReference"
            header="jobListings.jobReference"
          />
        </Col>
      </Row>
      <Row>
        <Col xs={24}>
          <RestSelect
            header="jobListings.campaign"
            source="campaignId"
            placeholder="jobListings.selectCampaign"
            resourceData={data || []}
            valueProp="id"
            titleProp="name"
            required
          />
        </Col>
      </Row>
      <Row gutter={[16, 16]}>
        <Col md={12} xs={24}>
          <RestFormDateTimePicker
            source="startDate"
            header="applications.startDate"
            disabled
          />
        </Col>
        <Col md={12} xs={24}>
          <RestFormDateTimePicker
            source="applicationDeadline"
            header="jobListings.steps.applicationDeadline"
            placeholder="jobListings.selectApplicationDeadline"
            required
            disabledDate={disabledDate}
            disabled={record?.jobStatus === JOB_STATUS.closed}
          />
        </Col>
      </Row>
    </JobListingDetailFormStyles>
  );
};

JobListingDetailForm.propTypes = {
  record: PropTypes.object,
};

export default JobListingDetailForm;

import programLearningBanner from 'assets/images/new-feature-announcement/program-learning-banner.png';

export const NEW_FEATURE = {
  programsAndLearning: {
    title: 'New Feature Release - Programs & Learning',
    content:
      'Introducing a new feature for CRM users! You can now create and manage Programs & Learning content and link them with your services. This allows you to provide far more information to families about the curriculum, learning programs and activities you offer by age group and highlight what differentiates your services from your competitors. Our simple to use Programs & Learning templates enable you to easily create and manage this content, set age and service restrictions for the content and manage all aspects of this information for your listings in one place.',
    img: programLearningBanner,
    linkToRedirect: '/programs-and-learning',
  },
};

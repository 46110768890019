import { Button, Col, Form, Input, Row, Space } from 'antd';
import { pick, isEmpty, omit } from 'lodash-es';
import { RestInputContext } from 'components/RestInput/RestInputContext';
import RestSelect from 'components/RestInput/RestSelect';
import { REPORT_TYPES } from 'configs/localData';
import {
  LABEL_TABLE_KEY,
  MAX_LIMIT_MAP,
  MAX_LIMIT_LIST,
} from 'configs/localData/constant';
import { formatLabelTable } from 'containers/Centres/components/CentreTabs/ServicesTab/utils';
import { getPriceBenchmarkingSummary } from 'redux/priceBenchmarking/actions';
import i18next from 'i18next';
import PropTypes from 'prop-types';
import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router';
import {
  getServiceAgeGroups,
  getServiceTypes,
  getSessionTypes,
} from 'redux/config/selectors';
import { resetResourceFilterPriceBenchMarking } from 'redux/priceBenchmarking/slice';
import { convertSearchUrl, getFilterFromUrl } from 'utils/tools';
import { getResourceDataSelector } from 'redux/priceBenchmarking/selectors';
import { handleLoadMapViewData } from 'containers/PriceBenchmarking/utils';
import CentreAddressSelectForm from './CentreAddressSelectForm';
import { FilterStyles } from './styles';

const Filter = ({ retrieveList }) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { push } = useHistory();
  const { search } = useLocation();
  const [isDisableBtn, setIsDisableBtn] = useState(false);
  const [isWrongAddress, setIsWrongAddress] = useState();
  const addressFormRef = useRef(null);
  const { model } = useParams();
  const mapViewTab = model === 'mapView';

  const outsideFilter = getFilterFromUrl(search);
  const filter = outsideFilter?.filter;

  const appSettings = useSelector((state) => state.config?.data?.AppSetting);

  const serviceTypes = useSelector(getServiceTypes);
  const sessionTypes = useSelector(getSessionTypes);
  const serviceAgeGroups = useSelector(getServiceAgeGroups);
  const resourceFilter = useSelector(getResourceDataSelector) || {};

  const ageGroupLabel = formatLabelTable(LABEL_TABLE_KEY.ageGroup, appSettings);

  const serviceTypeLabel = formatLabelTable(
    LABEL_TABLE_KEY.serviceType,
    appSettings,
  );

  const sessionTypeLabel = formatLabelTable(
    LABEL_TABLE_KEY.sessionType,
    appSettings,
  );

  const getSummaryPriceBenmarking = (restFilter) => {
    const summaryFilter = [
      'centreId',
      'city',
      'postCode',
      'reportType',
      'serviceAgeGroupId',
      'serviceTypeId',
      'sessionTypeId',
      'latitude',
      'longitude',
      'state',
    ];
    return dispatch(
      getPriceBenchmarkingSummary(pick(restFilter, summaryFilter)),
    );
  };

  useEffect(() => {
    if (!isEmpty(outsideFilter?.filter)) {
      form.setFieldsValue({
        centreId: filter?.centreId,
        reportType: filter?.reportType,
        serviceAgeGroupId: filter?.serviceAgeGroupId,
        serviceTypeId: filter?.serviceTypeId,
        sessionTypeId: filter?.sessionTypeId,
        city: filter?.city,
        postCode: filter?.postCode,
        state: filter?.state,
        displayAddress: filter?.displayAddress,
      });
      const restFilter = omit(filter, ['displayAddress', 'addressL1']);

      mapViewTab
        ? handleLoadMapViewData(
            0,
            restFilter,
            resourceFilter?.total,
            retrieveList,
          )
        : retrieveList({
            filter: {
              outsideFilter: {
                ...restFilter,
                limit: mapViewTab ? MAX_LIMIT_MAP : MAX_LIMIT_LIST,
                offset: 0,
                orderBy: outsideFilter.orderBy || 'centreName',
              },
            },
            isRefresh: true,
          });
      getSummaryPriceBenmarking(restFilter);
      push({
        search: `?${convertSearchUrl({
          ...restFilter,
          limit: mapViewTab ? MAX_LIMIT_MAP : MAX_LIMIT_LIST,
          displayAddress: filter?.displayAddress,
          addressL1: filter?.addressL1,
          offset: 0,
          orderBy: outsideFilter.orderBy,
        })}`,
      });
    }
  }, []); // eslint-disable-line

  const onSubmitFilter = () => {
    form
      .validateFields()
      .then((values) => {
        const {
          centreId,
          city,
          postCode,
          reportType,
          serviceAgeGroupId,
          serviceTypeId,
          sessionTypeId,
          geolocation,
          state,
          displayAddress,
          addressL1,
        } = values;

        const restFilter = {
          centreId,
          city,
          state,
          postCode,
          reportType,
          serviceAgeGroupId,
          serviceTypeId,
          sessionTypeId,
          latitude: geolocation?.latitude || filter?.latitude,
          longitude: geolocation?.longitude || filter?.longitude,
        };
        push({
          search: `?${convertSearchUrl({
            ...restFilter,
            displayAddress,
            addressL1,
            limit: mapViewTab ? MAX_LIMIT_MAP : MAX_LIMIT_LIST,
            offset: 0,
          })}`,
        });
        getSummaryPriceBenmarking(restFilter);
        mapViewTab
          ? handleLoadMapViewData(
              0,
              restFilter,
              resourceFilter?.total,
              retrieveList,
            )
          : retrieveList({
              filter: {
                outsideFilter: {
                  ...restFilter,
                  limit: MAX_LIMIT_LIST,
                  offset: 0,
                  orderBy: 'centreName',
                },
              },
              isRefresh: true,
            });
      })
      .catch(() => {});
  };
  const onClearFilter = () => {
    form.resetFields();
    push({ search: '?' });
    dispatch(resetResourceFilterPriceBenchMarking());
    setIsDisableBtn(false);
    setIsWrongAddress(false);
    addressFormRef.current.resetFullForm();
  };

  return (
    <FilterStyles>
      <Form form={form} autoComplete="off" layout="vertical">
        <RestInputContext.Provider
          value={{
            form,
            allowPressEnter: true,
            handleSubmit: onSubmitFilter,
          }}
        >
          <Form.Item
            label={i18next.t('priceBenchmarking.selectNameOrAddress')}
            required
          >
            <CentreAddressSelectForm
              setIsDisableBtn={setIsDisableBtn}
              isWrongAddress={isWrongAddress}
              setIsWrongAddress={setIsWrongAddress}
              ref={addressFormRef}
            />
          </Form.Item>
          <Form.Item name="geolocation" className="d-none">
            <Input />
          </Form.Item>
          <Row gutter={32}>
            <Col xl={12} lg={12} md={24} xs={24}>
              <RestSelect
                required
                source="serviceTypeId"
                placeholder={i18next.t('priceBenchmarking.selectServiceType')}
                header={serviceTypeLabel}
                valueProp="id"
                titleProp="name"
                resourceData={serviceTypes || []}
              />
            </Col>
            <Col xl={12} lg={12} md={24} xs={24}>
              <RestSelect
                required
                source="sessionTypeId"
                placeholder={i18next.t('priceBenchmarking.selectSessionType')}
                header={sessionTypeLabel}
                valueProp="id"
                titleProp="name"
                resourceData={sessionTypes || []}
              />
            </Col>
          </Row>
          <Row gutter={32}>
            <Col xl={12} lg={12} md={24} xs={24}>
              <RestSelect
                required
                source="serviceAgeGroupId"
                placeholder={i18next.t('priceBenchmarking.selectAgeGroup')}
                header={ageGroupLabel}
                valueProp="id"
                titleProp="name"
                resourceData={
                  [
                    {
                      name: i18next.t('priceBenchmarking.allAgeGroups'),
                      id: 'all',
                    },
                    ...serviceAgeGroups,
                  ] || []
                }
              />
            </Col>
            <Col xl={12} lg={12} md={24} xs={24}>
              <RestSelect
                required
                source="reportType"
                placeholder={i18next.t('priceBenchmarking.selectReportType')}
                header={i18next.t('priceBenchmarking.reportType')}
                valueProp="value"
                titleProp="text"
                formatText={(data) => i18next.t(data)}
                resourceData={REPORT_TYPES || []}
              />
            </Col>
          </Row>
          <Form.Item>
            <Space className="flex-center-end mt-39">
              <Button
                type="primary"
                htmlType="submit"
                disabled={isDisableBtn}
                onClick={onSubmitFilter}
              >
                {i18next.t('button.runPriceBenchmarkingReport')}
              </Button>
              <Button className="clearButton" onClick={onClearFilter}>
                {i18next.t('button.clearFilter')}
              </Button>
            </Space>
          </Form.Item>
        </RestInputContext.Provider>
      </Form>
    </FilterStyles>
  );
};

Filter.propTypes = {
  retrieveList: PropTypes.func,
};

Filter.defaultProps = {
  retrieveList: () => null,
};

export default Filter;

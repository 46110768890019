import Layout from 'antd/lib/layout/layout';
import styled from 'styled-components';

const EULAStyles = styled(Layout)`
  min-height: 100vh;
  .mainView {
    height: 100vh;
    margin-left: 80px;
    overflow: hidden;
    transition: all 0.3s ease 0s;
    background: ${({ theme }) => theme.background.container};
  }
`;

export default EULAStyles;

import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { getByIdCentres, clearCurrentCentre } from 'redux/centres/actions';
import { centresSelectors } from 'redux/centres/selectors';
import { PREFIX_URL } from 'configs/localData/constant';
import { Skeleton } from 'antd';
import CentreClaimInfo from './Details/CentreInfo';

const CentreCardWrapper = () => {
  const { id } = useParams();

  const dispatch = useDispatch();

  const currentCentre = useSelector(centresSelectors.getCurrentData);

  const loading = useSelector(centresSelectors.getLoadingCurrentRecord);

  useEffect(() => {
    id &&
      dispatch(
        getByIdCentres({
          data: {
            id,
            include: 'claimCentre',
          },
          options: {
            isRequestApi: true,
            isRefresh: true,
            prefixUrl: PREFIX_URL.public,
          },
        }),
      );

    return () => {
      dispatch(clearCurrentCentre());
    };
  }, [id, dispatch]);

  if (loading) {
    return <Skeleton active paragraph={{ rows: 8 }} />;
  }

  return <CentreClaimInfo record={currentCentre} />;
};

export default CentreCardWrapper;

import SwitchAction from 'components/RestActions/SwitchAction';
import { useCentreServiceControllerUpdateOneMutation } from 'generated/apis';
import React, { useCallback } from 'react';

interface Props {
  id: string;
  checked: boolean;
  source: string;
}

const SwitchToggle = ({ id, checked, source }: Props) => {
  const [updateService, { isLoading }] =
    useCentreServiceControllerUpdateOneMutation();

  const onChange = useCallback(
    (checked) => {
      updateService({
        serviceId: id,
        updateCentreServiceDto: {
          [source]: checked,
        },
      });
    },
    [updateService, id, source],
  );

  return (
    <SwitchAction
      permissionKey="myCentres"
      action="isEdit"
      checked={checked}
      onChange={onChange}
      switchProps={{
        loading: isLoading,
      }}
    />
  );
};

export default SwitchToggle;

import React from 'react';

import { Row, Col } from 'antd';
import ContactInfo from './ContactInfo';
import ContactDetailStyles from './styles';
import SummaryApplication from './SummaryApplication';
import SummaryEnquiries from './SummaryEnquiries';
import ApplicationsTable from './ApplicationsTable';
import EnquiriesTable from './EnquiriesTable';
import ChildrenInfo from './ChildrenInfo';
import LeadActivitiesTable from './LeadActivitiesTable';

const ContactDetail = () => (
  <ContactDetailStyles>
    <Row gutter={[24, 24]}>
      <Col xl={6} lg={8} md={24} xs={24}>
        <ContactInfo />
      </Col>

      <Col xl={18} lg={16} md={24} xs={24}>
        <Row gutter={[24, 24]}>
          <Col xl={14} lg={24} md={24} xs={24}>
            <Row gutter={[0, 24]}>
              <Col span={24}>
                <ApplicationsTable />
              </Col>
              <Col span={24}>
                <EnquiriesTable />
              </Col>
              <Col span={24}>
                <LeadActivitiesTable />
              </Col>
            </Row>
          </Col>
          <Col xl={10} lg={24} md={24} xs={24}>
            <Row gutter={[0, 24]}>
              <Col span={24}>
                <SummaryApplication />
              </Col>
              <Col span={24}>
                <SummaryEnquiries />
              </Col>
              <Col span={24}>
                <ChildrenInfo />
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
    </Row>
  </ContactDetailStyles>
);

export default ContactDetail;

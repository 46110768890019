import React from 'react';
import { FormInstance } from 'antd';

export interface InputContext {
  form?: FormInstance;
  allowPressEnter?: boolean;
  record?: object;
  handleSubmit?: () => void;
}

export const RestInputContext = React.createContext<InputContext>({});

import React from 'react';
import { SiderStartScreenStyle } from 'containers/Centres/components/CentreTabs/EducatorStaffProfilesTab/Create/styles';
import i18next from 'i18next';
import StaffProfileSider from 'assets/images/staff-profile-sider.png';

const SiderStartScreen = () => (
  <SiderStartScreenStyle>
    {i18next.t('educatorStaffProfile.creatProfile')}
    <img src={StaffProfileSider} width={280} alt="Educator Staff Sider" />
  </SiderStartScreenStyle>
);

export default SiderStartScreen;

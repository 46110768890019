import styled from 'styled-components';

export const CentresNotifyModalStyles = styled.div`
  padding-bottom: 10px;
  .centre-item {
    padding: 15px 0;
  }

  .centre-notify__desc {
    line-height: 24px;
    color: ${({ theme }) => theme.text.mainL2};
    margin-bottom: 10px;
  }

  .centre-list {
    &__header {
      padding: 10px 0;
      border-bottom: 1px solid ${({ theme }) => theme.border.default};
    }
    &__header-name {
      font-size: 16px;
      line-height: 25px;
      font-weight: bold;
    }
    &__header-action {
      display: flex;
      align-items: center;
    }
    &__enable-all {
      margin-left: 10px;
    }
    &__view-more {
      padding-left: 0 !important;
    }
  }
`;

import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'antd';

import CheckPermission from 'components/common/CheckPermissions';

const SignUpButtonCheckPermission = ({ onClickSignup, loading, textBtn }) => (
  <CheckPermission
    permissionKey="subscriptionLevel"
    action="isEdit"
    isSkipSubscription
    isDisabled
  >
    <Button className="btn-sign" onClick={onClickSignup} loading={loading}>
      {textBtn}
    </Button>
  </CheckPermission>
);

SignUpButtonCheckPermission.propTypes = {
  onClickSignup: PropTypes.func,
  loading: PropTypes.bool,
  textBtn: PropTypes.string,
};

export default SignUpButtonCheckPermission;

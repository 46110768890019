import React from 'react';
import PropTypes from 'prop-types';
import ReadMore from 'components/common/ReadMore';
import { Typography } from 'antd';
import i18next from 'i18next';

const WhatYouWillBeDoing = ({ candidateResponsibilities }) => (
  <div>
    <Typography.Text className="fw-bold text-20">
      {i18next.t('jobs.moreAboutTheJob.whatYouWillBeDoing')}
    </Typography.Text>
    <div className="mt-12">
      <ReadMore
        text={candidateResponsibilities || ''}
        readMoreCharacterLimit={500}
        showLessButton
        isCheckInsertLink
        showLessText="button.readLess"
      />
    </div>
  </div>
);

WhatYouWillBeDoing.propTypes = {
  candidateResponsibilities: PropTypes.node,
};

export default WhatYouWillBeDoing;

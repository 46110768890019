import React from 'react';
import PropTypes from 'prop-types';
import MessageFooter from 'containers/Enquiries/components/MessageDetail/MessageFooter';

const Footer = ({ id, applicationId }) => (
  <MessageFooter
    isUploadFile
    id={id}
    isRefreshDocument
    applicationId={applicationId}
  />
);

Footer.propTypes = {
  id: PropTypes.string,
  applicationId: PropTypes.string,
};

export default Footer;

import React, { useState } from 'react';
import ProfileDetailSection from 'containers/Centres/components/CentreTabs/EducatorStaffProfilesTab/components/StaffProfileDetail/ProfileDetailSection';
import { StaffProfileModel } from 'types/staffProfiles';
import {
  DocumentBoldIcon,
  DocumentPlaceholderIcon,
  EditIcon,
} from 'components/common/SVGIcon';
import i18next from 'i18next';
import LineEllipsis from 'components/common/ReadMore/LinesEllipsis';
import { isEmpty } from 'lodash-es';
import EmptyContainer from 'containers/Centres/components/CentreTabs/EducatorStaffProfilesTab/components/EmptyContainer';
import ModalCustom from 'components/common/ModalCustom';
import { Form } from 'antd';
import AboutStaffForm from 'containers/Centres/components/CentreTabs/EducatorStaffProfilesTab/Create/Steps/AboutStaff/AboutStaffForm';
import { useEditStaffProfilesMutation } from 'redux/@rtkQuery/educatorStaffProfile';
import { PlusOutlined } from '@ant-design/icons';

interface Props {
  staffProfile: StaffProfileModel;
  disable?: boolean;
}

const AboutStaffDetail = ({ staffProfile, disable }: Props) => {
  const [form] = Form.useForm();
  const [isVisibleEditAboutStaff, setIsVisibleEditAboutStaff] = useState(false);
  const { about: initialValues, id, centreId } = staffProfile;
  const [editStaffProfiles] = useEditStaffProfilesMutation();
  const isEmptyAbout = isEmpty(staffProfile?.about);

  const handleCloseEditModal = () => {
    setIsVisibleEditAboutStaff(false);
    form.resetFields();
  };

  const handleSubmitEditModal = () => {
    const about = form.getFieldsValue()?.about;
    const payload = {
      id,
      centreId,
      about,
    };

    editStaffProfiles(payload)
      .unwrap()
      .then((response) => {
        handleCloseEditModal();
        form.setFieldsValue({ about: response.raw?.[0]?.about });
      });
  };

  const handleOpenEditModal = () => {
    setIsVisibleEditAboutStaff(true);
  };

  return (
    <>
      <ProfileDetailSection
        icon={<DocumentBoldIcon className="text-20 text-primary" />}
        title={i18next.t('educatorStaffProfile.aboutName', {
          name: staffProfile.basicInfo.firstName,
        })}
        onAdd={handleOpenEditModal}
        headerIcon={isEmptyAbout ? <PlusOutlined /> : <EditIcon />}
        disabled={disable}
      >
        {isEmptyAbout ? (
          <EmptyContainer
            Icon={DocumentPlaceholderIcon}
            description="educatorStaffProfile.noAbout"
            buttonTitle="educatorStaffProfile.addBiography"
            name={staffProfile.basicInfo.firstName}
            onAdd={handleOpenEditModal}
            disabled={disable}
          />
        ) : (
          <LineEllipsis
            className="ellipsis-text whitespace-pre-line"
            text={initialValues}
            maxLine={5}
            trimRight
          />
        )}
      </ProfileDetailSection>
      <ModalCustom
        title={i18next.t('educatorStaffProfile.aboutName', {
          name: staffProfile.basicInfo.firstName,
        })}
        visible={isVisibleEditAboutStaff}
        onCancel={handleCloseEditModal}
        onOk={handleSubmitEditModal}
        width={1000}
      >
        <AboutStaffForm form={form} initialValues={initialValues} />
      </ModalCustom>
    </>
  );
};

export default AboutStaffDetail;

import { createSelector } from 'reselect';
import { CRUDSelectors } from 'redux/crudCreator';
import { values } from 'lodash-es';
import { MODEL_NAME } from './actions';

const getRawCompareRating = (state) =>
  state?.brandBenchmarking?.compareRatingChart;

const getRawData = (state) => state.brandBenchmarking.data;

const getRawBrandBenchmarking = (state) => state?.brandBenchmarking?.summary;

export const getBrandBenchmarkingSummary = createSelector(
  [getRawBrandBenchmarking],
  (data) => data || [],
);

const getResourceBrandBenchmarkingData = (state) =>
  state.brandBenchmarking.resourceData;

export const getResourceDataBrandBenchmarkingSelector = createSelector(
  [getResourceBrandBenchmarkingData],
  (data) => data,
);

export const getDataBrandBenchmarking = createSelector([getRawData], (data) =>
  values(data),
);

const getRawAverageData = (state) => state?.brandBenchmarking?.averageAllBrands;

export const getAverageDataSelector = createSelector(
  [getRawAverageData],
  (data) => data,
);

export const getBrandBenchmarkingCompareRatingSelector = createSelector(
  [getRawCompareRating],
  (data) => data || {},
);

const getServiceApprovalsData = (state) =>
  state?.brandBenchmarking?.userBrandBenchmarkingChart?.serviceApprovals;

const getNQSRatingData = (state) =>
  state.brandBenchmarking?.userBrandBenchmarkingChart?.nqsRating;

const getKindicareRatingData = (state) =>
  state.brandBenchmarking?.userBrandBenchmarkingChart?.kindicareRating;

const getBrandBenchmarkCharts = (state) =>
  state?.brandBenchmarking?.userBrandBenchmarkingChart;

export const getBrandServiceApprovals = createSelector(
  getServiceApprovalsData,
  (data) => data,
);

export const getBrandBenchmarkingNSQRating = createSelector(
  [getNQSRatingData],
  (data) => data || [],
);

export const getBrandBenchmarkingKindicareRating = createSelector(
  [getKindicareRatingData],
  (data) => data || [],
);

export const getBrandBenchmarkChartsSelector = createSelector(
  [getBrandBenchmarkCharts],
  (data) => data || {},
);

export const brandBenchmarkingSelectors = new CRUDSelectors(MODEL_NAME);

const { default: styled } = require('styled-components');

const HeaderUserInfoEnquiryStyles = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  .info-section {
    .title {
      font-weight: ${({ isContactSession }) =>
        isContactSession ? '700' : '600'};
      font-size: ${({ isContactSession }) =>
        isContactSession ? '16px' : '14px'};
    }

    line-height: 20px;
  }
  .info-section {
    margin-left: 20px;
  }
  .anticon-crown {
    position: absolute;
    top: 5px;
    left: ${({ isContactSession }) => (isContactSession ? '48px' : '59px')};
    font-size: 20px;
    color: rgb(250, 173, 20);
  }
`;

export default HeaderUserInfoEnquiryStyles;

import React from 'react';
import { useSortable } from '@dnd-kit/sortable';
import UploadImageItem from 'components/RestInput/RestUploadFile/UploadImageItem';
import { SortableItemStyle } from 'components/RestInput/RestUploadFile/styles';

const SortableItem = ({
  id,
  imageItemProps,
  index,
  item,
  isShowVirtualTour,
}) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ id });

  return (
    <SortableItemStyle
      transform={transform}
      transition={transition}
      isDragging={isDragging}
      {...(isShowVirtualTour ? { xs: 12 } : { md: 8, xs: 12 })}
      ref={setNodeRef}
      {...attributes}
    >
      <div>
        <span
          data-cypress="draggable-handle"
          className="dragable-item"
          {...listeners}
        >
          <svg viewBox="0 0 20 20" width="1em" height="1em">
            <path
              d="M7 2a2 2 0 1 0 .001 4.001A2 2 0 0 0 7 2zm0 6a2 2 0 1 0 .001 4.001A2 2 0 0 0 7 8zm0 6a2 2 0 1 0 .001 4.001A2 2 0 0 0 7 14zm6-8a2 2 0 1 0-.001-4.001A2 2 0 0 0 13 6zm0 2a2 2 0 1 0 .001 4.001A2 2 0 0 0 13 8zm0 6a2 2 0 1 0 .001 4.001A2 2 0 0 0 13 14z"
              fill="currentColor"
            />
          </svg>
        </span>

        <UploadImageItem
          item={item}
          index={index}
          isShowVirtualTour={isShowVirtualTour}
          {...imageItemProps}
        />
      </div>
    </SortableItemStyle>
  );
};

export default SortableItem;

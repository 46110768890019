import { Image } from 'antd';
import Popover from 'antd/lib/popover';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { formatMoney } from 'utils/tools';
import CentreCardInfo from 'containers/PriceBenchmarking/components/CentreCardInfo';
import { MarketDefaultStyles } from 'containers/PriceBenchmarking/components/MapView/styles';
import { MarkerIconStyles } from './styles';

const MarkerIcon = ({
  item,
  customMarker,
  colorStyles,
  Icon,
  hasSelectedCentre,
  equalLocation,
}) => {
  const [visible, setVisible] = useState(false);
  const { color, backgroundColor, borderColor } = colorStyles;
  const brandLogoImage = item?.brand?.logo;

  if (item.isMatchedAddress) {
    return (
      <MarketDefaultStyles>
        <Popover content={item?.displayAddress}>
          <Icon />
        </Popover>
      </MarketDefaultStyles>
    );
  }

  return (
    <MarkerIconStyles
      color={color}
      backgroundColor={backgroundColor}
      hasSelectedCentre={hasSelectedCentre}
      equalLocation={equalLocation}
      borderColor={borderColor}
      brandLogoImage={brandLogoImage}
    >
      <Popover
        content={
          <CentreCardInfo
            item={item}
            isExistOnSlide={false}
            fullWidth={false}
          />
        }
        placement="right"
        overlayClassName="reset-padding-overlay"
        visible={visible}
        onVisibleChange={setVisible}
        className={visible && 'selected'}
        getPopupContainer={(trigger) => trigger.parentElement}
      >
        {customMarker || (
          <div className="market">
            <div className="info-wrapper">
              <div className="info-popup">
                {brandLogoImage ? (
                  <Image src={brandLogoImage} preview={false} />
                ) : (
                  ''
                )}
                <div className="value">
                  {`$${formatMoney(item?.averageAllAgeGroup, 3, true)}`}
                </div>
              </div>
            </div>
            {hasSelectedCentre || equalLocation ? (
              <div>
                <Icon />
              </div>
            ) : (
              <div className="point-market" role="presentation" />
            )}
          </div>
        )}
      </Popover>
    </MarkerIconStyles>
  );
};
MarkerIcon.propTypes = {
  item: PropTypes.object,
  customMarker: PropTypes.element,
  Icon: PropTypes.any,
  colorStyles: PropTypes.object,
  hasSelectedCentre: PropTypes.bool,
  equalLocation: PropTypes.bool,
};

export default MarkerIcon;

import React, { useEffect, useCallback } from 'react';
import { Tabs } from 'antd';
import i18next from 'i18next';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import {
  getByIdEnquiries,
  clearCurrentEnquiries,
} from 'redux/enquiries/actions';

import SummaryTab from './SummaryTab';
import MailboxTab from './MailboxTab';
import DocumentsTab from './DocumentsTab';
import { TabsStyles } from './styles';
import ActivitiesTab from './ActivitiesTab';
import TasksTab from './TasksTab';

const { TabPane } = Tabs;

const ApplicationTabs = ({ inboxId }) => {
  const TABS = [
    {
      key: 'summary',
      title: 'applications.tabs.summary',
      ComponentList: SummaryTab,
    },
    {
      key: 'mailbox',
      title: 'applications.tabs.mailbox',
      ComponentList: MailboxTab,
      className: 'mailbox-tab',
    },
    {
      key: 'documents',
      title: 'applications.tabs.documents',
      ComponentList: DocumentsTab,
    },
    {
      key: 'activities',
      title: 'applications.tabs.activities',
      ComponentList: ActivitiesTab,
    },
    {
      key: 'tasks',
      title: 'applications.tabs.tasks',
      ComponentList: TasksTab,
    },
  ];

  const dispatch = useDispatch();

  const { push } = useHistory();

  const { id, modelDetail } = useParams();

  useEffect(() => {
    inboxId &&
      dispatch(
        getByIdEnquiries({
          data: { id: inboxId },
          options: { customApiResource: 'inboxes', isRequestApi: true },
        }),
      );

    return () => dispatch(clearCurrentEnquiries());
  }, [inboxId]); // eslint-disable-line

  const onChange = useCallback(
    (key) => {
      push(`/applications/${id}/show/${key}`);
    },
    [push, id],
  );

  return (
    <TabsStyles>
      <Tabs activeKey={modelDetail} onChange={onChange}>
        {TABS.map((tab) => (
          <TabPane
            tab={i18next.t(tab.title)}
            key={tab.key}
            className={tab.className || ''}
          >
            <tab.ComponentList />
          </TabPane>
        ))}
      </Tabs>
    </TabsStyles>
  );
};

ApplicationTabs.propTypes = {
  inboxId: PropTypes.string,
};

export default ApplicationTabs;

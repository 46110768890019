import React from 'react';
import { Form, Skeleton } from 'antd';
import i18next from 'i18next';
import { AdDetailItem } from 'api/advertisement/type';
import AdCreativeForm, {
  AdCreativeInitialValues,
} from 'containers/Advertisement/Create/Steps/AdCreative/AdCreativeForm';
import AdModal from './index';

interface AdCreativeModalProps {
  visible?: boolean;
  onClose?: () => void;
  adDetailValues: AdDetailItem;
}

const AdCreativeModal: React.FC<AdCreativeModalProps> = ({
  visible,
  onClose,
  adDetailValues,
}) => {
  const [form] = Form.useForm();
  const initialValues: AdCreativeInitialValues = {
    headline: adDetailValues.headline,
    description: adDetailValues.description,
    callingButton: adDetailValues.callingButton?.id,
    destination: adDetailValues.callingButton?.destination,
  };
  const initialImages = adDetailValues.mediaList?.map(({ type, url }) => ({
    type,
    url,
  }));

  const onCancel = () => {
    onClose();
  };
  const onSave = () => {
    const currentMediaList = form.getFieldValue('mediaList');
    form.setFieldsValue({
      mediaList: currentMediaList?.map((v) => ({
        type: v.type?.includes('image') ? 'image' : 'video',
        url: v.url || v.response,
      })),
    });
  };

  return (
    <AdModal
      visible={visible}
      title={i18next.t('advertisement.adCreative')}
      onSave={onSave}
      onCancel={onCancel}
      width={850}
      form={form}
    >
      <Skeleton loading={!adDetailValues} active>
        <AdCreativeForm
          form={form}
          initialValues={initialValues}
          initialImages={initialImages}
          currentAdType={adDetailValues.type}
          disabledTitle
        />
      </Skeleton>
    </AdModal>
  );
};

export default AdCreativeModal;

import {
  CloseOutlined,
  DownOutlined,
  RightOutlined,
  UpOutlined,
} from '@ant-design/icons';
import { List, Space, Tooltip } from 'antd';
import { EXPORT_STATUS } from 'configs/localData';
import i18next from 'i18next';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectExportList,
  selectVisibleStateDownloadPopup,
} from 'redux/exportProgress/selectors';
import {
  ExportItem,
  setVisibleDownloadPopup,
} from 'redux/exportProgress/slice';
import DownloadItem from './DownloadItem';
import { CollapseDownloadPopup, CollapsePanel } from './styles';

const DOWNLOAD_PANEL_KEY = 'DOWNLOAD_PANEL_KEY';

const DownloadPopup = () => {
  const dispatch = useDispatch();
  const [activeKey, setActiveKey] = useState(DOWNLOAD_PANEL_KEY);

  const visible = useSelector(selectVisibleStateDownloadPopup);
  const exportList = useSelector(selectExportList);

  const isAllExportCompleted = exportList.every(
    (item) => item?.status === EXPORT_STATUS.complete,
  );

  const isPanelOpen = activeKey === DOWNLOAD_PANEL_KEY;

  const onClosePopup = (
    event: React.MouseEvent<HTMLSpanElement, MouseEvent>,
  ) => {
    event.stopPropagation();
    dispatch(setVisibleDownloadPopup(false));
  };

  const renderExtraHeaderActions = () => (
    <Space size={20}>
      <Tooltip
        title={
          isPanelOpen
            ? i18next.t('common.minimize')
            : i18next.t('common.maximize')
        }
        getPopupContainer={(trigger: HTMLElement) =>
          trigger.parentNode as HTMLElement
        }
      >
        {isPanelOpen ? <DownOutlined /> : <UpOutlined />}
      </Tooltip>
      <Tooltip title={i18next.t('button.close')}>
        <CloseOutlined onClick={onClosePopup} />
      </Tooltip>
    </Space>
  );

  const onChange = (key: string) => {
    setActiveKey(key);
  };

  const onClickViewExportLibrary = () => {
    window.open('/export-library');
  };

  return (
    visible && (
      <CollapseDownloadPopup
        activeKey={activeKey}
        onChange={onChange}
        bordered={false}
        accordion
      >
        <CollapsePanel
          header={
            isAllExportCompleted
              ? i18next.t('common.downloadReady')
              : i18next.t('common.preparingDownload')
          }
          showArrow={false}
          extra={renderExtraHeaderActions()}
          isPanelOpen={isPanelOpen}
          key={DOWNLOAD_PANEL_KEY}
        >
          <List
            dataSource={exportList}
            renderItem={(item: ExportItem) => (
              <List.Item key={item.id}>
                <DownloadItem {...item} />
              </List.Item>
            )}
          />

          <span
            role="presentation"
            className="cursor-pointer flex-center-start fw-bold text-primary"
            onClick={onClickViewExportLibrary}
          >
            {i18next.t('marketingMetrics.viewExportLibrary')}
            {<RightOutlined className="ml-8" />}
          </span>
        </CollapsePanel>
      </CollapseDownloadPopup>
    )
  );
};

export default DownloadPopup;

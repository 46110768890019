import React from 'react';
import RestInputItem from 'components/RestInput/RestInputItem';

const ApplicationsForm = (props) => (
  <div {...props}>
    <RestInputItem source="amount" header="applications.amount" />
  </div>
);

export default ApplicationsForm;

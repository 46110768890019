import { Button } from 'antd';
import { omit } from 'lodash-es';
import React from 'react';
import { ArrowLeftOutlined, ArrowRightOutlined } from '@ant-design/icons';

const Arrow = ({ type, ...props }) => {
  const isShowLeft = props.currentSlide > 0;
  const isShowRight = props.currentSlide < props.slideCount;
  if (type === 'left' && !isShowLeft) return null;
  if (type !== 'left' && !isShowRight) return null;

  return (
    <Button
      {...omit(props, ['currentSlide', 'slideCount'])}
      size="small"
      shape="circle"
      className={type === 'left' ? 'arrow-left' : 'arrow-right'}
      icon={
        type === 'left' ? (
          <ArrowLeftOutlined className="text-5" />
        ) : (
          <ArrowRightOutlined className="text-5" />
        )
      }
    />
  );
};

export default Arrow;

import { createSlice } from '@reduxjs/toolkit';
import {
  getAllCustomFail,
  getAllCustomPending,
  getAllCustomSuccess,
  INITIAL_STATE_CUSTOM,
  resetResourceDataCustom,
} from 'redux/crudCreatorCustom/utils';
import { getUnownedCompaniesOfUser } from './actions';

export const initialState = {
  ...INITIAL_STATE_CUSTOM,
};

const { actions, reducer } = createSlice({
  name: 'UnownedCompaniesOfUser',
  initialState,
  reducers: {
    resetResourceFilterCompaniesOfUser: resetResourceDataCustom,
  },
  extraReducers: {
    [getUnownedCompaniesOfUser.pending]: getAllCustomPending,
    [getUnownedCompaniesOfUser.fulfilled]: getAllCustomSuccess,
    [getUnownedCompaniesOfUser.rejected]: getAllCustomFail,
  },
});

export const { resetResourceFilterCompaniesOfUser } = actions;

export default reducer;

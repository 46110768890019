import React, { useCallback } from 'react';
import i18next from 'i18next';
import PropTypes from 'prop-types';
import { useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { getAllActivities } from 'redux/applications/actions';
import { ACTIVITY_TYPES } from 'configs/localData';
import TableCustom from 'components/common/TableCustom';
import {
  getActivitiesSelector,
  getResourceDataActivitiesSelector,
} from 'redux/applications/selectors';
import { formatDateTime } from 'utils/textUtils';

const ActivityTable = ({ showActitivityDetail }) => {
  const dispatch = useDispatch();
  const activities = useSelector(getActivitiesSelector);
  const { id } = useParams();
  const [rowId, setRowId] = React.useState(null);
  const resourceFilter = useSelector(getResourceDataActivitiesSelector) || {};

  const retrieveList = React.useCallback(
    (data, isRefresh) => {
      id &&
        dispatch(
          getAllActivities({
            id,
            data: { orderBy: '-updatedAt', ...data },
            options: { isRefresh },
          }),
        );
    },
    [id, dispatch],
  );

  const onChange = useCallback(
    (e) => {
      retrieveList(
        {
          offset: (e.current - 1) * e.pageSize,
          limit: e.pageSize,
        },
        true,
      );
    },
    [retrieveList],
  );
  const handleRowClick = (rowId) => {
    showActitivityDetail(rowId);
    setRowId(rowId);
  };

  React.useEffect(() => {
    retrieveList({}, true);
  }, [id]); // eslint-disable-line

  const columns = [
    {
      title: i18next.t('activity.activitySubject'),
      dataIndex: 'subject',
      ellipsis: true,
    },
    {
      title: i18next.t('activity.type'),
      dataIndex: 'type',
      width: '25%',
      render: (text) => {
        const type = ACTIVITY_TYPES.find((element) => element.value === text);
        return (
          <span style={{ color: type.color }}>{i18next.t(type.text)}</span>
        );
      },
    },
    {
      title: i18next.t('activity.activityDate'),
      dataIndex: 'date',
      width: '25%',
      render: (text) => formatDateTime(text),
    },
  ];

  return (
    <TableCustom
      xScroll={500}
      data={activities}
      columns={columns}
      rowClassName={(record) => (record.id === rowId ? 'selected-row' : '')}
      onChange={onChange}
      onRow={(record) => ({
        onClick: () => handleRowClick(record.id),
      })}
      pagination={{
        pageSize: resourceFilter.limit,
        total: resourceFilter.total,
      }}
    />
  );
};

ActivityTable.propTypes = {
  showActitivityDetail: PropTypes.func,
};

export default ActivityTable;

import { createAsyncThunk } from '@reduxjs/toolkit';
import { apiWrapper } from 'utils/reduxUtils';
import { generateEULAApi } from 'api/legalDocuments';

export const getEULAData = createAsyncThunk(
  'legalDocuments/getEULAData',
  async (payload, thunkApi) => {
    try {
      return await apiWrapper({}, generateEULAApi);
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  },
);

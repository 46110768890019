import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { enableAllNotificationAndAlert } from 'redux/notificationsAndAlerts/actions';
import i18next from 'i18next';
import { ENABLE_TYPES_CONST } from 'configs/localData/constant';
import SwitchAction from 'components/RestActions/SwitchAction';

const { confirm } = Modal;

const SwitchAllCentre = ({ item }) => {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState();

  const centreIds = useSelector((state) => state.auth.centreIds);

  const handleEnableAll = async (checked) => {
    setLoading(true);

    return Promise.all([
      new Promise((resolve) => setTimeout(resolve, 600)),
      dispatch(
        enableAllNotificationAndAlert({
          key: item?.key,
          data: {
            centreIds,
            key: item?.key,
            status: checked
              ? ENABLE_TYPES_CONST.active.value
              : ENABLE_TYPES_CONST.inactive.value,
          },
        }),
      ),
    ]).finally(() => {
      setLoading(false);
    });
  };

  const showConfirm = (checked) => {
    confirm({
      title: i18next.t('notificationsAndAlerts.title'),
      content: i18next.t(
        checked
          ? 'notificationsAndAlerts.enableAllCentresConfirmDesc'
          : 'notificationsAndAlerts.disableAllCentresConfirmDesc',
      ),
      onOk() {
        return handleEnableAll(checked);
      },
    });
  };

  return (
    <SwitchAction
      checked={item?.data?.status === ENABLE_TYPES_CONST.active.value}
      onChange={showConfirm}
      loading={loading}
      permissionKey="myCentres"
      action="isEdit"
      switchProps={{ className: 'centre-list__enable-all' }}
    />
  );
};

SwitchAllCentre.propTypes = {
  item: PropTypes.object,
};

export default SwitchAllCentre;

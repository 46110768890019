import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  getAllJobApplicationsApi,
  getJobApplicationProfileApi,
  getJobApplicationSummaryApi,
  getJobApplicationSummaryCardApi,
  getTotalSubmittedJobApplicationsApi,
  updateJobApplicationStatusApi,
} from 'api/jobApplications';
import { apiWrapper } from 'utils/reduxUtils';

export const MODEL_NAME = 'jobApplications';

export const getAllJobApplications = createAsyncThunk(
  `${MODEL_NAME}/getAllJobApplications`,
  async (payload = {}, thunkApi) => {
    try {
      const { size, page, filter, sorts, total } =
        thunkApi.getState().jobApplications?.resourceData || {};

      const response = await apiWrapper({}, getAllJobApplicationsApi, {
        size,
        total,
        page,
        filter,
        sorts,
        ...payload.data,
      });

      return {
        data: {
          page: Math.ceil(response.total / size),
          ...response,
        },
        options: payload.options,
      };
    } catch (error) {
      return thunkApi.rejectWithValue({ id: payload.data.id, data: error });
    }
  },
);

export const getTotalSubmittedJobApplications = createAsyncThunk(
  `${MODEL_NAME}/getTotalSubmittedJobApplications`,
  async (_payload, thunkAPI) => {
    try {
      return await apiWrapper({}, getTotalSubmittedJobApplicationsApi);
    } catch (error) {
      return thunkAPI.rejectWithValue({ data: error });
    }
  },
);

export const getJobApplicationSummaryCard = createAsyncThunk(
  `${MODEL_NAME}/getJobApplicationSummaryCard`,
  async (payload = {}, thunkAPI) => {
    try {
      const response = await apiWrapper(
        {},
        getJobApplicationSummaryCardApi,
        payload,
      );
      return { data: response };
    } catch (error) {
      return thunkAPI.rejectWithValue({ data: error });
    }
  },
);

export const getJobApplicationSummary = createAsyncThunk(
  `${MODEL_NAME}/getJobApplicationSummary`,
  async (payload = {}, thunkAPI) => {
    try {
      const { id } = payload;
      return await apiWrapper({}, getJobApplicationSummaryApi, id);
    } catch (error) {
      return thunkAPI.rejectWithValue({ id: payload });
    }
  },
);

export const getJobApplicationsCardList = createAsyncThunk(
  `${MODEL_NAME}/getJobApplicationsCardList`,
  async (payload = {}, thunkApi) => {
    try {
      const { size, page, filter, sorts, total } =
        thunkApi.getState().jobApplications?.resourceData || {};

      const response = await apiWrapper({}, getAllJobApplicationsApi, {
        size,
        total,
        page,
        filter,
        sorts,
        ...payload.data,
      });

      return {
        data: response.cardList,
        options: payload.options,
      };
    } catch (error) {
      return thunkApi.rejectWithValue({ id: payload.data.id, data: error });
    }
  },
);

export const updateJobApplicationStatus = createAsyncThunk(
  `${MODEL_NAME}/updateJobApplicationStatus`,
  async (payload = {}, thunkAPI) => {
    try {
      const response = await apiWrapper(
        {
          isShowSuccessNoti: true,
        },
        updateJobApplicationStatusApi,
        payload,
      );
      if (response) {
        return response;
      }
      return thunkAPI.rejectWithValue(response);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  },
);

export const getJobApplicationProfile = createAsyncThunk(
  `${MODEL_NAME}/getJobApplicationProfile`,
  async (payload = {}, thunkAPI) => {
    try {
      const { id } = payload;
      return await apiWrapper({}, getJobApplicationProfileApi, id);
    } catch (error) {
      return thunkAPI.rejectWithValue({ id: payload });
    }
  },
);

import styled from 'styled-components';

export const Wrapper = styled.div`
  margin-right: 26px;
  display: flex;
  gap: 14px;
  margin-top: 8px;
  height: 24px;
`;

export const DisplayType = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  color: ${({ isSelected, theme }) =>
    isSelected ? theme.palette.primary : theme.color.grayNeutral400};

  p {
    margin: 0;
    margin-left: 10px;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: ${({ isSelected, theme }) =>
      isSelected ? theme.palette.primary : theme.color.grayNeutral400};
  }
`;

import { createSelector } from 'reselect';

const getResourcesData = (state) => state.resources.data;

const getResourcesLoading = (state) => state.resources.loading;

export const getResourcesDataSelector = createSelector(
  [getResourcesData],
  (data) => data,
);

export const getResourcesLoadingSelector = createSelector(
  [getResourcesLoading],
  (data) => data,
);

import PropTypes from 'prop-types';
import React from 'react';
import { Tooltip, Typography } from 'antd';
import i18next from 'i18next';

const JobApplicationValueItem = ({ icon, value, title, className }) => (
  <Tooltip title={i18next.t(title)}>
    <div className="flex items-center">
      <span className="text-green text-16">{icon}</span>
      <Typography.Text className={`${className} ml-8`}>
        {value || i18next.t('error.waitingUpdate')}
      </Typography.Text>
    </div>
  </Tooltip>
);

JobApplicationValueItem.propTypes = {
  icon: PropTypes.element,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  title: PropTypes.string,
  className: PropTypes.string,
};

export default JobApplicationValueItem;

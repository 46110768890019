import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import RequiredDays from './RequiredDays';
import BlockInfo from './BlockInfo';
import Footer from './Footer';

import { ApplicationItemWrapper, Content } from './styles';

const grid = 10;

function getStyle(provided, style) {
  if (!style) {
    return provided.draggableProps.style;
  }

  return {
    ...provided.draggableProps.style,
    ...style,
  };
}

const ApplicationItem = (props) => {
  const {
    application,
    isDragging,
    isGroupedOver,
    provided,
    style,
    isClone,
    index,
    colors,
  } = props;

  const child = application.careOption || {};

  return (
    <ApplicationItemWrapper
      grid={grid}
      isDragging={isDragging}
      isGroupedOver={isGroupedOver}
      isClone={isClone}
      colors={colors}
      ref={provided.innerRef}
      {...provided.draggableProps}
      {...provided.dragHandleProps}
      style={getStyle(provided, style)}
      data-is-dragging={isDragging}
      data-testid={application.id}
      data-index={index}
    >
      <Link to={`/applications/${application.id}/show`}>
        <Content>
          <BlockInfo application={application} />
          <RequiredDays requiredDays={child.requiredDays} />
          <Footer centreServices={child.centreServices} fee={application.fee} />
        </Content>
      </Link>
    </ApplicationItemWrapper>
  );
};

ApplicationItem.propTypes = {
  application: PropTypes.object,
  isDragging: PropTypes.bool,
  isGroupedOver: PropTypes.bool,
  provided: PropTypes.object,
  style: PropTypes.object,
  isClone: PropTypes.bool,
  index: PropTypes.number,
  colors: PropTypes.object,
};

ApplicationItem.defaultProps = {
  application: {},
  colors: {},
};

export default React.memo(ApplicationItem);

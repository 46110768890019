import React from 'react';
import PropTypes from 'prop-types';
import { getDisplayAddress } from 'utils/dataUtils';
import { EnvironmentOutlined } from '@ant-design/icons';
import { formatDistance } from 'utils/tools';
import { Tooltip } from 'antd';
import { CentreAddressDistanceStyles } from './styles';

const CentreAddressDistance = ({ isInline = true, item, isEllipsis }) => (
  <CentreAddressDistanceStyles
    className={`centre-address-distance ${
      isEllipsis ? 'centre-address-distance__ellipsis' : ''
    }`}
  >
    <EnvironmentOutlined className="environment-icon" />
    {!!item?.distance && [
      isInline ? (
        <span
          key={item.id}
          className="centre-address-distance__value center-address"
        >
          {item.distance ? formatDistance(item.distance) : '0.00'}
          km &#9679;
        </span>
      ) : (
        <span
          key={item.id}
          className="centre-address-distance__value center-address"
        >
          {item.distance ? formatDistance(item.distance) : '0.00'}
          km &#9679;
        </span>
      ),
    ]}
    {isInline ? (
      <Tooltip placement="top" title={item?.displayAddress}>
        <span key={item.id} className="centre-address-distance__address">
          {item?.displayAddress || getDisplayAddress(item)}
        </span>
      </Tooltip>
    ) : (
      <div key={item.id} className="centre-address-distance__address">
        {item?.displayAddress || getDisplayAddress(item)}
      </div>
    )}
  </CentreAddressDistanceStyles>
);

CentreAddressDistance.propTypes = {
  item: PropTypes.object,
  isEllipsis: PropTypes.bool,
  isInline: PropTypes.bool,
};

export default CentreAddressDistance;

import { Empty, Select, SelectProps, Spin } from 'antd';
import { SelectValue } from 'antd/es/select';
import { DefaultOptionType } from 'antd/lib/select';
import useInfiniteLoadQuery from 'hooks/useInfiniteLoadQuery';
import { uniqBy } from 'lodash-es';
import React from 'react';
import { convertDataToSelectOptions } from 'utils/tools';

export interface Props<Type> extends SelectProps<SelectValue> {
  apiUrl: string;
  initValues?: Type[];
  labelProp?: string;
  valueProp?: string;
  searchProp?: string;
  uniqueProp?: string;
  shouldResetListAfterSelect?: boolean;
  convertDataToOptions?: (data: Type[]) => {
    value: string | React.ReactNode;
    label: unknown;
  }[];
}

function RestInfinitySelect<Type>({
  apiUrl,
  labelProp = 'name',
  valueProp = 'id',
  uniqueProp = 'id',
  searchProp = 'q',
  initValues = [],
  shouldResetListAfterSelect,
  convertDataToOptions,
  ...rest
}: Props<Type>) {
  const {
    data,
    loading,
    loadMore,
    onSearch,
    onBlur,
    isSearching,
    retrieveListFirstTime,
  } = useInfiniteLoadQuery({
    apiUrl,
    searchProp,
  });

  const items = uniqBy([...initValues, ...data], uniqueProp);
  const formattedData = convertDataToOptions
    ? convertDataToOptions(items as Type[])
    : convertDataToSelectOptions(items, labelProp, valueProp);

  const Spinning = <Spin className="mx-12 my-4" />;

  const options = loading
    ? [
        ...formattedData,
        {
          value: null,
          label: Spinning,
        },
      ]
    : formattedData;

  const onSelect = (value: string | number, option: DefaultOptionType) => {
    if (shouldResetListAfterSelect) {
      retrieveListFirstTime();
    }

    if (rest?.onSelect) {
      rest.onSelect(value, option);
    }
  };

  const onDeselect = (value: string | number, option: DefaultOptionType) => {
    if (shouldResetListAfterSelect) {
      retrieveListFirstTime();
    }
    if (rest?.onDeselect) {
      rest.onDeselect(value, option);
    }
  };

  return (
    <Select
      showArrow
      loading={loading}
      options={options}
      optionFilterProp="label"
      onPopupScroll={loadMore}
      onSearch={onSearch}
      onSelect={onSelect}
      onDeselect={onDeselect}
      notFoundContent={loading ? Spinning : <Empty />}
      onBlur={() => {
        onBlur();
        isSearching && retrieveListFirstTime();
      }}
      {...rest}
    />
  );
}

export default RestInfinitySelect;

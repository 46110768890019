import React, { useCallback, useEffect, useRef, useState } from 'react';
import PageTitle from 'components/common/PageTitle';
import i18next from 'i18next';
import Filter from 'containers/LeadActivities/components/Filter';
import LeadActivitiesTable from 'containers/LeadActivities/components/LeadActivitiesTable';
import ContentUnavailable from 'components/common/ContentUnavailiable';
import useCheckPermissionListingPerformance from 'hooks/useCheckPermissionListingPerformance';
import ExportCsvActions from 'components/common/ExportCsvActions';
import {
  useExportLeadActivitiesMutation,
  useGetLeadActivitiesQuery,
} from 'redux/@rtkQuery/leadActivities';
import { GET_LEAD_ACTIVITIES_DETAIL_API } from 'api/leadActivities';
import { isEmpty } from 'lodash-es';
import { getFilterFromUrl, getQueryParams, getSearchUrl } from 'utils/tools';
import { useHistory, useLocation } from 'react-router-dom';
import {
  DEFAULT_CURRENT_PAGE,
  DEFAULT_LIMIT_ITEM,
} from 'configs/localData/constant';
import { LeadActivitiesPayload } from 'types/leadActivities';
import { getLeadActivitiesDefaultsFilter } from './utils';

const LeadActivities = () => {
  const { isViewLeadActivities } = useCheckPermissionListingPerformance();
  const { location, push } = useHistory();
  const { search } = useLocation();
  const { filter: query } = getFilterFromUrl(search);

  const queryParams = getQueryParams(search);

  const formatQueryParams: LeadActivitiesPayload = {
    ...queryParams,
    sorts: queryParams?.sorts,
  };

  const pushedDefaultFilter = useRef<boolean>(false);

  const pushQuery = useCallback(
    (searchStr) => {
      push(`${location.pathname}?${getSearchUrl(searchStr)}`);
    },
    [location.pathname, push],
  );

  const [payload, setPayload] = useState<LeadActivitiesPayload>({
    page: query?.page || DEFAULT_CURRENT_PAGE,
    size: query?.size || DEFAULT_LIMIT_ITEM,
    filter: query.filter,
    sorts: query?.sorts,
  });

  const { data, isLoading, isFetching } = useGetLeadActivitiesQuery(
    {
      ...payload,
      page: query?.page || DEFAULT_CURRENT_PAGE,
      size: query?.size || DEFAULT_LIMIT_ITEM,
      filter: query.filter,
    },
    {
      skip: !pushedDefaultFilter.current && isEmpty(query),
    },
  );

  useEffect(() => {
    if (isEmpty(query)) {
      const defaultFilter = getLeadActivitiesDefaultsFilter();
      pushQuery(defaultFilter);
      pushedDefaultFilter.current = true;
    }
  }, [query, pushQuery]);

  return (
    <div>
      <PageTitle
        extraAction={
          isViewLeadActivities && (
            <ExportCsvActions
              disableExportButton={isEmpty(data?.results)}
              useExportMutation={useExportLeadActivitiesMutation}
              reportDetailApi={GET_LEAD_ACTIVITIES_DETAIL_API}
              formatQueryParams={formatQueryParams}
            />
          )
        }
      >
        {i18next.t('leadActivities.title')}
      </PageTitle>
      {isViewLeadActivities ? (
        <>
          <Filter />
          <LeadActivitiesTable
            data={data}
            setPayload={setPayload}
            pushQuery={pushQuery}
            loading={isLoading || isFetching}
          />
        </>
      ) : (
        <ContentUnavailable className="mt-70" />
      )}
    </div>
  );
};

export default LeadActivities;

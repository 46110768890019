import React, { FunctionComponent } from 'react';
import { useDispatch } from 'react-redux';
import { goToNextStep, goToPrevStep } from 'redux/advertisement/slice';

export interface FooterProps {
  onBack?: () => Promise<void> | void;
  onNext?: () => Promise<void> | void;
  isNextDisabled?: boolean;
}

const withFooterFunc =
  (WrappedComponent: FunctionComponent<FooterProps>) =>
  ({ onBack, onNext, isNextDisabled }: FooterProps) => {
    const dispatch = useDispatch();

    const onBackHandler = async () => {
      onBack && (await onBack());
      dispatch(goToPrevStep());
    };

    const onNextHandler = async () => {
      if (!isNextDisabled) {
        onNext && (await onNext());
        dispatch(goToNextStep());
      }
    };

    return <WrappedComponent onBack={onBackHandler} onNext={onNextHandler} />;
  };

export default withFooterFunc;

import React from 'react';
import { Col, Row } from 'antd';
import i18next from 'i18next';
import { PUBLISH_STATUS, SHOW_HIDE_STATUS } from 'configs/localData';
import InfiniteAutoComplete from 'components/InfiniteFormItem/InfiniteAutoComplete';
import {
  useGetStaffProfileConfigsQuery,
  useGetSuggestionsQuery,
} from 'redux/@rtkQuery/educatorStaffProfile';
import { useParams } from 'react-router-dom';
import RestSelect from 'components/RestInput/RestSelect';

const StaffProfileFilter = () => {
  const { id } = useParams();
  const { data } = useGetStaffProfileConfigsQuery();

  return (
    <div>
      <Row gutter={16} className="row-filter">
        <Col xl={4} md={12} xs={12}>
          <InfiniteAutoComplete
            name="name"
            useQuery={useGetSuggestionsQuery}
            queryParams={{ centreId: id, isNameSuggestion: true }}
            placeholder={i18next.t('educatorStaffProfile.placeholder.name')}
            valueProp="name"
            searchKey="name"
            showArrow
          />
        </Col>
        <Col xl={4} md={12} xs={12}>
          <RestSelect
            source="isDraft"
            placeholder={i18next.t(
              'educatorStaffProfile.placeholder.publishStatus',
            )}
            valueProp="value"
            titleProp="label"
            resourceData={Object.values(PUBLISH_STATUS) || []}
            isShowTooltip
            autoComplete="off"
          />
        </Col>
        <Col xl={4} md={12} xs={12}>
          <InfiniteAutoComplete
            name="jobTitle"
            useQuery={useGetSuggestionsQuery}
            queryParams={{ centreId: id, isJobTitleSuggestion: true }}
            placeholder={i18next.t('educatorStaffProfile.placeholder.jobTitle')}
            valueProp="jobTitle"
            labelProp="jobTitle"
            searchKey="jobTitle"
            showArrow
          />
        </Col>

        <Col xl={4} md={12} xs={12}>
          <RestSelect
            source="jobRole"
            placeholder={i18next.t('educatorStaffProfile.placeholder.jobRole')}
            valueProp="name"
            titleProp="name"
            resourceData={data?.roles ?? []}
            isShowTooltip
            autoComplete="off"
          />
        </Col>
        <Col xl={4} md={12} xs={12}>
          <RestSelect
            source="qualification"
            placeholder={i18next.t(
              'educatorStaffProfile.placeholder.qualificationLevel',
            )}
            valueProp="name"
            titleProp="name"
            resourceData={data?.qualifications ?? []}
            isShowTooltip
            autoComplete="off"
          />
        </Col>
        <Col xl={4} md={12} xs={12}>
          <RestSelect
            source="isShowThisProfile"
            placeholder={i18next.t(
              'educatorStaffProfile.placeholder.showHideProfile',
            )}
            valueProp="value"
            titleProp="label"
            resourceData={Object.values(SHOW_HIDE_STATUS) || []}
            isShowTooltip
            autoComplete="off"
          />
        </Col>
      </Row>
    </div>
  );
};

export default StaffProfileFilter;

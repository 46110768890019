import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { clearMessages, getMessages } from 'redux/enquiries/actions';

import MailboxStyles from './styles';
import Footer from './Footer';
import Body from './Body';
import Header from './Header';

const CommentSection = () => {
  const dispatch = useDispatch();

  const currentData =
    useSelector((state) => state.applications.currentData) || {};

  const { inboxId, id } = currentData;

  const retrieveList = useCallback(
    (data, isRefresh) =>
      new Promise((resolve) => {
        inboxId &&
          resolve(
            dispatch(
              getMessages({
                data: { id: inboxId, orderBy: '-createdAt', ...data },
                options: { isRefresh },
              }),
            ),
          );
      }),
    [inboxId, dispatch],
  );

  useEffect(
    () => () => {
      dispatch(clearMessages());
    },
    [inboxId, dispatch],
  );

  return (
    <MailboxStyles className="mailbox-container">
      <div className="mailbox-wrapper">
        <Header />
        <Body retrieveList={retrieveList} />
        <Footer id={inboxId} applicationId={id} />
      </div>
    </MailboxStyles>
  );
};

export default CommentSection;

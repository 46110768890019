import React, { useCallback, useState } from 'react';
import i18next from 'i18next';

import { Button, Col, Form, Input, Row } from 'antd';
import { PaperPlanIcon } from 'components/common/SVGIcon';
import { useParams } from 'react-router';
import { useDispatch } from 'react-redux';
import { postJobApplicationMailbox } from 'redux/jobs/jobApplications/mailbox/action';
import PreviewFiles from 'containers/Enquiries/components/UploadFileMessage/PreviewFiles';
import { useFilesMessage } from 'containers/Enquiries/components/UploadFileMessage/context';
import { isEmpty } from 'lodash-es';
import { UPLOAD_FILE_STATUS } from 'configs/localData/constant';
import PropTypes from 'prop-types';
import { InputWrapperStyles } from './styles';
import UploadFileMessage from './UploadFileMessage';

const sourceFiles = 'files';

const Footer = ({ scrollToBottom }) => {
  const [form] = Form.useForm();
  const { validateFields, resetFields, setFieldsValue } = form;
  const { id } = useParams();
  const dispatch = useDispatch();
  const { clearFileList } = useFilesMessage();

  const formatListFile = useCallback(
    (files) =>
      files
        ?.filter((item) => item?.status !== UPLOAD_FILE_STATUS.error)
        ?.map((file) => {
          const { ...restFile } = file;
          return restFile;
        }),
    [],
  );

  const sendMessage = () => {
    validateFields().then(({ content, files }) => {
      if (isEmpty(content?.trim()) && isEmpty(files)) return;
      const filesFormat = formatListFile(files);
      dispatch(
        postJobApplicationMailbox({
          applicationId: id,
          content: content?.trim(),
          files: filesFormat,
        }),
      ).then(() => {
        resetFields();
        clearFileList();
        scrollToBottom();
      });
    });
  };

  const [isDisabledSubmit, setIsDisabledSubmit] = useState(false);

  return (
    <Form onFinish={sendMessage} form={form}>
      <PreviewFiles source={sourceFiles} setFieldsValue={setFieldsValue} />

      <Row gutter={12} align="middle">
        <Col>
          <Form.Item name={sourceFiles}>
            <UploadFileMessage
              form={form}
              source={sourceFiles}
              applicationId={id}
              setIsDisabledSubmit={setIsDisabledSubmit}
            />
          </Form.Item>
        </Col>
        <InputWrapperStyles flex={1}>
          <Form.Item noStyle name="content">
            <Input.TextArea
              autoSize={{ minRows: 1, maxRows: 2 }}
              className="w-full py-0"
              placeholder={i18next.t('placeholder.typeMessage')}
              bordered={false}
            />
          </Form.Item>
        </InputWrapperStyles>
        <Col>
          <Button
            disabled={isDisabledSubmit}
            htmlType="submit"
            icon={<PaperPlanIcon className="text-20 text-green" />}
          />
        </Col>
      </Row>
    </Form>
  );
};

Footer.propTypes = {
  scrollToBottom: PropTypes.func,
};

export default Footer;

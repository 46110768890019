import React from 'react';
import PropTypes from 'prop-types';
import { RightOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import i18next from 'i18next';
import { formatDateTimeWorking } from 'utils/textUtils';

const CertificationContent = ({ item }) => (
  <div className="ml-20 mb-10 text-16 text-style">
    <strong>{item?.name}</strong>
    <div className="mt-8">{item?.issuingOrganization}</div>
    <p className="d-flex align-items-center mt-10 text-gray-500">
      {item?.startDate && (
        <>
          {`${i18next.t(
            'jobApplications.generalInfo.issued',
          )} ${formatDateTimeWorking(item?.startDate)}`}
          <span className="text-gray-500 text-10 mr-10 ml-10">&#9679;</span>
          {item?.endDate
            ? `${i18next.t(
                'jobApplications.generalInfo.expiry',
              )} ${formatDateTimeWorking(item?.endDate)}`
            : `${i18next.t('jobApplications.generalInfo.noExpirationDate')}`}
        </>
      )}
    </p>

    {!!item?.issuerId && (
      <p className="mt-10 text-gray-500">
        {`${i18next.t('jobApplications.generalInfo.issuedId')} ${
          item?.issuerId
        }`}
      </p>
    )}
    {!!item?.issuerUrl && (
      <Button
        className="text-green border-green mt-13"
        href={item?.issuerUrl}
        target="_blank"
      >
        {i18next.t('jobApplications.generalInfo.viewCredential')}
        <RightOutlined className="size-10" />
      </Button>
    )}
  </div>
);

CertificationContent.propTypes = {
  item: PropTypes.object,
};

export default CertificationContent;

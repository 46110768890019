import styled from 'styled-components';

export const CustomButtonStyles = styled.div`
  .normal-action-wrapper {
    position: relative;
  }

  .action-feature-icon {
    position: absolute;
    top: -12px;
    right: -8px;
    font-size: 20px;
    color: ${({ theme }) => theme.subscriptions.colorIcon};
  }
`;

import React, { useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { getFilterFromUrl } from 'utils/tools';
import { formatFromToTimeFilterSubmit } from 'utils/dataUtils';
import {
  DEFAULT_CURRENT_PAGE,
  DEFAULT_SORT_ADVERTISEMENT,
} from 'containers/Advertisement/constants';
import { RetrieveList } from 'containers/Advertisement/List';
import { AdsFilterType, SortByKey } from 'api/advertisement/type';
import FilterCustom from 'components/common/FilterCustom';
import FilterForm from './FilterForm';

export interface PushQuery {
  page?: string | number;
  size?: string | number;
  'sorts[]'?: SortByKey[] | unknown;
  filter?: AdsFilterType;
}

interface Props {
  retrieveList?: (params: RetrieveList) => void;
  pushQuery?: (params: PushQuery) => void;
}

const Filter = ({ retrieveList, pushQuery }: Props) => {
  const { search } = useLocation();
  const { filter } = getFilterFromUrl(search);

  const onSubmitFilter = ({ createdAt, updatedAt, ...value }) => {
    const restFilter = {
      size: filter.size,
      page: DEFAULT_CURRENT_PAGE,
      sorts: filter.sorts,
      filter: {
        createdAt: formatFromToTimeFilterSubmit(createdAt),
        updatedAt: formatFromToTimeFilterSubmit(updatedAt),
        ...value,
      },
    };
    retrieveList({
      filter: { ...restFilter },
      isRefresh: true,
    });

    pushQuery({
      ...restFilter,
      'sorts[]': filter.sorts,
    });
    return null;
  };

  const onClearFilter = useCallback(() => {
    retrieveList({
      filter: {
        size: filter.size,
        page: filter.page || DEFAULT_CURRENT_PAGE,
        sorts: filter.sorts || [DEFAULT_SORT_ADVERTISEMENT],
      },
      isRefresh: true,
    });
    pushQuery({
      'sorts[]': filter.sorts,
      size: filter.size,
      page: filter.page,
    });
  }, [retrieveList, filter, pushQuery]);

  return (
    <div>
      <FilterCustom
        onSubmitFilter={onSubmitFilter}
        onClearFilter={onClearFilter}
        responsiveFilter={{
          xxl: 20,
          xl: 20,
          lg: 24,
          md: 24,
          xs: 24,
        }}
        responsiveAction={{
          xxl: 4,
          xl: 4,
          lg: 24,
          md: 24,
          xs: 24,
        }}
      >
        <FilterForm />
      </FilterCustom>
    </div>
  );
};

export default Filter;

import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { getJobTemplateConfigSelector } from 'redux/jobs/jobTemplates/selectors';
import { remove } from 'lodash-es';
import { editJobTemplate } from 'redux/jobs/jobTemplates/actions';
import TableToggle from '../../TableToggle';
import { TabContentContainerStyle } from '../styles';

const JobFeaturesTab = ({ currentData }) => {
  const dispatch = useDispatch();
  const { id } = useParams();

  const [activeFeatures, setActiveFeatures] = useState(
    currentData?.features?.map((item) => ({ id: item?.id })) || [],
  );
  const featuresList = useSelector(getJobTemplateConfigSelector)?.features;

  const featuresData = featuresList?.map((category) => ({
    ...category,
    items: [
      ...category?.items?.map((item) => ({
        ...item,
        ...currentData?.features?.find((feature) => feature?.id === item?.id),
      })),
    ],
  }));

  useEffect(() => {
    if (currentData?.features?.length > 0) {
      setActiveFeatures(
        currentData?.features?.map((item) => ({ id: item?.id })),
      );
    }
  }, [currentData.features]);

  const updateJobFeatures = useCallback(
    (features) => {
      dispatch(
        editJobTemplate({
          id,
          data: {
            features,
          },
        }),
      );
    },
    [dispatch, id],
  );

  const handleToggleOnFeature = (featureId) => {
    const newFeature = [...activeFeatures, { id: featureId }];
    setActiveFeatures(newFeature);
    updateJobFeatures(newFeature);
  };

  const handleToggleOffFeature = (featureId) => {
    const newFeature = remove([...activeFeatures], (i) => i?.id !== featureId);
    setActiveFeatures(newFeature);
    updateJobFeatures(newFeature);
  };

  const onSwitchChangeFeature = (checked, feature) => {
    checked
      ? handleToggleOnFeature(feature?.id)
      : handleToggleOffFeature(feature?.id);
  };

  return (
    <TabContentContainerStyle>
      <div className="column-items">
        {featuresData?.map((category, index) => (
          <div key={String(index)}>
            <div className="break-inside-avoid mb-24">
              <TableToggle
                dataSource={category?.items}
                onSwitchChange={onSwitchChangeFeature}
                categoryName={category?.category}
              />
            </div>
          </div>
        ))}
      </div>
    </TabContentContainerStyle>
  );
};

JobFeaturesTab.propTypes = {
  currentData: PropTypes.object,
};

export default JobFeaturesTab;

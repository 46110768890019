import theme from 'configs/theme';
import styled from 'styled-components';

export const CampaignCardStyles = styled.div`
  border-radius: 12px;
  padding: 16px;
  background-color: white;
  box-shadow: 0px 0px 1px rgba(12, 26, 75, 0.1),
    0px 4px 20px -2px rgba(50, 50, 71, 0.08);
  gap: 12px;
  cursor: pointer;
  &:hover {
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
      0 2px 4px -1px rgba(0, 0, 0, 0.06);
  }

  .row-locations-list {
    display: flex;
    align-items: stretch;
    flex-wrap: wrap;
  }

  .tag {
    background-color: ${theme.background.lightGreen};
    height: 24px;
    width: 32px;
    text-align: center;
    font-weight: 700;
    color: ${theme.palette.green};
  }

  .statistic {
    display: flex;
    gap: 4px;
    height: 54px;

    .decor-line {
      border-radius: 9999px;
      width: 4px;
      height: 100%;
      margin-right: 6px;

      &--green {
        background-color: ${theme.palette.green};
      }
      &--pink {
        background-color: ${theme.palette.pink};
      }
    }
  }
`;

import { post, put } from './utils';

export async function stripeCheckoutSessionApi(id, data) {
  return post(`/admin/CRMPackages/${id}/stripeCheckoutSessions`, data);
}

export async function callbackStripeCheckoutSessionApi(id, data) {
  return post(`/admin/CRMPackages/${id}/stripeCheckoutSessions/callback`, data);
}

export async function updateCentreSubscriptionPackageApi(id, data) {
  return put(`/admin/centreSubscriptions/CRMPackages/${id}`, data);
}

import React, { useCallback } from 'react';
import { Avatar, Progress, Tooltip, Typography } from 'antd';
import ImageField from 'components/RestField/ImageField';
import KindiRating from 'components/common/KindiRating';
import { formatMoney } from 'utils/tools';
import { OpenNewTabIcon } from 'components/common/SVGIcon';
import { getLinkCentreDetailRichweb } from 'utils/dataUtils';
import { Link } from 'react-router-dom';
import i18next from 'i18next';
import { EyeOutlined } from '@ant-design/icons';
import { CHARACTER_DASH } from 'configs/localData/constant';
import { ViewButtonStyles } from './styles';

const useFormat = () => {
  const formatBrand = useCallback(
    (data) => data && <Avatar src={data} size={48} />,
    [],
  );

  const formatCentreName = useCallback((data) => <span>{data}</span>, []);

  const formatMarketShare = useCallback(
    (data) => <Progress percent={data || 0} format={(data) => `${data}%`} />,
    [],
  );

  const formatRevenuePotential = (data) => (data ? `$${data}` : CHARACTER_DASH);

  const formatValueColumData = (data) =>
    data ? formatMoney(data) : CHARACTER_DASH;

  const formatNQSRating = useCallback(
    (data, record = {}) => (
      <ImageField
        className="h-40 w-auto"
        source="image"
        record={data || record.NQSRType}
      />
    ),
    [],
  );

  const formatKindiRating = useCallback(
    (data) => <KindiRating value={data} />,
    [],
  );

  const formatPriceAgeGroup = useCallback(
    (data, record = {}, averageAllCentre, ageGroupType) => {
      const dataOfAgeGroup = record[ageGroupType];
      const formatData = () => {
        if (dataOfAgeGroup) {
          if (ageGroupType === '0to12months') {
            return Number(record['0to12months']) >
              Number(averageAllCentre?.average0to12months)
              ? 'danger'
              : 'success';
          }
          if (ageGroupType === '13to24months') {
            return Number(record['13to24months']) >
              Number(averageAllCentre?.average13to24months)
              ? 'danger'
              : 'success';
          }
          if (ageGroupType === '25to36months') {
            return Number(record['25to36months']) >
              Number(averageAllCentre?.average25to36months)
              ? 'danger'
              : 'success';
          }
          if (ageGroupType === '36monthsplus') {
            return Number(record['36monthsplus']) >
              Number(averageAllCentre?.average36monthsplus)
              ? 'danger'
              : 'success';
          }
          if (ageGroupType === 'schoolage') {
            return Number(record?.schoolage) >
              Number(averageAllCentre?.averageSchoolage)
              ? 'danger'
              : 'success';
          }
          return Number(data) > Number(averageAllCentre?.averageAllAgeGroup)
            ? 'danger'
            : 'success';
        }
        return null;
      };

      const displayAgeGroupData = () => {
        if (data) {
          return `$${formatMoney(data, 3, true)}`;
        }
        if (dataOfAgeGroup) {
          return `$${formatMoney(dataOfAgeGroup, 3, true)}`;
        }
        return <span className="text-black">{CHARACTER_DASH}</span>;
      };

      return (
        <Typography.Text type={formatData()}>
          {displayAgeGroupData()}
        </Typography.Text>
      );
    },
    [],
  );

  const formatGroupAction = useCallback(
    (data, record) => (
      <Tooltip title={i18next.t('button.view')}>
        <ViewButtonStyles>
          {record?.isOwnedCentre ? (
            <Link to={`/centres/${data}/show/summary`}>
              <EyeOutlined />
            </Link>
          ) : (
            <a
              href={getLinkCentreDetailRichweb(record)}
              target="_blank"
              rel="noopener noreferrer"
            >
              <OpenNewTabIcon />
            </a>
          )}
        </ViewButtonStyles>
      </Tooltip>
    ),
    [],
  );

  return {
    formatBrand,
    formatCentreName,
    formatMarketShare,
    formatNQSRating,
    formatKindiRating,
    formatPriceAgeGroup,
    formatGroupAction,
    formatRevenuePotential,
    formatValueColumData,
  };
};

export default useFormat;

import React from 'react';
import PropTypes from 'prop-types';
import { Switch } from 'antd';
import { CrownFilled } from '@ant-design/icons';

import { upgradeModal } from 'components/common/CheckPermissions/UpgradeSubscriptionModal';

const SwitchFallback = ({ checked, switchProps }) => {
  const onClickButton = () => {
    upgradeModal.open();
  };

  return (
    <div className="switch-action-wrapper">
      <Switch checked={checked} onClick={onClickButton} {...switchProps} />
      <CrownFilled className="action-feature-icon" />
    </div>
  );
};

SwitchFallback.propTypes = {
  checked: PropTypes.bool,
  switchProps: PropTypes.object,
};

export default SwitchFallback;

import React from 'react';
import PropTypes from 'prop-types';
import { Form, Button, InputNumber, Switch } from 'antd';
import i18next from 'i18next';
import { useDispatch, useSelector } from 'react-redux';
import CRUDActions from 'redux/crudActions';
import { SERVICE_AGE_GROUPS } from 'configs/localData';

import { RestInputContext } from 'components/RestInput/RestInputContext';
import RestSelect from 'components/RestInput/RestSelect';
import RestInputItem from 'components/RestInput/RestInputItem';
import FormServiceStyles from './styles';

const FormService = ({ record, onCancel }) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const serviceTypes = useSelector((state) => state.config.data?.ServiceType);

  const handleSubmit = () => {
    form
      .validateFields()
      .then(({ serviceId, ...values }) => {
        // Do something with value
        dispatch(
          CRUDActions.centres.edit({
            data: {
              id: record.id,
              serviceId,
              serviceName: serviceTypes?.find((item) => item.id === serviceId)
                ?.name,
              ...values,
            },
          }),
        );
        onCancel();
      })
      .catch(() => {});
  };

  return (
    <FormServiceStyles>
      <Form form={form} layout="vertical">
        <RestInputContext.Provider value={{ record: record || {}, form }}>
          <RestSelect
            required
            header="centres.service"
            placeholder="centres.service"
            source="serviceId"
            resourceData={serviceTypes || []}
            valueProp="id"
            titleProp="name"
          />
          <RestSelect
            header="centres.ageGroup"
            placeholder="centres.ageGroup"
            source="serviceAgeGroup"
            resourceData={SERVICE_AGE_GROUPS || []}
            valueProp="value"
            titleProp="text"
            formatText={(data) => i18next.t(data)}
          />
          <RestInputItem
            header="centres.costPerDay"
            placeholder="centres.costPerDay"
            source="costPerDay"
            ContentComponent={InputNumber}
            min={0}
            ruleType="number"
          />
          <RestInputItem
            header="centres.vacancy"
            placeholder="centres.vacancy"
            source="vacancy_cod"
            ContentComponent={Switch}
            valuePropName="checked"
            ruleType="boolean"
          />
        </RestInputContext.Provider>
      </Form>
      <div className="div-button-group">
        <Button onClick={onCancel}>{i18next.t('button.cancel')}</Button>
        <Button className="submit-button" type="primary" onClick={handleSubmit}>
          {i18next.t('button.save')}
        </Button>
      </div>
    </FormServiceStyles>
  );
};

FormService.propTypes = {
  record: PropTypes.object,
  onCancel: PropTypes.func,
};

export default FormService;

import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  enableNotificationAndAlertForCentresApi,
  getByIdNotificationAlertApi,
} from 'api/centreAppSettings';
import { makeActions } from 'redux/crudCreator/actions';
import { apiWrapper } from 'utils/reduxUtils';

export const MODEL_NAME = 'notificationsAndAlerts';
export const notificationsAndAlertsActions = makeActions(MODEL_NAME);

export const getAllNotificationsAndAlerts =
  notificationsAndAlertsActions.getAll;

export const getByIdNotificationAlert = createAsyncThunk(
  'notificationsAndAlerts/getByIdNotificationAlert',
  async (payload, thunkAPI) => {
    try {
      const response = await apiWrapper(
        {},
        getByIdNotificationAlertApi,
        payload,
      );
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  },
);

export const enableOneNotificationAndAlert = createAsyncThunk(
  'notificationsAndAlerts/enableOneNotificationAndAlert',
  async (payload = {}, thunkAPI) => {
    try {
      const response = await apiWrapper(
        { isShowSuccessNoti: true },
        enableNotificationAndAlertForCentresApi,
        payload.data,
      );

      return { success: true, data: response };
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  },
);

export const enableOneCentreWhenMultiCentreNotificationAndAlert =
  createAsyncThunk(
    'notificationsAndAlerts/enableOneCentreWhenMultiCentreNotificationAndAlert',
    async (payload = {}, thunkAPI) => {
      try {
        const response = await apiWrapper(
          { isShowSuccessNoti: true },
          enableNotificationAndAlertForCentresApi,
          payload.data,
        );

        return { success: true, data: response };
      } catch (error) {
        return thunkAPI.rejectWithValue(error);
      }
    },
  );

export const enableAllNotificationAndAlert = createAsyncThunk(
  'notificationsAndAlerts/enableAllNotificationAndAlert',
  async (payload = {}, thunkAPI) => {
    try {
      const response = await apiWrapper(
        { isShowSuccessNoti: true },
        enableNotificationAndAlertForCentresApi,
        payload.data,
      );
      return { success: true, data: response };
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  },
);

import React from 'react';
import { Form, FormInstance, Input } from 'antd';
import i18next from 'i18next';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { setStaffProfilePayload } from 'redux/staffProfile/slice';

const { TextArea } = Input;
const { Item } = Form;

interface AboutStaffFormProps {
  form: FormInstance;
  initialValues?: string;
}

const AboutStaffForm = ({ form, initialValues = '' }: AboutStaffFormProps) => {
  const dispatch = useDispatch();
  const onFinish = (formValues) => {
    dispatch(setStaffProfilePayload({ about: formValues.about }));
  };
  return (
    <Form
      form={form}
      layout="vertical"
      initialValues={{ about: initialValues }}
      onFinish={onFinish}
    >
      <ItemStyled
        name="about"
        label={i18next.t('educatorStaffProfile.aboutForm.description')}
      >
        <TextAreaStyled
          autoComplete="off"
          rows={10}
          allowClear
          maxLength={2000}
          placeholder={i18next.t('educatorStaffProfile.aboutForm.placeholder')}
          showCount={{
            formatter: ({ count, maxLength }) =>
              i18next.t('educatorStaffProfile.aboutForm.charactersCount', {
                count,
                maxLength,
              }),
          }}
        />
      </ItemStyled>
    </Form>
  );
};

const TextAreaStyled = styled(TextArea)`
  textarea.ant-input {
    resize: none;
  }

  .ant-input-affix-wrapper .ant-input {
    border: 1px solid #eaecf7;
  }
`;

const ItemStyled = styled(Item)`
  .ant-form-item-label > label {
    color: #000000;
    font-weight: bold;
  }
`;

export default AboutStaffForm;

import React from 'react';
import PropTypes from 'prop-types';
import i18next from 'i18next';
import { Divider, Row, Col, Tooltip, Typography } from 'antd';
import { formatMoneyValue } from 'utils/tools';
import { CHARACTER_DASH } from 'configs/localData/constant';

const SummaryBenchmarkingCard = ({
  IconComponent,
  yourTotalTitle,
  yourTotalValue,
  allTotalTitle,
  allTotalValue,
  percentShareValue,
  isNoUserCentre,
  isMoney,
}) => (
  <div className="p-16 bg-white rounded-16">
    <Row gutter={[24, 24]}>
      <Col xxl={4} xl={6} sm={4}>
        <IconComponent />
      </Col>
      <Col xxl={20} xl={18} sm={20}>
        <div>
          <Tooltip
            placement="top"
            title={i18next.t(yourTotalTitle)}
            className="ellipsis-t fw-bold text-14 d-block"
          >
            {i18next.t(yourTotalTitle)}
          </Tooltip>
          <div>
            {isNoUserCentre ? (
              <div className="fw-bold text-20">{CHARACTER_DASH}</div>
            ) : (
              <div className="flex-center-start">
                <Tooltip
                  placement="top"
                  title={
                    isMoney
                      ? `$${formatMoneyValue(yourTotalValue, 3, true) || ''}`
                      : formatMoneyValue(yourTotalValue)
                  }
                  className="ellipsis-t fw-600"
                >
                  <div className="ellipsis-t fw-bold text-20 pr-10">
                    {isMoney
                      ? `$${formatMoneyValue(yourTotalValue, 3, true) || ''}`
                      : formatMoneyValue(yourTotalValue)}
                  </div>
                </Tooltip>
                {percentShareValue && (
                  <Tooltip
                    placement="top"
                    title={`${percentShareValue}${i18next.t(
                      'priceBenchmarking.summaries.share',
                    )}`}
                    className="ellipsis-t fw-600"
                  >
                    <Typography.Text className="text-secondary-blue">
                      {Number(allTotalValue)
                        ? `${percentShareValue}${i18next.t(
                            'priceBenchmarking.summaries.share',
                          )}`
                        : ''}
                    </Typography.Text>
                  </Tooltip>
                )}
              </div>
            )}
          </div>
          <Divider className="p-0 m-0 mt-10" />
        </div>
        <div className="mt-10">
          <Tooltip placement="top" title={i18next.t(allTotalTitle)}>
            <div className="ellipsis-t fw-bold text-14">
              {i18next.t(allTotalTitle)}
            </div>
          </Tooltip>
          <Tooltip
            placement="topLeft"
            title={
              isMoney
                ? `$${formatMoneyValue(allTotalValue, 3, true) || ''}`
                : formatMoneyValue(allTotalValue)
            }
            className="ellipsis-t fw-600"
          >
            <div className="ellipsis-t fw-bold text-20">
              {isMoney
                ? `$${formatMoneyValue(allTotalValue, 3, true)}`
                : formatMoneyValue(allTotalValue)}
            </div>
          </Tooltip>
        </div>
      </Col>
    </Row>
  </div>
);

SummaryBenchmarkingCard.propTypes = {
  IconComponent: PropTypes.any,
  isMoney: PropTypes.bool,
  yourTotalTitle: PropTypes.string,
  yourTotalValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  allTotalTitle: PropTypes.string,
  allTotalValue: PropTypes.string,
  percentShareValue: PropTypes.string,
  isNoUserCentre: PropTypes.bool,
};

export default SummaryBenchmarkingCard;

import { Col, Row, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { PRICE_DETAIL_BLOCK } from 'data/SubscriptionPricing/priceBlockDetail';
import { RightArrowIcon } from 'components/common/SVGIcon';
import React from 'react';
import { SubscriptionsListStyled } from './styles';
import PriceBlock from './components/PriceBlock';

const { Title } = Typography;

const SubscriptionsList = ({ onClickSignup, subscriptionData }) => {
  const { t } = useTranslation();
  return (
    <SubscriptionsListStyled>
      <div className="main-title">
        <Title className="text-center">
          {t('subscriptionPricing.subscriptionList.title')}
        </Title>
      </div>
      <div className="price-block-list">
        <Row gutter={[18, 18]} wrap>
          {PRICE_DETAIL_BLOCK.map((priceDetail, index) => (
            <Col xs={24} sm={24} md={12} lg={6} xl={6} xxl={6} key={index}>
              <PriceBlock
                priceDetail={priceDetail}
                subscriptionItem={subscriptionData[index]}
                onClickSignup={onClickSignup}
              />
            </Col>
          ))}
        </Row>
      </div>
      <div className="see-how-btn">
        <a
          target="_blank"
          href={`${process.env.REACT_APP_RW_URL}/subscription-pricing`}
          rel="noopener noreferrer"
        >
          {t(
            'subscriptionPricing.subscriptionList.seeHowOurSubscriptionsCompare',
          )}
          {'   '}
          <RightArrowIcon />
        </a>
      </div>
    </SubscriptionsListStyled>
  );
};

export default SubscriptionsList;

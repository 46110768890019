import styled from 'styled-components';

export const RecommendsTagStyled = styled.div`
  background: #db147f;
  color: white;
  font-weight: bold;
  padding: 5px 10px;
  border-radius: 0 0 12px 12px;
  position: absolute;
  top: 0;
  left: 50%;
  width: 100%;
  transform: translate(-50%, 0);

  .anticon {
    margin-right: 5px;
  }

  @media only screen and (max-width: 390px) {
    max-width: 150px;
  }

  @media only screen and (max-width: 1199px) {
    max-width: 200px;
    font-size: 13px;
  }

  @media only screen and (min-width: 1200px) {
    font-size: 14px;
    max-width: 200px;
  }
`;

import { Col, Row, Spin } from 'antd';
import React, { useEffect, useCallback, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { Waypoint } from 'react-waypoint';
import { getJobApplicationMailbox } from 'redux/jobs/jobApplications/mailbox/action';
import {
  getDataJobApplicationMailbox,
  getDataJobApplicationMailboxPaging,
  getLoadingJobApplicationMailbox,
} from 'redux/jobs/jobApplications/mailbox/selector';
import i18next from 'i18next';
import { RefreshIcon } from 'components/common/SVGIcon';
import { FilesMessageProvider } from 'containers/Enquiries/components/UploadFileMessage/context';
import Footer from './Footer';
import MessageItem from './MessageItem';

import { MailboxTabStyles } from './styles';

const MailboxTab = () => {
  const dispatch = useDispatch();
  const endListRef = useRef();

  const messages = useSelector(getDataJobApplicationMailbox);
  const paging = useSelector(getDataJobApplicationMailboxPaging);
  const loading = useSelector(getLoadingJobApplicationMailbox);

  const { id } = useParams();

  const refetchMessages = useCallback(
    ({ applicationId = id, size = 10, page = 1 }) => {
      dispatch(getJobApplicationMailbox({ applicationId, size, page }));
    },
    [dispatch, id],
  );

  useEffect(() => {
    refetchMessages({ applicationId: id });
  }, [refetchMessages, id]);

  const scrollToBottom = () => {
    endListRef.current && endListRef.current.scrollTo(0, 0);
  };

  return (
    <MailboxTabStyles className="bg-white rounded-16">
      <div className="mailbox-tab__enquiries p-24" ref={endListRef}>
        <Row
          onClick={refetchMessages}
          gutter={6}
          justify="center"
          align="middle"
          className="text-green fw-600 cursor-pointer"
        >
          <Col className="flex-center">
            <RefreshIcon />
          </Col>
          <Col>{i18next.t('enquiries.reloadNewMessage')}</Col>
        </Row>

        {messages?.map((e) => (
          <MessageItem
            key={e.id}
            note={
              e.isSystem
                ? `${i18next.t('jobApplications.messageFromSystem')}:`
                : undefined
            }
            isSender={!e.seekerId}
            content={e.message}
            files={e.file}
            avatar={e.seekerAvatar}
            {...e}
          />
        ))}
        <Waypoint
          onEnter={() => {
            if (messages?.length !== 0) {
              refetchMessages({
                applicationId: id,
                size: paging?.size,
                page: paging?.page + 1,
              });
            }
          }}
        />
        {loading && <Spin />}
      </div>
      <div className="mailbox-tab__input-area">
        <FilesMessageProvider>
          <Footer scrollToBottom={scrollToBottom} />
        </FilesMessageProvider>
      </div>
    </MailboxTabStyles>
  );
};

export default MailboxTab;

import React, {
  forwardRef,
  useImperativeHandle,
  useState,
  useRef,
} from 'react';
import PropTypes from 'prop-types';
import i18next from 'i18next';
import Button from 'antd/lib/button';
import { EyeOutlined } from '@ant-design/icons';

import RestEditor from 'components/RestInput/RestEditor';

import { getPrefixKeyUploadCentre } from 'utils/fileUtils';
import CentreModal from '.';
import { MoreInfoCentreModalStyles } from './styles';
import MobilePreviewModal from './MobilePreviewModal';

const MoreCentreInfoModal = forwardRef(
  ({ openMobilePreview, moreInformation, centreId, record }, ref) => {
    const [visible, setVisible] = useState(false);
    const editorRef = useRef();

    useImperativeHandle(
      ref,
      () => ({
        toggleModal: () => {
          setVisible(true);
        },
      }),
      [],
    );

    const onCancel = () => {
      setVisible(false);
    };

    const handlePreview = () => {
      editorRef?.current && openMobilePreview(editorRef.current.currentContent);
    };

    const previewModalRef = useRef();

    const openMobilePreviewModalItem = () => {
      previewModalRef?.current &&
        previewModalRef.current.open(editorRef.current.currentContent);
    };

    return (
      <CentreModal
        title="centreDetail.titleMoreCentreInfoModal"
        visible={visible}
        onCancel={onCancel}
        width={1000}
      >
        <MoreInfoCentreModalStyles>
          <Button
            icon={<EyeOutlined />}
            className="mobile-view-mode-btn btn-second-primary"
            onClick={record ? openMobilePreviewModalItem : handlePreview}
          >
            {i18next.t('button.mobilePreview')}
          </Button>
          <RestEditor
            defaultValue={moreInformation || record?.moreInformation}
            editorRef={editorRef}
            source="moreInformation"
            header="centreDetail.moreCentreInformation"
            height={600}
            prefixKey={getPrefixKeyUploadCentre(centreId)}
          />
        </MoreInfoCentreModalStyles>
        {record && <MobilePreviewModal ref={previewModalRef} />}
      </CentreModal>
    );
  },
);

MoreCentreInfoModal.propTypes = {
  openMobilePreview: PropTypes.func,
  moreInformation: PropTypes.string,
  centreId: PropTypes.string,
  record: PropTypes.object,
};

MoreCentreInfoModal.defaultProps = {};

export default MoreCentreInfoModal;

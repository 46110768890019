import React from 'react';
import PropTypes from 'prop-types';
import RestInputItem from 'components/RestInput/RestInputItem';
import { Col, Row } from 'antd';
import RestSelect from 'components/RestInput/RestSelect';
import i18next from 'i18next';
import { ENABLE_TYPES } from 'configs/localData/constant';

const Filter = ({ centreId }) => (
  // const centres = useSelector((state) => state.auth.centres);

  <div>
    <Row gutter={16}>
      <Col lg={12} md={12} xs={12}>
        <RestInputItem
          source={['description', '$ilike']}
          placeholder="notificationsAndAlerts.notifications"
          isShowTooltip
        />
      </Col>
      <Col lg={12} md={12} xs={12}>
        <RestSelect
          source="status"
          placeholder={
            centreId
              ? 'common.isActive'
              : 'notificationsAndAlerts.enableAllCentres'
          }
          resourceData={ENABLE_TYPES}
          valueProp="value"
          titleProp="text"
          formatText={(text) => i18next.t(text)}
          isShowTooltip
        />
      </Col>
    </Row>
  </div>
);
Filter.propTypes = {
  centreId: PropTypes.string,
};

export default Filter;

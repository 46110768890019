import React from 'react';
import {
  Avatar,
  Col,
  Divider,
  Modal,
  Row,
  Space,
  Tooltip,
  Typography,
} from 'antd';
import {
  ClockIcon,
  CopyFillIcon,
  DollarSquareIcon,
  ErrorIcon,
  TeacherIcon,
} from 'components/common/SVGIcon';
import i18next from 'i18next';
import {
  ApplicationItemWrapper,
  Content,
} from 'containers/Applications/components/Primatives/ApplicationItem/styles';
import {
  CalendarOutlined,
  EnvironmentOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { formatDateApplication } from 'utils/textUtils';
import { JOB_APPLICATION_STATUS_CONSTANT } from 'configs/localData';
import CompanyDefaultLogo from 'assets/icons/company-default-logo.svg';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { JobApplicationsItem } from 'types/jobApplications';
import RequiredDays from './RequiredDays';
import JobMatchProgressCircle from '../../JobMatchProgressCircle';
import JobApplicationValueItem from './JobApplicationValueItem';
import { StraightBarStyle } from '../styles';

const grid = 10;

function getStyle(provided, style) {
  if (!style) {
    return provided.draggableProps.style;
  }

  return {
    ...provided.draggableProps.style,
    ...style,
  };
}

interface JobApplicationItemProps {
  application: JobApplicationsItem;
  isDragging?: boolean;
  isGroupedOver?: boolean;
  provided?: any;
  style?: object;
  isClone?: boolean;
  index?: number;
  colors?: object;
}

const JobApplicationItem = (props: JobApplicationItemProps) => {
  const {
    application,
    isDragging,
    isGroupedOver,
    provided,
    style,
    isClone,
    index,
    colors,
  } = props;

  const applicantName = `${application?.seeker?.firstname || ''} ${
    application?.seeker?.lastname || ''
  }`;

  const { t } = useTranslation();
  const { push } = useHistory();

  const redirectToJobApplicationDetail = () => {
    if (application?.isDeleted) {
      Modal.error({
        title: (
          <div className="fw-bold">
            {t('jobApplications.applicationNoExists.title')}
          </div>
        ),
        content: t('jobApplications.applicationNoExists.description'),
        icon: <ErrorIcon />,
        okText: t('button.gotIt'),
        centered: true,
      });
    } else {
      push(`/job-applications/${application?.id}/show/summary`);
    }
  };

  return (
    <ApplicationItemWrapper
      grid={grid}
      isDragging={isDragging}
      isGroupedOver={isGroupedOver}
      isClone={isClone}
      colors={colors}
      ref={provided.innerRef}
      {...provided.draggableProps}
      {...provided.dragHandleProps}
      style={getStyle(provided, style)}
      data-is-dragging={isDragging}
      data-testid={application.id}
      data-index={index}
    >
      <div onClick={redirectToJobApplicationDetail} role="presentation">
        <Content>
          <Row align="middle" justify="space-between">
            <Col span={18}>
              <Row align="middle">
                <Col span={4}>
                  <Avatar
                    src={application?.seeker?.avatar}
                    icon={<UserOutlined />}
                  />
                </Col>
                <Col span={20} className="pl-10 ellipsis-t-2">
                  <Tooltip placement="top" title={applicantName}>
                    <div className="ellipsis-t fw-bold">{applicantName}</div>
                  </Tooltip>
                  {application?.seeker?.pronouns && (
                    <span>{`(${application?.seeker?.pronouns})`}</span>
                  )}
                  <span />
                </Col>
              </Row>
            </Col>
            <Col span={4}>
              <JobMatchProgressCircle
                jobMatchScore={Number(application?.post?.jobMatchScore) || 0}
              />
            </Col>
          </Row>
          <div className="leading-loose mt-12">
            {application?.appliedTime && (
              <JobApplicationValueItem
                title="applications.appliedTime"
                icon={<ClockIcon />}
                value={`${i18next.t(
                  'applications.applied',
                )} ${formatDateApplication(application?.appliedTime)}`}
                className="text-14"
              />
            )}
            {application?.qualification?.name && (
              <JobApplicationValueItem
                title="jobApplications.highestQualification"
                icon={<TeacherIcon />}
                value={application?.qualification?.name}
                className="text-14"
              />
            )}
            {application?.distance && (
              <JobApplicationValueItem
                title="jobApplications.distanceToWorkplace"
                icon={<EnvironmentOutlined />}
                value={`${application?.distance} km`}
                className="text-14 fw-500 text-dark-green"
              />
            )}
            {(application?.availableStart?.isAvailableStart ||
              application?.availableStart?.specificStartDate) && (
              <JobApplicationValueItem
                title="jobApplications.availability"
                icon={<CalendarOutlined />}
                value={
                  application?.availableStart?.isAvailableStart
                    ? i18next.t('jobListings.immediateStart')
                    : formatDateApplication(
                        application?.availableStart?.specificStartDate,
                      )
                }
                className="text-14"
              />
            )}
          </div>
          {!!application?.availableDays?.length && (
            <div className="mt-12">
              <RequiredDays requiredDays={application?.availableDays} />
            </div>
          )}

          <Divider />
          <Row justify="space-between">
            <Col span={16}>
              <Space>
                <StraightBarStyle />
                <strong className="text-14 ellipsis-t-2">
                  {application?.post?.title}
                </strong>
              </Space>
            </Col>
            {application?.status?.name !==
              JOB_APPLICATION_STATUS_CONSTANT.cancelled.value && (
              <Col span={8}>
                <Typography.Paragraph
                  copyable={{
                    icon: [<CopyFillIcon />],
                    tooltips: [
                      i18next.t('jobApplications.copyJobReference'),
                      i18next.t('jobApplications.copiedJobReference'),
                    ],
                    text: application?.post?.jobReference,
                  }}
                >
                  <span className="text-gray-neutral-500 text-decoration-line fw-600 text-12">
                    {application?.post?.jobReference ||
                      i18next.t('error.waitingUpdate')}
                  </span>
                </Typography.Paragraph>
              </Col>
            )}
          </Row>
          <div className="leading-loose ">
            <JobApplicationValueItem
              title="jobApplications.workplace"
              icon={
                <Avatar
                  src={application?.workplace?.logo || CompanyDefaultLogo}
                  size={15}
                />
              }
              value={application?.workplace?.name}
              className="text-14"
            />
            <JobApplicationValueItem
              title="jobApplications.salary"
              icon={<DollarSquareIcon />}
              value={application?.salary?.displayText}
              className="text-12 fw-bold text-dark-green"
            />
          </div>
        </Content>
      </div>
    </ApplicationItemWrapper>
  );
};

export default React.memo(JobApplicationItem);

import styled from 'styled-components';

const ArchiveModalStyles = styled.div`
  .archive-desc,
  .ant-radio-wrapper {
    font-size: 16px;
  }
`;

export default ArchiveModalStyles;

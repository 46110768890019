import styled from 'styled-components';

const TableViewStyles = styled.div`
  font-size: 16px;
  .total-Wrapper {
    margin-bottom: 20px;
    background-color: white;
    padding: 10px 20px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    .anticon {
      cursor: pointer;
    }
  }
  .total__number {
    font-weight: 700;
    color: ${({ theme }) => theme.palette.primary};
  }
  .ant-table-tbody > tr.ant-table-row-selected > td {
    background-color: white;
  }
  .anticon-exclamation-circle {
    height: 100px !important;
  }
  .link-class {
    &:hover,
    &:active {
      color: ${({ theme }) => theme.palette.primary} !important;
    }
  }

  .ant-checkbox-input + .ant-checkbox-inner {
    border-color: ${({ theme }) => theme.border.gray};
  }
  .ant-checkbox-input:hover + .ant-checkbox-inner {
    border-color: ${({ theme }) => theme.palette.primary};
  }
`;

export default TableViewStyles;

import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'antd';
import i18next from 'i18next';

import CheckPermission from 'components/common/CheckPermissions';
import { ReplyIcon } from 'components/common/SVGIcon';
import FallbackBtn from './FallbackBtn';
import { ReplyActionStyles } from './styles';

const ReplyAction = ({ onVisibleReply, record }) => (
  <ReplyActionStyles>
    <CheckPermission
      packageName={record?.CRMPackage?.name}
      record={record}
      permissionKey="replyReviews"
      action="isEdit"
      isDisabled
      fallback={<FallbackBtn />}
    >
      <Button
        className="reply-button"
        icon={<ReplyIcon />}
        onClick={onVisibleReply}
      >
        {i18next.t('button.reply')}
      </Button>
    </CheckPermission>
  </ReplyActionStyles>
);

ReplyAction.propTypes = {
  onVisibleReply: PropTypes.func,
  record: PropTypes.object,
};

ReplyAction.defaultProps = {
  onVisibleReply: () => null,
};

export default ReplyAction;

import { useSelector } from 'react-redux';
import { getCentresObjKeyById } from 'redux/auth/selectors';

const useCRMPackageOfCentre = (centreId) => {
  const centresObjKeyById = useSelector(getCentresObjKeyById);

  const CRMPackage =
    centresObjKeyById?.[centreId]?.centreSubscription?.CRMPackage;

  return CRMPackage || {};
};

export default useCRMPackageOfCentre;

import React, { useEffect, useContext } from 'react';
import { Col, Row } from 'antd';
import RestSelect from 'components/RestInput/RestSelect';
import { RestInputContext } from 'components/RestInput/RestInputContext';
import { useLocation } from 'react-router';
import { getFilterFromUrl } from 'utils/tools';
import { JOB_LISTING_STATUS } from 'containers/JobListings/constants';
import { useSelector } from 'react-redux';
import { getJobRoles } from 'redux/config/selectors';
import i18next from 'i18next';

const FilterForm = () => {
  const { form } = useContext(RestInputContext);
  const { search } = useLocation();
  const jobRoles = useSelector(getJobRoles);
  useEffect(() => {
    const { filter } = getFilterFromUrl(search) || {};
    const restFilter = filter?.filter || {};
    form.setFieldsValue({
      ...restFilter,
    });
  }, [search, form]);

  return (
    <Row gutter={16} type="flex" className="min-w-700">
      <Col xs={12}>
        <RestSelect
          source="jobRoleId"
          placeholder="jobListings.jobRole"
          resourceData={jobRoles}
          valueProp="id"
          titleProp="name"
          isShowTooltip
        />
      </Col>
      <Col xs={12}>
        <RestSelect
          source="jobListingStatus"
          placeholder="jobListings.jobListingStatus"
          resourceData={JOB_LISTING_STATUS}
          formatText={(data) => i18next.t(data)}
          valueProp="value"
          titleProp="text"
          isShowTooltip
        />
      </Col>
    </Row>
  );
};

export default FilterForm;

import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { unownedCentresOfUserSelectors } from 'redux/unownedCentresOfUser/selectors';

import ListCheckboxSelect from '../Form/ListCheckboxSelect';

const CentresAddMoreSelect = ({ retrieveList }) => {
  const data = useSelector(unownedCentresOfUserSelectors.getDataArr);
  const paging = useSelector(unownedCentresOfUserSelectors.getResourceData);
  const loading = useSelector(unownedCentresOfUserSelectors.getLoading);

  return (
    <ListCheckboxSelect
      retrieveList={retrieveList}
      name="centreIds"
      data={data}
      paging={paging}
      loading={loading}
      selectedResourceTitles="users.selectedCentresTotal"
      selectedResourceTitle="users.selectedCentreTotal"
    />
  );
};

CentresAddMoreSelect.propTypes = {
  retrieveList: PropTypes.func,
};

export default CentresAddMoreSelect;

import React from 'react';
import { useHistory } from 'react-router';
import EditButton from 'components/RestActions/EditButton';
import { Link } from 'react-router-dom';
import { Tag, Tooltip } from 'antd';
import { BriefcaseIcon } from 'components/common/SVGIcon';
import { JOB_TEMPLATE_STATUS } from 'configs/localData';
import TagCustom from 'components/common/TagCustom';
import { isEmpty } from 'lodash-es';

const useFormat = () => {
  const { push } = useHistory();

  const formatJobTemplateName = (data, record) => (
    <Link to={`/jobTemplates/${record?.id}/show/generalInformation`}>
      <span className="t-bold black ellipsis-t-2 link-class">{data}</span>
    </Link>
  );

  const formatJobType = (data) =>
    !isEmpty(data) && (
      <Tag icon={<BriefcaseIcon />} className="text-14 fw-600">
        {data?.name}
      </Tag>
    );

  const formatJobTemplateStatus = (data) => {
    const item = JOB_TEMPLATE_STATUS.find((item) => item.value === data);
    if (!item) return data;
    return (
      <TagCustom
        className="fw-bold"
        color={item.color}
        backgroundColor={item.backgroundColor}
        text={item.text}
      />
    );
  };

  const formatDiscount = (data) =>
    data && (
      <Tooltip title={data?.type?.name || ''}>
        <div className="flex items-center">
          {data?.type?.iconUrl && (
            <img
              src={data?.type?.iconUrl}
              alt=""
              width={18}
              height={18}
              className="mr-10"
            />
          )}
          <span>{data?.value || ''}</span>
        </div>
      </Tooltip>
    );

  const formatGroupAction = (id) => {
    const goToJobTemplateDetail = () => {
      push(`/jobTemplates/${id}/show/generalInformation`);
    };
    return (
      <div className="flex-center-between">
        <EditButton
          isView
          title="jobs.jobTemplates.viewTemplate"
          source="show"
          onClickCustom={goToJobTemplateDetail}
        />
      </div>
    );
  };

  return {
    formatJobTemplateName,
    formatJobType,
    formatJobTemplateStatus,
    formatDiscount,
    formatGroupAction,
  };
};

export default useFormat;

import React, { useState, forwardRef, useImperativeHandle } from 'react';
import i18next from 'i18next';
import { Form, Button, Space, Radio } from 'antd';
import ModalCustom from 'components/common/ModalCustom';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { getApplicationDeclinedReasons } from 'redux/config/selectors';
import FormReasonDelete from './styles';

const DeclineReasonModal = forwardRef(
  ({ updateStatus, changeStatusValues }, ref) => {
    const [form] = Form.useForm();
    const [visible, setVisible] = useState(false);

    const applicationDeclinedReasons = useSelector(
      getApplicationDeclinedReasons,
    );

    useImperativeHandle(
      ref,
      () => ({
        open: () => {
          setVisible(true);
        },
      }),
      [],
    );

    const onCancel = () => {
      setVisible(false);
      form.resetFields();
    };

    const handleDecline = () => {
      form
        .validateFields()
        .then((value) => {
          updateStatus({
            applicationId: changeStatusValues?.applicationId,
            status: changeStatusValues?.status,
            declinedReasonId: value?.declinedReasonId,
          });
        })
        .then(() => {
          onCancel();
        });
    };

    return (
      <ModalCustom
        width={516}
        height={600}
        visible={visible}
        title={i18next.t(
          'jobApplications.generalInfo.declineApplications.title',
        )}
        onCancel={onCancel}
        footer={null}
        resetPaddingBottomBody={false}
      >
        <FormReasonDelete form={form} onFinish={handleDecline}>
          <div className="delete-desc text-secondary mb-20">
            {i18next.t(
              'jobApplications.generalInfo.declineApplications.message',
            )}
          </div>
          <Form.Item
            name="declinedReasonId"
            rules={[
              {
                required: true,
                message: i18next.t(
                  'jobApplications.generalInfo.declineApplications.validatedMsg',
                ),
              },
            ]}
          >
            <Radio.Group>
              <Space direction="vertical" size={10}>
                {applicationDeclinedReasons?.map((item) => (
                  <Radio key={item.id} value={item.id}>
                    {i18next.t(item.reason)}
                  </Radio>
                ))}
              </Space>
            </Radio.Group>
          </Form.Item>
          <Form.Item>
            <Button
              onClick={onCancel}
              type="primary w-full mt-20 fw-600"
              className="mr-6"
              htmlType="button"
              ghost
            >
              {i18next.t('button.cancel')}
            </Button>
            <Button
              htmlType="submit"
              type="primary w-full mt-20 fw-600"
              className="ml-6"
            >
              {i18next.t('button.decline')}
            </Button>
          </Form.Item>
        </FormReasonDelete>
      </ModalCustom>
    );
  },
);

DeclineReasonModal.propTypes = {
  updateStatus: PropTypes.func,
  changeStatusValues: PropTypes.object,
};

export default DeclineReasonModal;

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import i18next from 'i18next';
import { EditIcon } from 'components/common/SVGIcon';
import CustomButton from 'components/RestActions/CustomButton';
import { getUrlIfyMessage } from 'utils/dataUtils';
import AutomatedForm from './AutomatedForm';

const AutomatedEdit = ({ item }) => {
  const [isEdit, setIsEdit] = useState();

  return (
    <div>
      {isEdit ? (
        <AutomatedForm setIsEdit={setIsEdit} item={item} />
      ) : (
        <div className="message-box__wrapper">
          <div
            dangerouslySetInnerHTML={{
              __html:
                getUrlIfyMessage(item?.data?.text) ||
                i18next.t('error.waitingUpdate'),
            }}
            className="message-box__content"
          />
          <div className="text-right">
            <CustomButton
              buttonProps={{ icon: <EditIcon />, type: 'primary' }}
              handleClick={() => setIsEdit(true)}
              permissionKey="automatedResponses"
              action="isEdit"
              title="button.edit"
            />
          </div>
        </div>
      )}
    </div>
  );
};

AutomatedEdit.propTypes = {
  item: PropTypes.object,
};

export default AutomatedEdit;

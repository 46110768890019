import React from 'react';
import { Col, Row } from 'antd';
import PropTypes from 'prop-types';
import i18next from 'i18next';
import NQSRatingPieChart from 'components/common/NQSRatingPieChart';
import KindicareRatingPieChart from 'components/common/KindicareRatingPieChart';
import ServiceApprovalBarChart from 'components/common/ServiceApprovalBarChart';
import {
  getBrandBenchmarkingKindicareRating,
  getBrandBenchmarkingNSQRating,
  getBrandServiceApprovals,
} from 'redux/brandBenchmarking/selectors';
import { useSelector } from 'react-redux';

const UserBrandBenchmarkingChart = ({
  selectedBrandName,
  selectedCompanyName,
}) => {
  const resultNQSRatingChart = useSelector(getBrandBenchmarkingNSQRating);
  const resultNQSRating = resultNQSRatingChart.map((item) => ({
    name: item?.name,
    percent: Number(item?.value),
    color: item?.color,
    image: item?.image,
    total: item?.total,
  }));

  const resultKindicareRatingChart = useSelector(
    getBrandBenchmarkingKindicareRating,
  );

  const resultKindicareRating = resultKindicareRatingChart.map((item) => ({
    name: item?.name,
    percent: Number(item?.value),
    color: item?.color,
    total: item?.total,
  }));

  const resultServiceApprovals = useSelector(getBrandServiceApprovals);
  return (
    <Row gutter={[24, 24]} className="mt-32">
      <Col xl={8} sm={24} xs={24}>
        <NQSRatingPieChart
          title={`${i18next.t('companyBenchmarking.ratingTypes.nqsRating')} - ${
            selectedCompanyName ?? ''
          } - ${selectedBrandName ?? ''}`}
          resultNQSRating={resultNQSRating}
        />
      </Col>
      <Col xl={8} sm={24} xs={24}>
        <KindicareRatingPieChart
          title={`${i18next.t(
            'companyBenchmarking.ratingTypes.kindiCareRating',
          )} - ${selectedCompanyName ?? ''} - ${selectedBrandName ?? ''}`}
          resultKindicareRating={resultKindicareRating}
        />
      </Col>
      <Col xl={8} sm={24} xs={24}>
        <ServiceApprovalBarChart
          title={`${i18next.t(
            'companyBenchmarking.userCompanyCharts.serviceApprovalsByYear',
          )} - ${selectedCompanyName ?? ''} - ${selectedBrandName ?? ''}`}
          resultServiceApprovals={resultServiceApprovals}
        />
      </Col>
    </Row>
  );
};

UserBrandBenchmarkingChart.propTypes = {
  selectedBrandName: PropTypes.string,
  selectedCompanyName: PropTypes.string,
};

export default UserBrandBenchmarkingChart;

import React from 'react';
import { Row, Col } from 'antd';
import IdentityInfo from './IdentityInfo';
import TargetedAudienceInfo from './TargetedAudienceInfo';
import DurationInfo from './DurationInfo';
import EngagementInfo from './EngagementInfo';
import AdDetailInfo from './AdDetailInfo';

const AdvertisementDetail = ({ currentData }) => (
  <div className="mt-10">
    <Row gutter={[24, 24]}>
      <Col xl={8} lg={10} md={24} xs={24}>
        <AdDetailInfo currentData={currentData} />
      </Col>
      <Col xl={16} lg={14} md={24} xs={24}>
        <Row gutter={[24, 24]}>
          <Col span={24}>
            <IdentityInfo
              identities={currentData?.identities}
              isChildcareRelated={currentData?.isChildcareRelated}
              isSocialRelated={currentData?.isSocialRelated}
            />
          </Col>
          <Col span={24}>
            <TargetedAudienceInfo
              targetedAudience={currentData?.targetedAudience}
            />
          </Col>
          <Col span={24}>
            <DurationInfo duration={currentData?.duration} />
          </Col>
          <Col span={24}>
            <EngagementInfo engagement={currentData?.engagement} />
          </Col>
        </Row>
      </Col>
    </Row>
  </div>
);

export default AdvertisementDetail;

import FilterCustom from 'components/common/FilterCustom';
import React, { useMemo } from 'react';
import { StaffProfileQueryParams } from 'containers/Centres/components/CentreTabs/EducatorStaffProfilesTab/index';
import { getFilterFromUrl } from 'utils/tools';
import { useLocation } from 'react-router-dom';
import { DEFAULT_CURRENT_PAGE, DEFAULT_SIZE } from 'configs/localData/constant';
import StaffProfileFilter from './StaffProfileFilter';

interface FilterProps {
  pushQuery?: (params: StaffProfileQueryParams) => void;
}

const Filter = ({ pushQuery }: FilterProps) => {
  const { search } = useLocation();
  const params = getFilterFromUrl(search).filter;

  const initialValues = useMemo(
    () => getFilterFromUrl(search).filter,
    [search],
  );

  const onSubmitFilter = (values) => {
    pushQuery({
      ...initialValues,
      filter: values,
      page: DEFAULT_CURRENT_PAGE,
      size: DEFAULT_SIZE,
    });
  };

  const onClearFilter = () => {
    pushQuery({
      page: params.page || DEFAULT_CURRENT_PAGE,
      size: params.size || DEFAULT_SIZE,
    });
  };

  return (
    <div>
      <FilterCustom
        onSubmitFilter={onSubmitFilter}
        onClearFilter={onClearFilter}
        responsiveFilter={{
          xxl: 20,
          xl: 20,
          lg: 24,
          md: 24,
          xs: 24,
        }}
        responsiveAction={{
          xxl: 4,
          xl: 4,
          lg: 24,
          md: 24,
          xs: 24,
        }}
        initialValues={initialValues.filter}
      >
        <StaffProfileFilter />
      </FilterCustom>
    </div>
  );
};

export default Filter;

import { createSelector } from 'reselect';

import { CRUDSelectors } from '../crudCreator/selectors';
import { MODEL_NAME } from './actions';

const getCurrentApplication = (state) => state.applications.currentData;

const getApplication = (state) => state.applications || {};

const getResourceDataInfoRequest = (state) =>
  state.applications.infoRequest?.resourceData;

const getInfoRequest = (state) => state.applications.infoRequest?.data;

const getInfoRequestLoading = (state) => state.applications.infoRequestLoading;

const getCreateInfoRequestLoading = (state) =>
  state.applications.createInfoRequestLoading;

const getResourceDataDocuments = (state) =>
  state.applications.documents?.resourceData;

const getDocuments = (state) => state.applications.documents?.data;

const getDocumentsLoading = (state) => state.applications.documentsLoading;

const getCreateDocumentsLoading = (state) =>
  state.applications.createDocumentsLoading;

const getActivities = (state) => state.applications.activity?.data;

const getResourceDataActivities = (state) =>
  state.applications.activity?.resourceData;

const getTasks = (state) => state.applications.task?.data;

const getResourceDataTasks = (state) => state.applications.task?.resourceData;

export const countPendingSelector = createSelector(
  [getApplication],
  (application) => application?.countPending,
);

export const childrenSelector = createSelector(
  [getCurrentApplication],
  (currentApplication) => currentApplication?.careOptions || [],
);

export const getDataApplicationArr = createSelector(
  [getApplication],
  (applications) => Object.values(applications.data),
);

export const getResourceDataInfoRequestSelector = createSelector(
  [getResourceDataInfoRequest],
  (data) => data || {},
);

export const getInfoRequestSelector = createSelector(
  [getInfoRequest],
  (data) => data || [],
);

export const getInfoRequestLoadingSelector = createSelector(
  [getInfoRequestLoading],
  (data) => data,
);

export const getCreateInfoRequestLoadingSelector = createSelector(
  [getCreateInfoRequestLoading],
  (data) => data,
);

export const enabledLoadMoreInfoRequestSelector = createSelector(
  [getInfoRequestLoading, getResourceDataInfoRequest],
  (loading, resources) => {
    const { offset, limit, numberOfPages } = resources || {};
    return !loading && offset / limit + 1 < numberOfPages;
  },
);

export const getResourceDataDocumentsSelector = createSelector(
  [getResourceDataDocuments],
  (data) => data || {},
);

export const getDocumentsSelector = createSelector(
  [getDocuments],
  (data) => data || [],
);

export const getDocumentsLoadingSelector = createSelector(
  [getDocumentsLoading],
  (data) => data,
);

export const getCreateDocumentsLoadingSelector = createSelector(
  [getCreateDocumentsLoading],
  (data) => data,
);

export const getActivitiesSelector = createSelector(
  [getActivities],
  (data) => data || [],
);

export const getActivityById = (id) =>
  createSelector(
    [getActivities],
    (data) => data?.find((ele) => ele.id === id) || {},
  );

export const getResourceDataActivitiesSelector = createSelector(
  [getResourceDataActivities],
  (data) => data || {},
);

export const getTasksSelector = createSelector(
  [getTasks],
  (data) => data || [],
);

export const getTaskById = (id) =>
  createSelector(
    [getTasks],
    (data) => data?.find((ele) => ele.id === id) || {},
  );

export const getResourceDataTasksSelector = createSelector(
  [getResourceDataTasks],
  (data) => data || {},
);

export const countApplicationsSelector = createSelector(
  [getApplication],
  (application) => ({
    countArchived: application?.totalArchivedApplications ?? 0,
    countWithoutArchived:
      application?.total - application?.totalArchivedApplications,
  }),
);

export const applicationsSelectors = new CRUDSelectors(MODEL_NAME);

import styled from 'styled-components';

export default styled.div`
  background: #fff;
  border-radius: ${({ theme }) => theme.borderRadius.default};
  height: 100%;
  position: relative;

  .header-section {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 15px;
  }

  .load-more {
    margin-bottom: 10px;
  }

  .ant-avatar {
    min-width: 35px;
  }

  .ant-input {
    background: #f2f3f5;
    border-radius: 14px;
  }
`;

export const BodyCommentStyles = styled.div`
  display: flex;
  flex-direction: column-reverse;

  max-height: 60vh;
  overflow-y: auto;
  overflow-x: hidden;
  padding-right: 5px;

  .list-files-container {
    margin-top: 10px;
    .file-item {
      margin-bottom: 5px !important;
    }
  }

  .message {
    display: flex;
    margin-bottom: 15px;

    .message-content {
      margin-left: 5px;

      .bubble {
        padding: 7px 12px;
        border-radius: 14px;
        background: #f0f2f5;
        margin-bottom: 5px;

        .name {
          font-weight: 600;
          margin-right: 5px;
        }
      }

      .time {
        margin-left: 10px;
      }
    }
  }
`;

export const HeaderWrapper = styled.div`
  padding: 10px 20px;
  .name-user {
    font-weight: bold;
    margin-left: 15px;
  }
  border-bottom: 2px solid ${({ theme }) => theme.text.mainL6};
`;

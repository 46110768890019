import React, { useState } from 'react';
import { StepFooter } from 'components/RestLayout/StepsCreateLayout/styles';
import { Button, Col, Row } from 'antd';
import i18next from 'i18next';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import withFooterFunc, {
  FooterProps,
} from 'containers/Advertisement/hocs/withFooterFunc';

const Footer = ({ onNext, onBack }: FooterProps) => {
  const [disable, setDisable] = useState(false);
  const [loading, setLoading] = useState(false);
  const onSubmitCreateAd = () => {
    setDisable(true);
    setLoading(true);
    onNext();
    setTimeout(() => {
      setDisable(false);
      setLoading(false);
    }, 500);
  };

  return (
    <StepFooter>
      <Row justify="space-between">
        <Col>
          <Button
            onClick={onBack}
            icon={<LeftOutlined />}
            className="btn-line-primary"
          >
            {i18next.t('button.back')}
          </Button>
        </Col>
        <Col>
          <Button
            type="primary"
            onClick={onSubmitCreateAd}
            disabled={disable}
            loading={loading}
          >
            {i18next.t('button.publish')}
            <RightOutlined />
          </Button>
        </Col>
      </Row>
    </StepFooter>
  );
};

export default withFooterFunc(Footer);

import styled, { css } from 'styled-components';

const Wrapper = styled.div`
  ${({ isFixedCampaignSearchBar }) =>
    isFixedCampaignSearchBar &&
    css`
      overflow-y: hidden;
    `}

  .ant-form {
    max-height: ${({ maxHeight }) => maxHeight}px;
    overflow: auto;
  }

  .ant-radio-wrapper {
    font-size: 16px;
  }
  .ant-space-item {
    padding: 6px 0;
  }
  .ant-radio-group {
    width: 100%;
  }
  .ant-form-item-control-input-content {
    display: flex;
  }
  .ant-col {
    flex-direction: column-reverse;
  }
`;

export default Wrapper;

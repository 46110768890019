import React from 'react';
import { Space, Tooltip } from 'antd';
import {
  DEFAULT_CURRENT_PAGE,
  DEFAULT_SORT_INDIVIDUAL,
} from 'containers/JobListings/constants';
import { useHistory } from 'react-router';
import { getSearchUrl } from 'utils/tools';
import { DEFAULT_SORT_JOB_APPLICATIONS } from 'containers/JobApplications/constants';
import { RoundOpenInNewIcon } from 'components/common/SVGIcon';

const useFormat = () => {
  const { push } = useHistory();

  const formatApplicationAction = (data, record) => {
    const handleClick = () => {
      window.open(
        `/job-applications/board?${getSearchUrl({
          page: DEFAULT_CURRENT_PAGE,
          sorts: [DEFAULT_SORT_JOB_APPLICATIONS],
          filter: {
            jobRole: record?.id,
          },
        })}`,
      );
    };
    return (
      <Space onClick={handleClick} className="cursor-pointer">
        <span className="mr-8 fw-bold text-decoration-line">{data}</span>
        <RoundOpenInNewIcon />
      </Space>
    );
  };

  const formatJobRoleName = (data, record) => {
    const handleClick = () => {
      push(
        `/job-listings/individual/list?${getSearchUrl({
          page: DEFAULT_CURRENT_PAGE,
          sorts: [DEFAULT_SORT_INDIVIDUAL],
          filter: {
            jobRoleId: record?.id,
          },
        })}`,
      );
    };

    return (
      <div className="t-bold black ellipsis-t-2 link-class cursor-pointer">
        <Tooltip onClick={handleClick} title={data}>
          {data}
        </Tooltip>
      </div>
    );
  };

  return {
    formatApplicationAction,
    formatJobRoleName,
  };
};

export default useFormat;

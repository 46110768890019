import React, { useEffect } from 'react';
import { Form, Space } from 'antd';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { setJobTemplatePayload } from 'redux/jobs/jobTemplates/slice';
import { getJobTemplateConfigSelector } from 'redux/jobs/jobTemplates/selectors';
import i18next from 'i18next';
import { cleanObject } from 'utils/dataUtils';
import useWindowAutoScroll from 'hooks/useWindowAutoScroll';
import { isEmpty } from 'lodash-es';
import TableToggle from '../../TableToggle';

const ImmunisationForm = ({ form, initTemplate }) => {
  useWindowAutoScroll();
  const dispatch = useDispatch();

  const { immunisations = [] } = useSelector(getJobTemplateConfigSelector);

  const onFinish = (immunisations) => {
    dispatch(
      setJobTemplatePayload({ immunisations: cleanObject(immunisations) }),
    );
  };

  useEffect(() => {
    form.resetFields();
    if (!isEmpty(initTemplate?.immunisations)) {
      form.setFieldsValue(initTemplate?.immunisations);
    }
  }, [form, initTemplate]);

  return (
    <div>
      <Form form={form} onFinish={onFinish}>
        <Space direction="vertical" size={24} className="w-full">
          <TableToggle
            dataSource={immunisations}
            categoryName={i18next.t(
              'jobs.createJobTemplateStep.immunisationRequirements',
            )}
          />
        </Space>
      </Form>
    </div>
  );
};

ImmunisationForm.propTypes = {
  form: PropTypes.object,
  initTemplate: PropTypes.object,
};

export default ImmunisationForm;

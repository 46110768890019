import React from 'react';
import PropTypes from 'prop-types';

const Link = ({ externalUrl, target, children, className, ...props }) =>
  externalUrl ? (
    <a target={target} href={externalUrl} className={className} {...props}>
      {children}
    </a>
  ) : (
    ''
  );

Link.propTypes = {
  externalUrl: PropTypes.string,
  target: PropTypes.string,
  children: PropTypes.node,
  className: PropTypes.string,
};

export default Link;

import crudActions from 'redux/crudActions';
import { CRUDSelectors } from 'redux/crudCreator';

const models = Object.keys(crudActions);

const crudSelectors = {};
models.forEach((name) => {
  crudSelectors[name] = new CRUDSelectors(name);
});

export default crudSelectors;

import CardSection from 'components/common/CardSection';
import useRolesCompanyProfile from 'containers/CompanyProfile/hooks/useRolesCompanyProfile';
import React, { useState } from 'react';
import { getYoutubeEmbedURL } from 'utils/tools';
import { pick } from 'lodash-es';
import { useDispatch, useSelector } from 'react-redux';
import { updateCompanyProfileMedia } from 'redux/jobs/companyProfile/actions';
import { NoDataIcon } from 'components/common/SVGIcon';
import i18next from 'i18next';
import {
  getIDCompanyProfileSelector,
  getRecruitmentVideosSelector,
  getCompanyProfileSelector,
} from 'redux/jobs/companyProfile/selectors';
import RecruitmentVideoModal from '../../Modals/RecruitmentVideoModal';

const RecruitmentVideo = () => {
  const [visibleModal, setVisibleModal] = useState(false);
  const onCancelModal = () => setVisibleModal(false);
  const disabledAction = useRolesCompanyProfile();
  const companyId = useSelector(getIDCompanyProfileSelector);
  const recruitmentVideos = useSelector(getRecruitmentVideosSelector);
  const { data } = useSelector(getCompanyProfileSelector);
  const mediaList = data?.mediaList.map((item) => ({
    ...pick(item, ['type', 'isRecruitment', 'url']),
    isFeatured: item?.isFeatured ?? false,
  }));

  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const onClickButton = () => {
    setVisibleModal(true);
  };

  const onFinish = async (values) => {
    setLoading(true);
    dispatch(
      updateCompanyProfileMedia({
        companyId,
        data: {
          mediaList: [
            ...mediaList,
            {
              ...values,
              type: 'video',
              isFeatured: false,
              isRecruitment: true,
            },
          ],
        },
      }),
    );
    setLoading(false);
    onCancelModal();
  };

  return (
    <>
      <CardSection
        isEdit
        onClickButton={onClickButton}
        title="jobs.companyProfile.recruitmentVideo.title"
        action="isEdit"
        disabled={disabledAction}
      >
        {recruitmentVideos?.length ? (
          recruitmentVideos?.map(({ url, id }) => (
            <iframe
              key={id}
              className="rounded-8 w-full"
              title={url}
              frameBorder="0"
              allowFullScreen="allowfullscreen"
              src={getYoutubeEmbedURL(url) || url}
            />
          ))
        ) : (
          <div className="flex-col flex-center">
            <NoDataIcon />
            <div>{i18next.t('jobs.companyProfile.emptyRecruitmentVideo')}</div>
          </div>
        )}
      </CardSection>
      <RecruitmentVideoModal
        visible={visibleModal}
        onCancel={onCancelModal}
        onFinish={onFinish}
        loading={loading}
        initialValues={{ url: recruitmentVideos?.[0]?.url }}
      />
    </>
  );
};

export default RecruitmentVideo;

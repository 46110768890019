import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { ThemeProvider } from 'styled-components';
import { ConfigProvider, Empty } from 'antd';
import { PersistGate } from 'redux-persist/integration/react';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import TagManager from 'react-gtm-module';
import store, { history, persistor } from 'redux/store';
import theme from './configs/theme';
import Routes from './routes';
import './configs/language';
import * as serviceWorker from './serviceWorker';
import AppWrapper, { GlobalStyle } from './appStyle';

if (process.env.NODE_ENV !== 'development') {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    environment: process.env.REACT_APP_SENTRY_ENV || 'production',
    ignoreErrors: ['ResizeObserver loop limit exceeded'],
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 0,
  });

  TagManager.initialize({
    gtmId: process.env.REACT_APP_GTM_ID,
  });
}

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <ThemeProvider theme={theme}>
        <AppWrapper>
          <GlobalStyle />
          <ConnectedRouter history={history}>
            <ConfigProvider renderEmpty={() => <Empty />}>
              <Routes />
            </ConfigProvider>
          </ConnectedRouter>
        </AppWrapper>
      </ThemeProvider>
    </PersistGate>
  </Provider>,
  document.getElementById('root'),
);

serviceWorker.unregister();

import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import I18n from 'i18next';
import { Tooltip } from 'antd';
import { getRecordData } from 'utils/tools';
import { RestInputContext } from '../RestInputContext';
import FormRangePicker from '../../form/FormRangePicker';

const RestRangePickerInput = ({
  sourceGt,
  sourceLt,
  isShowTooltip,
  placeholder,
  ...props
}) => {
  const { record, form } = useContext(RestInputContext);

  const getDefaultValue = () => {
    const startTime = getRecordData(record, sourceGt);
    const endTime = getRecordData(record, sourceLt);
    return [
      startTime ? moment(startTime) : null,
      endTime ? moment(endTime) : null,
    ];
  };

  return (
    <Tooltip placement="top" title={isShowTooltip ? I18n.t(placeholder) : ''}>
      <div>
        <FormRangePicker
          form={form}
          {...props}
          defaultValue={getDefaultValue()}
        />
      </div>
    </Tooltip>
  );
};

RestRangePickerInput.propTypes = {
  source: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  sourceGt: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  sourceLt: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  formatDate: PropTypes.string,
  placeholder: PropTypes.string,
  isShowTooltip: PropTypes.bool,
};

RestRangePickerInput.defaultProps = {
  source: 'rangeValue',
  sourceGt: 'startTime',
  sourceLt: 'endTime',
};

export default RestRangePickerInput;

import CheckPermission from 'components/common/CheckPermissions';
import React from 'react';
import EditButtonWrapper from 'components/RestActions/EditButton/EditButtonWrapper';
import EditBtnFallback from './EditBtnFallback';
import { EditButtonStyles } from './styles';

interface RecordType {
  id?: string;
  [key: string]: unknown;
}

interface Props {
  gotoEditPage?: (id?: string) => void;
  record?: RecordType;
  isView?: boolean;
  isShowText?: boolean;
  onClickCustom?: (id?: string, record?: RecordType) => void;
  gotoShowPage?: (id?: string) => void;
  checkVisible?: (record?: RecordType) => boolean;
  permissionKey?: string;
  action?: string;
  isSkipSubscription?: boolean;
  packageName?: string;
  disabled?: boolean;
  title?: string;
  iconCustom?: JSX.Element;
}

const EditButton = ({
  isView,
  isShowText,
  onClickCustom,
  record,
  gotoShowPage,
  gotoEditPage,
  checkVisible = () => true,
  permissionKey,
  action,
  isSkipSubscription,
  packageName,
  disabled,
  title,
  iconCustom,
}: Props) => {
  const handleOnClick = () => {
    if (onClickCustom) {
      onClickCustom(record?.id, record);
    } else if (isView) {
      gotoShowPage(record ? record.id : '');
    } else {
      gotoEditPage(record ? record.id : '');
    }
  };
  return (
    <EditButtonStyles className="edit-div-button">
      {checkVisible(record) && (
        <CheckPermission
          packageName={packageName}
          permissionKey={permissionKey}
          action={action}
          isSkipSubscription={isSkipSubscription}
          isDisabled
          fallback={<EditBtnFallback />}
        >
          <EditButtonWrapper
            isView={isView}
            isShowText={isShowText}
            handleOnClick={handleOnClick}
            disabled={disabled}
            title={title}
            iconCustom={iconCustom}
          />
        </CheckPermission>
      )}
    </EditButtonStyles>
  );
};

export default EditButton;

import React, { useCallback } from 'react';
import i18next from 'i18next';
import PropTypes from 'prop-types';
import { Row, Col, Divider } from 'antd';
import { formatMoney } from 'utils/tools';
import { formatTimeDisplay } from 'utils/textUtils';

import { SummaryDetailCardStyles, SummaryViewStyles } from './styles';

const SummaryView = ({ title, data, IconComponent, total, color }) => {
  const formatValue = useCallback(
    ({ value, isPerAnnum, isPercent, isTime }) => {
      if (isPercent) return `${formatMoney(value)}%`;

      if (isTime) return formatTimeDisplay(value);

      if (isPerAnnum)
        return `${i18next.t('currencyUnit.sign')}${formatMoney(
          value,
        )} ${i18next.t('text.perAnnum')}`;

      return formatMoney(value);
    },
    [],
  );

  return (
    <SummaryViewStyles>
      <div className="title-card">
        <div className="title-card__left">
          {IconComponent && <IconComponent />}
          <span className="title-card__name">{i18next.t(title)}</span>
        </div>
        <div className="title-card__total" style={{ color }}>
          {total || 0}
        </div>
      </div>
      <Divider />
      <SummaryDetailCardStyles>
        <Row className="content-info-card" gutter={[10, 20]}>
          {data?.map((item, index) => (
            <React.Fragment key={String(index)}>
              <Col span={16} className="col-title-item">
                <span className="text-name">{i18next.t(item.title)}</span>
              </Col>
              <Col span={8} className="col-value-item">
                {formatValue({
                  value: item.value,
                  isPerAnnum: item.isPerAnnum,
                  isPercent: item.isPercent,
                  isTime: item.isTime,
                })}
              </Col>
            </React.Fragment>
          ))}
        </Row>
      </SummaryDetailCardStyles>
    </SummaryViewStyles>
  );
};

SummaryView.propTypes = {
  title: PropTypes.string,
  data: PropTypes.array,
  IconComponent: PropTypes.any,
  total: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  color: PropTypes.string,
};

export default SummaryView;

import React, { useMemo, useCallback } from 'react';
import i18next from 'i18next';
import PropTypes from 'prop-types';
import { Space } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { getDocumentTypes } from 'redux/config/selectors';
import { delInformationRequest } from 'redux/applications/actions';
import { formatDateApplication } from 'utils/textUtils';

import DeleteButtonRest from 'components/RestActions/DeleteButton';
import DropdownStatusInfo from './DropdownStatusInfo';
import { CardInfoRequestStyles } from './styles';

const CardInfoRequest = ({ index, item }) => {
  const dispatch = useDispatch();

  const documentTypeData = useSelector(getDocumentTypes);

  const documentType = useMemo(
    () =>
      documentTypeData?.find((document) => document.id === item.documentTypeId)
        ?.name,
    [documentTypeData, item.documentTypeId],
  );

  const deleteItem = useCallback(
    () => {
      dispatch(
        delInformationRequest({ id: item.id, messageId: item.message?.id }),
      );
    },
    [item.id, item.message], // eslint-disable-line
  );

  return (
    <CardInfoRequestStyles>
      <div className="title-info">
        <span>{`${i18next.t('informationRequests.title')} ${index + 1}`}</span>
        <DeleteButtonRest
          permissionKey="applications"
          action="isEdit"
          resource="informationRequests"
          customMessage="informationRequests.customMessageDelete"
          deleteItem={deleteItem}
        />
      </div>
      <Space className="card-info" direction="vertical" size={15}>
        <div className="card-info__item item-status">
          <span className="card-info__item__name">
            {i18next.t('informationRequests.status')}
          </span>
          <span className="card-info__item__value value-status">
            <DropdownStatusInfo id={item.id} status={item.status} />
          </span>
        </div>

        <div className="card-info__item">
          <span className="card-info__item__name">
            {i18next.t('informationRequests.documentType')}
          </span>
          <span className="card-info__item__value">{documentType}</span>
        </div>

        <div className="card-info__item">
          <span className="card-info__item__name">
            {i18next.t('informationRequests.dateRequested')}
          </span>
          <span className="card-info__item__value">
            {formatDateApplication(item.requestedAt)}
          </span>
        </div>

        <div className="card-info__item">
          <span className="card-info__item__name">
            {i18next.t('informationRequests.dateReceived')}
          </span>
          <span className="card-info__item__value">
            {formatDateApplication(item.receivedAt)}
          </span>
        </div>

        <div className="card-info__item">
          <span className="card-info__item__name">
            {i18next.t('informationRequests.message')}
          </span>
          <span className="card-info__item__value message-value">
            {item.message?.message}
          </span>
        </div>
      </Space>
    </CardInfoRequestStyles>
  );
};

CardInfoRequest.propTypes = {
  index: PropTypes.number,
  item: PropTypes.object,
};

CardInfoRequest.defaultProps = {
  index: 0,
  item: {},
};

export default CardInfoRequest;

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Checkbox, Row, Col, Input, Tooltip } from 'antd';
import { InfoCircleFilled } from '@ant-design/icons';
import i18next from 'i18next';
import {
  controllersSelector,
  initialPermissionsSelector,
  permissionsByControllerSelector,
} from 'redux/roles/selectors';
import RestInputItem from 'components/RestInput/RestInputItem';
import RoleDetailStyles from './styles';

const RoleDetail = ({ permissions, setPermissions }) => {
  const controllers = useSelector(controllersSelector);
  const initialPermissions = useSelector(initialPermissionsSelector);
  const permissionsByController = useSelector(permissionsByControllerSelector);

  const handleCheckAll = (controller) => (e) => {
    const allCurrentRowPermissions = permissionsByController?.[controller]?.map(
      (permission) => permission.id,
    );
    setPermissions({
      ...permissions,
      [controller]: {
        ...permissions?.[controller],
        checkedList: e.target.checked ? allCurrentRowPermissions : [],
        indeterminate: false,
        checkedAll: e.target.checked,
      },
    });
  };

  const handleCheck = (controller) => (checkedList) => {
    const allCurrentRowPermissions = permissionsByController?.[controller]?.map(
      (permission) => permission.id,
    );
    setPermissions({
      ...permissions,
      [controller]: {
        ...permissions?.[controller],
        checkedList,
        indeterminate:
          !!checkedList.length &&
          checkedList.length < allCurrentRowPermissions.length,
        checkedAll: checkedList.length === allCurrentRowPermissions.length,
      },
    });
  };

  useEffect(() => {
    if (initialPermissions) {
      setPermissions(initialPermissions);
    }
  }, [initialPermissions, setPermissions]);

  return (
    <RoleDetailStyles>
      <div className="section-title">{i18next.t('roles.roleDetail')}</div>
      <RestInputItem required source="name" header="roles.name" />
      <RestInputItem
        source="description"
        header="roles.description"
        ContentComponent={Input.TextArea}
      />
      <br />
      <div className="section-title">{i18next.t('roles.permissions')}</div>
      <br />
      {controllers?.map((controller) => (
        <div key={controller} className="controller-section">
          <div className="controller-header">
            <div className="title">{i18next.t(`roles.${controller}`)}</div>
            <div className="divider" />
            <Checkbox
              onChange={handleCheckAll(controller)}
              disabled={permissions?.['*']?.checkedAll && controller !== '*'}
              indeterminate={
                permissions?.[controller]?.indeterminate &&
                !permissions?.['*']?.checkedAll
              }
              checked={
                permissions?.[controller]?.checkedAll ||
                permissions?.['*']?.checkedAll
              }
            >
              {i18next.t('roles.selectAll')}
            </Checkbox>
          </div>
          <div className="checkbox-row" style={{ display: 'flex' }}>
            <Checkbox.Group
              onChange={handleCheck(controller)}
              disabled={permissions?.['*']?.checkedAll && controller !== '*'}
              value={
                permissions?.['*']?.checkedAll
                  ? permissionsByController?.[controller]?.map(
                      (permission) => permission.id,
                    )
                  : permissions?.[controller]?.checkedList
              }
            >
              <Row gutter={16}>
                {permissionsByController?.[controller]?.map((item) => (
                  <Col key={item?.id} span={8}>
                    <Checkbox value={item.id}>
                      {item.name || i18next.t(item.policy.replace(':', '.'))}
                      {item.description && (
                        <Tooltip title={item.description}>
                          <InfoCircleFilled />
                        </Tooltip>
                      )}
                    </Checkbox>
                  </Col>
                ))}
              </Row>
            </Checkbox.Group>
          </div>
        </div>
      ))}
    </RoleDetailStyles>
  );
};

RoleDetail.propTypes = {
  permissions: PropTypes.object,
  setPermissions: PropTypes.func,
};

export default RoleDetail;

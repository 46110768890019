import { Tabs } from 'antd';
import styled from 'styled-components';

export const Container = styled.div`
  .ant-form {
    padding: 4px;
  }

  .ant-radio-group,
  .ant-space,
  .ant-space-item,
  .ant-radio-wrapper,
  .ant-radio-wrapper > span:nth-child(2) {
    width: 100%;
  }
`;

export const ExistingAudienceWrapper = styled.div`
  .anticon {
    color: #758098;
    font-size: 16px;
  }

  .ant-radio-group,
  .ant-space,
  .ant-space-item,
  .ant-radio-wrapper,
  .ant-radio-wrapper > span:nth-child(2) {
    width: 100%;
  }

  .ant-col.ant-form-item-control {
    flex-direction: column-reverse;
  }
`;

export const AudienceTabs = styled(Tabs)`
  & {
    margin-top: 16px;
  }
  .ant-tabs-nav::before {
    display: none;
  }
  .ant-tabs-tab-btn {
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: ${({ theme }) => theme.text.grayNeutral500};
  }
`;

import styled from 'styled-components';
import ModalCustom from 'components/common/ModalCustom';
import { Button } from 'antd';

export const CreateProgramStyle = styled.div`
  .ant-modal-content {
    font-size: 28px;
  }
  .ant-form-item-label {
    padding-bottom: 8px;
    label {
      color: ${({ theme }) => theme.text.blackNeutral800};
      &:before {
        margin-right: 0 !important;
      }
    }
  }
  .ant-checkbox-wrapper {
    margin-left: 0 !important ;
  }
  .file-list-view {
    justify-content: flex-start;
  }
  .ant-form label {
    font-size: 16px;
  }
  .ant-checkbox + span {
    padding-left: 16px;
  }
  .ant-checkbox-wrapper.ant-checkbox-wrapper-in-form-item {
    .ant-checkbox-checked {
      font-size: 20px;
    }
    .ant-checkbox-inner,
    .ant-checkbox-input {
      width: 20px;
      height: 20px;
      &:after {
        left: 25%;
      }
    }
    span {
      font-size: 16px;
      color: ${({ theme }) => theme.text.blackNeutral800};
    }
  }
  .form-item-upload-image {
    margin-top: 8px;
    .ant-upload.ant-upload-drag p.ant-upload-drag-icon {
      margin-bottom: 0 !important;

      .anticon {
        font-size: 20px !important;
        color: ${({ theme }) => theme.text.grayNeutral500} !important;
      }
    }
  }
  .ant-upload-drag-container {
    .whitespace-pre-line {
      padding: 8px !important;
      color: ${({ theme }) => theme.text.grayNeutral500} !important;
    }
  }
  .tox-tinymce {
    border-radius: 12px;
    border: 1px solid #d0d5dd;
  }

  .ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: ${({ theme }) => theme.border.gray};
  }
  .ant-checkbox-wrapper:hover .ant-checkbox-inner,
  .ant-checkbox:hover .ant-checkbox-inner,
  .ant-checkbox-checked .ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: ${({ theme }) => theme.border.primary};
  }
`;

export const CreateProgramModalStyle = styled(ModalCustom)`
  .ant-modal-title {
    font-size: 28px;
  }
  .ant-modal-close-x > .anticon {
    font-size: 24px;
  }
`;

export const CreateProgramButtonStyle = styled(Button)`
  span {
    line-height: 20px;
    vertical-align: top;
  }
`;

export const ProgramsLearningInfoStyles = styled.div`
  padding: 12px 8px;
  background: ${({ theme }) => theme.table.headerBackground};
  border-left: 4px solid ${({ theme }) => theme.color.blueInformationDefault};
`;

import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'antd';
import { useHistory } from 'react-router';

import { useEnquiryContext } from './context';
import StatusGroups from './StatusGroups';
import ContactEnquiry from '../components/ContactEnquiry';
import MessageDetail from '../components/MessageDetail';
import MessageList from '../components/MessageList';

const ChatBoxList = ({
  inboxId,
  tab,
  getListAndSummary,
  retrieveList,
  getSummaries,
}) => {
  const { collapsed } = useEnquiryContext();
  const { push } = useHistory();

  const handleChangeTab = (value) => {
    push(`/enquiries/${value}/${inboxId}`);
  };

  return (
    <div className="wrapper-relative chat-box-wrapper">
      <div className="wrapper-absolute">
        <Row className="enquiries__row-wrapper" gutter={16} wrap={false}>
          <Col
            xl={collapsed ? 24 : 18}
            lg={collapsed ? 24 : 16}
            md={24}
            xs={24}
            className="enquiries__chat-box-col"
          >
            <div className="enquiries__chat-box">
              <StatusGroups handleChangeTab={handleChangeTab} tab={tab} />
              <div className="enquiries__chat-wrapper wrapper-relative">
                <div className="wrapper-absolute">
                  <Row className="enquiries__row-list">
                    <Col
                      xxl={10}
                      xl={10}
                      lg={10}
                      md={10}
                      xs={10}
                      className="enquiries__inbox-col"
                    >
                      <MessageList
                        inboxId={inboxId}
                        retrieveList={retrieveList}
                        getListAndSummary={getListAndSummary}
                      />
                    </Col>
                    <Col
                      xxl={14}
                      xl={14}
                      lg={14}
                      md={14}
                      xs={14}
                      className="enquiries__message-col"
                    >
                      <MessageDetail
                        inboxId={inboxId}
                        getSummaries={getSummaries}
                      />
                    </Col>
                  </Row>
                </div>
              </div>
            </div>
          </Col>
          <Col
            xxl={6}
            xl={6}
            lg={8}
            md={0}
            xs={0}
            className={`enquiries__contact-col ${
              collapsed ? 'enquiries__contact-collapsed' : ''
            }`}
          >
            <ContactEnquiry />
          </Col>
        </Row>
      </div>
    </div>
  );
};

ChatBoxList.propTypes = {
  inboxId: PropTypes.string,
  tab: PropTypes.string,
  getSummaries: PropTypes.func,
  getListAndSummary: PropTypes.func,
  retrieveList: PropTypes.func,
};

export default ChatBoxList;

import React, { forwardRef, useState, useImperativeHandle } from 'react';
import PropTypes from 'prop-types';
import { Form, Input, notification } from 'antd';
import i18next from 'i18next';
import { useSelector } from 'react-redux';
import { resendVerificationEmailApi } from 'api/centres';
import { EMAIL_CONTACT } from 'configs/localData/constant';

import ResendEmailSuccess from './ResendEmailSuccess';
import CentreModalStyles from './styles';

const ResendEmailModal = ({ id }, ref) => {
  const [success, setSuccess] = useState(false);

  const [visible, setVisible] = useState(false);

  const [loading, setLoading] = useState(false);

  const [form] = Form.useForm();

  const centreName = useSelector((state) => state.centres.currentData?.name);

  useImperativeHandle(
    ref,
    () => ({
      openModal: () => setVisible(true),
    }),
    [],
  );

  const handleSubmit = () => {
    form
      .validateFields()
      .then(async (values) => {
        if (id) {
          setLoading(true);
          const response = await resendVerificationEmailApi(id, values);
          setLoading(false);
          if (response?.success) setSuccess(true);
        }
      })
      .catch((error) => {
        setLoading(false);
        notification.error({
          message: i18next.t('error.title'),
          description: error.message || i18next.t('error.description'),
        });
      });
  };

  const handleCancel = () => {
    setVisible(false);
    if (success) setSuccess(false);
    else {
      form.resetFields();
    }
  };

  return (
    <CentreModalStyles
      visible={visible}
      style={{ top: 50 }}
      onCancel={handleCancel}
      onOk={success ? handleCancel : handleSubmit}
      isShowCancel={false}
      title={i18next.t('centresClaim.resendEmail.title')}
      okText={i18next.t(`${success ? 'button.done' : 'button.send'}`)}
      loading={loading}
      className={success ? 'modal-footer-center' : ''}
    >
      {success ? (
        <ResendEmailSuccess />
      ) : (
        <div>
          <div className="guide-content mb-20">
            <span>
              {i18next.t('centresClaim.resendEmail.guideContent', {
                centreName,
              })}
            </span>{' '}
            <a href={`mailto:${EMAIL_CONTACT}`} className="footer-email">
              {EMAIL_CONTACT}
            </a>
          </div>
          <Form form={form} layout="vertical">
            <Form.Item
              rules={[
                {
                  required: true,
                  message: i18next.t('input.email.validateMsg.required'),
                },
                {
                  type: 'email',
                  message: i18next.t('input.email.validateMsg.invalid'),
                },
              ]}
              label={i18next.t('centres.email')}
              name="email"
            >
              <Input placeholder={i18next.t('input.enterHere')} />
            </Form.Item>
          </Form>
        </div>
      )}
    </CentreModalStyles>
  );
};

ResendEmailModal.propTypes = {
  id: PropTypes.string,
};

export default forwardRef(ResendEmailModal);

import React, { forwardRef, useState, useImperativeHandle } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router';

import RestUploadFile from 'components/RestInput/RestUploadFile';

import { getPrefixKeyUploadCentre } from 'utils/fileUtils';
import { isEmpty } from 'lodash-es';
import CentreModal from '.';

const UploadBusinessLogoModal = forwardRef(
  ({ visible, record, logo, onCancel }, ref) => {
    const [isDisabled, setIsDisabled] = useState(false);
    const { id } = useParams();
    const [visibleModal, setVisibleModal] = useState(false);

    const onCancelModal = () => setVisibleModal(false);

    useImperativeHandle(
      ref,
      () => ({
        toggleModal: () => {
          setVisibleModal(true);
        },
      }),
      [],
    );

    const formatOnSubmit = ({ logo }) => ({
      ...(!isEmpty(logo) ? { logo: { url: logo?.url } } : { logo: {} }),
    });

    return (
      <CentreModal
        title="centreDetail.editBrandLogo"
        visible={visible || visibleModal}
        onCancel={onCancel || onCancelModal}
        isDisabled={isDisabled}
        formatOnSubmit={formatOnSubmit}
      >
        <RestUploadFile
          setIsDisabled={setIsDisabled}
          record={logo || { logo: record?.logo?.url }}
          source="logo"
          onlyShowImg
          multiple={false}
          prefixKey={getPrefixKeyUploadCentre(id)}
        />
      </CentreModal>
    );
  },
);

UploadBusinessLogoModal.propTypes = {
  record: PropTypes.object,
  visible: PropTypes.bool,
  onCancel: PropTypes.func,
  logo: PropTypes.object,
};

export default UploadBusinessLogoModal;

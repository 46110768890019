import styled from 'styled-components';
import { Card } from 'antd';

const CardStyles = styled(Card)`
  .ant-card-head-title {
    white-space: unset !important;
  }
  .card-item {
    &__header {
      font-size: 18px;
      font-weight: bold;
      .anticon {
        color: ${({ theme }) => theme.palette.primary};
        padding: 10px;
        font-size: 50px;
        background: ${({ theme }) => `${theme.palette.primary}20`};
        border-radius: 50%;
        margin-right: 15px;
      }
    }

    &__btn-download {
      border-color: ${({ theme }) => theme.palette.primary};
      color: ${({ theme }) => theme.palette.primary};
    }

    &__link-award {
      width: 200px;
      height: 200px;
      margin-bottom: 12px;
    }

    &__image-award {
      width: 100%;
    }
  }
  .ant-card-body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  &.resource-card {
    border-radius: 20px;
  }
`;

export default CardStyles;

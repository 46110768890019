import styled from 'styled-components';
import { Button, Typography } from 'antd';
import theme from 'configs/theme';

const { Text } = Typography;

export const DownloadBtnStyle = styled(Button)`
  svg {
    font-size: 22px;
    transition: transform 0.2s;
  }
  &:hover {
    svg {
      color: ${({ disabled }) => !disabled && '#DB147F'};
      transform: scale(1.1, 1.1);
    }
  }
`;

export const RefreshButton = styled.div`
  .ant-btn-background-ghost.ant-btn-primary {
    border-radius: 12px;
  }
`;

export const FileNameText = styled(Text)`
  &:hover {
    color: ${theme.palette.primary};
  }
`;

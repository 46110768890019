import styled from 'styled-components';

const UploadFileMessageStyles = styled.div`
  .ant-form-item {
    margin-bottom: 0 !important;
  }
  .ant-form-item-control-input {
    display: none;
  }
  .ant-upload.ant-upload-select {
    height: 100%;
  }

  .button-upload {
    border: none;
    background: none;
    box-shadow: none;
    padding-right: 0;
    height: 100%;
    .anticon {
      font-size: 24px;
      color: ${({ theme }) => theme.text.mainL2};
    }
  }
`;

export default UploadFileMessageStyles;

export const PreviewFilesStyles = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const UploadFileItemStyles = styled.div`
  display: flex;
  position: relative;
  margin: 10px;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 60px;
  border-radius: 4px;
  .image,
  .icon-div-item {
    width: 100%;
    height: 100%;
    border-radius: 4px;
  }
  .image {
    object-fit: cover;
  }
  .icon-div-item {
    display: flex;
    align-items: center;
    justify-content: center;
    .anticon {
      font-size: 50px;
    }
  }
  .remove-button {
    position: absolute;
    right: -10px;
    top: -10px;
    font-size: 20px;
    background: #fff;
    border-radius: 50%;
  }
  .loading {
    position: absolute;
    font-size: 20px;
  }
  .error {
    color: ${({ theme }) => theme.color.red};
  }
`;

import React from 'react';
import { Button } from 'antd';
import { useHistory } from 'react-router';
import { useDispatch } from 'react-redux';
import { PlusCircleOutlined, RightOutlined } from '@ant-design/icons';
import i18next from 'i18next';
import PageTitle from 'components/common/PageTitle';
import { CopyIcon } from 'components/common/SVGIcon';
import { resetJobListingPayload } from 'redux/jobs/jobListings/slice';
import useRolesJobTemplates from 'containers/Users/hooks/useRoleJobTemplates';
import { CreateTemplateActionWrapper } from './styles';

const CreateTemplateAction = () => {
  const { push } = useHistory();
  const dispatch = useDispatch();

  const isDisabledCreateJobTemplate = useRolesJobTemplates();

  const onClickCreateJobListing = () => {
    dispatch(resetJobListingPayload());
    push(`/job-listings/create/choose-existing-job-template`);
  };

  const onClickCreateTemplate = () => {
    push(`/jobTemplates/create/step`);
  };

  return (
    <CreateTemplateActionWrapper className="flex">
      <PageTitle>{i18next.t('jobListings.startNewJobListing')}</PageTitle>
      <Button className="start-btn flex" onClick={onClickCreateJobListing}>
        <span className="btn-content">
          <CopyIcon />
          <span>
            {i18next.t('jobListings.createListingFromExistingTemplate')}
          </span>
        </span>
        <RightOutlined />
      </Button>
      <Button
        className="start-btn flex"
        onClick={onClickCreateTemplate}
        disabled={isDisabledCreateJobTemplate}
      >
        <span className="btn-content">
          <PlusCircleOutlined />
          <span>{i18next.t('jobListings.createNewTemplate')}</span>
        </span>
        <RightOutlined />
      </Button>
    </CreateTemplateActionWrapper>
  );
};

export default CreateTemplateAction;

import React, { useCallback } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { notification, Modal } from 'antd';
import { enableFeaturedMarketingApi } from 'api/marketings';
import i18next from 'i18next';
import PropTypes from 'prop-types';
import { useParams } from 'react-router';
import { useDispatch } from 'react-redux';
import { delFeaturedMarketing } from 'redux/centres/actions';
import SwitchAction from 'components/RestActions/SwitchAction';

const { confirm } = Modal;

const EnableFeaturedAction = ({ record, marketingId, marketingIds }) => {
  const { id: centreId } = useParams();
  const dispatch = useDispatch();

  const valueChecked = marketingIds?.includes(marketingId) || false;

  const showError = useCallback((message) => {
    notification.error({
      message: i18next.t('error.title'),
      description: message || i18next.t('error.description'),
    });
  }, []);

  const showConfirm = () => {
    if (valueChecked) {
      confirm({
        title: i18next.t('marketing.delFeaturedTitleConfirm'),
        content: i18next.t('marketing.delFeaturedDescConfirm'),
        onOk() {
          return new Promise((resolve) => {
            if (centreId && marketingId) {
              resolve(
                dispatch(delFeaturedMarketing({ centreId, marketingId })),
              );
            }
          });
        },
      });
    } else {
      confirm({
        title: i18next.t('marketing.enabledFeaturedTitleConfirm'),
        content: i18next.t('marketing.enabledFeaturedDescConfirm'),
        onOk() {
          return new Promise((resolve) => {
            if (centreId && marketingId) {
              resolve(handleSignUp());
            }
          });
        },
      });
    }
  };

  const handleSignUp = () =>
    enableFeaturedMarketingApi(centreId, marketingId, {
      successUrl: `${window.location.origin}/centres/${centreId}/marketings/${marketingId}/sign-up-results`,
      cancelUrl: `${window.location.origin}/centres/${centreId}/show/marketings`,
    })
      .then(async (response) => {
        if (response?.id) {
          const stripe = await loadStripe(
            process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY,
          );

          stripe
            .redirectToCheckout({
              sessionId: response.id,
            })
            .then((result) => {
              if (result.error) {
                notification.error({
                  message: i18next('error.title'),
                  description: i18next('error.description'),
                });
              }
            });
        } else {
          showError(response?.message);
        }
      })
      .catch((response) => {
        showError(response?.message);
      });

  return (
    <SwitchAction
      packageName={record?.CRMPackage?.name}
      checked={valueChecked}
      onChange={showConfirm}
      permissionKey="marketing"
      action="isEdit"
    />
  );
};

EnableFeaturedAction.propTypes = {
  marketingId: PropTypes.string,
  marketingIds: PropTypes.array,
  record: PropTypes.object,
};

export default EnableFeaturedAction;

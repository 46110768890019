import i18next from 'i18next';
import React, { useState, useCallback, useMemo } from 'react';
import { FormInstance } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { setCreateAdPayload } from 'redux/advertisement/slice';
import {
  EXISTING_AUDIENCE_TAB_KEY,
  NEW_AUDIENCE_TAB_KEY,
} from 'containers/Advertisement/constants';
import { getCreateAdPayload } from 'redux/advertisement/selectors';
import { StepRef } from 'containers/Advertisement/Create';
import { StepTitle } from '../styles';
import Footer from './Footer';
import { Container } from './styles';
import AudienceTabsForm from './AudienceTabsForm';

interface Props {
  form: FormInstance;
}

const TargetedAudience = ({ form }: Props, ref: React.RefObject<StepRef>) => {
  const dispatch = useDispatch();
  const { audienceActiveTab, targetedAudience } =
    useSelector(getCreateAdPayload);

  const [activeTab, setActiveTab] = useState(
    audienceActiveTab ?? NEW_AUDIENCE_TAB_KEY,
  );

  const fieldsNeedValidate = useMemo(
    () =>
      activeTab === EXISTING_AUDIENCE_TAB_KEY
        ? ['id']
        : ['userType', 'userLocation', 'userLocationValues'],
    [activeTab],
  );

  const handleFinish = useCallback(
    async () =>
      form.validateFields(fieldsNeedValidate).then(({ id, ...values }) => {
        const payload = id ? { id } : { ...values };
        const audienceActiveTab = id
          ? EXISTING_AUDIENCE_TAB_KEY
          : NEW_AUDIENCE_TAB_KEY;
        dispatch(
          setCreateAdPayload({ targetedAudience: payload, audienceActiveTab }),
        );
      }),
    [fieldsNeedValidate, dispatch, form],
  );

  React.useImperativeHandle(
    ref,
    () => ({
      fieldsNeedValidate,
    }),
    [fieldsNeedValidate],
  );

  return (
    <Container>
      <StepTitle>{i18next.t('advertisement.steps.step4')}</StepTitle>
      <AudienceTabsForm
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        form={form}
        handleFinish={handleFinish}
        initialValues={targetedAudience}
      />
      <Footer onNext={handleFinish} />
    </Container>
  );
};

export default React.forwardRef(TargetedAudience);

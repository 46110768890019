import { PREFIX_API_VER_2 } from 'configs/localData/constant';
import { keyBy } from 'lodash-es';
import { getValidData, convertRequestParamsAddEdit } from '../../utils/tools';

export const PRIMARY_KEY = 'id';

const formatOrderBy = (isOrderBy = true, orderBy) => {
  if (orderBy) return orderBy;

  if (isOrderBy) return '-createdAt';

  return undefined;
};

export const convertRequestParams = (
  type,
  params,
  resource,
  options = { isTrimStr: false, isOrderBy: true },
) => {
  const formattedParams = {
    ...params,
    page: options?.prefixApi ? params.page : undefined,
    count: undefined,
  };

  const paramsAddEdit = { ...params };

  const filter = getValidData(formattedParams.filter, true);

  switch (type) {
    case 'GET_ALL':
      return {
        ...formattedParams,
        orderBy:
          options?.prefixApi === PREFIX_API_VER_2
            ? null
            : formatOrderBy(options?.isOrderBy, formattedParams.orderBy),
        filter:
          Object.keys(filter).length > 0 ? JSON.stringify(filter) : undefined,
      };
    case 'GET_BY_ID':
      return {
        ...params,
        [PRIMARY_KEY]: Number(params[PRIMARY_KEY]),
      };
    case 'EDIT':
      delete paramsAddEdit.id;
      return convertRequestParamsAddEdit(paramsAddEdit, options.isTrimStr);
    case 'CREATE':
      return convertRequestParamsAddEdit(paramsAddEdit, options.isTrimStr);
    case 'DELETE':
    default:
      return {};
  }
};

export const convertResponseData = (
  type,
  response,
  options = { primaryKey: PRIMARY_KEY },
) => {
  const { results, total, ...rest } = response || {};
  switch (type) {
    case 'GET_ALL':
      return {
        data: keyBy(
          results?.map((data) => ({
            ...data,
            [options.primaryKey]: data[options.primaryKey || PRIMARY_KEY],
            backupId: data[PRIMARY_KEY],
          })),
          options.primaryKey || PRIMARY_KEY,
        ),
        ids: results?.map((data) => data[options.primaryKey || PRIMARY_KEY]),
        total,
        additionData: rest,
      };
    case 'GET_BY_ID':
    case 'CREATE':
      return response
        ? {
            ...response,
            [options.primaryKey]: response[options.primaryKey || PRIMARY_KEY],
          }
        : null;
    case 'EDIT':
      return response && response ? { ...response } : null;
    case 'DELETE':
    default:
      return response;
  }
};

import { pick } from 'lodash-es';
import { EXISTING_AUDIENCE_TAB_KEY, NEW_AUDIENCE_TAB_KEY } from './constants';

export const transformDataStructureStepsForm = (adItem) => {
  const { type, duration, callingButton, targetedAudience } = adItem;

  const restTemplate = pick(adItem, [
    'isSocialRelated',
    'isChildcareRelated',
    'description',
    'mediaList',
    'headline',
  ]);

  return {
    ...restTemplate,
    callingButton: callingButton?.id,
    destination: callingButton?.destination,
    duration: {
      durationDays: duration?.durationDays,
      durationHours: duration?.durationHours,
      from: duration?.startTime,
      to: duration?.endTime,
    },
    audienceActiveTab: targetedAudience?.isShared
      ? EXISTING_AUDIENCE_TAB_KEY
      : NEW_AUDIENCE_TAB_KEY,
    targetedAudience: {
      id: targetedAudience?.id,
      userType: targetedAudience?.userType,
      userLocation: targetedAudience?.userLocation,
      userLocationValues: targetedAudience?.userLocationValue,
    },
    type: type?.id,
  };
};

import React from 'react';
import PropTypes from 'prop-types';
import { Steps } from 'antd';
import { JOB_TEMPLATE_STEPS } from 'containers/JobTemplates/constants';
import i18next from 'i18next';

const JobTemplateSteps = ({ currentStep, onChange }) => (
  <div>
    <Steps direction="vertical" current={currentStep} onChange={onChange}>
      {JOB_TEMPLATE_STEPS?.map((item, index) => (
        <Steps.Step
          title={i18next.t(item?.title)}
          key={Number(index)}
          className="mb-30"
        />
      ))}
    </Steps>
  </div>
);

JobTemplateSteps.propTypes = {
  currentStep: PropTypes.number,
  onChange: PropTypes.func,
};

export default JobTemplateSteps;

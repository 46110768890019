import React from 'react';
import PropTypes from 'prop-types';
import { Space } from 'antd';
import { useHistory } from 'react-router';
import EditButton from 'components/RestActions/EditButton';

const GroupActions = ({ customerType, id }) => {
  const { push, location } = useHistory();

  const redirectDetailPage = () => {
    id && push(`${location.pathname}/${id}/show`);
  };

  const redirectEditPage = () => {
    id &&
      push({
        hash: `#contacts/${id}/edit`,
      });
  };

  return (
    <Space>
      {customerType === 'AUTO' ? (
        <EditButton
          onClickCustom={redirectDetailPage}
          isView
          permissionKey="contacts"
          action="isView"
        />
      ) : (
        <EditButton
          permissionKey="contacts"
          action="isEdit"
          onClickCustom={redirectEditPage}
        />
      )}
    </Space>
  );
};

GroupActions.propTypes = {
  customerType: PropTypes.string,
  id: PropTypes.string,
};

export default GroupActions;

import React from 'react';
import { Avatar, Tag, Tooltip } from 'antd';
import i18next from 'i18next';
import { CloseOutlined, UserOutlined } from '@ant-design/icons';

import { ENQUIRY_TYPES_CONST } from 'configs/localData';
import { useSelector } from 'react-redux';
import { getCurrentEnquiry } from 'redux/enquiries/selectors';
import {
  MailIcon,
  PhoneIcon,
  PreferredContactMethodOutlineIcon,
} from 'components/common/SVGIcon';
import { useHistory } from 'react-router';
import EmptyData from 'components/common/EmptyData';
import { useEnquiryContext } from 'containers/Enquiries/List/context';
import { getCentresObjKeyById } from 'redux/auth/selectors';
import CheckPermission from 'components/common/CheckPermissions';
import HeaderUserInfoEnquiryFallback from 'components/RestActions/HeaderUserInfoEnquiry';
import ChildrenItem from './ChildrenItem';
import ContactEnquiryStyles from './styles';

const ContactEnquiry = () => {
  const history = useHistory();
  const { user, enquiryType, parentRequestDetails, centreId } =
    useSelector(getCurrentEnquiry) || {};

  const { setCollapsed } = useEnquiryContext();

  const packageName =
    useSelector(getCentresObjKeyById)?.[centreId]?.centreSubscription
      ?.CRMPackage?.name;

  return (
    <ContactEnquiryStyles>
      {user?.id ? (
        <>
          <div className="contact-enquiry__header flex-center-between">
            <div className="user-info">
              <CheckPermission
                packageName={packageName}
                permissionKey="contacts"
                action="isEdit"
                isDisabled
                fallback={
                  <HeaderUserInfoEnquiryFallback user={user} isContactSession />
                }
              >
                <div
                  className="left-section flex items-center cursor-pointer"
                  role="presentation"
                  onClick={() =>
                    user?.contactId &&
                    history.push(`/contacts/list/${user.contactId}/show`)
                  }
                >
                  <Avatar
                    className="mr-10"
                    size={40}
                    src={user?.avatar}
                    icon={<UserOutlined />}
                  />
                  <div className="info-section">
                    <div className="section-title">
                      {`${user?.firstName || ''} ${user?.lastName || ''}`}
                    </div>
                  </div>
                </div>
              </CheckPermission>
            </div>

            <CloseOutlined
              className="close-icon pl-10 cursor-pointer"
              onClick={() => setCollapsed(true)}
            />
          </div>

          <div className="contact-enquiry__body">
            <div className="contact-enquiry__section">
              <div className="contact-enquiry__section-title">
                {i18next.t('title.about')}
              </div>

              <div className="contact-enquiry__section-info">
                <div className="item-info">
                  <PhoneIcon />
                  <span className="item-info__value">
                    {user?.phoneNumber || i18next.t('error.waitingUpdate')}
                  </span>
                </div>

                <div className="item-info">
                  <MailIcon />
                  <span className="item-info__value ellipsis-t">
                    {user?.email || i18next.t('error.waitingUpdate')}
                  </span>
                </div>

                <div className="item-info">
                  <PreferredContactMethodOutlineIcon />
                  <Tooltip
                    title={i18next.t('enquiries.preferredContactMethod')}
                  >
                    <span className="item-info__value ellipsis-t preferred-contact-method">
                      {user?.preferredContactMethod ||
                        i18next.t('error.waitingUpdate')}
                    </span>
                  </Tooltip>
                </div>
              </div>
            </div>

            <div className="contact-enquiry__section">
              <div className="contact-enquiry__section-title">
                {i18next.t('enquiries.enquiryReason')}
              </div>

              <div className="contact-enquiry__section-info">
                <div className="enquiry-type">
                  {ENQUIRY_TYPES_CONST[enquiryType?.name] && (
                    <Tag color={ENQUIRY_TYPES_CONST[enquiryType.name].color}>
                      {i18next.t(ENQUIRY_TYPES_CONST[enquiryType.name].text)}
                    </Tag>
                  )}
                </div>
              </div>
            </div>

            {parentRequestDetails?.length ? (
              <div className="contact-enquiry__section">
                <div className="contact-enquiry__section-title">
                  {i18next.t('applications.childInformation')}
                </div>

                <div className="contact-enquiry__section-info">
                  {parentRequestDetails.map((item, idx) => (
                    <ChildrenItem key={String(idx)} item={item} />
                  ))}
                </div>
              </div>
            ) : null}
          </div>
        </>
      ) : (
        <EmptyData className="p-12" />
      )}
    </ContactEnquiryStyles>
  );
};

export default ContactEnquiry;

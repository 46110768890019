import { createSlice } from '@reduxjs/toolkit';
import {
  getAllCustomFail,
  getAllCustomPending,
  getAllCustomSuccess,
  INITIAL_STATE_CUSTOM,
  resetResourceDataCustom,
} from 'redux/crudCreatorCustom/utils';
import { getCentresOfUser } from './actions';

export const initialState = {
  ...INITIAL_STATE_CUSTOM,
};

const { actions, reducer } = createSlice({
  name: 'CentresOfUser',
  initialState,
  reducers: {
    resetResourceFilterCentresOfUser: resetResourceDataCustom,
  },
  extraReducers: {
    [getCentresOfUser.pending]: getAllCustomPending,
    [getCentresOfUser.fulfilled]: getAllCustomSuccess,
    [getCentresOfUser.rejected]: getAllCustomFail,
  },
});

export const { resetResourceFilterCentresOfUser } = actions;

export default reducer;

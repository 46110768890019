import TooltipBarChart from 'components/charts/TooltipBarChart';
import LegendBarChart from 'components/charts/LegendBarChart';
import React from 'react';
import {
  Bar,
  CartesianGrid,
  ComposedChart,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import PropTypes from 'prop-types';
import i18next from 'i18next';
import { isEmpty } from 'lodash-es';
import CardCustom from 'containers/CompanyBenchmarking/components/CardCustom';

const ServiceApprovalBarChart = ({ title, resultServiceApprovals }) =>
  !isEmpty(resultServiceApprovals) && (
    <CardCustom title={title}>
      <div align="middle" className="flex-column-center h-full">
        <ResponsiveContainer height={380}>
          <ComposedChart layout="vertical" data={resultServiceApprovals}>
            <CartesianGrid stroke="#EBECF0" horizontal={false} />
            <XAxis type="number" tickLine={false} stroke="#ABA7A7" />
            <YAxis
              dataKey="year"
              type="category"
              tickCount={10}
              tickLine={false}
              stroke="#ABA7A7"
            />
            <Tooltip content={<TooltipBarChart />} />
            <Legend content={<LegendBarChart />} />
            <Bar
              dataKey="existenceCentres"
              stackId=""
              barSize={8}
              fill="#26C1FC"
              name={i18next.t(
                'companyBenchmarking.userCompanyCharts.existingCentres',
              )}
            />
            <Bar
              dataKey="newApprovedCentres"
              stackId=""
              barSize={8}
              fill="#FF73C3"
              name={i18next.t(
                'companyBenchmarking.userCompanyCharts.newApprovedCentres',
              )}
            />
          </ComposedChart>
        </ResponsiveContainer>
      </div>
    </CardCustom>
  );

ServiceApprovalBarChart.propTypes = {
  title: PropTypes.string,
  resultServiceApprovals: PropTypes.array,
};

export default ServiceApprovalBarChart;

import React, { useState, useMemo, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { List, Skeleton } from 'antd';
import { isEmpty } from 'lodash-es';
import { Waypoint } from 'react-waypoint';
import { useSelector } from 'react-redux';
import { getCentreId } from 'redux/auth/selectors';

import TaskItem from './TaskItem';
import { ListTaskStyles } from './styles';

const limitDefault = 10;

const ListTask = ({ isOverdue, action }) => {
  const centreId = useSelector(getCentreId);

  const [loading, setLoading] = useState(false);

  const [tasks, setTasks] = useState({
    offset: 0,
    total: 0,
    data: [],
  });

  const getTasksAction = useCallback(
    ({ offset, isRefresh }) => {
      if (action) {
        setLoading(true);
        action({
          limit: limitDefault,
          orderBy: '-date',
          offset,
        })
          .then((response) => {
            if (isRefresh) {
              setTasks({
                offset: limitDefault,
                total: response.total || 0,
                data: response.results,
              });
            } else {
              setTasks({
                offset: tasks.offset + limitDefault,
                total: response.total || 0,
              });
            }

            setLoading(false);
          })
          .catch(() => {
            setLoading(false);
          });
      }
    },
    [tasks, action],
  ); // eslint-disable-line

  useEffect(() => {
    if (isEmpty(tasks.data)) {
      getTasksAction({ offset: 0 });
    } else {
      getTasksAction({ offset: 0, isRefresh: true });
    }
  }, [centreId]); // eslint-disable-line

  const enabledLoadMore = useMemo(
    () => !loading && tasks?.data?.length < tasks.total,
    [loading, tasks],
  );

  const handleCompleteTask = useCallback(
    (taskId) => {
      setTasks({
        offset: tasks.offset,
        total: tasks.total - 1,
        data: tasks.data?.filter((item) => item.id !== taskId),
      });
    },
    [tasks],
  );

  const handleWaypoint = () => {
    getTasksAction({ offset: tasks.offset });
  };

  const content = useMemo(() => {
    if (loading && isEmpty(tasks.data)) return null;
    return (
      <List
        itemLayout="vertical"
        dataSource={tasks.data}
        renderItem={(item, index) => (
          <TaskItem
            {...(!isOverdue && {
              handleCompleteTask,
            })}
            isOverdue={isOverdue}
            item={item}
            key={String(index)}
          />
        )}
      />
    );
  }, [loading, tasks, handleCompleteTask, isOverdue]);

  return (
    <ListTaskStyles>
      {content}
      {loading && <Skeleton active avatar />}
      {enabledLoadMore && <Waypoint onEnter={handleWaypoint} />}
    </ListTaskStyles>
  );
};

ListTask.propTypes = {
  action: PropTypes.func,
  isOverdue: PropTypes.bool,
};

export default ListTask;

import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'redux/store';

const selectMarketingMetrics = (state: RootState) => state.marketingMetrics;

export const dateOptionSelector = createSelector(
  selectMarketingMetrics,
  (marketingMetrics) => marketingMetrics.dateOption,
);

export const isCompareSelector = createSelector(
  selectMarketingMetrics,
  (marketingMetrics) => marketingMetrics?.isCompare,
);

export const compareOptionSelector = createSelector(
  selectMarketingMetrics,
  (marketingMetrics) => marketingMetrics?.compareOption,
);

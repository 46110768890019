import { post, get, put, del } from './utils';

export async function loginApi(params) {
  return post('/admin/auth/login', params);
}

export async function logoutApi(data) {
  return post('/admin/auth/logout', data);
}

export async function getPermissionsApi() {
  return get('/admin/auth/permission');
}

export async function getPermissionByIdApi(id) {
  return get(`/admin/auth/permission/${id}`);
}

export async function getCurrentUserApi(filter) {
  return get('/admin/users/me', filter);
}

export async function getCurrentUserPermissionsApi() {
  return get('/admin/users/me/rolePermissions');
}

export async function updateCurrentUserApi(data) {
  return put('/admin/users/me', data);
}

export async function createInstallationApi(params) {
  return post('/installations', params);
}

export async function updateInstallationApi(id, params) {
  return put(`/installations/${id}`, params);
}

export async function deleteInstallationApi(id) {
  return del(`/installations/${id}`);
}

export async function forgotPasswordApi(data) {
  return post('/admin/auth/forgotPassword', data);
}

export async function resetPasswordApi(data) {
  return post('/admin/auth/resetPassword', data);
}

export async function changePasswordApi(data) {
  return post('/admin/auth/changePassword', data);
}

export async function registerApi(data) {
  return post('/admin/auth/register', data);
}

export async function registerWithTokenApi(data) {
  return post('/admin/auth/registerByToken', data);
}

export async function disableUserApi(id, isDisabled) {
  return put(`/admin/users/${id}/disable`, { isDisabled });
}

export async function subscribeUserApi(data) {
  return post('/admin/auth/subscribe', data);
}

export const registerClientWithTokenApi = (data) =>
  post('/web/auth/claimCentreRegister', data);

export const verifyRegisterTokenApi = (data) =>
  post('/web/auth/verifyRegisterToken', data);

export async function refreshTokenApi(params) {
  return post('/admin/auth/refreshToken', params);
}

export async function loginClaimCentreApi(params) {
  return post('/web/auth/claimCentreLogin', params);
}

export const verifyResetPasswordTokenApi = (data) =>
  post('/admin/auth/verify-reset-password-token', data);

export async function getCentresOfUserApi(id, data) {
  return get(`/admin/users/${id}/centres`, data);
}

export async function getUnownedCentresOfUserApi(id, data) {
  return get(`/admin/users/${id}/unowned-centres`, data);
}

export async function postCentresOfUserApi(id, data) {
  return post(`/admin/users/${id}/centres`, data);
}

export async function delCentresOfUserApi(id, centreId) {
  return del(`/admin/users/${id}/centres/${centreId}`);
}

export async function getCentresOfMeBasicApi(id, data) {
  return get('/admin/users/me/centres/basic-info', data);
}

export async function getProvidersOfMeBasicApi(id, data) {
  return get(`/admin/users/me/providers/basic-info`, data);
}

export async function getCompaniesOfMeBasicApi(id, data) {
  return get(`/admin/users/me/companies/basic-info`, data);
}

export async function getProvidersOfUserApi(id, data) {
  return get(`/admin/users/${id}/providers`, data);
}

export async function getUnownedProvidersOfUserApi(id, data) {
  return get(`/admin/users/${id}/unowned-providers`, data);
}

export async function postProvidersOfUserApi(id, data) {
  return post(`/admin/users/${id}/providers`, data);
}

export async function delProvidersOfUserApi(id, providerId) {
  return del(`/admin/users/${id}/providers/${providerId}`, null);
}

export async function editRoleUserApi(id, data) {
  return put(`/admin/users/${id}/roles`, data);
}

export async function getUnownedCompaniesOfUserApi(id, data) {
  return get(`/admin/users/${id}/unowned-companies`, data);
}

export async function getCompaniesOfUserApi(id, data) {
  return get(`/admin/users/${id}/companies`, data);
}

export async function postCompaniesOfUserApi(id, data) {
  return post(`/admin/users/${id}/companies`, data);
}

export async function delCompaniesOfUserApi(id, data) {
  return del(`/admin/users/${id}/companies`, data);
}

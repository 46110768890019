import React from 'react';
import PropTypes from 'prop-types';
import CardCustom from 'components/common/CardCustom';
import { BookFillIcon, TeacherFilledIcon } from 'components/common/SVGIcon';
import CardCustomContent from '../../CardContentCustom';
import EducationAndQualificationContent from '../../CardContentCustom/EducationAndQualificationContent';

const MyEducationAndQualifications = ({ educationAndQualifications }) => (
  <div className="mb-32">
    <CardCustom
      title="jobApplications.generalInfo.myEducationAndQualification"
      Icon={TeacherFilledIcon}
    >
      <div>
        {educationAndQualifications?.map((item, index) => (
          <CardCustomContent Icon={BookFillIcon} key={Number(index)}>
            <EducationAndQualificationContent item={item} />
          </CardCustomContent>
        ))}
      </div>
    </CardCustom>
  </div>
);

MyEducationAndQualifications.propTypes = {
  educationAndQualifications: PropTypes.array,
};

export default MyEducationAndQualifications;

import { PREFIX_API_VER_2 } from 'configs/localData/constant';
import { get, put } from './utils';

export async function getConfigApi() {
  return get(`/admin/app/config`);
}

export async function getConfigV2Api(data) {
  return get(`/crm/config`, data, PREFIX_API_VER_2);
}

export async function getSummariesApi() {
  return get('/admin/dashboards');
}

export async function getRevenueApi(data) {
  return put('/admin/revenue', data);
}

export async function getGlobalSaleApi() {
  return get('/admin/global-sales');
}

export async function getSummariesCustomersApi() {
  return get('/admin/summaries-customers');
}
export async function getPopularProductApi() {
  return get('/admin/popular-product');
}

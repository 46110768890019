import React, { useCallback, useState } from 'react';
import { useGetExistingUsersQuery } from 'redux/@rtkQuery/educatorStaffProfile';
import { ExistingUserModel, ExistingUserPayload } from 'types/staffProfiles';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import Footer from 'containers/Centres/components/CentreTabs/EducatorStaffProfilesTab/Create/Footer';
import { Empty, Input, Row, Skeleton, Spin } from 'antd';
import { SearchIcon } from 'components/common/SVGIcon';
import i18next from 'i18next';
import InfiniteScroll from 'react-infinite-scroller';
import { UserListContainerStyle } from 'containers/Centres/components/CentreTabs/EducatorStaffProfilesTab/Create/styles';
import { DEFAULT_CURRENT_PAGE } from 'configs/localData/constant';
import { setStaffProfilePayload } from 'redux/staffProfile/slice';
import { useDispatch } from 'react-redux';
import { debounce, omit } from 'lodash-es';
import { getFilterFromUrl, getSearchUrl } from 'utils/tools';
import iconEmpty from 'assets/images/shape-icon-empty.png';
import ExistingUserItem from './ExistingUserItem';

const ExistingProfiles = () => {
  const dispatch = useDispatch();
  const { centreId } = useParams();
  const { push, goBack, location } = useHistory();
  const { search } = useLocation();
  const { filter } = getFilterFromUrl(search).filter;
  const pathName = location?.pathname;
  const [selectedUser, setSelectedUser] = useState<ExistingUserModel>(null);
  const [payload, setPayload] = useState<ExistingUserPayload>({
    centreId,
    page: DEFAULT_CURRENT_PAGE,
    size: 50,
    filter,
  });
  const { data, isFetching, isLoading } = useGetExistingUsersQuery(payload, {
    refetchOnMountOrArgChange: true,
  });
  const users = data ? data.results : [];

  const handleOnBack = () => {
    goBack();
  };

  const handleOnNext = () => {
    dispatch(
      setStaffProfilePayload({
        basicInfo: {
          ...omit(selectedUser, 'id', 'email'),
          userId: selectedUser.id,
        },
      }),
    );
    push(`/educator-staff-profile/${centreId}/create/steps`);
  };

  const onSearch = useCallback(
    (value) => {
      const searchFilter = {
        filter: {
          name: value,
        },
      };

      setPayload((prev) => ({
        ...prev,
        page: DEFAULT_CURRENT_PAGE,
        ...searchFilter,
      }));
      push(
        `${pathName}?${getSearchUrl({
          ...searchFilter,
        })}`,
      );
    },
    [pathName, push],
  );

  const debounceSearch = debounce(onSearch, 800);
  const loading =
    payload.page === DEFAULT_CURRENT_PAGE && isFetching ? true : isLoading;

  return (
    <div className="flex gap-14 flex-col pb-0">
      <Input
        defaultValue={filter?.name ?? ''}
        placeholder={i18next.t(
          'educatorStaffProfile.placeholder.searchExisting',
        )}
        prefix={<SearchIcon className="text-gray-500 mr-8" />}
        onChange={(e) => {
          const searchValue = e.target?.value;
          debounceSearch(searchValue);
        }}
        allowClear
      />
      <UserListContainerStyle>
        <Skeleton loading={loading}>
          {users?.length ? (
            <InfiniteScroll
              pageStart={0}
              initialLoad={false}
              useWindow={false}
              loader={
                <Row justify="center" key={0}>
                  <Spin spinning={isLoading || isFetching} />
                </Row>
              }
              loadMore={() => {
                if (!isFetching && !isLoading) {
                  setPayload({ ...payload, page: payload.page + 1 });
                }
              }}
              hasMore={users?.length < data?.paging?.total}
            >
              {users?.map((user) => (
                <ExistingUserItem
                  key={user.id}
                  user={user}
                  checked={selectedUser?.id === user.id}
                  setSelectUser={() =>
                    setSelectedUser((prev) =>
                      !!prev && prev.id === user.id ? null : user,
                    )
                  }
                />
              ))}
            </InfiniteScroll>
          ) : (
            <Empty
              image={iconEmpty}
              imageStyle={{ height: 160 }}
              className="text-gray-500 mt-70 mb-48"
              description={i18next.t('error.noResultsFound')}
            />
          )}
        </Skeleton>
      </UserListContainerStyle>
      <Footer
        onBack={handleOnBack}
        onNext={handleOnNext}
        isDisableNextButton={!selectedUser || !users?.length}
      />
    </div>
  );
};

export default ExistingProfiles;

import styled from 'styled-components';

const CardConfigStyles = styled.div`
  .ant-card-meta-description {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .ant-card-meta-title {
    margin-bottom: 0 !important;
  }
`;

export default CardConfigStyles;

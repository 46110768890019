import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import i18next from 'i18next';

import { Badge } from 'antd';
import { ACTIVE_TYPES } from 'configs/localData';

const CentreNameInfo = ({ name, isActive }) => {
  const activeItem = useMemo(
    () => ACTIVE_TYPES.find((item) => item.value === isActive) || {},
    [isActive],
  );

  return (
    <div className="name-centre">
      <span className="title-centre">
        {name || i18next.t('error.waitingUpdate')}
      </span>
      <Badge
        color={activeItem.color}
        text={i18next.t(activeItem.text)}
        style={{ color: activeItem.color }}
      />
    </div>
  );
};

CentreNameInfo.propTypes = {
  name: PropTypes.string,
  isActive: PropTypes.bool,
};

export default CentreNameInfo;

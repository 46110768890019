import React from 'react';
import PropTypes from 'prop-types';

import { Content } from 'antd/lib/layout/layout';
import EULAStyles from './styles';

const EULALayout = ({ children }) => (
  <EULAStyles>
    <Content>{children}</Content>
  </EULAStyles>
);

EULALayout.propTypes = {
  children: PropTypes.node,
};

export default EULALayout;

import styled from 'styled-components';

export const ButtonStyles = styled.div`
  .delete-action-wrapper {
    position: relative;
  }
  .ant-btn {
    width: 100%;
    text-align: left;
  }

  .action-feature-icon {
    position: absolute;
    top: -3px;
    right: -3px;
    font-size: 18px;
    color: ${({ theme }) => theme.subscriptions.colorIcon};
  }
`;

import React, { useEffect } from 'react';
import { Radio, RadioChangeEvent } from 'antd';
import { CHART_RESOLUTION } from 'redux/@rtkQuery/marketingMetris/type';
import { useLocation } from 'react-router-dom';
import { getQueryParams } from 'utils/tools';
import { getMaximumChartResolution } from 'containers/MarketingMetrics/utils';
import { FilterRadioGroup } from './styles';

const CHART_RESOLUTIONS_CONST = Object.freeze({
  DAILY: {
    value: CHART_RESOLUTION.DAILY,
  },
  WEEKLY: {
    value: CHART_RESOLUTION.WEEKLY,
  },
  MONTHLY: {
    value: CHART_RESOLUTION.MONTHLY,
  },
  QUARTERLY: {
    value: CHART_RESOLUTION.QUARTERLY,
  },
  YEARLY: {
    value: CHART_RESOLUTION.YEARLY,
  },
});

interface Props {
  resolution: CHART_RESOLUTION;
  setResolution: React.Dispatch<React.SetStateAction<CHART_RESOLUTION>>;
}

const ChartResolutionFilter = ({ resolution, setResolution }: Props) => {
  const { search } = useLocation();
  const filter = getQueryParams(search)?.filter;
  const { filteredStart, filteredEnd } = filter?.timeRange || {};

  const onChange = (e: RadioChangeEvent) => {
    setResolution(e.target.value);
  };

  useEffect(() => {
    const recommendChartResolution = getMaximumChartResolution({
      filteredStart,
      filteredEnd,
    });

    setResolution(recommendChartResolution);
  }, [filteredStart, filteredEnd, setResolution]);

  return (
    <div className="flex-center-end mt-32 mb-30">
      <FilterRadioGroup onChange={onChange} value={resolution}>
        {Object.values(CHART_RESOLUTIONS_CONST).map(({ value }) => (
          <Radio.Button key={value} value={value}>
            {value}
          </Radio.Button>
        ))}
      </FilterRadioGroup>
    </div>
  );
};

export default ChartResolutionFilter;

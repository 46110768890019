import React from 'react';
import RestInputItem from 'components/RestInput/RestInputItem';

const CreateCampaignForm = () => (
  <RestInputItem
    required
    header="jobListings.campaignName"
    placeholder="jobListings.campaignName"
    source="name"
  />
);

export default CreateCampaignForm;

import React from 'react';
import { Row, Col } from 'antd';
import {
  TotalApplicationsIcon,
  TotalAcceptedIcon,
  TotalValueIcon,
} from 'components/common/SVGIcon';

import SummaryCard from 'components/common/SummaryCardCustom';
import { useApplicationList } from '../../List/context';

const Summaries = () => {
  const { summaries } = useApplicationList();

  const summariesMap = [
    {
      title: 'applications.summaries.totalApplications',
      count: summaries.totalApplications,
      IconComponent: TotalApplicationsIcon,
    },
    {
      title: 'applications.summaries.totalAccepted',
      count: summaries.totalAccepted,
      IconComponent: TotalAcceptedIcon,
    },
    {
      title: 'applications.summaries.totalValue',
      count: summaries.totalValue,
      IconComponent: TotalValueIcon,
      isPerAnnum: true,
    },
  ];

  return (
    <Row gutter={[25, 25]} style={{ marginBottom: 24 }}>
      {summariesMap.map((item, index) => (
        <Col lg={8} md={8} xs={24} key={String(index)}>
          <SummaryCard {...item} />
        </Col>
      ))}
    </Row>
  );
};

export default Summaries;

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import I18n from 'i18next';

import Notifications from './Notifications';
import HeaderWrapper from './styles';
import SearchInput from './SearchInput';
import CentreDropdown from './CentreDropdown';
import UserInfoHeader from './UserInfo';

const Header = ({ searchHeader }) => {
  const [visible, setVisible] = useState(false);
  const toggleDrawer = () => {
    setVisible(!visible);
  };

  useEffect(() => {
    I18n.changeLanguage('en');
  }, []);

  return (
    <HeaderWrapper className="header">
      <div className="leftHeader">
        <div className="title">{I18n.t('appInfo.name')}</div>
        <CentreDropdown />
        {searchHeader?.actionSearch && (
          <SearchInput searchHeader={searchHeader} />
        )}
      </div>
      <div className="rightHeader">
        <UserInfoHeader />
        <Notifications
          closable={false}
          onClose={toggleDrawer}
          visible={visible}
        />
      </div>
    </HeaderWrapper>
  );
};
Header.propTypes = {
  searchHeader: PropTypes.object,
};

export default Header;

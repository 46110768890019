import BoardList from 'containers/JobApplications/components/BoardList';
import JobApplicationList from 'containers/JobApplications/components/TableListView';
import {
  BOARD_VALUE,
  DEFAULT_SORT_JOB_APPLICATIONS,
} from 'containers/JobApplications/constants';
import {
  DEFAULT_CURRENT_PAGE,
  LIMIT_ITEM,
} from 'containers/JobListings/constants';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router';
import { getConfigV2 } from 'redux/config/actions';
import { getAllJobApplications } from 'redux/jobs/jobApplications/actions';
import {
  getDataJobApplications,
  getLoadingJobApplications,
} from 'redux/jobs/jobApplications/selectors';
import { getFilterFromUrl, getSearchUrl } from 'utils/tools';
import HeaderFilter from './components/HeaderFilter';

const ApplicationsTab = () => {
  const dispatch = useDispatch();
  const { id, viewModeDetail } = useParams();
  const jobApplicationsData = useSelector(getDataJobApplications);

  const loading = useSelector(getLoadingJobApplications);
  const { search } = useLocation();
  const activeTab = viewModeDetail || BOARD_VALUE;

  const { filter = {} } = getFilterFromUrl(search);
  const filterRef = useRef(filter);
  const { location, push } = useHistory();

  const [filterData, setFilterData] = useState(filter);
  const retrieveList = useCallback(
    ({ filter = {}, isRefresh, keepFilter }) => {
      dispatch(
        getAllJobApplications({
          data: {
            ...filter,
            filter: {
              jobPostId: id,
              ...filter.filter,
            },
          },
          options: {
            keepFilter,
            isRefresh,
          },
        }),
      );
    },
    [dispatch, id],
  );

  useEffect(() => {
    retrieveList({
      filter: {
        sorts: filterRef.current.sorts || [DEFAULT_SORT_JOB_APPLICATIONS],
        page: filterRef.current.page || DEFAULT_CURRENT_PAGE,
        size: filterRef.current.size || LIMIT_ITEM,
        filter: filterRef.current.filter,
      },
      isRefresh: true,
      keepFilter: true,
    });
    dispatch(getConfigV2());
  }, [retrieveList, dispatch]);

  const pushQuery = useCallback(
    (searchStr) => {
      push(`${location.pathname}?${getSearchUrl(searchStr)}`);
    },
    [location.pathname, push],
  );

  return (
    <div>
      <HeaderFilter
        pushQuery={pushQuery}
        retrieveList={retrieveList}
        activeTab={activeTab}
        setFilterData={setFilterData}
      />
      {activeTab === BOARD_VALUE ? (
        <BoardList isApplicationOfAJob jobPostId={id} filterData={filterData} />
      ) : (
        <JobApplicationList
          jobApplicationsData={jobApplicationsData}
          loading={loading}
          pushQuery={pushQuery}
          retrieveList={retrieveList}
          setFilterData={setFilterData}
        />
      )}
    </div>
  );
};

export default ApplicationsTab;

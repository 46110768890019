export const PROFILE_START_SCREEN_PARAM = 'start';

export const EXISTING_PROFILE_PARAM = 'existing-users';

export const CREATE_PROFILE_STEPS = [
  { title: 'educatorStaffProfile.basicInformationForm.title', required: true },
  { title: 'educatorStaffProfile.aboutTheEducatorStaff', required: false },
  { title: 'educatorStaffProfile.educationAndQualification', required: true },
  { title: 'educatorStaffProfile.awards', required: false },
];

export const PROFILE_STEP = {
  BASIC_INFORMATION: 0,
  ABOUT_STAFF: 1,
  EDUCATION_QUALIFICATION: 2,
  AWARDS: 3,
};

export const OTHER = 'Other';

export const YEAR_OF_EXPERIENCES_SELECTIONS = [
  { id: '1', name: "Less than 1 Year's Experience", value: '0' },
  { id: '2', name: "1+ Year's Experience", value: '1' },
  { id: '3', name: "2+ Year's Experience", value: '2' },
  { id: '4', name: "3+ Year's Experience", value: '3' },
  { id: '5', name: "5+ Year's Experience", value: '5' },
  { id: '6', name: "10+ Year's Experience", value: '10' },
];

export const MAX_BASIC_INFO_CHARS_LENGTH = 255;

export const ITEM_EDUCATION_AT_LEAST = 1;

export const STAFF_PROFILE_SAVE_EXIT_NO_ASK = 'staffProfileSaveExitNoAsk';

import React from 'react';
import { FilterContainer } from 'containers/LeadActivities/components/Filter/styles';
import FilterCustom from 'components/common/FilterCustom';
import { RestInputContext } from 'components/RestInput/RestInputContext';
import GridCards from 'components/GridCards';
import RestSelect from 'components/RestInput/RestSelect';
import i18next from 'i18next';
import InfiniteAutoComplete from 'components/InfiniteFormItem/InfiniteAutoComplete';
import { useHistory, useLocation } from 'react-router';
import {
  getServiceAgeGroups,
  getServiceTypes,
  getSessionTypes,
  getStates,
} from 'redux/config/selectors';
import { useSelector } from 'react-redux';
import ReferenceInput from 'containers/rest/ReferenceInput';
import { FORMAT_DATE_PICKER, PREFIX_URL } from 'configs/localData/constant';
import { AutoComplete, Empty } from 'antd';
import { getCentresOfUser } from 'redux/auth/selectors';
import moment from 'moment';
import { convertSearchUrl, getDateMoment, getFilterFromUrl } from 'utils/tools';
import { formatFromToTimeFilterSubmit } from 'utils/dataUtils';
import { omit } from 'lodash-es';
import { useProgramNameControllerGetProgramNameQuery } from 'generated/apis';
import { PROGRAM_NAME_VALUE } from 'containers/ProgramsAndLearning/constants';
import RestRangePickerInput from 'components/RestInput/RestRangePickerInput';

interface Props {
  resetPage: () => void;
}

const Filter = ({ resetPage }: Props) => {
  const { push } = useHistory();

  const { search } = useLocation();
  const { filter } = getFilterFromUrl(search);

  const serviceTypes = useSelector(getServiceTypes);
  const sessionTypes = useSelector(getSessionTypes);
  const serviceAgeGroups = useSelector(getServiceAgeGroups);
  const centresData = useSelector(getCentresOfUser);
  const statesData = useSelector(getStates);

  const onSubmitFilter = (values) => {
    resetPage();
    const createdAt = formatFromToTimeFilterSubmit(values?.createdAt);
    const updatedAt = formatFromToTimeFilterSubmit(values?.updatedAt);

    const filterParams = {
      ...values,
      createdAt,
      updatedAt,
      page: 1,
    };

    push({
      search: `?${convertSearchUrl({
        ...omit(filterParams, ['page']),
      })}`,
    });
  };
  const onClearFilter = () => {
    resetPage();

    push({
      search: `?`,
    });
  };

  const rangesFooterCustom = {
    [i18next.t('dateTimePickerText.today')]: [
      moment().startOf('day'),
      moment().endOf('day'),
    ],
    [i18next.t('dateTimePickerText.yesterday')]: [
      moment().subtract(1, 'days').startOf('day'),
      moment().subtract(1, 'days').endOf('day'),
    ],
    [i18next.t('dateTimePickerText.last7Days')]: [
      moment().subtract(7, 'days').startOf('day'),
      moment().subtract(1, 'days').endOf('day'),
    ],
    [i18next.t('dateTimePickerText.last30Days')]: [
      moment().subtract(30, 'days').startOf('day'),
      moment().subtract(1, 'days').endOf('day'),
    ],
    [i18next.t('dateTimePickerText.last12Months')]: [
      moment().subtract(1, 'days').subtract(12, 'months').startOf('day'),
      moment().subtract(1, 'days').endOf('day'),
    ],
  };

  const disabledDate = (current) => {
    const today = new Date();
    return current && current > today;
  };
  return (
    <FilterContainer className="mb-32">
      <FilterCustom
        onSubmitFilter={onSubmitFilter}
        onClearFilter={onClearFilter}
        initialValues={{
          ...filter,
          updatedAt: [
            getDateMoment(filter?.updatedAt?.from),
            getDateMoment(filter?.updatedAt?.to),
          ],
          createdAt: [
            getDateMoment(filter?.createdAt?.from),
            getDateMoment(filter?.createdAt?.to),
          ],
        }}
        responsiveFilter={{
          xl: 20,
          xs: 24,
        }}
        responsiveAction={{
          xl: 4,
          xs: 24,
        }}
      >
        <RestInputContext.Consumer>
          {() => (
            <GridCards
              gap="8px"
              size={[8, 10]}
              columns={{ sm: 1, md: 2, xxl: 5 }}
            >
              <InfiniteAutoComplete
                name="programName"
                showSearch
                filterOption={false}
                useQuery={useProgramNameControllerGetProgramNameQuery}
                placeholder={i18next.t('programLearning.programName')}
                searchKey={PROGRAM_NAME_VALUE}
                labelProp={PROGRAM_NAME_VALUE}
                valueProp={PROGRAM_NAME_VALUE}
              />

              <RestSelect
                source="serviceTypeId"
                placeholder="centres.serviceType"
                valueProp="id"
                titleProp="name"
                resourceData={serviceTypes}
                isShowTooltip
                autoComplete="off"
              />
              <RestSelect
                source="ageGroupId"
                placeholder="centres.ageGroup"
                resourceData={serviceAgeGroups}
                isShowTooltip
                autoComplete="off"
                titleProp="name"
                valueProp="id"
              />
              <RestSelect
                source="sessionTypeId"
                placeholder="centres.sessionType"
                resourceData={sessionTypes}
                isShowTooltip
                autoComplete="off"
                titleProp="name"
                valueProp="id"
              />

              <RestSelect
                SelectElement={AutoComplete}
                source="centreName"
                placeholder="centres.centreName"
                valueProp="name"
                titleProp="name"
                resourceData={centresData || []}
                isShowTooltip
                autoComplete="off"
                notFoundContent={<Empty />}
              />
              <ReferenceInput
                prefixUrl={PREFIX_URL.admin}
                resource="users/me/providers/basic-info"
                source="providerName"
                searchKey="name"
                notLikeFilter
                initialFilter={{ orderBy: 'name' }}
                autoComplete="off"
              >
                <RestSelect
                  SelectElement={AutoComplete}
                  titleProp="name"
                  valueProp="name"
                  placeholder="centres.provider"
                  isFilterOption={false}
                  autoComplete="off"
                  isShowTooltip
                  notFoundContent={<Empty />}
                />
              </ReferenceInput>
              <ReferenceInput
                prefixUrl={PREFIX_URL.admin}
                source="brandName"
                resource="brands"
                searchKey="brand.name"
                initialFilter={{ orderBy: 'name' }}
                autoComplete="off"
              >
                <RestSelect
                  SelectElement={AutoComplete}
                  titleProp="name"
                  valueProp="name"
                  placeholder="input.brand.name"
                  isFilterOption={false}
                  autoComplete="off"
                  isShowTooltip
                />
              </ReferenceInput>
              <RestSelect
                source="state"
                placeholder="centres.state"
                resourceData={statesData}
                isShowTooltip
                autoComplete="off"
              />

              <RestRangePickerInput
                source="createdAt"
                placeholder="common.createdAt"
                isShowTooltip
                sourceGt="createdAt.from"
                sourceLt="createdAt.to"
                isShowRangesFooter
                rangesFooterCustom={rangesFooterCustom}
                disabledDate={disabledDate}
                formatDate={FORMAT_DATE_PICKER}
              />
              <RestRangePickerInput
                source="updatedAt"
                placeholder="common.lastUpdated"
                isShowTooltip
                sourceGt="updatedAt.from"
                sourceLt="updatedAt.to"
                isShowRangesFooter
                rangesFooterCustom={rangesFooterCustom}
                disabledDate={disabledDate}
                formatDate={FORMAT_DATE_PICKER}
              />
            </GridCards>
          )}
        </RestInputContext.Consumer>
      </FilterCustom>
    </FilterContainer>
  );
};

export default Filter;

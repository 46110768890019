import { Modal } from 'antd';
import CheckPermission from 'components/common/CheckPermissions';
import React from 'react';
import DeleteButtonWrapper from 'components/RestActions/DeleteButton/DeleteButtonWrapper';
import { useTranslation } from 'react-i18next';
import DeleteBtnFallback from './DeleteBtnFallback';
import { DeleteButtonStyles } from './styles';

const { confirm } = Modal;

interface RecordType {
  id?: string;
  [key: string]: unknown;
}

interface Props {
  deleteItem?: (id?: string, record?: RecordType) => void;
  record?: RecordType;
  resource?: string;
  customMessage?: string;
  permissionKey?: string;
  action?: string;
  onClickCustom?: () => void;
  isSkipSubscription?: boolean;
  packageName?: string;
  disabled?: boolean;
  iconCustom?: JSX.Element;
}

const DeleteButton = ({
  deleteItem,
  record,
  resource,
  customMessage,
  permissionKey,
  action,
  onClickCustom,
  isSkipSubscription,
  packageName,
  disabled = false,
  iconCustom,
}: Props) => {
  const { t } = useTranslation();
  const handleDelete = () => {
    if (onClickCustom) onClickCustom();
    else {
      confirm({
        title: `${t('popup.alertDelete')} ${t(`${resource}.titleDelete`)}`,
        content: t('popup.alertDeleteDes', {
          customMessage: customMessage ? t(customMessage) : `${record.id}`,
        }),
        okText: t('button.ok'),
        cancelText: t('button.cancel'),
        onOk: () => deleteItem(record?.id, record),
        onCancel: () => {},
      });
    }
  };
  return (
    <DeleteButtonStyles className="delete-div-button">
      <CheckPermission
        packageName={packageName}
        permissionKey={permissionKey}
        action={action}
        isSkipSubscription={isSkipSubscription}
        isDisabled
        fallback={<DeleteBtnFallback />}
      >
        <DeleteButtonWrapper
          disabled={disabled}
          handleDelete={handleDelete}
          iconCustom={iconCustom}
        />
      </CheckPermission>
    </DeleteButtonStyles>
  );
};

export default DeleteButton;

import React from 'react';
import i18next from 'i18next';
import PropTypes from 'prop-types';
import { LockOutlined } from '@ant-design/icons';
import { EMAIL_FEEDBACK } from 'configs/localData/constant';

import ResultWrapper from '../ResultWrapper';

const ResultComingSoon = ({ description }) => (
  <ResultWrapper
    icon={<LockOutlined />}
    title={i18next.t('comingSoon.text')}
    subTitle={
      <div>
        {i18next.t('comingSoon.description', { feature: description })}
        <a href={`mailto:${EMAIL_FEEDBACK}`}>{EMAIL_FEEDBACK}</a>
      </div>
    }
  />
);

ResultComingSoon.propTypes = {
  description: PropTypes.string,
};

export default ResultComingSoon;

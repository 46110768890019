import { createAsyncThunk } from '@reduxjs/toolkit';
import { apiWrapper } from 'utils/reduxUtils';
import {
  editAboutUsCardApi,
  editEducatorandStaffDiscountsApi,
  editSocialMediaChannelsApi,
  getAllSocialMediaChannelsApi,
  getCompanyProfileApi,
  updateCompanyProfileMediaApi,
  editCompanyProfileApi,
  addCompanyVirtualTourApi,
  getCompanyVirtualTourApi,
} from 'api/companyProfile';
import i18next from 'i18next';
import { notification } from 'antd';

export const MODEL_NAME = 'companyProfile';

export interface VirtualTourUrlsProps {
  uid: string;
  url: string;
  originalUrl: string;
  value: string;
  isHaveViewFullScreenButton: boolean;
  id: string;
}

interface PayloadProps {
  data?: object;
  companyId?: string;
  isShowSuccess?: boolean;
  virtualTourUrls?: VirtualTourUrlsProps[];
}

export const getCompanyProfile = createAsyncThunk(
  `${MODEL_NAME}/getCompanyProfile`,
  async (payload, thunkApi) => {
    try {
      const response = await apiWrapper({}, getCompanyProfileApi);

      return response;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  },
);

export const editEducatorandStaffDiscounts = createAsyncThunk(
  `${MODEL_NAME}/editEducatorandStaffDiscounts`,
  async (payload, thunkApi) => {
    try {
      return await apiWrapper(
        { isShowSuccessNoti: true },
        editEducatorandStaffDiscountsApi,
        payload,
      );
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  },
);

export const editAboutUsCard = createAsyncThunk(
  `${MODEL_NAME}/editAboutUsCard`,
  async (payload, thunkApi) => {
    try {
      return await apiWrapper(
        { isShowSuccessNoti: true },
        editAboutUsCardApi,
        payload,
      );
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  },
);

export const getCompanyProfileSocialMediaChannels = createAsyncThunk(
  `${MODEL_NAME}/getCompanyProfileSocialMediaChannels`,
  async (payload, thunkAPI) => {
    try {
      const response = await apiWrapper(
        {},
        getAllSocialMediaChannelsApi,
        payload,
      );

      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  },
);

export const editCompanyProfileSocialMediaChannels = createAsyncThunk(
  `${MODEL_NAME}/editCompanyProfileSocialMediaChannels`,
  async (payload: PayloadProps, thunkAPI) => {
    try {
      const { data, companyId, isShowSuccess = true } = payload;

      const requests = Object.entries(data).map(([key, value]) =>
        apiWrapper(
          { isShowSuccessNoti: false },
          editSocialMediaChannelsApi,
          companyId,
          {
            value: value || '',
            companyExternalLinkId: String(key),
          },
        ),
      );

      const results = await Promise.all(requests);

      isShowSuccess &&
        notification.success({
          message: i18next.t('success.title'),
          description: i18next.t('success.description'),
        });

      return { results, isSuccess: true };
    } catch (error) {
      return thunkAPI.rejectWithValue({ error });
    }
  },
);

export const updateCompanyProfileMedia = createAsyncThunk(
  `${MODEL_NAME}/updateCompanyProfileMedia`,
  async (payload: PayloadProps, thunkApi) => {
    try {
      const { data, companyId } = payload;

      const response = await apiWrapper(
        {
          isShowSuccessNoti: true,
        },
        updateCompanyProfileMediaApi,
        companyId,
        data,
      );

      return response;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  },
);

export const editCompanyProfile = createAsyncThunk(
  `${MODEL_NAME}/editCompanyProfile`,
  async (payload: PayloadProps, thunkAPI) => {
    try {
      const response = await apiWrapper(
        { isShowSuccessNoti: true },
        editCompanyProfileApi,
        payload?.data,
      );
      return { data: response };
    } catch (error) {
      return thunkAPI.rejectWithValue({ data: error });
    }
  },
);

export const addCompanyVirtualTour = createAsyncThunk(
  'centres/addCompanyVirtualTour',
  async (payload: PayloadProps, thunkAPI) => {
    try {
      const { companyId, virtualTourUrls } = payload;
      const response = await apiWrapper(
        { isShowSuccessNoti: true },
        addCompanyVirtualTourApi,
        companyId,
        {
          virtualTourUrls,
        },
      );
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue({ data: error });
    }
  },
);

export const getCompanyVirtualTour = createAsyncThunk(
  'centres/getCompanyVirtualTour',
  async (payload: PayloadProps, thunkAPI) => {
    try {
      const { companyId } = payload;
      const response = await apiWrapper(
        {},
        getCompanyVirtualTourApi,
        companyId,
      );
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue({ data: error });
    }
  },
);

import React from 'react';
import PropType from 'prop-types';
import { formatDateFromNow } from 'utils/textUtils';
import { getSunMoonTime } from 'utils/formatFieldUtils';
import { MessageTimeStyles } from './styles';

const MessageTime = ({ datetime }) => (
  <MessageTimeStyles className="flex-center">
    {formatDateFromNow(datetime)}
    {getSunMoonTime(datetime, { sunColor: '#fdb374', moonColor: '#64c9fd' })}
  </MessageTimeStyles>
);

export default MessageTime;

MessageTime.propTypes = {
  datetime: PropType.string,
};

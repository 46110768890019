import React, { forwardRef, useImperativeHandle, useState } from 'react';
import PropTypes from 'prop-types';
import RestUploadFile from 'components/RestInput/RestUploadFile';
import { getPrefixKeyUploadCompanyProfile } from 'utils/fileUtils';
import ModalCustom from 'components/common/ModalCustom';
import i18next from 'i18next';
import { Form } from 'antd';
import { RestInputContext } from 'components/RestInput/RestInputContext';
import { useDispatch, useSelector } from 'react-redux';
import {
  getIDCompanyProfileSelector,
  getRecruitmentVideosSelector,
} from 'redux/jobs/companyProfile/selectors';
import { pick } from 'lodash-es';
import { updateCompanyProfileMedia } from 'redux/jobs/companyProfile/actions';

const CompanyPhotosModal = forwardRef(({ record }, ref) => {
  const [form] = Form.useForm();
  const [isDisabled, setIsDisabled] = useState(false);
  const companyId = useSelector(getIDCompanyProfileSelector);
  const recruitmentVideos = useSelector(getRecruitmentVideosSelector)?.map(
    (item) => ({
      ...pick(item, ['type', 'isRecruitment', 'url']),
      isFeatured: item?.isFeatured ?? false,
    }),
  );
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [visibleModal, setVisibleModal] = useState(false);

  const onCancelModal = () => setVisibleModal(false);

  useImperativeHandle(
    ref,
    () => ({
      openModal: () => {
        setVisibleModal(true);
      },
    }),
    [],
  );

  const onFinish = async (values) => {
    const formatValuesOnSubmit = {
      mediaList: [
        ...recruitmentVideos,
        ...values?.images?.map((item) => ({
          ...pick(item, ['url', 'type']),
          isFeatured: item?.featured ?? false,
        })),
      ],
    };

    setLoading(true);
    await dispatch(
      updateCompanyProfileMedia({
        companyId,
        data: formatValuesOnSubmit,
      }),
    );
    setLoading(false);
    onCancelModal();
  };

  return (
    <ModalCustom
      title={i18next.t('jobs.companyProfile.editPhotosAndVideos')}
      visible={visibleModal}
      onCancel={onCancelModal}
      isDisabled={isDisabled}
      onOk={form.submit}
      loading={loading}
    >
      <Form
        form={form}
        onFinish={onFinish}
        layout="vertical"
        scrollToFirstError
      >
        <RestInputContext.Provider value={{ form, record }}>
          <RestUploadFile
            setIsDisabled={setIsDisabled}
            record={record}
            source="images"
            onlyShowImg
            isSetFeature
            isAttachURLVideo
            isDrapDrop
            prefixKey={getPrefixKeyUploadCompanyProfile(companyId)}
          />
        </RestInputContext.Provider>
      </Form>
    </ModalCustom>
  );
});

CompanyPhotosModal.propTypes = {
  record: PropTypes.object,
};

export default CompanyPhotosModal;

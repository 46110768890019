import React, { useCallback } from 'react';
import i18next from 'i18next';
import { Button, Modal, Tag, Tooltip } from 'antd';
import {
  APPLICATION_STATUS,
  APPLICATION_STATUS_CONSTANT,
} from 'configs/localData';
import { useHistory } from 'react-router';
import { formatDateApplication } from 'utils/textUtils';
import { formatMoney } from 'utils/tools';
import UserInfo from 'components/RestField/UserInfo';
import {
  ArchiveButtonIcon,
  EyeIcon,
  UnArchivedIcon,
} from 'components/common/SVGIcon';
import { ExclamationCircleFilled } from '@ant-design/icons';
import { updateApplicationArchiveStatus } from 'redux/applications/actions';
import { useDispatch } from 'react-redux';
import ServiceApplied from './ServiceApplied';
import { ActionsWrapper } from './styles';

const { confirm } = Modal;

const useFormat = () => {
  const dispatch = useDispatch();
  const { push } = useHistory();

  const formatSender = useCallback(
    (data) => (
      <UserInfo
        record={{
          ...data,
          fullName: `${data?.firstName || ''} ${data?.lastName || ''}`,
        }}
        roleProp="email"
        nameProp="fullName"
        isLink={false}
        isShowGender
        size={45}
      />
    ),
    [],
  );

  const formatAmount = useCallback((data) => formatMoney(data), []);

  const formatContractValue = useCallback(
    (data) =>
      `${i18next.t('currencyUnit.sign')} ${formatMoney(data)} ${i18next.t(
        'text.perAnnum',
      )}`,
    [],
  );

  const formatTime = useCallback((data) => formatDateApplication(data), []);

  const formatStatus = useCallback((data) => {
    const restItem = APPLICATION_STATUS?.find((item) => item.value === data);

    return restItem ? (
      <Tag
        style={{
          color: restItem?.color,
          backgroundColor: restItem?.backgroundColor,
          border: 'transparent',
          padding: '2px 10px',
        }}
      >
        {i18next.t(restItem?.text)}
      </Tag>
    ) : (
      i18next.t('error.waitingUpdate')
    );
  }, []);

  const formatServices = useCallback(
    (data, record) => (
      <ServiceApplied
        centreServices={data}
        requiredDays={record?.careOption?.requiredDays}
      />
    ),
    [],
  );

  const handleClickRecord = useCallback(
    (id) => {
      push(`/applications/${id}/show`);
    },
    [], // eslint-disable-line
  );

  const renderAction = useCallback(
    (id, record, openArchiveModal) => {
      const disabledUnarchive =
        record?.archivedReason &&
        (record?.status === APPLICATION_STATUS_CONSTANT.declined ||
          record?.status === APPLICATION_STATUS_CONSTANT.cancelled);

      const handleClick = () => {
        if (!record?.archivedReason) {
          openArchiveModal(id);
          return;
        }

        confirm({
          title: i18next.t('applications.unarchiveApplication'),
          content: i18next.t('applications.unarchiveDesc'),
          icon: <ExclamationCircleFilled />,
          okText: i18next.t('button.unarchive'),
          onOk: () => {
            dispatch(
              updateApplicationArchiveStatus({
                id,
                body: {
                  archivedReason: null,
                },
                refreshList: true,
              }),
            );
          },
          okButtonProps: {
            className: 'fw-bold',
          },
          cancelButtonProps: {
            className: 'fw-bold',
          },
        });
      };

      const getArchiveTooltip = () => {
        if (record?.archivedReason) {
          return disabledUnarchive
            ? i18next.t('applications.disabledArchive')
            : i18next.t('button.unarchive');
        }

        return i18next.t('button.archive');
      };

      return (
        <ActionsWrapper className="flex-center">
          <Tooltip title={i18next.t('button.view')}>
            <Button
              type="text"
              shape="circle"
              icon={<EyeIcon />}
              onClick={() => handleClickRecord(id)}
            />
          </Tooltip>
          <Tooltip
            title={getArchiveTooltip()}
            placement={disabledUnarchive ? 'topRight' : 'top'}
          >
            <Button
              disabled={disabledUnarchive}
              onClick={handleClick}
              type="text"
              shape="circle"
              icon={
                record?.archivedReason ? (
                  <UnArchivedIcon />
                ) : (
                  <ArchiveButtonIcon />
                )
              }
            />
          </Tooltip>
        </ActionsWrapper>
      );
    },
    [handleClickRecord, dispatch],
  );

  return {
    formatSender,
    formatAmount,
    formatTime,
    formatStatus,
    renderAction,
    formatServices,
    formatContractValue,
    handleClickRecord,
  };
};

export default useFormat;

import styled from 'styled-components';

const TooltipStyles = styled.div`
  background: white;
  padding: 10px;
  background-clip: padding-box;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.15);
  border-radius: 8px;

  .circle-tooltip {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    margin-right: 5px;
  }
  .rectangle-tooltip {
    width: 8px;
    height: 8px;
    border-radius: 1px;
    margin-right: 5px;
  }
  .content-tooltip {
    list-style-type: none;
    & > .value-item {
      display: flex;
      align-items: center;
      &:not(:first-child) {
        margin-top: 10px;
      }
    }
  }
  .title-tooltip {
    margin-bottom: 10px;
    text-align: center;
  }
  .strong-value {
    margin-left: 5px;
  }
  .ant-divider {
    margin: 0;
    margin-bottom: 10px;
  }
`;

export default TooltipStyles;

import { Button, Form, Space } from 'antd';
import i18next from 'i18next';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { convertSearchUrl, getFilterFromUrl } from 'utils/tools';
import PropTypes from 'prop-types';
import { FilterStyles } from 'containers/PriceBenchmarking/components/Filter/styles';
import {
  MAX_LIMIT,
  QUERY_ALL_ID,
  DEFAULT_ORDER_BY,
} from 'containers/CompanyBenchmarking/localData/constants';
import {
  getActivePrimaryChildCareServices,
  getNationalStates,
} from 'redux/companyBenchmarking/selectors';
import { isEmpty } from 'lodash-es';
import { resetResourceFilterBrandBenchMarking } from 'redux/brandBenchmarking/slice';
import { RestInputContext } from 'components/RestInput/RestInputContext';
import { ownedCompaniesOfAllUserRolesSelector } from 'redux/companiesOfUser/selectors';
import { companyBrandsSelectors } from 'redux/companyBrands/selectors';
import {
  getBrandBenchmarkingCompareRating,
  getUserBrandBenchmarkingChart,
  getBrandBenchmarkingSummary,
} from 'redux/brandBenchmarking/actions';
import SelectFilterForm from './SelectFilterForm';

const Filter = ({ getBrandBenchmarkingData }) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { push } = useHistory();
  const { search } = useLocation();
  const { filter, orderBy } = getFilterFromUrl(search);
  const filterRef = useRef(filter);
  const orderByRef = useRef(orderBy);
  const [checkCompanyChange, setCheckCompanyChange] = useState(false);

  const ownedCompanies = useSelector(ownedCompaniesOfAllUserRolesSelector);
  const brandsOfCompany = useSelector(companyBrandsSelectors.getDataArr);

  const primaryChildCareServices = useSelector(
    getActivePrimaryChildCareServices,
  );
  const nationalStates = useSelector(getNationalStates);
  const companyIdSelected = form.getFieldValue('companyId');
  const [isClearBtnClicked, setIsClearBtnClicked] = useState(false);

  const ALL_NATIONAL_STATES = i18next.t(
    'companyBenchmarking.filterForm.allStates',
  );

  const ALL_PRIMARY_CHILDCARE_SERVICES = i18next.t(
    'companyBenchmarking.filterForm.allPrimaryChildCareService',
  );

  const ALL_BRANDS_OF_COMPANY = i18next.t('brandBenchmarking.allBrands');

  const QUERY_ALL_BRAND_OF_COMPANY = {
    id: QUERY_ALL_ID,
    name: ALL_BRANDS_OF_COMPANY,
  };

  const brandsOfCompanyDropdown = () => {
    if (brandsOfCompany?.length > 1) {
      return [QUERY_ALL_BRAND_OF_COMPANY, ...Object.values(brandsOfCompany)];
    }
    return brandsOfCompany;
  };

  const fetchUserBrandBenchmarkingChart = useCallback(
    (value) => dispatch(getUserBrandBenchmarkingChart(value)),
    [dispatch],
  );

  const nationalStatesDropdown =
    [ALL_NATIONAL_STATES, ...Object.values(nationalStates)] || [];

  const QUERY_ALL_PRIMARY_CHILDCARE_SERVICES_OPTION = {
    id: QUERY_ALL_ID,
    name: ALL_PRIMARY_CHILDCARE_SERVICES,
  };

  const primaryChildCareServicesDropdown =
    [
      QUERY_ALL_PRIMARY_CHILDCARE_SERVICES_OPTION,
      ...primaryChildCareServices,
    ] || [];

  const getBrandBenchmarkingCompareRatingData = useCallback(
    (params) => dispatch(getBrandBenchmarkingCompareRating(params)),
    [dispatch],
  );

  const fetchBrandBenchmarkingSummary = useCallback(
    (formValues) => dispatch(getBrandBenchmarkingSummary(formValues)),
    [dispatch],
  );

  const clearAllFormValues = () => {
    push({ search: '?' });
    form.resetFields();
    dispatch(resetResourceFilterBrandBenchMarking());
    setIsClearBtnClicked(true);
  };

  const onSubmitFilter = () => {
    form.validateFields().then((values) => {
      push({
        search: `?${convertSearchUrl({
          ...values,
          limit: MAX_LIMIT,
          offset: 0,
        })}`,
      });
      setCheckCompanyChange(false);
      fetchUserBrandBenchmarkingChart(values);
      getBrandBenchmarkingData({
        filter: {
          ...values,
          limit: MAX_LIMIT,
          offset: 0,
        },
        isRefresh: true,
      });
      getBrandBenchmarkingCompareRatingData(values);
      fetchBrandBenchmarkingSummary(values);
      const selectedBrandName =
        values?.brandId === QUERY_ALL_ID
          ? i18next.t('brandBenchmarking.allBrands')
          : brandsOfCompany.find((brand) => brand?.id === values?.brandId)
              ?.name;
      localStorage.setItem('selectedBrandName', selectedBrandName);
    });
  };

  const getBrandValueFilter = useCallback(() => {
    if (!isEmpty(filter)) {
      if (
        companyIdSelected !== filter?.companyId ||
        (companyIdSelected === filter?.companyId && checkCompanyChange)
      ) {
        if (brandsOfCompany?.length > 1) {
          return QUERY_ALL_ID;
        }
        return brandsOfCompany[0]?.id;
      }
      return filter.brandId;
    }

    if (brandsOfCompany?.length > 1) {
      if (companyIdSelected) {
        return QUERY_ALL_ID;
      }
      return null;
    }
    return brandsOfCompany[0]?.id;
  }, [companyIdSelected, brandsOfCompany, checkCompanyChange, filter]);

  useEffect(() => {
    let defaultFormValues = {};
    if (!isEmpty(filter)) {
      defaultFormValues = {
        companyId: companyIdSelected || filter?.companyId,
        brandId: getBrandValueFilter(),
        state: filter?.state,
        primaryChildCareServiceId: filter?.primaryChildCareServiceId,
      };
    } else if (ownedCompanies?.length > 0) {
      if (isClearBtnClicked) {
        defaultFormValues = {
          companyId: companyIdSelected,
          brandId: getBrandValueFilter(),
        };
      } else {
        defaultFormValues = {
          companyId: companyIdSelected || ownedCompanies[0]?.id,
          brandId: getBrandValueFilter(),
          state: ALL_NATIONAL_STATES,
          primaryChildCareServiceId: QUERY_ALL_ID,
        };
      }
    }
    form.setFieldsValue(defaultFormValues);
  }, [
    brandsOfCompany,
    ALL_NATIONAL_STATES,
    companyIdSelected,
    filter,
    form,
    getBrandValueFilter,
    isClearBtnClicked,
    ownedCompanies,
  ]);

  useEffect(() => {
    if (!isEmpty(filterRef?.current)) {
      const filterValue = {
        companyId: filterRef?.current?.companyId,
        brandId: filterRef?.current?.brandId,
        state: filterRef?.current?.state,
        primaryChildCareServiceId:
          filterRef?.current?.primaryChildCareServiceId,
      };

      const queryParams = {
        limit: MAX_LIMIT,
        offset: 0,
        orderBy: orderByRef.current || DEFAULT_ORDER_BY,
      };

      fetchUserBrandBenchmarkingChart(filterValue);
      getBrandBenchmarkingData({
        filter: {
          ...filterValue,
          ...queryParams,
        },
        isRefresh: true,
      });

      getBrandBenchmarkingCompareRatingData(filterValue);
      fetchBrandBenchmarkingSummary(filterValue);
      push({
        search: `?${convertSearchUrl({
          ...filterValue,
          ...queryParams,
        })}`,
      });
    }
  }, [
    getBrandBenchmarkingData,
    push,
    fetchUserBrandBenchmarkingChart,
    getBrandBenchmarkingCompareRatingData,
    fetchBrandBenchmarkingSummary,
  ]);

  return (
    <FilterStyles>
      <Form form={form} autoComplete="off" layout="vertical">
        <RestInputContext.Provider value={{ form }}>
          <SelectFilterForm
            ownedCompanies={ownedCompanies}
            brandsOfCompanyDropdown={brandsOfCompanyDropdown()}
            nationalStatesDropdown={nationalStatesDropdown}
            primaryChildCareServicesDropdown={primaryChildCareServicesDropdown}
            setCheckCompanyChange={setCheckCompanyChange}
          />
          <Form.Item>
            <Space className="flex-center-end mt-39">
              <Button type="primary" htmlType="submit" onClick={onSubmitFilter}>
                {i18next.t('button.runBrandBenchmarkingReport')}
              </Button>
              <Button className="clear-button" onClick={clearAllFormValues}>
                {i18next.t('button.clearFilter')}
              </Button>
            </Space>
          </Form.Item>
        </RestInputContext.Provider>
      </Form>
    </FilterStyles>
  );
};

Filter.propTypes = {
  getBrandBenchmarkingData: PropTypes.func,
};

export default Filter;

import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, Link } from 'react-router-dom';
import { Form, Button, Input } from 'antd';
import i18n from 'i18next';
import { login as loginAction } from 'redux/auth/actions';
import { ArrowRightOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import AuthContact from 'components/common/AuthContact';
import { UnderlineIcon } from 'components/common/SVGIcon';
import { LoginStyles, ClaimButtonStyles } from './styles';

const FormItem = Form.Item;

const Login = ({ customActionLogin, handleAfterLogin }) => {
  const [form] = Form.useForm();

  const dispatch = useDispatch();

  const loading = useSelector((state) => state.auth.loading);

  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);

  const login = (params) => dispatch(loginAction(params));

  const handleSubmit = () => {
    form.validateFields().then(async (values) => {
      if (customActionLogin) {
        customActionLogin(values);
      } else {
        await login(values);
      }
      if (handleAfterLogin) {
        handleAfterLogin();
      }
    });
  };

  if (isAuthenticated) {
    return <Redirect to="/centres/board" />;
  }

  return (
    <LoginStyles>
      <div className="login-title">
        <div className="title flex">
          <div className="maintitle relative">
            <span className="title-text">{i18n.t('login.title')}</span>
            <UnderlineIcon />
          </div>
          <ClaimButtonStyles className="fw-bold flex-center">
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={`/claim-centres`}
            >
              {i18n.t('button.claim')}
            </a>
            <ArrowRightOutlined className="text-primary ml-6" />
          </ClaimButtonStyles>
        </div>
      </div>
      <Form form={form} layout="vertical" onFinish={handleSubmit}>
        <FormItem
          label={i18n.t('input.email.placeholder')}
          name="email"
          rules={[
            {
              required: true,
              message: i18n.t('input.email.validateMsg.required'),
            },
          ]}
        >
          <Input />
        </FormItem>
        <FormItem
          label={i18n.t('input.password.placeholder')}
          name="password"
          rules={[
            {
              required: true,
              message: i18n.t('input.password.validateMsg.required'),
            },
          ]}
        >
          <Input.Password type="password" />
        </FormItem>
        <div className="action-div">
          <div className="div-forgot-password">
            <Link to="/forgot-password">
              {i18n.t('forgotPassword.titleQuestion')}
            </Link>
          </div>
          <Button
            type="primary"
            htmlType="submit"
            className="login-form-button"
            loading={loading}
          >
            {i18n.t('login.loginBtn')}
          </Button>
          <AuthContact
            className="mt-20 text-left"
            text="auth.contactText.l1Login"
          />
        </div>
      </Form>
    </LoginStyles>
  );
};

Login.propTypes = {
  customActionLogin: PropTypes.func,
  handleAfterLogin: PropTypes.func,
};

export default Login;

import styled from 'styled-components';

export const HeaderSidebarWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 10px;
  padding-bottom: 0px;
  align-items: center;
  .title-card {
    font-weight: bold;
    font-size: 20px;
    line-height: 25px;
    color: ${({ theme }) => theme.palette.primary};
  }
`;

export const TaskDetailWrapper = styled.div`
  background: ${({ theme }) => theme.background.content};
  box-shadow: 0px 2px 10px rgba(39, 40, 51, 0.08);
  border-radius: 8px;
  padding: 10px 20px;
  .list-card-info {
    width: 100%;
  }
`;

export const TaskContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 16px;

  .field-item {
    display: flex;
    justify-content: space-between;
    .ant-row {
      width: 100%;
    }
  }
  .icon-type {
    display: flex;
    flex-direction: column;
    align-items: center;
    p {
      margin-top: 10px;
      font-weight: bold;
    }
    > span[role='img'] > svg {
      width: 50px;
      height: auto;
    }
  }
  button {
    background-color: ${({ theme }) => theme.background.primary};
    color: #fff;
    font-size: 16px;
    font-weight: bold;
  }
`;

export type Responsive = {
  xxl?: number;
  xl?: number;
  lg?: number;
  md?: number;
  sm?: number;
  xs?: number;
};

export type Pagination = {
  page?: number;
  size?: number;
  total?: number;
};

export interface SelectCheckboxState {
  ids?: string[];
  isSelectAll?: boolean;
  notInIds?: string[];
  searchValue?: string;
}

export interface ResponseAPI<TData> {
  results: TData;
  paging: Pagination;
}

export interface ThemeDefault {
  palette: {
    [key: string]: string | string[];
  };
  fonts: {
    [key: string]: string | string[];
  };
  fontWeight: {
    [key: string]: number;
  };
  background: {
    [key: string]: string | string[];
  };
  text: {
    [key: string]: string | string[];
  };
  border: {
    [key: string]: string | string[];
  };
  borderRadius: {
    [key: string]: string | string[];
  };
  scrollbar: {
    [key: string]: string | string[];
  };
  color: {
    [key: string]: string | string[];
  };
  alert: {
    [key: string]: string | string[];
  };
  card: {
    [key: string]: string | string[];
  };
  sidebar: {
    [key: string]: string | string[];
  };
  drawer: {
    [key: string]: string | string[];
  };
  table: {
    [key: string]: string | string[];
  };
  boardColors: {
    [key: string]: string | string[];
  };
  boxShadow: string | string[];
  subscriptions: {
    [key: string]: string | string[];
  };
  contentWrapper: {
    paddingPx: string;
    paddingNumber: number;
  };
  footer: {
    [key: string]: string | string[];
  };
  icons: {
    [key: string]: string | string[];
  };
}

export enum ButtonType {
  primary = 'primary',
  default = 'default',
  ghost = 'ghost',
  dashed = 'dashed',
  link = 'link',
  text = 'text',
}

export type ChildrenProp = {
  children: React.ReactNode;
};

export interface CurrentLocation extends Record<string, number> {
  longitude: number;
  latitude: number;
}

export interface ResourceFilter {
  offset?: number;
  filter?: object;
  outsideFilter?: object;
}

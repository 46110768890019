import React from 'react';
import { KindiCareWatermarkWrapper } from 'components/KindiCareWatermark/styles';
import i18next from 'i18next';
import { OpenNewTabIcon } from 'components/common/SVGIcon';
import { DOMAIN_LANDING, KC_HOST_NAME } from 'configs/localData/constant';
import FullLogo from '../../assets/images/logo_plus.svg';

const KindiCareWatermark = () => (
  <KindiCareWatermarkWrapper>
    <div className="logo-container">
      <img alt="full-logo" src={FullLogo} />
    </div>
    <p>{i18next.t('priceBenchmarking.marketInsights')}</p>
    <span className="kc-link">
      <a
        href={KC_HOST_NAME}
        className="kc-link"
        target="_blank"
        rel="noopener noreferrer"
      >
        {DOMAIN_LANDING}
      </a>
      <span className="open-new-tab-icon">
        <OpenNewTabIcon />
      </span>
    </span>
  </KindiCareWatermarkWrapper>
);

export default KindiCareWatermark;

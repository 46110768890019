import styled from 'styled-components';
import { Form } from 'antd';

const FormReasonDelete = styled(Form)`
  .delete-desc,
  .ant-radio-wrapper {
    font-size: 16px;
  }
  .ant-space-item {
    padding: 10px 0;
  }
  .ant-form-item-control-input-content {
    display: flex;
  }
`;

export default FormReasonDelete;

import { Form, FormInstance, Radio, Space } from 'antd';
import { IUserLocation } from 'api/advertisement/type';
import i18next from 'i18next';
import React from 'react';
import { useSelector } from 'react-redux';
import {
  getAdsFormConfigLoadingSelector,
  getAdsFormConfigSelector,
  getCreateAdPayload,
} from 'redux/advertisement/selectors';
import RestInfinitySelect from 'components/RestInput/RestInfinitySelect';
import { FormEditAudienceStyles } from './styles';

interface UserLocationOption extends IUserLocation {
  FormComponent?: React.ReactNode;
  apiUrl?: string;
}

interface Props {
  form: FormInstance;
}

const subFormSource = 'userLocationValues';

const FormEditAudience = ({ form }: Props) => {
  const { adsTargetedAudience } = useSelector(getAdsFormConfigSelector);
  const loading = useSelector(getAdsFormConfigLoadingSelector);
  const { targetedAudience } = useSelector(getCreateAdPayload);

  const onUserLocationChange = () => {
    form.setFieldsValue({ [subFormSource]: undefined });
  };

  const userLocationOptions: UserLocationOption[] =
    adsTargetedAudience?.userLocation?.map((item) => {
      switch (item.name) {
        case 'By State':
          return {
            ...item,
            apiUrl: '/crm/area/state',
          };
        case 'By LGA':
          return {
            ...item,
            apiUrl: '/crm/area/lga',
          };
        case 'By Postcode':
          return {
            ...item,
            apiUrl: '/crm/area/postcode',
          };
        case 'By Suburb':
          return {
            ...item,
            apiUrl: '/crm/area/suburb',
          };
        default:
          return item;
      }
    });

  const renderSubFormOption = ({
    FormComponent,
    apiUrl,
    value,
  }: UserLocationOption) => {
    if (FormComponent) {
      return FormComponent;
    }

    if (apiUrl) {
      return (
        <Form.Item
          name={subFormSource}
          initialValue={getDefaultSelectLocation(value)}
          rules={[
            {
              required: true,
              message: i18next.t('error.required'),
            },
          ]}
        >
          <RestInfinitySelect
            apiUrl={apiUrl}
            autoFocus
            allowClear
            mode="multiple"
            showSearch
            searchProp="searchKey"
            uniqueProp={null}
            placeholder={i18next.t('common.pleaseSelect')}
            filterOption={false}
            shouldResetListAfterSelect
            convertDataToOptions={(data) =>
              data.map((value: string) => ({ label: value, value }))
            }
          />
        </Form.Item>
      );
    }
  };

  const getDefaultRadioValue = (data): string =>
    data?.find((item) => item?.default)?.value;

  const getDefaultSelectLocation = (value: string): string[] =>
    targetedAudience?.userLocation === value
      ? targetedAudience?.userLocationValues
      : [];

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <FormEditAudienceStyles>
      <Form.Item
        name="userType"
        label={i18next.t('advertisement.byUserType')}
        initialValue={getDefaultRadioValue(adsTargetedAudience?.userType)}
      >
        <Radio.Group>
          <Space direction="vertical">
            {adsTargetedAudience?.userType?.map((item) => (
              <Radio value={item.value} key={item.value}>
                {item.name}
              </Radio>
            ))}
          </Space>
        </Radio.Group>
      </Form.Item>
      <Form.Item
        label={i18next.t('advertisement.byUserLocation')}
        name="userLocation"
        initialValue={getDefaultRadioValue(adsTargetedAudience?.userLocation)}
      >
        <Radio.Group onChange={onUserLocationChange}>
          <Space direction="vertical">
            {userLocationOptions?.map((item) => (
              <Radio value={item.value} key={item.value}>
                {item.name}

                <span
                  role="presentation"
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                  }}
                >
                  <Form.Item
                    noStyle
                    shouldUpdate={(prev, curr) =>
                      prev.userLocation !== curr.userLocation
                    }
                  >
                    {({ getFieldValue }) =>
                      getFieldValue('userLocation') === item.value
                        ? renderSubFormOption(item)
                        : null
                    }
                  </Form.Item>
                </span>
              </Radio>
            ))}
          </Space>
        </Radio.Group>
      </Form.Item>
    </FormEditAudienceStyles>
  );
};

export default FormEditAudience;

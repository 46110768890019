import React from 'react';
import { Button, Space, Tooltip } from 'antd';
import PropTypes from 'prop-types';
import i18next from 'i18next';
import { ArchiveIcon } from 'components/common/SVGIcon';
import { ActionButtonStyles } from './styles';
import SeeContactBtn from './SeeContactBtn';

const ActionButton = ({ isShowArchive, openArchiveModal }) => (
  <ActionButtonStyles>
    <Space>
      {isShowArchive && (
        <Tooltip title={i18next.t('button.archive')}>
          <Button
            size="small"
            shape="circle"
            icon={<ArchiveIcon />}
            className="btn-info-fill archive-button"
            onClick={openArchiveModal}
          />
        </Tooltip>
      )}
      <SeeContactBtn />
    </Space>
  </ActionButtonStyles>
);

ActionButton.propTypes = {
  isShowArchive: PropTypes.bool,
  openArchiveModal: PropTypes.func,
};

export default ActionButton;

import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'antd';
import i18n from 'i18next';
import { RestInputContext } from 'components/RestInput/RestInputContext';
import RestAvatarInput from 'components/RestInput/RestAvatarInput';
import RestInputItem from 'components/RestInput/RestInputItem';
import FormStyles from './styles';

const Form = ({ form, record }) => (
  <RestInputContext.Provider value={{ form, record: record || {} }}>
    <FormStyles>
      <RestAvatarInput
        style={{
          marginBottom: 20,
          width: 150,
          height: 150,
          borderRadius: '50%',
        }}
        record={record}
        className="avatar-section"
        cropDimension={{ width: 300, height: 300 }}
        hasCrop
        source="avatar"
        header={i18n.t('profile.uploadAvatar')}
      />
      <Row gutter={16} className="form-section">
        <Col md={12} xs={24}>
          <RestInputItem
            required
            source="firstName"
            header="profile.firstName"
            placeholder="profile.firstName"
            isValidatorEmpty
            minLength={2}
            maxLength={100}
          />
        </Col>
        <Col md={12} xs={24}>
          <RestInputItem
            required
            source="lastName"
            header="profile.lastName"
            placeholder="profile.lastName"
            isValidatorEmpty
            minLength={2}
            maxLength={100}
          />
        </Col>
        <Col md={12} xs={24}>
          <RestInputItem
            required
            source="email"
            header="profile.email"
            disabled
            placeholder="profile.email"
          />
        </Col>
        <Col md={12} xs={24}>
          <RestInputItem
            source="phoneNumber"
            header="profile.phoneNumber"
            placeholder="profile.phoneNumber"
          />
        </Col>
        <Col md={12} xs={24}>
          <RestInputItem
            source="jobTitle"
            header="users.jobTitle"
            placeholder="users.jobTitle"
          />
        </Col>
      </Row>
    </FormStyles>
  </RestInputContext.Provider>
);

Form.propTypes = {
  form: PropTypes.object,
  record: PropTypes.object,
};

export default Form;

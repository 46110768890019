import React, { useContext, useEffect } from 'react';
import { Col, FormInstance, Row } from 'antd';
import { useLocation } from 'react-router-dom';
import { getFilterFromUrl } from 'utils/tools';
import { RestInputContext } from 'components/RestInput/RestInputContext';
import moment from 'moment';
import RestSelect from 'components/RestInput/RestSelect';
import RestRangePickerInput from 'components/RestInput/RestRangePickerInput';
import {
  CHANNEL,
  DELIVERY_STATUS,
  IDENTITY,
  USER_TYPE,
} from 'containers/Advertisement/constants';
import i18next from 'i18next';
import InfiniteAutoComplete from 'components/InfiniteFormItem/InfiniteAutoComplete';
import { useGetTitleAdvertisementQuery } from 'redux/@rtkQuery/advertisement';

const FilterForm = () => {
  const { form }: { form?: FormInstance } = useContext(RestInputContext);
  const { search } = useLocation();

  useEffect(() => {
    const { filter } = getFilterFromUrl(search);
    const { createdAt, updatedAt, ...restFilter } = filter?.filter || {};

    form.setFieldsValue({
      updatedAt: Object.values(updatedAt || {}).map((item) => moment(item)),
      createdAt: Object.values(createdAt || {}).map((item) => moment(item)),
      ...restFilter,
    });
  }, [search, form]);

  return (
    <Row gutter={16}>
      <Col lg={4} md={12} xs={24}>
        <InfiniteAutoComplete
          name="title"
          showSearch
          filterOption={false}
          useQuery={useGetTitleAdvertisementQuery}
          placeholder={i18next.t('advertisement.headline')}
          searchKey="title"
          labelProp="headline"
          valueProp="headline"
        />
      </Col>
      <Col lg={3} md={12} xs={24}>
        <RestSelect
          source="delivery"
          placeholder="advertisement.deliveryStatus"
          isShowTooltip
          resourceData={DELIVERY_STATUS}
        />
      </Col>
      <Col lg={3} md={12} xs={24}>
        <RestSelect
          source="channel"
          placeholder="advertisement.channel"
          isShowTooltip
          resourceData={CHANNEL}
        />
      </Col>
      <Col lg={3} md={12} xs={24}>
        <RestSelect
          source="userType"
          placeholder="users.userType"
          isShowTooltip
          resourceData={USER_TYPE}
        />
      </Col>
      <Col lg={3} md={12} xs={24}>
        <RestSelect
          source="identity"
          placeholder="advertisement.identity"
          isShowTooltip
          resourceData={IDENTITY}
        />
      </Col>
      <Col lg={4} md={12} xs={24}>
        <RestRangePickerInput
          source="createdAt"
          placeholder="advertisement.createdAt"
          isShowTooltip
          sourceGt="createdAt.from"
          sourceLt="createdAt.to"
        />
      </Col>
      <Col lg={4} md={12} xs={24}>
        <RestRangePickerInput
          source="updatedAt"
          placeholder="advertisement.lastUpdated"
          isShowTooltip
          sourceGt="updatedAt.from"
          sourceLt="updatedAt.to"
        />
      </Col>
    </Row>
  );
};

export default FilterForm;

import React from 'react';
import PropTypes from 'prop-types';
import i18next from 'i18next';
import RestShow from 'containers/rest/Show';
import CustomersDetail from '../components/CustomersDetail';

const CustomersShow = (props) => {
  const customBreadcrumb = [
    { path: '/customers', title: i18next.t('customers.header') },
    {
      path: props?.location?.pathname || '/',
      title: i18next.t('customers.detail'),
    },
  ];
  return (
    <RestShow
      {...props}
      resource="customers"
      customBreadcrumb={customBreadcrumb}
    >
      <CustomersDetail customerId={props.match?.params?.id} />
    </RestShow>
  );
};

CustomersShow.propTypes = {
  location: PropTypes.object,
  match: PropTypes.object,
};

export default CustomersShow;

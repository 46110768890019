import styled from 'styled-components';

const TooltipStyles = styled.div`
  background: #fff;
  padding: 16px;
  min-width: 220px;
  min-height: 132px;
  border-radius: 12px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.15);
  .title-tooltip {
    color: ${({ theme }) => theme.text.mainL3};
    font-weight: 400;
    font-size: 12px;
  }
`;

export default TooltipStyles;

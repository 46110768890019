import React, { useEffect, useCallback } from 'react';

import i18next from 'i18next';
import { useDispatch } from 'react-redux';
import { getProvidersOfMeBasic } from 'redux/providersOfMeBasic/actions';
import ProviderFilter from './ProviderFilter';
import ProviderListSelect from './ProviderListSelect';

const ProviderForm = () => {
  const dispatch = useDispatch();

  const retrieveList = useCallback(
    ({ filter = {}, isRefresh, keepFilter }) => {
      dispatch(
        getProvidersOfMeBasic({
          data: {
            orderBy: 'name',
            ...filter,
            filter: filter.filter,
          },
          options: {
            keepFilter,
            isRefresh,
          },
        }),
      );
    },
    [], // eslint-disable-line
  );

  useEffect(() => {
    retrieveList({
      filter: {
        limit: 10,
        offset: 0,
      },
      isRefresh: true,
    });
  }, []); // eslint-disable-line

  return (
    <div>
      <div className="title-session">{i18next.t('users.selectProviders')}</div>
      <ProviderFilter retrieveList={retrieveList} />
      <ProviderListSelect retrieveList={retrieveList} />
    </div>
  );
};

export default ProviderForm;

import styled from 'styled-components';
import { Menu } from 'antd';

const GroupActionsStyles = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  .anticon {
    font-size: 20px;
  }
`;

export default GroupActionsStyles;

export const OverlayStyles = styled(Menu)`
  .ant-btn {
    border: none;
    background: transparent;
    font-size: 16px;
    padding-top: 0;
    padding-bottom: 0;
    height: 32px;
  }
`;

import React, { useRef } from 'react';
import { Carousel } from 'antd';
import { ArrowRightOutlined } from '@ant-design/icons';
import { CarouselRef } from 'antd/lib/carousel';
import { getYoutubeEmbedURL } from 'utils/tools';
import i18next from 'i18next';
import { ArrowRightStyle, CarouselStyle, ImageDefaultStyle } from './styles';

interface SlideProps {
  mediaItems: UploadMediaProps[] | number[];
}

const Slide = ({ mediaItems }: SlideProps) => {
  const carouselRef = useRef<CarouselRef>();

  const handleNextSlide = () => {
    carouselRef.current && carouselRef.current.next();
  };

  const renderItem = (item: UploadMediaProps) => {
    if (item.type?.includes('image')) {
      return (
        <img
          className="w-full h-full rounded-top-left-right-radius-8 object-cover"
          src={item.url || item.response}
          alt={item.key}
        />
      );
    }
    if (item.type?.includes('video')) {
      const youtubeLink = getYoutubeEmbedURL(item?.url || item.response);
      if (item.source === 'youtube' || !!youtubeLink) {
        return (
          <iframe
            title="iframe-video-item"
            frameBorder="0"
            className="iframe-video object-cover"
            marginWidth={0}
            marginHeight={0}
            allowFullScreen
            src={youtubeLink}
          />
        );
      }
      return (
        <video
          controls
          className="iframe-video object-cover"
          src={item.url || item.response}
        >
          <source src={item.url || item.response} type="video/mp4" />
          {i18next.t('error.notSupportVideo')}
          <track src="" kind="captions" srcLang="en" label="english_captions" />
        </video>
      );
    }
  };

  return (
    <CarouselStyle>
      <Carousel ref={carouselRef} afterChange={handleNextSlide}>
        {mediaItems?.map((item, index) => (
          <div key={index}>
            <ImageDefaultStyle>
              {(item?.url || item?.response) && renderItem(item)}
            </ImageDefaultStyle>
          </div>
        ))}
      </Carousel>

      <div
        className={
          'flex-center-end absolute w-full h-full p-12 top-0 rounded-top-left-right-radius-8'
        }
      >
        <ArrowRightStyle
          className="flex-center rounded-full bg-white cursor-pointer"
          onClick={handleNextSlide}
          role="button"
        >
          <ArrowRightOutlined className="text-11" color="#38465C" />
        </ArrowRightStyle>
      </div>
    </CarouselStyle>
  );
};

export default Slide;

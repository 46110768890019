import React, { useCallback, useMemo } from 'react';
import { PropTypes } from 'prop-types';
import TitleSorter from 'components/common/TableTitleSorter';
import TableWrapper from './styles';

const TableCustom = ({ ...props }) => {
  const {
    isSorter,
    columns,
    data,
    isSetRowKeyId,
    xScroll,
    pagination,
    isResetStyle,
    className,
    ...rest
  } = props;
  const rowKey = (data = {}, index) => (isSetRowKeyId ? data.id : index);

  const columnsFormat = useMemo(() => {
    if (isSorter)
      return columns.map(({ title, ...column }) => ({
        ...column,
        title: column.sorter
          ? (sortOrder, sortColumn) => (
              <TitleSorter
                title={title}
                sortOrder={sortOrder}
                sortColumn={sortColumn}
                sortDesc={
                  sortOrder.sortColumn?.dataIndex === column.dataIndex
                    ? sortOrder.sortOrder
                    : column.defaultSortOrder
                }
              />
            )
          : title,
      }));
    return columns;
  }, [columns, isSorter]);

  const showTotal = useCallback(
    (total, range) => `${range[0]}-${range[1]}/${total}`,
    [],
  );

  return (
    <TableWrapper
      isResetStyle={isResetStyle}
      columns={columnsFormat}
      dataSource={data}
      pagination={{
        ...pagination,
        showSizeChanger: true,
        showQuickJumper: true,
        showTotal,
      }}
      rowKey={rowKey}
      scroll={{ x: xScroll || 1100 }}
      isSorter={isSorter}
      className={`${className || ''} ${isResetStyle ? '' : 'table-wrapper'}`}
      {...rest}
    />
  );
};

TableCustom.propTypes = {
  columns: PropTypes.array,
  data: PropTypes.array,
  isSetRowKeyId: PropTypes.bool,
  xScroll: PropTypes.any,
  isSorter: PropTypes.bool,
  pagination: PropTypes.object,
  isResetStyle: PropTypes.bool,
  className: PropTypes.string,
};

TableCustom.defaultProps = {
  pagination: {},
};

export default TableCustom;

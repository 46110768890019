import { ROLES_CONST } from 'configs/localData/permissions';
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getCurrentRole } from 'redux/auth/selectors';

const useRolesJobTemplates = () => {
  const currentRole = useSelector(getCurrentRole);
  const [disabledAction, setDisabledAction] = useState(false);

  const checkUserRole = useCallback(() => {
    switch (currentRole) {
      case ROLES_CONST.companyAdmin:
      case ROLES_CONST.companyStaff:
        return setDisabledAction(false);

      case ROLES_CONST.providerAdmin:
      case ROLES_CONST.providerStaff:
      case ROLES_CONST.centreAdmin:
      case ROLES_CONST.centreStaff:
        return setDisabledAction(true);

      default:
        return null;
    }
  }, [currentRole]);

  useEffect(() => checkUserRole(), [checkUserRole]);

  return disabledAction;
};

export default useRolesJobTemplates;

import React from 'react';
import PropTypes from 'prop-types';
import i18next from 'i18next';
import ReadMore from 'components/common/ReadMore';
import { formatDateTimeWorking } from 'utils/textUtils';
import { OTHER_CAPITALIZE } from 'containers/JobListings/constants';

const EducationAndQualificationContent = ({ item }) => {
  const fieldOfStudy =
    item?.fieldOfStudy !== OTHER_CAPITALIZE
      ? item?.fieldOfStudy
      : item?.customFieldOfStudy;

  return (
    <div className="text-16 leading-loose">
      <strong>{item?.name}</strong>
      <div className="fw-400">
        {fieldOfStudy}
        {fieldOfStudy ? (
          <span className="text-gray-500 text-10 mr-10 ml-10">&#9679;</span>
        ) : null}
        {item?.school}
      </div>

      <div className="text-gray-500">
        {`${formatDateTimeWorking(item?.startDate)} - ${
          item?.isCurrentlyStudying
            ? i18next.t('jobApplications.present')
            : formatDateTimeWorking(item?.endDate)
        }`}
      </div>
      <ReadMore
        text={item?.description || ''}
        className="text-16"
        readMoreCharacterLimit={500}
      />
    </div>
  );
};

EducationAndQualificationContent.propTypes = {
  item: PropTypes.object,
};

export default EducationAndQualificationContent;

import React, { useCallback } from 'react';
import i18next from 'i18next';
import PropTypes from 'prop-types';
import { KEYS_DAYS_IN_WEEK } from 'configs/localData';
import { ServiceAppliedTableStyled } from './styles';

const ServiceApplied = ({ serviceTypes, requiredDays }) => {
  const getDayText = useCallback((value) => {
    const item = KEYS_DAYS_IN_WEEK.find((item) => item.value === value) || {};
    return item.text ? i18next.t(item.text) : value;
  }, []);

  return (
    <ServiceAppliedTableStyled>
      <div className="tag-service">
        {serviceTypes.map((service, index) => (
          <div className="service-name" key={String(index)}>
            {service.name || i18next.t('error.waitingUpdate')}
          </div>
        ))}
      </div>
      <div className="required-days">
        {requiredDays.map((date, index) => (
          <span key={String(index)}>{getDayText(date)}</span>
        ))}
      </div>
    </ServiceAppliedTableStyled>
  );
};

ServiceApplied.propTypes = {
  serviceTypes: PropTypes.array,
  requiredDays: PropTypes.array,
};

ServiceApplied.defaultProps = {
  serviceTypes: [],
  requiredDays: [],
};

export default ServiceApplied;

import styled from 'styled-components';

export const CreateButtonStyles = styled.div`
  .create-action-wrapper {
    position: relative;
  }

  .action-feature-icon {
    position: absolute;
    top: -10px;
    right: -8px;
    font-size: 20px;
    color: ${({ theme }) => theme.subscriptions.colorIcon};
  }

  .ant-btn {
    width: 32px;
    height: 32px;
    padding: 0;
    min-width: 32px !important;
  }
`;

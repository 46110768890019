import React, { useCallback, useEffect, useRef, useState } from 'react';
import { isEmpty } from 'lodash-es';
import { useLocation, useHistory } from 'react-router';
import { convertSearchUrl, getFilterFromUrl } from 'utils/tools';
import { useDispatch, useSelector } from 'react-redux';
import { getAdDetail, getAdsList } from 'redux/advertisement/actions';
import { RetrieveList } from 'containers/Advertisement/List';
import {
  DEFAULT_CURRENT_PAGE,
  DEFAULT_SORT_ADVERTISEMENT,
  LIMIT_ITEM,
} from 'containers/Advertisement/constants';
import {
  enabledLoadMoreExistingAd,
  getAdsListLoadingSelector,
  getAdsListSelector,
  getAdsResourceDataSelector,
  hasSelectedAdSelector,
} from 'redux/advertisement/selectors';
import { Button, Card, Col, Row, Skeleton, Spin } from 'antd';
import AdsThumbnailCard from 'containers/Advertisement/components/AdsThumbnailCard';
import i18next from 'i18next';
import { Waypoint } from 'react-waypoint';
import { NoDataIcon } from 'components/common/SVGIcon';
import {
  gotoFirstStep,
  resetCreateAdPayload,
  setCreateAdPayload,
  setSelectedAd,
} from 'redux/advertisement/slice';
import { transformDataStructureStepsForm } from 'containers/Advertisement/utils';
import useModifyAdsPermission from 'containers/Advertisement/hooks/useModifyAdsPermission';
import { ThunkDispatch } from '@reduxjs/toolkit';
import { RootState } from 'redux/store';
import Footer from '../Steps/TypeOfAd/Footer';
import { CardStyle } from './styles';
import SearchAdsInput from './SearchAdsInput';

const ExistingAdsGrid = () => {
  const { push } = useHistory();
  const dispatch = useDispatch<ThunkDispatch<RootState, void, any>>();
  const { search, pathname } = useLocation();
  const { filter } = getFilterFromUrl(search);
  const filterRef = useRef(filter.filter);

  const pageRef = useRef(DEFAULT_CURRENT_PAGE);
  const { canModifyAds } = useModifyAdsPermission();
  const [selectingAdId, setSelectingAdId] = useState('');

  const existingAdsData = useSelector(getAdsListSelector);

  const loading = useSelector(getAdsListLoadingSelector);
  const resourceData = useSelector(getAdsResourceDataSelector);
  const isEnableLoadMoreExistingAd = useSelector(enabledLoadMoreExistingAd);
  const hasSelectedAd = useSelector(hasSelectedAdSelector);

  const selectedAd = document.getElementsByClassName('card-item-selected');

  const retrieveList = useCallback(
    ({ filter = {}, isRefresh }: RetrieveList) => {
      dispatch(
        getAdsList({
          data: {
            sorts: [DEFAULT_SORT_ADVERTISEMENT],
            page: DEFAULT_CURRENT_PAGE,
            size: LIMIT_ITEM,
            filter: isEmpty(filter.filter) ? undefined : filter.filter,
            ...filter,
          },
          options: {
            isRefresh,
          },
        }),
      );
    },
    [dispatch],
  );

  useEffect(() => {
    retrieveList({
      filter: {
        filter: isEmpty(filterRef.current) ? undefined : filterRef.current,
      },
      isRefresh: true,
    });
  }, [retrieveList]);

  const handleEnterWaypoint = () => {
    retrieveList({
      filter: {
        sorts: filter.sorts || [DEFAULT_SORT_ADVERTISEMENT],
        page: (pageRef.current += 1),
        size: filter.size || LIMIT_ITEM,
        filter: isEmpty(filter.filter) ? undefined : filter.filter,
      },
      isRefresh: false,
    });
  };

  const resetPage = () => {
    pageRef.current = DEFAULT_CURRENT_PAGE;
  };

  useEffect(() => {
    if (selectingAdId) {
      dispatch(setSelectedAd(true));
      push({
        pathname,
        search: `?${convertSearchUrl({
          ...filterRef.current,
          id: selectingAdId,
        })}`,
      });
    }
  }, [dispatch, push, selectingAdId, pathname]);

  const handleSelectTemplate = (id) => {
    if (id !== selectingAdId) {
      setSelectingAdId(id);
    }
  };

  const onNext = () => {
    if (!isEmpty(selectedAd)) {
      dispatch(getAdDetail(selectingAdId || filter?.id)).then(({ payload }) => {
        dispatch(gotoFirstStep());
        const initPayload = transformDataStructureStepsForm(payload);
        dispatch(setCreateAdPayload(initPayload));
        push(`/advertisement/create/steps`);
      });
    } else {
      dispatch(setSelectedAd(false));
    }
  };

  const handleCreateNewAd = () => {
    dispatch(resetCreateAdPayload());
    push(`/advertisement/create/steps`);
  };

  return (
    <CardStyle>
      {(!isEmpty(existingAdsData) || !isEmpty(filter?.filter)) && (
        <div>
          <SearchAdsInput retrieveList={retrieveList} resetPage={resetPage} />
          {hasSelectedAd !== null && !hasSelectedAd && (
            <span className="text-error">
              {i18next.t('jobs.jobTemplates.requireSelectOne')}
            </span>
          )}
        </div>
      )}
      <div className="mt-32">
        <Skeleton
          active
          paragraph={{ rows: 6 }}
          loading={loading && !resourceData?.total}
        >
          {!isEmpty(existingAdsData) ? (
            <>
              <Row gutter={[24, 24]}>
                {existingAdsData?.map((item) => (
                  <Col xs={24} lg={12} xxl={8} key={item?.id}>
                    <Card
                      className={`h-full rounded-8 ${
                        item?.id === selectingAdId || item?.id === filter?.id
                          ? 'card-item-selected'
                          : 'card-item'
                      }  
                      `}
                      onClick={() => handleSelectTemplate(item?.id)}
                    >
                      <AdsThumbnailCard
                        isShowThumbnail
                        mediaItems={item?.postMedias}
                        title={item?.headline}
                        description={item?.description}
                        isExistingCard
                        isShowTopAction={false}
                        isStyleCard={false}
                        item={item}
                        callingButton={item?.callingButton?.name}
                      />
                    </Card>
                  </Col>
                ))}
              </Row>
              {loading && (
                <div className="flex-center mt-20">
                  <Spin />
                </div>
              )}
              {isEnableLoadMoreExistingAd && (
                <Waypoint onEnter={handleEnterWaypoint} />
              )}
            </>
          ) : (
            <div className="flex-center flex-direction-column">
              <NoDataIcon />
              <div className="text-center">
                <h3>{i18next.t('advertisement.noAds')}</h3>
                <span>{i18next.t('advertisement.noAdDescription')}</span>
              </div>
              {isEmpty(filter) && (
                <div className="mt-20">
                  <Button
                    type="primary"
                    onClick={handleCreateNewAd}
                    disabled={!canModifyAds}
                  >
                    {i18next.t('advertisement.createNewAd')}
                  </Button>
                </div>
              )}
            </div>
          )}
        </Skeleton>
        <Footer onNext={onNext} />
      </div>
    </CardStyle>
  );
};

export default ExistingAdsGrid;

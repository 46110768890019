import { Space } from 'antd';
import styled from 'styled-components';
import { SummaryMetricCardContainer } from '../SummaryMetricCard/styles';

export const CardSpace = styled(Space)`
  --gap: 18px;
  --columns: 1;
  --width: calc(
    (100% / var(--columns)) - var(--gap) + (var(--gap) / var(--columns))
  );

  .ant-space-item {
    flex-grow: 1;
    flex-basis: var(--width);
    max-width: var(--width);
  }

  ${SummaryMetricCardContainer} {
    min-width: var(--width);
  }

  @media only screen and (min-width: 840px) {
    --columns: 2;
  }
  @media only screen and (min-width: 1060px) {
    --columns: 3;
  }
  @media only screen and (min-width: 1430px) {
    --columns: 4;
  }
  @media only screen and (min-width: 1530px) {
    --columns: 5;
  }
`;

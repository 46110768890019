import styled from 'styled-components';

const ListStyles = styled.div`
  min-height: 500px;
  display: flex;
  flex-direction: column;

  .chat-box-wrapper {
    min-height: 600px;
  }

  .enquiries {
    &__row-wrapper {
      height: 100%;
    }
    &__chat-box {
      display: flex;
      flex-direction: column;
      background: #fff;
      border-radius: 8px;
      height: 100%;
    }
    &__chat-wrapper {
      flex-grow: 1;
      position: relative;
    }
    &__status-groups {
      padding: 10px 16px;
      border-bottom: 1px solid ${({ theme }) => theme.border.light};
    }
  }

  .enquiries__contact-collapsed {
    transform: scale(0);
    width: 0;
    height: 0;
  }
  .enquiries__contact-col {
    transition: all 0.6s;
    transform-origin: top right;
  }
  .enquiries__chat-box-col {
    transition: all 0.6s;
  }

  .message-detail {
    border-left: 1px solid ${({ theme }) => theme.border.light};
  }

  .ant-radio-button-wrapper {
    border: none !important;
    border-radius: 8px !important;
    font-size: 16px;
    &:before {
      content: none !important;
    }
    color: ${({ theme }) => theme.text.primary};
  }
  .ant-radio-button-wrapper-checked {
    font-weight: bold;
    background: ${({ theme }) => `${theme.palette.primary}10`};
    color: ${({ theme }) => theme.palette.primary};
  }

  .enquiries__row-list,
  .enquiries__row-list > .ant-col,
  .enquiries__row-list > .ant-col > div,
  .enquiries__row-wrapper,
  .enquiries__row-wrapper > .ant-col {
    height: 100%;
  }

  .ant-avatar {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export default ListStyles;

import React from 'react';
import { CrownFilled, EyeOutlined } from '@ant-design/icons';
import { Button, Tooltip } from 'antd';

import { upgradeModal } from 'components/common/CheckPermissions/UpgradeSubscriptionModal';
import i18next from 'i18next';

interface Props {
  currentCentreId?: string;
  contactId?: string;
}
const ViewBtnFallback = ({ currentCentreId, contactId }: Props) => {
  const onClickButton = () => {
    upgradeModal.open(null, currentCentreId);
  };
  return (
    <Tooltip
      placement="topRight"
      title={!contactId && i18next.t('leadActivities.userNotExistContacts')}
      overlayInnerStyle={{ maxWidth: 140, textAlign: 'center' }}
    >
      <div>
        <CrownFilled className="absolute text-warning z-10 left-9" />

        <Button
          onClick={onClickButton}
          type="primary"
          shape="circle"
          icon={<EyeOutlined />}
        />
      </div>
    </Tooltip>
  );
};

export default ViewBtnFallback;

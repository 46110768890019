import React, { createContext, useContext, useState } from 'react';
import PropTypes from 'prop-types';

const initialValue = {
  fileList: [],
  setFileList: () => [],
};

const FilesMessageContext = createContext(initialValue);

export const FilesMessageProvider = ({ children }) => {
  const [fileList, setFileList] = useState([]);

  const value = {
    fileList,
    setFileList,
  };

  return (
    <FilesMessageContext.Provider value={value}>
      {children}
    </FilesMessageContext.Provider>
  );
};

FilesMessageProvider.propTypes = {
  children: PropTypes.node,
};

export const useFilesMessage = () => {
  const { fileList, setFileList } = useContext(FilesMessageContext);

  const clearFileList = () => setFileList([]);

  return {
    fileList,
    setFileList,
    clearFileList,
  };
};

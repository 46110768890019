import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { convertSearchUrl } from 'utils/tools';
import FilterCustom from 'components/common/FilterCustom';
import ContactFilterForm from './FilterForm';

const ContactFilterCustom = ({ resourceFilter }) => {
  const { push } = useHistory();

  const onSubmitFilter = ({ filter, ...values }) => {
    const restFilter = {
      ...resourceFilter,
      offset: 0,
      outsideFilter: { ...resourceFilter?.outsideFilter, ...values },
      filter: {
        ...resourceFilter?.filter,
        ...filter,
      },
    };

    push({ search: `?${convertSearchUrl(restFilter)}` });
  };

  const onClearFilter = () => {
    push({ search: '?' });
  };

  return (
    <FilterCustom onSubmitFilter={onSubmitFilter} onClearFilter={onClearFilter}>
      <ContactFilterForm resourceFilter={resourceFilter} />
    </FilterCustom>
  );
};

ContactFilterCustom.propTypes = {
  resourceFilter: PropTypes.object,
};

ContactFilterCustom.defaultProps = {};

export default ContactFilterCustom;

import PageTitle from 'components/common/PageTitle';
import React, { useCallback, useEffect, useRef } from 'react';
import i18next from 'i18next';
import FilterForm from 'containers/CompanyBenchmarking/components/FilterForm';
import { getAllCompanyBenchmarking } from 'redux/companyBenchmarking/actions';
import { useDispatch, useSelector } from 'react-redux';
import { CompanyBenchmarkingWrapper } from 'containers/CompanyBenchmarking/styles';
import { useLocation } from 'react-router';
import { getFilterFromUrl } from 'utils/tools';
import { isEmpty } from 'lodash-es';
import EmptyData from 'components/common/EmptyData';
import { Spin } from 'antd';
import {
  companyBenchmarkingSelectors,
  getCompanyBenchmarkChartsSelector,
  getCompanyBenchmarkingCompareRatingSelector,
  getCompanyBenchmarkSummarySelector,
  getDataCompanyBenchmarking,
} from 'redux/companyBenchmarking/selectors';
import CompareRatingBarChart from 'containers/CompanyBenchmarking/components/CompareRatingBarChart';
import { getOwnedCompaniesOfAllUserRoles } from 'redux/companiesOfUser/actions';
import { ownedCompaniesOfAllUserRolesSelector } from 'redux/companiesOfUser/selectors';
import Summaries from './components/Summaries';
import UserCompanyBenchmarkingChart from './components/UserCompanyBenchmarkingChart';
import CompanyBenchmarkingTable from './components/CompanyBenchmarkingTable';

const CompanyBenchmarkingContainer = () => {
  const loading = useSelector(companyBenchmarkingSelectors.getLoading);
  const { loading: ratingChartLoading } = useSelector(
    getCompanyBenchmarkingCompareRatingSelector,
  );
  const { loading: summaryLoading } = useSelector(
    getCompanyBenchmarkSummarySelector,
  );
  const { loading: chartsLoading } = useSelector(
    getCompanyBenchmarkChartsSelector,
  );

  const dispatch = useDispatch();
  const { search } = useLocation();
  const { filter } = getFilterFromUrl(search);

  const data = useSelector(getDataCompanyBenchmarking);

  const ownedCompanies = useSelector(ownedCompaniesOfAllUserRolesSelector);

  const API_RESOURCE = 'insights/company-benchmarking';
  const scrollTargetRef = useRef();
  const fetchCompanyBenchmarkingData = useCallback(
    ({ filter = {}, isRefresh, isSort }) =>
      dispatch(
        getAllCompanyBenchmarking({
          data: {
            orderBy: 'name',
            ...filter,
          },
          options: {
            isRefresh,
            isSort,
            customApiResource: API_RESOURCE,
          },
        }),
      ),
    [dispatch],
  );

  const fetchOwnedCompaniesOfAllUserRoles = useCallback(
    () =>
      dispatch(
        getOwnedCompaniesOfAllUserRoles({
          orderBy: 'name',
        }),
      ),
    [dispatch],
  );

  const handleScroll = useCallback(() => {
    scrollTargetRef.current.handleImperativeScroll();
  }, []);

  useEffect(() => {
    fetchOwnedCompaniesOfAllUserRoles({});
  }, [fetchOwnedCompaniesOfAllUserRoles]);

  return (
    <CompanyBenchmarkingWrapper className="h-full">
      <PageTitle>
        {`${i18next.t('sideBar.insights.title')} - ${i18next.t(
          'sideBar.insights.companyBenchmarking',
        )}`}
      </PageTitle>
      <FilterForm
        fetchCompanyBenchmarkingData={fetchCompanyBenchmarkingData}
        ownedCompanies={ownedCompanies}
      />
      {!isEmpty(filter) ? (
        <>
          <Spin spinning={ratingChartLoading}>
            <CompareRatingBarChart
              ref={scrollTargetRef}
              handleScroll={handleScroll}
              ownedCompanies={ownedCompanies}
            />
          </Spin>
          <Spin spinning={chartsLoading}>
            <UserCompanyBenchmarkingChart ownedCompanies={ownedCompanies} />
          </Spin>
          <Spin spinning={summaryLoading}>
            <Summaries ownedCompanies={ownedCompanies} />
          </Spin>
          <Spin spinning={loading && isEmpty(data)}>
            <CompanyBenchmarkingTable
              retrieveList={fetchCompanyBenchmarkingData}
            />
          </Spin>
        </>
      ) : (
        <EmptyData className="mt-20" />
      )}
    </CompanyBenchmarkingWrapper>
  );
};

export default CompanyBenchmarkingContainer;

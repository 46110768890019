import React from 'react';
import { StepFooter } from 'components/RestLayout/StepsCreateLayout/styles';
import { Button, Col, Row } from 'antd';
import i18next from 'i18next';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import withFooterFunc, {
  FooterProps,
} from 'containers/Advertisement/hocs/withFooterFunc';

const Footer = ({ onNext, onBack, isNextDisabled }: FooterProps) => (
  <StepFooter>
    <Row justify="space-between">
      <Col>
        <Button
          onClick={onBack}
          icon={<LeftOutlined />}
          className="btn-line-primary"
        >
          {i18next.t('button.back')}
        </Button>
      </Col>
      <Col>
        <Button type="primary" onClick={onNext} disabled={isNextDisabled}>
          {i18next.t('button.next')}
          <RightOutlined />
        </Button>
      </Col>
    </Row>
  </StepFooter>
);

export default withFooterFunc(Footer);

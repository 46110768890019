import { Button, Modal, Skeleton } from 'antd';
import {
  useDetailProgramControllerGetDetailProgramQuery,
  useProgramsControllerDeleteOneProgramMutation,
} from 'generated/apis';
import i18next from 'i18next';
import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { EditFilledIcon } from 'components/common/SVGIcon';
import renderHTML from 'react-render-html';
import CreateProgramModal from 'containers/ProgramsAndLearning/CreateProgramModal';
import { ExclamationCircleFilled } from '@ant-design/icons';
import ProgramInfo from './ProgramInfo';
import { AboutProgramTitle, ProgramDetailsModal } from './styles';

interface Props {
  isEdit: boolean;
  handleAfterDelete?: () => void;
}

const ProgramDetails = forwardRef(
  ({ isEdit, handleAfterDelete }: Props, ref) => {
    const [visible, setVisible] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [programId, setProgramId] = useState<string | null>(null);
    const [deleteProgram] = useProgramsControllerDeleteOneProgramMutation();

    const { data, isFetching } =
      useDetailProgramControllerGetDetailProgramQuery(
        {
          programId,
        },
        {
          skip: !programId,
          refetchOnMountOrArgChange: true,
        },
      );

    useImperativeHandle(
      ref,
      () => ({
        open: (id: string) => {
          setProgramId(id);
          setVisible(true);
        },
      }),
      [],
    );

    const onCancel = () => {
      setProgramId(null);
      setVisible(false);
    };

    const openEditProgramModal = (e) => {
      e.preventDefault();
      e.stopPropagation();
      setIsModalOpen(true);
    };

    const closeEditProgramModal = () => {
      setIsModalOpen(false);
    };

    const handleDeleteProgram = () => {
      Modal.confirm({
        wrapClassName: 'program-delete-modal-wrapper',
        icon: <ExclamationCircleFilled />,
        title: i18next.t('programLearning.confirm.delete.title'),
        okText: i18next.t('programLearning.confirm.delete.btnOkText'),
        cancelText: i18next.t('programLearning.confirm.delete.btnCancelText'),
        content: i18next.t('programLearning.confirm.delete.message'),
        onOk: () =>
          deleteProgram({
            programId,
          }).then(() => {
            setIsModalOpen(false);
            setVisible(false);
            !!handleAfterDelete && handleAfterDelete();
          }),
      });
    };

    return (
      <ProgramDetailsModal
        visible={visible}
        title={i18next.t('programLearning.programDetails')}
        onCancel={onCancel}
        width={980}
        isScrollY
        footer={
          <div className="flex-center-between">
            <Button
              disabled={!isEdit}
              type="default"
              className={`btn-line-primary fw-bold ${
                !isEdit ? 'border-none' : ''
              }`}
              onClick={handleDeleteProgram}
            >
              <span className={`${!isEdit ? 'text-white' : ''}`}>
                {i18next.t('button.delete')}
              </span>
            </Button>
            <Button onClick={onCancel} type="primary" className="fw-bold">
              {i18next.t('button.close')}
            </Button>
          </div>
        }
      >
        {isFetching ? (
          <div>
            <div className="flex-center-between">
              <Skeleton.Image />
              <Skeleton paragraph={{ rows: 4 }} active className="ml-16" />
            </div>
            <Skeleton active className="mt-16" />
          </div>
        ) : (
          <div>
            <div className="flex justify-between">
              <ProgramInfo {...data?.result} />
              <Button
                disabled={!isEdit}
                type="primary"
                shape="circle"
                icon={<EditFilledIcon />}
                size="small"
                onClick={openEditProgramModal}
              />
            </div>
            <AboutProgramTitle className="text-20 mt-20">
              {i18next.t('programLearning.aboutProgramDetail')}
            </AboutProgramTitle>
            {!!data?.result?.about && (
              <div className="mt-8">{renderHTML(data?.result?.about)}</div>
            )}
          </div>
        )}
        <CreateProgramModal
          visible={isModalOpen}
          onClose={closeEditProgramModal}
          inEditMode
          initialValues={data?.result}
          key={data?.result?.id}
        />
      </ProgramDetailsModal>
    );
  },
);

export default ProgramDetails;

import styled from 'styled-components';

export default styled.div`
  overflow: hidden;
  border-radius: 20px;
  border: 2px solid #f2f2f2;

  .g-recaptcha {
    position: relative;
    width: 100%;
    background: #fff;
    margin: -3px;
    margin-left: -1px;
    margin-top: -1px;
  }
`;

import React, { useMemo, useState } from 'react';
import StepsCreateLayout from 'components/RestLayout/StepsCreateLayout';
import kindiCareLogo from 'assets/images/logo_plus.svg';
import theme from 'configs/theme';
import SiderStartScreen from 'containers/Centres/components/CentreTabs/EducatorStaffProfilesTab/Create/SiderStartScreen';
import CreateProfileOptions from 'containers/Centres/components/CentreTabs/EducatorStaffProfilesTab/Create/CreateProfileOptions';
import {
  EXISTING_PROFILE_PARAM,
  PROFILE_START_SCREEN_PARAM,
  PROFILE_STEP,
  STAFF_PROFILE_SAVE_EXIT_NO_ASK,
} from 'containers/Centres/components/CentreTabs/EducatorStaffProfilesTab/constants';
import CreateProfileSteps from 'containers/Centres/components/CentreTabs/EducatorStaffProfilesTab/Create/Steps';
import { Form, FormInstance, Modal } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import {
  getCurrentStepStaffProfile,
  getPayloadStaffProfile,
} from 'redux/staffProfile/selector';
import BasicInformation from 'containers/Centres/components/CentreTabs/EducatorStaffProfilesTab/Create/Steps/BasicInformation';
import AboutStaff from 'containers/Centres/components/CentreTabs/EducatorStaffProfilesTab/Create/Steps/AboutStaff';
import EducationAndQualification from 'containers/Centres/components/CentreTabs/EducatorStaffProfilesTab/Create/Steps/EducationAndQualification';
import Awards from 'containers/Centres/components/CentreTabs/EducatorStaffProfilesTab/Create/Steps/Awards';
import { goToStep, resetStaffProfilePayload } from 'redux/staffProfile/slice';
import ExistingProfiles from 'containers/Centres/components/CentreTabs/EducatorStaffProfilesTab/Create/ExistingUser';
import { isEmpty, isNil, omitBy } from 'lodash-es';
import i18next from 'i18next';
import { CloseCircleFilled } from '@ant-design/icons';
import {
  useCreateStaffProfileMutation,
  useEditStaffProfilesMutation,
} from 'redux/@rtkQuery/educatorStaffProfile';
import { RootState } from 'redux/store';
import SaveAndExitModal from 'components/ShowAskAgainModal';
import { StaffProfileModel } from 'types/staffProfiles';

const { error } = Modal;

interface CurrentStepData {
  form?: FormInstance;
  ContentComponent?: React.FC<{ form?: FormInstance }>;
}

const CreateStaffProfile = ({ match, history }) => {
  const { centreId, screen } = match.params;
  const [visible, setVisible] = useState(false);
  const dispatch = useDispatch();
  const currentStep = useSelector(getCurrentStepStaffProfile);
  const staffProfilePayload: StaffProfileModel = useSelector(
    getPayloadStaffProfile,
  );
  const [createStaffProfile] = useCreateStaffProfileMutation();
  const [editStaffProfile] = useEditStaffProfilesMutation();
  const isStartScreen =
    screen === PROFILE_START_SCREEN_PARAM || screen === EXISTING_PROFILE_PARAM;
  const [formStep1] = Form.useForm();
  const [formStep2] = Form.useForm();
  const currentUserId = useSelector(
    (state: RootState) => state?.auth?.data?.id,
  );

  const userNoSaveExitList =
    JSON.parse(localStorage.getItem(STAFF_PROFILE_SAVE_EXIT_NO_ASK)) || [];

  const isHidePopupSaveAndExit = userNoSaveExitList?.some(
    (item) => item?.userId === currentUserId,
  );

  const { form, ContentComponent }: CurrentStepData = useMemo(() => {
    if (screen === PROFILE_START_SCREEN_PARAM) {
      return {
        form: formStep1,
        ContentComponent: CreateProfileOptions,
      };
    }
    if (screen === EXISTING_PROFILE_PARAM) {
      return {
        ContentComponent: ExistingProfiles,
      };
    }
    switch (currentStep) {
      case PROFILE_STEP.BASIC_INFORMATION:
        return {
          form: formStep1,
          ContentComponent: BasicInformation,
        };
      case PROFILE_STEP.ABOUT_STAFF:
        return {
          form: formStep2,
          ContentComponent: AboutStaff,
        };
      case PROFILE_STEP.EDUCATION_QUALIFICATION:
        return {
          form: null,
          ContentComponent: EducationAndQualification,
        };
      case PROFILE_STEP.AWARDS:
        return {
          form: null,
          ContentComponent: Awards,
        };
      default:
        return {};
    }
  }, [currentStep, formStep1, formStep2, screen]);

  const onJumpToStep = (newStep: number) => {
    if (
      currentStep === PROFILE_STEP.EDUCATION_QUALIFICATION &&
      newStep > PROFILE_STEP.EDUCATION_QUALIFICATION &&
      isEmpty(staffProfilePayload?.educations)
    ) {
      error({
        title: i18next.t('educatorStaffProfile.messageUpdateQualification'),
        icon: <CloseCircleFilled className="text-error" />,
      });
      return;
    }

    if (
      newStep > PROFILE_STEP.EDUCATION_QUALIFICATION &&
      isEmpty(staffProfilePayload?.educations)
    ) {
      form && form.submit();
      return;
    }

    if (form) {
      form.validateFields().then(() => {
        form.submit();
        dispatch(goToStep(newStep));
      });
    } else {
      dispatch(goToStep(newStep));
    }
  };

  const SiderComponent = isStartScreen ? (
    <SiderStartScreen />
  ) : (
    <CreateProfileSteps currentStep={currentStep} onChange={onJumpToStep} />
  );

  const onExit = () => {
    dispatch(resetStaffProfilePayload());
    history.push(`/centres/${centreId}/show/educator-staff-profiles`);
  };

  const handleSaveAndExit = async () => {
    let formData = { ...staffProfilePayload };
    switch (currentStep) {
      case PROFILE_STEP.BASIC_INFORMATION: {
        const basicInfo = omitBy(
          {
            ...formStep1.getFieldsValue(),
            userId: staffProfilePayload?.basicInfo?.userId ?? null,
          },
          isNil,
        );
        formData = {
          ...formData,
          basicInfo: isEmpty(basicInfo) ? null : basicInfo,
        };
        break;
      }
      case PROFILE_STEP.ABOUT_STAFF:
        formData = { ...formData, about: formStep2.getFieldsValue()?.about };
        break;
      default:
        break;
    }

    if (formData?.basicInfo?.jobRole) {
      const [jobRole, jobRankId] = formData?.basicInfo?.jobRole?.split('-');
      formData = {
        ...formData,
        basicInfo: {
          ...formData?.basicInfo,
          jobRole,
          jobRankId: Number(jobRankId),
        },
      };
    }
    const body = {
      ...formData,
      centreId,
      isDraft: true,
      currentStep,
    };

    if (staffProfilePayload?.isDraft && staffProfilePayload.id) {
      editStaffProfile(body).then(() => onExit());
    } else {
      createStaffProfile(body).then(() => onExit());
    }
  };

  const onSaveAndExit = () =>
    isHidePopupSaveAndExit ? handleSaveAndExit() : setVisible(true);

  return (
    <div>
      <StepsCreateLayout
        SiderComponent={SiderComponent}
        backgroundSider={theme.color.lightPink}
        logo={kindiCareLogo}
        onExit={() => (isStartScreen ? onExit() : onSaveAndExit())}
        textHeaderAction={isStartScreen ? 'button.exit' : 'button.saveAndExit'}
      >
        <ContentComponent form={form} />
      </StepsCreateLayout>
      <SaveAndExitModal
        visible={visible}
        resource={STAFF_PROFILE_SAVE_EXIT_NO_ASK}
        setVisible={setVisible}
        handleSave={handleSaveAndExit}
        title={i18next.t('educatorStaffProfile.saveProfile')}
        content={i18next.t('educatorStaffProfile.saveProfileMessage')}
      />
    </div>
  );
};
export default CreateStaffProfile;

import styled from 'styled-components';

const ResetPasswordStyleWrapper = styled.div`
  .isoLoginContentWrapper {
    width: 500px;
    min-width: 100%;
    height: 100%;
    overflow-y: auto;
    z-index: 10;
    position: relative;
    background: #fff;
  }

  .isoLoginContent {
    display: flex;
    flex-direction: column;
    padding: 20px 20px;
    position: relative;
    @media only screen and (max-width: 767px) {
      width: 100%;
    }

    .div-logo {
      text-align: center;
      margin-bottom: 30px;
    }

    .isoLogoWrapper {
      width: 100%;
      display: flex;
      margin-bottom: 50px;
      margin-right: 10px;
      justify-content: center;
      flex-shrink: 0;
      text-align: center;
    }

    .isoSignInForm {
      width: 100%;
      padding-top: 30px;
      display: flex;
      flex-shrink: 0;
      flex-direction: column;

      .isoInputWrapper {
        &:last-of-type {
          margin-bottom: 0;
        }

        input {
          &::-webkit-input-placeholder {
            color: ${({ theme }) => theme.text.empty};
          }

          &:-moz-placeholder {
            color: ${({ theme }) => theme.text.empty};
          }

          &::-moz-placeholder {
            color: ${({ theme }) => theme.text.empty};
          }
          &:-ms-input-placeholder {
            color: ${({ theme }) => theme.text.empty};
          }
        }
      }

      .isoForgotPass {
        font-size: 12px;
        color: ${({ theme }) => theme.text.tabTitle};
        text-decoration: none;

        &:hover {
          color: ${({ theme }) => theme.palette.primary};
        }
      }
      button {
        font-weight: 500;
      }
    }
  }

  .buttonWrapper {
    display: flex;
    justify-content: center;
    margin-top: 30px;
    .ant-btn {
      width: 100%;
    }
  }

  .txtDescription {
    margin-top: 30px;
  }
  .success-icon-div {
    text-align: center;
    margin-bottom: 20px;
    .anticon {
      font-size: 50px;
      color: ${({ theme }) => theme.color.green};
    }
  }
`;

export default ResetPasswordStyleWrapper;

export const GuideContentStyles = styled.div`
  text-align: center;
  margin-bottom: 30px;
  .guide-content__username {
    font-weight: bold;
    color: ${({ theme }) => theme.color.blueSecondary};
  }
`;

import React from 'react';
import { CrownFilled, UserOutlined } from '@ant-design/icons';
import { Tag } from 'antd';
import PropTypes from 'prop-types';
import { upgradeModal } from 'components/common/CheckPermissions/UpgradeSubscriptionModal';
import Avatar from 'antd/lib/avatar/avatar';
import i18next from 'i18next';
import HeaderUserInfoEnquiryStyles from './styles';

const HeaderUserInfoEnquiryFallback = ({
  user,
  enquiryTypeItem,
  isContactSession,
}) => {
  const onClickButton = () => {
    upgradeModal.open();
  };
  return (
    <HeaderUserInfoEnquiryStyles
      className="edit-action-wrapper d-flex"
      role="presentation"
      onClick={onClickButton}
      isContactSession={isContactSession}
    >
      <Avatar size={40} src={user?.avatar} icon={<UserOutlined />} />
      <div className="info-section">
        <div className="title">
          {`${user?.firstName || ''} ${user?.lastName || ''}`}
        </div>
        <div className="status">
          {enquiryTypeItem && (
            <Tag color={enquiryTypeItem.color}>
              {i18next.t(enquiryTypeItem.text)}
            </Tag>
          )}
        </div>
      </div>
      <CrownFilled className="action-feature-icon" />
    </HeaderUserInfoEnquiryStyles>
  );
};

HeaderUserInfoEnquiryFallback.propTypes = {
  user: PropTypes.object,
  enquiryTypeItem: PropTypes.object,
  isContactSession: PropTypes.bool,
};

export default HeaderUserInfoEnquiryFallback;

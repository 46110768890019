import React, {
  useState,
  forwardRef,
  useImperativeHandle,
  useRef,
} from 'react';
import PropTypes from 'prop-types';
import i18next from 'i18next';
import ModalCustom from 'components/common/ModalCustom';
import { Form, Button } from 'antd';
import FormChooseCampaign from 'containers/JobListings/components/Form/FormChooseCampaign';
import { moveJobListingToCampaign } from 'redux/jobs/jobListings/actions';
import { useDispatch } from 'react-redux';
import { getFilterFromUrl } from 'utils/tools';
import { useLocation } from 'react-router';
import {
  DEFAULT_CURRENT_PAGE,
  DEFAULT_SORT_INDIVIDUAL,
  LIMIT_ITEM,
} from 'containers/JobListings/constants';

const MoveCampaignModal = forwardRef(
  (
    { itemId, setSelectedRowKeys, retrieveList, isFixedCampaignSearchBar },
    ref,
  ) => {
    const [form] = Form.useForm();
    const [visible, setVisible] = useState();
    const dispatch = useDispatch();
    const { search } = useLocation();
    const { filter } = getFilterFromUrl(search);
    const filterRef = useRef(filter);

    useImperativeHandle(
      ref,
      () => ({
        open: () => {
          setVisible(true);
        },
      }),
      [],
    );

    const onCancel = () => {
      setVisible(false);
    };

    const onFinish = async ({ campaignId }) => {
      const res = await dispatch(
        moveJobListingToCampaign({ itemId, campaignId }),
      );
      setVisible(false);
      if (res?.meta?.requestStatus === 'fulfilled') {
        setSelectedRowKeys([]);
        retrieveList({
          filter: {
            sorts: filterRef.current.sorts || [DEFAULT_SORT_INDIVIDUAL],
            page: filterRef.current.page || DEFAULT_CURRENT_PAGE,
            size: filterRef.current.size || LIMIT_ITEM,
            filter: filterRef.current.filter,
          },
          isRefresh: true,
          keepFilter: true,
        });
      }
    };

    const renderFooter = () => (
      <div className="flex mb-0">
        <Button
          onClick={onCancel}
          type="primary w-full mt-20 fw-600"
          className="mr-5"
          htmlType="button"
          ghost
        >
          {i18next.t('button.cancel')}
        </Button>
        <Button
          onClick={form.submit}
          htmlType="submit"
          type="primary w-full mt-20 fw-600"
          className="ml-5"
        >
          {i18next.t('button.save')}
        </Button>
      </div>
    );

    return (
      <ModalCustom
        width={512}
        visible={visible}
        title={i18next.t('jobListings.moveToCampaign')}
        onCancel={onCancel}
        footer={renderFooter()}
        resetPaddingBottomBody={false}
      >
        <FormChooseCampaign
          onFinish={onFinish}
          form={form}
          maxHeight={300}
          isFixedCampaignSearchBar={isFixedCampaignSearchBar}
        />
      </ModalCustom>
    );
  },
);

MoveCampaignModal.propTypes = {
  itemId: PropTypes.array,
  setSelectedRowKeys: PropTypes.array,
  retrieveList: PropTypes.func,
  isFixedCampaignSearchBar: PropTypes.bool,
};
export default MoveCampaignModal;

import React from 'react';
import i18next from 'i18next';
import PropTypes from 'prop-types';
import JobAdditionForm from './FormAdditionJob';
import JobForm from './JobForm';

const JobHighlightBenefit = ({ initialValue }) => (
  <div>
    <div className="mb-12 fw-bold text-16">
      {i18next.t('jobs.moreAboutTheJob.jobHighLightsAndBenefits')}
      <span className="text-error"> * </span>
    </div>
    <JobAdditionForm name="benefits" initialValue={initialValue}>
      <JobForm />
    </JobAdditionForm>
  </div>
);

JobHighlightBenefit.propTypes = {
  initialValue: PropTypes.any,
};

export default JobHighlightBenefit;

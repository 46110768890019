import React from 'react';

import PropTypes from 'prop-types';
import styled from 'styled-components';

const CoverImageRatio = ({
  src,
  fullWidth,
  height = 200,
  ratioClassName = 'ratio-2-1',
}) => (
  <CoverImageRatioStyles
    height={height}
    className={
      fullWidth
        ? `${ratioClassName} cover-image__ratio`
        : 'cover-image__default'
    }
  >
    <img className="cover-image__image" alt="kindicare" src={src} />
  </CoverImageRatioStyles>
);

CoverImageRatio.propTypes = {
  src: PropTypes.string,
  fullWidth: PropTypes.bool,
  height: PropTypes.number,
  ratioClassName: PropTypes.string,
};

export default CoverImageRatio;

const CoverImageRatioStyles = styled.div`
  &.cover-image__ratio {
    > .cover-image__image {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &.cover-image__default {
    > .cover-image__image {
      height: ${({ height }) => `${height}px`};
      object-fit: cover;
      width: 100%;
    }
  }
`;

import styled from 'styled-components';

const BoxWrapper = styled.div`
  width: 100%;
  height: auto;
  margin-bottom: 30px;

  &.isoBoxWrapper {
    padding: 20px;
    background-color: ${({ theme }) => theme.background.content};
  }
  &.box-reset {
    padding: 0;
    background-color: transparent;
  }
  &:last-child {
    margin-bottom: 0;
  }

  @media only screen and (max-width: 767px) {
    padding: 20px;
  }

  &.half {
    width: calc(50% - 34px);
    @media (max-width: 767px) {
      width: 100%;
    }
  }
`;

export { BoxWrapper };

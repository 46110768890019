import styled from 'styled-components';
import { Modal } from 'antd';

export const ModalStyles = styled(Modal)`
  .ant-result {
    padding: 0;
  }
  .subscription-description {
    font-size: 14px;
    margin-top: 10px;
    &__end {
      margin-top: 5px;
    }
  }
`;

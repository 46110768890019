import React from 'react';
import { LEGEND_LABEL_TYPE } from 'containers/CompanyBenchmarking/localData/constants';
import Square from 'containers/CompanyBenchmarking/components/CompareRatingBarChart/LegendLabel/LabelItem/Square';
import Dashed from 'containers/CompanyBenchmarking/components/CompareRatingBarChart/LegendLabel/LabelItem/Dashed';
import PropTypes from 'prop-types';

const Content = ({ item: { color, value, avgRating, type } }) => (
  <>
    {type === LEGEND_LABEL_TYPE.SQUARE ? (
      <Square color={color} />
    ) : (
      <Dashed color={color} />
    )}
    <span className="legend-value">
      <span>{value}</span>
      <b>{avgRating}</b>
    </span>
  </>
);

Content.propTypes = {
  item: PropTypes.object,
  color: PropTypes.string,
  value: PropTypes.string,
  avgRating: PropTypes.string,
  type: PropTypes.string,
};

export default Content;

import i18next from 'i18next';
import { RightOutlined } from '@ant-design/icons';
import React, { MouseEventHandler } from 'react';
import CheckPermission from 'components/common/CheckPermissions';
import NextStepButtonFallBack from './NextStepButtonFallBack';
import { LinkStyle } from '../styles';

interface Props {
  centreStep: any[];
  onClickNext: MouseEventHandler<HTMLAnchorElement> | undefined;
  currentIndex: number;
  packageName: string;
  permissionKey: string;
  action: string;
  isSkipSubscription: boolean;
}

const NextStepButton = ({
  centreStep,
  onClickNext,
  currentIndex,
  packageName,
  permissionKey,
  action,
  isSkipSubscription,
}: Props) => {
  const btnClassName =
    currentIndex === centreStep.length - 1
      ? 'show-hide-btn__previous'
      : 'show-hide-btn__next';
  return (
    <CheckPermission
      packageName={packageName}
      permissionKey={permissionKey}
      action={action}
      isDisabled
      fallback={
        <NextStepButtonFallBack
          centreStep={centreStep}
          currentIndex={currentIndex}
        />
      }
      isSkipSubscription={isSkipSubscription}
    >
      <LinkStyle
        onClick={onClickNext}
        disabled={currentIndex === centreStep.length - 1}
        className="show-hide-btn ml-16"
      >
        <span className={`ml-16 ${btnClassName}`}>
          {i18next.t('button.next')}
        </span>

        <RightOutlined className={`ml-6 ${btnClassName}`} />
      </LinkStyle>
    </CheckPermission>
  );
};
export default NextStepButton;

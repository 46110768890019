import { pick } from 'lodash-es';
import { makeActions } from 'redux/crudCreator/actions';
import {
  postMessageApi,
  getMessagesApi,
  seenMessagesApi,
  readMessageApi,
  getTotalUnreadEnquiresApi,
  archiveEnquiryApi,
  getSummariesEnquiryApi,
} from 'api/enquiries';
import { createAsyncThunk, createAction } from '@reduxjs/toolkit';
import { apiWrapper } from 'utils/reduxUtils';
import { convertRequestParams } from '../crudCreator/dataProvider';

export const MODEL_NAME = 'enquiries';
export const enquiriesActions = makeActions(MODEL_NAME);

export const clearMessages = createAction('enquiries/clearMessages');

export const addNewMessageAfterCreateInfoRequest = createAction(
  'enquiries/addNewMessageAfterCreateInfoRequest',
);

export const delMessageAfterDelInfoRequest = createAction(
  'enquiries/delMessageAfterDelInfoRequest',
);

export const readMessage = createAsyncThunk(
  'enquiries/readMessage',
  async (payload = {}, thunkApi) => {
    try {
      const response = await apiWrapper({}, readMessageApi, payload.id);

      return response;
    } catch (error) {
      return thunkApi.rejectWithValue({ id: payload.id, data: error });
    }
  },
);

export const sendMessage = createAsyncThunk(
  'enquiries/sendMessage',
  async (payload = {}, thunkApi) => {
    try {
      const response = await apiWrapper(
        {},
        postMessageApi,
        payload.id,
        payload.data,
      );
      return { data: response, inboxId: payload.id };
    } catch (error) {
      return thunkApi.rejectWithValue({ id: payload.id, data: error });
    }
  },
);

export const getMessages = createAsyncThunk(
  'enquiries/getMessages',
  async (payload = {}, thunkApi) => {
    try {
      const { data = {}, options = {} } = payload;
      const { limit, offset, filter } =
        thunkApi.getState().enquiries.currentData?.resourceData || {};
      const { id, ...restData } = data;
      const convertRequest = convertRequestParams('GET_ALL', {
        limit,
        offset,
        filter,
        ...restData,
      });
      const response = await apiWrapper({}, getMessagesApi, id, convertRequest);

      return {
        data: {
          numberOfPages: Math.ceil(response.total / limit),
          ...response,
        },
        options,
      };
    } catch (error) {
      return thunkApi.rejectWithValue({ id: payload.data.id, data: error });
    }
  },
);

export const seenMessage = createAsyncThunk(
  'enquiries/seenMessage',
  async (payload = {}, thunkApi) => {
    try {
      const { id, ...restData } = payload;
      const response = await apiWrapper({}, seenMessagesApi, id, restData);
      return { data: response };
    } catch (error) {
      return thunkApi.rejectWithValue({ id: payload.id, data: error });
    }
  },
);

export const getTotalUnreadEnquires = createAsyncThunk(
  'enquiries/getTotalUnreadEnquires',
  async (_payload, thunkApi) => {
    try {
      const response = await apiWrapper({}, getTotalUnreadEnquiresApi);
      return response;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  },
);

export const getSummariesEnquiry = createAsyncThunk(
  'enquiries/getSummariesEnquiry',
  async (payload = {}, thunkApi) => {
    try {
      const convertRequest = convertRequestParams(
        'GET_ALL',
        pick(payload.data, ['q', 'filter.enquiryTypeId', 'filter.updatedAt']),
        'enquiries',
        {
          isOrderBy: false,
        },
      );

      const response = await apiWrapper(
        {},
        getSummariesEnquiryApi,
        convertRequest,
      );

      return response;
    } catch (error) {
      return thunkApi.rejectWithValue({ id: payload.id, data: error });
    }
  },
);

export const archiveEnquiry = createAsyncThunk(
  'enquiries/archiveEnquiry',
  async (payload = {}, thunkApi) => {
    try {
      const { q, filter } = thunkApi.getState().enquiries || {};

      const response = await apiWrapper(
        {},
        archiveEnquiryApi,
        payload.id,
        payload.data,
      );

      thunkApi.dispatch(getSummariesEnquiry({ data: { q, filter } }));

      return response;
    } catch (error) {
      return thunkApi.rejectWithValue({ id: payload.id, data: error });
    }
  },
);

export const getAllEnquiries = enquiriesActions.getAll;
export const editEnquiries = enquiriesActions.edit;
export const createEnquiries = enquiriesActions.create;
export const getByIdEnquiries = enquiriesActions.getDataById;
export const clearCurrentEnquiries = enquiriesActions.clearCurrent;
export const delEnquiries = enquiriesActions.del;
export const clearListEnquiries = enquiriesActions.clearList;

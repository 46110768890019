import React, { useCallback, useRef, useEffect } from 'react';
import successTick from 'assets/images/success-tick-circle.png';
import successLayer from 'assets/images/success-nested-layer.png';
import confettiBottom from 'assets/images/conffeti-bottom.png';
import confettiTopRight from 'assets/images/confetti-top-right.png';
import { useTranslation } from 'react-i18next';
import ReactCanvasConfetti from 'react-canvas-confetti';
import { CSSProperties } from 'styled-components';
import {
  ConfettiBottom,
  ConfettiTopRight,
  SuccessClaimWrapper,
  SuccessLayerImage,
} from './styles';

interface SuccessClaimProps {
  title: string;
  description: string;
  isClaim?: boolean;
}

const canvasStyles: CSSProperties = {
  position: 'fixed',
  pointerEvents: 'none',
  width: '100%',
  height: '100%',
  top: 0,
  left: 0,
};

const SuccessClaim = ({ title, description, isClaim }: SuccessClaimProps) => {
  const { t } = useTranslation();

  const isAnimatedConfetti = useRef(false);
  const refAnimationInstance = useRef(null);

  const getInstance = useCallback((instance) => {
    refAnimationInstance.current = instance;
  }, []);

  const makeShot = useCallback((particleRatio, opts) => {
    refAnimationInstance.current &&
      refAnimationInstance.current({
        ...opts,
        origin: { y: 0.7 },
        particleCount: Math.floor(200 * particleRatio),
      });
  }, []);

  const fire = useCallback(() => {
    makeShot(0.25, {
      spread: 26,
      startVelocity: 55,
    });

    makeShot(0.2, {
      spread: 60,
    });

    makeShot(0.35, {
      spread: 100,
      decay: 0.91,
      scalar: 0.8,
    });

    makeShot(0.1, {
      spread: 120,
      startVelocity: 25,
      decay: 0.92,
      scalar: 1.2,
    });

    makeShot(0.1, {
      spread: 120,
      startVelocity: 45,
    });
  }, [makeShot]);

  useEffect(() => {
    let timerId = null;
    if (!isAnimatedConfetti.current && isClaim) {
      isAnimatedConfetti.current = true;
      timerId = setTimeout(() => {
        fire();
      }, 100);
    }

    return () => clearTimeout(timerId);
  }, [fire, isClaim]);

  return (
    <SuccessClaimWrapper className="flex">
      <ReactCanvasConfetti refConfetti={getInstance} style={canvasStyles} />

      <div className="flex">
        <div className="mr-18">
          <img
            src={successTick}
            alt="success tick"
            width={56}
            className="success-tick"
          />
        </div>
        <h3>{t(title)}</h3>
      </div>
      <div>
        <p
          dangerouslySetInnerHTML={{
            __html: t(description),
          }}
        />
      </div>
      {isClaim && (
        <>
          <SuccessLayerImage src={successLayer} alt="success layer" />
          <ConfettiBottom src={confettiBottom} alt="success layer" />
          <ConfettiTopRight
            src={confettiTopRight}
            alt="success layer"
            width={320}
          />
        </>
      )}
    </SuccessClaimWrapper>
  );
};

export default SuccessClaim;

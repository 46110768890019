import { Result } from 'antd';
import i18next from 'i18next';
import React from 'react';
import { DOMAIN_LANDING } from 'configs/localData/constant';

const ResendEmailSuccess = () => (
  <div>
    <Result
      status="success"
      title={i18next.t('centresClaim.resendEmail.titleSuccess')}
      subTitle={
        <div className="mt-20">
          <p>{i18next.t('centresClaim.resendEmail.contentSuccess_l1')}</p>
          <p>
            <span className="text-primary">
              {i18next.t('centresClaim.resendEmail.contentSuccess_l2_text1')}
            </span>{' '}
            {i18next.t('centresClaim.resendEmail.contentSuccess_l2_text2')}
          </p>
          <p>{i18next.t('centresClaim.resendEmail.contentSuccess_l3')}</p>
          <p>
            {i18next.t('centresClaim.recommendApprovedSender', {
              domain: DOMAIN_LANDING,
            })}
          </p>
        </div>
      }
    />
  </div>
);

export default ResendEmailSuccess;

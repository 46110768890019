import { createSlice } from '@reduxjs/toolkit';
import {
  getApplicationsDashboard,
  getCentresDashboard,
  getActivitiesDashboard,
  getEnquiriesDashboard,
} from './actions';

export const initialState = {
  applications: {},
  centres: {},
  activities: {},
  enquiries: {},
};

const { reducer } = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {},
  extraReducers: {
    [getApplicationsDashboard.pending]: (state) => {
      state.applications.loading = true;
    },
    [getApplicationsDashboard.fulfilled]: (state, { payload }) => {
      state.applications.loading = false;
      state.applications.data = payload;
    },
    [getApplicationsDashboard.rejected]: (state, { payload }) => {
      state.applications.loading = false;
      state.applications.error = payload;
    },

    [getCentresDashboard.pending]: (state) => {
      state.centres.loading = true;
    },
    [getCentresDashboard.fulfilled]: (state, { payload }) => {
      state.centres.loading = false;
      state.centres.data = payload;
    },
    [getCentresDashboard.rejected]: (state, { payload }) => {
      state.centres.loading = false;
      state.centres.error = payload;
    },

    [getActivitiesDashboard.pending]: (state) => {
      state.activities.loading = true;
    },
    [getActivitiesDashboard.fulfilled]: (state, { payload }) => {
      state.activities.loading = false;
      state.activities.data = payload;
    },
    [getActivitiesDashboard.rejected]: (state, { payload }) => {
      state.activities.loading = false;
      state.activities.error = payload;
    },

    [getEnquiriesDashboard.pending]: (state) => {
      state.enquiries.loading = true;
    },
    [getEnquiriesDashboard.fulfilled]: (state, { payload }) => {
      state.enquiries.loading = false;
      state.enquiries.data = payload;
    },
    [getEnquiriesDashboard.rejected]: (state, { payload }) => {
      state.enquiries.loading = false;
      state.enquiries.error = payload;
    },
  },
});

export default reducer;

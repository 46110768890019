import React from 'react';
import i18next from 'i18next';
import { Col, Form, Radio, Row, Skeleton } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import {
  setCreateAdPayload,
  setSelectedAdType,
} from 'redux/advertisement/slice';
import {
  getAdsFormConfigLoadingSelector,
  getAdsFormConfigSelector,
  getCreateAdPayload,
} from 'redux/advertisement/selectors';
import { StepTitle } from '../styles';
import Footer from './Footer';
import AdsThumbnailCard from '../../../components/AdsThumbnailCard';

const TypeOfAd = ({ form }) => {
  const dispatch = useDispatch();
  const { type } = useSelector(getCreateAdPayload);
  const { adsTypes } = useSelector(getAdsFormConfigSelector);
  const loading = useSelector(getAdsFormConfigLoadingSelector);

  const onNext = () => {
    form.validateFields(['type']).then(({ type }) => {
      dispatch(setCreateAdPayload({ type }));
      dispatch(
        setSelectedAdType({ ...adsTypes.find((item) => item.id === type) }),
      );
    });
  };

  return (
    <div>
      <StepTitle>{i18next.t('advertisement.steps.step1')}</StepTitle>
      <Skeleton loading={loading} active>
        <Form form={form} initialValues={{ type }} onFinish={onNext}>
          <Form.Item name="type">
            <Radio.Group className="w-full">
              <Row>
                {adsTypes?.map((item, index) => (
                  <Col xl={8} md={12} xs={24} className="p-24" key={index}>
                    <Radio
                      value={item?.id}
                      onChange={() => {
                        dispatch(
                          setCreateAdPayload({
                            mediaList: [],
                          }),
                        );
                      }}
                    >
                      <div className="mb-12">
                        <strong className="text-16 text-black-base">
                          {item?.name}
                        </strong>
                        <div className="text-12 text-gray-500 ">
                          {item?.description}
                        </div>
                      </div>
                    </Radio>
                    <div className="ml-24">
                      <AdsThumbnailCard
                        maxImagesNumber={item.maxImagesNumber}
                        maxVideosNumber={item.maxVideosNumber}
                      />
                    </div>
                  </Col>
                ))}
              </Row>
            </Radio.Group>
          </Form.Item>
          <Footer onNext={onNext} />
        </Form>
      </Skeleton>
    </div>
  );
};

export default TypeOfAd;

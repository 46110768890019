import { Result } from 'antd';
import styled from 'styled-components';

const ResultStyles = styled(Result)`
  max-width: 800px;
  margin: auto;
  .ant-result-subtitle {
    margin-top: 15px;
    font-size: 16px;
  }
`;

export default ResultStyles;

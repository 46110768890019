import React, {
  forwardRef,
  useImperativeHandle,
  useState,
  useCallback,
} from 'react';
import PropTypes from 'prop-types';
import i18next from 'i18next';
import { useDispatch, useSelector } from 'react-redux';

import ModalCustom from 'components/common/ModalCustom';
import { CRUDSelectorsCustom } from 'redux/crudCreatorCustom/selector';
import InfiniteScrollData from 'components/common/InfiniteScrollData';
import { ProvidersModalStyles } from './styles';

const ProvidersModal = forwardRef(
  (
    {
      title = 'providers.providerOfCompany',
      resource = 'providersOfCompany',
      retrieveListAction,
    },
    ref,
  ) => {
    const [visible, setVisible] = useState(false);
    const [record, setRecord] = useState();
    const dispatch = useDispatch();

    const providersSelectorsCustom = new CRUDSelectorsCustom(resource);

    const providersData = useSelector(providersSelectorsCustom.getDataArr);
    const loading = useSelector(providersSelectorsCustom.getLoading);
    const enableLoadMore = useSelector(
      providersSelectorsCustom.enabledLoadMore,
    );

    const getProviders = ({ id, isRefresh }) => {
      id &&
        dispatch(
          retrieveListAction({
            id,
            data: { orderBy: 'name' },
            options: { isRefresh },
          }),
        );
    };

    useImperativeHandle(
      ref,
      () => ({
        open: (data) => {
          setVisible(true);
          setRecord(data);
          getProviders({ id: data?.id, isRefresh: true });
        },
      }),
      [], // eslint-disable-line
    );

    const onCancel = () => setVisible(false);

    const handleViewMore = useCallback(() => {
      enableLoadMore && getProviders({ id: record?.id });
    }, [getProviders]); // eslint-disable-line

    return (
      <ModalCustom
        visible={visible}
        title={`${i18next.t(title)} ${record?.name || ''}`}
        footer={null}
        onCancel={onCancel}
        resetPaddingBottomBody={false}
        resetPaddingTopBody={false}
      >
        <ProvidersModalStyles>
          <InfiniteScrollData
            handleViewMore={handleViewMore}
            loading={loading}
            enableLoadMore={enableLoadMore}
          >
            {providersData?.map((provider, index) => (
              <div
                key={String(index)}
                className="provider-item flex-center-between"
              >
                {provider?.name}
              </div>
            ))}
          </InfiniteScrollData>
        </ProvidersModalStyles>
      </ModalCustom>
    );
  },
);

ProvidersModal.propTypes = {
  title: PropTypes.string,
  resource: PropTypes.string,
  retrieveListAction: PropTypes.func,
};

export default ProvidersModal;

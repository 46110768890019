import { createSlice } from '@reduxjs/toolkit';
import { unionBy } from 'lodash-es';
import {
  getAllJobApplications,
  getJobApplicationsCardList,
  getJobApplicationSummary,
  updateJobApplicationStatus,
  getJobApplicationProfile,
  getJobApplicationSummaryCard,
  getTotalSubmittedJobApplications,
} from './actions';

const initialState = {
  data: [],
  currentData: {
    profile: {},
  },
  summary: {},
  totalSubmitted: 0,
  jobApplicationsError: '',
};

const getAllJobApplicationsPending = (
  state,
  {
    meta: {
      arg: { data, options = {} },
    },
  },
) => {
  state.loading = true;
  if (options.isRefresh) {
    state.data = [];
    state.resourceData = {
      ...initialState.resourceData,
      ...data,
      cardList: state?.resourceData?.cardList ?? {},
    };
  }
};

const getAllJobApplicationsSuccess = (state, { payload: { data } }) => {
  state.data = unionBy([...state.data, ...(data.results || [])], 'id');
  state.resourceData.total = data.paging.total || 0;
  state.resourceData.page = data.paging.page;

  state.loading = false;
  state.error = null;
};

const getJobApplicationsCardListSuccess = (state, { payload }) => {
  state.resourceData.cardList = payload;

  state.loading = false;
  state.error = null;
};

const getJobApplicationSummaryPending = (state) => {
  state.getJobApplicationSummaryLoading = true;
};

const getJobApplicationSummaryFulfilled = (state, { payload }) => {
  state.currentData = { ...state.currentData, summary: payload };
  state.getJobApplicationSummaryLoading = false;
};

const getJobApplicationSummaryFailed = (state, { payload: { data } }) => {
  state.error = data;
  state.getJobApplicationSummaryLoading = false;
};

const getAllJobApplicationsFail = (state, { payload: { data } }) => {
  state.error = data;
  state.loading = false;
};

const updateJobApplicationStatusFailure = (state, { payload }) => {
  state.error = payload;
};

const getJobApplicationProfilePending = (state) => {
  state.jobApplicationsError = '';
  state.getJobApplicationProfileLoading = true;
};

const getJobApplicationProfileFulfilled = (state, { payload }) => {
  state.currentData = { ...state.currentData, profile: payload.result };
  state.getJobApplicationProfileLoading = false;
};

const getJobApplicationProfileFailed = (state, { payload }) => {
  state.jobApplicationsError = payload;
  state.getJobApplicationProfileLoading = false;
};

const getJobApplicationSummaryCardSuccess = (state, { payload: { data } }) => {
  state.summary = data.result;
  state.summary.loading = false;
};

const getJobApplicationSummaryCardPending = (state) => {
  state.summary.loading = true;
};

const getJobApplicationSummaryCardFail = (state) => {
  state.summary.loading = false;
};

const getTotalSubmittedJobApplicationsSuccess = (state, { payload }) => {
  state.totalSubmitted = payload.total;
};

const getTotalSubmittedJobApplicationsFailed = (state) => {
  state.totalSubmitted = 0;
};

const jobApplications = createSlice({
  name: 'jobApplications',
  initialState,
  reducers: {
    updateApplicationStatus: (state, { payload = {} }) => {
      const { status, reason } = payload;
      state.currentData.profile.status.id = status;
      if (reason) state.currentData.profile.seeker.declinedReason = reason;
    },
    updateTotalSubmittedJobApplication: (state, { payload }) => {
      state.totalSubmitted = payload.total;
    },
  },
  extraReducers: {
    [getAllJobApplications.pending]: getAllJobApplicationsPending,
    [getAllJobApplications.fulfilled]: getAllJobApplicationsSuccess,
    [getAllJobApplications.rejected]: getAllJobApplicationsFail,

    [getJobApplicationSummary.pending]: getJobApplicationSummaryPending,
    [getJobApplicationSummary.fulfilled]: getJobApplicationSummaryFulfilled,
    [getJobApplicationSummary.rejected]: getJobApplicationSummaryFailed,

    [updateJobApplicationStatus.rejected]: updateJobApplicationStatusFailure,

    [getJobApplicationsCardList.fulfilled]: getJobApplicationsCardListSuccess,

    [getJobApplicationProfile.pending]: getJobApplicationProfilePending,
    [getJobApplicationProfile.fulfilled]: getJobApplicationProfileFulfilled,
    [getJobApplicationProfile.rejected]: getJobApplicationProfileFailed,

    [getJobApplicationSummaryCard.pending]: getJobApplicationSummaryCardPending,
    [getJobApplicationSummaryCard.fulfilled]:
      getJobApplicationSummaryCardSuccess,
    [getJobApplicationSummaryCard.rejected]: getJobApplicationSummaryCardFail,

    [getTotalSubmittedJobApplications.fulfilled]:
      getTotalSubmittedJobApplicationsSuccess,
    [getTotalSubmittedJobApplications.pending]:
      getTotalSubmittedJobApplicationsFailed,
  },
});

export const { updateApplicationStatus, updateTotalSubmittedJobApplication } =
  jobApplications.actions;
export default jobApplications.reducer;

import { baseApi } from 'redux/@rtkQuery/baseApi';
import { CreateProgramPayload } from 'types/programsAndLearning';
import { PREFIX_API_VER_2 } from 'configs/localData/constant';
import { PROGRAMS_LEARNING_TAG } from 'redux/@rtkQuery/tagTypes';

export const programsLearningApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    createPrograms: builder.mutation<unknown, CreateProgramPayload>({
      query(data) {
        return {
          url: `/${PREFIX_API_VER_2}/crm/programs`,
          method: 'POST',
          body: data,
        };
      },
      extraOptions: { isShowSuccess: true, isShowError: true },
      invalidatesTags: [PROGRAMS_LEARNING_TAG],
    }),
  }),
});

export const { useCreateProgramsMutation } = programsLearningApi;

import { unionBy } from 'lodash-es';
import { makeCRUDSlice } from 'redux/crudCreator';
import {
  delCentresOfUser,
  editRoleUser,
  getCentresOfUser,
  MODEL_NAME,
  usersActions,
} from './actions';

const INITIAL_STATE_EXTRA = {
  resourceData: {
    filter: {},
    offset: 0,
    limit: 10,
    total: 0,
    numberOfPages: 1,
    sort: '',
  },
  data: [],
};

const getCentresOfUserPending = (
  state,
  {
    meta: {
      arg: { data, options = {} },
    },
  },
) => {
  if (options.isRefresh) {
    state.centres = {
      resourceData: {
        ...INITIAL_STATE_EXTRA.resourceData,
        ...data,
      },
      data: [],
      loading: true,
    };
  } else {
    state.centres.loading = true;
    const resourceData = state.centres.resourceData || {};

    state.centres.resourceData = {
      ...resourceData,
      offset: resourceData.offset + resourceData.limit || 0,
      ...data,
    };
  }
};

const getCentresOfUserSuccess = (state, { payload: { data } }) => {
  state.centres.data = unionBy(state.centres.data, data?.results, 'id');

  state.centres.resourceData = {
    ...state.centres.resourceData,
    total: data?.total || 0,
    numberOfPages: data.numberOfPages,
  };

  state.centres.loading = false;
  state.error = null;
};

const getCentresOfUserFail = (state, { payload }) => {
  state.error = payload;
  if (state.centres) {
    state.centres.loading = false;
  }
};

const delCentresOfUserSuccess = (
  state,
  { payload: { id, centreId, options } },
) => {
  state.centres.data = state.centres.data.filter(
    (item) => item.id !== centreId,
  );
  state.centres.resourceData.total -= 1;
  if (!options?.isGetAllUsersList) {
    const totalCentres = Number(state.data[id]?.centres?.totalCentres);
    if (totalCentres) {
      state.data[id].centres.totalCentres = totalCentres - 1;
    }
  }
};

const editRoleUserSuccess = (
  state,
  {
    meta: {
      arg: { role },
    },
  },
) => {
  state.currentData.roleId = role?.id;
  state.currentData.role = role;
};

const slice = makeCRUDSlice(MODEL_NAME, usersActions, {
  [getCentresOfUser.pending]: getCentresOfUserPending,
  [getCentresOfUser.fulfilled]: getCentresOfUserSuccess,
  [getCentresOfUser.rejected]: getCentresOfUserFail,

  [delCentresOfUser.fulfilled]: delCentresOfUserSuccess,

  [editRoleUser.fulfilled]: editRoleUserSuccess,
});

export default slice.reducer;

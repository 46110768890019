import styled from 'styled-components';
import { Button } from 'antd';

export const SiderStartScreenStyle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background: radial-gradient(
    rgba(255, 255, 255, 0.6) 0%,
    rgba(255, 255, 255, 0.13) 100%
  );
  backdrop-filter: blur(30px);
  border-radius: 24px;
  font-weight: 700;
  font-size: 36px;
  line-height: 44px;
  letter-spacing: -0.02em;
  color: ${({ theme }) => theme.palette.primary};
  padding-top: 80px;

  @media only screen and (max-width: 992px) {
    display: none;
  }
`;

export const CreateStaffProfileContainer = styled.div`
  width: 70%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin: 0 auto;
`;

export const OptionButton = styled(Button)`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  min-height: 72px;
  border: 1px solid #ebecf0;
  border-radius: 12px;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: ${({ theme }) => theme.text.blackNeutral800};
  transition: all 0.3s;

  .anticon {
    color: #38465c;
  }

  .arrow-icon {
    margin-left: auto !important;
  }

  &:hover .anticon {
    color: ${({ theme }) => theme.palette.primary};
  }
`;

export const UserListContainerStyle = styled.div`
  --bottom-height: 89px;
  --top-padding: 64px;
  --search-height: 40px;
  --search-gap: 14px;

  overflow-y: auto;
  height: calc(
    100vh - var(--header-height) - var(--bottom-height) - var(--search-height) -
      var(--search-gap) - var(--top-padding)
  );
`;

export const NextButtonStyle = styled(Button)`
  background-color: ${({ disabled, theme }) =>
    disabled && `${theme.boardColors.N30} !important`};
  border: ${({ disabled, theme }) =>
    disabled && `1px solid ${theme.text.gray300} !important`};
  color: ${({ disabled, theme }) =>
    disabled && `${theme.text.neutral400} !important`};

  .anticon.anticon-right {
    color: ${({ disabled, theme }) =>
      disabled && `${theme.text.neutral400} !important`};
  }
`;

import React, { useContext } from 'react';
import { Avatar } from 'antd';
import i18next from 'i18next';
import CompanyDefaultLogo from 'assets/icons/company-default-logo.svg';
import { RestInputContext } from 'components/RestInput/RestInputContext';
import CompanyProfileInfo from './CompanyProfileInfo';
import JobContactInfo from './JobContactInfo';
import CompanyLocationForm from './CompanyLocationForm';

const CompaniesForm = () => {
  const { record } = useContext(RestInputContext);

  return (
    <div>
      <div className="flex-center mb-32">
        <div className="avatar-company">
          <Avatar src={record?.logo || CompanyDefaultLogo} size={120}>
            {i18next.t('companies.logo')}
          </Avatar>
        </div>
      </div>

      <CompanyProfileInfo />
      <CompanyLocationForm />
      <JobContactInfo />
    </div>
  );
};

export default CompaniesForm;

import React, { useMemo, useEffect } from 'react';
import { Spin } from 'antd';
import i18next from 'i18next';
import CustomBreadcrumb from 'components/common/Breadcrumb';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import {
  getJobTemplateConfig,
  getJobTemplatesDetail,
} from 'redux/jobs/jobTemplates/actions';
import {
  getDataJobTemplateDetail,
  getLoadingJobTemplateDetail,
} from 'redux/jobs/jobTemplates/selectors';
import PageTitle from 'components/common/PageTitle';
import ButtonCreateJobListing from 'containers/JobListings/components/buttons/ButtonCreateJobListing';
import JobDetail from '../JobDetail';

const JobShow = () => {
  const { id } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getJobTemplatesDetail({ id }));
    dispatch(getJobTemplateConfig());
  }, [id, dispatch]);

  const currentData = useSelector(getDataJobTemplateDetail) || {};
  const loading = useSelector(getLoadingJobTemplateDetail);

  const customBreadcrumb = useMemo(
    () => [
      {
        title: i18next.t('sideBar.jobs'),
        path: '/jobTemplates',
      },
      {
        title: i18next.t('jobs.jobTemplates.title'),
        path: '#',
      },
      {
        title: currentData?.templateName || i18next.t('error.waitingUpdate'),
        path: '#',
      },
    ],
    [currentData.templateName],
  );

  return (
    <div>
      {loading ? (
        <Spin className="flex-center" />
      ) : (
        <>
          <CustomBreadcrumb data={customBreadcrumb} />
          <PageTitle
            extraAction={<ButtonCreateJobListing isShowFromJobTemplateDetail />}
          >
            {i18next.t('jobs.jobTemplates.titleDetail')}
          </PageTitle>
          <JobDetail currentData={currentData} />
        </>
      )}
    </div>
  );
};

export default JobShow;

import React, { useState } from 'react';
import i18next from 'i18next';
import CreateProgramModal from 'containers/ProgramsAndLearning/CreateProgramModal';
import { CreateProgramButtonStyle } from 'containers/ProgramsAndLearning/styles';

interface Props {
  isCreate: boolean;
  onClick?: (e: MouseEvent | React.MouseEvent<HTMLElement>) => void;
}

const CreateProgramButton = ({ isCreate, onClick }: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <>
      <CreateProgramButtonStyle
        disabled={!isCreate}
        type="primary"
        onClick={(e) => {
          onClick && onClick(e);
          setIsOpen(true);
        }}
        className="text-16 fw-bold p-12-24 h-48 rounded-12"
      >
        {i18next.t('programLearning.createProgram')}
      </CreateProgramButtonStyle>
      <CreateProgramModal visible={isOpen} onClose={() => setIsOpen(false)} />
    </>
  );
};

export default CreateProgramButton;

import i18next from 'i18next';
import React from 'react';
import { isEmpty } from 'lodash-es';
import { SpacingDot } from './styles';

interface Props {
  userType: string;
  userLocation: string;
  userLocationValues: string[];
}

const TargetedAudienceItem = ({
  userType,
  userLocation,
  userLocationValues,
}: Props) => (
  <div>
    <div className="text-gray-500 mb-4">
      {i18next.t('advertisement.byUserType')}
    </div>
    <div className="text-black-base mb-12">{userType}</div>
    <div className="text-gray-500 mb-4">
      {i18next.t('advertisement.byUserLocation')}
    </div>
    <div className="text-black-base">
      {userLocation}
      {isEmpty(userLocationValues) ? null : (
        <>
          <SpacingDot>●</SpacingDot> {userLocationValues.join(', ')}
        </>
      )}
    </div>
  </div>
);

export default TargetedAudienceItem;

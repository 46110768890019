import React, { useCallback } from 'react';

import { useHistory } from 'react-router-dom';
import { getSearch } from 'utils/tools';

import FilterCustom from 'components/common/FilterCustom';
import { useApplicationList } from '../../List/context';
import ApplicationFilterForm from './FilterForm';

const ApplicationFilterCustom = () => {
  const { setFilter } = useApplicationList();

  const history = useHistory();

  const { location } = history;

  const pushRoute = useCallback(
    (searchStr) => {
      history.push(`${location.pathname}?${getSearch({ filter: searchStr })}`);
    },
    [location.pathname, history], // eslint-disable-line
  );

  const formatTimeSubmit = useCallback(
    (timeArr) =>
      timeArr
        ? {
            $gt: timeArr?.[0]
              ? timeArr[0].startOf('date').toISOString()
              : undefined,
            $lt: timeArr?.[1]
              ? timeArr[1].endOf('date').toISOString()
              : undefined,
          }
        : undefined,
    [],
  );

  const onSubmitFilter = ({ submittedAt, startDay, ...values }) => {
    const filter = {
      ...values,
      submittedAt: formatTimeSubmit(submittedAt),
      startDay: formatTimeSubmit(startDay),
    };
    setFilter(filter);
    pushRoute(filter);
  };

  const onClearFilter = () => {
    setFilter({});
    pushRoute({});
  };

  return (
    <FilterCustom onSubmitFilter={onSubmitFilter} onClearFilter={onClearFilter}>
      <ApplicationFilterForm />
    </FilterCustom>
  );
};

export default ApplicationFilterCustom;

import styled from 'styled-components';

const CentresCardStyles = styled.div`
  height: 100%;
  background: #fff;
  border-radius: 8px;
  a {
    color: currentColor;
  }
  .centre-image {
    height: 150px;
    position: relative;
    .rating {
      position: absolute;
      top: 14px;
      left: 14px;
    }
    .nqs-rating-img {
      position: absolute;
      background: #fff;
      border-radius: 4px;
      right: 14px;
      bottom: 12px;
      height: 35px;
      object-fit: contain;
    }
    .indicator-progress {
      position: absolute;
      left: 10px;
      bottom: 6px;
      background: #fff;
      border-radius: 50px;
      object-fit: contain;
    }
    .ant-avatar {
      border-radius: 8px 8px 0px 0px;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      .anticon {
        color: ${({ theme }) => theme.text.formIcon};
        font-size: 30px;
      }
    }
    .ant-progress-inner:not(.ant-progress-circle-gradient)
      .ant-progress-circle-path {
      stroke-width: 12 !important;
    }
  }
  .content-card {
    padding: 10px 15px;
    .last-updated {
      font-size: 12px;
      padding-left: 2px;
      color: #7a7a9d;
    }
    .name-centre {
      font-size: 20px;
      font-weight: bold;
      margin-bottom: 8px;
      color: ${({ theme }) => theme.text.mainL1};
    }

    .info-content {
      display: flex;
      justify-content: space-between;
      font-size: 16px;
      .item-info {
        display: flex;
        font-size: 16px;
        .anticon {
          line-height: 30px;
          color: ${({ theme }) => theme.text.mainL2};
          font-size: 16px;
        }
      }
      .value-item {
        margin-left: 10px;
        word-break: break-word;
        line-height: 26px;
        color: ${({ theme }) => theme.text.mainL1};
      }
    }
  }
  .ant-progress-circle.ant-progress-status-success .ant-progress-text {
    color: #000;
  }
  .ant-progress-status-success
    .ant-progress-inner:not(.ant-progress-circle-gradient)
    .ant-progress-circle-path {
    stroke: #1890ff;
  }
  .ant-progress-circle .ant-progress-text {
    font-size: 12px;
    font-weight: 700;
    padding: 3px;
  }
`;

export default CentresCardStyles;

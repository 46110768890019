import React from 'react';
import { Popover } from 'antd';
import i18next from 'i18next';
import PropTypes from 'prop-types';
import { MarketDefaultStyles } from '../../containers/PriceBenchmarking/components/MapView/styles';

const MarketDefault = ({ Icon, displayAddress }) => (
  <MarketDefaultStyles>
    <Popover
      content={displayAddress ?? i18next.t('priceBenchmarking.defaultLocation')}
    >
      <Icon />
    </Popover>
  </MarketDefaultStyles>
);

MarketDefault.propTypes = {
  Icon: PropTypes.any,
  displayAddress: PropTypes.string,
};

export default MarketDefault;

import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getEULAData } from 'redux/legalDocuments/actions';
import { getEULADataSelector } from 'redux/legalDocuments/selectors';
import { Skeleton } from 'antd';
import { EULAContentStyles } from './styles';

const EULAContent = () => {
  const dispatch = useDispatch();

  const { data, isLoading } = useSelector(getEULADataSelector);

  const fetchEULAPage = useCallback(() => {
    dispatch(getEULAData());
  }, [dispatch]);

  useEffect(() => {
    fetchEULAPage();
  }, [fetchEULAPage]);

  return (
    <EULAContentStyles>
      <Skeleton loading={isLoading} paragraph={{ rows: 50 }} active>
        <div dangerouslySetInnerHTML={{ __html: data }} />
      </Skeleton>
    </EULAContentStyles>
  );
};

export default EULAContent;

import React, {
  forwardRef,
  useImperativeHandle,
  useState,
  useRef,
} from 'react';
import PropTypes from 'prop-types';
import RestEditor from 'components/RestInput/RestEditor';
import JobTemplateModal from 'containers/JobTemplates/components/Modals';

const AboutTheJobModal = forwardRef(({ aboutTheJob }, ref) => {
  const [visible, setVisible] = useState(false);
  const editorRef = useRef();

  useImperativeHandle(
    ref,
    () => ({
      toggleModal: () => {
        setVisible(true);
      },
    }),
    [],
  );

  const onCancel = () => {
    setVisible(false);
  };

  return (
    <JobTemplateModal
      title="jobs.moreAboutTheJob.aboutTheJob"
      visible={visible}
      onCancel={onCancel}
      width={1000}
    >
      <RestEditor
        defaultValue={aboutTheJob}
        editorRef={editorRef}
        source="aboutTheJob"
        header="jobs.moreAboutTheJob.aboutTheJob"
        height={600}
        required
      />
    </JobTemplateModal>
  );
});

AboutTheJobModal.propTypes = {
  aboutTheJob: PropTypes.string,
};

export default AboutTheJobModal;

import React from 'react';
import { TooltipProps } from 'recharts';
import MetricLegendItem from '../MetricLegendItem';
import { ListMetrics, MetricItem, TooltipContainer } from './styles';

interface PayloadItem {
  dataKey: string;
  color: string;
  name: string;
  value: string | number;
  opacity: number;
}

const TooltipContent = ({ payload }: TooltipProps<string, string | number>) => (
  <TooltipContainer>
    <ListMetrics
      split={false}
      header={
        <div className="flex-center text-12">{payload?.[0]?.payload?.date}</div>
      }
      dataSource={payload}
      renderItem={(item: PayloadItem) => (
        <MetricItem opacity={item.opacity}>
          <MetricLegendItem
            color={item.color}
            name={item.name}
            className="mr-24 text-12"
          />
          <strong className="text-12">{item.value}</strong>
        </MetricItem>
      )}
    />
  </TooltipContainer>
);

export default TooltipContent;

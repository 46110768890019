import i18next from 'i18next';
import React from 'react';
import { PlusCircleOutlined } from '@ant-design/icons';
import { FormInstance, Tabs } from 'antd';
import { CopyIcon } from 'components/common/SVGIcon';
import {
  EXISTING_AUDIENCE_TAB_KEY,
  NEW_AUDIENCE_TAB_KEY,
} from 'containers/Advertisement/constants';
import { TargetedAudience } from 'api/advertisement/type';
import NewAudience from './NewAudience';
import ExistingAudience from './ExistingAudience';
import { AudienceTabs } from './styles';

interface Props {
  form: FormInstance;
  handleFinish?: () => void;
  activeTab: string;
  setActiveTab: (activeTab: string) => void;
  onActiveTabChange?: (activeKey: string) => void;
  initialValues?: TargetedAudience;
}

const AudienceTabsForm = ({
  activeTab,
  setActiveTab,
  form,
  handleFinish,
  initialValues,
  onActiveTabChange,
}: Props) => {
  const TABS = [
    {
      key: NEW_AUDIENCE_TAB_KEY,
      title: 'advertisement.newAudience',
      Icon: PlusCircleOutlined,
      TabComponent: NewAudience,
      afterSavedAudience: () => setActiveTab(EXISTING_AUDIENCE_TAB_KEY),
    },
    {
      key: EXISTING_AUDIENCE_TAB_KEY,
      title: 'advertisement.existingAudience',
      Icon: CopyIcon,
      TabComponent: ExistingAudience,
    },
  ];

  const onChange = (activeKey: string) => {
    if (onActiveTabChange) {
      onActiveTabChange(activeKey);
      return;
    }

    form.resetFields();
    setActiveTab(activeKey);
  };

  return (
    <AudienceTabs activeKey={activeTab} onChange={onChange}>
      {TABS.map(({ title, Icon, key, TabComponent, ...tabProps }) => (
        <Tabs.TabPane
          key={key}
          tab={
            <span>
              <Icon />
              {i18next.t(title)}
            </span>
          }
        >
          <TabComponent
            form={form}
            onFinish={handleFinish}
            initialValues={initialValues}
            {...tabProps}
          />
        </Tabs.TabPane>
      ))}
    </AudienceTabs>
  );
};

export default AudienceTabsForm;

export const COLOR_KINDI_RATING = {
  price: '#DB147F',
  avg: '#32A4FC',
};

export const PREFIX_API_VER_1 = 'api/v1';

export const PREFIX_API_VER_2 = 'thor/v2';

export const CURRENCY_UNIT = 'AUD';

export const FORMAT_DEFAULT_MONEY = '0.00';

export const ENQUIRY_MODE_KEY = 'enquiry-mode';

export const EMBED_YOUTUBE_LINK = 'https://www.youtube.com/embed/';

export const EMAIL_FEEDBACK = 'feedback@kindicare.com';

export const EMAIL_CONTACT = 'customercare@kindicare.com';

export const SIGN_MASK_EMAIL = '●●●●●';

export const SUPPORT_EMAIL = 'support@kindicare.com';

export const DOMAIN_LANDING = 'kindicare.com';

export const KC_HOST_NAME = 'https://kindicare.com/';

export const RATING_LINK = 'https://kindicare.com/the-kindicare-rating';

export const LISTING_STEP = 11;

export const CUSTOMISE_BUTTONS_CONST = {
  applyBtn: {
    key: 'apply-button-url',
    text: 'button.apply',
  },
  bookATourOrVisit: {
    key: 'book-visit-and-tour-url',
    text: 'button.bookATourOrVisit',
  },
};

export const CUSTOMISE_BUTTONS = Object.values(CUSTOMISE_BUTTONS_CONST);

export const PREFIX_URL = {
  public: '/public',
  admin: '/admin',
  crm: '/crm',
};

export const KEYS_GET_GEO_MAP_REQUIRED = [
  'city',
  'state',
  'addressL1',
  'postCode',
];

export const PLACE_DEFAULT_OPTIONS = {
  componentRestrictions: { country: 'au' },
  strictBounds: true,
};

export const ENABLE_TYPES_CONST = {
  active: {
    value: 'ACTIVE',
    isEnable: true,
    text: 'isActive.active',
  },
  inactive: {
    value: 'INACTIVE',
    isEnable: false,
    text: 'isActive.inactive',
  },
};

export const ENABLE_TYPES = Object.values(ENABLE_TYPES_CONST);

export const LABEL_TABLE_KEY = {
  serviceType: 'title-of-centre-service',
  ageGroup: 'title-of-age-group',
  sessionType: 'title-of-session-type',
  childCareService: 'title-of-primary-childcare-service',
  isShowingListingPerformanceTag: 'is-showing-listing-performance-tag-new',
  isShowingProgramsAndLearningTagNew:
    'is-showing-programs-and-learning-tag-new',
};

export const MAX_LIMIT_LIST = 100;
export const LIMIT_COST_LENGTH = 6;
export const LIMIT_SERVICE_LENGTH = 25;
export const LIMIT_VALUE_FOR_MONEY_LENGTH = 9;
export const MAX_LIMIT_MAP = 200;
export const DEFAULT_DEADLINE_DAYS_DURATION = 30;
export const DEFAULT_DEADLINE_HOURS_DURATION = 0;
export const MIN_DEADLINE_DAYS = 0;
export const MAX_DEADLINE_DAYS = 365;
export const MAX_DEADLINE_HOURS = 24;
export const MAX_VALUE_WITH_DECIMALS = 1000;
export const CHARACTER_DASH = '-';
export const EMPTY_STRING = '';
export const SOCIAL_MEDIA_CHANNEL_TYPE = 'social_channel';
export const SORT_ASCEND_VALUE = 'ascend';
export const SORT_DESCEND_VALUE = 'descend';
export const DISPLAY_TYPES = { board: 'board', list: 'list' };

export const ORDER_BY_TYPE = {
  ASC: 'ASC',
  DESC: 'DESC',
};

export const JOB_STATUS = {
  open: 'Open',
  closed: 'Closed',
};

export const TABS_KEY_VALUE = {
  summary: 'summary',
  mailbox: 'mailbox',
  documents: 'documents',
  activitiesAndNotes: 'activities-and-notes',
  tasks: 'tasks',
};

export const ACTIVITY_TYPE = {
  IncomingCall: 'Incoming Call',
  OutgoingCall: 'Outgoing Call',
  Email: 'Email',
  ChangeJob: 'Change Job',
  Note: 'Note',
  Other: 'Other',
};

export const DEFAULT_LINKS = [
  'youtube.com',
  'youtu.be',
  'https://www.youtube.com',
  'https://youtu.be',
];

export const UPLOAD_FILE_STATUS = {
  uploading: 'uploading',
  done: 'done',
  error: 'error',
};

export const MAX_FILE_SIZE = 25;
export const JOB_DISCOUNT_TYPES = {
  staffDiscount: 'Staff Discount',
  educatorDiscount: 'Educator Discount',
};

export const ONE_HUNDRED_PERCENT_VALUE = '100%';

export const IMAGE_TYPES = ['png', 'jpg', 'jpeg', 'gif', 'tif', 'tiff', 'svg'];

export const VIDEO_TYPES = ['mov', 'mp4', 'avi', 'flv', 'wmv', 'webm'];

export const AUDIO_TYPES = ['mp3', 'wav'];

export const MAX_FILE_SIZE_IN_MB = 2;

export const MAX_FILE_DIMENSIONS = 1280;

export const TIME_ZONE_HEADER_KEY = 'time-zone';

export const DELIVERY_STATUS = {
  Running: 'Running',
  Completed: 'Completed',
};

export const EMPTY_CHARACTER = '_';

export const DEFAULT_SIZE = 10;

export const START_DATE_TRACKING_EVENT = '31 Oct 2021';

export const DEFAULT_LIMIT_ITEM = 10;

export const DEFAULT_CURRENT_PAGE = 1;

export const DEFAULT_LOCATION = {
  latitude: -33.85712217644495,
  longitude: 151.21538442448275,
};
export const FORMAT_DATE_PICKER = 'DD MMM YYYY';

export const REFRESH_TOKEN_KEY = 'refreshToken';

export const SESSION_TOKEN_KEY = 'sessionToken';

export const IMPERSONATE_SESSION_KEY = 'impersonateSession';

export const PREFIX_CRM_DEVICE_ID = 'crm';

export const LOCATION_DEFAULT = {
  subAdminArea: 'NSW',
  country: 'Australia',
  countryCode: 'AU',
  feature: 'Sydney Opera House',
  formattedAddress: 'Sydney Opera House, Sydney CBD, NSW 2000, Australia',
  locality: 'Sydney CBD',
  geoLocation: { latitude: -33.85712217644495, longitude: 151.21538442448275 },
  postalCode: '2000',
  streetName: 'Dobson Crescent',
  streetNumber: '56-58',
  subLocality: null,
  isDefault: false,
  isCurrentLocation: true,
};

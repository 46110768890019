import styled from 'styled-components';

export const FeaturesHeaderTableStyles = styled.div`
  line-height: 20px;
  background: ${({ theme }) => theme.table.headerBackground};
  padding: 12px 15px;
`;

export const ColumnFeatureStyles = styled.div`
  &::before {
    content: '';
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 1px;
    background: ${({ theme }) => theme.boardColors.N30};
    height: 90%;
  }
`;

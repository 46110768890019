import React from 'react';
import { Spin, Steps } from 'antd';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { centresSelectors } from 'redux/centres/selectors';
import { CLAIM_STATUSES } from 'configs/localData';
import { StepsStyled } from './styles';

interface Props {
  currentStep: number;
  onChange: (current: number) => void;
}

const ClaimSteps = ({ currentStep, onChange }: Props) => {
  const { t } = useTranslation();
  const currentCentre = useSelector(centresSelectors.getCurrentData);
  const loading = useSelector(centresSelectors.getLoadingCurrentRecord);

  const { isClaim } =
    CLAIM_STATUSES.find((status) =>
      status.value?.includes(currentCentre?.claimStatus),
    ) || CLAIM_STATUSES[0];

  const stepsData = [
    {
      title: 'centresClaim.find',
    },
    {
      title: isClaim ? 'centresClaim.claim' : 'button.dispute',
    },
    {
      title: 'centresClaim.login',
    },
  ];

  if (loading) return <Spin />;

  return (
    <StepsStyled
      current={currentStep}
      onChange={onChange}
      labelPlacement="vertical"
      isDispute={!isClaim}
      responsive={false}
    >
      {stepsData.map((item) => (
        <Steps.Step title={t(item.title)} key={item.title} className="mb-30" />
      ))}
    </StepsStyled>
  );
};

export default ClaimSteps;

import {
  IS_PREFERRED,
  IS_REQUIRED,
  NO_EXPERIENCE_REQUIRED_NAME,
  PREFIX_ID_KEY,
} from 'containers/JobTemplates/constants';
import {
  find,
  flatten,
  initial,
  isEmpty,
  isNil,
  last,
  mapKeys,
  mapValues,
  pick,
  pickBy,
  sortBy,
} from 'lodash-es';

export const getApplicationSettingsTab = (jobDetail) => {
  const settingProperties = [
    'qualification',
    'experience',
    'languages',
    'eligibility',
    'skills',
    'certifications',
    'immunisations',
  ];

  let applicationSettings = [];

  const data = pickBy(pick(jobDetail, settingProperties));

  const getRow = (category, value) => ({
    category,
    name: value?.name || value?.displayText,
    value: value?.isPreferred ? IS_PREFERRED : IS_REQUIRED,
    ...pick(value, ['id', 'ids']),
  });

  Object.entries(data).forEach(([category, value]) => {
    if (Array.isArray(value)) {
      const categorySettings = value.map((item) => getRow(category, item));
      applicationSettings = [...applicationSettings, ...categorySettings];
    } else {
      applicationSettings = [...applicationSettings, getRow(category, value)];
    }
    if (
      category === 'experience' &&
      value?.name === NO_EXPERIENCE_REQUIRED_NAME
    ) {
      applicationSettings = applicationSettings?.filter(
        (item) => item?.category !== 'experience',
      );
    }
  });

  return applicationSettings;
};

export const getValueFormSubmit = (key, values) => {
  const result = {};

  if (isEmpty(values[key])) {
    return null;
  }

  Object.entries(values[key] || {}).forEach(([id, value]) => {
    const obj = {
      id,
      isPreferred: value === IS_PREFERRED,
      isRequired: value === IS_REQUIRED,
    };

    if (key === 'experience' || key === 'qualification') {
      result[key] = obj;
    } else {
      result[key] = [...(result[key] || []), obj];
    }
  });

  return result;
};

export const getIDFromRawString = (rawId) => {
  if (!rawId) {
    return null;
  }

  return rawId.startsWith(PREFIX_ID_KEY)
    ? rawId.split(PREFIX_ID_KEY)?.[1]
    : rawId;
};

export const getToggleSettings = (categoryName, dataSource, config) =>
  Object.entries(dataSource || {})
    ?.filter(([, value]) => value)
    ?.map(([rawId]) => {
      const id = getIDFromRawString(rawId);

      return {
        category: categoryName,
        name: find(config, { id })?.name,
        id,
      };
    });

export const getEligibilitiesName = (eligibilityIds, config) => {
  const eligibilitiesName = sortBy(
    eligibilityIds?.map(
      (value) => find(config?.eligibilities, { value })?.name,
    ),
  ).filter((item) => !isNil(item));

  if (isEmpty(eligibilitiesName)) {
    return null;
  }

  return eligibilitiesName?.length === 1
    ? eligibilitiesName[0]
    : `${initial(eligibilitiesName).join(', ')} or ${last(eligibilitiesName)}`;
};

export const getRowsApplicationSettingsStep = (payload, config) => {
  const noExperienceId = config?.experiences?.find(
    (item) => item?.name === NO_EXPERIENCE_REQUIRED_NAME,
  )?.id;

  const isShowExperienceSetting = payload?.experience !== noExperienceId;

  const experienceSetting = isShowExperienceSetting
    ? [
        {
          category: 'experience',
          name: find(config?.experiences, {
            value: payload?.experience,
          })?.name,
          id: payload?.experience,
        },
      ]
    : [];

  const rawData = [
    {
      category: 'qualification',
      name: find(config?.qualifications, { value: payload?.qualification })
        ?.name,
      id: payload?.qualification,
    },
    ...experienceSetting,
    ...(payload?.languages?.map((id) => ({
      category: 'languages',
      name: find(config?.languages, { value: id })?.name,
      id,
    })) || []),
    {
      category: 'eligibility',
      ids: payload?.eligibility,
      name: getEligibilitiesName(payload?.eligibility, config),
    },
    ...getToggleSettings(
      'skills',
      payload?.skills,
      flatten(config?.skills?.map((item) => item?.items)),
    ),
    ...getToggleSettings(
      'certifications',
      payload?.certifications,
      config?.certifications,
    ),
    ...getToggleSettings(
      'immunisations',
      payload?.immunisations,
      config?.immunisations,
    ),
  ];

  const transformedData = rawData
    ?.filter((item) => !isEmpty(item?.id) || !isEmpty(item?.ids))
    ?.map((item) => ({
      ...item,
      value: IS_PREFERRED,
    }));

  return transformedData;
};

export const transformDataStructureStepsForm = (jobTemplate) => {
  const {
    discount,
    jobRole,
    jobType,
    salary,
    experience,
    languages,
    qualification,
    eligibility,
    features,
    skills,
    certifications,
    immunisations,
  } = jobTemplate;

  const restTemplate = pick(jobTemplate, [
    'templateName',
    'jobTitle',
    'minJobMatch',
    'benefits',
    'aboutTheJob',
    'candidateResponsibilities',
    'aboutCandidate',
  ]);

  const transformArray = (data) =>
    isEmpty(data)
      ? null
      : mapValues(
          mapKeys(data, (item) => `${PREFIX_ID_KEY}${item.id}`),
          'value',
        );

  return {
    ...restTemplate,
    discount: {
      typeId: discount?.type?.id,
      value: discount?.value,
    },
    jobRole: jobRole?.id,
    jobType: jobType?.id,
    salary: {
      ...salary,
      payTypeId: salary?.payType?.id,
      payRateId: salary?.payRate?.id,
    },
    experience: experience?.id,
    languages: languages?.map((item) => item?.id),
    qualification: qualification?.id,
    eligibility: eligibility?.ids,
    features: transformArray(features),
    skills: transformArray(skills),
    certifications: transformArray(certifications),
    immunisations: transformArray(immunisations),
  };
};

export const getDefaultPayTypeId = (config) =>
  config?.salary?.payTypes?.[0]?.id;

import React, { useContext, useMemo } from 'react';
import PropTypes from 'prop-types';

import { RestInputContext } from 'components/RestInput/RestInputContext';
import { useSelector } from 'react-redux';
import { companiesOfMeBasicSelectors } from 'redux/companiesOfMeBasic/selectors';
import ListCheckboxSelect from './ListCheckboxSelect';

const CompanyListSelect = ({ retrieveList }) => {
  const { record } = useContext(RestInputContext);
  const data = useSelector(companiesOfMeBasicSelectors.getDataArr);
  const paging = useSelector(companiesOfMeBasicSelectors.getResourceData);
  const loading = useSelector(companiesOfMeBasicSelectors.getLoading);

  const defaultValue = useMemo(
    () => record.companies?.map((item) => item?.id),
    [record.companies],
  );

  return (
    <ListCheckboxSelect
      retrieveList={retrieveList}
      name="companyIds"
      defaultValue={defaultValue}
      data={data}
      paging={paging}
      loading={loading}
      messageRequired="users.selectCompanyRequired"
      selectedResourceTitles="users.selectedCompaniesTotal"
      selectedResourceTitle="users.selectedCompanyTotal"
    />
  );
};

CompanyListSelect.propTypes = {
  retrieveList: PropTypes.func,
};

export default CompanyListSelect;

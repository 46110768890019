import styled from 'styled-components';

export default styled.div`
  .section-title {
    font-size: 20px;
    font-weight: bold;
  }

  .controller-header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .title {
      font-size: 18px;
      font-weight: 600;
    }

    .divider {
      flex-grow: 2;
      height: 1px;
      background: ${({ theme }) => theme.border.default};
      margin-left: 10px;
      margin-right: 10px;
    }
  }

  .checkbox-row {
    .ant-checkbox-group {
      width: 100%;

      .ant-col {
        margin-top: 10px;
        margin-bottom: 10px;

        .anticon {
          color: ${({ theme }) => theme.color.gray};
          margin-left: 5px;
        }
      }
    }
  }
`;

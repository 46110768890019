import { Form } from 'antd';
import RestEditor from 'components/RestInput/RestEditor';
import { RestInputContext } from 'components/RestInput/RestInputContext';
import { MoreAboutJobFormStyle } from 'containers/JobTemplates/styles';
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { setJobTemplatePayload } from 'redux/jobs/jobTemplates/slice';
import useWindowAutoScroll from 'hooks/useWindowAutoScroll';
import { isEmpty } from 'lodash-es';
import JobHighlightBenefit from './JobHighlightBenefit';

const MoreAboutJobForm = ({ form, initTemplate }) => {
  useWindowAutoScroll();
  const dispatch = useDispatch();

  const onFinish = (values) => {
    dispatch(setJobTemplatePayload(values));
  };

  useEffect(() => {
    form.resetFields();
    if (!isEmpty(initTemplate)) {
      form.setFieldsValue(initTemplate);
    }
  }, [form, initTemplate]);

  return (
    <MoreAboutJobFormStyle>
      <Form
        form={form}
        layout="vertical"
        scrollToFirstError
        onFinish={onFinish}
      >
        <RestInputContext.Provider value={{ form }}>
          <JobHighlightBenefit />
          <div className="mt-32">
            <RestEditor
              header="jobs.moreAboutTheJob.aboutTheJob"
              source="aboutTheJob"
              required
            />
          </div>
          <div className="mt-32">
            <RestEditor
              header="jobs.moreAboutTheJob.whatYouWillBeDoing"
              source="candidateResponsibilities"
              required
            />
          </div>
          <div className="mt-32">
            <RestEditor
              header="jobs.moreAboutTheJob.aboutYou"
              source="aboutCandidate"
            />
          </div>
        </RestInputContext.Provider>
      </Form>
    </MoreAboutJobFormStyle>
  );
};

MoreAboutJobForm.propTypes = {
  form: PropTypes.object,
  initTemplate: PropTypes.object,
};

export default MoreAboutJobForm;

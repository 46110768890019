import { Form } from 'antd';
import { RightOutlineIcon } from 'components/common/SVGIcon';
import InfiniteSelect from 'components/InfiniteFormItem/InfiniteSelect';
import EmptyPrograms from 'containers/Centres/components/CentreTabs/ServicesTab/Modals/EmptyPrograms';
import { ProgramSelectStyle } from 'containers/Centres/components/CentreTabs/ServicesTab/styles';
import ProgramDetails from 'containers/ProgramsAndLearning/components/ProgramDetails';
import { ProgramDetailsRef } from 'containers/ProgramsAndLearning/components/ProgramsLearningBoard';
import CreateProgramModal from 'containers/ProgramsAndLearning/CreateProgramModal';
import { useProgramsControllerGetProgramListQuery } from 'generated/apis';
import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  isProgramEdit: boolean;
  isProgramCreate: boolean;
}

const ProgramSelect = ({ isProgramEdit, isProgramCreate }: Props) => {
  const { t } = useTranslation();
  const [isCreate, setIsCreate] = useState(false);
  const programDetailsRef = useRef<ProgramDetailsRef>();

  const onProgramClick = (id: string) => {
    programDetailsRef.current.open(id);
  };

  return (
    <Form.Item shouldUpdate className="m-0">
      {({ getFieldValue, setFieldsValue }) => {
        const serviceTypeId = getFieldValue('serviceTypeId');
        const ageGroupId = getFieldValue('serviceAgeGroupId');
        const sessionTypeId = getFieldValue('sessionTypeId');
        return (
          <ProgramSelectStyle className="flex-col flex-center gap-12 w-full mb-20">
            <InfiniteSelect
              useQuery={useProgramsControllerGetProgramListQuery}
              name="programId"
              showSearch
              filterOption={false}
              searchKey="programName"
              labelProp="name"
              valueProp="id"
              placeholder={t('centres.programName')}
              formItemProps={{
                label: t('centres.programName'),
              }}
              queryParams={{
                serviceTypeId,
                ageGroupId,
                sessionTypeId,
              }}
              dropdownClassName="program-name-service-form"
              emptyComponent={
                <EmptyPrograms
                  onCreateProgram={() => setIsCreate(true)}
                  isCreate={isProgramCreate}
                />
              }
              className="w-full"
              skipUntilFocus={false}
              key={`${serviceTypeId}-${ageGroupId}-${sessionTypeId}`}
            />
            {getFieldValue('programId') && (
              <div
                className="w-full text-16 fw-bold text-primary cursor-pointer"
                onClick={() => onProgramClick(getFieldValue('programId'))}
                role="presentation"
              >
                {t('programLearning.viewProgramDetails')}
                <RightOutlineIcon className="ml-8 text-16 text-primary" />
              </div>
            )}
            <CreateProgramModal
              visible={isCreate}
              onClose={() => setIsCreate(false)}
            />
            <ProgramDetails
              isEdit={isProgramEdit}
              ref={programDetailsRef}
              handleAfterDelete={() => setFieldsValue({ programId: undefined })}
            />
          </ProgramSelectStyle>
        );
      }}
    </Form.Item>
  );
};

export default ProgramSelect;

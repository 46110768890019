import { PREFIX_API_VER_2 } from 'configs/localData/constant';
import qs from 'qs';
import { get, put, post, del } from './utils';

export const getAllJobListingApi = (data) =>
  get(`/crm/job-seekers/posts`, data, PREFIX_API_VER_2);

export const getSummaryJobListingApi = (data) =>
  get(`/crm/job-seekers/posts/summary`, data, PREFIX_API_VER_2);

export const deleteJobListingApi = (params) =>
  del(`/crm/job-seekers/posts?${qs.stringify(params)}`, null, PREFIX_API_VER_2);

export const moveJobListingToCampaignApi = (id, campaignId) =>
  put(
    `/crm/job-seekers/posts/bulk`,
    { campaignId, postIds: id },
    PREFIX_API_VER_2,
  );
export const getJobListingsDetailApi = (id) =>
  get(`/crm/job-seekers/posts/details?postId=${id}`, null, PREFIX_API_VER_2);

export const createCampaignApi = (data) =>
  post('/crm/job-seekers/campaigns', data, PREFIX_API_VER_2);

export const getCampaignsApi = (data) =>
  get('/crm/job-seekers/campaigns', data, PREFIX_API_VER_2);

export const getWorkplacesApi = (data) =>
  get('/crm/job-seekers/posts/workplaces', data, PREFIX_API_VER_2);

export const editCampaignApi = (id, data) =>
  put(`/crm/job-seekers/campaigns?campaignId=${id}`, data, PREFIX_API_VER_2);

export const checkJobReferencesExistentApi = (data) =>
  get('/crm/job-seekers/posts/job-references-existent', data, PREFIX_API_VER_2);

export const createJobListingApi = (data) =>
  post('/crm/job-seekers/posts', data, PREFIX_API_VER_2);

export const editJobListingApi = (id, data) =>
  put(`/crm/job-seekers/posts?postId=${id}`, data, PREFIX_API_VER_2);

export const closeJobListingApi = (id) =>
  del(`/crm/job-seekers/posts/cancel?id=${id}`, null, PREFIX_API_VER_2);

import { useCallback, useEffect, useState } from 'react';
import { CurrentLocation } from '../types';
import { LOCATION_DEFAULT } from '../configs/localData/constant';

function useGetCurrentLocation() {
  const [currentLocation, setCurrentLocation] = useState<CurrentLocation>(
    LOCATION_DEFAULT.geoLocation,
  );

  const getCurrentLocation = useCallback(() => {
    navigator.geolocation.getCurrentPosition((position) => {
      const { latitude } = position.coords;
      const { longitude } = position.coords;
      if (latitude || longitude) {
        setCurrentLocation({
          latitude,
          longitude,
        });
      }
    });
  }, []);

  useEffect(() => {
    getCurrentLocation();
  }, [getCurrentLocation]);

  return currentLocation;
}

export default useGetCurrentLocation;

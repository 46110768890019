import React, { useRef } from 'react';
import { Divider } from 'antd';
import EditCustomBtn from 'components/RestActions/EditCustomBtn';
import GeneralInfo from './GeneralInfo';
import ContactInfo from './ContactInfo';
import CentreNameInfo from './CentreNameInfo';
import CentreInformationModal from '../Modals/CentreDetailInfoModal';
import CentreInfoStyles from './styles';
import CentreImageInfo from './CentreImageInfo';
import ListingStrength from './ListingStrength';

interface Props {
  record: any;
}

const CentreInfo = ({ record }: Props) => {
  const modalRef = useRef<any>();

  const onClickButton = () => {
    modalRef.current && modalRef.current.toggleModal();
  };

  return (
    <CentreInfoStyles>
      <EditCustomBtn
        packageName={record?.CRMPackage?.name}
        permissionKey="myCentres"
        action="isEdit"
        onClickButton={onClickButton}
        isSkipSubscription
      />
      <CentreImageInfo images={record?.images} />
      <div className="content-info-container">
        <CentreNameInfo name={record?.name} isActive={record?.isActive} />
        <ListingStrength percent={record?.totalListingStrength} />
        <GeneralInfo record={record} />
        <Divider />
        <ContactInfo
          phone={record?.phone}
          mobilePhone={record?.mobilePhone}
          adminEmail={record?.adminEmail}
          website={record?.website}
        />
      </div>
      <CentreInformationModal ref={modalRef} record={record} />
    </CentreInfoStyles>
  );
};

export default CentreInfo;

import { get } from './utils';

export const getPriceBenchmarkingApi = (data) =>
  get(`/admin/insights/price-benchmarking`, data);

export const getPriceBenchmarkingSummaryApi = (data) =>
  get(`/admin/insights/price-benchmarking/summary`, data);

export const getUserCompanyBenchmarkingChartApi = (data) =>
  get(`/admin/insights/company-benchmarking/user-company-charts`, data);

export const getComapnyBenchmarkingSummaryApi = (data) =>
  get(`/admin/insights/company-benchmarking/summary`, data);

export const getCompareRatingApi = (data) =>
  get(`/admin/insights/company-benchmarking/comparison-chart`, data);

export const getBrandBenchmarkingCompareRatingApi = (data) =>
  get(`/admin/insights/brand-benchmarking/comparison-chart`, data);

export const getUserBrandBenchmarkingChartApi = (data) =>
  get(`/admin/insights/brand-benchmarking/user-brand-charts`, data);

export const getBrandBenchmarkingSummaryApi = (data) =>
  get(`/admin/insights/brand-benchmarking/summary`, data);

import React, { useEffect, useCallback } from 'react';
import { useParams, useHistory } from 'react-router';
import { Result, Button, Space } from 'antd';
import i18next from 'i18next';
import { useSelector } from 'react-redux';
import { getCentreId } from 'redux/auth/selectors';
import { callbackFeaturedMarketingApi } from 'api/marketings';
import { ResultSignUpStyles } from './styles';

const ResultFeaturedMarketing = () => {
  const { marketingId, id } = useParams();
  const query = useSelector((state) => state.router?.location?.query);
  const centreId = useSelector(getCentreId);

  const { push } = useHistory();

  useEffect(() => {
    if (id && marketingId && query['session-id']) {
      callbackFeaturedMarketingApi(id, marketingId, {
        stripeCheckoutSessionId: query['session-id'],
      });
    }
  }, [marketingId, id, query]);

  const redirectCentreDetail = useCallback(() => {
    (centreId || id) && push(`/centres/${centreId || id}/show/marketing`);
  }, [centreId, id, push]);

  return (
    <ResultSignUpStyles>
      <Result
        status="success"
        title={i18next.t('marketing.signUpSuccess')}
        subTitle={i18next.t('marketing.signUpSuccessDescription')}
        extra={
          <Space direction="vertical" size={15}>
            <Button onClick={redirectCentreDetail} type="primary">
              {i18next.t('button.updateYourCentre')}
            </Button>
          </Space>
        }
      />
    </ResultSignUpStyles>
  );
};

export default ResultFeaturedMarketing;

import React from 'react';
import PropTypes from 'prop-types';
import { Switch, Popconfirm } from 'antd';
import i18next from 'i18next';

import CheckPermission from 'components/common/CheckPermissions';
import SwitchActionStyles from './styles';
import SwitchFallback from './SwitchFallback';

const SwitchAction = ({
  checked,
  onChange,
  permissionKey,
  action,
  isShowConfirm,
  isSkipSubscription,
  loading,
  switchProps,
  packageName,
}) => {
  const element = (
    <SwitchActionStyles>
      <CheckPermission
        packageName={packageName}
        permissionKey={permissionKey}
        action={action}
        isSkipSubscription={isSkipSubscription}
        isDisabled
        fallback={
          <SwitchFallback checked={checked} switchProps={switchProps} />
        }
      >
        <Switch
          loading={loading}
          checked={checked || false}
          {...switchProps}
          {...(!isShowConfirm && { onChange })}
        />
      </CheckPermission>
    </SwitchActionStyles>
  );

  const onConfirm = () => {
    onChange(!checked);
  };

  return isShowConfirm ? (
    <Popconfirm
      title={
        checked
          ? i18next.t('users.confirmLockUser')
          : i18next.t('users.confirmUnLockUser')
      }
      onConfirm={onConfirm}
      okText={i18next.t('button.ok')}
      cancelText={i18next.t('button.cancel')}
    >
      {element}
    </Popconfirm>
  ) : (
    element
  );
};

SwitchAction.propTypes = {
  record: PropTypes.object,
  checked: PropTypes.bool,
  onChange: PropTypes.func,
  permissionKey: PropTypes.string,
  action: PropTypes.string,
  isShowConfirm: PropTypes.bool,
  isSkipSubscription: PropTypes.bool,
  loading: PropTypes.bool,
  switchProps: PropTypes.object,
  packageName: PropTypes.string,
};

export default SwitchAction;

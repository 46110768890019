import styled from 'styled-components';

export const TypesRatingStyles = styled.div`
  display: flex;
  flex-direction: column;
  .rating-type-item {
    &__image {
      background: #ffffff;
      border: 1px solid #ebecf0;
      box-sizing: border-box;
      border-radius: 8px;
      height: 32px;
      width: 56px;
      object-fit: cover;
    }
  }
`;

import React from 'react';
import PropTypes from 'prop-types';
import i18next from 'i18next';
import {
  MailIcon,
  PhoneIcon,
  PreferredContactIcon,
} from 'components/common/SVGIcon';
import { EnvironmentOutlined } from '@ant-design/icons';
import { getDisplayAddress } from 'utils/dataUtils';
import { Tooltip } from 'antd';

const ParentInfo = ({ title, item }) => (
  <div className="parent-info">
    <div className="parent-info__header">
      <div className="parent-info__title">{i18next.t(title)}</div>
      <div className="parent-info__name">
        {`${item?.firstName} ${item?.lastName}`}
      </div>
    </div>

    <div className="parent-info__content">
      <div className="parent-info__item">
        <PhoneIcon />
        <span className="value-info-item">
          {item?.phoneNumber || i18next.t('error.waitingUpdate')}
        </span>
      </div>

      <div className="parent-info__item">
        <MailIcon />
        <span className="value-info-item">
          {item?.email || i18next.t('error.waitingUpdate')}
        </span>
      </div>

      <div className="parent-info__item">
        <EnvironmentOutlined />
        <span className="value-info-item">
          {getDisplayAddress(item) || i18next.t('error.waitingUpdate')}
        </span>
      </div>
      {item?.preferredContactMethod && (
        <div className="parent-info__item">
          <PreferredContactIcon />
          <Tooltip title={i18next.t('enquiries.preferredContactMethod')}>
            <span className="value-info-item">
              {item?.preferredContactMethod || i18next.t('error.waitingUpdate')}
            </span>
          </Tooltip>
        </div>
      )}
    </div>
  </div>
);

ParentInfo.propTypes = {
  item: PropTypes.object,
  title: PropTypes.string,
};

export default ParentInfo;

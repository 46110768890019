import styled from 'styled-components';

export const GroupButtonStyle = styled.div`
  margin-left: auto;
  margin-right: auto;
  max-width: 500px;
  display: flex;
  justify-content: center;

  button {
    width: 100%;
  }
  .anticon {
    font-size: 16px;
  }
  .btn-book-demo {
    font-size: 18px !important;
    color: #32a4fc;
    border: 1px solid #32a4fc;
    border-radius: 12px;
  }
  .btn-learn-more {
    font-size: 18px !important;
    color: #db147f;
    border: 1px solid #db147f;
    border-radius: 12px;
  }
`;

import ModalCustom from 'components/common/ModalCustom';
import styled from 'styled-components';

export const CentreClaimFormWrapper = styled.div`
  .verify-div {
    display: flex;
    margin-top: 20px;
    .verify-message {
      margin-left: 10px;
    }
  }
  .use-new-email {
    color: ${({ theme }) => theme.palette.primary} !important;
    margin-top: 20px;
    margin-bottom: 20px;
    font-size: 16px;
    line-height: 24px;
    .ant-checkbox-disabled + span {
      color: ${({ theme }) => theme.palette.primary} !important;
    }
    .ant-checkbox-disabled .ant-checkbox-inner {
      background-color: ${({ theme }) => theme.palette.primary} !important;
      border-color: ${({ theme }) => theme.palette.primary} !important;
    }
    .ant-checkbox-disabled.ant-checkbox-checked .ant-checkbox-inner::after {
      border-color: #fff !important;
    }
  }
  .verify-message,
  .ant-checkbox-wrapper {
    font-size: 16px;
    line-height: 1.5;
    color: #141533;

    @media only screen and (min-width: 2000px) {
      font-size: 20px;
    }

    @media only screen and (min-width: 2400px) {
      font-size: 25px;
    }

    @media only screen and (min-width: 3000px) {
      font-size: 30px;
    }

    @media only screen and (min-width: 3400px) {
      font-size: calc(16px + 0.390625vw);
      line-height: 1.7;
    }
  }
  .new-admin-email {
    margin-left: 20px;
  }
  .ant-modal-footer {
    text-align: left !important;
  }
  .captcha-section {
    display: flex;
    justify-content: center;
    margin-top: 30px;
  }

  .ant-result {
    padding: 0 !important;
    .ant-result-title {
      font-size: 20px;
    }
  }

  &.modal-footer-center .ant-modal-footer {
    text-align: center !important;
  }

  --font-size: 16px;

  .ant-form-item-label label {
    font-weight: 700;
    font-size: var(--font-size);
    line-height: 1.5;
    color: #141533;
  }

  @media (max-width: 768px) {
    input,
    textarea {
      font-size: 16px;
    }
  }

  @media (min-width: 2000px) {
    --font-size: 20px;

    input,
    textarea,
    .ant-select-selection-placeholder,
    .ant-select-item,
    .ant-select-selection-item,
    .ant-form-item-explain-error,
    .ant-form-item-required::after {
      font-size: var(--font-size) !important;
    }
  }

  @media (min-width: 2600px) {
    --font-size: 28px;
    .ant-select-selector {
      height: 62px !important;
    }

    .ant-select-selection-item {
      line-height: 55px !important;
    }

    .ant-select-item {
      padding: 18px 24px !important;
    }

    .ant-checkbox-wrapper {
      margin-top: 24px;
      margin-bottom: 24px;
    }
    .ant-radio-inner {
      width: 40px;
      height: 40px;

      &::after {
        width: 20px;
        height: 20px;
      }
    }

    .ant-checkbox-inner {
      width: 40px;
      height: 40px;

      &::after {
        width: 12px;
        height: 20px;
      }
    }
    .ant-radio,
    .ant-checkbox {
      font-size: 40px;
    }

    .ant-checkbox {
      margin-right: 18px;
    }

    .ant-row.ant-form-item {
      margin-bottom: 50px;
    }

    .ant-form-item-label {
      padding-bottom: 16px;
    }
  }

  @media only screen and (min-width: 3400px) {
    --font-size: calc(16px + 0.390625vw);

    input,
    textarea,
    .ant-select-selection-placeholder,
    .ant-select-item,
    .ant-select-selection-item,
    .ant-form-item-explain-error {
      padding-left: 28px;
    }

    .ant-select-selector {
      padding-left: 25px !important;
    }

    .ant-select-single .ant-select-selector .ant-select-selection-placeholder {
      line-height: 51px;
    }
  }
`;

const CentreModalStyles = styled(ModalCustom)`
  .verify-div {
    display: flex;
    margin-top: 20px;
    .verify-message {
      margin-left: 10px;
    }
  }
  .use-new-email {
    color: ${({ theme }) => theme.palette.primary};
    margin-top: 5px;
    margin-bottom: 5px;
  }
  .new-admin-email {
    margin-left: 20px;
  }
  .ant-modal-footer {
    text-align: left !important;
  }
  .captcha-section {
    display: flex;
    justify-content: center;
    margin-top: 30px;
  }

  .ant-result {
    padding: 0 !important;
    .ant-result-title {
      font-size: 20px;
    }
  }

  &.modal-footer-center .ant-modal-footer {
    text-align: center !important;
  }
`;

export default CentreModalStyles;

import React, { useCallback } from 'react';
import { Select } from 'antd';
import i18next from 'i18next';
import { useSelector, useDispatch } from 'react-redux';
import { setCurrentCentreConfig } from 'redux/auth/slice';
import { onSearch } from 'utils/tools';
import { getCentreId, getCentresObjKeyById } from 'redux/auth/selectors';
import { CentreDropdownStyles } from './styles';

const { Option } = Select;

const CentreDropdown = () => {
  const dispatch = useDispatch();

  const centres = useSelector((state) => state.auth.data?.centres);
  const isShowAllCentres = useSelector((state) => state.auth.isShowAllCentres);
  const centresObjById = useSelector(getCentresObjKeyById);
  const centreId = useSelector(getCentreId);

  const onChange = (value) => {
    dispatch(
      setCurrentCentreConfig({
        centreId: value,
        centreLevelCRMPackageName:
          centresObjById?.[value]?.centreSubscription?.CRMPackage?.name,
        centreLevelCRMPackageSelected:
          centresObjById?.[value]?.centreSubscription?.CRMPackage,
      }),
    );
  };

  const onFilterOption = useCallback(
    (inputValue, option) => onSearch(option.children, inputValue),
    [],
  );

  return (
    <CentreDropdownStyles>
      <Select
        value={centreId || ''}
        style={{ width: 350, maxWidth: '100%' }}
        onChange={onChange}
        placeholder={i18next.t('centres.allCentres')}
        allowClear={false}
        showSearch
        filterOption={onFilterOption}
      >
        {isShowAllCentres ? (
          <Option value="">{i18next.t('centres.allCentres')}</Option>
        ) : null}
        {centres?.map((item) => (
          <Option key={item.id} value={item.id}>
            {item.name}
          </Option>
        ))}
      </Select>
    </CentreDropdownStyles>
  );
};

export default CentreDropdown;

import styled, { css } from 'styled-components';

interface ClaimCentreContainerProps {
  isDetailPage?: boolean;
}

export const ClaimCentreContainer = styled.div<ClaimCentreContainerProps>`
  --sidebar-width: 33%;

  .steps-sider {
    background-image: url('https://s3.ap-southeast-2.amazonaws.com/files.kindicare.com/uploads/static/assets/images/claim-centres/claim-background.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    overflow: auto;
    border-top-right-radius: 28px;
    border-bottom-right-radius: 28px;
    padding-left: 0 !important;
    padding-right: 0 !important;

    ${({ isDetailPage }) =>
      isDetailPage &&
      css`
        background-color: #fff;
        background-image: unset;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        padding-left: 48px !important;
        padding-right: 48px !important;
        border-right: 1px solid #ebecf0;
      `}

    flex: 0 0 var(--sidebar-width) !important;
    max-width: var(--sidebar-width) !important;
    min-width: var(--sidebar-width) !important;
    width: var(--sidebar-width) !important;
  }

  .ant-layout-footer {
    padding-left: 64px;
    padding-right: 64px;
  }

  .ant-layout {
    background-color: #fff;
  }

  .step-create-wrapper {
    --sider-width: var(--sidebar-width);
    @media only screen and (max-width: 992px) {
      --sider-width: 0 !important;
    }
    @media only screen and (min-width: 2000px) {
      --header-height: 120px;
    }

    @media only screen and (min-width: 3000px) {
      --header-height: 150px;
    }
  }

  .ant-layout-content {
    padding-bottom: 0 !important;
    @media only screen and (max-width: 992px) {
      padding: 20px !important;
    }
  }

  @media (max-width: 585px) {
    .ant-layout-header {
      padding-left: 12px;
      padding-right: 12px;
    }
  }

  @media (max-width: 992px) {
    .steps-sider {
      padding-left: 0 !important;
      padding-right: 0 !important;
      min-width: 0 !important;
      width: 0 !important;
      flex-basis: 0 !important;
      border: none !important;
    }

    .ant-layout-footer {
      padding-left: 20px;
      padding-right: 20px;
    }

    .ant-layout-header {
      border-right: none !important;
    }

    .ant-layout-content {
      border: none !important;
    }
  }

  @media only screen and (min-width: 1921px) {
    .ant-layout-content {
      margin: 0 10%;
      max-width: 60%;
    }

    .ant-layout-footer {
      padding: 32px 10%;
    }
  }

  @media only screen and (min-width: 2400px) {
    .ant-layout-content {
      max-width: 50%;
    }
  }
`;

export const ClaimContentStyle = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
  padding-bottom: 0;
  max-height: 200px;

  @media only screen and (max-width: 992px) {
    align-items: flex-start;
  }
  .header {
    padding: 20px;
    width: 100%;
    @media only screen and (max-width: 992px) {
      padding: 12px 0;
    }
  }
  .body {
    .circle {
      position: absolute;
      bottom: 0;
      @media only screen and (max-width: 992px) {
        top: 0;
      }
    }
  }
`;

export const CircleStyle = styled.div`
  --circle-size: 391px;
  --circle-padding-top: 80px;

  padding-top: var(--circle-padding-top);
  .anticon svg {
    width: var(--circle-size);
    height: var(--circle-size);
  }

  @media only screen and (max-width: 576px) {
    margin: auto;
    --circle-padding-top: 12px;
    --circle-size: 233px;
  }

  @media only screen and (min-width: 2000px) {
    --circle-padding-top: 85px;
    --circle-size: 420px;
  }
  @media only screen and (min-width: 2400px) {
    --circle-padding-top: 95px;
    --circle-size: 450px;
  }
  @media only screen and (min-width: 3000px) {
    --circle-padding-top: 100px;
    --circle-size: 500px;
  }
  @media only screen and (min-width: 3400px) {
    --circle-padding-top: 105px;
    --circle-size: 550px;
  }
  @media only screen and (min-width: 3800px) {
    --circle-padding-top: 110px;
    --circle-size: 600px;
  }
`;

export const MiddleStyle = styled.div`
  top: 50px;
  @media only screen and (max-width: 992px) {
    top: -20px;
  }
`;

export const ContentStyle = styled.div`
  --review-text-font-size: 20px;

  .review-text {
    font-size: var(--review-text-font-size);
  }
  @media only screen and (max-width: 576px) {
    --review-text-font-size: 14px !important;
    left: 0 !important;
    bottom: unset !important;
    top: -180px;
  }
  @media only screen and (max-width: 992px) {
    bottom: 90px !important;
  }
  @media only screen and (min-width: 2000px) {
    --review-text-font-size: 25px;
    top: -400px;
  }
  @media only screen and (min-width: 2400px) {
    --review-text-font-size: 30px;
    top: -450px;
  }
  @media only screen and (min-width: 3000px) {
    --review-text-font-size: 35px;
    top: -500px;
  }
  @media only screen and (min-width: 3400px) {
    --review-text-font-size: 40px;
    top: -550px;
  }
  @media only screen and (min-width: 3800px) {
    --review-text-font-size: 45px;
    top: -600px;
  }
`;

export const QuoteStyle = styled.div`
  --quote-top: -150px;
  --quote-user-avatar: 64px;
  --quote-logo: calc(var(--quote-user-avatar) / 2);
  --quote-title: 16px;
  --quote-icon: 32px;
  top: var(--quote-top);
  right: 0;

  .quote-avatar {
    width: calc(var(--quote-user-avatar) + (var(--quote-logo) / 2));
    min-width: calc(var(--quote-user-avatar) + (var(--quote-logo) / 2));
    height: var(--quote-user-avatar) !important;
    max-height: var(--quote-user-avatar) !important;
    .quote-user-avatar {
      width: var(--quote-user-avatar);
      height: var(--quote-user-avatar);
    }
    .quote-logo {
      height: var(--quote-logo);
      width: var(--quote-logo);
    }
  }
  .content {
    .title {
      font-size: var(--quote-title);
    }
    .position {
      font-size: var(--quote-title);
    }
    padding-right: 32px;
    @media only screen and (max-width: 575px) {
      padding-right: 0;
    }
  }

  .quote-icon {
    font-size: var(--quote-icon);
    position: absolute !important;
    right: 0;
    top: 0;
    @media only screen and (max-width: 575px) {
      top: 86px;
      right: 0;
    }
  }

  @media only screen and (max-width: 575px) {
    --quote-top: -50px !important;
    --quote-title: 14px;
  }

  @media only screen and (max-width: 992px) {
    --quote-top: -100px;
  }

  @media only screen and (min-width: 2000px) {
    --quote-user-avatar: 70px;
    --quote-title: 20px;
  }

  @media only screen and (min-width: 2400px) {
    --quote-top: -160px;
    --quote-user-avatar: 75px;
    --quote-title: 25px;
  }
  @media only screen and (min-width: 3000px) {
    --quote-top: -180px;
    --quote-user-avatar: 90px;
    --quote-title: 30px;
    --quote-icon: 30px;
  }
  @media only screen and (min-width: 3400px) {
    --quote-top: -200px;
    --quote-user-avatar: 105px;
    --quote-title: 35px;
    --quote-icon: 45px;
  }
  @media only screen and (min-width: 3800px) {
    --quote-user-avatar: 115px;
  }
`;

export const CarouselStyle = styled.div`
  --brand-size: 72px;
  --brand-padding: 8px;

  .ant-btn-circle.ant-btn-sm {
    background-color: #7ac4fd;
    position: absolute;
    z-index: 2;
    top: 35px;
    scale: 0.6;
    color: white;
    &:hover,
    &:focus,
    &:active {
      border: none;
      color: white;
    }
  }
  .arrow-left {
    left: 0;
  }
  .arrow-right {
    right: 0;
  }
  .brand-logo {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    width: var(--brand-size);
    height: var(--brand-size);
    padding: var(--brand-padding);
    box-shadow: 0px 0px 1px rgba(12, 26, 75, 0.1),
      0px 10px 16px rgba(20, 37, 63, 0.06);

    img {
      width: 100%;
      height: 100%;
      border-radius: 9999px;
      aspect-ratio: 1/1;
    }
  }

  @media only screen and (max-width: 576px) {
    --brand-size: 48px;
    .ant-btn-circle.ant-btn-sm {
      top: 20px;
    }
  }

  @media only screen and (min-width: 1921px) {
    --brand-size: 80px;
    --brand-padding: 12px;

    .ant-btn-icon-only.ant-btn-sm {
      width: 45px !important;
      height: 45px !important;
      top: 25px;
      .anticon {
        font-size: 28px;
      }
    }
  }

  @media only screen and (min-width: 2559px) {
    --brand-size: 90px;
    .ant-btn-icon-only.ant-btn-sm {
      width: 50px !important;
      height: 50px !important;
      top: 35px;

      .anticon {
        font-size: 24px;
      }
    }
  }
  @media only screen and (min-width: 3000px) {
    --brand-size: 105px;
    .ant-btn-icon-only.ant-btn-sm {
      width: 60px !important;
      height: 60px !important;
      top: 35px;
      .anticon {
        font-size: 28px;
      }
    }
    .arrow-left {
      left: -10px;
    }
    .arrow-right {
      right: -10px;
    }
  }
  @media only screen and (min-width: 3400px) {
    --brand-size: 130px;
    .ant-btn-icon-only.ant-btn-sm {
      width: 80px !important;
      height: 80px !important;
      top: 40px !important;
      .anticon {
        font-size: 36px;
      }
    }
    .arrow-left {
      left: -20px;
    }
    .arrow-right {
      right: -20px;
    }
  }
  @media only screen and (min-width: 3800px) {
    --brand-size: 155px;
    .ant-btn-icon-only.ant-btn-sm {
      width: 90px !important;
      height: 90px !important;
      top: 50px !important;
      .anticon {
        font-size: 36px;
      }
    }
  }
`;

import React, { useState } from 'react';
import i18next from 'i18next';
import TableScrollCustom from 'components/common/TableScrollCustom';
import { useSelector } from 'react-redux';
import {
  companyBenchmarkingSelectors,
  getDataCompanyBenchmarking,
  getAverageDataSelector,
  getResourceDataCompanyBenchmarkingSelector,
} from 'redux/companyBenchmarking/selectors';
import PropTypes from 'prop-types';
import useTableInfinity from 'hooks/useTableInfinity';
import LoadingMore from 'components/LoadingMore/LoadingMore';
import {
  convertSearchUrl,
  getFilterFromUrl,
  getSortOrderColumn,
} from 'utils/tools';
import { useHistory, useLocation } from 'react-router';
import {
  DEFAULT_ORDER_BY,
  MAX_LIMIT,
} from 'containers/CompanyBenchmarking/localData/constants';
import { TableStyle } from './styles';
import useFormatFnc from './useFormat';
import AverageAllCompanies from './AverageAllCompanies';

const CompanyBenchmarkingTable = ({ retrieveList }) => {
  const [isSorting, setIsSorting] = useState(false);
  const useFormat = useFormatFnc();
  const { push } = useHistory();
  const { search } = useLocation();
  const { filter, orderBy } = getFilterFromUrl(search);
  const loading = useSelector(companyBenchmarkingSelectors.getLoading);
  const data = useSelector(getDataCompanyBenchmarking);
  const resourceFilter =
    useSelector(getResourceDataCompanyBenchmarkingSelector) || {};
  const averageAllCompany = useSelector(getAverageDataSelector);

  const { isFetchingMore } = useTableInfinity(
    data,
    loading,
    resourceFilter,
    retrieveList,
    { defaultOrderBy: 'name' },
  );

  const columns = [
    {
      title: i18next.t('companyBenchmarking.ranking'),
      dataIndex: 'ranking',
      width: 100,
      fixed: 'left',
      render: (_, record) => useFormat.formatRanking(record, orderBy),
    },
    {
      title: i18next.t('companyBenchmarking.companyName'),
      dataIndex: 'name',
      render: (_, record) => useFormat.formatName(record),
      width: 300,
      fixed: 'left',
    },
    {
      title: i18next.t('companyBenchmarking.averageServiceApprovalAge'),
      dataIndex: 'averageServiceApprovalAge',
      render: (_, record) =>
        useFormat.formatColumnData(record?.averageServiceApprovalAge),
      width: 280,
    },
    {
      title: i18next.t('companyBenchmarking.centres'),
      dataIndex: 'totalCentres',
      render: (_, record) =>
        useFormat.formatValueColumData(record?.totalCentres),
      width: 150,
    },
    {
      title: i18next.t('companyBenchmarking.approvedPlaces'),
      dataIndex: 'approvedPlaces',
      render: (_, record) =>
        useFormat.formatValueColumData(record?.approvedPlaces),
      width: 180,
    },
    {
      title: i18next.t('companyBenchmarking.averageApprovedPlaces'),
      dataIndex: 'averageApprovedPlaces',
      render: (_, record) =>
        useFormat.formatValueColumData(record?.averageApprovedPlaces),
      width: 250,
    },
    {
      title: i18next.t('companyBenchmarking.%marketShare'),
      dataIndex: 'marketShare',
      render: (_, record) => useFormat.formatMarketShare(record?.marketShare),
      width: 250,
    },
    {
      title: i18next.t('companyBenchmarking.revenuePotential'),
      dataIndex: 'revenuePotential',
      render: (_, record) =>
        useFormat.formatRevenuePotential(record?.revenuePotential),
      width: 200,
    },
    {
      title: i18next.t('companyBenchmarking.averageKindiCareRating'),
      dataIndex: 'averageKindiCareRating',
      render: (_, record) =>
        useFormat.formatKindiRating(record?.averageKindiCareRating),
      width: 250,
    },
    {
      title: i18next.t('companyBenchmarking.averageReviewScore'),
      dataIndex: 'averageReviewScore',
      render: (_, record) =>
        useFormat.formatReviewScore(record?.averageReviewScore),
      width: 220,
    },
    {
      title: i18next.t('companyBenchmarking.averageNumberOfReviews'),
      dataIndex: 'averageNumberOfReviews',
      render: (_, record) =>
        useFormat.formatAverageNumberOfReviews(record?.averageNumberOfReviews),
      width: 280,
    },
    {
      title: i18next.t('companyBenchmarking.%provisional'),
      dataIndex: 'provisional',
      render: (_, record) =>
        useFormat.formatPercentColumnData(record?.provisional),
      width: 200,
    },
    {
      title: i18next.t('companyBenchmarking.%significantImprovementRequired'),
      dataIndex: 'significantImprovementRequired',
      render: (_, record) =>
        useFormat.formatPercentColumnData(
          record?.significantImprovementRequired,
        ),
      width: 320,
    },
    {
      title: i18next.t('companyBenchmarking.%workingTowardsNQS'),
      dataIndex: 'workingTowardsNQS',
      render: (_, record) =>
        useFormat.formatPercentColumnData(record?.workingTowardsNQS),
      width: 250,
    },
    {
      title: i18next.t('companyBenchmarking.%meetingNQS'),
      dataIndex: 'meetingNQS',
      render: (_, record) =>
        useFormat.formatPercentColumnData(record?.meetingNQS),
      width: 200,
    },
    {
      title: i18next.t('companyBenchmarking.%exceedingNQS'),
      dataIndex: 'exceedingNQS',
      render: (_, record) =>
        useFormat.formatPercentColumnData(record?.exceedingNQS),
      width: 200,
    },
    {
      title: i18next.t('companyBenchmarking.%excellent'),
      dataIndex: 'excellent',
      render: (_, record) =>
        useFormat.formatPercentColumnData(record?.excellent),
      width: 180,
    },
    {
      title: i18next.t('companyBenchmarking.averageAgeNQSRating'),
      dataIndex: 'averageAgeNQSRating',
      render: (_, record) =>
        useFormat.formatColumnData(record?.averageAgeNQSRating),
      width: 250,
    },
    {
      title: i18next.t('companyBenchmarking.averageListingsCompleteness'),
      dataIndex: 'averageListingsCompleteness',
      render: (data) => useFormat.formatMarketShare(data),
      width: 300,
    },
  ];

  const columnsHasSorter = columns?.map((column) => {
    const excludeColumnIndexes = ['ranking'];
    const sortOrder = getSortOrderColumn(orderBy, column?.dataIndex);

    if (excludeColumnIndexes.includes(column?.dataIndex)) {
      return column;
    }

    return {
      ...column,
      sorter: () => null,
      sortOrder,
      defaultSortOrder: sortOrder,
    };
  });

  const formatRowData = (id) => {
    const centreOfUserData = data?.find(
      (item) => item?.isOwnedCompany && item?.id === id,
    );
    if (resourceFilter?.companyId === id) {
      return 'selected-row fw-bold';
    }
    if (centreOfUserData) {
      return 'selected-row';
    }
    return null;
  };

  const onChange = (pagination, filters, sorter, { action }) => {
    if (action !== 'sort') {
      return;
    }

    const orderBy =
      sorter.field && sorter.order
        ? `${sorter.order === 'descend' ? '-' : ''}${sorter.field}`
        : undefined;

    const newSearchParams = {
      ...filter,
      limit: MAX_LIMIT,
      offset: 0,
    };

    setIsSorting(true);
    retrieveList({
      filter: {
        ...newSearchParams,
        orderBy: orderBy || DEFAULT_ORDER_BY,
      },
      isRefresh: false,
      isSort: true,
    }).finally(() => setIsSorting(false));

    push({
      search: `?${convertSearchUrl({ ...newSearchParams, orderBy })}`,
    });
  };

  return (
    <TableStyle className="mt-24">
      <TableScrollCustom
        isSorter
        columns={columnsHasSorter}
        dataSource={data}
        pagination={false}
        scroll={{ y: 500 }}
        scrollToFirstRowOnChange={false}
        onChange={onChange}
        loading={isSorting && loading}
        rowClassName={(record) => formatRowData(record?.id)}
        summary={() => AverageAllCompanies({ averageAllCompany })}
      />

      {isFetchingMore && <LoadingMore />}
    </TableStyle>
  );
};

CompanyBenchmarkingTable.propTypes = {
  retrieveList: PropTypes.func,
};

export default CompanyBenchmarkingTable;

import React, { useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Progress } from 'antd';
import i18next from 'i18next';
import { useHistory, useParams } from 'react-router';
import {
  CheckDoneIcon,
  EditListingStrengthIcon,
} from 'components/common/SVGIcon';
import { useDispatch } from 'react-redux';
import { getAllAutomatedResponses } from 'redux/automatedResponses/actions';
import CustomButton from 'components/RestActions/CustomButton';
import NextStepButton from './Buttons/NextStepButton/NextStepButton';
import PreviousStepButton from './Buttons/PreviousStepButton/PreviousStepButton';

const SKIP_CHECK_SUBSCRIPTION = 1;

interface Props {
  record: any;
  centreStep: any[];
}

const StepContent = ({ record, centreStep }: Props) => {
  const dispatch = useDispatch();

  const [currentIndex, setCurrentIndex] = useState(
    JSON.parse(localStorage.getItem('currentStep')) || 0,
  );

  localStorage.setItem('currentStep', currentIndex);

  const { push } = useHistory();

  const { id: centreId } = useParams();

  const onClickNext = () => {
    setCurrentIndex(currentIndex + 1);
  };

  const onClickPrev = () => {
    setCurrentIndex(currentIndex - 1);
  };
  const modalRef = useRef<any>();

  const { key, Component } = centreStep[currentIndex];

  const handleAction = useCallback(() => {
    if (key !== null) {
      if (key === 'automatedResponses') {
        push({
          pathname: `/enquiries/${key}`,
          state: {
            isUpdateStep11: true,
            centreId,
          },
        });
        dispatch(getAllAutomatedResponses({ id: centreId }));
      } else push(`/centres/${centreId}/show/${key}`);
    } else {
      modalRef.current && modalRef.current.toggleModal();
    }
  }, [push, centreId, key, dispatch]);

  const packageName = record?.CRMPackage?.name;

  const [disabled, setDisabled] = useState(false);

  useEffect(() => {
    if (record?.brand?.logo) {
      setDisabled(true);
    }
  }, [record?.brand?.logo]); // eslint-disable-line

  return (
    <div className="content-step">
      <div className="flex-center-between">
        <div className="flex items-center">
          <div>
            <Progress
              type="circle"
              percent={centreStep[currentIndex].percent}
              width={50}
              format={(data) =>
                data === 100 ? <CheckDoneIcon /> : <EditListingStrengthIcon />
              }
            />
            <p className="text-center mt-4">
              {`${centreStep[currentIndex].percent || 0}%`}
            </p>
          </div>

          <div className="ml-24" style={{ height: 120 }}>
            <div className="ml-12 content-step__title-info">
              {`${i18next.t(centreStep[currentIndex]?.title)}: ${i18next.t(
                centreStep[currentIndex]?.description,
              )}`}
            </div>
            <div className="show-hide-btn">
              <PreviousStepButton
                action="isEdit"
                currentIndex={Number(currentIndex)}
                isSkipSubscription={centreStep[currentIndex].step === 2}
                onClickPrev={onClickPrev}
                packageName={record?.CRMPackage?.name}
                permissionKey="myCentres"
              />
              <NextStepButton
                centreStep={centreStep}
                onClickNext={onClickNext}
                currentIndex={Number(currentIndex)}
                action="isEdit"
                isSkipSubscription={centreStep[currentIndex].step === 0}
                packageName={record?.CRMPackage?.name}
                permissionKey="myCentres"
              />
            </div>
          </div>
        </div>
        <div>
          <CustomButton
            isSkipSubscription={
              centreStep[currentIndex].step === SKIP_CHECK_SUBSCRIPTION
            }
            disabled={centreStep[currentIndex].step === 4 && disabled}
            packageName={packageName}
            buttonProps={{
              className: 'btn-second-primary content-step__btn-update',
            }}
            title={
              centreStep[currentIndex].step === 10
                ? i18next.t('button.checkItNow')
                : i18next.t('button.updateNow')
            }
            permissionKey={`${
              centreStep[currentIndex].step === 11
                ? 'automatedResponses'
                : 'centreListingStrength'
            }`}
            action="isEdit"
            handleClick={handleAction}
          />
        </div>
      </div>
      {!key && <Component ref={modalRef} record={record} />}
    </div>
  );
};

StepContent.propTypes = {
  record: PropTypes.object,
  centreStep: PropTypes.array,
};

export default StepContent;

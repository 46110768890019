import React, { useMemo } from 'react';
import { Row, Col } from 'antd';
import { useSelector } from 'react-redux';
import { usersSelectors } from 'redux/users/selectors';
import { ROLES_CONST } from 'configs/localData/permissions';
import UserDetailStyles from './styles';
import UserInfo from './UserInfo';
import CentresOfUserList from '../CentresOfUserList';
import ProvidersOfUserList from '../ProvidersOfUserList';
import CompaniesOfUserList from '../CompaniesOfUserList';

const UserDetail = () => {
  const currentData = useSelector(usersSelectors.getCurrentData);

  const contentList = useMemo(() => {
    switch (currentData.role?.name) {
      case ROLES_CONST.companyAdmin:
      case ROLES_CONST.companyStaff:
        return <CompaniesOfUserList />;

      case ROLES_CONST.providerAdmin:
      case ROLES_CONST.providerStaff:
        return <ProvidersOfUserList />;

      case ROLES_CONST.centreAdmin:
      case ROLES_CONST.centreStaff:
        return <CentresOfUserList />;

      default:
        return <div />;
    }
  }, [currentData.role]); // eslint-disable-line

  return (
    <UserDetailStyles>
      <Row gutter={[24, 24]}>
        <Col xxl={6} xl={6} lg={8} md={24} xs={24}>
          <UserInfo />
        </Col>

        <Col xxl={18} xl={18} lg={16} md={24} xs={24}>
          {contentList}
        </Col>
      </Row>
    </UserDetailStyles>
  );
};

export default UserDetail;

import React from 'react';
import { Space } from 'antd';
import { useHistory } from 'react-router';
import { PlusCircleOutlined, RightOutlined } from '@ant-design/icons';
import i18next from 'i18next';
import PageTitle from 'components/common/PageTitle';
import { CopyIcon } from 'components/common/SVGIcon';
import { resetCreateAdPayload } from 'redux/advertisement/slice';
import { useDispatch } from 'react-redux';
import { CreateAdOptionsWrapper, OptionButton } from './styles';

const CreateAdOptions = () => {
  const { push } = useHistory();
  const dispatch = useDispatch();

  const onCreateFromExistingAd = () => {
    dispatch(resetCreateAdPayload());
    push('/advertisement/create/ads-existing');
  };

  const onCreateNewAd = () => {
    dispatch(resetCreateAdPayload());
    push('/advertisement/create/steps');
  };

  return (
    <CreateAdOptionsWrapper>
      <PageTitle>{i18next.t('advertisement.startCreate')}</PageTitle>
      <Space direction="vertical" size={20} className="w-full">
        <OptionButton
          icon={<CopyIcon className="text-20 mr-10" />}
          onClick={onCreateFromExistingAd}
        >
          {i18next.t('advertisement.createFromExisting')}
          <RightOutlined className="arrow-icon" />
        </OptionButton>
        <OptionButton
          icon={<PlusCircleOutlined className="text-20 mr-10" />}
          onClick={onCreateNewAd}
        >
          {i18next.t('advertisement.createNew')}
          <RightOutlined className="arrow-icon" />
        </OptionButton>
      </Space>
    </CreateAdOptionsWrapper>
  );
};

export default CreateAdOptions;

import React from 'react';
import PropTypes from 'prop-types';
import { Image, Tooltip, Space } from 'antd';
import { TypesRatingStyles } from './styles';

const RatingTypesSection = ({ data }) => (
  <TypesRatingStyles className="mx-24">
    <Space size={24} direction="vertical">
      {data?.map((item, idx) => (
        <div key={String(idx)} className="w-full flex-center-between">
          <div className="flex-center">
            {item?.image && (
              <Image
                className="rating-type-item__image mr-10"
                src={item?.image}
                preview={false}
              />
            )}
            <div
              className="fw-bold line-clamp-1"
              style={{
                color: item?.color,
              }}
            >
              <Tooltip placement="topLeft" title={item?.name}>
                {item?.name}
              </Tooltip>
            </div>
          </div>
          <div className="fw-bold">{`${item?.percent?.toFixed(2)}%`}</div>
        </div>
      ))}
    </Space>
  </TypesRatingStyles>
);

RatingTypesSection.propTypes = {
  data: PropTypes.array,
};

export default RatingTypesSection;

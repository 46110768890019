import styled from 'styled-components';

export const DeleteButtonStyles = styled.div`
  .ant-btn {
    border: 0px !important;
    color: ${({ theme }) => theme.text.primary} !important;
    background: transparent !important;
    box-shadow: none !important;
    height: 32px !important;
    width: 32px !important;
    min-width: 32px !important;
    .anticon {
      font-size: 20px;
      color: ${({ theme }) => theme.text.mainL3};
    }

    &:hover,
    &:focus {
      background: transparent;
      transform: scale(1.1, 1.1);
      .anticon {
        color: ${({ theme }) => theme.palette.primary} !important;
      }
    }
  }

  .delete-action-wrapper {
    position: relative;
  }

  .action-feature-icon {
    position: absolute;
    top: -3px;
    right: -3px;
    font-size: 18px;
    color: ${({ theme }) => theme.subscriptions.colorIcon};
  }
`;

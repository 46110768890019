import styled from 'styled-components';

const CustomerInfoStyles = styled.div`
  background: #fff;
  padding: 20px;
  position: relative;

  @media only screen and (max-width: 992px) and (min-width: 560px) {
    display: flex;
    flex-direction: row;
    .info-other {
      display: flex;
      flex-wrap: wrap;
      margin-left: 20px;
      & > div {
        margin: 0 !important;
        padding: 0 15px 15px 15px;
      }
    }
  }
  .avatar-customer {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 24px;
  }
  .info-other > div:not(:last-child) {
    margin-bottom: 15px;
  }
  .title-info-other {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 5px;
  }
  .content-info-other > div {
    &:not(:last-child) {
      margin-bottom: 10px;
    }
    & > .anticon {
      margin-right: 10px;
    }
  }

  .email-text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .address-section {
    display: flex;
    align-items: center;
    .address-group > span {
      display: block;
    }
  }
`;

export default CustomerInfoStyles;

import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Badge, Button, Layout, Menu, Tag, Tooltip } from 'antd';
import { DEFAULT_INBOX_ID } from 'configs/localData';
import { useHistory, useLocation } from 'react-router';
import { AuditOutlined } from '@ant-design/icons';
import { countPendingSelector } from 'redux/applications/selectors';

import {
  BriefIcon,
  CentreIcon,
  CollapseSidebarIcon,
  DashBoardIcon,
  EnquiriesIcon,
  FileIcon,
  InsightsIcon,
  MarketingIcon,
  ProgramsLearningIcon,
  ReportsIcon,
  ResourceIcon,
  SettingOutlineIcon,
  TransactionsIcon,
  UsersIcon,
  VisitTourIcon,
} from 'components/common/SVGIcon';
import CheckPermissionsWrapper from 'components/common/CheckPermissions';

import { formatLabelTable } from 'containers/Centres/components/CentreTabs/ServicesTab/utils';
import { LABEL_TABLE_KEY } from 'configs/localData/constant';
import i18next from 'i18next';
import styled from 'styled-components';
import Logo from '../../assets/images/logo.svg';
import FullLogo from '../../assets/images/logo_plus.svg';
import { BadgeStyles, SideBarStyles } from './styles';
import MenuItem from './MenuItem';
import MenuItemFallBack from './MenuItemFallBack';
import { RootState } from '../../redux/store';
import { ChildrenProp } from '../../types';

const getCurrentTab = (str, key) => {
  const paths = str && str.split('/');
  return paths && paths[key];
};

export const BadgeWrapper = styled(Badge)<ChildrenProp>``;

const SideBar = ({ collapsed, onToggle }) => {
  const location = useLocation();
  const url = getCurrentTab(location.pathname, 1);
  const countPending = useSelector(countPendingSelector);
  const totalUnreadEnquires = useSelector(
    (state: RootState) => state.enquiries.totalUnread,
  );
  const totalSubmittedJobApplications = useSelector(
    (state: RootState) => state.jobApplications.totalSubmitted,
  );
  const history = useHistory();

  const total =
    totalSubmittedJobApplications > 99 ? '99+' : totalSubmittedJobApplications;

  const appSettings = useSelector(
    (state: RootState) => state.config?.data?.AppSetting,
  );

  const isShowListingPerformanceTag = formatLabelTable(
    LABEL_TABLE_KEY.isShowingListingPerformanceTag,
    appSettings,
  );

  const isShowProgramsAndLearningTag = formatLabelTable(
    LABEL_TABLE_KEY.isShowingProgramsAndLearningTagNew,
    appSettings,
  );

  const menus = [
    {
      key: 'dashboard',
      text: 'sideBar.dashboard',
      Icon: DashBoardIcon,
      url: '/',
      permissionKey: 'dashboard',
      action: 'isView',
    },
    {
      key: 'centres',
      text: 'sideBar.marketplaceListings',
      Icon: CentreIcon,
      url: '/centres/board',
      permissionKey: 'myCentres',
      action: 'isView',
    },
    {
      key: 'programs-and-learning',
      text: 'sideBar.programsAndLearning',
      Icon: ProgramsLearningIcon,
      url: '/programs-and-learning',
      permissionKey: 'programsAndLearning',
      tag: isShowProgramsAndLearningTag && (
        <Tag color="#3EBD5C" className="mr-0 px-3 fw-600 right-20">
          {i18next.t('sideBar.newTag')}
        </Tag>
      ),
    },
    {
      key: 'enquiries',
      text: 'sideBar.parentEnquiries',
      Icon: EnquiriesIcon,
      url: `/enquiries/all/${DEFAULT_INBOX_ID}`,
      permissionKey: 'enquiries',
      action: 'isView',
      count: totalUnreadEnquires,
    },
    {
      key: 'visits&tours',
      text: 'sideBar.visits&tours',
      Icon: VisitTourIcon,
      url: `/visits&tours`,
      action: 'isView',
      permissionKey: 'visitsAndTours',
    },
    {
      key: 'applications',
      text: 'sideBar.enrolmentApplications',
      Icon: FileIcon,
      url: '/applications/board',
      count: countPending,
      permissionKey: 'applications',
      action: 'isView',
    },
    {
      key: 'contacts',
      text: 'sideBar.contacts',
      Icon: UsersIcon,
      url: '/contacts/board',
      permissionKey: 'contacts',
      action: 'isView',
    },
    {
      key: 'marketing',
      text: 'sideBar.kindiCareAds',
      Icon: MarketingIcon,
      subMenu: [
        {
          key: 'advertisement',
          text: 'sideBar.DisplayAdsAndNativeAds',
          url: '/advertisement',
        },
      ],
    },
    {
      key: 'jobs',
      text: 'sideBar.kindiCareJobs',
      Icon: BriefIcon,
      subMenu: [
        {
          key: 'companyProfile',
          text: 'jobs.sideBar.recruitmentProfile',
          url: '/companyProfile',
        },
        {
          key: 'jobTemplates',
          text: 'jobs.sideBar.jobTemplates',
          url: '/jobTemplates',
        },
        {
          key: 'job-listings',
          text: 'jobs.sideBar.jobListings',
          url: '/job-listings/campaign/board',
        },
        {
          key: 'job-applications',
          text: 'jobs.sideBar.jobApplications',
          url: '/job-applications/board',
          count: totalSubmittedJobApplications > 0 ? total : null,
        },
      ],
      count: totalSubmittedJobApplications > 0 ? total : null,
    },
    {
      key: 'listingPerformance',
      text: 'sideBar.kindiCareAnalytics',
      Icon: ReportsIcon,
      url: '/marketing',
      tag: isShowListingPerformanceTag ? (
        <Tag color="#3EBD5C" className="mr-0 px-3 fw-600">
          {isShowListingPerformanceTag ? i18next.t('sideBar.newTag') : null}
        </Tag>
      ) : null,
      subMenu: [
        {
          key: 'marketing-metrics',
          text: 'sideBar.marketingMetrics',
          url: '/marketing-metrics',
        },
        {
          key: 'lead-activities',
          text: 'sideBar.leadActivities',
          url: '/lead-activities',
        },
      ],
    },
    {
      key: 'insights',
      text: 'sideBar.kindiCareInsights',
      Icon: InsightsIcon,
      subMenu: [
        {
          key: 'priceBenchmarking',
          text: 'Price Benchmarking',
          url: '/priceBenchmarking/list',
        },
        {
          key: 'companyBenchmarking',
          text: 'Company Benchmarking',
          url: '/companyBenchmarking',
        },
        {
          key: 'brandBenchmarking',
          text: 'Brand Benchmarking',
          url: '/brandBenchmarking',
        },
      ],
    },
    {
      key: 'resources',
      text: 'sideBar.resources',
      Icon: ResourceIcon,
      subMenu: [
        {
          key: 'digital-tiles',
          text: 'sideBar.digitalTiles',
          url: '/digital-tiles',
        },
        {
          key: 'export-library',
          text: 'sideBar.dataExportLibrary',
          url: '/export-library',
        },
      ],
    },
    {
      key: 'subscriptions',
      text: 'sideBar.subscriptions',
      Icon: AuditOutlined,
      url: '/subscriptions',
      permissionKey: 'subscriptionLevel',
      action: 'isView',
      isSkipSubscription: true,
    },
    {
      key: 'transactions',
      text: 'sideBar.transactions',
      Icon: TransactionsIcon,
      url: '/transactions',
      permissionKey: 'transactions',
      action: 'isView',
    },
    {
      key: 'config',
      text: 'sideBar.settings',
      Icon: SettingOutlineIcon,
      url: '/config',
      permissionKey: 'settings',
      action: 'isView',
    },
  ];
  return (
    <SideBarStyles className={`sider-wrapper sider-wrapper-${collapsed}`}>
      <Layout.Sider
        width="260"
        trigger={null}
        collapsible
        collapsed={collapsed}
        className="sidebar"
        collapsedWidth={72}
      >
        <Tooltip
          placement="right"
          title={
            collapsed
              ? i18next.t('sideBar.expandSidebar')
              : i18next.t('sideBar.collapseSidebar')
          }
          className="tooltip"
        >
          <Button
            type="text"
            onClick={onToggle}
            className={`collapse-sidebar-button ${
              collapsed ? 'collapse-sidebar-reverse' : ''
            }`}
            icon={<CollapseSidebarIcon />}
          />
        </Tooltip>
        <div className="logo h-64">
          {collapsed && <img alt="" src={Logo} />}
          <img alt="" src={FullLogo} className="fullLogo h-40" />
        </div>
        <Menu
          mode="inline"
          selectedKeys={[url || 'dashboard']}
          defaultSelectedKeys={[url || 'dashboard']}
        >
          {menus.map((menu) => {
            if (menu.subMenu) {
              return (
                <CheckPermissionsWrapper
                  key={menu.key}
                  permissionKey={menu.permissionKey}
                  action={menu.action}
                  isSkipSubscription={menu.isSkipSubscription}
                  isDisabled
                  menu={menu}
                  collapsed={collapsed}
                  fallback={<MenuItemFallBack />}
                >
                  <Menu.SubMenu
                    icon={
                      <>
                        {menu.count ? (
                          <BadgeWrapper count={menu.count}>
                            <menu.Icon />
                          </BadgeWrapper>
                        ) : (
                          <menu.Icon />
                        )}
                      </>
                    }
                    title={
                      menu?.tag ? (
                        <div className="flex flex-row flex-center-between w-full gap-4">
                          <p className="ellipsis-t mb-0">
                            {i18next.t(menu.text)}
                          </p>
                          {menu.tag}
                        </div>
                      ) : (
                        i18next.t(menu.text)
                      )
                    }
                  >
                    {menu.subMenu.map((item) => (
                      <Menu.Item
                        key={item.key}
                        onClick={() => history.push(item.url)}
                        title={i18next.t(item.text)}
                      >
                        <span>{i18next.t(item.text)}</span>
                        {item.count && (
                          <BadgeStyles>
                            <Badge count={menu.count} className="ml-10" />
                          </BadgeStyles>
                        )}
                      </Menu.Item>
                    ))}
                  </Menu.SubMenu>
                </CheckPermissionsWrapper>
              );
            }
            return (
              <CheckPermissionsWrapper
                key={menu.key}
                permissionKey={menu.permissionKey}
                action={menu.action}
                isSkipSubscription={menu.isSkipSubscription}
                isDisabled
                menu={menu}
                collapsed={collapsed}
                fallback={<MenuItemFallBack />}
              >
                <MenuItem />
              </CheckPermissionsWrapper>
            );
          })}
        </Menu>
      </Layout.Sider>
    </SideBarStyles>
  );
};

SideBar.propTypes = {
  collapsed: PropTypes.bool,
};

export default SideBar;

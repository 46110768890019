import { UseQuery } from '@reduxjs/toolkit/dist/query/react/buildHooks';
import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { isEmpty, debounce } from 'lodash-es';
import { BaseQueryFn, QueryDefinition } from '@reduxjs/toolkit/dist/query';

const BROWSER_OFFSET = 15;
const DEFAULT_SIZE = 10;
const FIRST_PAGE = 1;

interface DefaultParams {
  page?: number;
  size?: number;
  filter?: object;
  [key: string]: unknown;
}

interface DefaultResult<TRecord> {
  results: TRecord[];
  paging: {
    page: number;
    size: number;
    total: number;
  };
}

interface Props<TRecord> {
  useQuery: UseQuery<
    QueryDefinition<DefaultParams, BaseQueryFn, string, DefaultResult<TRecord>>
  >;
  searchKey?: string;
  queryParams?: object;
  skip?: boolean;
}
const useRTKQueryInfiniteLoad = <TRecord,>({
  useQuery,
  searchKey,
  queryParams,
  skip,
}: Props<TRecord>) => {
  const [size] = useState(DEFAULT_SIZE);
  const [localPage, setLocalPage] = useState(FIRST_PAGE);
  const [searchValue, setSearchValue] = useState<string | undefined>();
  const [combinedData, setCombinedData] = useState<TRecord[]>([]);

  const filter = useMemo(() => {
    if (searchValue && searchKey) {
      return {
        [searchKey]: searchValue,
      };
    }
    return undefined;
  }, [searchValue, searchKey]);

  const { data, isFetching, isLoading, refetch } = useQuery(
    {
      page: localPage,
      size,
      filter,
      ...queryParams,
    },
    { skip },
  );

  const fetchData = useMemo(() => data?.results, [data]);

  const {
    page: remotePage = FIRST_PAGE,
    total: remoteTotal = 0,
    size: remoteSize = DEFAULT_SIZE,
  } = data?.paging || {};

  const maxPages = Math.ceil(remoteTotal / remoteSize);

  const hasMore = remotePage < maxPages;

  useEffect(() => {
    if (isEmpty(fetchData) && fetchData?.length !== 0) {
      return;
    }
    if (localPage === FIRST_PAGE) {
      setCombinedData(fetchData ?? []);
    } else if (localPage === remotePage) {
      setCombinedData((previousData) => [
        ...(previousData ?? []),
        ...(fetchData ?? []),
      ]);
    }
  }, [fetchData, localPage, remotePage]);

  const refresh = useCallback(() => {
    setLocalPage(FIRST_PAGE);
    setSearchValue(undefined);
  }, []);

  const fetchNextPage = () => {
    if (localPage < maxPages && localPage === remotePage) {
      setLocalPage((page) => page + 1);
    }
  };

  const loadMore = (event: React.UIEvent) => {
    if (!hasMore) return;
    const target = event.target as HTMLDivElement;
    if (
      !isFetching &&
      target.scrollTop + target.offsetHeight <=
        target.scrollHeight + BROWSER_OFFSET &&
      target.scrollTop + target.offsetHeight >=
        target.scrollHeight - BROWSER_OFFSET
    ) {
      fetchNextPage();
    }
  };

  const onSearch = useMemo<(value: string) => void>(
    () =>
      debounce((value: string) => {
        if (value) {
          setCombinedData([]);
        }
        setLocalPage(FIRST_PAGE);
        setSearchValue(value);
      }, 500),
    [],
  );

  return {
    data: combinedData,
    page: localPage,
    total: remoteTotal,
    isLoading,
    isFetching,
    fetchNextPage,
    refresh,
    refetch,
    loadMore,
    onSearch,
  };
};

export default useRTKQueryInfiniteLoad;

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import i18next from 'i18next';
import { useDispatch, useSelector } from 'react-redux';
import { getAllApplicationsNotPaging } from 'redux/applications/actions';
import { getCentreId } from 'redux/auth/selectors';
import PageTitle from 'components/common/PageTitle';
import { ArchiveOutlineIcon } from 'components/common/SVGIcon';
import { VIEW_MODES } from 'configs/localData';
import clsx from 'clsx';
import BoardList from '../components/BoardList';
import TableView from '../components/TableView';
import { ListStyles } from './styles';
import { ApplicationListProvider } from './context';
import Summaries from '../components/Summaries';
import Header from './Header';

export const TABS_MODE_APPLICATION = {
  board: {
    ...VIEW_MODES.board,
    component: <BoardList />,
  },
  list: {
    ...VIEW_MODES.list,
    component: <TableView key="tableWithoutArchived" />,
  },
  archived: {
    title: 'enquiriesStatus.archived',
    key: 'archived',
    Icon: ArchiveOutlineIcon,
    component: <TableView key="tableArchived" />,
  },
};

const ApplicationsList = (props) => {
  const dispatch = useDispatch();
  const { match, history } = props;
  const activeTab = match?.params?.model || 'board';

  const centreId = useSelector(getCentreId);

  useEffect(() => {
    dispatch(getAllApplicationsNotPaging({}));
  }, [centreId]); // eslint-disable-line

  return (
    <ListStyles className="full-content">
      <PageTitle>{i18next.t('applications.header')}</PageTitle>
      <ApplicationListProvider>
        <Summaries />
        <Header activeTab={activeTab} history={history} />
        <div className="container-wrapper">
          <div
            className={clsx(
              activeTab === TABS_MODE_APPLICATION.board.key &&
                'board-container',
            )}
          >
            {TABS_MODE_APPLICATION?.[activeTab]?.component}
          </div>
        </div>
      </ApplicationListProvider>
    </ListStyles>
  );
};

ApplicationsList.propTypes = {
  match: PropTypes.object,
  history: PropTypes.object,
};

export default ApplicationsList;

import React from 'react';
import PropTypes from 'prop-types';
import {
  PieChart,
  Pie,
  Cell,
  ResponsiveContainer,
  Tooltip,
  Legend,
} from 'recharts';
import { isEmpty } from 'lodash-es';
import { getColorByIndex } from 'utils/tools';
import EmptyData from 'components/common/EmptyData';
import LegendChart from 'components/charts/LegendChart';
import TooltipChart from '../TooltipChart';
import { PieChartStyles } from './styles';

import useFormatFnc from './useFormat';

const PieChartCustom = ({
  data,
  dataKey,
  keyTotal,
  height,
  width,
  innerRadius,
  paddingAngle,
  isShowLabel,
  verticalAlignLegend,
  alignLegend,
  wrapperStyleLegend,
  cx,
  cy,
  formatValueTooltip,
  colors,
  isShowLabelLine,
  customExtra,
  outerRadius,
  isCircleLegend,
}) => {
  const useFormat = useFormatFnc({
    keyTotal,
    formatValueTooltip,
    isShowLabelLine,
  });

  const isTransparentStroke = data?.some((item) => item?.percent === 100);

  return (
    <PieChartStyles className="pie-chart-wrapper">
      {isEmpty(data) ? (
        <EmptyData />
      ) : (
        <ResponsiveContainer width={width} height={height}>
          <PieChart>
            <Pie
              data={data}
              cx={cx}
              {...(cy && {
                cy,
              })}
              innerRadius={innerRadius}
              outerRadius={outerRadius}
              paddingAngle={paddingAngle}
              dataKey={dataKey}
              labelLine={false}
              label={isShowLabel ? useFormat.formatLabel : false}
              isAnimationActive={false}
              stroke={isTransparentStroke ? 'transparent' : undefined}
            >
              {data.map((entry, index) => (
                <Cell
                  key={String(`cell-${index}`)}
                  fill={entry.color || colors[index] || getColorByIndex(index)}
                />
              ))}
            </Pie>
            <Tooltip
              formatValue={useFormat.formatValue}
              content={<TooltipChart isShowTitle={false} />}
              isCircleLegend={isCircleLegend}
            />
            <Legend
              align={alignLegend}
              verticalAlign={verticalAlignLegend}
              wrapperStyle={wrapperStyleLegend}
              content={<LegendChart customExtra={customExtra} />}
            />
          </PieChart>
        </ResponsiveContainer>
      )}
    </PieChartStyles>
  );
};

PieChartCustom.propTypes = {
  dataKey: PropTypes.string,
  data: PropTypes.array,
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  innerRadius: PropTypes.number,
  paddingAngle: PropTypes.number,
  isShowLabel: PropTypes.bool,
  keyTotal: PropTypes.string,
  verticalAlignLegend: PropTypes.string,
  alignLegend: PropTypes.string,
  wrapperStyleLegend: PropTypes.object,
  cx: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  cy: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  formatValueTooltip: PropTypes.func,
  colors: PropTypes.array,
  isShowLabelLine: PropTypes.bool,
  customExtra: PropTypes.any,
  outerRadius: PropTypes.number,
  isCircleLegend: PropTypes.bool,
};

PieChartCustom.defaultProps = {
  dataKey: 'percent',
  height: 300,
  width: '100%',
  innerRadius: 50,
  paddingAngle: 4,
  isShowLabel: true,
  keyTotal: 'total',
  verticalAlignLegend: 'middle',
  alignLegend: 'left',
  wrapperStyleLegend: {
    maxWidth: '100%',
  },
  cx: '50%',
  colors: [],
  outerRadius: 80,
  isCircleLegend: true,
};

export default PieChartCustom;

import styled from 'styled-components';

interface SkeletonProps {
  isShowDescription?: boolean;
}
interface ThumnailCardProps {
  isStyleCard: boolean;
}

export const ThumnailCardStyle = styled.div<ThumnailCardProps>`
  box-shadow: ${({ isStyleCard }) =>
    isStyleCard
      ? `0px 0px 1px rgba(12, 26, 75, 0.1),
    0px 4px 20px -2px rgba(50, 50, 71, 0.08)`
      : 'none'};
  .ant-card-meta-title {
    font-weight: 700;
  }
`;

export const SkeletonStyle = styled.div<SkeletonProps>`
  height: 14px;
  background-color: ${({ isShowDescription, theme }) =>
    isShowDescription ? theme.background.gray300 : theme.color.grayNeutral400};
`;

export const ImageDefaultStyle = styled.div`
  position: relative;
  aspect-ratio: 16/9;
  background-color: #ebecf0;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;

  .iframe-video {
    aspect-ratio: 16/9;
    object-fit: contain;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 100%;
    transform: translate(-50%, -50%);
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }
`;

export const SponsoredTextStyle = styled.div`
  top: -162px;
  left: 10px;
`;
export const IconDowloadStyle = styled.div`
  top: -162px;
  right: 10px;
  padding: 8px 12px;
`;

export const CarouselStyle = styled.div`
  .ant-carousel .slick-dots li.slick-active button {
    background: #758098;
  }
  .ant-carousel .slick-dots {
    z-index: 0;
    bottom: 0;
    li {
      width: 8px;
      button {
        background: #ffffff;
        opacity: 1;
        border-radius: 100px;
        width: 5px;
        height: 5px;
      }
    }
  }
`;

export const ArrowRightStyle = styled.div`
  width: 25px;
  height: 25px;
`;

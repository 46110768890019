import { createAsyncThunk } from '@reduxjs/toolkit';
import { apiWrapper } from 'utils/reduxUtils';
import {
  changeEmailApi,
  claimCentreApi,
  disputeCentreApi,
  signUpMerchantApi,
  signUpMerchantCallbackApi,
  getSummaryDetailCentreApi,
  getRatingsCentreApi,
  getAllCentresApi,
  changeFinishedStepApi,
  editAppSettingsCentreByKeyApi,
  getAppSettingsCentreApi,
  editSocialMediaChannelsApi,
  getAllSocialMediaChannelsApi,
  getKindiCareRatingSummaryApi,
  getVirtualTourPreviewApi,
  addCentreVirtualTourApi,
  getCentreVirtualTourApi,
} from 'api/centres';

import {
  createCentreServiceApi,
  editCentreServiceApi,
  delCentreServiceApi,
} from 'api/centreServices';

import { makeActions } from 'redux/crudCreator/actions';
import { delFeaturedMarketingApi } from 'api/marketings';
import { disputeReviewApi } from 'api/ratings';
import i18next from 'i18next';
import { notification } from 'antd';
import { ONE_HUNDRED_PERCENT_VALUE } from 'configs/localData/constant';
import { VirtualTourUrlsProps } from 'redux/jobs/companyProfile/actions';
import { getDataByIdApi } from 'api/crud';
import { omit } from 'lodash-es';
import { CentreDetail } from 'types/centres';
import {
  convertRequestParams,
  convertResponseData,
  PRIMARY_KEY,
} from '../crudCreator/dataProvider';

interface DataPayload {
  id?: string;
  roomName?: string | null;
  orderBy?: string;
  limit?: number;
  offset?: number;
}

interface PayloadProps {
  id?: string;
  values?: object;
  centreId?: string;
  marketingId?: string;
  virtualTourUrls?: VirtualTourUrlsProps[];
  isShowSuccess?: boolean;
  url?: string;
  data?: DataPayload;
  options?: {
    isRefresh?: boolean;
  };
}

export const MODEL_NAME = 'centres';
export const centresActions = makeActions(MODEL_NAME);

export const getAllCentres = centresActions.getAll;
export const editCentres = centresActions.edit;
export const createCentres = centresActions.create;
export const getByIdCentres = centresActions.getDataById;
export const clearCurrentCentre = centresActions.clearCurrent;

export const changeEmail = createAsyncThunk(
  'centres/changeEmail',
  async (payload: PayloadProps, thunkAPI) => {
    try {
      const { id, values } = payload;
      const response = await apiWrapper(
        {
          isShowSuccessNoti: true,
        },
        changeEmailApi,
        id,
        values,
      );
      if (response) {
        return response;
      }
      return thunkAPI.rejectWithValue(response);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  },
);

export const claimCentre = createAsyncThunk(
  'centres/claimCentre',
  async (payload: PayloadProps, thunkAPI) => {
    try {
      const { id, values } = payload;
      const response = await apiWrapper(
        {
          isShowSuccessNoti: false,
        },
        claimCentreApi,
        id,
        values,
      );
      if (response) {
        return response;
      }
      return thunkAPI.rejectWithValue(response);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  },
);

export const disputeCentre = createAsyncThunk(
  'centres/disputeCentre',
  async (payload: PayloadProps, thunkAPI) => {
    try {
      const { id, values } = payload;
      const response = await apiWrapper(
        {
          isShowSuccessNoti: false,
        },
        disputeCentreApi,
        id,
        values,
      );
      if (response) {
        return response;
      }
      return thunkAPI.rejectWithValue(response);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  },
);

export const signUpMerchant = createAsyncThunk(
  'centres/signUpMerchant',
  async (payload: PayloadProps, thunkAPI) => {
    try {
      const response = await apiWrapper(null, signUpMerchantApi, payload);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  },
);

export const signUpMerchantCallback = createAsyncThunk(
  'centres/signUpMerchantCallback',
  async (payload: PayloadProps, thunkAPI) => {
    try {
      const response = await apiWrapper(
        {
          isShowSuccessNoti: true,
          successDescription:
            'You have successfully signed up your merchant with PayPal',
        },
        signUpMerchantCallbackApi,
        payload,
      );
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  },
);

export const getSummaryDetailCentre = createAsyncThunk(
  'centres/getSummaryDetailCentre',
  async (payload, thunkAPI) => {
    try {
      const response = await apiWrapper({}, getSummaryDetailCentreApi, payload);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  },
);

export const getRatingsCentre = createAsyncThunk(
  'centres/getRatingsCentre',
  async (payload: PayloadProps, thunkApi: any) => {
    try {
      const { data = {}, options = {} } = payload;

      const { limit, offset, filter } =
        thunkApi.getState().centres.ratings?.resourceData || {};
      const { id, ...restData } = data;

      const convertRequest = convertRequestParams('GET_ALL', {
        limit,
        offset,
        filter,
        ...restData,
      });

      const response = await apiWrapper(
        {},
        getRatingsCentreApi,
        id,
        convertRequest,
      );

      return {
        data: {
          numberOfPages: Math.ceil(response.total / limit),
          ...response,
        },
        options,
      };
    } catch (error) {
      return thunkApi.rejectWithValue({ id: payload.data.id, data: error });
    }
  },
);

export const searchHeaderCentre = createAsyncThunk(
  'centres/searchHeader',
  async (payload: PayloadProps, thunkApi: any) => {
    try {
      const { data = {}, options = {} } = payload;
      const { limit, offset, filter, includes } =
        thunkApi.getState().centres || {};
      const convertRequest = convertRequestParams('GET_ALL', {
        limit,
        offset,
        filter,
        includes,
        ...data,
      });

      const response = await apiWrapper({}, getAllCentresApi, convertRequest);

      const result = convertResponseData('GET_ALL', response);

      if (result.data) {
        return {
          data: {
            numberOfPages: Math.ceil(result.total / limit),
            ...result,
          },
          options,
        };
      }
      return thunkApi.rejectWithValue({ data: response, options });
    } catch (error) {
      return thunkApi.rejectWithValue({ data: error });
    }
  },
);

export const createCentreService = createAsyncThunk(
  'centres/createCentreServiceCustom',
  async (payload: PayloadProps, thunkAPI) => {
    try {
      const { data } = payload;
      const response = await apiWrapper(
        { isShowSuccessNoti: true },
        createCentreServiceApi,
        data,
      );
      return { data: response };
    } catch (error) {
      return thunkAPI.rejectWithValue({ data: error });
    }
  },
);

export const editCentreService = createAsyncThunk(
  'centres/editCentreServiceCustom',
  async (payload: PayloadProps, thunkAPI) => {
    try {
      const { data } = payload;
      const { id, ...restData } = data;
      const response = await apiWrapper(
        { isShowSuccessNoti: false },
        editCentreServiceApi,
        id,
        restData,
      );
      return { data: response };
    } catch (error) {
      return thunkAPI.rejectWithValue({ data: error, id: payload.data.id });
    }
  },
);

export const delCentreService = createAsyncThunk(
  'centres/delCentreServiceCustom',
  async (payload: PayloadProps, thunkAPI) => {
    try {
      const response = await apiWrapper(
        { isShowSuccessNoti: true },
        delCentreServiceApi,
        payload,
      );

      return { data: response, id: payload };
    } catch (error) {
      return thunkAPI.rejectWithValue({ data: error, id: payload });
    }
  },
);

export const delFeaturedMarketing = createAsyncThunk(
  'centres/delFeaturedMarketing',
  async (payload: PayloadProps, thunkAPI) => {
    try {
      await apiWrapper(
        { isShowSuccessNoti: true },
        delFeaturedMarketingApi,
        payload.centreId,
        payload.marketingId,
      );

      return payload;
    } catch (error) {
      return thunkAPI.rejectWithValue({ data: error, id: payload });
    }
  },
);

export const disputeReview = createAsyncThunk(
  'ratings/disputeReview',
  async (payload: PayloadProps, thunkApi) => {
    try {
      const response = await apiWrapper(
        {
          isShowSuccessNoti: true,
        },
        disputeReviewApi,
        payload.id,
        payload.data,
      );
      return response;
    } catch (error) {
      return thunkApi.rejectWithValue({ id: payload.id, data: error });
    }
  },
);

export const changeFinishedStep = createAsyncThunk(
  'centres/changeFinishedStep',
  async (payload: PayloadProps, thunkAPI) => {
    try {
      const response = await apiWrapper(
        { isShowSuccessNoti: false },
        changeFinishedStepApi,
        payload.centreId,
        payload.data,
      );
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue({ data: error });
    }
  },
);

export const getAppSettingsCentre = createAsyncThunk(
  'centres/getAppSettingsCentre',
  async (payload, thunkAPI) => {
    try {
      const response = await apiWrapper({}, getAppSettingsCentreApi, payload);

      return response?.results;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  },
);

export const editAppSettingsCentre = createAsyncThunk(
  'centres/editAppSettingsCentre',
  async (payload: PayloadProps, thunkAPI) => {
    try {
      const { data, id, isShowSuccess = true } = payload;

      const requests = Object.entries(data).map(([key, value]) =>
        apiWrapper({}, editAppSettingsCentreByKeyApi, id, key, {
          value: value?.trim() || null,
        }),
      );

      const results = await Promise.all(requests);

      isShowSuccess &&
        notification.success({
          message: i18next.t('success.title'),
          description: i18next.t('success.description'),
        });

      return { data: results, isSuccess: true };
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  },
);

export const getAllSocialMediaChannels = createAsyncThunk(
  'centres/getAllSocialMediaChannels',
  async (payload, thunkAPI) => {
    try {
      const response = await apiWrapper(
        {},
        getAllSocialMediaChannelsApi,
        payload,
      );

      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  },
);

export const editSocialMediaChannels = createAsyncThunk(
  'centres/editSocialMediaChannels',
  async (payload: PayloadProps, thunkAPI) => {
    try {
      const { data, centreId, isShowSuccess = true } = payload;

      const requests = Object.entries(data).map(([key, value]) =>
        apiWrapper(
          { isShowSuccessNoti: false },
          editSocialMediaChannelsApi,
          centreId,
          {
            value: value || '',
            centreExternalLinkId: key,
          },
        ),
      );

      const results = await Promise.all(requests);

      isShowSuccess &&
        notification.success({
          message: i18next.t('success.title'),
          description: i18next.t('success.description'),
        });

      return { results, isSuccess: true };
    } catch (error) {
      return thunkAPI.rejectWithValue({ error });
    }
  },
);

export const getKindiCareRatingSummary = createAsyncThunk(
  'centres/getKindiCareRatingSummary',
  async (payload: PayloadProps, thunkAPI) => {
    try {
      const { id } = payload;
      return await apiWrapper({}, getKindiCareRatingSummaryApi, id);
    } catch (error) {
      return thunkAPI.rejectWithValue({ data: error, id: payload });
    }
  },
);

export const getVirtualTourPreview = createAsyncThunk(
  'centres/getVirtualTourPreview',
  async (payload: PayloadProps, thunkAPI) => {
    try {
      const response = await apiWrapper({}, getVirtualTourPreviewApi, {
        url: payload?.url,
        width: ONE_HUNDRED_PERCENT_VALUE,
        height: ONE_HUNDRED_PERCENT_VALUE,
      });
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue({ data: error });
    }
  },
);

export const addCentreVirtualTour = createAsyncThunk(
  'centres/addCentreVirtualTour',
  async (payload: PayloadProps, thunkAPI) => {
    try {
      const { centreId, virtualTourUrls } = payload;
      const response = await apiWrapper(
        { isShowSuccessNoti: true },
        addCentreVirtualTourApi,
        centreId,
        {
          virtualTourUrls,
        },
      );
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue({ data: error });
    }
  },
);

export const getCentreVirtualTour = createAsyncThunk(
  'centres/getCentreVirtualTour',
  async (payload: PayloadProps, thunkAPI) => {
    try {
      const { centreId } = payload;
      const response = await apiWrapper({}, getCentreVirtualTourApi, centreId);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue({ data: error });
    }
  },
);

export interface CentreByIdPayload {
  data?: CentreDetail;
  options?: {
    isRequestApi?: boolean;
    isShowProgress?: boolean;
    customApiResource?: string;
    prefixUrl?: string;
    suffixUrl?: string;
  };
}

export const getCentreById = createAsyncThunk(
  `centres/getDataById`,
  async (payload: CentreByIdPayload, thunkAPI) => {
    const { data, options = { isRequestApi: true } } = payload;
    try {
      if (!options.isRequestApi) {
        return { data, options: { isRequestApi: false } };
      }

      const response = await apiWrapper(
        { isShowProgress: options.isShowProgress },
        getDataByIdApi,
        'centres',
        data[PRIMARY_KEY],
        omit(data, [PRIMARY_KEY]),
        options.prefixUrl,
        options.suffixUrl,
      );
      const result = convertResponseData('GET_BY_ID', response);
      if (result) {
        return { data: result, options, id: data[PRIMARY_KEY] };
      }
      return thunkAPI.rejectWithValue({
        data: result,
        options,
        id: data[PRIMARY_KEY],
      });
    } catch (error) {
      return thunkAPI.rejectWithValue({
        data: error,
        options,
        id: data[PRIMARY_KEY],
      });
    }
  },
);

import React, { useState } from 'react';
import i18next from 'i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Form, Button, Divider, Input } from 'antd';
import { forgotPassword as forgotPasswordAction } from 'redux/auth/actions';
import AuthContact from 'components/common/AuthContact';
import ForgotPasswordStyleWrapper from './styles';
import logo from '../../assets/images/logo_plus.svg';
import GuideContent from './GuideContent';
import ForgotPasswordSuccess from './ForgotPasswordSuccess';

const FormItem = Form.Item;

const ForgotPassword = () => {
  const [isRequestSuccess, setIsRequestSuccess] = useState(false);
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.auth.loading);
  const forgotPassword = (params) => dispatch(forgotPasswordAction(params));
  const handleLogin = () => {
    form
      .validateFields()
      .then((values) => {
        forgotPassword(values).then(({ payload }) => {
          payload && setIsRequestSuccess(true);
        });
      })
      .catch(() => {});
  };

  if (isRequestSuccess) {
    return <ForgotPasswordSuccess email={form.getFieldValue('email')} />;
  }

  return (
    <ForgotPasswordStyleWrapper className="isoSignInPage">
      <img alt="" src={logo} className="logo" />
      <div className="title">
        <div className="maintitle">
          <span className="title-text">
            {i18next.t('forgotPassword.title')}
          </span>
        </div>
      </div>
      <GuideContent />
      <div className="isoSignInForm">
        <Form form={form} onFinish={handleLogin} layout="vertical">
          <div className="isoInputWrapper">
            <FormItem
              name="email"
              label={i18next.t('login.yourEmail')}
              rules={[
                {
                  required: true,
                  message: i18next.t('input.email.validateMsg.required'),
                },
                {
                  type: 'email',
                  message: i18next.t('input.email.validateMsg.invalid'),
                },
              ]}
            >
              <Input />
            </FormItem>
          </div>
          <div className="buttonWrapper">
            <Button loading={loading} type="primary" htmlType="submit">
              {i18next.t('button.send')}
            </Button>
            <Divider>{i18next.t('text.or')}</Divider>
            <Button type="secondary">
              <Link to="/login">{i18next.t('login.loginBtn')}</Link>
            </Button>
          </div>
          <AuthContact
            className="mt-20"
            text="auth.contactText.l1ResetPassword"
          />
        </Form>
      </div>
    </ForgotPasswordStyleWrapper>
  );
};

export default ForgotPassword;

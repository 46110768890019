import React from 'react';
import { Tooltip } from 'antd';

interface RowItemProp {
  Icon?: React.FC<React.HTMLAttributes<object>>;
  value?: string;
  tooltip?: string;
}

const RowItem = ({ Icon, value, tooltip }: RowItemProp) => (
  <div className="flex-center-start flex-row gap-8 cursor-pointer">
    {!!value && <Icon className="text-16 text-center text-primary" />}

    {tooltip ? (
      <Tooltip title={tooltip}>
        <div className="text-14">{value}</div>
      </Tooltip>
    ) : (
      <div className="text-14 line-clamp-1">{value}</div>
    )}
  </div>
);

export default RowItem;

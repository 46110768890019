import React from 'react';
import { Button } from 'antd';
import PropTypes from 'prop-types';

import CheckPermission from 'components/common/CheckPermissions';
import { EditIcon } from 'components/common/SVGIcon';
import EditBtnFallback from './EditBtnFallback';
import EditCustomBtnStyles from './styles';

const EditCustomBtn = ({
  onClickButton,
  permissionKey,
  action,
  isSkipSubscription,
  packageName,
  disabled,
}) => (
  <EditCustomBtnStyles className="edit-wrapper">
    <CheckPermission
      packageName={packageName}
      permissionKey={permissionKey}
      action={action}
      isDisabled
      fallback={<EditBtnFallback />}
      isSkipSubscription={isSkipSubscription}
    >
      <Button
        onClick={onClickButton}
        className="edit-custom-btn"
        type="primary"
        shape="circle"
        icon={<EditIcon />}
        disabled={disabled}
      />
    </CheckPermission>
  </EditCustomBtnStyles>
);

EditCustomBtn.propTypes = {
  permissionKey: PropTypes.string,
  action: PropTypes.string,
  onClickButton: PropTypes.func,
  isSkipSubscription: PropTypes.bool,
  packageName: PropTypes.string,
  disabled: PropTypes.bool,
};

EditCustomBtn.defaultProps = {
  onClickButton: () => null,
};

export default EditCustomBtn;

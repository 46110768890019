import React, { useEffect, useState } from 'react';
import { Row, Col } from 'antd';
import PropTypes from 'prop-types';
import i18next from 'i18next';
import { getDataPriceBenchmarkingSummary } from 'redux/priceBenchmarking/selectors';
import {
  TotalYourCentresIcon,
  TotalApprovedPlacesIcon,
  TotalRevenueIcon,
  TotalPriceIcon,
} from 'components/common/SVGIcon';
import { useSelector } from 'react-redux';
import SummaryCard from 'components/common/SummaryPriceBenchmarkingCard';
import { getFullDisplayAddress } from 'utils/dataUtils';

const Summaries = ({ resultFilter }) => {
  const centresData = useSelector((state) => state?.auth?.data?.centres);
  const summary = useSelector(getDataPriceBenchmarkingSummary);
  const centreIdFilter = resultFilter.centreId;
  const reportTypes = resultFilter.reportType;
  const currentCentre = centresData?.find(
    (centre) => centre.id === centreIdFilter,
  );
  const address =
    resultFilter.displayAddress || getFullDisplayAddress(resultFilter);
  const [summaryTitle, setSummaryTitle] = useState('');

  const summariesMap = [
    {
      summaryTitle: 'insight.summaries.yourCentres',
      summaryValue: Number(summary?.userStatictis?.totalCentres),
      summaryTotalTitle: 'insight.summaries.totalCentres',
      summaryTotalValue: Number(summary?.totalStaticts?.totalCentres),
      IconComponent: TotalYourCentresIcon,
      isShareValue: true,
    },
    {
      summaryTitle: 'insight.summaries.yourApprovedPlaces',
      summaryValue: Number(summary?.userStatictis?.totalCapacity),
      summaryTotalTitle: 'insight.summaries.totalApproved',
      summaryTotalValue: Number(summary?.totalStaticts?.totalCapacity),
      IconComponent: TotalApprovedPlacesIcon,
      isShareValue: true,
    },
    {
      summaryTitle: 'insight.summaries.yourEstRevenue',
      summaryValue: Number(summary?.userStatictis?.totalRevenuePotential),
      summaryTotalTitle: 'insight.summaries.totalEstRevenue',
      summaryTotalValue: Number(summary?.totalStaticts?.totalRevenuePotential),
      IconComponent: TotalRevenueIcon,
      isShareValue: true,
      isMoney: true,
    },
    {
      summaryTitle: 'insight.summaries.yourAverage',
      summaryValue: summary?.userStatictis?.totalAgeGroupPrice,
      summaryTotalTitle: 'insight.summaries.totalAverage',
      summaryTotalValue: summary?.totalStaticts?.totalAgeGroupPrice,
      IconComponent: TotalPriceIcon,
      isMoney: true,
      isCheckPrice: true,
      isNoUserCentre: Number(summary?.userStatictis?.totalCentres) === 0,
    },
  ];

  useEffect(() => {
    if (centreIdFilter) {
      switch (reportTypes) {
        case 'postcode':
          setSummaryTitle(
            `${currentCentre.name}, ${currentCentre.postCode}, ${currentCentre.state}`,
          );
          break;
        case 'radius1000':
          setSummaryTitle(
            `${currentCentre.name} - ${i18next.t(
              'priceBenchmarking.summaries.radius1000',
            )}`,
          );
          break;
        case 'radius2500':
          setSummaryTitle(
            `${currentCentre.name} - ${i18next.t(
              'priceBenchmarking.summaries.radius2500',
            )}`,
          );
          break;
        case 'radius5000':
          setSummaryTitle(
            `${currentCentre.name} - ${i18next.t(
              'priceBenchmarking.summaries.radius5000',
            )}`,
          );
          break;
        default:
          setSummaryTitle(
            `${currentCentre.name}, ${currentCentre.city}, ${currentCentre.postCode}, ${currentCentre.state}`,
          );
      }
    } else {
      switch (reportTypes) {
        case 'postcode':
          setSummaryTitle(address);
          break;
        case 'radius1000':
          setSummaryTitle(
            `${address} - ${i18next.t(
              'priceBenchmarking.summaries.radius1000',
            )}`,
          );
          break;
        case 'radius2500':
          setSummaryTitle(
            `${address} - ${i18next.t(
              'priceBenchmarking.summaries.radius2500',
            )}`,
          );
          break;
        case 'radius5000':
          setSummaryTitle(
            `${address} - ${i18next.t(
              'priceBenchmarking.summaries.radius5000',
            )}`,
          );
          break;
        default:
          setSummaryTitle(address);
          break;
      }
    }
  }, [resultFilter, address, centreIdFilter, currentCentre, reportTypes]);

  return (
    <div>
      <Row className="mb-20 flex-center-between">
        <Col>
          <div className="fw-bold leading-loose text-16 text-primary">
            {summaryTitle}
          </div>
        </Col>
      </Row>
      <Row gutter={[25, 25]} className="mb-20">
        {summariesMap.map((item, index) => (
          <Col lg={6} md={12} xs={24} key={String(index)}>
            <SummaryCard {...item} />
          </Col>
        ))}
      </Row>
    </div>
  );
};

Summaries.propTypes = {
  resultFilter: PropTypes.object,
};

export default Summaries;

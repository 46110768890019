import React from 'react';
import { Row, Col } from 'antd';
import RestInputItem from 'components/RestInput/RestInputItem';
import RestSelect from 'components/RestInput/RestSelect';
import i18next from 'i18next';
import { useSelector } from 'react-redux';
import { getServiceTypes } from 'redux/config/selectors';
import { omit } from 'lodash-es';

const Filter = ({ ...props }) => {
  const restValues = omit(props, 'format');
  const serviceTypes = useSelector(getServiceTypes);

  return (
    <Row {...restValues} gutter={16} justify="end">
      <Col md={6} xs={24}>
        <RestInputItem
          source="childName"
          placeholder="transactions.applicationName"
        />
      </Col>
      <Col md={6} xs={24}>
        <RestSelect
          source="serviceAppliedId"
          placeholder="transactions.serviceApplied"
          valueProp="id"
          titleProp="name"
          resourceData={serviceTypes}
          formatText={(value) => i18next.t(value)}
        />
      </Col>
    </Row>
  );
};

export default Filter;

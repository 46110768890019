import React from 'react';
import PropTypes from 'prop-types';
import { Checkbox, Tooltip, Typography } from 'antd';
import { JOB_LISTING_DETAIL_TABS } from 'containers/JobListings/constants';

const CheckboxItem = ({ checked, item, onAddItem, onRemoveItem }) => {
  const onChange = (e) => {
    if (e.target.checked) {
      onAddItem(item?.id);
    } else {
      onRemoveItem(item?.id);
    }
  };

  const onOpenJobDetail = () => {
    window.open(
      `/job-listings/${item?.id}/show/${JOB_LISTING_DETAIL_TABS.JOB_DETAILS}`,
    );
  };
  const renderItem = () => {
    if (item?.applications > 0) {
      return (
        <Tooltip title="You can't delete a job listing having applications.">
          <div className="flex mt-16 w-full">
            <Checkbox disabled className="mr-12" />
            <Typography.Text
              onClick={onOpenJobDetail}
              className="text-gray-500 cursor-pointer"
            >
              <span className="text-decoration-line fw-600">
                {item?.jobReference}
              </span>
            </Typography.Text>
            <span className="text-gray-300 text-16 mr-10 ml-10">&#9679;</span>
            <Typography.Text
              onClick={onOpenJobDetail}
              className="text-gray-500 cursor-pointer"
            >
              <span>{item?.jobTitle}</span>
            </Typography.Text>
          </div>
        </Tooltip>
      );
    }
    return (
      <div className="flex mt-16">
        <Checkbox checked={checked} onChange={onChange} className="mr-12" />
        <div>
          <Typography.Text
            onClick={onOpenJobDetail}
            className="text-black-base cursor-pointer "
          >
            <span className="text-decoration-line fw-600">
              {item?.jobReference}
            </span>
          </Typography.Text>
          <span className="text-gray-300 text-16 mr-10 ml-10">&#9679;</span>
          <Typography.Text
            onClick={onOpenJobDetail}
            className="text-black-base cursor-pointer"
          >
            <span>{item?.jobTitle}</span>
          </Typography.Text>
        </div>
      </div>
    );
  };
  return renderItem();
};

CheckboxItem.propTypes = {
  checked: PropTypes.bool,
  item: PropTypes.object,
  onAddItem: PropTypes.func,
  onRemoveItem: PropTypes.func,
};

export default CheckboxItem;

import {
  AVG_POSITION_LINE,
  DEFAULT_COMPARISON_WIDTH_CHART,
} from 'containers/CompanyBenchmarking/localData/constants';

export const renderAverageLine = (chartData, avgRating) => {
  if (avgRating !== 'NaN') {
    const splitedAvg = avgRating.split('');
    const lastPart = splitedAvg[splitedAvg.length - 1];

    const trailingZeroNumber = chartData.find(
      (item) => lastPart === '0' && Number(item?.rating) === Number(avgRating),
    );

    if (trailingZeroNumber) {
      return {
        position: AVG_POSITION_LINE.MIDDLE,
        rating: trailingZeroNumber?.rating,
      };
    }
    return chartData?.find((item) => Number(item?.rating) > Number(avgRating));
  }
  return null;
};

export const calculateChartWidthByDataLength = (length) => {
  let widthChart = 0;
  switch (true) {
    case length >= 35 && length <= 100:
      widthChart = 1750;
      break;
    case length >= 101 && length <= 300:
      widthChart = 2250;
      break;
    case length >= 301:
      widthChart = 3000;
      break;
    default:
      widthChart = DEFAULT_COMPARISON_WIDTH_CHART;
      break;
  }
  return widthChart;
};

export const splitStringByLength = (str, length) => {
  if (typeof str === 'string' && str?.length > length) {
    return `${str.substring(0, length)}...`;
  }
  return str;
};

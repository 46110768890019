import styled from 'styled-components';

export const CustomerFieldStyles = styled.div`
  display: flex;
  align-items: center;
  .ant-avatar {
    margin-right: 16px;
  }
  .fullName {
    font-weight: 600;
    font-size: 14px;
    margin-bottom: 4px;
  }
`;

import { baseApi as api } from '../redux/@rtkQuery/baseApi';
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    centreServiceControllerGetMany: build.query<
      CentreServiceControllerGetManyApiResponse,
      CentreServiceControllerGetManyApiArg
    >({
      query: (queryArg) => ({
        url: `/thor/v2/crm/centre-services`,
        params: {
          page: queryArg.page,
          size: queryArg.size,
          limit: queryArg.limit,
          offset: queryArg.offset,
          sortKey: queryArg.sortKey,
          sortOrder: queryArg.sortOrder,
          centreId: queryArg.centreId,
        },
      }),
    }),
    centreServiceControllerCreateOne: build.mutation<
      CentreServiceControllerCreateOneApiResponse,
      CentreServiceControllerCreateOneApiArg
    >({
      query: (queryArg) => ({
        url: `/thor/v2/crm/centre-services`,
        method: 'POST',
        body: queryArg.createCentreServiceDto,
      }),
    }),
    centreServiceControllerUpdateOne: build.mutation<
      CentreServiceControllerUpdateOneApiResponse,
      CentreServiceControllerUpdateOneApiArg
    >({
      query: (queryArg) => ({
        url: `/thor/v2/crm/centre-services`,
        method: 'PUT',
        body: queryArg.updateCentreServiceDto,
        params: { serviceId: queryArg.serviceId },
      }),
    }),
    centreServiceControllerDeleteOne: build.mutation<
      CentreServiceControllerDeleteOneApiResponse,
      CentreServiceControllerDeleteOneApiArg
    >({
      query: (queryArg) => ({
        url: `/thor/v2/crm/centre-services`,
        method: 'DELETE',
        params: { serviceId: queryArg.serviceId },
      }),
    }),
    centreFeaturesControllerGet: build.query<
      CentreFeaturesControllerGetApiResponse,
      CentreFeaturesControllerGetApiArg
    >({
      query: (queryArg) => ({
        url: `/thor/v2/crm/centre/details/features`,
        params: {
          centreId: queryArg.centreId,
          centreSlug: queryArg.centreSlug,
        },
      }),
    }),
    centreFeaturesControllerPut: build.mutation<
      CentreFeaturesControllerPutApiResponse,
      CentreFeaturesControllerPutApiArg
    >({
      query: (queryArg) => ({
        url: `/thor/v2/crm/centre/details/features`,
        method: 'PUT',
        body: queryArg.updateCentreFeatureDto,
      }),
    }),
    educatorProfileControllerGetEducatorProfiles: build.query<
      EducatorProfileControllerGetEducatorProfilesApiResponse,
      EducatorProfileControllerGetEducatorProfilesApiArg
    >({
      query: (queryArg) => ({
        url: `/thor/v2/crm/centre/educator-profiles`,
        params: {
          page: queryArg.page,
          size: queryArg.size,
          limit: queryArg.limit,
          offset: queryArg.offset,
          centreId: queryArg.centreId,
          name: queryArg.name,
          isShowThisProfile: queryArg.isShowThisProfile,
          jobTitle: queryArg.jobTitle,
          jobRole: queryArg.jobRole,
          qualification: queryArg.qualification,
          isDraft: queryArg.isDraft,
        },
      }),
    }),
    educatorProfileControllerCreateEducatorProfile: build.mutation<
      EducatorProfileControllerCreateEducatorProfileApiResponse,
      EducatorProfileControllerCreateEducatorProfileApiArg
    >({
      query: (queryArg) => ({
        url: `/thor/v2/crm/centre/educator-profiles`,
        method: 'POST',
        body: queryArg.createEducatorDto,
      }),
    }),
    educatorProfileControllerUpdateEducatorProfile: build.mutation<
      EducatorProfileControllerUpdateEducatorProfileApiResponse,
      EducatorProfileControllerUpdateEducatorProfileApiArg
    >({
      query: (queryArg) => ({
        url: `/thor/v2/crm/centre/educator-profiles`,
        method: 'PUT',
        body: queryArg.updateEducatorDto,
        params: { id: queryArg.id },
      }),
    }),
    educatorProfileControllerDeleteEducatorProfile: build.mutation<
      EducatorProfileControllerDeleteEducatorProfileApiResponse,
      EducatorProfileControllerDeleteEducatorProfileApiArg
    >({
      query: (queryArg) => ({
        url: `/thor/v2/crm/centre/educator-profiles`,
        method: 'DELETE',
        params: { id: queryArg.id },
      }),
    }),
    educatorProfileControllerGetEducatorProfile: build.query<
      EducatorProfileControllerGetEducatorProfileApiResponse,
      EducatorProfileControllerGetEducatorProfileApiArg
    >({
      query: (queryArg) => ({
        url: `/thor/v2/crm/centre/educator-profiles/details`,
        params: { id: queryArg.id },
      }),
    }),
    educatorProfileControllerSuggestion: build.query<
      EducatorProfileControllerSuggestionApiResponse,
      EducatorProfileControllerSuggestionApiArg
    >({
      query: (queryArg) => ({
        url: `/thor/v2/crm/centre/educator-profiles/suggestion`,
        params: {
          page: queryArg.page,
          size: queryArg.size,
          limit: queryArg.limit,
          offset: queryArg.offset,
          centreId: queryArg.centreId,
          isNameSuggestion: queryArg.isNameSuggestion,
          isJobTitleSuggestion: queryArg.isJobTitleSuggestion,
          name: queryArg.name,
          jobTitle: queryArg.jobTitle,
        },
      }),
    }),
    educatorProfileControllerGetConfigs: build.query<
      EducatorProfileControllerGetConfigsApiResponse,
      EducatorProfileControllerGetConfigsApiArg
    >({
      query: () => ({ url: `/thor/v2/crm/centre/educator-profiles/configs` }),
    }),
    educatorProfileControllerGetCentreUsers: build.query<
      EducatorProfileControllerGetCentreUsersApiResponse,
      EducatorProfileControllerGetCentreUsersApiArg
    >({
      query: (queryArg) => ({
        url: `/thor/v2/crm/centre/educator-profiles/users`,
        params: {
          page: queryArg.page,
          size: queryArg.size,
          limit: queryArg.limit,
          offset: queryArg.offset,
          centreId: queryArg.centreId,
          name: queryArg.name,
          jobTitle: queryArg.jobTitle,
        },
      }),
    }),
    contactApplicationControllerGetApplicationList: build.query<
      ContactApplicationControllerGetApplicationListApiResponse,
      ContactApplicationControllerGetApplicationListApiArg
    >({
      query: (queryArg) => ({
        url: `/thor/v2/crm/contacts/applications`,
        headers: { centreId: queryArg.centreId },
        params: {
          page: queryArg.page,
          size: queryArg.size,
          limit: queryArg.limit,
          offset: queryArg.offset,
          contactId: queryArg.contactId,
          sorts: queryArg.sorts,
        },
      }),
    }),
    crmContactsConfigControllerGetCrmContactConfig: build.query<
      CrmContactsConfigControllerGetCrmContactConfigApiResponse,
      CrmContactsConfigControllerGetCrmContactConfigApiArg
    >({
      query: () => ({ url: `/thor/v2/crm/contacts/config` }),
    }),
    contactEnquiriesControllerGetEnquiriesList: build.query<
      ContactEnquiriesControllerGetEnquiriesListApiResponse,
      ContactEnquiriesControllerGetEnquiriesListApiArg
    >({
      query: (queryArg) => ({
        url: `/thor/v2/crm/contacts/enquiries`,
        headers: { centreId: queryArg.centreId },
        params: {
          page: queryArg.page,
          size: queryArg.size,
          limit: queryArg.limit,
          offset: queryArg.offset,
          contactId: queryArg.contactId,
          sorts: queryArg.sorts,
        },
      }),
    }),
    contactsLeadActivitiesControllerGetTableDataForCentre: build.query<
      ContactsLeadActivitiesControllerGetTableDataForCentreApiResponse,
      ContactsLeadActivitiesControllerGetTableDataForCentreApiArg
    >({
      query: (queryArg) => ({
        url: `/thor/v2/crm/contacts/lead-activities`,
        params: {
          page: queryArg.page,
          size: queryArg.size,
          limit: queryArg.limit,
          offset: queryArg.offset,
          contactId: queryArg.contactId,
          sorts: queryArg.sorts,
          channel: queryArg.channel,
          loginStatus: queryArg.loginStatus,
          userId: queryArg.userId,
          userName: queryArg.userName,
          userEmail: queryArg.userEmail,
          userPhone: queryArg.userPhone,
          types: queryArg.types,
          centreName: queryArg.centreName,
          centreId: queryArg.centreId,
          providerName: queryArg.providerName,
          state: queryArg.state,
          brandName: queryArg.brandName,
          clickThroughDate: queryArg.clickThroughDate,
          subscription: queryArg.subscription,
          PRNumber: queryArg.prNumber,
          SENumber: queryArg.seNumber,
        },
      }),
    }),
    checkIfApplicationExistsControllerCheckIfApplicationExists: build.query<
      CheckIfApplicationExistsControllerCheckIfApplicationExistsApiResponse,
      CheckIfApplicationExistsControllerCheckIfApplicationExistsApiArg
    >({
      query: (queryArg) => ({
        url: `/thor/v2/crm/job-seekers/posts/applications/is-exists`,
        params: { applicationId: queryArg.applicationId },
      }),
    }),
    leadActivitiesControllerGetTableDataForCentre: build.query<
      LeadActivitiesControllerGetTableDataForCentreApiResponse,
      LeadActivitiesControllerGetTableDataForCentreApiArg
    >({
      query: (queryArg) => ({
        url: `/thor/v2/crm/listing-performance/lead-activities/centres`,
        params: {
          page: queryArg.page,
          size: queryArg.size,
          limit: queryArg.limit,
          offset: queryArg.offset,
          sorts: queryArg.sorts,
          channel: queryArg.channel,
          loginStatus: queryArg.loginStatus,
          userId: queryArg.userId,
          userName: queryArg.userName,
          userEmail: queryArg.userEmail,
          userPhone: queryArg.userPhone,
          types: queryArg.types,
          centreName: queryArg.centreName,
          centreId: queryArg.centreId,
          providerName: queryArg.providerName,
          state: queryArg.state,
          brandName: queryArg.brandName,
          clickThroughDate: queryArg.clickThroughDate,
          subscription: queryArg.subscription,
          PRNumber: queryArg.prNumber,
          SENumber: queryArg.seNumber,
        },
      }),
    }),
    leadActivitiesConfigControllerGetConfigLeadActivities: build.query<
      LeadActivitiesConfigControllerGetConfigLeadActivitiesApiResponse,
      LeadActivitiesConfigControllerGetConfigLeadActivitiesApiArg
    >({
      query: () => ({
        url: `/thor/v2/crm/listing-performance/lead-activities/config`,
      }),
    }),
    laConfigPrNumberControllerGetUserNameConfig: build.query<
      LaConfigPrNumberControllerGetUserNameConfigApiResponse,
      LaConfigPrNumberControllerGetUserNameConfigApiArg
    >({
      query: (queryArg) => ({
        url: `/thor/v2/crm/listing-performance/lead-activities/config/pr-number`,
        params: {
          page: queryArg.page,
          size: queryArg.size,
          limit: queryArg.limit,
          offset: queryArg.offset,
          sorts: queryArg.sorts,
          channel: queryArg.channel,
          loginStatus: queryArg.loginStatus,
          userId: queryArg.userId,
          userName: queryArg.userName,
          userEmail: queryArg.userEmail,
          userPhone: queryArg.userPhone,
          types: queryArg.types,
          centreName: queryArg.centreName,
          centreId: queryArg.centreId,
          providerName: queryArg.providerName,
          state: queryArg.state,
          brandName: queryArg.brandName,
          clickThroughDate: queryArg.clickThroughDate,
          subscription: queryArg.subscription,
          PRNumber: queryArg.prNumber,
          SENumber: queryArg.seNumber,
        },
      }),
    }),
    laConfigSeNumberControllerGetUserNameConfig: build.query<
      LaConfigSeNumberControllerGetUserNameConfigApiResponse,
      LaConfigSeNumberControllerGetUserNameConfigApiArg
    >({
      query: (queryArg) => ({
        url: `/thor/v2/crm/listing-performance/lead-activities/config/se-number`,
        params: {
          page: queryArg.page,
          size: queryArg.size,
          limit: queryArg.limit,
          offset: queryArg.offset,
          sorts: queryArg.sorts,
          channel: queryArg.channel,
          loginStatus: queryArg.loginStatus,
          userId: queryArg.userId,
          userName: queryArg.userName,
          userEmail: queryArg.userEmail,
          userPhone: queryArg.userPhone,
          types: queryArg.types,
          centreName: queryArg.centreName,
          centreId: queryArg.centreId,
          providerName: queryArg.providerName,
          state: queryArg.state,
          brandName: queryArg.brandName,
          clickThroughDate: queryArg.clickThroughDate,
          subscription: queryArg.subscription,
          PRNumber: queryArg.prNumber,
          SENumber: queryArg.seNumber,
        },
      }),
    }),
    laConfigUserEmailControllerGetUserEmailConfig: build.query<
      LaConfigUserEmailControllerGetUserEmailConfigApiResponse,
      LaConfigUserEmailControllerGetUserEmailConfigApiArg
    >({
      query: (queryArg) => ({
        url: `/thor/v2/crm/listing-performance/lead-activities/config/user-email`,
        params: {
          page: queryArg.page,
          size: queryArg.size,
          limit: queryArg.limit,
          offset: queryArg.offset,
          sorts: queryArg.sorts,
          channel: queryArg.channel,
          loginStatus: queryArg.loginStatus,
          userId: queryArg.userId,
          userName: queryArg.userName,
          userEmail: queryArg.userEmail,
          userPhone: queryArg.userPhone,
          types: queryArg.types,
          centreName: queryArg.centreName,
          centreId: queryArg.centreId,
          providerName: queryArg.providerName,
          state: queryArg.state,
          brandName: queryArg.brandName,
          clickThroughDate: queryArg.clickThroughDate,
          subscription: queryArg.subscription,
          PRNumber: queryArg.prNumber,
          SENumber: queryArg.seNumber,
        },
      }),
    }),
    laConfigUserNameControllerGetUserNameConfig: build.query<
      LaConfigUserNameControllerGetUserNameConfigApiResponse,
      LaConfigUserNameControllerGetUserNameConfigApiArg
    >({
      query: (queryArg) => ({
        url: `/thor/v2/crm/listing-performance/lead-activities/config/user-name`,
        params: {
          page: queryArg.page,
          size: queryArg.size,
          limit: queryArg.limit,
          offset: queryArg.offset,
          sorts: queryArg.sorts,
          channel: queryArg.channel,
          loginStatus: queryArg.loginStatus,
          userId: queryArg.userId,
          userName: queryArg.userName,
          userEmail: queryArg.userEmail,
          userPhone: queryArg.userPhone,
          types: queryArg.types,
          centreName: queryArg.centreName,
          centreId: queryArg.centreId,
          providerName: queryArg.providerName,
          state: queryArg.state,
          brandName: queryArg.brandName,
          clickThroughDate: queryArg.clickThroughDate,
          subscription: queryArg.subscription,
          PRNumber: queryArg.prNumber,
          SENumber: queryArg.seNumber,
        },
      }),
    }),
    laConfigUserPhoneControllerGetUserPhoneConfig: build.query<
      LaConfigUserPhoneControllerGetUserPhoneConfigApiResponse,
      LaConfigUserPhoneControllerGetUserPhoneConfigApiArg
    >({
      query: (queryArg) => ({
        url: `/thor/v2/crm/listing-performance/lead-activities/config/user-phone`,
        params: {
          page: queryArg.page,
          size: queryArg.size,
          limit: queryArg.limit,
          offset: queryArg.offset,
          sorts: queryArg.sorts,
          channel: queryArg.channel,
          loginStatus: queryArg.loginStatus,
          userId: queryArg.userId,
          userName: queryArg.userName,
          userEmail: queryArg.userEmail,
          userPhone: queryArg.userPhone,
          types: queryArg.types,
          centreName: queryArg.centreName,
          centreId: queryArg.centreId,
          providerName: queryArg.providerName,
          state: queryArg.state,
          brandName: queryArg.brandName,
          clickThroughDate: queryArg.clickThroughDate,
          subscription: queryArg.subscription,
          PRNumber: queryArg.prNumber,
          SENumber: queryArg.seNumber,
        },
      }),
    }),
    crmLeadActivitiesReportControllerRequestReport: build.mutation<
      CrmLeadActivitiesReportControllerRequestReportApiResponse,
      CrmLeadActivitiesReportControllerRequestReportApiArg
    >({
      query: (queryArg) => ({
        url: `/thor/v2/crm/listing-performance/lead-activities/report`,
        method: 'POST',
        body: queryArg.sortAndFilterLaDto,
      }),
    }),
    crmLeadActivitiesReportDetailsControllerGetOne: build.query<
      CrmLeadActivitiesReportDetailsControllerGetOneApiResponse,
      CrmLeadActivitiesReportDetailsControllerGetOneApiArg
    >({
      query: (queryArg) => ({
        url: `/thor/v2/crm/listing-performance/lead-activities/report/details`,
        params: { id: queryArg.id },
      }),
    }),
    crmLpMarketingListControllerGetChartData: build.query<
      CrmLpMarketingListControllerGetChartDataApiResponse,
      CrmLpMarketingListControllerGetChartDataApiArg
    >({
      query: (queryArg) => ({
        url: `/thor/v2/crm/listing-performance/marketing-metric/centres`,
        params: {
          page: queryArg.page,
          size: queryArg.size,
          limit: queryArg.limit,
          offset: queryArg.offset,
          channel: queryArg.channel,
          companyId: queryArg.companyId,
          providerId: queryArg.providerId,
          centreId: queryArg.centreId,
          brandId: queryArg.brandId,
          companyName: queryArg.companyName,
          providerName: queryArg.providerName,
          centreName: queryArg.centreName,
          brandName: queryArg.brandName,
          state: queryArg.state,
          primaryChildCareServiceId: queryArg.primaryChildCareServiceId,
          crmPackageId: queryArg.crmPackageId,
          timeRange: queryArg.timeRange,
          chartLines: queryArg.chartLines,
          isActive: queryArg.isActive,
          chartResolution: queryArg.chartResolution,
          timezone: queryArg.timezone,
        },
      }),
    }),
    crmLpMarketingChartControllerGetChartData: build.query<
      CrmLpMarketingChartControllerGetChartDataApiResponse,
      CrmLpMarketingChartControllerGetChartDataApiArg
    >({
      query: (queryArg) => ({
        url: `/thor/v2/crm/listing-performance/marketing-metric/chart`,
        params: {
          page: queryArg.page,
          size: queryArg.size,
          limit: queryArg.limit,
          offset: queryArg.offset,
          channel: queryArg.channel,
          companyId: queryArg.companyId,
          providerId: queryArg.providerId,
          centreId: queryArg.centreId,
          brandId: queryArg.brandId,
          companyName: queryArg.companyName,
          providerName: queryArg.providerName,
          centreName: queryArg.centreName,
          brandName: queryArg.brandName,
          state: queryArg.state,
          primaryChildCareServiceId: queryArg.primaryChildCareServiceId,
          crmPackageId: queryArg.crmPackageId,
          timeRange: queryArg.timeRange,
          chartLines: queryArg.chartLines,
          isActive: queryArg.isActive,
          chartResolution: queryArg.chartResolution,
          timezone: queryArg.timezone,
        },
      }),
    }),
    crmLpMarketingReportControllerGetOne: build.query<
      CrmLpMarketingReportControllerGetOneApiResponse,
      CrmLpMarketingReportControllerGetOneApiArg
    >({
      query: (queryArg) => ({
        url: `/thor/v2/crm/listing-performance/marketing-metric/report/detail`,
        params: { id: queryArg.id },
      }),
    }),
    crmLpMarketingReportControllerRequestReport: build.mutation<
      CrmLpMarketingReportControllerRequestReportApiResponse,
      CrmLpMarketingReportControllerRequestReportApiArg
    >({
      query: (queryArg) => ({
        url: `/thor/v2/crm/listing-performance/marketing-metric/report`,
        method: 'POST',
        body: queryArg.getTableCentreKeyMetricDto,
      }),
    }),
    crmLpMarketingSummaryControllerGetChartData: build.query<
      CrmLpMarketingSummaryControllerGetChartDataApiResponse,
      CrmLpMarketingSummaryControllerGetChartDataApiArg
    >({
      query: (queryArg) => ({
        url: `/thor/v2/crm/listing-performance/marketing-metric/summary`,
        params: {
          page: queryArg.page,
          size: queryArg.size,
          limit: queryArg.limit,
          offset: queryArg.offset,
          channel: queryArg.channel,
          companyId: queryArg.companyId,
          providerId: queryArg.providerId,
          centreId: queryArg.centreId,
          brandId: queryArg.brandId,
          companyName: queryArg.companyName,
          providerName: queryArg.providerName,
          centreName: queryArg.centreName,
          brandName: queryArg.brandName,
          state: queryArg.state,
          primaryChildCareServiceId: queryArg.primaryChildCareServiceId,
          crmPackageId: queryArg.crmPackageId,
          timeRange: queryArg.timeRange,
          chartLines: queryArg.chartLines,
          isActive: queryArg.isActive,
          chartResolution: queryArg.chartResolution,
          timezone: queryArg.timezone,
        },
      }),
    }),
    newFeatureControllerGetNewFeatureForCrm: build.query<
      NewFeatureControllerGetNewFeatureForCrmApiResponse,
      NewFeatureControllerGetNewFeatureForCrmApiArg
    >({
      query: (queryArg) => ({
        url: `/thor/v2/crm/new-feature`,
        params: { isImpersonate: queryArg.isImpersonate },
      }),
    }),
    newFeatureControllerSetNewFeatureForCrm: build.mutation<
      NewFeatureControllerSetNewFeatureForCrmApiResponse,
      NewFeatureControllerSetNewFeatureForCrmApiArg
    >({
      query: (queryArg) => ({
        url: `/thor/v2/crm/new-feature`,
        method: 'POST',
        body: queryArg.showNewFeatureBodyDto,
      }),
    }),
    parentApplicationConfigControllerGetArchivedReasonConfig: build.query<
      ParentApplicationConfigControllerGetArchivedReasonConfigApiResponse,
      ParentApplicationConfigControllerGetArchivedReasonConfigApiArg
    >({
      query: () => ({ url: `/thor/v2/crm/parent-application/config` }),
    }),
    programNameControllerGetProgramName: build.query<
      ProgramNameControllerGetProgramNameApiResponse,
      ProgramNameControllerGetProgramNameApiArg
    >({
      query: (queryArg) => ({
        url: `/thor/v2/crm/programs/config/program-name`,
        params: {
          page: queryArg.page,
          size: queryArg.size,
          limit: queryArg.limit,
          offset: queryArg.offset,
          filter: queryArg.filter,
        },
      }),
    }),
    confirmProgramControllerGetConfirmProgram: build.mutation<
      ConfirmProgramControllerGetConfirmProgramApiResponse,
      ConfirmProgramControllerGetConfirmProgramApiArg
    >({
      query: (queryArg) => ({
        url: `/thor/v2/crm/programs/confirm`,
        method: 'POST',
        body: queryArg.updateProgramDto,
        params: { programId: queryArg.programId },
      }),
    }),
    detailProgramControllerGetDetailProgram: build.query<
      DetailProgramControllerGetDetailProgramApiResponse,
      DetailProgramControllerGetDetailProgramApiArg
    >({
      query: (queryArg) => ({
        url: `/thor/v2/crm/programs/detail`,
        params: { programId: queryArg.programId },
      }),
    }),
    programPermissionControllerGetProgramPermission: build.query<
      ProgramPermissionControllerGetProgramPermissionApiResponse,
      ProgramPermissionControllerGetProgramPermissionApiArg
    >({
      query: () => ({ url: `/thor/v2/crm/programs/permission` }),
    }),
    permittedUsersControllerGetPermittedUsers: build.query<
      PermittedUsersControllerGetPermittedUsersApiResponse,
      PermittedUsersControllerGetPermittedUsersApiArg
    >({
      query: (queryArg) => ({
        url: `/thor/v2/crm/programs/permitted-users`,
        params: {
          page: queryArg.page,
          size: queryArg.size,
          limit: queryArg.limit,
          offset: queryArg.offset,
          centreId: queryArg.centreId,
        },
      }),
    }),
    programsControllerCreateOneProgram: build.mutation<
      ProgramsControllerCreateOneProgramApiResponse,
      ProgramsControllerCreateOneProgramApiArg
    >({
      query: (queryArg) => ({
        url: `/thor/v2/crm/programs`,
        method: 'POST',
        body: queryArg.createProgramDto,
      }),
    }),
    programsControllerUpdateOneProgram: build.mutation<
      ProgramsControllerUpdateOneProgramApiResponse,
      ProgramsControllerUpdateOneProgramApiArg
    >({
      query: (queryArg) => ({
        url: `/thor/v2/crm/programs`,
        method: 'PUT',
        body: queryArg.updateProgramDto,
        params: { programId: queryArg.programId },
      }),
    }),
    programsControllerGetProgramList: build.query<
      ProgramsControllerGetProgramListApiResponse,
      ProgramsControllerGetProgramListApiArg
    >({
      query: (queryArg) => ({
        url: `/thor/v2/crm/programs`,
        params: {
          page: queryArg.page,
          size: queryArg.size,
          limit: queryArg.limit,
          offset: queryArg.offset,
          filter: queryArg.filter,
          programName: queryArg.programName,
          serviceTypeName: queryArg.serviceTypeName,
          serviceTypeId: queryArg.serviceTypeId,
          ageGroupName: queryArg.ageGroupName,
          ageGroupId: queryArg.ageGroupId,
          sessionTypeName: queryArg.sessionTypeName,
          sessionTypeId: queryArg.sessionTypeId,
          centreName: queryArg.centreName,
          providerName: queryArg.providerName,
          brandName: queryArg.brandName,
          state: queryArg.state,
          createdAt: queryArg.createdAt,
          updatedAt: queryArg.updatedAt,
        },
      }),
    }),
    programsControllerDeleteOneProgram: build.mutation<
      ProgramsControllerDeleteOneProgramApiResponse,
      ProgramsControllerDeleteOneProgramApiArg
    >({
      query: (queryArg) => ({
        url: `/thor/v2/crm/programs`,
        method: 'DELETE',
        params: { programId: queryArg.programId },
      }),
    }),
    resourcesControllerGetAll: build.query<
      ResourcesControllerGetAllApiResponse,
      ResourcesControllerGetAllApiArg
    >({
      query: (queryArg) => ({
        url: `/thor/v2/crm/resources/export-library`,
        params: {
          page: queryArg.page,
          size: queryArg.size,
          limit: queryArg.limit,
          offset: queryArg.offset,
          sortKey: queryArg.sortKey,
          sortOrder: queryArg.sortOrder,
        },
      }),
    }),
    resourcesControllerDeleteOne: build.mutation<
      ResourcesControllerDeleteOneApiResponse,
      ResourcesControllerDeleteOneApiArg
    >({
      query: (queryArg) => ({
        url: `/thor/v2/crm/resources/export-library`,
        method: 'DELETE',
        params: { id: queryArg.id },
      }),
    }),
    crmConfigControllerGetSummaryJobListings: build.query<
      CrmConfigControllerGetSummaryJobListingsApiResponse,
      CrmConfigControllerGetSummaryJobListingsApiArg
    >({
      query: () => ({ url: `/thor/v2/crm/config` }),
    }),
    crmConfigControllerGetPageEula: build.query<
      CrmConfigControllerGetPageEulaApiResponse,
      CrmConfigControllerGetPageEulaApiArg
    >({
      query: () => ({ url: `/thor/v2/crm/config/page-eula` }),
    }),
    centreControllerFindOne: build.query<
      CentreControllerFindOneApiResponse,
      CentreControllerFindOneApiArg
    >({
      query: (queryArg) => ({ url: `/thor/v2/crm/centres/${queryArg.id}` }),
    }),
    ratingSummaryControllerGetRatingSummary: build.query<
      RatingSummaryControllerGetRatingSummaryApiResponse,
      RatingSummaryControllerGetRatingSummaryApiArg
    >({
      query: (queryArg) => ({
        url: `/thor/v2/crm/centres/details/rating-summary`,
        params: { centreId: queryArg.centreId },
      }),
    }),
    basicInfoControllerUpdateBasicInfo: build.mutation<
      BasicInfoControllerUpdateBasicInfoApiResponse,
      BasicInfoControllerUpdateBasicInfoApiArg
    >({
      query: (queryArg) => ({
        url: `/thor/v2/crm/centres/details/basic-info`,
        method: 'PUT',
        body: queryArg.updateBasicInfoDto,
        headers: {
          Authorization: queryArg.authorization,
          centreid: queryArg.centreid,
          centreids: queryArg.centreids,
        },
        params: { centreId: queryArg.centreId },
      }),
    }),
    virtualTourCenTreControllerUpsertVirtualTourForCentre: build.mutation<
      VirtualTourCenTreControllerUpsertVirtualTourForCentreApiResponse,
      VirtualTourCenTreControllerUpsertVirtualTourForCentreApiArg
    >({
      query: (queryArg) => ({
        url: `/thor/v2/crm/centres/details/virtual-tour`,
        method: 'POST',
        body: queryArg.virtualTourUrlsDto,
        params: { centreId: queryArg.centreId },
      }),
    }),
    virtualTourCenTreControllerGetVirtualTourOfCentre: build.query<
      VirtualTourCenTreControllerGetVirtualTourOfCentreApiResponse,
      VirtualTourCenTreControllerGetVirtualTourOfCentreApiArg
    >({
      query: (queryArg) => ({
        url: `/thor/v2/crm/centres/details/virtual-tour`,
        params: { centreId: queryArg.centreId },
      }),
    }),
    jobListingControllerCreateOne: build.mutation<
      JobListingControllerCreateOneApiResponse,
      JobListingControllerCreateOneApiArg
    >({
      query: (queryArg) => ({
        url: `/thor/v2/crm/job-seekers/posts`,
        method: 'POST',
        body: queryArg.createJobListingDto,
      }),
    }),
    jobListingControllerUpdateOne: build.mutation<
      JobListingControllerUpdateOneApiResponse,
      JobListingControllerUpdateOneApiArg
    >({
      query: (queryArg) => ({
        url: `/thor/v2/crm/job-seekers/posts`,
        method: 'PUT',
        body: queryArg.updateJobListingDto,
        params: { postId: queryArg.postId },
      }),
    }),
    jobListingControllerDeleteMany: build.mutation<
      JobListingControllerDeleteManyApiResponse,
      JobListingControllerDeleteManyApiArg
    >({
      query: (queryArg) => ({
        url: `/thor/v2/crm/job-seekers/posts`,
        method: 'DELETE',
        params: { postIds: queryArg.postIds },
      }),
    }),
    jobListingControllerGetJobListings: build.query<
      JobListingControllerGetJobListingsApiResponse,
      JobListingControllerGetJobListingsApiArg
    >({
      query: (queryArg) => ({
        url: `/thor/v2/crm/job-seekers/posts`,
        params: {
          page: queryArg.page,
          size: queryArg.size,
          limit: queryArg.limit,
          offset: queryArg.offset,
          jobReference: queryArg.jobReference,
          campaign: queryArg.campaign,
          templateId: queryArg.templateId,
          campaignId: queryArg.campaignId,
          jobRoleId: queryArg.jobRoleId,
          jobTemplate: queryArg.jobTemplate,
          templateName: queryArg.templateName,
          name: queryArg.name,
          workplaceType: queryArg.workplaceType,
          workplace: queryArg.workplace,
          jobTypeId: queryArg.jobTypeId,
          status: queryArg.status,
          jobListingStatus: queryArg.jobListingStatus,
          brandId: queryArg.brandId,
          state: queryArg.state,
          timeDuration: queryArg.timeDuration,
          createdAt: queryArg.createdAt,
          updatedAt: queryArg.updatedAt,
          lastUpdatedAt: queryArg.lastUpdatedAt,
          jobTitle: queryArg.jobTitle,
          sorts: queryArg.sorts,
        },
      }),
    }),
    jobListingControllerBulkAction: build.mutation<
      JobListingControllerBulkActionApiResponse,
      JobListingControllerBulkActionApiArg
    >({
      query: (queryArg) => ({
        url: `/thor/v2/crm/job-seekers/posts/bulk`,
        method: 'POST',
        body: queryArg.bulkActionDto,
      }),
    }),
    jobListingControllerUpdateMany: build.mutation<
      JobListingControllerUpdateManyApiResponse,
      JobListingControllerUpdateManyApiArg
    >({
      query: (queryArg) => ({
        url: `/thor/v2/crm/job-seekers/posts/bulk`,
        method: 'PUT',
        body: queryArg.moveToCampaignBodyDto,
      }),
    }),
    jobListingDetailsControllerGetTemplateDetails: build.query<
      JobListingDetailsControllerGetTemplateDetailsApiResponse,
      JobListingDetailsControllerGetTemplateDetailsApiArg
    >({
      query: (queryArg) => ({
        url: `/thor/v2/crm/job-seekers/posts/details`,
        params: { postId: queryArg.postId },
      }),
    }),
    jobReferenceExistentControllerCheckJobReferenceListExists: build.query<
      JobReferenceExistentControllerCheckJobReferenceListExistsApiResponse,
      JobReferenceExistentControllerCheckJobReferenceListExistsApiArg
    >({
      query: (queryArg) => ({
        url: `/thor/v2/crm/job-seekers/posts/job-references-existent`,
        params: { organizations: queryArg.organizations },
      }),
    }),
    summaryJobListingControllerGetSummaryJobListings: build.query<
      SummaryJobListingControllerGetSummaryJobListingsApiResponse,
      SummaryJobListingControllerGetSummaryJobListingsApiArg
    >({
      query: (queryArg) => ({
        url: `/thor/v2/crm/job-seekers/posts/summary`,
        params: {
          page: queryArg.page,
          size: queryArg.size,
          limit: queryArg.limit,
          offset: queryArg.offset,
          jobReference: queryArg.jobReference,
          campaign: queryArg.campaign,
          templateId: queryArg.templateId,
          campaignId: queryArg.campaignId,
          jobRoleId: queryArg.jobRoleId,
          jobTemplate: queryArg.jobTemplate,
          templateName: queryArg.templateName,
          name: queryArg.name,
          workplaceType: queryArg.workplaceType,
          workplace: queryArg.workplace,
          jobTypeId: queryArg.jobTypeId,
          status: queryArg.status,
          jobListingStatus: queryArg.jobListingStatus,
          brandId: queryArg.brandId,
          state: queryArg.state,
          timeDuration: queryArg.timeDuration,
          createdAt: queryArg.createdAt,
          updatedAt: queryArg.updatedAt,
          lastUpdatedAt: queryArg.lastUpdatedAt,
          jobTitle: queryArg.jobTitle,
          sorts: queryArg.sorts,
        },
      }),
    }),
    workplaceControllerGetJobOrganizationsByIds: build.query<
      WorkplaceControllerGetJobOrganizationsByIdsApiResponse,
      WorkplaceControllerGetJobOrganizationsByIdsApiArg
    >({
      query: (queryArg) => ({
        url: `/thor/v2/crm/job-seekers/posts/workplaces/get-by-ids`,
        params: { organizationIds: queryArg.organizationIds },
      }),
    }),
    workplaceControllerGetWorkplaceList: build.query<
      WorkplaceControllerGetWorkplaceListApiResponse,
      WorkplaceControllerGetWorkplaceListApiArg
    >({
      query: (queryArg) => ({
        url: `/thor/v2/crm/job-seekers/posts/workplaces`,
        params: {
          page: queryArg.page,
          size: queryArg.size,
          limit: queryArg.limit,
          offset: queryArg.offset,
          companyId: queryArg.companyId,
          brandId: queryArg.brandId,
          centre: queryArg.centre,
          SENumber: queryArg.seNumber,
          state: queryArg.state,
          suburb: queryArg.suburb,
          postCode: queryArg.postCode,
          workplaceType: queryArg.workplaceType,
          inIds: queryArg.inIds,
          notInIds: queryArg.notInIds,
        },
      }),
    }),
    workplaceControllerGetWorkplacesBasicInfo: build.query<
      WorkplaceControllerGetWorkplacesBasicInfoApiResponse,
      WorkplaceControllerGetWorkplacesBasicInfoApiArg
    >({
      query: (queryArg) => ({
        url: `/thor/v2/crm/job-seekers/posts/workplaces/basic-info`,
        params: {
          page: queryArg.page,
          size: queryArg.size,
          limit: queryArg.limit,
          offset: queryArg.offset,
          workplaceName: queryArg.workplaceName,
        },
      }),
    }),
    jobListingCancelControllerCancelOne: build.mutation<
      JobListingCancelControllerCancelOneApiResponse,
      JobListingCancelControllerCancelOneApiArg
    >({
      query: (queryArg) => ({
        url: `/thor/v2/crm/job-seekers/posts/cancel`,
        method: 'DELETE',
        params: { id: queryArg.id },
      }),
    }),
    jobPostTemplateConfigControllerGetFormTemplateConfig: build.query<
      JobPostTemplateConfigControllerGetFormTemplateConfigApiResponse,
      JobPostTemplateConfigControllerGetFormTemplateConfigApiArg
    >({
      query: () => ({
        url: `/thor/v2/crm/job-seekers/posts/templates/form-config`,
      }),
    }),
    jobPostTemplateControllerGetJobTemplates: build.query<
      JobPostTemplateControllerGetJobTemplatesApiResponse,
      JobPostTemplateControllerGetJobTemplatesApiArg
    >({
      query: (queryArg) => ({
        url: `/thor/v2/crm/job-seekers/posts/templates`,
        params: {
          page: queryArg.page,
          size: queryArg.size,
          limit: queryArg.limit,
          offset: queryArg.offset,
          sorts: queryArg.sorts,
        },
      }),
    }),
    jobPostTemplateControllerCreateJobTemplates: build.mutation<
      JobPostTemplateControllerCreateJobTemplatesApiResponse,
      JobPostTemplateControllerCreateJobTemplatesApiArg
    >({
      query: (queryArg) => ({
        url: `/thor/v2/crm/job-seekers/posts/templates`,
        method: 'POST',
        body: queryArg.createJobTemplateDto,
      }),
    }),
    jobPostTemplateControllerUpdateJobTemplates: build.mutation<
      JobPostTemplateControllerUpdateJobTemplatesApiResponse,
      JobPostTemplateControllerUpdateJobTemplatesApiArg
    >({
      query: (queryArg) => ({
        url: `/thor/v2/crm/job-seekers/posts/templates`,
        method: 'PUT',
        body: queryArg.updateJobTemplateDto,
        params: { templateId: queryArg.templateId },
      }),
    }),
    jobTemplateFilterConfigControllerGetFilterConfig: build.query<
      JobTemplateFilterConfigControllerGetFilterConfigApiResponse,
      JobTemplateFilterConfigControllerGetFilterConfigApiArg
    >({
      query: () => ({
        url: `/thor/v2/crm/job-seekers/posts/templates/filter-config`,
      }),
    }),
    jobTemplateDetailsControllerGetTemplateDetails: build.query<
      JobTemplateDetailsControllerGetTemplateDetailsApiResponse,
      JobTemplateDetailsControllerGetTemplateDetailsApiArg
    >({
      query: (queryArg) => ({
        url: `/thor/v2/crm/job-seekers/posts/templates/details`,
        params: { templateId: queryArg.templateId },
      }),
    }),
    jobPostTemplateBasicInfoControllerGetJobTemplatesBasicInfo: build.query<
      JobPostTemplateBasicInfoControllerGetJobTemplatesBasicInfoApiResponse,
      JobPostTemplateBasicInfoControllerGetJobTemplatesBasicInfoApiArg
    >({
      query: (queryArg) => ({
        url: `/thor/v2/crm/job-seekers/posts/templates/basic-info`,
        params: {
          page: queryArg.page,
          size: queryArg.size,
          limit: queryArg.limit,
          offset: queryArg.offset,
          templateName: queryArg.templateName,
        },
      }),
    }),
    jobApplicationControllerGetAllJobApplications: build.query<
      JobApplicationControllerGetAllJobApplicationsApiResponse,
      JobApplicationControllerGetAllJobApplicationsApiArg
    >({
      query: (queryArg) => ({
        url: `/thor/v2/crm/job-seekers/posts/applications`,
        params: {
          page: queryArg.page,
          size: queryArg.size,
          limit: queryArg.limit,
          offset: queryArg.offset,
          sorts: queryArg.sorts,
        },
      }),
    }),
    jobApplicationDetailControllerGetProfileSeeker: build.query<
      JobApplicationDetailControllerGetProfileSeekerApiResponse,
      JobApplicationDetailControllerGetProfileSeekerApiArg
    >({
      query: (queryArg) => ({
        url: `/thor/v2/crm/job-seekers/posts/applications/profile`,
        params: { id: queryArg.id },
      }),
    }),
    summanyInfoControllerGetInfoSummary: build.query<
      SummanyInfoControllerGetInfoSummaryApiResponse,
      SummanyInfoControllerGetInfoSummaryApiArg
    >({
      query: (queryArg) => ({
        url: `/thor/v2/crm/job-seekers/posts/applications/summany`,
        params: { id: queryArg.id },
      }),
    }),
    mailBoxControllerGetMessageMailBoxApplication: build.query<
      MailBoxControllerGetMessageMailBoxApplicationApiResponse,
      MailBoxControllerGetMessageMailBoxApplicationApiArg
    >({
      query: (queryArg) => ({
        url: `/thor/v2/crm/job-seekers/posts/applications/mail-box`,
        params: {
          page: queryArg.page,
          size: queryArg.size,
          limit: queryArg.limit,
          offset: queryArg.offset,
          applicationId: queryArg.applicationId,
        },
      }),
    }),
    changeStatusControllerChangeStatusJobApplication: build.mutation<
      ChangeStatusControllerChangeStatusJobApplicationApiResponse,
      ChangeStatusControllerChangeStatusJobApplicationApiArg
    >({
      query: (queryArg) => ({
        url: `/thor/v2/crm/job-seekers/posts/applications/change-status`,
        method: 'POST',
        params: {
          applicationId: queryArg.applicationId,
          statusId: queryArg.statusId,
          declinedReasonId: queryArg.declinedReasonId,
          currentStatus: queryArg.currentStatus,
        },
      }),
    }),
    documentControllerGetDocumentOfSeekerInMailBox: build.query<
      DocumentControllerGetDocumentOfSeekerInMailBoxApiResponse,
      DocumentControllerGetDocumentOfSeekerInMailBoxApiArg
    >({
      query: (queryArg) => ({
        url: `/thor/v2/crm/job-seekers/posts/applications/documents`,
        params: {
          page: queryArg.page,
          size: queryArg.size,
          limit: queryArg.limit,
          offset: queryArg.offset,
          applicationId: queryArg.applicationId,
        },
      }),
    }),
    jobApplicationSummaryControllerGetSummaryJobApplications: build.query<
      JobApplicationSummaryControllerGetSummaryJobApplicationsApiResponse,
      JobApplicationSummaryControllerGetSummaryJobApplicationsApiArg
    >({
      query: (queryArg) => ({
        url: `/thor/v2/crm/job-seekers/posts/applications/application-summary`,
        params: {
          page: queryArg.page,
          size: queryArg.size,
          limit: queryArg.limit,
          offset: queryArg.offset,
        },
      }),
    }),
    totalSubmittedJobApplicationControllerGetTotalSubmittedJobApplications:
      build.query<
        TotalSubmittedJobApplicationControllerGetTotalSubmittedJobApplicationsApiResponse,
        TotalSubmittedJobApplicationControllerGetTotalSubmittedJobApplicationsApiArg
      >({
        query: () => ({
          url: `/thor/v2/crm/job-seekers/posts/applications/total-submitted-applications`,
        }),
      }),
    activityNoteControllerCreateActivity: build.mutation<
      ActivityNoteControllerCreateActivityApiResponse,
      ActivityNoteControllerCreateActivityApiArg
    >({
      query: (queryArg) => ({
        url: `/thor/v2/crm/job-seekers/posts/applications/activity-notes`,
        method: 'POST',
        body: queryArg.applicationActivity,
      }),
    }),
    activityNoteControllerGetAllActivityNote: build.query<
      ActivityNoteControllerGetAllActivityNoteApiResponse,
      ActivityNoteControllerGetAllActivityNoteApiArg
    >({
      query: (queryArg) => ({
        url: `/thor/v2/crm/job-seekers/posts/applications/activity-notes`,
        params: {
          page: queryArg.page,
          size: queryArg.size,
          limit: queryArg.limit,
          offset: queryArg.offset,
        },
      }),
    }),
    activityNoteDetailControllerGetOne: build.query<
      ActivityNoteDetailControllerGetOneApiResponse,
      ActivityNoteDetailControllerGetOneApiArg
    >({
      query: (queryArg) => ({
        url: `/thor/v2/crm/job-seekers/posts/applications/activity-notes/detail`,
        params: { id: queryArg.id },
      }),
    }),
    applicantBasicInfoControllerGetAllApplicantsBasicInfo: build.query<
      ApplicantBasicInfoControllerGetAllApplicantsBasicInfoApiResponse,
      ApplicantBasicInfoControllerGetAllApplicantsBasicInfoApiArg
    >({
      query: (queryArg) => ({
        url: `/thor/v2/crm/job-seekers/posts/applicant/basic-info`,
        params: {
          page: queryArg.page,
          size: queryArg.size,
          limit: queryArg.limit,
          offset: queryArg.offset,
        },
      }),
    }),
    jobReferenceBasicInfoControllerGetJobReferencesBasicInfo: build.query<
      JobReferenceBasicInfoControllerGetJobReferencesBasicInfoApiResponse,
      JobReferenceBasicInfoControllerGetJobReferencesBasicInfoApiArg
    >({
      query: (queryArg) => ({
        url: `/thor/v2/crm/job-seekers/posts/job-reference/basic-info`,
        params: {
          page: queryArg.page,
          size: queryArg.size,
          limit: queryArg.limit,
          offset: queryArg.offset,
        },
      }),
    }),
    jobPostTitleBasicInfoControllerGetJobTitleBasicInfo: build.query<
      JobPostTitleBasicInfoControllerGetJobTitleBasicInfoApiResponse,
      JobPostTitleBasicInfoControllerGetJobTitleBasicInfoApiArg
    >({
      query: (queryArg) => ({
        url: `/thor/v2/crm/job-seekers/posts/job-title/basic-info`,
        params: {
          page: queryArg.page,
          size: queryArg.size,
          limit: queryArg.limit,
          offset: queryArg.offset,
          jobTitle: queryArg.jobTitle,
        },
      }),
    }),
    campaignControllerCreateOne: build.mutation<
      CampaignControllerCreateOneApiResponse,
      CampaignControllerCreateOneApiArg
    >({
      query: (queryArg) => ({
        url: `/thor/v2/crm/job-seekers/campaigns`,
        method: 'POST',
        body: queryArg.createCampaignDto,
      }),
    }),
    campaignControllerGetCampaigns: build.query<
      CampaignControllerGetCampaignsApiResponse,
      CampaignControllerGetCampaignsApiArg
    >({
      query: (queryArg) => ({
        url: `/thor/v2/crm/job-seekers/campaigns`,
        params: {
          page: queryArg.page,
          size: queryArg.size,
          limit: queryArg.limit,
          offset: queryArg.offset,
          name: queryArg.name,
        },
      }),
    }),
    campaignControllerEditOne: build.mutation<
      CampaignControllerEditOneApiResponse,
      CampaignControllerEditOneApiArg
    >({
      query: (queryArg) => ({
        url: `/thor/v2/crm/job-seekers/campaigns`,
        method: 'PUT',
        body: queryArg.createCampaignDto,
        params: { campaignId: queryArg.campaignId },
      }),
    }),
    campaignControllerGetCampaignsBasicInfo: build.query<
      CampaignControllerGetCampaignsBasicInfoApiResponse,
      CampaignControllerGetCampaignsBasicInfoApiArg
    >({
      query: (queryArg) => ({
        url: `/thor/v2/crm/job-seekers/campaigns/basic-info`,
        params: {
          page: queryArg.page,
          size: queryArg.size,
          limit: queryArg.limit,
          offset: queryArg.offset,
          name: queryArg.name,
        },
      }),
    }),
    jobChatboxControllerGetOwnedChatboxes: build.query<
      JobChatboxControllerGetOwnedChatboxesApiResponse,
      JobChatboxControllerGetOwnedChatboxesApiArg
    >({
      query: (queryArg) => ({
        url: `/thor/v2/crm/job-seekers/chatboxes`,
        params: {
          page: queryArg.page,
          size: queryArg.size,
          limit: queryArg.limit,
          offset: queryArg.offset,
          status: queryArg.status,
          fromTime: queryArg.fromTime,
          toTime: queryArg.toTime,
          timeZone: queryArg.timeZone,
          text: queryArg.text,
        },
      }),
    }),
    jobChatboxControllerArchiveOwnedChatbox: build.mutation<
      JobChatboxControllerArchiveOwnedChatboxApiResponse,
      JobChatboxControllerArchiveOwnedChatboxApiArg
    >({
      query: () => ({
        url: `/thor/v2/crm/job-seekers/chatboxes`,
        method: 'PUT',
      }),
    }),
    jobChatboxSummaryControllerGetChatboxSummaries: build.query<
      JobChatboxSummaryControllerGetChatboxSummariesApiResponse,
      JobChatboxSummaryControllerGetChatboxSummariesApiArg
    >({
      query: () => ({ url: `/thor/v2/crm/job-seekers/chatboxes/summaries` }),
    }),
    jobChatMessageControllerGetOwnedChatMessages: build.query<
      JobChatMessageControllerGetOwnedChatMessagesApiResponse,
      JobChatMessageControllerGetOwnedChatMessagesApiArg
    >({
      query: (queryArg) => ({
        url: `/thor/v2/crm/job-seekers/chat-messages`,
        params: {
          chatBoxId: queryArg.chatBoxId,
          applicationId: queryArg.applicationId,
          createdAt: queryArg.createdAt,
        },
      }),
    }),
    jobChatMessageControllerSendMessages: build.mutation<
      JobChatMessageControllerSendMessagesApiResponse,
      JobChatMessageControllerSendMessagesApiArg
    >({
      query: (queryArg) => ({
        url: `/thor/v2/crm/job-seekers/chat-messages`,
        method: 'POST',
        body: queryArg.sendMessageDto,
      }),
    }),
    s3PreSignedUrlControllerGetOwnedChatMessages: build.mutation<
      S3PreSignedUrlControllerGetOwnedChatMessagesApiResponse,
      S3PreSignedUrlControllerGetOwnedChatMessagesApiArg
    >({
      query: (queryArg) => ({
        url: `/thor/v2/crm/job-seekers/chat-messages/s3-presigned-url`,
        method: 'POST',
        body: queryArg.getS3PreSignedUrlDto,
      }),
    }),
    basicInfoControllerGetOwnedCompanyBasicInfo: build.query<
      BasicInfoControllerGetOwnedCompanyBasicInfoApiResponse,
      BasicInfoControllerGetOwnedCompanyBasicInfoApiArg
    >({
      query: () => ({ url: `/thor/v2/crm/companies/details/basic-info` }),
    }),
    basicInfoControllerUpdateOwnedCompanyBasicInfo: build.mutation<
      BasicInfoControllerUpdateOwnedCompanyBasicInfoApiResponse,
      BasicInfoControllerUpdateOwnedCompanyBasicInfoApiArg
    >({
      query: () => ({
        url: `/thor/v2/crm/companies/details/basic-info`,
        method: 'PUT',
      }),
    }),
    aboutUsControllerUpdateOwnedCompanyBasicInfo: build.mutation<
      AboutUsControllerUpdateOwnedCompanyBasicInfoApiResponse,
      AboutUsControllerUpdateOwnedCompanyBasicInfoApiArg
    >({
      query: () => ({
        url: `/thor/v2/crm/companies/details/about-us`,
        method: 'PUT',
      }),
    }),
    jobDiscountControllerUpdateOwnedCompanyBasicInfo: build.mutation<
      JobDiscountControllerUpdateOwnedCompanyBasicInfoApiResponse,
      JobDiscountControllerUpdateOwnedCompanyBasicInfoApiArg
    >({
      query: () => ({
        url: `/thor/v2/crm/companies/details/job-discount`,
        method: 'PUT',
      }),
    }),
    companyMediaControllerUpdateOwnedCompanyMedia: build.mutation<
      CompanyMediaControllerUpdateOwnedCompanyMediaApiResponse,
      CompanyMediaControllerUpdateOwnedCompanyMediaApiArg
    >({
      query: (queryArg) => ({
        url: `/thor/v2/crm/companies/details/media`,
        method: 'PUT',
        body: queryArg.updateCompanyBasicInfoDto,
        params: { companyId: queryArg.companyId },
      }),
    }),
    virtualTourCompanyControllerUpsertVirtualTourForCompany: build.mutation<
      VirtualTourCompanyControllerUpsertVirtualTourForCompanyApiResponse,
      VirtualTourCompanyControllerUpsertVirtualTourForCompanyApiArg
    >({
      query: (queryArg) => ({
        url: `/thor/v2/crm/companies/details/virtual-tour`,
        method: 'POST',
        body: queryArg.virtualTourUrlsDto,
        params: { companyId: queryArg.companyId },
      }),
    }),
    virtualTourCompanyControllerGetVirtualTourOfCompany: build.query<
      VirtualTourCompanyControllerGetVirtualTourOfCompanyApiResponse,
      VirtualTourCompanyControllerGetVirtualTourOfCompanyApiArg
    >({
      query: (queryArg) => ({
        url: `/thor/v2/crm/companies/details/virtual-tour`,
        params: { companyId: queryArg.companyId },
      }),
    }),
    adsPostCallingButtonControllerGetAllCallingButton: build.query<
      AdsPostCallingButtonControllerGetAllCallingButtonApiResponse,
      AdsPostCallingButtonControllerGetAllCallingButtonApiArg
    >({
      query: (queryArg) => ({
        url: `/thor/v2/crm/ads/posts/calling-button`,
        params: {
          page: queryArg.page,
          size: queryArg.size,
          limit: queryArg.limit,
          offset: queryArg.offset,
        },
      }),
    }),
    adsPostCallingButtonControllerCreateOneCallingButton: build.mutation<
      AdsPostCallingButtonControllerCreateOneCallingButtonApiResponse,
      AdsPostCallingButtonControllerCreateOneCallingButtonApiArg
    >({
      query: (queryArg) => ({
        url: `/thor/v2/crm/ads/posts/calling-button`,
        method: 'POST',
        body: queryArg.createCallingButtonDto,
      }),
    }),
    adsPostCallingButtonControllerUpdateOneCallingButton: build.mutation<
      AdsPostCallingButtonControllerUpdateOneCallingButtonApiResponse,
      AdsPostCallingButtonControllerUpdateOneCallingButtonApiArg
    >({
      query: (queryArg) => ({
        url: `/thor/v2/crm/ads/posts/calling-button`,
        method: 'PUT',
        body: queryArg.updateCallingButtonDto,
        params: { callingButtonId: queryArg.callingButtonId },
      }),
    }),
    adsPostCallingButtonControllerDeleteCallingButton: build.mutation<
      AdsPostCallingButtonControllerDeleteCallingButtonApiResponse,
      AdsPostCallingButtonControllerDeleteCallingButtonApiArg
    >({
      query: (queryArg) => ({
        url: `/thor/v2/crm/ads/posts/calling-button`,
        method: 'DELETE',
        params: { callingButtonId: queryArg.callingButtonId },
      }),
    }),
    adsPostCallingButtonControllerGetNumberOfAdsUsed: build.query<
      AdsPostCallingButtonControllerGetNumberOfAdsUsedApiResponse,
      AdsPostCallingButtonControllerGetNumberOfAdsUsedApiArg
    >({
      query: (queryArg) => ({
        url: `/thor/v2/crm/ads/posts/calling-button/post-count`,
        params: { callingButtonId: queryArg.callingButtonId },
      }),
    }),
    closeAdsPostControllerCloseAdsPost: build.mutation<
      CloseAdsPostControllerCloseAdsPostApiResponse,
      CloseAdsPostControllerCloseAdsPostApiArg
    >({
      query: (queryArg) => ({
        url: `/thor/v2/crm/ads/posts/close`,
        method: 'PUT',
        params: { adsPostId: queryArg.adsPostId },
      }),
    }),
    adsFormConfigControllerGetFormTemplateConfig: build.query<
      AdsFormConfigControllerGetFormTemplateConfigApiResponse,
      AdsFormConfigControllerGetFormTemplateConfigApiArg
    >({
      query: () => ({ url: `/thor/v2/crm/ads/posts/form-config` }),
    }),
    adsPostControllerGetAdsPostList: build.query<
      AdsPostControllerGetAdsPostListApiResponse,
      AdsPostControllerGetAdsPostListApiArg
    >({
      query: (queryArg) => ({
        url: `/thor/v2/crm/ads/posts`,
        params: {
          page: queryArg.page,
          size: queryArg.size,
          limit: queryArg.limit,
          offset: queryArg.offset,
        },
      }),
    }),
    adsPostControllerCreateAdsTemplates: build.mutation<
      AdsPostControllerCreateAdsTemplatesApiResponse,
      AdsPostControllerCreateAdsTemplatesApiArg
    >({
      query: (queryArg) => ({
        url: `/thor/v2/crm/ads/posts`,
        method: 'POST',
        body: queryArg.createAdsDto,
      }),
    }),
    adsPostControllerUpdateAdsTemplates: build.mutation<
      AdsPostControllerUpdateAdsTemplatesApiResponse,
      AdsPostControllerUpdateAdsTemplatesApiArg
    >({
      query: (queryArg) => ({
        url: `/thor/v2/crm/ads/posts`,
        method: 'PUT',
        body: queryArg.updateAdsDto,
        params: { adsPostId: queryArg.adsPostId },
      }),
    }),
    adsPostControllerDeleteAdsTemplates: build.mutation<
      AdsPostControllerDeleteAdsTemplatesApiResponse,
      AdsPostControllerDeleteAdsTemplatesApiArg
    >({
      query: (queryArg) => ({
        url: `/thor/v2/crm/ads/posts`,
        method: 'DELETE',
        params: { adsPostId: queryArg.adsPostId },
      }),
    }),
    s3PreSignedUrlControllerGetS3PreSignedUrlForUploadAdsMedia: build.mutation<
      S3PreSignedUrlControllerGetS3PreSignedUrlForUploadAdsMediaApiResponse,
      S3PreSignedUrlControllerGetS3PreSignedUrlForUploadAdsMediaApiArg
    >({
      query: (queryArg) => ({
        url: `/thor/v2/crm/ads/posts/s3-presigned-url`,
        method: 'POST',
        body: queryArg.getS3PreSignedUrlDto,
      }),
    }),
    adsPostTargetedAudienceControllerGetAllTargetedAudiences: build.query<
      AdsPostTargetedAudienceControllerGetAllTargetedAudiencesApiResponse,
      AdsPostTargetedAudienceControllerGetAllTargetedAudiencesApiArg
    >({
      query: (queryArg) => ({
        url: `/thor/v2/crm/ads/posts/targeted-audiences`,
        params: {
          page: queryArg.page,
          size: queryArg.size,
          limit: queryArg.limit,
          offset: queryArg.offset,
        },
      }),
    }),
    adsPostTargetedAudienceControllerUpdateTargetedAudience: build.mutation<
      AdsPostTargetedAudienceControllerUpdateTargetedAudienceApiResponse,
      AdsPostTargetedAudienceControllerUpdateTargetedAudienceApiArg
    >({
      query: (queryArg) => ({
        url: `/thor/v2/crm/ads/posts/targeted-audiences`,
        method: 'PUT',
        body: queryArg.updateTargetedAudienceDto,
        params: { targetedAudienceId: queryArg.targetedAudienceId },
      }),
    }),
    adsPostTargetedAudienceControllerCreateOneTargetedAudience: build.mutation<
      AdsPostTargetedAudienceControllerCreateOneTargetedAudienceApiResponse,
      AdsPostTargetedAudienceControllerCreateOneTargetedAudienceApiArg
    >({
      query: (queryArg) => ({
        url: `/thor/v2/crm/ads/posts/targeted-audiences`,
        method: 'POST',
        body: queryArg.createTargetedAudienceDto,
      }),
    }),
    adsPostTargetedAudienceControllerDeleteTargetedAudience: build.mutation<
      AdsPostTargetedAudienceControllerDeleteTargetedAudienceApiResponse,
      AdsPostTargetedAudienceControllerDeleteTargetedAudienceApiArg
    >({
      query: (queryArg) => ({
        url: `/thor/v2/crm/ads/posts/targeted-audiences`,
        method: 'DELETE',
        params: { targetedAudienceId: queryArg.targetedAudienceId },
      }),
    }),
    adsPostTargetedAudienceControllerGetNumberOfAdsUsed: build.query<
      AdsPostTargetedAudienceControllerGetNumberOfAdsUsedApiResponse,
      AdsPostTargetedAudienceControllerGetNumberOfAdsUsedApiArg
    >({
      query: (queryArg) => ({
        url: `/thor/v2/crm/ads/posts/targeted-audiences/post-count`,
        params: { targetedAudienceId: queryArg.targetedAudienceId },
      }),
    }),
    adsPostTitleControllerGetAdsPostTitles: build.query<
      AdsPostTitleControllerGetAdsPostTitlesApiResponse,
      AdsPostTitleControllerGetAdsPostTitlesApiArg
    >({
      query: (queryArg) => ({
        url: `/thor/v2/crm/ads/posts/title`,
        params: {
          page: queryArg.page,
          size: queryArg.size,
          limit: queryArg.limit,
          offset: queryArg.offset,
        },
      }),
    }),
    adsPostDetailControllerGetAdsPost: build.query<
      AdsPostDetailControllerGetAdsPostApiResponse,
      AdsPostDetailControllerGetAdsPostApiArg
    >({
      query: (queryArg) => ({
        url: `/thor/v2/crm/ads/posts/details`,
        params: { postId: queryArg.postId },
      }),
    }),
    lgaControllerGetLga: build.query<
      LgaControllerGetLgaApiResponse,
      LgaControllerGetLgaApiArg
    >({
      query: (queryArg) => ({
        url: `/thor/v2/crm/area/lga`,
        params: {
          page: queryArg.page,
          size: queryArg.size,
          limit: queryArg.limit,
          offset: queryArg.offset,
          searchKey: queryArg.searchKey,
        },
      }),
    }),
    postcodeControllerGetPostcode: build.query<
      PostcodeControllerGetPostcodeApiResponse,
      PostcodeControllerGetPostcodeApiArg
    >({
      query: (queryArg) => ({
        url: `/thor/v2/crm/area/postcode`,
        params: {
          page: queryArg.page,
          size: queryArg.size,
          limit: queryArg.limit,
          offset: queryArg.offset,
          searchKey: queryArg.searchKey,
        },
      }),
    }),
    stateControllerGetState: build.query<
      StateControllerGetStateApiResponse,
      StateControllerGetStateApiArg
    >({
      query: (queryArg) => ({
        url: `/thor/v2/crm/area/state`,
        params: {
          page: queryArg.page,
          size: queryArg.size,
          limit: queryArg.limit,
          offset: queryArg.offset,
          searchKey: queryArg.searchKey,
        },
      }),
    }),
    suburbControllerGetSuburb: build.query<
      SuburbControllerGetSuburbApiResponse,
      SuburbControllerGetSuburbApiArg
    >({
      query: (queryArg) => ({
        url: `/thor/v2/crm/area/suburb`,
        params: {
          page: queryArg.page,
          size: queryArg.size,
          limit: queryArg.limit,
          offset: queryArg.offset,
          searchKey: queryArg.searchKey,
        },
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as enhancedApi };
export type CentreServiceControllerGetManyApiResponse =
  /** status 200  */ CentreServiceResp;
export type CentreServiceControllerGetManyApiArg = {
  page?: number;
  size?: number;
  limit?: number;
  offset?: number;
  sortKey?:
    | 'ageGroup'
    | 'capacity'
    | 'costPerDay'
    | 'serviceType'
    | 'roomName'
    | 'sessionType'
    | 'isActive'
    | 'vacancyCod'
    | 'program';
  sortOrder?: 'ASC' | 'DESC';
  centreId: string;
};
export type CentreServiceControllerCreateOneApiResponse =
  /** status 201  */ CentreService;
export type CentreServiceControllerCreateOneApiArg = {
  createCentreServiceDto: CreateCentreServiceDto;
};
export type CentreServiceControllerUpdateOneApiResponse =
  /** status 201  */ CentreService;
export type CentreServiceControllerUpdateOneApiArg = {
  serviceId: string;
  updateCentreServiceDto: UpdateCentreServiceDto;
};
export type CentreServiceControllerDeleteOneApiResponse =
  /** status 201  */ CentreService;
export type CentreServiceControllerDeleteOneApiArg = {
  serviceId: string;
};
export type CentreFeaturesControllerGetApiResponse =
  /** status 201  */ ICentreFeatureCategoriesResp;
export type CentreFeaturesControllerGetApiArg = {
  centreId: string;
  centreSlug: string;
};
export type CentreFeaturesControllerPutApiResponse = unknown;
export type CentreFeaturesControllerPutApiArg = {
  updateCentreFeatureDto: UpdateCentreFeatureDto;
};
export type EducatorProfileControllerGetEducatorProfilesApiResponse = unknown;
export type EducatorProfileControllerGetEducatorProfilesApiArg = {
  page?: number;
  size?: number;
  limit?: number;
  offset?: number;
  centreId: string;
  name?: string;
  isShowThisProfile?: string;
  jobTitle?: string;
  jobRole?: string;
  qualification?: string;
  isDraft: boolean;
};
export type EducatorProfileControllerCreateEducatorProfileApiResponse = unknown;
export type EducatorProfileControllerCreateEducatorProfileApiArg = {
  createEducatorDto: CreateEducatorDto;
};
export type EducatorProfileControllerUpdateEducatorProfileApiResponse = unknown;
export type EducatorProfileControllerUpdateEducatorProfileApiArg = {
  id: string;
  updateEducatorDto: UpdateEducatorDto;
};
export type EducatorProfileControllerDeleteEducatorProfileApiResponse = unknown;
export type EducatorProfileControllerDeleteEducatorProfileApiArg = {
  id: string;
};
export type EducatorProfileControllerGetEducatorProfileApiResponse = unknown;
export type EducatorProfileControllerGetEducatorProfileApiArg = {
  id: string;
};
export type EducatorProfileControllerSuggestionApiResponse = unknown;
export type EducatorProfileControllerSuggestionApiArg = {
  page?: number;
  size?: number;
  limit?: number;
  offset?: number;
  centreId: string;
  isNameSuggestion: boolean;
  isJobTitleSuggestion: boolean;
  name?: string;
  jobTitle?: string;
};
export type EducatorProfileControllerGetConfigsApiResponse = unknown;
export type EducatorProfileControllerGetConfigsApiArg = void;
export type EducatorProfileControllerGetCentreUsersApiResponse = unknown;
export type EducatorProfileControllerGetCentreUsersApiArg = {
  page?: number;
  size?: number;
  limit?: number;
  offset?: number;
  centreId: string;
  name?: string;
  jobTitle?: string;
};
export type ContactApplicationControllerGetApplicationListApiResponse = unknown;
export type ContactApplicationControllerGetApplicationListApiArg = {
  page?: number;
  size?: number;
  limit?: number;
  offset?: number;
  contactId: string;
  sorts: string[];
  centreId: string;
};
export type CrmContactsConfigControllerGetCrmContactConfigApiResponse =
  /** status 201  */ IContactConfigResponse;
export type CrmContactsConfigControllerGetCrmContactConfigApiArg = void;
export type ContactEnquiriesControllerGetEnquiriesListApiResponse = unknown;
export type ContactEnquiriesControllerGetEnquiriesListApiArg = {
  page?: number;
  size?: number;
  limit?: number;
  offset?: number;
  contactId: string;
  sorts: string[];
  centreId: string;
};
export type ContactsLeadActivitiesControllerGetTableDataForCentreApiResponse =
  unknown;
export type ContactsLeadActivitiesControllerGetTableDataForCentreApiArg = {
  page?: number;
  size?: number;
  limit?: number;
  offset?: number;
  contactId: string;
  sorts: string[];
  channel?: 'App' | 'Web' | 'All';
  loginStatus?: 'logged' | 'non';
  userId?: string;
  userName?: string;
  userEmail?: string;
  userPhone?: string;
  types: any;
  centreName?: string;
  centreId?: string;
  providerName?: string;
  state?: string;
  brandName?: string;
  clickThroughDate?: ClickThroughDate;
  subscription?: string;
  prNumber?: string;
  seNumber?: string;
};
export type CheckIfApplicationExistsControllerCheckIfApplicationExistsApiResponse =
  /** status 200  */ IsJobApplicationExistsResponse;
export type CheckIfApplicationExistsControllerCheckIfApplicationExistsApiArg = {
  applicationId: string;
};
export type LeadActivitiesControllerGetTableDataForCentreApiResponse = unknown;
export type LeadActivitiesControllerGetTableDataForCentreApiArg = {
  page?: number;
  size?: number;
  limit?: number;
  offset?: number;
  sorts: string[];
  channel?: 'App' | 'Web' | 'All';
  loginStatus?: 'logged' | 'non';
  userId?: string;
  userName?: string;
  userEmail?: string;
  userPhone?: string;
  types: any;
  centreName?: string;
  centreId?: string;
  providerName?: string;
  state?: string;
  brandName?: string;
  clickThroughDate?: ClickThroughDate;
  subscription?: string;
  prNumber?: string;
  seNumber?: string;
};
export type LeadActivitiesConfigControllerGetConfigLeadActivitiesApiResponse =
  unknown;
export type LeadActivitiesConfigControllerGetConfigLeadActivitiesApiArg = void;
export type LaConfigPrNumberControllerGetUserNameConfigApiResponse = unknown;
export type LaConfigPrNumberControllerGetUserNameConfigApiArg = {
  page?: number;
  size?: number;
  limit?: number;
  offset?: number;
  sorts: string[];
  channel?: 'App' | 'Web' | 'All';
  loginStatus?: 'logged' | 'non';
  userId?: string;
  userName?: string;
  userEmail?: string;
  userPhone?: string;
  types: any;
  centreName?: string;
  centreId?: string;
  providerName?: string;
  state?: string;
  brandName?: string;
  clickThroughDate?: ClickThroughDate;
  subscription?: string;
  prNumber?: string;
  seNumber?: string;
};
export type LaConfigSeNumberControllerGetUserNameConfigApiResponse = unknown;
export type LaConfigSeNumberControllerGetUserNameConfigApiArg = {
  page?: number;
  size?: number;
  limit?: number;
  offset?: number;
  sorts: string[];
  channel?: 'App' | 'Web' | 'All';
  loginStatus?: 'logged' | 'non';
  userId?: string;
  userName?: string;
  userEmail?: string;
  userPhone?: string;
  types: any;
  centreName?: string;
  centreId?: string;
  providerName?: string;
  state?: string;
  brandName?: string;
  clickThroughDate?: ClickThroughDate;
  subscription?: string;
  prNumber?: string;
  seNumber?: string;
};
export type LaConfigUserEmailControllerGetUserEmailConfigApiResponse = unknown;
export type LaConfigUserEmailControllerGetUserEmailConfigApiArg = {
  page?: number;
  size?: number;
  limit?: number;
  offset?: number;
  sorts: string[];
  channel?: 'App' | 'Web' | 'All';
  loginStatus?: 'logged' | 'non';
  userId?: string;
  userName?: string;
  userEmail?: string;
  userPhone?: string;
  types: any;
  centreName?: string;
  centreId?: string;
  providerName?: string;
  state?: string;
  brandName?: string;
  clickThroughDate?: ClickThroughDate;
  subscription?: string;
  prNumber?: string;
  seNumber?: string;
};
export type LaConfigUserNameControllerGetUserNameConfigApiResponse = unknown;
export type LaConfigUserNameControllerGetUserNameConfigApiArg = {
  page?: number;
  size?: number;
  limit?: number;
  offset?: number;
  sorts: string[];
  channel?: 'App' | 'Web' | 'All';
  loginStatus?: 'logged' | 'non';
  userId?: string;
  userName?: string;
  userEmail?: string;
  userPhone?: string;
  types: any;
  centreName?: string;
  centreId?: string;
  providerName?: string;
  state?: string;
  brandName?: string;
  clickThroughDate?: ClickThroughDate;
  subscription?: string;
  prNumber?: string;
  seNumber?: string;
};
export type LaConfigUserPhoneControllerGetUserPhoneConfigApiResponse = unknown;
export type LaConfigUserPhoneControllerGetUserPhoneConfigApiArg = {
  page?: number;
  size?: number;
  limit?: number;
  offset?: number;
  sorts: string[];
  channel?: 'App' | 'Web' | 'All';
  loginStatus?: 'logged' | 'non';
  userId?: string;
  userName?: string;
  userEmail?: string;
  userPhone?: string;
  types: any;
  centreName?: string;
  centreId?: string;
  providerName?: string;
  state?: string;
  brandName?: string;
  clickThroughDate?: ClickThroughDate;
  subscription?: string;
  prNumber?: string;
  seNumber?: string;
};
export type CrmLeadActivitiesReportControllerRequestReportApiResponse = unknown;
export type CrmLeadActivitiesReportControllerRequestReportApiArg = {
  sortAndFilterLaDto: SortAndFilterLaDto;
};
export type CrmLeadActivitiesReportDetailsControllerGetOneApiResponse = unknown;
export type CrmLeadActivitiesReportDetailsControllerGetOneApiArg = {
  id: string;
};
export type CrmLpMarketingListControllerGetChartDataApiResponse = unknown;
export type CrmLpMarketingListControllerGetChartDataApiArg = {
  page?: number;
  size?: number;
  limit?: number;
  offset?: number;
  channel: 'App' | 'Web' | 'All';
  companyId?: string;
  providerId?: string;
  centreId?: string;
  brandId?: string;
  companyName?: string;
  providerName?: string;
  centreName?: string;
  brandName?: string;
  state?: string;
  primaryChildCareServiceId?: string;
  crmPackageId?: string;
  timeRange?: {
    filteredStart?: string;
    filteredEnd?: string;
    comparedStart?: string;
    comparedEnd?: string;
  };
  chartLines: string[];
  isActive?: boolean;
  chartResolution?: 'Daily' | 'Weekly' | 'Monthly' | 'Quarterly' | 'Yearly';
  timezone?: string;
};
export type CrmLpMarketingChartControllerGetChartDataApiResponse = unknown;
export type CrmLpMarketingChartControllerGetChartDataApiArg = {
  page?: number;
  size?: number;
  limit?: number;
  offset?: number;
  channel: 'App' | 'Web' | 'All';
  companyId?: string;
  providerId?: string;
  centreId?: string;
  brandId?: string;
  companyName?: string;
  providerName?: string;
  centreName?: string;
  brandName?: string;
  state?: string;
  primaryChildCareServiceId?: string;
  crmPackageId?: string;
  timeRange?: {
    filteredStart?: string;
    filteredEnd?: string;
    comparedStart?: string;
    comparedEnd?: string;
  };
  chartLines: string[];
  isActive?: boolean;
  chartResolution?: 'Daily' | 'Weekly' | 'Monthly' | 'Quarterly' | 'Yearly';
  timezone?: string;
};
export type CrmLpMarketingReportControllerGetOneApiResponse = unknown;
export type CrmLpMarketingReportControllerGetOneApiArg = {
  id: string;
};
export type CrmLpMarketingReportControllerRequestReportApiResponse = unknown;
export type CrmLpMarketingReportControllerRequestReportApiArg = {
  getTableCentreKeyMetricDto: GetTableCentreKeyMetricDto;
};
export type CrmLpMarketingSummaryControllerGetChartDataApiResponse = unknown;
export type CrmLpMarketingSummaryControllerGetChartDataApiArg = {
  page?: number;
  size?: number;
  limit?: number;
  offset?: number;
  channel: 'App' | 'Web' | 'All';
  companyId?: string;
  providerId?: string;
  centreId?: string;
  brandId?: string;
  companyName?: string;
  providerName?: string;
  centreName?: string;
  brandName?: string;
  state?: string;
  primaryChildCareServiceId?: string;
  crmPackageId?: string;
  timeRange?: {
    filteredStart?: string;
    filteredEnd?: string;
    comparedStart?: string;
    comparedEnd?: string;
  };
  chartLines: string[];
  isActive?: boolean;
  chartResolution?: 'Daily' | 'Weekly' | 'Monthly' | 'Quarterly' | 'Yearly';
  timezone?: string;
};
export type NewFeatureControllerGetNewFeatureForCrmApiResponse =
  /** status 200  */ ShowNewFeatureRespDto;
export type NewFeatureControllerGetNewFeatureForCrmApiArg = {
  isImpersonate: boolean;
};
export type NewFeatureControllerSetNewFeatureForCrmApiResponse =
  /** status 200  */ boolean;
export type NewFeatureControllerSetNewFeatureForCrmApiArg = {
  showNewFeatureBodyDto: ShowNewFeatureBodyDto;
};
export type ParentApplicationConfigControllerGetArchivedReasonConfigApiResponse =
  /** status 201  */ IParentApplicationArchivedResp;
export type ParentApplicationConfigControllerGetArchivedReasonConfigApiArg =
  void;
export type ProgramNameControllerGetProgramNameApiResponse =
  /** status 201  */ IProgramConfigResponse;
export type ProgramNameControllerGetProgramNameApiArg = {
  page?: number;
  size?: number;
  limit?: number;
  offset?: number;
  filter?: {
    programName?: string;
  };
};
export type ConfirmProgramControllerGetConfirmProgramApiResponse =
  /** status 201  */ IConfirmProgramResponse;
export type ConfirmProgramControllerGetConfirmProgramApiArg = {
  programId: string;
  updateProgramDto: UpdateProgramDto;
};
export type DetailProgramControllerGetDetailProgramApiResponse =
  /** status 201  */ IProgramResponse;
export type DetailProgramControllerGetDetailProgramApiArg = {
  programId: string;
};
export type ProgramPermissionControllerGetProgramPermissionApiResponse =
  /** status 201  */ IProgramPermissionResponse;
export type ProgramPermissionControllerGetProgramPermissionApiArg = void;
export type PermittedUsersControllerGetPermittedUsersApiResponse =
  /** status 200  */ PermittedUserDtoResp;
export type PermittedUsersControllerGetPermittedUsersApiArg = {
  page?: number;
  size?: number;
  limit?: number;
  offset?: number;
  centreId: string;
};
export type ProgramsControllerCreateOneProgramApiResponse =
  /** status 201  */ IProgramResponse;
export type ProgramsControllerCreateOneProgramApiArg = {
  createProgramDto: CreateProgramDto;
};
export type ProgramsControllerUpdateOneProgramApiResponse =
  /** status 201  */ IProgramResponse;
export type ProgramsControllerUpdateOneProgramApiArg = {
  programId: string;
  updateProgramDto: UpdateProgramDto;
};
export type ProgramsControllerGetProgramListApiResponse =
  /** status 201  */ IProgramListResponse;
export type ProgramsControllerGetProgramListApiArg = {
  page?: number;
  size?: number;
  limit?: number;
  offset?: number;
  filter?: {
    programName?: string;
  };
  programName?: string;
  serviceTypeName?: string;
  serviceTypeId?: string;
  ageGroupName?: string;
  ageGroupId?: string;
  sessionTypeName?: string;
  sessionTypeId?: string;
  centreName?: string;
  providerName?: string;
  brandName?: string;
  state?: string;
  createdAt?: {
    from?: string;
    to?: string;
  };
  updatedAt?: {
    from?: string;
    to?: string;
  };
};
export type ProgramsControllerDeleteOneProgramApiResponse =
  /** status 201  */ IProgramResponse;
export type ProgramsControllerDeleteOneProgramApiArg = {
  programId: string;
};
export type ResourcesControllerGetAllApiResponse = unknown;
export type ResourcesControllerGetAllApiArg = {
  page?: number;
  size?: number;
  limit?: number;
  offset?: number;
  sortKey: any;
  sortOrder: string;
};
export type ResourcesControllerDeleteOneApiResponse = unknown;
export type ResourcesControllerDeleteOneApiArg = {
  id: string;
};
export type CrmConfigControllerGetSummaryJobListingsApiResponse = unknown;
export type CrmConfigControllerGetSummaryJobListingsApiArg = void;
export type CrmConfigControllerGetPageEulaApiResponse = unknown;
export type CrmConfigControllerGetPageEulaApiArg = void;
export type CentreControllerFindOneApiResponse = unknown;
export type CentreControllerFindOneApiArg = {
  id: string;
};
export type RatingSummaryControllerGetRatingSummaryApiResponse = unknown;
export type RatingSummaryControllerGetRatingSummaryApiArg = {
  centreId: string;
};
export type BasicInfoControllerUpdateBasicInfoApiResponse = unknown;
export type BasicInfoControllerUpdateBasicInfoApiArg = {
  centreId: string;
  /** Authorization */
  authorization?: string;
  /** centreId */
  centreid?: string;
  /** centreIds */
  centreids?: string;
  updateBasicInfoDto: UpdateBasicInfoDto;
};
export type VirtualTourCenTreControllerUpsertVirtualTourForCentreApiResponse =
  /** status 200 Virtual Tour Results */ VirtualTourCentreResponse;
export type VirtualTourCenTreControllerUpsertVirtualTourForCentreApiArg = {
  centreId: string;
  virtualTourUrlsDto: VirtualTourUrlsDto;
};
export type VirtualTourCenTreControllerGetVirtualTourOfCentreApiResponse =
  /** status 200 Get Virtual Tour Of Centre */ IVirtualTourResponse;
export type VirtualTourCenTreControllerGetVirtualTourOfCentreApiArg = {
  centreId: string;
};
export type JobListingControllerCreateOneApiResponse = unknown;
export type JobListingControllerCreateOneApiArg = {
  createJobListingDto: CreateJobListingDto;
};
export type JobListingControllerUpdateOneApiResponse = unknown;
export type JobListingControllerUpdateOneApiArg = {
  postId: string;
  updateJobListingDto: UpdateJobListingDto;
};
export type JobListingControllerDeleteManyApiResponse = unknown;
export type JobListingControllerDeleteManyApiArg = {
  postIds: string[];
};
export type JobListingControllerGetJobListingsApiResponse = unknown;
export type JobListingControllerGetJobListingsApiArg = {
  page?: number;
  size?: number;
  limit?: number;
  offset?: number;
  jobReference?: string;
  campaign?: string;
  templateId?: string;
  campaignId?: string;
  jobRoleId?: string;
  jobTemplate?: string;
  templateName?: string;
  name?: string;
  workplaceType?: 'centre' | 'company';
  workplace?: string;
  jobTypeId?: string;
  status?: string;
  jobListingStatus?: string;
  brandId?: string;
  state?: string;
  timeDuration?: TimeDuration;
  createdAt?: TimeDuration;
  updatedAt?: TimeDuration;
  lastUpdatedAt?: TimeDuration;
  jobTitle?: string;
  sorts: string[];
};
export type JobListingControllerBulkActionApiResponse = unknown;
export type JobListingControllerBulkActionApiArg = {
  bulkActionDto: BulkActionDto;
};
export type JobListingControllerUpdateManyApiResponse = unknown;
export type JobListingControllerUpdateManyApiArg = {
  moveToCampaignBodyDto: MoveToCampaignBodyDto;
};
export type JobListingDetailsControllerGetTemplateDetailsApiResponse = unknown;
export type JobListingDetailsControllerGetTemplateDetailsApiArg = {
  postId: string;
};
export type JobReferenceExistentControllerCheckJobReferenceListExistsApiResponse =
  unknown;
export type JobReferenceExistentControllerCheckJobReferenceListExistsApiArg = {
  organizations: string[];
};
export type SummaryJobListingControllerGetSummaryJobListingsApiResponse =
  unknown;
export type SummaryJobListingControllerGetSummaryJobListingsApiArg = {
  page?: number;
  size?: number;
  limit?: number;
  offset?: number;
  jobReference?: string;
  campaign?: string;
  templateId?: string;
  campaignId?: string;
  jobRoleId?: string;
  jobTemplate?: string;
  templateName?: string;
  name?: string;
  workplaceType?: 'centre' | 'company';
  workplace?: string;
  jobTypeId?: string;
  status?: string;
  jobListingStatus?: string;
  brandId?: string;
  state?: string;
  timeDuration?: TimeDuration;
  createdAt?: TimeDuration;
  updatedAt?: TimeDuration;
  lastUpdatedAt?: TimeDuration;
  jobTitle?: string;
  sorts: string[];
};
export type WorkplaceControllerGetJobOrganizationsByIdsApiResponse = unknown;
export type WorkplaceControllerGetJobOrganizationsByIdsApiArg = {
  organizationIds: string[];
};
export type WorkplaceControllerGetWorkplaceListApiResponse = unknown;
export type WorkplaceControllerGetWorkplaceListApiArg = {
  page?: number;
  size?: number;
  limit?: number;
  offset?: number;
  companyId?: string;
  brandId?: string;
  centre?: string;
  seNumber?: string;
  state?: string;
  suburb?: string;
  postCode?: string;
  workplaceType?: string;
  inIds?: any;
  notInIds?: any;
};
export type WorkplaceControllerGetWorkplacesBasicInfoApiResponse = unknown;
export type WorkplaceControllerGetWorkplacesBasicInfoApiArg = {
  page?: number;
  size?: number;
  limit?: number;
  offset?: number;
  workplaceName: string;
};
export type JobListingCancelControllerCancelOneApiResponse = unknown;
export type JobListingCancelControllerCancelOneApiArg = {
  id: string;
};
export type JobPostTemplateConfigControllerGetFormTemplateConfigApiResponse =
  unknown;
export type JobPostTemplateConfigControllerGetFormTemplateConfigApiArg = void;
export type JobPostTemplateControllerGetJobTemplatesApiResponse = unknown;
export type JobPostTemplateControllerGetJobTemplatesApiArg = {
  page?: number;
  size?: number;
  limit?: number;
  offset?: number;
  sorts: string[];
};
export type JobPostTemplateControllerCreateJobTemplatesApiResponse = unknown;
export type JobPostTemplateControllerCreateJobTemplatesApiArg = {
  createJobTemplateDto: CreateJobTemplateDto;
};
export type JobPostTemplateControllerUpdateJobTemplatesApiResponse = unknown;
export type JobPostTemplateControllerUpdateJobTemplatesApiArg = {
  templateId: string;
  updateJobTemplateDto: UpdateJobTemplateDto;
};
export type JobTemplateFilterConfigControllerGetFilterConfigApiResponse =
  unknown;
export type JobTemplateFilterConfigControllerGetFilterConfigApiArg = void;
export type JobTemplateDetailsControllerGetTemplateDetailsApiResponse = unknown;
export type JobTemplateDetailsControllerGetTemplateDetailsApiArg = {
  templateId: string;
};
export type JobPostTemplateBasicInfoControllerGetJobTemplatesBasicInfoApiResponse =
  unknown;
export type JobPostTemplateBasicInfoControllerGetJobTemplatesBasicInfoApiArg = {
  page?: number;
  size?: number;
  limit?: number;
  offset?: number;
  templateName: string;
};
export type JobApplicationControllerGetAllJobApplicationsApiResponse = unknown;
export type JobApplicationControllerGetAllJobApplicationsApiArg = {
  page?: number;
  size?: number;
  limit?: number;
  offset?: number;
  sorts: string[];
};
export type JobApplicationDetailControllerGetProfileSeekerApiResponse = unknown;
export type JobApplicationDetailControllerGetProfileSeekerApiArg = {
  id: string;
};
export type SummanyInfoControllerGetInfoSummaryApiResponse = unknown;
export type SummanyInfoControllerGetInfoSummaryApiArg = {
  id: string;
};
export type MailBoxControllerGetMessageMailBoxApplicationApiResponse = unknown;
export type MailBoxControllerGetMessageMailBoxApplicationApiArg = {
  page?: number;
  size?: number;
  limit?: number;
  offset?: number;
  applicationId: string;
};
export type ChangeStatusControllerChangeStatusJobApplicationApiResponse =
  unknown;
export type ChangeStatusControllerChangeStatusJobApplicationApiArg = {
  applicationId: string;
  statusId: string;
  declinedReasonId?: string;
  currentStatus: string;
};
export type DocumentControllerGetDocumentOfSeekerInMailBoxApiResponse = unknown;
export type DocumentControllerGetDocumentOfSeekerInMailBoxApiArg = {
  page?: number;
  size?: number;
  limit?: number;
  offset?: number;
  applicationId: string;
};
export type JobApplicationSummaryControllerGetSummaryJobApplicationsApiResponse =
  unknown;
export type JobApplicationSummaryControllerGetSummaryJobApplicationsApiArg = {
  page?: number;
  size?: number;
  limit?: number;
  offset?: number;
};
export type TotalSubmittedJobApplicationControllerGetTotalSubmittedJobApplicationsApiResponse =
  unknown;
export type TotalSubmittedJobApplicationControllerGetTotalSubmittedJobApplicationsApiArg =
  void;
export type ActivityNoteControllerCreateActivityApiResponse = unknown;
export type ActivityNoteControllerCreateActivityApiArg = {
  applicationActivity: ApplicationActivity;
};
export type ActivityNoteControllerGetAllActivityNoteApiResponse = unknown;
export type ActivityNoteControllerGetAllActivityNoteApiArg = {
  page?: number;
  size?: number;
  limit?: number;
  offset?: number;
};
export type ActivityNoteDetailControllerGetOneApiResponse = unknown;
export type ActivityNoteDetailControllerGetOneApiArg = {
  id: string;
};
export type ApplicantBasicInfoControllerGetAllApplicantsBasicInfoApiResponse =
  unknown;
export type ApplicantBasicInfoControllerGetAllApplicantsBasicInfoApiArg = {
  page?: number;
  size?: number;
  limit?: number;
  offset?: number;
};
export type JobReferenceBasicInfoControllerGetJobReferencesBasicInfoApiResponse =
  unknown;
export type JobReferenceBasicInfoControllerGetJobReferencesBasicInfoApiArg = {
  page?: number;
  size?: number;
  limit?: number;
  offset?: number;
};
export type JobPostTitleBasicInfoControllerGetJobTitleBasicInfoApiResponse =
  unknown;
export type JobPostTitleBasicInfoControllerGetJobTitleBasicInfoApiArg = {
  page?: number;
  size?: number;
  limit?: number;
  offset?: number;
  jobTitle: string;
};
export type CampaignControllerCreateOneApiResponse = unknown;
export type CampaignControllerCreateOneApiArg = {
  createCampaignDto: CreateCampaignDto;
};
export type CampaignControllerGetCampaignsApiResponse = unknown;
export type CampaignControllerGetCampaignsApiArg = {
  page?: number;
  size?: number;
  limit?: number;
  offset?: number;
  name: string;
};
export type CampaignControllerEditOneApiResponse = unknown;
export type CampaignControllerEditOneApiArg = {
  campaignId: string;
  createCampaignDto: CreateCampaignDto;
};
export type CampaignControllerGetCampaignsBasicInfoApiResponse = unknown;
export type CampaignControllerGetCampaignsBasicInfoApiArg = {
  page?: number;
  size?: number;
  limit?: number;
  offset?: number;
  name: string;
};
export type JobChatboxControllerGetOwnedChatboxesApiResponse = unknown;
export type JobChatboxControllerGetOwnedChatboxesApiArg = {
  page?: number;
  size?: number;
  limit?: number;
  offset?: number;
  status?: string;
  fromTime?: string;
  toTime?: string;
  timeZone: string;
  text?: string;
};
export type JobChatboxControllerArchiveOwnedChatboxApiResponse = unknown;
export type JobChatboxControllerArchiveOwnedChatboxApiArg = void;
export type JobChatboxSummaryControllerGetChatboxSummariesApiResponse = unknown;
export type JobChatboxSummaryControllerGetChatboxSummariesApiArg = void;
export type JobChatMessageControllerGetOwnedChatMessagesApiResponse = unknown;
export type JobChatMessageControllerGetOwnedChatMessagesApiArg = {
  chatBoxId?: string;
  applicationId?: string;
  createdAt: string;
};
export type JobChatMessageControllerSendMessagesApiResponse = unknown;
export type JobChatMessageControllerSendMessagesApiArg = {
  sendMessageDto: SendMessageDto;
};
export type S3PreSignedUrlControllerGetOwnedChatMessagesApiResponse = unknown;
export type S3PreSignedUrlControllerGetOwnedChatMessagesApiArg = {
  getS3PreSignedUrlDto: GetS3PreSignedUrlDto;
};
export type BasicInfoControllerGetOwnedCompanyBasicInfoApiResponse = unknown;
export type BasicInfoControllerGetOwnedCompanyBasicInfoApiArg = void;
export type BasicInfoControllerUpdateOwnedCompanyBasicInfoApiResponse = unknown;
export type BasicInfoControllerUpdateOwnedCompanyBasicInfoApiArg = void;
export type AboutUsControllerUpdateOwnedCompanyBasicInfoApiResponse = unknown;
export type AboutUsControllerUpdateOwnedCompanyBasicInfoApiArg = void;
export type JobDiscountControllerUpdateOwnedCompanyBasicInfoApiResponse =
  unknown;
export type JobDiscountControllerUpdateOwnedCompanyBasicInfoApiArg = void;
export type CompanyMediaControllerUpdateOwnedCompanyMediaApiResponse = unknown;
export type CompanyMediaControllerUpdateOwnedCompanyMediaApiArg = {
  companyId: string;
  updateCompanyBasicInfoDto: UpdateCompanyBasicInfoDto;
};
export type VirtualTourCompanyControllerUpsertVirtualTourForCompanyApiResponse =
  /** status 200 Virtual Tour Results */ VirtualTourCompanyResponse;
export type VirtualTourCompanyControllerUpsertVirtualTourForCompanyApiArg = {
  companyId: string;
  virtualTourUrlsDto: VirtualTourUrlsDto;
};
export type VirtualTourCompanyControllerGetVirtualTourOfCompanyApiResponse =
  /** status 200 Get Virtual Tour Of Company */ IVirtualTourResponse;
export type VirtualTourCompanyControllerGetVirtualTourOfCompanyApiArg = {
  companyId: string;
};
export type AdsPostCallingButtonControllerGetAllCallingButtonApiResponse =
  unknown;
export type AdsPostCallingButtonControllerGetAllCallingButtonApiArg = {
  page?: number;
  size?: number;
  limit?: number;
  offset?: number;
};
export type AdsPostCallingButtonControllerCreateOneCallingButtonApiResponse =
  unknown;
export type AdsPostCallingButtonControllerCreateOneCallingButtonApiArg = {
  createCallingButtonDto: CreateCallingButtonDto;
};
export type AdsPostCallingButtonControllerUpdateOneCallingButtonApiResponse =
  unknown;
export type AdsPostCallingButtonControllerUpdateOneCallingButtonApiArg = {
  callingButtonId: string;
  updateCallingButtonDto: UpdateCallingButtonDto;
};
export type AdsPostCallingButtonControllerDeleteCallingButtonApiResponse =
  unknown;
export type AdsPostCallingButtonControllerDeleteCallingButtonApiArg = {
  callingButtonId: string;
};
export type AdsPostCallingButtonControllerGetNumberOfAdsUsedApiResponse =
  unknown;
export type AdsPostCallingButtonControllerGetNumberOfAdsUsedApiArg = {
  callingButtonId: string;
};
export type CloseAdsPostControllerCloseAdsPostApiResponse = unknown;
export type CloseAdsPostControllerCloseAdsPostApiArg = {
  adsPostId: string;
};
export type AdsFormConfigControllerGetFormTemplateConfigApiResponse = unknown;
export type AdsFormConfigControllerGetFormTemplateConfigApiArg = void;
export type AdsPostControllerGetAdsPostListApiResponse = unknown;
export type AdsPostControllerGetAdsPostListApiArg = {
  page?: number;
  size?: number;
  limit?: number;
  offset?: number;
};
export type AdsPostControllerCreateAdsTemplatesApiResponse = unknown;
export type AdsPostControllerCreateAdsTemplatesApiArg = {
  createAdsDto: CreateAdsDto;
};
export type AdsPostControllerUpdateAdsTemplatesApiResponse = unknown;
export type AdsPostControllerUpdateAdsTemplatesApiArg = {
  adsPostId: string;
  updateAdsDto: UpdateAdsDto;
};
export type AdsPostControllerDeleteAdsTemplatesApiResponse = unknown;
export type AdsPostControllerDeleteAdsTemplatesApiArg = {
  adsPostId: string;
};
export type S3PreSignedUrlControllerGetS3PreSignedUrlForUploadAdsMediaApiResponse =
  unknown;
export type S3PreSignedUrlControllerGetS3PreSignedUrlForUploadAdsMediaApiArg = {
  getS3PreSignedUrlDto: GetS3PreSignedUrlDto;
};
export type AdsPostTargetedAudienceControllerGetAllTargetedAudiencesApiResponse =
  unknown;
export type AdsPostTargetedAudienceControllerGetAllTargetedAudiencesApiArg = {
  page?: number;
  size?: number;
  limit?: number;
  offset?: number;
};
export type AdsPostTargetedAudienceControllerUpdateTargetedAudienceApiResponse =
  unknown;
export type AdsPostTargetedAudienceControllerUpdateTargetedAudienceApiArg = {
  targetedAudienceId: string;
  updateTargetedAudienceDto: UpdateTargetedAudienceDto;
};
export type AdsPostTargetedAudienceControllerCreateOneTargetedAudienceApiResponse =
  unknown;
export type AdsPostTargetedAudienceControllerCreateOneTargetedAudienceApiArg = {
  createTargetedAudienceDto: CreateTargetedAudienceDto;
};
export type AdsPostTargetedAudienceControllerDeleteTargetedAudienceApiResponse =
  unknown;
export type AdsPostTargetedAudienceControllerDeleteTargetedAudienceApiArg = {
  targetedAudienceId: string;
};
export type AdsPostTargetedAudienceControllerGetNumberOfAdsUsedApiResponse =
  unknown;
export type AdsPostTargetedAudienceControllerGetNumberOfAdsUsedApiArg = {
  targetedAudienceId: string;
};
export type AdsPostTitleControllerGetAdsPostTitlesApiResponse = unknown;
export type AdsPostTitleControllerGetAdsPostTitlesApiArg = {
  page?: number;
  size?: number;
  limit?: number;
  offset?: number;
};
export type AdsPostDetailControllerGetAdsPostApiResponse = unknown;
export type AdsPostDetailControllerGetAdsPostApiArg = {
  postId: string;
};
export type LgaControllerGetLgaApiResponse = unknown;
export type LgaControllerGetLgaApiArg = {
  page?: number;
  size?: number;
  limit?: number;
  offset?: number;
  searchKey?: string;
};
export type PostcodeControllerGetPostcodeApiResponse = unknown;
export type PostcodeControllerGetPostcodeApiArg = {
  page?: number;
  size?: number;
  limit?: number;
  offset?: number;
  searchKey?: string;
};
export type StateControllerGetStateApiResponse = unknown;
export type StateControllerGetStateApiArg = {
  page?: number;
  size?: number;
  limit?: number;
  offset?: number;
  searchKey?: string;
};
export type SuburbControllerGetSuburbApiResponse = unknown;
export type SuburbControllerGetSuburbApiArg = {
  page?: number;
  size?: number;
  limit?: number;
  offset?: number;
  searchKey?: string;
};
export type SessionTypeView = {
  id?: string;
  name?: string | null;
  type?: object;
  isActive?: boolean | null;
  createdAt?: string | null;
  updatedAt?: string | null;
  order?: number | null;
};
export type ServiceTypeView = {
  id?: string | null;
  externalId?: string | null;
  name?: string | null;
  icon?: string | null;
  description?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
  isActive?: boolean | null;
  sortOrder?: number | null;
  weekPerYear?: number | null;
  hourlyFeeCap?: number | null;
  isShow?: boolean | null;
  primaryChildCareServiceId?: number | null;
};
export type ProgramView = {
  id?: string;
  name?: string | null;
  companyId?: string | null;
  serviceTypeId?: string | null;
  image?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
  providerIds?: object | null;
};
export type AgeGroupView = {
  id?: string;
  name?: string | null;
  type?: object;
  createdAt?: string | null;
  updatedAt?: string | null;
  order?: number | null;
  isActive?: boolean | null;
  textColor?: string | null;
  backgroundColor?: string | null;
};
export type CentreServiceView = {
  id?: string | null;
  centreId?: string;
  serviceTypeId?: string | null;
  centreValueForMoney?: string | null;
  vacancyCod?: boolean | null;
  costPerDay?: number;
  isActive?: boolean | null;
  createdAt?: string | null;
  updatedAt?: string | null;
  score?: number;
  serviceAgeGroupId?: object;
  capacity?: number | null;
  roomName?: object;
  lastUpdated?: string | null;
  cappedFee?: number;
  sessionTypeId?: object;
  programId?: string | null;
  priceLastUpdated?: string | null;
  sessionType?: SessionTypeView;
  serviceType?: ServiceTypeView;
  program?: ProgramView;
  ageGroup?: AgeGroupView | null;
};
export type CentreServiceResp = {
  requestId?: string;
  total: number;
  result?: CentreServiceView[];
};
export type CentreService = {
  id: string | null;
  centreId: string;
  centre: object;
  serviceTypeId: string | null;
  centreValueForMoney: string | null;
  vacancyCod: boolean | null;
  costPerDay: number;
  isActive: boolean | null;
  createdAt: string | null;
  updatedAt: string | null;
  score: number;
  serviceAgeGroupId: object;
  ageGroup?: object | null;
  capacity: number | null;
  roomName: object;
  lastUpdated: string | null;
  cappedFee: number;
  sessionTypeId: object;
  programId?: string | null;
  serviceType: object;
  priceLastUpdated?: string | null;
  sessionType: object;
  program?: object;
  subsidy?: number;
  valueForMoney: number;
};
export type CreateCentreServiceDto = {
  centreId: string;
  roomName?: string;
  capacity?: number;
  serviceTypeId: string;
  serviceAgeGroupId: string;
  sessionTypeId: string;
  programId?: string;
  costPerDay: number;
  vacancyCod?: boolean;
  isActive?: boolean;
};
export type UpdateCentreServiceDto = {
  roomName?: string;
  capacity?: number;
  serviceTypeId?: string;
  serviceAgeGroupId?: string;
  sessionTypeId?: string;
  programId?: string;
  costPerDay?: number;
  vacancyCod?: boolean;
  isActive?: boolean;
};
export type CentreFeatureItem = {
  featureId: string;
  name: string;
  icon: string;
  enable: boolean;
};
export type ICentreFeatureCategoriesItem = {
  categoryId: string;
  name: string;
  icon: string;
  features: CentreFeatureItem;
};
export type ICentreFeatureCategoriesResp = {
  results: ICentreFeatureCategoriesItem;
};
export type UpdateCentreFeatureDto = {
  centreId: string;
  featureId: string;
  enable: boolean;
};
export type BasicInfo = {
  firstName?: string;
  userId?: string | null;
  roleName?: object;
  roleRank?: object;
  lastName?: string;
  jobTitle?: string;
  jobRole?: string;
  jobRankId?: number;
  experience?: string;
  avatar?: string;
};
export type CreateEducatorDto = {
  centreId?: string;
  basicInfo: BasicInfo;
  about?: string;
  educations: string[];
  awards?: string[];
  isDraft: boolean;
  currentStep?: object;
};
export type UpdateEducatorDto = {
  centreId?: string;
  basicInfo: BasicInfo;
  about?: string;
  educations: string[];
  awards?: string[];
  isDraft: boolean;
  currentStep?: object;
  isShowThisProfile?: boolean;
};
export type IContactActivityConfig = {
  value: string;
  text: string;
};
export type IContactConfigResponse = {
  activity: IContactActivityConfig[] | null;
};
export type ClickThroughDate = {
  from: string;
  to: string;
};
export type IsJobApplicationExistsResponse = {
  isExist: boolean;
};
export type FilterLaDto = {
  channel?: 'App' | 'Web' | 'All';
  loginStatus?: 'logged' | 'non';
  userId?: string;
  userName?: string;
  userEmail?: string;
  userPhone?: string;
  types: string[];
  centreName?: string;
  centreId?: string;
  providerName?: string;
  state?: string;
  brandName?: string;
  clickThroughDate?: ClickThroughDate;
  subscription?: string;
  PRNumber?: string;
  SENumber?: string;
};
export type SortAndFilterLaDto = {
  page?: number;
  size?: number;
  limit?: number;
  offset?: number;
  sorts: string[];
  filter: FilterLaDto;
};
export type TimeRangeDto = {
  'timeRange[filteredStart]': string;
  'timeRange[filteredEnd]': string;
  'timeRange[comparedStart]'?: string;
  'timeRange[comparedEnd]'?: string;
};
export type GetTableCentreKeyMetricDto = {
  page?: number;
  size?: number;
  limit?: number;
  offset?: number;
  channel: 'App' | 'Web' | 'All';
  companyId?: string;
  providerId?: string;
  centreId?: string;
  brandId?: string;
  companyName?: string;
  providerName?: string;
  centreName?: string;
  brandName?: string;
  state?: string;
  primaryChildCareServiceId?: string;
  crmPackageId?: string;
  timeRange: TimeRangeDto;
  chartLines: string[];
  isActive?: boolean;
  chartResolution?: 'Daily' | 'Weekly' | 'Monthly' | 'Quarterly' | 'Yearly';
  sortKey: object;
  sortOrder: object;
  timezone?: string;
};
export type ShowNewFeatureItemDto = {
  newBadge?: boolean;
  showModal?: boolean;
};
export type ShowNewFeatureRespDto = {
  programsAndLearning: ShowNewFeatureItemDto;
};
export type ShowNewFeatureBodyDto = {
  name: string;
  showModal: boolean;
  isImpersonate: boolean;
};
export type IParentApplicationArchivedConfig = {
  value: string;
  text: string;
};
export type IParentApplicationArchivedResp = {
  archivedReason: IParentApplicationArchivedConfig[] | null;
};
export type IProgramConfigResponse = {
  results: object[] | null;
  paging: {
    page?: number;
    size?: number;
    total?: number;
  };
};
export type IConfirmProgram = {
  ageGroupChanges?: boolean;
  sessionTypeChanges?: boolean;
  serviceTypeChanges?: boolean;
};
export type IConfirmProgramResponse = {
  result: IConfirmProgram;
  isChanges: boolean;
  misMatch: boolean;
};
export type UpdateProgramDto = {
  name?: string;
  serviceTypeId?: string;
  ageGroupIds?: string[];
  sessionTypeIds?: string[];
  about?: string;
  image?: string;
};
export type ServiceType = {
  id?: string | null;
  externalId?: string | null;
  name?: string | null;
  icon?: string | null;
  description?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
  isActive?: boolean | null;
  sortOrder?: number | null;
  weekPerYear?: number | null;
  hourlyFeeCap?: number | null;
  isShow?: boolean | null;
  primaryChildCareServiceId?: number | null;
};
export type ServiceAgeGroup = {
  type?: string;
  id: string;
  name?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
  order?: number | null;
  isActive?: boolean | null;
  textColor?: string | null;
  backgroundColor?: string | null;
  logo?: string | null;
  services: string[];
  conditionHasServiceAgeGroups?: object;
};
export type SessionType = {
  id: string;
  name?: string | null;
  type?: object;
  isActive?: boolean | null;
  createdAt?: string | null;
  updatedAt?: string | null;
  order?: number | null;
  services?: string[];
  displayName?: object;
};
export type Program = {
  serviceType?: ServiceType | null;
  ageGroup?: ServiceAgeGroup[];
  sessionType?: SessionType[];
  id: string;
  name?: string | null;
  companyId?: string | null;
  serviceTypeId?: string | null;
  image?: string | null;
  about?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
  providerIds?: object | null;
  services?: string[];
};
export type IProgramResponse = {
  result: Program | null;
};
export type IProgramPermission = {
  isCreate: boolean;
  isEdit: boolean;
};
export type IProgramPermissionResponse = {
  result: IProgramPermission;
};
export type PermittedUserDto = {
  id: string;
  name: string;
  avatar: string;
  role: string;
  email: string;
  phone?: string;
};
export type PermittedUserDtoResp = {
  requestId: string;
  result: PermittedUserDto[];
};
export type CreateProgramDto = {
  name: string;
  serviceTypeId: string;
  ageGroupIds: string[];
  sessionTypeIds: string[];
  about: string;
  image: string;
};
export type IProgramListResponse = {
  results: Program[] | null;
  paging: {
    page?: number;
    size?: number;
    total?: number;
  };
};
export type UpdateBasicInfoDto = {
  applicationFeeAmount?: number;
  placeAllowed?: number;
  shortDescription?: string;
};
export type IVirtualTour = {
  value: string;
  isHaveViewFullScreenButton?: object;
  url: string;
  originalUrl: string;
  id: string;
};
export type VirtualTourCentreResponse = {
  results: IVirtualTour[];
  centreId: string;
};
export type VirtualTourUrl = {
  id?: string;
  originalUrl: string;
};
export type VirtualTourUrlsDto = {
  virtualTourUrls: VirtualTourUrl[];
};
export type IVirtualTourResponse = {
  results: string[];
};
export type CreateJobListingDto = {
  templateId: string;
  organizations: string[];
  isSelectAll: boolean;
  startDate?: string;
  isImmediateStart?: boolean;
  endDateDefault?: string;
  endDates?: string[];
  campaignId?: string;
  filter?: object;
};
export type UpdateJobListingDto = {
  campaignId?: string;
  applyEndDate?: string;
  jobReference: string;
};
export type TimeDuration = {
  from?: string;
  to?: string;
};
export type BulkActionDto = {
  action: 'delete';
  data: object;
};
export type MoveToCampaignBodyDto = {
  campaignId?: string;
  postIds: string[];
};
export type JobDiscountDto = {
  typeId: string;
  value: number | null;
};
export type JobSalaryDto = {
  isIncludesCasualLoading?: boolean;
  isIncludesSuper: boolean;
  max?: number;
  min?: number;
  amount?: number;
  payRateId: string;
  payTypeId: string;
};
export type JobRequirementDto = {
  id: string;
  isPreferred: boolean;
  isRequired: boolean;
};
export type JobEligibility = {
  ids: string[];
  isPreferred: boolean;
  isRequired: boolean;
};
export type CreateJobTemplateDto = {
  templateName?: string | null;
  jobTitle?: string | null;
  jobRole?: string | null;
  jobType?: string | null;
  discount?: JobDiscountDto | null;
  salary?: JobSalaryDto;
  experience?: JobRequirementDto | null;
  languages?: object | null;
  qualification?: JobRequirementDto | null;
  eligibility?: JobEligibility | null;
  minJobMatch?: number | null;
  benefits?: object | null;
  aboutTheJob?: string | null;
  aboutCandidate?: string | null;
  candidateResponsibilities?: string | null;
  features?: object | null;
  skills?: object | null;
  certifications?: object | null;
  immunisations?: object | null;
};
export type UpdateJobTemplateDto = {};
export type ApplicationActivity = {
  type: string;
  subject: string;
  date: string;
  note: string;
  applicationId: string;
};
export type CreateCampaignDto = {
  name: string;
};
export type SendMessageDto = {
  chatBoxId: object;
  content: string;
  files: string[];
};
export type GetS3PreSignedUrlDto = {
  chatBoxId: string;
  filename: string;
};
export type UpdateCompanyBasicInfoDto = {
  name?: string | null;
  description?: string | null;
  isActive?: boolean | null;
  website?: string | null;
  logo?: string | null;
  typeId?: string | null;
  featuredPhotoUrl?: string | null;
  isFeatured?: boolean | null;
  geolocation?: object;
  address?: string | null;
  street?: string | null;
  suburb?: string | null;
  postCode?: string | null;
  state?: string | null;
  country?: string | null;
  jobContactPhone?: string | null;
  jobContactEmail?: string | null;
  staffDiscount?: number;
  educatorDiscount?: number;
  mediaList?: object | null;
};
export type VirtualTourCompanyResponse = {
  results: IVirtualTour[];
  companyId: string;
};
export type CreateCallingButtonDto = {
  name: string;
  destinationType: object;
};
export type UpdateCallingButtonDto = {
  name?: string;
  destinationType?: object;
};
export type TargetedAudienceDto = {
  id?: object;
  userType?: object;
  userLocation?: object;
  userLocationValues?: string[];
};
export type DurationDto = {
  from?: string;
  to?: string;
};
export type CreateAdsDto = {
  isDraft?: boolean;
  type?: string | null;
  headline?: string | null;
  description?: string | null;
  callingButton?: string | null;
  destination?: string | null;
  mediaList?: string[];
  isChildcareRelated?: boolean;
  isSocialRelated?: boolean;
  targetedAudience?: TargetedAudienceDto;
  duration?: DurationDto;
};
export type UpdateAdsDto = {};
export type UpdateTargetedAudienceDto = {};
export type CreateTargetedAudienceDto = {
  name: string;
  userType: object;
  userLocation: object;
  userLocationValues?: string[];
};
export const {
  useCentreServiceControllerGetManyQuery,
  useLazyCentreServiceControllerGetManyQuery,
  useCentreServiceControllerCreateOneMutation,
  useCentreServiceControllerUpdateOneMutation,
  useCentreServiceControllerDeleteOneMutation,
  useCentreFeaturesControllerGetQuery,
  useLazyCentreFeaturesControllerGetQuery,
  useCentreFeaturesControllerPutMutation,
  useEducatorProfileControllerGetEducatorProfilesQuery,
  useLazyEducatorProfileControllerGetEducatorProfilesQuery,
  useEducatorProfileControllerCreateEducatorProfileMutation,
  useEducatorProfileControllerUpdateEducatorProfileMutation,
  useEducatorProfileControllerDeleteEducatorProfileMutation,
  useEducatorProfileControllerGetEducatorProfileQuery,
  useLazyEducatorProfileControllerGetEducatorProfileQuery,
  useEducatorProfileControllerSuggestionQuery,
  useLazyEducatorProfileControllerSuggestionQuery,
  useEducatorProfileControllerGetConfigsQuery,
  useLazyEducatorProfileControllerGetConfigsQuery,
  useEducatorProfileControllerGetCentreUsersQuery,
  useLazyEducatorProfileControllerGetCentreUsersQuery,
  useContactApplicationControllerGetApplicationListQuery,
  useLazyContactApplicationControllerGetApplicationListQuery,
  useCrmContactsConfigControllerGetCrmContactConfigQuery,
  useLazyCrmContactsConfigControllerGetCrmContactConfigQuery,
  useContactEnquiriesControllerGetEnquiriesListQuery,
  useLazyContactEnquiriesControllerGetEnquiriesListQuery,
  useContactsLeadActivitiesControllerGetTableDataForCentreQuery,
  useLazyContactsLeadActivitiesControllerGetTableDataForCentreQuery,
  useCheckIfApplicationExistsControllerCheckIfApplicationExistsQuery,
  useLazyCheckIfApplicationExistsControllerCheckIfApplicationExistsQuery,
  useLeadActivitiesControllerGetTableDataForCentreQuery,
  useLazyLeadActivitiesControllerGetTableDataForCentreQuery,
  useLeadActivitiesConfigControllerGetConfigLeadActivitiesQuery,
  useLazyLeadActivitiesConfigControllerGetConfigLeadActivitiesQuery,
  useLaConfigPrNumberControllerGetUserNameConfigQuery,
  useLazyLaConfigPrNumberControllerGetUserNameConfigQuery,
  useLaConfigSeNumberControllerGetUserNameConfigQuery,
  useLazyLaConfigSeNumberControllerGetUserNameConfigQuery,
  useLaConfigUserEmailControllerGetUserEmailConfigQuery,
  useLazyLaConfigUserEmailControllerGetUserEmailConfigQuery,
  useLaConfigUserNameControllerGetUserNameConfigQuery,
  useLazyLaConfigUserNameControllerGetUserNameConfigQuery,
  useLaConfigUserPhoneControllerGetUserPhoneConfigQuery,
  useLazyLaConfigUserPhoneControllerGetUserPhoneConfigQuery,
  useCrmLeadActivitiesReportControllerRequestReportMutation,
  useCrmLeadActivitiesReportDetailsControllerGetOneQuery,
  useLazyCrmLeadActivitiesReportDetailsControllerGetOneQuery,
  useCrmLpMarketingListControllerGetChartDataQuery,
  useLazyCrmLpMarketingListControllerGetChartDataQuery,
  useCrmLpMarketingChartControllerGetChartDataQuery,
  useLazyCrmLpMarketingChartControllerGetChartDataQuery,
  useCrmLpMarketingReportControllerGetOneQuery,
  useLazyCrmLpMarketingReportControllerGetOneQuery,
  useCrmLpMarketingReportControllerRequestReportMutation,
  useCrmLpMarketingSummaryControllerGetChartDataQuery,
  useLazyCrmLpMarketingSummaryControllerGetChartDataQuery,
  useNewFeatureControllerGetNewFeatureForCrmQuery,
  useLazyNewFeatureControllerGetNewFeatureForCrmQuery,
  useNewFeatureControllerSetNewFeatureForCrmMutation,
  useParentApplicationConfigControllerGetArchivedReasonConfigQuery,
  useLazyParentApplicationConfigControllerGetArchivedReasonConfigQuery,
  useProgramNameControllerGetProgramNameQuery,
  useLazyProgramNameControllerGetProgramNameQuery,
  useConfirmProgramControllerGetConfirmProgramMutation,
  useDetailProgramControllerGetDetailProgramQuery,
  useLazyDetailProgramControllerGetDetailProgramQuery,
  useProgramPermissionControllerGetProgramPermissionQuery,
  useLazyProgramPermissionControllerGetProgramPermissionQuery,
  usePermittedUsersControllerGetPermittedUsersQuery,
  useLazyPermittedUsersControllerGetPermittedUsersQuery,
  useProgramsControllerCreateOneProgramMutation,
  useProgramsControllerUpdateOneProgramMutation,
  useProgramsControllerGetProgramListQuery,
  useLazyProgramsControllerGetProgramListQuery,
  useProgramsControllerDeleteOneProgramMutation,
  useResourcesControllerGetAllQuery,
  useLazyResourcesControllerGetAllQuery,
  useResourcesControllerDeleteOneMutation,
  useCrmConfigControllerGetSummaryJobListingsQuery,
  useLazyCrmConfigControllerGetSummaryJobListingsQuery,
  useCrmConfigControllerGetPageEulaQuery,
  useLazyCrmConfigControllerGetPageEulaQuery,
  useCentreControllerFindOneQuery,
  useLazyCentreControllerFindOneQuery,
  useRatingSummaryControllerGetRatingSummaryQuery,
  useLazyRatingSummaryControllerGetRatingSummaryQuery,
  useBasicInfoControllerUpdateBasicInfoMutation,
  useVirtualTourCenTreControllerUpsertVirtualTourForCentreMutation,
  useVirtualTourCenTreControllerGetVirtualTourOfCentreQuery,
  useLazyVirtualTourCenTreControllerGetVirtualTourOfCentreQuery,
  useJobListingControllerCreateOneMutation,
  useJobListingControllerUpdateOneMutation,
  useJobListingControllerDeleteManyMutation,
  useJobListingControllerGetJobListingsQuery,
  useLazyJobListingControllerGetJobListingsQuery,
  useJobListingControllerBulkActionMutation,
  useJobListingControllerUpdateManyMutation,
  useJobListingDetailsControllerGetTemplateDetailsQuery,
  useLazyJobListingDetailsControllerGetTemplateDetailsQuery,
  useJobReferenceExistentControllerCheckJobReferenceListExistsQuery,
  useLazyJobReferenceExistentControllerCheckJobReferenceListExistsQuery,
  useSummaryJobListingControllerGetSummaryJobListingsQuery,
  useLazySummaryJobListingControllerGetSummaryJobListingsQuery,
  useWorkplaceControllerGetJobOrganizationsByIdsQuery,
  useLazyWorkplaceControllerGetJobOrganizationsByIdsQuery,
  useWorkplaceControllerGetWorkplaceListQuery,
  useLazyWorkplaceControllerGetWorkplaceListQuery,
  useWorkplaceControllerGetWorkplacesBasicInfoQuery,
  useLazyWorkplaceControllerGetWorkplacesBasicInfoQuery,
  useJobListingCancelControllerCancelOneMutation,
  useJobPostTemplateConfigControllerGetFormTemplateConfigQuery,
  useLazyJobPostTemplateConfigControllerGetFormTemplateConfigQuery,
  useJobPostTemplateControllerGetJobTemplatesQuery,
  useLazyJobPostTemplateControllerGetJobTemplatesQuery,
  useJobPostTemplateControllerCreateJobTemplatesMutation,
  useJobPostTemplateControllerUpdateJobTemplatesMutation,
  useJobTemplateFilterConfigControllerGetFilterConfigQuery,
  useLazyJobTemplateFilterConfigControllerGetFilterConfigQuery,
  useJobTemplateDetailsControllerGetTemplateDetailsQuery,
  useLazyJobTemplateDetailsControllerGetTemplateDetailsQuery,
  useJobPostTemplateBasicInfoControllerGetJobTemplatesBasicInfoQuery,
  useLazyJobPostTemplateBasicInfoControllerGetJobTemplatesBasicInfoQuery,
  useJobApplicationControllerGetAllJobApplicationsQuery,
  useLazyJobApplicationControllerGetAllJobApplicationsQuery,
  useJobApplicationDetailControllerGetProfileSeekerQuery,
  useLazyJobApplicationDetailControllerGetProfileSeekerQuery,
  useSummanyInfoControllerGetInfoSummaryQuery,
  useLazySummanyInfoControllerGetInfoSummaryQuery,
  useMailBoxControllerGetMessageMailBoxApplicationQuery,
  useLazyMailBoxControllerGetMessageMailBoxApplicationQuery,
  useChangeStatusControllerChangeStatusJobApplicationMutation,
  useDocumentControllerGetDocumentOfSeekerInMailBoxQuery,
  useLazyDocumentControllerGetDocumentOfSeekerInMailBoxQuery,
  useJobApplicationSummaryControllerGetSummaryJobApplicationsQuery,
  useLazyJobApplicationSummaryControllerGetSummaryJobApplicationsQuery,
  useTotalSubmittedJobApplicationControllerGetTotalSubmittedJobApplicationsQuery,
  useLazyTotalSubmittedJobApplicationControllerGetTotalSubmittedJobApplicationsQuery,
  useActivityNoteControllerCreateActivityMutation,
  useActivityNoteControllerGetAllActivityNoteQuery,
  useLazyActivityNoteControllerGetAllActivityNoteQuery,
  useActivityNoteDetailControllerGetOneQuery,
  useLazyActivityNoteDetailControllerGetOneQuery,
  useApplicantBasicInfoControllerGetAllApplicantsBasicInfoQuery,
  useLazyApplicantBasicInfoControllerGetAllApplicantsBasicInfoQuery,
  useJobReferenceBasicInfoControllerGetJobReferencesBasicInfoQuery,
  useLazyJobReferenceBasicInfoControllerGetJobReferencesBasicInfoQuery,
  useJobPostTitleBasicInfoControllerGetJobTitleBasicInfoQuery,
  useLazyJobPostTitleBasicInfoControllerGetJobTitleBasicInfoQuery,
  useCampaignControllerCreateOneMutation,
  useCampaignControllerGetCampaignsQuery,
  useLazyCampaignControllerGetCampaignsQuery,
  useCampaignControllerEditOneMutation,
  useCampaignControllerGetCampaignsBasicInfoQuery,
  useLazyCampaignControllerGetCampaignsBasicInfoQuery,
  useJobChatboxControllerGetOwnedChatboxesQuery,
  useLazyJobChatboxControllerGetOwnedChatboxesQuery,
  useJobChatboxControllerArchiveOwnedChatboxMutation,
  useJobChatboxSummaryControllerGetChatboxSummariesQuery,
  useLazyJobChatboxSummaryControllerGetChatboxSummariesQuery,
  useJobChatMessageControllerGetOwnedChatMessagesQuery,
  useLazyJobChatMessageControllerGetOwnedChatMessagesQuery,
  useJobChatMessageControllerSendMessagesMutation,
  useS3PreSignedUrlControllerGetOwnedChatMessagesMutation,
  useBasicInfoControllerGetOwnedCompanyBasicInfoQuery,
  useLazyBasicInfoControllerGetOwnedCompanyBasicInfoQuery,
  useBasicInfoControllerUpdateOwnedCompanyBasicInfoMutation,
  useAboutUsControllerUpdateOwnedCompanyBasicInfoMutation,
  useJobDiscountControllerUpdateOwnedCompanyBasicInfoMutation,
  useCompanyMediaControllerUpdateOwnedCompanyMediaMutation,
  useVirtualTourCompanyControllerUpsertVirtualTourForCompanyMutation,
  useVirtualTourCompanyControllerGetVirtualTourOfCompanyQuery,
  useLazyVirtualTourCompanyControllerGetVirtualTourOfCompanyQuery,
  useAdsPostCallingButtonControllerGetAllCallingButtonQuery,
  useLazyAdsPostCallingButtonControllerGetAllCallingButtonQuery,
  useAdsPostCallingButtonControllerCreateOneCallingButtonMutation,
  useAdsPostCallingButtonControllerUpdateOneCallingButtonMutation,
  useAdsPostCallingButtonControllerDeleteCallingButtonMutation,
  useAdsPostCallingButtonControllerGetNumberOfAdsUsedQuery,
  useLazyAdsPostCallingButtonControllerGetNumberOfAdsUsedQuery,
  useCloseAdsPostControllerCloseAdsPostMutation,
  useAdsFormConfigControllerGetFormTemplateConfigQuery,
  useLazyAdsFormConfigControllerGetFormTemplateConfigQuery,
  useAdsPostControllerGetAdsPostListQuery,
  useLazyAdsPostControllerGetAdsPostListQuery,
  useAdsPostControllerCreateAdsTemplatesMutation,
  useAdsPostControllerUpdateAdsTemplatesMutation,
  useAdsPostControllerDeleteAdsTemplatesMutation,
  useS3PreSignedUrlControllerGetS3PreSignedUrlForUploadAdsMediaMutation,
  useAdsPostTargetedAudienceControllerGetAllTargetedAudiencesQuery,
  useLazyAdsPostTargetedAudienceControllerGetAllTargetedAudiencesQuery,
  useAdsPostTargetedAudienceControllerUpdateTargetedAudienceMutation,
  useAdsPostTargetedAudienceControllerCreateOneTargetedAudienceMutation,
  useAdsPostTargetedAudienceControllerDeleteTargetedAudienceMutation,
  useAdsPostTargetedAudienceControllerGetNumberOfAdsUsedQuery,
  useLazyAdsPostTargetedAudienceControllerGetNumberOfAdsUsedQuery,
  useAdsPostTitleControllerGetAdsPostTitlesQuery,
  useLazyAdsPostTitleControllerGetAdsPostTitlesQuery,
  useAdsPostDetailControllerGetAdsPostQuery,
  useLazyAdsPostDetailControllerGetAdsPostQuery,
  useLgaControllerGetLgaQuery,
  useLazyLgaControllerGetLgaQuery,
  usePostcodeControllerGetPostcodeQuery,
  useLazyPostcodeControllerGetPostcodeQuery,
  useStateControllerGetStateQuery,
  useLazyStateControllerGetStateQuery,
  useSuburbControllerGetSuburbQuery,
  useLazySuburbControllerGetSuburbQuery,
} = injectedRtkApi;

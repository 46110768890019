import styled from 'styled-components';

const AlphabetBarStyles = styled.div`
  margin-bottom: 20px;

  .ant-space {
    padding: 10px;
    background: #ffffff;
    border-radius: 8px;
  }
  button {
    font-weight: bold;
  }
`;

export default AlphabetBarStyles;

/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Col, Row, Divider } from 'antd';
import i18next from 'i18next';
import { round } from 'lodash-es';

import { RATING_LINK } from 'configs/localData/constant';

import CardSection from 'components/common/CardSection';
import KindiRating from 'components/common/KindiRating';

import SliderRating from './SliderRating';
import { KindiCareRatingStyles } from './styles';

const KindiCareRating = ({ kindiCareRating, kindiRatingSummary }) => {
  const isNR = useMemo(() => !kindiCareRating, [kindiCareRating]);

  const formatPrice = useMemo(
    () => ({
      price: round(kindiCareRating, 1) || 0,
      avg: round(kindiRatingSummary.average, 1) || 0,
      min: isNR ? 0 : round(kindiRatingSummary.min, 1) || 0,
      max: round(kindiRatingSummary.max, 1) || 0,
    }),
    [kindiCareRating, kindiRatingSummary, isNR],
  );

  return (
    <CardSection title="centreDetail.kindiCareRating">
      <KindiCareRatingStyles>
        <div className="header-ratings">
          <KindiRating className="rating-value" value={kindiCareRating} />
          <div className="good-text">{kindiRatingSummary?.kindiRatingType}</div>
        </div>
        {formatPrice?.min < formatPrice?.max && (
          <div className="slider-summary">
            <SliderRating formatPrice={formatPrice} isNR={isNR} />

            <Row gutter={10} className="legend-summary">
              <Col span={12} className="legend-item">
                <div className="dot-legend dot-legend-avg" />
                <div className="name-legend">
                  {i18next.t('centreDetail.average')}
                </div>
              </Col>
              <Col span={12} className="legend-item">
                <div className="dot-legend dot-legend-service" />
                <div className="name-legend">
                  {i18next.t('centreDetail.thisService')}
                </div>
              </Col>
            </Row>
          </div>
        )}
        <div
          className="summary-message"
          dangerouslySetInnerHTML={{ __html: kindiRatingSummary?.description }}
        />
        <Divider />
        <div className="footer-rating">
          <span>{i18next.t('centreDetail.learnMoreAboutThe')}</span>{' '}
          <a
            className="link-kindi-rating"
            href={RATING_LINK}
            target="_blank"
            rel="noopener noreferrer"
          >
            {i18next.t('centreDetail.kindiCareRating')}
          </a>
        </div>
      </KindiCareRatingStyles>
    </CardSection>
  );
};

KindiCareRating.propTypes = {
  kindiRatingSummary: PropTypes.object,
  kindiCareRating: PropTypes.any,
};

KindiCareRating.defaultProps = {
  kindiRatingSummary: {},
};
export default KindiCareRating;

import React, { useState } from 'react';
import {
  AvatarPlaceholderIcon,
  BriefCaseOutLineIcon,
  ErrorIcon,
  PersonFillIcon,
  TeacherIcon,
  TrashOutlineIcon,
  WarningIcon,
} from 'components/common/SVGIcon';
import RowItem from 'containers/Centres/components/CentreTabs/EducatorStaffProfilesTab/components/BoardView/RowItem';
import { StaffProfileModel } from 'types/staffProfiles';
import StaffProfileDetailModal from 'containers/Centres/components/CentreTabs/EducatorStaffProfilesTab/components/StaffProfileDetail';
import ShowProfileButton from 'containers/Centres/components/CentreTabs/EducatorStaffProfilesTab/components/ShowProfileButton';
import { Button, Modal } from 'antd';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { goToStep, setStaffProfilePayload } from 'redux/staffProfile/slice';
import useModifyStaffProfile from 'containers/Centres/components/CentreTabs/EducatorStaffProfilesTab/hooks/useModifyStaffProfile';
import { isNumber } from 'lodash-es';
import { useTranslation } from 'react-i18next';
import EducationStatus from '../EducationStatus';

interface CardItemProps {
  staffProfile: StaffProfileModel;
}

const CardItem = ({ staffProfile }: CardItemProps) => {
  const { push } = useHistory();
  const { id: centreId } = useParams();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const {
    isModifyStaffProfile,
    deleteProfile,
    checkModifyStaffProfileExisting,
  } = useModifyStaffProfile();
  const [visible, setVisible] = useState(false);
  const { isDraft, educations, basicInfo, isShowThisProfile, id } =
    staffProfile;
  const staffProfileName = basicInfo.lastName
    ? `${basicInfo.firstName} ${basicInfo.lastName}`
    : basicInfo.firstName;

  const checkModifyPermission = () => {
    const roleRank = staffProfile?.basicInfo?.roleRank;
    return isNumber(roleRank)
      ? checkModifyStaffProfileExisting(roleRank)
      : isModifyStaffProfile;
  };

  const handleDeleteProfile = (
    e: MouseEvent | React.MouseEvent<HTMLElement>,
  ) => {
    if (!checkModifyPermission()) {
      e.stopPropagation();
      Modal.error({
        title: t('educatorStaffProfile.deleteMessage'),
        icon: <ErrorIcon />,
      });
      return;
    }
    e.stopPropagation();
    Modal.confirm({
      title: t(
        isDraft
          ? 'educatorStaffProfile.deleteDraft'
          : 'educatorStaffProfile.deleteProfile',
      ),
      content: t(
        isDraft
          ? 'educatorStaffProfile.deleteDraftConfirm'
          : 'educatorStaffProfile.deleteProfileConfirm',
      ),
      okText: t('button.delete'),
      icon: <WarningIcon className="text-24" />,
      onOk: () => deleteProfile({ id }),
    });
  };

  const handleViewDetail = () => {
    if (staffProfile?.isDraft && !checkModifyPermission()) {
      Modal.error({
        title: t('educatorStaffProfile.editMessage'),
        icon: <ErrorIcon />,
      });
      return;
    }
    if (staffProfile.isDraft) {
      dispatch(goToStep(staffProfile.currentStep));
      dispatch(setStaffProfilePayload(staffProfile));
      push(`/educator-staff-profile/${centreId}/create/steps`);
    } else {
      setVisible(true);
    }
  };

  return (
    <>
      <div
        className=" relative px-16 py-24 card-item-container flex flex-col rounded-12 shadow gap-24 bg-white cursor-pointer h-full"
        role="presentation"
        onClick={handleViewDetail}
      >
        <div className="w-full flex-center">
          <div className="relative w-100 h-100">
            {basicInfo.avatar ? (
              <img
                src={basicInfo.avatar}
                alt={staffProfileName}
                className="w-full h-full object-cover rounded-full"
              />
            ) : (
              <div className="bg-neutral-100 w-full h-full rounded-full flex-center text-32 text-neutral-400">
                <AvatarPlaceholderIcon />
              </div>
            )}

            <div
              className={`absolute bottom-0 -right-8 bg-success-soft px-8 rounded-10 text-12 text-success-default fw-bold ${
                isDraft && 'bg-neutral-200 text-neutral-500'
              }`}
            >
              {isDraft ? t('status.draft') : t('status.completed')}
            </div>
          </div>
          <Button
            icon={<TrashOutlineIcon />}
            className="cursor-pointer text-20 absolute top-0 right-0"
            onClick={handleDeleteProfile}
            type="text"
          />
        </div>
        <div className="flex flex-col gap-8">
          <div className="text-14 text-primary fw-bold line-clamp-1">
            {staffProfileName}
          </div>
          <RowItem Icon={PersonFillIcon} value={basicInfo.jobTitle} />
          <RowItem Icon={BriefCaseOutLineIcon} value={basicInfo.experience} />
          {!!educations?.length && (
            <div className="flex flex-col gap-4">
              <RowItem Icon={TeacherIcon} value={educations[0].name} />
              <div className="pl-24">
                <EducationStatus isCompleted={educations[0].isCompleted} />
              </div>
            </div>
          )}
          {!staffProfile.isDraft && (
            <ShowProfileButton
              staffProfile={staffProfile}
              key={`${id}-${isShowThisProfile}`}
              disabled={!checkModifyPermission()}
            />
          )}
        </div>
      </div>
      <StaffProfileDetailModal
        staffProfile={staffProfile}
        visible={visible}
        onClose={() => setVisible(false)}
        disable={!checkModifyPermission()}
      />
    </>
  );
};

export default CardItem;

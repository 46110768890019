import React, { useState, useEffect, useMemo } from 'react';
import StepsCreateLayout from 'components/RestLayout/StepsCreateLayout';
import ClaimSteps from 'containers/ClaimCentres/components/ClaimSteps';
import { useParams, useLocation, useHistory } from 'react-router';
import { useSelector } from 'react-redux';
import { centresSelectors } from 'redux/centres/selectors';
import { Skeleton } from 'antd';
import { ClaimCentreContainer } from './styles';
import ClaimSideCover from './components/ClaimSideCover';
import CentreCardWrapper from './components/CentreCardWrapper';
import CentreModal from './components/CentreModal';
import ClaimContent from './components/ClaimContent';
import ClaimCentreRegister from './components/ClaimCentreRegister';

const ClaimCentresPage = () => {
  const { id } = useParams();
  const { pathname } = useLocation();
  const { push } = useHistory();
  const [currentStep, setCurrentStep] = useState(0);
  const { name } = useSelector(centresSelectors.getCurrentData) || {};
  const loading = useSelector(centresSelectors.getLoadingCurrentRecord);

  const onChangeStep = (step: number) => {
    if (step === 2 || step === 1) {
      return;
    }

    if (step === 0) {
      push('/claim-centres');
    }

    setCurrentStep(step);
  };

  const CustomHeader = (
    <ClaimSteps currentStep={currentStep} onChange={onChangeStep} />
  );

  const isStep1 = !!id;

  const SiderComponent = isStep1 ? (
    <CentreCardWrapper />
  ) : (
    <ClaimSideCover currentStep={currentStep} />
  );

  useEffect(() => {
    if (id && name) {
      setCurrentStep(1);
      push(`/claim-centres/${id}`);
    }
    if (pathname.includes('/claim-centre-register')) {
      setCurrentStep(2);
    }
  }, [id, name, pathname, push]);

  const content = useMemo(() => {
    switch (currentStep) {
      case 0:
        return <ClaimContent />;
      case 1:
        return (
          <CentreModal
            onBack={() => {
              push('/claim-centres');
              setCurrentStep(0);
            }}
          />
        );
      case 2:
        return <ClaimCentreRegister />;
      default:
        return null;
    }
  }, [currentStep, push]);

  return (
    <ClaimCentreContainer isDetailPage={isStep1}>
      <StepsCreateLayout
        CustomHeader={CustomHeader}
        SiderComponent={SiderComponent}
      >
        {loading ? <Skeleton paragraph={{ rows: 8 }} /> : content}
      </StepsCreateLayout>
    </ClaimCentreContainer>
  );
};

export default ClaimCentresPage;

import { PREFIX_API_VER_2 } from 'configs/localData/constant';
import { get, post, put } from './utils';

export const createJobTemplateApi = (data) =>
  post(`/crm/job-seekers/posts/templates`, data, PREFIX_API_VER_2);

export const getJobTemplateConfigApi = () =>
  get(`/crm/job-seekers/posts/templates/form-config`, {}, PREFIX_API_VER_2);

export const getAllJobTemplateApi = (data) =>
  get(`/crm/job-seekers/posts/templates`, data, PREFIX_API_VER_2);

export const getJobTemplatesDetailApi = (id) =>
  get(
    `/crm/job-seekers/posts/templates/details?templateId=${id}`,
    null,
    PREFIX_API_VER_2,
  );

export const editJobTemplateApi = (id, data) =>
  put(
    `/crm/job-seekers/posts/templates?templateId=${id}`,
    data,
    PREFIX_API_VER_2,
  );

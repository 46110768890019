import { Table } from 'antd';
import styled from 'styled-components';

export const TableStyled = styled(Table)`
  .ant-table-thead {
    padding: 12px;
    .ant-table-cell {
      padding: 12px;
      background: ${({ theme }) => theme.table.headerBackground};
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
    }
  }
  .ant-table-tbody > tr > td {
    border-bottom: none;
  }
  .ant-table-cell .ant-row.ant-form-item {
    margin-bottom: 0;
  }
`;

import styled, { css } from 'styled-components';
import { Table } from 'antd';

export const JobAdditionFormStyle = styled.div`
  .diamond {
    width: 10px;
    height: 10px;
    background-color: ${({ theme }) => theme.palette.green};
    transform: rotate(45deg);
    border-radius: 2px;
  }
`;

export const MoreAboutJobFormStyle = styled.div`
  .ant-form-vertical .ant-form-item-label > label,
  .ant-col-24.ant-form-item-label > label,
  .ant-col-xl-24.ant-form-item-label > label {
    font-weight: 700;
    font-size: 16px;
    color: ${({ theme }) => theme.text.mainL1};
  }

  .form-item-editor .tox.tox-tinymce {
    border-radius: 12px;
  }
`;

export const BasicInformationStyle = styled.div`
  .ant-checkbox + span {
    padding-top: 2px;
  }
  .ant-form-vertical .ant-form-item-label > label,
  .ant-col-24.ant-form-item-label > label,
  .ant-col-xl-24.ant-form-item-label > label {
    font-weight: 700;
    font-size: 16px;
    color: ${({ theme }) => theme.text.blackNeutral800};
  }
`;

export const TableActionStyled = styled(Table)`
  padding: 12px;
  background: #ffffff;
  border: 1px solid ${({ theme }) => theme.boardColors.N30};
  border-radius: 12px;

  .ant-table-thead {
    padding: 12px;

    .ant-table-cell {
      padding: 12px;
      background: ${({ theme }) => theme.table.headerBackground};
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      color: ${({ theme }) => theme.text.primary};

      &:first-child {
        border-bottom-left-radius: 8px;
      }
      &:last-child {
        border-bottom-right-radius: 8px;
      }
    }
  }

  .ant-table-tbody > tr > td {
    border-bottom: none;
    &:first-child {
      padding-left: 6px;
    }
  }

  .ant-table-cell .ant-row.ant-form-item {
    margin-bottom: 0;
  }

  ${({ isRadioTable }) =>
    isRadioTable &&
    css`
      .ant-table-cell {
        .ant-row.ant-form-item {
          margin-bottom: 0;
        }

        .ant-radio-group {
          width: 100%;
          padding: 0 16px;
          display: flex;
          justify-content: space-between;
        }
      }
    `}
`;

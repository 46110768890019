import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

import CheckPermission from 'components/common/CheckPermissions';
import FallbackBtn from './FallbackBtn';
import { CreateButtonStyles } from './styles';

const CreateButton = ({ onToggleModal }) => (
  <CreateButtonStyles>
    <CheckPermission
      permissionKey="applications"
      action="isEdit"
      isDisabled
      fallback={<FallbackBtn />}
    >
      <Button
        className="add-button"
        type="primary"
        shape="circle"
        icon=<PlusOutlined />
        onClick={onToggleModal}
      />
    </CheckPermission>
  </CreateButtonStyles>
);

CreateButton.propTypes = {
  onToggleModal: PropTypes.func,
};

CreateButton.defaultProps = {
  onToggleModal: () => {},
};

export default CreateButton;

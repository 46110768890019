import styled from 'styled-components';
import { Button } from 'antd';

export const ButtonWrapper = styled(Button)`
  width: 100%;
  align-items: center;
`;

export const FooterButtonRowWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-size: 16px;
  font-weight: 500;
  padding: 24px;
  button {
    min-width: 150px;
  }
`;

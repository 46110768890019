import React from 'react';
import { Program } from 'generated/apis';
import { Image, Space } from 'antd';
import {
  CalendarIcon,
  ClockIcon,
  HandHeartIcon,
  ProfileUserIcon,
} from 'components/common/SVGIcon';
import TagCustom from 'components/common/TagCustom';
import moment from 'moment';
import ImageError from 'components/common/ImageError';
import theme from 'configs/theme';

const ProgramInfo = ({
  ageGroup,
  image,
  name,
  serviceType,
  sessionType,
  updatedAt,
}: Program) => {
  const programDetails = [
    {
      Icon: HandHeartIcon,
      content: serviceType?.name,
      isShow: !!serviceType,
      className: 'text-black-base',
    },
    {
      Icon: ProfileUserIcon,
      content: (
        <Space size={[8, 8]} wrap>
          {ageGroup?.map((item) => (
            <TagCustom
              key={item.id}
              color={item.textColor}
              backgroundColor={item.backgroundColor}
              name={item.name}
              className="fw-500 text-16"
              height="24px"
            />
          ))}
        </Space>
      ),
      isShow: !!ageGroup?.length,
    },
    {
      Icon: CalendarIcon,
      content: (
        <Space size={[8, 8]} wrap>
          {sessionType?.map((item) => (
            <TagCustom
              key={item.id}
              color={theme.text.blackNeutral800}
              backgroundColor={theme.background.container}
              name={item.name}
              className="fw-500 text-16"
            />
          ))}
        </Space>
      ),
      isShow: !!sessionType?.length,
    },
    {
      Icon: ClockIcon,
      content: `Last updated: ${`${moment(updatedAt).fromNow()} (${moment(
        updatedAt,
      ).format('DD MMM YYYY [at] h:mm a')})`}`,
      isShow: !!updatedAt,
      className: 'text-black-base',
    },
  ];
  return (
    <div className="flex">
      <div className="mt-4">
        {image ? (
          <Image
            className="rounded-8 aspect-video object-cover"
            width={280}
            height="100%"
            preview={false}
            src={image}
            alt="program image"
          />
        ) : (
          <ImageError className="rounded-8" />
        )}
      </div>
      <div className="ml-16">
        <div className="fw-bold text-20 mb-8">{name}</div>
        <Space size={[8, 8]} direction="vertical">
          {programDetails.map(
            ({ Icon, content, isShow, className }, index) =>
              isShow && (
                <div className="flex" key={index}>
                  {<Icon className="mt-2 text-primary text-18" />}
                  <div className={`ml-16 text-16 ${className && className}`}>
                    {content}
                  </div>
                </div>
              ),
          )}
        </Space>
      </div>
    </div>
  );
};

export default ProgramInfo;

import { Col, Row } from 'antd';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getJobTemplateConfigSelector } from 'redux/jobs/jobTemplates/selectors';
import PropTypes from 'prop-types';
import i18next from 'i18next';
import { times, chunk, remove } from 'lodash-es';
import { editJobTemplate } from 'redux/jobs/jobTemplates/actions';
import { useParams } from 'react-router';
import TableToggle from '../../TableToggle';

const CertificationsTab = ({ currentData }) => {
  const dispatch = useDispatch();
  const { id } = useParams();

  const [activeCertifications, setActiveCertifications] = useState(
    currentData?.certifications || [],
  );

  const certificationsList = useSelector(
    getJobTemplateConfigSelector,
  )?.certifications;

  const certificationsData = certificationsList?.map((certification) => ({
    ...certification,
    ...currentData?.certifications?.find((e) => e?.id === certification?.id),
  }));

  const formatCertificationsData = useMemo(
    () => chunk(certificationsData, Math.ceil(certificationsData?.length / 2)),
    [certificationsData],
  );

  useEffect(() => {
    if (currentData?.certifications?.length > 0) {
      setActiveCertifications(currentData?.certifications);
    }
  }, [currentData.certifications]);

  const updateCertification = useCallback(
    (certifications) => {
      dispatch(
        editJobTemplate({
          id,
          data: {
            certifications,
          },
        }),
      );
    },
    [dispatch, id],
  );

  const handleToggleOnCertification = (certification) => {
    const newCertification = [
      ...activeCertifications,
      {
        ...certification,
        isPreferred: true,
        isRequired: false,
      },
    ];
    setActiveCertifications(newCertification);
    updateCertification(newCertification);
  };

  const handleToggleOffCertification = (certification) => {
    const newCertification = remove(
      [...activeCertifications],
      (i) => i?.id !== certification?.id,
    );
    setActiveCertifications(newCertification);
    updateCertification(newCertification);
  };

  const onSwitchChangeCertification = (checked, certification) => {
    checked
      ? handleToggleOnCertification(certification)
      : handleToggleOffCertification(certification);
  };

  return (
    <Row wrap gutter={[24, 24]}>
      {times(2).map((idx) => (
        <Col key={String(idx)} xs={24} lg={12}>
          <TableToggle
            dataSource={formatCertificationsData[idx]}
            onSwitchChange={onSwitchChangeCertification}
            categoryName={i18next.t(
              'jobs.createJobTemplateStep.certifications',
            )}
          />
        </Col>
      ))}
    </Row>
  );
};

CertificationsTab.propTypes = {
  currentData: PropTypes.object,
};

export default CertificationsTab;

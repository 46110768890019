import { Tabs } from 'antd';
import i18next from 'i18next';
import React, { useCallback } from 'react';
import { useHistory, useParams } from 'react-router';
import { TABS_KEY_VALUE } from 'configs/localData/constant';
import SummaryTab from './SummaryTab';
import MailboxTab from './MailboxTab';

const { TabPane } = Tabs;

const JobApplicationTabs = () => {
  const { push } = useHistory();
  const { id, modelDetail } = useParams();

  const TABS = [
    {
      key: TABS_KEY_VALUE.summary,
      title: 'applications.tabs.summary',
      TabComponent: SummaryTab,
    },
    {
      key: TABS_KEY_VALUE.mailbox,
      title: 'applications.tabs.mailbox',
      TabComponent: MailboxTab,
    },
  ];

  const onChange = useCallback(
    (key) => {
      push(`/job-applications/${id}/show/${key}`);
    },
    [id, push],
  );

  return (
    <div>
      <Tabs
        activeKey={modelDetail || TABS_KEY_VALUE.summary}
        onChange={onChange}
      >
        {TABS.map(({ title, key, TabComponent, ...tab }) => (
          <TabPane tab={i18next.t(title)} key={key}>
            <TabComponent {...tab} />
          </TabPane>
        ))}
      </Tabs>
    </div>
  );
};

export default JobApplicationTabs;

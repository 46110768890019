import styled from 'styled-components';

const AutomatedListStyles = styled.div`
  border-right: 1px solid ${({ theme }) => theme.border.light};
  height: 100%;

  .automated-item {
    padding: 20px;
    &:hover {
      background: rgba(255, 197, 242, 0.15);
    }
  }
  .automated-item-selected {
    background: rgba(255, 197, 242, 0.32) !important;
  }

  .automated-list {
    &__title {
      padding: 0 20px 10px 20px;
    }
  }

  .ant-list-item {
    border: none !important;
    padding: 0 !important;
  }
  .txt-message {
    color: ${({ theme }) => theme.text.formLabel} !important;
    font-size: 12px;
    font-weight: 400;
  }
`;

export default AutomatedListStyles;

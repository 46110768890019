import styled from 'styled-components';

const BoardStyles = styled.div`
  position: relative;
  flex-grow: 1;
  height: ${({ containerHeight }) => containerHeight || '100%'};
  .container-scroll-x > div {
    width: 100%;
    overflow-x: auto;
  }
`;

export default BoardStyles;

export const ContainerScroll = styled.div`
  & > div {
    -webkit-user-select: none;
    user-select: none;
    white-space: nowrap;
    overflow-x: auto;
    overflow-y: hidden;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    padding-bottom: 8px;
    height: 100%;
  }
`;

export const ContainerBoard = styled.div`
  background-color: '#4C9AFF';
  display: inline-flex;
`;

export const ContainerColumn = styled.div`
  &:not(:first-child) {
    margin-left: 20px;
  }
  display: flex;
  flex-direction: column;
  min-width: 300px;
  width: ${({ numberColumn }) => `${100 / numberColumn}%`};
  height: 100%;
  max-height: 100%;
  vertical-align: top;

  border-radius: 6px;
  position: relative;
  white-space: normal;
`;

export const HeaderColumn = styled.div`
  background-color: ${({ isDragging }) => (isDragging ? '#E3FCEF' : '#FFFFFF')};
  transition: background-color 0.2s ease;
  border-radius: 6px;
  margin-bottom: 10px;

  .line-color {
    width: 100%;
    height: 3px;
    background: ${({ lineColor }) => lineColor || '#CCCCEE'};
    border-radius: 6px 6px 0px 0px;
  }
  .title-header {
    padding: 14px;
    font-size: 15px;
    font-weight: bold;
  }
`;

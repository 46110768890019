import React, { useState } from 'react';
import { Form, InputNumber } from 'antd';
import ModalCustom from 'components/common/ModalCustom';
import i18next from 'i18next';
import { getDiscountNumberRules } from 'utils/validateUtils';
import { useDispatch } from 'react-redux';
import { editEducatorandStaffDiscounts } from 'redux/jobs/companyProfile/actions';
import PropTypes from 'prop-types';

const EducatorAndStaffDiscountsModal = ({
  educatorDiscount,
  staffDiscount,
  onCancel,
  ...props
}) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const { validateFields, resetFields } = form;
  const onOk = () => {
    validateFields()
      .then((formValues) => {
        setLoading(true);
        dispatch(editEducatorandStaffDiscounts(formValues)).finally(() => {
          setLoading(false);
          handleCancel();
        });
      })
      .catch((error) => {
        form.scrollToField(error.errorFields?.[0]?.name?.[0]);
      });
  };

  const handleCancel = () => {
    onCancel();
    resetFields();
  };
  return (
    <ModalCustom
      title={i18next.t(
        'jobs.companyProfile.modal.educatorAndStaffDiscount.title',
      )}
      onCancel={handleCancel}
      onOk={onOk}
      loading={loading}
      {...props}
      width={1000}
    >
      <Form form={form} layout="vertical" scrollToFirstError>
        <Form.Item
          label={`${i18next.t(
            'jobs.companyProfile.modal.educatorAndStaffDiscount.educatorDiscount',
          )} (%)`}
          shouldUpdate
          name="educatorDiscount"
          rules={getDiscountNumberRules(
            'jobs.companyProfile.modal.educatorAndStaffDiscount.validateMsg.discountNumberMustBeInteger',
            'jobs.companyProfile.modal.educatorAndStaffDiscount.validateMsg.invalidNumberDiscount',
            'jobs.companyProfile.modal.educatorAndStaffDiscount.educatorDiscount',
          )}
          initialValue={Number(educatorDiscount)}
        >
          <InputNumber
            placeholder={`${i18next.t(
              'jobs.companyProfile.modal.educatorAndStaffDiscount.educatorDiscount',
            )}`}
          />
        </Form.Item>
        <Form.Item
          shouldUpdate
          label={`${i18next.t(
            'jobs.companyProfile.modal.educatorAndStaffDiscount.staffDiscount',
          )} (%)`}
          name="staffDiscount"
          rules={getDiscountNumberRules(
            'jobs.companyProfile.modal.educatorAndStaffDiscount.validateMsg.discountNumberMustBeInteger',
            'jobs.companyProfile.modal.educatorAndStaffDiscount.validateMsg.invalidNumberDiscount',
            'jobs.companyProfile.modal.educatorAndStaffDiscount.staffDiscount',
          )}
          initialValue={Number(staffDiscount)}
        >
          <InputNumber
            placeholder={`${i18next.t(
              'jobs.companyProfile.modal.educatorAndStaffDiscount.staffDiscount',
            )}`}
          />
        </Form.Item>
      </Form>
    </ModalCustom>
  );
};

EducatorAndStaffDiscountsModal.propTypes = {
  educatorDiscount: PropTypes.string,
  staffDiscount: PropTypes.string,
  onCancel: PropTypes.func,
};

export default EducatorAndStaffDiscountsModal;

import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { Divider, Form, Input, Skeleton } from 'antd';
import i18next from 'i18next';

import { RestInputContext } from 'components/RestInput/RestInputContext';
import EmptyData from 'components/common/EmptyData';

import CheckboxItem from './CheckboxItem';
import PagingCustom from './PagingCustom';
import { ListCheckboxSelectStyles } from './styles';

const ListCheckboxSelect = ({
  retrieveList,
  defaultValue,
  name,
  messageRequired = 'users.selectCentreRequired',
  data,
  paging,
  loading,
  selectedResourceTitles,
  selectedResourceTitle,
}) => {
  const { form } = useContext(RestInputContext);

  const [checkedIds, setCheckedIds] = useState(defaultValue || []);

  const onAdd = (id) => {
    const newIds = [...checkedIds, id];
    setCheckedIds(newIds);
  };

  const onRemove = (id) => {
    const newIds = checkedIds?.filter((oldId) => oldId !== id);
    setCheckedIds(newIds);
  };

  useEffect(() => {
    form.setFieldsValue({
      [name]: checkedIds,
    });
  }, [checkedIds, form, name]);

  return (
    <ListCheckboxSelectStyles className="centre-list">
      <div className="d-flex flex-center-between">
        <Form.Item
          className="form-item-centreId"
          name={name}
          rules={[
            {
              required: true,
              message: i18next.t(messageRequired),
            },
          ]}
        >
          <Input />
        </Form.Item>

        <PagingCustom paging={paging} retrieveList={retrieveList} />
      </div>

      <Divider />

      {checkedIds?.length ? (
        <div className="centre-list__selected-total">
          {checkedIds?.length > 1
            ? i18next.t(selectedResourceTitles, {
                number: checkedIds?.length,
              })
            : i18next.t(selectedResourceTitle, {
                number: checkedIds?.length,
              })}
        </div>
      ) : null}

      <div className="centre-list__list">
        <Skeleton loading={loading} active>
          {data?.length ? (
            data.map((item) => (
              <CheckboxItem
                onAdd={onAdd}
                onRemove={onRemove}
                key={item?.id}
                item={item}
                checked={checkedIds?.includes(item?.id)}
              />
            ))
          ) : (
            <EmptyData />
          )}
        </Skeleton>
      </div>
    </ListCheckboxSelectStyles>
  );
};

ListCheckboxSelect.propTypes = {
  retrieveList: PropTypes.func,
  name: PropTypes.string,
  messageRequired: PropTypes.string,
  defaultValue: PropTypes.array,
  data: PropTypes.array,
  paging: PropTypes.object,
  loading: PropTypes.bool,
  selectedResourceTitle: PropTypes.string,
  selectedResourceTitles: PropTypes.string,
};

export default ListCheckboxSelect;

import PropTypes from 'prop-types';
import React, { useContext, useEffect } from 'react';
import { Col, Row } from 'antd';
import ReferenceInputV2 from 'containers/rest/ReferenceInputV2';
import RestSelect from 'components/RestInput/RestSelect';
import i18next from 'i18next';
import RestRangePickerInput from 'components/RestInput/RestRangePickerInput';
import moment from 'moment';
import { getFilterFromUrl } from 'utils/tools';
import { RestInputContext } from 'components/RestInput/RestInputContext';
import { useLocation, useParams } from 'react-router';
import { useSelector } from 'react-redux';
import {
  getJobApplicationStatus,
  getQualifications,
} from 'redux/config/selectors';
import {
  DISPLAY_TYPES,
  PREFIX_API_VER_2,
  PREFIX_URL,
} from 'configs/localData/constant';

const FilterForm = ({ applicantId }) => {
  const { form } = useContext(RestInputContext);
  const { search } = useLocation();
  const jobApplicationStatus = useSelector(getJobApplicationStatus);
  const qualifications = useSelector(getQualifications);
  const { viewModeDetail } = useParams();

  useEffect(() => {
    const { filter } = getFilterFromUrl(search);

    const { lastUpdated, ...restFilter } = filter?.filter || {};

    form.setFieldsValue({
      lastUpdated: Object.values(lastUpdated || {}).map((item) => moment(item)),
      ...restFilter,
    });
  }, [form, search]);

  return (
    <Row gutter={16}>
      <Col xl={6} lg={12} md={12} xs={24}>
        <ReferenceInputV2
          prefixUrl={PREFIX_URL.crm}
          resource="job-seekers/posts/applicant/basic-info"
          source="applicantName"
          searchKey="fullname"
          mappedBy="fullname"
          notLikeFilter
          requestApi={PREFIX_API_VER_2}
          initialFilter={{ [`filter[postId]`]: applicantId }}
        >
          <RestSelect
            valueProp="fullname"
            titleProp="fullname"
            placeholder="jobListings.jobListingDetails.applicant"
            autoComplete="off"
            isFilterOption={false}
            isShowTooltip
          />
        </ReferenceInputV2>
      </Col>
      {viewModeDetail === DISPLAY_TYPES.list && (
        <Col xl={6} lg={12} md={12} xs={24}>
          <RestSelect
            source="status"
            placeholder="users.status"
            resourceData={jobApplicationStatus}
            formatText={(data) => i18next.t(data)}
            valueProp="application_id"
            titleProp="application_name"
            isShowTooltip
          />
        </Col>
      )}
      <Col xl={6} lg={12} md={12} xs={24}>
        <RestSelect
          source="qualificationLevel"
          placeholder="jobListings.jobListingDetails.qualificationLevel"
          resourceData={qualifications}
          valueProp="id"
          titleProp="name"
          isShowTooltip
        />
      </Col>
      <Col xl={6} lg={12} md={12} xs={24}>
        <RestRangePickerInput
          sourceGt="lastUpdated.from"
          sourceLt="lastUpdated.to"
          source="lastUpdated"
          placeholder="jobListings.lastUpdated"
          isShowTooltip
        />
      </Col>
    </Row>
  );
};

FilterForm.propTypes = {
  applicantId: PropTypes.string,
};

export default FilterForm;

import React from 'react';
import PropTypes from 'prop-types';
import { CardCustomWrapper } from './styles';

const CardCustomContent = ({ Icon, children, isProgress }) => (
  <CardCustomWrapper>
    <div className="flex p-16 item-style">
      <div className="mr-24">
        {Icon && (
          <Icon
            className={`${
              isProgress ? '' : 'rounded-full flex-center ic-style'
            } text-pastel-pink text-24`}
          />
        )}
        {isProgress && <div className="steps-item-tail" />}
      </div>
      {children}
    </div>
  </CardCustomWrapper>
);
CardCustomContent.propTypes = {
  children: PropTypes.node,
  Icon: PropTypes.any,
  isProgress: PropTypes.bool,
};

export default CardCustomContent;

import TableScrollCustom from 'components/common/TableScrollCustom';
import { MAX_LIMIT_LIST } from 'configs/localData/constant';
import i18next from 'i18next';
import { debounce, isEmpty, omit } from 'lodash-es';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import { getServiceAgeGroups } from 'redux/config/selectors';
import {
  getAverageDataPriceBenchmarking,
  getDataPriceBenchmarking,
  getResourceDataSelector,
  priceBenchmarkingSelectors,
} from 'redux/priceBenchmarking/selectors';
import { formatDateUpdatePrice } from 'utils/textUtils';
import { convertSearchUrl, getFilterFromUrl } from 'utils/tools';
import loadingGif from 'assets/icons/loading.gif';
import AverageAllCentres from './AverageAllCentres';
import { TableStyle } from './styles';
import useFormatFnc from './useFormat';

const PriceBenchmarkingTable = ({ dataSource, retrieveList }) => {
  const useFormat = useFormatFnc();
  const { push } = useHistory();
  const { search } = useLocation();
  const [isFetching, setIsFetching] = useState(false);
  const [isSorting, setIsSorting] = useState(false);

  const data = useSelector(getDataPriceBenchmarking);

  const loading = useSelector(priceBenchmarkingSelectors.getLoading);
  const resourceFilter = useSelector(getResourceDataSelector) || {};
  const averageAllCentre = useSelector(getAverageDataPriceBenchmarking);
  const allowLoadmore = useRef(true);

  const outsideFilter = getFilterFromUrl(search);
  const filter = outsideFilter?.filter;
  const offset = outsideFilter?.offset;
  const restFilter = omit(filter, ['displayAddress', 'addressL1']);

  const getSorterOrder = useCallback(
    (source) => {
      if (outsideFilter.orderBy === source) return 'ascend';
      if (outsideFilter.orderBy === `-${source}`) return 'descend';
      return '';
    },
    [outsideFilter.orderBy],
  );

  const formatRowData = (id) => {
    const centreOfUserData = dataSource?.find(
      (item) => item?.isOwnedCentre && item?.id === id,
    );
    if (resourceFilter?.outsideFilter?.centreId === id) {
      return 'selected-row fw-bold';
    }
    if (centreOfUserData) {
      return 'selected-row';
    }
    return '';
  };

  const ageGroupColumn = [
    {
      title: i18next.t('priceBenchmarking.0to12Months'),
      dataIndex: '0to12months',
      render: (data, record) =>
        useFormat.formatPriceAgeGroup(
          data,
          record,
          averageAllCentre,
          '0to12months',
        ),
      width: 180,
    },
    {
      title: i18next.t('priceBenchmarking.13to24Months'),
      dataIndex: '13to24months',
      render: (data, record) =>
        useFormat.formatPriceAgeGroup(
          data,
          record,
          averageAllCentre,
          '13to24months',
        ),
      width: 180,
    },
    {
      title: i18next.t('priceBenchmarking.25to36Months'),
      dataIndex: '25to36months',
      render: (data, record) =>
        useFormat.formatPriceAgeGroup(
          data,
          record,
          averageAllCentre,
          '25to36months',
        ),
      width: 180,
    },
    {
      title: i18next.t('priceBenchmarking.36MonthsPlus'),
      dataIndex: '36monthsplus',
      render: (data, record) =>
        useFormat.formatPriceAgeGroup(
          data,
          record,
          averageAllCentre,
          '36monthsplus',
        ),
      width: 180,
    },
    {
      title: i18next.t('priceBenchmarking.schoolAge'),
      dataIndex: 'schoolage',
      render: (data, record) =>
        useFormat.formatPriceAgeGroup(
          data,
          record,
          averageAllCentre,
          'schoolage',
        ),
      width: 160,
    },
  ];

  const serviceAgeGroups = useSelector(getServiceAgeGroups);

  const serviceAgeGroupCurrent = serviceAgeGroups.find(
    (item) => item.id === filter?.serviceAgeGroupId,
  );

  const getAgeGroupColumn = () => {
    if (filter.serviceAgeGroupId === 'all') {
      return ageGroupColumn;
    }
    return ageGroupColumn.filter(
      (item) => item.dataIndex === serviceAgeGroupCurrent?.type,
    );
  };

  const columns = [
    {
      title: i18next.t('centres.brandTitle'),
      dataIndex: ['brand', 'logo'],
      render: useFormat.formatBrand,
      width: 120,
    },
    {
      title: i18next.t('centres.centreName'),
      dataIndex: 'centreName',
      width: 300,
      render: (data, record) => useFormat.formatCentreName(record?.name),
    },
    {
      title: i18next.t('centres.suburb'),
      dataIndex: 'suburb',
      width: 180,
      render: (data, record) => <span>{record?.city}</span>,
    },
    {
      title: i18next.t('centres.postCode'),
      dataIndex: 'postCode',
      width: 180,
    },
    {
      title: i18next.t('priceBenchmarking.distance'),
      dataIndex: 'distance',
      width: 200,
      render: (data) => `${data} km`,
    },
    {
      title: i18next.t('centres.capacity'),
      dataIndex: 'placeAllowed',
      width: 200,
      render: (data) => useFormat.formatValueColumData(data),
    },
    {
      title: i18next.t('priceBenchmarking.marketShare'),
      dataIndex: 'marketShare',
      render: (data, record) =>
        useFormat.formatMarketShare(record?.marketSharePercent),
      width: 220,
    },
    {
      title: i18next.t('priceBenchmarking.revenuePotential'),
      dataIndex: 'revenuePotential',
      width: 200,
      render: (data) => useFormat.formatRevenuePotential(data),
    },
    {
      title: i18next.t('priceBenchmarking.kindiCareRating'),
      dataIndex: 'kindiCareRating',
      render: useFormat.formatKindiRating,
      width: 200,
    },
    {
      title: i18next.t('centres.NQSRating'),
      dataIndex: 'NQSRating',
      render: useFormat.formatNQSRating,
      width: 180,
    },
    ...getAgeGroupColumn(),
    {
      title: i18next.t('priceBenchmarking.averageAllAgeGroups'),
      dataIndex: 'averageAllAgeGroup',
      render: (data, record) =>
        useFormat.formatPriceAgeGroup(data, record, averageAllCentre),
      width: 220,
    },
    {
      title: i18next.t('priceBenchmarking.pricesLastUpdated'),
      dataIndex: 'pricesLastUpdated',
      render: (data) => formatDateUpdatePrice(data),
      width: 200,
    },
    {
      title: '',
      dataIndex: 'id',
      width: 80,
      fixed: 'right',
      render: useFormat.formatGroupAction,
    },
  ];

  const columnsHasSorter = columns.map((column) => {
    const excludeColumnIndexes = ['id'];
    const sortOrder = getSorterOrder(column?.dataIndex);

    if (
      excludeColumnIndexes.includes(column?.dataIndex) ||
      Array.isArray(column?.dataIndex)
    ) {
      return column;
    }

    return {
      ...column,
      sorter: () => null,
      sortOrder,
      defaultSortOrder: sortOrder,
    };
  });

  const handleFetch = () => {
    const newLimit = MAX_LIMIT_LIST;
    const newOffset = offset + newLimit;
    if (
      !isEmpty(outsideFilter?.filter) &&
      data?.length < resourceFilter?.total &&
      allowLoadmore.current
    ) {
      allowLoadmore.current = false;
      const el = document.createElement('div', {
        style: {
          textAlign: 'center',
          paddingTop: 20,
          paddingBottom: 20,
          border: '1px solid #e8e8e8',
          width: '100%',
          height: 50,
          backgroundColor: 'red',
        },
      });
      el.className = 'loading-list';
      el.innerHTML = `<div class="flex-center w-full p-33"><img class="w-50" src=${loadingGif} /></div>`;
      const box = document.querySelector('.ant-table-body');
      box.appendChild(el);

      retrieveList({
        filter: {
          outsideFilter: restFilter,
          limit: newLimit,
          offset: newOffset,
          orderBy: outsideFilter.orderBy || 'centreName',
        },
        isRefresh: false,
      }).then(({ payload }) => {
        if (payload?.data?.ids) {
          const element = document.querySelector('.loading-list');
          element && element.remove();
          setIsFetching(false);
          allowLoadmore.current = true;
        }
      });

      push({
        search: `?${convertSearchUrl({
          ...filter,
          limit: MAX_LIMIT_LIST,
          offset: newOffset,
          orderBy: outsideFilter.orderBy,
        })}`,
      });
    }
  };

  useEffect(() => {
    if (isFetching) {
      handleFetch();
      setIsFetching(false);
    }
  }, [isFetching]); // eslint-disable-line

  const onChange = useCallback(
    (e, filters, sorter) => {
      const formatSort =
        sorter && sorter.field && sorter.order
          ? `${sorter.order === 'descend' ? '-' : ''}${sorter.field}`
          : '';

      setIsSorting(true);
      retrieveList({
        filter: {
          outsideFilter: {
            ...restFilter,
            limit: MAX_LIMIT_LIST,
            orderBy: formatSort || 'centreName',
          },
        },
        isRefresh: false,
        isSort: true,
      }).finally(() => setIsSorting(false));

      push({
        search: `?${convertSearchUrl({
          ...restFilter,
          orderBy: formatSort,
          displayAddress: filter?.displayAddress,
          addressL1: filter?.addressL1,
        })}`,
      });
    },
    [retrieveList, push, restFilter, filter],
  );

  const handleScroll = debounce((event) => {
    const element = event.target;
    const maxScroll = element.scrollHeight - element.clientHeight;
    const currentScroll = element.scrollTop;
    if (
      Math.round(currentScroll) <= Math.round(maxScroll) &&
      Math.round(currentScroll) >= Math.round(maxScroll) - 10
    ) {
      if (
        !isEmpty(outsideFilter?.filter) &&
        dataSource?.length <= resourceFilter?.total &&
        !isFetching
      ) {
        if (!loading) {
          setIsFetching(true);
        }
      }
      if (dataSource?.length === resourceFilter?.total) {
        const element = document.querySelector('.loading-list');
        element && element.remove();
      }
    }
  }, 300);

  useEffect(() => {
    const tableContent = document.querySelector('.ant-table-body');
    tableContent.addEventListener('scroll', handleScroll);
    return () => tableContent.removeEventListener('scroll', handleScroll);
  }, [handleScroll]);

  return (
    <TableStyle>
      <TableScrollCustom
        columns={columnsHasSorter}
        dataSource={dataSource}
        pagination={false}
        scroll={{ y: 500 }}
        scrollToFirstRowOnChange={false}
        isSorter
        loading={isSorting && loading}
        onChange={onChange}
        summary={() =>
          AverageAllCentres({ averageAllCentre, serviceAgeGroupCurrent })
        }
        rowClassName={(record) => formatRowData(record?.id)}
      />
    </TableStyle>
  );
};

PriceBenchmarkingTable.propTypes = {
  dataSource: PropTypes.array,
  retrieveList: PropTypes.func,
};

export default PriceBenchmarkingTable;

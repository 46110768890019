import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { Avatar, Image, Tag, Tooltip, Typography } from 'antd';
import {
  DollarSquareIcon,
  FormatCircleIcon,
  LanguageIcon,
  PersonalCardIcon,
  TeacherIcon,
} from 'components/common/SVGIcon';
import i18next from 'i18next';
import EditCustomBtn from 'components/RestActions/EditCustomBtn';
import CompanyDefaultLogo from 'assets/icons/company-default-logo.svg';
import useRolesJobTemplates from 'containers/Users/hooks/useRoleJobTemplates';
import { NO_DISCOUNTS } from 'containers/JobTemplates/constants';
import { isEmpty } from 'lodash-es';
import { JobTemplateInfoStyles } from './styles';
import useFormatFnc from '../JobTemplateList/useFormat';
import JobTemplateDetailItem from './JobTemplateDetailItem';
import BasicInformationGeneralModal from '../Modals/BasicInformationGeneralModal';

const JobTemplateInfo = ({ currentData }) => {
  const useFormat = useFormatFnc();
  const disabledAction = useRolesJobTemplates();

  const jobTemplateDetailModalRef = useRef();

  const openJobTemplateDetailModal = () => {
    jobTemplateDetailModalRef.current.toggleModal(currentData);
  };

  return (
    <>
      <JobTemplateInfoStyles>
        <EditCustomBtn
          action="isEdit"
          onClickButton={openJobTemplateDetailModal}
          disabled={disabledAction}
          className="text-right"
        />
        <div className="flex-center avatar-style">
          <Avatar
            src={currentData?.organization?.logoUrl || CompanyDefaultLogo}
            size={100}
          />
        </div>
        <div>
          <span className="fw-bold text-20">
            <span className="mr-12">
              {currentData?.templateName || i18next.t('error.waitingUpdate')}
            </span>
            <span className="text-14">
              {useFormat.formatJobTemplateStatus(currentData?.status)}
            </span>
          </span>
        </div>
        <div className="text-16 text-gray-neutral-500">
          {i18next.t('centres.lastUpdated', {
            time: currentData?.formattedUpdatedAt,
          })}
        </div>

        <div className="divider-info" />
        {currentData?.jobTitle && (
          <Typography.Text className="fw-bold text-20 mr-10">
            {currentData?.jobTitle}
          </Typography.Text>
        )}

        <div>
          <Tooltip title={i18next.t('jobs.jobTemplates.jobType')}>
            {useFormat.formatJobType(currentData?.jobType)}
          </Tooltip>
        </div>

        <Tooltip
          title={i18next.t('jobs.jobTemplates.salary')}
          className="flex text-16 fw-bold"
        >
          <div className="text-green text-18">
            <DollarSquareIcon />
          </div>
          <div className="text-dark-green ml-8">
            {currentData?.salary?.displayText ||
              i18next.t('error.waitingUpdate')}
          </div>
        </Tooltip>

        {currentData?.discount?.type?.name !== NO_DISCOUNTS && (
          <JobTemplateDetailItem
            title="jobs.jobTemplates.discount"
            icon={
              <Image
                className="flex-center"
                preview={false}
                src={currentData?.discount?.type?.iconUrl}
                width="18px"
                alt=""
              />
            }
            value={`${currentData?.discount?.value}% ${currentData?.discount?.type?.name}`}
            className="text-16"
          />
        )}

        <JobTemplateDetailItem
          title="jobs.basicInformation.qualification"
          icon={<TeacherIcon />}
          value={currentData?.qualification?.name}
          className="text-16"
        />

        <JobTemplateDetailItem
          title="jobs.basicInformation.experience"
          icon={<FormatCircleIcon />}
          value={currentData?.experience?.name}
          className="text-16"
        />

        <Tooltip
          title={i18next.t('jobs.basicInformation.languages')}
          className="flex text-16"
        >
          <div className="text-green text-18">
            <LanguageIcon />
          </div>
          <div className="ml-8">
            {isEmpty(currentData?.languages) ? (
              <span>{i18next.t('error.waitingUpdate')}</span>
            ) : (
              currentData?.languages?.map((item, index) => (
                <Tag key={String(index)} className="fw-600 text-14">
                  {item?.name}
                </Tag>
              ))
            )}
          </div>
        </Tooltip>

        <Tooltip
          title={i18next.t('jobs.basicInformation.workEligibility')}
          className="flex text-16"
        >
          <div className="text-green text-18">
            <PersonalCardIcon />
          </div>
          <div className="ml-8">
            {currentData?.eligibility?.displayText ||
              i18next.t('error.waitingUpdate')}
          </div>
        </Tooltip>
      </JobTemplateInfoStyles>
      <BasicInformationGeneralModal
        ref={jobTemplateDetailModalRef}
        record={currentData}
        title="jobs.basicInformation.editBasicInformation"
      />
    </>
  );
};

JobTemplateInfo.propTypes = {
  currentData: PropTypes.object,
};

export default JobTemplateInfo;

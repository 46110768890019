import React, { forwardRef, useImperativeHandle, useState } from 'react';
import ModalCustom from 'components/common/ModalCustom';
import MobilePreviewIframe from '../MobilePreviewIframe';

const MobilePreviewModal = forwardRef((props, ref) => {
  const [visible, setVisible] = useState(false);
  const [moreInformation, setMoreInformation] = useState();

  useImperativeHandle(
    ref,
    () => ({
      open: (data) => {
        setVisible(true);
        setMoreInformation(data);
      },
    }),
    [],
  );

  const onCancel = () => {
    setVisible(false);
  };

  return (
    <ModalCustom
      width={800}
      visible={visible}
      footer={null}
      onCancel={onCancel}
      resetPaddingTopBody={false}
      resetPaddingBottomBody={false}
    >
      <MobilePreviewIframe srcDoc={moreInformation} />
    </ModalCustom>
  );
});

export default MobilePreviewModal;

import React from 'react';
import { Button, Space } from 'antd';
import i18next from 'i18next';
import { CSVIcon, FolderOpenIcon } from 'components/common/SVGIcon';
import { useExportMarketingMetricsMutation } from 'redux/@rtkQuery/marketingMetris';
import { useDispatch } from 'react-redux';
import {
  setExportList,
  setVisibleDownloadPopup,
} from 'redux/exportProgress/slice';
import { GET_REPORT_METRICS_DETAIL_API } from 'api/marketingMetrics';
import { UseMutation } from '@reduxjs/toolkit/dist/query/react/buildHooks';
import { BaseQueryFn, MutationDefinition } from '@reduxjs/toolkit/dist/query';
import { ExportBtnStyle } from './styles';

interface ResultsProps {
  name?: string;
  userId?: string;
  type?: string;
  status?: string;
  exportTime?: Date | string;
  filterCondition?: string;
  url?: string | null;
  createdAt?: Date | string;
  updatedAt?: Date | string;
  id?: string;
  requestId?: string;
}

interface Props<TRecord> {
  disableExportButton?: boolean;
  useExportMutation?: UseMutation<
    MutationDefinition<any, BaseQueryFn, string, ResultsProps>
  >;
  reportDetailApi?: string;
  formatQueryParams: TRecord;
}

const ExportCsvActions = <TRecord,>({
  disableExportButton,
  useExportMutation = useExportMarketingMetricsMutation,
  reportDetailApi = GET_REPORT_METRICS_DETAIL_API,
  formatQueryParams,
}: Props<TRecord>) => {
  const dispatch = useDispatch();

  const [exportCsvReport] = useExportMutation();

  const handleExportCSV = async () => {
    const response = await exportCsvReport({
      ...formatQueryParams,
    });

    if ('data' in response) {
      dispatch(
        setExportList({
          ...response.data,
          refreshApi: {
            url: reportDetailApi,
            params: {
              id: response.data?.id,
            },
          },
        }),
      );

      dispatch(setVisibleDownloadPopup(true));
    }
  };

  const onClickViewExportLibrary = () => {
    window.open('/export-library');
  };

  return (
    <ExportBtnStyle>
      <Space size={24}>
        <Button
          onClick={handleExportCSV}
          icon={<CSVIcon className="text-18" />}
          className="btn-line-primary bg-transparent flex-center fw-bold"
          disabled={disableExportButton}
        >
          {i18next.t('marketingMetrics.export')}
        </Button>
        <span
          role="presentation"
          onClick={onClickViewExportLibrary}
          className="flex-center text-primary cursor-pointer fw-bold text-decoration-line"
        >
          <FolderOpenIcon className="text-20 mr-8" />
          {i18next.t('marketingMetrics.viewExportLibrary')}
        </span>
      </Space>
    </ExportBtnStyle>
  );
};

export default ExportCsvActions;

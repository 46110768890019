import React, { useContext, useEffect, useState } from 'react';
import EmptyData from 'components/common/EmptyData';
import PropTypes from 'prop-types';
import { Form } from 'antd';
import { RestInputContext } from 'components/RestInput/RestInputContext';
import i18next from 'i18next';
import renderHTML from 'react-render-html';
import CheckboxItem from './CheckboxItem';
import { ConfirmDeleteJobPopupStyle } from './styles';

const ListCheckboxSelect = ({ selectedRowKeyNoApply, data, setDisabled }) => {
  const defaultValue = selectedRowKeyNoApply?.map((item) => item?.id);
  const { form } = useContext(RestInputContext);
  const [checkedIds, setCheckedIds] = useState(defaultValue || []);

  const onAddItem = (id) => {
    const newIds = [...checkedIds, id];
    setCheckedIds(newIds);
    if (newIds?.length > 0) {
      setDisabled(false);
    }
  };

  const onRemoveItem = (id) => {
    const newIds = checkedIds?.filter((oldId) => oldId !== id);
    setCheckedIds(newIds);
    if (newIds?.length === 0) {
      setDisabled(true);
    }
  };

  useEffect(() => {
    form.setFieldsValue({
      postIds: checkedIds,
    });
  }, [checkedIds, form]);

  const renderConfirmPopup = () => {
    if (checkedIds?.length === 0) {
      return (
        <div className="text-16 fw-400">
          <strong className="mr-5 text-primary">{checkedIds?.length}</strong>
          <span>{i18next.t('jobListings.jobListingsSelected')}</span>
        </div>
      );
    }
    return (
      <ConfirmDeleteJobPopupStyle className="text-16 fw-400">
        {renderHTML(
          checkedIds?.length === 1
            ? i18next.t('jobListings.deleteIndividual.confirmSingleDelete', {
                jobNumber: 1,
              })
            : i18next.t('jobListings.deleteIndividual.confirmMultipleDelete', {
                jobNumber: checkedIds?.length,
              }),
        )}
      </ConfirmDeleteJobPopupStyle>
    );
  };

  return (
    <div>
      {renderConfirmPopup()}
      <div className="text-14">
        <Form.Item name="postIds">
          {data?.length ? (
            data.map((item) => (
              <CheckboxItem
                onAddItem={onAddItem}
                onRemoveItem={onRemoveItem}
                key={item?.id}
                item={item}
                checked={checkedIds?.includes(item?.id)}
                className="flex items-center"
              />
            ))
          ) : (
            <EmptyData />
          )}
        </Form.Item>
      </div>
    </div>
  );
};

ListCheckboxSelect.propTypes = {
  selectedRowKeyNoApply: PropTypes.array,
  data: PropTypes.array,
  setDisabled: PropTypes.func,
};

export default ListCheckboxSelect;

import React, { useCallback, useEffect } from 'react';
import { FormInstance } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import {
  getAdsFormConfigLoadingSelector,
  getCreateAdPayload,
  getSelectedAdTypeSelector,
} from 'redux/advertisement/selectors';
import { setCreateAdPayload } from 'redux/advertisement/slice';
import AdCreativeForm, {
  AdCreativeInitialValues,
} from 'containers/Advertisement/Create/Steps/AdCreative/AdCreativeForm';
import Footer from './Footer';
import { AdCreativeContainerStyle } from './styles';

interface AdCreateProps {
  form: FormInstance;
}

const AdCreative: React.FC<AdCreateProps> = ({ form }) => {
  const dispatch = useDispatch();
  const adType = useSelector(getSelectedAdTypeSelector);
  const adPayload = useSelector(getCreateAdPayload);
  const loading = useSelector(getAdsFormConfigLoadingSelector);

  const initialValues: AdCreativeInitialValues = {
    headline: adPayload.headline,
    description: adPayload.description,
    callingButton: adPayload.callingButton,
    destination: adPayload.destination,
  };

  const initialImages =
    adPayload.mediaList?.map(({ type, url }) => ({ type, url })) ?? [];

  useEffect(() => form.resetFields(), [form, adPayload]);

  const onBack = async () => {
    form.resetFields();
  };

  const onNext = useCallback(async () => {
    await form
      .validateFields([
        'headline',
        'description',
        'callingButton',
        'destination',
        'mediaList',
      ])
      .then(
        ({ headline, description, callingButton, destination, mediaList }) => {
          dispatch(
            setCreateAdPayload({
              headline,
              description,
              callingButton,
              destination,
              mediaList: mediaList?.map((v) => ({
                type: v.type?.includes('image') ? 'image' : 'video',
                url: v.url || v.response,
              })),
            }),
          );
          form.resetFields();
        },
      );
  }, [dispatch, form]);

  return (
    <AdCreativeContainerStyle>
      <AdCreativeForm
        form={form}
        initialValues={initialValues}
        initialImages={initialImages}
        currentAdType={adType}
        onNext={onNext}
      />

      <Footer onBack={onBack} onNext={onNext} isNextDisabled={loading} />
    </AdCreativeContainerStyle>
  );
};

export default AdCreative;

import React, { useCallback } from 'react';

import { useHistory } from 'react-router';
import { useSelector } from 'react-redux';
import { getCentresObjKeyById } from 'redux/auth/selectors';

import TagCustom from 'components/common/TagCustom';
import ImageField from 'components/RestField/ImageField';
import KindiRating from 'components/common/KindiRating';
import EditButton from 'components/RestActions/EditButton';
import { Progress } from 'antd';

const useFormat = () => {
  const { push } = useHistory();
  const centresObjKeyById = useSelector(getCentresObjKeyById);

  const formatCentreName = useCallback(
    (data) => <span className="t-bold">{data}</span>,
    [],
  );

  const formatSubscription = useCallback(
    (data, record) => {
      const packageName =
        centresObjKeyById?.[record?.id]?.centreSubscription?.CRMPackage?.name ||
        record?.CRMPackage?.name;
      return (
        <TagCustom
          color="#32A4FC"
          backgroundColor="#E9F4FF"
          name={packageName}
        />
      );
    },
    [centresObjKeyById],
  );

  const formatNQSRating = useCallback(
    (data, record = {}) => (
      <ImageField
        style={{ objectFit: 'contain', height: 40, width: 'auto' }}
        source="image"
        record={record.NQSRType}
      />
    ),
    [],
  );

  const formatKindiRating = useCallback(
    (data) => <KindiRating value={data} />,
    [],
  );

  const formatListStrength = useCallback(
    (data) => (
      <Progress
        strokeLinecap="square"
        className="pr-32"
        percent={data}
        format={(data) => `${data}%`}
      />
    ),
    [],
  );

  const formatGroupAction = useCallback(
    (id) => (
      <EditButton
        isView
        source="show"
        onClickCustom={() => {
          localStorage.removeItem('currentStep');
          push(`/centres/${id}/show/summary`);
        }}
      />
    ),
    [push],
  );

  return {
    formatCentreName,
    formatSubscription,
    formatNQSRating,
    formatKindiRating,
    formatGroupAction,
    formatListStrength,
  };
};

export default useFormat;

import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { Form } from 'antd';
import ModalCustom from 'components/common/ModalCustom';
import PropTypes from 'prop-types';
import { RestInputContext } from 'components/RestInput/RestInputContext';
import i18next from 'i18next';
import moment from 'moment';
import {
  editJobListing,
  getJobListingsDetail,
} from 'redux/jobs/jobListings/actions';
import { useDispatch } from 'react-redux';
import { handleApiError } from 'utils/validateUtils';
import JobListingDetailForm from '../Form/JobListingDetailForm';

const JobListingDetailModal = forwardRef(({ record }, ref) => {
  const [visible, setVisible] = useState(false);
  const [form] = Form.useForm();

  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  useImperativeHandle(
    ref,
    () => ({
      open: (initialValue) => {
        setVisible(true);
        form.setFieldsValue({
          ...initialValue,
          campaignId: initialValue?.campaignId,
          startDate: moment(initialValue?.startDate),
          applicationDeadline: moment(initialValue?.applicationDeadline),
          workplace: initialValue?.organization?.name,
        });
      },
    }),
    [form],
  );

  const onCancel = () => {
    setVisible(false);
    form.resetFields();
  };

  const onFinish = () => {
    form.validateFields().then((values) => {
      setLoading(true);
      dispatch(
        editJobListing({
          postId: record?.id,
          data: {
            jobReference: values?.jobReference,
            campaignId: values?.campaignId,
            applyEndDate: moment(values?.applicationDeadline),
          },
        }),
      )
        .then(({ payload }) => {
          handleApiError(payload?.data, form);
          if (payload?.data?.id) {
            onCancel();
            dispatch(getJobListingsDetail({ id: record?.id }));
          }
        })
        .finally(() => {
          setLoading(false);
        });
    });
  };

  return (
    <ModalCustom
      title={i18next.t('jobListings.jobListingDetails.editJobListing')}
      onCancel={onCancel}
      visible={visible}
      onOk={form.submit}
      record={record}
      width={900}
      loading={loading}
    >
      <Form
        form={form}
        layout="vertical"
        scrollToFirstError
        onFinish={onFinish}
      >
        <RestInputContext.Provider value={{ form }}>
          <JobListingDetailForm record={record} />
        </RestInputContext.Provider>
      </Form>
    </ModalCustom>
  );
});

JobListingDetailModal.propTypes = {
  record: PropTypes.object,
};

export default JobListingDetailModal;

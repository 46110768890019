import React from 'react';
import { CrownFilled } from '@ant-design/icons';
import { Button } from 'antd';
import i18next from 'i18next';
import PropTypes from 'prop-types';
import { omit } from 'lodash-es';

import { upgradeModal } from 'components/common/CheckPermissions/UpgradeSubscriptionModal';

const BtnFallback = ({ title, buttonProps }) => {
  const onClickButton = () => {
    upgradeModal.open();
  };

  const restButtonProps = omit(buttonProps, 'htmlType');

  return (
    <div className="normal-action-wrapper">
      <Button onClick={onClickButton} {...restButtonProps}>
        {i18next.t(title)}
      </Button>
      <CrownFilled className="action-feature-icon" />
    </div>
  );
};

BtnFallback.propTypes = {
  title: PropTypes.string,
  buttonProps: PropTypes.object,
};

export default BtnFallback;

import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import i18next from 'i18next';
import { useSelector } from 'react-redux';
import { centresSelectors } from 'redux/centres/selectors';

import TableCustom from 'components/common/TableCustom';
import { formatDateFromNow } from 'utils/textUtils';
import { CENTRE_STATUS_VALUE } from 'configs/localData';
import useFormatFnc from './useFormat';

const CentresList = ({ resourceData, retrieveList, pushQuery }) => {
  const useFormat = useFormatFnc();

  const resourceFilter = useSelector(centresSelectors.getFilters) || {};
  const loading = useSelector((state) => state.centres.loading);

  const getSorterOrder = useCallback(
    (source) => {
      if (resourceFilter.orderBy === source) return 'ascend';
      if (resourceFilter.orderBy === `-${source}`) return 'descend';
      return '-updatedAt';
    },
    [resourceFilter.orderBy],
  );

  const columns = useMemo(
    () => [
      {
        title: i18next.t('centres.name'),
        dataIndex: 'name',
        width: '15%',
        render: useFormat.formatCentreName,
        sorter: () => null,
        defaultSortOrder: getSorterOrder('name'),
      },
      {
        title: i18next.t('centres.provider'),
        dataIndex: 'provider',
        render: (value) => value?.name,
        sorter: () => null,
        defaultSortOrder: getSorterOrder('name'),
      },
      {
        title: i18next.t('centres.brandTitle'),
        dataIndex: 'brand',
        render: (value) => value?.name,
      },
      {
        title: i18next.t('centres.companyTitle'),
        dataIndex: 'company',
        render: (value) => value?.name,
      },
      {
        title: i18next.t('centres.suburb'),
        dataIndex: 'city',
        sorter: () => null,
        defaultSortOrder: getSorterOrder('city'),
      },
      {
        title: i18next.t('centres.subscription'),
        dataIndex: 'subscription',
        render: (value, record) =>
          record?.claimStatus === CENTRE_STATUS_VALUE.claimed &&
          useFormat.formatSubscription(value, record),
        sorter: () => null,
        defaultSortOrder: getSorterOrder('subscription'),
      },
      {
        title: i18next.t('centres.NQSRating'),
        dataIndex: 'currentOverallNQSRating',
        render: useFormat.formatNQSRating,
        sorter: () => null,
        defaultSortOrder: getSorterOrder('currentOverallNQSRating'),
      },
      {
        title: i18next.t('centres.rating'),
        dataIndex: 'kindiCareRating',
        render: useFormat.formatKindiRating,
        sorter: () => null,
        defaultSortOrder: getSorterOrder('kindiCareRating'),
      },
      {
        title: i18next.t('centres.listingStrength'),
        dataIndex: 'totalListingStrength',
        render: useFormat.formatListStrength,
        sorter: () => null,
        defaultSortOrder: getSorterOrder('totalListingStrength'),
        width: 250,
      },
      {
        title: i18next.t('centres.capacity'),
        dataIndex: 'placeAllowed',
        sorter: () => null,
        defaultSortOrder: getSorterOrder('placeAllowed'),
      },
      {
        title: i18next.t('centres.services'),
        dataIndex: 'totalServices',
        sorter: () => null,
        defaultSortOrder: getSorterOrder('totalServices'),
      },
      {
        title: i18next.t('centres.waitlist'),
        dataIndex: 'totalWaitListed',
        sorter: () => null,
        defaultSortOrder: getSorterOrder('totalWaitListed'),
      },
      {
        title: i18next.t('centres.lastUpdated', { time: undefined }),
        dataIndex: 'updatedAt',
        render: (text) => formatDateFromNow(text),
      },
      {
        title: '',
        dataIndex: 'id',
        width: 80,
        fixed: 'right',
        render: useFormat.formatGroupAction,
      },
    ],
    [getSorterOrder, useFormat],
  );

  const onChange = useCallback(
    (e, filters, sorter) => {
      const formatSort =
        sorter && sorter.field && sorter.order
          ? `${sorter.order === 'descend' ? '-' : ''}${sorter.field}`
          : '-updatedAt';

      const restFilter = {
        offset: (e.current - 1) * e.pageSize,
        limit: e.pageSize,
        orderBy: formatSort,
        filter: resourceFilter.filter,
      };

      retrieveList({
        filter: restFilter,
        isRefresh: true,
      });

      pushQuery(restFilter);
    },
    [pushQuery, retrieveList, resourceFilter.filter],
  );

  return (
    <TableCustom
      xScroll={2000}
      isSorter
      onChange={onChange}
      data={resourceData}
      columns={columns}
      loading={loading}
      pagination={{
        pageSize: resourceFilter.limit,
        current: resourceFilter.offset / resourceFilter.limit + 1,
        total: resourceFilter.count,
      }}
    />
  );
};

CentresList.propTypes = {
  resourceData: PropTypes.array,
  retrieveList: PropTypes.func,
  pushQuery: PropTypes.func,
};

CentresList.defaultProps = {
  retrieveList: () => null,
  pushQuery: () => null,
};

export default CentresList;

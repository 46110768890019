import React from 'react';
import { Result } from 'antd';
import i18next from 'i18next';

const ReminderSelectCentre = () => (
  <Result
    status="warning"
    title={i18next.t('subscriptions.reminderSelectCentre')}
  />
);

export default ReminderSelectCentre;

import React from 'react';
import i18next from 'i18next';
import styled from 'styled-components';

const StepNumber = styled.div`
  min-width: 32px;
  height: 32px;
  width: 32px;
  -font-size: 16px;
  font-size: var(--font-size);
  @media only screen and (min-width: 2000px) {
    height: 37px;
    width: 37px;
    --font-size: 18px;
  }

  @media only screen and (min-width: 2400px) {
    height: 42px;
    width: 42px;
    --font-size: 20px;
  }

  @media only screen and (min-width: 3000px) {
    height: 47px;
    width: 47px;
    --font-size: 24px;
  }

  @media only screen and (min-width: 3400px) {
    height: 60px;
    width: 60px;
    --font-size: calc(18px + 0.390625vw);
  }
`;

export const StepText = styled.div`
  --font-size: 28px;
  font-size: var(--font-size);

  @media only screen and (min-width: 2000px) {
    --font-size: 33px;
  }

  @media only screen and (min-width: 2400px) {
    --font-size: 38px;
  }

  @media only screen and (min-width: 3000px) {
    --font-size: 43px;
  }

  @media only screen and (min-width: 3400px) {
    --font-size: calc(35px + 0.390625vw);
  }

  @media (max-width: 996px) {
    font-size: 22px !important;
  }

  @media (max-width: 768px) {
    font-size: 20px !important;
  }
`;

interface Props {
  stepNumber: number;
  title: string;
  className?: string;
  color?: string;
}

const StepTitle = ({ stepNumber, title, color, className }: Props) => (
  <div className={`flex gap-16 items-center mb-16 ${className}`}>
    <StepNumber
      className="bg-primary rounded-full flex justify-center items-center text-white fw-bold"
      style={{ backgroundColor: color ?? '#DB147F' }}
    >
      {stepNumber}
    </StepNumber>
    <StepText className="text-primary fw-bold" style={{ color }}>
      {i18next.t(title)}
    </StepText>
  </div>
);

export default StepTitle;

import React, { useState } from 'react';
import ModalCustom from 'components/common/ModalCustom';
import { Button, Checkbox, Space, Typography } from 'antd';
import i18next from 'i18next';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import { ADS_IDENTITY_KEY } from 'containers/Advertisement/constants';

interface IdentityModalProps {
  visible: boolean;
  setIsChildcareRelated: (boolean) => void;
}

const IdentityModal = ({
  visible,
  setIsChildcareRelated,
}: IdentityModalProps) => {
  const [isShowAdsIdentity, setIsShowAdsIdentity] = useState(false);

  const currentUserId = useSelector(
    (state: RootState) => state?.auth?.data?.id,
  );

  const usersSettingIdentity =
    JSON.parse(localStorage.getItem(ADS_IDENTITY_KEY)) || [];

  const handleOnSubmit = () => {
    usersSettingIdentity.push({
      currentUserId,
    });

    if (isShowAdsIdentity) {
      localStorage.setItem(
        ADS_IDENTITY_KEY,
        JSON.stringify(usersSettingIdentity),
      );
    }
    setIsChildcareRelated(false);
  };

  const onShowIdentity = (e) => {
    if (e.target.checked) {
      setIsShowAdsIdentity(true);
    } else {
      setIsShowAdsIdentity(false);
    }
  };

  return (
    <ModalCustom
      isShowCancel
      isScrollY={false}
      loading={false}
      isDisabled={false}
      width={560}
      okText={i18next.t('button.ok')}
      cancelText={i18next.t('button.cancel')}
      className="text-16"
      resetPaddingTopBody={false}
      resetPaddingBottomBody={false}
      visible={visible}
      closable={false}
      footer={[
        <Space className="flex-center-between">
          <Checkbox
            className="text-16"
            checked={isShowAdsIdentity}
            onChange={onShowIdentity}
          >
            {i18next.t('common.noAskAgain')}
          </Checkbox>
          <Button
            type="primary"
            className="uppercase-text"
            onClick={handleOnSubmit}
          >
            {i18next.t('button.ok')}
          </Button>
        </Space>,
      ]}
    >
      <Typography.Text className="text-16">
        {i18next.t('advertisement.identityOptions.contentIdentity')}
      </Typography.Text>
    </ModalCustom>
  );
};

export default IdentityModal;

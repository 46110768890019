import styled from 'styled-components';

export const SuccessClaimWrapper = styled.div`
  position: relative;
  height: 100%;
  z-index: 2;
  flex-direction: column;

  h3 {
    position: relative;
    z-index: 2;
    font-weight: 700;
    font-size: 28px;
    line-height: 1.33;
    color: ${({ theme }) => theme.palette.primary};
  }

  p {
    position: relative;
    z-index: 2;
    margin-top: 24px;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #141533;
    white-space: pre-line;
    margin-left: 78px;
  }

  a {
    color: ${({ theme }) => theme.palette.primary};
  }

  br {
    display: block;
    margin-bottom: 16px;
  }

  @media (max-width: 768px) {
    h3 {
      font-size: 24px;
      line-height: 30px;
    }

    p {
      font-size: 14px;
      line-height: 24px;
    }
  }

  @media (max-width: 562px) {
    h3 {
      font-size: 18px;
      line-height: 28px;
    }
    p {
      margin-left: 0;
    }
  }

  @media only screen and (min-width: 2000px) {
    h3 {
      font-size: 32px;
    }

    p {
      font-size: 20px;
      line-height: 1.7;
    }

    .success-tick {
      width: 80px;
      height: 80px;
    }
  }

  @media only screen and (min-width: 2400px) {
    h3 {
      font-size: 34px;
    }
    p {
      font-size: 25px;
    }
  }

  @media only screen and (min-width: 3000px) {
    h3 {
      font-size: 40px;
    }
    p {
      font-size: 30px;
    }
  }

  @media only screen and (min-width: 3400px) {
    h3 {
      font-size: calc(28px + 0.390625vw);
    }
    p {
      font-size: calc(16px + 0.390625vw);
    }
  }
`;

export const SuccessLayerImage = styled.img`
  z-index: 1;
  position: absolute;
  bottom: 0;
  right: 0;
  width: 200px;
  height: 200px;
  animation: scaleImage 2s;
  animation-fill-mode: forwards;
  transition: all 0.3s;

  @keyframes scaleImage {
    0% {
      scale: 1;
    }
    100% {
      scale: 1.4;
    }
  }
`;

export const ConfettiTopRight = styled.img`
  position: absolute;
  top: -55px;
  right: 0;

  @media (max-width: 768px) {
    width: 150px;
  }
`;

export const ConfettiBottom = styled.img`
  position: absolute;
  bottom: 0;
  width: 100%;
`;

import React, { useEffect } from 'react';
import { Button, Modal, Spin, Typography } from 'antd';
import i18next from 'i18next';
import CustomBreadcrumb from 'components/common/Breadcrumb';
import { useDispatch, useSelector } from 'react-redux';
import {
  closeAds,
  getAdDetail,
  getAdsFormConfig,
} from 'redux/advertisement/actions';
import { useParams } from 'react-router';
import {
  getAdDetailData,
  getDetailAdsLoadingSelector,
} from 'redux/advertisement/selectors';
import AdvertisementDetail from '../components/AdvertisementDetail';
import { DELIVERY_STATUS } from '../constants';

const AdvertisementShow = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const currentData = useSelector(getAdDetailData);
  const loading = useSelector(getDetailAdsLoadingSelector);

  useEffect(() => {
    if (id) {
      dispatch(getAdDetail(id));
      dispatch(getAdsFormConfig());
    }
  }, [id, dispatch]);

  const customBreadcrumb = [
    {
      title: i18next.t('sideBar.marketing'),
      path: '#',
    },
    {
      title: i18next.t('advertisement.title'),
      path: '/advertisement',
    },
    {
      title: currentData?.headline,
      path: '#',
    },
  ];

  const handleCloseAds = () => {
    Modal.confirm({
      title: i18next.t('advertisement.closeAdConfirm'),
      okText: i18next.t('advertisement.button.closeAds'),
      onOk: () => {
        id && dispatch(closeAds(id));
      },
    });
  };

  return (
    <div>
      {loading ? (
        <Spin className="flex-center" />
      ) : (
        <>
          <CustomBreadcrumb data={customBreadcrumb} />
          <div className="flex flex-row flex-center-between">
            <Typography.Text className="text-32 fw-bold">
              {i18next.t('advertisement.titleDetail')}
            </Typography.Text>
            <Button
              className="btn-line-primary fw-bold"
              onClick={handleCloseAds}
              disabled={
                currentData?.deliveryStatus?.value !== DELIVERY_STATUS.active
              }
            >
              {i18next.t('advertisement.button.closeAds')}
            </Button>
          </div>
          <AdvertisementDetail currentData={currentData} />
        </>
      )}
    </div>
  );
};

export default AdvertisementShow;

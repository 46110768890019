import React from 'react';
import PropTypes from 'prop-types';
import SwitchOneCentre from '../SwitchOneCentre';

const CentreItem = ({ item, keyNotify }) => (
  <div className="centre-item flex-center-between">
    <span>{item?.centre?.name}</span>
    <SwitchOneCentre item={item} keyNotify={keyNotify} />
  </div>
);

CentreItem.propTypes = {
  item: PropTypes.object,
  keyNotify: PropTypes.string,
};

export default CentreItem;

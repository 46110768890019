import React, { useCallback, useContext, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Col, Form, Row } from 'antd';
import RestSelect from 'components/RestInput/RestSelect';
import i18next from 'i18next';
import { LABEL_TABLE_KEY } from 'configs/localData/constant';
import { formatLabelTable } from 'containers/Centres/components/CentreTabs/ServicesTab/utils';
import { useDispatch, useSelector } from 'react-redux';
import { getAllCompanyBrands } from 'redux/companyBrands/actions';
import { useLocation } from 'react-router';
import { getFilterFromUrl } from 'utils/tools';
import { companyBrandsSelectors } from 'redux/companyBrands/selectors';
import { RestInputContext } from 'components/RestInput/RestInputContext';
import { DEFAULT_ORDER_BY } from 'containers/CompanyBenchmarking/localData/constants';
import { COMPANY_BRANDS_LIMIT } from 'containers/BrandBenchmarking/localData/constants';

const API_RESOURCE = 'company-brands';
const SelectFilterForm = ({
  ownedCompanies,
  brandsOfCompanyDropdown,
  nationalStatesDropdown,
  primaryChildCareServicesDropdown,
  setCheckCompanyChange,
}) => {
  const appSettings = useSelector((state) => state.config?.data?.AppSetting);
  const dispatch = useDispatch();
  const brandsOfCompany = useSelector(companyBrandsSelectors.getDataArr);
  const { form } = useContext(RestInputContext);
  const { search } = useLocation();
  const { filter } = getFilterFromUrl(search);
  const filterRef = useRef(filter);
  const { limit, offset, total, loading } = useSelector(
    (state) => state.brands,
  );

  const primaryChildCareServicesLabel = formatLabelTable(
    LABEL_TABLE_KEY.childCareService,
    appSettings,
  );

  const getCompanyOwnedBrands = useCallback(
    ({ filter = {}, isRefresh }) => {
      dispatch(
        getAllCompanyBrands({
          data: {
            orderBy: DEFAULT_ORDER_BY,
            ...filter,
          },
          options: {
            isRefresh,
            customApiResource: API_RESOURCE,
          },
        }),
      );
    },
    [dispatch],
  );

  useEffect(() => {
    if (ownedCompanies?.length > 0) {
      getCompanyOwnedBrands({
        filter: {
          offset: 0,
          companyId: filterRef.current?.companyId || ownedCompanies[0]?.id,
          limit: COMPANY_BRANDS_LIMIT,
        },
        isRefresh: true,
      });
    }
  }, [getCompanyOwnedBrands, ownedCompanies]);

  const handleSelectCompany = (id) => {
    if (id) {
      getCompanyOwnedBrands({
        filter: {
          limit: COMPANY_BRANDS_LIMIT,
          offset: 0,
          companyId: id,
        },
        isRefresh: true,
      });
      setCheckCompanyChange(true);
    }
  };

  const handleScroll = (e) => {
    const { target } = e;
    if (
      Math.round(target.scrollTop, 0) + target.offsetHeight ===
      target.scrollHeight
    ) {
      const newOffset = limit + offset;
      if (brandsOfCompany?.length < total) {
        getCompanyOwnedBrands({
          filter: {
            limit: 10,
            offset: newOffset,
            companyId: form.getFieldValue('companyId'),
          },
        });
      }
    }
  };

  return (
    <div>
      <Row gutter={32}>
        <Col xl={12} lg={12} md={24} xs={24}>
          <RestSelect
            required
            source="companyId"
            placeholder={i18next.t('input.company.placeholder')}
            header={i18next.t('input.company.name')}
            valueProp="id"
            titleProp="name"
            resourceData={ownedCompanies}
            onChange={handleSelectCompany}
          />
        </Col>
        <Col md={12} xs={24}>
          <Form.Item
            noStyle
            shouldUpdate={(prevValues, curValues) =>
              prevValues.companyId !== curValues.companyId
            }
          >
            {({ getFieldValue }) => (
              <RestSelect
                required
                source="brandId"
                titleProp="name"
                valueProp="id"
                header="centres.brand"
                placeholder="input.brand.placeholder"
                isFilterOption={false}
                disabled={getFieldValue('companyId') === undefined}
                resourceData={brandsOfCompanyDropdown}
                onPopupScroll={handleScroll}
                loading={loading}
              />
            )}
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={32}>
        <Col xl={12} lg={12} md={24} xs={24}>
          <RestSelect
            required
            source="state"
            placeholder={i18next.t('input.state.placeholder')}
            header={i18next.t('input.state.name')}
            resourceData={nationalStatesDropdown}
          />
        </Col>
        <Col xl={12} lg={12} md={24} xs={24}>
          <RestSelect
            required
            source="primaryChildCareServiceId"
            placeholder={i18next.t(
              'input.primaryChildcareServiceType.placeholder',
            )}
            header={primaryChildCareServicesLabel}
            valueProp="id"
            titleProp="name"
            formatText={(data) => i18next.t(data)}
            resourceData={primaryChildCareServicesDropdown}
          />
        </Col>
      </Row>
    </div>
  );
};

SelectFilterForm.propTypes = {
  ownedCompanies: PropTypes.array,
  brandsOfCompanyDropdown: PropTypes.array,
  nationalStatesDropdown: PropTypes.array,
  primaryChildCareServicesDropdown: PropTypes.array,
  setCheckCompanyChange: PropTypes.func,
};

export default SelectFilterForm;

import React from 'react';
import StepTitle from 'containers/ClaimCentres/components/StepTitle';
import SearchBar from 'containers/ClaimCentres/components/SearchCentres/SearchBar';
import Brands from 'containers/ClaimCentres/components/SearchCentres/Brands';
import KindiCareQuotes from 'containers/ClaimCentres/components/SearchCentres/Quotes';
import { CircleGroupIcon, MessageIcon } from 'components/common/SVGIcon';
import {
  CircleStyle,
  ClaimContentStyle,
  ContentStyle,
  MiddleStyle,
  QuoteStyle,
} from 'containers/ClaimCentres/styles';
import renderHTML from 'react-render-html';
import { Button } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import useWindowSize from 'hooks/useWindowSize';
import { GroupButtonStyle } from './styles';

const ClaimContent = () => {
  const { t } = useTranslation();
  const { width } = useWindowSize();

  return (
    <ClaimContentStyle>
      <div className="header">
        <StepTitle stepNumber={1} title="centresClaim.findYourCentreService" />
        <SearchBar />
      </div>

      <CircleStyle>
        <CircleGroupIcon className="circle" />
      </CircleStyle>

      <MiddleStyle className="w-full relative">
        <ContentStyle className="absolute left-20 bottom-130 w-full text-20">
          <div className="review-text">
            {renderHTML(`<span style="color:#DB147F">Loved and trusted by over 70%</span> of
        Australia's<br/> top early learning providers`)}
          </div>
          <Brands />
        </ContentStyle>
        <QuoteStyle className="absolute">
          <KindiCareQuotes />
        </QuoteStyle>
        <div className="absolute top-140 w-full">
          {width <= 992 && (
            <GroupButtonStyle>
              <Button
                className="btn-book-demo fw-bold"
                icon={<MessageIcon />}
                target={'_blank'}
                href={`${process.env.REACT_APP_RW_URL}/contact-us?reason=Book+a+Demo`}
              >
                {t('button.bookADemo')}
              </Button>
              <Button
                target={'_blank'}
                icon={<ExclamationCircleOutlined />}
                className="btn-learn-more ml-24 fw-bold"
                href={`${process.env.REACT_APP_RW_URL}/for-childcare-providers`}
              >
                {t('button.learnMore')}
              </Button>
            </GroupButtonStyle>
          )}
        </div>
      </MiddleStyle>
    </ClaimContentStyle>
  );
};

export default ClaimContent;

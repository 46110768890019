import React, { useState } from 'react';
import { useParams } from 'react-router';

import CreateButton from 'components/RestActions/CreateButton';
import CreateDocumentModal from './CreateDocumentModal';

const CreateDocumentButton = () => {
  const [visible, setVisible] = useState(false);

  const toggleModal = () => {
    setVisible(!visible);
  };

  const { id } = useParams();

  return (
    <>
      <CreateButton
        permissionKey="applications"
        action="isEdit"
        onClickCustom={toggleModal}
      />
      <CreateDocumentModal
        visible={visible}
        onCancel={toggleModal}
        applicationId={id}
      />
    </>
  );
};

export default CreateDocumentButton;

import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, Switch } from 'react-router-dom';
import { flatMap, map } from 'lodash-es';
import { withTranslation } from 'react-i18next';
import { getConfig, getConfigV2 } from 'redux/config/actions';
import { getApplicationPendingCount } from 'redux/applications/actions';
import Home from 'pages/Dashboard';
import Profile from 'pages/Profile';
import PrivateLayout from 'layout/PrivateLayout';
import NotificationsAndAlerts from 'pages/NotificationsAndAlerts';
import Contacts from 'pages/Contacts';
import Transactions from 'pages/Transactions';
import Applications from 'pages/Applications';
import Customers from 'pages/Customers';
import CentreServices from 'pages/CentreServices';
import Enquiries from 'pages/Enquiries';
import Centres from 'pages/Centres';
import Users from 'pages/Users';
import Roles from 'pages/Roles';
import Config from 'pages/Config';
import VisitsTours from 'pages/VisitsTours';
import MerchantSignUp from 'pages/MerchantSignUp';
import Subscriptions from 'pages/Subscriptions';
import useCheckPermission from 'components/common/CheckPermissions/useCheckPermission';
import LeadActivities from 'pages/ListingPerformance/LeadActivities';
import { getTotalUnreadEnquires } from 'redux/enquiries/actions';
import AutomatedResponsesPage from 'pages/AutomatedResponses';
import PriceBenchmarking from 'pages/Insights/PriceBenchmarking';
import CompanyBenchmarking from 'pages/Insights/CompanyBenchmarking';
import BrandBenchmarking from 'pages/Insights/BrandBenchmarking';
import JobTemplates from 'pages/Jobs/JobTemplates';
import CompanyProfile from 'pages/Jobs/CompanyProfile';
import JobApplications from 'pages/Jobs/JobApplications';
import JobListings from 'pages/Jobs/JobListings';
import Advertisement from 'pages/Marketing/Advertisement';
import { getTotalSubmittedJobApplications } from 'redux/jobs/jobApplications/actions';
import { getActiveOnJobModulePermission } from 'redux/auth/selectors';
import MarketingMetrics from 'pages/ListingPerformance/MarketingMetrics';
import { AppDispatch, RootState } from 'redux/store';
import ProgramsAndLearningContainer from 'containers/ProgramsAndLearning';
import Resources from '../../pages/Resources';
import PrivateRoute from './PrivateRoute';

interface RouteObject {
  action?: string;
  component?: React.ComponentType;
  isSkipSubscription?: boolean;
  path?: string;
  permissionKey?: string;
  exact?: boolean;
  routes?: RouteObject[];
  title?: string;
  hasPrivateLayoutWrapper?: boolean;
}

const routes: RouteObject[] = [
  {
    path: '/visits&tours',
    component: VisitsTours,
  },
  {
    path: '/digital-tiles',
    action: 'isView',
    component: Resources,
    exact: true,
  },
  {
    path: '/export-library',
    action: 'isView',
    component: Resources,
    exact: true,
  },
  {
    path: '/marketing-metrics',
    exact: true,
    routes: [
      {
        path: '/',
        component: MarketingMetrics.List,
        action: 'isView',
        isSkipSubscription: true,
      },
    ],
  },
  {
    path: '/lead-activities',
    exact: true,
    routes: [
      {
        path: '/',
        component: LeadActivities.List,
        action: 'isView',
        isSkipSubscription: true,
      },
    ],
  },
  {
    path: '/priceBenchmarking/:model',
    component: PriceBenchmarking,
    exact: true,
  },
  {
    path: '/companyBenchmarking',
    component: CompanyBenchmarking,
  },
  {
    path: '/brandBenchmarking',
    component: BrandBenchmarking,
  },
  {
    path: '/contacts/:model',
    routes: [
      {
        path: '/',
        component: Contacts.List,
        permissionKey: 'contacts',
        action: 'isView',
        exact: true,
      },
      {
        path: '/:id/show',
        component: Contacts.Show,
        permissionKey: 'contacts',
        action: 'isView',
      },
    ],
  },
  {
    path: '/subscriptions',
    exact: true,
    routes: [
      {
        path: '/',
        component: Subscriptions.List,
        permissionKey: 'subscriptionLevel',
        action: 'isView',
        isSkipSubscription: true,
      },
      {
        path: '/:id/sign-up-results/:model',
        component: Subscriptions.ResultSignUp,
        permissionKey: 'subscriptionLevel',
        action: 'isView',
        isSkipSubscription: true,
      },
    ],
  },
  {
    path: '/centres',
    routes: [
      {
        path: '/',
        component: Centres.List,
        permissionKey: 'myCentres',
        action: 'isView',
      },
      {
        path: '/:id/marketings/:marketingId/sign-up-results',
        component: Centres.ResultFeaturedMarketing,
        exact: true,
      },
      {
        path: '/:id/show/:modelDetail',
        component: Centres.Show,
        exact: true,
        permissionKey: 'myCentres',
        action: 'isView',
      },
    ],
  },

  {
    path: '/advertisement',
    routes: [
      {
        path: '/',
        component: Advertisement.List,
      },
      {
        path: '/:id/show',
        component: Advertisement.Show,
      },
    ],
  },

  {
    path: '/centres/:model',
    component: Centres.List,
    exact: true,
    permissionKey: 'myCentres',
    action: 'isView',
  },

  {
    path: '/transactions',
    routes: [
      {
        path: '/',
        component: Transactions.List,
        permissionKey: 'transactions',
        action: 'isView',
      },
    ],
  },

  {
    path: '/applications',
    routes: [
      {
        path: '/',
        component: Applications.List,
        permissionKey: 'applications',
        action: 'isView',
      },
      {
        path: '/create',
        component: Applications.Create,
      },
      {
        path: '/:id/show/:modelDetail',
        component: Applications.Show,
        exact: true,
        permissionKey: 'applications',
        action: 'isView',
      },
      {
        path: '/:id/show',
        component: Applications.Show,
        exact: true,
        permissionKey: 'applications',
        action: 'isView',
      },
    ],
  },

  {
    path: '/applications/:model',
    component: Applications.List,
    exact: true,
    permissionKey: 'applications',
    action: 'isView',
  },

  {
    path: '/customers',
    routes: [
      {
        path: '/',
        component: Customers.List,
      },
      {
        path: '/:id/show',
        component: Customers.Show,
      },
    ],
  },

  {
    path: '/centreServices',
    routes: [
      {
        path: '/',
        component: CentreServices.List,
      },
      {
        path: '/create',
        component: CentreServices.Create,
      },
      {
        path: '/:id/edit',
        component: CentreServices.Edit,
      },
    ],
  },
  {
    path: '/enquiries',
    routes: [
      {
        path: '/automatedResponses',
        component: AutomatedResponsesPage,
        permissionKey: 'enquiries',
        action: 'isView',
        exact: true,
      },
      {
        path: '/:tab/:id',
        component: Enquiries.List,
        permissionKey: 'enquiries',
        action: 'isView',
      },
    ],
  },

  {
    path: '/profile',
    component: Profile,
    exact: true,
    title: 'profile.title',
  },
  {
    path: '/merchant-sign-up/:id',
    component: MerchantSignUp,
  },
  {
    path: '/config',
    routes: [
      {
        path: '/',
        component: Config,
        permissionKey: 'settings',
        action: 'isView',
      },
      {
        path: '/notificationsAndAlerts',
        component: NotificationsAndAlerts.List,
        permissionKey: 'myCentres',
        action: 'isView',
      },
    ],
  },
  {
    path: '/config/centres',
    routes: [
      {
        path: '/',
        component: Centres.List,
        permissionKey: 'myCentres',
        action: 'isView',
      },
      {
        path: '/:id/show',
        component: Centres.Show,
        permissionKey: 'myCentres',
        action: 'isView',
      },
    ],
  },
  {
    path: '/config/roles',
    routes: [
      {
        path: '/',
        component: Roles.List,
        permissionKey: 'settings',
        action: 'isView',
      },
      {
        path: '/create',
        component: Roles.Create,
        permissionKey: 'settings',
        action: 'isEdit',
      },
      {
        path: '/:id/edit',
        component: Roles.Edit,
        permissionKey: 'settings',
        action: 'isEdit',
      },
    ],
  },
  {
    path: '/config/users',
    routes: [
      {
        path: '/',
        component: Users.List,
        permissionKey: 'abilityChangeUserPermissions',
        action: 'isView',
        isSkipSubscription: true,
      },
      {
        path: '/create',
        component: Users.Create,
        permissionKey: 'abilityChangeUserPermissions',
        action: 'isEdit',
        isSkipSubscription: true,
      },
      {
        path: '/:id/show',
        component: Users.Show,
        permissionKey: 'abilityChangeUserPermissions',
        action: 'isView',
        isSkipSubscription: true,
        exact: true,
      },
      {
        path: '/:id/show/addMoreCentres',
        component: Users.AddMoreCentres,
        permissionKey: 'abilityChangeUserPermissions',
        action: 'isEdit',
        isSkipSubscription: true,
        exact: true,
      },
      {
        path: '/:id/show/addMoreProviders',
        component: Users.AddMoreProviders,
        permissionKey: 'abilityChangeUserPermissions',
        action: 'isEdit',
        isSkipSubscription: true,
        exact: true,
      },
      {
        path: '/:id/show/addMoreCompanies',
        component: Users.AddMoreCompanies,
        permissionKey: 'abilityChangeUserPermissions',
        action: 'isEdit',
        isSkipSubscription: true,
        exact: true,
      },
    ],
  },
  {
    path: '/',
    component: Home,
    exact: true,
    title: 'dashboard.title',
    hasPrivateLayoutWrapper: true,
    permissionKey: 'dashboard',
    action: 'isView',
  },

  {
    path: '/jobTemplates',
    routes: [
      {
        path: '/:id/show/:modelDetail',
        component: JobTemplates.Show,
      },
    ],
  },
  {
    path: '/jobTemplates',
    component: JobTemplates.List,
  },
  {
    path: '/companyProfile',
    component: CompanyProfile.Show,
  },
  {
    path: '/job-listings',
    routes: [
      {
        path: '/:id/show/:modelDetail/:viewModeDetail?',
        component: JobListings.Show,
      },
      {
        path: '/:tabMode/:viewMode',
        component: JobListings.List,
      },
    ],
  },
  {
    path: '/job-applications',
    routes: [
      {
        path: '/:id/show/:modelDetail',
        component: JobApplications.Show,
      },
      {
        path: '/:model',
        component: JobApplications.List,
      },
    ],
  },
  {
    path: '/programs-and-learning',
    routes: [
      {
        path: '/',
        component: ProgramsAndLearningContainer,
      },
    ],
  },
];

const wrappedRoutes = map(
  flatMap(routes, (route) => {
    if (route.routes) {
      return map(route.routes, (subRoute) => ({
        exact: subRoute.path === '/',
        ...subRoute,
        path: route.path + subRoute.path,
        hasPrivateLayoutWrapper: route.hasPrivateLayoutWrapper,
        component: withTranslation()(subRoute.component || route.component),
      }));
    }
    return route;
  }),
  (route) => <PrivateRoute {...route} key={route.path} />,
);

const PrivateRoutes = () => {
  const dispatch = useDispatch<AppDispatch>();
  const currentUserId = useSelector((state: RootState) => state.auth.data?.id);
  const centreId = useSelector((state: RootState) => state.auth.centreId);
  const activeOnJobModulePermission = useSelector(
    getActiveOnJobModulePermission,
  );

  const { isCan } = useCheckPermission({
    permissionKey: 'applications',
    action: 'isView',
  });

  const { isCan: isCanEnquiries } = useCheckPermission({
    permissionKey: 'enquiries',
    action: 'isView',
  });

  useEffect(() => {
    if (currentUserId) {
      dispatch(getConfig()).then(() => dispatch(getConfigV2()));
      activeOnJobModulePermission &&
        dispatch(getTotalSubmittedJobApplications());
    }
  }, [currentUserId, dispatch, activeOnJobModulePermission]);

  useEffect(() => {
    if (isCan) {
      setTimeout(() => dispatch(getApplicationPendingCount()), 500);
    }
  }, [isCan, centreId, dispatch]);

  useEffect(() => {
    if (isCanEnquiries) {
      dispatch(getTotalUnreadEnquires());
    }
  }, [isCanEnquiries, centreId, dispatch]);

  return (
    <PrivateLayout>
      <Switch>
        {wrappedRoutes}
        <Redirect
          to={{
            pathname: '/404',
          }}
        />
      </Switch>
    </PrivateLayout>
  );
};

export default PrivateRoutes;

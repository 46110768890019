import React from 'react';
import { Row, Col } from 'antd';
import PropTypes from 'prop-types';

import BusinessCard from './BusinessCard';
import SummaryEnquiries from './SummaryEnquiries';
import SummaryApplication from './SummaryApplication';
import CentreInformation from './CentreInformation';
import CentrePhoto from './CentrePhoto';
import SummaryTabStyles from './styles';
import SocialMediaChannels from './SocialMediaChannels';
import CentreVirtualTour from './CentreVirtualTour';

const SummaryTab = ({ record }) => (
  <SummaryTabStyles>
    <Row gutter={[24, 24]} className="row-summary">
      <Col xl={8} lg={12} md={12} xs={24}>
        <SummaryEnquiries />
      </Col>
      <Col xl={8} lg={12} md={12} xs={24}>
        <SummaryApplication />
      </Col>
      <Col xl={8} lg={24} md={24} xs={24}>
        <BusinessCard logo={record.logo?.url} brandLogo={record?.brand?.logo} />
      </Col>
      <Col xl={16} lg={24} md={24} xs={24}>
        <CentreInformation
          applicationFeeAmount={record.applicationFeeAmount}
          placeAllowed={record.placeAllowed}
          description={record.shortDescription}
        />
      </Col>
      <Col xl={8} lg={24} md={24} xs={24}>
        <CentrePhoto images={record.images} />
      </Col>
      <Col xl={16} lg={24} md={24} xs={24}>
        <SocialMediaChannels />
      </Col>
      <Col xl={8} lg={24} md={24} xs={24}>
        <CentreVirtualTour />
      </Col>
    </Row>
  </SummaryTabStyles>
);

SummaryTab.propTypes = {
  record: PropTypes.object,
};

SummaryTab.defaultProps = {
  record: {},
};

export default SummaryTab;

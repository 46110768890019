import styled from 'styled-components';

export const JobListingInfoStyles = styled.div`
  background: white;
  position: relative;
  border-radius: 8px;
  .edit-wrapper {
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 2;
  }

  .avatar-job {
    .ant-avatar {
      width: 100% !important;
      border-radius: 8px 8px 0px 0px;
    }
  }
`;

export const StatisticsInfoStyles = styled.div`
  border-left: 4px solid
    ${({ isTotalApplications }) =>
      isTotalApplications ? '#2BADA7' : '#FD82C3'};
  padding: 5px 0px 5px 10px;
`;

export const JobListingGeneralInfoStyles = styled.div`
  padding: 8px 24px 24px 24px;
  .listing-status {
    padding: 16px 0px;
  }
`;

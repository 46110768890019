import React from 'react';
import PropTypes from 'prop-types';
import Recaptcha from 'react-grecaptcha';
import RecaptchaStyles from './styles';

const RecaptchaCommon = ({ callback, expiredCallback }) => (
  <RecaptchaStyles>
    <Recaptcha
      sitekey={process.env.REACT_APP_RECAPTCHA_KEY}
      callback={callback}
      locale="en"
      expiredCallback={expiredCallback}
    />
  </RecaptchaStyles>
);

RecaptchaCommon.propTypes = {
  callback: PropTypes.func,
  expiredCallback: PropTypes.func,
};

export default RecaptchaCommon;

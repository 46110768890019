import styled from 'styled-components';

export const CreateButtonStyles = styled.div`
  .create-action-wrapper {
    position: relative;
  }

  .action-feature-icon {
    position: absolute;
    top: -12px;
    right: -10px;
    font-size: 20px;
    color: ${({ theme }) => theme.subscriptions.colorIcon};
  }
  .ant-btn:not([disabled]) {
    background: ${({ isPrimaryColor, theme }) =>
      isPrimaryColor ? theme.palette.primary : theme.color.blueSecondary};
    border: none;
    color: #fff;
    font-weight: bold;
  }
`;

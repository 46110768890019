import React from 'react';
import PropTypes from 'prop-types';
import { Badge, Menu } from 'antd';
import { useHistory } from 'react-router';
import i18next from 'i18next';
import styled from 'styled-components';
import { ChildrenProp } from '../../types';

export const BadgeWrapper = styled(Badge)<ChildrenProp>``;

const MenuItem = (props) => {
  const history = useHistory();
  const { disabled, menu, collapsed, ...restProps } = props;
  const handleClick = () => {
    history.push(menu.url);
  };
  return (
    <Menu.Item
      {...restProps}
      disabled={disabled}
      key={menu.key}
      title={i18next.t(menu.text)}
      onClick={handleClick}
      icon={
        <>
          {menu.count ? (
            <BadgeWrapper count={menu.count}>
              <menu.Icon />
            </BadgeWrapper>
          ) : (
            <menu.Icon />
          )}
        </>
      }
    >
      {!collapsed && (
        <span className="menu-label">
          {menu?.tag ? (
            <>
              {i18next.t(menu.text)}
              {menu?.tag}
            </>
          ) : (
            i18next.t(menu.text)
          )}
        </span>
      )}
    </Menu.Item>
  );
};

MenuItem.propTypes = {
  disabled: PropTypes.bool,
  menu: PropTypes.object,
  collapsed: PropTypes.bool,
  onClick: PropTypes.func,
  IconMenuItem: PropTypes.node,
};

export default MenuItem;

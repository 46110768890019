import React from 'react';
import PropTypes from 'prop-types';
import { Form, Row, Col } from 'antd';
import { useSelector } from 'react-redux';

import crudSelectors from '../../../redux/crudSelectors';
import ButtonRow from '../FooterButtonRow';
import { RestInputContext } from '../../RestInput/RestInputContext';

const FormComponent = ({
  onBack,
  children,
  positionOfSubmitButton,
  customSubmitButton,
  record,
  showModal,
  formatOnSubmit,
  onSubmit,
  resource,
  okText,
}) => {
  const loading = useSelector(crudSelectors[resource].getCreateLoading);
  const isDisabled = useSelector(
    (state) => state[resource]?.isDisabledButtonSubmit,
  );

  const [form] = Form.useForm();
  const getData = () =>
    new Promise((resolve) => {
      form.validateFields().then((values) => {
        const submitData = formatOnSubmit ? formatOnSubmit(values) : values;
        resolve(submitData);
        resolve({});
      });
    });

  const handleSubmit = () =>
    new Promise((resolve, reject) => {
      form
        .validateFields()
        .then((values) => {
          const submitData = formatOnSubmit ? formatOnSubmit(values) : values;
          onSubmit(submitData, form);
          resolve(values);
        })
        .catch((err) => {
          reject(err);
        });
    });
  return (
    <Form
      layout="vertical"
      form={form}
      onFinish={handleSubmit}
      scrollToFirstError
    >
      <Row className="drawerContainer">
        <Col
          className="content-create"
          md={positionOfSubmitButton === 'left' ? 20 : 24}
          xs={24}
        >
          <div className="content-form">
            <RestInputContext.Provider value={{ form, record }}>
              {React.cloneElement(children, { form })}
            </RestInputContext.Provider>
          </div>
        </Col>
        <Col
          className="footer"
          md={positionOfSubmitButton === 'left' ? 4 : 24}
          xs={24}
        >
          {customSubmitButton ? (
            React.cloneElement(customSubmitButton, {
              loading,
              disabled: isDisabled,
              handleSubmit,
              onBack,
              getData,
            })
          ) : (
            <ButtonRow
              type="create"
              loading={loading}
              disabled={isDisabled}
              showModal={showModal}
              handleSubmit={handleSubmit}
              onBack={onBack}
              okText={okText}
            />
          )}
        </Col>
      </Row>
    </Form>
  );
};

FormComponent.propTypes = {
  onBack: PropTypes.func,
  onSubmit: PropTypes.func,
  children: PropTypes.node,
  positionOfSubmitButton: PropTypes.string,
  customSubmitButton: PropTypes.node,
  record: PropTypes.object,
  showModal: PropTypes.bool,
  formatOnSubmit: PropTypes.func,
  resource: PropTypes.string,
  okText: PropTypes.string,
};

FormComponent.defaultProps = {
  positionOfSubmitButton: 'bottom',
  record: {},
};

export default FormComponent;

import TooltipBarChart from 'components/charts/TooltipBarChart';
import LegendBarChart from 'components/charts/LegendBarChart';
import React from 'react';
import { useSelector } from 'react-redux';
import {
  Bar,
  CartesianGrid,
  ComposedChart,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { getDataServiceApprovals } from 'redux/companyBenchmarking/selectors';
import PropTypes from 'prop-types';
import i18next from 'i18next';
import { isEmpty } from 'lodash-es';
import CardCustom from '../CardCustom';

const ServiceApprovalBarChart = ({ userCompanySelectedName }) => {
  const serviceApprovalsData = useSelector(getDataServiceApprovals);
  return (
    !isEmpty(serviceApprovalsData) && (
      <CardCustom
        title={`${i18next.t(
          'companyBenchmarking.userCompanyCharts.serviceApprovalsByYear',
        )} - ${userCompanySelectedName ?? ''}`}
      >
        <div align="middle" className="flex-column-center h-full">
          <ResponsiveContainer height={380}>
            <ComposedChart layout="vertical" data={serviceApprovalsData}>
              <CartesianGrid stroke="#EBECF0" horizontal={false} />
              <XAxis type="number" tickLine={false} stroke="#ABA7A7" />
              <YAxis
                dataKey="year"
                type="category"
                tickCount={10}
                tickLine={false}
                stroke="#ABA7A7"
              />
              <Tooltip content={<TooltipBarChart />} />
              <Legend content={<LegendBarChart />} />
              <Bar
                dataKey="existenceCentres"
                stackId=""
                barSize={8}
                fill="#26C1FC"
                name={i18next.t(
                  'companyBenchmarking.userCompanyCharts.existingCentres',
                )}
              />
              <Bar
                dataKey="newApprovedCentres"
                stackId=""
                barSize={8}
                fill="#FF73C3"
                name={i18next.t(
                  'companyBenchmarking.userCompanyCharts.newApprovedCentres',
                )}
              />
            </ComposedChart>
          </ResponsiveContainer>
        </div>
      </CardCustom>
    )
  );
};

ServiceApprovalBarChart.propTypes = {
  userCompanySelectedName: PropTypes.string,
};

export default ServiceApprovalBarChart;

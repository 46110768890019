import React from 'react';
import { Layout, Row, Col, Button } from 'antd';
import PublicFooter from 'containers/Footer/PublicFooter';
import logo from 'assets/images/logo_plus.svg';
import { useTranslation } from 'react-i18next';
import { PublicLayoutWrapper, Header } from './styles';

const { Content } = Layout;

interface Props {
  children: React.ReactNode;
}
const PublicLayout = ({ children }: Props) => {
  const { t } = useTranslation();

  const openNewContact = () => {
    window.open(`${process.env.REACT_APP_RW_URL}/contact-us`);
  };

  const openNewRichWeb = () => {
    window.open(`${process.env.REACT_APP_RW_URL}`);
  };

  return (
    <PublicLayoutWrapper className="wrapper-container">
      <Layout className="layout">
        <div className="main-container">
          <Content className="content-layout">
            <Header className="flex items-center justify-between">
              <div
                className="cursor-pointer"
                onClick={openNewRichWeb}
                aria-hidden="true"
              >
                <img src={logo} alt="" className="w-144" />
              </div>
              <Button
                onClick={openNewContact}
                ghost
                type="primary"
                className="text-14 fw-bold"
              >
                {t('menu.contactCustomerCare')}
              </Button>
            </Header>
            <Row className="row-layout">
              <Col xs={0} md={11} className="col-image">
                <div className="wrapper-image">
                  <img
                    src="https://s3.ap-southeast-2.amazonaws.com/files.kindicare.com/uploads/static/assets/images/crm-cover-login-1x.png"
                    alt=""
                    className="image-auth"
                  />
                </div>
              </Col>
              <Col xs={24} md={13} className="col-main-content">
                <div className="main-content">{children}</div>
              </Col>
            </Row>
          </Content>
          <PublicFooter />
        </div>
      </Layout>
    </PublicLayoutWrapper>
  );
};

export default PublicLayout;

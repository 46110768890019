import React, { useCallback } from 'react';
import { Progress } from 'antd';
import i18next from 'i18next';
import { formatMoney } from 'utils/tools';
import { useSelector } from 'react-redux';
import { getEnrolmentPipeline } from 'redux/dashboard/selectors';

import CardCommon from '../CardCommon';
import { EnrolmentPipelineStyles } from './styles';

const EnrolmentPipeline = () => {
  const enrolmentPipelineData = useSelector(getEnrolmentPipeline);

  const formatValue = useCallback(
    (value) =>
      `${i18next.t('currencyUnit.sign')}${formatMoney(value)} ${i18next.t(
        'text.perAnnum',
      )}`,
    [],
  );

  return (
    <CardCommon title="applications.enrolmentPipeline">
      <EnrolmentPipelineStyles>
        {enrolmentPipelineData.map((item) => (
          <div className="progress-item" key={item.value}>
            <div className="progress-item__title">{i18next.t(item.text)}</div>
            <Progress
              percent={item.enrolmentPipelinePercent}
              strokeColor={item.color}
              format={() => formatValue(item.enrolmentPipelineValue)}
            />
          </div>
        ))}
      </EnrolmentPipelineStyles>
    </CardCommon>
  );
};

export default EnrolmentPipeline;

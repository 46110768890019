import React from 'react';
import PropTypes from 'prop-types';

import { Avatar } from 'antd';
import { PictureFilled } from '@ant-design/icons';
import { getFeaturedImage } from 'utils/dataUtils';

const CentreImageInfo = ({ images }) => {
  const featureImage = getFeaturedImage(images);

  return (
    <div className="avatar-centre">
      <Avatar
        shape="square"
        icon={<PictureFilled />}
        src={featureImage}
        size={246}
      />
    </div>
  );
};

CentreImageInfo.propTypes = {
  images: PropTypes.array,
};

export default CentreImageInfo;

import styled from 'styled-components';
import { Menu } from 'antd';

export default styled.div`
  display: flex;
  flex-direction: column;
`;

export const MessageHeaderStyles = styled.div`
  padding: 10px 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid ${({ theme }) => theme.border.light};
  .right-section {
    display: flex;
    overflow: hidden;
    > div {
      margin-left: 10px;
    }
  }
  .left-section {
    display: flex;
    align-items: center;
    cursor: pointer;

    .info-section {
      margin-left: 14px;

      .title {
        font-weight: 600;
      }
    }

    .ant-badge {
      sup {
        top: auto !important;
        bottom: 0 !important;
        transform: translate(50%, 0%) !important;
      }

      .ant-badge-dot {
        width: 10px;
        height: 10px;
      }
    }
  }
  @media only screen and (max-width: 576px) {
    display: flex;
    flex-direction: column;
    .right-section {
      margin-top: 10px;
      max-width: 100%;
    }
  }
`;

export const MessageBodyStyles = styled.div`
  flex: auto;
  padding: 10px 30px;
  overflow-y: scroll;

  .detect-link {
    text-decoration: underline;
  }

  .messages-list {
    display: flex;
    flex-direction: column-reverse;
  }

  .seen-section {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    > span {
      margin: 0 5px;
    }
  }

  .time-message-title {
    text-align: center;
    font-weight: bold;
    color: #aba7a7;
    margin-top: 15px;
  }

  .wrapper-content-message {
    display: flex;
    width: 100%;
    margin: 10px 0;

    .message-content {
      margin: 0 10px;
      max-width: 65%;

      .bubble {
        position: relative;

        padding: 14px;
        padding-bottom: 10px;

        .text {
          margin-bottom: 5px;
          font-size: 16px;
          .message-text {
            white-space: pre-line;
            word-break: break-word;
          }
        }

        .time {
          text-align: right;
          font-size: 10px;
        }

        &::after {
          content: ' ';
          position: absolute;
          width: 0;
          height: 0;
          top: auto;
        }
      }
    }
  }

  .receiver {
    .wrapper-content-message {
      display: flex;
    }
    .detect-link {
      color: ${({ theme }) => theme.text.primary};
    }
    .message-content {
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      .bubble {
        background: #f7f8f9;
        border-radius: 0 20px 20px 20px;
        .time {
          text-align: left;
        }
      }
    }
  }

  .sender {
    .wrapper-content-message {
      display: flex;
      flex-direction: row-reverse;
    }

    .message-content {
      display: flex;
      flex-direction: column;
      align-items: flex-end;

      .name {
        text-align: right;
      }

      .bubble {
        color: #fff;
        background: ${({ theme }) => theme.palette.primary};
        border-radius: 20px 0 20px 20px;
      }
    }
    .detect-link {
      color: #fff !important;
    }
  }

  ::-webkit-scrollbar-track {
    background: #fff !important;
  }

  .reload-new-message {
    width: 100%;
    border: none;
    background: none;
    box-shadow: none;
    color: ${({ theme }) => theme.palette.primary};
    font-weight: bold;
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    .anticon {
      display: flex;
      font-size: 16px;
    }
  }
  .title-info-request {
    &__name {
      font-weight: bold;
      font-size: 18px;
      line-height: 23px;
    }
    .ant-divider-horizontal {
      margin: 5px 0 10px 0 !important;
      border-top: 1px solid #e96daf;
    }
  }
`;

export const MessageFooterStyles = styled.div`
  display: flex;
  border-top: 2px solid ${({ theme }) => theme.border.light};
`;

export const FooterFormStyles = styled.div`
  width: 100%;
  padding: 15px;

  .button-submit {
    background: ${({ theme }) => theme.color.blueSecondary};
    border: 1px solid ${({ theme }) => theme.color.blueSecondary};
    color: #fff;
    width: 84px;
    margin-left: 15px;
    height: 48px;
    .anticon {
      font-size: 21px;
      display: flex;
      justify-content: center;
    }
  }
  .ant-form {
    display: flex;
    width: 100%;
    .ant-form-item {
      margin-bottom: 0 !important;
    }
    .content-type-text {
      flex: 1;
      display: flex;
      background: #f7f8f9;
      min-height: 48px;
      border-radius: ${({ theme }) => theme.borderRadius.default};
    }

    .ant-input {
      border: none;
      resize: none;
      cursor: auto;
      background: none;
      min-height: 100% !important;
      line-height: 30px;
      font-size: 16px;

      &:hover,
      &:focus {
        border: none;
        box-shadow: none;
      }
      &::-webkit-scrollbar {
        width: 5px;
      }

      &::-webkit-scrollbar-track {
        background: #f0f3fa;
        border-radius: 10px;
      }

      &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background: #b7b6c2;
      }
    }
  }
  @media only screen and (max-width: 576px) {
    .button-submit {
      width: 40px;
    }
  }
`;

export const MenuOverlayStyles = styled(Menu)`
  .ant-btn {
    font-size: 16px;
    height: 32px;
    padding-top: 0;
    padding-bottom: 0;
    border: none;
    background: transparent;
    &:hover,
    &:focus {
      border: none;
      background: transparent;
    }
  }
`;

export const ActionButtonStyles = styled.div`
  .icon-action {
    font-size: 20px;
  }
`;

export const CentreNameInfoStyles = styled.div`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  color: #316dde;
  background: rgba(50, 164, 252, 0.2);
  border-radius: 8px;
  padding: 3px 10px;
`;

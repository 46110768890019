import styled from 'styled-components';

export const ExportExcelWrapper = styled.div`
  display: flex;

  button {
    margin-left: 16px;
  }

  .ant-picker {
    border-color: transparent;
  }

  .ant-picker-focused {
    border-color: ${({ theme }) => theme.palette.primary} !important;
  }
`;

import React, { useMemo, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import i18next from 'i18next';
import { VALUE_ROOT_OPEN_TIME } from 'configs/localData';
import { isEmpty } from 'lodash-es';
import { Form, Space, Button } from 'antd';

import { isEqualTwoListKeysByTime } from 'utils/tools';

import EditCustomBtn from 'components/RestActions/EditCustomBtn';
import SchoolTermForm from './SchoolTermForm';
import { handleFormatOpenTime } from '../utils';
import { useCentre } from '../../../../Show/context';
import { SectionHourStyles } from '../styles';

const SchoolTerm = ({ openTime, record }) => {
  const [form] = Form.useForm();

  const { validateFields, resetFields } = form;
  const { updateCentre } = useCentre();

  const isDifferent = useMemo(() => {
    if (isEmpty(openTime)) return false;

    const isListMorningDifferent = !isEqualTwoListKeysByTime(
      VALUE_ROOT_OPEN_TIME.arrRootMorning1,
      VALUE_ROOT_OPEN_TIME.arrRootMorning2,
      openTime,
    );
    // Nếu arrRootMorning1 != arrRootMorning2 --> return về isListMorningDifferent = true
    // Ngược lại, tiếp tục xét tới list arrRootAfternoon1 và arrRootAfternoon2
    if (isListMorningDifferent) return true;

    const isListAfternoonDifferent = !isEqualTwoListKeysByTime(
      VALUE_ROOT_OPEN_TIME.arrRootAfternoon1,
      VALUE_ROOT_OPEN_TIME.arrRootAfternoon2,
      openTime,
    );
    return isListAfternoonDifferent;
  }, [openTime]);

  const [isEdit, setIsEdit] = useState(false);

  const onClickButton = useCallback(() => {
    setIsEdit(true);
  }, []);

  const handleCancel = useCallback(() => {
    setIsEdit(false);
    resetFields();
  }, []); // eslint-disable-line

  const onSubmit = useCallback(() => {
    validateFields().then((values) => {
      const newOpenTime = handleFormatOpenTime({
        ...values,
        oldOpenTime: openTime,
      });

      updateCentre({ openTime: newOpenTime }).then(({ payload }) => {
        if (payload?.data?.id) {
          handleCancel();
        }
      });
    });
  }, [validateFields, updateCentre, openTime]); // eslint-disable-line

  return (
    <SectionHourStyles>
      <div className="header-section">
        <span className="name-section">
          {i18next.t('seasonType.schoolTerm')}
        </span>
        {isEdit ? (
          <Space size={20}>
            <Button className="cancel-button" onClick={handleCancel}>
              {i18next.t('button.cancel')}
            </Button>
            <Button type="primary" onClick={onSubmit}>
              {i18next.t('button.save')}
            </Button>
          </Space>
        ) : (
          <EditCustomBtn
            packageName={record?.CRMPackage?.name}
            permissionKey="myCentres"
            action="isEdit"
            onClickButton={onClickButton}
          />
        )}
      </div>
      <Form form={form}>
        <SchoolTermForm
          isEdit={isEdit}
          openTime={openTime}
          isDifferent={isDifferent}
        />
      </Form>
    </SectionHourStyles>
  );
};

SchoolTerm.propTypes = {
  openTime: PropTypes.object,
  record: PropTypes.object,
};

SchoolTerm.defaultProps = {
  openTime: {},
};

export default SchoolTerm;

import { Pagination } from 'types';

export enum CENTRE_METRIC_SORT_KEY {
  CENTRE_NAME = 'centre.name',
  IMPRESSIONS = 'impressions',
  LISTING_VIEWS = 'listViews',
  ENQUIRIES = 'enquiries',
  APPLICATIONS = 'applications',
  APPLICATION_CUSTOM_LINK = 'applicationsCustomLink',
  MESSAGES_SENT = 'sentMessages',
  MESSAGES_RECEIVED = 'receivedMessages',
  BOOK_A_TOUR = 'bookATour',
  WEBSITE_VISITS = 'websiteVisits',
  PHONE_CALLS = 'phoneCalls',
  SOCIAL_VISITS = 'socialVisits',
  COMPARE_SHORTLIST_SHARE = 'comparesAndShares',
}

export enum SORT_TYPE {
  ASC = 'asc',
  DESC = 'desc',
}

export interface SortByKey {
  orderByKey: string;
  orderByType: string;
}

export type RangeDate = {
  from: string;
  to: string;
};
export interface CentreMetricsFilterType {
  title?: string;
  createdAt?: RangeDate;
  updatedAt?: RangeDate;
  channel?: string;
  delivery?: string;
  activeStatus?: string;
  userType?: string;
  identity?: string;
}

export interface CentreMetricsInfo {
  id?: string;
  name?: string;
  images?: string;
}

export interface CentreMetrics {
  application?: string;
  bookATour?: string;
  centre?: CentreMetricsInfo;
  comparesAndShares?: string;
  applicationsCustomLink?: string;
  enquiries?: string;
  impression?: string;
  listViews?: string;
  phoneCall?: string;
  receivedMessages?: string;
  sentMessages?: string;
  socialVisits?: string;
  websiteVisits?: string;
}

export interface CentreMetricsResponse {
  paging?: Pagination;
  requestId?: string;
  results?: CentreMetrics[];
}

export interface ExportLibrary {
  requestId: string;
  paging: Pagination;
  results: {
    id: string;
    createdAt: Date;
    updatedAt: Date;
    name: string;
    userId: string;
    type: string;
    status: string;
    exportTime: Date;
    filterCondition: string;
    url: string;
  };
}
export interface ExportMarketingMetricsResponse {
  id: string;
  createdAt: Date;
  updatedAt: Date;
  name: string;
  userId: string;
  type: string;
  status?: string;
  exportTime: Date;
  filterCondition: string;
  url: string;
}

export interface TimeRange {
  filteredStart?: string;
  filteredEnd?: string;
  comparedStart?: string;
  comparedEnd?: string;
}

export interface CentreMetricsPayload {
  channel?: string;
  providerId?: string;
  centreId?: string;
  brandId?: string;
  stateId?: string;
  timeRange?: TimeRange;
  page?: number;
  size?: number;
  sortKey?: CENTRE_METRIC_SORT_KEY;
  sortOrder?: SORT_TYPE;
  dateOption?: string;
  isCompare?: boolean | string;
  compareOption?: string;
}

export interface ExportLibraryPayload {
  sortKey?: string;
  sortOrder?: string;
  size?: number | string;
  page?: number | string;
}
export interface ChartMarketingMetricsResponse {
  data: MetricRecord[];
  dataLines: DataLine[];
  resolution: CHART_RESOLUTION;
}

export interface ChartMarketingMetricsPayload
  extends Omit<CentreMetricsPayload, 'page' | 'size'> {
  chartLines?: string[];
  chartResolution?: CHART_RESOLUTION;
}

export interface MetricRecord {
  date: string;
  [key: string]: number | string | bigint;
}

export interface DataLine {
  label: string;
  value: string;
  fillColor: string;
  isSelected: boolean;
}

export interface SummaryMetricsResponse {
  result: SummaryMetricItem[];
}

export interface SummaryMetricItem {
  id: string;
  name: string;
  icon: string;
  color: string;
  value: string;
  tooltips: Tooltips;
  percentChange: PercentChange;
}

export interface Tooltips {
  description?: string;
  comparison?: Comparison;
}

export interface Comparison {
  filtered: string;
  compared?: string;
  message?: string;
  value: string;
}

export interface PercentChange {
  text: string;
  icon?: string;
  color?: string;
}

export interface DeleteResponse {
  affected?: number;
  raw?: Array<unknown>;
  requestId?: string;
}

export enum CHART_RESOLUTION {
  DAILY = 'Daily',
  WEEKLY = 'Weekly',
  MONTHLY = 'Monthly',
  QUARTERLY = 'Quarterly',
  YEARLY = 'Yearly',
}

import React, { useEffect, useContext } from 'react';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { getServiceAgeGroups, getServiceTypes } from 'redux/config/selectors';

import {
  APPLICATION_STATUS,
  APPLICATION_STATUS_CONSTANT,
} from 'configs/localData';

import { useApplicationList } from 'containers/Applications/List/context';
import { RestInputContext } from 'components/RestInput/RestInputContext';
import RestInputItem from 'components/RestInput/RestInputItem';
import RestRangePickerInput from 'components/RestInput/RestRangePickerInput';
import RestSelect from 'components/RestInput/RestSelect';
import i18next from 'i18next';
import { formatLabelTable } from 'containers/Centres/components/CentreTabs/ServicesTab/utils';
import { LABEL_TABLE_KEY } from 'configs/localData/constant';
import GridCards from 'components/GridCards';
import { useParams } from 'react-router';
import { TABS_MODE_APPLICATION } from 'containers/Applications/List';
import { useAppSelector } from 'redux/hooks';
import { useParentApplicationConfigControllerGetArchivedReasonConfigQuery } from 'generated/apis';

const ApplicationFilterForm = () => {
  const { model } = useParams();
  const { filter, setFilter } = useApplicationList();
  const { form } = useContext(RestInputContext);

  const isArchivedViewMode = model === TABS_MODE_APPLICATION.archived.key;

  const serviceTypes = useSelector(getServiceTypes) || [];

  const serviceAgeGroups = useSelector(getServiceAgeGroups) || [];

  const { data: archivedData } =
    useParentApplicationConfigControllerGetArchivedReasonConfigQuery(null, {
      skip: !isArchivedViewMode,
    });

  // fix clear filter
  useEffect(() => {
    const { submittedAt, startDay, ...restFilter } = filter || {};
    form.setFieldsValue({
      ...restFilter,
      submittedAt: [
        submittedAt?.$gt ? moment(submittedAt?.$gt) : null,
        submittedAt?.$lt ? moment(submittedAt?.$lt) : null,
      ],
      startDay: [
        startDay?.$gt ? moment(startDay?.$gt) : null,
        startDay?.$lt ? moment(startDay?.$lt) : null,
      ],
    });
  }, []); // eslint-disable-line

  const appSettings = useAppSelector((state) => state.config?.data?.AppSetting);

  useEffect(() => {
    setFilter();
    form.resetFields();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isArchivedViewMode]);

  return (
    <GridCards
      gap="8px"
      size={[8, 10]}
      columns={{ sm: 1, md: 2, lg: 2, xxl: isArchivedViewMode ? 7 : 6 }}
    >
      <RestInputItem
        source={['child']}
        placeholder="applications.childName"
        isShowTooltip
      />
      <RestSelect
        source="status"
        placeholder="applications.status"
        resourceData={
          isArchivedViewMode
            ? APPLICATION_STATUS
            : APPLICATION_STATUS.filter(
                (item) =>
                  ![
                    APPLICATION_STATUS_CONSTANT.declined,
                    APPLICATION_STATUS_CONSTANT.cancelled,
                  ].includes(item.value),
              )
        }
        valueProp="value"
        titleProp="text"
        isShowTooltip
        formatText={(value) => i18next.t(value as string)}
      />
      <RestSelect
        source="serviceTypeId"
        placeholder={formatLabelTable(LABEL_TABLE_KEY.serviceType, appSettings)}
        resourceData={serviceTypes || []}
        valueProp="id"
        titleProp="name"
        isShowTooltip
      />
      <RestSelect
        source="serviceAgeGroupId"
        placeholder={formatLabelTable(LABEL_TABLE_KEY.ageGroup, appSettings)}
        resourceData={serviceAgeGroups || []}
        valueProp="id"
        titleProp="name"
        isShowTooltip
      />
      {isArchivedViewMode && (
        <RestSelect
          source="archivedReason"
          placeholder="applications.archiveReason"
          resourceData={archivedData?.archivedReason ?? []}
          valueProp="value"
          titleProp="text"
          isShowTooltip
        />
      )}
      <RestRangePickerInput
        sourceGt={['submittedAt', '$gt']}
        sourceLt={['submittedAt', '$lt']}
        source="submittedAt"
        placeholder="applications.appliedTime"
        isShowTooltip
      />
      <RestRangePickerInput
        sourceGt={['startDay', '$gt']}
        sourceLt={['startDay', '$lt']}
        source="startDay"
        placeholder="applications.startDay"
        isShowTooltip
      />
    </GridCards>
  );
};

export default ApplicationFilterForm;

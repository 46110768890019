import React from 'react';
import { FormInstance, notification } from 'antd';
import {
  AwardCircleIcon,
  AwardWithoutCircleIcon,
} from 'components/common/SVGIcon';
import i18next from 'i18next';
import { isEmpty } from 'lodash-es';
import { useDispatch, useSelector } from 'react-redux';
import { getPayloadStaffProfile } from 'redux/staffProfile/selector';
import { setStaffProfilePayload } from 'redux/staffProfile/slice';
import ContentCard from 'components/CardItemProfile';
import moment from 'moment';
import EmptyContainer from 'containers/Centres/components/CentreTabs/EducatorStaffProfilesTab/components/EmptyContainer';
import AwardsItem from './AwardsItem';
import AwardFromModal from './AwardFromModal';

interface Props {
  visible: boolean;
  editIndexCurrent: number;
  openAddModal: () => void;
  formModalStep4: FormInstance;
  setEditIndexCurrent: (index: number) => void;
  setVisible: (params: boolean) => void;
  onSave: () => void;
  onSaveAddAnother: () => void;
}

const AwardsContainer = ({
  visible,
  setVisible,
  formModalStep4,
  editIndexCurrent,
  openAddModal,
  setEditIndexCurrent,
  onSave,
  onSaveAddAnother,
}: Props) => {
  const awards = useSelector(getPayloadStaffProfile)?.awards ?? [];
  const dispatch = useDispatch();

  const onDelete = (index) => {
    const payloadList = [...awards];
    payloadList.splice(index, 1);

    dispatch(setStaffProfilePayload({ awards: payloadList }));
    notification.success({
      message: i18next.t('success.title'),
      description: i18next.t('success.description'),
    });
  };

  const openEditModal = (data, index) => {
    formModalStep4.setFieldsValue({ ...data, year: moment(data.year) });
    setVisible(true);
    setEditIndexCurrent(index);
  };

  return (
    <div>
      {isEmpty(awards) ? (
        <EmptyContainer
          Icon={AwardWithoutCircleIcon}
          description={'educatorStaffProfile.noDataDescriptionAwards'}
          buttonTitle="educatorStaffProfile.addAwards"
          onAdd={openAddModal}
          className="mt-70"
        />
      ) : (
        <div>
          {awards?.map((item, index) => (
            <ContentCard
              key={`${item.name}-${item?.company}}`}
              Icon={<AwardCircleIcon className="text-40" />}
              onEdit={() => openEditModal(item, index)}
              onDelete={() => onDelete(index)}
              deleteConfirmTitle="educatorStaffProfile.deleteAdAwardTitle"
              deleteConfirmContent="educatorStaffProfile.deleteAdAwardContent"
            >
              <AwardsItem {...item} />
            </ContentCard>
          ))}
        </div>
      )}

      <AwardFromModal
        formModalStep4={formModalStep4}
        visible={visible}
        title="educatorStaffProfile.awards"
        editIndexCurrent={editIndexCurrent}
        setVisible={setVisible}
        onSave={onSave}
        onSaveAddAnother={onSaveAddAnother}
      />
    </div>
  );
};

export default AwardsContainer;

import styled from 'styled-components';
import { Col } from 'antd';
import { Transform } from '@dnd-kit/utilities';

export const FormPhotosStyles = styled.div`
  .file-list-view {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 16px;
    margin-bottom: 24px;
    .anticon {
      font-size: 20px;
      color: ${({ theme }) => theme.text.gray700};
    }
  }
  .uploadImage {
    margin: 0;
  }
  .form-item-upload-image {
    margin-bottom: 0;
    label {
      font-weight: bold;
      font-size: 16px;
      line-height: 20px;
      color: ${({ theme }) => theme.text.mainL1};
    }
    label:after {
      content: '';
    }
    .ant-form-item-control-input {
      min-height: 150px;
    }
    .ant-upload.ant-upload-drag {
      background: ${({ theme }) => theme.background.content} !important;
      height: 150px;
      border-width: 2px;
      p.ant-upload-drag-icon .anticon {
        font-size: 28px !important;
        color: ${({ theme }) => theme.text.mainL3} !important;
      }
      p.ant-upload-text {
        font-size: 16px !important;
        line-height: 26px;
        color: ${({ theme }) => theme.text.mainL3} !important;
      }
      p.ant-upload-drag-icon {
        margin-bottom: 5px !important;
      }
    }
  }
`;

interface UploadImageItemProps {
  itemScale?: number;
  isNoneBorderRadius?: boolean;
}

export const UploadImageItemStyles = styled.div<UploadImageItemProps>`
  width: 150px;
  height: ${(props) => props.itemScale * 150}px;
  position: relative;
  margin: 10px;
  justify-content: center;
  user-select: none;
  .image-only-show {
    cursor: pointer;
  }
  .image-upload-view {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: ${(props) => (props.isNoneBorderRadius ? 0 : '10px')};
  }
  .icon-div-item {
    width: 100%;
    height: 100%;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #f0f0f0;
    .icon-file {
      font-size: 50px;
    }
  }
  .delete-icon-image {
    position: absolute;
    color: ${({ theme }) => theme.text.mainL2};
    font-size: 26px;
    right: -10px;
    top: -10px;
  }
  .wrapper-loading,
  .icon-error {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .icon-loading,
  .icon-error {
    font-size: 24px;
  }
  .icon-error {
    color: red;
  }
  img {
    display: none;
    -webkit-user-drag: none;
  }
  img[src] {
    display: block;
  }

  .lbSetFeature {
    background: ${({ theme }) => theme.background.disabled};
    color: white;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 2;
    border-radius: 0 0 10px 10px;
    cursor: pointer;
    visibility: hidden;
    text-align: center;
    padding: 2px 0;
    &.feature-active {
      visibility: visible;
      background: ${({ theme }) => theme.palette.primary};
    }
    &:hover {
      visibility: visible;
      background: ${({ theme }) => theme.palette.primary};
    }
  }
  &:hover {
    .lbSetFeature {
      visibility: visible;
    }
  }

  .iframe-video {
    width: 100%;
    height: ${(props) => props.itemScale * 150}px;
    border-radius: ${(props) => (props.isNoneBorderRadius ? 0 : '10px')};
  }

  .iframe-video-wrapper {
    position: relative;
    cursor: pointer;
    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }
  }
`;

export const ModalContentPreviewStyles = styled.div`
  padding-top: 20px;
`;

export const URLVideoFormStyles = styled.div`
  .url-video {
    &__title {
      margin-bottom: 10px;
      display: block;
    }
    &__form-item {
      flex: 1;
    }
    &__add-btn {
      margin-left: 10px;
    }
  }
`;

export const DrapDropWrapper = styled.div`
  margin-top: 30px;
`;

export const SortableGridStyles = styled.div`
  margin-top: 30px;
  .dragable-item {
    position: absolute;
    top: 20px;
    left: 20px;
    font-size: 22px;
    line-height: 1;
    z-index: 5;
    cursor: move;
    background-color: rgb(13, 10, 3, 0.2);
    box-shadow: 0px 8px 16px rgba(39, 40, 51, 0.16);
    border-radius: 4px;
    color: #fff;
  }
`;

interface DeleteIconStyleProps {
  isShowVirtualTour?: boolean;
}

export const DeleteIconStyle = styled.div<DeleteIconStyleProps>`
  position: absolute;
  color: ${({ theme }) => theme.text.mainL2};
  font-size: 26px;
  right: ${({ isShowVirtualTour }) => (isShowVirtualTour ? '-145px' : '-12px')};
  top: -20px;
`;

export const RenderIframeStyle = styled.div`
  height: 155px;
  width: 282px;
  position: relative;
  cursor: pointer;
  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
  padding-bottom: 55%;
  iframe {
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 8px;
    width: 100%;
    height: 100%;
  }
`;

interface SortableItemStyleProps {
  transform: Transform;
  transition: string | null;
  isDragging?: boolean;
}

export const SortableItemStyle = styled(Col)<SortableItemStyleProps>`
  transform: CSS.Transform.toString(${({ transform }) => transform});
  transition: ${({ transition }) => transition};
  z-index: ${({ isDragging }) => (isDragging ? 100 : 'auto')};
  position: relative;
  min-width: 180px;
`;

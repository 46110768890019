import React from 'react';
import { Tooltip } from 'antd';
import { QuestionFillIcon } from 'components/common/SVGIcon';
import { CustomLabelStyle } from 'components/form/Label/styles';

interface LabelWithTooltipProps {
  label: string;
  tooltip: string;
}

const LabelWithTooltip: React.FC<LabelWithTooltipProps> = ({
  label,
  tooltip,
}) => (
  <div className="flex items-center mb-8">
    <CustomLabelStyle className={'text-16 fw-bold'}>{label}</CustomLabelStyle>
    <Tooltip placement="topLeft" title={tooltip}>
      <QuestionFillIcon className="ml-6" />
    </Tooltip>
  </div>
);

export default LabelWithTooltip;

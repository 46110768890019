import { createSlice } from '@reduxjs/toolkit';
import { CREATE_JOB_TEMPLATE_FINAL_STEP } from 'containers/JobTemplates/constants';
import { unionBy } from 'lodash-es';
import {
  createJobTemplate,
  editJobTemplate,
  getAllJobTemplates,
  getJobTemplateConfig,
  getJobTemplatesDetail,
} from './actions';

const initialState = {
  hasSelectedTemplate: null,
  redirectToCurrentStepUrl: '',
  currentJobTemplateStep: 0,
  jobTemplatePayload: {},
  data: [],
  jobTemplatesConfig: {
    data: {},
    loading: false,
    error: {},
  },
  currentData: {},
  createLoading: false,
  getJobTemplatesDetailLoading: false,
};

const getAllJobTemplatesPending = (
  state,
  {
    meta: {
      arg: { data, options = {} },
    },
  },
) => {
  state.loading = true;
  if (options.isRefresh) {
    state.data = [];
    state.resourceData = {
      ...initialState.resourceData,
      ...data,
    };
  }
};

const getAllJobTemplatesSuccess = (state, { payload: { data } }) => {
  state.data = unionBy([...state.data, ...(data.results || [])], 'id');
  state.resourceData.total = data.paging.total || 0;
  state.resourceData.page = data.paging.page;

  state.loading = false;
  state.error = null;
};

const getAllJobTemplatesFail = (state, { payload: { data } }) => {
  state.error = data;
  state.loading = false;
};

const getJobTemplatesDetailPending = (state) => {
  state.getJobTemplatesDetailLoading = true;
};

const getJobTemplatesDetailFulfilled = (state, { payload }) => {
  state.currentData = payload?.result;
  state.getJobTemplatesDetailLoading = false;
};

const getJobTemplatesDetailFailed = (state, { payload: { data } }) => {
  state.error = data;
  state.getJobTemplatesDetailLoading = false;
};

const getJobTemplateConfigPending = (state) => {
  state.jobTemplatesConfig.loading = true;
};

const getJobTemplateConfigSuccess = (state, { payload }) => {
  state.jobTemplatesConfig.data = payload?.result;
  state.jobTemplatesConfig.loading = false;
};

const getJobTemplateConfigFail = (state, { payload }) => {
  state.jobTemplatesConfig.loading = false;
  state.jobTemplatesConfig.error = payload;
};

const editJobTemplateSuccess = (state, { payload: { data } }) => {
  state.currentData = { ...state.currentData, ...data.result };
};

const createJobTemplatePending = (state) => {
  state.createLoading = true;
};

const createJobTemplateSuccessOrFail = (state) => {
  state.createLoading = false;
};

const jobTemplates = createSlice({
  name: 'jobTemplates',
  initialState,
  reducers: {
    goToStepCreateTemplate: (state, { payload: step }) => {
      if (step >= 0 && step <= CREATE_JOB_TEMPLATE_FINAL_STEP) {
        state.currentJobTemplateStep = step;
      }
    },
    goToNextStep: (state) => {
      const newStep = state.currentJobTemplateStep + 1;
      if (newStep > 0 && newStep <= CREATE_JOB_TEMPLATE_FINAL_STEP) {
        state.currentJobTemplateStep = newStep;
      }
    },
    goToPrevStep: (state) => {
      const newStep = state.currentJobTemplateStep - 1;
      if (newStep >= 0 && newStep <= CREATE_JOB_TEMPLATE_FINAL_STEP) {
        state.currentJobTemplateStep = newStep;
      }
    },
    setJobTemplatePayload: (state, { payload }) => {
      state.jobTemplatePayload = {
        ...state.jobTemplatePayload,
        ...payload,
      };
    },
    resetJobTemplatePayload: (state) => {
      state.jobTemplatePayload = {};
    },
    resetCreateJobTemplateStep: (state) => {
      state.currentJobTemplateStep = 0;
    },
    setSelectedTemplate: (state, { payload }) => {
      state.hasSelectedTemplate = payload;
    },
  },
  extraReducers: {
    [getJobTemplatesDetail.pending]: getJobTemplatesDetailPending,
    [getJobTemplatesDetail.fulfilled]: getJobTemplatesDetailFulfilled,
    [getJobTemplatesDetail.rejected]: getJobTemplatesDetailFailed,

    [getAllJobTemplates.pending]: getAllJobTemplatesPending,
    [getAllJobTemplates.fulfilled]: getAllJobTemplatesSuccess,
    [getAllJobTemplates.rejected]: getAllJobTemplatesFail,

    [getJobTemplateConfig.pending]: getJobTemplateConfigPending,
    [getJobTemplateConfig.fulfilled]: getJobTemplateConfigSuccess,
    [getJobTemplateConfig.rejected]: getJobTemplateConfigFail,

    [createJobTemplate.pending]: createJobTemplatePending,
    [createJobTemplate.fulfilled]: createJobTemplateSuccessOrFail,
    [createJobTemplate.rejected]: createJobTemplateSuccessOrFail,

    [editJobTemplate.fulfilled]: editJobTemplateSuccess,
  },
});

export const {
  goToNextStep,
  goToPrevStep,
  goToStepCreateTemplate,
  setJobTemplatePayload,
  resetJobTemplatePayload,
  resetCreateJobTemplateStep,
  setSelectedTemplate,
} = jobTemplates.actions;

export default jobTemplates.reducer;

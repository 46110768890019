import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  deleteJobListingApi,
  getAllJobListingApi,
  getJobListingsDetailApi,
  getSummaryJobListingApi,
  checkJobReferencesExistentApi,
  createCampaignApi,
  editCampaignApi,
  createJobListingApi,
  getCampaignsApi,
  getWorkplacesApi,
  editJobListingApi,
  moveJobListingToCampaignApi,
  closeJobListingApi,
} from 'api/jobListings';
import { notification } from 'antd';
import i18next from 'i18next';
import { apiWrapper } from 'utils/reduxUtils';
import { upperFirstChar } from 'utils/textUtils';

export const MODEL_NAME = 'jobListings';

export const getAllJobListings = createAsyncThunk(
  `${MODEL_NAME}/getAllJobListings`,
  async (payload = {}, thunkApi) => {
    try {
      const { size, page, filter, sorts, total } =
        thunkApi.getState().jobListings?.resourceData || {};
      const response = await apiWrapper({}, getAllJobListingApi, {
        size,
        total,
        page,
        filter,
        sorts,
        ...payload.data,
      });
      return {
        data: {
          page: Math.ceil(response.total / size),
          ...response,
          viewType: payload?.data?.viewType,
        },
        options: payload.options,
      };
    } catch (error) {
      return thunkApi.rejectWithValue({
        id: payload.data.id,
        data: error,
        viewType: `job${upperFirstChar(payload?.data?.viewType)}`,
      });
    }
  },
);

export const getSummaryJobListing = createAsyncThunk(
  `${MODEL_NAME}/getSummaryJobListing`,
  async (payload = {}, thunkAPI) => {
    try {
      const response = await apiWrapper({}, getSummaryJobListingApi, payload);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  },
);

export const delJobListing = createAsyncThunk(
  `${MODEL_NAME}/deleteJobListing`,
  async (payload = {}, thunkAPI) => {
    try {
      const response = await apiWrapper(
        { isShowSuccessNoti: true },
        deleteJobListingApi,
        payload,
      );
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  },
);

export const closeJobListing = createAsyncThunk(
  `${MODEL_NAME}/closeJobListing`,
  async (payload = {}, thunkAPI) => {
    try {
      const response = await apiWrapper(
        { isShowSuccessNoti: true },
        closeJobListingApi,
        payload,
      );
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  },
);

export const getJobListingsDetail = createAsyncThunk(
  `${MODEL_NAME}/getJobListingsDetail`,
  async (payload = {}, thunkAPI) => {
    try {
      const { id } = payload;
      return await apiWrapper({}, getJobListingsDetailApi, id);
    } catch (error) {
      return thunkAPI.rejectWithValue({ id: payload });
    }
  },
);

export const createCampaign = createAsyncThunk(
  `${MODEL_NAME}/createCampaign`,
  async (payload = {}, thunkAPI) => {
    try {
      const response = await createCampaignApi(payload);

      notification.success({
        message: i18next.t('success.title'),
        description: i18next.t('jobListings.createCampaignSuccess'),
      });
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error?.message);
    }
  },
);

export const getCampaigns = createAsyncThunk(
  `${MODEL_NAME}/getCampaigns`,
  async (payload, thunkAPI) => {
    try {
      const response = await apiWrapper({}, getCampaignsApi, payload?.data);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  },
);

export const moveJobListingToCampaign = createAsyncThunk(
  `${MODEL_NAME}/moveJobListingToCampaign`,
  async (payload = {}, thunkAPI) => {
    const { itemId, campaignId } = payload;
    try {
      const response = await apiWrapper(
        { isShowSuccessNoti: true },
        moveJobListingToCampaignApi,
        itemId,
        campaignId,
      );
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  },
);
export const getWorkplaces = createAsyncThunk(
  `${MODEL_NAME}/getWorkplaces`,
  async (payload, thunkAPI) => {
    try {
      const response = await apiWrapper({}, getWorkplacesApi, payload?.data);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  },
);

export const editCampaign = createAsyncThunk(
  `${MODEL_NAME}/editCampaign`,
  async (payload, thunkAPI) => {
    try {
      const response = await apiWrapper(
        { isShowSuccessNoti: true },
        editCampaignApi,
        payload?.id,
        payload?.data,
      );
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error?.message);
    }
  },
);

export const createJobListing = createAsyncThunk(
  `${MODEL_NAME}/createJobListing`,
  async (payload, thunkAPI) => {
    try {
      const response = await apiWrapper(
        {
          isShowSuccessNoti: true,
          successDescription: i18next.t('jobListings.createJobListingSuccess'),
        },
        createJobListingApi,
        payload,
      );
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  },
);

export const checkJobReferencesExistent = createAsyncThunk(
  `${MODEL_NAME}/checkJobReferencesExistent`,
  async (payload, thunkAPI) => {
    try {
      const response = await apiWrapper(
        {},
        checkJobReferencesExistentApi,
        payload,
      );
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  },
);

export const editJobListing = createAsyncThunk(
  `${MODEL_NAME}/editJobListing`,
  async (payload = {}, thunkAPI) => {
    try {
      const { data, postId } = payload;
      const response = await apiWrapper(
        { isShowSuccessNoti: true },
        editJobListingApi,
        postId,
        data,
      );
      return { data: response };
    } catch (error) {
      return thunkAPI.rejectWithValue({ data: error });
    }
  },
);

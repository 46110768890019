import React from 'react';
import { StepFooter } from 'components/RestLayout/StepsCreateLayout/styles';
import { Button, Row } from 'antd';
import i18next from 'i18next';
import { RightOutlined } from '@ant-design/icons';
import withFooterFunc, {
  FooterProps,
} from 'containers/Advertisement/hocs/withFooterFunc';

const Footer = ({ onNext }: FooterProps) => (
  <StepFooter>
    <Row justify="end">
      <Button type="primary" onClick={onNext}>
        {i18next.t('button.next')}
        <RightOutlined />
      </Button>
    </Row>
  </StepFooter>
);

export default withFooterFunc(Footer);

import React from 'react';
import PropTypes from 'prop-types';
import CardCustom from 'components/common/CardCustom';
import { LampChargeIcon, NebulasIcon } from 'components/common/SVGIcon';
import SkillContent from '../../CardContentCustom/SkillContent';
import CardCustomContent from '../../CardContentCustom';

const MySkills = ({ skills }) => (
  <div className="mb-32">
    <CardCustom title="jobApplications.generalInfo.mySkills" Icon={NebulasIcon}>
      <div>
        {skills?.map((item, index) => (
          <CardCustomContent Icon={LampChargeIcon} key={Number(index)}>
            <SkillContent item={item} />
          </CardCustomContent>
        ))}
      </div>
    </CardCustom>
  </div>
);

MySkills.propTypes = {
  skills: PropTypes.array,
};

export default MySkills;

import React from 'react';
import { Typography } from 'antd';
import i18next from 'i18next';
import { ContentNotAvailableIcon } from '../SVGIcon';

interface Props {
  className?: string;
}

const ContentUnavailable = ({ className }: Props) => (
  <div className={`flex-col flex-center ${className ?? ''}`}>
    <ContentNotAvailableIcon />
    <Typography.Text className="text-16">
      {i18next.t('error.roleHasNoPermission')}
    </Typography.Text>
  </div>
);

export default ContentUnavailable;

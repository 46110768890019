import { createAsyncThunk } from '@reduxjs/toolkit';
import { convertRequestParams } from 'redux/crudCreator/dataProvider';
import { apiWrapper } from 'utils/reduxUtils';

export const getAllCustom = (resource, apiGetAll, idProp = 'id') =>
  createAsyncThunk(
    `${resource}/getAllCustom`,
    async (payload = {}, thunkApi) => {
      try {
        const { data = {}, options = {} } = payload;

        const { limit, offset, filter, includes, outsideFilter, orderBy } =
          thunkApi.getState()[resource]?.resourceData || {};

        const { outsideFilter: outsideFilterPayload, ...dataPayload } = data;

        const convertRequest = convertRequestParams(
          'GET_ALL',
          {
            limit,
            offset,
            filter,
            includes,
            orderBy,
            ...outsideFilter,
            ...dataPayload,
            ...outsideFilterPayload,
            ...(options.keepFilter && {
              filter: { ...filter, ...data?.filter },
            }),
          },
          resource,
          options,
        );

        const response = await apiWrapper(
          {},
          apiGetAll,
          payload[idProp],
          convertRequest,
        );

        return {
          data: {
            numberOfPages: Math.ceil(response.total / limit),
            ...response,
          },
          options,
        };
      } catch (error) {
        return thunkApi.rejectWithValue({ id: payload.data.id, data: error });
      }
    },
  );

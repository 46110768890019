import { put, get, post, del } from './utils';

export async function updateApplicationStatusApi(id, status) {
  return put(`/admin/applications/${id}/${status}`);
}

export async function getApplicationPendingCountApi() {
  return get('/admin/applications/summary');
}

export async function getAllApplicationsNotPagingApi() {
  return get('/admin/applications/all');
}

export async function getAllInformationRequestApi(id, data) {
  return get(`/admin/applications/${id}/informationRequests`, data);
}

export async function createInformationRequestApi(id, data) {
  return post(`/admin/applications/${id}/informationRequests`, data);
}

export async function editInformationRequestApi(id, data) {
  return put(`/admin/informationRequests/${id}`, data);
}

export async function delInformationRequestApi(id) {
  return del(`/admin/informationRequests/${id}`);
}

export async function getAllDocumentsApi(id, data) {
  return get(`/admin/applications/${id}/documents`, data);
}

export async function createDocumentApi(id, data) {
  return post(`/admin/applications/${id}/documents`, data);
}

export async function delDocumentApi(id) {
  return del(`/admin/applicationDocuments/${id}`);
}

export async function getAllActivitiesApi(id, data) {
  return get(`/admin/applications/${id}/activities`, data);
}

export async function createActivityApi(id, data) {
  return post(`/admin/applications/${id}/activities`, data);
}

export async function editActivityApi(id, data) {
  return put(`/admin/activities/${id}`, data);
}

export async function doneTaskApi(id) {
  return put(`/admin/activities/${id}/tasks`);
}

export async function updateApplicationArchiveStatusApi(
  id: string,
  data: { archivedReason: string | null },
) {
  return put(`/admin/applications/archived/${id}`, data);
}

import React, { useCallback, useMemo, useRef } from 'react';

import { useParams } from 'react-router';
import { useSelector } from 'react-redux';
import { forEach } from 'lodash-es';

import CardSection from 'components/common/CardSection';
import { CUSTOMISE_BUTTONS } from 'configs/localData/constant';

import CustomiseButtonsModal from '../../Modals/CustomiseButtonsModal';
import { CustomiseButtonsStyles } from './styles';
import CustomiseButtonItem from './CustomiseButtonItem';

const CustomiseButtons = () => {
  const modalRef = useRef();

  const { id } = useParams();

  const data = useSelector((state) => state.centres.appSettings);

  const initialValue = useMemo(() => {
    const res = {};

    forEach(data, (item) => {
      if (item.key) {
        res[item.key] = item.value;
      }
    });

    return res;
  }, [data]);

  const onClickButton = useCallback(() => {
    modalRef.current.open(initialValue);
  }, [initialValue]);

  return (
    <>
      <CardSection
        isEdit
        onClickButton={onClickButton}
        title="centreDetail.customiseButtons"
        permissionKey="customiseButtons"
        action="isEdit"
      >
        <CustomiseButtonsStyles>
          {CUSTOMISE_BUTTONS.map((item) => (
            <CustomiseButtonItem
              key={item.key}
              titleBtn={item.text}
              customItem={data?.find((cus) => cus?.key === item.key)}
            />
          ))}
        </CustomiseButtonsStyles>
      </CardSection>
      <CustomiseButtonsModal id={id} ref={modalRef} />
    </>
  );
};

export default CustomiseButtons;

import styled from 'styled-components';

export const SelectWorkplaceStepWrapperStyles = styled.div`
  .selected-workspace-panel {
    background: ${({ theme }) => theme.background.container};
    padding: 10px 30px;
    & > span {
      font-weight: bold;
      color: ${({ theme }) => theme.palette.primary};
    }
  }

  .reference-title {
    font-weight: bold;
    color: ${({ theme }) => theme.text.grayNeutral500};
  }

  .ant-checkbox-group {
    width: 100%;
    & > label {
      margin: 10px 0;
    }
  }

  .ant-checkbox-wrapper {
    width: 100%;
    margin: 0;
  }

  .selected-group {
    margin-top: 16px;

    .ant-checkbox-group > label {
      margin: 15px 0;
    }

    .ant-checkbox-wrapper > span:last-child:not(.ant-checkbox) {
      width: 100%;
    }
    .reference-input {
      margin-bottom: 0 !important;
    }

    &.hidden-item {
      margin-bottom: 0;
      .ant-form-item-control-input {
        display: none;
      }
    }
  }

  .divider {
    border-bottom: 1px solid #d0d5dd;
    padding-bottom: 12px;
  }

  .ant-divider {
    border-color: #ebecf0;
    margin: 18px 0;
  }

  .overlay-reference .ant-tooltip-inner {
    min-width: 200px !important;
    padding: 4px 12px !important;
  }

  .tooltip-reference {
    font-weight: 700;
    font-size: 15px;
    line-height: 24px;
    color: ${({ theme }) => theme.text.grayNeutral500};
    span {
      margin-left: 6px;
    }
  }

  .check-all {
    width: fit-content;
  }
`;

import { createSelector } from 'reselect';
import { RootState } from 'redux/store';

const selectStaffProfile = (state: RootState) => state.staffProfile;

export const getCurrentStepStaffProfile = createSelector(
  selectStaffProfile,
  (staffProfile) => staffProfile.currentStep,
);

export const getPayloadStaffProfile = createSelector(
  selectStaffProfile,
  (staffProfile) => staffProfile.staffProfilePayload,
);

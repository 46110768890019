import styled from 'styled-components';

const EULAStyles = styled.div`
  padding: 30px 100px;
  font-family: 'Mulish';
  background: #fff;
  @media only screen and (max-width: 1200px) {
    padding: 30px 50px;
  }
  @media only screen and (max-width: 768px) {
    padding: 30px;
  }
  @media only screen and (max-width: 576px) {
    padding: 20px;
  }
`;

export default EULAStyles;

export const EULAContentStyles = styled.div`
  font-size: 16px;
  li {
    margin-bottom: 10px;
  }
`;

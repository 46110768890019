import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from 'antd';
import DiamondPoint from 'components/common/DiamondPoint';
import i18next from 'i18next';

const JobHighlightsAndBenefits = ({ benefits }) => (
  <div>
    <div>
      <Typography.Text className="fw-bold text-20 mr-10">
        {i18next.t('jobs.moreAboutTheJob.jobHighLightsAndBenefits')}
      </Typography.Text>
      <div className="mt-12">
        {benefits?.map((item, index) => (
          <div className="flex" key={String(index)}>
            <div className="mt-12">
              <DiamondPoint />
            </div>
            <div className="ml-12 leading-loose text-16 break-all">
              {item}
              <p className="mt-10" />
            </div>
          </div>
        ))}
      </div>
    </div>
  </div>
);

JobHighlightsAndBenefits.propTypes = {
  benefits: PropTypes.array,
};

export default JobHighlightsAndBenefits;

import {
  Button,
  Empty,
  Form,
  FormInstance,
  Input,
  Modal,
  Radio,
  Space,
  Spin,
} from 'antd';
import {
  EditRoundedIcon,
  SearchIcon,
  TrashIcon,
} from 'components/common/SVGIcon';
import i18next from 'i18next';
import React, { useRef, useCallback, useState, useEffect } from 'react';
import { Waypoint } from 'react-waypoint';
import { isEmpty, debounce } from 'lodash-es';
import TargetedAudienceItem from 'containers/Advertisement/components/items/TargetedAudienceItem';
import { InfoCircleFilled } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { deleteAdAudience, getAdAudiences } from 'redux/advertisement/actions';
import { AudienceItem, TargetedAudience } from 'api/advertisement/type';
import { getAdAudiencesSelector } from 'redux/advertisement/selectors';
import { getCountAdsUsingAudience } from 'api/advertisement';
import renderHTML from 'react-render-html';
import { ExistingAudienceWrapper } from './styles';
import EditAudienceModal, { EditAudienceModalRef } from './EditAudienceModal';

interface Props {
  form: FormInstance;
  onFinish?: () => void;
  initialValues?: TargetedAudience;
}

const ExistingAudience = ({ form, onFinish, initialValues }: Props) => {
  const dispatch = useDispatch();
  const editAudienceModalRef = useRef<EditAudienceModalRef>();
  const [textSearch, setTextSearch] = useState('');
  const [checkingAdUsedAudience, setCheckingAdUsedAudience] =
    useState<string>(null);

  const { data, loading, resourceParams } = useSelector(getAdAudiencesSelector);

  const getListAudience = useCallback(
    ({ data, isRefresh }) =>
      dispatch(
        getAdAudiences({
          data: {
            page: 1,
            size: 10,
            ...data,
          },
          options: {
            isRefresh,
          },
        }),
      ),
    [dispatch],
  );

  const debounceSearch = debounce((inputTextSearch: string) => {
    setTextSearch(inputTextSearch);

    getListAudience({
      data: {
        textSearch: inputTextSearch || undefined,
      },
      isRefresh: true,
    });
  }, 600);

  const onSearchChange = (e) => {
    debounceSearch(e.target.value);
  };

  const onEnterWaypoint = () => {
    getListAudience({
      data: {
        page: +resourceParams.page + 1,
        size: resourceParams.size || 10,
        textSearch: textSearch || undefined,
      },
      isRefresh: false,
    });
  };

  const isEnableLoadMore =
    !isEmpty(data) &&
    !loading &&
    resourceParams.page < Math.ceil(resourceParams.total / resourceParams.size);

  const handleEditAudience = async (item: AudienceItem) => {
    setCheckingAdUsedAudience(item.id);

    const { result: countAdsUsingThisAudience } =
      (await getCountAdsUsingAudience(item.id)) || {};

    setCheckingAdUsedAudience(null);

    if (countAdsUsingThisAudience === 0) {
      editAudienceModalRef.current.toggleVisible(item);
      return;
    }

    Modal.confirm({
      title: renderHTML(
        i18next.t('advertisement.confirmEditExistingAudience', {
          countAds: countAdsUsingThisAudience,
          verbForm: countAdsUsingThisAudience === 1 ? 'ad is' : 'ads are',
        }),
      ),
      okText: i18next.t('button.continue'),
      icon: <InfoCircleFilled />,
      onOk: () => {
        editAudienceModalRef.current.toggleVisible(item);
      },
      cancelButtonProps: { className: 'btn-line-primary fw-bold' },
    });
  };

  const handleRemoveAudience = (id: string) => {
    Modal.confirm({
      title: i18next.t('advertisement.confirmRemoveAudience'),
      okText: i18next.t('button.remove'),
      icon: <InfoCircleFilled />,
      onOk: () => dispatch(deleteAdAudience(id)),
      cancelButtonProps: { className: 'btn-line-primary fw-bold' },
    });
  };

  useEffect(() => {
    getListAudience({
      data: null,
      isRefresh: true,
    });
  }, [form, getListAudience]);

  return (
    <ExistingAudienceWrapper>
      <Input
        prefix={<SearchIcon className="text-gray-500 mr-10" />}
        placeholder={i18next.t('advertisement.searchAudience')}
        onChange={onSearchChange}
        allowClear
      />
      <Form
        className="mt-16"
        form={form}
        onFinish={onFinish}
        initialValues={{ id: initialValues?.id }}
      >
        <Form.Item
          name="id"
          className="mb-0"
          rules={[
            {
              required: true,
              message: i18next.t('advertisement.requiredAudience'),
            },
          ]}
        >
          <Radio.Group>
            <Space direction="vertical" size={10}>
              {isEmpty(data) ? (
                <Empty className="mt-32" />
              ) : (
                data?.map((item: AudienceItem) => (
                  <Radio key={item.id} value={item.id}>
                    <div className="flex-center-between">
                      <strong>{item.name}</strong>
                      <div>
                        <Button
                          onClick={() => handleEditAudience(item)}
                          loading={checkingAdUsedAudience === item.id}
                          shape="circle"
                          type="text"
                          icon={<EditRoundedIcon />}
                        />
                        <Button
                          onClick={() => handleRemoveAudience(item.id)}
                          shape="circle"
                          type="text"
                          icon={<TrashIcon />}
                        />
                      </div>
                    </div>
                    <TargetedAudienceItem
                      userType={item.userType}
                      userLocation={item.userLocation}
                      userLocationValues={item.userLocationValues}
                    />
                  </Radio>
                ))
              )}
            </Space>
          </Radio.Group>
        </Form.Item>
        {isEnableLoadMore && (
          <div className="h-1">
            <Waypoint onEnter={onEnterWaypoint} />
          </div>
        )}
        {loading && <Spin className="mt-12 ml-24" />}
      </Form>
      <EditAudienceModal ref={editAudienceModalRef} />
    </ExistingAudienceWrapper>
  );
};

export default ExistingAudience;

import { baseApi } from 'redux/@rtkQuery/baseApi';
import {
  CentreMetricsPayload,
  CentreMetricsResponse,
  ExportLibrary,
  ExportLibraryPayload,
  ChartMarketingMetricsPayload,
  ChartMarketingMetricsResponse,
  ExportMarketingMetricsResponse,
  SummaryMetricsResponse,
  DeleteResponse,
} from 'redux/@rtkQuery/marketingMetris/type';
import qs from 'qs';
import { ORDER_BY_TYPE, PREFIX_API_VER_2 } from 'configs/localData/constant';
import { MARKETING_METRICS_TAG } from 'redux/@rtkQuery/tagTypes';
import { isNil, omitBy } from 'lodash-es';
import {
  DEFAULT_CURRENT_PAGE,
  DEFAULT_SORT_EXPORT_LIBRARY,
} from 'containers/MarketingMetrics/constants';
import { stringifyObjectWithBrackets } from 'api/utils';
import { LIMIT_ITEM } from 'containers/JobListings/constants';

export const marketingMetricsApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getMarketingMetricsCentres: builder.query<
      CentreMetricsResponse,
      CentreMetricsPayload
    >({
      query: (payload) =>
        `/${PREFIX_API_VER_2}/crm/listing-performance/marketing-metric/centres?${qs.stringify(
          omitBy(payload, isNil),
        )}`,
      serializeQueryArgs: ({ endpointName }) => endpointName,
      merge: (currentCache, newItems, otherArgs) => {
        if (otherArgs.arg.page === DEFAULT_CURRENT_PAGE) {
          return newItems;
        }
        currentCache.results.push(...newItems.results);
      },
      forceRefetch({ currentArg, previousArg }) {
        return currentArg !== previousArg;
      },
      providesTags: [MARKETING_METRICS_TAG],
    }),

    exportMarketingMetrics: builder.mutation<
      Partial<ExportMarketingMetricsResponse>,
      CentreMetricsPayload
    >({
      query(data) {
        return {
          url: `/${PREFIX_API_VER_2}/crm/listing-performance/marketing-metric/report`,
          method: 'POST',
          body: data,
        };
      },
    }),

    getExportLibrary: builder.query<ExportLibrary, ExportLibraryPayload>({
      query: (params) =>
        `/${PREFIX_API_VER_2}/crm/resources/export-library?${stringifyObjectWithBrackets(
          {
            sortKey: params.sortKey || DEFAULT_SORT_EXPORT_LIBRARY,
            sortOrder: params.sortOrder || ORDER_BY_TYPE.DESC,
            page: params.page || DEFAULT_CURRENT_PAGE,
            size: params.size || LIMIT_ITEM,
          },
        )}`,
      providesTags: [MARKETING_METRICS_TAG],
    }),

    deleteExportLibrary: builder.mutation<DeleteResponse, string>({
      query(id) {
        return {
          url: `/${PREFIX_API_VER_2}/crm/resources/export-library?id=${id}`,
          method: 'DELETE',
        };
      },
    }),

    getMarketingMetricsChart: builder.query<
      ChartMarketingMetricsResponse,
      ChartMarketingMetricsPayload
    >({
      query(params) {
        return {
          url: `/${PREFIX_API_VER_2}/crm/listing-performance/marketing-metric/chart?${stringifyObjectWithBrackets(
            params,
          )}`,
        };
      },
      providesTags: [MARKETING_METRICS_TAG],
    }),

    getMarketingMetricsSummary: builder.query<
      SummaryMetricsResponse,
      ChartMarketingMetricsPayload
    >({
      query(params) {
        return {
          url: `/${PREFIX_API_VER_2}/crm/listing-performance/marketing-metric/summary?${stringifyObjectWithBrackets(
            params,
          )}`,
        };
      },
      providesTags: [MARKETING_METRICS_TAG],
    }),
  }),
});

export const {
  useGetMarketingMetricsCentresQuery,
  useExportMarketingMetricsMutation,
  useGetExportLibraryQuery,
  useDeleteExportLibraryMutation,
  useGetMarketingMetricsChartQuery,
  useGetMarketingMetricsSummaryQuery,
} = marketingMetricsApi;

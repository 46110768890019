import { createSlice } from '@reduxjs/toolkit';
import {
  getConfig,
  getSummaries,
  getRevenue,
  getGlobalSales,
  getSummariesCustomers,
  getPopularProduct,
  getConfigV2,
} from './actions';
import {
  getSubscriptionConfig,
  formatRolePermissionConfig,
} from './dataProvider';

export const initialState = {
  data: {},
  dataConfig: {},
};

const getConfigPending = (state) => {
  state.loading = true;
};

const getConfigSuccess = (state, { payload }) => {
  state.data = payload;
  state.loading = false;
  state.data.rolePermissionsConfig = formatRolePermissionConfig(
    payload.rolePermission,
  );
  state.data.subscriptionsConfig = getSubscriptionConfig(payload.CRMPackage);
  state.isLoaded = true;
};

const getConfigFail = (state, { payload }) => {
  state.loading = false;
  state.error = payload;
};

const getConfigV2Success = (state, { payload }) => {
  state.configV2 = payload;
  state.loading = false;
  state.isLoaded = true;
};

const getConfigV2Fail = (state, { payload }) => {
  state.loading = false;
  state.error = payload;
};

const getConfigV2Pending = (state) => {
  state.configLoading = true;
};

const { actions, reducer } = createSlice({
  name: 'Config',
  initialState,
  reducers: {
    clearSummariesData: (state) => {
      state.summaries = {};
    },
  },
  extraReducers: {
    [getConfig.pending]: getConfigPending,
    [getConfig.fulfilled]: getConfigSuccess,
    [getConfig.rejected]: getConfigFail,

    [getConfigV2.fulfilled]: getConfigV2Success,
    [getConfigV2.rejected]: getConfigV2Fail,

    [getSummaries.fulfilled]: (state, { payload }) => {
      state.summaries = payload;
    },
    [getSummaries.rejected]: (state, { payload }) => {
      state.error = payload;
    },
    [getRevenue.fulfilled]: (state, { payload }) => {
      if (payload.type === 'day') {
        state.revenue.byDay = payload.data;
      } else {
        state.revenue.byMonth = payload.data;
      }
    },
    [getRevenue.fulfilled]: (state, { payload }) => {
      state.error = payload;
    },
    [getGlobalSales.fulfilled]: (state, { payload }) => {
      state.globalSales = payload;
    },
    [getGlobalSales.rejected]: (state, { payload }) => {
      state.error = payload;
    },
    [getSummariesCustomers.fulfilled]: (state, { payload }) => {
      state.summariesCustomers = payload;
    },
    [getSummariesCustomers.rejected]: (state, { payload }) => {
      state.error = payload;
    },
    [getPopularProduct.fulfilled]: (state, { payload }) => {
      state.popularProduct = payload;
    },
    [getPopularProduct.rejected]: (state, { payload }) => {
      state.error = payload;
    },

    [getConfigV2.pending]: getConfigV2Pending,
    [getConfigV2.fulfilled]: getConfigV2Success,
    [getConfigV2.rejected]: getConfigV2Fail,
  },
});

export const { clearSummariesData } = actions;

export default reducer;

import I18n from 'i18next';
import { notification } from 'antd';
import axios from 'axios';
import { PREFIX_API_VER_1 } from 'configs/localData/constant';
import { getRefreshToken, getSessionToken, setSessionToken } from 'utils/token';
import { logoutApi } from 'api/user';
import { ADS_IDENTITY_KEY } from 'containers/Advertisement/constants';
import { apiWrapper } from './reduxUtils';

export const logoutAction = async () => {
  try {
    const response = await apiWrapper(
      {
        isShowProgress: false,
        isShowSuccessNoti: false,
        isCheckError: false,
      },
      logoutApi,
      {
        refreshToken: getRefreshToken(),
      },
    );
    return response;
  } finally {
    sessionStorage.clear();
    const getAsIdentity = localStorage.getItem(ADS_IDENTITY_KEY);
    localStorage.clear();
    localStorage.setItem(ADS_IDENTITY_KEY, getAsIdentity);
    window.location.href = '/login';
  }
};

export const refreshAccessToken = async () => {
  if (!getSessionToken) {
    logoutAction();
    return;
  }
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_BASE_API_URL}/${PREFIX_API_VER_1}/admin/auth/refreshToken`,
      {
        token: getRefreshToken(),
      },
    );
    const newToken = response.data.token;
    if (newToken) {
      setSessionToken(newToken);
    }
    return newToken;
  } catch (err) {
    notification.error({
      message: I18n.t('error.title'),
      description: err.message || I18n.t('error.description'),
    });
    logoutAction();
  }
};

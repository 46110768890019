import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { Avatar } from 'antd';
import PropTypes from 'prop-types';
import i18next from 'i18next';
import { UserOutlined } from '@ant-design/icons';
import { getRecordData } from 'utils/tools';
import { isEmpty } from 'lodash-es';
import { GENDERS } from 'configs/localData';
import { UserTagWrapper } from './styles';

const UserInfo = (props) => {
  const {
    record,
    isShowName,
    size,
    isLink,
    prefixLink,
    nameProp,
    roleProp,
    avatarProp,
    suffixLink,
    isShowGender,
    genderProp,
  } = props;

  const genderItem = useMemo(
    () =>
      GENDERS.find(
        (gender) =>
          gender.value === getRecordData(record, genderProp)?.toLowerCase(),
      ),
    [record, genderProp],
  );

  const nameUser = useMemo(
    () => getRecordData(record, nameProp) || record?.email,
    [record, nameProp],
  );

  const roleUser = useMemo(
    () => getRecordData(record, roleProp),
    [record, roleProp],
  );

  const genderAvatar = useMemo(
    () => (
      <div className="avatar-wrapper">
        <Avatar
          src={getRecordData(record, avatarProp)}
          icon={<UserOutlined />}
          size={size}
          style={{ minWidth: '32px' }}
          alt=""
        />
        {isShowGender && genderItem?.icon && (
          <genderItem.icon
            className="gender-icon"
            style={{
              color: genderItem.color,
              backgroundColor: genderItem.background,
              transform: genderItem.transform,
            }}
          />
        )}
      </div>
    ),
    [record, genderItem, avatarProp, isShowGender, size],
  );

  const content =
    record &&
    (isLink ? (
      <Link
        to={`/${prefixLink}/${record?.id}/${suffixLink}`}
        className="link-class"
      >
        <div className="name-user">{nameUser}</div>
        <div style={{ lineHeight: '12px' }}>
          <small className="small-text">{roleUser}</small>
        </div>
      </Link>
    ) : (
      // eslint-disable-next-line
      <a role="main" className="non-link-class">
        <div className="name-user">{nameUser}</div>
        <div style={{ lineHeight: '12px' }}>
          <small className="small-text">{roleUser}</small>
        </div>
      </a>
    ));

  return (
    <UserTagWrapper>
      <div className="avatar-item">
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {genderAvatar}
          {!isEmpty(record) && isShowName ? (
            content
          ) : (
            <span>{i18next.t('error.waitingUpdate')}</span>
          )}
        </div>
      </div>
    </UserTagWrapper>
  );
};

UserInfo.propTypes = {
  record: PropTypes.object,
  isShowName: PropTypes.bool,
  size: PropTypes.number,
  isLink: PropTypes.bool,
  nameProp: PropTypes.string,
  roleProp: PropTypes.string,
  avatarProp: PropTypes.string,
  prefixLink: PropTypes.string,
  suffixLink: PropTypes.string,
  genderProp: PropTypes.string,
  isShowGender: PropTypes.bool,
};

UserInfo.defaultProps = {
  nameProp: 'fullName',
  roleProp: 'businessRole',
  avatarProp: 'avatar',
  isShowName: true,
  isLink: true,
  prefixLink: '/users',
  suffixLink: 'show',
  genderProp: 'gender',
};

export default UserInfo;

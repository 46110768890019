import React, { useContext, useEffect } from 'react';
import { Col, Row } from 'antd';
import RestSelect from 'components/RestInput/RestSelect';
import { JOB_LISTING_STATUS } from 'containers/JobListings/constants';
import { RestInputContext } from 'components/RestInput/RestInputContext';
import { useLocation } from 'react-router';
import ReferenceInputV2 from 'containers/rest/ReferenceInputV2';
import { getFilterFromUrl } from 'utils/tools';
import { useSelector } from 'react-redux';
import { getJobRoles, getJobTypes } from 'redux/config/selectors';
import i18next from 'i18next';
import { PREFIX_API_VER_2, PREFIX_URL } from 'configs/localData/constant';

const FilterForm = () => {
  const { form } = useContext(RestInputContext);
  const { search } = useLocation();
  const jobRoles = useSelector(getJobRoles) || {};
  const jobTypes = useSelector(getJobTypes) || {};

  useEffect(() => {
    const { filter } = getFilterFromUrl(search) || {};
    const { ...restFilter } = filter?.filter || {};
    form.setFieldsValue({
      ...restFilter,
    });
  }, [search, form]);

  return (
    <Row gutter={16} className="justify-end">
      <Col lg={4} md={12} xs={24}>
        <ReferenceInputV2
          prefixUrl={PREFIX_URL.crm}
          resource="job-seekers/posts/templates/basic-info"
          source="templateName"
          searchKey="templateName"
          mappedBy="templateName"
          notLikeFilter
          requestApi={PREFIX_API_VER_2}
        >
          <RestSelect
            valueProp="templateName"
            titleProp="templateName"
            placeholder="jobListings.jobTemplate"
            autoComplete="off"
            isFilterOption={false}
            isShowTooltip
          />
        </ReferenceInputV2>
      </Col>
      <Col lg={4} md={12} xs={24}>
        <ReferenceInputV2
          prefixUrl={PREFIX_URL.crm}
          resource="job-seekers/posts/job-title/basic-info"
          source="jobTitle"
          searchKey="jobTitle"
          mappedBy="jobTitle"
          notLikeFilter
          requestApi={PREFIX_API_VER_2}
        >
          <RestSelect
            valueProp="jobTitle"
            titleProp="jobTitle"
            placeholder="jobListings.jobTitle"
            autoComplete="off"
            isFilterOption={false}
            isShowTooltip
          />
        </ReferenceInputV2>
      </Col>
      <Col lg={4} md={12} xs={24}>
        <RestSelect
          source="jobRoleId"
          placeholder="jobListings.jobRole"
          resourceData={jobRoles || []}
          valueProp="id"
          titleProp="name"
          isShowTooltip
        />
      </Col>
      <Col lg={4} md={12} xs={24}>
        <RestSelect
          source="jobTypeId"
          placeholder="jobListings.jobType"
          resourceData={jobTypes || []}
          isShowTooltip
          valueProp="id"
          titleProp="name"
        />
      </Col>
      <Col lg={4} md={12} xs={24}>
        <RestSelect
          source="jobListingStatus"
          placeholder="jobListings.jobListingStatus"
          resourceData={JOB_LISTING_STATUS}
          formatText={(data) => i18next.t(data)}
          valueProp="value"
          titleProp="text"
          isShowTooltip
        />
      </Col>
    </Row>
  );
};

export default FilterForm;

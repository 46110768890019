import React from 'react';
import PropTypes from 'prop-types';

import CheckPermission from 'components/common/CheckPermissions';
import DeleteButton from './DeleteButton';
import FallbackBtn from './FallbackBtn';
import { ButtonStyles } from './styles';

const DeleteDocument = (props) => (
  <ButtonStyles>
    <CheckPermission
      permissionKey="applications"
      action="isEdit"
      fallback={<FallbackBtn />}
    >
      <div className="delete-action-wrapper">
        <DeleteButton {...props} />
      </div>
    </CheckPermission>
  </ButtonStyles>
);

DeleteDocument.propTypes = {
  deleteItem: PropTypes.func,
};

DeleteDocument.defaultProps = {
  deleteItem: () => {},
};

export default DeleteDocument;

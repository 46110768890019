import React from 'react';
import PrivateRoute from 'routes/PrivateRoutes/PrivateRoute';
import JobListings from 'pages/Jobs/JobListings';
import JobTemplates from 'pages/Jobs/JobTemplates';
import { flatMap, map } from 'lodash-es';
import EducatorStaffProfile from 'pages/EducatorStaffProfile';
import Advertisement from 'pages/Marketing/Advertisement';
import { match } from 'react-router';

interface Route {
  path: string;
  component?: React.ComponentType<{ match: match }>;
  routes?: Route[];
}

const PRIVATE_ROUTES_WITHOUT_LAYOUT: Route[] = [
  {
    path: '/jobTemplates/create/:model',
    component: JobTemplates.Create,
  },
  {
    path: '/job-listings/create/:screen',
    component: JobListings.Create,
  },
  {
    path: '/educator-staff-profile/:centreId',
    routes: [
      {
        path: '/create/:screen',
        component: EducatorStaffProfile.Create,
      },
    ],
  },
  {
    path: '/advertisement',
    routes: [
      {
        path: '/create/:screen',
        component: Advertisement.Create,
      },
    ],
  },
];

const renderPrivateRoutesWithoutLayout = () =>
  map(
    flatMap(PRIVATE_ROUTES_WITHOUT_LAYOUT, (route) => {
      if (route.routes) {
        return map(route.routes, (subRoute) => ({
          ...subRoute,
          path: route.path + subRoute.path,
          component: subRoute.component || route.component,
        }));
      }
      return route;
    }),
    (route) => <PrivateRoute {...route} key={route.path} />,
  );

export default renderPrivateRoutesWithoutLayout;

import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { GENDERS } from 'configs/localData';

const ChildrenItem = ({ child }) => {
  const genderItem = useMemo(
    () =>
      GENDERS.find((gender) => gender.value === child.gender?.toLowerCase()),
    [child.gender],
  );
  return (
    <div className="children-item">
      {genderItem?.icon && (
        <genderItem.icon
          className="children-item__gender"
          style={{
            color: genderItem.color,
            backgroundColor: genderItem.background,
            transform: genderItem.transform,
          }}
        />
      )}

      <span className="children-item__name text-primary">
        {`${child.firstName || ''} ${child.lastName || ''}`}
      </span>
    </div>
  );
};

ChildrenItem.propTypes = {
  child: PropTypes.object,
};

export default ChildrenItem;

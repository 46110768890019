import React, { useState } from 'react';
import i18next from 'i18next';
import PageTitle from 'components/common/PageTitle';
import MetricsLineChart from 'components/charts/MetricsLineChart';
import LegendContent from 'components/charts/MetricsLineChart/LegendContent';
import { useGetMarketingMetricsChartQuery } from 'redux/@rtkQuery/marketingMetris';
import { getQueryParams } from 'utils/tools';
import { useLocation } from 'react-router-dom';
import { isEmpty, omit } from 'lodash-es';
import { Empty, notification, Skeleton } from 'antd';
import {
  DEFAULT_CHART_LINES_SELECTED,
  IGNORE_SEARCH_URL_PARAMS,
} from 'containers/MarketingMetrics/constants';
import iconEmpty from 'assets/images/shape-icon-empty.png';
import { WarningFilled } from '@ant-design/icons';
import {
  ChartMarketingMetricsPayload,
  CHART_RESOLUTION,
} from 'redux/@rtkQuery/marketingMetris/type';
import { getMaximumChartResolution } from 'containers/MarketingMetrics/utils';
import { KeyMetricsWrapper, SpinWrapper } from './style';
import KeyMetricCards from '../KeyMetricCards';
import ChartResolutionFilter from '../ChartResolutionFilter';

const KeyMetrics = () => {
  const { search } = useLocation();
  const filter = getQueryParams(search)?.filter;

  const [activeLine, setActiveLine] = useState<string>(null);
  const [selectedLines, setSelectedLines] = useState<string[]>(
    DEFAULT_CHART_LINES_SELECTED,
  );
  const [resolution, setResolution] = useState<CHART_RESOLUTION | undefined>();

  const { data, isLoading, isFetching } = useGetMarketingMetricsChartQuery(
    {
      ...omit<ChartMarketingMetricsPayload>(filter, IGNORE_SEARCH_URL_PARAMS),
      chartLines: selectedLines,
      chartResolution:
        resolution ?? getMaximumChartResolution(filter?.timeRange),
    },
    {
      skip: isEmpty(filter),
    },
  );

  const notifyMinMetrics = () => {
    notification.warning({
      message: i18next.t('marketingMetrics.errors.minMetrics'),
      icon: <WarningFilled className="text-warning" />,
      duration: 3,
      className: 'notification-warning',
    });
  };

  const onCardClick = (cardClicked: string) => {
    if (selectedLines.length === 1 && selectedLines.includes(cardClicked)) {
      notifyMinMetrics();
      return;
    }
    notification.destroy();

    setSelectedLines((prev) =>
      prev.includes(cardClicked)
        ? prev.filter((item) => item !== cardClicked)
        : [...prev, cardClicked],
    );
  };

  const onSelectedLegendChange = (checkedValues: string[]) => {
    if (!checkedValues.length) {
      notifyMinMetrics();
      return;
    }
    notification.destroy();
    setSelectedLines(checkedValues);
  };

  return (
    <KeyMetricsWrapper>
      <PageTitle>{i18next.t('marketingMetrics.keyMetrics')}</PageTitle>
      {isEmpty(data?.data) ? (
        <Empty
          image={iconEmpty}
          imageStyle={{ height: 160 }}
          className="text-gray-500 mt-70 mb-48"
          description={i18next.t('error.noDataFound')}
        />
      ) : (
        <>
          <KeyMetricCards
            selectedLines={selectedLines}
            onCardClick={onCardClick}
          />
          {isLoading ? (
            <Skeleton />
          ) : (
            <SpinWrapper spinning={isFetching}>
              <ChartResolutionFilter
                resolution={resolution}
                setResolution={setResolution}
              />
              <MetricsLineChart
                data={data?.data}
                dataLines={data?.dataLines}
                activeLine={activeLine}
                setActiveLine={setActiveLine}
                selectedLines={selectedLines}
              />
              <LegendContent
                payload={data?.dataLines}
                activeLine={activeLine}
                selectedLine={selectedLines}
                onSelectedLegendChange={onSelectedLegendChange}
                className="mt-32"
              />
            </SpinWrapper>
          )}
        </>
      )}
    </KeyMetricsWrapper>
  );
};

export default KeyMetrics;

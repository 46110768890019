import React from 'react';

export const ICTicked = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M6.9987 0.222656C3.32536 0.222656 0.332031 3.21599 0.332031 6.88932C0.332031 10.5627 3.32536 13.556 6.9987 13.556C10.672 13.556 13.6654 10.5627 13.6654 6.88932C13.6654 3.21599 10.672 0.222656 6.9987 0.222656ZM10.1854 5.35599L6.40536 9.13599C6.31203 9.22932 6.18536 9.28266 6.05203 9.28266C5.9187 9.28266 5.79203 9.22932 5.6987 9.13599L3.81203 7.24932C3.6187 7.05599 3.6187 6.73599 3.81203 6.54266C4.00536 6.34932 4.32536 6.34932 4.5187 6.54266L6.05203 8.07599L9.4787 4.64932C9.67203 4.45599 9.99203 4.45599 10.1854 4.64932C10.3787 4.84266 10.3787 5.15599 10.1854 5.35599Z"
      fill={props.fill}
    />
  </svg>
);

import ModalCustom from 'components/common/ModalCustom';
import styled from 'styled-components';

export const ProgramDetailsModal = styled(ModalCustom)`
  .ant-modal-header {
    border-bottom: 1px solid ${({ theme }) => theme.boardColors.N30} !important;

    .ant-modal-title {
      font-size: 28px;
    }
  }

  .ant-modal-footer {
    border-top: 1px solid #ebecf0 !important;
    padding: 16px 32px !important;
  }

  .ant-modal-body {
    padding: 24px 32px !important;
  }

  .ant-skeleton-image {
    border-radius: 8px;
    height: 170px;
    width: 302px;
  }
`;

export const AboutProgramTitle = styled.p`
  font-weight: 700;
  line-height: 30px;
  margin-bottom: 0;
`;

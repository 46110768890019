import React from 'react';
import { Col, Row } from 'antd';

import RestSelect from 'components/RestInput/RestSelect';
import COUNTRIES from 'configs/localData/countries';

const AddressAdditionalForm = () => (
  <div>
    <Row gutter={16}>
      <Col md={12} xs={24}>
        <RestSelect
          required
          titleProp="name"
          valueProp="name"
          source="country"
          header="centres.country"
          placeholder="input.country.placeholder"
          resourceData={COUNTRIES}
          defaultValue={COUNTRIES[0].name}
        />
      </Col>
    </Row>
  </div>
);

export default AddressAdditionalForm;

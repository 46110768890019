import React from 'react';
import PropTypes from 'prop-types';

const LinkEmail = ({ email, className }) => (
  <a href={`mailto:${email}`} className={className}>
    {email}
  </a>
);

LinkEmail.propTypes = {
  email: PropTypes.string,
  className: PropTypes.string,
};

export default LinkEmail;

import React, { useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { List, Avatar } from 'antd';
import { formatDate } from 'utils/textUtils';
import { ENQUIRY_TYPES } from 'configs/localData';
import { UserOutlined } from '@ant-design/icons';
import i18next from 'i18next';
import { getSunMoonTime } from 'utils/formatFieldUtils';

const InboxItem = ({ record, handleClickInbox, checkIsActiveInbox }) => {
  const currentType = ENQUIRY_TYPES.find(
    (enquiryType) => enquiryType.value === record?.enquiryType?.name,
  );

  const isActiveInbox = useMemo(
    () => checkIsActiveInbox(record.id),
    [checkIsActiveInbox, record.id],
  );

  const getMessageContent = useCallback(
    (firstMessage) => {
      if (firstMessage?.message) return firstMessage?.message;

      if (firstMessage?.files?.length > 0) {
        const fileContent =
          record.centreId === firstMessage?.senderId
            ? `${i18next.t('messages.you')}`
            : `${firstMessage?.senderFirstName || ''} ${
                firstMessage?.senderLastName || ''
              }`;

        return `${fileContent} ${i18next.t('messages.contentSendFile')}`;
      }

      return firstMessage?.message || i18next.t('error.waiting');
    },
    [record.centreId],
  );

  const onClickInbox = () => {
    handleClickInbox(record.id);
  };

  return (
    <List.Item
      key={record?.id}
      onClick={onClickInbox}
      className={`${record?.centreHasNewMessage && 'not-seen'} ${
        isActiveInbox && 'current-message'
      }`}
    >
      <List.Item.Meta
        avatar={
          <div className="notification-icon">
            <Avatar
              size={40}
              src={record?.user?.avatar}
              icon={<UserOutlined />}
            />
          </div>
        }
        title={
          <div className="title-section">
            <div className="name-section">
              <div className="title">
                {`${record?.user?.firstName ? record?.user?.firstName : ''} ${
                  record?.user?.lastName ? record?.user?.lastName : ''
                }`}
              </div>
              <span className="date text-12">
                {formatDate(record?.latestMessage?.createdAt, 'D MMM YYYY')}
                {getSunMoonTime(record?.latestMessage?.createdAt)}
              </span>
            </div>
            <div className="type-section">
              <div
                className="dot-type"
                style={{ background: currentType?.dotColor }}
              />
              <span className="name-type">{i18next.t(currentType?.text)}</span>
            </div>
          </div>
        }
        description={getMessageContent(record?.latestMessage)}
      />
    </List.Item>
  );
};

InboxItem.propTypes = {
  record: PropTypes.object,
  handleClickInbox: PropTypes.func,
  checkIsActiveInbox: PropTypes.func,
};

InboxItem.defaultProps = {
  record: {},
};

export default InboxItem;

import React, { useCallback } from 'react';
import { Modal, Select } from 'antd';
import i18next from 'i18next';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { editInformationRequest } from 'redux/applications/actions';
import { INFO_REQUEST_STATUS } from 'configs/localData';
import { ArrowDownIcon } from 'components/common/SVGIcon';
import { DropDownWrapper } from './styles';

const { Option } = Select;

const DropdownStatus = ({ id, status, disabled }) => {
  const dispatch = useDispatch();

  const getItemStatus = useCallback(
    (status) => INFO_REQUEST_STATUS.find((item) => item.value === status),
    [],
  );

  const getStatusText = useCallback(
    (status) => {
      const statusItem = getItemStatus(status);
      return statusItem?.text ? i18next.t(statusItem.text) : status;
    },
    [getItemStatus],
  );

  const getObjConfirm = useCallback(
    (currentStatus, newStatus) => ({
      beforeStatus: getStatusText(currentStatus),
      endStatus: getStatusText(newStatus),
    }),
    [getStatusText],
  );

  const handleUpdateStatus = useCallback(
    (status) => {
      dispatch(editInformationRequest({ id, data: { status } }));
    },
    [dispatch, id],
  );

  const handleChange = useCallback(
    (value) => {
      Modal.confirm({
        title: i18next.t('informationRequests.confirmTitle'),
        content: i18next.t(
          'informationRequests.confirmContent',
          getObjConfirm(status, value),
        ),
        onOk: () => {
          handleUpdateStatus(value);
        },
      });
    },
    [getObjConfirm, status, handleUpdateStatus],
  );

  return (
    <DropDownWrapper
      value={status}
      style={{ width: 170, maxWidth: '100%' }}
      onChange={handleChange}
      suffixIcon={<ArrowDownIcon style={{ pointerEvents: 'none' }} />}
      dropdownClassName="dropdown-status"
      disabled={disabled}
    >
      {INFO_REQUEST_STATUS.map((item) => (
        <Option key={item.value} value={item.value}>
          <div className="option-div-item">
            <span
              style={{
                color: item.color,
              }}
            >
              {i18next.t(item.text)}
            </span>
          </div>
        </Option>
      ))}
    </DropDownWrapper>
  );
};

DropdownStatus.propTypes = {
  status: PropTypes.string,
  id: PropTypes.string,
  disabled: PropTypes.bool,
};

export default DropdownStatus;

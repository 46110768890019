import React from 'react';
import i18next from 'i18next';
import { ModalCustomStyle } from 'containers/Centres/components/CentreTabs/EducatorStaffProfilesTab/components/StaffProfileDetail/styles';
import BasicInfoDetail from 'containers/Centres/components/CentreTabs/EducatorStaffProfilesTab/components/StaffProfileDetail/BasicInfoDetail';
import { StaffProfileModel } from 'types/staffProfiles';
import AboutStaffDetail from 'containers/Centres/components/CentreTabs/EducatorStaffProfilesTab/components/StaffProfileDetail/AboutStaffDetail';
import EducationQualificationDetail from 'containers/Centres/components/CentreTabs/EducatorStaffProfilesTab/components/StaffProfileDetail/EducationQualificationDetail';
import AwardDetail from 'containers/Centres/components/CentreTabs/EducatorStaffProfilesTab/components/StaffProfileDetail/AwardDetail';
import ShowProfileButton from 'containers/Centres/components/CentreTabs/EducatorStaffProfilesTab/components/ShowProfileButton';
import useModifyStaffProfile from 'containers/Centres/components/CentreTabs/EducatorStaffProfilesTab/hooks/useModifyStaffProfile';
import { ButtonType } from 'types';
import { Modal } from 'antd';
import { WarningIcon } from 'components/common/SVGIcon';

interface StaffProfileDetailModalProps {
  visible: boolean;
  onClose: () => void;
  staffProfile: StaffProfileModel;
  disable?: boolean;
}

const StaffProfileDetailModal = ({
  visible,
  onClose,
  staffProfile,
  disable,
}: StaffProfileDetailModalProps) => {
  const { deleteProfile } = useModifyStaffProfile();

  const handleDeleteProfile = () => {
    Modal.confirm({
      title: i18next.t('educatorStaffProfile.deleteProfile'),
      content: i18next.t('educatorStaffProfile.deleteProfileConfirm'),
      okText: i18next.t('button.delete'),
      icon: <WarningIcon className="text-24" />,
      onOk: () => deleteProfile({ id: staffProfile.id }),
    });
  };

  return (
    <ModalCustomStyle
      title={i18next.t('educatorStaffProfile.profileDetails')}
      onCancel={onClose}
      onOk={handleDeleteProfile}
      visible={visible}
      width={900}
      isShowCancel
      okText={i18next.t('educatorStaffProfile.deleteProfile')}
      cancelText={i18next.t('button.close')}
      isScrollY
      className="top-50"
      resetPaddingTopBody
      resetPaddingBottomBody
      isDisabled={disable}
      okType={disable ? ButtonType.ghost : ButtonType.default}
    >
      <div className="flex flex-col gap-24 justify-end">
        <ShowProfileButton
          staffProfile={staffProfile}
          className="flex-center-end fex-row gap-8 mt-8"
          disabled={disable}
        />
        <BasicInfoDetail disable={disable} staffProfile={staffProfile} />
        <AboutStaffDetail disable={disable} staffProfile={staffProfile} />
        <EducationQualificationDetail
          disable={disable}
          staffProfile={staffProfile}
        />
        <AwardDetail disable={disable} staffProfile={staffProfile} />
      </div>
    </ModalCustomStyle>
  );
};

export default StaffProfileDetailModal;

import { convertResponseData } from '../crudCreator/dataProvider';

export const convertDataApplications = (response) => {
  const data =
    Array.isArray(response) &&
    response?.filter((item) => item.status !== 'DRAFT');

  return {
    results: convertResponseData('GET_ALL', {
      results: data || [],
      total: data?.length || 0,
    }),
    dataArr: data,
  };
};

import React from 'react';
import PropTypes from 'prop-types';

import CheckPermission from 'components/common/CheckPermissions';
import DropdownFallback from './DropdownFallback';
import { DropdownUserRoleStyles } from './styles';
import UserRoleSelect from './UserRoleSelect';

const DropdownUserRole = (props) => (
  <DropdownUserRoleStyles>
    <CheckPermission
      permissionKey="abilityChangeUserPermissions"
      action="isEdit"
      isSkipSubscription
      isDisabled
      fallback={<DropdownFallback status={props.roleId} />}
    >
      <UserRoleSelect {...props} />
    </CheckPermission>
  </DropdownUserRoleStyles>
);

DropdownUserRole.propTypes = {
  roleId: PropTypes.string,
};

export default DropdownUserRole;

import React, { useState } from 'react';
import i18next from 'i18next';
import { ModalContainerStyle } from 'containers/Advertisement/Create/Steps/AdCreative/styles';
import { Form, Radio, Skeleton, Typography } from 'antd';
import { CallingButtonResult } from 'api/advertisement/type';
import ModalCustom from 'components/common/ModalCustom';
import { useDispatch, useSelector } from 'react-redux';
import RestInputItem from 'components/RestInput/RestInputItem';
import { getAdsFormConfigSelector } from 'redux/advertisement/selectors';
import { editCallingButton } from 'redux/advertisement/actions';
import LabelWithTooltip from 'components/form/Label/LabelWithTooltip';

interface EditCallingButtonModalProps {
  defaultValue: CallingButtonResult;
  visible: boolean;
  isDisabled: boolean;
  onClose: (callingButton?: CallingButtonResult) => void;
}

const EditCallingButtonModal = ({
  defaultValue,
  visible,
  isDisabled,
  onClose,
}: EditCallingButtonModalProps) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { adsCreative } = useSelector(getAdsFormConfigSelector);

  const handleCancel = () => {
    form.resetFields();
    onClose();
  };

  const onOk = () => {
    form.validateFields().then((res) => {
      setIsLoading(true);
      dispatch(
        editCallingButton({
          callingButtonId: defaultValue.id,
          name: res.name,
          destinationType: res.destinationType,
        }),
      );
      setIsLoading(false);
      form.resetFields();
      onClose({
        id: defaultValue.id,
        name: res.name,
        destinationType: res.destinationType,
      });
    });
  };

  return (
    <ModalCustom
      title={i18next.t('advertisement.editCallingButton')}
      onCancel={handleCancel}
      onOk={onOk}
      loading={isLoading}
      isShowCancel
      okText={i18next.t('button.save')}
      cancelText={i18next.t('button.cancel')}
      isScrollY={false}
      className={undefined}
      resetPaddingTopBody={undefined}
      resetPaddingBottomBody={undefined}
      isDisabled={undefined}
      width={560}
      visible={visible}
    >
      <ModalContainerStyle>
        <Skeleton loading={!defaultValue} active>
          <Form form={form} initialValues={defaultValue}>
            <LabelWithTooltip
              label={i18next.t('advertisement.callingButtonName')}
              tooltip={i18next.t('advertisement.tooltipMessage.maximum', {
                max: 18,
              })}
            />
            <RestInputItem
              required
              source="name"
              placeholder="advertisement.input.callingButton"
              rules={[
                {
                  max: 18,
                  message: i18next.t('advertisement.tooltipMessage.maximum', {
                    max: 18,
                  }),
                },
              ]}
            />
            <Form.Item
              label={i18next.t('advertisement.destinationType')}
              name="destinationType"
              rules={[{ required: true, message: i18next.t('error.required') }]}
            >
              <Radio.Group
                value={'callingButton'}
                className="w-full"
                disabled={isDisabled}
              >
                <div className="column-items">
                  {adsCreative?.callingButtonTypes?.map((item) => (
                    <div key={item?.value} className="break-inside-avoid my-6">
                      <Radio value={item?.name}>
                        <Typography.Text>{item?.name}</Typography.Text>
                      </Radio>
                    </div>
                  ))}
                </div>
              </Radio.Group>
            </Form.Item>
          </Form>
        </Skeleton>
      </ModalContainerStyle>
    </ModalCustom>
  );
};

export default EditCallingButtonModal;

import React from 'react';
import PropTypes from 'prop-types';
import { Draggable } from 'react-beautiful-dnd';
import { useSelector } from 'react-redux';
import Skeleton from 'antd/lib/skeleton';

import ApplicationList from '../Primatives/ApplicationList';
import Header from './Header';
import { ContainerColumn } from './styles';

const Column = ({
  listId,
  title,
  applications,
  index,
  isScrollable,
  isCombineEnabled,
  useClone,
  numberColumn,
  lineColor,
  colors,
  isDragDisabledColumn,
  isDragDisabledItem,
  summaryStatus,
}) => {
  const loading = useSelector((state) => state.applications.loading);

  return (
    <Draggable
      draggableId={listId}
      index={index}
      isDragDisabled={isDragDisabledColumn}
    >
      {(provided, snapshot) => (
        <ContainerColumn
          ref={provided.innerRef}
          {...provided.draggableProps}
          numberColumn={numberColumn}
        >
          <Header
            isDragging={snapshot.isDragging}
            {...provided.dragHandleProps} // cmt to disable allow drap & drop
            title={title}
            lineColor={lineColor}
            count={summaryStatus.count}
            total={summaryStatus.total}
          />
          <Skeleton active loading={loading}>
            <ApplicationList
              count={summaryStatus.count}
              listId={listId}
              title={title}
              listType="APPLICATION"
              style={{
                backgroundColor: snapshot.isDragging ? '#E3FCEF' : null,
                // overflowY: 'auto',
              }}
              applications={applications}
              internalScroll={isScrollable}
              isCombineEnabled={Boolean(isCombineEnabled)}
              useClone={Boolean(useClone)}
              colors={colors}
              isDragDisabledItem={isDragDisabledItem}
            />
          </Skeleton>
        </ContainerColumn>
      )}
    </Draggable>
  );
};

Column.propTypes = {
  listId: PropTypes.string,
  title: PropTypes.string,
  applications: PropTypes.array,
  index: PropTypes.number,
  isScrollable: PropTypes.bool,
  isCombineEnabled: PropTypes.bool,
  useClone: PropTypes.bool,
  numberColumn: PropTypes.number,
  lineColor: PropTypes.string,
  colors: PropTypes.object,
  isDragDisabledColumn: PropTypes.bool,
  isDragDisabledItem: PropTypes.bool,
  summaryStatus: PropTypes.object,
};

Column.defaultProps = {
  summaryStatus: {},
};

export default Column;

import { YellowMagicStarIcon } from 'components/common/SVGIcon';
import React from 'react';
import { RecommendsTagStyled } from './styles';

const RecommendsTag = ({ style }) => (
  <RecommendsTagStyled style={style}>
    <YellowMagicStarIcon />
    <span>KindiCare Recommends</span>
  </RecommendsTagStyled>
);

export default RecommendsTag;

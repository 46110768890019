import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Spin, List } from 'antd';
import { Waypoint } from 'react-waypoint';
import { useHistory, useParams } from 'react-router';
import { enquiriesSelectors } from 'redux/enquiries/selectors';

import InboxListWrapper, { ListStyles } from './styles';
import Header from './Header';
import InboxItem from './InboxItem';

const CustomList = ({ inboxId, retrieveList, getListAndSummary }) => {
  const { push } = useHistory();

  const { tab } = useParams();

  const loading = useSelector(enquiriesSelectors.getLoading);

  const resourceData = useSelector(enquiriesSelectors.getDataArr) || [];

  const enabledLoadMore = useSelector(enquiriesSelectors.enabledLoadMore);

  const checkIsActiveInbox = useCallback((id) => inboxId === id, [inboxId]);

  const handleEnterWaypoint = () => {
    retrieveList({}, false);
  };

  const handleClickInbox = (id) => {
    push(`/enquiries/${tab}/${id}/show`);
  };

  return (
    <InboxListWrapper>
      <Header getListAndSummary={getListAndSummary} />
      <ListStyles>
        <div className="inbox-list-data">
          <List
            itemLayout="horizontal"
            dataSource={resourceData}
            renderItem={(record) => (
              <InboxItem
                record={record}
                handleClickInbox={handleClickInbox}
                checkIsActiveInbox={checkIsActiveInbox}
              />
            )}
          />
          {loading && (
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <Spin />
            </div>
          )}
          {enabledLoadMore && (
            <div style={{ height: 1 }}>
              <Waypoint onEnter={handleEnterWaypoint} />
            </div>
          )}
        </div>
      </ListStyles>
    </InboxListWrapper>
  );
};

CustomList.propTypes = {
  retrieveList: PropTypes.func,
  inboxId: PropTypes.string,
  getListAndSummary: PropTypes.func,
};

export default CustomList;

import React, { useContext, useEffect } from 'react';
import { Col, Row } from 'antd';
import RestSelect from 'components/RestInput/RestSelect';
import i18next from 'i18next';
import RestRangePickerInput from 'components/RestInput/RestRangePickerInput';
import { RestInputContext } from 'components/RestInput/RestInputContext';
import { useLocation, useParams } from 'react-router';
import { getFilterFromUrl } from 'utils/tools';
import { useSelector } from 'react-redux';
import moment from 'moment';
import ReferenceInputV2 from 'containers/rest/ReferenceInputV2';
import {
  getJobApplicationStatus,
  getJobRoles,
  getJobTypes,
  getQualifications,
  getStates,
} from 'redux/config/selectors';
import {
  DISPLAY_TYPES,
  PREFIX_API_VER_2,
  PREFIX_URL,
} from 'configs/localData/constant';
import ReferenceInput from 'containers/rest/ReferenceInput';

function FilterForm() {
  const { form } = useContext(RestInputContext);
  const { search } = useLocation();
  const { model } = useParams();

  const jobApplicationStatus = useSelector(getJobApplicationStatus);
  const jobApplicationsRole = useSelector(getJobRoles);
  const jobApplicationsState = useSelector(getStates);
  const jobApplicationsQualifications = useSelector(getQualifications);
  const JobApplicationType = useSelector(getJobTypes);

  useEffect(() => {
    const { filter } = getFilterFromUrl(search);

    const { lastUpdated, ...restFilter } = filter?.filter || {};

    form.setFieldsValue({
      lastUpdated: Object.values(lastUpdated || {}).map((item) => moment(item)),
      ...restFilter,
    });
  }, [form, search]);

  return (
    <Row gutter={16}>
      <Col lg={4} md={12} xs={12}>
        <ReferenceInputV2
          prefixUrl={PREFIX_URL.crm}
          resource="job-seekers/posts/applicant/basic-info"
          source="applicantName"
          searchKey="fullname"
          notLikeFilter
          requestApi={PREFIX_API_VER_2}
          autoComplete="off"
        >
          <RestSelect
            valueProp="fullname"
            titleProp="fullname"
            placeholder="jobApplications.applicant"
            isFilterOption={false}
            isShowTooltip
          />
        </ReferenceInputV2>
      </Col>
      {model === DISPLAY_TYPES.list && (
        <Col lg={4} md={12} xs={12}>
          <RestSelect
            source="status"
            placeholder="jobApplications.status"
            isShowTooltip
            resourceData={jobApplicationStatus}
            valueProp="application_id"
            titleProp="application_name"
            formatText={(data) => i18next.t(data)}
          />
        </Col>
      )}

      <Col lg={4} md={12} xs={12}>
        <ReferenceInputV2
          prefixUrl={PREFIX_URL.crm}
          resource="job-seekers/posts/job-reference/basic-info"
          source="jobReference"
          searchKey="jobReference"
          mappedBy="jobReference"
          notLikeFilter
          requestApi={PREFIX_API_VER_2}
          autoComplete="off"
        >
          <RestSelect
            valueProp="jobReference"
            titleProp="jobReference"
            placeholder="jobApplications.jobReference"
            isFilterOption={false}
            isShowTooltip
          />
        </ReferenceInputV2>
      </Col>
      <Col lg={4} md={12} xs={12}>
        <ReferenceInputV2
          prefixUrl={PREFIX_URL.crm}
          resource="job-seekers/campaigns/basic-info"
          source="campaign"
          searchKey="name"
          notLikeFilter
          requestApi={PREFIX_API_VER_2}
          autoComplete="off"
          mappedBy="name"
        >
          <RestSelect
            valueProp="name"
            titleProp="name"
            placeholder="jobListings.campaign"
            isFilterOption={false}
            isShowTooltip
          />
        </ReferenceInputV2>
      </Col>
      <Col lg={4} md={12} xs={12}>
        <RestSelect
          source="jobRole"
          placeholder="jobApplications.jobRole"
          isShowTooltip
          resourceData={jobApplicationsRole}
          valueProp="id"
          titleProp="name"
        />
      </Col>
      <Col lg={4} md={12} xs={12}>
        <ReferenceInputV2
          prefixUrl={PREFIX_URL.crm}
          resource="job-seekers/posts/templates/basic-info"
          source="jobTemplate"
          searchKey="templateName"
          notLikeFilter
          requestApi={PREFIX_API_VER_2}
          autoComplete="off"
          mappedBy="templateName"
        >
          <RestSelect
            valueProp="templateName"
            titleProp="templateName"
            placeholder="jobApplications.jobTemplate"
            isFilterOption={false}
            isShowTooltip
          />
        </ReferenceInputV2>
      </Col>
      <Col lg={4} md={12} xs={12}>
        <RestSelect
          source="jobType"
          placeholder="jobApplications.jobType"
          isShowTooltip
          resourceData={JobApplicationType}
          valueProp="id"
          titleProp="name"
        />
      </Col>
      <Col lg={4} md={12} xs={12}>
        <ReferenceInputV2
          prefixUrl={PREFIX_URL.crm}
          resource="job-seekers/posts/workplaces/basic-info"
          source="workplace"
          searchKey="workplaceName"
          notLikeFilter
          requestApi={PREFIX_API_VER_2}
          autoComplete="off"
          mappedBy="workplaceName"
        >
          <RestSelect
            valueProp="workplaceName"
            titleProp="workplaceName"
            placeholder="jobApplications.workplace"
            isFilterOption={false}
            isShowTooltip
          />
        </ReferenceInputV2>
      </Col>
      <Col lg={4} md={12} xs={12}>
        <ReferenceInput
          prefixUrl={PREFIX_URL.admin}
          source="brand"
          resource="brands"
          searchKey="brand.name"
          requestParams={{ centreIds: 'all' }}
          customApiResource="job-brands"
        >
          <RestSelect
            titleProp="name"
            valueProp="id"
            placeholder="centres.brandTitle"
            isFilterOption={false}
          />
        </ReferenceInput>
      </Col>
      <Col lg={4} md={12} xs={12}>
        <RestSelect
          source="state"
          placeholder="jobApplications.state"
          isShowTooltip
          resourceData={jobApplicationsState}
        />
      </Col>
      <Col lg={4} md={12} xs={12}>
        <RestSelect
          source="qualificationLevel"
          placeholder="jobApplications.qualificationLevel"
          isShowTooltip
          resourceData={jobApplicationsQualifications}
          valueProp="id"
          titleProp="name"
        />
      </Col>
      <Col lg={4} md={12} xs={12}>
        <RestRangePickerInput
          sourceGt="lastUpdated.from"
          sourceLt="lastUpdated.to"
          source="lastUpdated"
          placeholder="jobListings.lastUpdated"
          isShowTooltip
        />
      </Col>
    </Row>
  );
}

export default FilterForm;

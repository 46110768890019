import React, { memo } from 'react';
import { FormInstance, Switch, Typography } from 'antd';
import {
  COMPARE_OPTIONS,
  RANGE_PICKER_OPTION_CUSTOM,
} from 'containers/MarketingMetrics/constants';
import i18next from 'i18next';
import clsx from 'clsx';
import { TickChooseIcon } from 'components/common/SVGIcon';
import { getFilterFromUrl, rangeLastYear } from 'utils/tools';
import { useDispatch, useSelector } from 'react-redux';
import {
  setCompareOption,
  setDateOption,
  setIsCompare,
} from 'redux/marketingMetrics/slice';
import {
  compareOptionSelector,
  dateOptionSelector,
  isCompareSelector,
} from 'redux/marketingMetrics/selector';
import { calculatorCompareDate } from 'containers/MarketingMetrics/utils';
import { useLocation } from 'react-router-dom';
import moment from 'moment';
import { LeftDatePickerWrapper, OptionItem } from '../../style';

const { Text } = Typography;

export interface RangeOptionsItem {
  key: string;
  label: string;
  value?: moment.Moment[];
  compareWithValue?: moment.Moment[];
}
interface Props {
  form: FormInstance;
  rangeDate: moment.Moment[];
}
interface DateOptionComponent {
  label: string;
  keyName?: string;
  value?: moment.Moment[];
  compareWithValue?: moment.Moment[];
}
interface CompareOptionItem {
  label: string;
  keyName?: string;
  handleSelect: () => void;
}

const LeftDatePicker = ({ form, rangeDate }: Props) => {
  const dispatch = useDispatch();
  const compareOption = useSelector(compareOptionSelector);
  const { search } = useLocation();
  const { filter } = getFilterFromUrl(search).filter;
  const dateOption = useSelector(dateOptionSelector);
  const isCompare = useSelector(isCompareSelector);

  const selectOptionDate = (key, value, compareWithValue) => {
    dispatch(setDateOption(key));
    localStorage.setItem('dateOptionMarketingMetric', key);
    if (key === RANGE_PICKER_OPTION_CUSTOM.custom.key) {
      return;
    }
    form.setFieldsValue({ rangeDate: value });
    if (!isCompare) {
      form.setFieldsValue({ compareDate: null });
      return;
    }
    form.setFieldsValue({
      compareDate:
        compareOption === COMPARE_OPTIONS.precedingPeriod
          ? compareWithValue
          : rangeLastYear(value),
    });
  };

  const handleChangeSwitch = (value) => {
    localStorage.setItem('isCompareMarketingMetric', value);
    dispatch(setIsCompare(value));
    value
      ? form.setFieldsValue({
          compareDate: calculatorCompareDate(
            form?.getFieldValue('rangeDate'),
            compareOption,
          ),
        })
      : form.setFieldsValue({ compareDate: null });
  };

  const DateOptionComponent = ({
    label,
    keyName,
    value,
    compareWithValue,
  }: DateOptionComponent) => {
    const isDateOption = dateOption ?? filter?.dateOption;
    const isShowTick = keyName === isDateOption;
    return (
      <OptionItem
        className="flex cursor-pointer"
        onClick={() => selectOptionDate(keyName, value, compareWithValue)}
      >
        <Text className={clsx(isShowTick && 'text-primary fw-bold')}>
          {i18next.t(label)}
        </Text>
        {isShowTick && <TickChooseIcon />}
      </OptionItem>
    );
  };

  const CompareOptionItem = ({
    label,
    keyName,
    handleSelect,
  }: CompareOptionItem) => {
    const isShowTick = compareOption === keyName;
    return (
      <OptionItem className="flex cursor-pointer" onClick={handleSelect}>
        <Text className={clsx(isShowTick && 'text-primary fw-bold')}>
          {i18next.t(label)}
        </Text>
        {isShowTick && <TickChooseIcon />}
      </OptionItem>
    );
  };

  const handleSelectPrecedingPeriod = () => {
    dispatch(setCompareOption(COMPARE_OPTIONS.precedingPeriod));
    localStorage.setItem(
      'compareOptionMarketingMetric',
      COMPARE_OPTIONS.precedingPeriod,
    );
    const differenceDate =
      Math.abs(rangeDate?.[0].diff(rangeDate?.[1], 'days')) + 1;

    const compareDateCalculator = [
      moment(rangeDate?.[0]).subtract(differenceDate, 'days'),
      moment(rangeDate?.[0]).subtract(1, 'days'),
    ];
    form.setFieldsValue({ compareDate: compareDateCalculator });
  };

  const handleSelectSamePeriodLastYear = () => {
    dispatch(setCompareOption(COMPARE_OPTIONS.samePeriodLastYear));
    localStorage.setItem(
      'compareOptionMarketingMetric',
      COMPARE_OPTIONS.samePeriodLastYear,
    );
    form.setFieldsValue({ compareDate: rangeLastYear(rangeDate) });
  };

  return (
    <LeftDatePickerWrapper>
      <div className="border-b">
        <div className="pl-12 pr-12 pt-6">
          <DateOptionComponent
            label={RANGE_PICKER_OPTION_CUSTOM.today.label}
            keyName={RANGE_PICKER_OPTION_CUSTOM.today.key}
            value={RANGE_PICKER_OPTION_CUSTOM.today.value}
            compareWithValue={RANGE_PICKER_OPTION_CUSTOM.today.compareWithValue}
          />
          <DateOptionComponent
            label={RANGE_PICKER_OPTION_CUSTOM.yesterday.label}
            keyName={RANGE_PICKER_OPTION_CUSTOM.yesterday.key}
            value={RANGE_PICKER_OPTION_CUSTOM.yesterday.value}
            compareWithValue={
              RANGE_PICKER_OPTION_CUSTOM.yesterday.compareWithValue
            }
          />
          <DateOptionComponent
            label={RANGE_PICKER_OPTION_CUSTOM.last7Days.label}
            keyName={RANGE_PICKER_OPTION_CUSTOM.last7Days.key}
            value={RANGE_PICKER_OPTION_CUSTOM.last7Days.value}
            compareWithValue={
              RANGE_PICKER_OPTION_CUSTOM.last7Days.compareWithValue
            }
          />
          <DateOptionComponent
            label={RANGE_PICKER_OPTION_CUSTOM.last30Days.label}
            keyName={RANGE_PICKER_OPTION_CUSTOM.last30Days.key}
            value={RANGE_PICKER_OPTION_CUSTOM.last30Days.value}
            compareWithValue={
              RANGE_PICKER_OPTION_CUSTOM.last30Days.compareWithValue
            }
          />
          <DateOptionComponent
            label={RANGE_PICKER_OPTION_CUSTOM.last12Months.label}
            keyName={RANGE_PICKER_OPTION_CUSTOM.last12Months.key}
            value={RANGE_PICKER_OPTION_CUSTOM.last12Months.value}
            compareWithValue={
              RANGE_PICKER_OPTION_CUSTOM.last12Months.compareWithValue
            }
          />
          <DateOptionComponent
            label={RANGE_PICKER_OPTION_CUSTOM.custom.label}
            keyName={RANGE_PICKER_OPTION_CUSTOM.custom.key}
          />
        </div>
      </div>
      <div className="p-12">
        <div className="flex-center-between pb-12">
          <Text className="fw-bold">
            {i18next.t('marketingMetrics.compare')}
          </Text>
          <Switch onChange={handleChangeSwitch} defaultChecked={isCompare} />
        </div>
        {isCompare && (
          <div>
            <CompareOptionItem
              label="marketingMetrics.precedingPeriod"
              keyName={COMPARE_OPTIONS.precedingPeriod}
              handleSelect={handleSelectPrecedingPeriod}
            />
            <CompareOptionItem
              label="marketingMetrics.samePeriodLastYear"
              keyName={COMPARE_OPTIONS.samePeriodLastYear}
              handleSelect={handleSelectSamePeriodLastYear}
            />
          </div>
        )}
      </div>
    </LeftDatePickerWrapper>
  );
};

export default memo(LeftDatePicker);

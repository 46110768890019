import React, { useMemo } from 'react';
import i18next from 'i18next';
import PropType from 'prop-types';
import { Avatar } from 'antd';
import { EnvironmentOutlined } from '@ant-design/icons';
import { CentreDetail } from 'types/centres';
import {
  CentreBasedTypeIcon,
  ChildcareServiceTypeIcon,
  CompanyRawIcon,
  MailIcon,
  PhoneRightIcon,
  SENumberIcon,
} from 'components/common/SVGIcon';
import DefaultCentreImage from 'assets/images/default-centre-cover.png';
import { getFeaturedImage, getLinkCentreDetailRichweb } from 'utils/dataUtils';
import {
  BrandLogo,
  CentreInfoStyles,
  KCRatingImage,
  NQSRatingImage,
} from './styles';

interface Props {
  record: CentreDetail;
}

const CentreClaimInfo = ({ record }: Props) => {
  const phoneDisplay = useMemo(() => {
    if (record.phone && record.mobilePhone)
      return `${record.phone} - ${record.mobilePhone}`;
    if (record.phone || record.mobilePhone)
      return `${record.phone || record.mobilePhone}`;
    return null;
  }, [record.phone, record.mobilePhone]);

  const CenterImage = record.images ? getFeaturedImage(record?.images) : null;

  const centreInfo = [
    {
      Icon: CompanyRawIcon,
      value: record?.provider?.name,
    },
    {
      Icon: EnvironmentOutlined,
      value: record.displayAddress,
    },
    {
      Icon: PhoneRightIcon,
      value: phoneDisplay,
    },
    {
      Icon: MailIcon,
      value: record?.adminEmail ? `xxx${record.adminEmail.slice(3)}` : null,
    },
    {
      Icon: ChildcareServiceTypeIcon,
      value: record?.primaryChildCareService?.name,
    },
    {
      Icon: CentreBasedTypeIcon,
      value: record?.type,
    },
    {
      Icon: SENumberIcon,
      value: record?.governmentCentreServiceId,
    },
  ];

  return (
    <CentreInfoStyles>
      <a
        href={getLinkCentreDetailRichweb(record)}
        target="_blank"
        rel="noopener noreferrer"
      >
        <div className="image-header">
          {!!record?.kindiCareRatingImage && (
            <KCRatingImage
              width={48}
              src={record?.kindiCareRatingImage}
              alt="KindiCare rating"
            />
          )}
          <Avatar
            className="thumbnail-centre"
            src={CenterImage || DefaultCentreImage}
            shape="square"
          />
          {!!record?.brand?.logo && <BrandLogo src={record?.brand?.logo} />}
          {!!record?.NQSRType?.image && (
            <NQSRatingImage src={record?.NQSRType?.image} />
          )}
        </div>
        <div className="info-content">
          <div className="name-centre">
            {record.name || i18next.t('error.waitingUpdate')}
          </div>
          {centreInfo.map((item) =>
            item.value ? (
              <div className="item-section" key={item.value}>
                <div className="title-item">
                  <item.Icon />
                </div>
                <span className="value-item break-all">{item.value}</span>
              </div>
            ) : null,
          )}
        </div>
      </a>
    </CentreInfoStyles>
  );
};

CentreClaimInfo.propTypes = {
  record: PropType.object,
};

CentreClaimInfo.defaultProps = {
  record: {},
};

export default CentreClaimInfo;

import React, { useMemo } from 'react';
import { Col, Divider, Row, Tag, Typography, Skeleton, Avatar } from 'antd';
import PropTypes from 'prop-types';
import {
  CopyFillIcon,
  LocationV2Icon,
  DollarSquareIcon,
  EducatorDiscountFillIcon,
  BriefcaseIcon,
} from 'components/common/SVGIcon';
import { useSelector } from 'react-redux';
import { getLoadingJobApplicationProfile } from 'redux/jobs/jobApplications/selectors';
import DotCircle from 'components/common/DotCircle';
import { useHistory } from 'react-router';
import { PictureFilled } from '@ant-design/icons';
import { JobListingCardStyles } from './styles';
import JobApplicationGeneralInfoItem from '../InfoItem';
import StatisticsInfo from './StatisticsInfo';

const { Paragraph } = Typography;

const JobListingCard = ({ record = {} }) => {
  const { push } = useHistory();
  const loading = useSelector(getLoadingJobApplicationProfile);
  const { jobListing } = record;

  const statusContainer = useMemo(() => {
    if (jobListing?.status === 'Open') {
      return <div className="fw-bold status__open">{jobListing?.status}</div>;
    }

    if (jobListing?.status === 'Deleted') {
      return (
        <div className="fw-bold status__deleted">{jobListing?.status}</div>
      );
    }

    return <div className="fw-bold status__closed">{jobListing?.status}</div>;
  }, [jobListing]);

  const handleClickJobListing = () => {
    jobListing?.status !== 'Deleted' &&
      push(`/job-listings/${jobListing?.id}/show/job-details`);
  };

  return (
    <Skeleton loading={loading}>
      <JobListingCardStyles onClick={handleClickJobListing}>
        <Row justify="space-between" className="listing-status">
          <Col>{statusContainer}</Col>
          <Col>
            {jobListing?.jobReference &&
              jobListing?.status?.value !== 'Deleted' && (
                <Paragraph
                  copyable={{
                    icon: [<CopyFillIcon />],
                    tooltips: ['Copy', 'Copied'],
                    text: jobListing?.jobReference,
                  }}
                  className="mt-6 text-16"
                >
                  <span className="text-gray-neutral-500 text-decoration-line fw-600">
                    {jobListing?.jobReference}
                  </span>
                </Paragraph>
              )}
          </Col>
        </Row>
        <div className="flex flex-row justify-start">
          <div className="mr-12">
            <Avatar
              shape="square"
              icon={<PictureFilled />}
              src={jobListing?.workplaceAvatar}
              size={60}
            />
          </div>
          <div>
            <div>
              {jobListing?.jobTitle && (
                <span className="break-all fw-bold text-20">
                  {jobListing?.jobTitle}
                </span>
              )}
            </div>
            <div>
              {jobListing?.workplaceName && (
                <span className="break-all fw-400 text-16">
                  {jobListing?.workplaceName}
                </span>
              )}
            </div>
          </div>
        </div>
        {jobListing?.workplaceAddress && (
          <JobApplicationGeneralInfoItem
            icon={<LocationV2Icon />}
            content={jobListing?.workplaceAddress}
            className="text-16 fw-400"
          />
        )}
        {jobListing?.salary && (
          <JobApplicationGeneralInfoItem
            icon={<DollarSquareIcon />}
            content={jobListing?.salary}
            className="text-16 salary-text"
          />
        )}

        <div className="job-card__info-item">
          {jobListing?.discount && (
            <>
              <EducatorDiscountFillIcon className="text-18 mr-10" />
              <span className="text-16">{`${jobListing?.discount}%`}</span>
              <DotCircle color="#D0D5DD" size={5} />
            </>
          )}
          {jobListing?.jobType && (
            <>
              <Tag icon={<BriefcaseIcon />} className="text-16 fw-600">
                {jobListing?.jobType}
              </Tag>
              <DotCircle color="#D0D5DD" size={5} />
            </>
          )}
          {jobListing?.lastUpdated && (
            <span className="posted-time text-16">
              {jobListing?.lastUpdated}
            </span>
          )}
        </div>
        <Divider />
        <Row gutter={[5, 5]}>
          <Col md={12} xs={24}>
            <StatisticsInfo
              title="jobApplications.generalInfo.totalApplications"
              statisticsNumber={jobListing?.totalApplication ?? 0}
              isTotalApplicationsColor
            />
          </Col>
          <Col md={12} xs={24}>
            <StatisticsInfo
              title="jobApplications.generalInfo.newApplications"
              statisticsNumber={jobListing?.totalNewApplication ?? 0}
            />
          </Col>
        </Row>
      </JobListingCardStyles>
    </Skeleton>
  );
};

JobListingCard.propTypes = {
  record: PropTypes.object,
};

export default JobListingCard;

import { Divider } from 'antd';
import React from 'react';
import { useParams } from 'react-router';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash-es';
import AboutTheJob from '../../Steps/PreviewStep/AboutTheJob';
import AboutYou from '../../Steps/PreviewStep/AboutYou';
import JobFeatures from '../../Steps/PreviewStep/JobFeatures';
import JobHighlightsAndBenefits from '../../Steps/PreviewStep/JobHighlightsAndBenefits';
import SnapShootJobTemplate from '../../Steps/PreviewStep/SnapShootJobTemplate';
import WhatYouWillBeDoing from '../../Steps/PreviewStep/WhatYouWillBeDoing';
import { JobDetailWrapper } from './styles';

const JobDetailsTab = ({ currentData }) => {
  const { modelDetail } = useParams();

  return (
    <JobDetailWrapper className="bg-white p-33 rounded-20">
      <SnapShootJobTemplate
        currentJobTemplate={currentData}
        modelDetail={modelDetail}
      />
      <Divider />
      <JobHighlightsAndBenefits benefits={currentData?.benefits} />
      <Divider />
      {!isEmpty(currentData?.features) && (
        <>
          <JobFeatures features={currentData?.features} />
          <Divider />
        </>
      )}
      <AboutTheJob aboutTheJob={currentData?.aboutTheJob} />
      <Divider />
      <WhatYouWillBeDoing
        candidateResponsibilities={currentData?.candidateResponsibilities}
      />
      <Divider />
      <AboutYou currentJobTemplate={currentData} />
    </JobDetailWrapper>
  );
};

JobDetailsTab.propTypes = {
  currentData: PropTypes.object,
};

export default JobDetailsTab;

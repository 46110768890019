import React from 'react';
import RestShow from 'containers/rest/Show';
import RestFieldItem from 'components/RestField/RestFieldItem';

const TransactionsShow = (props) => (
  <RestShow {...props} hasEdit resource="transactions">
    <RestFieldItem source="applicationId" header="transactions.applicationId" />
  </RestShow>
);

export default TransactionsShow;

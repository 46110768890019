import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  getApplicationsDashboardApi,
  getCentresDashboardApi,
  getActivitiesDashboardApi,
  getEnquiriesDashboardApi,
} from 'api/dashboard';
import { apiWrapper } from 'utils/reduxUtils';

export const getApplicationsDashboard = createAsyncThunk(
  'dashboard/getApplicationsDashboard',
  async (payload, thunkAPI) => {
    try {
      const response = await apiWrapper({}, getApplicationsDashboardApi);
      if (response) {
        return response;
      }
      return thunkAPI.rejectWithValue(response);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  },
);

export const getCentresDashboard = createAsyncThunk(
  'dashboard/getCentresDashboard',
  async (payload, thunkAPI) => {
    try {
      const response = await apiWrapper({}, getCentresDashboardApi);
      if (response) {
        return response;
      }
      return thunkAPI.rejectWithValue(response);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  },
);

export const getActivitiesDashboard = createAsyncThunk(
  'dashboard/getActivitiesDashboard',
  async (payload, thunkAPI) => {
    try {
      const response = await apiWrapper({}, getActivitiesDashboardApi);
      if (response) {
        return response;
      }
      return thunkAPI.rejectWithValue(response);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  },
);

export const getEnquiriesDashboard = createAsyncThunk(
  'dashboard/getEnquiriesDashboard',
  async (payload, thunkAPI) => {
    try {
      const response = await apiWrapper({}, getEnquiriesDashboardApi);
      if (response) {
        return response;
      }
      return thunkAPI.rejectWithValue(response);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  },
);

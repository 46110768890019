import i18next from 'i18next';
import { forEach, compact, isEmpty, pickBy } from 'lodash-es';
import DefaultImage from 'assets/images/default-centre-cover.png';
import { APPLICATION_STATUS_CONST_ITEM } from 'configs/localData';
import slugify from 'slugify';
import moment from 'moment';
import {
  EMPTY_CHARACTER,
  DEFAULT_DEADLINE_HOURS_DURATION,
  MAX_DEADLINE_DAYS,
  MAX_DEADLINE_HOURS,
  MIN_DEADLINE_DAYS,
} from 'configs/localData/constant';
import { EXISTING_AUDIENCE_TAB_KEY } from 'containers/Advertisement/constants';
import { validateRegex } from './validateUtils';
import { capitalize } from './tools';

export const getFeaturedImage = (images) => {
  const featuredItem = images?.find((item) => item?.featured);
  const centreImages = images?.filter(
    (item) => item?.type?.includes('image') || item?.type !== 'video',
  );

  if (!isEmpty(featuredItem)) {
    return featuredItem?.url;
  }

  if (images?.[0]?.type === 'video') {
    if (isEmpty(centreImages)) return DefaultImage;
    return centreImages?.[0]?.url;
  }

  return images?.[0]?.url || DefaultImage;
};

export const getClassificationCentre = (kindiCareRating) => {
  if (!kindiCareRating) {
    return i18next.t('classification.notRated');
  }
  if (kindiCareRating >= 9) {
    return i18next.t('classification.excellent');
  }
  if (kindiCareRating >= 8.5) {
    return i18next.t('classification.veryGood');
  }
  if (kindiCareRating >= 7.5) {
    return i18next.t('classification.good');
  }
  if (kindiCareRating >= 5) {
    return i18next.t('classification.fair');
  }
  return i18next.t('classification.poor');
};

export const splitAddressFromGoogleMap = (addressArr) => {
  const addressObj = {};

  forEach(addressArr, (itemAddress) => {
    switch (itemAddress?.types?.[0]) {
      case 'street_number': {
        addressObj.addressL1 = `${itemAddress.long_name}${
          addressObj.addressL1 ? ` ${addressObj.addressL1}` : ''
        }`;
        break;
      }

      case 'street_address': {
        addressObj.addressL1 = `${itemAddress.long_name}${
          addressObj.addressL1 ? ` ${addressObj.addressL1}` : ''
        }`;
        break;
      }

      case 'route': {
        addressObj.addressL1 = `${
          addressObj.addressL1 ? `${addressObj.addressL1} ` : ''
        }${itemAddress.short_name}`;
        break;
      }

      case 'postal_code': {
        addressObj.postCode = `${itemAddress.long_name}`;
        break;
      }

      case 'locality':
        addressObj.city = itemAddress.long_name;
        break;

      case 'administrative_area_level_1':
        addressObj.state = itemAddress.short_name;
        break;

      case 'country':
        addressObj.country = itemAddress.long_name;
        break;

      default:
        break;
    }
  });
  return addressObj;
};

export const getAddressCentreToGetGeo = (values) =>
  `${values.addressL2 ? `${values.addressL2}, ` : ''}${values.addressL1}, ${
    values.city
  } ${values.state} ${values.postCode}`;

export const getAddressCompanyToGetGeo = (values) =>
  `${values.street}, ${values.suburb} ${values.state} ${values.postCode}`;

export const getDisplayAddress = (item) =>
  compact([item.address, item.suburb, item.state, item.postCode]).join(', ');

export const getUrlIfyMessage = (string) =>
  string?.replace(
    validateRegex.url,
    (url) => `<a class="detect-link" href="${url}" target="_blank">${url}</a>`,
  );

export const getStatusOrder = (status) => {
  const statusData = Object.values(APPLICATION_STATUS_CONST_ITEM).find(
    (item) => item.value === status,
  );
  return statusData ? statusData.indexOrder : null;
};

export const calculateAvgPriceAllAgeGroup = (
  summaryValue,
  summaryTotalValue,
) => {
  const difference = summaryValue - summaryTotalValue;
  const percentage = Math.abs((difference / summaryTotalValue) * 100);
  const result = percentage ? `${percentage.toFixed(2)}% ` : '0.00% ';
  return {
    result,
    status: difference <= 0 ? 'lower' : 'higher',
  };
};

export const isValidAddress = (addressObj) =>
  addressObj?.addressL1 &&
  addressObj?.state &&
  addressObj?.postCode &&
  addressObj?.city;

export const generateCentreDetailLink = (item) => {
  const country = item?.country
    ? slugify(capitalize(item.country))
    : 'Australia';
  const state = item?.state || 'NSW';
  const postcode = item?.postCode || '2092';
  const suburb = item?.city ? slugify(item.city, { lower: true }) : 'seaforth';
  const id = item?.slug || item?.id;
  const domain = process.env.REACT_APP_RW_URL;
  return `${domain}/childcare/centres/${country}/${state}/${postcode}/${suburb}/${id}`;
};

export const getLinkCentreDetailRichweb = (item, extra) => {
  const country = item?.country
    ? slugify(capitalize(item.country))
    : 'Australia';
  const state = item?.state || 'NSW';
  const postCode = item?.postCode || '2092';
  const suburb = item?.city ? slugify(item.city, { lower: true }) : 'seaforth';
  const slug = item?.slug || item?.id;

  const centreDetailPath = [
    'childcare',
    'centres',
    country,
    state,
    postCode,
    suburb,
    slug,
  ].join('/');

  return `${process.env.REACT_APP_RW_URL}/${centreDetailPath}${extra || ''}`;
};

export const slugifySuburb = (suburb) => slugify(suburb || '', { lower: true });

export const slugifyUrl = (str) =>
  slugify(str || '', { lower: true, strict: true });

export const getLinkJobDetailRichWeb = (item, extra) => {
  const suburbItem = item?.centre?.suburb || item?.company?.suburb;
  const state = item?.centre?.state || item?.company?.state || 'NSW';
  const postcode = item?.centre?.postcode || item?.company?.postcode || '2000';
  const suburb = suburbItem ? slugifySuburb(suburbItem) : 'sydney';
  const company = item?.company?.name
    ? slugifyUrl(item.company.name)
    : EMPTY_CHARACTER;
  const brand = item?.centre?.brand
    ? slugifyUrl(item.centre.brand)
    : EMPTY_CHARACTER;
  const centre = item?.centre?.centreName
    ? slugifyUrl(item.centre.centreName)
    : EMPTY_CHARACTER;
  const jobSlug = item?.slug || item?.id;

  const jobDetailPath = [
    'childcare',
    'jobs',
    'australia',
    state,
    postcode,
    suburb,
    company,
    brand,
    centre,
    jobSlug,
  ].join('/');

  return `${process.env.REACT_APP_RW_URL}/${jobDetailPath}${extra || ''}`;
};

export const getFullDisplayAddress = (item) =>
  compact([
    item?.addressL1 || item?.street,
    item?.city || item?.suburb,
    item?.state,
    item?.postCode,
    item?.country,
  ]).join(', ');

export const formatFromToTimeFilterSubmit = (timeArr) =>
  timeArr
    ? {
        from: timeArr?.[0]
          ? moment(timeArr[0]).startOf('date').toISOString()
          : undefined,
        to: timeArr?.[1]
          ? moment(timeArr[1]).endOf('date').toISOString()
          : undefined,
      }
    : undefined;

export const cleanObject = (obj) => {
  const cleanedObject = pickBy(obj);

  return isEmpty(cleanedObject) ? null : cleanedObject;
};

export const splitCompanyAddressFromGoogleMap = (addressArr) => {
  const addressObj = {};

  forEach(addressArr, (itemAddress) => {
    switch (itemAddress?.types?.[0]) {
      case 'street_number': {
        addressObj.street = `${itemAddress.long_name}${
          addressObj.street ? ` ${addressObj.street}` : ''
        }`;
        break;
      }

      case 'street_address': {
        addressObj.street = `${itemAddress.long_name}${
          addressObj.street ? ` ${addressObj.street}` : ''
        }`;
        break;
      }

      case 'route': {
        addressObj.street = `${
          addressObj.street ? `${addressObj.street} ` : ''
        }${itemAddress.short_name}`;
        break;
      }

      case 'postal_code': {
        addressObj.postCode = `${itemAddress.long_name}`;
        break;
      }

      case 'locality':
        addressObj.suburb = itemAddress.long_name;
        break;

      case 'administrative_area_level_1':
        addressObj.state = itemAddress.short_name;
        break;

      case 'country':
        addressObj.country = itemAddress.long_name;
        break;

      default:
        break;
    }
  });
  return addressObj;
};

export const isValidDurationDays = (durationDays) =>
  durationDays >= MIN_DEADLINE_DAYS && durationDays <= MAX_DEADLINE_DAYS;

export const isValidDurationHours = (durationTimes) =>
  durationTimes >= DEFAULT_DEADLINE_HOURS_DURATION &&
  durationTimes <= MAX_DEADLINE_HOURS;

export const getAudienceValidateFields = (activeTab) =>
  activeTab === EXISTING_AUDIENCE_TAB_KEY
    ? ['id']
    : ['userType', 'userLocation', 'userLocationValues'];

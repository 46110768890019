import { Radio } from 'antd';
import styled from 'styled-components';

export const FilterRadioGroup = styled(Radio.Group)`
  padding: 4px;
  border-radius: 12px;
  background-color: ${({ theme }) => theme.background.container};

  .ant-radio-button-wrapper {
    color: #758098;
    font-weight: 700;
    height: 32px;
    line-height: 32px;
    border: none;
    border-radius: 8px;
    background-color: ${({ theme }) => theme.background.container};

    &:not(:first-child)::before {
      display: none;
    }
    &-disabled {
      color: ${({ theme }) => theme.background.gray300};
    }
    &-checked {
      color: #fff !important;
      background-color: ${({ theme }) => theme.palette.primary};
    }
  }
`;

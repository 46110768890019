import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { getJobTemplateConfigSelector } from 'redux/jobs/jobTemplates/selectors';
import { editJobTemplate } from 'redux/jobs/jobTemplates/actions';
import { remove } from 'lodash-es';
import TableToggle from '../../TableToggle';
import { TabContentContainerStyle } from '../styles';

const JobRequirementsSkillsTab = ({ currentData }) => {
  const dispatch = useDispatch();
  const { id } = useParams();

  const [activeSkills, setActiveSkills] = useState(
    currentData?.skills?.map((item) => ({
      id: item?.id,
      isPreferred: item?.isPreferred,
      isRequired: item?.isRequired,
    })),
  );
  const skillsList = useSelector(getJobTemplateConfigSelector)?.skills;

  const skillsData = skillsList?.map((category) => ({
    ...category,
    items: [
      ...category?.items?.map((item) => ({
        ...item,
        ...currentData?.skills?.find((skill) => skill?.id === item?.id),
      })),
    ],
  }));

  useEffect(() => {
    if (currentData?.skills?.length > 0) {
      setActiveSkills(
        currentData?.skills?.map((item) => ({
          id: item?.id,
          isPreferred: item?.isPreferred,
          isRequired: item?.isRequired,
        })),
      );
    }
  }, [currentData.skills]);

  const updateJobSkills = useCallback(
    (skills) => {
      dispatch(
        editJobTemplate({
          id,
          data: {
            skills,
          },
        }),
      );
    },
    [dispatch, id],
  );

  const handleToggleOnSkill = (skillId) => {
    const newSkill = [
      ...activeSkills,
      { id: skillId, isPreferred: true, isRequired: false },
    ];
    setActiveSkills(newSkill);
    updateJobSkills(newSkill);
  };

  const handleToggleOffSkill = (skillId) => {
    const newSkill = remove([...activeSkills], (i) => i?.id !== skillId);
    setActiveSkills(newSkill);
    updateJobSkills(newSkill);
  };

  const onSwitchChangeSkill = (checked, skill) => {
    checked ? handleToggleOnSkill(skill?.id) : handleToggleOffSkill(skill?.id);
  };

  return (
    <TabContentContainerStyle>
      <div className="column-items">
        {skillsData?.map((category, index) => (
          <div className="pb-24" key={String(index)}>
            <div className="break-inside-avoid">
              <TableToggle
                dataSource={category?.items}
                onSwitchChange={onSwitchChangeSkill}
                categoryName={category?.category}
              />
            </div>
          </div>
        ))}
      </div>
    </TabContentContainerStyle>
  );
};

JobRequirementsSkillsTab.propTypes = {
  currentData: PropTypes.object,
};

export default JobRequirementsSkillsTab;

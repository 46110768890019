import styled from 'styled-components';

export const ReviewRatingStyles = styled.div`
  .row-right-rating {
    margin-bottom: -12px !important;
  }
`;

export const NQSRatingStyles = styled.div`
  .NQSRating-title {
    text-align: center;
    .image-nqs-rating {
      filter: drop-shadow(0px 2px 16px rgba(39, 40, 51, 0.12));
      border-radius: 8px;
      width: 90px;
      object-fit: contain;
      margin-bottom: 15px;
    }
    .title-nqs-rating {
      font-size: 12px;
      line-height: 17px;
      color: ${({ theme }) => theme.text.mainL2};
      margin-bottom: 25px;
    }
  }
  .row-ratting {
    .item-title {
      font-size: 16px;
      line-height: 20px;
      color: ${({ theme }) => theme.text.mainL1};
    }
    .item-value {
      color: ${({ theme }) => theme.color.warning};
      text-align: right;
    }
  }
`;

import React from 'react';
import PropTypes from 'prop-types';
import { Table } from 'antd';
import useFormatFnc from 'containers/CompanyBenchmarking/components/CompanyBenchmarkingTable/useFormat';

const AverageAllBrands = ({ averageAllBrand }) => {
  const useFormat = useFormatFnc();

  const averageBrandData = [
    {
      value: '',
    },
    {
      value: useFormat.formatName(averageAllBrand),
    },
    {
      value: useFormat.formatColumnData(
        averageAllBrand?.averageServiceApprovalAge,
      ),
    },
    {
      value: useFormat.formatColumnData(averageAllBrand?.totalCentres),
    },
    {
      value: useFormat.formatColumnData(averageAllBrand?.approvedPlaces),
    },
    {
      value: useFormat.formatColumnData(averageAllBrand?.averageApprovedPlaces),
    },
    {
      value: useFormat.formatMarketShare(averageAllBrand?.marketShare),
    },
    {
      value: useFormat.formatRevenuePotential(
        averageAllBrand?.revenuePotential,
      ),
    },
    {
      value: useFormat.formatKindiRating(
        averageAllBrand?.averageKindiCareRating,
      ),
    },
    {
      value: useFormat.formatReviewScore(averageAllBrand?.averageReviewScore),
    },
    {
      value: useFormat.formatAverageNumberOfReviews(
        averageAllBrand?.averageNumberOfReviews,
      ),
    },
    {
      value: useFormat.formatPercentColumnData(averageAllBrand?.provisional),
    },
    {
      value: useFormat.formatPercentColumnData(
        averageAllBrand?.significantImprovementRequired,
      ),
    },
    {
      value: useFormat.formatPercentColumnData(
        averageAllBrand?.workingTowardsNQS,
      ),
    },
    {
      value: useFormat.formatPercentColumnData(averageAllBrand?.meetingNQS),
    },
    {
      value: useFormat.formatPercentColumnData(averageAllBrand?.exceedingNQS),
    },
    {
      value: useFormat.formatPercentColumnData(averageAllBrand?.excellent),
    },
    {
      value: useFormat.formatColumnData(averageAllBrand?.averageAgeNQSRating),
    },
    {
      value: useFormat.formatMarketShare(
        averageAllBrand?.averageListingsCompleteness,
      ),
    },
  ];

  return (
    <Table.Summary fixed>
      <Table.Summary.Row className="summary-row">
        {averageBrandData?.map((item, index) => (
          <Table.Summary.Cell index={index} key={Number(index)}>
            {item?.value}
          </Table.Summary.Cell>
        ))}
      </Table.Summary.Row>
    </Table.Summary>
  );
};
AverageAllBrands.propTypes = {
  averageAllBrand: PropTypes.object,
};
export default AverageAllBrands;

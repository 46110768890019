import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { loginClaimCentre } from 'redux/auth/actions';

import PublicLayout from '../../layout/PublicLayout';
import LoginForm from '../../containers/Login';
import LoginWrapper from './styles';

export default function LoginClaimCentre() {
  const dispatch = useDispatch();

  const query = useSelector((state) => state.router?.location?.query);

  const login = (params) =>
    dispatch(loginClaimCentre({ ...params, token: query.token }));

  return (
    <PublicLayout>
      <LoginWrapper>
        <LoginForm customActionLogin={login} />
      </LoginWrapper>
    </PublicLayout>
  );
}

import React from 'react';
import { ExistingUserModel } from 'types/staffProfiles';
import {
  AvatarPlaceholderIcon,
  CheckCircleCheckedIcon,
  CheckCircleUncheckIcon,
} from 'components/common/SVGIcon';
import { Typography } from 'antd';

const { Text } = Typography;

interface Props {
  user: ExistingUserModel;
  checked: boolean;
  setSelectUser: () => void;
}

const ExistingUserItem = ({ user, checked, setSelectUser }: Props) => {
  const fullName = user.lastName
    ? `${user.firstName} ${user.lastName}`
    : user.firstName;

  return (
    <div
      className="flex flex-row gap-14 py-24 border-b-neutral-300 cursor-pointer"
      role="presentation"
      onClick={setSelectUser}
    >
      {checked ? (
        <CheckCircleCheckedIcon className="text-primary text-20" />
      ) : (
        <CheckCircleUncheckIcon className="text-20" />
      )}
      <div className="w-88 h-88">
        {user.avatar ? (
          <img
            src={user.avatar}
            alt={fullName}
            className="w-full h-full object-cover rounded-full"
          />
        ) : (
          <div className="bg-neutral-100 w-full h-full rounded-full flex-center text-32 text-neutral-400">
            <AvatarPlaceholderIcon />
          </div>
        )}
      </div>
      <div className="flex flex-col gap-8 justify-center">
        <Text className="text-16 fw-bold text-black-800">{fullName}</Text>
        {user.email && (
          <Text className="text-16 text-gray-neutral-500">{user.email}</Text>
        )}
        {user.jobTitle && (
          <Text className="text-16 text-black-800">{user.jobTitle}</Text>
        )}
      </div>
    </div>
  );
};

export default ExistingUserItem;

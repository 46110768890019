import React from 'react';
import { Typography } from 'antd';
import i18next from 'i18next';
import CustomBreadcrumb from 'components/common/Breadcrumb';
import { getDataJobApplicationProfile } from 'redux/jobs/jobApplications/selectors';
import { useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import JobApplicationDetail from '../components/JobApplicationDetail';

const JobApplicationShow = () => {
  const currentData = useSelector(getDataJobApplicationProfile) ?? {};

  const customBreadcrumb = [
    {
      title: i18next.t('jobApplications.title'),
      path: '/job-applications/board',
    },
    {
      title: `${currentData?.seeker?.firstname} ${currentData?.seeker?.lastname}`,
      path: '#',
    },
  ];

  return (
    <div>
      {!isEmpty(currentData) && <CustomBreadcrumb data={customBreadcrumb} />}
      <Typography.Text className="text-32 fw-bold">
        {i18next.t('jobApplications.titleDetail')}
      </Typography.Text>
      <JobApplicationDetail />
    </div>
  );
};

export default JobApplicationShow;

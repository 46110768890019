import React from 'react';
import i18next from 'i18next';
import PropTypes from 'prop-types';
import { Row, Col } from 'antd';
import { formatDateApplication } from 'utils/textUtils';
import { formatMoney } from 'utils/tools';
import { useSelector } from 'react-redux';
import { getCentresObjKeyById } from 'redux/auth/selectors';

import { BlockInfoStyles } from './styles';
import DropdownStatusApplication from '../DropdownStatusApplication';

const BlockInfo = ({ record, handleUpdateStatus }) => {
  const child = record.careOption || {};
  const centreName =
    useSelector(getCentresObjKeyById)?.[record?.centreId]?.name;

  return (
    <BlockInfoStyles className="info-application">
      <Row gutter={16} className="row-info-application">
        <Col span={12} className="title-item-col">
          <div>{i18next.t('applications.currentStatus')}</div>
        </Col>
        <Col span={12} className="value-item-col">
          <div>
            <DropdownStatusApplication
              status={record.status}
              handleUpdateStatus={handleUpdateStatus}
              child={child}
            />
          </div>
        </Col>

        <Col span={12} className="title-item-col">
          <div>{i18next.t('applications.applied')}</div>
        </Col>
        <Col span={12} className="value-item-col">
          <div>{formatDateApplication(record.submittedAt)}</div>
        </Col>

        <Col span={12} className="title-item-col">
          <div>{i18next.t('applications.startDay')}</div>
        </Col>
        <Col span={12} className="value-item-col">
          <div>{formatDateApplication(child.startDay)}</div>
        </Col>

        <Col span={12} className="title-item-col">
          <div>{i18next.t('applications.value')}</div>
        </Col>
        <Col span={12} className="value-item-col">
          <div>
            {`${i18next.t('currencyUnit.sign')}${formatMoney(
              child.value,
            )} ${i18next.t('text.perAnnum')}`}
          </div>
        </Col>

        <Col span={12} className="title-item-col">
          <div>{i18next.t('applications.applicationFee')}</div>
        </Col>
        <Col span={12} className="value-item-col">
          <div>
            {`${i18next.t('currencyUnit.sign')}${formatMoney(record.fee)}`}
          </div>
        </Col>

        <Col span={12} className="title-item-col">
          <div>{i18next.t('centres.centreName')}</div>
        </Col>
        <Col span={12} className="value-item-col">
          <div>{centreName}</div>
        </Col>
      </Row>
    </BlockInfoStyles>
  );
};

BlockInfo.propTypes = {
  record: PropTypes.object,
  handleUpdateStatus: PropTypes.func,
};

export default BlockInfo;

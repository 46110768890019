import React from 'react';
import PropTypes from 'prop-types';
import { Typography, Tooltip } from 'antd';
import { Wrapper } from './styles';

const TitleTooltip = ({ title, rows, className }) => (
  <Wrapper>
    <Tooltip title={title}>
      <Typography.Paragraph
        className={className ?? 'text'}
        placement="top"
        ellipsis={{ rows, expandable: true }}
      >
        {title}
      </Typography.Paragraph>
    </Tooltip>
  </Wrapper>
);

TitleTooltip.propTypes = {
  title: PropTypes.string,
  rows: PropTypes.number,
  className: PropTypes.string,
};

export default TitleTooltip;

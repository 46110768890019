import { Spin } from 'antd';
import ReactDOM from 'react-dom';
import React from 'react';

const LoadingMore = () =>
  ReactDOM.createPortal(
    <Spin className="flex-center p-33 w-full" />,
    document.querySelector('.ant-table-body'),
  );

export default LoadingMore;

import styled from 'styled-components';

export const ButtonActionStyles = styled.div`
  margin-top: 30px;
  button {
    width: 100%;
    font-weight: 500;
    color: ${({ theme }) => theme.palette.primary};
    border: 1px solid ${({ theme }) => theme.palette.primary};
  }

  .fallback-decline-btn {
    position: relative;
    .action-feature-icon {
      position: absolute;
      top: -12px;
      right: -9px;
      font-size: 20px;
      color: ${({ theme }) => theme.subscriptions.colorIcon};
    }
  }
`;

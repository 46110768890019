import { del, get, post, put } from 'api/utils';
import { PREFIX_API_VER_2 } from 'configs/localData/constant';
import qs from 'qs';
import { UpsertAudienceBody } from './type';

export const getAdsFormConfigApi = (params) =>
  get('/crm/ads/posts/form-config', params, PREFIX_API_VER_2);

export const getAdAudiencesApi = (params) =>
  get('/crm/ads/posts/targeted-audiences', params, PREFIX_API_VER_2);

export const createAdAudienceApi = (body: UpsertAudienceBody) =>
  post('/crm/ads/posts/targeted-audiences', body, PREFIX_API_VER_2);

export const editAdAudienceApi = (
  targetedAudienceId: string,
  body: UpsertAudienceBody,
) =>
  put(
    `/crm/ads/posts/targeted-audiences?targetedAudienceId=${targetedAudienceId}`,
    body,
    PREFIX_API_VER_2,
  );

export const deleteAdAudienceApi = (targetedAudienceId: string) =>
  del(
    `/crm/ads/posts/targeted-audiences?targetedAudienceId=${targetedAudienceId}`,
    null,
    PREFIX_API_VER_2,
  );

export const getCountAdsUsingAudience = (targetedAudienceId: string) =>
  get(
    `/crm/ads/posts/targeted-audiences/post-count?targetedAudienceId=${targetedAudienceId}`,
    null,
    PREFIX_API_VER_2,
  );
export const getCallingButtonListApi = (params) =>
  get('/crm/ads/posts/calling-button', params, PREFIX_API_VER_2);

export const addNewCallingButtonApi = (params) =>
  post('/crm/ads/posts/calling-button', params, PREFIX_API_VER_2);

export const editCallingButtonApi = (params, data) =>
  put(
    `/crm/ads/posts/calling-button?${qs.stringify(params)}`,
    data,
    PREFIX_API_VER_2,
  );

export const deleteCallingButtonApi = (params) =>
  del(
    `/crm/ads/posts/calling-button?${qs.stringify(params)}`,
    params,
    PREFIX_API_VER_2,
  );

export const getUsedCallingButtonCountApi = (params) =>
  get('/crm/ads/posts/calling-button/post-count', params, PREFIX_API_VER_2);

export const getAllAdsApi = (params) =>
  get('/crm/ads/posts', params, PREFIX_API_VER_2);

export const getAllAdsTitleApi = (params) =>
  get('/crm/ads/posts/title ', params, PREFIX_API_VER_2);

export const getAdDetailApi = (id: string) =>
  get(`/crm/ads/posts/details?postId=${id}`, null, PREFIX_API_VER_2);

export const createAdApi = (params) =>
  post('/crm/ads/posts', params, PREFIX_API_VER_2);

export const editAdApi = (params, data) =>
  put(`/crm/ads/posts?${qs.stringify(params)}`, data, PREFIX_API_VER_2);

export const closeAdsApi = (id: string) =>
  put(`/crm/ads/posts/close?adsPostId=${id}`, null, PREFIX_API_VER_2);

export const deleteAdApi = (id: string) =>
  del(`/crm/ads/posts?adsPostId=${id}`, null, PREFIX_API_VER_2);

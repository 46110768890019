import React, { useRef, useEffect, useCallback } from 'react';
import i18next from 'i18next';
import { Input, Form, Radio, Space, Button, Spin } from 'antd';
import { SearchIcon } from 'components/common/SVGIcon';
import PropTypes from 'prop-types';
import useCheckJobListingPermission from 'containers/JobListings/hooks/useCheckJobListingPermission';
import { useDispatch, useSelector } from 'react-redux';
import { getCampaignsSelector } from 'redux/jobs/jobListings/selectors';
import { getCampaigns } from 'redux/jobs/jobListings/actions';
import { Waypoint } from 'react-waypoint';
import { debounce, isEmpty, pickBy } from 'lodash-es';
import { useHistory } from 'react-router';
import qs from 'qs';
import { LIMIT_CAMPAIGN_DEFAULT } from 'containers/JobListings/constants';
import Wrapper from './styles';
import CreateCampaignModal from '../../Modals/CreateCampaignModal';

const FormChooseCampaign = ({
  form,
  onFinish,
  initialValues,
  showCreate,
  maxHeight,
  isFixedCampaignSearchBar,
}) => {
  const campaignModalRef = useRef();
  const dispatch = useDispatch();
  const { push, location } = useHistory();
  const { name = '', ...restQuery } = qs.parse(location.search, {
    ignoreQueryPrefix: true,
  });

  const nameRef = useRef(name);

  const { data, loading, resourceParams } = useSelector(getCampaignsSelector);
  const { canModifyCampaign } = useCheckJobListingPermission();

  const isEnableLoadMore =
    !isEmpty(data) &&
    !loading &&
    resourceParams.page < Math.ceil(resourceParams.total / resourceParams.size);

  const getListCampaign = useCallback(
    ({ data, isRefresh }) =>
      dispatch(
        getCampaigns({
          data: {
            page: 1,
            size: LIMIT_CAMPAIGN_DEFAULT,
            ...data,
          },
          options: {
            isRefresh,
          },
        }),
      ),
    [dispatch],
  );

  const openCreateCampaignModal = () => {
    campaignModalRef.current.open();
  };

  const onEnterWaypoint = () => {
    getListCampaign({
      data: {
        page: +resourceParams.page + 1,
        size: resourceParams.size || LIMIT_CAMPAIGN_DEFAULT,
        ...(name && {
          filter: {
            name,
          },
        }),
      },
      isRefresh: false,
    });
  };

  const debounceSearch = debounce((name) => {
    push({
      search: qs.stringify(pickBy({ ...restQuery, name })),
    });

    getListCampaign({
      data: {
        filter: name
          ? {
              name,
            }
          : undefined,
      },
      isRefresh: true,
    });
  }, 600);

  const onSearchChange = (e) => {
    const { value } = e.target;

    debounceSearch(value);
  };

  useEffect(() => {
    getListCampaign({
      data: {
        filter: nameRef.current
          ? {
              name: nameRef.current,
            }
          : undefined,
      },
      isRefresh: true,
    }).then(({ payload }) => {
      form.setFieldsValue({ campaignId: payload?.results?.[0]?.id });
    });
  }, [form, getListCampaign]);

  return (
    <Wrapper
      maxHeight={maxHeight}
      isFixedCampaignSearchBar={isFixedCampaignSearchBar}
    >
      <div className="flex-center-between">
        <Input
          prefix={<SearchIcon className="text-gray-500 mr-10" />}
          placeholder={i18next.t('jobListings.searchCampaign')}
          onChange={onSearchChange}
          defaultValue={name}
        />
        {showCreate && (
          <Button
            className="btn-line-primary t-bold ml-12"
            disabled={!canModifyCampaign}
            onClick={openCreateCampaignModal}
          >
            {i18next.t('jobListings.buttons.createCampaign')}
          </Button>
        )}
      </div>
      <Form
        className="mt-16"
        onFinish={onFinish}
        form={form}
        initialValues={{ ...initialValues }}
      >
        <Form.Item
          name="campaignId"
          className="mb-0"
          rules={[
            {
              required: true,
              message: i18next.t('jobListings.pleaseSelectCampaign'),
            },
          ]}
        >
          <Radio.Group>
            <Space direction="vertical" size={10}>
              {data?.map((item) => (
                <Radio key={item.id} value={item.id}>
                  {item.name}
                </Radio>
              ))}
            </Space>
          </Radio.Group>
        </Form.Item>
        {isEnableLoadMore && (
          <div style={{ height: 1 }}>
            <Waypoint onEnter={onEnterWaypoint} />
          </div>
        )}
        {loading && <Spin className="mt-12" />}
      </Form>
      <CreateCampaignModal ref={campaignModalRef} />
    </Wrapper>
  );
};

FormChooseCampaign.propTypes = {
  form: PropTypes.object,
  onFinish: PropTypes.func,
  initialValues: PropTypes.object,
  maxHeight: PropTypes.number,
  showCreate: PropTypes.bool,
  isFixedCampaignSearchBar: PropTypes.bool,
};
export default FormChooseCampaign;

import React, { useMemo, useEffect, useState } from 'react';
import i18next from 'i18next';
import { useSelector } from 'react-redux';
import { formatDateApplication } from 'utils/textUtils';
import { formatEnquiryStatus, formatEnquiryType } from 'utils/formatFieldUtils';

import CardSection from 'components/common/CardSection';
import TableCustom from 'components/common/TableCustom';
import { getCentreId } from 'redux/auth/selectors';
import { Link } from 'react-router-dom';
import { useGetEnquiriesQuery } from 'redux/@rtkQuery/contacts';
import {
  DEFAULT_CURRENT_PAGE,
  SORT_DESCEND_VALUE,
} from 'configs/localData/constant';
import { DefaultFilter } from 'redux/@rtkQuery/contacts/types';
import { useParams } from 'react-router';
import {
  DEFAULT_LIMIT_ITEM,
  PAGE_SIZE_OPTIONS,
} from 'containers/Contacts/constants';
import GroupActionEnquiry from './GroupActionEnquiry';

const DEFAULT_FILTER: DefaultFilter = {
  size: DEFAULT_LIMIT_ITEM,
  page: DEFAULT_CURRENT_PAGE,
};

const EnquiriesTable = () => {
  const [filterValue, setFilterValue] = useState(DEFAULT_FILTER);

  const centreId = useSelector(getCentreId);

  const { id } = useParams();

  const { data, isLoading, refetch, isFetching } = useGetEnquiriesQuery({
    contactId: id,
    ...filterValue,
  });

  const columns = useMemo(
    () => [
      {
        title: i18next.t('centres.centreName'),
        dataIndex: 'centreName',
        key: 'centreName',
        sorter: true,
        render: (data, record) => (
          <Link
            className="text-black"
            to={`/centres/${record.centreId}/show/summary`}
          >
            {data || ''}
          </Link>
        ),
      },
      {
        title: i18next.t('enquiries.enquiryType'),
        dataIndex: 'enquiryTypeName',
        render: formatEnquiryType,
      },
      {
        title: i18next.t('enquiries.enquiryData'),
        dataIndex: 'createdAt',
        key: 'createdAt',
        render: formatDateApplication,
        width: 140,
        sorter: true,
      },
      {
        title: i18next.t('common.status'),
        dataIndex: 'centreHasNewMessage',
        key: 'centreHasNewMessage',
        render: (centreHasNewMessage, record) =>
          formatEnquiryStatus(
            centreHasNewMessage,
            record.centreResponded,
            record.centreEnquiryStatus,
          ),
        width: 150,
      },
      {
        fixed: 'right',
        width: 80,
        dataIndex: 'id',
        key: 'id',
        render: (id, record) => (
          <GroupActionEnquiry
            id={id}
            centreEnquiryStatus={record?.centreEnquiryStatus}
          />
        ),
      },
    ],
    [],
  );

  useEffect(() => {
    refetch();
  }, [centreId, refetch]);

  const onChange = (pagination, filters, sorter) => {
    const formatSort =
      sorter && sorter.columnKey && sorter.order
        ? `${sorter.order === SORT_DESCEND_VALUE ? '-' : ''}${sorter.columnKey}`
        : null;

    setFilterValue({
      sorts: [formatSort],
      size: pagination?.pageSize,
      page: pagination?.current,
    });
  };

  return (
    <CardSection title="enquiries.header">
      <TableCustom
        pagination={{
          pageSize: data?.paging?.size || DEFAULT_LIMIT_ITEM,
          current: data?.paging?.page || DEFAULT_CURRENT_PAGE,
          total: data?.paging?.total || 0,
          pageSizeOptions: PAGE_SIZE_OPTIONS,
        }}
        isResetStyle
        xScroll={760}
        data={data?.results || []}
        columns={columns}
        loading={isLoading || isFetching}
        onChange={onChange}
      />
    </CardSection>
  );
};

export default EnquiriesTable;

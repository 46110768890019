import React, { useEffect } from 'react';

import CustomBreadcrumb from 'components/common/Breadcrumb';
import i18next from 'i18next';
import { DEFAULT_INBOX_ID } from 'configs/localData';
import PageTitle from 'components/common/PageTitle';
import { Button, Col, Row } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { setCurrentDataAutomatedResponse } from 'redux/automatedResponses/slice';
import { getByIdCentres } from 'redux/centres/actions';
import { ReturnIcon } from 'components/common/SVGIcon';
import { useHistory, useLocation } from 'react-router';
import AutomatedStyles, { AutomatedDetailWrapperStyles } from './styles';
import AutomatedList from './components/AutomatedList';
import AutomatedDetail from './components/AutomatedDetail';

const AutomatedResponsesPage = () => {
  const dispatch = useDispatch();
  const { push } = useHistory();

  const location = useLocation();

  const isUpdateStep = location?.state?.isUpdateStep11;
  const beforeCentreId = location?.state?.centreId;

  const breadcrumbData = [
    {
      title: i18next.t('enquiries.header'),
      path: `/enquiries/all/${DEFAULT_INBOX_ID}`,
    },

    {
      title: i18next.t('automatedResponses.header'),
    },
  ];

  const centreId = useSelector((state) => state.auth.centreId);

  const firstCentreId = useSelector((state) => state.auth.centreIds?.[0]);

  useEffect(() => {
    if (centreId) {
      dispatch(
        getByIdCentres({
          data: { id: centreId },
        }),
      );
    } else if (firstCentreId) {
      dispatch(
        getByIdCentres({
          data: { id: firstCentreId },
        }),
      );
    }
  }, [centreId, dispatch, firstCentreId]);

  useEffect(
    () => () => {
      dispatch(setCurrentDataAutomatedResponse({}));
    },
    [dispatch],
  );

  const onReturn = () => {
    if (centreId) {
      push(`/centres/${centreId}/show/summary`);
    } else {
      push(`/centres/${beforeCentreId}/show/summary`);
    }
  };

  return (
    <AutomatedDetailWrapperStyles>
      <AutomatedStyles>
        <CustomBreadcrumb data={breadcrumbData} />
        <PageTitle>{i18next.t('automatedResponses.header')}</PageTitle>

        <div className="automated__content">
          <Row>
            <Col xl={6} lg={8} md={24} xs={24}>
              <AutomatedList />
            </Col>
            <Col xl={18} lg={16} md={24} xs={24}>
              <AutomatedDetail />
            </Col>
          </Row>
        </div>
      </AutomatedStyles>
      {isUpdateStep && (
        <div className="btn-automated text-center">
          <Button
            onClick={onReturn}
            className="btn-return"
            icon={<ReturnIcon />}
          >
            {i18next.t('button.returnToMyCentre')}
          </Button>
        </div>
      )}
    </AutomatedDetailWrapperStyles>
  );
};

export default AutomatedResponsesPage;

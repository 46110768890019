import { Col, Row } from 'antd';
import theme from 'configs/theme';
import styled from 'styled-components';

export const MessageTimeStyles = styled.div`
  color: #38465c;
  text-align: center;
  gap: 4px;
  .anticon {
    font-size: 16px;
  }
`;

export const MailboxTabStyles = styled.div`
  .mailbox-tab__enquiries {
    height: 600px;
    display: flex;
    flex-direction: column-reverse;
    overflow-y: auto;
    gap: 20px;
  }
  .mailbox-tab__input-area {
    padding: 16px 20px;
    box-shadow: inset 0px 1px 0px #e9ecf7;
  }
  .ant-btn,
  .ant-btn[disabled] {
    border-color: white;
    background-color: white;
    &::after {
      display: none;
    }
  }
`;

export const MessageItemStyles = styled(Row)`
  .message-item__content {
    background: ${(props) =>
      props.issender ? theme.palette.green : theme.background.container};
    max-width: min(600px, 80%);

    .ant-typography {
      color: ${(props) => (props.issender ? '#ffffff' : '#141533')};
    }
  }
`;

export const InputWrapperStyles = styled(Col)`
  background: ${theme.background.container};
  border-radius: 16px;
  padding: 8px 12px;
`;

export const OkButtonStyles = {
  backgroundColor: `${theme.palette.green}`,
  borderColor: `${theme.palette.green}`,
};

import React from 'react';
import PropTypes from 'prop-types';

import FormStyles from './styles';
import RoleForm from './RoleForm';
import GeneralUserForm from './GeneralUserForm';

const UserForm = ({ isEdit }) => (
  <FormStyles>
    <GeneralUserForm />

    <RoleForm isEdit={isEdit} />
  </FormStyles>
);

UserForm.propTypes = {
  isEdit: PropTypes.bool,
};

UserForm.defaultProps = {};

export default UserForm;

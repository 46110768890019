import React from 'react';
import PropTypes from 'prop-types';
import RestSelect from 'components/RestInput/RestSelect';
import { capitalize } from 'lodash-es';

const SelectWorkplace = ({
  name = 0,
  resourceData = [],
  onSelectWorkplace,
  ...props
}) => {
  const data = resourceData?.map((item) => ({
    name: `${item?.centreName} - ${capitalize(item?.workplaceType)}`,
    organizationId: item?.organizationId,
  }));

  return (
    <RestSelect
      mode="multiple"
      source={[name, 'organizationIds']}
      placeholder="input.workplaceName.placeholder"
      resourceData={data}
      onChange={onSelectWorkplace}
      valueProp="organizationId"
      titleProp="name"
      allowSearch
      {...props}
    />
  );
};

SelectWorkplace.propTypes = {
  name: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  resourceData: PropTypes.array,
  onSelectWorkplace: PropTypes.func,
};

export default SelectWorkplace;

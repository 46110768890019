import React from 'react';
import PageTitle from 'components/common/PageTitle';
import i18next from 'i18next';
import { Result } from 'antd';
import { ModuleAccessWarningWrapper } from './styles';

interface Props {
  title: string;
  headlineTextCustom?: string;
}

const ModuleAccessWarning = ({ title, headlineTextCustom }: Props) => (
  <ModuleAccessWarningWrapper>
    <PageTitle>{i18next.t(title)}</PageTitle>
    <Result
      status="warning"
      title={
        <>
          {i18next.t('insights.accessWarning')} {headlineTextCustom ?? title}
          <br />
          {i18next.t('insights.pleaseContact')}
          <a
            rel="noopener noreferrer"
            target="_blank"
            className="text-blue"
            href="https://kindicare.com/contact-us/"
          >
            {i18next.t('insights.customerCare')}
          </a>
          {i18next.t('insights.forMoreHelp')}
        </>
      }
    />
  </ModuleAccessWarningWrapper>
);

export default ModuleAccessWarning;

import React from 'react';
import { MetricTag } from './TooltipContent/styles';

interface Props {
  color: string;
  name: string;
  className?: string;
  tagWidth?: number;
}

const MetricLegendItem = ({ color, name, tagWidth, className }: Props) => (
  <div className={`flex-center ${className ?? ''}`}>
    <MetricTag color={color} width={tagWidth} className="mr-10" />
    <div>{name}</div>
  </div>
);

export default MetricLegendItem;

import React, { useCallback } from 'react';
import i18next from 'i18next';
import PropTypes from 'prop-types';
import { KEYS_DAYS_IN_WEEK } from 'configs/localData';
import { ServiceAppliedTableStyled } from './styles';

const ServiceApplied = ({ centreServices, requiredDays }) => {
  const getDayText = useCallback((value) => {
    const item = KEYS_DAYS_IN_WEEK.find((item) => item.value === value) || {};
    return item.text ? i18next.t(item.text) : value;
  }, []);

  return (
    <ServiceAppliedTableStyled>
      <div className="tag-service">
        {centreServices.map((item, index) => (
          <div key={String(index)} className="service-item-wrapper">
            <div className="service-name">
              {item?.serviceType?.name || i18next.t('error.waitingUpdate')}
            </div>
            {item.serviceAgeGroup?.name && (
              <div className="age-group-service">
                {`(${item?.serviceAgeGroup?.name})`}
              </div>
            )}
          </div>
        ))}
      </div>
      <div className="required-days">
        {requiredDays.map((date, index) => (
          <span key={String(index)}>{getDayText(date)}</span>
        ))}
      </div>
    </ServiceAppliedTableStyled>
  );
};

ServiceApplied.propTypes = {
  centreServices: PropTypes.array,
  requiredDays: PropTypes.array,
};

ServiceApplied.defaultProps = {
  centreServices: [],
  requiredDays: [],
};

export default ServiceApplied;

import React from 'react';
import PropTypes from 'prop-types';
import i18next from 'i18next';
import { Button } from 'antd';

import CheckPermission from 'components/common/CheckPermissions';
import BtnFallback from './Fallback';
import { CustomButtonStyles } from './styles';

const CustomButton = ({
  title,
  permissionKey,
  action,
  handleClick,
  isSkipSubscription,
  buttonProps,
  loading,
  packageName,
  disabled,
}) => (
  <CustomButtonStyles className="custom-button-wrapper">
    <CheckPermission
      packageName={packageName}
      permissionKey={permissionKey}
      action={action}
      isSkipSubscription={isSkipSubscription}
      isDisabled
      fallback={<BtnFallback title={title} buttonProps={buttonProps} />}
    >
      <Button
        onClick={handleClick}
        loading={loading}
        disabled={disabled}
        {...buttonProps}
      >
        {i18next.t(title)}
      </Button>
    </CheckPermission>
  </CustomButtonStyles>
);

CustomButton.propTypes = {
  handleClick: PropTypes.func,
  title: PropTypes.string,
  permissionKey: PropTypes.string,
  action: PropTypes.string,
  isSkipSubscription: PropTypes.bool,
  buttonProps: PropTypes.object,
  loading: PropTypes.bool,
  packageName: PropTypes.string,
  disabled: PropTypes.bool,
};

CustomButton.defaultProps = {
  title: 'button.normal',
  buttonProps: {},
};

export default CustomButton;

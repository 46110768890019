import { createSlice } from '@reduxjs/toolkit';
import { uniqBy } from 'lodash-es';
import {
  addCompanyVirtualTour,
  editAboutUsCard,
  editCompanyProfile,
  editCompanyProfileSocialMediaChannels,
  editEducatorandStaffDiscounts,
  getCompanyProfile,
  getCompanyProfileSocialMediaChannels,
  getCompanyVirtualTour,
  MODEL_NAME,
  updateCompanyProfileMedia,
} from './actions';
import { formatMediaListResponse } from './utils';

const initialState = {
  loading: false,
  data: null,
  error: null,
};

const getCompanyProfilePending = (state) => {
  state.loading = true;
};

const getCompanyProfileFulfilled = (state, { payload }) => {
  state.loading = false;

  const transformResponse = {
    ...payload,
    mediaList: formatMediaListResponse(payload?.mediaList),
  };

  state.data = transformResponse;
  state.error = null;
};

const getCompanyProfileFailed = (state, { payload }) => {
  state.loading = false;
  state.error = payload;
};

const getCompanyProfileSocialMediaChannelsSuccess = (state, { payload }) => {
  state.socialMedia = payload.buttons;
  state.totalSocialMedia = payload.total;
};

const editCompanyProfileSocialMediaChannelsSuccess = (state, { payload }) => {
  state.socialMedia = uniqBy(
    [...payload.results, ...(state.socialMedia || [])],
    'id',
  );
};

const updateCompanyProfileMediaSuccess = (state, { payload }) => {
  state.data.mediaList = formatMediaListResponse(payload?.mediaList);
  state.data.recruitmentMedia = formatMediaListResponse(
    payload?.recruitmentMedia,
  );
};

const editEducatorandStaffDiscountsSuccess = (state, { payload }) => {
  state.data.educatorDiscount = payload?.educatorDiscount;
  state.data.staffDiscount = payload?.staffDiscount;
};

const editAboutUsCardSuccess = (state, { payload }) => {
  state.data.description = payload?.description;
};

const editCompanyProfileSuccess = (state, { payload }) => {
  const transformResponse = {
    ...payload?.data,
    mediaList: formatMediaListResponse(payload?.data?.mediaList),
  };

  state.data = transformResponse;
};

const getCompanyVirtualTourSuccess = (state, { payload }) => {
  state.companyVirtualTour = payload?.results;
};

const addCompanyVirtualTourSuccess = (state, { payload }) => {
  state.companyVirtualTour = payload?.results;
};

const companyProfile = createSlice({
  name: MODEL_NAME,
  initialState,
  extraReducers: {
    [getCompanyProfile.pending]: getCompanyProfilePending,
    [getCompanyProfile.fulfilled]: getCompanyProfileFulfilled,
    [getCompanyProfile.rejected]: getCompanyProfileFailed,

    [getCompanyProfileSocialMediaChannels.fulfilled]:
      getCompanyProfileSocialMediaChannelsSuccess,

    [editCompanyProfileSocialMediaChannels.fulfilled]:
      editCompanyProfileSocialMediaChannelsSuccess,

    [updateCompanyProfileMedia.fulfilled]: updateCompanyProfileMediaSuccess,
    [editEducatorandStaffDiscounts.fulfilled]:
      editEducatorandStaffDiscountsSuccess,

    [editAboutUsCard.fulfilled]: editAboutUsCardSuccess,
    [editCompanyProfile.fulfilled]: editCompanyProfileSuccess,

    [getCompanyVirtualTour.fulfilled]: getCompanyVirtualTourSuccess,
    [addCompanyVirtualTour.fulfilled]: addCompanyVirtualTourSuccess,
  },
});

export default companyProfile.reducer;

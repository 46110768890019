import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { keyBy } from 'lodash-es';

import { getImpersonateSessionToken, getSessionToken } from 'utils/token';
import { Dictionary } from 'lodash';
import { AuthCentre, AuthCRMPackage, AuthResponse } from 'types/auth';
import {
  changePassword,
  forgotPassword,
  getAllCentresOfUser,
  getCurrentUser,
  getCurrentUserPermissions,
  login,
  loginClaimCentre,
  register,
  registerClientWithToken,
  resetPassword,
  updateCurrentUser,
  verifyRegisterToken,
  verifyResetPasswordToken,
} from 'redux/auth/actions';

interface InitialState {
  isAuthenticated?: boolean;
  data?: AuthResponse;
  role?: string;
  loginError?: boolean;
  loginSuccess?: boolean;
  permissionData?: unknown;
  error?: unknown;
  multiCentreLevelCRMPackageName?: string;
  loading?: boolean;
  centresOfUser?: unknown;
  isVerifiedResetPassword?: boolean;
  loadingVerifyResetPassword?: boolean;
  centreId?: string;
  permissions?: Array<any>;
  isVerified?: boolean;
  registerUser?: unknown;
  centresObjKeyById?: Dictionary<object>;
  centreIds?: string[];
  isShowAllCentres?: boolean;
  centreLevelCRMPackageName?: string;
  centreLevelCRMPackageSelected?: AuthCRMPackage;
  lowestCentreLevelCRMPackage?: AuthCRMPackage;
  centreIdSelected?: string;
}

const initialState: InitialState = {
  isAuthenticated: !!getImpersonateSessionToken() || !!getSessionToken(),
  data: {},
  role: null,
  loginError: false,
  loginSuccess: false,
  permissionData: null,
  loading: false,
  error: '',
  multiCentreLevelCRMPackageName: '',
  centresOfUser: '',
  isVerifiedResetPassword: false,
  loadingVerifyResetPassword: false,
  centreId: '',
  permissions: [],
  isVerified: false,
  registerUser: {},
  centresObjKeyById: {},
  centreIds: [],
  isShowAllCentres: false,
  centreLevelCRMPackageName: '',
  centreLevelCRMPackageSelected: {},
  lowestCentreLevelCRMPackage: {},
};

const formatCentreValue = (
  state: InitialState,
  centres: AuthCentre[],
  centreIdSelected?: string,
) => {
  const centresObjKeyById = keyBy(centres, 'id');

  state.centresObjKeyById = centresObjKeyById;

  const centreIds = Object.keys(centresObjKeyById);

  state.centreIds = centreIds;

  const isShowAllCentres = centres?.length > 1;

  state.isShowAllCentres = isShowAllCentres;

  if (centreIds?.includes(centreIdSelected)) {
    state.centreLevelCRMPackageName =
      centresObjKeyById[centreIdSelected].centreSubscription?.CRMPackage?.name;
    state.centreLevelCRMPackageSelected =
      centresObjKeyById[centreIdSelected].centreSubscription?.CRMPackage;
    return;
  }
  if (isShowAllCentres) {
    state.centreId = undefined;
    return;
  }

  const centreId = centreIds?.[0];

  if (centreId) {
    state.centreId = centreId;
    state.centreLevelCRMPackageName =
      centresObjKeyById[centreId].centreSubscription?.CRMPackage?.name;
    state.centreLevelCRMPackageSelected =
      centresObjKeyById[centreId].centreSubscription?.CRMPackage;
  }
};

const getCurrentUserSuccess = (
  state,
  { payload }: PayloadAction<AuthResponse>,
) => {
  state.data = payload;

  state.role = payload.role?.name;

  state.isAuthenticated = true;

  state.multiCentreLevelCRMPackageName =
    payload.multiCentreLevelCRMPackage?.name;

  state.lowestCentreLevelCRMPackage =
    payload?.lowestCentreLevelCRMPackage?.name;

  formatCentreValue(state, payload?.centres, state.centreId);
};

const loginSuccess = (state, { payload }) => {
  state.loading = false;

  state.isAuthenticated = true;

  state.loginError = false;

  state.loginSuccess = true;

  state.data = payload.data;

  state.role = payload.data?.role?.name;

  state.multiCentreLevelCRMPackageName =
    payload.data?.multiCentreLevelCRMPackage?.name;

  state.lowestCentreLevelCRMPackage =
    payload?.lowestCentreLevelCRMPackage?.name;

  formatCentreValue(state, payload?.data?.centres);
};

const setCurrentCentreConfigSuccess = (
  state,
  {
    payload: {
      centreId,
      centreLevelCRMPackageName,
      centreLevelCRMPackageSelected,
    },
  },
) => {
  state.centreId = centreId;
  state.centreLevelCRMPackageName = centreLevelCRMPackageName;
  state.centreLevelCRMPackageSelected = centreLevelCRMPackageSelected;
};

const { reducer, actions } = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    getPermissionsSuccess: (state, { payload }) => {
      state.loading = false;
      state.permissionData = payload;
    },
    getPermissionsFail: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
    subscribeUser: (state) => {
      state.loading = true;
    },
    subscribeUserSuccess: (state) => {
      state.loading = false;
    },
    subscribeUserFail: (state, { payload }) => {
      state.error = payload.error;
      state.loading = false;
    },
    logoutSuccess: () => {
      sessionStorage.clear();
      localStorage.clear();
      window.location.href = '/login';
    },
    setCurrentCentreConfig: setCurrentCentreConfigSuccess,
    setCentreId: (state, { payload }) => {
      state.centreId = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(login.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(login.fulfilled, loginSuccess);
    builder.addCase(login.rejected, (state) => {
      state.loading = false;
      state.isAuthenticated = false;
    });
    builder.addCase(loginClaimCentre.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(loginClaimCentre.fulfilled, loginSuccess);
    builder.addCase(loginClaimCentre.rejected, (state) => {
      state.loading = false;
      state.isAuthenticated = false;
    });
    builder.addCase(register.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(register.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(register.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(
      getCurrentUserPermissions.fulfilled,
      (state, { payload }) => {
        state.permissions = payload;
      },
    );
    builder.addCase(
      getCurrentUserPermissions.rejected,
      (state, { payload }) => {
        state.error = payload;
      },
    );
    builder.addCase(getCurrentUser.fulfilled, getCurrentUserSuccess);
    builder.addCase(updateCurrentUser.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updateCurrentUser.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.data = payload.data;
    });
    builder.addCase(updateCurrentUser.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(forgotPassword.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(forgotPassword.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(forgotPassword.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(resetPassword.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(resetPassword.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(resetPassword.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(changePassword.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(changePassword.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(changePassword.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(verifyRegisterToken.fulfilled, (state, { payload }) => {
      state.isVerified = true;
      state.registerUser = payload;
    });
    builder.addCase(verifyRegisterToken.rejected, (state, { payload }) => {
      state.error = payload;
    });
    builder.addCase(registerClientWithToken.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(registerClientWithToken.fulfilled, (state) => {
      state.loading = false;
      state.isAuthenticated = true;
    });
    builder.addCase(registerClientWithToken.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(verifyResetPasswordToken.pending, (state) => {
      state.loadingVerifyResetPassword = true;
    });
    builder.addCase(verifyResetPasswordToken.fulfilled, (state) => {
      state.isVerifiedResetPassword = true;
      state.loadingVerifyResetPassword = false;
    });
    builder.addCase(verifyResetPasswordToken.rejected, (state, { payload }) => {
      state.error = payload;
      state.isVerifiedResetPassword = false;
      state.loadingVerifyResetPassword = false;
    });

    builder.addCase(getAllCentresOfUser.fulfilled, (state, { payload }) => {
      state.centresOfUser = payload;
    });
  },
});

export const { logoutSuccess, setCurrentCentreConfig, setCentreId } = actions;

export default reducer;

import styled from 'styled-components';

export const SummaryTabStyles = styled.div`
  .child-parent-info > div:not(:last-child) {
    margin-bottom: 20px;
  }
`;

export const ActivitiesStyles = styled.div`
  padding: 15px 30px;
  background: ${({ theme }) => theme.background.content};
  border-radius: 8px;
  .title-summary {
    font-weight: bold;
    font-size: 20px;
    margin-bottom: 30px;
  }

  .timeline-wrapper > .timeline-item-wrapper:not(:last-child) {
    margin-bottom: 15px;
  }

  .timeline-item-wrapper {
    .timeAt-timeline {
      color: ${({ theme }) => theme.text.mainL2};
      margin-bottom: 15px;
    }
  }
  .info-timeline {
    background: #ffffff;
    box-shadow: 0px 4px 20px #ededf6;
    border-radius: 12px;
    padding: 15px;
    .title-timeline {
      font-weight: bold;
      font-size: 16px;
      margin-bottom: 10px;
    }
  }
  .wrapper-icon {
    background: #ffffff;
    box-shadow: 0px 4px 20px #ededf6;
    padding: 12px;
    border-radius: 50%;
    font-size: 16px;
  }
  .ant-timeline-item-content {
    margin-left: 40px !important;
  }
  .ant-timeline-item-tail {
    border-left: 1px dashed #d3cfee !important;
  }
`;

export const InformationWrapper = styled.div`
  background: ${({ theme }) => theme.background.content};
  border-radius: 8px;
  .title-info {
    background: ${({ theme }) => theme.palette.primary};
    border-radius: 8px;
    font-weight: bold;
    font-size: 20px;
    padding: 10px 20px;
    color: #ffffff;
  }

  .content-info-wrapper {
    padding: 18px;
  }

  .content-info {
    padding: 18px;
    .info-item {
      color: ${({ theme }) => theme.text.primary};

      margin-bottom: 10px;
      .title-info-item {
        font-size: 16px;
        &:after {
          content: ':';
        }
      }
      .value-info-item {
        font-size: 16px;
        margin-left: 6px;
        font-weight: bold;
        word-break: break-word;
      }
      .yes-value {
        color: #fb8429;
      }
      .no-value {
        color: #36bf57;
      }
    }
  }
`;

export const InformationCardWrapper = styled.div`
  background: #ffffff;
  box-shadow: 0px 2px 10px rgba(39, 40, 51, 0.08);
  border-radius: 8px;

  margin-bottom: 20px;

  .title-card-info {
    font-weight: bold;
    font-size: 20px;
    padding: 10px 18px;
    color: ${({ theme }) => theme.palette.primary};
  }
  .ant-divider-horizontal {
    margin: 0 !important;
  }
`;

import React, { useEffect } from 'react';
import i18next from 'i18next';
import Button from 'antd/lib/button';
import { Col, Row, Form } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { RestInputContext } from 'components/RestInput/RestInputContext';
import { isEmpty } from 'lodash-es';
import { Responsive } from 'types';
import FilterCustomStyles from './styles';

interface Props {
  children: React.ReactNode;
  greyInput?: boolean;
  onSubmitFilter: (params: unknown) => void;
  onClearFilter: () => void;
  responsiveFilter?: Responsive;
  responsiveAction?: Responsive;
  initialValues?: unknown;
}

const FilterCustom = ({
  children,
  greyInput,
  onSubmitFilter,
  onClearFilter,
  responsiveFilter = {
    xxl: 20,
    xl: 18,
    lg: 18,
    md: 24,
    xs: 24,
  },
  responsiveAction = {
    xxl: 4,
    xl: 6,
    lg: 6,
    md: 24,
    xs: 24,
  },
  initialValues,
}: Props) => {
  const [form] = useForm();

  const onFilter = () => {
    form
      .validateFields()
      .then((values) => {
        onSubmitFilter(values);
      })
      .catch(() => {});
  };

  const onClear = () => {
    form.resetFields();
    onClearFilter();
  };

  useEffect(() => {
    if (!isEmpty(initialValues)) {
      form.setFieldsValue(initialValues);
    }
  }, [form, initialValues]);

  return (
    <FilterCustomStyles
      className={greyInput ? 'grey-input-filter' : 'default-input-filter'}
    >
      <Form form={form} autoComplete="off">
        <RestInputContext.Provider
          value={{
            form,
            allowPressEnter: true,
            handleSubmit: onFilter,
          }}
        >
          <Row gutter={16} className="row-filter">
            <Col {...responsiveFilter}>{children}</Col>

            <Col {...responsiveAction} className="row-action-bottom">
              <Button
                type="primary"
                className="filterButton fw-bold"
                onClick={onFilter}
              >
                {i18next.t('button.filter')}
              </Button>
              <Button className="clearButton fw-bold" onClick={onClear}>
                {i18next.t('button.clearFilter')}
              </Button>
            </Col>
          </Row>
        </RestInputContext.Provider>
      </Form>
    </FilterCustomStyles>
  );
};

export default FilterCustom;

import styled from 'styled-components';

export const RoleListTableStyle = styled.div`
  .link-class {
    &:hover,
    &:active {
      color: ${({ theme }) => theme.palette.primary} !important;
    }
  }
`;

import styled from 'styled-components';

const CentreInfoTabStyles = styled.div`
  .row-centre-info-tab {
    display: flex;
    align-items: stretch;
    flex-wrap: wrap;
  }
  .row-right-centre-info {
    margin-bottom: -12px !important;
  }
`;

export default CentreInfoTabStyles;

export const CentreDescriptionStyles = styled.div`
  line-height: 26px;
  font-size: 16px;
  word-break: break-word;
  white-space: pre-line;
  a {
    text-decoration: underline;
  }
`;

export const MoreInformationStyles = styled.div`
  line-height: 26px;
  font-size: 16px;
  word-break: break-word;
  iframe {
    border: none;
  }
  iframe,
  img {
    max-width: 100%;
  }
  img {
    object-fit: contain;
    height: auto;
  }
`;

export const AdditionalStyles = styled.div`
  .row-centre-information {
    font-size: 16px;
  }
  .col-value {
    font-weight: 600;
    line-height: 26px;
    word-break: break-word;
  }
`;

export const CustomiseButtonsStyles = styled.div`
  font-size: 16px;
  .customise-button-item + .customise-button-item {
    margin-top: 10px;
  }
  a {
    text-decoration: underline;
  }
`;

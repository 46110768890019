import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  visible: false,
  actionSearch: undefined,
};

const { actions, reducer } = createSlice({
  name: 'searchHeader',
  initialState,
  reducers: {
    setActionSearch: (state, { payload }) => {
      state.actionSearch = payload;
    },
    setVisible: (state, { payload }) => {
      state.visible = payload;
    },
  },
});

export const { setActionSearch, setVisible } = actions;
export default reducer;

import styled from 'styled-components';
import { Select } from 'antd';

export const InfoChildStyles = styled.div`
  padding: 15px;
  background: ${({ theme }) => theme.background.content};
  border-radius: 8px;
  .ant-divider-horizontal {
    margin: 10px 0 15px 0 !important;
  }
`;

export const ChildAvatarStyles = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-bottom: 15px;

  .avatar-wrapper {
    position: relative;
    margin-bottom: 12px;
    .gender-icon {
      position: absolute;
      padding: 5px;
      border-radius: 50%;
      bottom: 0;
      right: 0;
      font-size: 20px;
    }
  }
  .name-user {
    font-weight: bold;
    font-size: 18px;
  }
`;

export const BlockInfoStyles = styled.div`
  .row-info-application {
    font-size: 16px;
    & > .ant-col {
      margin-bottom: 15px;
    }
    .title-item-col {
      display: flex;
      > div {
        margin: auto 0;
        &:after {
          content: ':';
        }
      }
    }
    .value-item-col {
      font-weight: 600;
    }
  }
`;

export const ServicesInfoStyles = styled.div`
  .title-service {
    font-weight: bold;
    font-size: 18px;
    margin-bottom: 10px;
  }
  .service-item {
    margin-top: 15px;
    text-align: center;
    color: #316dde;
    background: rgba(50, 164, 252, 0.2);
    border-radius: 8px;
    padding: 4px 10px;
    font-weight: 600;
  }
  .age-group-service {
    font-size: 12px;
    color: ${({ theme }) => theme.text.secondary};
    text-align: center;
    margin-top: 5px;
  }
`;

export const ActionWrapper = styled.div`
  margin-top: 30px;
  button {
    width: 100%;
    font-weight: 500;
    color: ${({ theme }) => theme.palette.primary};
    border: 1px solid ${({ theme }) => theme.palette.primary};
  }
`;

export const DropDownStatusWrapper = styled(Select)`
  .span-option-status {
    padding: 5px 10px;
    border-radius: 8px;
    font-weight: 600;
  }
  .ant-select-arrow {
    color: ${({ theme }) => theme.palette.primary};
  }
  .ant-select-selector {
    border: 1px solid #f2f2f2 !important;
    box-sizing: border-box;
    border-radius: 8px;
  }
  &.ant-select-focused .ant-select-selector {
    border-color: ${({ theme }) => theme.palette.primary} !important;
  }
`;

export const ArchiveInfo = styled.div`
  display: inline-flex;
  text-align: center;
  padding: 2px 8px;
  border-radius: 8px;
  background: #eef2fa;
  color: #174364;
  font-size: 14px;
  font-weight: 700;
  line-height: 24px;
`;

import React from 'react';
import { Route } from 'react-router-dom';
import EULAPage from 'pages/EULA';
import Login from '../../pages/Login';
import ClaimCentres from '../../pages/ClaimCentres';
import LoginClaimCentre from '../../pages/LoginClaimCentre';
import ResetPassword from '../../pages/ResetPassword';
import ForgotPassword from '../../pages/ForgotPassword';
import Invite from '../../pages/Invite';

export const PUBLIC_ROUTES = [
  {
    path: '/login',
    component: Login,
    exact: true,
  },
  {
    path: '/invite',
    component: Invite,
    exact: true,
  },
  {
    path: '/claim-centre-register',
    component: ClaimCentres,
    exact: true,
  },
  {
    path: '/forgot-password',
    component: ForgotPassword,
    exact: true,
  },
  {
    path: '/resetPassword',
    component: ResetPassword,
    exact: true,
  },
  {
    path: '/claim-centres',
    component: ClaimCentres,
    exact: true,
  },
  {
    path: '/claim-centres/:id',
    component: ClaimCentres,
    exact: true,
  },
  {
    path: '/claim-centre-login',
    component: LoginClaimCentre,
    exact: true,
  },
  {
    path: '/EULA',
    component: EULAPage,
    exact: true,
  },
];

const publicRoutes = () =>
  PUBLIC_ROUTES.map((route) => <Route {...route} key={route.path} />);

export default publicRoutes;

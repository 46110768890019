import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'antd';
import ViewModeSwitcher from 'components/common/ViewModeSwitcher';
import ApplicationFilterCustom from '../components/FilterOther';
import { HeaderFilterStyles } from './styles';
import { TABS_MODE_APPLICATION } from '.';
import { useApplicationList } from './context';

const Header = ({ activeTab, history }) => {
  const { countApplicationPerTab } = useApplicationList();

  const onChange = (e) => {
    history.push(`/applications/${e.target.value}`);
  };

  return (
    <HeaderFilterStyles>
      <Row gutter={16}>
        <Col span={24}>
          <ViewModeSwitcher
            viewModes={TABS_MODE_APPLICATION}
            onChange={onChange}
            activeTab={activeTab}
            countPerTab={countApplicationPerTab}
          />
        </Col>
        <Col span={24}>
          <ApplicationFilterCustom />
        </Col>
      </Row>
    </HeaderFilterStyles>
  );
};

Header.propTypes = {
  activeTab: PropTypes.string,
  history: PropTypes.object,
};

export default Header;

import React from 'react';
import { Image } from 'antd';
import { Brand } from 'types/brand';

interface Props {
  brand: Brand;
}

const BrandItem = ({ brand }: Props) => {
  const onOpenCentreBrand = () => {
    window.open(
      `${process.env.REACT_APP_RW_URL}/featured-centres?brandId=${brand?.id}`,
    );
  };

  return (
    <div className="pb-24 px-6 xs:p-0 cursor-pointer">
      <div
        className="rounded-full shadow-sm mr-12 brand-logo"
        role="presentation"
        onClick={onOpenCentreBrand}
      >
        <Image src={brand.logo || 'error'} preview={false} />
      </div>
    </div>
  );
};

export default BrandItem;

import PageTitle from 'components/common/PageTitle';
import React, { useCallback, useEffect } from 'react';
import i18next from 'i18next';
import { Spin } from 'antd';
import { getAllBrandBenchmarking } from 'redux/brandBenchmarking/actions';
import { getOwnedCompaniesOfAllUserRoles } from 'redux/companiesOfUser/actions';
import EmptyData from 'components/common/EmptyData';
import { isEmpty } from 'lodash-es';
import { useLocation } from 'react-router';
import { getFilterFromUrl } from 'utils/tools';
import { useDispatch, useSelector } from 'react-redux';
import { DEFAULT_ORDER_BY } from 'containers/CompanyBenchmarking/localData/constants';
import {
  getBrandBenchmarkingCompareRatingSelector,
  brandBenchmarkingSelectors,
  getDataBrandBenchmarking,
  getBrandBenchmarkingSummary,
} from 'redux/brandBenchmarking/selectors';
import { ownedCompaniesOfAllUserRolesSelector } from 'redux/companiesOfUser/selectors';
import Summaries from 'containers/BrandBenchmarking/components/Summaries';
import UserBrandBenchmarkingChart from './components/UserBrandBenchmarkingChart';
import BrandBenchmarkingTable from './components/BrandBenchmarkingTable';
import Filter from './components/Filter';
import BrandCompareRatingChart from './components/BrandCompareRatingChart';

const API_RESOURCE = 'insights/brand-benchmarking';

const BrandBenchmarkingContainer = () => {
  const dispatch = useDispatch();
  const { search } = useLocation();
  const { filter } = getFilterFromUrl(search);

  const { loading: ratingChartLoading } = useSelector(
    getBrandBenchmarkingCompareRatingSelector,
  );
  const ownedCompanies = useSelector(ownedCompaniesOfAllUserRolesSelector);

  const selectedBrandName = localStorage.getItem('selectedBrandName');

  const selectedState = filter.state;
  const selectedCompanyName = ownedCompanies.find(
    (company) => company?.id === filter.companyId,
  )?.name;

  const loading = useSelector(brandBenchmarkingSelectors.getLoading);

  const data = useSelector(getDataBrandBenchmarking);

  const { loading: isSummaryLoading } = useSelector(
    getBrandBenchmarkingSummary,
  );

  const getBrandBenchmarkingData = useCallback(
    ({ filter = {}, isRefresh, isSort }) =>
      dispatch(
        getAllBrandBenchmarking({
          data: {
            orderBy: DEFAULT_ORDER_BY,
            ...filter,
          },
          options: {
            isRefresh,
            isSort,
            customApiResource: API_RESOURCE,
          },
        }),
      ),
    [dispatch],
  );

  const fetchOwnedCompaniesOfAllUserRoles = useCallback(
    () =>
      dispatch(
        getOwnedCompaniesOfAllUserRoles({
          orderBy: DEFAULT_ORDER_BY,
          limit: 20,
        }),
      ),
    [dispatch],
  );

  useEffect(() => {
    fetchOwnedCompaniesOfAllUserRoles({});
  }, [fetchOwnedCompaniesOfAllUserRoles]);

  return (
    <div>
      <PageTitle>
        {`${i18next.t('sideBar.insights.title')} - ${i18next.t(
          'sideBar.insights.brandBenchmarking',
        )}`}
      </PageTitle>
      <Filter getBrandBenchmarkingData={getBrandBenchmarkingData} />
      {!isEmpty(filter) ? (
        <>
          <Spin spinning={ratingChartLoading}>
            <BrandCompareRatingChart
              selectedCompanyName={selectedCompanyName}
              selectedBrandName={selectedBrandName}
              selectedState={selectedState}
            />
          </Spin>
          <UserBrandBenchmarkingChart
            selectedBrandName={selectedBrandName}
            selectedCompanyName={selectedCompanyName}
          />
          <Spin spinning={isSummaryLoading}>
            <Summaries
              ownedCompanies={ownedCompanies}
              selectedBrandName={selectedBrandName}
            />
          </Spin>
          <Spin spinning={loading && isEmpty(data)}>
            <BrandBenchmarkingTable
              retrieveList={getBrandBenchmarkingData}
              selectBrandId={filter?.brandId}
            />
          </Spin>
        </>
      ) : (
        <EmptyData className="mt-20" />
      )}
    </div>
  );
};

export default BrandBenchmarkingContainer;

import styled from 'styled-components';

const CreateEditStyles = styled.div`
  .create-user-title,
  .edit-user-title,
  .add-more-title {
    font-weight: bold;
    font-size: 20px;
    line-height: 25px;
    margin-bottom: 15px;
  }
  .content-create-wrapper,
  .content-edit-wrapper,
  .add-more-content {
    max-width: 1060px;
    padding: 48px 100px;
    background-color: ${({ theme }) => theme.background.content};
    border-radius: 8px;
    margin: auto;
  }
  @media only screen and (max-width: 678px) {
    padding: 20px;
  }
`;

export default CreateEditStyles;

export const FormAddMoreStyles = styled.div`
  .group-action-footer {
    margin-top: 20px;
    .ant-btn {
      min-width: 120px;
    }
  }
`;

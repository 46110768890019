import React from 'react';
import { Avatar, Typography } from 'antd';
import { CentreMetricsItem } from 'containers/MarketingMetrics/components/CentreMetricsTable/style';

const { Text } = Typography;

interface CentreMetricsInfoProps {
  id: string;
  name: string;
  image: string;
}

const CentreMetricsInfo = ({ id, name, image }: CentreMetricsInfoProps) => {
  const handleViewCentreDetails = () => {
    window.open(`/centres/${id}/show/summary`);
  };

  return (
    <CentreMetricsItem
      onClick={handleViewCentreDetails}
      role="presentation"
      className={'flex-row flex-center-start gap-8 cursor-pointer'}
    >
      <Avatar size={45} src={image} className={'min-w-45'} />
      <Text className={'text-14 fw-bold'}>{name}</Text>
    </CentreMetricsItem>
  );
};

export default CentreMetricsInfo;

import styled from 'styled-components';
import { Table } from 'antd';

const TableWrapper = styled(Table)`
  &.table-wrapper {
    padding: 12px;
    background: ${({ theme }) => theme.background.content};
    border-radius: 8px;
  }

  .ant-table-thead > tr:first-child > th {
    background: ${({ theme }) => theme.table.headerBackground};
    font-weight: bold;
    &:hover {
      background: ${({ theme }) => theme.table.headerBackground};
    }
  }
  .ant-table-scroll .ant-table-body {
    overflow-x: auto !important;
  }
  .ant-table-column-sorters {
    .ant-table-column-sorter {
      display: ${({ isSorter }) => (isSorter ? 'none' : 'initial')};
    }
    .icon-sorter {
      font-size: 20px;
    }
  }
  .ant-dropdown-trigger .anticon {
    font-size: 14px;
  }
  .ant-table-container .ant-table-sticky-scroll {
    display: none;
  }
  @media only screen and (min-width: 1600px) {
    .ant-table-body {
      max-height: 700px !important;
    }
  }
`;

export default TableWrapper;

import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { unownedCompaniesOfUserSelectors } from 'redux/unownedCompaniesOfUser/selectors';
import ListCheckboxSelect from '../Form/ListCheckboxSelect';

const CompaniesAddMoreSelect = ({ retrieveList }) => {
  const data = useSelector(unownedCompaniesOfUserSelectors.getDataArr);
  const paging = useSelector(unownedCompaniesOfUserSelectors.getResourceData);
  const loading = useSelector(unownedCompaniesOfUserSelectors.getLoading);

  return (
    <ListCheckboxSelect
      retrieveList={retrieveList}
      name="companyIds"
      data={data}
      paging={paging}
      loading={loading}
      selectedResourceTitles="users.selectedCompaniesTotal"
      selectedResourceTitle="users.selectedCompanyTotal"
      messageRequired="users.selectCompanyRequired"
    />
  );
};

CompaniesAddMoreSelect.propTypes = {
  retrieveList: PropTypes.func,
};

export default CompaniesAddMoreSelect;

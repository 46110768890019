import React from 'react';
import PropTypes from 'prop-types';
import { CrownFilled } from '@ant-design/icons';

import { upgradeModal } from 'components/common/CheckPermissions/UpgradeSubscriptionModal';
import DropdownStatus from './DropdownStatus';

const DropdownFallback = ({ status }) => {
  const handleClick = () => {
    upgradeModal.open();
  };

  return (
    <div
      className="select-status-fallback-wrapper"
      onClick={handleClick}
      role="presentation"
    >
      <DropdownStatus status={status} />
      <CrownFilled className="action-feature-icon" />
    </div>
  );
};

DropdownFallback.propTypes = {
  status: PropTypes.string,
};

export default DropdownFallback;

import { createAsyncThunk } from '@reduxjs/toolkit';
import { apiWrapper } from 'utils/reduxUtils';
import { getPermissionsApi } from 'api/roles';
import { makeActions } from 'redux/crudCreator/actions';

export const MODEL_NAME = 'roles';
export const rolesActions = makeActions(MODEL_NAME);

export const getAllRoles = rolesActions.getAll;
export const editRoles = rolesActions.edit;
export const createRoles = rolesActions.create;
export const getByIdRoles = rolesActions.getDataById;

export const getPermissions = createAsyncThunk(
  'roles/getPermissions',
  async (payload, thunkAPI) => {
    try {
      const response = await apiWrapper(
        { isShowProgress: false, isShowSuccessNoti: false },
        getPermissionsApi,
      );
      return response.results;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  },
);

import {
  getAllCustomFail,
  getAllCustomPending,
  INITIAL_STATE_CUSTOM,
} from 'redux/crudCreatorCustom/utils';
import { createSlice } from '@reduxjs/toolkit';
import { uniqBy, uniq } from 'lodash-es';
import {
  getAllBrandBenchmarking,
  getBrandBenchmarkingCompareRating,
  getUserBrandBenchmarkingChart,
  getBrandBenchmarkingSummary,
} from './actions';

const initialState = {
  ...INITIAL_STATE_CUSTOM,
  compareRatingChart: {},
  userBrandBenchmarkingChart: {},
  summary: {
    loading: false,
  },
};

const getBrandBenchmarkingSuccess = (state, { payload: { data, options } }) => {
  state.loading = false;
  if (options.isSort) {
    state.data = [];
    state.ids = [];
  }
  state.ids = uniq([...(state.ids || []), ...data.ids]);
  state.data = uniqBy([...state?.data, ...Object.values(data?.data)], 'id');

  state.resourceData.total = data?.total;
  state.resourceData.numberOfPages = data?.numberOfPages;
  state.averageAllBrands = data?.additionData?.averageAllBrands;
};

const getCompareRatingPending = (state) => {
  state.compareRatingChart.loading = true;
};

const getCompareRatingSuccess = (state, { payload: { data } }) => {
  const convertedData = {
    ...data,
    charts: data?.charts?.map((item) => ({
      ...item,
      allTotal: item?.allBrandsTotal,
      userTotal: item?.userBrandsTotal,
    })),
    userRatingAvg: data?.userBrandsRatingAvg,
    allRatingAvg: data?.allBrandsRatingAvg,
  };

  state.compareRatingChart = convertedData;
  state.compareRatingChart.loading = false;
};

const getCompareRatingFail = (state) => {
  state.compareRatingChart.loading = false;
};

const getUserBrandBenchmarkingChartPending = (state) => {
  state.userBrandBenchmarkingChart.loading = true;
};

const getUserBrandBenchmarkingChartSuccess = (state, { payload: { data } }) => {
  state.userBrandBenchmarkingChart = data;
  state.userBrandBenchmarkingChart.loading = false;
};

const getUserBrandBenchmarkingChartFail = (state) => {
  state.userBrandBenchmarkingChart.loading = false;
};

const getBrandBenchmarkingSummarySuccess = (state, { payload: { data } }) => {
  state.summary = data;
  state.summary.loading = false;
};

const getBrandBenchmarkingSummaryFail = (state) => {
  state.summary.loading = false;
};

const getBrandBenchmarkingSummaryPending = (state) => {
  state.summary.loading = true;
};

const { reducer, actions } = createSlice({
  name: 'brandBenchmarking',
  initialState,
  reducers: {
    resetResourceFilterBrandBenchMarking: (state) => {
      state.ids = [];
      state.data = [];
      state.total = 0;
      state.resourceData.outsideFilter = {};
      state.userBrandBenchmarkingChart = {};
      state.averageAllBrands = {};
    },
  },
  extraReducers: {
    [getAllBrandBenchmarking.pending]: getAllCustomPending,
    [getAllBrandBenchmarking.fulfilled]: getBrandBenchmarkingSuccess,
    [getAllBrandBenchmarking.rejected]: getAllCustomFail,

    [getBrandBenchmarkingCompareRating.pending]: getCompareRatingPending,
    [getBrandBenchmarkingCompareRating.fulfilled]: getCompareRatingSuccess,
    [getBrandBenchmarkingCompareRating.rejected]: getCompareRatingFail,

    [getUserBrandBenchmarkingChart.pending]:
      getUserBrandBenchmarkingChartPending,
    [getUserBrandBenchmarkingChart.fulfilled]:
      getUserBrandBenchmarkingChartSuccess,
    [getUserBrandBenchmarkingChart.rejected]: getUserBrandBenchmarkingChartFail,

    [getBrandBenchmarkingSummary.pending]: getBrandBenchmarkingSummaryPending,
    [getBrandBenchmarkingSummary.fulfilled]: getBrandBenchmarkingSummarySuccess,
    [getBrandBenchmarkingSummary.rejected]: getBrandBenchmarkingSummaryFail,
  },
});

export const { resetResourceFilterBrandBenchMarking } = actions;

export default reducer;

import React, { useMemo } from 'react';
import i18next from 'i18next';
import { useHistory } from 'react-router';
import { useSelector } from 'react-redux';
import { contactsSelectors } from 'redux/contacts/selectors';
import { formatDateTimeTable } from 'utils/textUtils';
import { convertSearchUrl } from 'utils/tools';
import TableCustom from 'components/common/TableCustom';
import GroupActions from './GroupActions';
import CustomerField from '../CustomerField';
import Children from './Children';
import TableStyles from './styles';

const TableView = () => {
  const { push } = useHistory();

  const resourceFilter = useSelector(contactsSelectors.getFilters) || {};

  const loading = useSelector((state) => state.contacts.loading);

  const resourceData = useSelector(contactsSelectors.getDataArr);

  const columns = useMemo(
    () => [
      {
        title: i18next.t('contacts.parentGuardianName'),
        dataIndex: 'fullName',
        width: 300,
        render: (text, record) => <CustomerField {...record} />,
      },
      {
        title: i18next.t('contacts.email'),
        dataIndex: 'email',
      },
      {
        title: i18next.t('contacts.phone'),
        dataIndex: 'phone',
      },
      {
        title: i18next.t('contacts.address'),
        dataIndex: 'address',
      },
      {
        title: i18next.t('children.title'),
        dataIndex: 'children',
        render: (data) => <Children data={data} />,
      },
      {
        title: i18next.t('common.lastActiveAt'),
        dataIndex: 'lastActiveAt',
        render: formatDateTimeTable,
      },
      {
        title: i18next.t('contacts.createAt'),
        dataIndex: 'createdAt',
        render: formatDateTimeTable,
      },
      {
        title: '',
        dataIndex: 'id',
        width: 80,
        fixed: 'right',
        render: (id, record) => (
          <GroupActions id={id} customerType={record?.customerType} />
        ),
      },
    ],
    [],
  );

  const onChange = (e, filters, sorter) => {
    const formatSort =
      sorter && sorter.field && sorter.order
        ? `${sorter.order === 'descend' ? '-' : ''}${sorter.field}`
        : null;

    const restFilter = {
      offset: (e.current - 1) * e.pageSize,
      limit: e.pageSize,
      orderBy: formatSort,
      outsideFilter: resourceFilter.outsideFilter,
      filter: resourceFilter.filter,
    };

    push({ search: `?${convertSearchUrl(restFilter)}` });
  };

  return (
    <TableStyles>
      <TableCustom
        xScroll={1200}
        isSorter
        onChange={onChange}
        data={resourceData}
        columns={columns}
        loading={loading}
        pagination={{
          pageSize: resourceFilter.limit,
          current: resourceFilter.offset / resourceFilter.limit + 1,
          total: resourceFilter.count,
        }}
      />
    </TableStyles>
  );
};

export default TableView;

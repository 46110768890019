import React from 'react';
import { Button, DatePicker, Form, FormInstance, Input } from 'antd';
import ModalCustom from 'components/common/ModalCustom';
import i18next from 'i18next';
import { isNumber } from 'lodash-es';

interface Props {
  formModalStep4: FormInstance;
  visible: boolean;
  title: string;
  isDisabled?: boolean;
  setVisible: (param: boolean) => void;
  editIndexCurrent: number | null;
  onSave: () => void;
  onSaveAddAnother?: () => void;
}

interface IAwardFormList {
  name: string;
  label?: string;
  children: React.ReactNode;
  rules?: Array<{
    required?: boolean;
    message?: string;
    max?: number;
    min?: number;
  }>;
}

const AwardFromModal = ({
  formModalStep4,
  visible,
  title,
  isDisabled,
  editIndexCurrent,
  setVisible,
  onSave,
  onSaveAddAnother,
}: Props) => {
  const AwardFormList: IAwardFormList[] = [
    {
      name: 'name',
      label: i18next.t('educatorStaffProfile.award'),
      children: (
        <Input
          autoComplete="off"
          placeholder={i18next.t('educatorStaffProfile.enterAward')}
        />
      ),
      rules: [
        {
          max: 250,
          message: i18next.t('error.maxLength', { max: 250 }),
        },
        {
          required: true,
          message: i18next.t('error.required'),
        },
      ],
    },
    {
      name: 'company',
      label: i18next.t('educatorStaffProfile.company'),
      children: (
        <Input
          autoComplete="off"
          placeholder={i18next.t('educatorStaffProfile.enterCompany')}
        />
      ),
      rules: [
        {
          required: true,
          message: i18next.t('error.required'),
        },
        {
          max: 250,
          message: i18next.t('error.maxLength', { max: 250 }),
        },
      ],
    },
    {
      name: 'year',
      label: i18next.t('educatorStaffProfile.year'),
      children: <DatePicker picker="year" className="w-full" />,
      rules: [
        {
          required: true,
          message: i18next.t('error.required'),
        },
      ],
    },
  ];

  const onCancel = () => {
    setVisible(false);
  };

  return (
    <ModalCustom
      visible={visible}
      title={i18next.t(title)}
      onCancel={onCancel}
      width={600}
      isDisabled={isDisabled}
      footer={
        <>
          {isNumber(editIndexCurrent) ? (
            <div className="flex justify-end">
              <Button type="primary" ghost onClick={onCancel} className="mr-12">
                {i18next.t('button.cancel')}
              </Button>
              <div>
                <Button type="primary" onClick={onSave}>
                  {i18next.t('button.save')}
                </Button>
              </div>
            </div>
          ) : (
            <div className="flex justify-between	">
              <Button type="primary" ghost onClick={onCancel}>
                {i18next.t('button.cancel')}
              </Button>
              <div>
                <Button type="primary" ghost onClick={onSave}>
                  {i18next.t('button.save')}
                </Button>

                <Button type="primary" onClick={onSaveAddAnother}>
                  {i18next.t('button.saveAddAnother')}
                </Button>
              </div>
            </div>
          )}
        </>
      }
    >
      <Form form={formModalStep4} layout="vertical">
        {AwardFormList.map(({ label = '', name, children, rules }) => (
          <Form.Item name={name} label={label} rules={rules}>
            {children}
          </Form.Item>
        ))}
      </Form>
    </ModalCustom>
  );
};

export default AwardFromModal;

import React from 'react';
import { Form, Input } from 'antd';
import PropTypes from 'prop-types';
import i18next from 'i18next';

const JobForm = ({ field }) => {
  const { name } = field || { name: 0 };

  return (
    <Form.Item
      name={name}
      rules={[
        { required: true, message: i18next.t('error.required') },
        {
          max: 150,
          message: i18next.t('jobs.moreAboutTheJob.invalidLengthInput'),
        },
      ]}
    >
      <Input.TextArea placeholder={i18next.t('input.enterHere')} autoSize />
    </Form.Item>
  );
};

JobForm.propTypes = {
  field: PropTypes.object,
};

export default JobForm;

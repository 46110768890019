import React from 'react';
import i18next from 'i18next';
import { Link } from 'react-router-dom';
import { Button } from 'antd';
import PropTypes from 'prop-types';
import { CheckCircleOutlined } from '@ant-design/icons';
import Text from 'components/common/Text';
import AuthContact from 'components/common/AuthContact';
import { replaceAt } from 'utils/textUtils';
import { SIGN_MASK_EMAIL } from 'configs/localData/constant';
import ForgotPasswordStyleWrapper from './styles';

const ForgotPasswordSuccess = ({ email }) => {
  const maskedEmail =
    replaceAt(email, 1, SIGN_MASK_EMAIL) || i18next.t('auth.yourEmail');

  return (
    <ForgotPasswordStyleWrapper className="isoSignInPage">
      <div className="isoLoginContentWrapper">
        <div className="isoLoginContent">
          <div className="success-icon-div">
            <CheckCircleOutlined />
          </div>
          <Text type="h3" align="center">
            {i18next.t('forgotPassword.success.title')}
          </Text>
          <Text align="center" className="txtDescription">
            {i18next.t('forgotPassword.success.description', {
              email: maskedEmail,
            })}
          </Text>
          <br />
          <Button type="primary">
            <Link to="/login">{i18next.t('login.loginBtn')}</Link>
          </Button>
          <AuthContact
            className="mt-20"
            text="auth.contactText.l1ResetPassword"
          />
        </div>
      </div>
    </ForgotPasswordStyleWrapper>
  );
};

ForgotPasswordSuccess.propTypes = {
  email: PropTypes.string,
};

export default ForgotPasswordSuccess;

import styled from 'styled-components';

const FormServiceStyles = styled.div`
  .div-button-group {
    margin-top: 50px;
  }
  .ant-input-number {
    width: 100%;
  }
`;

export default FormServiceStyles;

import React, { createContext, useContext, useState } from 'react';
import PropTypes from 'prop-types';

const initialValue = {
  visible: false,
  setVisible: () => {},
  file: {},
};

const ViewFileModalContext = createContext(initialValue);

export const ViewFileModalProvider = ({ children }) => {
  const [visible, setVisible] = useState(false);
  const [file, setFile] = useState({});

  const value = {
    visible,
    file,
    setVisible,
    setFile,
  };
  return (
    <ViewFileModalContext.Provider value={value}>
      {children}
    </ViewFileModalContext.Provider>
  );
};

ViewFileModalProvider.propTypes = {
  children: PropTypes.node,
};

export const useViewFileModal = () => {
  const { visible, setVisible, file, setFile } =
    useContext(ViewFileModalContext);

  const toggleModal = () => {
    setVisible(!visible);
  };

  return {
    visible,
    toggleModal,
    file,
    setFile,
  };
};

import { isEmpty, isPlainObject } from 'lodash-es';
import { UploadFileCustom } from 'components/RestInput/RestUploadFile/index';

const makeObjFile = (value) => ({
  uid: value,
  name: value,
  status: 'done',
  url: value,
  id: value,
});

export const makeFileList = (values) => {
  if (isEmpty(values)) return [];

  if (Array.isArray(values))
    return values.map((value, idx) =>
      value && value.url
        ? { uid: String(idx), ...value, status: 'done' }
        : makeObjFile(value),
    );

  if (isPlainObject(values))
    return [
      {
        ...makeObjFile(values.url),
        ...values,
      },
    ];

  return [makeObjFile(values)];
};

export const formatNewFileListWithFeature = (fileList, indexFeatured) => {
  const data = fileList?.map((file, idx) => {
    if (indexFeatured === idx) return { ...file, featured: true };
    return { ...file, featured: false };
  });

  const fileListFormValue = data?.map((file) => ({
    key: file.name || file.key,
    url: file.response || file.url,
    featured: file.featured,
    type: file.type,
    source: file.source,
  }));

  return { fileList: data, fileListFormValue };
};

const getuid = () => new Date().getTime().toString();

export const formatUploadedFile = (file, url) => ({
  key: file.name || file.key,
  url,
  type: file.type,
  source: file.source,
  status: file.status || 'done',
  featured: file.featured,
});

export const formatYoutubeFile = (url: string): UploadFileCustom => {
  const uid = getuid();
  return {
    uid,
    name: `youtube-${uid}`,
    url,
    type: 'video',
    source: 'youtube',
    status: 'done',
  };
};

export const formatNewFileListWithAttachVideoUrl = (fileList, url) => {
  // format file list add property 'type', 'source'
  const data = [
    ...fileList,
    { uid: getuid(), url, type: 'video', source: 'youtube', status: 'done' },
  ];

  // form item value
  const fileListFormValue = data?.map((file) => ({
    key: file.name || file.key,
    url: file.response || file.url,
    featured: file.featured,
    type: file.type,
    source: file.source,
  }));

  return { fileList: data, fileListFormValue };
};

export const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

import React from 'react';
import { Row, Col } from 'antd';
import PieChartCustom from 'components/charts/PieChartCustom';
import { useSelector } from 'react-redux';
import i18next from 'i18next';
import { isEmpty } from 'lodash-es';
import { getCompanyBenchmarkingKindicareRating } from 'redux/companyBenchmarking/selectors';
import PropTypes from 'prop-types';
import RatingTypesSection from './RatingTypesSection';
import { PieChartRatingStyles } from './styles';
import CardCustom from '../CardCustom';

const KindicareRatingPieChart = ({ userCompanySelectedName }) => {
  const resultChart = useSelector(getCompanyBenchmarkingKindicareRating);

  const resultKindicareRating = resultChart.map((item) => ({
    name: item?.name,
    percent: Number(item?.value),
    color: item?.color,
  }));
  return (
    !isEmpty(resultChart) && (
      <CardCustom
        title={`${i18next.t(
          'companyBenchmarking.ratingTypes.kindiCareRating',
        )} - ${userCompanySelectedName ?? ''}`}
      >
        <PieChartRatingStyles>
          <Row align="middle" className="kindicare-rating-style">
            <Col xxl={10} xl={24} sm={12} xs={24}>
              <PieChartCustom
                data={resultKindicareRating}
                innerRadius={0}
                paddingAngle={0}
                isShowLabel={false}
                isShowLegend={false}
                isCircleLegend={false}
                cy="50%"
                cx="50%"
                height={200}
              />
            </Col>
            <Col xxl={14} xl={24} sm={12} xs={24} className="p-0">
              <RatingTypesSection data={resultKindicareRating} />
            </Col>
          </Row>
        </PieChartRatingStyles>
      </CardCustom>
    )
  );
};

KindicareRatingPieChart.propTypes = {
  userCompanySelectedName: PropTypes.string,
};

export default KindicareRatingPieChart;

import React from 'react';
import { Button, Typography } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

const { Text } = Typography;

interface Props {
  icon: JSX.Element;
  title: string;
  onAdd: () => void;
  children: React.ReactNode;
  headerIcon?: JSX.Element;
  disabled?: boolean;
}

const ProfileDetailSection = ({
  icon,
  title,
  onAdd,
  children,
  headerIcon,
  disabled = false,
}: Props) => (
  <div className="flex flex-col bg-white rounded-16 shadow">
    <div className="flex-center-between border-b-solid-gray-550 px-24 py-16 ">
      <div className="flex-center-start gap-10">
        {icon}
        <Text className="fw-bold text-18">{title}</Text>
      </div>
      <Button
        className="add-button"
        type="primary"
        shape="circle"
        icon={headerIcon ?? <PlusOutlined />}
        onClick={onAdd}
        size="small"
        disabled={disabled}
      />
    </div>
    <div className="p-24">{children}</div>
  </div>
);

export default ProfileDetailSection;

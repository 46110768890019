import React from 'react';

import PropTypes from 'prop-types';
import i18next from 'i18next';
import KindiRatingStyles from './styles';

const KindiRating = ({
  kindiCareRatingImage,
  kindiCareRating,
  className,
  size = 'medium',
}) => {
  const formatValue = Number(kindiCareRating)
    ? Number(kindiCareRating).toFixed(1)
    : i18next.t('priceBenchmarking.kindiCareRatingNR');

  return (
    <KindiRatingStyles
      className={`${className || ''} kindicare-rating-${size}`}
      isRatingImage={!!kindiCareRatingImage}
      size={size}
    >
      {kindiCareRatingImage ? (
        <img
          className="centre-item__rating-img"
          alt="kindicare"
          src={kindiCareRatingImage}
        />
      ) : (
        formatValue
      )}
    </KindiRatingStyles>
  );
};

KindiRating.propTypes = {
  kindiCareRating: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  kindiCareRatingImage: PropTypes.string,
  className: PropTypes.string,
  size: PropTypes.string,
};

export default KindiRating;

import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import i18next from 'i18next';
import { Avatar, Tag } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import {
  ENQUIRIES_STATUS_CONST,
  ENQUIRY_TYPES,
  REASON_ARCHIVE_ENQUIRY,
} from 'configs/localData';
import TagCustom from 'components/common/TagCustom';
import CheckPermission from 'components/common/CheckPermissions';
import { useSelector } from 'react-redux';
import { getCentresObjKeyById } from 'redux/auth/selectors';
import HeaderUserInfoEnquiryFallback from 'components/RestActions/HeaderUserInfoEnquiry';
import { useHistory } from 'react-router';
import { MessageHeaderStyles } from './styles';
import ActionButton from './ActionButton';
import CentreNameInfo from './CentreNameInfo';

const MessageHeader = ({
  user,
  enquiryType,
  id,
  centreId,
  isShowArchive,
  openArchiveModal,
  archivedReason,
  getSummaries,
}) => {
  const history = useHistory();

  const enquiryTypeItem = useMemo(
    () =>
      ENQUIRY_TYPES.find((item) => item.value === enquiryType?.name) ||
      ENQUIRY_TYPES[0],
    [enquiryType],
  );

  const archivedReasonName = useMemo(() => {
    const resTex = REASON_ARCHIVE_ENQUIRY.find(
      (item) => item.value === archivedReason,
    )?.text;

    return resTex ? ` - ${i18next.t(resTex)}` : '';
  }, [archivedReason]);

  const packageName =
    useSelector(getCentresObjKeyById)?.[centreId]?.centreSubscription
      ?.CRMPackage?.name;

  return (
    <MessageHeaderStyles>
      <div>
        <CheckPermission
          packageName={packageName}
          permissionKey="contacts"
          action="isEdit"
          isFollowPackageName
          isDisabled
          fallback={
            <HeaderUserInfoEnquiryFallback
              user={user}
              enquiryTypeItem={enquiryTypeItem}
            />
          }
        >
          <div
            className="left-section"
            role="presentation"
            onClick={() =>
              user?.contactId &&
              history.push(`/contacts/list/${user.contactId}/show`)
            }
          >
            <Avatar size={40} src={user?.avatar} icon={<UserOutlined />} />
            <div className="info-section">
              <div className="title">
                {`${user?.firstName || ''} ${user?.lastName || ''}`}
              </div>
              <div className="status">
                {enquiryTypeItem && (
                  <Tag color={enquiryTypeItem.color}>
                    {i18next.t(enquiryTypeItem.text)}
                  </Tag>
                )}
              </div>
            </div>
          </div>
        </CheckPermission>
      </div>
      <div className="center-section overflow-hidden">
        {!isShowArchive && (
          <TagCustom
            name={`${i18next.t(
              ENQUIRIES_STATUS_CONST.archived.text,
            )}${archivedReasonName}`}
            color={ENQUIRIES_STATUS_CONST.archived.fillColor}
            backgroundColor={ENQUIRIES_STATUS_CONST.archived.backgroundColor}
          />
        )}
      </div>
      <div className="right-section">
        <CentreNameInfo centreId={centreId} />

        <ActionButton
          id={id}
          isShowArchive={isShowArchive}
          openArchiveModal={openArchiveModal}
          getSummaries={getSummaries}
        />
      </div>
    </MessageHeaderStyles>
  );
};

MessageHeader.propTypes = {
  user: PropTypes.object,
  enquiryType: PropTypes.object,
  id: PropTypes.string,
  centreId: PropTypes.string,
  openArchiveModal: PropTypes.func,
  isShowArchive: PropTypes.bool,
  archivedReason: PropTypes.string,
  getSummaries: PropTypes.func,
};

export default MessageHeader;

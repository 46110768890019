import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col } from 'antd';
import {
  getApplicationsDashboard,
  getCentresDashboard,
  getEnquiriesDashboard,
  getActivitiesDashboard,
} from 'redux/dashboard/actions';
import { getCentreId } from 'redux/auth/selectors';

import SummaryApplication from './SummaryApplication';
import SummaryActivities from './SummaryActivities';
import SummaryCentres from './SummaryCentres';
import EnrolmentPipeline from '../EnrolmentPipeline';
import EnquiriesSummary from '../EnquiriesSummary';
import CentresPerformance from '../CentresPerformance';

const SummaryRow = () => {
  const dispatch = useDispatch();
  const centreId = useSelector(getCentreId);

  useEffect(() => {
    dispatch(getApplicationsDashboard());
    dispatch(getActivitiesDashboard());
    dispatch(getCentresDashboard());
    dispatch(getEnquiriesDashboard());
  }, [centreId, dispatch]);

  return (
    <Row gutter={[24, 24]}>
      <Col xl={8} lg={12} md={12} xs={24}>
        <SummaryApplication />
      </Col>
      <Col xl={8} lg={12} md={12} xs={24}>
        <SummaryActivities />
      </Col>
      <Col xl={8} lg={24} md={24} xs={24}>
        <SummaryCentres />
      </Col>
      <Col xl={16} lg={12} md={12} xs={24}>
        <EnrolmentPipeline />
      </Col>
      <Col xl={8} lg={12} md={12} xs={24}>
        <EnquiriesSummary />
      </Col>
      <Col span={24}>
        <CentresPerformance />
      </Col>
    </Row>
  );
};
export default SummaryRow;

import React from 'react';
import { DiamondPointStyle } from './styles';

const DiamondPoint = () => (
  <DiamondPointStyle>
    <div className="diamond" />
  </DiamondPointStyle>
);

export default DiamondPoint;

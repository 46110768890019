import {
  DEFAULT_CURRENT_PAGE,
  DEFAULT_SORT_CAMPAIGN,
  LIMIT_ITEM,
  VIEW_TYPE_JOB_LISTINGS,
} from 'containers/JobListings/constants';
import Summaries from 'containers/JobListings/Tabs/CampaignView/components/Summaries';
import { isEmpty } from 'lodash-es';
import React, { useEffect, useRef, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router';
import {
  getAllJobListings,
  getSummaryJobListing,
} from 'redux/jobs/jobListings/actions';
import { getJobListingViewByCampaign } from 'redux/jobs/jobListings/selectors';
import { getFilterFromUrl, getSearchUrl } from 'utils/tools';
import NoJobListings from '../NoJobListings';
import CampaignBoardView from './components/CampaignBoardView';
import CampaignListTable from './components/CampaignListTable';
import Filter from './components/Filter';

const CampaignView = () => {
  const dispatch = useDispatch();
  const { location, push } = useHistory();

  const { search } = useLocation();
  const { filter } = getFilterFromUrl(search);
  const filterRef = useRef(filter);
  const { tabMode, viewMode } = useParams();

  const { data: jobCampaignData, loading } = useSelector(
    getJobListingViewByCampaign,
  );

  const retrieveList = useCallback(
    ({ filter = {}, isRefresh, keepFilter }) => {
      dispatch(
        getAllJobListings({
          data: {
            ...filter,
            viewType: VIEW_TYPE_JOB_LISTINGS.CAMPAIGN,
          },
          options: {
            keepFilter,
            isRefresh,
          },
        }),
      );
      dispatch(
        getSummaryJobListing({
          ...filter,
          viewType: VIEW_TYPE_JOB_LISTINGS.CAMPAIGN,
        }),
      );
    },
    [dispatch],
  );

  useEffect(() => {
    if (tabMode === VIEW_TYPE_JOB_LISTINGS.CAMPAIGN) {
      retrieveList({
        filter: {
          sorts: filterRef.current.sorts || [DEFAULT_SORT_CAMPAIGN],
          page: filterRef.current.page || DEFAULT_CURRENT_PAGE,
          size: filterRef.current.size || LIMIT_ITEM,
          filter: filterRef.current.filter,
        },
        isRefresh: true,
        keepFilter: true,
      });
    }
  }, [retrieveList, tabMode]);

  const pushQuery = useCallback(
    (searchStr) => {
      push(`${location.pathname}?${getSearchUrl(searchStr)}`);
    },
    [location.pathname, push],
  );

  const onViewModeChange = (mode) => {
    push(`/job-listings/campaign/${mode}`);
  };

  const content =
    viewMode === 'list' ? (
      <CampaignListTable
        jobCampaignData={jobCampaignData}
        retrieveList={retrieveList}
        pushQuery={pushQuery}
        loading={loading}
      />
    ) : (
      <CampaignBoardView
        jobCampaignData={jobCampaignData}
        retrieveList={retrieveList}
        pushQuery={pushQuery}
        loading={loading}
      />
    );

  return (
    <>
      <Summaries />
      <Filter
        retrieveList={retrieveList}
        pushQuery={pushQuery}
        onViewChange={onViewModeChange}
        viewMode={viewMode}
      />
      {jobCampaignData?.length || loading || !isEmpty(filter?.filter) ? (
        content
      ) : (
        <NoJobListings
          title="jobListings.noCampaign"
          description="jobListings.noCampaignDesc"
          isShowCreateCampaign
        />
      )}
    </>
  );
};

export default CampaignView;

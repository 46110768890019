import React from 'react';
import i18next from 'i18next';
import PropTypes from 'prop-types';

import CardCommonStyles from './styles';

const CardCommon = ({ title, children, isLightBackground }) => (
  <CardCommonStyles isLightBackground={isLightBackground}>
    <div className="title-card">{i18next.t(title)}</div>
    <div className="content-card-info">{children}</div>
  </CardCommonStyles>
);

CardCommon.propTypes = {
  title: PropTypes.string,
  children: PropTypes.any,
  isLightBackground: PropTypes.bool,
};

CardCommon.defaultProps = {
  isLightBackground: true,
};

export default CardCommon;

import { createSelector } from '@reduxjs/toolkit';

const getRawCompanyProfile = (state) => state.companyProfile;

const getRawIDCompanyProfile = (state) => state.companyProfile?.data?.id;

const getRawCompanyProfileAboutUsCard = (state) =>
  state.companyProfile?.data?.description;

const getRawEducatorDiscount = (state) =>
  state.companyProfile?.data?.educatorDiscount;

const getRawStaffDiscount = (state) =>
  state.companyProfile?.data?.staffDiscount;

const getSocialMediaChannels = (state) => state.companyProfile?.socialMedia;

const getTotalSocialMediaChannels = (state) =>
  state.companyProfile?.totalSocialMedia;

const getRawRecruitmentVideosData = (state) =>
  state.companyProfile?.data?.recruitmentMedia;

const getCompanyVirtualTourData = (state) =>
  state?.companyProfile?.companyVirtualTour;

export const getCompanyProfileSelector = createSelector(
  [getRawCompanyProfile],
  (data) => data,
);

export const getIDCompanyProfileSelector = createSelector(
  [getRawIDCompanyProfile],
  (data) => data,
);

export const getCompanyProfileAboutUsCardSelector = createSelector(
  [getRawCompanyProfileAboutUsCard],
  (data) => data,
);

export const getEducatorandStaffDiscountsSelector = createSelector(
  [getRawEducatorDiscount, getRawStaffDiscount],
  (educatorDiscount, staffDiscount) => ({
    educatorDiscount,
    staffDiscount,
  }),
);

export const getSocialMediaChannelsData = createSelector(
  [getSocialMediaChannels],
  (data) => data,
);

export const getTotalSocialMediaChannelsValue = createSelector(
  [getTotalSocialMediaChannels],
  (data) => data,
);

export const getRecruitmentVideosSelector = createSelector(
  [getRawRecruitmentVideosData],
  (data) => data,
);

export const getCompanyVirtualTourSelector = createSelector(
  [getCompanyVirtualTourData],
  (data) => data,
);

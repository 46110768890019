import styled from 'styled-components';

const BoardViewStyles = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  -webkit-user-select: none;
  user-select: none;
  white-space: nowrap;
  overflow-x: auto;
  overflow-y: hidden;
  display: inline-flex;
  width: 100%;
  padding-bottom: 8px;

  @media only screen and (max-height: 800px) {
    height: 500px;
    position: relative;
  }
`;

export default BoardViewStyles;

export const ContactItemStyles = styled.div`
  border-radius: 8px;

  background-color: #fff;

  box-shadow: 0px 1px 16px rgba(0, 0, 0, 0.06);

  padding: 16px;
  &:not(:last-child) {
    margin-bottom: 16px;
  }
  user-select: none;

  color: ${({ theme }) => theme.text.primary};

  display: flex;
  flex-direction: column;
  & > a {
    color: ${({ theme }) => theme.text.primary};
    &:hover,
    &:active {
      color: ${({ theme }) => theme.text.primary};
      text-decoration: none;
    }

    &:focus {
      outline: none;
      box-shadow: none;
    }
  }

  font-size: 16px;
  line-height: 20px;

  .item-info + .item-info,
  .children-item + .children-item {
    margin-top: 12px;
  }

  .item-info {
    display: flex;
    .anticon {
      line-height: 24px;
    }
    &__value {
      word-break: break-word;
    }
  }

  .contact-item {
    &__user {
      margin-bottom: 16px;
      display: flex;
      align-items: center;
      .name-user {
        font-weight: bold;
      }
    }
    &__user-info {
      margin-left: 10px;
    }
    &__info {
      color: ${({ theme }) => theme.text.mainL5};
      .item-info .anticon {
        margin-right: 8px;
      }
    }
    &__activity-time {
      margin-bottom: 16px;
      color: ${({ theme }) => theme.text.mainL2};
      font-size: 14px;
    }
  }

  .children-item {
    display: flex;
    align-items: center;
    &__gender {
      width: 18px;
      height: 18px;
      border-radius: 50%;
      margin-right: 8px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 12px;
    }
  }

  .ant-divider {
    margin: 12px 0 !important;
  }
`;

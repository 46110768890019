import React from 'react';
import { useSelector } from 'react-redux';
import { TotalActivitiesIcon } from 'components/common/SVGIcon';

import SummaryView from 'components/common/SummaryCardDetail/SummaryView';

const SummaryActivities = () => {
  const summaries =
    useSelector((state) => state.dashboard.activities.data?.activitiesItem) ||
    {};

  const data = [
    {
      title: 'activities.mailboxReceived',
      value: summaries.mailboxReceived,
    },
    {
      title: 'activities.mailboxAwaitingReply',
      value: summaries.mailboxAwaitingReply,
    },
  ];

  return (
    <SummaryView
      title="activities.totalActivities"
      data={data}
      IconComponent={TotalActivitiesIcon}
      color="#32A4FC"
      total={summaries.totalActivities}
    />
  );
};

export default SummaryActivities;

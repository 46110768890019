import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'antd';
import { omit } from 'lodash-es';
import { useLocation } from 'react-router';
import { getFilterFromUrl, getSearch } from 'utils/tools';

import ViewModeSwitcher from 'components/common/ViewModeSwitcher';
import FilterCustom from '../components/FilterCustom';
import { HeaderFilterStyles } from './styles';

const HeaderCentre = ({ history, activeTab, retrieveList, pushQuery }) => {
  const location = useLocation();
  const onChange = (e) => {
    const filter = omit(getFilterFromUrl(location.search), ['limit', 'offset']);
    history && history.push(`/centres/${e.target.value}?${getSearch(filter)}`);
  };

  return (
    <HeaderFilterStyles>
      <Row gutter={24}>
        <Col xxl={4} xl={6} lg={6} md={24} xs={24}>
          <ViewModeSwitcher onChange={onChange} activeTab={activeTab} />
        </Col>
        <Col xxl={20} xl={18} lg={18} md={24} xs={24}>
          <FilterCustom retrieveList={retrieveList} pushQuery={pushQuery} />
        </Col>
      </Row>
    </HeaderFilterStyles>
  );
};

HeaderCentre.propTypes = {
  history: PropTypes.object,
  activeTab: PropTypes.string,
  pushQuery: PropTypes.func,
  retrieveList: PropTypes.func,
};

HeaderCentre.defaultProps = {
  history: {},
  pushQuery: () => null,
  retrieveList: () => null,
};

export default HeaderCentre;

import React from 'react';
import { Form } from 'antd';
import i18next from 'i18next';
import PropTypes from 'prop-types';
import { RestInputContext } from 'components/RestInput/RestInputContext';

import ModalCustom from 'components/common/ModalCustom';
import RestInputItem from 'components/RestInput/RestInputItem';
import RestSelect from 'components/RestInput/RestSelect';
import { ACTIVITY_TYPES } from 'configs/localData';
import TextArea from 'antd/lib/input/TextArea';
import RestFormDateTimePicker from 'components/RestInput/RestDateTimePicker';
import { useSelector } from 'react-redux';

const TaskModal = ({
  visible,
  onCancel,
  onOk,
  toggleModal,
  initialValue,
  okText,
}) => {
  const [form] = Form.useForm();
  const loading = useSelector((state) => state.applications.taskLoading);

  const handleCancel = () => {
    onCancel();
    form.resetFields();
  };

  const handleOk = () => {
    form.validateFields().then((value) => {
      onOk(value).then(({ payload }) => {
        if (payload?.data?.id) {
          form.resetFields();
          toggleModal();
        }
      });
    });
  };

  React.useEffect(() => {
    initialValue && form.setFieldsValue(initialValue);
    return () => {
      form.resetFields();
    };
  }, [initialValue, visible]); //eslint-disable-line

  return (
    <ModalCustom
      title={
        initialValue?.id ? i18next.t('task.edit') : i18next.t('task.create')
      }
      okText={okText}
      visible={visible}
      onCancel={handleCancel}
      onOk={handleOk}
      forceRender
      isDisabled={loading}
    >
      <Form form={form} layout="vertical">
        <RestInputContext.Provider value={{ form }}>
          <RestInputItem
            required
            source="subject"
            placeholder="task.subject"
            header="task.subject"
          />
          <RestSelect
            required
            source="type"
            header="task.type"
            placeholder="task.type"
            resourceData={ACTIVITY_TYPES}
            formatText={(data, record) => (
              <span style={{ color: record.color }}>{i18next.t(data)}</span>
            )}
            valueProp="value"
            titleProp="text"
          />
          <RestFormDateTimePicker
            required
            showTime
            source="date"
            header="task.date"
            formatDate="DD-MM-YYYY HH:mm"
          />
          <RestInputItem
            required
            source="note"
            placeholder="task.note"
            header="task.note"
            ContentComponent={TextArea}
          />
        </RestInputContext.Provider>
      </Form>
    </ModalCustom>
  );
};

TaskModal.propTypes = {
  visible: PropTypes.bool,
  onCancel: PropTypes.func,
  onOk: PropTypes.func,
  toggleModal: PropTypes.func,
  initialValue: PropTypes.object,
  okText: PropTypes.string,
};

TaskModal.defaultProps = {
  onCancel: () => null,
};

export default TaskModal;

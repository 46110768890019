import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'antd';
import i18next from 'i18next';
import { take } from 'lodash-es';
import CentreItem from './CentreItem';

const defaultPageSize = 10;

const CentreList = ({ centreAppSettings, keyNotify }) => {
  const [data, setData] = useState([]);

  const [page, setPage] = useState(1);

  useEffect(() => {
    setData(take(centreAppSettings, page * defaultPageSize));
  }, [centreAppSettings, page]); // eslint-disable-line

  const enableLoadMore = data?.length < centreAppSettings?.length;

  const handleViewMore = () => {
    setPage(page + 1);
  };

  return (
    <div className="centre-list">
      {data?.map((centre, index) => (
        <CentreItem key={String(index)} item={centre} keyNotify={keyNotify} />
      ))}
      {enableLoadMore && (
        <Button
          type="link"
          className="centre-list__view-more"
          onClick={handleViewMore}
        >
          {i18next.t('button.viewMore')}
        </Button>
      )}
    </div>
  );
};

CentreList.propTypes = {
  centreAppSettings: PropTypes.array,
  keyNotify: PropTypes.string,
};

export default CentreList;

import React, { useEffect, useState } from 'react';
import GridCards from 'components/GridCards';
import { useGetStaffProfilesQuery } from 'redux/@rtkQuery/educatorStaffProfile';
import { Empty, Pagination, PaginationProps, Skeleton } from 'antd';
import { useLocation, useParams } from 'react-router-dom';
import { getFilterFromUrl } from 'utils/tools';
import { StaffProfileListPayload } from 'types/staffProfiles';
import { DEFAULT_CURRENT_PAGE, DEFAULT_SIZE } from 'configs/localData/constant';
import iconEmpty from 'assets/images/shape-icon-empty.png';
import i18next from 'i18next';
import { StaffProfileQueryParams } from 'containers/Centres/components/CentreTabs/EducatorStaffProfilesTab/index';
import { isEqual, omit } from 'lodash-es';
import CreateProfileButton from 'containers/Centres/components/CentreTabs/EducatorStaffProfilesTab/components/CreateProfileButton';
import { isEmpty } from 'lodash';
import CardItem from './CardItem';

interface Props {
  pushQuery?: (params: StaffProfileQueryParams) => void;
}

const BoardView = ({ pushQuery }: Props) => {
  const { id } = useParams();
  const { search } = useLocation();
  const params = getFilterFromUrl(search).filter;
  const [payload, setPayload] = useState<StaffProfileListPayload>({
    centreId: id,
    page: params.page ?? DEFAULT_CURRENT_PAGE,
    size: params.size ?? DEFAULT_SIZE,
    ...params.filter,
  });

  const { data, isLoading, isFetching } = useGetStaffProfilesQuery(payload);

  useEffect(() => {
    const _customPayload = {
      centreId: id,
      ...params.filter,
      page: params.page ?? DEFAULT_CURRENT_PAGE,
      size: params.size ?? DEFAULT_SIZE,
    };

    if (!isEqual(_customPayload, payload)) {
      setPayload(_customPayload);
    }
  }, [payload, params, id]);

  useEffect(() => {
    if (
      !isFetching &&
      !isEmpty(data) &&
      data.results === null &&
      payload.page > DEFAULT_CURRENT_PAGE
    ) {
      const _payload = {
        ...payload,
        page: payload.page - 1,
      };
      setPayload(_payload);
      pushQuery({
        page: _payload.page,
        size: _payload.size,
        filter: omit(_payload, ['centreId', 'page', 'size']),
      });
    }
  }, [data, isFetching, payload, payload.page, payload.size, pushQuery]);

  const onChange: PaginationProps['onChange'] = (_page, pageSize) => {
    setPayload({ ...payload, page: _page, size: pageSize });
    pushQuery({
      page: _page,
      size: pageSize,
      filter: omit(payload, ['centreId', 'page', 'size']),
    });
  };

  return (
    <div className="w-full">
      <Skeleton loading={isLoading} active>
        {data?.results?.length ? (
          <div className="flex flex-col gap-24 items-end">
            <GridCards
              size={[24, 24]}
              gap="24px"
              columns={{ sm: 1, md: 2, lg: 2, xl: 3, xxl: 5 }}
              className="align-items-stretch justify-start w-full"
            >
              {data?.results?.map((item) => (
                <CardItem staffProfile={item} key={item.id} />
              ))}
            </GridCards>
            <Pagination
              key={payload.page}
              showQuickJumper
              showSizeChanger
              current={payload.page}
              defaultCurrent={payload.page}
              total={data?.paging?.total}
              onChange={onChange}
              pageSize={payload.size || 10}
              showTotal={(total, range) => `${range[0]}-${range[1]}/${total}`}
            />
          </div>
        ) : (
          <Empty
            image={iconEmpty}
            imageStyle={{ height: 160 }}
            className="text-gray-500 mt-70 mb-48"
            description={
              <>
                <p>{i18next.t('error.noProfiles')}</p>
                <CreateProfileButton />
              </>
            }
          />
        )}
      </Skeleton>
    </div>
  );
};

export default BoardView;

import styled from 'styled-components';
import { Modal } from 'antd';

export const ModalWrapper = styled(Modal)`
  min-height: 30%;
  max-width: calc(100vw - 16px);

  .ant-modal-content {
    overflow: hidden;
  }

  .ant-modal-header {
    border: none;
    color: ${({ theme }) => theme.palette.primary};
    display: flex;
    align-items: center;
    padding-right: 50px;
    .ant-modal-title {
      line-height: 28px !important;
    }
  }

  .ant-modal-body {
    overflow-x: hidden;
  }

  &.reset-padding-top-body .ant-modal-body {
    padding-top: 0;
  }

  &.reset-padding-bottom-body .ant-modal-body {
    padding-bottom: 0;
  }

  &.modal-scroll-y .ant-modal-body {
    max-height: 70vh;
    overflow-y: auto;
  }

  .ant-modal-title,
  .ant-modal-close,
  .ant-modal-close-icon,
  .ant-modal-close-x > .anticon {
    color: ${({ theme }) => theme.palette.primary};
    font-size: 22px;
    font-weight: bold;
  }

  .multi-upload-wrapper .ant-form-item:not(:first-child) {
    display: none;
  }
  .multi-upload-wrapper .ant-form-item:first-child {
    margin-bottom: 10px;
  }

  .ant-form-item-label {
    padding-bottom: 0 !important;
    height: 21px !important;
    margin-bottom: 8px;
    label {
      color: ${({ theme }) => theme.text.blackNeutral800};
      &:before {
        margin-right: 0 !important;
        display: none !important;
      }
    }
  }
  .ant-form label {
    font-size: 16px;
  }

  textarea,
  .ant-input,
  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector,
  .ant-select-selection,
  .ant-input-number,
  .ant-select-dropdown-menu-item,
  .ant-select-dropdown-menu,
  .ant-select-dropdown,
  .ant-select-clear-icon,
  .ant-select-dropdown-menu-vertical {
    border-radius: ${({ theme }) => theme.borderRadius.input};
    &:focus,
    &:active {
      border: 1px solid ${({ theme }) => theme.border.primary};
    }
  }

  .ant-input-affix-wrapper {
    .ant-input {
      border: none;
    }
  }

  .ant-input-affix-wrapper-focused {
    border: 1px solid ${({ theme }) => theme.border.primary}!important;
  }

  .ant-select-selection__clear {
    background-color: transparent;
    color: white;
    border-radius: 5px;
  }
  .ant-select-arrow-icon {
    background-color: transparent;
  }
  * {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  .ant-modal-footer {
    border: none;
    padding: 24px;
    button:not(:first-child) {
      margin-left: 15px !important;
    }
    button {
      min-width: 100px;
      font-weight: 700;
    }
  }
  .ant-calendar-picker,
  .ant-select {
    width: 100%;
  }

  .ant-tabs-bar {
    font-weight: 500;
  }
  .ant-tabs-nav {
    .ant-tabs-tab {
      padding-bottom: 5px;
    }
  }
  .ant-tabs-tab {
    font-family: 'Mulish';
    color: ${({ theme }) => theme.text.tabTitle};
  }
  .ant-list {
    margin-top: 20px;
    overflow: auto;
    max-height: 460px;
  }
  div::-webkit-scrollbar-thumb {
    border-radius: 3px !important;
    background: ${({ theme }) => theme.scrollbar.thumb};
  }
  div::-webkit-scrollbar-track {
    position: absolute;
    border-radius: 3px !important;
    background: ${({ theme }) => theme.scrollbar.track};
  }
  div::-webkit-scrollbar-thumb:hover {
    border-radius: 3px !important;
    background: ${({ theme }) => theme.scrollbar.thumb};
  }
  div::-webkit-scrollbar {
    width: 6px;
    border-radius: 3px !important;
    background: ${({ theme }) => theme.scrollbar.thumb};
  }
  .ant-list-split .ant-list-item {
    border-bottom: none;
    padding: 1px 0px;
  }
  .ant-list-empty-text {
    color: ${({ theme }) => theme.text.empty};
  }
  .modalTitleContent {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    height: 55px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    display: flex;
    .modalBtnBack {
      font-size: 20px;
      margin: 0px 24px;
      color: ${({ theme }) => theme.drawer.closeIcon};
    }
    .modalTitle {
      flex: 1;
      padding-left: 24px;
      font-size: 20px;
      color: ${({ theme }) => theme.drawer.headerTitle};
    }
  }

  .ant-form-item-control {
    line-height: 2;
  }
  .txtTitle {
    font-size: 12px;
  }
  .ant-form-item-label {
    line-height: 1.5em;
    padding-bottom: 5px;
    display: flex;
    flex-direction: column;
  }
  .ant-input-number {
    width: 100%;
  }
  .txtTitleForm {
    color: ${({ theme }) => theme.text.formLabel};
    font-size: 12px;
  }
  .ant-form-item .ant-form-explain {
    margin-top: 2px;
  }
  .title-header-item {
    font-size: 16px;
    color: #32a4fc;
    color: ${({ theme }) => theme.color.blueSecondary};
  }
  .ant-checkbox {
    margin-top: 4px;
  }
`;

import { createSlice } from '@reduxjs/toolkit';
import {
  CLOSED_JOB_LISTING,
  CREATE_JOB_LISTING_FINAL_STEP,
} from 'containers/JobListings/constants';
import { unionBy, remove } from 'lodash-es';
import { upperFirstChar } from 'utils/textUtils';
import {
  getAllJobListings,
  delJobListing,
  getWorkplaces,
  getJobListingsDetail,
  getSummaryJobListing,
  MODEL_NAME,
  createJobListing,
  getCampaigns,
  editJobListing,
  closeJobListing,
} from './actions';

const initialState = {
  currentStep: 0,
  jobListingPayload: {},
  createLoading: false,
  hasSelectedTemplate: null,
  data: [],
  currentData: {},
  summary: {
    loading: false,
  },
  getJobListingsDetailLoading: false,
  campaigns: {
    data: [],
    loading: false,
    resourceParams: {
      page: 1,
      size: 12,
      total: 0,
      filter: {},
    },
  },
  workplaces: {
    data: [],
    loading: false,
    resourceParams: {
      page: 1,
      size: 12,
      total: 0,
      filter: {},
    },
  },
  jobCampaign: {
    data: [],
    loading: false,
    resourceData: {
      page: 1,
      total: 0,
      filter: {},
    },
  },
  jobRole: {
    data: [],
    loading: false,
    resourceData: {
      page: 1,
      total: 0,
      filter: {},
    },
  },
  jobTemplate: {
    data: [],
    loading: false,
    resourceData: {
      page: 1,
      total: 0,
      filter: {},
    },
  },
  jobIndividual: {
    data: [],
    loading: false,
    resourceData: {
      page: 1,
      total: 0,
      filter: {},
    },
  },
  selectedWorkplaces: [],
};

const getAllJobListingsPending = (
  state,
  {
    meta: {
      arg: { data, options = {} },
    },
  },
) => {
  const viewType = `job${upperFirstChar(data?.viewType)}`;
  state[viewType].loading = true;

  if (options.isRefresh) {
    state[viewType].data = [];
    state[viewType].resourceData = {
      ...initialState?.[viewType].resourceData,
      ...data,
    };
  }
};

const getAllJobListingsSuccess = (state, { payload: { data } }) => {
  const viewType = `job${upperFirstChar(data?.viewType)}`;

  state[viewType].data = unionBy(
    [...state[viewType]?.data, ...(data?.results || [])],
    'id',
  );

  state[viewType].resourceData.total = data?.paging?.total || 0;
  state[viewType].resourceData.page = data?.paging?.page;

  state[viewType].loading = false;
  state.error = null;
};

const getAllJobListingsFail = (state, { payload: { data } }) => {
  state.error = data;
  if (state[data?.viewType]?.loading) {
    state[data.viewType].loading = false;
  }
};

const getSummaryJobListingPending = (state) => {
  state.summary.loading = true;
};

const getSummaryJobListingSuccess = (state, { payload: data }) => {
  state.summary = data;
  state.summary.loading = false;
};

const getSummaryJobListingFail = (state) => {
  state.summary.loading = false;
};

const delJobListingSuccess = (state, payload) => {
  const deleteList = payload?.meta?.arg;
  for (let i = 0; i < deleteList.length; i += 1) {
    state.data = remove(state.data, (item) => item.id !== deleteList[i]);
  }
  state.error = null;
};

const delJobListingFail = (state, { payload: { data } }) => {
  state.error = data;
};
const getJobListingsDetailPending = (state) => {
  state.getJobListingsDetailLoading = true;
};

const getJobListingsDetailSuccess = (state, { payload }) => {
  state.currentData = payload?.result;
  state.getJobListingsDetailLoading = false;
};

const getJobListingsDetailFailed = (state, { payload: { data } }) => {
  state.error = data;
  state.getJobListingsDetailLoading = false;
};
const getCampaignsPending = (state, { meta: { arg } }) => {
  state.campaigns.loading = true;

  if (arg?.options?.isRefresh) {
    state.campaigns.data = [];
  }
};

const getCampaignsSuccess = (state, { payload, meta }) => {
  state.campaigns.data = unionBy(
    [...state.campaigns.data, ...(payload?.results || [])],
    'id',
  );
  state.campaigns.resourceParams = {
    ...meta.arg.data,
    total: payload?.paging?.total,
  };
  state.campaigns.loading = false;
};

const getCampaignsFail = (state) => {
  state.campaigns.loading = false;
};

const getWorkplacesPending = (state, { meta: { arg } }) => {
  state.workplaces.loading = true;

  if (arg?.options?.isRefresh) {
    state.workplaces.data = [];
  }
};

const getWorkplacesSuccess = (state, { payload, meta }) => {
  state.workplaces.data = unionBy(
    [...state.workplaces.data, ...(payload?.results || [])],
    'organizationId',
  );
  state.workplaces.resourceParams = {
    ...meta.arg?.data,
    total: payload?.paging?.total,
  };
  state.workplaces.loading = false;
};

const getWorkplacesFail = (state) => {
  state.workplaces.loading = false;
};

const createJobListingPending = (state) => {
  state.createLoading = true;
};

const createJobListingSuccessOrFail = (state) => {
  state.createLoading = false;
};

const editJobListingSuccess = (state, { meta: { arg } }) => {
  state.currentData = { ...arg?.data };
};

const closeJobListingSuccess = (state) => {
  state.currentData.jobStatus = CLOSED_JOB_LISTING;
  state.currentData.applicationDeadline = new Date();
};

const jobListings = createSlice({
  name: MODEL_NAME,
  initialState,
  reducers: {
    goToStepJobListing: (state, { payload: step }) => {
      if (step >= 0 && step <= CREATE_JOB_LISTING_FINAL_STEP) {
        state.currentStep = step;
      }
    },
    goToNextStep: (state) => {
      const newStep = state.currentStep + 1;
      if (newStep > 0 && newStep <= CREATE_JOB_LISTING_FINAL_STEP) {
        state.currentStep = newStep;
      }
    },
    goToPrevStep: (state) => {
      const newStep = state.currentStep - 1;
      if (newStep >= 0 && newStep <= CREATE_JOB_LISTING_FINAL_STEP) {
        state.currentStep = newStep;
      }
    },
    setJobListingPayload: (state, { payload }) => {
      state.jobListingPayload = {
        ...state.jobListingPayload,
        ...payload,
      };
    },
    resetJobListingPayload: (state) => {
      state.currentStep = 0;
      state.jobListingPayload = {};
      state.selectedWorkplaces = [];
    },

    setSelectedTemplate: (state, { payload }) => {
      state.hasSelectedTemplate = payload;
    },
    setSelectedWorkplaces: (state, { payload }) => {
      state.selectedWorkplaces = [...(state.selectedWorkplaces || []), payload];
    },

    removeSelectedWorkplaces: (state, { payload }) => {
      state.selectedWorkplaces = state.selectedWorkplaces.filter(
        (item) => item?.organizationId !== payload,
      );
    },
  },
  extraReducers: {
    [getAllJobListings.pending]: getAllJobListingsPending,
    [getAllJobListings.fulfilled]: getAllJobListingsSuccess,
    [getAllJobListings.rejected]: getAllJobListingsFail,

    [delJobListing.fulfilled]: delJobListingSuccess,
    [delJobListing.rejected]: delJobListingFail,

    [getSummaryJobListing.pending]: getSummaryJobListingPending,
    [getSummaryJobListing.fulfilled]: getSummaryJobListingSuccess,
    [getSummaryJobListing.rejected]: getSummaryJobListingFail,

    [getJobListingsDetail.pending]: getJobListingsDetailPending,
    [getJobListingsDetail.fulfilled]: getJobListingsDetailSuccess,
    [getJobListingsDetail.rejected]: getJobListingsDetailFailed,

    [getCampaigns.pending]: getCampaignsPending,
    [getCampaigns.fulfilled]: getCampaignsSuccess,
    [getCampaigns.rejected]: getCampaignsFail,

    [getWorkplaces.pending]: getWorkplacesPending,
    [getWorkplaces.fulfilled]: getWorkplacesSuccess,
    [getWorkplaces.rejected]: getWorkplacesFail,

    [createJobListing.pending]: createJobListingPending,
    [createJobListing.fulfilled]: createJobListingSuccessOrFail,
    [createJobListing.rejected]: createJobListingSuccessOrFail,

    [editJobListing.fulfilled]: editJobListingSuccess,

    [closeJobListing.fulfilled]: closeJobListingSuccess,
  },
});

export const {
  goToNextStep,
  goToPrevStep,
  goToStepJobListing,
  setJobListingPayload,
  resetJobListingPayload,
  setSelectedTemplate,
  setSelectedWorkplaces,
  removeSelectedWorkplaces,
} = jobListings.actions;

export default jobListings.reducer;

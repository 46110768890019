import { notification } from 'antd';
import I18n from 'i18next';
import { isEmpty } from 'lodash-es';
import { logoutSuccess } from 'redux/auth/slice';
import store, { history } from 'redux/store';

const ERROR_CODE = [401];

interface Config {
  isShowProgress?: boolean;
  isShowSuccessNoti?: boolean;
  isCheckError?: boolean;
  successDescription?: string;
  isShowLoading?: boolean;
}

export async function apiWrapper(
  config: Config = {
    isShowProgress: true,
    isShowSuccessNoti: false,
    isCheckError: false,
  },
  apiFunc,
  ...params
) {
  try {
    const response = await apiFunc(...params);

    config?.isShowSuccessNoti &&
      notification.success({
        message: I18n.t('success.title'),
        description:
          config.successDescription ||
          response.message ||
          I18n.t('success.description'),
      });
    return response;
  } catch (err) {
    if (!config?.isCheckError || isEmpty(err?.errors)) {
      notification.destroy();
      notification.error({
        message: I18n.t('error.title'),
        description: err.message || I18n.t('error.description'),
      });
      checkError(err);
    }
    throw err;
  }
}

export function checkError(res: { code: number }) {
  if (res.code === 401) {
    store.dispatch(logoutSuccess());
  } else if (ERROR_CODE.indexOf(res.code) > -1) {
    history.push(`/error/${res.code}`);
  }
}

import React, { useCallback, useMemo } from 'react';
import {
  ExclamationCircleOutlined,
  FileOutlined,
  LoadingOutlined,
} from '@ant-design/icons';
import i18next from 'i18next';
import { getExtensionFile, isImageFile } from 'utils/fileUtils';
import { FILE_TYPES } from 'configs/localData';
import renderHTML from 'react-render-html';
import { UPLOAD_FILE_STATUS } from 'configs/localData/constant';
import { CloseFillIcon } from 'components/common/SVGIcon';

import { getYoutubeEmbedURL } from 'utils/tools';
import {
  DeleteIconStyle,
  RenderIframeStyle,
  UploadImageItemStyles,
} from 'components/RestInput/RestUploadFile/styles';
import { UploadFileCustom } from 'components/RestInput/RestUploadFile/index';

export interface UploadImageItemProps {
  item?: UploadFileCustom;
  deleteImage?: (index: number) => void;
  index?: number;
  onlyShowImg?: boolean;
  onSetFeature?: (index: number) => void;
  isSetFeature?: boolean;
  onPreviewImage?: (image: UploadFileCustom) => void;
  isAttachURLVideo?: boolean;
  itemScale?: number;
  isNoneBorderRadius?: boolean;
  isShowVirtualTour?: boolean;
}

const UploadImageItem = ({
  item,
  deleteImage,
  index,
  onlyShowImg,
  isSetFeature,
  onSetFeature,
  onPreviewImage,
  isAttachURLVideo,
  isShowVirtualTour,
  itemScale = 1,
  isNoneBorderRadius = false,
}: UploadImageItemProps) => {
  const handleClick = useCallback(
    () =>
      onPreviewImage({
        ...item,
        url: item?.value || item?.url || item?.response,
        type: item?.type,
      }),
    [item, onPreviewImage],
  );

  const restFileItem = useMemo(
    () =>
      FILE_TYPES.find(
        (file) =>
          file.value.toLocaleLowerCase() ===
          getExtensionFile(item.url || item.response)?.toLocaleLowerCase(),
      ),
    [item.url, item.response],
  );

  const fileResult = useMemo(() => {
    if (isAttachURLVideo && item?.type?.includes('video')) {
      const urlVideo = getYoutubeEmbedURL(item?.url || item.response);
      return (
        <div
          className="iframe-video-wrapper"
          role="presentation"
          onClick={handleClick}
        >
          {urlVideo ? (
            <iframe
              title="iframe-video-item"
              frameBorder="0"
              className="iframe-video"
              allowFullScreen
              src={urlVideo}
            />
          ) : (
            (item.response || item.url) && (
              <video controls className="iframe-video">
                <source src={item.response || item.url} type="video/mp4" />
                {i18next.t('error.notSupportVideo')}
                <track
                  src=""
                  kind="captions"
                  srcLang="en"
                  label="english_captions"
                />
              </video>
            )
          )}
        </div>
      );
    }
    if (isShowVirtualTour) {
      return (
        <RenderIframeStyle role="presentation" onClick={handleClick}>
          {renderHTML(item?.value)}
        </RenderIframeStyle>
      );
    }
    if (onlyShowImg) {
      return (
        <img
          className="image-upload-view image-only-show"
          src={item?.url || item?.response}
          alt=""
          onClick={handleClick}
          role="presentation"
        />
      );
    }

    return (typeof item?.type === 'string' && item.type?.includes('image')) ||
      isImageFile(item.url) ? (
      <img
        className="image-upload-view"
        src={item.url || item?.response}
        alt=""
      />
    ) : (
      <div className="icon-div-item">
        {restFileItem ? (
          <restFileItem.icon
            style={{
              color: restFileItem.color,
            }}
            className="icon-file"
          />
        ) : (
          <FileOutlined className="icon-file" />
        )}
      </div>
    );
  }, [
    item,
    onlyShowImg,
    restFileItem,
    handleClick,
    isAttachURLVideo,
    isShowVirtualTour,
  ]);

  return (
    <UploadImageItemStyles
      className="uploadImage"
      itemScale={itemScale}
      isNoneBorderRadius={isNoneBorderRadius}
    >
      {item.status === UPLOAD_FILE_STATUS.error && (
        <ExclamationCircleOutlined className="icon-error" />
      )}
      {item.status !== UPLOAD_FILE_STATUS.error &&
        item.status !== UPLOAD_FILE_STATUS.done &&
        !item.url &&
        !isShowVirtualTour && (
          <div className="wrapper-loading">
            <LoadingOutlined className="icon-loading" />
          </div>
        )}
      {fileResult}
      <DeleteIconStyle isShowVirtualTour={isShowVirtualTour}>
        <CloseFillIcon onClick={() => deleteImage(index)} />
      </DeleteIconStyle>
      {isSetFeature && !item.type?.includes('video') ? (
        <div
          role="presentation"
          onClick={() => onSetFeature(index)}
          className={`lbSetFeature  ${item.featured ? 'feature-active' : ''}`}
          onMouseDown={(e) => e.stopPropagation()}
        >
          {i18next.t('button.setFeature')}
        </div>
      ) : null}
    </UploadImageItemStyles>
  );
};

export default UploadImageItem;

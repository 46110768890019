import { Divider, Form, FormInstance, List, Popover, SelectProps } from 'antd';
import {
  ListSelectedOptionHidden,
  SelectCheckboxStyles,
  StyledCheckbox,
} from 'components/InfiniteFormItem/InfiniteSelectCheckbox/styles';
import i18next from 'i18next';
import React, { useMemo, useState, useEffect } from 'react';
import { LabeledValue } from 'antd/es/select';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { get, isObject, uniqBy } from 'lodash-es';

type SelectValueType = (string | number)[] | LabeledValue[];

interface Props extends SelectProps<SelectValueType> {
  className?: string;
  labelProp?: string;
  valueProp?: string;
  uniqueProp?: string;
  searchKey?: string;
  form?: FormInstance;
  name?: string;
  data: unknown[];
  initValues?: unknown[];
  onChange?: (value: unknown) => void;
}

interface ConvertOptionParams {
  labelProp?: string;
  valueProp?: string;
  selectedValues?: SelectValueType;
}

const convertDataToCheckboxOptions = (
  options: unknown[],
  { labelProp, valueProp, selectedValues }: ConvertOptionParams,
): (LabeledValue & { rawLabel: string })[] =>
  options?.map((option) => {
    const label = isObject(option) ? get(option, labelProp) : option;
    const optionValue = isObject(option) ? get(option, valueProp) : option;

    const checked = selectedValues?.includes(optionValue);

    return {
      key: optionValue,
      rawLabel: label,
      label: (
        <div key={optionValue}>
          <StyledCheckbox className={checked ? 'd-none' : ''} />
          {label}
        </div>
      ),
      value: optionValue,
    };
  });

const SelectCheckbox = ({
  onChange,
  value,
  labelProp = 'name',
  valueProp = 'id',
  uniqueProp = null,
  data,
  initValues = [],
  className,
  ...rest
}: Props) => {
  const [indeterminate, setIndeterminate] = useState(false);
  const [checkAll, setCheckAll] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [debounceSearchValue, setDebounceSearchValue] = useState('');

  const items = useMemo(
    () =>
      uniqBy(
        [...initValues, ...data],
        uniqueProp ? (item) => get(item, uniqueProp) : null,
      ),
    [data, initValues, uniqueProp],
  );

  const options = useMemo(() => {
    const optionsWithCheckbox = convertDataToCheckboxOptions(items, {
      labelProp,
      valueProp,
      selectedValues: value,
    });

    if (debounceSearchValue) {
      return optionsWithCheckbox.filter((option) =>
        option.rawLabel
          ?.toLowerCase()
          ?.includes(debounceSearchValue.toLowerCase()),
      );
    }

    return optionsWithCheckbox;
  }, [items, labelProp, valueProp, value, debounceSearchValue]);

  const onSearchDropdownChange = (value: string) => {
    setSearchValue(value);
  };

  const onCheckAllChange = (e: CheckboxChangeEvent) => {
    const { checked } = e.target;
    setCheckAll(checked);
    setIndeterminate(false);

    if (checked) {
      onChange(options.map((item) => item.value));
    } else {
      onChange([]);
    }
  };

  const dropdownRender = (menu: React.ReactElement) => (
    <>
      {!!options.length && (
        <div className="my-8 mx-12">
          <StyledCheckbox
            indeterminate={indeterminate}
            checked={checkAll}
            onChange={onCheckAllChange}
            className="checkbox-select-all"
          >
            {`${i18next.t('text.selectAll')}`}
          </StyledCheckbox>
        </div>
      )}
      <Divider className="mt-0 mb-8" />
      {menu}
    </>
  );

  const renderMaxTagPlaceholder = (omittedValues: LabeledValue[]) => {
    const totalPlaceholder = value?.length ? value.length - 1 : '...';

    const content = (
      <ListSelectedOptionHidden
        dataSource={omittedValues}
        itemLayout="vertical"
        split={false}
        size="small"
        renderItem={(item: LabeledValue) => (
          <List.Item key={item?.value}>{item?.value}</List.Item>
        )}
      />
    );

    return (
      <Popover
        overlayClassName="reset-padding-overlay"
        placement="topLeft"
        content={content}
        trigger="hover"
        zIndex={99}
      >
        {`+${checkAll ? totalPlaceholder : omittedValues.length}`}
      </Popover>
    );
  };

  const onSelectChange = (newValue: string[]) => {
    onChange(newValue);
    setIndeterminate(!!newValue.length && newValue.length < items.length);
    setCheckAll(newValue.length === items.length);
  };

  const onDropdownVisibleChange = (open: boolean) => {
    setIndeterminate(!!value?.length && value?.length < items.length);
    setCheckAll(value?.length === items.length);

    if (!open) {
      setSearchValue('');
    }
  };

  useEffect(() => {
    const timerId = setTimeout(() => {
      setDebounceSearchValue(searchValue);
    }, 500);

    return () => clearTimeout(timerId);
  }, [searchValue]);

  return (
    <SelectCheckboxStyles
      filterOption={false}
      searchValue={searchValue}
      onSearch={onSearchDropdownChange}
      onDropdownVisibleChange={onDropdownVisibleChange}
      autoClearSearchValue={false}
      allowClear
      showArrow
      maxTagCount="responsive"
      mode="multiple"
      menuItemSelectedIcon={<StyledCheckbox defaultChecked />}
      options={options}
      value={value}
      onChange={onSelectChange}
      dropdownRender={dropdownRender}
      maxTagPlaceholder={renderMaxTagPlaceholder}
      dropdownClassName="select-checkbox select-not-infinite"
      className={`w-full ${className ?? ''}`}
      {...rest}
    />
  );
};

const FormSelectCheckbox = ({ name, ...props }: Props) => (
  <Form.Item name={name}>
    <SelectCheckbox {...props} />
  </Form.Item>
);

export default FormSelectCheckbox;

import React, { useRef } from 'react';
import CardSection from 'components/common/CardSection';
import PropTypes from 'prop-types';
import AboutTheJobModal from 'containers/JobTemplates/components/Modals/AboutTheJobModal';
import ReadMore from 'components/common/ReadMore';

const AboutTheJob = ({ aboutTheJob, disabledAction }) => {
  const aboutTheJobModalRef = useRef();

  const openAboutTheJobModal = () => {
    aboutTheJobModalRef?.current && aboutTheJobModalRef.current.toggleModal();
  };

  return (
    <>
      <CardSection
        isEdit
        onClickButton={openAboutTheJobModal}
        title="jobs.moreAboutTheJob.aboutTheJob"
        action="isEdit"
        disabled={disabledAction}
      >
        <ReadMore
          text={aboutTheJob}
          readMoreCharacterLimit={500}
          showLessButton
          isCheckInsertLink
        />
      </CardSection>
      <AboutTheJobModal ref={aboutTheJobModalRef} aboutTheJob={aboutTheJob} />
    </>
  );
};

AboutTheJob.propTypes = {
  aboutTheJob: PropTypes.string,
  disabledAction: PropTypes.bool,
};

export default AboutTheJob;

import { createSlice } from '@reduxjs/toolkit';
import { xor, uniq } from 'lodash-es';
import { PRIMARY_KEY } from './dataProvider';

export const INITIAL_STATE_EXTRA = {
  resourceData: {
    filter: {},
    offset: 0,
    limit: 10,
    total: 0,
    numberOfPages: 1,
  },
  data: [],
};

export const INITIAL_STATE = {
  loading: false,
  itemLoadings: {},
  error: null,
  data: {},
  ids: [],
  currentId: null,
  filter: {},
  outsideFilter: {},
  offset: 0,
  limit: 10,
  total: 0,
  numberOfPages: 1,
  sort: '',
  currentData: {},
};

export const clearList = (state) => {
  state.loading = false;
  state.ids = [];
  state.data = {};
  state.total = 0;
  state.limit = 10;
  state.filter = {};
  state.numberOfPages = 1;
};

export const getAll = (
  state,
  {
    meta: {
      arg: { data, options = {} },
    },
  },
) => {
  if (options.isRefresh) {
    // eslint-disable-next-line
    state = {
      ...state,
      ...INITIAL_STATE,
      currentData: state.currentData,
      currentId: state.currentId,
      loading: true,
      ...data,
    };
  } else {
    // eslint-disable-next-line
    state = {
      ...state,
      loading: true,
      error: null,
      offset: state.offset + state.limit,
    };
  }
  return state;
};

export const getAllSuccess = (state, { payload: { data, options = {} } }) => {
  state.loading = false;
  state.ids = options.isRefresh ? data.ids : uniq([...state.ids, ...data.ids]);
  state.data = { ...state.data, ...data.data };
  state.additionData = data.additionData;
  state.total = data.total;
  state.numberOfPages = data.numberOfPages;
};

export const getAllFailure = (state, { payload }) => {
  state.loading = false;
  state.error = payload?.data;
};

export const getDataById = (
  state,
  {
    meta: {
      arg: { data, options = {} },
    },
  },
) => {
  state.currentId = data[PRIMARY_KEY];
  if (options.isRequestApi) {
    state.itemLoadings = { ...state.itemLoadings, [data[PRIMARY_KEY]]: true };
  }
};

export const getDataByIdSuccess = (
  state,
  { payload: { data, options = {}, id } },
) => {
  if (options.isRequestApi) {
    state.currentData = {
      ...state.currentData,
      ...data,
    };
  }
  state.itemLoadings = { ...state.itemLoadings, [id]: false };
};

export const getDataByIdFailure = (state, { payload: { data, id } }) => {
  state.error = data;
  state.itemLoadings = { ...state.itemLoadings, [id]: false };
};

export const create = (state) => {
  state.error = null;
  state.createLoading = true;
};

export const createSuccess = (state, { payload: { data, options } }) => {
  if (!options.isGetAll) {
    state.data = { ...state.data, [data[PRIMARY_KEY]]: data };
    state.ids = [data[PRIMARY_KEY], ...state.ids];
    state.currentId = data.id;
    state.total += 1;
  }
  state.error = null;
  state.createLoading = false;
};

export const createFailure = (state, { payload: { data } }) => {
  state.error = data;
  state.createLoading = false;
};

export const edit = (
  state,
  {
    meta: {
      arg: { data },
    },
  },
) => {
  state.error = null;
  state.itemLoadings = { ...state.itemLoadings, [data[PRIMARY_KEY]]: true };
};

export const editSuccess = (state, { payload: { data, id, options } }) => {
  if (!options.isGetAll) {
    state.data = {
      ...state.data,
      [data[PRIMARY_KEY]]: { ...state.data[data[PRIMARY_KEY]], ...data },
    };
    state.currentData = {
      ...state.currentData,
      ...data,
    };
  }
  state.error = null;
  state.itemLoadings = { ...state.itemLoadings, [id]: false };
};

export const editFailure = (state, { payload: { data, id } }) => {
  state.error = data;
  state.itemLoadings = { ...state.itemLoadings, [id]: false };
};

export const del = (
  state,
  {
    meta: {
      arg: { data },
    },
  },
) => {
  state.error = null;
  state.itemLoadings = data[PRIMARY_KEY]
    ? { ...state.itemLoadings, [data[PRIMARY_KEY]]: true }
    : null;
};

export const delSuccess = (state, { payload: { data } }) => {
  if (data[PRIMARY_KEY] && state.data[data[PRIMARY_KEY]]) {
    delete state.data[data[PRIMARY_KEY]];

    state.error = null;
    state.currentId = null;
    state.itemLoadings = data[PRIMARY_KEY]
      ? { ...state.itemLoadings, [data[PRIMARY_KEY]]: null }
      : null;
    state.ids = xor(state.ids, [data[PRIMARY_KEY]]);

    state.total = state.total - 1 || 0;
  }
};

export const delFailure = (state, { payload: { id, data } }) => {
  state.error = data;
  state.itemLoadings = id ? { ...state.itemLoadings, [id]: null } : null;
};

const setIsDisabledButtonSubmit = (state, { payload }) => {
  state.isDisabledButtonSubmit = payload;
};

const clearCurrent = (state) => {
  state.error = null;
  state.currentId = null;
  state.currentData = {};
};

export const makeCRUDSlice = (
  model,
  actions,
  customActions = {},
  ignoreActions = [],
) => {
  const extraReducers = {
    [actions.getAll.pending]: getAll,
    [actions.getAll.fulfilled]: getAllSuccess,
    [actions.getAll.rejected]: getAllFailure,

    [actions.getDataById.pending]: getDataById,
    [actions.getDataById.fulfilled]: getDataByIdSuccess,
    [actions.getDataById.rejected]: getDataByIdFailure,

    [actions.create.pending]: create,
    [actions.create.fulfilled]: createSuccess,
    [actions.create.rejected]: createFailure,

    [actions.edit.pending]: edit,
    [actions.edit.fulfilled]: editSuccess,
    [actions.edit.rejected]: editFailure,

    [actions.del.pending]: del,
    [actions.del.fulfilled]: delSuccess,
    [actions.del.rejected]: delFailure,
    [actions.clearCurrent]: clearCurrent,
    [actions.clearList]: clearList,
    [actions.setIsDisabledButtonSubmit]: setIsDisabledButtonSubmit,
  };

  ignoreActions.forEach((element) => {
    const _ignoreActions = Object.keys(extraReducers).filter(
      (key) => key.indexOf(element) > -1,
    );
    _ignoreActions.forEach((e) => {
      delete extraReducers[e];
    });
  });

  const slice = createSlice({
    name: model,
    initialState: INITIAL_STATE,
    reducers: {},
    extraReducers: { ...extraReducers, ...customActions },
  });
  return slice;
};

export default makeCRUDSlice;

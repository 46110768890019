import React, { useRef } from 'react';
import GridPhotosCustom from 'components/common/GridPhotosCustom';
import CardSection from 'components/common/CardSection';
import { useSelector } from 'react-redux';
import { getCompanyProfileSelector } from 'redux/jobs/companyProfile/selectors';
import useRolesCompanyProfile from 'containers/CompanyProfile/hooks/useRolesCompanyProfile';
import { isEmpty } from 'lodash-es';
import { NoDataIcon } from 'components/common/SVGIcon';
import i18next from 'i18next';
import CompanyPhotosModal from '../../Modals/CompanyPhotosModal';

const CompanyPhotos = () => {
  const editModalRef = useRef();

  const disabledAction = useRolesCompanyProfile();
  const { data } = useSelector(getCompanyProfileSelector);
  const mediaList = data?.mediaList;

  const onClickButton = () => {
    editModalRef.current.openModal();
  };

  return (
    <>
      <CardSection
        isEdit
        onClickButton={onClickButton}
        title="jobs.companyProfile.companyPhotosAndVideos"
        action="isEdit"
        disabled={disabledAction}
      >
        {isEmpty(mediaList) ? (
          <div className="flex-col flex-center">
            <NoDataIcon />
            <div>{i18next.t('jobs.companyProfile.emptyPhotosAndVideos')}</div>
          </div>
        ) : (
          <GridPhotosCustom images={mediaList} />
        )}
      </CardSection>
      <CompanyPhotosModal record={{ images: mediaList }} ref={editModalRef} />
    </>
  );
};

export default CompanyPhotos;

import React from 'react';
import RestInputItem from 'components/RestInput/RestInputItem';

const EnquiriesForm = (props) => (
  <div {...props}>
    <RestInputItem source="content" header="enquiries.content" />
  </div>
);

export default EnquiriesForm;

import styled from 'styled-components';

const HoursTabStyles = styled.div``;

export default HoursTabStyles;

export const CardHourStyles = styled.div`
  background: ${({ theme }) => theme.background.content};
  border-radius: 8px;
  padding: 30px;
  .title-card-hour {
    font-weight: bold;
    font-size: 18px;
    line-height: 23px;
    margin-bottom: 20px;
  }
  .info-hours {
    .hour-item:not(:last-child) {
      margin-bottom: 20px;
    }
    .label-item {
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
      color: ${({ theme }) => theme.text.mainL2};
      margin-bottom: 10px;
    }
    .value-item {
      background: #d4ecff;
      border-radius: 8px;
      padding: 6px 15px;
      font-size: 18px;
      text-align: center;
    }
    .ant-picker {
      background: #d4ecff;
      border: 1px solid transparent;
    }
    .ant-picker-focused {
      border: 1px solid ${({ theme }) => theme.palette.primary};
    }
    .ant-picker-input {
      input {
        font-size: 18px;
        line-height: 23px;
      }
      .ant-picker-suffix {
        color: ${({ theme }) => theme.text.mainL3};
      }
      .ant-picker-suffix .anticon {
        font-size: 18px;
      }
    }
  }
`;

export const SectionHourStyles = styled.div`
  .header-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    .name-section {
      font-weight: bold;
      font-size: 24px;
      line-height: 30px;
      color: ${({ theme }) => theme.palette.primary};
    }
  }
  .hour-section {
    &__checkbox-different {
      margin-bottom: 20px;
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
      .ant-checkbox-inner {
        width: 25px;
        height: 25px;
        border-radius: 6px;
        border-width: 2px;
        &::after {
          width: 8px;
          height: 14px;
        }
      }
      &__bold {
        font-weight: 600;
      }
      &__light {
        color: ${({ theme }) => theme.text.mainL2};
      }
    }
  }
  .cancel-button {
    color: ${({ theme }) => theme.palette.primary};
    border-color: ${({ theme }) => theme.palette.primary};
  }
`;

export const HourEditStyles = styled.div``;

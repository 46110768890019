import React from 'react';
import { Button } from 'antd';
import { useHistory } from 'react-router';
import { resetCreateJobTemplateStep } from 'redux/jobs/jobTemplates/slice';
import { useDispatch } from 'react-redux';
import { CreateTemplateActionWrapper } from 'containers/JobTemplates/components/StepContent/CreateTemplateAction/styles';
import { PlusCircleOutlined, RightOutlined } from '@ant-design/icons';
import i18next from 'i18next';
import PageTitle from 'components/common/PageTitle';
import { CopyIcon } from 'components/common/SVGIcon';
import {
  CREATE_EXISTING_TEMPLATE,
  CREATE_NEW_TEMPLATE,
} from 'containers/JobTemplates/constants';

const CreateTemplateAction = () => {
  const { push } = useHistory();
  const dispatch = useDispatch();

  const handleOnCreateButtonClick = (action) => {
    switch (action) {
      default:
      case CREATE_NEW_TEMPLATE:
        (() => {
          dispatch(resetCreateJobTemplateStep());
          push(`/jobTemplates/create/step`);
        })();
        break;
      case CREATE_EXISTING_TEMPLATE:
        (() => {
          dispatch(resetCreateJobTemplateStep());
          push(`/jobTemplates/create/jobExistingTemplate`);
        })();
        break;
    }
  };
  return (
    <CreateTemplateActionWrapper className="flex">
      <PageTitle>
        {i18next.t('jobs.createJobTemplateStep.createOptionsTitle')}
      </PageTitle>
      <Button
        className="start-btn flex"
        onClick={() => handleOnCreateButtonClick(CREATE_NEW_TEMPLATE)}
      >
        <span className="btn-content">
          <PlusCircleOutlined />
          <span>{i18next.t('jobs.createJobTemplateStep.new')}</span>
        </span>
        <RightOutlined />
      </Button>
      <Button
        className="start-btn flex"
        onClick={() => handleOnCreateButtonClick(CREATE_EXISTING_TEMPLATE)}
      >
        <span className="btn-content">
          <CopyIcon />
          <span>{i18next.t('jobs.createJobTemplateStep.existing')}</span>
        </span>
        <RightOutlined />
      </Button>
    </CreateTemplateActionWrapper>
  );
};

export default CreateTemplateAction;

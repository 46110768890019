import styled from 'styled-components';

const ListStyles = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;

  .container-contact {
    flex-grow: 1;
    position: relative;
  }

  .divider-board {
    margin-top: 0 !important;
    border-color: #e6eaef !important;
  }
`;

export default ListStyles;

import styled from 'styled-components';

const KindiRatingStyles = styled.div`
  font-size: 20px;
  color: #ffffff;
  padding: ${({ isRatingImage }) =>
    isRatingImage ? '0 !important' : '4px 6px'};
  background: var(--primary);
  border-radius: 8px;
  display: inline-flex;
  &.kindicare-rating-medium {
    background: ${({ theme }) => theme.palette.primary};
    > img {
      padding: ${({ isRatingImage }) =>
        isRatingImage ? '8px 0 4px 0' : 'unset'};
    }
  }

  &.kindicare-rating-large {
    > img {
      padding: ${({ isRatingImage }) =>
        isRatingImage ? '12px 0 7px 0' : 'unset'};
    }
  }
`;

export default KindiRatingStyles;

import styled from 'styled-components';

interface TagCustomStyle {
  isBorder: boolean;
}

const TagCustomWrapper = styled.div<TagCustomStyle>`
  border: ${({ isBorder }) => (isBorder ? 'solid' : 'none')};
  border-width: 1px;
  align-items: center;
  display: inline-flex;
  line-height: 26px;
  border-radius: 8px;
  padding: 2px 10px;
  overflow: hidden;
  max-width: 100%;
  > div {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 100%;
  }

  .anticon {
    margin-right: 5px;
  }
`;

export default TagCustomWrapper;

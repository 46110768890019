import styled from 'styled-components';

export const CardStyle = styled.div`
  .card-item-selected {
    border: 1px solid ${({ theme }) => theme.palette.primary};
    &::after {
      content: '\u2714';
      font-weight: bold;
      color: #fff;
      font-size: 16px;
      position: absolute;
      top: 0px;
      right: 0px;
      background: ${({ theme }) => theme.background.primary};
      transform: translate(-30%, 5%);
      z-index: 1;
    }
    &::before {
      content: '';
      background: transparent;
      border-style: solid;
      border-width: 22px 22px;
      border-color: #db147f #db147f transparent transparent;
      position: absolute;
      top: 0px;
      right: 0px;
      border-radius: 0 6px 0 0;
      z-index: 1;
    }
  }
  .card-item {
    border: 1px solid #dcdcdc !important;
  }
`;

import i18next from 'i18next';
import React from 'react';

import { GuideContentStyles } from './styles';

const GuideContent = () => (
  <GuideContentStyles className="guide-content">
    {i18next.t('forgotPassword.guideContent.l1')}
    <span className="guide-content__username">
      {i18next.t('forgotPassword.guideContent.l2')}
    </span>
    {i18next.t('forgotPassword.guideContent.l3')}
  </GuideContentStyles>
);

export default GuideContent;

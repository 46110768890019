import styled from 'styled-components';

export const MapMultiMarketStyles = styled.div`
  position: relative;

  .container-loading {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #00000073;
    display: none;
    justify-content: center;
    align-items: center;
  }
  &.map-multi-loading .container-loading {
    display: flex;
  }

  #watermark-container {
    position: absolute !important;
    top: 10px !important;
    right: 60px !important;
  }
`;

export const MarketDefaultStyles = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getJobApplicationSummary } from 'redux/jobs/jobApplications/actions';
import { useParams } from 'react-router';
import { getDataJobApplicationSummary } from 'redux/jobs/jobApplications/selectors';
import MyCertifications from './MyCertifications';
import MyDocuments from './MyDocuments';
import MyEducationAndQualifications from './MyEducationAndQualifications';
import MyImmunisations from './MyImmunisations';
import MySkills from './MySkills';
import MyWorkExperience from './MyWorkExperience';

const SummaryTab = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const summary = useSelector(getDataJobApplicationSummary) || {};

  useEffect(() => {
    if (id) dispatch(getJobApplicationSummary({ id }));
  }, [id, dispatch]);

  return (
    <>
      {!!summary?.experiences?.length && (
        <MyWorkExperience workExperience={summary?.experiences} />
      )}
      {!!summary?.qualifications?.length && (
        <MyEducationAndQualifications
          educationAndQualifications={summary?.qualifications}
        />
      )}
      {!!summary?.certification?.length && (
        <MyCertifications certifications={summary?.certification} />
      )}
      {!!summary?.immunisation?.length && (
        <MyImmunisations immunisations={summary?.immunisation} />
      )}

      {!!summary?.skills?.length && <MySkills skills={summary?.skills} />}

      {!!summary?.document && <MyDocuments document={summary?.document} />}
    </>
  );
};

export default SummaryTab;

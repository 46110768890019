import {
  FilterValue,
  SorterResult,
  SortOrder,
  TablePaginationConfig,
} from 'antd/lib/table/interface';
import { FilterIcon } from 'components/common/SVGIcon';
import TableCustom from 'components/common/TableCustom';
import { ACTIVE_TYPES, VACANCY_COD } from 'configs/localData';
import {
  LABEL_TABLE_KEY,
  ORDER_BY_TYPE,
  SORT_ASCEND_VALUE,
  SORT_DESCEND_VALUE,
} from 'configs/localData/constant';
import {
  DEFAULT_CURRENT_PAGE,
  LIMIT_ITEM,
} from 'containers/Advertisement/constants';
import ProgramColumn from 'containers/Centres/components/CentreTabs/ServicesTab/components/ProgramColumn';
import useFormatFnc from 'containers/Centres/components/CentreTabs/ServicesTab/useFormat';
import {
  CentreServiceControllerGetManyApiArg,
  CentreServiceView,
  useCentreServiceControllerGetManyQuery,
} from 'generated/apis';
import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router';
import {
  getServiceAgeGroups,
  getServiceTypes,
  getSessionTypes,
} from 'redux/config/selectors';
import { RootState } from 'redux/store';
import { getFilterFromUrl, getSearchUrl } from 'utils/tools';
import { filterSelect, formatLabelTable } from '../utils';

interface Props {
  isProgramEdit?: boolean;
  isProgramCreate?: boolean;
}

const ServicesTable = ({ isProgramEdit, isProgramCreate }: Props) => {
  const { t } = useTranslation();
  const { location, push } = useHistory();
  const pathName = location?.pathname;
  const useFormat = useFormatFnc();
  const { search } = useLocation();
  const { id } = useParams();
  const { filter: query } = getFilterFromUrl(search);

  const [payload, setPayload] = useState<CentreServiceControllerGetManyApiArg>({
    page: DEFAULT_CURRENT_PAGE,
    size: LIMIT_ITEM,
    centreId: id,
  });

  const { data, isFetching } = useCentreServiceControllerGetManyQuery(payload);
  const serviceTypes = useSelector(getServiceTypes);
  const sessionTypes = useSelector(getSessionTypes);
  const serviceAgeGroups = useSelector(getServiceAgeGroups);
  const appSettings = useSelector(
    (state: RootState) => state.config?.data?.AppSetting,
  );
  const ageGroupLabel = useMemo(
    () => formatLabelTable(LABEL_TABLE_KEY.ageGroup, appSettings),
    [appSettings],
  );

  const servicesFilter = useMemo(
    () => serviceTypes.map((item) => ({ value: item.id, text: item.name })),
    [serviceTypes],
  );

  const sessionFilter = useMemo(
    () => sessionTypes.map((item) => ({ value: item.id, text: item.name })),
    [sessionTypes],
  );

  const vacancyFormat = useMemo(
    () =>
      VACANCY_COD.map((item) => ({
        value: item.value,
        text: t(item.text),
      })),
    [t],
  );

  const activesFormat = useMemo(
    () =>
      ACTIVE_TYPES.map((item) => ({
        value: item.value,
        text: t(item.text),
      })),
    [t],
  );

  const ageGroupFormat = useMemo(
    () =>
      serviceAgeGroups.map((item) => ({
        value: item.id,
        text: item.name,
      })),
    [serviceAgeGroups],
  );

  const getSorterOrder = useCallback(
    (source): SortOrder => {
      if (query.sortKey === source) {
        if (query.sortOrder === ORDER_BY_TYPE.ASC) return SORT_ASCEND_VALUE;
        if (query.sortOrder === ORDER_BY_TYPE.DESC) return SORT_DESCEND_VALUE;
      }
      return null;
    },
    [query],
  );

  const columns = useMemo(
    () => [
      {
        title: t('centres.name'),
        dataIndex: 'serviceType',
        sorter: true,
        filters: servicesFilter,
        onFilter: (value, record) =>
          filterSelect(value, record, 'serviceTypeId'),
        filterIcon: <FilterIcon className="text-gray-400" />,
        defaultSortOrder: getSorterOrder('serviceType'),
        render: (value, record) => record.serviceType.name,
      },
      {
        title: ageGroupLabel,
        dataIndex: 'ageGroup',
        sorter: true,
        filters: ageGroupFormat,
        onFilter: (value, record) => filterSelect(value, record, 'ageGroup.id'),
        filterIcon: <FilterIcon className="text-gray-400" />,
        defaultSortOrder: getSorterOrder('ageGroup'),
        render: (value, record) => record.ageGroup.name,
      },
      {
        title: formatLabelTable(LABEL_TABLE_KEY.sessionType, appSettings),
        dataIndex: 'sessionType',
        sorter: true,
        filters: sessionFilter,
        onFilter: (value, record) =>
          filterSelect(value, record, 'sessionTypeId'),
        filterIcon: <FilterIcon className="text-gray-400" />,
        defaultSortOrder: getSorterOrder('sessionType'),
        render: (value, record) => record.sessionType.name,
      },
      {
        title: t('programLearning.programName'),
        dataIndex: 'program',
        sorter: true,
        defaultSortOrder: getSorterOrder('program'),
        render: (value, record) =>
          value ? (
            <ProgramColumn
              isProgramEdit={isProgramEdit}
              id={record?.program?.id}
              name={record?.program?.name}
            />
          ) : (
            '-'
          ),
      },
      {
        title: t('centres.room'),
        dataIndex: 'roomName',
        sorter: true,
        render: useFormat.formatRoomName,
        defaultSortOrder: getSorterOrder('roomName'),
      },
      {
        title: t('centres.capacity'),
        dataIndex: 'capacity',
        render: useFormat.formatCapacity,
        sorter: true,
        width: 120,
        defaultSortOrder: getSorterOrder('capacity'),
      },
      {
        title: t('centres.costPerSession'),
        dataIndex: 'costPerDay',
        render: useFormat.formatCostPerDay,
        sorter: true,
        width: 120,
        defaultSortOrder: getSorterOrder('costPerDay'),
      },
      {
        title: t('centres.vacancy'),
        dataIndex: 'vacancyCod',
        render: useFormat.formatVacancy,
        sorter: true,
        filters: vacancyFormat,
        onFilter: (value, record) => filterSelect(value, record, 'vacancyCod'),
        filterIcon: <FilterIcon className="text-gray-400" />,
        width: 120,
        defaultSortOrder: getSorterOrder('vacancyCod'),
      },
      {
        title: t('centres.status'),
        dataIndex: 'isActive',
        render: useFormat.formatIsActive,
        sorter: true,
        filters: activesFormat,
        onFilter: (value, record) => filterSelect(value, record, 'isActive'),
        filterIcon: <FilterIcon className="text-gray-400" />,
        width: 120,
        defaultSortOrder: getSorterOrder('isActive'),
      },
      {
        title: '',
        dataIndex: 'id',
        width: 100,
        fixed: 'right',
        render: (data, record) =>
          useFormat.formatGroupAction(
            data,
            record,
            isProgramEdit,
            isProgramCreate,
          ),
      },
    ],
    [
      t,
      servicesFilter,
      getSorterOrder,
      ageGroupLabel,
      ageGroupFormat,
      appSettings,
      sessionFilter,
      useFormat,
      vacancyFormat,
      activesFormat,
      isProgramEdit,
      isProgramCreate,
    ],
  );

  const convertSortOrder = (
    sort: SortOrder,
  ): CentreServiceControllerGetManyApiArg['sortOrder'] => {
    switch (sort) {
      case 'ascend':
        return 'ASC';
      case 'descend':
        return 'DESC';
      default:
        return null;
    }
  };

  const pushQuery = useCallback(
    (query: CentreServiceControllerGetManyApiArg) => {
      push(`${pathName}?${getSearchUrl(query)}`);
    },
    [pathName, push],
  );

  const onChange = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<CentreServiceView>,
  ) => {
    const sortKey =
      sorter.field?.toString() as CentreServiceControllerGetManyApiArg['sortKey'];
    const sortOrder = convertSortOrder(sorter.order);

    const restFilter: CentreServiceControllerGetManyApiArg = {
      centreId: id,
      sortKey: sortOrder ? sortKey : null,
      sortOrder,
      size: pagination.pageSize,
      page: pagination.current,
    };

    setPayload((prev) => ({
      ...prev,
      ...restFilter,
    }));

    pushQuery(restFilter);
  };

  return (
    <TableCustom
      xScroll={1300}
      filterIcon={<FilterIcon className="text-gray-400" />}
      data={data?.result}
      loading={isFetching}
      columns={columns}
      onChange={onChange}
      pagination={{
        pageSize: payload.size || LIMIT_ITEM,
        current: payload?.page || DEFAULT_CURRENT_PAGE,
        total: data?.total,
      }}
    />
  );
};

export default ServicesTable;

import React, { useRef, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Input, Form } from 'antd';
import { getSummariesEnquiry, sendMessage } from 'redux/enquiries/actions';
import { getAllDocuments } from 'redux/applications/actions';
import i18next from 'i18next';
import { useParams } from 'react-router';
import { omit } from 'lodash-es';
import { SendIcon } from 'components/common/SVGIcon';
import { ENQUIRIES_STATUS_CONST } from 'configs/localData';
import { enquiriesSelectors } from 'redux/enquiries/selectors';
import PreviewFiles from '../UploadFileMessage/PreviewFiles';
import UploadFileMessage from '../UploadFileMessage';
import { useFilesMessage } from '../UploadFileMessage/context';
import { FooterFormStyles } from './styles';

const sourceFiles = 'files';

const MessageFooter = ({
  id,
  isUploadFile,
  isRefreshDocument,
  applicationId,
  centreResponded,
}) => {
  const dispatch = useDispatch();

  const { tab } = useParams();

  const ref = useRef(null);

  const { clearFileList } = useFilesMessage();

  const [form] = Form.useForm();

  const { validateFields, resetFields, setFieldsValue } = form;

  const [isDisabledSubmit, setIsDisabledSubmit] = useState(false);

  const [loading, setLoading] = useState(false);

  const resourceFilter = useSelector(enquiriesSelectors.getFilters) || {};

  const currentInbox = useSelector(enquiriesSelectors.getCurrentData) || {};

  const formatListFile = useCallback(
    (files) =>
      files
        ?.filter((item) => item?.status !== 'error')
        ?.map((file) => omit(file, 'status')),
    [],
  );

  const onSubmit = () => {
    if (!id) return;
    validateFields().then(({ files, message }) => {
      const filesFormat = formatListFile(files);
      setLoading(true);
      dispatch(
        sendMessage({
          id,
          data: {
            ...(message?.trim() !== '' && { message }),
            ...(filesFormat?.length > 0 && {
              files: filesFormat,
            }),
          },
          centreResponded,
          isRemoveItem:
            (!centreResponded &&
              tab === ENQUIRIES_STATUS_CONST.notResponded.key) ||
            tab === ENQUIRIES_STATUS_CONST.archived.key,
        }),
      )
        .then(({ payload }) => {
          if (payload?.data?.id && currentInbox?.archivedAt) {
            dispatch(
              getSummariesEnquiry({
                data: { q: resourceFilter?.q, filter: resourceFilter?.filter },
              }),
            );
          }

          if (applicationId && isRefreshDocument && filesFormat?.length > 0) {
            dispatch(
              getAllDocuments({
                data: { id: applicationId, orderBy: '-createdAt' },
                options: { isRefresh: true },
              }),
            );
          }

          clearFileList();

          setLoading(false);

          const messageBody = document.getElementById('message-body');

          if (messageBody) {
            messageBody.scrollTop = messageBody.scrollHeight;
          }

          resetFields();

          const timeout = setTimeout(() => {
            ref.current.focus();
            clearTimeout(timeout);
          }, 50);
        })
        .catch(() => setLoading(false));
    });
  };

  const handlePressEnter = (evt) => {
    if (
      evt.keyCode === 13 &&
      (evt.ctrlKey || evt.metaKey) &&
      !isDisabledSubmit
    ) {
      evt.preventDefault();
      onSubmit();
    }
  };

  return (
    <FooterFormStyles>
      <PreviewFiles source={sourceFiles} setFieldsValue={setFieldsValue} />
      <Form onFinish={onSubmit} form={form}>
        <div className="content-type-text">
          {isUploadFile && (
            <UploadFileMessage
              form={form}
              source={sourceFiles}
              setIsDisabledSubmit={setIsDisabledSubmit}
            />
          )}
          <Form.Item noStyle name="message">
            <Input.TextArea
              ref={ref}
              placeholder={i18next.t('enquiries.typeMessage')}
              onPressEnter={handlePressEnter}
              autoSize={{ minRows: 1, maxRows: 3 }}
              bordered={false}
            />
          </Form.Item>
        </div>

        <Form.Item>
          <Button
            className="button-submit"
            disabled={isDisabledSubmit}
            htmlType="submit"
            icon={<SendIcon />}
            loading={loading}
          />
        </Form.Item>
      </Form>
    </FooterFormStyles>
  );
};

MessageFooter.propTypes = {
  id: PropTypes.string,
  isUploadFile: PropTypes.bool,
  isRefreshDocument: PropTypes.bool,
  applicationId: PropTypes.string,
  centreResponded: PropTypes.bool,
};

export default MessageFooter;

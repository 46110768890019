import styled from 'styled-components';

export const DiamondPointStyle = styled.div`
  .diamond {
    width: 10px;
    height: 10px;
    background-color: ${({ theme }) => theme.palette.green};
    transform: rotate(45deg);
    border-radius: 2px;
    margin-bottom: 18px;
  }
`;

import React from 'react';
import ViewFileModal from 'components/RestField/ModalViewFile';
import { useViewFileModal } from './context';

const ViewDocumentModal = () => {
  const { visible, toggleModal, file } = useViewFileModal();

  return <ViewFileModal visible={visible} file={file} onCancel={toggleModal} />;
};

export default ViewDocumentModal;

import React, { useEffect, useContext } from 'react';
import { Col, Row } from 'antd';
import ReferenceInputV2 from 'containers/rest/ReferenceInputV2';
import RestSelect from 'components/RestInput/RestSelect';
import RestRangePickerInput from 'components/RestInput/RestRangePickerInput';
import { RestInputContext } from 'components/RestInput/RestInputContext';
import { useLocation } from 'react-router';
import { getFilterFromUrl } from 'utils/tools';
import moment from 'moment';
import { JOB_LISTING_STATUS } from 'containers/JobListings/constants';
import i18next from 'i18next';
import { PREFIX_API_VER_2, PREFIX_URL } from 'configs/localData/constant';

const FilterForm = () => {
  const { form } = useContext(RestInputContext);
  const { search } = useLocation();

  useEffect(() => {
    const { filter } = getFilterFromUrl(search) || {};
    const { lastUpdatedAt, ...restFilter } = filter?.filter || {};

    form.setFieldsValue({
      lastUpdatedAt: Object.values(lastUpdatedAt || {}).map((item) =>
        moment(item),
      ),
      ...restFilter,
    });
  }, [search, form]);

  return (
    <Row gutter={16}>
      <Col lg={8} md={12} xs={24}>
        <ReferenceInputV2
          prefixUrl={PREFIX_URL.crm}
          resource="job-seekers/campaigns/basic-info"
          source="name"
          searchKey="name"
          notLikeFilter
          requestApi={PREFIX_API_VER_2}
          mappedBy="name"
        >
          <RestSelect
            valueProp="name"
            titleProp="name"
            placeholder="jobListings.campaign"
            isFilterOption={false}
            isShowTooltip
            autoComplete="off"
          />
        </ReferenceInputV2>
      </Col>
      <Col lg={8} md={12} xs={24}>
        <RestSelect
          source="jobListingStatus"
          placeholder="jobListings.jobListingStatus"
          resourceData={JOB_LISTING_STATUS}
          formatText={(data) => i18next.t(data)}
          valueProp="value"
          titleProp="text"
          isShowTooltip
        />
      </Col>
      <Col lg={8} md={12} xs={24}>
        <RestRangePickerInput
          sourceGt="lastUpdatedAt.from"
          sourceLt="lastUpdatedAt.to"
          source="lastUpdatedAt"
          placeholder="jobListings.lastUpdated"
          isShowTooltip
          isShowRangesFooter
        />
      </Col>
    </Row>
  );
};

export default FilterForm;

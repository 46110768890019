import React from 'react';
import { useSelector } from 'react-redux';
import { TotalApplicationsIcon } from 'components/common/SVGIcon';

import SummaryView from 'components/common/SummaryCardDetail/SummaryView';

const SummaryApplication = () => {
  const summaries =
    useSelector(
      (state) => state.dashboard.applications.data?.applicationsItem,
    ) || {};

  const data = [
    {
      title: 'centreDetail.openApplicationValue',
      value: summaries.openApplicationsValue,
      isPerAnnum: true,
    },
    {
      title: 'centreDetail.totalWaitlisted',
      value: summaries.totalWaitlisted,
    },
    {
      title: 'centreDetail.waitlistValue',
      value: summaries.waitlistedValue,
      isPerAnnum: true,
    },
  ];

  return (
    <SummaryView
      title="applications.header"
      data={data}
      IconComponent={TotalApplicationsIcon}
      color="#F2994A"
      total={summaries.totalApplications}
    />
  );
};

export default SummaryApplication;

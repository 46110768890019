import styled from 'styled-components';

interface ViewButtonProps {
  contactId: boolean;
}

export const LeadActivitiesListStyle = styled.div`
  .link-class {
    &:hover,
    &:active {
      color: ${({ theme }) => theme.palette.primary} !important;
    }
  }
`;

export const ViewButtonStyles = styled.div<ViewButtonProps>`
  .ant-btn {
    border: 0px;
    color: ${({ theme, contactId }) =>
      contactId ? theme.text.gray700 : theme.text.gray300} !important;
    background: transparent !important;
    box-shadow: none;
    height: 32px !important;
    width: 32px !important;
    min-width: 32px !important;
    .anticon {
      font-size: 20px;
    }
  }
  &:hover,
  &:focus {
    background: transparent;
    .anticon {
      color: ${({ theme, contactId }) =>
        contactId && theme.palette.primary} !important;
    }
  }
`;

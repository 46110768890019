import { ROLES_CONST } from 'configs/localData/permissions';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { checkRole } from 'utils/tools';
import { useDeleteStaffProfileMutation } from 'redux/@rtkQuery/educatorStaffProfile';
import { Modal } from 'antd';
import i18next from 'i18next';
import { getCurrentRole, getRoleDetail } from 'redux/auth/selectors';

const useModifyStaffProfile = () => {
  const currentRole = useSelector(getCurrentRole);
  const currentRoleDetail = useSelector(getRoleDetail);

  const [deleteProfile, { isLoading: deleteLoading }] =
    useDeleteStaffProfileMutation();

  const isModifyStaffProfile = useMemo(
    () =>
      checkRole(
        [
          ROLES_CONST.companyAdmin,
          ROLES_CONST.companyStaff,
          ROLES_CONST.providerAdmin,
          ROLES_CONST.providerStaff,
          ROLES_CONST.centreAdmin,
        ],
        currentRole,
      ),
    [currentRole],
  );

  const checkModifyStaffProfileExisting = (roleRank: number) => {
    if (currentRole === ROLES_CONST.centreStaff) return false;
    return currentRoleDetail?.rank <= roleRank;
  };

  const confirmDeleteProfile = (staffProfileId: string) => {
    Modal.confirm({
      title: i18next.t('educatorStaffProfile.deleteProfileQuestion'),
      content: i18next.t('educatorStaffProfile.deleteProfileConfirm'),
      okText: i18next.t('button.delete'),
      onOk: () => deleteProfile({ id: staffProfileId }),
    });
  };

  return {
    isModifyStaffProfile,
    deleteLoading,
    deleteProfile,
    confirmDeleteProfile,
    checkModifyStaffProfileExisting,
  };
};

export default useModifyStaffProfile;

import React from 'react';
import i18next from 'i18next';
import PropTypes from 'prop-types';
import { LegendBarChartStyle } from './styles';

const LegendBarChart = ({ payload }) => (
  <LegendBarChartStyle>
    <div className="legend-bar-row">
      {payload?.map((item, index) => (
        <div span={12} key={String(index)} className="flex items-center ml-40">
          <div
            className="w-16 h-16 mr-10"
            style={{ background: item?.color || item?.payload?.fill }}
          />
          <span className="text-16">
            {i18next.t(item?.payload?.text) || item?.value}
          </span>
        </div>
      ))}
    </div>
  </LegendBarChartStyle>
);

LegendBarChart.propTypes = {
  payload: PropTypes.array,
};

export default LegendBarChart;

import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  getUserCompanyBenchmarkingChartApi,
  getComapnyBenchmarkingSummaryApi,
  getCompareRatingApi,
} from 'api/insights';
import { makeActions } from 'redux/crudCreator';
import { apiWrapper } from 'utils/reduxUtils';

export const MODEL_NAME = 'companyBenchmarking';
export const companyBenchmarkingActions = makeActions(MODEL_NAME);
export const getAllCompanyBenchmarking = companyBenchmarkingActions.getAll;

export const getCompanyBenchmarkingSummary = createAsyncThunk(
  `${MODEL_NAME}/getSummary`,
  async (payload, thunkAPI) => {
    try {
      const response = await apiWrapper(
        {},
        getComapnyBenchmarkingSummaryApi,
        payload,
      );
      return { data: response };
    } catch (error) {
      return thunkAPI.rejectWithValue({ data: error });
    }
  },
);

export const getUserCompanyBenchmarkingChart = createAsyncThunk(
  `${MODEL_NAME}/user-company-charts`,
  async (payload, thunkAPI) => {
    try {
      const response = await apiWrapper(
        {},
        getUserCompanyBenchmarkingChartApi,
        payload,
      );
      return { data: response };
    } catch (error) {
      return thunkAPI.rejectWithValue({ data: error });
    }
  },
);

export const getCompanyBenchmarkingCompareRatingData = createAsyncThunk(
  `${MODEL_NAME}/compareRating`,
  async (payload, thunkAPI) => {
    try {
      const response = await apiWrapper({}, getCompareRatingApi, payload);
      return { data: response };
    } catch (error) {
      return thunkAPI.rejectWithValue({ data: error });
    }
  },
);

import React from 'react';
import PropTypes from 'prop-types';
import {
  Skeleton,
  Avatar,
  Row,
  Col,
  Typography,
  Progress,
  Tooltip,
} from 'antd';
import i18next from 'i18next';
import { PictureOutlined, EnvironmentOutlined } from '@ant-design/icons';

import {
  ChildIcon,
  WaitlistIcon,
  KindiCareIcon,
  ServiceIcon,
} from 'components/common/SVGIcon';
import KindiRating from 'components/common/KindiRating';

import { formatDateFromNow } from 'utils/textUtils';
import { getFeaturedImage } from 'utils/dataUtils';
import { formatSubscription } from 'utils/formatFieldUtils';
import { CENTRE_STATUS_VALUE } from 'configs/localData';
import useCRMPackageOfCentre from 'hooks/useCRMPackageOfCentre';
import { useHistory } from 'react-router';
import CentresCardStyles from './styles';

const CentresCard = ({ record }) => {
  const name =
    useCRMPackageOfCentre(record?.id)?.name || record?.CRMPackage?.name;
  const { push } = useHistory();

  const redirectDetail = () => {
    localStorage.removeItem('currentStep');
    record?.id && push(`/centres/${record?.id}/show/summary`);
  };
  return (
    <CentresCardStyles>
      <Skeleton loading={!record} active>
        <Typography.Link onClick={redirectDetail}>
          <>
            <div className="centre-image">
              <Avatar
                icon={<PictureOutlined />}
                src={getFeaturedImage(record?.images)}
              />
              <KindiRating className="rating" value={record?.kindiCareRating} />
              <Row>
                <img
                  alt=""
                  src={record?.NQSRType?.image}
                  className="nqs-rating-img"
                />
                <Tooltip title={i18next.t('centres.listingStrength')}>
                  <Progress
                    className="indicator-progress"
                    strokeLinecap="square"
                    type="circle"
                    percent={record?.totalListingStrength}
                    width={55}
                    format={(data) => `${data}%`}
                  />
                </Tooltip>
              </Row>
            </div>
            <div className="content-card">
              <div className="last-updated">
                {i18next.t('centres.lastUpdated', {
                  time: formatDateFromNow(record?.updatedAt),
                })}
              </div>
              <div className="name-centre">
                {record?.name || i18next.t('error.waitingUpdate')}
              </div>
              <Row gutter={[10, 10]} className="info-content">
                <Col span={24} className="address item-info">
                  <EnvironmentOutlined />
                  <span className="value-item">
                    {record?.displayAddress || i18next.t('error.waitingUpdate')}
                  </span>
                </Col>
                <Col span={12} className="item-info">
                  <ChildIcon />
                  <span className="value-item">
                    {`${record?.placeAllowed || 0} ${i18next.t(
                      'centres.childrenOpacity',
                    )}`}
                  </span>
                </Col>
                <Col span={12} className="item-info">
                  <WaitlistIcon />
                  <span className="value-item">
                    {record?.totalWaitListed || 0}
                  </span>
                </Col>
                {record?.claimStatus === CENTRE_STATUS_VALUE.claimed && (
                  <Col span={12} className="item-info">
                    <KindiCareIcon />
                    {formatSubscription(name)}
                  </Col>
                )}

                <Col span={12} className="item-info">
                  <ServiceIcon />
                  <span className="value-item">
                    {record?.totalServices || 0}
                  </span>
                </Col>
              </Row>
            </div>
          </>
        </Typography.Link>
      </Skeleton>
    </CentresCardStyles>
  );
};

CentresCard.propTypes = {
  record: PropTypes.object,
};

CentresCard.defaultProps = {
  record: {},
};

export default CentresCard;

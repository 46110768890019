import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { getCentresObjKeyById } from 'redux/auth/selectors';
import { CentreNameInfoStyles } from './styles';

const CentreNameInfo = ({ centreId }) => {
  const centreName = useSelector(getCentresObjKeyById)?.[centreId]?.name;

  return <CentreNameInfoStyles>{centreName}</CentreNameInfoStyles>;
};

CentreNameInfo.propTypes = {
  centreId: PropTypes.string,
};

export default CentreNameInfo;

import React, { useCallback, useMemo } from 'react';
import i18n from 'i18next';
import { Select, Spin } from 'antd';
import { Waypoint } from 'react-waypoint';
import { Dictionary, isEmpty, isObject, map } from 'lodash';
import { getRecordData, onSearch as onChangeSearch } from 'utils/tools';
import FormItem from 'components/form/FormItem';
import { SelectProps } from 'antd/lib/select';
import { NamePath } from 'rc-field-form/lib/interface';
import { SelectStyles } from './style';

const { Option } = Select;

type ResourceDataType = Dictionary<unknown> | unknown[];

export interface FormSelectProps extends SelectProps {
  SelectElement?: React.FunctionComponent<SelectProps>;
  allowClear?: boolean;
  header?: string;
  required?: boolean;
  placeholder?: string;
  resourceData?: ResourceDataType;
  valueProp?: string;
  titleProp?: string;
  onSearch?: (value?: unknown) => void;
  onClear?: () => void;
  onChange?: (value?: unknown, option?: unknown) => void;
  formatText?: (value?: unknown, data?: any) => string;
  onEnter?: (value?: unknown) => void;
  isFilterOption?: boolean;
  isShowSearch?: boolean;
  searchKey?: string;
  loading?: boolean;
  autoComplete?: string;
  source?: NamePath;
  format?: (data?: ResourceDataType) => ResourceDataType;
  children?: React.ReactElement;
  [key: string]: unknown;
}

const FormSelect = ({
  SelectElement,
  allowClear = true,
  header,
  required = false,
  placeholder = 'placeholder.undefined',
  resourceData,
  valueProp,
  titleProp,
  onSearch,
  onClear,
  onChange,
  formatText = (data: string) => data,
  onEnter,
  isFilterOption = true,
  isShowSearch = true,
  searchKey,
  loading,
  autoComplete = 'new-password',
  source,
  children,
  className,
  disabled,
  format,
  ...props
}: FormSelectProps) => {
  const onSelectOption = useCallback(
    (inputValue, option) => {
      if (
        onChangeSearch(
          isObject(option.children)
            ? getRecordData(
                option.children.props && option.children.props.record,
                searchKey,
              )
            : option.children,
          inputValue,
        )
      ) {
        return option.value;
      }
      return null;
    },
    [searchKey],
  );

  const dataOption = useMemo<ResourceDataType>(
    () => (format ? format(resourceData) : resourceData),
    [resourceData, format],
  );

  const optionWaypoint = useMemo(
    () => (
      <Option
        className="loading-select-option"
        disabled
        value="waypointTracking"
        key="waypoint"
      >
        <Waypoint onEnter={onEnter} />
      </Option>
    ),
    [onEnter],
  );

  const optionLoading = useMemo(
    () => (
      <Option
        className="loading-select-option"
        disabled
        value="loadingTracking"
        key="loading"
      >
        <div className="loading-select">
          <Spin />
        </div>
      </Option>
    ),
    [],
  );

  const SelectType = SelectElement || SelectStyles;

  return (
    <FormItem
      {...props}
      placeholder={i18n.t(placeholder)}
      header={i18n.t(header)}
      required={required}
      name={source}
      autoComplete={autoComplete}
    >
      <SelectType
        {...props}
        disabled={disabled}
        placeholder={i18n.t(placeholder)}
        filterOption={isFilterOption ? onSelectOption : false}
        showSearch={isShowSearch}
        allowClear={allowClear}
        className={className}
        {...(isShowSearch && {
          onSearch: (value) => {
            if (onSearch) onSearch(value);
            return value;
          },
        })}
        {...(allowClear && { onClear })}
        onChange={onChange}
      >
        {map(dataOption, (data, index) =>
          children ? (
            <Option
              key={String(index)}
              value={valueProp ? getRecordData(data, valueProp) : data}
            >
              {React.cloneElement(children, {
                key: String(index),
                record: data,
                valueProp,
                titleProp,
              })}
            </Option>
          ) : (
            <Option
              key={String(index)}
              value={valueProp ? getRecordData(data, valueProp) : data}
            >
              {formatText(
                titleProp ? getRecordData(data, titleProp) : data,
                data,
              )}
            </Option>
          ),
        )}
        {!isEmpty(dataOption) && !loading && optionWaypoint}
        {loading && optionLoading}
      </SelectType>
    </FormItem>
  );
};

export default FormSelect;

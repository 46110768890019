import styled from 'styled-components';

export const EnrolmentPipelineStyles = styled.div`
  .ant-progress {
    display: flex;
    align-items: center;
  }
  .progress-item {
    + .progress-item {
      margin-top: 15px;
    }

    &__title {
      font-weight: bold;
      font-size: 16px;
      line-height: 20px;
      margin-bottom: 5px;
    }
    .ant-progress-text {
      width: 150px;
      font-weight: bold;
      font-size: 16px;
      overflow: hidden;
      text-overflow: ellipsis;
      text-align: right;
      color: ${({ theme }) => theme.text.primary} !important;
    }
  }
`;

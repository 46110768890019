import React from 'react';
import i18next from 'i18next';
import { Tooltip } from 'antd';
import TagCustomWrapper from './styles';

interface Props {
  text?: string;
  color?: string;
  backgroundColor?: string;
  className?: string;
  name?: string;
  IconCPN?: string;
  borderColor?: string;
  isBorder?: boolean;
  isShowTooltip?: boolean;
  titleTooltipCustom?: string;
  width?: string | number;
  height?: string | number;
}

const TagCustom = ({
  text,
  color,
  backgroundColor,
  className,
  name,
  IconCPN,
  borderColor,
  isBorder,
  isShowTooltip,
  titleTooltipCustom,
  width,
  height,
}: Props) => (
  <Tooltip
    title={isShowTooltip && (titleTooltipCustom || name || i18next.t(text))}
  >
    <TagCustomWrapper
      className={className}
      isBorder={isBorder}
      style={{ backgroundColor, color, borderColor, width, height }}
    >
      {IconCPN && <IconCPN />}
      <div>{name || i18next.t(text)}</div>
    </TagCustomWrapper>
  </Tooltip>
);

export default TagCustom;

import styled from 'styled-components';

export const KindiCareWatermarkWrapper = styled.div`
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 150px;
  height: 90px;
  padding: 8px 0;
  background: rgba(255, 255, 255, 0.62);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(0.3px);
  -webkit-backdrop-filter: blur(0.3px);
  border: 1px solid rgba(255, 255, 255, 0.3);

  p {
    margin-top: 8px;
    color: ${({ theme }) => theme.text.formLabel};
    font-weight: 500;
    font-size: 13px;
  }

  .logo-container {
    width: 110px;
    height: 43px;

    & > img {
      width: 100%;
      height: 100%;
    }
  }

  .kc-link {
    margin-top: -5px;
    a {
      color: ${({ theme }) => theme.palette.blueLink};
      font-size: 11px;
      font-weight: 500;
    }
    .open-new-tab-icon {
      color: ${({ theme }) => theme.palette.blueLink};
      padding-left: 5px;

      & > span > svg {
        width: 10px;
        height: 10px;
      }
    }
  }
`;

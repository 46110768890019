import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import i18next from 'i18next';
import { signUpMerchantCallback } from 'redux/centres/actions';
import { Spin, Result } from 'antd';
import MerchantSignUpStyles from './styles';

const MerchantSignUp = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const [hasError, setHasError] = useState(false);

  const query = useSelector((state) => state.router?.location?.query);

  useEffect(() => {
    dispatch(
      signUpMerchantCallback({
        id,
        merchantIdInPaypal: query?.merchantIdInPayPal,
      }),
    ).then(({ error }) => {
      if (!error) {
        history.push(`/centres/${id}/show/summary`);
      } else {
        setHasError(true);
      }
    });
    // eslint-disable-next-line
  }, []);

  return (
    <MerchantSignUpStyles>
      {hasError ? (
        <Result status="warning" title={i18next.t('merchant.somethingWrong')} />
      ) : (
        <Spin />
      )}
    </MerchantSignUpStyles>
  );
};

export default MerchantSignUp;

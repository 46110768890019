import { createSelector } from '@reduxjs/toolkit';

const getRawDataJobApplications = (state) => state.jobApplications?.data;
const getRawJobApplicationsLoading = (state) => state.jobApplications?.loading;
const getRawResourceDataJobApplications = (state) =>
  state.jobApplications?.resourceData;

const getRawDataJobApplicationSummary = (state) =>
  state.jobApplications?.currentData.summary;

const getRawDataJobApplicationProfile = (state) =>
  state.jobApplications?.currentData?.profile;

const getRawJobApplicationSummary = (state) =>
  state.jobApplications?.getJobApplicationSummaryLoading;

const getRawLoadingJobApplicationProfile = (state) =>
  state.jobApplications?.getJobApplicationProfileLoading;

const getRawErrorJobApplicationProfile = (state) =>
  state.jobApplications?.jobApplicationsError;

const getSummaryJobApplications = (state) => state.jobApplications?.summary;

export const getDataJobApplications = createSelector(
  [getRawDataJobApplications],
  (data) => data,
);

export const getLoadingJobApplications = createSelector(
  [getRawJobApplicationsLoading],
  (data) => data,
);

export const getResourceDataJobApplications = createSelector(
  [getRawResourceDataJobApplications],
  (data) => data,
);

export const getDataJobApplicationSummary = createSelector(
  [getRawDataJobApplicationSummary],
  (data) => data,
);

export const getDataJobApplicationProfile = createSelector(
  [getRawDataJobApplicationProfile],
  (data) => data,
);

export const getLoadingJobApplicationProfile = createSelector(
  [getRawLoadingJobApplicationProfile],
  (data) => data,
);

export const getErrorApplicationProfile = createSelector(
  [getRawErrorJobApplicationProfile],
  (data) => data,
);

export const getLoadingJobApplicationSummary = createSelector(
  [getRawJobApplicationSummary],
  (data) => data,
);

export const getSummaryJobApplicationsCard = createSelector(
  [getSummaryJobApplications],
  (data) => data,
);

import React from 'react';
import { Card } from 'antd';
import PropTypes from 'prop-types';
import i18next from 'i18next';
import { CardCustomStyles } from './styles';

const CardCustom = ({ title, children, className, Icon }) => (
  <CardCustomStyles>
    <Card
      hoverable
      className={className}
      title={
        <div className="flex items-center">
          <div>{Icon && <Icon className="text-green text-24" />}</div>
          <div className="fw-bold text-24 ml-12 title">{i18next.t(title)}</div>
        </div>
      }
    >
      <div className="mt-10">{children}</div>
    </Card>
  </CardCustomStyles>
);

CardCustom.propTypes = {
  title: PropTypes.string,
  children: PropTypes.any,
  className: PropTypes.node,
  Icon: PropTypes.func,
};

export default CardCustom;

import React, { useCallback, useRef } from 'react';
import i18next from 'i18next';
import { formatDateTimeTable } from 'utils/textUtils';
import { Modal, Switch } from 'antd';
import { useDispatch } from 'react-redux';
import { editUsers } from 'redux/users/actions';

import List from 'containers/rest/List';
import RestFieldItem from 'components/RestField/RestFieldItem';
import ActionGroup from 'components/RestActions/ActionGroup';
import EditButton from 'components/RestActions/EditButton';
import DeleteButton from 'components/RestActions/DeleteButton';
import CentresModal from 'components/CentresViewAll/CentresModal';
import { getCentresOfUser } from 'redux/centresOfUser/actions';
import Filter from '../components/Filter';
import CentresUserField from '../components/CentresUserField';
import { formatUserName } from './formatField';

const UsersList = (props) => {
  const centresUserModalRef = useRef();

  const dispatch = useDispatch();

  const breadcrumbCustom = [
    {
      title: i18next.t('sideBar.settings'),
      path: '/config',
    },
    {
      title: i18next.t('users.header'),
    },
  ];

  const openCentresUserModal = useCallback((record) => {
    centresUserModalRef.current.open(record);
  }, []);

  const formatCentreName = useCallback(
    (data, record) => (
      <CentresUserField
        roleName={record?.role?.name}
        data={data}
        handleClickViewAll={() => openCentresUserModal(record)}
      />
    ),
    [openCentresUserModal],
  );

  const handleBlockUser = (value, record) => {
    Modal.confirm({
      title: i18next.t(
        record?.isBlocked
          ? 'users.unBlockUserConfirmTitle'
          : 'users.blockUserConfirmTitle',
      ),
      content: i18next.t(
        record?.isBlocked
          ? 'users.unlockUserConfirmContent'
          : 'users.blockUserConfirmContent',
      ),
      onOk() {
        return dispatch(
          editUsers({
            data: { id: record?.id, isBlocked: value },
          }),
        );
      },
    });
  };

  return (
    <div>
      <List
        {...props}
        resource="users"
        hasSearch={false}
        layoutButtonCreate="inline"
        filter={<Filter />}
        permissionKey="abilityChangeUserPermissions"
        isSkipSubscription
        widthTable="1500px"
        redirects={{
          edit: 'screen',
          create: 'screen',
        }}
        rootPath="/config"
        isShowBreadcrumb
        breadcrumbCustom={breadcrumbCustom}
      >
        <RestFieldItem
          source="firstName"
          header="users.name"
          format={formatUserName}
        />
        <RestFieldItem source="jobTitle" header="users.jobTitle" width="12%" />
        <RestFieldItem
          source="role.description"
          header="users.role"
          width="12%"
        />
        <RestFieldItem
          source="centres"
          header="centres.centre"
          format={formatCentreName}
        />
        <RestFieldItem
          source="isBlocked"
          header="users.blocked"
          width="10%"
          valueProp="checked"
          customOnChange={handleBlockUser}
          component={<Switch />}
        />
        <RestFieldItem
          sorter
          source="lastActiveAt"
          header="common.lastActiveAt"
          format={formatDateTimeTable}
          width="10%"
        />
        <RestFieldItem
          source="createdAt"
          header="users.createdAt"
          format={formatDateTimeTable}
          width="10%"
        />
        <ActionGroup widthAction={100}>
          <EditButton
            permissionKey="abilityChangeUserPermissions"
            isSkipSubscription
            action="isView"
            isView
          />
          <DeleteButton
            permissionKey="abilityChangeUserPermissions"
            action="isEdit"
            isSkipSubscription
            customMessage="users.confirmDelete"
          />
        </ActionGroup>
      </List>
      <CentresModal
        ref={centresUserModalRef}
        title="centres.centresOfUser"
        resource="centresOfUser"
        retrieveListAction={getCentresOfUser}
      />
    </div>
  );
};

export default UsersList;

import { isEmpty, forEach, groupBy, mapValues, keyBy } from 'lodash-es';

export const getSubscriptionConfig = (data) => {
  if (isEmpty(data)) return {};

  const featureCentreLevel = {};
  const featureMultiCentre = {};

  forEach(data, (item) => {
    featureCentreLevel[item.name] = item.businessFeaturesCentreLevel;
    featureMultiCentre[item.name] = item.businessFeaturesMultiCentreLevel;
  });

  return { featureCentreLevel, featureMultiCentre };
};

export const formatRolePermissionConfig = (data) => {
  const groupByRole = groupBy(data, 'role.name');

  const rest = mapValues(groupByRole, (arr) => keyBy(arr, 'controller'));

  return rest;
};

import { baseApi } from 'redux/@rtkQuery/baseApi';
import { PREFIX_API_VER_1 } from 'configs/localData/constant';
import { stringifyObjectWithBrackets } from 'api/utils';
import { BrandsQueryPayload, BrandsQueryResponse } from 'types/brand';

export const brandsApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    brands: builder.query<BrandsQueryResponse, BrandsQueryPayload>({
      query(params) {
        return {
          url: `/${PREFIX_API_VER_1}/public/brands?${stringifyObjectWithBrackets(
            params,
          )}`,
        };
      },
    }),
  }),
});

export const { useBrandsQuery } = brandsApi;

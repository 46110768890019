import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { getRolesArr } from 'redux/config/selectors';
import { getCurrentRole } from 'redux/auth/selectors';
import { ROLES_CONST } from 'configs/localData/permissions';

const useRolesData = () => {
  const roles = useSelector(getRolesArr);
  const currentRole = useSelector(getCurrentRole);

  const filterRole = useCallback(
    (acceptArr) => roles?.filter((item) => acceptArr?.includes(item.name)),
    [roles],
  );

  const rolesDefault = useMemo(() => {
    switch (currentRole) {
      case ROLES_CONST.companyAdmin:
        return filterRole([
          ROLES_CONST.companyStaff,
          ROLES_CONST.providerAdmin,
          ROLES_CONST.providerStaff,
          ROLES_CONST.centreAdmin,
          ROLES_CONST.centreStaff,
        ]);

      case ROLES_CONST.providerAdmin:
        return filterRole([
          ROLES_CONST.providerStaff,
          ROLES_CONST.centreAdmin,
          ROLES_CONST.centreStaff,
        ]);

      case ROLES_CONST.centreAdmin:
        return filterRole([ROLES_CONST.centreStaff]);

      default:
        return [];
    }
  }, [filterRole, currentRole]);

  return rolesDefault;
};

export default useRolesData;

import React from 'react';
import i18next from 'i18next';
import PropTypes from 'prop-types';
import { formatMoney } from 'utils/tools';
import { AverageEnquiriesStyles } from './styles';

const TotalEnquiries = ({ totalEnquiries }) => (
  <AverageEnquiriesStyles>
    <span>{i18next.t('enquiries.totalEnquiries')}</span>
    <span>{formatMoney(totalEnquiries)}</span>
  </AverageEnquiriesStyles>
);

TotalEnquiries.propTypes = {
  totalEnquiries: PropTypes.number,
};

export default TotalEnquiries;

import React, { useCallback, useMemo, useRef, useState } from 'react';
import { Form } from 'antd';
import i18next from 'i18next';
import SortableGrid from 'components/RestInput/RestUploadFile/SortableGrid';
import { last, remove } from 'lodash-es';
import ModalPreview from 'components/RestInput/RestUploadFile/ModalPreview';
import ModalCustom from 'components/common/ModalCustom';
import { getRecordData } from 'utils/tools';
import { makeFileList } from 'components/RestInput/RestUploadFile/utils';
import { addCentreVirtualTour } from 'redux/centres/actions';
import { useParams } from 'react-router';
import { nanoid } from '@reduxjs/toolkit';
import { addCompanyVirtualTour } from 'redux/jobs/companyProfile/actions';
import { getIDCompanyProfileSelector } from 'redux/jobs/companyProfile/selectors';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import UrlVideoForm from '../Form/UrlVideoForm';

const source = 'data';

export interface ResponseData {
  url: string;
  originalUrl: string;
  value: string;
  isHaveViewFullScreenButton: boolean;
  id: string;
}

interface Props {
  visible: boolean;
  onCancel: () => void;
  record: {
    data: ResponseData[];
  };
  isCompanyVirtualTour?: boolean;
}

interface ModalRef {
  toggleModal: (url: string) => void;
}

interface FileUpdated {
  uid: string;
  originalUrl: string;
  value: string;
  id: string;
  isHaveViewFullScreenButton: boolean;
  url: string;
  name?: string;
  response?: string;
  key?: string;
}

const VirtualTourModal = ({
  visible,
  onCancel,
  record,
  isCompanyVirtualTour,
}: Props) => {
  const [form] = Form.useForm();
  const initialValue = useMemo(() => getRecordData(record, source), [record]);
  const { id: centreId } = useParams();
  const companyId: string = useAppSelector(getIDCompanyProfileSelector);
  const [loading, setLoading] = useState<boolean>(false);

  const [fileList, setFileList] = useState(makeFileList(initialValue) || []);

  const modalPreviewRef = useRef<ModalRef>();

  const dispatch = useAppDispatch();

  const addURLVideo = (originalUrl, value) => {
    const newFileListObj = [...fileList, { uid: nanoid(), originalUrl, value }];
    setFileList(newFileListObj);
  };

  const formatValueFile = useCallback((results) => {
    const newFile: FileUpdated = last(results);

    if (newFile) {
      return {
        key: newFile.name || newFile.key,
        url: newFile.response || newFile.url,
      };
    }
  }, []);

  const onChangeSortable = (newList) => {
    setFileList(newList);
    form.setFieldsValue({ [source]: formatValueFile(newList) });
  };

  const deleteImage = (index) => {
    const newList = remove(fileList, (obj, idx) => idx !== index);
    setFileList(newList);
    form.setFieldsValue({ [source]: formatValueFile(newList) });
  };

  const onPreviewImage = (url) => {
    modalPreviewRef.current && modalPreviewRef.current.toggleModal(url);
  };

  const handleCancel = () => {
    onCancel();
    setFileList(makeFileList(initialValue));
    form.setFieldsValue({
      urlVideo: null,
    });
  };

  const handleAddVirtualTour = () => {
    setLoading(true);
    dispatch(
      isCompanyVirtualTour
        ? addCompanyVirtualTour({
            companyId,
            virtualTourUrls: fileList,
          })
        : addCentreVirtualTour({ centreId, virtualTourUrls: fileList }),
    )
      .then(() => {
        onCancel();
        form.setFieldsValue({
          urlVideo: null,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <ModalCustom
      title={
        isCompanyVirtualTour
          ? i18next.t('jobs.companyProfile.addCompanyVirtualTour')
          : i18next.t('centreDetail.titleCentreVirtualTour')
      }
      visible={visible}
      onCancel={handleCancel}
      width={680}
      onOk={handleAddVirtualTour}
      isDisabled={loading}
      loading={loading}
    >
      <div className="max-h-550">
        <Form form={form}>
          <UrlVideoForm form={form} addURLVideo={addURLVideo} />
          <SortableGrid
            fileList={fileList}
            onChangeSortable={onChangeSortable}
            isShowVirtualTour
            imageItemProps={{
              deleteImage,
              onPreviewImage,
            }}
          />
        </Form>

        <ModalPreview ref={modalPreviewRef} isShowVirtualTour />
      </div>
    </ModalCustom>
  );
};

export default VirtualTourModal;

import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { Spin } from 'antd';
import DrawerStyles from './styles';

const ViewFileModal = ({ visible, file, onCancel }) => {
  const [timerId, setTimerId] = useState(null);
  const [loading, setLoading] = useState(true);
  const imageRef = useRef(null);

  useEffect(() => {
    setLoading(true);
    const interval = setInterval(() => {
      if (document.getElementById('iframeID')) {
        document.getElementById('iframeID').src =
          document.getElementById('iframeID').src;
      }
    }, 3000);
    setTimerId(interval);
    return () => clearInterval(interval);
  }, []);

  const clearLoadIframe = () => {
    try {
      setLoading(false);
      clearInterval(timerId);
    } catch (err) {
      setLoading(false);
    }
  };

  const handleClose = () => {
    onCancel(false);
  };

  const handleImageLoaded = () => {
    if (imageRef?.current?.complete) {
      setLoading(false);
    }
  };

  return (
    <DrawerStyles
      visible={visible}
      onClose={handleClose}
      footer={null}
      width="100vw"
      title={file?.name}
      destroyOnClose
    >
      <div className="holds-the-iframe">
        {loading && <Spin className="loading-file" />}

        {file.isImage ? (
          <div className="div-image">
            <img
              src={file.url}
              alt=""
              ref={imageRef}
              onLoad={handleImageLoaded}
            />
          </div>
        ) : (
          <iframe
            title="viewFile"
            id="iframeID"
            name="iframeID"
            src={file?.url}
            onLoad={clearLoadIframe}
          />
        )}
      </div>
    </DrawerStyles>
  );
};

ViewFileModal.propTypes = {
  visible: PropTypes.bool,
  onCancel: PropTypes.func,
  file: PropTypes.object,
};

export default ViewFileModal;

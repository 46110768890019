import React, { useState, useRef, useMemo } from 'react';
import PropTypes from 'prop-types';
import EmptyData from 'components/common/EmptyData';

import i18next from 'i18next';
import ModalPreview from './ModelPreview';
import PhotoItem from './PhotoItem';
import GridPhotosWrapper from './styles';

const GridPhotos = ({ images, picsShowing, maxWidth }) => {
  const [visiblePreviewModal, setVisiblePreviewModal] = useState(false);

  const carouselRef = useRef(null);

  const onToggle = (index) => {
    setVisiblePreviewModal(!visiblePreviewModal);
    setTimeout(() => {
      carouselRef.current && carouselRef.current.goToImage(index);
    }, 100);
  };

  const restImageObj = useMemo(() => {
    if (picsShowing) {
      const cloneImage = [...(images || [])];
      const restSubImage = cloneImage?.splice(picsShowing) || [];
      return { subCount: restSubImage.length, remainImages: cloneImage };
    }
    return { subCount: 0, remainImages: images };
  }, [images, picsShowing]);

  const { subCount, remainImages } = restImageObj;
  const lastIndexRemain = remainImages?.length - 1;

  return (
    <>
      <GridPhotosWrapper
        maxWidth={maxWidth}
        className={maxWidth ? 'grid-photos-max-width' : ''}
      >
        {lastIndexRemain < 0 ? (
          <EmptyData description={i18next.t('error.noPhoto')} />
        ) : (
          remainImages?.map((item, index) => {
            if (subCount && index === lastIndexRemain)
              return (
                <PhotoItem
                  key={String(index)}
                  url={item?.url}
                  onPreview={() => onToggle(index)}
                  subCount={subCount}
                  type={item?.type}
                />
              );
            return (
              <PhotoItem
                key={String(index)}
                url={item?.url}
                onPreview={() => onToggle(index)}
                type={item?.type}
              />
            );
          })
        )}
      </GridPhotosWrapper>
      <ModalPreview
        ref={carouselRef}
        images={images}
        visible={visiblePreviewModal}
        handleCancel={onToggle}
      />
    </>
  );
};

GridPhotos.propTypes = {
  images: PropTypes.array,
  picsShowing: PropTypes.number,
  maxWidth: PropTypes.string,
};
GridPhotos.defaultProps = {
  images: [],
};

export default GridPhotos;

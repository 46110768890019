import React from 'react';
import { Col, Form, FormInstance, Row } from 'antd';
import i18next from 'i18next';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import FormUploadAvatar from 'components/form/FormUploadAvatar';
import { AddAvatarPlaceholderIcon } from 'components/common/SVGIcon';
import RestInputItem from 'components/RestInput/RestInputItem';
import RestSelect from 'components/RestInput/RestSelect';
import { validateRegex } from 'utils/validateUtils';
import { BasicInfoStaffProfile, StaffProfileModel } from 'types/staffProfiles';
import { setStaffProfilePayload } from 'redux/staffProfile/slice';
import { useGetStaffProfileConfigsQuery } from 'redux/@rtkQuery/educatorStaffProfile';
import { getPayloadStaffProfile } from 'redux/staffProfile/selector';
import { isEmpty, isNil, omitBy } from 'lodash-es';
import {
  MAX_BASIC_INFO_CHARS_LENGTH,
  YEAR_OF_EXPERIENCES_SELECTIONS,
} from '../../../constants';

const { Item } = Form;

interface BasicInformationFormProps {
  form: FormInstance;
  initialValues?: BasicInfoStaffProfile;
}

const getMaxLengthInputValidateRules = (maxLength) => [
  {
    max: Number(maxLength) || 999,
    message: i18next.t(
      'educatorStaffProfile.basicInformationForm.invalid.maxLength',
    ),
  },
];

const getInputValidateRules = () => [
  {
    pattern: validateRegex.noSpecialChars,
    message: i18next.t(
      'educatorStaffProfile.basicInformationForm.invalid.input',
    ),
  },
];

const defaultUploadAvatarStyles = {
  marginBottom: 20,
  width: 150,
  height: 150,
  borderRadius: '100%',
  textAlign: 'center',
};

const uploadAvatarId = 'upload-basic-avatar';

const BasicInformationForm = ({
  form,
  initialValues = {},
}: BasicInformationFormProps) => {
  const { data: staffProfileConfig } = useGetStaffProfileConfigsQuery();
  const dispatch = useDispatch();
  const staffProfileFormValues: StaffProfileModel = useSelector(
    getPayloadStaffProfile,
  );
  const basicInfoValues = staffProfileFormValues?.basicInfo;

  const userId =
    isEmpty(initialValues) && !isEmpty(basicInfoValues)
      ? basicInfoValues.userId
      : initialValues?.userId ?? null;

  const onFinish = (formValues) => {
    dispatch(
      setStaffProfilePayload({
        basicInfo: omitBy(
          {
            ...formValues,
            userId,
          },
          isNil,
        ),
      }),
    );
  };

  const jobRoleOptions = staffProfileConfig?.roles?.map((item) => ({
    name: item?.name,
    value: `${item?.name}-${item?.rank}`,
  }));

  return (
    <>
      <BasicInformationFormStyled>
        <Form
          onFinish={onFinish}
          form={form}
          layout="vertical"
          initialValues={initialValues}
        >
          <Row>
            <Col xs={24} xl={24} md={24} className="flex-center flex-col">
              <Form.Item shouldUpdate>
                {({ getFieldValue }) => (
                  <FormUploadAvatar
                    form={form}
                    source="avatar"
                    idFor={uploadAvatarId}
                    defaultValue={
                      getFieldValue('avatar') || <AddAvatarPlaceholderIcon />
                    }
                    cropDimension={{ width: 300, height: 300 }}
                    hasCrop
                    style={defaultUploadAvatarStyles}
                  />
                )}
              </Form.Item>
              <label
                className="gray-neural cursor-pointer -mt-20"
                htmlFor={uploadAvatarId}
              >
                {i18next.t('educatorStaffProfile.basicInformationForm.addAvt')}
              </label>
            </Col>
          </Row>
          <Row gutter={[16, 16]} className="items-end mt-32">
            <Col xs={24} md={12} xl={12}>
              <RestInputItem
                header="educatorStaffProfile.basicInformationForm.firstName"
                placeholder="educatorStaffProfile.basicInformationForm.firstNameInputPlaceholder"
                source="firstName"
                required
                rules={[
                  ...getMaxLengthInputValidateRules(
                    MAX_BASIC_INFO_CHARS_LENGTH,
                  ),
                  ...getInputValidateRules(),
                ]}
                autoComplete="off"
              />
            </Col>
            <Col xs={24} md={12} xl={12}>
              <RestInputItem
                header="educatorStaffProfile.basicInformationForm.lastName"
                placeholder="educatorStaffProfile.basicInformationForm.lastNameInputPlaceholder"
                source="lastName"
                required={false}
                rules={[
                  ...getMaxLengthInputValidateRules(
                    MAX_BASIC_INFO_CHARS_LENGTH,
                  ),
                  ...getInputValidateRules(),
                ]}
                autoComplete="off"
              />
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col xs={24} md={12} xl={12}>
              <RestInputItem
                header="educatorStaffProfile.basicInformationForm.jobTitle"
                placeholder="educatorStaffProfile.basicInformationForm.jobTitleInputPlaceholder"
                source="jobTitle"
                required
                rules={[
                  ...getMaxLengthInputValidateRules(
                    MAX_BASIC_INFO_CHARS_LENGTH,
                  ),
                ]}
                autoComplete="off"
              />
            </Col>
            <Col xs={24} md={12} xl={12}>
              <Item hidden name="jobRankId">
                <RestSelect source="jobRankId" />
              </Item>
              <RestSelect
                isShowSearch
                isFilterOption
                source="jobRole"
                resourceData={jobRoleOptions}
                titleProp="name"
                valueProp="value"
                header="educatorStaffProfile.basicInformationForm.jobRole"
                placeholder="educatorStaffProfile.selectOne"
                required
              />
            </Col>
          </Row>
          <Row>
            <Col xs={24} md={24} xl={24}>
              <RestSelect
                isShowSearch
                isFilterOption
                source="experience"
                resourceData={YEAR_OF_EXPERIENCES_SELECTIONS}
                titleProp="name"
                valueProp="name"
                header="educatorStaffProfile.basicInformationForm.yearOfExp"
                placeholder="educatorStaffProfile.selectOne"
                required
              />
            </Col>
          </Row>
          <section />
        </Form>
      </BasicInformationFormStyled>
    </>
  );
};

const BasicInformationFormStyled = styled.div`
  .ant-form-item-label > label {
    color: ${({ theme }) => theme.text.mainL1};
  }

  .gray-neural {
    color: ${({ theme }) => theme.color.grayNeutral400};
  }

  .ant-avatar-circle {
    background: transparent !important;
  }

  .ant-form-item-control .ant-avatar .anticon {
    font-size: 150px;
  }
`;

export default BasicInformationForm;

import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Avatar } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import { CustomerFieldStyles } from './styles';

const CustomerField = memo(({ avatar, fullName, additionalParentInfo }) => (
  <CustomerFieldStyles>
    <Avatar size={45} src={avatar} alt="" icon={<UserOutlined />} />
    <div>
      <div className="fullName">{fullName}</div>
      <div className="note">
        {`${additionalParentInfo?.firstName || ''} ${
          additionalParentInfo?.lastName || ''
        }`}
      </div>
    </div>
  </CustomerFieldStyles>
));

CustomerField.propTypes = {
  avatar: PropTypes.string,
  fullName: PropTypes.string,
  additionalParentInfo: PropTypes.string,
};

export default CustomerField;

import React from 'react';
import PropTypes from 'prop-types';
import i18next from 'i18next';
import { StatisticsInfoStyles } from './styles';

const StatisticsInfo = ({
  title,
  statisticsNumber,
  isTotalApplicationsColor,
}) => (
  <StatisticsInfoStyles isTotalApplications={isTotalApplicationsColor}>
    <div className="text-12 fw-bold text-gray-neutral-500">
      {i18next.t(title)}
    </div>
    <div className="text-20 fw-bold">{statisticsNumber}</div>
  </StatisticsInfoStyles>
);

StatisticsInfo.propTypes = {
  title: PropTypes.string,
  statisticsNumber: PropTypes.number,
  isTotalApplicationsColor: PropTypes.bool,
};

export default StatisticsInfo;

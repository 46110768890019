import React, { useCallback, useEffect, useRef, useState } from 'react';
import PageTitle from 'components/common/PageTitle';
import i18next from 'i18next';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { getActiveOnJobModulePermission } from 'redux/auth/selectors';
import {
  getDataJobApplications,
  getLoadingJobApplications,
  getSummaryJobApplicationsCard,
} from 'redux/jobs/jobApplications/selectors';
import {
  getAllJobApplications,
  getJobApplicationSummaryCard,
} from 'redux/jobs/jobApplications/actions';
import { getFilterFromUrl, getSearchUrl } from 'utils/tools';
import { useLocation } from 'react-router-dom';
import { useHistory } from 'react-router';
import { getConfigV2 } from 'redux/config/actions';
import JobModuleAccessWarning from 'components/common/JobModuleAccessWarning';
import { Spin } from 'antd';
import { BOARD_VALUE } from '../constants';
import Summaries from '../components/Summaries';
import JobApplicationList from '../components/TableListView';
import JoApplicationsFilter from '../components/Filter';
import BoardList from '../components/BoardList';

const JobApplicationsList = (props) => {
  const { match, history } = props;
  const activeTab = match?.params?.model || BOARD_VALUE;

  const jobApplicationsData = useSelector(getDataJobApplications);
  const loading = useSelector(getLoadingJobApplications);
  const { search } = useLocation();
  const { filter } = getFilterFromUrl(search);
  const filterRef = useRef(filter);
  const { location, push } = useHistory();
  const [filterData, setFilterData] = useState(filter);

  const dispatch = useDispatch();
  const activeOnJobModulePermission = useSelector(
    getActiveOnJobModulePermission,
  );
  const { loading: isSummaryLoading } = useSelector(
    getSummaryJobApplicationsCard,
  );

  const retrieveList = useCallback(
    ({ filter = {}, isRefresh, keepFilter }) => {
      dispatch(
        getAllJobApplications({
          data: {
            ...filter,
            filter: {
              ...filter.filter,
            },
          },
          options: {
            keepFilter,
            isRefresh,
          },
        }),
      );
    },
    [dispatch],
  );

  useEffect(() => {
    dispatch(getConfigV2());
    activeOnJobModulePermission &&
      dispatch(
        getJobApplicationSummaryCard({
          filter: filterRef.current.filter,
        }),
      );
  }, [retrieveList, dispatch, activeOnJobModulePermission]);

  const pushQuery = useCallback(
    (searchStr) => {
      push(`${location.pathname}?${getSearchUrl(searchStr)}`);
    },
    [location.pathname, push],
  );

  return (
    <div>
      {activeOnJobModulePermission ? (
        <>
          <PageTitle>{i18next.t('jobs.sideBar.jobApplications')}</PageTitle>
          <Spin spinning={isSummaryLoading}>
            <Summaries resultFilter={filter} />
          </Spin>

          <JoApplicationsFilter
            history={history}
            activeTab={activeTab}
            retrieveList={retrieveList}
            pushQuery={pushQuery}
            setFilterData={setFilterData}
          />
          {activeTab === BOARD_VALUE ? (
            <BoardList filterData={filterData} />
          ) : (
            <JobApplicationList
              jobApplicationsData={jobApplicationsData}
              loading={loading}
              pushQuery={pushQuery}
              retrieveList={retrieveList}
              setFilterData={setFilterData}
            />
          )}
        </>
      ) : (
        <JobModuleAccessWarning title="jobApplications.title" />
      )}
    </div>
  );
};

JobApplicationsList.propTypes = {
  match: PropTypes.object,
  history: PropTypes.object,
};

export default JobApplicationsList;

import React from 'react';
import PropTypes from 'prop-types';
import CardCustom from 'components/common/CardCustom';
import { AwardFillIcon } from 'components/common/SVGIcon';
import CardCustomContent from '../../CardContentCustom';
import CertificationContent from '../../CardContentCustom/CertificationContent';

const MyCertifications = ({ certifications }) => (
  <div className="mb-32">
    <CardCustom
      title="jobApplications.generalInfo.myCertifications"
      Icon={AwardFillIcon}
    >
      <div>
        {certifications?.map((item, index) => (
          <CardCustomContent Icon={AwardFillIcon} key={Number(index)}>
            <CertificationContent item={item} />
          </CardCustomContent>
        ))}
      </div>
    </CardCustom>
  </div>
);

MyCertifications.propTypes = {
  certifications: PropTypes.array,
};

export default MyCertifications;

import React from 'react';
import { CrownFilled } from '@ant-design/icons';
import { Button } from 'antd';

import { upgradeModal } from 'components/common/CheckPermissions/UpgradeSubscriptionModal';
import { DuplicateIcon } from 'components/common/SVGIcon';

const DuplicateBtnFallback = () => {
  const onClickButton = () => {
    upgradeModal.open();
  };
  return (
    <div className="delete-action-wrapper">
      <Button
        onClick={onClickButton}
        type="primary"
        shape="circle"
        icon={<DuplicateIcon />}
      />
      <CrownFilled className="action-feature-icon" />
    </div>
  );
};

export default DuplicateBtnFallback;

import { uniq, uniqBy, remove } from 'lodash-es';
import { makeCRUDSlice } from 'redux/crudCreator';
import {
  MODEL_NAME,
  applicationsActions,
  updateApplicationStatus,
  getApplicationPendingCount,
  getAllApplicationsNotPaging,
  getAllInformationRequests,
  createInformationRequest,
  editInformationRequest,
  delInformationRequest,
  getAllDocuments,
  createDocument,
  delDocument,
  getAllActivities,
  getAllTasks,
  createActivity,
  editActivity,
  createTask,
  editTask,
  doneTask,
  getLatestActivities,
  getLatestTask,
  updateApplicationArchiveStatus,
} from './actions';

const INITIAL_STATE_EXTRA = {
  resourceData: {
    filter: {},
    offset: 0,
    page: 1,
    limit: 10,
    total: 0,
    numberOfPages: 1,
    sort: '',
  },
  data: [],
};

const updateApplicationStatusSuccess = (state, { payload, meta }) => {
  state.currentData = {
    ...state.currentData,
    ...payload,
  };
  state.data = {
    ...state.data,
    [payload.id]: {
      ...state.data[payload.id],
      ...payload,
    },
  };
  if (meta.arg.isReduceCountPending) {
    state.countPending = (state.countPending || 1) - 1;
  }
};

const updateApplicationStatusFailure = (state, { payload }) => {
  state.error = payload;
};

const getApplicationPendingCountSuccess = (state, { payload }) => {
  state.countPending = Number(payload.countPending);
};

const getApplicationPendingCountFailure = (state, { payload }) => {
  state.error = payload;
};

const getAllApplicationsSuccess = (state, { payload: { data, options } }) => {
  state.loading = false;
  state.ids = options.isRefresh ? data.ids : uniq([...state.ids, ...data.ids]);
  state.data = data.data;
  state.total = data.total;
  state.numberOfPages = data.numberOfPages;
  state.totalArchivedApplications =
    Object.values(data?.data || {}).filter((item) => item?.archivedReason)
      ?.length ?? 0;
};

const getAllApplicationsFailure = (state, { payload }) => {
  state.error = payload;
  state.loading = false;
};

const getAllInformationRequestsPending = (
  state,
  {
    meta: {
      arg: { data, options = {} },
    },
  },
) => {
  state.infoRequestLoading = true;

  if (options.isRefresh) {
    state.infoRequest = {
      resourceData: {
        ...INITIAL_STATE_EXTRA.resourceData,
        ...data,
      },
      data: [],
    };
  } else {
    const resourceData = state.infoRequest.resourceData || {};

    state.infoRequest.resourceData = {
      ...resourceData,
      offset: resourceData.offset + resourceData.limit || 0,
      ...data,
    };
  }
};

const getAllInformationRequestsSuccess = (state, { payload: { data } }) => {
  const infoRequestData = state.infoRequest.data;

  state.infoRequest.data = uniqBy([...infoRequestData, ...data?.results], 'id');

  state.infoRequest.resourceData = {
    ...state.infoRequest.resourceData,
    total: data?.total || 0,
    numberOfPages: data.numberOfPages,
  };

  state.infoRequestLoading = false;
  state.error = null;
};

const getAllInformationRequestsFail = (state, { payload: { data } }) => {
  state.error = data;
  state.infoRequestLoading = false;
};

const createInformationRequestPending = (state) => {
  state.createInfoRequestLoading = true;
};

const insertDocuments = (applicationDocuments, state) => {
  const totalNewDocuments = applicationDocuments.length;

  if (totalNewDocuments && state.documents) {
    state.documents.data = [...applicationDocuments, ...state.documents.data];
    const total = state.documents.resourceData.total + totalNewDocuments;
    state.documents.resourceData.total = total;
    state.documents.resourceData.numberOfPages = Math.ceil(
      total / state.documents.resourceData.limit,
    );
  }
};

const createInformationRequestSuccess = (
  state,
  { payload: { data, applicationDocuments } },
) => {
  state.infoRequest.data = [data, ...state.infoRequest.data];
  state.createInfoRequestLoading = false;
  insertDocuments(applicationDocuments, state);
  state.error = null;
};

const createInformationRequestFail = (state, { payload: { data } }) => {
  state.createInfoRequestLoading = false;
  state.error = data;
};

const editInformationRequestSuccess = (state, { payload: { data } }) => {
  state.infoRequest.data = state.infoRequest.data?.map((item) =>
    item.id === data.id ? { ...item, ...data } : item,
  );
  state.error = null;
};

const editInformationRequestFail = (state, { payload: { data } }) => {
  state.error = data;
};

const delInformationRequestSuccess = (state, { payload: { id } }) => {
  state.infoRequest.data = remove(
    state.infoRequest.data,
    (item) => item.id !== id,
  );
  state.error = null;
};

const delInformationRequestFail = (state, { payload: { data } }) => {
  state.error = data;
};

const getAllDocumentsPending = (
  state,
  {
    meta: {
      arg: { data, options = {} },
    },
  },
) => {
  state.documentsLoading = true;

  if (options.isRefresh) {
    state.documents = {
      resourceData: {
        ...INITIAL_STATE_EXTRA.resourceData,
        ...data,
      },
      data: [],
    };
  } else {
    const resourceData = state.documents.resourceData || {};

    state.documents.resourceData = {
      ...resourceData,
      offset: resourceData.offset + resourceData.limit || 0,
      ...data,
    };
  }
};

const getAllDocumentsSuccess = (state, { payload: { data } }) => {
  const documentsData = state.documents.data;

  state.documents.data = uniqBy([...documentsData, ...data?.results], 'id');

  state.documents.resourceData = {
    ...state.documents.resourceData,
    total: data?.total || 0,
    numberOfPages: data.numberOfPages,
  };

  state.documentsLoading = false;
  state.error = null;
};

const getAllDocumentsFail = (state, { payload: { data } }) => {
  state.error = data;
  state.documentsLoading = false;
};

const createDocumentPending = (state) => {
  state.createDocumentLoading = true;
};

const createDocumentSuccess = (state, { payload: { data } }) => {
  state.documents.data = [data, ...state.documents.data];
  state.createDocumentLoading = false;
  state.documents.resourceData.total += 1;
  state.error = null;
};

const createDocumentFail = (state, { payload: { data } }) => {
  state.createDocumentLoading = false;
  state.error = data;
};

const delDocumentSuccess = (state, { payload: { id } }) => {
  state.documents.data = remove(state.documents.data, (item) => item.id !== id);
  state.documents.resourceData.total -= 1;
  state.error = null;
};

const delDocumentFail = (state, { payload: { data } }) => {
  state.error = data;
};

const getAllActivitiesPending = (
  state,
  {
    meta: {
      arg: { data, options = {} },
    },
  },
) => {
  state.activityLoading = true;

  if (options.isRefresh) {
    state.activity = {
      resourceData: {
        ...INITIAL_STATE_EXTRA.resourceData,
        ...data,
      },
      data: [],
    };
  } else {
    const resourceData = state.activity.resourceData || {};

    state.activity.resourceData = {
      ...resourceData,
      offset: resourceData.offset + resourceData.limit || 0,
      ...data,
    };
  }
};

const getAllActivitiesSuccess = (state, { payload: { data } }) => {
  const activitiesData = state.activity.data;
  state.activity.data = uniqBy([...activitiesData, ...data.results], 'id');

  state.activity.resourceData = {
    ...state.activity.resourceData,
    total: data?.total || 0,
  };

  state.activityLoading = false;
  state.error = null;
};

const getAllActivitiesFail = (state, { payload: { data } }) => {
  state.error = data;
  state.activityLoading = false;
};

const createActivityPending = (state) => {
  state.activityLoading = true;
};

const createActivitySuccess = (state, { payload: { data } }) => {
  state.activity.data = [data, ...state.activity.data];
  state.activityLoading = false;
  state.latestActivity = data;
  state.activity.resourceData.total += 1;
};

const createActivityFail = (state) => {
  state.activityLoading = false;
};

const editActivityPending = (state) => {
  state.activityLoading = true;
};

const editActivityFail = (state) => {
  state.activityLoading = false;
};

const editActivitySuccess = (state, { payload: { data } }) => {
  const activityData = state.activity.data;

  state.activityLoading = false;
  state.activity.data = activityData.map((activity) =>
    activity.id === data.id ? data : activity,
  );
  state.latestActivity = data;
};

const getAllTasksPending = (
  state,
  {
    meta: {
      arg: { data, options = {} },
    },
  },
) => {
  state.taskLoading = true;

  if (options.isRefresh) {
    state.task = {
      resourceData: {
        ...INITIAL_STATE_EXTRA.resourceData,
        ...data,
      },
      data: [],
    };
  } else {
    const resourceData = state.task.resourceData || {};

    state.task.resourceData = {
      ...resourceData,
      offset: resourceData.offset + resourceData.limit || 0,
      ...data,
    };
  }
};

const getAllTasksSuccess = (state, { payload: { data } }) => {
  const taskData = state.task.data;
  state.task.data = uniqBy([...taskData, ...data.results], 'id');

  state.task.resourceData = {
    ...state.task.resourceData,
    total: data?.total || 0,
  };

  state.taskLoading = false;
  state.error = null;
};

const getAllTasksFail = (state, { payload: { data } }) => {
  state.error = data;
  state.taskLoading = false;
};

const createTaskPending = (state) => {
  state.taskLoading = true;
};

const createTaskSuccess = (state, { payload: { data } }) => {
  state.task.data = [data, ...state.task.data];
  state.task.resourceData.total += 1;
  state.taskLoading = false;
  state.latestTask = data;
};

const createTaskFail = (state) => {
  state.taskLoading = false;
};

const editTaskPending = (state) => {
  state.taskLoading = true;
};

const editTaskFail = (state) => {
  state.taskLoading = false;
};

const editTaskSuccess = (state, { payload: { data } }) => {
  const taskData = state.task.data;

  state.taskLoading = false;
  state.task.data = taskData.map((task) => (task.id === data.id ? data : task));
  state.latestTask = data;
};

const doneTaskPending = (state) => {
  state.taskLoading = true;
};

const doneTaskFail = (state) => {
  state.taskLoading = false;
};

const doneTaskSuccess = (state, { payload: { data } }) => {
  const taskData = state.task?.data;

  state.taskLoading = false;

  if (taskData) {
    state.task.data = taskData.filter((task) => task.id !== data.id);
    state.task.resourceData.total -= 1;
    if (state.activity?.data) {
      state.activity.data.unshift(data);
      state.activity.resourceData.total += 1;
    }
  }
};

const getLatestActivitiesSuccess = (state, { payload: { data } }) => {
  state.latestActivity = data?.results?.[0];
};

const getLatestTaskSuccess = (state, { payload: { data } }) => {
  state.latestTask = data?.results?.[0];
};

const updateApplicationArchiveStatusSuccess = (state, { payload, meta }) => {
  if (state.currentData?.id === meta.arg?.id) {
    state.currentData.archivedReason = payload?.data?.archivedReason;
  }
};

const slice = makeCRUDSlice(MODEL_NAME, applicationsActions, {
  [getAllApplicationsNotPaging.pending]: (state) => {
    state.loading = true;
  },
  [getAllApplicationsNotPaging.fulfilled]: getAllApplicationsSuccess,
  [getAllApplicationsNotPaging.rejected]: getAllApplicationsFailure,
  [updateApplicationStatus.fulfilled]: updateApplicationStatusSuccess,
  [updateApplicationStatus.rejected]: updateApplicationStatusFailure,
  [getApplicationPendingCount.fulfilled]: getApplicationPendingCountSuccess,
  [getApplicationPendingCount.rejected]: getApplicationPendingCountFailure,

  [getAllInformationRequests.pending]: getAllInformationRequestsPending,
  [getAllInformationRequests.fulfilled]: getAllInformationRequestsSuccess,
  [getAllInformationRequests.rejected]: getAllInformationRequestsFail,

  [createInformationRequest.pending]: createInformationRequestPending,
  [createInformationRequest.fulfilled]: createInformationRequestSuccess,
  [createInformationRequest.rejected]: createInformationRequestFail,

  [editInformationRequest.fulfilled]: editInformationRequestSuccess,
  [editInformationRequest.rejected]: editInformationRequestFail,

  [delInformationRequest.fulfilled]: delInformationRequestSuccess,
  [delInformationRequest.rejected]: delInformationRequestFail,

  [getAllDocuments.pending]: getAllDocumentsPending,
  [getAllDocuments.fulfilled]: getAllDocumentsSuccess,
  [getAllDocuments.rejected]: getAllDocumentsFail,

  [createDocument.pending]: createDocumentPending,
  [createDocument.fulfilled]: createDocumentSuccess,
  [createDocument.rejected]: createDocumentFail,

  [delDocument.fulfilled]: delDocumentSuccess,
  [delDocument.rejected]: delDocumentFail,

  [getAllActivities.pending]: getAllActivitiesPending,
  [getAllActivities.fulfilled]: getAllActivitiesSuccess,
  [getAllActivities.rejected]: getAllActivitiesFail,

  [createActivity.pending]: createActivityPending,
  [createActivity.fulfilled]: createActivitySuccess,
  [createActivity.rejected]: createActivityFail,

  [editActivity.pending]: editActivityPending,
  [editActivity.fulfilled]: editActivitySuccess,
  [editActivity.rejected]: editActivityFail,

  [getAllTasks.pending]: getAllTasksPending,
  [getAllTasks.fulfilled]: getAllTasksSuccess,
  [getAllTasks.rejected]: getAllTasksFail,

  [createTask.pending]: createTaskPending,
  [createTask.fulfilled]: createTaskSuccess,
  [createTask.rejected]: createTaskFail,

  [editTask.pending]: editTaskPending,
  [editTask.fulfilled]: editTaskSuccess,
  [editTask.rejected]: editTaskFail,

  [doneTask.pending]: doneTaskPending,
  [doneTask.fulfilled]: doneTaskSuccess,
  [doneTask.rejected]: doneTaskFail,

  [getLatestActivities.fulfilled]: getLatestActivitiesSuccess,
  [getLatestTask.fulfilled]: getLatestTaskSuccess,

  [updateApplicationArchiveStatus.fulfilled]:
    updateApplicationArchiveStatusSuccess,
});

export default slice.reducer;

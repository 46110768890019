import React from 'react';
import i18next from 'i18next';
import ContentCard from 'components/CardItemProfile';
import { BookCircleIcon, EducationIcon } from 'components/common/SVGIcon';
import { FormInstance, notification } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { getPayloadStaffProfile } from 'redux/staffProfile/selector';
import { setStaffProfilePayload } from 'redux/staffProfile/slice';
import EmptyContainer from 'containers/Centres/components/CentreTabs/EducatorStaffProfilesTab/components/EmptyContainer';
import EducationFormModal from './EducationFormModal';
import EducationItem from './EducationItem';

interface Props {
  visible: boolean;
  formModalStep3: FormInstance;
  editIndexCurrent: number;
  openAddModal: () => void;
  onSave: () => void;
  onSaveAddAnother: () => void;
  setVisible: (params: boolean) => void;
  setEditIndexCurrent: (params: number) => void;
  isOther: boolean;
  setIsOther: (params: boolean) => void;
}

const EducationContainer = ({
  visible,
  setVisible,
  formModalStep3,
  editIndexCurrent,
  setEditIndexCurrent,
  openAddModal,
  onSave,
  onSaveAddAnother,
  isOther,
  setIsOther,
}: Props) => {
  const educations = useSelector(getPayloadStaffProfile)?.educations ?? [];
  const dispatch = useDispatch();

  const onDelete = (index) => {
    const payloadList = [...educations];
    payloadList.splice(index, 1);

    dispatch(setStaffProfilePayload({ educations: payloadList }));
    notification.success({
      message: i18next.t('success.title'),
      description: i18next.t('success.description'),
    });
  };

  const openEditModal = (data, index) => {
    formModalStep3.resetFields();
    setVisible(true);
    setEditIndexCurrent(index);
    formModalStep3.setFieldsValue(data);
  };

  return (
    <div className="flex flex-col gap-24">
      {educations[0] ? (
        educations?.map((item, index) => (
          <ContentCard
            key={item?.id}
            Icon={<BookCircleIcon />}
            onEdit={() => openEditModal(item, index)}
            onDelete={() => onDelete(index)}
            deleteConfirmTitle="educatorStaffProfile.deleteEducationTitle"
            deleteConfirmContent="educatorStaffProfile.deleteEducationContent"
          >
            <EducationItem {...item} />
          </ContentCard>
        ))
      ) : (
        <EmptyContainer
          Icon={EducationIcon}
          description="educatorStaffProfile.noDataDescriptionEducation"
          buttonTitle="educatorStaffProfile.addEducation"
          onAdd={openAddModal}
          className="mt-70"
        />
      )}

      <EducationFormModal
        setVisible={setVisible}
        formModalStep3={formModalStep3}
        visible={visible}
        editIndexCurrent={editIndexCurrent}
        title="educatorStaffProfile.educationAndQualification"
        onSave={onSave}
        onSaveAddAnother={onSaveAddAnother}
        currentEducation={educations?.[editIndexCurrent]}
        isOther={isOther}
        setIsOther={setIsOther}
      />
    </div>
  );
};

export default EducationContainer;

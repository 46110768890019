import React from 'react';
import { Button, Col, Row } from 'antd';
import { useSelector } from 'react-redux';
import { getDataJobTemplateDetail } from 'redux/jobs/jobTemplates/selectors';
import i18next from 'i18next';
import { ReturnIcon } from 'components/common/SVGIcon';
import { useHistory, useLocation } from 'react-router';
import qs from 'qs';
import { getDataJobListingDetail } from 'redux/jobs/jobListings/selectors';
import { JobDetailStyles } from './styles';
import JobTemplateInfo from '../JobTemplateInfo';
import JobTabs from '../JobTabs';

const JobDetail = () => {
  const currentData = useSelector(getDataJobTemplateDetail);
  const jobListingData = useSelector(getDataJobListingDetail);
  const { push } = useHistory();
  const { search } = useLocation();

  const { isCreateJobListing, isJobListingDetail } = qs.parse(search, {
    ignoreQueryPrefix: true,
  });

  const onReturnCreateJobListing = () => {
    push(`/job-listings/create/steps?id=${currentData?.id}`);
  };

  const onReturnJobListingDetail = () => {
    push(`/job-listings/${jobListingData?.id}/show/job-details`);
  };

  return (
    <JobDetailStyles className="mt-32">
      <Row gutter={24} className="row-customer-detail">
        <Col xxl={6} xl={6} lg={8} md={24} xs={24}>
          <JobTemplateInfo currentData={currentData} />
        </Col>
        <Col xxl={18} xl={18} lg={16} md={24} xs={24}>
          <JobTabs currentData={currentData} />
        </Col>
      </Row>
      {isCreateJobListing && (
        <div className="btn-return">
          <Button
            onClick={onReturnCreateJobListing}
            className="fw-bold text-16 bg-green text-white"
            icon={<ReturnIcon />}
          >
            {i18next.t('button.returnToCreateJobListing')}
          </Button>
        </div>
      )}
      {isJobListingDetail && (
        <div className="btn-return">
          <Button
            onClick={onReturnJobListingDetail}
            className="fw-bold text-16 bg-green text-white"
            icon={<ReturnIcon />}
          >
            {i18next.t('button.returnToJobListingDetail')}
          </Button>
        </div>
      )}
    </JobDetailStyles>
  );
};

export default JobDetail;

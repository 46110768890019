import React, { useState, forwardRef, useImperativeHandle } from 'react';
import PropTypes from 'prop-types';
import i18next from 'i18next';
import ModalCustom from 'components/common/ModalCustom';
import { isEmpty } from 'lodash-es';
import { Form } from 'antd';
import { RestInputContext } from 'components/RestInput/RestInputContext';
import ListCheckboxSelect from './ListCheckboxSelect';

const DeleteMultipleJobModal = forwardRef(
  ({ selectedRowKeyNoApply, selectedRowKeyHaveApply, onDelete }, ref) => {
    const [form] = Form.useForm();

    const [visible, setVisible] = useState();
    const [loading, setLoading] = useState(false);
    const [disabled, setDisabled] = useState(false);

    const jobListingData = selectedRowKeyHaveApply?.concat(
      selectedRowKeyNoApply,
    );

    useImperativeHandle(
      ref,
      () => ({
        open: () => {
          setVisible(true);
        },
      }),
      [],
    );

    const onCancel = () => {
      setVisible(false);
      form.resetFields();
      setDisabled(false);
    };

    const onOk = () => {
      form
        .validateFields()
        .then(async ({ postIds }) => {
          setLoading(true);
          if (!isEmpty(postIds)) {
            await onDelete(postIds);
            onCancel();
          }
        })
        .then(() => {
          setLoading(false);
        });
    };

    return (
      <ModalCustom
        visible={visible}
        title={i18next.t('jobListings.deleteJobListing')}
        onCancel={onCancel}
        okText="button.delete"
        onOk={onOk}
        resetPaddingBottomBody={false}
        centered
        loading={loading}
        isDisabled={disabled || isEmpty(selectedRowKeyNoApply)}
        isScrollY
      >
        <RestInputContext.Provider value={{ form }}>
          <Form form={form}>
            <ListCheckboxSelect
              data={jobListingData}
              selectedRowKeyNoApply={selectedRowKeyNoApply}
              setDisabled={setDisabled}
            />
          </Form>
        </RestInputContext.Provider>
      </ModalCustom>
    );
  },
);

DeleteMultipleJobModal.propTypes = {
  selectedRowKeyHaveApply: PropTypes.array,
  selectedRowKeyNoApply: PropTypes.array,
  onDelete: PropTypes.func,
};
export default DeleteMultipleJobModal;

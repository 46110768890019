import React, { useRef } from 'react';
import { Modal, Select } from 'antd';
import i18next from 'i18next';
import PropTypes from 'prop-types';
import {
  APPLICATION_STATUS_CONSTANT,
  JOB_APPLICATION_STATUS,
} from 'configs/localData';
import { ArrowDownIcon } from 'components/common/SVGIcon';
import { DropDownWrapper } from './styles';
import DeclineReasonModal from './DeclineModal';

const { Option } = Select;

const getStatusName = (statusId) =>
  JOB_APPLICATION_STATUS.find(
    (status) => status.indexOrder?.toString() === statusId,
  )?.value;

const DropdownStatus = ({ status, handleUpdateStatus, child, disabled }) => {
  const getItemStatus = (status) =>
    JOB_APPLICATION_STATUS.find((item) => item?.value === status);

  const getStatusText = (status) => {
    const statusItem = getItemStatus(status);
    return statusItem?.text ? i18next.t(statusItem?.text) : status;
  };

  const getObjConfirm = (currentStatus, newStatus) => ({
    childName: `${child.name || ''}`,
    beforeStatus: getStatusText(currentStatus),
    endStatus: getStatusText(newStatus),
  });

  const declineReasonModalRef = useRef();

  const openDeclineReasonModal = () => {
    declineReasonModalRef.current && declineReasonModalRef.current.open();
  };

  const handleChange = (value) => {
    const statusName = getStatusName(value);
    const lastStatusName = getStatusName(status);
    if (statusName === APPLICATION_STATUS_CONSTANT.declined) {
      openDeclineReasonModal();
      return;
    }

    Modal.confirm({
      title: i18next.t('jobApplications.generalInfo.changeStatus.title'),
      content: i18next.t(
        'jobApplications.generalInfo.changeStatus.displayText',
        getObjConfirm(lastStatusName, statusName),
      ),
      onOk: () => {
        handleUpdateStatus(value);
      },
    });
  };

  return (
    <>
      <DropDownWrapper
        value={status}
        className="w-full"
        onChange={handleChange}
        suffixIcon={<ArrowDownIcon style={{ pointerEvents: 'none' }} />}
        dropdownClassName="dropdown-status"
        disabled={disabled}
      >
        {JOB_APPLICATION_STATUS.map((item) => (
          <Option key={item?.value} value={item?.indexOrder?.toString()}>
            <div>
              <span
                className="span-option-status"
                style={{
                  color: item?.color,
                  background: item?.backgroundColor,
                }}
              >
                {i18next.t(item?.text)}
              </span>
            </div>
          </Option>
        ))}
      </DropDownWrapper>
      <DeclineReasonModal
        ref={declineReasonModalRef}
        updateStatus={(reason) => {
          const statusId = JOB_APPLICATION_STATUS.find(
            (item) => item.value === APPLICATION_STATUS_CONSTANT.declined,
          ).indexOrder?.toString();
          handleUpdateStatus(statusId, reason?.declinedReasonId);
        }}
      />
    </>
  );
};

DropdownStatus.propTypes = {
  status: PropTypes.string,
  handleUpdateStatus: PropTypes.func,
  child: PropTypes.object,
  disabled: PropTypes.bool,
};

DropdownStatus.defaultProps = {
  handleUpdateStatus: () => null,
  child: {},
};

export default DropdownStatus;

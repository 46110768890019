import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from 'antd';
import { LegendLabelWrapper } from './styles';
import { splitStringByLength } from '../utils';
import Content from './LabelItem/Content';
import {
  ALL_COMPANIES_COLOR,
  LEGEND_LABEL_TYPE,
  MAX_LABEL_LENGTH,
  SELECTED_COMPANY_COLOR,
} from '../constants';

const LegendLabel = ({
  payload,
  isEmptyCompany,
  userRatingAvg,
  allRatingAvg,
  allDataKey,
  userDataKey,
}) => {
  if (isEmptyCompany && payload?.length > 0) {
    payload.splice(1, 1);
  }

  const initItem = {
    isShowTooltip: true,
    type: LEGEND_LABEL_TYPE.SQUARE,
  };

  const transformedPayload = payload.map((item) => {
    let transformedItem = item;
    if (item.dataKey === allDataKey) {
      transformedItem = {
        ...item,
        ...initItem,
      };
    }

    if (item.dataKey === userDataKey) {
      transformedItem = {
        ...item,
        ...initItem,
        rawValue: item.value,
        value: splitStringByLength(item.value, MAX_LABEL_LENGTH),
        tooltipTitle: item.value,
      };
    }
    return transformedItem;
  });

  const avgAllPayload = transformedPayload.map((item) => {
    const color =
      item.dataKey === allDataKey
        ? ALL_COMPANIES_COLOR
        : SELECTED_COMPANY_COLOR;
    const value = splitStringByLength(
      `${item.value} Average`,
      MAX_LABEL_LENGTH,
    );
    const avgRating =
      item.dataKey === allDataKey ? `: ${allRatingAvg}` : `: ${userRatingAvg}`;

    return {
      ...item,
      value,
      type: LEGEND_LABEL_TYPE.DASHED,
      isShowTooltip: item.dataKey !== allDataKey,
      color,
      avgRating,
      tooltipTitle: `${item.rawValue} Average${avgRating}`,
    };
  });

  const legendLabels = [...transformedPayload, ...avgAllPayload];

  return (
    <LegendLabelWrapper>
      {legendLabels.map((item, index) => (
        <div key={Number(index)}>
          {item.isShowTooltip ? (
            <div className="legend-item">
              <Tooltip title={item.tooltipTitle}>
                <div>
                  <Content item={item} />
                </div>
              </Tooltip>
            </div>
          ) : (
            <div className="legend-item">
              <Content item={item} />
            </div>
          )}
        </div>
      ))}
    </LegendLabelWrapper>
  );
};

LegendLabel.propTypes = {
  payload: PropTypes.array,
  isEmptyCompany: PropTypes.bool,
  allRatingAvg: PropTypes.string,
  userRatingAvg: PropTypes.string,
  allDataKey: PropTypes.string,
  userDataKey: PropTypes.string,
};

export default LegendLabel;

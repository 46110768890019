import React, { useCallback, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DISPLAY_TYPES } from 'configs/localData/constant';
import { getJobListingViewByJobIndividual } from 'redux/jobs/jobListings/selectors';
import {
  DEFAULT_CURRENT_PAGE,
  DEFAULT_SORT_INDIVIDUAL,
  LIMIT_ITEM,
  VIEW_TYPE_JOB_LISTINGS,
} from 'containers/JobListings/constants';
import { Waypoint } from 'react-waypoint';
import {
  getAllJobListings,
  getSummaryJobListing,
} from 'redux/jobs/jobListings/actions';
import { useHistory, useLocation, useParams } from 'react-router';
import { getFilterFromUrl, getSearchUrl } from 'utils/tools';
import { isEmpty } from 'lodash-es';
import IndividualJob from 'components/common/IndividualJob';
import { Spin } from 'antd';
import Summaries from './components/Summaries';
import TableView from './components/TableView';
import ListingFilterCustom from './components/Filter';
import NoJobListings from '../NoJobListings';
import { JobList } from './styles';

const IndividualJobListingView = () => {
  const dispatch = useDispatch();
  const { tabMode, viewMode } = useParams();

  const { location, push } = useHistory();
  const { search } = useLocation();
  const { filter } = getFilterFromUrl(search);
  const filterRef = useRef(filter);
  const { data: jobIndividualData, loading } = useSelector(
    getJobListingViewByJobIndividual,
  );
  const { resourceData } = useSelector(getJobListingViewByJobIndividual) || {};

  const retrieveList = useCallback(
    ({ filter = {}, isRefresh, keepFilter }) => {
      dispatch(
        getAllJobListings({
          data: {
            ...filter,
            viewType: VIEW_TYPE_JOB_LISTINGS.INDIVIDUAL,
          },
          options: {
            keepFilter,
            isRefresh,
          },
        }),
      );

      dispatch(
        getSummaryJobListing({
          ...filter,
          viewType: VIEW_TYPE_JOB_LISTINGS.INDIVIDUAL,
        }),
      );
    },
    [dispatch],
  );

  useEffect(() => {
    if (tabMode === VIEW_TYPE_JOB_LISTINGS.INDIVIDUAL) {
      retrieveList({
        filter: {
          sorts: filterRef.current.sorts || [DEFAULT_SORT_INDIVIDUAL],
          page: filterRef.current.page || DEFAULT_CURRENT_PAGE,
          size: filterRef.current.size || LIMIT_ITEM,
          filter: filterRef.current.filter,
        },
        isRefresh: true,
        keepFilter: true,
      });
    }
  }, [retrieveList, tabMode]);

  const pushQuery = useCallback(
    (searchStr) => {
      push(`${location.pathname}?${getSearchUrl(searchStr)}`);
    },
    [location.pathname, push],
  );

  const handleEnterWaypoint = () => {
    const restFilter = {
      page: resourceData.page ? resourceData.page + 1 : DEFAULT_CURRENT_PAGE,
      size: LIMIT_ITEM,
      sorts: [DEFAULT_SORT_INDIVIDUAL],
      filter: { ...filter.filter },
    };

    if (
      resourceData.page * LIMIT_ITEM < resourceData.total &&
      jobIndividualData.length > 0 &&
      !loading
    ) {
      retrieveList({
        filter: restFilter,
        isRefresh: false,
        keepFilter: false,
      });
    }
  };

  const handleUpdateViewType = (mode) => {
    push(`/job-listings/individual/${mode}`);
  };

  return (
    <div>
      <Summaries />
      <div className="mt-32">
        <ListingFilterCustom
          retrieveList={retrieveList}
          pushQuery={pushQuery}
          type={viewMode}
          handleUpdateViewType={handleUpdateViewType}
        />
      </div>

      {jobIndividualData?.length || loading || !isEmpty(filter?.filter) ? (
        <>
          {viewMode === DISPLAY_TYPES.list ? (
            <TableView
              jobIndividualData={jobIndividualData}
              retrieveList={retrieveList}
              pushQuery={pushQuery}
              loading={loading}
            />
          ) : (
            <>
              {!!jobIndividualData.length && (
                <JobList>
                  {jobIndividualData?.map((job) => (
                    <IndividualJob job={job} key={job.id} />
                  ))}
                </JobList>
              )}

              {loading && (
                <div className="flex justify-center">
                  <Spin />
                </div>
              )}
              <Waypoint onEnter={handleEnterWaypoint} />
            </>
          )}
        </>
      ) : (
        <NoJobListings
          title="jobListings.noJobListings"
          description="jobListings.noJobListingsDesc"
          isShowCreateJobListing
        />
      )}
    </div>
  );
};

export default IndividualJobListingView;

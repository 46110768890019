import React, { useRef } from 'react';
import i18next from 'i18next';
import PropTypes from 'prop-types';

import { HeaderInfoRequestStyles } from './styles';
import CreateModal from './CreateModal';
import CreateButton from './CreateButton';

const HeaderInfoRequest = ({ applicationId }) => {
  const modalRef = useRef();

  const onToggleModal = () => {
    modalRef.current && modalRef.current.toggleModal();
  };

  return (
    <>
      <HeaderInfoRequestStyles>
        <span className="title-card">
          {i18next.t('informationRequests.title')}
        </span>
        <CreateButton onToggleModal={onToggleModal} />
      </HeaderInfoRequestStyles>
      <CreateModal ref={modalRef} applicationId={applicationId} />
    </>
  );
};

HeaderInfoRequest.propTypes = {
  applicationId: PropTypes.string,
};

export default HeaderInfoRequest;

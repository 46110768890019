import React from 'react';
import { Col, Divider, Row, Tooltip, Typography } from 'antd';
import PropTypes from 'prop-types';
import {
  ClockIcon,
  CopyFillIcon,
  DocumentTextIcon,
  FolderOpenIcon,
  LocationFillIcon,
  ShopIcon,
  TimerIcon,
  UserTagIcon,
} from 'components/common/SVGIcon';
import { formatDateApplication } from 'utils/textUtils';
import i18next from 'i18next';
import JobListingGeneralInfoItem from './JobListingGeneralInfoItem';
import StatisticsInfo from './StatisticsInfo';
import { JobListingGeneralInfoStyles } from './styles';
import JobListingStatus from '../JobListingStatus';

const { Paragraph } = Typography;

const JobListingGeneralInfo = ({ record }) => (
  <JobListingGeneralInfoStyles>
    <Row justify="space-between" className="listing-status">
      <Col>
        <JobListingStatus status={record?.jobStatus} />
      </Col>
      <Col>
        <Paragraph
          copyable={{
            icon: [<CopyFillIcon />],
            tooltips: [
              i18next.t('jobListings.copy'),
              i18next.t('jobListings.copied'),
            ],
            text: record?.jobReference,
          }}
          className="mt-6"
        >
          <span className="text-gray-neutral-500 text-decoration-line fw-600">
            {record?.jobReference}
          </span>
        </Paragraph>
      </Col>
    </Row>
    <div className="text-20 fw-bold">
      <Tooltip title={i18next.t('jobListings.jobTitle')}>
        {record?.jobTitle}
      </Tooltip>
    </div>
    <JobListingGeneralInfoItem
      icon={<UserTagIcon />}
      content={record?.jobRole?.name}
      titleTooltip="jobListings.jobRole"
    />
    <JobListingGeneralInfoItem
      icon={<ShopIcon />}
      content={record?.organization?.name}
      titleTooltip="jobListings.workplace"
    />
    <JobListingGeneralInfoItem
      icon={<LocationFillIcon />}
      content={record?.organization?.address}
      titleTooltip="jobListings.jobListingDetails.workplaceAddress"
    />
    <Divider />
    <JobListingGeneralInfoItem
      icon={<FolderOpenIcon className="text-green" />}
      content={record?.campaignName}
      titleTooltip="jobListings.campaignName"
    />
    <JobListingGeneralInfoItem
      icon={<DocumentTextIcon />}
      content={record?.templateName}
      titleTooltip="jobListings.jobTemplate"
    />
    <Divider />
    {record?.isStartImmediate ? (
      <JobListingGeneralInfoItem
        icon={<ClockIcon />}
        content="jobListings.immediateStart"
        titleTooltip="jobListings.steps.startDate"
      />
    ) : (
      <JobListingGeneralInfoItem
        icon={<ClockIcon />}
        content={formatDateApplication(record?.startDate)}
        titleTooltip="jobListings.steps.startDate"
      />
    )}
    <JobListingGeneralInfoItem
      icon={<TimerIcon />}
      content={formatDateApplication(record?.applicationDeadline)}
      titleTooltip="jobListings.steps.applicationDeadline"
    />
    <div className="text-16 text-gray-neutral-500 mt-16">
      {record?.distanceUpdatedAt}
    </div>
    <Divider />
    <Row gutter={[5, 5]}>
      <Col md={12} xs={24}>
        <StatisticsInfo
          title="jobListings.jobListingDetails.totalApplications"
          statisticsNumber={record?.totalApplications}
          isTotalApplicationsColor
        />
      </Col>
      <Col md={12} xs={24}>
        <StatisticsInfo
          title="jobListings.jobListingDetails.newApplications"
          statisticsNumber={record?.newApplications}
        />
      </Col>
    </Row>
  </JobListingGeneralInfoStyles>
);

JobListingGeneralInfo.propTypes = {
  record: PropTypes.object,
};

export default JobListingGeneralInfo;

import React from 'react';
import PropTypes from 'prop-types';
import { Progress } from 'antd';
import { MatchingScoreWrapper } from './styles';

const JobMatchProgressCircle = (props = {}) => {
  const { score, unit } = props;
  return (
    <MatchingScoreWrapper className="pointer">
      <Progress
        type="circle"
        width={48}
        percent={score}
        format={(percent) => (
          <div>
            {percent}
            <sup>{unit}</sup>
          </div>
        )}
        className="progress-style"
      />
    </MatchingScoreWrapper>
  );
};

JobMatchProgressCircle.propTypes = {
  score: PropTypes.string,
  unit: PropTypes.string,
};

export default JobMatchProgressCircle;

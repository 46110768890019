import styled from 'styled-components';

export const ClaimSideCoverContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-image: url('https://s3.ap-southeast-2.amazonaws.com/files.kindicare.com/uploads/static/assets/images/claim-centres/claim-cover-main.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;

  @media only screen and (max-width: 992px) {
    display: none;
  }

  @media only screen and (min-width: 2000px) {
    height: 94%;
  }
`;

export const CoverText = styled.div`
  --font-size: 32px;
  position: absolute;
  top: 50px;
  width: 100%;
  font-weight: 700;
  font-size: var(--font-size);
  line-height: 1.25;
  text-align: center;
  color: #ffffff;

  @media only screen and (min-width: 2000px) {
    --font-size: 36px;
  }

  @media only screen and (min-width: 2400px) {
    --font-size: 40px;
  }

  @media only screen and (min-width: 3000px) {
    --font-size: calc(40px + 0.390625vw);
  }
`;

export const GroupButton = styled.div`
  --font-size: 18px;
  --height-btn: 40px;
  position: absolute;
  display: flex;
  justify-content: center;
  gap: 12px;
  bottom: -40px;
  width: 100%;
  .anticon {
    font-size: calc(var(--font-size) - 2px);
  }

  .btn-book-demo {
    color: #32a4fc;
    border: 1px solid #32a4fc;
    font-size: var(--font-size);
    border-radius: 12px;
  }
  .btn-learn-more {
    color: #db147f;
    border: 1px solid #db147f;
    font-size: var(--font-size);
    border-radius: 12px;
  }

  .ant-btn {
    height: var(--height-btn);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 12px 16px !important;
  }

  @media only screen and (min-width: 2000px) {
    --font-size: 20px;
    --height-btn: 50px;
  }

  @media only screen and (min-width: 2400px) {
    --font-size: 24px;
    --height-btn: 52px;
  }

  @media only screen and (min-width: 3000px) {
    --font-size: 32px;
    --height-btn: unset;

    .ant-btn {
      padding: 16px 26px !important;
    }

    bottom: -90px;
  }
`;

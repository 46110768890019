import React from 'react';
import PropTypes from 'prop-types';
import EditButton from 'components/RestActions/EditButton';
import { useHistory } from 'react-router';
import { ENQUIRIES_STATUS_CONST } from 'configs/localData';

const GroupActionEnquiry = ({ id, centreEnquiryStatus }) => {
  const { push } = useHistory();

  const handleRedirect = () => {
    centreEnquiryStatus === ENQUIRIES_STATUS_CONST.archived.value
      ? push(`/enquiries/archived/${id}`)
      : push(`/enquiries/all/${id}`);
  };
  return <EditButton isView onClickCustom={handleRedirect} />;
};

GroupActionEnquiry.propTypes = {
  id: PropTypes.string,
  centreEnquiryStatus: PropTypes.string,
};

export default GroupActionEnquiry;

import { Layout } from 'antd';
import styled from 'styled-components';

export const BasicInfoBannerWrapper = styled(Layout)`
  --header-height: 88px;
  --sider-width: 30%;

  .btn-back:not(:disabled) {
    background: ${({ theme }) => theme.background.content};
    color: ${({ theme }) => theme.palette.primary};
    border: 1px solid ${({ theme }) => theme.palette.primary};
  }

  .btn-exit-wrapper {
    position: absolute;
    top: 50%;
    right: 64px;
    transform: translateY(-50%);
    & > button > span {
      text-decoration: underline;
    }
  }

  .btn-exit:not(:disabled) {
    color: ${({ theme }) => theme.palette.primary};
    &:hover,
    &:focus,
    &:active {
      background-color: white;
    }
  }

  .banner {
    &-container {
      background: linear-gradient(
        213.28deg,
        #d6f1eb 0.73%,
        #c9eade 54.5%,
        #edf8d8 98.45%
      );
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 100%;
      position: relative;
    }

    &-content {
      position: relative;
      margin: 30px 40px;

      &::after {
        content: '';
        background: radial-gradient(
          85.48% 229.42% at 1.95% 3.59%,
          rgba(255, 255, 255, 0.6) 0%,
          rgba(255, 255, 255, 0.13) 100%
        );
        backdrop-filter: blur(30px);
        border-radius: 24px;
        width: 85%;
        height: 90.5%;
        position: absolute;
        top: 45%;
        left: 0;
        right: 0;
        margin-left: auto;
        margin-right: auto;
        -ms-transform: translate(0, -50%);
        transform: translate(0, -50%);
        margin-top: 30px;
        z-index: 1;
      }
      &-img {
        padding: 10px;
        position: relative;
        z-index: 2;
      }
      &-title {
        text-align: center;
        font-weight: 700;
        font-size: 36px;
        line-height: 50px;
        letter-spacing: -0.02em;
        color: ${({ theme }) => theme.text.darkGreen};
        margin: 30px 0 65px;
        position: relative;
        z-index: 2;
        top: 5.5%;
        white-space: pre-line;
      }
    }
    &-title {
      position: absolute;
      transform: translate(0, -50%);
      left: 0;
      right: 0;
      margin-left: 0;
      margin-right: 0;
      text-align: center;
      font-weight: 700;
      font-size: 2vw;
      line-height: 3vw;
      letter-spacing: -0.02em;
      color: ${({ theme }) => theme.text.darkGreen};
      margin-bottom: 20px;
      z-index: 99999;
    }
  }

  .kc-job-logo {
    width: 235px;
    & > img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  .ant-layout-sider {
    background: ${({ theme }) => theme.background.lightGreen};
    height: 100vh;
  }
  .ant-layout-header,
  .ant-layout-footer,
  .ant-layout-content {
    background: white;
  }
  .ant-layout-footer {
    border-top: 1px solid ${({ theme }) => theme.border.lightGray};
  }
  .ant-layout-header {
    position: relative;
    border-bottom: 1px solid ${({ theme }) => theme.border.lightGray};
    height: var(--header-height);
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .ant-steps-vertical {
    padding: 100px 0 0 75px;
  }
  .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon {
    background: ${({ theme }) => theme.palette.green};
    border-color: ${({ theme }) => theme.palette.green};
  }
  .ant-steps-item-finish .ant-steps-item-icon {
    border-color: ${({ theme }) => theme.palette.green};
  }
  .ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
    color: ${({ theme }) => theme.palette.green};
  }
  .ant-steps-item-wait .ant-steps-item-icon {
    background: rgba(43, 173, 167, 0.32);
    border: none;
  }
  .ant-steps-item-wait .ant-steps-item-icon > .ant-steps-icon {
    color: white;
  }
  .ant-steps-item-tail {
    background: ${({ theme }) => theme.palette.green};
    margin-top: 35px;
    padding-bottom: 0 !important;
  }
  .ant-steps-item-wait .ant-steps-item-tail {
    background: rgba(43, 173, 167, 0.32);
  }
  .ant-steps-item-process
    > .ant-steps-item-container
    > .ant-steps-item-content
    > .ant-steps-item-title {
    color: ${({ theme }) => theme.text.darkGreen};
    font-weight: bold;
  }

  .ant-steps-item-wait,
  .ant-steps-item-finish
    > .ant-steps-item-container
    > .ant-steps-item-content
    > .ant-steps-item-title {
    color: ${({ theme }) => theme.text.grayNeutral500};
    font-weight: 600;
  }

  .ant-steps-vertical > .ant-steps-item-container > .ant-steps-item-tail {
    background: ${({ theme }) => theme.palette.green};
  }
  .ant-steps-vertical
    > .ant-steps-item
    > .ant-steps-item-container
    > .ant-steps-item-tail {
    height: 0;
  }

  .ant-layout-content {
    min-height: 80%;
  }

  .ant-layout-sider {
    overflow: hidden;
    height: 100vh;
    position: fixed;
    z-index: 0;

    .ant-layout-sider-children {
      overflow-y: auto;
      overflow-x: hidden;
      &::-webkit-scrollbar {
        border-radius: 10px;
        width: 4px;
      }
      ::-webkit-scrollbar-track {
        background: #fff !important;
      }
      ::-webkit-scrollbar-thumb {
        background: #fff !important;
      }

      &:hover {
        ::-webkit-scrollbar-thumb {
          background: ${({ theme }) => theme.scrollbar.thumb} !important;
        }
      }
    }
  }

  .ant-layout-header {
    position: fixed;
    top: 0;
    left: var(--sider-width);
    right: 0;
    z-index: 10;
  }

  .ant-layout {
    min-height: 100vh;
  }

  .ant-layout-content {
    position: absolute;
    top: var(--header-height);
    left: var(--sider-width);
    right: 0;
    bottom: 0;
    padding: 64px;
    padding-bottom: 120px;

    > div {
      padding-bottom: calc(var(--header-height) + 64px);
    }
  }

  .ant-layout-footer {
    position: fixed;
    left: var(--sider-width);
    right: 0;
    bottom: 0;
    padding: 24px 40px;
    z-index: 10;
  }

  @media only screen and (max-width: 1200px) {
    .banner {
      &-content {
        &-title {
          font-size: 2vw;
          line-height: 3vw;
        }
      }
    }
  }

  @media only screen and (max-width: 992px) {
    --sider-width: 80px;

    .ant-layout-content {
      padding: 32px;
    }

    .ant-steps-item-title {
      display: none;
    }
    .ant-steps-vertical {
      padding: 100px 25px;
    }

    .banner {
      &-container {
        background: ${({ theme }) => theme.background.lightGreen};
        &::after {
          display: none;
        }
      }
      &-content {
        display: none;
      }
    }
  }
`;

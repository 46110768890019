import React from 'react';
import renderHTML from 'react-render-html';
import i18next from 'i18next';
import { GallerySlashIcon } from '../SVGIcon';

interface Props {
  className?: string;
}

const ImageError = ({ className }: Props) => (
  <div
    className={`flex-center flex-direction-column rounded-top-left-right-radius-8 bg-n30 h-150 ${
      className || ''
    }`}
  >
    <div className="flex-center text-gray-neutral-500 text-20">
      <GallerySlashIcon />
    </div>
    <div className="px-32 text-center text-12 text-gray-neutral-500">
      {renderHTML(i18next.t('error.errorImageInfo'))}
    </div>
  </div>
);

export default ImageError;

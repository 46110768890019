import React from 'react';
import { useSelector } from 'react-redux';
import { getEnquiriesSummary } from 'redux/dashboard/selectors';

import PieChartCustom from 'components/charts/PieChartCustom';
import CardCommon from '../CardCommon';
import TotalEnquiries from './TotalEnquiries';
import { EnquiriesSummaryStyles } from './styles';

const EnrolmentPipeline = () => {
  const { chartData, totalEnquiries } = useSelector(getEnquiriesSummary) || {};
  return (
    <CardCommon title="centreDetail.enquiriesSummary">
      <EnquiriesSummaryStyles>
        <PieChartCustom
          data={chartData}
          innerRadius={0}
          paddingAngle={0}
          alignLegend="bottom"
          verticalAlignLegend="bottom"
          customExtra={<TotalEnquiries totalEnquiries={totalEnquiries} />}
          height={220}
          cy={110}
        />
      </EnquiriesSummaryStyles>
    </CardCommon>
  );
};

export default EnrolmentPipeline;

import { Col, Row } from 'antd';
import FilterCustom from 'components/common/FilterCustom';
import ViewModeSwitcher from 'components/common/ViewModeSwitcher';
import PropTypes from 'prop-types';
import {
  DEFAULT_CURRENT_PAGE,
  DEFAULT_SORT_JOB_APPLICATIONS,
  LIMIT_ITEM,
} from 'containers/JobApplications/constants';
import React, { useCallback } from 'react';
import { useLocation } from 'react-router';
import { getFilterFromUrl, getSearchUrl } from 'utils/tools';
import { formatFromToTimeFilterSubmit } from 'utils/dataUtils';
import { useDispatch } from 'react-redux';
import { getJobApplicationSummaryCard } from 'redux/jobs/jobApplications/actions';
import FilterForm from './FilterForm';

const JoApplicationsFilter = ({
  retrieveList,
  pushQuery,
  history,
  activeTab,
  setFilterData,
}) => {
  const { search } = useLocation();
  const { filter } = getFilterFromUrl(search);
  const dispatch = useDispatch();

  const onSubmitFilter = ({ lastUpdated, ...value }) => {
    const restFilter = {
      size: filter.size,
      page: DEFAULT_CURRENT_PAGE,
      sorts: [DEFAULT_SORT_JOB_APPLICATIONS],
      filter: {
        lastUpdated: formatFromToTimeFilterSubmit(lastUpdated),
        ...value,
      },
    };
    retrieveList({
      filter: restFilter,
      isRefresh: true,
    });
    dispatch(getJobApplicationSummaryCard({ filter: restFilter?.filter }));
    setFilterData({ ...restFilter });
    pushQuery({
      'sorts[]': filter.sorts,
      ...restFilter,
    });
  };

  const onChange = (e) => {
    history &&
      history.push(
        `/job-applications/${e.target.value}?${getSearchUrl(filter)}`,
      );
  };

  const onClearFilter = useCallback(() => {
    retrieveList({
      filter: {
        size: filter.size || LIMIT_ITEM,
        page: filter.page || DEFAULT_CURRENT_PAGE,
        sorts: filter.sorts || [DEFAULT_SORT_JOB_APPLICATIONS],
      },
      isRefresh: true,
    });
    setFilterData({});
    dispatch(getJobApplicationSummaryCard());
    pushQuery({ 'sorts[]': filter?.sorts });
  }, [dispatch, retrieveList, filter, pushQuery, setFilterData]);

  return (
    <FilterCustom onSubmitFilter={onSubmitFilter} onClearFilter={onClearFilter}>
      <Row gutter={24}>
        <Col xxl={4} xl={6} lg={6} md={24} xs={24}>
          <ViewModeSwitcher onChange={onChange} activeTab={activeTab} />
        </Col>
        <Col xxl={20} xl={18} lg={18} md={24} xs={24}>
          <FilterForm />
        </Col>
      </Row>
    </FilterCustom>
  );
};

JoApplicationsFilter.propTypes = {
  history: PropTypes.object,
  activeTab: PropTypes.string,
  setFilterData: PropTypes.func,
  pushQuery: PropTypes.func,
  retrieveList: PropTypes.func,
};

export default JoApplicationsFilter;

import React from 'react';
import { CurrentStepTitle } from 'containers/Centres/components/CentreTabs/EducatorStaffProfilesTab/Create/Steps/styles';
import i18next from 'i18next';
import Footer from 'containers/Centres/components/CentreTabs/EducatorStaffProfilesTab/Create/Footer';
import { useDispatch, useSelector } from 'react-redux';
import { goToNextStep, setStaffProfilePayload } from 'redux/staffProfile/slice';
import { FormInstance } from 'antd';
import { getPayloadStaffProfile } from 'redux/staffProfile/selector';
import AboutStaffForm from './AboutStaffForm';

interface AboutStaffFormProps {
  form: FormInstance;
}

const AboutStaff = ({ form }: AboutStaffFormProps) => {
  const dispatch = useDispatch();
  const staffProfileFormValues = useSelector(getPayloadStaffProfile);
  const aboutStaffValue = staffProfileFormValues?.about;
  const { basicInfo } = staffProfileFormValues;
  const educatorStaffName =
    basicInfo?.firstName ||
    basicInfo?.lastName ||
    form.getFieldValue('firstName') ||
    form.getFieldValue('lastName') ||
    '';

  const onNext = () => {
    form.validateFields().then((formValues) => {
      dispatch(setStaffProfilePayload({ about: formValues.about }));
      dispatch(goToNextStep());
    });
  };

  return (
    <div>
      <CurrentStepTitle>
        {i18next.t('educatorStaffProfile.aboutOptional', {
          name: educatorStaffName,
        })}
      </CurrentStepTitle>
      <AboutStaffForm form={form} initialValues={aboutStaffValue} />
      <Footer onNext={onNext} />
    </div>
  );
};

export default AboutStaff;

import React from 'react';
import ModalCustom from 'components/common/ModalCustom';
import i18next from 'i18next';
import RestInputItem from 'components/RestInput/RestInputItem';

interface Props {
  onOk: () => void;
  onCancel: () => void;
  visible: boolean;
  loading?: boolean;
}

const SaveAudienceModal = ({ visible, onOk, onCancel, loading }: Props) => (
  <ModalCustom
    visible={visible}
    title={i18next.t('advertisement.nameAudience')}
    onCancel={onCancel}
    resetPaddingBottomBody
    resetPaddingTopBody
    onOk={onOk}
    okText="button.ok"
    cancelButtonProps={{ className: 'btn-line-primary fw-bold' }}
    width={440}
    loading={loading}
  >
    <RestInputItem
      source="name"
      required
      isValidatorEmpty
      maxLength={250}
      header="advertisement.audienceType"
      placeholder="input.audienceType.placeholder"
    />
  </ModalCustom>
);

export default SaveAudienceModal;

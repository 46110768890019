import React from 'react';
import { Input } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import i18next from 'i18next';
import { debounce } from 'lodash-es';
import { useHistory, useLocation } from 'react-router';
import { convertSearchUrl, getFilterFromUrl } from 'utils/tools';
import { RetrieveList } from 'containers/Advertisement/List';

interface SearchAds {
  retrieveList?: (params: RetrieveList) => void;
  resetPage: () => void;
}

const SearchAdsInput = ({ retrieveList, resetPage }: SearchAds) => {
  const { search } = useLocation();
  const { filter: restFilter } = getFilterFromUrl(search);
  const { push } = useHistory();

  const debounceSearch = debounce((value) => {
    resetPage();

    const filter = {
      title: value,
    };

    push({
      search: `?${convertSearchUrl({
        ...restFilter,
        filter,
      })}`,
    });

    retrieveList({
      filter: {
        filter: filter?.title ? filter : undefined,
      },
      isRefresh: true,
    });
  }, 400);

  const onChange = (e) => {
    const textValue = e.target?.value;
    debounceSearch(textValue);
  };

  const renderPrefix = () => (
    <SearchOutlined size={14} className="text-gray-500 mr-10" />
  );

  return (
    <Input
      allowClear
      prefix={renderPrefix()}
      placeholder={i18next.t('advertisement.searchForAnAd')}
      onChange={onChange}
      defaultValue={restFilter.filter?.title || ''}
    />
  );
};

export default SearchAdsInput;

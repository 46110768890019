import React from 'react';
import PropTypes from 'prop-types';
import i18next from 'i18next';
import { Avatar, Space } from 'antd';
import { KEYS_DAYS_IN_WEEK } from 'configs/localData';
import { AvailableDaysStyles } from './styles';

const AvailableDays = ({ availableDays }) => {
  const getClassActive = (value) =>
    availableDays.some((item) => item?.day === value)
      ? 'avatar-active'
      : 'avatar-disabled';

  return (
    <AvailableDaysStyles>
      <Space size={6} align="centre">
        {KEYS_DAYS_IN_WEEK?.map((item, index) => (
          <Avatar
            key={String(index)}
            className={`day-item ${getClassActive(item?.value)}`}
          >
            {i18next.t(item?.text)}
          </Avatar>
        ))}
      </Space>
    </AvailableDaysStyles>
  );
};

AvailableDays.propTypes = {
  availableDays: PropTypes.array,
};

AvailableDays.defaultProps = {
  availableDays: [],
};

export default AvailableDays;

import React from 'react';
import PropTypes from 'prop-types';
import ReadMore from 'components/common/ReadMore';

const WorkExperienceContent = ({ item }) => (
  <div className="text-16 leading-loose">
    <strong className="job-title text-16">{item?.roleName}</strong>
    <div className="fw-400">{item?.organizationName}</div>
    <div className="text-gray-500 flex items-center w-full working-time">
      {item?.jobType}
      {item?.jobType && (
        <span className="text-gray-500 text-16 mr-10 ml-10">&#9679;</span>
      )}
      {item?.duration}
    </div>
    <ReadMore
      text={item?.description || ''}
      showLessButton
      readMoreCharacterLimit={500}
    />
  </div>
);

WorkExperienceContent.propTypes = {
  item: PropTypes.object,
};

export default WorkExperienceContent;

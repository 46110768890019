import { Tabs } from 'antd';
import useRolesJobTemplates from 'containers/Users/hooks/useRoleJobTemplates';
import i18next from 'i18next';
import React, { useCallback } from 'react';
import { useHistory, useParams, useLocation } from 'react-router';
import { useSelector } from 'react-redux';
import { getDataJobTemplateDetail } from 'redux/jobs/jobTemplates/selectors';
import CertificationsTab from './CertificationsTab';
import GeneralInformationTab from './GeneralInformationTab';
import ImmunisationRequirementsTab from './ImmunisationRequirementsTab';
import JobApplicationSettingsTab from './JobApplicationSettingsTab';
import JobFeaturesTab from './JobFeaturesTab';
import JobRequirementsSkillsTab from './JobRequirementsSkillsTab';

const { TabPane } = Tabs;

const JobTabs = () => {
  const { push } = useHistory();
  const { id, modelDetail } = useParams();
  const { search } = useLocation();

  const currentJob = useSelector(getDataJobTemplateDetail);
  const disabledAction = useRolesJobTemplates();

  const TABS = [
    {
      key: 'generalInformation',
      title: 'jobs.jobDetails.tabs.generalInformation',
      TabComponent: GeneralInformationTab,
    },
    {
      key: 'jobFeatures',
      title: 'jobs.jobDetails.tabs.jobFeatures',
      TabComponent: JobFeaturesTab,
    },
    {
      key: 'certifications',
      title: 'jobs.jobDetails.tabs.certifications',
      TabComponent: CertificationsTab,
    },
    {
      key: 'jobRequirementsSkills',
      title: 'jobs.jobDetails.tabs.jobRequirementsSkills',
      TabComponent: JobRequirementsSkillsTab,
    },
    {
      key: 'immunisationRequirements',
      title: 'jobs.jobDetails.tabs.immunisationRequirements',
      TabComponent: ImmunisationRequirementsTab,
    },
    {
      key: 'jobApplicationSettings',
      title: 'jobs.jobDetails.tabs.jobApplicationSettings',
      TabComponent: JobApplicationSettingsTab,
    },
  ];

  const onChange = useCallback(
    (key) => {
      push({
        pathname: `/jobTemplates/${id}/show/${key}`,
        search,
      });
    },
    [id, push, search],
  );

  return (
    <div>
      <Tabs activeKey={modelDetail || 'generalInformation'} onChange={onChange}>
        {TABS.map(({ title, key, TabComponent, ...tab }) => (
          <TabPane tab={i18next.t(title)} key={key}>
            <TabComponent
              {...tab}
              record={{}}
              currentData={currentJob}
              disabledAction={disabledAction}
            />
          </TabPane>
        ))}
      </Tabs>
    </div>
  );
};

export default JobTabs;

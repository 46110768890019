import { Skeleton } from 'antd';
import noData from 'assets/images/no-data.png';
import OrganisationUserItem from 'containers/Centres/components/CentreTabs/ServicesTab/Modals/OrganisationUserItem';
import { CreateProgramButtonStyle } from 'containers/ProgramsAndLearning/styles';
import { usePermittedUsersControllerGetPermittedUsersQuery } from 'generated/apis';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router/esm/react-router';

interface Props {
  onCreateProgram?: () => void;
  isCreate: boolean;
}

const EmptyPrograms = ({ onCreateProgram, isCreate }: Props) => {
  const { id } = useParams();
  const { t } = useTranslation();

  const { data, isLoading: permittedUserLoading } =
    usePermittedUsersControllerGetPermittedUsersQuery(
      {
        centreId: id,
      },
      {
        skip: isCreate,
      },
    );

  return (
    <div className="flex-col flex-center px-24 py-32 gap-24 empty-programs">
      <div className="flex-col flex-center gap-16">
        <img src={noData} alt="no-data" />
        <div className="text-neutral-700">
          {t('programLearning.noProgramsDescription')}
        </div>
      </div>
      {isCreate ? (
        <CreateProgramButtonStyle
          type="primary"
          onClick={onCreateProgram}
          className="text-16 fw-bold p-12-24 h-48 rounded-12"
        >
          {t('programLearning.createProgram')}
        </CreateProgramButtonStyle>
      ) : (
        <Skeleton active loading={permittedUserLoading}>
          <div className="flex-col flex-center gap-24 ">
            <div className="text-neutral-700">
              {t('programLearning.contactToCreateProgram')}
            </div>
            {data?.result?.map((user) => (
              <OrganisationUserItem key={user.id} user={user} />
            ))}
          </div>
        </Skeleton>
      )}
    </div>
  );
};

export default EmptyPrograms;

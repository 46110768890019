import React, { useLayoutEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import i18n from 'i18next';
import { CloseSquareOutlined } from '@ant-design/icons';
import { useHistory, useLocation } from 'react-router';
import Text from 'components/common/Text';
import RestEditComponent from 'components/RestLayout/Edit';
import CRUDActions from 'redux/crudActions';
import { getIdByUrl } from 'utils/tools';
import { PRIMARY_KEY } from 'redux/crudCreator/dataProvider';
import crudSelectors from 'redux/crudSelectors';
import { handleApiError } from 'utils/validateUtils';

const RestEdit = (props) => {
  const {
    showModal,
    visibleModal,
    header,
    resource,
    customOnSubmit,
    defaultOptions,
    isClearCurrent,
    className,
    defaultOptionsGetById,
    resourceCustom,
  } = props;
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const id = getIdByUrl(resourceCustom || resource, location);

  const errorRequest = useSelector(crudSelectors[props.resource].getError);
  const record = useSelector(crudSelectors[props.resource].getCurrentData);
  const closeModal = () => {
    history.replace(location.pathname);
  };
  const gotoShowPage = (id) =>
    history.push(`${location.pathname.replace('/:id/edit', '')}/${id}/show`);

  const onBack = () => {
    if (!visibleModal) {
      if (!props.isRedirect) return;
      history.goBack();
    } else {
      closeModal();
    }
  };

  const onSubmit = (data, form) => {
    if (customOnSubmit) {
      dispatch(
        customOnSubmit({
          id,
          data,
          options: {
            isBack: true,
            ...defaultOptions,
          },
        }),
      );
    } else
      dispatch(
        CRUDActions[resource].edit({
          data: {
            ...data,
            [PRIMARY_KEY]: id,
          },
          options: { isBack: true, ...defaultOptions },
        }),
      ).then(({ payload: { data } }) => {
        handleApiError(data, form);
        if (data.id && !(!defaultOptions || defaultOptions.isBack === false)) {
          onBack();
        }
      });
  };

  useLayoutEffect(() => {
    if (id) {
      dispatch(
        CRUDActions[resource].getDataById({
          data: {
            [PRIMARY_KEY]: id,
          },
          options: {
            isRequestApi: true,
            isRefresh: true,
            ...defaultOptionsGetById,
          },
        }),
      );
    }
    return () => {
      isClearCurrent && dispatch(CRUDActions[resource].clearCurrent());
    };
  }, [id, defaultOptionsGetById, dispatch, resource, isClearCurrent]);

  const content = (
    <RestEditComponent
      {...props}
      resource={resource}
      header={header}
      customOnSubmit={customOnSubmit}
      showModal={showModal}
      onBack={onBack}
      onSubmit={onSubmit}
      record={record}
      error={errorRequest}
      gotoShowPage={gotoShowPage}
    />
  );

  return !showModal ? (
    <div className={className}>{content}</div>
  ) : (
    <div className={className}>
      {header !== null && (
        <Text type="h3" className="modalTitleContent">
          <div className="modalTitle">
            {!header || typeof header === 'string'
              ? i18n.t(header || `${resource}.editPage`)
              : header}
          </div>
          <CloseSquareOutlined onClick={onBack} className="modalBtnBack" />
        </Text>
      )}
      {content}
    </div>
  );
};

RestEdit.propTypes = {
  resource: PropTypes.string,
  showModal: PropTypes.bool,
  header: PropTypes.string,
  visibleModal: PropTypes.bool,
  defaultOptions: PropTypes.shape({
    isShowSuccessNoti: PropTypes.bool,
    isBack: PropTypes.bool,
  }),
  customOnSubmit: PropTypes.func,
  isRedirect: PropTypes.bool,
  isClearCurrent: PropTypes.bool,
  className: PropTypes.string,
  defaultOptionsGetById: PropTypes.object,
  resourceCustom: PropTypes.string,
};

RestEdit.defaultProps = {
  isRedirect: true,
  defaultOptions: {},
  isClearCurrent: true,
};

export default RestEdit;

import { Progress } from 'antd';
import styled from 'styled-components';

export const AnimateProgress = styled(Progress)`
  .ant-progress-bg {
    animation: leftToRight 2.5s infinite linear;
  }

  @keyframes leftToRight {
    0% {
      left: -25%;
    }
    100% {
      left: 100%;
    }
  }
`;

import styled from 'styled-components';

export const NoTemplatesStyles = styled.div`
  .ant-card-meta-title {
    font-weight: 700;
    font-size: 18px;
    color: ${({ theme }) => theme.text.blackNeutral800};
  }
  .ant-card-meta-description {
    color: ${({ theme }) => theme.text.grayNeutral500};
  }
`;

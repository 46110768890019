import React from 'react';
import PropTypes from 'prop-types';
import { Button, Col, Layout, Row } from 'antd';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import i18next from 'i18next';
import { useSelector } from 'react-redux';
import {
  getJobListingCurrentStepSelector,
  getLoadingCreateJobListing,
} from 'redux/jobs/jobListings/selectors';
import { CREATE_JOB_LISTING_FINAL_STEP } from '../constants';

const JobTemplateFooter = ({ handleGoToNextStep, handleGoToPrevStep }) => {
  const currentStep = useSelector(getJobListingCurrentStepSelector);
  const createLoading = useSelector(getLoadingCreateJobListing);

  const isFinalStep = currentStep === CREATE_JOB_LISTING_FINAL_STEP;

  const isPublishing = isFinalStep && createLoading;

  return (
    <Layout.Footer>
      <Row justify="space-between">
        <Col>
          <Button
            onClick={handleGoToPrevStep}
            icon={<LeftOutlined />}
            className="btn-back"
          >
            {i18next.t('button.back')}
          </Button>
        </Col>
        <Col>
          <Button
            type="primary"
            onClick={handleGoToNextStep}
            loading={isPublishing}
            disabled={isPublishing}
          >
            {isFinalStep
              ? i18next.t('button.publish')
              : i18next.t('button.next')}
            <RightOutlined />
          </Button>
        </Col>
      </Row>
    </Layout.Footer>
  );
};

JobTemplateFooter.propTypes = {
  handleGoToPrevStep: PropTypes.func,
  handleGoToNextStep: PropTypes.func,
};

export default JobTemplateFooter;

import { Spin } from 'antd';
import styled from 'styled-components';

export const KeyMetricsWrapper = styled.div`
  background-color: #fff;
  border-radius: 12px;
  padding: 24px;
  margin-bottom: 36px;
  h1 {
    font-size: 24px;
  }
`;

export const SpinWrapper = styled(Spin)<{ children: React.ReactNode }>``;

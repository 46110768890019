import React, { useEffect, useState } from 'react';
import i18next from 'i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Button, Input } from 'antd';
import {
  resetPassword as resetPasswordAction,
  verifyResetPasswordToken,
} from 'redux/auth/actions';

import { validateRegex } from 'utils/validateUtils';
import AuthContact from 'components/common/AuthContact';
import Loading from 'components/common/Loading';
import GuideContent from './GuideContent';
import ResetPasswordStyleWrapper from './styles';
import logo from '../../assets/images/logo_plus.svg';
import ResetPasswordSuccess from './ResetPasswordSuccess';
import ResetPasswordExpired from './ResetPasswordExpired';

const FormItem = Form.Item;

const ResetPassword = () => {
  const [form] = Form.useForm();
  const { getFieldValue, validateFields, setFields } = form;
  const dispatch = useDispatch();
  const [isRequestSuccess, setIsRequestSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const query = useSelector((state) => state.router?.location?.query);
  const isVerified = useSelector((state) => state.auth.isVerifiedResetPassword);
  const loadingVerifyResetPassword = useSelector(
    (state) => state.auth.loadingVerifyResetPassword,
  );

  useEffect(() => {
    query?.token &&
      dispatch(
        verifyResetPasswordToken({
          resetPasswordToken: query.token,
          email: query.email,
        }),
      );
  }, [dispatch, query.email, query.token]);

  if (loadingVerifyResetPassword) {
    return <Loading />;
  }
  if (!isVerified) return <ResetPasswordExpired />;

  const resetPassword = async (password, resetPasswordToken) => {
    setLoading(true);
    const response = await dispatch(
      resetPasswordAction({ password, resetPasswordToken }),
    );
    setLoading(false);
    if (response?.payload) setIsRequestSuccess(true);
  };

  const handleResetPassword = () => {
    validateFields().then((values) => {
      if (values) {
        const { confirmPassword, password } = values;
        if (confirmPassword === password) {
          resetPassword(password, query?.token);
        } else {
          setFields({
            confirmPassword: {
              value: confirmPassword,
              errors: [
                new Error(i18next.t('input.confirmPassword.validateMsg.match')),
              ],
            },
          });
        }
      }
    });
  };

  const handleValidatePassword = (rule, value) => {
    if (!value || getFieldValue('password') === value) {
      return Promise.resolve();
    }
    return Promise.reject(i18next.t('input.confirmPassword.validateMsg.match'));
  };

  if (isRequestSuccess) return <ResetPasswordSuccess />;

  return (
    <ResetPasswordStyleWrapper className="isoSignInPage">
      <img alt="" src={logo} className="logo" />
      <div className="title">
        <div className="maintitle">
          <span className="title-text">{i18next.t('login.resetPassword')}</span>
        </div>
      </div>

      <GuideContent email={query?.email} />
      <div className="isoSignInForm">
        <Form form={form} onFinish={handleResetPassword} layout="vertical">
          <FormItem
            name="password"
            label={i18next.t('login.password')}
            rules={[
              {
                required: true,
                message: i18next.t('input.password.validateMsg.required'),
              },
              {
                pattern: validateRegex.password,
                message: i18next.t('input.password.validateMsg.pattern'),
              },
            ]}
          >
            <Input.Password type="password" />
          </FormItem>
          <FormItem
            name="confirmPassword"
            dependencies={['password']}
            label={i18next.t('input.confirmPassword.placeholder')}
            rules={[
              {
                required: true,
                message: i18next.t(
                  'input.confirmPassword.validateMsg.required',
                ),
              },
              { validator: handleValidatePassword },
            ]}
          >
            <Input.Password type="password" />
          </FormItem>
          <div className="buttonWrapper">
            <Button type="primary" htmlType="submit" loading={loading}>
              {i18next.t('button.reset')}
            </Button>
          </div>
        </Form>
        <AuthContact
          className="mt-20"
          text="auth.contactText.l1ResetPassword"
        />
      </div>
    </ResetPasswordStyleWrapper>
  );
};

export default ResetPassword;

import { Button, Col, Form, Row, Space } from 'antd';
import i18next from 'i18next';
import RestSelect from 'components/RestInput/RestSelect';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import {
  getActivePrimaryChildCareServices,
  getNationalStates,
} from 'redux/companyBenchmarking/selectors';
import { LABEL_TABLE_KEY } from 'configs/localData/constant';
import { formatLabelTable } from 'containers/Centres/components/CentreTabs/ServicesTab/utils';
import { convertSearchUrl, getFilterFromUrl } from 'utils/tools';
import { resetResourceFilterCompanyBenchMarking } from 'redux/companyBenchmarking/slice';
import { isEmpty } from 'lodash-es';
import PropTypes from 'prop-types';
import { FilterStyles } from 'containers/CompanyBenchmarking/components/FilterForm/styles';
import {
  DEFAULT_ORDER_BY,
  MAX_LIMIT,
  QUERY_ALL_ID,
} from 'containers/CompanyBenchmarking/localData/constants';
import {
  getUserCompanyBenchmarkingChart,
  getCompanyBenchmarkingSummary,
  getCompanyBenchmarkingCompareRatingData,
} from 'redux/companyBenchmarking/actions';

const FilterForm = ({ fetchCompanyBenchmarkingData, ownedCompanies }) => {
  const [isClearBtnClicked, setIsClearBtnClicked] = useState(false);
  const appSettings = useSelector((state) => state.config?.data?.AppSetting);
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { push } = useHistory();
  const { search } = useLocation();
  const { filter, orderBy } = getFilterFromUrl(search);
  const primaryChildCareServices = useSelector(
    getActivePrimaryChildCareServices,
  );
  const filterRef = useRef(filter);
  const orderByRef = useRef(orderBy);
  const nationalStates = useSelector(getNationalStates);

  const ALL_NATIONAL_STATES = i18next.t(
    'companyBenchmarking.filterForm.allStates',
  );
  const nationalStatesDropdown =
    [ALL_NATIONAL_STATES, ...Object.values(nationalStates)] || [];
  const primaryChildCareServicesLabel = formatLabelTable(
    LABEL_TABLE_KEY.childCareService,
    appSettings,
  );
  const ALL_PRIMARY_CHILDCARE_SERVICES = i18next.t(
    'companyBenchmarking.filterForm.allPrimaryChildCareService',
  );
  const QUERY_ALL_PRIMARY_CHILDCARE_SERVICES_OPTION = {
    id: QUERY_ALL_ID,
    name: ALL_PRIMARY_CHILDCARE_SERVICES,
  };
  const primaryChildCareServicesDropdown =
    [
      QUERY_ALL_PRIMARY_CHILDCARE_SERVICES_OPTION,
      ...primaryChildCareServices,
    ] || [];

  const fetchUserCompanyBenchmarkingChart = useCallback(
    (value) => dispatch(getUserCompanyBenchmarkingChart(value)),
    [dispatch],
  );

  const getSummaryBenchmarking = useCallback(
    (newFormValues) => dispatch(getCompanyBenchmarkingSummary(newFormValues)),
    [dispatch],
  );

  const clearAllFormValues = () => {
    setIsClearBtnClicked(true);
    dispatch(resetResourceFilterCompanyBenchMarking());
    push({ search: '?' });
  };

  const fetchCompareRatingData = useCallback(
    ({ filter = {} }) => {
      dispatch(
        getCompanyBenchmarkingCompareRatingData({
          ...filter,
        }),
      );
    },
    [dispatch],
  );

  const onSubmitFilter = () => {
    form
      .validateFields()
      .then((values) => {
        let { primaryChildCareServiceId } = values;
        if (primaryChildCareServiceId === ALL_PRIMARY_CHILDCARE_SERVICES) {
          primaryChildCareServiceId = QUERY_ALL_ID;
        }
        const newFormValues = {
          ...values,
          primaryChildCareServiceId,
        };

        push({
          search: `?${convertSearchUrl({
            ...newFormValues,
            limit: MAX_LIMIT,
            offset: 0,
          })}`,
        });

        fetchCompanyBenchmarkingData({
          filter: {
            ...newFormValues,
            limit: MAX_LIMIT,
            offset: 0,
          },
          isRefresh: true,
        });
        fetchUserCompanyBenchmarkingChart(newFormValues);
        getSummaryBenchmarking(newFormValues);
        fetchCompareRatingData({
          filter: {
            ...newFormValues,
            companyId: values.companyId,
          },
        });
      })
      .catch(() => {});
  };

  useEffect(() => {
    let defaultFormValues = {};
    if (!isEmpty(filter)) {
      defaultFormValues = {
        companyId: filter.companyId,
        state: filter.state,
        primaryChildCareServiceId: filter.primaryChildCareServiceId,
      };
    } else if (isClearBtnClicked) {
      form.resetFields();
    } else if (ownedCompanies.length > 0) {
      defaultFormValues = {
        companyId: ownedCompanies[0].id,
        state: ALL_NATIONAL_STATES,
        primaryChildCareServiceId: ALL_PRIMARY_CHILDCARE_SERVICES,
      };
    }
    form.setFieldsValue(defaultFormValues);
  }, [
    ALL_PRIMARY_CHILDCARE_SERVICES,
    ALL_NATIONAL_STATES,
    filter,
    form,
    isClearBtnClicked,
    ownedCompanies,
  ]);

  useEffect(() => {
    if (!isEmpty(filterRef?.current)) {
      const filterValue = {
        companyId: filterRef?.current?.companyId,
        state: filterRef?.current?.state,
        primaryChildCareServiceId:
          filterRef?.current?.primaryChildCareServiceId,
      };
      fetchUserCompanyBenchmarkingChart(filterValue);
      getSummaryBenchmarking(filterValue);
      fetchCompanyBenchmarkingData({
        filter: {
          ...filterValue,
          limit: MAX_LIMIT,
          offset: 0,
          orderBy: orderByRef.current || DEFAULT_ORDER_BY,
        },
        isRefresh: true,
      });
      fetchCompareRatingData({
        filter: {
          ...filterValue,
          companyId: filterValue.companyId,
        },
      });
      push({
        search: `?${convertSearchUrl({
          ...filterValue,
          limit: MAX_LIMIT,
          offset: 0,
          orderBy: orderByRef.current,
        })}`,
      });
    }
  }, [
    fetchUserCompanyBenchmarkingChart,
    fetchCompanyBenchmarkingData,
    push,
    getSummaryBenchmarking,
    fetchCompareRatingData,
  ]);

  return (
    <FilterStyles>
      <Form form={form} autoComplete="off" layout="vertical">
        <Row gutter={32}>
          <Col xl={12} lg={12} md={24} xs={24}>
            <RestSelect
              required
              source="companyId"
              placeholder={i18next.t(
                'companyBenchmarking.filterForm.placeholder.selectCompany',
              )}
              header={i18next.t(
                'companyBenchmarking.filterForm.header.company',
              )}
              valueProp="id"
              titleProp="name"
              resourceData={ownedCompanies}
            />
          </Col>
          <Col xl={12} lg={12} md={24} xs={24}>
            <RestSelect
              required
              source="state"
              placeholder={i18next.t(
                'companyBenchmarking.filterForm.placeholder.selectState',
              )}
              header={i18next.t('companyBenchmarking.filterForm.header.state')}
              resourceData={nationalStatesDropdown}
            />
          </Col>
        </Row>
        <Row gutter={32}>
          <Col xl={12} lg={12} md={24} xs={24}>
            <RestSelect
              required
              source="primaryChildCareServiceId"
              placeholder={i18next.t(
                'companyBenchmarking.filterForm.placeholder.selectPrimaryChildcare',
              )}
              header={primaryChildCareServicesLabel}
              valueProp="id"
              titleProp="name"
              formatText={(data) => i18next.t(data)}
              resourceData={primaryChildCareServicesDropdown}
            />
          </Col>
        </Row>
        <Form.Item>
          <Space className="flex-center-end mt-39">
            <Button type="primary" htmlType="submit" onClick={onSubmitFilter}>
              {i18next.t('button.runCompanyBenchmarkingReport')}
            </Button>
            <Button className="clear-button" onClick={clearAllFormValues}>
              {i18next.t('button.clearFilter')}
            </Button>
          </Space>
        </Form.Item>
      </Form>
    </FilterStyles>
  );
};

FilterForm.propTypes = {
  fetchCompanyBenchmarkingData: PropTypes.func,
  ownedCompanies: PropTypes.array,
};

export default FilterForm;

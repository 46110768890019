import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Layout } from 'antd';

import Footer from 'containers/Footer';
import PrivateLayoutWrapper from './styles';
import Header from '../../containers/Header';
import SideBar from '../../containers/SideBar';
import Impersonation from './Impersonation';

const { Content } = Layout;

const PrivateLayout = ({ children, searchHeader }) => {
  const [collapsed, setCollapsed] = useState(
    localStorage.getItem('collapsed') === 'true',
  );
  const toggle = () => {
    localStorage.setItem('collapsed', !collapsed);
    setCollapsed(!collapsed);
  };

  return (
    <PrivateLayoutWrapper className="windowView">
      <input
        onChange={() => {}}
        id="collapsedTracker"
        type="checkbox"
        checked={!collapsed}
      />
      <label
        role="presentation"
        htmlFor="collapsedTracker"
        className="overlay"
        onClick={toggle}
      />
      <SideBar onToggle={toggle} collapsed={collapsed} />
      <Layout className="mainView">
        <Impersonation />
        <Header searchHeader={searchHeader} />
        <Content className="main-container">
          <div className="content">{children}</div>
          <Footer />
        </Content>
      </Layout>
    </PrivateLayoutWrapper>
  );
};

PrivateLayout.propTypes = {
  children: PropTypes.any,
  searchHeader: PropTypes.object,
};

export default PrivateLayout;

import React from 'react';
import { Col, InputNumber, Row, Typography } from 'antd';
import i18next from 'i18next';
import RestInputItem from 'components/RestInput/RestInputItem';
import RestFormDateTimePicker from 'components/RestInput/RestDateTimePicker';
import { LABEL_TABLE_KEY } from 'configs/localData/constant';
import { useSelector } from 'react-redux';
import CompanyBrandForm from './CompanyBrandForm';
import { formatLabelTable } from '../CentreTabs/ServicesTab/utils';

const CentreInfoForm = () => {
  const appSettings = useSelector((state) => state.config?.data?.AppSetting);

  return (
    <div>
      <Typography.Title level={5} className="centre-form__title-section">
        {i18next.t('centres.centreInformation')}
      </Typography.Title>
      <Row gutter={16}>
        <Col md={12} xs={24}>
          <RestInputItem
            required
            source="name"
            header="centres.name"
            placeholder="centres.name"
            isValidatorEmpty
          />
        </Col>
        <Col md={6} xs={24}>
          <RestInputItem
            header={formatLabelTable(
              LABEL_TABLE_KEY.childCareService,
              appSettings,
            )}
            source={['primaryChildCareService', 'name']}
            placeholder={formatLabelTable(
              LABEL_TABLE_KEY.childCareService,
              appSettings,
            )}
            disabled
          />
        </Col>
        <Col md={6} xs={24}>
          <RestInputItem
            header="centres.centreServiceType"
            source="type"
            placeholder="centres.centreServiceType"
            disabled
          />
        </Col>
      </Row>
      <Row gutter={16}>
        <Col md={6} xs={12}>
          <RestInputItem
            source="governmentCentreServiceId"
            header="centres.SENumber"
            placeholder="centres.SENumber"
            disabled
          />
        </Col>
        <Col md={6} xs={12}>
          <RestInputItem
            source="governmentReferenceId"
            header="centres.PRNumber"
            placeholder="centres.PRNumber"
            disabled
          />
        </Col>
        <Col md={12} xs={24}>
          <RestInputItem
            disabled
            source={['provider', 'name']}
            header="centres.providerName"
            placeholder="centres.providerName"
            isValidatorEmpty
          />
        </Col>
      </Row>
      <CompanyBrandForm />
      <Row gutter={16}>
        <Col md={12} xs={24}>
          <RestFormDateTimePicker
            source="dateApplied"
            header="centres.dateApplied"
            placeholder="centres.dateApplied"
            disabled
          />
        </Col>

        <Col md={12} xs={24}>
          <RestInputItem
            source="placeAllowed"
            header="centreDetail.approvedPlaces"
            placeholder="centreDetail.approvedPlaces"
            ContentComponent={InputNumber}
            disabled
          />
        </Col>
      </Row>
    </div>
  );
};

export default CentreInfoForm;

import React, { useState } from 'react';
import CardSection from 'components/common/CardSection';
import i18next from 'i18next';
import { Col, Row } from 'antd';
import useModifyAdsPermission from 'containers/Advertisement/hooks/useModifyAdsPermission';
import { isEmpty } from 'lodash-es';
import TargetedAudienceItem from '../items/TargetedAudienceItem';
import EmptyData from './EmptyData';
import TargetedAudienceModal from '../Modals/TargetedAudienceModal';

const TargetedAudienceInfo = ({ targetedAudience }) => {
  const { canModifyAds, canModifyAdStatus } = useModifyAdsPermission();
  const [showModalEdit, setShowModalEdit] = useState(false);

  return (
    <>
      <CardSection
        isEdit
        title="advertisement.targetedAudience"
        action="isEdit"
        disabled={!canModifyAds || !canModifyAdStatus}
        onClickButton={() => setShowModalEdit(true)}
      >
        {isEmpty(targetedAudience) ? (
          <EmptyData description="advertisement.targetedAudiencesEmpty" />
        ) : (
          <Row gutter={24} className="text-16">
            <Col className="text-black-base">{`${i18next.t(
              'advertisement.targetedAudience',
            )}:`}</Col>
            <Col>
              <TargetedAudienceItem
                userType={targetedAudience?.userType}
                userLocation={targetedAudience?.userLocation}
                userLocationValues={targetedAudience?.userLocationValue}
              />
            </Col>
          </Row>
        )}
        <TargetedAudienceModal
          visible={showModalEdit}
          onClose={() => setShowModalEdit(false)}
          initialValues={targetedAudience}
        />
      </CardSection>
    </>
  );
};

export default TargetedAudienceInfo;

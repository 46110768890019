import styled from 'styled-components';

export const CentreMetricsTableWrapper = styled.div`
  h1 {
    font-size: 24px;
  }
`;

export const CentreMetricsItem = styled.div`
  &:hover {
    span {
      color: ${({ theme }) => theme.palette.primary};
    }
  }
`;

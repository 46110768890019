import { createSelector } from 'reselect';
import { uniqBy, intersection } from 'lodash-es';

const getAllPermissions = (state) => state.roles.permissions;
const getCurrentRole = (state) => state.roles.currentData;

export const allPermissionsSelector = createSelector(
  [getAllPermissions],
  (allPermissions) => allPermissions,
);

export const controllersSelector = createSelector(
  [getAllPermissions],
  (permissions) =>
    uniqBy(permissions, 'controller')?.map((item) => item.controller),
);

export const initialPermissionsSelector = createSelector(
  [getAllPermissions, getCurrentRole],
  (allPermissions, currentRole) => {
    const currentPermissions = currentRole?.rolePermissions;
    const controllers = uniqBy(allPermissions, 'controller')?.map(
      (item) => item.controller,
    );
    if (currentPermissions && allPermissions) {
      return controllers.reduce((o, key) => {
        const checkedAllPermission = allPermissions
          ?.filter((item) => item.controller === key)
          ?.find((item) => item.policy === `${key}:*` || item.policy === '*');
        const isCheckedAll = currentPermissions?.find(
          (permission) => permission?.permissionId === checkedAllPermission?.id,
        );
        const allCurrentRowPermissions = allPermissions
          ?.filter((item) => item.controller === key)
          ?.filter((item) => item.policy !== `${item.controller}:*`);
        return {
          ...o,
          [key]: {
            checkedList: isCheckedAll
              ? allCurrentRowPermissions?.map((item) => item.id)
              : currentPermissions
                  ?.filter((permission) =>
                    allCurrentRowPermissions?.find(
                      (item) => item.id === permission?.permissionId,
                    ),
                  )
                  ?.map((item) => item.permissionId),
            indeterminate: currentPermissions?.find(
              (permission) =>
                checkedAllPermission?.id === permission?.permissionId,
            )
              ? false
              : !!intersection(
                  currentPermissions?.map((item) => item.permissionId),
                  allCurrentRowPermissions?.map((item) => item.id),
                ).length &&
                intersection(
                  currentPermissions?.map((item) => item.permissionId),
                  allCurrentRowPermissions?.map((item) => item.id),
                ).length <
                  allCurrentRowPermissions?.map((item) => item.id).length,
            checkedAll: !!isCheckedAll,
            checkedAllId: checkedAllPermission?.id,
          },
        };
      }, {});
    }
    return controllers.reduce((o, key) => {
      const checkedAllPermission = allPermissions
        ?.filter((item) => item.controller === key)
        ?.find((item) => item.policy === `${key}:*`);
      return {
        ...o,
        [key]: {
          checkedList: [],
          checkedAllId: checkedAllPermission?.id,
        },
      };
    }, {});
  },
);

export const permissionsByControllerSelector = createSelector(
  [getAllPermissions],
  (allPermissions) => {
    const controllers = uniqBy(allPermissions, 'controller')?.map(
      (item) => item.controller,
    );
    return controllers.reduce(
      (o, controller) => ({
        ...o,
        [controller]: allPermissions
          ?.filter(
            (permission) => permission.policy !== `${permission.controller}:*`,
          )
          ?.filter((permission) => permission.controller === controller),
      }),
      {},
    );
  },
);

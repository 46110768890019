import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { Col, Divider, Row } from 'antd';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  ReferenceLine,
} from 'recharts';
import { useLocation } from 'react-router';
import { getFilterFromUrl } from 'utils/tools';
import moment from 'moment';
import PropTypes from 'prop-types';
import { calculateChartWidthByDataLength, renderAverageLine } from './utils';
import LegendLabel from './LegendLabel';
import CustomTooltip from './CustomTooltip';
import { CompareRatingBarChartWrapper } from './styles';
import {
  ALL_COMPANIES_COLOR,
  AVG_POSITION_LINE,
  CHART_DATE_FORMAT,
  DEFAULT_BAR_SIZE,
  DEFAULT_COMPARISON_WIDTH_CHART,
  DEFAULT_HOVER_COLOR,
  DEFAULT_LEGEND_TYPE,
  DEFAULT_MARGIN_CHART,
  DEFAULT_RADIUS_STYLE,
  SELECTED_COMPANY_COLOR,
  TICK_COLOR,
} from './constants';

const CompareRatingBarChart = (
  {
    handleScroll,
    allLabel,
    data,
    chartTitle,
    chartDefaultTitle,
    allDataKey,
    userDataKey,
    objectCompareBarName,
  },
  ref,
) => {
  const barChartRef = useRef();
  const { search } = useLocation();
  const { filter } = getFilterFromUrl(search);
  const currentDate = moment().format(CHART_DATE_FORMAT);
  const [isEmptyCompany, setIsEmptyCompany] = useState(false);
  const [verticalRangeChart, setVerticalRangeChart] = useState([0, 0]);
  const [widthChart, setWidthChart] = useState(DEFAULT_COMPARISON_WIDTH_CHART);
  const [avgAllCompanyVerticalLinePoint, setAvgAllCompanyVerticalLinePoint] =
    useState({});
  const [avgUserCompanyVerticalLinePoint, setAvgUserCompanyVerticalLinePoint] =
    useState({});
  const selectedNationalState = filter?.state;

  const {
    charts: chartData,
    maxVerticalValue,
    userRatingAvg,
    allRatingAvg,
  } = data;

  useImperativeHandle(ref, () => ({
    handleImperativeScroll() {
      barChartRef.current.scrollIntoView({ behavior: 'smooth' });
    },
  }));

  useEffect(() => {
    const dataLength = chartData?.length;
    if (dataLength > 0) {
      const widthChart = calculateChartWidthByDataLength(dataLength);
      setVerticalRangeChart([0, Math.ceil(maxVerticalValue)]);
      setWidthChart(widthChart);

      userRatingAvg === 'NaN'
        ? setIsEmptyCompany(true)
        : setIsEmptyCompany(false);

      const avgAllVerticalPoint = renderAverageLine(chartData, allRatingAvg);

      const avgUserVerticalPoint = renderAverageLine(chartData, userRatingAvg);

      setAvgAllCompanyVerticalLinePoint(avgAllVerticalPoint);
      setAvgUserCompanyVerticalLinePoint(avgUserVerticalPoint);

      handleScroll();
    }
  }, [
    chartData,
    maxVerticalValue,
    selectedNationalState,
    userRatingAvg,
    allRatingAvg,
    handleScroll,
  ]);

  return (
    <CompareRatingBarChartWrapper ref={barChartRef}>
      <Row>
        <Col
          xl={24}
          lg={24}
          md={24}
          xs={24}
          justify="center"
          align="center"
          className="chart-header"
        >
          <span className="chart-title">
            {isEmptyCompany ? chartDefaultTitle : chartTitle}
          </span>
          <span className="current-date">{currentDate}</span>
        </Col>
      </Row>
      <Divider />
      <Row className="custom-row">
        <Col
          xl={24}
          lg={24}
          md={24}
          xs={24}
          justify="start"
          align="center"
          className="chart-body"
        >
          <ResponsiveContainer
            width={widthChart}
            minWidth={widthChart - 1}
            minHeight={360}
          >
            <BarChart
              data={chartData}
              margin={DEFAULT_MARGIN_CHART}
              barCategoryGap={12}
              barGap={4}
            >
              <Tooltip
                animationDuration={300}
                animationEasing="ease-out"
                cursor={{ fill: DEFAULT_HOVER_COLOR }}
                content={
                  <CustomTooltip
                    isEmptyCompany={isEmptyCompany}
                    allDataKey={allDataKey}
                    userDataKey={userDataKey}
                  />
                }
                wrapperStyle={{
                  top: -20,
                  left: -130,
                }}
              />
              <CartesianGrid vertical={false} stroke={DEFAULT_HOVER_COLOR} />
              <XAxis
                dataKey="rating"
                tickLine={false}
                axisLine={false}
                tick={{ fill: TICK_COLOR }}
                type="category"
                interval={0}
              />
              <YAxis
                domain={verticalRangeChart}
                tickLine={false}
                axisLine={false}
                tick={{ fill: TICK_COLOR }}
                tickFormatter={(item) => `${item.toFixed(2)}%`}
                width={70}
              />
              <Legend
                wrapperStyle={{ paddingTop: '15px' }}
                content={
                  <LegendLabel
                    isEmptyCompany={isEmptyCompany}
                    userRatingAvg={userRatingAvg}
                    allRatingAvg={allRatingAvg}
                    allDataKey={allDataKey}
                    userDataKey={userDataKey}
                  />
                }
              />
              <Bar
                barSize={DEFAULT_BAR_SIZE}
                name={allLabel}
                dataKey={allDataKey}
                fill={ALL_COMPANIES_COLOR}
                radius={DEFAULT_RADIUS_STYLE}
                legendType={DEFAULT_LEGEND_TYPE}
              />
              <Bar
                barSize={DEFAULT_BAR_SIZE}
                name={objectCompareBarName}
                dataKey={userDataKey}
                fill={SELECTED_COMPANY_COLOR}
                radius={DEFAULT_RADIUS_STYLE}
                legendType={isEmptyCompany ? 'none' : DEFAULT_LEGEND_TYPE}
              />
              <ReferenceLine
                x={avgAllCompanyVerticalLinePoint?.rating ?? ''}
                stroke={ALL_COMPANIES_COLOR}
                strokeDasharray="3 3"
                strokeWidth={2}
                position={
                  avgAllCompanyVerticalLinePoint?.position ||
                  AVG_POSITION_LINE.START
                }
              />
              <ReferenceLine
                x={avgUserCompanyVerticalLinePoint?.rating ?? ''}
                stroke={SELECTED_COMPANY_COLOR}
                strokeDasharray="3 3"
                strokeWidth={2}
                position={
                  avgUserCompanyVerticalLinePoint?.position ||
                  AVG_POSITION_LINE.START
                }
              />
            </BarChart>
          </ResponsiveContainer>
        </Col>
      </Row>
    </CompareRatingBarChartWrapper>
  );
};

CompareRatingBarChart.propTypes = {
  handleScroll: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.any }),
  ]),
  allLabel: PropTypes.string,
  data: PropTypes.object,
  chartTitle: PropTypes.string,
  chartDefaultTitle: PropTypes.string,
  allDataKey: PropTypes.string,
  userDataKey: PropTypes.string,
  objectCompareBarName: PropTypes.string,
};

export default forwardRef(CompareRatingBarChart);

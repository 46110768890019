import React, { useCallback, useEffect } from 'react';
import i18next from 'i18next';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { Tabs } from 'antd';
import { useCentre } from 'containers/Centres/Show/context';
import EducatorStaffProfileTab from 'containers/Centres/components/CentreTabs/EducatorStaffProfilesTab';
import ServicesTab from 'containers/Centres/components/CentreTabs/ServicesTab';
import { RootState } from 'redux/store';
import { CentreModel } from 'types/centre';
import FeaturesTab from './FeaturesTab';
import SummaryTab from './SummaryTab';
import CentreInformationTab from './CentreInformationTab';
import HoursTab from './HoursTab';
import RatingsReviewsTab from './RatingsReviewsTab';
import CentreTabsStyles from './styles';
import MarketingTab from './MarketingTab';

const { TabPane } = Tabs;

const LISTING_STRENGTH_MAX = 100;

const CentreTabs = () => {
  const { push } = useHistory();

  const { id, modelDetail } = useParams<{ id: string; modelDetail?: string }>();

  const currentCentre = useSelector(
    (state: RootState) => state.centres.currentData,
  ) as CentreModel;

  const { totalListingStrength, isFinishedStep } = currentCentre;

  const { setCollapsed } = useCentre();

  useEffect(() => {
    if (totalListingStrength === LISTING_STRENGTH_MAX && isFinishedStep) {
      setCollapsed(false);
    } else setCollapsed(true);
  }, [totalListingStrength, isFinishedStep, setCollapsed]);

  const TABS = [
    {
      key: 'summary',
      title: 'centreDetail.tabs.summary',
      TabComponent: SummaryTab,
    },
    {
      key: 'centreInformation',
      title: 'centreDetail.tabs.centreInformation',
      TabComponent: CentreInformationTab,
    },
    {
      key: 'hours',
      title: 'centreDetail.tabs.hours',
      TabComponent: HoursTab,
    },
    {
      key: 'services',
      title: 'centreDetail.tabs.services',
      TabComponent: ServicesTab,
    },
    {
      key: 'features',
      title: 'centreDetail.tabs.centreFeaturesAndInclusions',
      TabComponent: FeaturesTab,
    },
    {
      key: 'educator-staff-profiles',
      title: 'centreDetail.tabs.educatorStaffProfile',
      TabComponent: EducatorStaffProfileTab,
    },
    {
      key: 'nqsRating',
      title: 'centreDetail.tabs.ratingsReviews',
      TabComponent: RatingsReviewsTab,
    },
    {
      key: 'marketing',
      title: 'centreDetail.tabs.marketing.title',
      TabComponent: MarketingTab,
    },
  ];

  const onChange = useCallback(
    (key) => {
      push(`/centres/${id}/show/${key}`);
    },
    [push, id],
  );

  return (
    <CentreTabsStyles>
      <Tabs activeKey={modelDetail || 'summary'} onChange={onChange}>
        {TABS.map(({ title, key, TabComponent, ...tab }) => (
          <TabPane tab={i18next.t(title)} key={key}>
            <TabComponent {...tab} record={currentCentre} />
          </TabPane>
        ))}
      </Tabs>
    </CentreTabsStyles>
  );
};

export default CentreTabs;

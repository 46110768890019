export const LEAD_ACTIVITIES_TAG = 'leadActivities';

export const MARKETING_METRICS_TAG = 'marketingMetrics';

export const EDUCATOR_STAFF_PROFILE_TAG = 'educatorStaffProfile';

export const ADVERTISEMENT_TAG = 'advertisement';

export const PROGRAMS_LEARNING_TAG = 'programsAndLearning';

export const CENTRE_FEATURES_TAG = 'centreFeatureTag';

export const CENTRE_SERVICE_TAG = 'centreServiceTag';

export default [
  LEAD_ACTIVITIES_TAG,
  MARKETING_METRICS_TAG,
  ADVERTISEMENT_TAG,
  PROGRAMS_LEARNING_TAG,
  EDUCATOR_STAFF_PROFILE_TAG,
  CENTRE_FEATURES_TAG,
  CENTRE_SERVICE_TAG,
];

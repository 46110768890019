import styled from 'styled-components';

export const TitleSorterWrapper = styled.div`
  display: inline-flex;
  align-items: center;

  .icon-sorter {
    margin-left: 6px;
    color: ${({ theme }) => theme.text.mainL3};
    pointer-events: none; // fix issue tooltip still display when touched sorter icon
  }
  .asc-sorter-icon,
  .desc-sorter-icon {
    color: ${({ theme }) => theme.palette.primary};
  }
  .asc-sorter-icon {
    transform: scaleY(-1);
  }
`;

import React from 'react';
import { Row, Col } from 'antd';
import { isEmpty } from 'lodash-es';
import PieChartCustom from 'components/charts/PieChartCustom';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import i18next from 'i18next';
import { getCompanyBenchmarkingNSQRating } from 'redux/companyBenchmarking/selectors';
import RatingTypesSection from './RatingTypesSection';
import CardCustom from '../CardCustom';
import { PieChartRatingStyles } from './styles';

const NQSRatingPieChart = ({ userCompanySelectedName }) => {
  const resultChart = useSelector(getCompanyBenchmarkingNSQRating);
  const resultNQSRating = resultChart.map((item) => ({
    name: item?.name,
    percent: Number(item?.value),
    color: item?.color,
    image: item?.image,
  }));

  return (
    !isEmpty(resultChart) && (
      <CardCustom
        title={`${i18next.t('companyBenchmarking.ratingTypes.nqsRating')} - ${
          userCompanySelectedName ?? ''
        }`}
      >
        <PieChartRatingStyles>
          <Row align="middle" className="nqs-rating-style">
            <Col xxl={10} xl={24} sm={11} xs={24}>
              <PieChartCustom
                data={resultNQSRating}
                innerRadius={0}
                paddingAngle={0}
                isShowLabel={false}
                isShowLegend={false}
                isCircleLegend={false}
                cy="50%"
                cx="50%"
                height={200}
              />
            </Col>
            <Col
              xxl={14}
              xl={24}
              sm={13}
              xs={24}
              className="flex-column-center p-0"
            >
              <RatingTypesSection data={resultNQSRating} />
            </Col>
          </Row>
        </PieChartRatingStyles>
      </CardCustom>
    )
  );
};

NQSRatingPieChart.propTypes = {
  userCompanySelectedName: PropTypes.string,
};

export default NQSRatingPieChart;

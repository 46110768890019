import { Steps } from 'antd';
import styled from 'styled-components';
import { ReactNode } from 'react';

interface StepsProps {
  children: ReactNode;
}

export const VerticalSteps = styled(Steps)<StepsProps>`
  .ant-steps-vertical {
    padding: 100px 0 0 75px;
  }
  .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon {
    background: ${({ theme }) => theme.palette.primary};
    border-color: ${({ theme }) => theme.palette.primary};
  }
  .ant-steps-item-finish .ant-steps-item-icon {
    border-color: ${({ theme }) => theme.palette.primary};
  }
  .ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
    color: ${({ theme }) => theme.palette.primary};
  }
  .ant-steps-item-wait .ant-steps-item-icon {
    background: #feb2e0;
    border: none;
  }
  .ant-steps-item-wait .ant-steps-item-icon > .ant-steps-icon {
    color: white;
  }
  .ant-steps-item-tail {
    background: ${({ theme }) => theme.palette.primary};
    margin-top: 35px;
    padding-bottom: 0 !important;
  }
  .ant-steps-item-wait .ant-steps-item-tail {
    background: #feb2e0;
  }
  .ant-steps-item-process
    > .ant-steps-item-container
    > .ant-steps-item-content
    > .ant-steps-item-title {
    color: ${({ theme }) => theme.text.darkGreen};
    font-weight: bold;
  }

  .ant-steps-item-wait,
  .ant-steps-item-finish
    > .ant-steps-item-container
    > .ant-steps-item-content
    > .ant-steps-item-title {
    color: ${({ theme }) => theme.text.grayNeutral500};
    font-weight: 600;
  }

  .ant-steps-vertical > .ant-steps-item-container > .ant-steps-item-tail {
    background: ${({ theme }) => theme.palette.primary};
  }
  .ant-steps-vertical
    > .ant-steps-item
    > .ant-steps-item-container
    > .ant-steps-item-tail {
    height: 0;
  }

  @media only screen and (min-width: 1300px) {
    margin-left: 50px;
  }
`;

export const StepTitle = styled.h1`
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  color: ${({ theme }) => theme.text.blackNeutral800};
`;

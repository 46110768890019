import React, { useEffect } from 'react';
import { CurrentStepTitle } from 'containers/Centres/components/CentreTabs/EducatorStaffProfilesTab/Create/Steps/styles';
import i18next from 'i18next';
import Footer from 'containers/Centres/components/CentreTabs/EducatorStaffProfilesTab/Create/Footer';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { FormInstance, Typography } from 'antd';
import {
  goToNextStep,
  resetStaffProfilePayload,
  setStaffProfilePayload,
} from 'redux/staffProfile/slice';
import { getPayloadStaffProfile } from 'redux/staffProfile/selector';
import { StaffProfileModel } from 'types/staffProfiles';
import { isNil, omitBy } from 'lodash-es';
import BasicInformationForm from './BasicInformationForm';

const { Text } = Typography;

interface BasicInformationProps {
  form: FormInstance;
}

const BasicInformation = ({ form }: BasicInformationProps) => {
  const { goBack } = useHistory();
  const dispatch = useDispatch();
  const staffProfileFormValues: StaffProfileModel = useSelector(
    getPayloadStaffProfile,
  );
  const basicInfoValues = staffProfileFormValues?.basicInfo;

  useEffect(() => {
    if (basicInfoValues) {
      form.setFieldsValue({
        ...basicInfoValues,
        firstName: basicInfoValues?.firstName?.trim(),
        lastName: basicInfoValues?.lastName?.trim(),
      });
    }
  }, [basicInfoValues, form]);

  const onBack = () => {
    form.resetFields();
    dispatch(resetStaffProfilePayload());
    goBack();
  };

  const onNext = () => {
    form.validateFields().then((formValues) => {
      dispatch(
        setStaffProfilePayload({
          basicInfo: omitBy(
            {
              ...formValues,
              userId: basicInfoValues?.userId ?? null,
            },
            isNil,
          ),
        }),
      );
      dispatch(goToNextStep());
    });
  };

  return (
    <>
      <CurrentStepTitle>
        {i18next.t('educatorStaffProfile.basicInformationForm.title')}
        <Text className="text-red">*</Text>
      </CurrentStepTitle>
      <BasicInformationForm form={form} />
      <Footer onBack={onBack} onNext={onNext} />
    </>
  );
};

export default BasicInformation;

import React from 'react';
import I18n from 'i18next';
import { Tooltip, Button } from 'antd';
import { DuplicateIcon } from 'components/common/SVGIcon';

interface Props {
  handleDuplicate: () => void;
  disabled: boolean;
}

const DuplicateButtonWrapper = ({ handleDuplicate, disabled }: Props) => (
  <Tooltip title={disabled ? '' : I18n.t('button.duplicate')}>
    <div className="delete-action-wrapper">
      <Button
        disabled={disabled}
        icon={<DuplicateIcon />}
        onClick={handleDuplicate}
      />
    </div>
  </Tooltip>
);

export default DuplicateButtonWrapper;

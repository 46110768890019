import React, { useState } from 'react';
import i18next from 'i18next';
import { Switch } from 'antd';
import { useEditStaffProfilesMutation } from 'redux/@rtkQuery/educatorStaffProfile';
import { StaffProfileModel } from 'types/staffProfiles';

interface Props {
  staffProfile: StaffProfileModel;
  className?: string;
  disabled?: boolean;
}

const ShowProfileButton = ({ staffProfile, className, disabled }: Props) => {
  const [isChecked, setIsChecked] = useState(staffProfile.isShowThisProfile);
  const [editProfile, { isLoading }] = useEditStaffProfilesMutation();

  const handleToggle = (
    checked: boolean,
    e: MouseEvent | React.MouseEvent<HTMLButtonElement>,
  ) => {
    e.stopPropagation();
    editProfile({ ...staffProfile, isShowThisProfile: checked }).then(() => {
      setIsChecked(checked);
    });
  };

  return (
    <div className={className ?? 'flex-center-start fex-row gap-8 mt-8'}>
      <div className="text-14">
        {i18next.t('educatorStaffProfile.showThisProfile')}
      </div>
      <Switch
        disabled={disabled}
        loading={isLoading}
        checked={isChecked}
        onChange={handleToggle}
        size="small"
      />
    </div>
  );
};

export default ShowProfileButton;

import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { Droppable } from 'react-beautiful-dnd';
import PropTypes from 'prop-types';
import InnerList from './InnerList';
import { Wrapper, ScrollContainer } from './styles';

const ApplicationList = (props) => {
  const {
    ignoreContainerClipping,
    internalScroll,
    scrollContainerStyle,
    isDropDisabled,
    isCombineEnabled,
    listId,
    listType,
    style,
    applications,
    title,
    colors,
    isDragDisabledItem,
    count,
  } = props;

  const [dataColumn, setDataColumn] = useState([]);

  const initialPaging = { page: 1, pageSize: 10 };

  const [paging, setPaging] = useState(initialPaging);

  const setDataColumnPaging = useCallback(
    (paging) => {
      const dataArr = [...applications];
      setDataColumn(dataArr.slice(0, paging.page * paging.pageSize));
    },
    [applications],
  );

  useEffect(() => {
    setPaging(initialPaging);
    setDataColumnPaging(initialPaging);
  }, [applications]); // eslint-disable-line

  useEffect(() => {
    if (paging.page !== initialPaging.page && count > dataColumn?.length) {
      setDataColumnPaging(paging);
    }
  }, [paging]); // eslint-disable-line

  const isShowWaypoint = useMemo(
    () => dataColumn.length > 0 && count > dataColumn.length,
    [count, dataColumn.length],
  );

  const handleLoadMore = useCallback(() => {
    setPaging({ ...paging, page: paging.page + 1 });
  }, [paging]);

  return (
    <Droppable
      droppableId={listId}
      title={title}
      type={listType}
      ignoreContainerClipping={ignoreContainerClipping}
      isDropDisabled={isDropDisabled}
      isCombineEnabled={isCombineEnabled}
      // renderClone={useClone ? renderClone : null}
    >
      {(dropProvided, dropSnapshot) => (
        <Wrapper
          style={style}
          isDraggingOver={dropSnapshot.isDraggingOver}
          isDropDisabled={isDropDisabled}
          isDraggingFrom={Boolean(dropSnapshot.draggingFromThisWith)}
          {...dropProvided.droppableProps}
        >
          {internalScroll ? (
            <ScrollContainer style={scrollContainerStyle}>
              <InnerList
                applications={dataColumn}
                title={title}
                dropProvided={dropProvided}
                colors={colors}
                isDragDisabledItem={isDragDisabledItem}
                handleLoadMore={handleLoadMore}
                isShowWaypoint={isShowWaypoint}
              />
            </ScrollContainer>
          ) : (
            <InnerList
              applications={dataColumn}
              title={title}
              dropProvided={dropProvided}
              colors={colors}
              isDragDisabledItem={isDragDisabledItem}
              handleLoadMore={handleLoadMore}
              isShowWaypoint={isShowWaypoint}
            />
          )}
        </Wrapper>
      )}
    </Droppable>
  );
};

ApplicationList.propTypes = {
  ignoreContainerClipping: PropTypes.bool,
  internalScroll: PropTypes.bool,
  scrollContainerStyle: PropTypes.object,
  isDropDisabled: PropTypes.bool,
  isCombineEnabled: PropTypes.bool,
  listId: PropTypes.string,
  listType: PropTypes.string,
  style: PropTypes.object,
  applications: PropTypes.array,
  title: PropTypes.string,
  useClone: PropTypes.bool,
  colors: PropTypes.object,
  isDragDisabledItem: PropTypes.bool,
  count: PropTypes.number,
};

ApplicationList.defaultProps = {
  listId: 'LIST',
  applications: [],
};

export default ApplicationList;

import React from 'react';
import PropTypes from 'prop-types';
import i18next from 'i18next';
import { formatMoney } from 'utils/tools';
import { InformationWrapper } from './styles';

const SubsidyInfo = ({ record }) => (
  <InformationWrapper>
    <div className="title-info">{i18next.t('applications.subsidy.title')}</div>
    <div className="content-info">
      <div className="info-item">
        <span className="title-info-item">
          {i18next.t('applications.subsidy.household')}
        </span>
        <span className="value-info-item">
          {record?.estimateHouseholdIncome
            ? `${i18next.t('currencyUnit.sign')}${formatMoney(
                record?.estimateHouseholdIncome,
                3,
                true,
              )} ${i18next.t('text.perAnnum')}`
            : i18next.t('error.waitingUpdate')}
        </span>
      </div>

      <div className="info-item">
        <span className="title-info-item">
          {i18next.t('applications.subsidy.hours')}
        </span>
        <span className="value-info-item">
          {record?.activityHours || i18next.t('error.waitingUpdate')}
        </span>
      </div>

      <div className="info-item">
        <span className="title-info-item">
          {i18next.t('applications.subsidy.amount')}
        </span>
        <span className="value-info-item">
          {record?.annualSubsidyAmount
            ? `${i18next.t('currencyUnit.sign')}${formatMoney(
                record?.annualSubsidyAmount,
                3,
                true,
              )} ${i18next.t('text.perAnnum')}`
            : i18next.t('error.waitingUpdate')}
        </span>
      </div>

      <div className="info-item">
        <span className="title-info-item">
          {i18next.t('applications.subsidy.percentage')}
        </span>
        <span className="value-info-item">
          {record?.subsidyPercentage || i18next.t('error.waitingUpdate')}
        </span>
      </div>
    </div>
  </InformationWrapper>
);

SubsidyInfo.propTypes = {
  record: PropTypes.object,
};

export default SubsidyInfo;

import TableCustom from 'components/common/TableCustom';
import i18next from 'i18next';
import React from 'react';
import PropTypes from 'prop-types';

import { useSelector } from 'react-redux';
import { formatKindiCareFee } from 'utils/formatFieldUtils';
import EnableFeaturedAction from './EnableFeaturedAction';
import { MarketingTableWrapper } from './styles';

const MarketingTable = ({ record }) => {
  const marketingIds = useSelector(
    (state) => state.centres.currentData?.marketingIds,
  );

  const columns = [
    {
      title: i18next.t('marketing.campaignType'),
      dataIndex: 'name',
    },
    {
      title: i18next.t('common.KindiCareFee'),
      dataIndex: 'amount',
      align: 'center',
      render: formatKindiCareFee,
    },
    {
      title: i18next.t('common.enabled'),
      dataIndex: 'id',
      align: 'right',
      render: (data) => (
        <EnableFeaturedAction
          record={record}
          marketingId={data}
          marketingIds={marketingIds}
        />
      ),
    },
  ];

  const data = useSelector((state) => state.config.data?.marketings);

  return (
    <MarketingTableWrapper>
      <div className="title">
        {i18next.t('centreDetail.tabs.marketing.title')}
      </div>
      <TableCustom
        xScroll={500}
        data={data}
        columns={columns}
        pagination={{ hideOnSinglePage: true }}
      />
    </MarketingTableWrapper>
  );
};
MarketingTable.propTypes = {
  record: PropTypes.object,
};
export default MarketingTable;

import React, { useRef } from 'react';
import { Button } from 'antd';
import i18next from 'i18next';
import PropTypes from 'prop-types';
import {
  APPLICATION_STATUS_CONSTANT,
  JOB_APPLICATION_STATUS,
} from 'configs/localData';
import DeclineReasonModal from 'containers/JobApplications/components/JobApplicationInfo/DropdownStatusApplication/DeclineModal';

const DeclineButton = ({ handleUpdateStatus, disabled }) => {
  const declineReasonModalRef = useRef();
  const handleSubmit = () => {
    declineReasonModalRef.current && declineReasonModalRef.current.open();
  };

  return (
    <>
      <Button onClick={handleSubmit} disabled={disabled}>
        {i18next.t('button.declineApplication')}
      </Button>
      <DeclineReasonModal
        ref={declineReasonModalRef}
        updateStatus={(reason) => {
          const statusId = JOB_APPLICATION_STATUS.find(
            (item) => item.value === APPLICATION_STATUS_CONSTANT.declined,
          ).indexOrder?.toString();
          handleUpdateStatus(statusId, reason?.declinedReasonId);
        }}
      />
    </>
  );
};

DeclineButton.propTypes = {
  handleUpdateStatus: PropTypes.func,
  disabled: PropTypes.bool,
};

DeclineButton.defaultProps = {
  handleUpdateStatus: () => null,
};

export default DeclineButton;

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'antd';
import { useDispatch } from 'react-redux';
import { changeFinishedStep } from 'redux/centres/actions';
import CentreInfo from '../CentreInfo';
import CentreTabs from '../CentreTabs';
import CentreDetailStyles from './styles';
import ListingStrengthCard from './ListingStrengthCard';
import { useCentre } from '../../Show/context';
import SuccessListingStrengthModal from './SuccessListingStrengthModal';

const CentreDetail = ({ centreId, record }) => {
  const { collapsed } = useCentre();

  const dispatch = useDispatch();

  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(
    () => {
      if (record?.isFinishedStep) {
        setVisible(true);
      }
    },
    [record?.isFinishedStep], // eslint-disable-line
  );

  const onCancel = () => {
    setVisible(false);
  };

  const onCompleted = () => {
    setLoading(true);
    dispatch(
      changeFinishedStep({
        centreId,
        data: { isFinishedStep: false },
      }),
    )
      .then(({ payload }) => {
        if (payload?.data?.id) {
          onCancel();
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <CentreDetailStyles>
      <Row gutter={24} className="row-customer-detail">
        <Col xxl={6} xl={6} lg={8} md={24} xs={24}>
          <CentreInfo record={record} />
        </Col>

        <Col xxl={18} xl={18} lg={16} md={24} xs={24}>
          <div
            className={`listing-strength__contact-col ${
              !collapsed ? 'listing-strength__contact-collapsed' : ''
            }`}
          >
            <ListingStrengthCard className="steps-content" record={record} />
          </div>
          <CentreTabs centreId={centreId} record={record} />
        </Col>
      </Row>
      {record?.isFinishedStep && (
        <SuccessListingStrengthModal
          visible={visible}
          onCancel={onCancel}
          onCompleted={onCompleted}
          loading={loading}
        />
      )}
    </CentreDetailStyles>
  );
};
CentreDetail.propTypes = {
  record: PropTypes.object,
  centreId: PropTypes.string,
};

export default CentreDetail;

import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  getBrandBenchmarkingCompareRatingApi,
  getUserBrandBenchmarkingChartApi,
  getBrandBenchmarkingSummaryApi,
} from 'api/insights';
import { makeActions } from 'redux/crudCreator';
import { apiWrapper } from 'utils/reduxUtils';

export const MODEL_NAME = 'brandBenchmarking';
export const brandBenchmarkingActions = makeActions(MODEL_NAME);
export const getAllBrandBenchmarking = brandBenchmarkingActions.getAll;

export const getUserBrandBenchmarkingChart = createAsyncThunk(
  `${MODEL_NAME}/user-brand-charts`,
  async (payload, thunkAPI) => {
    try {
      const response = await apiWrapper(
        {},
        getUserBrandBenchmarkingChartApi,
        payload,
      );
      return { data: response };
    } catch (error) {
      return thunkAPI.rejectWithValue({ data: error });
    }
  },
);

export const getBrandBenchmarkingSummary = createAsyncThunk(
  `${MODEL_NAME}/getSummary`,
  async (payload, thunkAPI) => {
    try {
      const response = await apiWrapper(
        {},
        getBrandBenchmarkingSummaryApi,
        payload,
      );
      return { data: response };
    } catch (error) {
      return thunkAPI.rejectWithValue({ data: error });
    }
  },
);

export const getBrandBenchmarkingCompareRating = createAsyncThunk(
  `${MODEL_NAME}/compareRating`,
  async (payload, thunkAPI) => {
    try {
      const response = await apiWrapper(
        {},
        getBrandBenchmarkingCompareRatingApi,
        payload,
      );
      return { data: response };
    } catch (error) {
      return thunkAPI.rejectWithValue({ data: error });
    }
  },
);

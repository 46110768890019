import styled from 'styled-components';

export const LoginStyles = styled.div`
  .login-title {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    width: 100%;
  }

  .ant-form-item {
    margin-bottom: 15px !important;
  }
  .div-forgot-password {
    text-align: right;
    margin-bottom: 25px;
  }
  .maintitle {
    svg {
      position: absolute;
      position: absolute;
      left: 0;
    }
  }
  .title {
    display: flex;
    justify-content: space-between;
    width: inherit;
  }
`;

export const ClaimButtonStyles = styled.div`
  font-size: 16px;
`;

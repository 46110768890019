import React from 'react';
import DigitalTiles from 'containers/DigitalTiles';
import { useHistory } from 'react-router';
import PageTitle from 'components/common/PageTitle';
import i18next from 'i18next';
import { Tabs } from 'antd';
import ExportLibrary from 'containers/ExportLibrary';

const { TabPane } = Tabs;

const TABS = [
  {
    key: 'digital-tiles',
    text: 'sideBar.digitalTiles',
    url: '/digital-tiles',
    component: DigitalTiles,
  },
  {
    key: 'export-library',
    text: 'sideBar.exportLibrary',
    url: '/export-library',
    component: ExportLibrary,
  },
];

const Resources = ({ match, ...props }: any) => {
  const history = useHistory();

  const onChange = (key) => {
    history.push(`/${key}`);
  };
  return (
    <div>
      <PageTitle>{i18next.t('sideBar.resources')}</PageTitle>
      <Tabs
        className="text-gray-500"
        activeKey={match?.path.substring(1)}
        onChange={onChange}
      >
        {TABS.map((tab) => (
          <TabPane tab={i18next.t(tab.text)} key={tab.key}>
            <tab.component
              rootPath="/"
              noCardWrapper
              layoutButtonCreate="non-inline"
              {...props}
            />
          </TabPane>
        ))}
      </Tabs>
    </div>
  );
};

export default Resources;

import storage from 'redux-persist/lib/storage';

export const authPersistConfig = {
  key: 'auth',
  storage,
  whitelist: ['centreId', 'centreLevelCRMPackageName', 'centreIds'],
};

export const jobTemplatesPersistConfig = {
  key: 'jobTemplates',
  storage,
  whitelist: ['jobTemplatePayload', 'currentJobTemplateStep'],
};

export const jobListingPersistConfig = {
  key: 'jobListings',
  storage,
  whitelist: ['jobListingPayload', 'currentStep', 'selectedWorkplaces'],
};

export const advertisementPersistConfig = {
  key: 'advertisement',
  storage,
  whitelist: ['createPayload', 'currentStep', 'adType'],
};

export const staffProfileConfig = {
  key: 'staffProfile',
  storage,
  whitelist: ['staffProfilePayload', 'currentStep'],
};

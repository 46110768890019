import styled from 'styled-components';

export const BasicInformationGeneralFormStyles = styled.div`
  .column-items {
    column-count: 1;
    column-gap: 24px;
  }

  @media only screen and (min-width: 700px) {
    .column-items {
      column-count: 2;
      column-gap: 24px;
    }
  }

  .ant-input-number-input-wrap {
    width: 72px !important;
  }

  .ant-form-item-label > label {
    color: ${({ theme }) => theme.text.blackNeutral800} !important;
  }
`;

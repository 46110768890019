import React, { useState } from 'react';
import i18next from 'i18next';
import { Button } from 'antd';
import PropTypes from 'prop-types';
import { EditIcon } from 'components/common/SVGIcon';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { editActivity } from 'redux/applications/actions';
import { HeaderSidebarWrapper } from './styles';
import ActivityModal from '../Modal';

const HeaderActivityDetail = ({ record }) => {
  const dispatch = useDispatch();
  const [initialValue, setInitialValue] = useState();
  const [visible, setVisibleModal] = useState(false);

  const toggleModal = () => {
    setVisibleModal(!visible);
  };

  const onOk = (value) =>
    dispatch(editActivity({ id: record.id, data: value }));

  React.useEffect(() => {
    record && setInitialValue({ ...record, date: moment(record.date) });
  }, [record]);

  return (
    <>
      <HeaderSidebarWrapper>
        <span className="title-card">{i18next.t('activity.detail')}</span>
        <Button
          type="primary"
          shape="circle"
          disabled={!record.id}
          icon=<EditIcon />
          onClick={toggleModal}
        />
      </HeaderSidebarWrapper>
      <ActivityModal
        onOk={onOk}
        initialValue={initialValue}
        visible={visible}
        onCancel={toggleModal}
        toggleModal={toggleModal}
      />
    </>
  );
};

HeaderActivityDetail.propTypes = {
  record: PropTypes.object,
};

export default HeaderActivityDetail;

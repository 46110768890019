import React from 'react';
import PropTypes from 'prop-types';

import FormFooter from './FormFooter';
import { MessageFooterStyles } from './styles';
import { FilesMessageProvider } from '../UploadFileMessage/context';

const Footer = ({
  isUploadFile,
  id,
  isRefreshDocument,
  applicationId,
  centreResponded,
}) => (
  <FilesMessageProvider>
    <MessageFooterStyles>
      <FormFooter
        isUploadFile={isUploadFile}
        id={id}
        applicationId={applicationId}
        isRefreshDocument={isRefreshDocument}
        centreResponded={centreResponded}
      />
    </MessageFooterStyles>
  </FilesMessageProvider>
);

Footer.propTypes = {
  isUploadFile: PropTypes.bool,
  id: PropTypes.string,
  isRefreshDocument: PropTypes.bool,
  applicationId: PropTypes.string,
  centreResponded: PropTypes.bool,
};

export default Footer;

import PageTitle from 'components/common/PageTitle';
import i18next from 'i18next';
import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Checkbox, Form, Modal } from 'antd';
import { RestInputContext } from 'components/RestInput/RestInputContext';
import { ExclamationCircleFilled } from '@ant-design/icons';
import { flatten, isEmpty } from 'lodash-es';
import { DeleteIcon } from 'components/common/SVGIcon';
import { LIMIT_WORKPLACES_DEFAULT } from 'containers/JobListings/constants';
import { useDispatch, useSelector } from 'react-redux';
import { getWorkplaces } from 'redux/jobs/jobListings/actions';
import { setJobListingPayload } from 'redux/jobs/jobListings/slice';
import { getWorkplacesSelector } from 'redux/jobs/jobListings/selectors';
import moment from 'moment';
import { useHistory } from 'react-router';
import qs from 'qs';
import { ApplicationDeadlineStepStyles, ExceptionListItem } from './styles';
import DeadlineDurationForm from './DeadlineDurationForm';
import SelectWorkplace from './SelectWorkplace';

const ApplicationDeadlineStep = ({ form, initialValues = {} }) => {
  const [selectedWorkplaces, setSelectedWorkplaces] = useState([]);
  const [hasException, setHasException] = useState(initialValues?.hasException);

  const { location } = useHistory();

  const { filter } = qs.parse(location.search, {
    ignoreQueryPrefix: true,
  });

  const { selectedOrganizations = [], isSelectAll = false } = initialValues;

  const dispatch = useDispatch();

  const {
    data: allWorkplacesData,
    resourceParams,
    loading,
  } = useSelector(getWorkplacesSelector);

  const retrieveList = useCallback(
    ({ data, isRefresh } = {}) =>
      dispatch(
        getWorkplaces({
          data: {
            page: 1,
            size: LIMIT_WORKPLACES_DEFAULT,
            ...data,
          },
          options: {
            isRefresh,
          },
        }),
      ),
    [dispatch],
  );

  const onDropdownVisibleChange = () => {
    retrieveList({
      data: {
        filter: {
          ...(isSelectAll && !isEmpty(filter) ? filter : null),
          inIds: isSelectAll ? [] : selectedOrganizations,
        },
      },
      isRefresh: true,
    });
  };

  const onSearchExcludeOption = (searchTerm) => {
    retrieveList({
      data: {
        filter: {
          ...(isSelectAll && !isEmpty(filter) ? filter : null),
          inIds: isSelectAll ? [] : selectedOrganizations,
          centre: searchTerm,
        },
      },
      isRefresh: true,
    });
  };

  const onEnterWaypoint = () => {
    if (isSelectAll && allWorkplacesData?.length < resourceParams?.total) {
      retrieveList({
        data: {
          page: +resourceParams.page + 1,
          size: resourceParams.size,
          filter: !isEmpty(filter) ? filter : null,
        },
        isRefresh: false,
      });
    } else if (allWorkplacesData?.length < selectedOrganizations?.length) {
      retrieveList({
        data: {
          page: +resourceParams.page + 1,
          size: resourceParams.size,
          filter: {
            ...(isSelectAll && !isEmpty(filter) ? filter : null),
            inIds: selectedOrganizations,
          },
        },
        isRefresh: false,
      });
    }
  };

  const onFinish = ({ endDateDefault, endDates }) => {
    const formattedData = {
      hasException,
      endDateDefault: moment(endDateDefault).format('DD MMM YYYY'),
      endDates: endDates?.map((item) => ({
        ...item,
        fromDate: moment(item?.fromDate).format('DD MMM YYYY'),
        endDate: moment(item?.endDate).format('DD MMM YYYY'),
      })),
    };

    dispatch(setJobListingPayload(formattedData));
  };

  const onSelectWorkplace = () => {
    const endDates = form.getFieldValue('endDates') || [];

    const selectedOrgs = endDates.map((item, index) => ({
      ids: item?.organizationIds,
      formKey: index,
    }));

    setSelectedWorkplaces(selectedOrgs);
  };

  const getWorkplacesSelected = (formKey) => {
    const currentResourceIds =
      form.getFieldValue(['endDates', formKey, 'organizationIds']) || [];

    const selectedIds = flatten(
      selectedWorkplaces.map((item) =>
        item.formKey !== formKey ? item.ids : [],
      ),
    );

    const result = allWorkplacesData?.filter(
      (item) =>
        !selectedIds.includes(item.organizationId) ||
        currentResourceIds.includes(item.organizationId),
    );

    return result;
  };

  const onCheckExceptionChange = (e) => {
    const { checked } = e.target;
    if (!checked && !isEmpty(form.getFieldValue('endDates'))) {
      Modal.confirm({
        title: i18next.t('jobListings.confirmRemoveAllException'),
        icon: <ExclamationCircleFilled />,
        okText: i18next.t('button.remove'),
        cancelButtonProps: { className: 'btn-line-primary' },
        onOk: () => {
          form.resetFields(['endDates']);
          setHasException(false);
          setSelectedWorkplaces([]);
        },
      });
    } else {
      setHasException(checked);
    }
  };

  const onRemoveException = (callback, name) => {
    Modal.confirm({
      title: i18next.t('jobListings.confirmRemoveException'),
      icon: <ExclamationCircleFilled />,
      okText: i18next.t('button.remove'),
      cancelButtonProps: { className: 'btn-line-primary' },
      onOk: () => {
        callback(name);

        const removedIndex = selectedWorkplaces.findIndex(
          (item) => item.formKey === name,
        );

        const newWorkplaces = selectedWorkplaces.filter(
          (item, index) => index !== removedIndex,
        );

        setSelectedWorkplaces(newWorkplaces);
      },
    });
  };

  return (
    <ApplicationDeadlineStepStyles>
      <PageTitle className="step-title">
        {i18next.t('jobListings.steps.step4')}
      </PageTitle>
      <Form
        form={form}
        layout="inline"
        onFinish={onFinish}
        initialValues={{
          ...initialValues,
          endDateDefault:
            initialValues?.endDateDefault &&
            moment(initialValues?.endDateDefault),
          endDates: initialValues?.endDates?.map((item) => ({
            ...item,
            fromDate: moment(item?.fromDate),
            endDate: moment(item?.endDate),
          })),
        }}
      >
        <RestInputContext.Provider value={{ form, record: {} }}>
          <DeadlineDurationForm
            daySource="days"
            endDateSource="endDateDefault"
            startDateSource="fromDate"
          />

          <div className="w-full mt-32">
            <Checkbox
              checked={hasException}
              onChange={onCheckExceptionChange}
              defaultChecked={initialValues?.hasException}
              className="mb-16"
            >
              {i18next.t('button.addException')}
            </Checkbox>

            {hasException && (
              <Form.List
                name="endDates"
                className="list-exception"
                initialValue={[{}]}
              >
                {(fields, { add, remove }, { errors }) => (
                  <>
                    {fields.map(({ key, name }) => (
                      <div key={key} className="flex ml-24">
                        <span className="exception-index">
                          {`${name + 1}.`}
                        </span>

                        <ExceptionListItem>
                          <div className="w-full flex mb-16">
                            <SelectWorkplace
                              name={name}
                              resourceData={getWorkplacesSelected(name)}
                              onSelectWorkplace={onSelectWorkplace}
                              required
                              loading={loading}
                              onSearch={onSearchExcludeOption}
                              onEnter={onEnterWaypoint}
                              onDropdownVisibleChange={onDropdownVisibleChange}
                            />
                            <DeleteIcon
                              onClick={() => onRemoveException(remove, name)}
                            />
                          </div>
                          <DeadlineDurationForm
                            prefixSource="endDates"
                            daySource={[name, 'days']}
                            endDateSource={[name, 'endDate']}
                            startDateSource={[name, 'fromDate']}
                          />
                        </ExceptionListItem>
                      </div>
                    ))}
                    <Form.ErrorList errors={errors} />

                    <Form.Item noStyle shouldUpdate>
                      {({ getFieldValue }) => {
                        const selectedIds = flatten(
                          getFieldValue('endDates').map(
                            (item) => item?.organizationIds,
                          ),
                        );

                        return (
                          allWorkplacesData.length !== selectedIds.length && (
                            <Button
                              type="text"
                              onClick={() => add()}
                              className="btn-add-more"
                            >
                              {`+ ${i18next.t('button.addMore')}`}
                            </Button>
                          )
                        );
                      }}
                    </Form.Item>
                  </>
                )}
              </Form.List>
            )}
          </div>
        </RestInputContext.Provider>
      </Form>
    </ApplicationDeadlineStepStyles>
  );
};

ApplicationDeadlineStep.propTypes = {
  form: PropTypes.object,
  initialValues: PropTypes.object,
};

export default ApplicationDeadlineStep;

import { Col, Row } from 'antd';
import React, { useRef } from 'react';
import { IProgramListResponse } from 'generated/apis';
import ProgramsLearningCard from '../ProgramsLearningCard';
import ProgramDetails from '../ProgramDetails';

interface Props {
  data?: IProgramListResponse;
  isEdit?: boolean;
}

export interface ProgramDetailsRef {
  open: (id: string) => void;
}

const ProgramsAndLearningBoard = ({ data, isEdit }: Props) => {
  const programDetailsRef = useRef<ProgramDetailsRef>();

  const onCardClick = (id: string) => {
    programDetailsRef.current.open(id);
  };

  return (
    <>
      <Row gutter={[24, 24]}>
        {data?.results?.map((item) => (
          <Col key={item?.id} xl={6} lg={8} sm={12} xs={24}>
            <ProgramsLearningCard
              isEdit={isEdit}
              onClick={() => onCardClick(item?.id)}
              {...item}
            />
          </Col>
        ))}
      </Row>
      <ProgramDetails isEdit={isEdit} ref={programDetailsRef} />
    </>
  );
};

export default ProgramsAndLearningBoard;

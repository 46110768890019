import React from 'react';
import PropTypes from 'prop-types';
import { getIdByUrl } from 'utils/tools';

import Edit from 'containers/rest/Edit';
import Form from '../components/Form';

const CentreServicesEdit = (props) => {
  const centreId = getIdByUrl('centres', props.location);

  const formatOnSubmit = (data) => ({
    centreId,
    ...data,
  });

  return (
    <Edit {...props} resource="centreServices" formatOnSubmit={formatOnSubmit}>
      <Form isEdit />
    </Edit>
  );
};

CentreServicesEdit.propTypes = {
  location: PropTypes.object,
};

export default CentreServicesEdit;

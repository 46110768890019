import React, { useState, useEffect } from 'react';
import { Popover } from 'antd';
import PropTypes from 'prop-types';
import { MarkerStyles, ContentMarkerStyles } from './styles';

const MarkerPoint = ({ name, address }) => {
  // fix Warning: Expected server HTML to contain a matching <div>
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    setVisible(true);
  }, []);

  const content = (
    <ContentMarkerStyles>
      <div className="marker-name">{name}</div>
      <div>{address}</div>
    </ContentMarkerStyles>
  );

  return (
    <MarkerStyles className={`${address ? '' : 'd-none'}`}>
      <Popover
        getPopupContainer={(triggerNode) => triggerNode.parentNode}
        overlayClassName="overlay-popover-marker"
        content={content}
        visible={visible}
      >
        <div className="pointer-marker" />
      </Popover>
    </MarkerStyles>
  );
};

MarkerPoint.propTypes = {
  name: PropTypes.string,
  address: PropTypes.string,
};

export default MarkerPoint;

import React, { useEffect, useState } from 'react';
import i18next from 'i18next';
import { formatNightDayTable } from 'utils/formatFieldUtils';
import TableCustom from 'components/common/TableCustom';
import { useParams } from 'react-router';
import { useGetLeadActivitiesContactQuery } from 'redux/@rtkQuery/contacts';
import { LeadActivitiesPayload } from 'types/leadActivities';
import {
  DEFAULT_CURRENT_PAGE,
  SORT_DESCEND_VALUE,
} from 'configs/localData/constant';
import useFormatFnc from 'containers/LeadActivities/components/LeadActivitiesTable/useFormat';
import { Link } from 'react-router-dom';
import CardSection from 'components/common/CardSection';
import { getCentreId } from 'redux/auth/selectors';
import { useSelector } from 'react-redux';
import {
  DEFAULT_LIMIT_ITEM,
  PAGE_SIZE_OPTIONS,
} from 'containers/Contacts/constants';

const DEFAULT_FILTER = {
  page: DEFAULT_CURRENT_PAGE,
  size: DEFAULT_LIMIT_ITEM,
};

const LeadActivitiesTable = () => {
  const centreId = useSelector(getCentreId);

  const useFormat = useFormatFnc();
  const { id } = useParams();

  const [payload, setPayload] = useState<LeadActivitiesPayload>(DEFAULT_FILTER);
  const { data, isLoading, isFetching, refetch } =
    useGetLeadActivitiesContactQuery({
      contactId: id,
      ...payload,
    });

  useEffect(() => {
    refetch();
  }, [centreId, refetch]);

  const columns = [
    {
      title: i18next.t('leadActivities.table.header.centreName'),
      dataIndex: 'centreName',
      sorter: true,
      width: 250,
      render: (_, record) => (
        <Link
          className="text-black"
          to={`/centres/${record.centre?.centreId}/show/summary`}
        >
          {record.centre?.centreName || ''}
        </Link>
      ),
    },
    {
      title: i18next.t('leadActivities.table.header.clickThroughType'),
      dataIndex: 'clickThroughType',
      sorter: true,
      width: 180,
      render: (_, record) =>
        useFormat.formatTagType(record?.clickThrough) || '-',
    },

    {
      title: i18next.t('leadActivities.table.header.providerName'),
      dataIndex: 'providerName',
      sorter: true,
      width: 200,
      render: (value) => useFormat.formatData(value),
    },
    {
      title: i18next.t('leadActivities.table.header.brand'),
      dataIndex: 'brandName',
      sorter: true,
      width: 200,
      render: (value) => useFormat.formatData(value),
    },
    {
      title: i18next.t('leadActivities.table.header.subscription'),
      dataIndex: 'subscriptionRank',
      sorter: true,
      width: 200,
      render: (_, record) =>
        useFormat.formatTagType(record?.subscription) || '-',
    },
    {
      title: i18next.t('leadActivities.table.header.clickThroughDate'),
      dataIndex: 'clickThroughDate',
      sorter: true,
      width: 200,
      render: (_, record) =>
        formatNightDayTable(record?.clickThrough?.date) || '-',
    },
  ];

  const onChange = (pagination, filters, sorter) => {
    const formatSort =
      sorter && sorter.field && sorter.order
        ? `${sorter.order === SORT_DESCEND_VALUE ? '-' : ''}${sorter.field}`
        : null;

    setPayload({
      size: pagination?.pageSize,
      page: pagination?.current,
      sorts: [formatSort],
    });
  };

  return (
    <CardSection title="leadActivities.title">
      <TableCustom
        columns={columns}
        dataSource={data?.results ?? []}
        pagination={{
          pageSize: data?.paging?.size || DEFAULT_LIMIT_ITEM,
          current: data?.paging?.page || DEFAULT_CURRENT_PAGE,
          total: data?.paging?.total,
          pageSizeOptions: PAGE_SIZE_OPTIONS,
        }}
        isResetStyle
        loading={isLoading || isFetching}
        onChange={onChange}
        xScroll={1400}
      />
    </CardSection>
  );
};

export default LeadActivitiesTable;

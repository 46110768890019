import React from 'react';
import i18next from 'i18next';
import { Row, Col } from 'antd';
import CheckPermissionsWrapper from 'components/common/CheckPermissions';
import PageTitle from 'components/common/PageTitle';
import coverCentres from 'assets/images/setting-centres.jpg';
import coverUsers from 'assets/images/setting-users.jpg';
import coverNofify from 'assets/images/setting-noti.jpg';

import CardConfig from './components/CardConfig';

const ConfigPage = () => {
  const dataConfig = [
    {
      title: 'centres.title',
      description: 'centres.descriptionCard',
      link: '/config/centres',
      background: '#BEE6F8',
      permissionKey: 'myCentres',
      action: 'isView',
      cover: coverCentres,
    },
    {
      title: 'centreUsers.title',
      description: 'centreUsers.descriptionCard',
      link: '/config/users',
      background: '#BEE6F8',
      permissionKey: 'abilityChangeUserPermissions',
      action: 'isView',
      isSkipSubscription: true,
      cover: coverUsers,
    },
    {
      title: 'notificationsAndAlerts.title',
      description: 'notificationsAndAlerts.descriptionCard',
      link: '/config/notificationsAndAlerts',
      background: '#BEE6F8',
      cover: coverNofify,
    },
  ];

  return (
    <div>
      <PageTitle>{i18next.t('settings.header')}</PageTitle>
      <Row gutter={24}>
        {dataConfig.map((record, index) => (
          <CheckPermissionsWrapper
            permissionKey={record.permissionKey}
            action={record.action}
            isSkipSubscription={record.isSkipSubscription}
            key={String(index)}
          >
            <Col xl={8} lg={8} md={12} xs={24} style={{ marginBottom: 24 }}>
              <CardConfig item={record} />
            </Col>
          </CheckPermissionsWrapper>
        ))}
      </Row>
    </div>
  );
};

export default ConfigPage;

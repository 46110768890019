import { List } from 'antd';
import styled from 'styled-components';

export const TooltipContainer = styled.div`
  background: #ffffff;
  box-shadow: 0px 8px 24px rgba(36, 38, 64, 0.04);
  border-radius: 8px;
  max-width: 300px;
`;

export const ListMetrics = styled(List)`
  .ant-list-items {
    padding: 16px;
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 6px;
    }

    &::-webkit-scrollbar-track {
      background: #f1f1f1;
    }

    &::-webkit-scrollbar-thumb {
      background: #bbb;
      border-radius: 8px;
    }
  }

  .ant-list-header {
    border-bottom: 1px solid #ebecf0;
  }
`;

interface MetricTagProps {
  width?: number;
  color?: string;
}

export const MetricTag = styled.span<MetricTagProps>`
  display: inline-block;
  background: ${({ color }) => color};
  border-radius: 8px;
  min-width: ${({ width }) => width || 24}px;
  height: 6px;
`;

export const MetricItem = styled(List.Item)<{ opacity: number }>`
  opacity: ${({ opacity }) => opacity};
  display: flex;
  padding: 5px 0;
  transition: opacity 0.3s ease-in-out;

  strong {
    margin-left: auto;
  }
`;

import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { Button, Form, Layout } from 'antd';
import kindiCareJobLogo from 'assets/images/kc-job-logo.png';
import { useDispatch, useSelector } from 'react-redux';
import i18next from 'i18next';
import { getJobTemplatesDetail } from 'redux/jobs/jobTemplates/actions';
import ExistingJobTemplateGrid from 'containers/JobTemplates/components/ExistingJobTemplateGrid';
import { useLocation } from 'react-router-dom';
import { getFilterFromUrl } from 'utils/tools';
import {
  goToNextStep,
  goToPrevStep,
  goToStepJobListing,
  resetJobListingPayload,
  setJobListingPayload,
} from 'redux/jobs/jobListings/slice';
import {
  getJobListingCurrentStepSelector,
  getJobListingPayloadSelector,
} from 'redux/jobs/jobListings/selectors';
import qs from 'qs';
import { getConfig } from 'redux/config/actions';
import { setSelectedTemplate } from 'redux/jobs/jobTemplates/slice';
import { BasicInfoBannerWrapper } from './styles';
import Footer from './Footer';
import Steps from './Steps';
import CreateTemplateAction from './CreateTemplateAction';
import {
  CHOOSE_EXISTING_TEMPLATE_PARAM,
  CREATE_JOB_LISTINGS_STEPS_PARAM,
  CREATE_JOB_LISTING_FINAL_STEP,
  SELECT_WORKPLACE_STEP_INDEX,
  START_CREATE_PARAM,
} from '../constants';
import PreviewStep from '../components/Steps/PreviewStep';
import SelectWorkplaceStep from '../components/Steps/SelectWorkplaceStep';
import SelectStartDateStep from '../components/Steps/SelectStartDateStep';
import ApplicationDeadlineStep from '../components/Steps/ApplicationDeadlineStep';
import CampaignStep from '../components/Steps/CampaignStep';

const { Header, Sider, Content } = Layout;

const CreateJobListing = ({ match, history }) => {
  const dispatch = useDispatch();
  const screenParam = match.params.screen;
  const { search } = useLocation();
  const {
    filter: { id: existTemplateId },
  } = getFilterFromUrl(search);

  const { from } = qs.parse(search, { ignoreQueryPrefix: true });

  const fromRef = useRef(from);

  const [jumpStep, setJumpStep] = useState(null);

  const currentUserId = useSelector((state) => state.auth.data?.id);
  const currentStep = useSelector(getJobListingCurrentStepSelector);
  const payload = useSelector(getJobListingPayloadSelector);

  const [formSelectWorkplace] = Form.useForm();
  const [formStartDate] = Form.useForm();
  const [formApplicationDeadline] = Form.useForm();
  const [formCampaign] = Form.useForm();

  const { content, form } = (() => {
    if (screenParam === START_CREATE_PARAM) {
      return { content: <CreateTemplateAction /> };
    }

    if (screenParam === CHOOSE_EXISTING_TEMPLATE_PARAM) {
      return { content: <ExistingJobTemplateGrid /> };
    }

    switch (currentStep) {
      case 0:
        return {
          content: <PreviewStep />,
        };
      case 1:
        return {
          content: <SelectWorkplaceStep jumpStep={jumpStep} />,
          form: formSelectWorkplace,
        };
      case 2:
        return {
          content: <SelectStartDateStep />,
          form: formStartDate,
        };
      case 3:
        return {
          content: <ApplicationDeadlineStep />,
          form: formApplicationDeadline,
        };
      case 4:
        return {
          content: <CampaignStep />,
          form: formCampaign,
        };
      default:
        return {};
    }
  })();

  const resetStepAndPayload = () => {
    dispatch(resetJobListingPayload());
  };

  const handleSubmitForm = (callback) => {
    if (!form) {
      callback();
      return;
    }

    form.validateFields().then(() => {
      form.submit();

      if (currentStep === SELECT_WORKPLACE_STEP_INDEX) {
        return;
      }

      callback();
    });
  };

  const handleJumpStep = (newStep) => {
    setJumpStep(newStep);

    setTimeout(() => setJumpStep(null), 500);

    if (currentStep === CREATE_JOB_LISTING_FINAL_STEP) {
      dispatch(goToStepJobListing(newStep));
      return;
    }

    handleSubmitForm(() => dispatch(goToStepJobListing(newStep)));
  };

  const handleGoToPrevStep = () => {
    if (currentStep === 0 || screenParam === CHOOSE_EXISTING_TEMPLATE_PARAM) {
      resetStepAndPayload();
      history.push('/job-listings/create/choose');
      return;
    }
    dispatch(goToPrevStep());
  };

  const handleGoToNextStep = () => {
    if (screenParam === CHOOSE_EXISTING_TEMPLATE_PARAM) {
      const selectedTemplates = document.getElementsByClassName(
        'template-card-selected',
      );
      if (selectedTemplates?.length > 0) {
        resetStepAndPayload();
        dispatch(setSelectedTemplate(true));
        dispatch(getJobTemplatesDetail({ id: existTemplateId }));
        dispatch(setJobListingPayload({ templateId: existTemplateId }));
        history.push(`/job-listings/create/steps?id=${existTemplateId}`);
      } else {
        dispatch(setSelectedTemplate(false));
      }
    } else {
      handleSubmitForm(() => dispatch(goToNextStep()));
    }
  };

  const handleExitCreateTemplate = () => {
    if (fromRef.current) {
      history.push(fromRef.current);
      resetStepAndPayload();
      return;
    }
    history.push('/job-listings/campaign/list');
    resetStepAndPayload();
  };

  useEffect(() => {
    if (existTemplateId) {
      dispatch(getJobTemplatesDetail({ id: existTemplateId }));
    }
  }, [dispatch, existTemplateId]);

  useEffect(() => {
    if (currentUserId) {
      dispatch(getConfig());
    }
  }, [currentUserId, dispatch]);

  return (
    <BasicInfoBannerWrapper>
      <Sider width="30%" breakpoint="lg">
        {screenParam === CREATE_JOB_LISTINGS_STEPS_PARAM ? (
          <Steps currentStep={currentStep} onChange={handleJumpStep} />
        ) : (
          <div className="banner-container flex flex-center h-full w-full">
            <div className="banner-content">
              <div className="banner-content-title">
                {i18next.t('jobListings.buttons.createJobListing')}
              </div>
            </div>
          </div>
        )}
      </Sider>
      <Layout>
        <Header>
          <div className="text-center kc-job-logo">
            <img src={kindiCareJobLogo} alt="kc-job-logo" />
          </div>
          <div className="btn-exit-wrapper">
            <Button
              type="text"
              className="btn-exit fw-bold"
              onClick={handleExitCreateTemplate}
            >
              {i18next.t('button.exit')}
            </Button>
          </div>
        </Header>
        <Content className="p-64">
          {React.cloneElement(content, {
            form,
            initialValues: payload,
          })}
        </Content>
        {screenParam !== START_CREATE_PARAM ||
        screenParam === CHOOSE_EXISTING_TEMPLATE_PARAM ? (
          <Footer
            handleGoToNextStep={handleGoToNextStep}
            handleGoToPrevStep={handleGoToPrevStep}
          />
        ) : null}
      </Layout>
    </BasicInfoBannerWrapper>
  );
};

CreateJobListing.propTypes = {
  match: PropTypes.object,
  history: PropTypes.object,
};

export default CreateJobListing;

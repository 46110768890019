import { Col, FormInstance, InputNumber, Row, Switch } from 'antd';
import RestInputItem from 'components/RestInput/RestInputItem';
import RestSelect from 'components/RestInput/RestSelect';
import { LABEL_TABLE_KEY } from 'configs/localData/constant';
import ProgramSelect from 'containers/Centres/components/CentreTabs/ServicesTab/Modals/ProgramSelect';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import {
  getServiceAgeGroups,
  getServiceTypes,
  getSessionTypes,
} from 'redux/config/selectors';
import { InitialStateConfig } from 'types/config';
import { formatLabelTable } from '../utils';

interface Props {
  isEdit?: boolean;
  isProgramEdit?: boolean;
  isProgramCreate?: boolean;
  form: FormInstance;
}

const ServiceForm = ({
  isEdit,
  isProgramEdit,
  isProgramCreate,
  form,
}: Props) => {
  const { t } = useTranslation();
  const serviceTypes = useSelector(getServiceTypes);
  const SessionTypes = useSelector(getSessionTypes);
  const serviceAgeGroups = useSelector(getServiceAgeGroups);
  const appSettings = useSelector(
    ({ config }: { config: InitialStateConfig }) => config?.data?.AppSetting,
  );
  const serviceTypeActive = serviceTypes.filter(
    (item) => item?.isActive === true,
  );
  const serviceAgeGroupsActive = serviceAgeGroups.filter(
    (item) => item?.isActive === true,
  );

  const resetProgramId = () => {
    form.setFieldsValue({ programId: undefined });
  };

  return (
    <div>
      <RestSelect
        required
        header={formatLabelTable(LABEL_TABLE_KEY.serviceType, appSettings)}
        placeholder={formatLabelTable(LABEL_TABLE_KEY.serviceType, appSettings)}
        source="serviceTypeId"
        resourceData={serviceTypeActive || []}
        valueProp="id"
        titleProp="name"
        onChange={resetProgramId}
      />
      <RestSelect
        required
        header={formatLabelTable(LABEL_TABLE_KEY.ageGroup, appSettings)}
        placeholder={formatLabelTable(LABEL_TABLE_KEY.ageGroup, appSettings)}
        source="serviceAgeGroupId"
        resourceData={serviceAgeGroupsActive || []}
        valueProp="id"
        titleProp="name"
        onChange={resetProgramId}
      />
      <RestSelect
        required
        header={formatLabelTable(LABEL_TABLE_KEY.sessionType, appSettings)}
        placeholder={formatLabelTable(LABEL_TABLE_KEY.sessionType, appSettings)}
        source="sessionTypeId"
        resourceData={SessionTypes || []}
        valueProp="id"
        titleProp="name"
        onChange={resetProgramId}
      />
      <ProgramSelect
        isProgramEdit={isProgramEdit}
        isProgramCreate={isProgramCreate}
      />
      <RestInputItem
        required
        header="centres.costPerSession"
        placeholder="centres.costPerSession"
        source="costPerDay"
        ContentComponent={InputNumber}
        min={0}
        ruleType="number"
      />
      <RestInputItem
        header="centres.roomName"
        placeholder="centres.roomName"
        source="roomName"
        rules={[
          {
            max: 255,
            message: t('error.maxLength', { max: 255 }),
          },
        ]}
      />
      <RestInputItem
        header="centres.capacity"
        placeholder="centres.capacity"
        source="capacity"
        ContentComponent={InputNumber}
        min={0}
        ruleType="number"
      />

      {isEdit && (
        <Row gutter={16}>
          <Col span={12}>
            <RestInputItem
              source="vacancyCod"
              header="centreServices.vacancy"
              placeholder="centreServices.vacancy"
              ContentComponent={Switch}
              ruleType="boolean"
              valuePropName="checked"
            />
          </Col>
          <Col span={12}>
            <RestInputItem
              source="isActive"
              header="centreServices.isActive"
              placeholder="centreServices.isActive"
              ContentComponent={Switch}
              ruleType="boolean"
              valuePropName="checked"
            />
          </Col>
        </Row>
      )}
    </div>
  );
};

export default ServiceForm;

import { NoDataIcon } from 'components/common/SVGIcon';
import i18next from 'i18next';
import React from 'react';

interface EmptyDataProp {
  icon?: JSX.Element;
  description?: string;
}

const EmptyData = ({ icon = <NoDataIcon />, description }: EmptyDataProp) => (
  <div className="flex-col flex-center">
    {icon}
    <div>{i18next.t(description)}</div>
  </div>
);

export default EmptyData;

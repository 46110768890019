import styled from 'styled-components';
import { Modal } from 'antd';

export const ModalWrapper = styled(Modal)`
  min-height: 30%;

  .multi-upload-wrapper .ant-form-item:first-child {
    margin-bottom: 10px;
  }

  .drawerContainer {
    display: flex;
    flex-direction: column;
    height: 100%;
    margin-left: 0 !important;
    margin-right: 0 !important;
    padding-bottom: 70px;
    .footer {
      padding: 0 !important;
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
    }
  }

  .content {
    flex: 1;
    overflow-y: auto;
    overflow-x: hidden;
  }
  .ant-drawer-content-wrapper {
    min-width: 450px;
    .ant-drawer-body {
      padding: 0px;
      padding-top: 60px;
      overflow: hidden;
      display: flex;
      & > div {
        overflow-y: scroll;
      }
      & > div,
      form {
        height: 100%;
        width: 100%;
      }
    }

    .content-form {
      padding: 10px 15px 20px 15px;
    }
  }
  .ant-modal-header {
    border: none;
    color: ${({ theme }) => theme.palette.primary};
  }
  .ant-modal-content {
    padding-top: 60px;
  }
  .ant-modal-title {
    color: ${({ theme }) => theme.palette.primary};
    font-size: 22px;
    font-weight: bold;
  }
  .ant-modal-close,
  .ant-modal-close-icon {
    display: none;
  }

  textarea,
  .ant-input,
  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector,
  .ant-select-selection,
  .ant-input-number,
  .ant-select-dropdown-menu-item,
  .ant-select-dropdown-menu,
  .ant-select-dropdown,
  .ant-select-clear-icon,
  .ant-select-dropdown-menu-vertical {
    border-radius: ${({ theme }) => theme.borderRadius.input};
    &:focus,
    &:active {
      border: 1px solid ${({ theme }) => theme.border.primary};
    }
  }

  .ant-select-focused.ant-select-single:not(.ant-select-customize-input)
    .ant-select-selector,
  .ant-input-number-focused,
  .ant-picker-focused {
    border: 1px solid ${({ theme }) => theme.border.primary};
  }

  .ant-input-affix-wrapper {
    .ant-input {
      border: none;
    }
  }

  .ant-input-affix-wrapper-focused {
    border: 1px solid ${({ theme }) => theme.border.primary}!important;
  }

  .ant-select-selection__clear {
    background-color: transparent;
    color: white;
    border-radius: 5px;
  }
  .ant-select-arrow-icon {
    background-color: transparent;
  }
  * {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  .ant-modal-footer {
    border-top: 1px solid ${({ theme }) => theme.background.container};
  }

  .ant-modal-body {
    padding: 10px 24px;
  }

  .ant-tabs-bar {
    font-weight: 500;
  }
  .ant-tabs-nav {
    .ant-tabs-tab {
      padding-bottom: 5px;
    }
  }
  .ant-tabs-tab {
    font-family: 'Mulish';
    color: ${({ theme }) => theme.text.tabTitle};
  }
  .ant-list {
    margin-top: 20px;
    overflow: auto;
    max-height: 460px;
  }
  div::-webkit-scrollbar-thumb {
    border-radius: 3px !important;
    background: ${({ theme }) => theme.scrollbar.thumb} !important;
  }
  div::-webkit-scrollbar-track {
    position: absolute;
    border-radius: 3px !important;
    background: ${({ theme }) => theme.scrollbar.track} !important;
  }
  div::-webkit-scrollbar-thumb:hover {
    border-radius: 3px !important;
    background: ${({ theme }) => theme.scrollbar.thumb} !important;
  }
  div::-webkit-scrollbar {
    width: 6px;
    border-radius: 3px !important;
    background: ${({ theme }) => theme.scrollbar.thumb} !important;
  }
  .ant-list-split .ant-list-item {
    border-bottom: none;
    padding: 1px 0px;
  }
  .ant-list-empty-text {
    color: ${({ theme }) => theme.text.empty};
  }
  .modalTitleContent {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    height: 64px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    display: flex;
    color: ${({ theme }) => theme.palette.primary};
    font-size: 22px;
    font-weight: bold;
    .modalBtnBack {
      margin: 0px 24px;
      color: ${({ theme }) => theme.palette.primary};
    }
    .modalTitle {
      flex: 1;
      padding-left: 24px;
      color: ${({ theme }) => theme.palette.primary};
    }
  }

  .ant-form-item {
  }
  .ant-form-item-control {
    line-height: 2;
  }
  .txtTitle {
    font-size: 12px;
  }
  .ant-form-item-label {
    line-height: 1.5em;
    padding-bottom: 5px;
  }
  .ant-input-number {
    width: 100%;
  }
  .txtTitleForm {
    color: ${({ theme }) => theme.text.formLabel};
    font-size: 12px;
  }
`;

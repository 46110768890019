import { CheckOutlined } from '@ant-design/icons';
import { Button, Modal } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import { useDispatch } from 'react-redux';
import { doneTask, getAllTasks } from 'redux/applications/actions';
import i18next from 'i18next';
import { useParams } from 'react-router';

const DoneButton = ({ taskId }) => {
  const dispatch = useDispatch();
  const { id } = useParams();

  const showConfirm = () => {
    Modal.confirm({
      title: i18next.t('task.confirm'),
      onOk: () => {
        dispatch(doneTask(taskId)).then(() => {
          dispatch(
            getAllTasks({
              id,
              data: { orderBy: '-createdAt' },
              options: true,
            }),
          );
        });
      },
    });
  };

  return (
    <Button onClick={showConfirm} icon={<CheckOutlined />}>
      {i18next.t('task.complete')}
    </Button>
  );
};

DoneButton.propTypes = {
  taskId: PropTypes.string,
};
export default DoneButton;

import React, { useEffect, useContext } from 'react';
import { Col, Row } from 'antd';
import RestSelect from 'components/RestInput/RestSelect';
import RestRangePickerInput from 'components/RestInput/RestRangePickerInput';
import { RestInputContext } from 'components/RestInput/RestInputContext';
import { useLocation } from 'react-router';
import { capitalizeFirstLetter, getFilterFromUrl } from 'utils/tools';
import ReferenceInputV2 from 'containers/rest/ReferenceInputV2';
import moment from 'moment';
import { JOB_LISTING_STATUS } from 'containers/JobListings/constants';
import i18next from 'i18next';
import { PREFIX_API_VER_2, PREFIX_URL } from 'configs/localData/constant';

import {
  getStates,
  getWorkplaceType,
  getJobRoles,
  getJobTypes,
} from 'redux/config/selectors';
import ReferenceInput from 'containers/rest/ReferenceInput';
import { useSelector } from 'react-redux';

const FilterForm = () => {
  const { form } = useContext(RestInputContext);
  const { search } = useLocation();
  const stateList = useSelector(getStates);
  const workplaceTypes = useSelector(getWorkplaceType);
  const jobRoles = useSelector(getJobRoles);
  const jobTypes = useSelector(getJobTypes);

  useEffect(() => {
    const { filter } = getFilterFromUrl(search);
    const { updatedAt, createdAt, ...restFilter } = filter?.filter || {};
    form.setFieldsValue({
      createdAt: Object.values(createdAt || {}).map((item) => moment(item)),
      updatedAt: Object.values(updatedAt || {}).map((item) => moment(item)),
      ...restFilter,
    });
  }, [search, form]);

  return (
    <Row gutter={16} type="flex">
      <Col xl={4} xs={12} md={8}>
        <ReferenceInputV2
          prefixUrl={PREFIX_URL.crm}
          resource="job-seekers/posts/job-reference/basic-info"
          source="jobReference"
          searchKey="jobReference"
          mappedBy="jobReference"
          notLikeFilter
          requestApi={PREFIX_API_VER_2}
        >
          <RestSelect
            valueProp="jobReference"
            titleProp="jobReference"
            placeholder="jobListings.jobReference"
            isFilterOption={false}
            isShowTooltip
            autoComplete="off"
          />
        </ReferenceInputV2>
      </Col>
      <Col xl={4} xs={12} md={8}>
        <ReferenceInputV2
          prefixUrl={PREFIX_URL.crm}
          resource="job-seekers/campaigns/basic-info"
          source="campaign"
          searchKey="name"
          mappedBy="name"
          notLikeFilter
          requestApi={PREFIX_API_VER_2}
        >
          <RestSelect
            valueProp="name"
            titleProp="name"
            placeholder="jobListings.campaign"
            isFilterOption={false}
            isShowTooltip
            autoComplete="off"
          />
        </ReferenceInputV2>
      </Col>
      <Col xl={4} xs={12} md={8}>
        <RestSelect
          source="jobRoleId"
          placeholder="jobListings.jobRole"
          resourceData={jobRoles}
          valueProp="id"
          titleProp="name"
          isShowTooltip
        />
      </Col>
      <Col xl={4} xs={12} md={8}>
        <ReferenceInputV2
          prefixUrl={PREFIX_URL.crm}
          resource="job-seekers/posts/templates/basic-info"
          source="templateName"
          searchKey="templateName"
          mappedBy="templateName"
          notLikeFilter
          requestApi={PREFIX_API_VER_2}
        >
          <RestSelect
            valueProp="templateName"
            titleProp="templateName"
            placeholder="jobListings.jobTemplate"
            isFilterOption={false}
            isShowTooltip
            autoComplete="off"
          />
        </ReferenceInputV2>
      </Col>
      <Col xl={4} xs={12} md={8}>
        <RestSelect
          source="workplaceType"
          placeholder="jobListings.workplaceType"
          resourceData={workplaceTypes}
          formatText={(data) => capitalizeFirstLetter(data)}
          isShowTooltip
        />
      </Col>
      <Col xl={4} xs={12} md={8}>
        {
          <ReferenceInputV2
            prefixUrl={PREFIX_URL.crm}
            resource="job-seekers/posts/workplaces/basic-info"
            source="workplace"
            searchKey="workplaceName"
            mappedBy="workplaceName"
            notLikeFilter
            requestApi={PREFIX_API_VER_2}
          >
            <RestSelect
              valueProp="workplaceName"
              titleProp="workplaceName"
              placeholder="jobListings.workplace"
              isFilterOption={false}
              isShowTooltip
              autoComplete="off"
            />
          </ReferenceInputV2>
        }
      </Col>
      <Col xl={4} xs={12} md={8}>
        <RestSelect
          source="jobTypeId"
          placeholder="jobListings.jobType"
          resourceData={jobTypes}
          valueProp="id"
          titleProp="name"
          isShowTooltip
        />
      </Col>
      <Col xl={4} xs={12} md={8}>
        <RestSelect
          source="jobListingStatus"
          placeholder="jobListings.jobListingStatus"
          resourceData={JOB_LISTING_STATUS}
          formatText={(data) => i18next.t(data)}
          valueProp="value"
          titleProp="text"
          isShowTooltip
        />
      </Col>
      <Col xl={4} xs={12} md={8}>
        <ReferenceInput
          prefixUrl={PREFIX_URL.admin}
          source="brandId"
          resource="brands"
          searchKey="brand.name"
          initialFilter={{ orderBy: 'name' }}
        >
          <RestSelect
            titleProp="name"
            valueProp="id"
            placeholder="centres.brandTitle"
            isFilterOption={false}
          />
        </ReferenceInput>
      </Col>
      <Col xl={4} xs={12} md={8}>
        <RestSelect
          source="state"
          placeholder="jobListings.state"
          resourceData={stateList}
          isShowTooltip
        />
      </Col>

      <Col xl={4} xs={12} md={8}>
        <RestRangePickerInput
          sourceGt="createdAt.from"
          sourceLt="createdAt.to"
          source="createdAt"
          placeholder="jobListings.createdAt"
          isShowTooltip
          isShowRangesFooter
        />
      </Col>
      <Col xl={4} xs={12} md={8}>
        <RestRangePickerInput
          sourceGt="lastUpdatedAt.from"
          sourceLt="lastUpdatedAt.to"
          source="updatedAt"
          placeholder="jobListings.lastUpdated"
          isShowTooltip
          isShowRangesFooter
        />
      </Col>
    </Row>
  );
};

export default FilterForm;

import i18next from 'i18next';
import { CrownFilled, RightOutlined } from '@ant-design/icons';
import React from 'react';
import { upgradeModal } from 'components/common/CheckPermissions/UpgradeSubscriptionModal';
import { LinkStyle } from '../styles';

interface IStep {
  createdAt: string;
  updatedAt: string;
  centreId: string;
  stepId: string;
  percent: number;
  step: number;
  description: string;
  key?: string;
  title: string;
}

interface Props {
  centreStep: IStep[];
  currentIndex: number;
}

const NextStepButtonFallBack = ({ centreStep, currentIndex }: Props) => {
  const btnClassName =
    currentIndex === centreStep.length - 1
      ? 'show-hide-btn__previous'
      : 'show-hide-btn__next';
  const onClickNext = () => {
    upgradeModal.open();
  };
  return (
    <LinkStyle
      onClick={onClickNext}
      disabled={currentIndex === centreStep.length - 1}
      className="show-hide-btn ml-16"
    >
      <span className={`ml-16 ${btnClassName}`}>
        {i18next.t('button.next')}
        <CrownFilled className="feature-icon" />
      </span>

      <RightOutlined className={`ml-6 ${btnClassName}`} />
    </LinkStyle>
  );
};

export default NextStepButtonFallBack;

import { createSelector } from 'reselect';
import { ENQUIRY_TYPES, APPLICATION_STATUS_BOARD } from 'configs/localData';
import i18next from 'i18next';
import { sum } from 'lodash-es';

const getRawEnquiriesSummary = (state) =>
  state.dashboard.enquiries.data?.enquiriesSummaryItem;

const getRawEnrolmentPipeline = (state) =>
  state.dashboard.applications.data?.enrolmentPipelineItem;

const findEnquiryType = (value) => {
  const res = ENQUIRY_TYPES.find((item) => item.value === value) || {};
  return {
    color: res.dotColor || '#f2f2f2',
    name: res.text ? i18next.t(res.text) : value,
  };
};

export const getEnquiriesSummary = createSelector(
  [getRawEnquiriesSummary],
  (enquiriesSummary) => {
    if (!enquiriesSummary?.totalEnquiries) return {};

    const data = enquiriesSummary?.enquiryTypes?.map((item) => ({
      ...item,
      ...findEnquiryType(item.name),
    }));
    return {
      totalEnquiries: enquiriesSummary?.totalEnquiries,
      chartData: data,
    };
  },
);

export const getEnrolmentPipeline = createSelector(
  [getRawEnrolmentPipeline],
  (data) => {
    const total = sum(Object.values(data || {})) || 1;

    const res = APPLICATION_STATUS_BOARD.map((item) => ({
      ...item,
      enrolmentPipelineValue: data?.[item.keyEnrolmentPipelineValue] || 0,
      enrolmentPipelinePercent:
        (data?.[item.keyEnrolmentPipelineValue] / total) * 100,
    }));

    return res;
  },
);

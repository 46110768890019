import React, { useCallback, useEffect } from 'react';
import PageTitle from 'components/common/PageTitle';
import i18next from 'i18next';
import ContentUnavailable from 'components/common/ContentUnavailable';
import {
  CENTRE_METRIC_SORT_KEY,
  CentreMetricsPayload,
  SORT_TYPE,
} from 'redux/@rtkQuery/marketingMetris/type';
import { getFilterFromUrl, getSearchUrl } from 'utils/tools';
import { useHistory, useLocation } from 'react-router-dom';
import { isEmpty } from 'lodash';
import { getCentreMetricsDefaultsFilter } from 'containers/MarketingMetrics/utils';
import Filter from './components/Filter';
import KeyMetrics from './components/KeyMetrics';
import CentreMetricsTable from './components/CentreMetricsTable';
import useCheckPermissionMetrics from './hooks/useCheckPermissionMetrics';

export interface PushQueryParams {
  page?: number;
  size?: number;
  sortKey?: CENTRE_METRIC_SORT_KEY;
  sortOrder?: SORT_TYPE;
  filter?: CentreMetricsPayload;
}

const MarketingMetrics = () => {
  const { location, push } = useHistory();
  const { search } = useLocation();
  const { filter } = getFilterFromUrl(search).filter;
  const isCanViewMarketingMetrics = useCheckPermissionMetrics();
  const pathName = location?.pathname;

  const pushQuery = useCallback(
    (query: PushQueryParams) => {
      push(`${pathName}?${getSearchUrl(query)}`);
    },
    [pathName, push],
  );

  useEffect(() => {
    if (isEmpty(filter)) {
      const defaultFilter = getCentreMetricsDefaultsFilter();
      pushQuery(defaultFilter);
    }
  }, [filter, pushQuery]);

  return (
    <div>
      <PageTitle>{i18next.t('marketingMetrics.title')}</PageTitle>
      {isCanViewMarketingMetrics ? (
        <>
          <Filter pushQuery={pushQuery} />
          <KeyMetrics />
          <CentreMetricsTable pushQuery={pushQuery} />
        </>
      ) : (
        <ContentUnavailable className="mt-70" />
      )}
    </div>
  );
};

export default MarketingMetrics;

import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'antd';

import MarketingTabStyles from './styles';
import SummaryApplication from '../SummaryTab/SummaryApplication';
import SummaryEnquiries from '../SummaryTab/SummaryEnquiries';
import MarketingTable from './MarketingTable';

const MarketingTab = ({ record }) => (
  <MarketingTabStyles>
    <Row gutter={[24, 24]}>
      <Col xl={16} lg={24} md={24} xs={24}>
        <MarketingTable record={record} />
      </Col>
      <Col xl={8} lg={24} md={24} xs={24}>
        <Row gutter={[24, 24]}>
          <Col xl={24} md={12} xs={24}>
            <SummaryApplication />
          </Col>
          <Col xl={24} md={12} xs={24}>
            <SummaryEnquiries />
          </Col>
        </Row>
      </Col>
    </Row>
  </MarketingTabStyles>
);

MarketingTab.propTypes = {
  record: PropTypes.object,
};

MarketingTab.defaultProps = {};

export default MarketingTab;

import React, { useState } from 'react';
import i18next from 'i18next';
import TableScrollCustom from 'components/common/TableScrollCustom';
import { useSelector } from 'react-redux';
import {
  brandBenchmarkingSelectors,
  getDataBrandBenchmarking,
  getAverageDataSelector,
  getResourceDataBrandBenchmarkingSelector,
} from 'redux/brandBenchmarking/selectors';
import PropTypes from 'prop-types';
import useTableInfinity from 'hooks/useTableInfinity';
import LoadingMore from 'components/LoadingMore/LoadingMore';
import {
  DEFAULT_ORDER_BY,
  QUERY_ALL_ID,
  MAX_LIMIT,
} from 'containers/CompanyBenchmarking/localData/constants';
import {
  convertSearchUrl,
  getFilterFromUrl,
  getSortOrderColumn,
} from 'utils/tools';
import { useHistory, useLocation } from 'react-router';
import useFormatFnc from 'containers/CompanyBenchmarking/components/CompanyBenchmarkingTable/useFormat';
import { CHARACTER_DASH, EMPTY_STRING } from 'configs/localData/constant';
import { TableStyle } from 'containers/CompanyBenchmarking/components/CompanyBenchmarkingTable/styles';
import AverageAllBrands from './AverageAllBrands';

const BrandBenchmarkingTable = ({ retrieveList, selectBrandId }) => {
  const { push } = useHistory();
  const useFormat = useFormatFnc();
  const { search } = useLocation();
  const [isSorting, setIsSorting] = useState(false);
  const { filter, orderBy } = getFilterFromUrl(search);
  const loading = useSelector(brandBenchmarkingSelectors?.getLoading);
  const data = useSelector(getDataBrandBenchmarking);
  const resourceFilter =
    useSelector(getResourceDataBrandBenchmarkingSelector) || {};
  const averageAllBrand = useSelector(getAverageDataSelector);

  const { isFetchingMore } = useTableInfinity(
    data,
    loading,
    resourceFilter,
    retrieveList,
    { defaultOrderBy: DEFAULT_ORDER_BY },
  );

  const columns = [
    {
      title: i18next.t('companyBenchmarking.ranking'),
      dataIndex: 'ranking',
      width: 100,
      fixed: 'left',
      render: (_, record) => useFormat.formatRanking(record, orderBy),
    },
    {
      title: i18next.t('brandBenchmarking.brandName'),
      dataIndex: 'name',
      render: (_, record) => useFormat.formatName(record),
      width: 300,
      fixed: 'left',
    },
    {
      title: i18next.t('companyBenchmarking.averageServiceApprovalAge'),
      dataIndex: 'averageServiceApprovalAge',
      render: (data) => useFormat.formatColumnData(data),
      width: 280,
    },
    {
      title: i18next.t('companyBenchmarking.centres'),
      dataIndex: 'totalCentres',
      render: (data) => useFormat.formatValueColumData(data),
      width: 150,
    },
    {
      title: i18next.t('companyBenchmarking.approvedPlaces'),
      dataIndex: 'approvedPlaces',
      render: (data) => useFormat.formatValueColumData(data),
      width: 200,
    },
    {
      title: i18next.t('companyBenchmarking.averageApprovedPlaces'),
      dataIndex: 'averageApprovedPlaces',
      render: (data) => useFormat.formatValueColumData(data),
      width: 250,
    },
    {
      title: i18next.t('companyBenchmarking.%marketShare'),
      dataIndex: 'marketShare',
      render: (data) => useFormat.formatMarketShare(data),
      width: 250,
    },
    {
      title: i18next.t('companyBenchmarking.revenuePotential'),
      dataIndex: 'revenuePotential',
      render: (data) => useFormat.formatRevenuePotential(data),
      width: 200,
    },
    {
      title: i18next.t('companyBenchmarking.averageKindiCareRating'),
      dataIndex: 'averageKindiCareRating',
      render: (data) => useFormat.formatKindiRating(data),
      width: 250,
    },
    {
      title: i18next.t('companyBenchmarking.averageReviewScore'),
      dataIndex: 'averageReviewScore',
      render: (data) => useFormat.formatReviewScore(data),
      width: 220,
    },
    {
      title: i18next.t('companyBenchmarking.averageNumberOfReviews'),
      dataIndex: 'averageNumberOfReviews',
      render: (data) => useFormat.formatAverageNumberOfReviews(data),
      width: 280,
    },
    {
      title: i18next.t('companyBenchmarking.%provisional'),
      dataIndex: 'provisional',
      render: (data) => useFormat.formatPercentColumnData(data),
      width: 200,
    },
    {
      title: i18next.t('companyBenchmarking.%significantImprovementRequired'),
      dataIndex: 'significantImprovementRequired',
      render: (data) => useFormat.formatPercentColumnData(data),
      width: 320,
    },
    {
      title: i18next.t('companyBenchmarking.%workingTowardsNQS'),
      dataIndex: 'workingTowardsNQS',
      render: (data) => useFormat.formatPercentColumnData(data),
      width: 250,
    },
    {
      title: i18next.t('companyBenchmarking.%meetingNQS'),
      dataIndex: 'meetingNQS',
      render: (data) => useFormat.formatPercentColumnData(data),
      width: 200,
    },
    {
      title: i18next.t('companyBenchmarking.%exceedingNQS'),
      dataIndex: 'exceedingNQS',
      render: (data) => useFormat.formatPercentColumnData(data),
      width: 200,
    },
    {
      title: i18next.t('companyBenchmarking.%excellent'),
      dataIndex: 'excellent',
      render: (data) => useFormat.formatPercentColumnData(data),
      width: 180,
    },
    {
      title: i18next.t('companyBenchmarking.averageAgeNQSRating'),
      dataIndex: 'averageAgeNQSRating',
      render: (data) => useFormat.formatColumnData(data),
      width: 250,
    },
    {
      title: i18next.t('companyBenchmarking.averageListingsCompleteness'),
      dataIndex: 'averageListingsCompleteness',
      render: (data) => useFormat.formatMarketShare(data),
      width: 300,
    },
  ];

  const columnsHasSorter = columns?.map((column) => {
    const excludeColumnIndexes = ['ranking'];
    const sortOrder = getSortOrderColumn(orderBy, column?.dataIndex);

    if (excludeColumnIndexes.includes(column?.dataIndex)) {
      return column;
    }
    return {
      ...column,
      sorter: () => null,
      sortOrder,
      defaultSortOrder: sortOrder,
    };
  });

  const formatRowData = (id) => {
    const centreOfUserData = data?.find(
      (item) => item?.isOwnedBrand && item?.id === id,
    );
    const selectBrand = data?.find((item) => item?.id === selectBrandId);

    if (
      selectBrand?.id === id ||
      (selectBrandId === QUERY_ALL_ID && centreOfUserData)
    ) {
      return 'selected-row fw-bold';
    }
    if (centreOfUserData) {
      return 'selected-row';
    }
    return null;
  };

  const onChange = (pagination, filters, sorter, { action }) => {
    if (action !== 'sort') {
      return;
    }

    const orderBy =
      sorter.field && sorter.order
        ? `${sorter.order === 'descend' ? CHARACTER_DASH : EMPTY_STRING}${
            sorter.field
          }`
        : undefined;

    const newSearchParams = {
      ...filter,
      limit: MAX_LIMIT,
      offset: 0,
    };

    setIsSorting(true);
    retrieveList({
      filter: {
        ...newSearchParams,
        orderBy: orderBy || DEFAULT_ORDER_BY,
      },
      isRefresh: false,
      isSort: true,
    }).finally(() => setIsSorting(false));

    push({
      search: `?${convertSearchUrl({ ...newSearchParams, orderBy })}`,
    });
  };

  return (
    <TableStyle className="mt-24">
      <TableScrollCustom
        isSorter
        columns={columnsHasSorter}
        dataSource={data}
        pagination={false}
        scroll={{ y: 500 }}
        scrollToFirstRowOnChange={false}
        onChange={onChange}
        loading={isSorting && loading}
        rowClassName={(record) => formatRowData(record?.id)}
        summary={() => AverageAllBrands({ averageAllBrand })}
      />

      {isFetchingMore && <LoadingMore />}
    </TableStyle>
  );
};
BrandBenchmarkingTable.propTypes = {
  retrieveList: PropTypes.func,
  selectBrandId: PropTypes.object,
};
export default BrandBenchmarkingTable;

import { post, put, del } from './utils';

interface CentreServicesData {
  centreId?: string;
  roomName?: string | null;
  serviceTypeId: string;
  serviceAgeGroupId: string;
  sessionTypeId: string;
  costPerDay: number;
  capacity?: number;
}

export const createCentreServiceApi = (data: CentreServicesData) =>
  post(`/admin/centreServices`, data);

export const editCentreServiceApi = (id: string, data: CentreServicesData) =>
  put(`/admin/centreServices/${id}`, data);

export const delCentreServiceApi = (id: string) =>
  del(`/admin/centreServices/${id}`);

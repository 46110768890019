import { unionBy, filter, findIndex, remove } from 'lodash-es';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  CLOSE_ADS_STATUS,
  CREATE_AD_FINAL_STEP_INDEX,
  DEFAULT_CALLING_BUTTON_ID,
  DEFAULT_TYPE_ID,
} from 'containers/Advertisement/constants';
import {
  AudienceItem,
  AdsType,
  CallingButton,
  CreatePayload,
  IAdsFormConfig,
  AdsItemResponse,
  AdsResourceParams,
  AdDetailItem,
} from 'api/advertisement/type';
import {
  addCallingButton,
  deleteCallingsButton,
  editCallingButton,
  getAdsFormConfig,
  getCallingButtonList,
  deleteAdAudience,
  editAdAudience,
  getAdAudiences,
  MODEL_NAME,
  createAdAudience,
  getAdsList,
  getAdDetail,
  closeAds,
  deleteAd,
} from './actions';

interface InitialState {
  currentStep: number;
  createPayload: CreatePayload;
  createLoading: boolean;
  config: IAdsFormConfig;
  audiences: {
    data: AudienceItem[];
    loading: boolean;
    resourceParams: Paging;
  };
  adType: AdsType;
  callingButton: CallingButton;
  loading: boolean;
  formConfigloading: boolean;
  detailLoading: boolean;
  data: AdsItemResponse[];
  resourceData: AdsResourceParams;
  title: Array<{
    headline: string;
    id: string;
  }>;
  currentData: AdDetailItem;
  hasSelectedAd: string;
}

const initialState: InitialState = {
  currentStep: 0,
  createPayload: {
    type: DEFAULT_TYPE_ID,
    headline: '',
    description: '',
    callingButton: DEFAULT_CALLING_BUTTON_ID,
    destination: '',
    mediaList: [],
    isDraft: false,
    isChildcareRelated: true,
    isSocialRelated: false,
    duration: {},
  },
  createLoading: false,
  config: {},
  audiences: {
    data: [],
    loading: false,
    resourceParams: {
      page: 1,
      size: 10,
      total: 10,
    },
  },
  adType: {},
  callingButton: {},
  data: [],
  resourceData: {
    page: 1,
    size: 12,
    total: 0,
    sorts: [],
    filter: {},
  },
  title: [],
  loading: false,
  formConfigloading: false,
  detailLoading: false,
  currentData: {},
  hasSelectedAd: null,
};

const advertisement = createSlice({
  name: MODEL_NAME,
  initialState,
  reducers: {
    goToStep: (state, { payload: step }) => {
      if (step >= 0 && step <= CREATE_AD_FINAL_STEP_INDEX) {
        state.currentStep = step;
      }
    },
    goToNextStep: (state) => {
      const newStep = state.currentStep + 1;
      if (newStep > 0 && newStep <= CREATE_AD_FINAL_STEP_INDEX) {
        state.currentStep = newStep;
      }
    },
    goToPrevStep: (state) => {
      const newStep = state.currentStep - 1;
      if (newStep >= 0 && newStep <= CREATE_AD_FINAL_STEP_INDEX) {
        state.currentStep = newStep;
      }
    },
    setCreateAdPayload: (state, { payload }) => {
      state.createPayload = {
        ...state.createPayload,
        ...payload,
      };
    },
    resetCreateAdPayload: (state) => {
      state.currentStep = 0;
      state.createPayload = initialState.createPayload;
      state.adType = {};
    },
    gotoFirstStep: (state) => {
      state.currentStep = 0;
    },
    setSelectedAdType: (state, { payload }) => {
      state.adType = payload;
    },
    setSelectedAd: (state, { payload }) => {
      state.hasSelectedAd = payload;
    },
  },
  extraReducers(builder) {
    builder.addCase(getAdsFormConfig.pending, (state) => {
      state.formConfigloading = true;
    });
    builder.addCase(getAdsFormConfig.fulfilled, (state, { payload }) => {
      state.config = payload;
      state.formConfigloading = false;
    });
    builder.addCase(getAdsFormConfig.rejected, (state) => {
      state.formConfigloading = false;
    });
    builder.addCase(getAdAudiences.pending, (state, { meta: { arg } }) => {
      state.audiences.loading = true;

      if (arg?.options?.isRefresh) {
        state.audiences.data = [];
      }
    });
    builder.addCase(
      getAdAudiences.fulfilled,
      (
        state,
        {
          payload,
          meta,
        }: PayloadAction<
          ListResponse<AudienceItem>,
          string,
          { arg: ParamsGetList }
        >,
      ) => {
        state.audiences.data = unionBy(
          [...state.audiences.data, ...(payload?.results || [])],
          'id',
        );
        state.audiences.resourceParams = {
          ...meta.arg.data,
          total: payload?.paging?.total,
        };
        state.audiences.loading = false;
      },
    );
    builder.addCase(getAdAudiences.rejected, (state) => {
      state.audiences.loading = false;
    });
    builder.addCase(
      deleteAdAudience.fulfilled,
      (state, { payload }: PayloadAction<AudienceItem>) => {
        state.audiences.data = filter(
          state.audiences.data,
          ({ id }: AudienceItem) => id !== payload.id,
        );
      },
    );
    builder.addCase(editAdAudience.fulfilled, (state, { payload }) => {
      const editedAudienceId = findIndex(state.audiences.data, {
        id: payload?.id,
      });

      if (editedAudienceId !== -1) {
        state.audiences.data[editedAudienceId] = payload;
      }
    });
    builder.addCase(
      createAdAudience.fulfilled,
      (state, { payload }: PayloadAction<AudienceItem>) => {
        state.audiences.data = [payload, ...state.audiences.data];
      },
    );
    builder.addCase(getCallingButtonList.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getCallingButtonList.fulfilled, (state, { payload }) => {
      state.callingButton = payload;
      state.loading = false;
    });
    builder.addCase(getCallingButtonList.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(addCallingButton.fulfilled, (state, { payload }) => {
      state.callingButton = {
        ...state.callingButton,
        results: [...state.callingButton.results, payload],
      };
    });
    builder.addCase(editCallingButton.fulfilled, (state, { payload }) => {
      state.callingButton = {
        ...state.callingButton,
        results: state.callingButton.results?.map((v) => {
          if (v.id === payload.id) return payload;
          return v;
        }),
      };
    });
    builder.addCase(deleteCallingsButton.fulfilled, (state, { payload }) => {
      state.callingButton = {
        ...state.callingButton,
        results: state.callingButton.results?.filter(
          (v) => v.id !== payload.id,
        ),
      };
    });
    builder.addCase(
      getAdsList.pending,
      (
        state,
        {
          meta: {
            arg: { options = {} },
          },
        },
      ) => {
        state.loading = true;
        if (options.isRefresh) {
          state.data = [];
          state.resourceData = {
            ...initialState.resourceData,
          };
        }
      },
    );
    builder.addCase(getAdsList.fulfilled, (state, { payload: { data } }) => {
      state.data = unionBy([...state.data, ...(data?.results || [])], 'id');
      state.resourceData = data?.paging;
      state.loading = false;
    });
    builder.addCase(getAdsList.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(getAdDetail.pending, (state) => {
      state.detailLoading = true;
    });
    builder.addCase(getAdDetail.fulfilled, (state, { payload }) => {
      state.currentData = payload;
      state.detailLoading = false;
    });
    builder.addCase(getAdDetail.rejected, (state) => {
      state.detailLoading = false;
    });

    builder.addCase(closeAds.fulfilled, (state) => {
      state.currentData.deliveryStatus = CLOSE_ADS_STATUS;
    });

    builder.addCase(deleteAd.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(deleteAd.fulfilled, (state, { payload }) => {
      state.data = remove(state.data, (obj) => obj.id !== payload?.id);
      state.loading = false;
    });
  },
});

export const {
  goToNextStep,
  goToPrevStep,
  goToStep,
  setCreateAdPayload,
  resetCreateAdPayload,
  setSelectedAdType,
  setSelectedAd,
  gotoFirstStep,
} = advertisement.actions;

export default advertisement.reducer;

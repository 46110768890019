import React, { useEffect, useLayoutEffect, useMemo, useState } from 'react';
import { Route, Switch } from 'react-router-dom';

import UpgradeSubscriptionModal from 'components/common/CheckPermissions/UpgradeSubscriptionModal';
import { getQueryParams } from 'utils/tools';
import DownloadPopup from 'components/DownloadPopup';
import PrivateRoutes from 'routes/PrivateRoutes';
import NewFeatureAnnouncementModal from 'components/NewFeatureAnnouncementModal';
import { NEW_FEATURE } from 'data/NewFeatureAnnouncement/newFeatute';
import publicRoutes from './PublicRoutes';
import RoutesWrapper from './styles';
import ModalRoute from './ModalRoute';
import NotFoundPage from '../containers/404Page';
import renderPrivateRoutesWithoutLayout from './PrivateRoutesWithoutLayout';

const Routes = () => {
  const [isReady, setIsReady] = useState(false);

  useLayoutEffect(() => {
    const query = getQueryParams(window.location.search);
    if (query.authToken) {
      sessionStorage.setItem('impersonateSession', query.authToken);
      window.location.href = `${window.location.origin}/centres/board`;
    }
    setIsReady(true);
  }, []);

  useEffect(() => {
    const ele = document.getElementById('ipl-progress-indicator');
    if (ele) {
      const timeout = setTimeout(() => {
        ele.classList.add('available');
        clearTimeout(timeout);
      }, 500);
    }
  }, []);

  const content = useMemo(
    () => (
      <>
        <RoutesWrapper>
          <Switch>
            <Route exact path="/404" component={NotFoundPage} />
            {publicRoutes()}
            {renderPrivateRoutesWithoutLayout()}
            <PrivateRoutes />
          </Switch>
          <ModalRoute />
        </RoutesWrapper>
        <UpgradeSubscriptionModal />
        <NewFeatureAnnouncementModal
          newFeature={NEW_FEATURE.programsAndLearning}
        />
        <DownloadPopup />
      </>
    ),
    [],
  );

  return isReady ? content : null;
};

export default Routes;

import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  getJobApplicationMailboxApi,
  postJobApplicationMailboxApi,
} from 'api/jobApplications';
import { apiWrapper } from 'utils/reduxUtils';

export const MODEL_NAME = 'jobApplicationMailbox';

export const getJobApplicationMailbox = createAsyncThunk(
  `${MODEL_NAME}/getJobApplicationMailbox`,
  async ({ applicationId, page, size } = {}, thunkApi) => {
    try {
      const response = await apiWrapper({}, getJobApplicationMailboxApi, {
        applicationId,
        page,
        size,
      });

      return response;
    } catch (error) {
      return thunkApi.rejectWithValue({ id: applicationId, data: error });
    }
  },
);

export const postJobApplicationMailbox = createAsyncThunk(
  `${MODEL_NAME}/postJobApplicationMailbox`,
  async ({ applicationId, content, files } = {}, thunkApi) => {
    try {
      const response = await apiWrapper({}, postJobApplicationMailboxApi, {
        applicationId,
        content,
        files,
      });

      thunkApi.dispatch(
        getJobApplicationMailbox({ applicationId, size: 10, page: 1 }),
      );

      if (response) return response;
      return thunkApi.rejectWithValue(response);
    } catch (error) {
      return thunkApi.rejectWithValue({ id: applicationId, data: error });
    }
  },
);

import React from 'react';
import PropTypes from 'prop-types';
import { Upload, notification, Modal } from 'antd';
import i18next from 'i18next';
import { uploadMedia } from 'api/uploadMedia';
import { useDispatch } from 'react-redux';
import { logoutSuccess } from 'redux/auth/slice';
import { resizeImageFile } from 'utils/fileUtils';

import { ExclamationCircleFilled, PaperClipOutlined } from '@ant-design/icons';
import UploadFileMessageStyles from 'containers/Enquiries/components/UploadFileMessage/styles';
import { useFilesMessage } from 'containers/Enquiries/components/UploadFileMessage/context';
import { MAX_FILE_SIZE, UPLOAD_FILE_STATUS } from 'configs/localData/constant';
import { getS3JobSeekerUrl } from 'api/jobApplications';
import { OkButtonStyles } from './styles';

const UploadFileMessage = ({
  form,
  source,
  applicationId,
  setIsDisabledSubmit,
}) => {
  const dispatch = useDispatch();

  const { fileList, setFileList } = useFilesMessage();

  const beforeUploadFile = (file) => {
    const isValidSizeFile = file?.size / 1000 / 1000 <= MAX_FILE_SIZE;
    if (!isValidSizeFile) {
      Modal.warning({
        title: i18next.t('jobApplications.fileSizeValidation'),
        okButtonProps: { style: OkButtonStyles },
        icon: <ExclamationCircleFilled />,
      });
    }
    return isValidSizeFile || Upload.LIST_IGNORE;
  };

  const customRequest = async ({ onSuccess, onError, file }) => {
    const compressedFile = await resizeImageFile(file);

    if (compressedFile) {
      try {
        const responseS3 = await getS3JobSeekerUrl(
          applicationId,
          compressedFile.name,
        );
        const response = await uploadMedia(
          responseS3.uploadUrl,
          compressedFile,
        );
        if (response?.status === 200) {
          onSuccess(responseS3.url, compressedFile);
        } else {
          onError(null, { status: UPLOAD_FILE_STATUS.done });
        }
      } catch (error) {
        onError(null, { status: UPLOAD_FILE_STATUS.done });
        if (error.code === 401) {
          dispatch(logoutSuccess());
          notification.error({
            message: i18next.t('error.title'),
            description: i18next.t('error.error401'),
            duration: 2,
            position: 'tr',
          });
        } else
          notification.error({
            message: i18next.t('error.title'),
            description: i18next.t('error.description'),
            position: 'tr',
            duration: 2,
          });
      }
    } else onError(null, { status: UPLOAD_FILE_STATUS.done });
  };

  const setFieldAfterUploadChange = (results) => {
    const formattedData = results?.map((data) => ({
      name: data.name,
      mimeType: data.type,
      url: data.response || data.url,
      status: data.status,
    }));

    form &&
      form.setFieldsValue({
        [source]: formattedData,
      });
  };

  const onChangeUpload = (e) => {
    setFileList(e.fileList);
    isDisableButtonSubmit(e.fileList);
    setFieldAfterUploadChange(e.fileList);
  };

  const isDisableButtonSubmit = (newFileList) => {
    const isDisabled =
      newFileList &&
      newFileList.length >= 0 &&
      newFileList?.filter((i) => {
        if (i.id !== undefined) return false;
        return i.response === undefined;
      }).length > 0;

    setIsDisabledSubmit(isDisabled);
  };

  return (
    <UploadFileMessageStyles>
      <Upload
        multiple
        customRequest={customRequest}
        onChange={onChangeUpload}
        showUploadList={false}
        fileList={fileList}
        beforeUpload={beforeUploadFile}
      >
        <PaperClipOutlined className="mt-20 text-green text-24 cursor-pointer" />
      </Upload>
    </UploadFileMessageStyles>
  );
};

UploadFileMessage.propTypes = {
  form: PropTypes.object,
  source: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  applicationId: PropTypes.string,
  setIsDisabledSubmit: PropTypes.func,
};

export default UploadFileMessage;

import { UseQuery } from '@reduxjs/toolkit/dist/query/react/buildHooks';
import {
  AutoComplete,
  AutoCompleteProps,
  Empty,
  Form,
  FormItemProps,
  Spin,
  Tooltip,
} from 'antd';
import { NamePath } from 'antd/lib/form/interface';
import useRTKQueryInfiniteLoad from 'hooks/useRTKQueryInfiniteLoad';
import i18next from 'i18next';
import { isString, uniqBy } from 'lodash-es';
import React, { useState } from 'react';
import { convertDataToSelectOptions } from 'utils/tools';

export interface Props<TRecord> extends AutoCompleteProps {
  useQuery: UseQuery<any>;
  queryParams?: object;
  skip?: boolean;
  skipUntilFocus?: boolean;
  initValues?: TRecord[];
  labelProp?: string;
  valueProp?: string;
  uniqueProp?: keyof TRecord;
  searchKey?: string;
  isShowTooltip?: boolean;
  name: NamePath;
  formItemProps?: FormItemProps;
  className?: string;
  convertDataToOptions?: (data: TRecord[]) => {
    value: string | number | null;
    label: string | React.ReactNode;
  }[];
}

function InfiniteAutoComplete<TRecord>({
  useQuery,
  convertDataToOptions,
  labelProp = 'name',
  valueProp = 'id',
  uniqueProp = null,
  searchKey = 'searchKey',
  initValues = [],
  queryParams,
  isShowTooltip = true,
  className,
  formItemProps,
  name,
  skip,
  skipUntilFocus = true,
  ...rest
}: Props<TRecord>) {
  const [isFocused, setIsFocused] = useState(false);

  const skipApiCondition = skipUntilFocus ? !isFocused : skip;

  const { data, isFetching, loadMore, onSearch, refresh } =
    useRTKQueryInfiniteLoad<TRecord>({
      useQuery,
      searchKey,
      queryParams,
      skip: skipApiCondition,
    });

  const items = uniqBy([...initValues, ...data], uniqueProp);

  const formattedData = convertDataToOptions
    ? convertDataToOptions(items)
    : convertDataToSelectOptions(items, labelProp, valueProp);

  const Spinning = <Spin className="mx-12 my-4" />;

  const options = isFetching
    ? [
        ...formattedData,
        {
          value: null,
          label: Spinning,
        },
      ]
    : formattedData;

  const onFocus = () => {
    if (!isFocused) {
      setIsFocused(true);
    }
  };

  const content = (
    <Form.Item name={name} onReset={refresh} {...formItemProps}>
      <AutoComplete
        allowClear
        filterOption={false}
        options={options}
        optionFilterProp="label"
        onPopupScroll={loadMore}
        onSearch={onSearch}
        onFocus={onFocus}
        notFoundContent={isFetching ? Spinning : <Empty />}
        className={`w-full ${className ?? ''}`}
        {...rest}
      />
    </Form.Item>
  );

  if (isShowTooltip && isString(rest?.placeholder)) {
    return (
      <Tooltip placement="top" title={i18next.t(rest.placeholder)}>
        {content}
      </Tooltip>
    );
  }

  return content;
}

export default InfiniteAutoComplete;

import { createAsyncThunk, createAction } from '@reduxjs/toolkit';
import { apiWrapper } from 'utils/reduxUtils';
import { getAllApi, getDataByIdApi, postApi, putApi, delApi } from 'api/crud';
import { omit } from 'lodash-es';
import {
  convertRequestParams,
  convertResponseData,
  PRIMARY_KEY,
} from './dataProvider';

export const getAll = (resource) =>
  createAsyncThunk(`${resource}/getAll`, async (payload, thunkAPI) => {
    try {
      const { options = {} } = payload;

      const { limit, offset, includes, filter, outsideFilter } =
        thunkAPI.getState()[resource];

      const { outsideFilter: outsideFilterPayload, ...data } =
        payload.data || {};

      const convertRequest = convertRequestParams(
        'GET_ALL',
        {
          limit,
          offset,
          filter,
          includes,
          ...outsideFilter,
          ...outsideFilterPayload,
          ...data,
        },
        resource,
        options,
      );
      const response = await apiWrapper(
        { isShowProgress: options.isShowProgress },
        getAllApi,
        options.customApiResource || `${resource}`,
        convertRequest,
      );

      const result = convertResponseData('GET_ALL', response, options);
      if (result.data) {
        return {
          data: {
            numberOfPages: Math.ceil(result.total / limit),
            ...result,
          },
          options,
        };
      }
      return thunkAPI.rejectWithValue({ data: response, options });
    } catch (error) {
      return thunkAPI.rejectWithValue({});
    }
  });

export const getDataById = (resource) =>
  createAsyncThunk(`${resource}/getDataById`, async (payload, thunkAPI) => {
    const { data, options = { isRequestApi: true } } = payload;
    try {
      if (!options.isRequestApi) {
        return { data, options: { isRequestApi: false } };
      }

      const response = await apiWrapper(
        { isShowProgress: options.isShowProgress },
        getDataByIdApi,
        options.customApiResource || `${resource}`,
        data[PRIMARY_KEY],
        omit(data, [PRIMARY_KEY]),
        options.prefixUrl,
        options.suffixUrl,
      );
      const result = convertResponseData('GET_BY_ID', response);
      if (result) {
        return { data: result, options, id: data[PRIMARY_KEY] };
      }
      return thunkAPI.rejectWithValue({
        data: result,
        options,
        id: data[PRIMARY_KEY],
      });
    } catch (error) {
      return thunkAPI.rejectWithValue({
        data: error,
        options,
        id: data[PRIMARY_KEY],
      });
    }
  });

export const edit = (resource) =>
  createAsyncThunk(`${resource}/edit`, async (payload, thunkAPI) => {
    const { data, options = {} } = payload;
    try {
      const convertRequest = convertRequestParams('EDIT', data, resource, {
        isTrimStr: options.isTrimStr,
      });
      const response = await apiWrapper(
        {
          isShowSuccessNoti: true,
          isCheckError: true,
          ...options,
        },
        putApi,
        options.customApiResource || `${resource}`,
        options.suffixResourceApi,
        data[PRIMARY_KEY],
        convertRequest,
        options.customParamsHeader,
      );
      const result = convertResponseData('EDIT', response);
      if (result) {
        if (options.isGetAll) {
          thunkAPI.dispatch(
            getAll(resource)({
              data: { offset: 0 },
              options: { isRefresh: true },
            }),
          );
        }
        return { data: { ...data, ...result }, id: data[PRIMARY_KEY], options };
      }
      return thunkAPI.rejectWithValue({
        id: data[PRIMARY_KEY],
        data: response,
      });
    } catch (error) {
      return thunkAPI.rejectWithValue({ id: data[PRIMARY_KEY], data: error });
    }
  });

export const create = (resource) =>
  createAsyncThunk(`${resource}/create`, async (payload, thunkAPI) => {
    const { data, options = {} } = payload;
    try {
      const convertRequest = convertRequestParams('CREATE', data, resource, {
        isTrimStr: options.isTrimStr,
      });
      const response = await apiWrapper(
        {
          isShowSuccessNoti: true,
          isCheckError: true,
          ...options,
        },
        postApi,
        options.customApiResource || `${resource}`,
        convertRequest,
        options.customParamsHeader,
      );
      const result = convertResponseData('CREATE', response);
      if (result) {
        if (options.isGetAll) {
          thunkAPI.dispatch(
            getAll(resource)({
              data: { offset: 0 },
              options: { isRefresh: true },
            }),
          );
        }
        return { data: result, options };
      }
      return thunkAPI.rejectWithValue({ data: response });
    } catch (error) {
      return thunkAPI.rejectWithValue({ data: error });
    }
  });

export const del = (resource) =>
  createAsyncThunk(`${resource}/del`, async (payload, thunkAPI) => {
    const { data, options = {} } = payload;
    try {
      const response = await apiWrapper(
        { isShowProgress: true },
        delApi,
        options.customApiResource || `${resource}`,
        data.path || data[PRIMARY_KEY],
      );
      const result = convertResponseData('DELETE', response);

      if (result.success) {
        return { data };
      }

      return thunkAPI.rejectWithValue({
        id: data[PRIMARY_KEY],
        data: response,
      });
    } catch (error) {
      return thunkAPI.rejectWithValue({ id: data[PRIMARY_KEY], data: error });
    }
  });

export const clearCurrent = (resource) =>
  createAction(`${resource}/clearCurrent`);

export const setIsDisabledButtonSubmit = (resource) =>
  createAction(`${resource}/setIsDisabledButtonSubmit`);

export const clearList = (resource) => createAction(`${resource}/clearList`);

export const makeActions = (resource) => ({
  getAll: getAll(resource),
  getDataById: getDataById(resource),
  edit: edit(resource),
  create: create(resource),
  del: del(resource),
  clearCurrent: clearCurrent(resource),
  clearList: clearList(resource),
  setIsDisabledButtonSubmit: setIsDisabledButtonSubmit(resource),
});

import styled from 'styled-components';

export const FilterContainer = styled.div`
  margin-bottom: 12px;

  .ant-form-item {
    margin-bottom: 0;
  }

  .ant-select {
    width: 100%;
  }

  .form-select-checkbox {
    .ant-select-selection-overflow-item-rest .ant-select-selection-item {
      max-width: 52px;
    }
    .ant-select-selection-item {
      max-width: 84px;
    }
    .ant-select-selection-item-content > div {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }

    @media (max-width: 1536px) {
      .ant-select-selection-item {
        max-width: 46px;
      }
    }
  }

  .ant-row.row-filter {
    row-gap: 16px !important;
  }
`;

import EditCustomBtn from 'components/RestActions/EditCustomBtn';
import React, { useRef } from 'react';
import { Divider } from 'antd';
import { useSelector } from 'react-redux';
import { getCompanyProfileSelector } from 'redux/jobs/companyProfile/selectors';
import useRolesCompanyProfile from 'containers/CompanyProfile/hooks/useRolesCompanyProfile';
import CompanyGeneralInfoStyles from './styles';
import CompanyImageInfo from './CompanyImageInfo';
import GeneralInfo from './GeneralInfo';
import JobContactInfo from './JobContactInfo';
import CompanyProfileGeneralInfoModal from '../Modals/CompanyProfileGeneralInfoModal';

const CompanyGeneralInfo = () => {
  const { data } = useSelector(getCompanyProfileSelector);

  const modalRef = useRef();

  const onClickButton = () => {
    modalRef.current && modalRef.current.open(data);
  };

  const disabledAction = useRolesCompanyProfile();

  return (
    <CompanyGeneralInfoStyles>
      <EditCustomBtn
        action="isEdit"
        onClickButton={onClickButton}
        disabled={disabledAction}
      />
      <CompanyImageInfo logo={data?.logo} />
      {data?.type && (
        <div className="w-full flex-center mb-20">
          <span className="company-type">{data?.type?.name}</span>
        </div>
      )}
      <div className="company-name-style">{data?.name}</div>
      <div className="content-info-container">
        <GeneralInfo record={data} />
        <Divider />
        <JobContactInfo record={data} />
      </div>
      <CompanyProfileGeneralInfoModal ref={modalRef} record={data} />
    </CompanyGeneralInfoStyles>
  );
};

export default CompanyGeneralInfo;

import PropTypes from 'prop-types';
import { Tooltip } from 'antd';
import TableCustom from 'components/common/TableCustom';
import {
  SORT_ASCEND_VALUE,
  SORT_DESCEND_VALUE,
} from 'configs/localData/constant';
import {
  DEFAULT_CURRENT_PAGE,
  DEFAULT_SORT_JOB_TEMPLATE,
  LIMIT_ITEM,
} from 'containers/JobListings/constants';
import i18next from 'i18next';
import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { getJobListingViewByJobTemplate } from 'redux/jobs/jobListings/selectors';
import { getFilterFromUrl } from 'utils/tools';
import useFormatFnc from '../../../useFormat';
import { JobTemplateTableStyle } from './styles';

function JobTemplateTable({
  jobTemplateData,
  retrieveList,
  pushQuery,
  loading,
}) {
  const useFormat = useFormatFnc();
  const { search } = useLocation();
  const { filter } = getFilterFromUrl(search);
  const { resourceData } = useSelector(getJobListingViewByJobTemplate) || {};

  const getSorterOrder = useCallback(
    (source) => {
      if (filter.sorts?.[0] === source) return SORT_ASCEND_VALUE;
      if (filter.sorts?.[0] === `-${source}`) return SORT_DESCEND_VALUE;
      return '';
    },
    [filter],
  );

  const columns = [
    {
      title: i18next.t('jobListings.jobTemplate'),
      dataIndex: 'templateName',
      width: 120,
      sorter: true,
      render: (data, record) => useFormat.formatJobTemplateName(data, record),
      defaultSortOrder: getSorterOrder('templateName'),
      fixed: 'left',
    },
    {
      title: i18next.t('jobListings.jobTitle'),
      dataIndex: 'jobTitle',
      width: 120,
      sorter: true,
      render: (data) => (
        <Tooltip title={data}>
          <span className="line-clamp-2">{data}</span>
        </Tooltip>
      ),
      defaultSortOrder: getSorterOrder('jobTitle'),
    },
    {
      title: i18next.t('jobListings.jobRole'),
      dataIndex: 'jobRole',
      width: 120,
      sorter: true,
      render: (data) => <Tooltip title={data}>{data}</Tooltip>,
      defaultSortOrder: getSorterOrder('jobRole'),
    },
    {
      title: i18next.t('jobListings.jobType'),
      dataIndex: 'jobType',
      width: 150,
      sorter: true,
      render: (data) => useFormat.formatJobType(data),
      defaultSortOrder: getSorterOrder('jobType'),
    },
    {
      title: `# ${i18next.t('jobListings.jobListings')}`,
      dataIndex: 'jobListings',
      width: 80,
      sorter: true,
      render: (data) => <span className="ellipsis-t-2">{data}</span>,
      defaultSortOrder: getSorterOrder('jobListings'),
    },
    {
      title: `# ${i18next.t('jobListings.workplaces')}`,
      dataIndex: 'workplaces',
      width: 80,
      sorter: true,
      render: (data) => <span className="ellipsis-t-2">{data}</span>,
      defaultSortOrder: getSorterOrder('workplaces'),
    },
    {
      title: `# ${i18next.t('jobListings.applications')}`,
      dataIndex: 'applications',
      width: 80,
      render: (data, record) =>
        useFormat.formatJobTemplateApplicationAction(data, record),
      sorter: true,
      defaultSortOrder: getSorterOrder('applications'),
    },
    {
      title: '',
      dataIndex: 'templateName',
      width: 50,
      fixed: 'right',
      render: (data, record) => useFormat.formatGroupAction(data, record),
    },
  ];

  const onChange = (e, filters, sorter) => {
    const formatSort =
      sorter && sorter.field && sorter.order
        ? `${sorter.order === SORT_DESCEND_VALUE ? '-' : ''}${sorter.field}`
        : null;

    const restFilter = {
      size: e.pageSize,
      page: e.current,
      sorts: [formatSort || DEFAULT_SORT_JOB_TEMPLATE],
      filter: { ...filter.filter },
    };

    retrieveList({
      filter: restFilter,
      isRefresh: true,
      keepFilter: true,
    });
    pushQuery({
      'sorts[]': restFilter.sorts,
      ...restFilter,
    });
  };

  return (
    <JobTemplateTableStyle>
      <TableCustom
        xScroll={1800}
        columns={columns}
        data={jobTemplateData}
        loading={loading}
        onChange={onChange}
        pagination={{
          pageSize: filter?.size || LIMIT_ITEM,
          current: filter?.page || DEFAULT_CURRENT_PAGE,
          total: resourceData?.total,
        }}
      />
    </JobTemplateTableStyle>
  );
}

JobTemplateTable.propTypes = {
  jobTemplateData: PropTypes.array,
  loading: PropTypes.bool,
  pushQuery: PropTypes.func,
  retrieveList: PropTypes.func,
};

export default JobTemplateTable;

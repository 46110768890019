import React, { useEffect, useContext } from 'react';
import { getFilterFromUrl } from 'utils/tools';
import { useLocation } from 'react-router';
import Row from 'antd/lib/row';
import Col from 'antd/lib/col';
import { useSelector } from 'react-redux';

import { LABEL_TABLE_KEY, PREFIX_URL } from 'configs/localData/constant';
import { getSubscriptionsArr } from 'redux/config/selectors';
import { getCentreId } from 'redux/auth/selectors';

import { RestInputContext } from 'components/RestInput/RestInputContext';
import RestSelect from 'components/RestInput/RestSelect';
import RestInputItem from 'components/RestInput/RestInputItem';
import ReferenceInput from 'containers/rest/ReferenceInput';
import { formatLabelTable } from '../CentreTabs/ServicesTab/utils';

const CentreFilterForm = () => {
  const { search } = useLocation();
  const { form } = useContext(RestInputContext);

  const serviceTypes = useSelector((state) => state.config.data?.ServiceType);

  const NQSRating = useSelector((state) => state.config.data?.NQSRType);

  const subscriptionsArr = useSelector(getSubscriptionsArr);

  const stateOfAustralia = useSelector(
    (state) => state.config.data?.stateOfAustralia,
  );

  const centreId = useSelector(getCentreId);

  useEffect(() => {
    const filter = getFilterFromUrl(search) || {};

    const { serviceTypeId, ...restFilter } = filter?.filter || {};

    form.setFieldsValue({
      ...restFilter,
      serviceTypeId: serviceTypeId?.$in?.[0],
    });
  }, [form, search]);

  const appSettings = useSelector((state) => state.config?.data?.AppSetting);

  return (
    <Row gutter={16} type="flex">
      <Col xl={6} md={12} xs={24}>
        <RestInputItem source="name" placeholder="centres.name" />
      </Col>
      <Col xl={6} md={12} xs={12}>
        <ReferenceInput
          prefixUrl={PREFIX_URL.admin}
          resource="users/me/companies/basic-info"
          source="companyId"
          searchKey="name"
          notLikeFilter
          initialFilter={{ orderBy: 'name' }}
        >
          <RestSelect
            titleProp="name"
            valueProp="id"
            placeholder="centres.companyTitle"
            isFilterOption={false}
          />
        </ReferenceInput>
      </Col>
      <Col xl={6} md={12} xs={12}>
        <ReferenceInput
          prefixUrl={PREFIX_URL.admin}
          resource="users/me/providers/basic-info"
          source="providerId"
          searchKey="name"
          notLikeFilter
          initialFilter={{ orderBy: 'name' }}
        >
          <RestSelect
            titleProp="name"
            valueProp="id"
            placeholder="input.provider.name"
            isFilterOption={false}
          />
        </ReferenceInput>
      </Col>
      <Col xl={6} md={12} xs={12}>
        <ReferenceInput
          prefixUrl={PREFIX_URL.admin}
          source="brandId"
          resource="brands"
          searchKey="brand.name"
          dependency={centreId}
          initialFilter={{ orderBy: 'name' }}
        >
          <RestSelect
            titleProp="name"
            valueProp="id"
            placeholder="centres.brandTitle"
            isFilterOption={false}
          />
        </ReferenceInput>
      </Col>
      <Col xl={6} md={12} xs={12}>
        <RestInputItem
          source="governmentReferenceId"
          header="centres.PRNumber"
          placeholder="centres.PRNumber"
        />
      </Col>

      <Col xl={6} md={12} xs={12}>
        <RestSelect
          source="serviceTypeId"
          placeholder={formatLabelTable(
            LABEL_TABLE_KEY.serviceType,
            appSettings,
          )}
          resourceData={serviceTypes || []}
          valueProp="id"
          titleProp="name"
          isShowTooltip
        />
      </Col>
      <Col xl={6} md={12} xs={12}>
        <RestSelect
          source="state"
          placeholder="centres.state"
          resourceData={stateOfAustralia || []}
          isShowTooltip
        />
      </Col>
      <Col xl={6} md={12} xs={12}>
        <RestSelect
          source="NQSROverallTypeId"
          placeholder="centres.NQSRating"
          resourceData={NQSRating || []}
          valueProp="id"
          titleProp="name"
          isShowTooltip
        />
      </Col>
      <Col xl={6} md={12} xs={12}>
        <RestSelect
          source="CRMPackageId"
          placeholder="centres.subscription"
          resourceData={subscriptionsArr}
          valueProp="id"
          titleProp="name"
          isShowTooltip
        />
      </Col>
    </Row>
  );
};

export default CentreFilterForm;

import { makeCRUDSlice } from 'redux/crudCreator';
import { findIndex } from 'lodash-es';
import { ENABLE_TYPES_CONST } from 'configs/localData/constant';
import {
  enableAllNotificationAndAlert,
  enableOneCentreWhenMultiCentreNotificationAndAlert,
  enableOneNotificationAndAlert,
  getByIdNotificationAlert,
  MODEL_NAME,
  notificationsAndAlertsActions,
} from './actions';

const getByIdNotificationAlertSuccess = (state, { payload }) => {
  if (payload?.id) {
    state.data[payload?.id] = payload;
  }
};

const enableAllNotificationAndAlertSuccess = (state, { meta }) => {
  state.data[meta.arg?.key].data.status = meta.arg.data?.status;

  state.data[meta.arg?.key].centreAppSettings = state.data[
    meta.arg?.key
  ].centreAppSettings?.map((item) => ({
    ...item,
    data: { status: meta.arg.data?.status },
  }));
};

const enableOneNotificationAndAlertSuccess = (state, { meta }) => {
  state.data[meta.arg?.key].data.status = meta.arg.data?.status;
};

const enableOneCentreWhenMultiCentreNotificationAndAlertSuccess = (
  state,
  { payload, meta },
) => {
  const matchedIdx = findIndex(state.data?.[meta.arg?.key]?.centreAppSettings, [
    'centre.id',
    meta.arg.data?.centreIds?.[0],
  ]);

  if (matchedIdx !== -1) {
    state.data[meta.arg?.key].centreAppSettings[matchedIdx].data.status =
      meta.arg.data.status;
  }

  let groupStatus = ENABLE_TYPES_CONST.active.value;

  state.data[meta.arg?.key].centreAppSettings.some((item) => {
    if (item.data?.status === ENABLE_TYPES_CONST.inactive.value) {
      groupStatus = ENABLE_TYPES_CONST.inactive.value;

      return true;
    }

    return false;
  });

  state.data[payload?.data?.[0]?.key].data.status = groupStatus;
};

const slice = makeCRUDSlice(MODEL_NAME, notificationsAndAlertsActions, {
  [getByIdNotificationAlert.fulfilled]: getByIdNotificationAlertSuccess,

  [enableAllNotificationAndAlert.fulfilled]:
    enableAllNotificationAndAlertSuccess,

  [enableOneNotificationAndAlert.fulfilled]:
    enableOneNotificationAndAlertSuccess,

  [enableOneCentreWhenMultiCentreNotificationAndAlert.fulfilled]:
    enableOneCentreWhenMultiCentreNotificationAndAlertSuccess,
});

export default slice.reducer;

import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { Form, Skeleton } from 'antd';
import { useSelector } from 'react-redux';
import { isEmpty } from 'lodash-es';
import { getDetailAdsLoadingSelector } from 'redux/advertisement/selectors';
import { Moment } from 'moment';
import AdModal from './index';
import DateTimeDurationForm from '../../Create/Steps/Duration/DateTimeDurationForm';

interface DurationModalProps {
  initialValues?: {
    durationDays?: number;
    durationHours?: number;
    endTime?: Moment | string;
    startTime?: Moment | string;
    durationTime?: string;
  };
}

const DurationModal = forwardRef(
  ({ initialValues }: DurationModalProps, ref) => {
    const [form] = Form.useForm();
    const [visible, setVisible] = useState<boolean>(false);
    const loading = useSelector(getDetailAdsLoadingSelector);

    const handleCancel = () => {
      setVisible(false);
      form.resetFields();
    };

    useImperativeHandle(
      ref,
      () => ({
        toggleModal: () => {
          setVisible(true);
        },
      }),
      [],
    );

    return (
      <AdModal
        visible={visible}
        onCancel={handleCancel}
        title="advertisement.duration"
        form={form}
        width={800}
        hasConvert
      >
        <Skeleton loading={loading && !isEmpty(initialValues)}>
          <DateTimeDurationForm form={form} initialValues={initialValues} />
        </Skeleton>
      </AdModal>
    );
  },
);

export default DurationModal;

import { get } from './utils';

export async function getApplicationsDashboardApi() {
  return get('/admin/dashboards/applications');
}

export async function getCentresDashboardApi() {
  return get('/admin/dashboards/centres');
}

export async function getActivitiesDashboardApi() {
  return get('/admin/dashboards/activities');
}

export async function getEnquiriesDashboardApi() {
  return get('/admin/dashboards/enquiries');
}

export async function getTasksOverdueApi(data) {
  return get('/admin/dashboards/tasks/overdue', data);
}

export async function getTasksUpcomingApi(data) {
  return get('/admin/dashboards/tasks/upcoming', data);
}

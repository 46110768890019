import React, { useCallback } from 'react';
import i18next from 'i18next';
import { useHistory, useLocation, useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import PageTitle from 'components/common/PageTitle';
import { getAllPriceBenchmarking } from 'redux/priceBenchmarking/actions';
import {
  getAverageDataPriceBenchmarking,
  getDataPriceBenchmarking,
  getDataPriceBenchmarkingSummary,
  getResourceDataSelector,
  priceBenchmarkingSelectors,
} from 'redux/priceBenchmarking/selectors';
import { isEmpty, omit } from 'lodash-es';
import { convertSearchUrl, getFilterFromUrl } from 'utils/tools';
import EmptyData from 'components/common/EmptyData';
import { Spin } from 'antd';
import { MAX_LIMIT_LIST } from 'configs/localData/constant';
import PriceBenchmarkingTable from './components/PriceBenchmarkingTable';
import ViewModeSwitcher from './components/ViewModeSwitcher';
import Summaries from './components/Summaries';
import Filter from './components/Filter';
import MapView from './components/MapView';
import { handleLoadMapViewData } from './utils';

const PriceBenchmarkingContainer = () => {
  const { model } = useParams();
  const { push } = useHistory();
  const { search } = useLocation();
  const dispatch = useDispatch();

  const activeTab = model || 'list';

  const dataSource = useSelector(getDataPriceBenchmarking);
  const { filter } = getFilterFromUrl(search);
  const loading = useSelector(priceBenchmarkingSelectors.getLoading);
  const allAverageData = useSelector(getAverageDataPriceBenchmarking);
  const resourceFilter = useSelector(getResourceDataSelector) || {};
  const { loading: isSummaryLoading } = useSelector(
    getDataPriceBenchmarkingSummary,
  );

  const retrieveList = useCallback(
    ({ filter = {}, isRefresh, isSort }) =>
      dispatch(
        getAllPriceBenchmarking({
          data: {
            ...filter,
          },
          options: {
            isRefresh,
            isSort,
            customApiResource: 'insights/price-benchmarking',
          },
        }),
      ),
    [dispatch],
  );

  const onChange = (e) => {
    const restFilter = omit(filter, ['displayAddress', 'addressL1']);

    push({
      pathname: `/priceBenchmarking/${e.target.value}`,
      search: `${search}`,
    });
    if (e.target.value === 'list') {
      retrieveList({
        filter: {
          outsideFilter: {
            ...restFilter,
            limit: MAX_LIMIT_LIST,
            offset: 0,
            orderBy: 'centreName',
          },
        },
        isRefresh: true,
      });
      push({
        search: `?${convertSearchUrl({
          ...restFilter,
          limit: MAX_LIMIT_LIST,
          displayAddress: filter?.displayAddress,
          addressL1: filter?.addressL1,
          offset: 0,
        })}`,
      });
    } else {
      handleLoadMapViewData(0, restFilter, resourceFilter?.total, retrieveList);
    }
  };

  return (
    <div>
      <PageTitle>
        {`${i18next.t('sideBar.insights.title')} - ${i18next.t(
          'sideBar.insights.priceBenchmarking',
        )}`}
      </PageTitle>
      <Filter retrieveList={retrieveList} />

      {!isEmpty(filter) ? (
        <div>
          <ViewModeSwitcher onChange={onChange} activeTab={activeTab} />
          <Spin spinning={isSummaryLoading}>
            <Summaries resultFilter={filter} />
          </Spin>

          <Spin spinning={loading && isEmpty(dataSource)}>
            {activeTab === 'list' ? (
              <PriceBenchmarkingTable
                dataSource={dataSource}
                retrieveList={retrieveList}
              />
            ) : (
              <MapView
                data={dataSource}
                allAverageData={allAverageData}
                height="80vh"
                retrieveList={retrieveList}
              />
            )}
          </Spin>
        </div>
      ) : (
        <EmptyData className="mt-20" />
      )}
    </div>
  );
};

export default PriceBenchmarkingContainer;

import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'antd';

import CentreDescription from './CentreDescription';
import CentrePhoto from '../SummaryTab/CentrePhoto';
import AdditionalDetails from './AdditionalDetails';
import CustomiseButtons from './CustomiseButtons';
import MoreInformation from './MoreInformation';
import CentreInfoTabStyles from './styles';

const CentreInformationTab = ({ record }) => (
  <CentreInfoTabStyles>
    <Row gutter={[24, 24]} className="row-centre-info-tab">
      <Col xl={16} lg={24} md={24} xs={24}>
        <CentreDescription shortDescription={record.shortDescription} />
      </Col>
      <Col xl={8} lg={24} md={24} xs={24}>
        <Row gutter={[24, 24]} className="row-right-centre-info">
          <Col xl={24} lg={24} md={24} xs={24}>
            <CentrePhoto images={record.images} picsShowing={4} />
          </Col>
          <Col xl={24} lg={24} md={24} xs={24}>
            <AdditionalDetails
              adminEmail={record.adminEmail}
              region={record.region}
              LGA={record.LGA}
            />
          </Col>
          <Col xl={24} lg={24} md={24} xs={24}>
            <CustomiseButtons />
          </Col>
          <Col xl={24} lg={24} md={24} xs={24}>
            <MoreInformation
              moreInformation={record.moreInformation}
              centreId={record.id}
            />
          </Col>
        </Row>
      </Col>
    </Row>
  </CentreInfoTabStyles>
);

CentreInformationTab.propTypes = {
  record: PropTypes.object,
};

CentreInformationTab.defaultProps = {
  record: {},
};

export default CentreInformationTab;

import React from 'react';
import { Row, Spin } from 'antd';
import InfiniteScroll from 'react-infinite-scroller';

interface Props {
  children: React.ReactNode;
  handleViewMore: () => void;
  loading?: boolean;
  enableLoadMore?: boolean;
}

const InfiniteScrollData = ({
  children,
  handleViewMore,
  loading,
  enableLoadMore,
}: Props) => (
  <InfiniteScroll
    pageStart={0}
    loadMore={handleViewMore}
    hasMore={loading || enableLoadMore}
    useWindow={false}
    loader={
      <Row justify="center">
        <Spin spinning={loading} />
      </Row>
    }
  >
    {children}
  </InfiniteScroll>
);

export default InfiniteScrollData;

import React from 'react';
import i18next from 'i18next';
import PropTypes from 'prop-types';
import LegendStyles from './styles';

const LegendChart = ({ payload, customExtra }) => (
  <LegendStyles>
    {customExtra && <div className="custom-extra">{customExtra}</div>}
    {payload?.map((entry, index) => (
      <div key={String(index)} className="legend-item">
        <div
          className="dot"
          style={{ background: entry.color || entry.payload.stroke }}
        />
        <span className="text-legend">
          {i18next.t(entry.payload.text) || entry.value}
        </span>
      </div>
    ))}
  </LegendStyles>
);

LegendChart.propTypes = {
  payload: PropTypes.array,
  customExtra: PropTypes.any,
};

LegendChart.defaultProps = {};

export default LegendChart;

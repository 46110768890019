import { makeActions } from 'redux/crudCreator';
import { getPriceBenchmarkingSummaryApi } from 'api/insights';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { apiWrapper } from 'utils/reduxUtils';

export const MODEL_NAME = 'priceBenchmarking';
export const priceBenchmarkingActions = makeActions(MODEL_NAME);

export const getAllPriceBenchmarking = priceBenchmarkingActions.getAll;

export const getPriceBenchmarkingSummary = createAsyncThunk(
  `${MODEL_NAME}/getSummary`,
  async (payload, thunkAPI) => {
    try {
      const response = await apiWrapper(
        {},
        getPriceBenchmarkingSummaryApi,
        payload,
      );
      return { data: response };
    } catch (error) {
      return thunkAPI.rejectWithValue({ data: error });
    }
  },
);

import styled from 'styled-components';

export const JobApplicationInfoStyles = styled.div`
  background: white;
  height: 100%;
  border-radius: 8px;
  padding: 24px;
  line-height: 35px;
  .avatar-style {
    padding: 36px 0 20px 0;
  }
  .edit-custom-btn {
    position: absolute;
    top: 10px;
    right: 24px;
  }

  .divider-info {
    width: 100%;
    height: 1px;
    background-color: ${({ theme }) => theme.boardColors.N30};
    margin: 15px 0px;
  }

  .row-info-application {
    font-size: 16px;
    & > .ant-col {
      margin-bottom: 15px;
    }
    .title-item-col {
      display: flex;
      > div {
        margin: auto 0;
        &:after {
          content: ':';
        }
      }
    }
    .value-item-col {
      font-weight: 600;
    }
  }
`;

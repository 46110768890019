import React from 'react';
import { useSelector } from 'react-redux';
import { TotalCentresIcon } from 'components/common/SVGIcon';

import SummaryView from 'components/common/SummaryCardDetail/SummaryView';

const SummaryCentres = () => {
  const summaries =
    useSelector((state) => state.dashboard.centres.data?.totalCentresItem) ||
    {};

  const data = [
    {
      title: 'centres.summaries.totalPlaces',
      value: summaries.totalPlaces,
    },
    {
      title: 'centres.enrolmentCoverage',
      value: summaries.enrolmentPipelineCoverage * 100,
      isPercent: true,
    },
    {
      title: 'centres.summaries.averageEnquiryResponseTime',
      value: summaries.averageEnquiriesResponseTime,
      isTime: true,
    },
  ];

  return (
    <SummaryView
      title="centres.summaries.totalCentres"
      data={data}
      IconComponent={TotalCentresIcon}
      color="#DB147F"
      total={summaries.totalCentres}
    />
  );
};

export default SummaryCentres;

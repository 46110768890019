import React from 'react';
import I18n from 'i18next';
import { Input } from 'antd';
import { FormItemProps } from 'antd/lib/form/FormItem';
import { Rule, RuleType } from 'rc-field-form/lib/interface';
import { FormItemWrapper } from './styles';

interface FormItemUIProps extends FormItemProps {
  header?: string;
  required?: boolean;
  requiredMessage?: string;
  defaultValue?: unknown;
  ruleType?: string;
  rules?: Rule[];
  children?: React.ReactElement;
  valuePropName?: string;
  className?: string;
  disabled?: boolean;
  label?: string;
  formOptions?: {
    [key: string]: unknown;
  };
  [key: string]: unknown;
}

const FormItemUI = ({
  header,
  required = false,
  requiredMessage = 'error.required',
  defaultValue,
  ruleType,
  rules = [],
  children = <Input />,
  valuePropName = 'value',
  className,
  disabled = false,
  label,
  formOptions = {},
  ...props
}: FormItemUIProps) => {
  const getRules = (): Rule[] => {
    if (ruleType !== undefined) {
      return [
        { required, message: I18n.t(requiredMessage) },
        {
          type: ruleType as RuleType,
          message: `${I18n.t('error.validateType')} ${I18n.t(
            ruleType || 'ruleType.string',
          )}`,
        },
        ...rules,
      ];
    }
    return [{ required, message: I18n.t(requiredMessage) }, ...rules];
  };

  return (
    <FormItemWrapper
      className={className}
      label={I18n.t(label || header)}
      {...props}
      valuePropName={valuePropName}
      rules={getRules()}
      normalize={(value) =>
        ruleType === 'number' && value === null ? 0 : value
      }
      initialValue={
        defaultValue !== undefined && defaultValue !== null
          ? defaultValue
          : undefined
      }
      {...formOptions}
    >
      {React.cloneElement(children, {
        ...props,
        disabled,
      })}
    </FormItemWrapper>
  );
};

export default FormItemUI;

import store from 'redux/store';
import moment from 'moment';
import { PREFIX_API_VER_1 } from 'configs/localData/constant';
import { getSessionToken } from 'utils/token';
import { get, post, put, del, getQueryString } from './utils';

export async function getAllApi(resource, data, prefixUrl, params, requestApi) {
  const prefix = prefixUrl || '/admin';
  const prefixApi = requestApi || PREFIX_API_VER_1;
  return get(`${prefix}/${resource}`, data, prefixApi, params);
}

export async function getDataByIdApi(
  resource,
  id,
  data,
  prefixUrl = '/admin',
  suffixUrl = '',
) {
  return get(`${prefixUrl}/${resource}/${id}${suffixUrl}`, data);
}

export async function delApi(resource, id) {
  if (id) {
    return del(`/admin/${resource}/${id}`);
  }
  return del(`/admin/${resource}`);
}

export async function postApi(resource, data, customParamsHeader) {
  return post(`/admin/${resource}`, data, customParamsHeader);
}

export async function putApi(
  resource,
  suffixResourceApi,
  id,
  data,
  customParamsHeader,
) {
  if (suffixResourceApi)
    return put(
      `/admin/${resource}/${id}/${suffixResourceApi}`,
      data,
      customParamsHeader,
    );
  return put(`/admin/${resource}/${id}`, data, customParamsHeader);
}

export async function exportExcelApi(resource, data) {
  return get(`/admin/${resource}/exportExcel`, data);
}

export const exportExcel = (resource, query) => {
  const request = new XMLHttpRequest();
  const { centreId } = store.getState().auth;
  request.open(
    'GET',
    `${
      process.env.REACT_APP_BASE_API_URL
    }/api/v1/admin/${resource}/exportExcel?${getQueryString(query)}`,
  );
  request.setRequestHeader('Authorization', getSessionToken());
  if (centreId) {
    request.setRequestHeader('centreId', centreId);
  }
  request.responseType = 'arraybuffer';
  request.onload = () => {
    if (request.status === 200) {
      // Try to find out the filename from the content disposition `filename` value
      // const disposition = request.getResponseHeader('Content-Disposition');
      // const matches = disposition.substring(
      //   disposition.indexOf('filename=') + 9,
      //   disposition.length,
      // );
      const filename = `${resource}-${moment().format('DD/MM/YYY')}.xlsx`;
      // matches != null && matches !== '' ? matches : `${resource}.xlsx`;
      // The actual download
      const blob = new Blob([request.response], {
        type: request.getResponseHeader('content-type'),
      });
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = filename;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };
  request.send();
};

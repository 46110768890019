import React from 'react';
import PropTypes from 'prop-types';
import { Waypoint } from 'react-waypoint';

import { DropZone } from 'containers/Applications/components/Primatives/styles';
import InnerApplicationList from './InnerApplicationList';

const InnerList = ({
  applications,
  dropProvided,
  colors,
  isDragDisabledItem,
  isShowWaypoint,
  handleLoadMore,
}) => (
  <div className="inner-list">
    <DropZone ref={dropProvided.innerRef}>
      <InnerApplicationList
        applications={applications}
        colors={colors}
        isDragDisabledItem={isDragDisabledItem}
      />
      {dropProvided.placeholder}
    </DropZone>
    {isShowWaypoint && (
      <div style={{ height: 1 }}>
        <Waypoint onEnter={handleLoadMore} />
      </div>
    )}
  </div>
);

InnerList.propTypes = {
  applications: PropTypes.array,
  dropProvided: PropTypes.object,
  colors: PropTypes.object,
  isDragDisabledItem: PropTypes.bool,
  isShowWaypoint: PropTypes.bool,
  handleLoadMore: PropTypes.func,
};

InnerList.defaultProps = {
  applications: [],
  dropProvided: {},
  colors: {},
  handleLoadMore: () => null,
};

export default InnerList;

import React from 'react';
import PropTypes from 'prop-types';
import RestSelect from 'components/RestInput/RestSelect';
import ReferenceInput from 'containers/rest/ReferenceInput';

const SuburbInput = ({
  handleGetGeoWhenChangeAddress,
  disabled = false,
  required,
  initialValue,
}) => (
  <div>
    <ReferenceInput
      source="suburb"
      resource="cities"
      searchKey="city"
      filterKey="city"
      mappedBy="city"
      initialValue={initialValue}
      initialFilter={{ orderBy: 'city' }}
    >
      <RestSelect
        required={required}
        header="input.suburb.name"
        titleProp="city"
        valueProp="city"
        placeholder="input.suburb.placeholder"
        isFilterOption={false}
        onChange={handleGetGeoWhenChangeAddress}
        disabled={disabled}
      />
    </ReferenceInput>
  </div>
);

SuburbInput.propTypes = {
  handleGetGeoWhenChangeAddress: PropTypes.func,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  initialValue: PropTypes.object,
};

export default SuburbInput;

import React from 'react';
import {
  closestCenter,
  DndContext,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  arrayMove,
  rectSortingStrategy,
  SortableContext,
  sortableKeyboardCoordinates,
} from '@dnd-kit/sortable';

import { findIndex } from 'lodash-es';
import { Row } from 'antd';
import { SortableGridStyles } from 'components/RestInput/RestUploadFile/styles';
import { UploadFileCustom } from 'components/RestInput/RestUploadFile/index';
import SortableItem from 'components/RestInput/RestUploadFile/SortableItem';
import { UploadImageItemProps } from 'components/RestInput/RestUploadFile/UploadImageItem';

interface Props {
  fileList: UploadFileCustom[];
  onChangeSortable?: (sortedFiles: UploadFileCustom[]) => void;
  imageItemProps: UploadImageItemProps;
  isShowVirtualTour?: boolean;
}

const SortableGrid = ({
  fileList,
  onChangeSortable,
  imageItemProps,
  isShowVirtualTour,
}: Props) => {
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  const handleDragEnd = (event) => {
    const { active, over } = event;

    if (active.id !== over.id) {
      const oldIndex = findIndex(fileList, (o) => o.uid === active.id);
      const newIndex = findIndex(fileList, (o) => o.uid === over.id);
      onChangeSortable(arrayMove(fileList, oldIndex, newIndex));
    }
  };

  return (
    <SortableGridStyles>
      <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragEnd={handleDragEnd}
      >
        <SortableContext
          items={fileList?.map((item) => item.uid)}
          strategy={rectSortingStrategy}
        >
          <Row gutter={[10, 10]}>
            {fileList.map((file, index) => (
              <SortableItem
                key={file.uid}
                id={file.uid}
                item={file}
                index={index}
                imageItemProps={imageItemProps}
                isShowVirtualTour={isShowVirtualTour}
              />
            ))}
          </Row>
        </SortableContext>
      </DndContext>
    </SortableGridStyles>
  );
};

export default SortableGrid;

import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from 'antd';
import ReadMore from 'components/common/ReadMore';
import i18next from 'i18next';

const AboutTheJob = ({ aboutTheJob }) => (
  <div>
    <Typography.Text className="fw-bold text-20">
      {i18next.t('jobs.moreAboutTheJob.aboutTheJob')}
    </Typography.Text>
    <div className="mt-12">
      <ReadMore
        text={aboutTheJob || ''}
        readMoreCharacterLimit={500}
        showLessButton
        isCheckInsertLink
        showLessText="button.readLess"
      />
    </div>
  </div>
);

AboutTheJob.propTypes = {
  aboutTheJob: PropTypes.node,
};

export default AboutTheJob;

import React from 'react';
import { Typography } from 'antd';

const { Text } = Typography;

interface Props {
  name?: string;
  company?: string;
  year?: string | number;
}

const AwardsItem = ({ name, company, year }: Props) => (
  <div className="text-16 flex flex-direction-column mb-25">
    <Text className="fw-bold">{name}</Text>
    <Text className="mt-4 mb-4">{company}</Text>
    <Text className="text-gray-500">{year}</Text>
  </div>
);
export default AwardsItem;

import styled from 'styled-components';

export const FormEditAudienceStyles = styled.div`
  .ant-form-item-label > label {
    margin-top: 18px;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: ${({ theme }) => theme.text.blackNeutral800};
  }

  .ant-radio-group,
  .ant-space,
  .ant-space-item,
  .ant-radio-wrapper,
  .ant-radio-wrapper > span:nth-child(2) {
    width: 100%;
  }

  .ant-row.ant-form-item {
    margin: 0;
  }

  .select-form-wrapper {
    margin-top: 8px;
    > .ant-row.ant-form-item {
      margin-bottom: 8px;
    }
  }
`;

import styled from 'styled-components';
import imgLogin from 'assets/images/auth-background.png';

export const PublicLayoutWrapper = styled.div`
  .layout {
    background: ${({ theme }) => theme.background.content};
    font-family: Mulish;
    font-style: normal;
    .row-layout {
      height: 100%;
      .col-image {
        .wrapper-image {
          display: flex;
          height: 100%;
          text-align: right;
        }
        .image-auth {
          max-width: 100%;
          object-fit: contain;
        }
      }
    }
    .ant-form-item-label label {
      font-size: 16px;
    }

    .main-container {
      overflow-y: auto;
      overflow-x: hidden;
      display: flex;
      flex-direction: column;
      flex-grow: 1;
    }

    .content-layout {
      flex: 1;
    }
  }

  .main-img {
    background-image: url(${imgLogin});
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    height: 100vh;
    flex: 1;
    @media (max-width: 992px) {
      display: none;
    }
    @media (max-width: 768px) {
      font-size: 20px;
    }
  }

  .main-content {
    padding: 60px 120px;
    @media (max-width: 1240px) {
      padding: 60px 80px;
    }
    @media (max-width: 992px) {
      padding: 60px 40px;
    }
    @media (max-width: 768px) {
      padding: 60px 20px;
    }
    .title {
      .maintitle {
        font-weight: 700;
        font-size: 36px;
      }
      margin-bottom: 30px;
    }
    label {
      color: ${({ theme }) => theme.text.primary};
      font-weight: 700;
    }
    @media (max-width: 992px) {
      .title {
        .maintitle {
          font-size: 28px;
          svg {
            width: 255px;
          }
        }
      }
    }
    @media (max-width: 768px) {
      .title {
        .maintitle {
          font-size: 20px;
          svg {
            width: 180px !important;
          }
        }
      }
    }
  }

  .ant-form-item-children {
    display: block;
  }

  .ant-divider {
    color: ${({ theme }) => theme.text.secondary};
  }
`;

export const Header = styled.div`
  border-bottom: 1px solid #ebecf0;
  padding: 20px 120px;
  @media (max-width: 1840px) {
    padding: 20px 130px;
  }
  @media (max-width: 1240px) {
    padding: 20px 80px;
  }
  @media (max-width: 992px) {
    padding: 20px 40px;
  }
  @media (max-width: 768px) {
    padding: 20px 20px;
  }
`;

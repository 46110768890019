import styled from 'styled-components';
import { Modal } from 'antd';

export const NewFeatureAnnouncementModalStyled = styled(Modal)`
  .anticon {
    font-size: 20px;
  }

  .ant-modal-close-x {
    position: absolute;
    top: 3px;
    right: 5px;
  }

  .ant-modal-body {
    padding: 0;
    overflow-y: auto;
  }

  .ant-modal-title {
    color: rgb(219, 20, 127);
    font-weight: 700;
    font-size: 22px;
  }

  .body-title,
  .body-description {
    color: ${({ theme }) => theme.text.blackNeutral800};
  }

  .ant-modal-footer {
    display: flex;
    justify-content: flex-end;
  }

  .ant-btn-lg {
    width: 137px;
    height: 48px;
    font-weight: 700;
    font-size: 16px;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    border-radius: 12px;
  }

  .body-content {
    width: auto;
  }

  .ant-modal-footer {
    padding: 13px 20px;
  }

  @media only screen and (min-width: 375px) {
    .ant-modal-body {
      height: 590px;
    }

    .body-title {
      font-size: 17px;
      font-weight: 700;
    }

    .body-content {
      padding: 22px;
    }

    .body-description {
      font-size: 14px;
      line-height: 25px;
    }
  }

  @media only screen and (min-width: 1366px) {
    .ant-modal-body {
      height: 485px;
    }

    .body-content {
      padding: 30px;
    }

    .body-title {
      font-size: 16px;
      font-weight: 700;
      line-height: 28px;
      margin: 0 0 10px 0;
    }

    .body-description {
      font-size: 14px;
      line-height: 30px;
    }
  }

  @media only screen and (min-width: 1440px) {
    .ant-modal-body {
      height: 545px;
    }

    .body-title {
      font-size: 18px;
    }
  }

  @media only screen and (min-width: 1920px) {
    .ant-modal-body {
      height: 678px;
    }
  }

  @media only screen and (min-width: 2560px) {
    .ant-modal-content {
      width: 643px;
    }

    .ant-modal-body {
      height: 760px;
    }

    .body-title {
      font-size: 20px;
      font-weight: 700;
      line-height: 30px;
      margin: 0 0 10px 0;
    }

    .body-description {
      font-size: 18px;
      line-height: 32px;
    }
  }

  @media only screen and (min-width: 3840px) {
    .ant-modal-content {
      width: 643px;
    }

    .body-content {
      padding: 32px;
    }

    .ant-modal-title {
      font-size: 28px;
    }

    .body-title {
      font-size: 22px;
      line-height: 32px;
      margin: 0 0 10px 0;
    }

    .ant-modal-body {
      height: 750px;
    }

    .body-title {
      font-size: 20px;
      font-weight: 700;
      line-height: 32px;
      margin: 0 0 10px 0;
    }

    .body-description {
      font-size: 16px;
      line-height: 32px;
    }

    .anticon {
      font-size: 22px;
    }
  }
`;

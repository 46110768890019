import React from 'react';
import { Row, Col } from 'antd';
import PieChartCustom from 'components/charts/PieChartCustom';
import { isEmpty } from 'lodash-es';
import PropTypes from 'prop-types';
import CardCustom from 'containers/CompanyBenchmarking/components/CardCustom';
import { PieChartRatingStyles } from './styles';
import RatingTypesSection from '../RatingTypesSection';

const KindicareRatingPieChart = ({ title, resultKindicareRating }) =>
  !isEmpty(resultKindicareRating) && (
    <CardCustom title={title}>
      <PieChartRatingStyles>
        <Row align="middle" className="kindicare-rating-style">
          <Col xxl={10} xl={24} sm={12} xs={24}>
            <PieChartCustom
              data={resultKindicareRating}
              innerRadius={0}
              paddingAngle={0}
              isShowLabel={false}
              isShowLegend={false}
              cy="50%"
              cx="50%"
              height={200}
            />
          </Col>
          <Col xxl={14} xl={24} sm={12} xs={24} className="p-0">
            <RatingTypesSection data={resultKindicareRating} />
          </Col>
        </Row>
      </PieChartRatingStyles>
    </CardCustom>
  );

KindicareRatingPieChart.propTypes = {
  title: PropTypes.string,
  resultKindicareRating: PropTypes.array,
};

export default KindicareRatingPieChart;

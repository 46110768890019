export const formatMediaListResponse = (mediaList) => {
  if (!mediaList?.length) {
    return [];
  }

  return mediaList?.map((item) => ({
    ...item,
    featured: item?.isFeatured,
    isFeatured: undefined,
  }));
};

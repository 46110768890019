import React from 'react';
import { Progress, Tooltip } from 'antd';
import i18next from 'i18next';
import { MatchingScoreWrapper } from './styles';

interface Props {
  jobMatchScore?: number;
}

const JobMatchProgressCircle = ({ jobMatchScore }: Props) => (
  <MatchingScoreWrapper className="pointer">
    <Tooltip title={i18next.t('jobApplications.jobMatchScore')}>
      <Progress
        type="circle"
        width={44}
        percent={jobMatchScore}
        format={(percent) => (
          <div>
            {percent}
            <sup>%</sup>
          </div>
        )}
        className="progress-style"
      />
    </Tooltip>
  </MatchingScoreWrapper>
);

export default JobMatchProgressCircle;

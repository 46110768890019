import styled from 'styled-components';

export const FilterHeaderStyles = styled.div`
  padding-top: 10px;
  .ant-input-affix-wrapper {
    background: ${({ theme }) => theme.background.lightBlue};
    border-color: transparent;

    .ant-input {
      background: ${({ theme }) => theme.background.lightBlue};
    }

    .anticon-search {
      color: ${({ theme }) => theme.palette.primary};
    }
  }
`;

export const EmptyStyles = styled.div``;

const InboxListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export default InboxListWrapper;

export const ListStyles = styled.div`
  flex-grow: 1;
  margin-top: 14px;
  overflow-y: auto;
  ::-webkit-scrollbar-track {
    background: #fff !important;
  }
  .not-seen {
    position: relative;
    font-weight: 600;

    .ant-list-item-meta-description {
      color: #2d1f21;
    }

    .ant-list-item-meta-title {
      .title {
        font-weight: 800;
      }

      .date {
        font-weight: 700;
        color: ${({ theme }) => theme.palette.primary};
      }
    }
  }

  .current-message {
    background: #db147f20;
  }

  .notification-icon {
    width: 45px;
    height: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #f0f0f0;
    border-radius: 50%;

    svg {
      margin-right: 0;
    }
  }

  .ant-list-item {
    cursor: pointer;
    padding-left: 14px;
    padding-right: 14px;
  }

  .not-seen-noti {
    background: #d0d0d0;
  }

  .ant-list-item-meta-avatar {
    margin-right: 10px;
  }

  .ant-list-item-meta-description {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  .ant-list-item-meta-title {
    .title-section {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;

      .name-section {
        display: flex;
        flex-direction: column;
        justify-content: center;
      }

      .type-section {
        display: flex;
        overflow: hidden;
        & > .dot-type {
          height: 6px;
          width: 6px;
          min-width: 6px;
          border-radius: 1px;
          margin-right: 3px;
          margin-top: 8px;
        }
        .name-type {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }

      .title {
        font-size: 16px;
      }
    }
  }

  @media (max-width: 900px) {
    .title-section {
      flex-direction: column;

      .type-section {
        max-width: 100%;
      }
    }
  }

  @media (max-width: 676px) {
    .ant-list-item {
      padding: 13px;
      border-radius: 7px;
    }

    .ant-list-items {
      margin-right: -7px;
    }

    .ant-list-item-meta-content {
      display: none;
    }
  }
`;

export const FilterMessageStyles = styled.div`
  padding: 15px;
`;

import React from 'react';
import i18next from 'i18next';
import { useSelector } from 'react-redux';
import { getCreateAdPayload } from 'redux/advertisement/selectors';
import { StepTitle } from '../styles';
import { IdentityStyles } from './styles';
import IdentityForm from './IdentityForm';

const Identity = ({ form, isShowFooter = true }) => {
  const identityValues = useSelector(getCreateAdPayload);

  return (
    <IdentityStyles>
      <StepTitle>{i18next.t('advertisement.steps.step3')}</StepTitle>
      <IdentityForm
        form={form}
        isShowFooter={isShowFooter}
        identityValues={identityValues}
      />
    </IdentityStyles>
  );
};

export default Identity;

import React from 'react';
import PropTypes from 'prop-types';
import i18next from 'i18next';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import { providersOfUserSelectors } from 'redux/providersOfUser/selectors';
import { delProvidersOfUser } from 'redux/providersOfUser/actions';

import TableCustom from 'components/common/TableCustom';
import DeleteButton from 'components/RestActions/DeleteButton';

import { getQueryParamsWhenChangeTable } from 'utils/tableUtils';

const ProvidersOfUserTable = ({ retrieveList }) => {
  const dispatch = useDispatch();

  const { id } = useParams();

  const resourceFilter =
    useSelector(providersOfUserSelectors.getResourceData) || {};

  const loading = useSelector(providersOfUserSelectors.getLoading);

  const data = useSelector(providersOfUserSelectors.getDataArr);

  const deleteProviderOfUser = (providerId) =>
    dispatch(
      delProvidersOfUser({
        id,
        providerId,
      }),
    );

  const columns = [
    {
      title: i18next.t('providers.provider'),
      dataIndex: 'name',
      sorter: true,
    },
    {
      title: i18next.t('providers.numberOfCentres'),
      dataIndex: 'totalCentres',
      sorter: true,
    },
    {
      title: '',
      dataIndex: 'id',
      width: 80,
      fixed: 'right',
      render: (id, record) => (
        <DeleteButton
          permissionKey="abilityChangeUserPermissions"
          action="isEdit"
          isSkipSubscription
          customMessage="users.confirmDelete"
          record={record}
          deleteItem={deleteProviderOfUser}
        />
      ),
    },
  ];

  const onChange = (e, filters, sorter) => {
    const queryParams = getQueryParamsWhenChangeTable(e, filters, sorter);

    retrieveList({
      filter: queryParams,
      isRefresh: true,
      keepFilter: true,
    });
  };

  return (
    <TableCustom
      xScroll={600}
      onChange={onChange}
      data={data}
      columns={columns}
      loading={loading}
      isResetStyle
      pagination={{
        pageSize: resourceFilter.limit,
        current: resourceFilter.offset / resourceFilter.limit + 1 || 1,
        total: resourceFilter.total,
      }}
    />
  );
};

ProvidersOfUserTable.propTypes = {
  retrieveList: PropTypes.func,
};

ProvidersOfUserTable.defaultProps = {
  retrieveList: () => null,
};

export default ProvidersOfUserTable;

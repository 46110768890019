import React from 'react';
import { Space } from 'antd';
import PropTypes from 'prop-types';
import { CommentOutlined } from '@ant-design/icons';

import CustomButton from 'components/RestActions/CustomButton';
import { useSelector } from 'react-redux';
import { ActionCommentStyles } from './styles';
import ReplyAction from '../ReplyAction';

const ActionsComment = ({
  onVisibleReply,
  openDisputeModal,
  isInternalRating,
  isShowDispute,
}) => {
  const currentCentre = useSelector((state) => state.centres.currentData);

  return (
    <ActionCommentStyles>
      <Space size={24}>
        {isInternalRating && (
          <ReplyAction onVisibleReply={onVisibleReply} record={currentCentre} />
        )}
        {isShowDispute && (
          <CustomButton
            packageName={currentCentre?.CRMPackage?.name}
            title="button.dispute"
            permissionKey="replyReviews"
            action="isEdit"
            isDisabled
            handleClick={openDisputeModal}
            buttonProps={{
              className: 'dispute-btn',
              icon: <CommentOutlined />,
            }}
          />
        )}
      </Space>
    </ActionCommentStyles>
  );
};

ActionsComment.propTypes = {
  onVisibleReply: PropTypes.func,
  openDisputeModal: PropTypes.func,
  isInternalRating: PropTypes.bool,
  isShowDispute: PropTypes.bool,
};

ActionsComment.defaultProps = {
  onVisibleReply: () => null,
};

export default ActionsComment;

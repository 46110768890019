import React from 'react';
import i18next from 'i18next';
import { Button } from 'antd';
import { CrownFilled } from '@ant-design/icons';
import { ReplyIcon } from 'components/common/SVGIcon';

import { upgradeModal } from 'components/common/CheckPermissions/UpgradeSubscriptionModal';

const FallbackReply = () => {
  const handleClick = () => {
    upgradeModal.open();
  };

  return (
    <div className="fallback-btn">
      <Button
        className="reply-button"
        icon={<ReplyIcon />}
        onClick={handleClick}
      >
        {i18next.t('button.reply')}
      </Button>
      <CrownFilled className="action-feature-icon" />
    </div>
  );
};

export default FallbackReply;

import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { checkPermissions } from 'utils/tools';
import { userPermissionsSelector } from 'redux/auth/selectors';
import { Dropdown } from 'antd';
import { MoreOutlined } from '@ant-design/icons';
import { GroupWrapper, ContentStyles } from './styles';

const ActionGroup = ({
  children,
  elementProps,
  gotoEditPage,
  gotoShowPage,
  deleteItem,
  pinItem,
  record,
  modelResource,
  IconComponent,
  placement,
  isShowMenuItem,
}) => {
  const userPermissions = useSelector(userPermissionsSelector);

  const content = React.Children.map(children, (element) =>
    checkPermissions(element.props.permissions, userPermissions)
      ? React.cloneElement(element, {
          resource: modelResource,
          gotoEditPage: element.props.gotoEditPage || gotoEditPage,
          gotoShowPage: element.props.gotoShowPage || gotoShowPage,
          deleteItem: element.props.deleteItem || deleteItem,
          pinItem: element.props.pinItem || pinItem,
          record,
          key: element.props.source,
        })
      : null,
  );
  return (
    <GroupWrapper {...elementProps}>
      {isShowMenuItem ? (
        <Dropdown
          overlay={
            <ContentStyles className="popover-actions-div">
              {content}
            </ContentStyles>
          }
          trigger={['click']}
          placement={placement}
        >
          <IconComponent className="iconSetting" />
        </Dropdown>
      ) : (
        <>{content}</>
      )}
    </GroupWrapper>
  );
};

ActionGroup.propTypes = {
  children: PropTypes.node,
  elementProps: PropTypes.object,
  record: PropTypes.object,
  gotoEditPage: PropTypes.func,
  gotoShowPage: PropTypes.func,
  deleteItem: PropTypes.func,
  pinItem: PropTypes.func,
  source: PropTypes.string,
  fixed: PropTypes.string,
  widthAction: PropTypes.number,
  resource: PropTypes.string,
  IconComponent: PropTypes.any,
  placement: PropTypes.string,
  modelResource: PropTypes.string,
  isShowMenuItem: PropTypes.bool,
};

ActionGroup.defaultProps = {
  source: 'actionGroup',
  fixed: 'right',
  placement: 'bottomRight',
  IconComponent: MoreOutlined,
};

export default ActionGroup;

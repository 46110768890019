import styled from 'styled-components';

export const Wrapper = styled.div`
  min-width: 390px;
  box-shadow: 0px 0px 1px rgba(12, 26, 75, 0.1),
    0px 4px 20px -2px rgba(50, 50, 71, 0.08);
  border-radius: 12px;
  padding: 16px;
  background-color: ${({ theme }) => theme.text.disabledBtn};
  cursor: pointer;
  &:hover {
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
      0 2px 4px -1px rgba(0, 0, 0, 0.06);
  }
`;

export const TopContainer = styled.div`
  width: 100%;
  padding-bottom: 16px;
  border-bottom: 1px solid ${({ theme }) => theme.boardColors.N30};

  .status-container {
    &__open {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 0 8px;
      gap: 10px;
      width: fit-content;
      height: 28px;
      background: ${({ theme }) => theme.background.successSoft};
      border-radius: 6px;
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 20px;
      color: ${({ theme }) => theme.text.successDefault};
    }
    &__closed {
      padding: 2px 8px;
      gap: 10px;
      width: fit-content;
      height: 28px;
      background: ${({ theme }) => theme.background.gray200};
      border-radius: 6px;
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 20px;
      display: flex;
      align-items: center;
      color: ${({ theme }) => theme.text.black};
    }
  }

  .id-container {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    display: flex;
    align-items: center;
    text-decoration-line: underline;
    color: ${({ theme }) => theme.text.grayNeutral500};
  }

  .avatar {
    width: 60px;
    height: 60px;
    border-radius: 8px;
    object-fit: cover;
    margin-right: 12px;
  }

  .title {
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: ${({ theme }) => theme.text.blackNeutral800};
  }

  .title-default {
    margin: 0;
    height: 24px;
    margin-top: 12px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    display: flex;
    align-items: center;
    color: ${({ theme }) => theme.text.blackNeutral800};
  }

  .count {
    margin-right: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px 2px;
    width: fit-content;
    height: 24px;
    min-width: 32px;
    margin-top: 12px;

    background: ${({ theme }) => theme.background.lightGreen};
    border-radius: 4px;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 24px;
    display: flex;
    align-items: center;
    color: ${({ theme }) => theme.palette.green};
  }

  .address {
    margin: 0;
    margin-left: 10px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    display: flex;
    align-items: center;
    text-align: center;
    color: ${({ theme }) => theme.text.primary};
  }

  .price {
    margin: 0;
    margin-left: 10px;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 24px;
    color: ${({ theme }) => theme.text.darkGreen};
  }

  .line-container {
    display: flex;
    align-items: center;
    margin-left: 10px;
    gap: 12px;

    &__time {
      margin: 0;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      display: flex;
      align-items: center;
      text-align: center;
      color: ${({ theme }) => theme.text.blackNeutral800};
    }

    &__work-type {
      margin: 0;
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 0px 8px;
      gap: 8px;
      width: fit-content;
      height: 24px;
      background: ${({ theme }) => theme.background.container};
      border: 1px solid ${({ theme }) => theme.background.gray300};
      border-radius: 8px;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 24px;
      color: ${({ theme }) => theme.background.blackNeutral800};
    }

    &__dot {
      width: 4px;
      height: 4px;
      background: ${({ theme }) => theme.background.gray300};
      border-radius: 50%;
    }
  }
`;

export const BottomContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding-top: 16px;

  .application-left {
    display: flex;
    justify-content: flex-start;
    width: 45%;
    height: 54px;
    gap: 10px;
  }

  .application-right {
    display: flex;
    justify-content: flex-start;
    width: 45%;
    height: 54px;
    gap: 10px;
  }
  .application-title {
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 18px;
    color: #758098;
  }
  .application-line-right {
    width: 4px;
    height: 100%;
    border-radius: 16px;
    background: ${({ theme }) => theme.palette.pink};
  }

  .application-line-left {
    width: 4px;
    height: 100%;
    border-radius: 16px;
    background: ${({ theme }) => theme.palette.green};
  }

  .application-center {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
  }

  .count {
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    padding-top: 10px;
    color: #141533;
  }
`;

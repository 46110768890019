import { PREFIX_API_VER_2 } from 'configs/localData/constant';
import qs from 'qs';
import { get, post } from './utils';

export const getAllJobApplicationsApi = (data) =>
  get(`/crm/job-seekers/posts/applications`, data, PREFIX_API_VER_2);

export const getJobApplicationSummaryCardApi = (data) =>
  get(
    `/crm/job-seekers/posts/applications/application-summary`,
    data,
    PREFIX_API_VER_2,
  );

export const getTotalSubmittedJobApplicationsApi = (data) =>
  get(
    `/crm/job-seekers/posts/applications/total-submitted-applications`,
    data,
    PREFIX_API_VER_2,
  );

export const getJobApplicationSummaryApi = (id) =>
  get(
    `/crm/job-seekers/posts/applications/summany?id=${id}`,
    null,
    PREFIX_API_VER_2,
  );

export const updateJobApplicationStatusApi = (params) =>
  post(
    `/crm/job-seekers/posts/applications/change-status?${qs.stringify(params)}`,
    null,
    PREFIX_API_VER_2,
  );

export const getJobApplicationMailboxApi = ({
  applicationId,
  page,
  size,
} = {}) =>
  get(
    `/crm/job-seekers/posts/applications/mail-box?applicationId=${applicationId}&size=${size}&page=${page}`,
    null,
    PREFIX_API_VER_2,
  );

export const postJobApplicationMailboxApi = ({
  applicationId,
  content,
  files,
} = {}) =>
  post(
    '/crm/job-seekers/chat-messages',
    { applicationId, content, files },
    PREFIX_API_VER_2,
  );
export const getJobApplicationProfileApi = (id) =>
  get(
    `/crm/job-seekers/posts/applications/profile?id=${id}`,
    null,
    PREFIX_API_VER_2,
  );

export async function getS3JobSeekerUrl(applicationId, filename) {
  return post(
    `/crm/job-seekers/chat-messages/s3-presigned-url`,
    {
      applicationId,
      filename,
    },
    PREFIX_API_VER_2,
  );
}

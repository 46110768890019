import moment from 'moment';
import i18next from 'i18next';

export const replaceAt = (str, index, replacement) => {
  if (!str) return '';
  if (index > str.length - 1) return str;
  return (
    str.substring(0, index) +
    replacement +
    str.substring(index + replacement.length)
  );
};

export const upperFirstChar = (text) =>
  text
    ? text.replace(
        /\w\S*/g,
        (txt) => txt.charAt(0).toUpperCase() + txt.substr(1),
      )
    : null;
export const lowerFirstChar = (text) =>
  text ? text.charAt(0).toLowerCase() + text.substr(1) : null;
export const replaceAll = (text, search, replacement) =>
  text?.replace(new RegExp(search, 'g'), replacement);

export const formatTimeDisplay = (number) => {
  if (!number) return '0';

  const hours = Math.floor(number);

  const days = Math.floor(hours / 24);

  const restHour = hours % 24;

  const restMinutes = Math.round((number - hours) * 60);

  return `${days ? `${days} days` : ''}${restHour ? ` ${restHour} hrs` : ''}${
    restMinutes ? ` ${restMinutes} mins` : ''
  }`;
};

export const formatDateTimeTable = (text) =>
  text ? moment(text).format('DD MMM YYYY, HH:mm') : null;

export const formatDateTime = (text) =>
  text
    ? moment(text).format('DD/MM/YY, hh:mma')
    : moment().format('DD/MM/YY, hh:mma');

export const formatDateTimeline = (text) =>
  text ? moment(text).format('MMM Do, YYYY, hh:mm a') : null;

export const formatBirthday = (text) =>
  text ? moment(text).format('DD/MM/YYYY') : null;

export const formatDateApplication = (text) =>
  text ? moment(text).format('DD MMM YYYY') : null;

export const formatDate = (text, type = 'D MMMM YYYY') =>
  text ? moment(text).format(type) : null;

export const formatTime = (text) =>
  text ? moment(text).format('hh:mma') : null;

export const formatDateTimeWorking = (text) =>
  text ? moment(text).format('MMM YYYY') : null;

export const formatDateFromNow = (date) => {
  if (moment().isAfter(date)) {
    return moment().diff(moment(date), 'days') < 1
      ? moment(date).fromNow()
      : moment(date).format('DD MMM, YYYY [at] HH:mm');
  }
  return i18next.t('text.justNow');
};

export const formatDateLastActive = (date) => {
  if (!date) return null;
  if (moment().isAfter(date)) {
    return moment().diff(moment(date), 'days') < 1
      ? moment(date).fromNow()
      : moment(date).format('DD MMM YYYY, HH:mm');
  }
  return i18next.t('common.active');
};

export const formatDateDashboard = (text) => {
  if (!text) return null;
  const dateTime = moment(text);
  let formatTime = 'h:mma';
  if (dateTime.minutes() === 0) formatTime = 'ha';
  return dateTime.isSame(moment(), 'year')
    ? dateTime.format(`D MMM, ${formatTime}`)
    : dateTime.format(`D MMM YYYY, ${formatTime}`);
};

export const getAge = (date) => {
  let yearsOld = 0;
  let monthsOld = 0;

  if (moment().isAfter(date)) {
    const totalMonths = moment().diff(date, 'months');
    yearsOld = Math.floor(totalMonths / 12);
    monthsOld = totalMonths % 12;
  }

  return (
    `${yearsOld ? `${yearsOld} ${i18next.t('text.years')}` : ''}${
      monthsOld ? ` ${monthsOld} ${i18next.t('text.months')}` : ''
    }` || 0
  );
};

export const encodeJsonToURI = (params) =>
  Object.keys(params)
    .map(
      (key) => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`,
    )
    .join('&');

export const makeActionName = (text) =>
  text
    ? lowerFirstChar(
        replaceAll(
          upperFirstChar(replaceAll(text, '_', ' ').toLowerCase()),
          ' ',
          '',
        ),
      )
    : null;

export const formatMoney = (number, currency, country = 'en-US') =>
  Intl.NumberFormat(country, {
    style: 'currency',
    currency: currency || 'USD',
  }).format(number);

export const inputNumberFormatter = () => ({
  formatter: (value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ','),
  parser: (value) => value.replace(/\$\s?|(,*)/g, ''),
  ruleType: 'number',
});

export const formatDateUpdatePrice = (date) => {
  let years = 0;
  let months = 0;
  let days = 0;

  const totalDates = moment().diff(moment(date), 'days');
  const totalMonths = moment().diff(moment(date), 'months');

  if (!date) return '';

  if (moment().isAfter(date)) {
    if (totalDates < 30) {
      return moment(date).fromNow();
    }
    if (totalDates >= 30 && totalMonths < 12) {
      months = Math.floor(totalDates / 30);
      days = totalDates % 30;
      return `${months} ${
        months > 1 ? i18next.t('text.months') : i18next.t('text.month')
      } ${days} ${
        days > 1 ? i18next.t('text.days') : i18next.t('text.day')
      } ${i18next.t('text.ago')}`;
    }
    years = Math.floor(totalMonths / 12);
    months = totalMonths % 12;
    return `${years} ${
      years > 1 ? i18next.t('text.years') : i18next.t('text.year')
    } ${months} ${
      months > 1 ? i18next.t('text.months') : i18next.t('text.month')
    } ${i18next.t('text.ago')}`;
  }
  return '';
};

export const roundHalf = (number) => (number ? Math.round(number * 2) / 2 : 0);

export const formatToLowercaseOriginal = (text) =>
  text?.replace(/\s+/g, ' ').trim().toLowerCase();

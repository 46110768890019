import React from 'react';
import Create from 'containers/rest/Create';
import Form from '../components/Form';

const ApplicationsCreate = (props) => (
  <Create {...props} resource="applications">
    <Form />
  </Create>
);

export default ApplicationsCreate;

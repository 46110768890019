import styled from 'styled-components';

export const SummaryCardStyles = styled.div`
  display: flex;
  background: #ffffff;

  box-shadow: 0px 12px 26px rgba(16, 30, 115, 0.06);
  border-radius: 8px;
  padding: 16px;
  align-items: stretch;

  .wrapper-icon {
    display: flex;
    align-items: center;
    font-size: 50px;
  }

  .wrapper-content {
    margin-left: 15px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow: hidden;
    .title {
      font-weight: bold;
      font-size: 14px;
      line-height: 20px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
    .count-value {
      font-weight: bold;
      font-size: 20px;
      line-height: 25px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }
`;

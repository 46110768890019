import { UserOutlined } from '@ant-design/icons';
import React from 'react';
import { Avatar } from 'antd';
import { MessageIcon, PhoneRightIcon } from 'components/common/SVGIcon';
import { PermittedUserDto } from 'generated/apis';

interface Props {
  user: PermittedUserDto;
}

const OrganisationUserItem = ({ user }: Props) => (
  <div className="flex-center-start w-full rounded-16 border-neutral-200 p-20 gap-20">
    <Avatar
      src={user.avatar}
      alt={`${user.name}-avatar`}
      size={120}
      icon={<UserOutlined />}
    />
    <div className="flex justify-center items-start  gap-8 flex-col text-16 text-neutral-700 ">
      <div className="fw-bold h-24">{user.name}</div>
      <div className="h-24">{user.role}</div>
      {!!user.email && (
        <div className="flex-center-start h-24 flex-row gap-8">
          <MessageIcon className="text-primary" />
          <div>{user.email}</div>
        </div>
      )}
      {!!user.phone && (
        <div className="flex-center-start h-24 flex-row gap-8">
          <PhoneRightIcon className="text-primary" />
          <div>{user.phone}</div>
        </div>
      )}
    </div>
  </div>
);

export default OrganisationUserItem;

import React, { useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Button, Input, Space, Form, Modal } from 'antd';
import i18next from 'i18next';
import { useDispatch, useSelector } from 'react-redux';

import {
  updateAutomatedResponses,
  updateAutomatedResponsesAllCentres,
} from 'redux/automatedResponses/actions';
import CustomButton from 'components/RestActions/CustomButton';
import { useAutomatedMessage } from './context';

const AutomatedForm = ({ setIsEdit, item }) => {
  const [form] = Form.useForm();
  const { setMessage } = useAutomatedMessage();
  const dispatch = useDispatch();
  const centreId = useSelector((state) => state.auth.centreId);

  const [loading, setLoading] = useState();

  const actionHandle = useMemo(
    () =>
      centreId ? updateAutomatedResponses : updateAutomatedResponsesAllCentres,
    [centreId],
  );

  const handleUpdate = (values) => {
    setLoading(true);

    return dispatch(
      actionHandle({
        centreId,
        id: item.id,
        key: item.key,
        data: { ...values, type: 'json' },
      }),
    )
      .then(({ payload }) => {
        if (payload?.key) setIsEdit(false);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onFinish = async (values) => {
    if (centreId) {
      handleUpdate(values);
    } else {
      Modal.confirm({
        title: i18next.t('automatedResponses.header'),
        content: i18next.t('automatedResponses.confirmChangeMessageAllCentre'),
        onOk() {
          return handleUpdate(values);
        },
      });
    }
  };

  const handleCancel = () => {
    form.resetFields();
    setIsEdit(false);
    setMessage(item?.data?.text);
  };

  const onChangeMessage = ({ target: { value } }) => {
    setMessage(value);
  };

  useEffect(() => {
    form.setFieldsValue(item);
  }, [item?.data?.text]); // eslint-disable-line

  return (
    <div>
      <Form form={form} onFinish={onFinish} autoComplete="off">
        <Form.Item
          name={['data', 'text']}
          rules={[{ required: true, message: i18next.t('error.required') }]}
        >
          <Input.TextArea
            onChange={onChangeMessage}
            placeholder={i18next.t('input.enterHere')}
            autoSize={{ minRows: 4, maxRows: 10 }}
          />
        </Form.Item>
        <div className="text-right mt-20">
          <Space>
            <Button className="btn-line-primary" onClick={handleCancel}>
              {i18next.t('button.cancel')}
            </Button>
            <CustomButton
              buttonProps={{ htmlType: 'submit', type: 'primary' }}
              loading={loading}
              permissionKey="myCentres"
              action="isEdit"
              title="button.save"
            />
          </Space>
        </div>
      </Form>
    </div>
  );
};

AutomatedForm.propTypes = {
  setIsEdit: PropTypes.func,
  item: PropTypes.object,
};

export default AutomatedForm;

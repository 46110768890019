import { getJobApplicationMailbox } from './action';

const { createSlice } = require('@reduxjs/toolkit');

const initialState = {
  data: [],
};

const getJobApplicationMailboxSuccess = (
  state,
  { payload: { result, paging } },
) => {
  if (paging.page === 1) {
    state.data = result;
  } else {
    state.data = [...state.data, ...result];
  }

  state.paging = {
    ...paging,
    totalPage: Math.ceil(paging.total / paging.size),
  };

  state.loading = false;
};

const getJobApplicationMailboxPending = (state) => {
  state.loading = true;
};

const getJobApplicationMailboxFailed = (state, { payload }) => {
  state.error = payload;
  state.loading = false;
};

const { reducer } = createSlice({
  name: 'jobApplicationMailbox',
  initialState,
  reducers: {},
  extraReducers: {
    [getJobApplicationMailbox.fulfilled]: getJobApplicationMailboxSuccess,
    [getJobApplicationMailbox.pending]: getJobApplicationMailboxPending,
    [getJobApplicationMailbox.rejected]: getJobApplicationMailboxFailed,
  },
});

export default reducer;

import React from 'react';
import { Row, Col } from 'antd';
import {
  TotalJobRoleIcon,
  TotalWorkplaceIcon,
  TotalActiveIcon,
  TotalOpenIcon,
} from 'components/common/SVGIcon';

import SummaryCard from 'components/common/SummaryCardCustom';
import { getSummaryJobListingSelector } from 'redux/jobs/jobListings/selectors';
import { useSelector } from 'react-redux';

const Summaries = () => {
  const summaries = useSelector(getSummaryJobListingSelector) || {};

  const summariesMap = [
    {
      title: 'jobListings.summaries.totalJobRoles',
      count: summaries?.totalJobRoles,
      IconComponent: TotalJobRoleIcon,
    },
    {
      title: 'jobListings.summaries.totalWorkplaces',
      count: summaries?.totalWorkplaces,
      IconComponent: TotalWorkplaceIcon,
    },
    {
      title: 'jobListings.summaries.totalActive',
      count: summaries?.totalActiveJobListings,
      IconComponent: TotalActiveIcon,
    },
    {
      title: 'jobListings.summaries.totalOpen',
      count: summaries?.totalOpenJobApplications,
      IconComponent: TotalOpenIcon,
    },
  ];

  return (
    <Row gutter={[25, 25]} className="mb-8">
      {summariesMap.map((item, index) => (
        <Col lg={6} md={12} xs={24} key={String(index)}>
          <SummaryCard {...item} isTitleTooltip />
        </Col>
      ))}
    </Row>
  );
};

export default Summaries;

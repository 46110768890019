import { CrownFilled, LeftOutlined } from '@ant-design/icons';
import i18next from 'i18next';
import React from 'react';
import { upgradeModal } from 'components/common/CheckPermissions/UpgradeSubscriptionModal';
import { LinkStyle } from '../styles';

interface Props {
  currentIndex: number;
}

const PreviousStepButtonFallBack = ({ currentIndex }: Props) => {
  const btnClassName =
    currentIndex === 0 ? 'show-hide-btn__previous' : 'show-hide-btn__next';
  const onClickPrev = () => {
    upgradeModal.open();
  };
  return (
    <LinkStyle
      onClick={onClickPrev}
      disabled={currentIndex === 0}
      className="show-hide-btn"
    >
      <LeftOutlined className={`ml-12 ${btnClassName}`} />
      <span className={`ml-6 ${btnClassName}`}>
        {i18next.t('button.previous')}
        {currentIndex > 0 && <CrownFilled className="feature-icon" />}
      </span>
    </LinkStyle>
  );
};

export default PreviousStepButtonFallBack;

import React from 'react';
import PropTypes from 'prop-types';
import i18next from 'i18next';
import { Avatar } from 'antd';
import {
  UserOutlined,
  MailOutlined,
  PhoneOutlined,
  EnvironmentOutlined,
  CalendarOutlined,
} from '@ant-design/icons';
import CustomerInfoStyles from './styles';

const CentreInfo = ({ record }) => (
  <CustomerInfoStyles>
    <div className="avatar-customer">
      <Avatar icon={<UserOutlined />} src={record?.avatar?.url} size={150} />
      <div className="name-customer">
        {record?.name || i18next.t('error.waitingUpdate')}
      </div>
    </div>
    <div className="info-other">
      <div className="item-info-other">
        <div className="content-info-other">
          <div>
            <CalendarOutlined />
            <span>{record?.birthday || i18next.t('error.waitingUpdate')}</span>
          </div>
          <div className="email-text">
            <MailOutlined />
            <span>{record?.email || i18next.t('error.waitingUpdate')}</span>
          </div>
          <div>
            <PhoneOutlined />
            <span>{record?.phone || i18next.t('error.waitingUpdate')}</span>
          </div>
          <div>
            <EnvironmentOutlined />
            <span>
              {record?.address?.trim() || i18next.t('error.waitingUpdate')}
            </span>
          </div>
        </div>
      </div>
    </div>
  </CustomerInfoStyles>
);

CentreInfo.propTypes = {
  record: PropTypes.object,
};

export default CentreInfo;

import React from 'react';
import PropTypes from 'prop-types';
import I18n from 'i18next';
import { Menu, Badge } from 'antd';
import { CrownFilled } from '@ant-design/icons';
import { upgradeModal } from 'components/common/CheckPermissions/UpgradeSubscriptionModal';

const MenuItemFallBack = (props) => {
  const { disabled, menu, collapsed, ...restProps } = props;
  const handleClick = () => {
    upgradeModal.open();
  };

  return (
    <Menu.Item
      {...restProps}
      disabled={disabled}
      key={menu.key}
      title={I18n.t(menu.text)}
      onClick={handleClick}
      icon={
        <Badge count={<CrownFilled className="feature-icon" />}>
          <menu.Icon />
        </Badge>
      }
    >
      {!collapsed && <span className="menu-label">{I18n.t(menu.text)}</span>}
    </Menu.Item>
  );
};

MenuItemFallBack.propTypes = {
  disabled: PropTypes.bool,
  menu: PropTypes.object,
  collapsed: PropTypes.bool,
};

export default MenuItemFallBack;

import { Col, Row } from 'antd';
import ViewModeSwitcher from 'components/common/ViewModeSwitcher';
import React from 'react';
import PropTypes from 'prop-types';
import { useHistory, useLocation, useParams } from 'react-router';
import { getFilterFromUrl, getSearchUrl } from 'utils/tools';
import Filter from '../Filter';

const HeaderFilter = ({
  activeTab,
  pushQuery,
  retrieveList,
  setFilterData,
}) => {
  const { push } = useHistory();
  const { id } = useParams();
  const { search } = useLocation();

  const onChange = (e) => {
    const { filter } = getFilterFromUrl(search);
    push(
      `/job-listings/${id}/show/applications/${e.target.value}?${getSearchUrl(
        filter,
      )}`,
    );
  };

  return (
    <Row gutter={16}>
      <Col xxl={4} xl={6} lg={5} xs={24}>
        <ViewModeSwitcher onChange={onChange} activeTab={activeTab} />
      </Col>
      <Col xxl={20} xl={18} lg={19} xs={24}>
        <Filter
          pushQuery={pushQuery}
          retrieveList={retrieveList}
          setFilterData={setFilterData}
        />
      </Col>
    </Row>
  );
};

HeaderFilter.propTypes = {
  activeTab: PropTypes.string,
  retrieveList: PropTypes.func,
  pushQuery: PropTypes.func,
  setFilterData: PropTypes.func,
};

export default HeaderFilter;

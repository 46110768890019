import React from 'react';
import { Row, Col } from 'antd';
import { useSelector } from 'react-redux';
import { getDataJobListingDetail } from 'redux/jobs/jobListings/selectors';
import JobListingInfo from '../JobListingInfo';
import JobListingTabs from '../JobListingTabs/index';

const JobListingDetail = () => {
  const currentData = useSelector(getDataJobListingDetail);

  return (
    <div className="mt-32">
      <Row gutter={24}>
        <Col xl={6} lg={8} md={24} xs={24}>
          <JobListingInfo currentData={currentData} />
        </Col>

        <Col xl={18} lg={16} md={24} xs={24}>
          <JobListingTabs currentData={currentData} />
        </Col>
      </Row>
    </div>
  );
};

export default JobListingDetail;

export const CREATE_AD_FINAL_STEP_INDEX = 4;

export const PARAM_START_SCREEN = 'start';

export const NEW_AUDIENCE_TAB_KEY = 'newAudience';

export const EXISTING_AUDIENCE_TAB_KEY = 'existingAudience';
export const AD_TYPES_NAME = {
  SINGLE_IMAGE_ADVERTISEMENT: 'Single image advertisement',
  SINGLE_VIDEO_ADVERTISEMENT: 'Single video advertisement',
  COLLECTION_ADVERTISEMENT: 'Collection advertisement',
};
export const DEFAULT_TYPE_ID = '1';
export const DEFAULT_CALLING_BUTTON_ID = '1';
export const ADS_IDENTITY_KEY = 'adsIdentity';
export const NO_ASK_SAVE_EXIT_CONFIRM = 'noAskSaveAndExitConfirm';
export const NO_ASK_EXIT_CONFIRM = 'noAskExitConfirm';

export const HEADLINE_LIMIT = 35;

export const DESCRIPTION_LIMIT = 70;
export const DEFAULT_SORT_ADVERTISEMENT = {
  orderByKey: 'createdAt',
  orderByType: 'DESC',
};

export const DEFAULT_CURRENT_PAGE = 1;

export const LIMIT_ITEM = 10;

export const DELIVERY_STATUS = {
  draft: 'Draft',
  schedule: 'Scheduled',
  active: 'Active',
  inactive: 'Inactive',
};

export const CHANNEL = {
  web: 'Web',
  app: 'App',
};

export const USER_TYPE = {
  all: 'All',
  web: 'Parents',
  app: 'Job Seekers',
};

export const IDENTITY = {
  all: 'All',
  childcareRelated: 'Childcare Related',
  SocialRelated: 'Social Related',
  SocialChildcareRelated: 'Social & Childcare Related',
  blank: 'Blank',
};

export const PARAM_ADS_EXISTING_SCREEN = 'ads-existing';

export const DEFAULT_IMAGE_NUMBER = 1;

export const IGNORE_ASK_EXIT_CONFIRM = 'ignoreAskExitConfirm';

export const AD_STEP = {
  TYPE_OF_AD: 0,
  AD_CREATIVE: 1,
  IDENTITY: 2,
  TARGETED_AUDIENCE: 3,
  DURATION: 4,
};

export const CLOSE_ADS_STATUS = {
  backgroundColor: '#EBECF0',
  color: '#758098',
  value: 'Inactive',
};

export const NEW_FEATURE_ANNOUNCEMENT_KEY = 'showNewFeatureAnnouncement';

import { Col, Row } from 'antd';
import SummaryBenchmarkingCard from 'components/common/SummaryBenchmarkingCard';
import {
  CompanyIcon,
  TotalApprovedPlacesIcon,
  TotalRevenueIcon,
  TotalYourCentresIcon,
} from 'components/common/SVGIcon';
import { summaryTitle } from 'containers/CompanyBenchmarking/utils';
import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import {
  getTotalStatictsOfCompanyBenchmarking,
  getUserStatictisOfCompanyBenchmarking,
  getNationalStates,
  getActivePrimaryChildCareServices,
} from 'redux/companyBenchmarking/selectors';
import { getFilterFromUrl } from 'utils/tools';

const Summaries = ({ ownedCompanies }) => {
  const totalStaticts = useSelector(getTotalStatictsOfCompanyBenchmarking);
  const userStatictis = useSelector(getUserStatictisOfCompanyBenchmarking);
  const primaryChildCareServices = useSelector(
    getActivePrimaryChildCareServices,
  );
  const nationalStates = Object.values(useSelector(getNationalStates));
  const { search, pathname } = useLocation();
  const { filter } = getFilterFromUrl(search);

  const summaries = [
    {
      yourTotalTitle: 'insight.summaries.yourCompany',
      yourTotalValue: userStatictis?.company,
      allTotalTitle: 'insight.summaries.totalCompany',
      allTotalValue: totalStaticts?.company,
      IconComponent: CompanyIcon,
    },
    {
      yourTotalTitle: 'insight.summaries.yourCentres',
      yourTotalValue: userStatictis?.centres,
      allTotalTitle: 'insight.summaries.totalCentres',
      allTotalValue: totalStaticts?.centres,
      IconComponent: TotalYourCentresIcon,
      percentShareValue: userStatictis?.percentCentreShare,
      isNoUserCentre: !Number(userStatictis?.centres),
    },
    {
      yourTotalTitle: 'insight.summaries.yourApprovedPlaces',
      yourTotalValue: userStatictis?.approvedPlaces,
      allTotalTitle: 'insight.summaries.totalApproved',
      allTotalValue: totalStaticts?.approvedPlaces,
      IconComponent: TotalApprovedPlacesIcon,
      percentShareValue: userStatictis?.percentApprovedPlacesShare,
      isNoUserCentre: !Number(userStatictis?.centres),
    },
    {
      yourTotalTitle: 'insight.summaries.yourEstRevenue',
      yourTotalValue: userStatictis?.revenuePotential,
      allTotalTitle: 'insight.summaries.totalEstRevenue',
      allTotalValue: totalStaticts?.revenuePotential,
      IconComponent: TotalRevenueIcon,
      percentShareValue: userStatictis?.percentRevenuePotentialShare,
      isMoney: true,
      isNoUserCentre: !Number(userStatictis?.centres),
    },
  ];

  return (
    <div>
      <div className="mb-20 text-primary fw-bold text-16 mt-32">
        {summaryTitle(
          pathname,
          primaryChildCareServices,
          nationalStates,
          ownedCompanies,
          filter,
        )}
      </div>
      <Row gutter={[25, 25]} className="mb-20">
        {summaries.map((item, index) => (
          <Col xl={6} md={12} xs={24} key={Number(index)}>
            <SummaryBenchmarkingCard {...item} />
          </Col>
        ))}
      </Row>
    </div>
  );
};

Summaries.propTypes = {
  resultFilter: PropTypes.object,
  ownedCompanies: PropTypes.array,
};

export default Summaries;

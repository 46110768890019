import styled from 'styled-components';

export default styled.div`
  .not-seen-row {
    background: #fafafa;
  }
`;

export const ListStyles = styled.div`
  display: flex;
  flex-direction: column;
  .ant-tabs {
    flex-grow: 1;
    .ant-tabs-content,
    .board-tab {
      height: 100%;
    }
  }
  .hidden-tab {
    display: none;
  }
  .container-wrapper {
    flex-grow: 1;
    position: relative;
    .board-container {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      @media only screen and (max-height: 800px) {
        height: 500px;
        position: relative;
      }
    }
  }
`;

export const HeaderFilterStyles = styled.div`
  .title-tab {
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    font-size: 16px;
  }
`;

export const ServiceAppliedTableStyled = styled.div`
  .tag-service > .service-item-wrapper {
    margin-bottom: 10px;
  }
  .service-name {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    word-break: break-all;
    white-space: nowrap;
    display: inline-block;
    color: #316dde;
    background: rgba(50, 164, 252, 0.2);
    border-radius: 8px;
    padding: 2px 10px;
    max-width: 100%;
  }
  .required-days {
    color: ${({ theme }) => theme.text.mainL2};
    & > span {
      margin-left: 5px;
      display: inline-block;
      font-size: 12px;
    }
  }
  .age-group-service {
    font-size: 12px;
    color: ${({ theme }) => theme.text.secondary};
    margin-left: 10px;
  }
`;

export const ActionsWrapper = styled.div`
  .ant-btn:not(:disabled) .anticon {
    color: ${({ theme }) => theme.text.grayNeutral500} !important;

    &:hover {
      color: ${({ theme }) => theme.palette.primary} !important;
    }
  }

  .anticon {
    font-size: 20px;
  }
`;

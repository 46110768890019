import React from 'react';
import { CardSpaceStyle } from 'components/GridCards/styles';
import { SpaceProps } from 'antd/lib/space';
import { Responsive } from 'types';

interface GridCardProps extends SpaceProps {
  children?: React.ReactNode;
  gap?: string;
  columns?: Responsive;
}

const GridCards = ({ children, ...props }: GridCardProps) => (
  <CardSpaceStyle size={[18, 18]} wrap className="w-full" {...props}>
    {children}
  </CardSpaceStyle>
);

export default GridCards;

import React from 'react';

import CheckPermission from 'components/common/CheckPermissions';
import FallbackDropdown from './FallbackDropdown';
import { DropdownStatusInfoStyles } from './styles';
import DropdownStatus from './DropdownStatus';

const DropdownStatusInfo = (props) => (
  <DropdownStatusInfoStyles>
    <CheckPermission
      permissionKey="applications"
      action="isEdit"
      isDisabled
      fallback={<FallbackDropdown {...props} />}
    >
      <DropdownStatus {...props} />
    </CheckPermission>
  </DropdownStatusInfoStyles>
);

DropdownStatusInfo.defaultProps = {};

export default DropdownStatusInfo;

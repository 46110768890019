import styled from 'styled-components';

export const ProgramLearningCardStyles = styled.div`
  .ant-card {
    border-radius: 16px;
    border: none;
  }
  .ant-card-cover img {
    border-radius: 16px 16px 0 0;
  }
  .ant-card-bordered .ant-card-cover {
    margin: 0;
  }
`;

import styled from 'styled-components';

const ServicesTabStyles = styled.div`
  .header-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    .title-name {
      font-weight: bold;
      font-size: 20px;
      line-height: 25px;
    }
    .add-button {
      background: ${({ theme }) => theme.color.blueSecondary};
      border: none;
      color: #fff;
      font-weight: bold;
    }
  }

  .group-actions {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .ant-btn .anticon {
    &:hover {
      color: ${({ theme }) => theme.border.primary};
    }
  }
`;

export const ProgramSelectStyle = styled.div`
  .ant-form-item {
    width: 100%;
    margin-bottom: 0;
  }
`;

export default ServicesTabStyles;

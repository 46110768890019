import React from 'react';
import { Button, Col, Layout, Row } from 'antd';
import { LeftOutlined } from '@ant-design/icons';
import i18next from 'i18next';
import { getLoadingCreateJobTemplate } from 'redux/jobs/jobTemplates/selectors';
import { useSelector } from 'react-redux';

interface Props {
  handleGoToPrevStep: () => void;
  handleSubmitForm: (params: { isPublishAndCreateJobListing: boolean }) => void;
}

const FinalStepFooter = ({ handleGoToPrevStep, handleSubmitForm }: Props) => {
  const createLoading = useSelector(getLoadingCreateJobTemplate);

  const isDisabledPublish = createLoading;

  return (
    <Layout.Footer className="!z-50">
      <Row justify="space-between">
        <Col>
          <Button
            onClick={handleGoToPrevStep}
            icon={<LeftOutlined />}
            className="btn-back"
          >
            {i18next.t('button.back')}
          </Button>
        </Col>
        <Row gutter={[20, 20]}>
          <Col>
            <Button
              onClick={() =>
                handleSubmitForm({ isPublishAndCreateJobListing: false })
              }
              disabled={isDisabledPublish}
              className="btn-back"
            >
              {i18next.t('button.publish&ViewTemplate')}
            </Button>
          </Col>
          <Col>
            <Button
              type="primary"
              onClick={() =>
                handleSubmitForm({ isPublishAndCreateJobListing: true })
              }
              disabled={isDisabledPublish}
            >
              {i18next.t('button.publish&CreateJobListing')}
            </Button>
          </Col>
        </Row>
      </Row>
    </Layout.Footer>
  );
};

export default FinalStepFooter;

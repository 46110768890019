import React, {
  forwardRef,
  useImperativeHandle,
  useState,
  useRef,
} from 'react';
import PropTypes from 'prop-types';
import RestEditor from 'components/RestInput/RestEditor';
import JobTemplateModal from 'containers/JobTemplates/components/Modals';

const AboutYouModal = forwardRef(({ aboutCandidate }, ref) => {
  const [visible, setVisible] = useState(false);
  const editorRef = useRef();

  useImperativeHandle(
    ref,
    () => ({
      toggleModal: () => {
        setVisible(true);
      },
    }),
    [],
  );

  const onCancel = () => {
    setVisible(false);
  };

  return (
    <JobTemplateModal
      title="jobs.moreAboutTheJob.aboutYou"
      visible={visible}
      onCancel={onCancel}
      width={1000}
    >
      <RestEditor
        defaultValue={aboutCandidate}
        editorRef={editorRef}
        source="aboutCandidate"
        header="jobs.moreAboutTheJob.aboutYou"
        height={600}
      />
    </JobTemplateModal>
  );
});

AboutYouModal.propTypes = {
  aboutCandidate: PropTypes.string,
};

export default AboutYouModal;

import React from 'react';
import i18next from 'i18next';
import PageTitle from 'components/common/PageTitle';
import { Space } from 'antd';
import { CopyIcon } from 'components/common/SVGIcon';
import { PlusCircleOutlined, RightOutlined } from '@ant-design/icons';
import { useHistory, useParams } from 'react-router-dom';
import {
  CreateStaffProfileContainer,
  OptionButton,
} from 'containers/Centres/components/CentreTabs/EducatorStaffProfilesTab/Create/styles';
import { useDispatch } from 'react-redux';
import { resetStaffProfilePayload } from 'redux/staffProfile/slice';
import { FormInstance } from 'rc-field-form';

interface Props {
  form?: FormInstance;
}

const CreateProfileOptions = ({ form }: Props) => {
  const { push } = useHistory();
  const { centreId } = useParams();
  const dispatch = useDispatch();

  const onCreateExitingProfile = () => {
    push(`/educator-staff-profile/${centreId}/create/existing-users`);
    dispatch(resetStaffProfilePayload());
  };

  const onCreateNewProfile = () => {
    dispatch(resetStaffProfilePayload());
    form.resetFields();
    push(`/educator-staff-profile/${centreId}/create/steps`);
  };

  return (
    <CreateStaffProfileContainer>
      <PageTitle>{i18next.t('educatorStaffProfile.creatProfile')}</PageTitle>
      <Space direction="vertical" size={20} className="w-full">
        <OptionButton
          icon={<CopyIcon className="text-20 mr-10" />}
          onClick={onCreateExitingProfile}
        >
          {i18next.t('educatorStaffProfile.createFromExisting')}
          <RightOutlined className="arrow-icon" />
        </OptionButton>
        <OptionButton
          icon={<PlusCircleOutlined className="text-20 mr-10" />}
          onClick={onCreateNewProfile}
        >
          {i18next.t('educatorStaffProfile.createNew')}
          <RightOutlined className="arrow-icon" />
        </OptionButton>
      </Space>
    </CreateStaffProfileContainer>
  );
};

export default CreateProfileOptions;

import moment from 'moment';
import { START_A_NEW_JOB_CONST } from './constants';

export const transformDataCreateOnSubmit = (payload = {}) => {
  const { timeOption, selectedOrganizations, jobReferences, campaignId } =
    payload;

  const isSelectAll = payload.isSelectAll || payload.indeterminate;

  const isImmediateStart =
    timeOption === START_A_NEW_JOB_CONST.immediateStart.value;

  const organizations = selectedOrganizations
    ?.filter((id) => typeof id === 'string')
    .map((id) => ({
      organizationId: id,
      jobReference: jobReferences?.[id],
    }));

  const endDateDefault = moment(payload.endDateDefault)
    .endOf('day')
    .toISOString();

  const endDates = payload.endDates?.map((item) => ({
    organizationIds: item?.organizationIds,
    endDate: moment(item?.endDate).endOf('day').toISOString(),
  }));

  const startDate = payload.startDate
    ? moment(payload.startDate).startOf('day').toISOString()
    : moment().startOf('day').toISOString();

  const filter = (() => {
    if (isSelectAll) {
      if (payload.indeterminate) {
        return {
          ...payload.filter,
          notInIds: payload.unSelectedOrgs,
        };
      }

      return payload.filter;
    }
    return null;
  })();

  return {
    templateId: payload.templateId,
    isSelectAll: Boolean(isSelectAll),
    isImmediateStart,
    organizations,
    campaignId,
    endDateDefault,
    endDates,
    startDate,
    filter,
  };
};

import styled from 'styled-components';
import { Typography } from 'antd';

export const LinkStyle = styled(Typography.Link)`
  .show-hide-btn {
    border: none;
    font-size: 14px;
    font-weight: bold;
    margin-top: 20px;

    &__next {
      color: ${({ theme }) => theme.text.text};
      position: relative;
    }

    &__previous {
      color: ${({ theme }) => theme.text.mainL2};
      position: relative;
    }
  }

  .show-hide-btn:hover {
    color: ${({ theme }) => theme.background.primary};
  }

  .feature-icon {
    color: #faad14;
    position: absolute;
    right: -9px;
    top: -6px;
  }
`;

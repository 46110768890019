import React from 'react';
import i18next from 'i18next';
import { Link } from 'react-router-dom';
import { Button } from 'antd';
import ResultWrapper from 'components/common/ResultWrapper';

const ResetPasswordExpired = () => (
  <ResultWrapper
    status="warning"
    title={i18next.t('resetPassword.tokenExpired')}
    style={{ paddingLeft: 0, paddingRight: 0 }}
    extra={
      <Button type="primary" className="w-full">
        <Link to="/login">{i18next.t('login.loginBtn')}</Link>
      </Button>
    }
  />
);

export default ResetPasswordExpired;

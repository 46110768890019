import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import i18next from 'i18next';
import { useSelector } from 'react-redux';
import Radio from 'antd/lib/radio';
import { map } from 'lodash-es';

import { ENQUIRIES_STATUS_CONST } from 'configs/localData';

const StatusGroups = ({ handleChangeTab, tab }) => {
  const summary = useSelector((state) => state.enquiries.summaries) || {};

  const formatTitle = useCallback(
    (text, count) => `${i18next.t(text)} (${count || 0})`,
    [],
  );

  const onChangeStatus = (e) => {
    handleChangeTab(e.target.value);
  };

  return (
    <div className="enquiries__status-groups">
      <Radio.Group value={tab} onChange={onChangeStatus}>
        <Radio.Button key="all" value="all">
          {formatTitle('common.all', summary.total)}
        </Radio.Button>
        {map(ENQUIRIES_STATUS_CONST, (item, idx) => (
          <Radio.Button key={String(idx)} value={item.key}>
            {formatTitle(item.text, summary?.[item.keySummary])}
          </Radio.Button>
        ))}
      </Radio.Group>
    </div>
  );
};

StatusGroups.propTypes = {
  handleChangeTab: PropTypes.func,
  tab: PropTypes.string,
};

export default StatusGroups;

import React from 'react';
import i18next from 'i18next';
import { Button, Form, FormInstance, Input, Tooltip } from 'antd';
import { QuestionFillIcon } from 'components/common/SVGIcon';
import { getConfirmVirtualTourURLRules } from 'utils/validateUtils';
import { getVirtualTourPreview } from 'redux/centres/actions';
import { useAppDispatch } from 'redux/hooks';

interface Props {
  addURLVideo: (urlVideo: string, result: string) => void;
  form: FormInstance;
}

const UrlVideoForm = ({ addURLVideo, form }: Props) => {
  const dispatch = useAppDispatch();
  const handleAddUrlVideo = () => {
    form.validateFields(['urlVideo']).then((values) => {
      if (values.urlVideo?.trim()) {
        dispatch(
          getVirtualTourPreview({
            url: values?.urlVideo?.trim(),
          }),
        ).then(({ payload }) => {
          if (payload?.result) {
            addURLVideo(values.urlVideo, payload?.result);
            form.setFieldsValue({
              urlVideo: null,
            });
          }
        });
      }
    });
  };
  return (
    <div>
      <div className="flex items-center mb-8">
        <strong>{i18next.t('centreDetail.insertVirtualTourLink')}</strong>
        <Tooltip
          placement="topLeft"
          title={i18next.t('centreDetail.messageInfoVirtualTour')}
        >
          <QuestionFillIcon className="ml-6" />
        </Tooltip>
      </div>

      <div className="flex">
        <Form.Item
          className="flex-1"
          name="urlVideo"
          rules={getConfirmVirtualTourURLRules()}
        >
          <Input placeholder={i18next.t('input.videoUrl.placeholder')} />
        </Form.Item>
        <Button type="primary" className="ml-10" onClick={handleAddUrlVideo}>
          {i18next.t('button.add')}
        </Button>
      </div>
    </div>
  );
};

export default UrlVideoForm;

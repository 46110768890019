import styled from 'styled-components';

const CustomerInfoStyles = styled.div`
  background: #fff;
  position: relative;
  border-radius: 8px;
  .edit-wrapper {
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 2;
  }
  .edit-div-button {
    position: absolute;
    top: 10px;
    right: 10px;
  }

  .avatar-centre {
    .ant-avatar {
      width: 100% !important;
      border-radius: 8px 8px 0px 0px;
    }
  }
  .content-info-container {
    padding: 20px;
    .name-centre {
      font-weight: bold;
      font-size: 24px;
      line-height: 30px;
      margin-bottom: 15px;
      .title-centre {
        margin-right: 10px;
      }
      .ant-badge {
        font-size: 16px;
      }
    }
  }
  .show-hide-btn {
    font-size: 16px;
    color: ${({ theme }) => theme.color.blueSecondary};
    font-weight: bold;
  }
  .title-info {
    font-weight: bold;
    font-size: 18px;
    line-height: 23px;
  }
  .title-info-other {
    font-weight: bold;
    font-size: 18px;
    line-height: 23px;
    margin-bottom: 15px;
  }
  .phone-item-info {
    > span {
      display: block;
    }
    > span:not(:last-child) {
      margin-bottom: 15px;
    }
  }

  .value-info-item {
    word-break: break-word;
  }

  .content-info-other {
    > .item-info {
      display: flex;
      font-size: 18px;
      .ant-tag {
        font-size: 18px;
      }
      &:not(:last-child) {
        margin-bottom: 15px;
      }
      & > .anticon {
        font-size: 16px;
        color: ${({ theme }) => theme.palette.primary};
        line-height: 30px;
        margin-right: 20px;
      }
    }
  }

  .address-section {
    .address-group > span {
      display: block;
    }
  }

  .ant-progress-status-success .ant-progress-bg {
    background-color: #1890ff;
  }

  .ant-progress-inner {
    background: #dee1e4;
  }
`;

export default CustomerInfoStyles;

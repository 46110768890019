import PropTypes from 'prop-types';
import { Button, Col, Form, Row } from 'antd';
import { CloseOutlined, PlusOutlined } from '@ant-design/icons';
import React, { useRef, useEffect, useContext } from 'react';
import { isEmpty } from 'lodash-es';
import i18next from 'i18next';
import { JobAdditionFormStyle } from 'containers/JobTemplates/styles';
import DiamondPoint from 'components/common/DiamondPoint';
import { RestInputContext } from 'components/RestInput/RestInputContext';

const JobAdditionForm = ({ children, name, initialValue }) => {
  const addBtnRef = useRef();
  const { form } = useContext(RestInputContext);

  const onAddJobs = () => {
    addBtnRef.current.click();
  };
  const onRemoveItem = (field, remove) => {
    remove(field.name);
  };

  useEffect(() => {
    if (!isEmpty(initialValue)) {
      form.setFieldsValue({
        [name]: initialValue,
      });
    }
  }, [form, name, initialValue]);

  return (
    <JobAdditionFormStyle>
      <Form.List
        name={name}
        initialValue={initialValue || ['']}
        rules={[
          {
            message: i18next.t('input.jobHighlight.validateMsg.invalid'),
            validator(_, value) {
              return isEmpty(value) ? Promise.reject() : Promise.resolve();
            },
          },
        ]}
      >
        {(fields, { add, remove }, { errors }) => (
          <>
            {fields.map((field) => (
              <Row key={field.key} align="middle">
                <Col xl={1} lg={1} md={1} sm={2} xs={2}>
                  <DiamondPoint />
                </Col>
                <Col xl={22} lg={22} md={22} sm={21} xs={21}>
                  {React.cloneElement(children, {
                    field,
                  })}
                </Col>
                <Col xl={1} lg={1} md={1} sm={1} xs={1}>
                  <Button
                    type="text"
                    onClick={() => onRemoveItem(field, remove)}
                    icon={<CloseOutlined />}
                    className="mb-20"
                  />
                </Col>
              </Row>
            ))}
            <div className="d-none">
              <Button
                ref={addBtnRef}
                type="dashed"
                onClick={() => add()}
                icon={<PlusOutlined />}
                className="w-full"
              />
            </div>
            {fields?.length < 5 && (
              <div
                role="presentation"
                className="cursor-pointer text-primary fw-bold text-16 w-100"
                onClick={onAddJobs}
              >
                {'+ '}
                {i18next.t('jobs.moreAboutTheJob.addMore')}
              </div>
            )}
            <Form.ErrorList errors={errors} />
          </>
        )}
      </Form.List>
    </JobAdditionFormStyle>
  );
};

JobAdditionForm.propTypes = {
  children: PropTypes.any,
  name: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  initialValue: PropTypes.any,
};

export default JobAdditionForm;

import theme from 'configs/theme';
import styled from 'styled-components';

export const JobTemplateCardStyles = styled.div`
  cursor: pointer;
  background: #ffffff;
  box-shadow: 0px 0px 1px rgba(12, 26, 75, 0.1),
    0px 4px 20px -2px rgba(50, 50, 71, 0.08);
  border-radius: 12px;
  padding: 16px;
  cursor: pointer;
  &:hover {
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
      0 2px 4px -1px rgba(0, 0, 0, 0.06);
  }

  display: flex;
  flex-direction: column;
  gap: 12px;

  .tag {
    background-color: ${theme.background.lightGreen};
    height: 24px;
    width: 32px;
    text-align: center;
    font-weight: 700;
    color: ${theme.palette.green};
  }

  .title {
    color: #141533;
    font-size: 16px;
    margin: 0;
  }

  .description {
    color: #141533;
    margin: 0;
  }

  .ant-divider {
    margin: 4px 0;
  }

  .dot {
    width: 4px;
    height: 4px;
    background: ${theme.text.gray300};
    border-radius: 50%;
  }

  .job-type {
    color: #141533;
    font-weight: 600;
  }

  .statistic {
    display: flex;
    gap: 4px;
    height: 54px;

    .decor-line {
      border-radius: 9999px;
      width: 4px;
      height: 100%;
      margin-right: 6px;

      &--green {
        background-color: ${theme.palette.green};
      }
      &--pink {
        background-color: ${theme.palette.pink};
      }
    }
  }
`;

export const JobList = styled.div`
  display: grid;
  gap: 24px;
  grid-template-columns: repeat(auto-fill, minmax(390px, 1fr));
`;

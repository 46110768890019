import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { debounce } from 'lodash-es';
import { Form } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import {
  retrieveReference as retrieveReferenceAction,
  retrieveReferenceInputData as retrieveReferenceInputDataAction,
} from 'redux/referenceData/actions';
import { getRecordData } from 'utils/tools';
import { RestInputContext } from 'components/RestInput/RestInputContext';
import {
  getEnabledLoadMoreReference,
  getReferenceInputResource,
  getReferenceLoading,
} from '../../../redux/referenceData/selectors';

function ReferenceInput(props) {
  const {
    record,
    children,
    source,
    setFieldsValue,
    searchKey,
    resource,
    initialFilter,
    dependency,
    style,
    filterKey,
    mappedBy,
    prefixUrl,
    searchOutsideFilter,
    notLikeFilter,
    initialValue,
    requestParams,
  } = props;
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const referenceInputResource = useSelector((state) =>
    getReferenceInputResource(state, props),
  );
  const resourceData = initialValue
    ? [initialValue, ...referenceInputResource]
    : referenceInputResource;

  const loadingData = useSelector((state) => getReferenceLoading(state, props));
  const enabledLoadMore = useSelector((state) =>
    getEnabledLoadMoreReference(state, props),
  );

  const retrieveReference = (data) =>
    dispatch(
      retrieveReferenceAction({
        resource: props.resource,
        ids: Array.isArray(data) ? data : [data],
        mappedBy,
        filterKey,
        customApiResource: props.customApiResource,
      }),
    );
  const retrieveList = (filter, isRefresh) => {
    dispatch(
      retrieveReferenceInputDataAction({
        resource,
        data: {
          ...props.initialFilter,
          ...filter,
          filter: {
            ...props.initialFilter?.filter,
            ...filter?.filter,
          },
        },
        options: {
          primaryKey: mappedBy,
          isRefresh,
          customApiResource: props.customApiResource,
          prefixUrl,
          requestParams,
        },
      }),
    );
  };

  const onSearch = useCallback((value) => {
    if (searchKey) {
      if (searchKey === 'q' || searchOutsideFilter) {
        retrieveList({ outsideFilter: { [searchKey]: value?.trim() } }, true);
      } else
        retrieveList(
          { filter: { [searchKey]: notLikeFilter ? value : { $like: value } } },
          true,
        );
    }
    // eslint-disable-next-line
  }, []);

  const onClear = useCallback(() => {
    retrieveList({ filter: {} }, true);
    // eslint-disable-next-line
  }, []);

  const debounceSearch = debounce(onSearch, 300);

  const retrieveListWaypoint = () => {
    if (enabledLoadMore) {
      retrieveList();
    }
  };

  useEffect(() => {
    if (getRecordData(record, source)) {
      retrieveReference(getRecordData(record, source));
    }
    retrieveList(initialFilter || { limit: 10, offset: 0, filter: {} }, true);
    // eslint-disable-next-line
  }, [dependency]);

  const newChildren = React.cloneElement(children, {
    onSearch: (value) => {
      debounceSearch(value);
    },
    onClear,
    onBlur: debounce(onClear, 600),
    onEnter: () => retrieveListWaypoint(),
    searchKey,
    record,
    loading: loadingData,
    form,
    source,
    setFieldsValue,
    resourceData,
    resource,
    style,
  });
  return newChildren;
}

ReferenceInput.propTypes = {
  resource: PropTypes.string.isRequired,
  resourceData: PropTypes.array,
  record: PropTypes.object,
  retrieveList: PropTypes.func,
  children: PropTypes.node,
  source: PropTypes.string,
  retrieveReference: PropTypes.func,
  setFieldsValue: PropTypes.func,
  form: PropTypes.object,
  searchKey: PropTypes.string,
  enabledLoadMore: PropTypes.bool,
  loadingData: PropTypes.bool,
  initialFilter: PropTypes.object,
  dependency: PropTypes.any,
  style: PropTypes.object,
  searchOutsideFilter: PropTypes.bool,
  notLikeFilter: PropTypes.bool,
};

function ReferenceInputWrapper(props) {
  return (
    <RestInputContext.Consumer>
      {({ record }) => <ReferenceInput record={record} {...props} />}
    </RestInputContext.Consumer>
  );
}

export default ReferenceInputWrapper;

import styled from 'styled-components';

export const ResendBoxStyles = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  padding: 16px 20px 16px 24px;
  background: ${({ theme }) => theme.color.lightBlue};
  border-left: 4px solid ${({ theme }) => theme.color.blueSecondary};
  border-radius: 16px;
  margin: 32px auto;

  .ant-btn {
    font-weight: 700;
    font-size: 14px;
    line-height: 24px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #ffffff;
    border-radius: 12px;
    margin-top: 16px;
  }

  .ant-btn:not(:disabled) {
    border-color: ${({ theme }) => theme.color.blueSecondary};
    background: ${({ theme }) => theme.color.blueSecondary};
  }

  h5,
  p {
    line-height: 1.7;
    margin: 0;
  }

  a {
    color: ${({ theme }) => theme.color.blueSecondary};
  }

  @media only screen and (min-width: 2000px) {
    --text-font-size: 20px;

    .anticon,
    h5 {
      font-size: var(--text-font-size);
    }

    p {
      font-size: calc(var(--text-font-size) - 4px);
    }

    .ant-btn {
      font-size: 18px;
      padding: 12px 24px;
      height: unset !important;
    }
  }

  @media only screen and (min-width: 2400px) {
    --text-font-size: 25px;

    .ant-btn {
      font-size: 24px;
      padding: 18px 28px;
    }
  }

  @media only screen and (min-width: 3000px) {
    --text-font-size: 30px;

    .ant-btn {
      font-size: 28px;
      padding: 18px 42px;
    }
  }

  @media only screen and (min-width: 3400px) {
    --text-font-size: calc(16px + 0.390625vw);
  }

  @media only screen and (max-width: 576px) {
    padding: 16px 20px;
    h5 {
      font-size: 16px;
    }

    .header {
      align-items: flex-start;
    }

    .anticon {
      margin-top: 6px;
    }
  }
`;

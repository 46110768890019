import React, { useMemo } from 'react';
import { Button, Modal } from 'antd';
import i18next from 'i18next';
import PropTypes from 'prop-types';
import { APPLICATION_STATUS_CONSTANT } from 'configs/localData';

const DeclineButton = ({ child, handleUpdateStatus, disabled }) => {
  const infoObj = useMemo(
    () => ({
      childName: `${child.firstName || ''} ${child.lastName || ''}`,
    }),
    [child],
  );

  const handleSubmit = () => {
    Modal.confirm({
      title: i18next.t('applications.confirmTitle'),
      content: i18next.t('applications.confirmDecline', infoObj),
      onOk: () => {
        handleUpdateStatus(APPLICATION_STATUS_CONSTANT.declined);
      },
    });
  };

  return (
    <Button onClick={handleSubmit} disabled={disabled}>
      {i18next.t('button.declineApplication')}
    </Button>
  );
};

DeclineButton.propTypes = {
  child: PropTypes.object,
  handleUpdateStatus: PropTypes.func,
  disabled: PropTypes.bool,
};

DeclineButton.defaultProps = {
  child: {},
  handleUpdateStatus: () => null,
};

export default DeclineButton;

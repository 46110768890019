import styled from 'styled-components';

const MailboxTabStyles = styled.div`
  .mailbox-container {
    height: auto;
  }

  #message-body {
    min-height: 250px;
  }
`;

export default MailboxTabStyles;

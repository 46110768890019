import { createSlice } from '@reduxjs/toolkit';
import { uniqBy } from 'lodash-es';
import {
  getAllCustomFail,
  getAllCustomPending,
  INITIAL_STATE_CUSTOM,
} from 'redux/crudCreatorCustom/utils';
import {
  getAllPriceBenchmarking,
  getPriceBenchmarkingSummary,
} from './actions';

export const initialState = {
  ...INITIAL_STATE_CUSTOM,
  summary: {},
};

const priceBenchmarkingSuccess = (state, { payload: { data, options } }) => {
  if (options.isSort) {
    state.data = [];
    state.ids = [];
  }

  state.loading = false;
  state.ids = data.ids;
  state.data = uniqBy([...state.data, ...Object.values(data.data)], 'id');
  state.averageAllCentre = data.additionData.averageAllCentre;
  state.resourceData.total = data.total;
  state.resourceData.numberOfPages = data?.numberOfPages;
};

const priceBenchmarkingSummarySuccess = (state, { payload: { data } }) => {
  state.summary = data;
  state.summary.loading = false;
};

const priceBenchmarkingSummaryPending = (state) => {
  state.summary.loading = true;
};

const priceBenchmarkingSummaryFail = (state) => {
  state.summary.loading = false;
};

const { actions, reducer } = createSlice({
  name: 'priceBenchmarking',
  initialState,
  reducers: {
    resetResourceFilterPriceBenchMarking: (state) => {
      state.ids = [];
      state.data = [];
      state.averageAllCentre = {};
      state.total = 0;
      state.resourceData.outsideFilter = {};
      state.summary = {};
    },
  },
  extraReducers: {
    [getAllPriceBenchmarking.pending]: getAllCustomPending,
    [getAllPriceBenchmarking.fulfilled]: priceBenchmarkingSuccess,
    [getAllPriceBenchmarking.rejected]: getAllCustomFail,

    [getPriceBenchmarkingSummary.pending]: priceBenchmarkingSummaryPending,
    [getPriceBenchmarkingSummary.fulfilled]: priceBenchmarkingSummarySuccess,
    [getPriceBenchmarkingSummary.rejected]: priceBenchmarkingSummaryFail,
  },
});

export const { resetResourceFilterPriceBenchMarking } = actions;

export default reducer;

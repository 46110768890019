import React from 'react';
import PropTypes from 'prop-types';
import addToInboxImage from 'assets/images/add-to-inbox.png';
import Meta from 'antd/lib/card/Meta';
import i18next from 'i18next';
import ButtonCreateJobListing from 'containers/JobListings/components/buttons/ButtonCreateJobListing';
import ButtonCreateCampaign from 'containers/JobListings/components/buttons/ButtonCreateCampaign';

const NoJobListings = ({
  title,
  description,
  isShowCreateCampaign,
  isShowCreateJobListing,
}) => (
  <div className="flex-center flex-col">
    <div>
      <img src={addToInboxImage} alt="" />
    </div>
    <Meta
      title={i18next.t(title)}
      description={i18next.t(description)}
      className="mb-20 text-center"
    />
    {isShowCreateJobListing && <ButtonCreateJobListing />}
    {isShowCreateCampaign && <ButtonCreateCampaign isRefreshCampaignList />}
  </div>
);

NoJobListings.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  isShowCreateCampaign: PropTypes.bool,
  isShowCreateJobListing: PropTypes.bool,
};

export default NoJobListings;

import React from 'react';
import i18next from 'i18next';
import { Row, Col } from 'antd';
import TaskTable from './TaskTable';
import TaskTabWrapper from './styles';
import DetailSidebar from './DetailSidebar';
import CreateButton from './CreateButton';

const TasksTab = () => {
  const [id, setId] = React.useState();

  const showTaskDetail = (taskId) => {
    setId(taskId);
  };

  return (
    <TaskTabWrapper>
      <Row gutter={[20, 20]}>
        <Col xl={16} lg={24} md={24} xs={24}>
          <div className="header-title">
            <div className="title-name">{i18next.t('task.title')}</div>
            <CreateButton />
          </div>
          <TaskTable showTaskDetail={showTaskDetail} />
        </Col>
        <Col xl={8} lg={24} md={24} xs={24}>
          <DetailSidebar taskId={id} />
        </Col>
      </Row>
    </TaskTabWrapper>
  );
};

export default TasksTab;

import React from 'react';
import { Image } from 'antd';

import SwitchAction from 'components/RestActions/SwitchAction';
import { FeatureItemResponse } from 'types/centreFeatures';
import { useUpdateCentreFeaturesMutation } from 'redux/@rtkQuery/centreFeatures';
import { useParams } from 'react-router-dom';

interface Props {
  item: FeatureItemResponse;
  checked: boolean;
  CRMPackageName?: string;
}

const FeatureItem = ({ item, checked, CRMPackageName }: Props) => {
  const { id } = useParams();

  const [updateCentreFeature] = useUpdateCentreFeaturesMutation();
  const handleUpdateFeature = (value, featureId) => {
    updateCentreFeature({
      centreId: id,
      enable: value,
      featureId,
    });
  };

  return (
    <div className="flex justify-between px-12">
      <div className="flex">
        <div className="mr-12">
          <Image width={32} height={32} src={item.icon} preview={false} />
        </div>
        <span className="text-16">{item.name}</span>
      </div>
      <div className="">
        <SwitchAction
          packageName={CRMPackageName}
          permissionKey="myCentres"
          action="isEdit"
          checked={checked}
          onChange={(value) => handleUpdateFeature(value, item?.featureId)}
        />
      </div>
    </div>
  );
};

export default FeatureItem;

import React from 'react';
import { StatusItem } from 'containers/Centres/components/CentreTabs/EducatorStaffProfilesTab/Create/Steps/styles';
import i18next from 'i18next';
import { LoadingIcon, TickCircleIcon } from 'components/common/SVGIcon';
import { Typography } from 'antd';

interface Props {
  isCompleted?: boolean;
}
const { Text } = Typography;

const EducationStatus = ({ isCompleted }: Props) =>
  isCompleted ? (
    <StatusItem backgroundColor="#EDF9F0" color="#3EBD5C">
      <Text className="mr-5 text-12 fw-bold">
        {i18next.t('educatorStaffProfile.completed')}
      </Text>
      <TickCircleIcon />
    </StatusItem>
  ) : (
    <StatusItem color="#F98437" backgroundColor="#FFF4EC">
      <Text className="mr-5 text-12 fw-bold">
        {i18next.t('educatorStaffProfile.studying')}
      </Text>
      <LoadingIcon />
    </StatusItem>
  );

export default EducationStatus;

import { Image, Skeleton } from 'antd';
import React from 'react';
import i18next from 'i18next';
import { useParams } from 'react-router-dom';
import { useGetCentreFeaturesQuery } from 'redux/@rtkQuery/centreFeatures';
import { isEmpty } from 'lodash-es';
import { CentreModel } from 'types/centre';
import FeatureCategoryItem from './FeatureCategoryItem';
import { FeaturesHeaderTableStyles } from './styles';

interface Props {
  record?: CentreModel;
}

const FeaturesTab = ({ record }: Props) => {
  const { id } = useParams();

  const { data, isLoading } = useGetCentreFeaturesQuery({
    centreId: id,
  });

  return (
    <div>
      <div className="fw-bold text-20 mb-20">
        {i18next.t('centreDetail.tabs.centreFeaturesAndInclusions')}
      </div>
      <div>
        <Skeleton loading={isLoading}>
          {data?.results?.map(
            (featureCategory) =>
              !isEmpty(featureCategory?.features) && (
                <div
                  key={featureCategory?.categoryId}
                  className="bg-white rounded-8 px-12 pt-12 mb-20"
                >
                  <FeaturesHeaderTableStyles className="fw-bold text-16 rounded-8">
                    <div className="flex items-center">
                      <div className="flex-center bg-white p-6 rounded-full mr-8">
                        <Image
                          width={25}
                          height={25}
                          src={featureCategory.icon}
                          preview={false}
                        />
                      </div>
                      <span>{featureCategory?.name}</span>
                    </div>
                  </FeaturesHeaderTableStyles>
                  <FeatureCategoryItem
                    {...featureCategory}
                    CRMPackageName={record?.CRMPackage?.name}
                  />
                </div>
              ),
          )}
        </Skeleton>
      </div>
    </div>
  );
};

export default FeaturesTab;

import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  getByKeyAppSettingsOfCentreApi,
  updateAppSettingsOfCentreApi,
} from 'api/appSettings';
import { apiWrapper } from 'utils/reduxUtils';

export const getByKeyAppSettingsOfCentre = createAsyncThunk(
  'appSettings/getByKeyAppSettingsOfCentre',
  async (payload, thunkApi) => {
    try {
      const response = await apiWrapper(
        {},
        getByKeyAppSettingsOfCentreApi,
        payload.centreId,
        payload.key,
      );
      return response;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  },
);

export const updateAppSettingsOfCentre = createAsyncThunk(
  'appSettings/updateAppSettingsOfCentre',
  async (payload, thunkApi) => {
    try {
      const response = await apiWrapper(
        {},
        updateAppSettingsOfCentreApi,
        payload.centreId,
        payload.key,
        payload.data,
      );
      return { data: response, success: true };
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  },
);

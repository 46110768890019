import React, {
  useCallback,
  useImperativeHandle,
  useState,
  forwardRef,
} from 'react';
import { Form, Input } from 'antd';
import i18next from 'i18next';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { getDocumentTypes } from 'redux/config/selectors';
import { createInformationRequest } from 'redux/applications/actions';

import { RestInputContext } from 'components/RestInput/RestInputContext';
import ModalCustom from 'components/common/ModalCustom';
import RestInputItem from 'components/RestInput/RestInputItem';
import RestSelect from 'components/RestInput/RestSelect';
import RestUploadFile from 'components/RestInput/RestUploadFile';

const CreateModal = forwardRef(({ applicationId }, ref) => {
  const [form] = Form.useForm();

  const { validateFields, resetFields } = form;

  const dispatch = useDispatch();

  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);

  useImperativeHandle(ref, () => ({
    toggleModal: () => {
      setVisible(!visible);
    },
  }));

  const documentTypes = useSelector(getDocumentTypes);

  const handleCancel = useCallback(() => {
    setVisible(false);
    resetFields();
  }, [setVisible, resetFields]);

  const onOk = useCallback(() => {
    validateFields().then((values) => {
      setLoading(true);
      dispatch(createInformationRequest({ id: applicationId, data: values }))
        .then(({ payload }) => {
          setLoading(false);
          if (payload?.data?.id) handleCancel();
        })
        .catch(() => setLoading(false));
    });
  }, [validateFields, applicationId, handleCancel, dispatch]);

  const formatResultFiles = useCallback(
    (files) =>
      files?.map((file) => ({
        name: file.name,
        url: file.response || file.url,
        type: file.type,
      })),
    [],
  );

  return (
    <ModalCustom
      title={i18next.t('informationRequests.title')}
      okText="button.request"
      visible={visible}
      onCancel={handleCancel}
      onOk={onOk}
      loading={loading}
      isDisabled={isDisabled}
    >
      <Form form={form} layout="vertical">
        <RestInputContext.Provider value={{ form, record: {} }}>
          <RestSelect
            required
            header="informationRequests.documentType"
            placeholder="informationRequests.documentType"
            source="documentTypeId"
            resourceData={documentTypes || []}
            valueProp="id"
            titleProp="name"
          />
          <RestInputItem
            required
            header="informationRequests.message"
            placeholder="informationRequests.message"
            source="message"
            ContentComponent={Input.TextArea}
            rows={4}
          />
          <RestUploadFile
            record={{}}
            setIsDisabled={setIsDisabled}
            source="files"
            label="text.uploadFile"
            accept="*"
            formatResult={formatResultFiles}
          />
        </RestInputContext.Provider>
      </Form>
    </ModalCustom>
  );
});

CreateModal.propTypes = {
  applicationId: PropTypes.string,
};

export default CreateModal;

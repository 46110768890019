import React from 'react';
import Edit from 'containers/rest/Edit';
import Form from '../components/Form';

const TransactionsEdit = (props) => (
  <Edit {...props} resource="transactions">
    <Form />
  </Edit>
);

export default TransactionsEdit;

import { Tooltip } from 'antd';
import ProgramDetails from 'containers/ProgramsAndLearning/components/ProgramDetails';
import { ProgramDetailsRef } from 'containers/ProgramsAndLearning/components/ProgramsLearningBoard';
import React, { useRef } from 'react';

interface Props {
  id?: string;
  name?: string;
  isProgramEdit?: boolean;
}

const ProgramColumn = ({ id, name, isProgramEdit }: Props) => {
  const programDetailsRef = useRef<ProgramDetailsRef>();
  const onProgramClick = () => {
    programDetailsRef.current.open(id);
  };

  return (
    <>
      <Tooltip title={name}>
        <div
          role="presentation"
          className="ellipsis-t-2 text-primary cursor-pointer text-decoration-line "
          onClick={onProgramClick}
        >
          {name}
        </div>
      </Tooltip>
      <ProgramDetails isEdit={isProgramEdit} ref={programDetailsRef} />
    </>
  );
};

export default ProgramColumn;

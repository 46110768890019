import React from 'react';
import PropTypes from 'prop-types';
import i18next from 'i18next';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { centresOfUserSelectors } from 'redux/centresOfUser/selectors';
import TableCustom from 'components/common/TableCustom';
import DeleteButton from 'components/RestActions/DeleteButton';

import { getQueryParamsWhenChangeTable } from 'utils/tableUtils';
import { delCentresOfUser } from 'redux/centresOfUser/actions';

const CentresOfUserTable = ({ retrieveList }) => {
  const dispatch = useDispatch();

  const { id } = useParams();

  const resourceFilter =
    useSelector(centresOfUserSelectors.getResourceData) || {};

  const loading = useSelector(centresOfUserSelectors.getLoading);

  const data = useSelector(centresOfUserSelectors.getDataArr);

  const deleteCentreOfUser = (centreId) =>
    dispatch(
      delCentresOfUser({
        id,
        centreId,
      }),
    );

  const columns = [
    {
      title: i18next.t('centres.name'),
      dataIndex: 'name',
      width: '15%',
      sorter: true,
      render: (data, record) => (
        <Link to={`/centres/${record.id}/show`} className="link-custom-primary">
          <span className="fw-bold">{data}</span>
        </Link>
      ),
    },
    {
      title: i18next.t('centres.centreType'),
      dataIndex: ['type', 'name'],
    },
    {
      title: i18next.t('centres.suburb'),
      dataIndex: 'city',
    },
    {
      title: i18next.t('centres.state'),
      dataIndex: 'state',
    },
    {
      title: '',
      dataIndex: 'id',
      width: 80,
      fixed: 'right',
      render: (id, record) => (
        <DeleteButton
          permissionKey="abilityChangeUserPermissions"
          action="isEdit"
          isSkipSubscription
          customMessage="centreUser.confirmDelete"
          record={record}
          deleteItem={deleteCentreOfUser}
          resource="centreUser"
        />
      ),
    },
  ];

  const onChange = (e, filters, sorter) => {
    const queryParams = getQueryParamsWhenChangeTable(e, filters, sorter);

    retrieveList({
      filter: queryParams,
      isRefresh: true,
      keepFilter: true,
    });
  };

  return (
    <TableCustom
      xScroll={800}
      onChange={onChange}
      data={data}
      columns={columns}
      loading={loading}
      isResetStyle
      pagination={{
        pageSize: resourceFilter.limit,
        current: resourceFilter.offset / resourceFilter.limit + 1 || 1,
        total: resourceFilter.total,
      }}
    />
  );
};

CentresOfUserTable.propTypes = {
  retrieveList: PropTypes.func,
};

CentresOfUserTable.defaultProps = {
  retrieveList: () => null,
};

export default CentresOfUserTable;

import React, { useState } from 'react';
import { CloseCircleFilled, PlusCircleFilled } from '@ant-design/icons';
import { Form, Modal, Typography, notification } from 'antd';
import { CurrentStepTitle } from 'containers/Centres/components/CentreTabs/EducatorStaffProfilesTab/Create/Steps/styles';
import i18next from 'i18next';
import Footer from 'containers/Centres/components/CentreTabs/EducatorStaffProfilesTab/Create/Footer';
import { nanoid } from '@reduxjs/toolkit';
import { useDispatch, useSelector } from 'react-redux';
import { getPayloadStaffProfile } from 'redux/staffProfile/selector';
import { isEmpty, isNumber, omit, sortBy } from 'lodash-es';
import { goToNextStep, setStaffProfilePayload } from 'redux/staffProfile/slice';
import EducationContainer from './EducationContainer';
import { OTHER } from '../../../constants';

const { error } = Modal;

const { Text } = Typography;
const EducationAndQualification = () => {
  const [visible, setVisible] = useState(false);
  const [editIndexCurrent, setEditIndexCurrent] = useState(null);
  const [isOther, setIsOther] = useState(false);
  const [formModalStep3] = Form.useForm();
  const staffProfileFormValues = useSelector(getPayloadStaffProfile);
  const educations = useSelector(getPayloadStaffProfile)?.educations || [];
  const dispatch = useDispatch();

  const openAddModal = () => {
    formModalStep3.resetFields();
    setVisible(true);
    setEditIndexCurrent(null);
  };

  const handleNext = () => {
    if (isEmpty(staffProfileFormValues.educations)) {
      error({
        title: i18next.t('educatorStaffProfile.messageUpdateQualification'),
        icon: <CloseCircleFilled className="text-error" />,
      });
    } else {
      dispatch(goToNextStep());
    }
  };

  const onSave = () => {
    formModalStep3.validateFields().then((values) => {
      const [name, rank] = values.name?.split('-') ?? [];
      let formNewValue = {
        ...values,
        name,
        rank: Number(rank ?? educations[editIndexCurrent]?.rank),
        id: nanoid(),
      };

      if (formNewValue?.fieldOfStudy !== OTHER)
        formNewValue = omit(formNewValue, ['otherFieldOfStudy']);

      let payloadList = [...educations];

      if (isNumber(editIndexCurrent)) {
        if (payloadList[editIndexCurrent]?.rank === formNewValue?.rank) {
          payloadList[editIndexCurrent] = formNewValue;
        } else {
          payloadList.splice(editIndexCurrent, 1);
          payloadList = [...payloadList, formNewValue];
          payloadList = sortBy(payloadList, 'rank').reverse();
        }
      } else {
        payloadList = [...payloadList, formNewValue];
        payloadList = sortBy(payloadList, 'rank').reverse();
      }

      dispatch(setStaffProfilePayload({ educations: payloadList }));

      notification.success({
        message: i18next.t('success.title'),
        description: i18next.t('success.description'),
      });
      setVisible(false);
    });
  };

  const onSaveAddAnother = () => {
    formModalStep3.validateFields().then((values) => {
      const [name, rank] = values.name?.split('-') ?? [];
      let formNewValue = {
        ...values,
        name,
        rank: Number(rank),
        id: nanoid(),
      };

      if (formNewValue?.fieldOfStudy !== OTHER) {
        formNewValue = omit(formNewValue, ['otherFieldOfStudy']);
      }

      let payloadList = [...educations, formNewValue];
      payloadList = sortBy(payloadList, 'rank').reverse();

      dispatch(setStaffProfilePayload({ educations: payloadList }));
      notification.success({
        message: i18next.t('success.title'),
        description: i18next.t('success.description'),
      });
      formModalStep3.resetFields();
      setIsOther(false);
    });
  };

  return (
    <div>
      <div className="flex-center-between mb-32">
        <CurrentStepTitle>
          {i18next.t('educatorStaffProfile.educationAndQualification')}
          <Text className="text-red">*</Text>
        </CurrentStepTitle>
        <PlusCircleFilled
          className="text-primary text-32"
          onClick={openAddModal}
        />
      </div>
      <EducationContainer
        visible={visible}
        editIndexCurrent={editIndexCurrent}
        setVisible={setVisible}
        setEditIndexCurrent={setEditIndexCurrent}
        formModalStep3={formModalStep3}
        openAddModal={openAddModal}
        onSave={onSave}
        onSaveAddAnother={onSaveAddAnother}
        isOther={isOther}
        setIsOther={setIsOther}
      />
      <Footer onNext={handleNext} />
    </div>
  );
};

export default EducationAndQualification;

import styled from 'styled-components';

export const CentreCardInfoStyles = styled.div`
  z-index: 1000;
  .cost {
    color: ${({ theme }) => theme.palette.primary};
    font-weight: bold;
  }

  .dollar-sign {
    color: ${({ theme }) => theme.palette.primary};
    transform: scale(1.1);
    margin-right: 10px;
  }

  .last-updated {
    margin-top: 10px;
    color: gray;
    font-size: 13px;
  }

  .centre-card-title {
    margin-top: 15px;
    font-weight: bold;
  }

  .ant-table-content {
    margin-top: 3px;
  }

  .ant-table-cell-row-hover {
    background: transparent !important;
  }

  .ant-card-meta-title {
    font-weight: bold;
    &:hover {
      color: ${({ theme }) => theme.palette.primary};
    }
  }

  .centre-distance {
    margin-top: 13px;
  }

  .ant-table-tbody > tr > td {
    border: none;
    padding: 5px;
  }

  .ant-table-tbody > tr {
    & > td:first-child {
      padding: 0;
      white-space: nowrap;
      text-overflow: ellipsis;
      max-width: 60px;
      overflow: hidden;
      color: ${({ theme }) => theme.palette.primary};
    }
    & > td:nth-child(2) {
      width: 50%;
      white-space: nowrap;
      text-overflow: ellipsis;
      max-width: 150px;
      overflow: hidden;
      padding-left: 10px;
    }
    & > td:last-child {
      text-align: left;
      width: 27%;
      white-space: nowrap;
      text-overflow: ellipsis;
      max-width: 120px;
      overflow: hidden;
      padding-left: 10px;
    }
  }

  .ant-card-actions {
    background-color: ${({ children }) =>
      children.props.differencevaluestatus === 'higher'
        ? '#FEEFEF'
        : '#EDF9F0'};
    border-radius: 0px 0px 8px 8px;
    & > li > span {
      &:hover {
        pointer-events: none;
      }
      color: ${({ children }) =>
        children.props.differencevaluestatus === 'higher'
          ? '#E52828'
          : '#36bf57'};
      font-weight: bold;
      font-size: 16px;
    }
  }
`;

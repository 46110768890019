import FilterCustom from 'components/common/FilterCustom';
import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import { getFilterFromUrl } from 'utils/tools';
import { formatFromToTimeFilterSubmit } from 'utils/dataUtils';
import {
  DEFAULT_CURRENT_PAGE,
  DEFAULT_SORT_CAMPAIGN,
} from 'containers/JobListings/constants';
import { Col, Row, Typography } from 'antd';
import { BoardViewIcon, ListViewIcon } from 'components/common/SVGIcon';
import i18next from 'i18next';
import FilterForm from './FilterForm';

const Filter = ({ retrieveList, pushQuery, viewMode, onViewChange }) => {
  const { search } = useLocation();
  const { filter } = getFilterFromUrl(search);

  const onSubmitFilter = ({ lastUpdatedAt, ...value }) => {
    const restFilter = {
      size: filter.size,
      page: DEFAULT_CURRENT_PAGE,
      sorts: filter.sorts || [DEFAULT_SORT_CAMPAIGN],
      filter: {
        lastUpdatedAt: formatFromToTimeFilterSubmit(lastUpdatedAt),
        ...value,
      },
    };
    retrieveList({
      filter: { ...restFilter },
      isRefresh: true,
    });
    pushQuery({
      'sorts[]': filter.sorts,
      ...restFilter,
    });
  };

  const onClearFilter = useCallback(() => {
    retrieveList({
      filter: {
        size: filter.size,
        page: filter.page || DEFAULT_CURRENT_PAGE,
        sorts: filter.sorts || [DEFAULT_SORT_CAMPAIGN],
      },
      isRefresh: true,
    });
    pushQuery({
      'sorts[]': filter.sorts,
      size: filter.size,
      page: filter.page,
    });
  }, [retrieveList, filter, pushQuery]);

  return (
    <Row justify="space-between" gutter={20}>
      <Col>
        <Row gutter={14}>
          <Col
            className={`flex-center gap-10 cursor-pointer ${
              viewMode === 'board' ? 'text-primary' : ' text-gray-400'
            }`}
            onClick={() => onViewChange('board')}
          >
            <BoardViewIcon />
            <Typography.Text
              className={`text-16 fw-bold ${
                viewMode === 'board' ? 'text-primary' : ' text-gray-400'
              }`}
            >
              {i18next.t('text.board')}
            </Typography.Text>
          </Col>
          <Col
            className={`flex-center gap-10 cursor-pointer ${
              viewMode === 'list' ? 'text-primary' : ' text-gray-400'
            }`}
            onClick={() => onViewChange('list')}
          >
            <ListViewIcon />
            <Typography.Text
              className={`text-16 fw-bold ${
                viewMode === 'list' ? 'text-primary' : ' text-gray-400'
              }`}
            >
              {i18next.t('text.list')}
            </Typography.Text>
          </Col>
        </Row>
      </Col>
      <Col flex="1 0 auto">
        <FilterCustom
          onSubmitFilter={onSubmitFilter}
          onClearFilter={onClearFilter}
          responsiveFilter={{
            xxl: 20,
            xl: 18,
            lg: 24,
            md: 24,
            xs: 24,
          }}
          responsiveAction={{
            xxl: 4,
            xl: 6,
            lg: 24,
            md: 24,
            xs: 24,
          }}
        >
          <FilterForm />
        </FilterCustom>
      </Col>
    </Row>
  );
};

Filter.propTypes = {
  retrieveList: PropTypes.func,
  pushQuery: PropTypes.func,
  viewMode: PropTypes.oneOf(['list', 'board']),
  onViewChange: PropTypes.func,
};

export default Filter;

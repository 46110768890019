import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PageTitle from 'components/common/PageTitle';
import i18next from 'i18next';

import { useLocation } from 'react-router-dom';
import { getFilterFromUrl, getQueryParams } from 'utils/tools';
import {
  SORT_ASCEND_VALUE,
  SORT_DESCEND_VALUE,
} from 'configs/localData/constant';
import {
  FilterValue,
  SorterResult,
  SortOrder,
  TablePaginationConfig,
} from 'antd/lib/table/interface';
import CentreMetricsInfo from 'containers/MarketingMetrics/components/CentreMetricsTable/CentreMetricsInfo';
import { FixedType } from 'rc-table/lib/interface';
import TableScrollCustom from 'components/common/TableScrollCustom';
import { debounce, isEmpty, isEqual, omit } from 'lodash-es';
import {
  CENTRE_METRIC_SORT_KEY,
  CentreMetrics,
  CentreMetricsPayload,
  SORT_TYPE,
} from 'redux/@rtkQuery/marketingMetris/type';
import { PushQueryParams } from 'containers/MarketingMetrics/index';
import { useGetMarketingMetricsCentresQuery } from 'redux/@rtkQuery/marketingMetris';
import {
  DEFAULT_COMPARE_LAST_7_DAYS,
  DEFAULT_CURRENT_PAGE,
  DEFAULT_LAST_7_DAYS,
  IGNORE_SEARCH_URL_PARAMS,
  LIMIT_CENTRES,
} from 'containers/MarketingMetrics/constants';
import { formatFromToTimeFilterSubmit } from 'utils/dataUtils';
import loadingGif from 'assets/icons/loading.gif';
import { CHANNEL_OPTIONS } from 'configs/localData';
import iconEmpty from 'assets/images/shape-icon-empty.png';
import { Empty } from 'antd';
import ExportCsvActions from 'components/common/ExportCsvActions';
import { CentreMetricsTableWrapper } from './style';

interface CentreMetricsTableProps {
  pushQuery?: (params: PushQueryParams) => void;
}

const CentreMetricsTable = ({ pushQuery }: CentreMetricsTableProps) => {
  const { search } = useLocation();
  const { filter: query } = getFilterFromUrl(search);

  const queryParams = getQueryParams(search);
  const formatQueryParams: CentreMetricsPayload = {
    ...queryParams?.filter,
    sortKey: queryParams?.sortKey,
    sortOrder: queryParams?.sortOrder,
  };

  const comparedTime = formatFromToTimeFilterSubmit(
    DEFAULT_COMPARE_LAST_7_DAYS,
  );
  const filteredTime = formatFromToTimeFilterSubmit(DEFAULT_LAST_7_DAYS);

  const defaultTimeRange = useMemo(
    () => ({
      filteredStart: filteredTime.from,
      filteredEnd: filteredTime.to,
      comparedStart: comparedTime.from,
      comparedEnd: comparedTime.to,
    }),
    [comparedTime.from, comparedTime.to, filteredTime.from, filteredTime.to],
  );

  const [payload, setPayload] = useState<CentreMetricsPayload>({
    ...omit<CentreMetricsPayload>(query.filter, IGNORE_SEARCH_URL_PARAMS),
    channel: CHANNEL_OPTIONS.all.value,
    timeRange: query?.filter?.timeRange ?? defaultTimeRange,
    sortKey: query?.sortKey ?? null,
    sortOrder: query?.sortOrder ?? null,
    page: DEFAULT_CURRENT_PAGE,
    size: LIMIT_CENTRES,
  });

  const { data, isLoading, isFetching } =
    useGetMarketingMetricsCentresQuery(payload);

  useEffect(() => {
    const _customPayload = {
      ...omit<CentreMetricsPayload>(query.filter, IGNORE_SEARCH_URL_PARAMS),
      sortKey: query?.sortKey ?? null,
      sortOrder: query?.sortOrder ?? null,
      page: query?.page ?? DEFAULT_CURRENT_PAGE,
      size: query?.size ?? LIMIT_CENTRES,
    };
    if (!isEqual(_customPayload, payload)) {
      setPayload(_customPayload);
    }
  }, [payload, query]);

  const getSorterOrder = useCallback(
    (source): SortOrder => {
      if (query.sortKey === source) {
        if (query.sortOrder === SORT_TYPE.ASC) return SORT_ASCEND_VALUE;
        if (query.sortOrder === SORT_TYPE.DESC) return SORT_DESCEND_VALUE;
      }
      return null;
    },
    [query],
  );
  const columns = [
    {
      title: i18next.t('marketingMetrics.header.centreName'),
      dataIndex: ['centre', 'name'],
      sorter: true,
      width: 300,
      defaultSortOrder: getSorterOrder('centre.id'),
      fixed: 'left' as FixedType,
      render: (value, record) => <CentreMetricsInfo {...record.centre} />,
    },
    {
      title: i18next.t('marketingMetrics.header.impressions'),
      tooltip: i18next.t('marketingMetrics.tooltip.impressions'),
      dataIndex: 'impressions',
      sorter: true,
      width: 180,
      defaultSortOrder: getSorterOrder('impressions'),
      render: (value) => value || '-',
    },
    {
      title: i18next.t('marketingMetrics.header.listingViews'),
      tooltip: i18next.t('marketingMetrics.tooltip.listViews'),
      dataIndex: 'listViews',
      sorter: true,
      width: 180,
      defaultSortOrder: getSorterOrder('listViews'),
      render: (value) => value || '-',
    },
    {
      title: i18next.t('marketingMetrics.header.enquiries'),
      tooltip: i18next.t('marketingMetrics.tooltip.enquiries'),
      dataIndex: 'enquiries',
      sorter: true,
      width: 180,
      defaultSortOrder: getSorterOrder('enquiries'),
      render: (value) => value || '-',
    },
    {
      title: i18next.t('marketingMetrics.header.applications'),
      tooltip: i18next.t('marketingMetrics.tooltip.applications'),
      dataIndex: 'applications',
      sorter: true,
      width: 180,
      defaultSortOrder: getSorterOrder('applications'),
      render: (value) => value || '-',
    },
    {
      title: i18next.t('marketingMetrics.header.applicationsCustomLink'),
      tooltip: i18next.t('marketingMetrics.tooltip.applicationsCustomLink'),
      dataIndex: 'applicationsCustomLink',
      sorter: true,
      width: 300,
      defaultSortOrder: getSorterOrder('applicationsCustomLink'),
      render: (value) => value || '-',
    },
    {
      title: i18next.t('marketingMetrics.header.totalMessagesReceived'),
      tooltip: i18next.t('marketingMetrics.tooltip.receivedMessages'),
      dataIndex: 'receivedMessages',
      sorter: true,
      width: 250,
      defaultSortOrder: getSorterOrder('receivedMessages'),
      render: (value) => value || '-',
    },
    {
      title: i18next.t('marketingMetrics.header.totalMessagesSent'),
      tooltip: i18next.t('marketingMetrics.tooltip.sentMessages'),
      dataIndex: 'sentMessages',
      sorter: true,
      width: 230,
      defaultSortOrder: getSorterOrder('sentMessages'),
      render: (value) => value || '-',
    },
    {
      title: i18next.t('marketingMetrics.header.bookATourCustomLink'),
      tooltip: i18next.t('marketingMetrics.tooltip.bookATour'),
      dataIndex: 'bookATour',
      sorter: true,
      width: 250,
      defaultSortOrder: getSorterOrder('bookATour'),
      render: (value) => value || '-',
    },
    {
      title: i18next.t('marketingMetrics.header.websiteVisits'),
      tooltip: i18next.t('marketingMetrics.tooltip.websiteVisits'),
      dataIndex: 'websiteVisits',
      sorter: true,
      width: 180,
      defaultSortOrder: getSorterOrder('websiteVisits'),
      render: (value) => value || '-',
    },
    {
      title: i18next.t('marketingMetrics.header.phoneCalls'),
      tooltip: i18next.t('marketingMetrics.tooltip.phoneCalls'),
      dataIndex: 'phoneCalls',
      sorter: true,
      width: 180,
      defaultSortOrder: getSorterOrder('phoneCalls'),
      render: (value) => value || '-',
    },
    {
      title: i18next.t('marketingMetrics.header.socialVisits'),
      tooltip: i18next.t('marketingMetrics.tooltip.socialVisits'),
      dataIndex: 'socialVisits',
      sorter: true,
      width: 180,
      defaultSortOrder: getSorterOrder('socialVisits'),
      render: (value) => value || '-',
    },
    {
      title: i18next.t('marketingMetrics.header.compareShortlistOrShare'),
      tooltip: i18next.t('marketingMetrics.tooltip.comparesAndShares'),
      dataIndex: 'comparesAndShares',
      sorter: true,
      width: 280,
      defaultSortOrder: getSorterOrder('comparesAndShares'),
      render: (value) => value || '-',
    },
  ];

  const getSortOrder = (order: SortOrder): SORT_TYPE => {
    switch (order) {
      case SORT_ASCEND_VALUE:
        return SORT_TYPE.ASC;
      case SORT_DESCEND_VALUE:
        return SORT_TYPE.DESC;
      default:
        return null;
    }
  };

  const onChange = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<CentreMetrics>,
  ) => {
    const sortKey = sorter.field
      .toString()
      .replace(',', '.') as CENTRE_METRIC_SORT_KEY;
    const sortOrder = getSortOrder(sorter.order);

    const restFilter = {
      sortKey: sortOrder ? sortKey : null,
      sortOrder,
      size: LIMIT_CENTRES,
      page: DEFAULT_CURRENT_PAGE,
    };

    setPayload((prev) => ({
      ...prev,
      timeRange: prev?.timeRange ?? defaultTimeRange,
      ...restFilter,
    }));

    pushQuery({
      ...query,
      filter: {
        ...query.filter,
        timeRange: query.filter?.timeRange ?? defaultTimeRange,
      },
      ...restFilter,
    });
  };

  const dataLength = data?.results.length;
  const dataTotal = data?.paging?.total;

  const handleScroll = useCallback(
    (event) => {
      const onScroll = debounce((event) => {
        const element = event.target;
        const maxScroll = element.scrollHeight - element.clientHeight;
        const currentScroll = element.scrollTop;
        if (
          Math.round(currentScroll) <= Math.round(maxScroll) &&
          Math.round(currentScroll) >= Math.round(maxScroll) - 10
        ) {
          const loadingElement = document.querySelector('.loading-list');
          if (dataLength < dataTotal && !isFetching) {
            if (!isLoading) {
              if (!loadingElement) {
                const el = document.createElement('div');
                el.setAttribute(
                  'style',
                  "text-align: 'center'; padding-top: 10px; padding-bottom: 10px",
                );
                el.className = 'loading-list';
                el.innerHTML = `<div class="flex-center w-full p-12"><img class="w-50" src=${loadingGif} /></div>`;
                const box = document.querySelector('.ant-table-body');
                box.appendChild(el);
              }

              pushQuery({
                filter: {
                  ...query.filter,
                },
                sortKey: payload.sortKey ?? null,
                sortOrder: payload.sortOrder ?? null,
                size: payload.size,
                page: payload.page + 1,
              });
            }
          }
          if (dataLength === dataTotal) {
            loadingElement && loadingElement.remove();
          }
        }
      }, 300);

      onScroll(event);
    },
    [
      dataTotal,
      dataLength,
      isFetching,
      isLoading,
      payload,
      pushQuery,
      query.filter,
    ],
  );

  useEffect(() => {
    const tableContent = document.querySelector('.ant-table-body');
    if (tableContent) {
      tableContent.addEventListener('scroll', handleScroll);
    }
    return () =>
      tableContent && tableContent.removeEventListener('scroll', handleScroll);
  }, [handleScroll]);

  return (
    <CentreMetricsTableWrapper>
      <PageTitle
        extraAction={
          <ExportCsvActions
            disableExportButton={isEmpty(data?.results)}
            formatQueryParams={formatQueryParams}
          />
        }
      >
        {`${i18next.t('marketingMetrics.centreKeyMetrics')} - ${
          data?.paging?.total ?? 0
        }
        ${i18next.t('marketingMetrics.centre(s)')}`}
      </PageTitle>

      <TableScrollCustom
        sticky
        isSorter={false}
        columns={columns}
        dataSource={data?.results ?? []}
        pagination={false}
        onChange={onChange}
        loading={
          isLoading || (isFetching && payload.page === DEFAULT_CURRENT_PAGE)
        }
        isSetRowKeyId={false}
        yScroll={600}
        isResetStyle={false}
        className={'table-wrapper'}
        isShowingTooltip
        scroll={{ y: '70vh', scrollToFirstRowOnChange: true }}
        locale={{
          emptyText: (
            <Empty
              image={iconEmpty}
              imageStyle={{ height: 160 }}
              className="text-gray-500 mt-70 mb-48"
              description={i18next.t('error.noDataFound')}
            />
          ),
        }}
      />
    </CentreMetricsTableWrapper>
  );
};
export default CentreMetricsTable;

import React from 'react';
import PropTypes from 'prop-types';
import { Button, Space } from 'antd';
import I18n from 'i18next';
import { FooterButtonRowWrapper } from './styles';

const ButtonRow = (props) => {
  const { loading, disabled, onBack, type, showModal, okText } = props;
  return (
    <FooterButtonRowWrapper className={showModal ? 'showTotal' : 'mt-20'}>
      <Space size={15}>
        <Button
          className="btn-back"
          onClick={() => {
            onBack();
          }}
        >
          {I18n.t('button.cancel')}
        </Button>
        <Button
          type="primary"
          loading={loading}
          disabled={disabled}
          htmlType="submit"
        >
          {okText
            ? I18n.t(okText)
            : I18n.t(type === 'create' ? 'button.create' : 'button.save')}
        </Button>
      </Space>
    </FooterButtonRowWrapper>
  );
};

ButtonRow.propTypes = {
  onBack: PropTypes.func,
  showModal: PropTypes.bool,
  loading: PropTypes.bool,
  type: PropTypes.oneOf(['create', 'edit']),
  disabled: PropTypes.bool,
  okText: PropTypes.string,
};

ButtonRow.defaultProps = {
  type: 'edit',
};
export default ButtonRow;

import CreateButton from 'components/RestActions/CreateButton';
import CreateServiceModal from 'containers/Centres/components/CentreTabs/ServicesTab/Modals/CreateServiceModal';
import React, { useState } from 'react';
import { CentreModel } from 'types/centre';

interface Props {
  record: CentreModel;
  isProgramEdit?: boolean;
  isProgramCreate?: boolean;
}

const CreateServiceButton = ({
  record,
  isProgramEdit,
  isProgramCreate,
}: Props) => {
  const [visible, setVisible] = useState(false);
  const toggleModal = () => {
    setVisible(!visible);
  };

  return (
    <>
      <CreateButton
        packageName={record?.CRMPackage?.name}
        permissionKey="myCentres"
        action="isEdit"
        onClickCustom={toggleModal}
      />
      <CreateServiceModal
        visible={visible}
        onCancel={toggleModal}
        isProgramCreate={isProgramCreate}
        isProgramEdit={isProgramEdit}
      />
    </>
  );
};

export default CreateServiceButton;

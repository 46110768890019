import { EditLightIcon, TrashOutlineIcon } from 'components/common/SVGIcon';
import DeleteButton from 'components/RestActions/DeleteButton';
import EditButton from 'components/RestActions/EditButton';
import EditServiceModal from 'containers/Centres/components/CentreTabs/ServicesTab/Modals/EditServiceModal';
import {
  CentreServiceView,
  useCentreServiceControllerDeleteOneMutation,
} from 'generated/apis';
import React, { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { getCurrentCentreSelector } from 'redux/centres/selectors';

interface Props {
  id: string;
  record: CentreServiceView;
  isProgramEdit: boolean;
  isProgramCreate: boolean;
}

const GroupAction = ({ id, record, isProgramEdit, isProgramCreate }: Props) => {
  const [deleteService] = useCentreServiceControllerDeleteOneMutation();
  const currentCentre = useSelector(getCurrentCentreSelector);

  const [editModalVisible, setEditModalVisible] = useState<boolean>(false);

  const onClickEditBtn = () => {
    setEditModalVisible(true);
  };

  const deleteItem = useCallback(async () => {
    await deleteService({ serviceId: id });
  }, [id, deleteService]);

  return (
    <div className="group-actions">
      <EditButton
        packageName={currentCentre?.CRMPackage?.name}
        permissionKey="myCentres"
        action="isEdit"
        onClickCustom={onClickEditBtn}
        iconCustom={<EditLightIcon className="text-neutral-700" />}
      />
      <DeleteButton
        permissionKey="myCentres"
        action="isEdit"
        deleteItem={deleteItem}
        packageName={currentCentre?.CRMPackage?.name}
        customMessage="centreServices.customMessageDelete"
        resource="centreServices"
        iconCustom={<TrashOutlineIcon />}
      />
      {editModalVisible && (
        <EditServiceModal
          key={record.id}
          editModalVisible={editModalVisible}
          record={record}
          isProgramEdit={isProgramEdit}
          isProgramCreate={isProgramCreate}
          onClose={() => setEditModalVisible(false)}
        />
      )}
    </div>
  );
};

export default GroupAction;

import { enhancedApi } from 'generated/apis';

import { DEFAULT_CURRENT_PAGE } from 'configs/localData/constant';
import { CENTRE_SERVICE_TAG, PROGRAMS_LEARNING_TAG } from './tagTypes';

export const enhanceGeneratedApi = enhancedApi.enhanceEndpoints({
  addTagTypes: [PROGRAMS_LEARNING_TAG],
  endpoints: {
    programsControllerGetProgramList: {
      providesTags: [PROGRAMS_LEARNING_TAG, CENTRE_SERVICE_TAG],
      serializeQueryArgs: ({ endpointName }) => endpointName,
      merge: (currentCache, newItems, otherArgs) => {
        if (otherArgs.arg.page === DEFAULT_CURRENT_PAGE) {
          return newItems;
        }
        currentCache.results.push(...newItems.results);
      },
      forceRefetch({ currentArg, previousArg }) {
        return currentArg !== previousArg;
      },
    },
    programsControllerUpdateOneProgram: {
      invalidatesTags: [PROGRAMS_LEARNING_TAG, CENTRE_SERVICE_TAG],
      extraOptions: { isShowSuccess: true, isShowError: true },
    },
    detailProgramControllerGetDetailProgram: {
      providesTags: [PROGRAMS_LEARNING_TAG],
    },
    programsControllerDeleteOneProgram: {
      invalidatesTags: [PROGRAMS_LEARNING_TAG, CENTRE_SERVICE_TAG],
      extraOptions: { isShowSuccess: true },
    },
    centreServiceControllerCreateOne: {
      invalidatesTags: [CENTRE_SERVICE_TAG],
      extraOptions: { isShowSuccess: true, isShowError: true },
    },
    centreServiceControllerGetMany: {
      providesTags: [CENTRE_SERVICE_TAG],
    },
    centreServiceControllerUpdateOne: {
      invalidatesTags: [CENTRE_SERVICE_TAG],
      extraOptions: { isShowSuccess: true, isShowError: true },
    },
    centreServiceControllerDeleteOne: {
      invalidatesTags: [CENTRE_SERVICE_TAG],
      extraOptions: { isShowSuccess: true, isShowError: true },
    },
  },
});

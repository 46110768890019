import React from 'react';
import { Button } from 'antd';
import i18next from 'i18next';
import { useHistory, useParams } from 'react-router';
import useCheckJobListingPermission from 'containers/JobListings/hooks/useCheckJobListingPermission';
import { getFullRoute } from 'utils/tools';
import qs from 'qs';
import PropTypes from 'prop-types';

const ButtonCreateJobListing = ({ isShowFromJobTemplateDetail }) => {
  const { push, location } = useHistory();
  const { id } = useParams();

  const { canModifyJobListing } = useCheckJobListingPermission();

  const onClickCreateListing = () => {
    push({
      pathname: isShowFromJobTemplateDetail
        ? '/job-listings/create/steps'
        : '/job-listings/create/choose',
      search: qs.stringify({ id, from: getFullRoute(location) }),
    });
  };

  return (
    <Button
      type="primary"
      className="t-bold"
      disabled={!canModifyJobListing}
      onClick={onClickCreateListing}
    >
      {i18next.t('jobListings.buttons.createJobListing')}
    </Button>
  );
};

ButtonCreateJobListing.propTypes = {
  isShowFromJobTemplateDetail: PropTypes.bool,
};

export default ButtonCreateJobListing;

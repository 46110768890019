import styled from 'styled-components';

export default styled.div`
  .not-seen-row {
    background: #fafafa;
  }
`;

export const ListStyles = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  .ant-tabs {
    flex-grow: 1;
    .ant-tabs-content,
    .board-tab {
      height: 100%;
    }
  }
  .hidden-tab {
    display: none;
  }
  .container-wrapper {
    flex-grow: 1;
    .board-container {
      height: 100%;
    }
  }
`;

export const HeaderFilterStyles = styled.div`
  .title-tab {
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    font-size: 16px;
  }
  .group-tab {
    .ant-radio-button-wrapper {
      background: transparent;
      border: none;
      font-size: 16px;
      padding: 0;
      box-shadow: none;

      .icon-tab {
        margin-right: 10px;
        font-size: 20px;
      }
      &::before {
        width: 0 !important;
      }

      &:not(:first-child) {
        padding-left: 20px;
      }
    }
  }
`;

export const ServiceAppliedTableStyled = styled.div`
  .service-name {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    word-break: break-all;
    white-space: nowrap;
    display: inline-block;
    margin-left: 5px;
    color: #316dde;
    background: rgba(50, 164, 252, 0.2);
    border-radius: 8px;
    padding: 2px 10px;
    max-width: 100%;
  }
  .required-days {
    color: ${({ theme }) => theme.text.mainL2};
    & > span {
      margin-left: 5px;
      display: inline-block;
      font-size: 12px;
    }
  }
`;

import styled from 'styled-components';

export const LegendLabelWrapper = styled.div`
  flex-wrap: wrap;
  display: flex;
  align-items: center;
  flex-direction: row;
  background: white;
  justify-content: space-evenly;
  min-width: 700px;
  max-width: 900px;

  .legend-item {
    padding: 5px 0;
    width: 450px;
  }

  .legend-value {
    margin-left: 3px;
    width: 50%;
    padding: 10px 5px 0;
  }

  .svg-custom-style {
    display: inline-block;
    position: relative;
    top: 2px;
  }
`;

import React from 'react';
import { Row, Col } from 'antd';

import Mailbox from './Mailbox';
import InfoRequests from '../InfoRequests';
import MailboxTabStyles from './styles';

const MailboxTab = () => (
  <MailboxTabStyles>
    <Row gutter={[20, 20]}>
      <Col xl={16} lg={24} md={16} sm={24} xs={24}>
        <Mailbox />
      </Col>
      <Col xl={8} lg={24} md={8} sm={24} xs={24}>
        <InfoRequests />
      </Col>
    </Row>
  </MailboxTabStyles>
);

export default MailboxTab;

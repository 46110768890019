import React, { useMemo } from 'react';
import i18next from 'i18next';
import { useSelector } from 'react-redux';

import { formatMoney } from 'utils/tools';
import TableCustom from 'components/common/TableCustom';
import CardCommon from '../CardCommon';

const CentresPerformance = () => {
  const data =
    useSelector(
      (state) => state.dashboard.centres.data?.centresPerformanceItem,
    ) || [];
  const loading = useSelector((state) => state.dashboard.centres.loading);

  const columns = useMemo(
    () => [
      {
        title: i18next.t('centres.centreName'),
        dataIndex: 'name',
      },
      {
        title: i18next.t('centres.enquiries'),
        dataIndex: 'totalEnquiries',
      },
      {
        title: i18next.t('centres.applications'),
        dataIndex: 'totalApplications',
      },
      {
        title: i18next.t('centres.enrolmentCoverage'),
        dataIndex: 'enrolmentPipelineCoverage',
        render: (data) => `${formatMoney((data || 0) * 100)}%`,
      },
    ],
    [],
  );

  return (
    <CardCommon title="centres.centresPerformance">
      <TableCustom
        xScroll
        columns={columns}
        data={data}
        pagination={{ hideOnSinglePage: true }}
        loading={loading}
      />
    </CardCommon>
  );
};

export default CentresPerformance;

import React, { useCallback, useContext, memo } from 'react';
import MarketingMetricsFilter from 'containers/MarketingMetrics/components/Filter/MarketingMetricsFilter';
import FilterCustom from 'components/common/FilterCustom';
import { getFilterFromUrl } from 'utils/tools';
import { useLocation } from 'react-router-dom';
import { formatFromToTimeFilterSubmit } from 'utils/dataUtils';
import { FormInstance } from 'antd';
import { RestInputContext } from 'components/RestInput/RestInputContext';
import {
  COMPARE_OPTIONS,
  DEFAULT_CURRENT_PAGE,
  IS_COMPARE,
  LIMIT_CENTRES,
  RANGE_PICKER_OPTION_CUSTOM,
} from 'containers/MarketingMetrics/constants';
import { getCentreMetricsDefaultsFilter } from 'containers/MarketingMetrics/utils';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { setDateDefaultWhenClean } from 'redux/marketingMetrics/slice';
import { PushQueryParams } from 'containers/MarketingMetrics';
import { pickBy } from 'lodash-es';
import { CHANNEL_OPTIONS } from 'configs/localData';

interface FilterProps {
  pushQuery?: (params: PushQueryParams) => void;
}

const Filter = ({ pushQuery }: FilterProps) => {
  const dispatch = useDispatch();
  const { form }: { form?: FormInstance } = useContext(RestInputContext);
  const { search } = useLocation();
  const { filter } = getFilterFromUrl(search).filter;

  const onSubmitFilter = ({ compareDate, rangeDate, ...values }) => {
    const isCompare = localStorage.getItem('isCompareMarketingMetric');
    const comparedTime = formatFromToTimeFilterSubmit(compareDate);
    const filteredTime = formatFromToTimeFilterSubmit(rangeDate);
    const isCheckCompare = String(isCompare) === IS_COMPARE.true;
    const timeRange = {
      comparedStart: isCheckCompare ? comparedTime?.from : null,
      comparedEnd: isCheckCompare ? comparedTime?.to : null,
      filteredStart: filteredTime?.from,
      filteredEnd: filteredTime?.to,
    };

    const submittedFilter = {
      ...values,
      timeRange: pickBy(timeRange),
      isCompare:
        localStorage.getItem('isCompareMarketingMetric') ?? IS_COMPARE.true,
      dateOption:
        localStorage.getItem('dateOptionMarketingMetric') ??
        RANGE_PICKER_OPTION_CUSTOM.last7Days.key,
      compareOption:
        localStorage.getItem('compareOptionMarketingMetric') ??
        COMPARE_OPTIONS.precedingPeriod,
    };

    pushQuery({
      page: DEFAULT_CURRENT_PAGE,
      size: LIMIT_CENTRES,
      filter: submittedFilter,
    });
    form.resetFields();
  };

  const onClearFilter = useCallback(() => {
    localStorage.setItem('isCompareMarketingMetric', IS_COMPARE.true);
    localStorage.setItem(
      'compareOptionMarketingMetric',
      COMPARE_OPTIONS.precedingPeriod,
    );
    dispatch(setDateDefaultWhenClean());
    pushQuery({
      page: DEFAULT_CURRENT_PAGE,
      size: LIMIT_CENTRES,
      filter: {
        timeRange: getCentreMetricsDefaultsFilter().filter.timeRange,
        channel: CHANNEL_OPTIONS.all.value,
        dateOption: RANGE_PICKER_OPTION_CUSTOM.last7Days.key,
        isCompare: true,
        compareOption: COMPARE_OPTIONS.precedingPeriod,
      },
    });
    localStorage.setItem(
      'dateOptionMarketingMetric',
      RANGE_PICKER_OPTION_CUSTOM.last7Days.key,
    );
  }, [pushQuery, dispatch]);

  return (
    <div className="mb-12">
      <FilterCustom
        onSubmitFilter={onSubmitFilter}
        onClearFilter={onClearFilter}
        responsiveFilter={{
          xxl: 20,
          xl: 20,
          lg: 24,
          md: 24,
          xs: 24,
        }}
        responsiveAction={{
          xxl: 4,
          xl: 4,
          lg: 24,
          md: 24,
          xs: 24,
        }}
        initialValues={{
          ...filter,
          rangeDate: [
            moment(filter?.timeRange?.filteredStart),
            moment(filter?.timeRange?.filteredEnd),
          ],
          compareDate: [
            moment(filter?.timeRange?.comparedStart),
            moment(filter?.timeRange?.comparedEnd),
          ],
        }}
      >
        <MarketingMetricsFilter />
      </FilterCustom>
    </div>
  );
};
export default memo(Filter);

import styled from 'styled-components';

export const JobApplicationListStyle = styled.div`
  .link-class {
    &:hover,
    &:active {
      color: ${({ theme }) => theme.palette.primary} !important;
    }
  }
`;

export const CustomerFieldStyles = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  .ant-avatar {
    margin-right: 16px;
  }
`;

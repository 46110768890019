import React from 'react';
import PropTypes from 'prop-types';
import { Divider } from 'antd';
import i18next from 'i18next';
import { ArchiveInfo, InfoChildStyles } from './styles';
import ChildAvatar from './ChildAvatar';
import BlockInfo from './BlockInfo';
import ServicesInfo from './ServicesInfo';
import ButtonAction from '../ButtonAction';

const InfoChild = ({ record, handleUpdateStatus }) => {
  const child = record.careOption || {};

  return (
    <InfoChildStyles>
      <ChildAvatar child={child} />
      {!!record?.archivedReason && (
        <div className="flex-center mb-16">
          <ArchiveInfo>
            {`${i18next.t('enquiriesStatus.archived')} - ${
              record?.archivedReason
            }`}
          </ArchiveInfo>
        </div>
      )}
      <BlockInfo record={record} handleUpdateStatus={handleUpdateStatus} />
      <Divider />
      <ServicesInfo child={child} />
      <ButtonAction
        handleUpdateStatus={handleUpdateStatus}
        status={record.status}
        child={child}
      />
    </InfoChildStyles>
  );
};

InfoChild.propTypes = {
  record: PropTypes.object,
  handleUpdateStatus: PropTypes.func,
};

InfoChild.defaultProps = {
  record: {},
};

export default InfoChild;

import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { checkRole } from 'utils/tools';
import { Redirect, Route } from 'react-router-dom';
import CheckPermissionWrapper from 'components/common/CheckPermissions';
import { getCurrentUser } from 'redux/auth/actions';
import { RootState } from 'redux/store';
import useRouter from '../../hooks/useRouter';

interface PrivateRouteProps {
  component?: React.ComponentType<unknown>;
  roles?: string[];
  permissionKey?: string;
  action?: string;
  isSkipSubscription?: boolean;
}

const PrivateRoute = ({
  component: Component,
  roles,
  permissionKey,
  action,
  isSkipSubscription,
  ...rest
}: PrivateRouteProps) => {
  const isAuthenticated = useSelector(
    (state: RootState) => state.auth.isAuthenticated,
  );
  const currentRole = useSelector((state: RootState) => state.auth.role);
  const dispatch = useDispatch();

  useEffect(() => {
    if (isAuthenticated) {
      dispatch(getCurrentUser());
    }
  }, [isAuthenticated, dispatch]);
  const { pathname } = useRouter();

  return checkRole(roles, currentRole) ? (
    <Route
      {...rest}
      exact
      render={(props) => (
        <>
          {isAuthenticated ? (
            <CheckPermissionWrapper
              permissionKey={permissionKey}
              action={action}
              isSkipSubscription={isSkipSubscription}
              isDefaultVisibleModal
              {...props}
            >
              <Component />
            </CheckPermissionWrapper>
          ) : (
            <Redirect
              to={{
                pathname: `/login`,
                search: `?from=${pathname.toString()}`,
              }}
            />
          )}
        </>
      )}
    />
  ) : (
    <Route render={null} />
  );
};

export default PrivateRoute;

import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { Form } from 'antd';
import ModalCustom from 'components/common/ModalCustom';
import PropTypes from 'prop-types';
import { RestInputContext } from 'components/RestInput/RestInputContext';
import i18next from 'i18next';
import { useDispatch } from 'react-redux';
import {
  createCampaign,
  editCampaign,
  getAllJobListings,
  getCampaigns,
  getSummaryJobListing,
} from 'redux/jobs/jobListings/actions';
import { isEmpty } from 'lodash-es';
import {
  DEFAULT_CURRENT_PAGE,
  DEFAULT_SORT_CAMPAIGN,
  LIMIT_CAMPAIGN_DEFAULT,
  LIMIT_ITEM,
  VIEW_TYPE_JOB_LISTINGS,
} from 'containers/JobListings/constants';
import { useLocation } from 'react-router';
import { getFilterFromUrl } from 'utils/tools';
import CreateCampaignForm from '../Form/CreateCampaignForm';

const CreateCampaignModal = forwardRef(
  ({ record, isRefreshCampaignList }, ref) => {
    const dispatch = useDispatch();
    const [visible, setVisible] = useState(false);
    const [loading, setLoading] = useState(false);
    const [form] = Form.useForm();

    const { search } = useLocation();
    const { filter } = getFilterFromUrl(search);

    const [selectCampaignId, setSelectCampaignId] = useState('');

    const checkError = (error, payload) => {
      if (!isEmpty(error)) {
        form.setFields([
          {
            name: 'name',
            errors: [payload],
          },
        ]);

        return;
      }

      dispatch(
        getCampaigns({
          data: {
            page: 1,
            size: LIMIT_CAMPAIGN_DEFAULT,
          },
          options: {
            isRefresh: true,
          },
        }),
      );

      if (isRefreshCampaignList) {
        const defaultParams = {
          sorts: filter.sorts || [DEFAULT_SORT_CAMPAIGN],
          page: filter.page || DEFAULT_CURRENT_PAGE,
          size: filter.size || LIMIT_ITEM,
          filter: filter.filter,
          viewType: VIEW_TYPE_JOB_LISTINGS.CAMPAIGN,
        };
        dispatch(
          getAllJobListings({
            data: defaultParams,
            options: {
              keepFilter: true,
              isRefresh: true,
            },
          }),
        );
        dispatch(getSummaryJobListing(defaultParams));
      }
      onCancel();
    };

    useImperativeHandle(
      ref,
      () => ({
        open: (initialValue) => {
          setVisible(true);
          form.setFieldsValue(initialValue);
          setSelectCampaignId(initialValue?.id);
        },
      }),
      [form],
    );

    const onCancel = () => {
      setVisible(false);
      form.resetFields();
    };

    const onFinish = (values) => {
      setLoading(true);

      if (selectCampaignId) {
        dispatch(
          editCampaign({
            id: selectCampaignId,
            data: values,
          }),
        )
          .then(({ error, payload }) => {
            checkError(error, payload);
          })
          .finally(() => {
            setLoading(false);
          });

        return;
      }

      dispatch(createCampaign(values))
        .then(({ error, payload }) => {
          checkError(error, payload);
        })
        .finally(() => {
          setLoading(false);
        });
    };

    return (
      <ModalCustom
        title={
          selectCampaignId
            ? i18next.t('jobListings.editCampaign')
            : i18next.t('jobListings.buttons.createCampaign')
        }
        onCancel={onCancel}
        visible={visible}
        onOk={form.submit}
        loading={loading}
        record={record}
        okText={
          selectCampaignId
            ? i18next.t('button.save')
            : i18next.t('button.create')
        }
      >
        <Form
          form={form}
          layout="vertical"
          scrollToFirstError
          onFinish={onFinish}
        >
          <RestInputContext.Provider value={{ form }}>
            <CreateCampaignForm />
          </RestInputContext.Provider>
        </Form>
      </ModalCustom>
    );
  },
);

CreateCampaignModal.propTypes = {
  record: PropTypes.object,
  isRefreshCampaignList: PropTypes.bool,
};

export default CreateCampaignModal;

import { createDraftSafeSelector, createSelector } from '@reduxjs/toolkit';

const getRawJobTemplateCurrentStep = (state) =>
  state.jobTemplates.currentJobTemplateStep;

const getRawJobTemplatePayload = (state) =>
  state.jobTemplates.jobTemplatePayload;

const getRawJobTemplateConfig = (state) =>
  state.jobTemplates?.jobTemplatesConfig?.data;

const getRawResourceDataJobTemplates = (state) =>
  state.jobTemplates?.resourceData;

const getRawJobTemplatesLoading = (state) => state.jobTemplates?.loading;

const getRawDataJobTemplates = (state) => state.jobTemplates?.data;

const getRawJobTemplateDetailLoading = (state) =>
  state.jobTemplates?.getJobTemplatesDetailLoading;

const getRawDataJobTemplateDetail = (state) => state.jobTemplates?.currentData;

const getHasSelectedTemplateValue = (state) =>
  state?.jobTemplates?.hasSelectedTemplate;

const getRawLoadingCreateJobTemplate = (state) =>
  state?.jobTemplates?.createLoading;

export const getJobTemplateCurrentStepSelector = createDraftSafeSelector(
  [getRawJobTemplateCurrentStep],
  (data) => data,
);

export const getJobTemplatePayloadSelector = createDraftSafeSelector(
  [getRawJobTemplatePayload],
  (data) => data,
);

export const getJobTemplateConfigSelector = createDraftSafeSelector(
  [getRawJobTemplateConfig],
  (data) => data,
);

export const getResourceDataJobTemplate = createSelector(
  [getRawResourceDataJobTemplates],
  (data) => data,
);

export const getLoadingJobTemplates = createSelector(
  [getRawJobTemplatesLoading],
  (data) => data,
);

export const getDataJobTemplates = createSelector(
  [getRawDataJobTemplates],
  (data) => data,
);

export const enabledLoadMoreExistingJobTemplates = createSelector(
  [getRawResourceDataJobTemplates, getRawJobTemplatesLoading],
  (resource, loading) => {
    const {
      page: currentPage = 1,
      size = 10,
      total: totalRecords = 0,
    } = resource || {};
    const totalPage = Math.ceil(totalRecords / size);
    return !loading && currentPage < totalPage;
  },
);

export const hasSelectedTemplateSelector = createSelector(
  [getHasSelectedTemplateValue],
  (data) => data,
);

export const getLoadingJobTemplateDetail = createSelector(
  [getRawJobTemplateDetailLoading],
  (data) => data,
);

export const getDataJobTemplateDetail = createSelector(
  [getRawDataJobTemplateDetail],
  (data) => data,
);

export const getLoadingCreateJobTemplate = createSelector(
  [getRawLoadingCreateJobTemplate],
  (data) => data,
);

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useLocation, useHistory } from 'react-router';
import useCheckPermission from 'components/common/CheckPermissions/useCheckPermission';

import Modal from 'components/common/Modal';
import Contacts from 'pages/Contacts';
import Transactions from 'pages/Transactions';
import Applications from 'pages/Applications';
import CentreServices from 'pages/CentreServices';
import Enquiries from 'pages/Enquiries';
import Centres from 'pages/Centres';
import Customers from 'pages/Customers';

const modalRoutes = [
  {
    path: '/contacts',
    routes: [
      {
        path: '/create',
        component: Contacts.Create,
      },
      {
        path: '/edit',
        component: Contacts.Edit,
      },
    ],
  },

  {
    path: '/transactions',
    routes: [
      {
        path: '/create',
        component: Transactions.Create,
      },
      {
        path: '/edit',
        component: Transactions.Edit,
      },
    ],
  },

  {
    path: '/applications',
    routes: [
      {
        path: '/create',
        component: Applications.Create,
      },
      {
        path: '/edit',
        component: Applications.Edit,
      },
    ],
  },

  {
    path: '/customers',
    routes: [
      {
        path: '/create',
        component: Customers.Create,
      },
      {
        path: '/edit',
        component: Customers.Edit,
      },
    ],
  },

  {
    path: '/centreServices',
    routes: [
      {
        path: '/create',
        component: CentreServices.Create,
      },
      {
        path: '/edit',
        component: CentreServices.Edit,
      },
    ],
  },

  {
    path: '/enquiries',
    routes: [
      {
        path: '/create',
        component: Enquiries.Create,
      },
    ],
  },

  {
    path: '/centres',
    routes: [
      {
        path: '/create',
        component: Centres.Create,
      },
      {
        path: '/edit',
        component: Centres.Edit,
      },
    ],
  },
];

let modal = null;

const getModalRoute = (currentModal) => {
  const modalRoute =
    currentModal &&
    modalRoutes.find((route) => currentModal.search(route.path) > -1);
  if (modalRoute) {
    return modalRoute.routes.find(
      (route) => currentModal.indexOf(route.path) > -1,
    );
  }
  return modalRoute;
};

const ModalRoute = () => {
  const location = useLocation();
  const history = useHistory();
  useEffect(() => {
    if (location.hash && location.hash !== '#') {
      const modelRoute = location.hash.replace('#', '/');
      modal = getModalRoute(modelRoute);
    }
  }, [location.hash]);
  const closeModal = () => {
    history.replace(`${location.pathname}${location.search}`);
  };

  const modelRoute = location.hash.replace('#', '/');

  modal = getModalRoute(modelRoute) || modal;

  const modalOptions = modal && modal?.modalOptions ? modal?.modalOptions : {};

  const { isCan } = useCheckPermission({
    permissionKey: modal?.permissionKey,
    action: modal?.action,
    isSkipSubscription: modal?.isSkipSubscription,
  });

  const visibleModal = location.hash && location.hash !== '#' && isCan;

  return (
    <Modal
      {...modalOptions}
      visible={visibleModal}
      footer={null}
      onCancel={closeModal}
      onClose={closeModal}
    >
      {modal?.component && (
        <modal.component
          showModal
          visibleModal={!!(location.hash && location.hash !== '#')}
          location={location}
        />
      )}
    </Modal>
  );
};

ModalRoute.propTypes = {
  location: PropTypes.object,
  currentModal: PropTypes.string,
  closeModal: PropTypes.func,
  showModal: PropTypes.func,
  replaceRoute: PropTypes.func,
};

export default ModalRoute;

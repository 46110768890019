import React, { useState, useImperativeHandle, forwardRef } from 'react';
import PropTypes from 'prop-types';
import Form from '../Form';

import CentreModal from '.';

interface Props {
  record: any;
}

type Modal = {
  toggleModal: () => void;
};
const CentreInformationModal = forwardRef<Modal, Props>((props, ref) => {
  const [visible, setVisible] = useState(false);

  const onCancel = () => setVisible(false);

  useImperativeHandle(
    ref,
    () => ({
      toggleModal: () => {
        setVisible(true);
      },
    }),
    [],
  );

  const formatOnSubmit = ({ LGA, region, ...values }) => ({
    ...values,
    LGA: LGA || null,
    region: region || null,
  });

  return (
    <CentreModal
      title="centreDetail.titleCentreDetailInformationModal"
      visible={visible}
      onCancel={onCancel}
      record={props.record}
      width={1100}
      formatOnSubmit={formatOnSubmit}
    >
      <Form />
    </CentreModal>
  );
});

CentreInformationModal.propTypes = {
  record: PropTypes.object,
};

export default CentreInformationModal;

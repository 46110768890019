import styled from 'styled-components';

const ContactDetailStyles = styled.div`
  .contact-info {
    background: #fff;
    position: relative;
    border-radius: 8px;
    .edit-wrapper {
      position: absolute;
      top: 10px;
      right: 10px;
      z-index: 2;
    }
    .edit-div-button {
      position: absolute;
      top: 10px;
      right: 10px;
    }

    &__thumbnail {
      .ant-avatar {
        width: 100% !important;
        border-radius: 8px 8px 0px 0px;
      }
    }

    &__content {
      padding: 20px;
    }
  }

  .parent-info__item + .parent-info__item {
    margin-top: 15px;
  }

  .parent-info {
    &__header {
      margin-bottom: 20px;
      font-weight: bold;
      font-size: 20px;
      line-height: 25px;
    }

    &__name {
      color: ${({ theme }) => theme.palette.primary};
      margin-top: 10px;
    }

    &__item {
      display: flex;
      font-size: 18px;
      & > .anticon {
        font-size: 16px;
        color: ${({ theme }) => theme.palette.primary};
        line-height: 30px;
        margin-right: 20px;
      }
    }

    .value-info-item {
      word-break: break-word;
      color: ${({ theme }) => theme.text.mainL5};
    }
  }

  .col-section > div + .col-section > div {
    margin-top: 24px;
  }
`;

export default ContactDetailStyles;

export const ChildrenNameStyles = styled.div`
  font-size: 16px;

  .gender-icon {
    padding: 5px;
    border-radius: 50%;
    font-size: 16px;
    margin-right: 10px;
  }
`;

export const ChildrenInfoStyles = styled.div`
  .children-item {
    font-size: 16px;
    &__label {
      display: flex;
      align-items: center;
    }
    &__value {
      font-size: 16px;
      font-weight: bold;
    }
    .anticon {
      font-size: 30px;
      margin-right: 10px;
    }
  }

  .ant-divider:last-child {
    display: none;
  }
`;

import styled from 'styled-components';

const AutomatedStyles = styled.div`
  .automated {
    &__content {
      background: ${({ theme }) => theme.background.content};
      border-radius: 24px;
    }
    &__title {
      font-weight: bold;
      font-size: 16px;
      line-height: 20px;
      margin-bottom: 10px;
    }
  }

  .automated-list,
  .automated-detail {
    padding: 28px 0;
  }
`;

export const AutomatedDetailWrapperStyles = styled.div`
  .btn-return {
    background: #2d1f21;
    border-radius: 8px;
    margin-top: 20px;
    color: #ffffff;
  }
`;

export default AutomatedStyles;

import styled from 'styled-components';

export const AvailableDaysStyles = styled.div`
  display: flex;
  justify-content: center;
  font-weight: 600;
  .ant-space {
    display: flex;
    justify-content: space-around;
    width: 100%;
  }
  .avatar-active {
    background: ${({ theme }) => theme.palette.green};
    color: #fff;
  }
  .avatar-disabled {
    color: ${({ theme }) => theme.palette.green};
    background: #ffffff;
    border: 1px solid ${({ theme }) => theme.palette.green};
  }
  .ant-avatar-string {
    font-size: 12px;
  }
`;

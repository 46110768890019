import styled from 'styled-components';

export const FilterStyles = styled.div`
  background: #eff7ff;
  width: 100%;
  border: 1px solid #ffffff;
  border-radius: 16px;
  padding: 48px 120px;
  gap: 48px;

  .clear-button {
    background: ${({ theme }) => theme.background.content};
    color: ${({ theme }) => theme.palette.primary};
    border: 1px solid ${({ theme }) => theme.palette.primary};
    box-sizing: border-box;
    font-weight: 700;
  }

  .ant-btn-primary {
    font-weight: 700;
    letter-spacing: 0.02em;
  }

  .ant-form-vertical .ant-form-item-label > label,
  .ant-col-24.ant-form-item-label > label,
  .ant-col-xl-24.ant-form-item-label > label {
    margin-bottom: 4px;
    color: #000;
    font-weight: 700;
  }

  span.ant-radio + * {
    font-weight: 700;
    padding-bottom: 10px;
  }
`;

export const CentreNameAddressStyles = styled.div`
  .address-full-form-button {
    padding-left: 5px !important;
    > span {
      text-decoration: underline;
    }
  }

  .location-question {
    color: #000;
    font-size: 14px;
  }

  .error-text {
    color: red;
    font-size: 14px;
  }

  .fill-address {
    margin-top: -5px;
  }
`;

import TableCustom from 'components/common/TableCustom';
import {
  SORT_ASCEND_VALUE,
  SORT_DESCEND_VALUE,
} from 'configs/localData/constant';
import { getFilterFromUrl, formatNullString } from 'utils/tools';
import i18next from 'i18next';
import { useSelector } from 'react-redux';
import React, { useCallback, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import { formatDateApplication } from 'utils/textUtils';
import { getResourceDataJobApplications } from 'redux/jobs/jobApplications/selectors';
import { getActiveOnJobModulePermission } from 'redux/auth/selectors';
import { JobApplicationListStyle } from './styles';
import useFormatFnc from './useFormat';
import {
  DEFAULT_CURRENT_PAGE,
  LIMIT_ITEM,
  DEFAULT_SORT_JOB_APPLICATIONS,
} from '../../constants';

const JobApplicationList = ({
  jobApplicationsData,
  loading,
  pushQuery,
  retrieveList,
  setFilterData,
}) => {
  const useFormat = useFormatFnc();
  const { search } = useLocation();
  const { filter } = getFilterFromUrl(search);
  const filterRef = useRef(filter);

  const resourceData = useSelector(getResourceDataJobApplications);
  const activeOnJobModulePermission = useSelector(
    getActiveOnJobModulePermission,
  );

  const getSorterOrder = useCallback(
    (source) => {
      if (filter.sorts?.[0] === source) return SORT_ASCEND_VALUE;
      if (filter.sorts?.[0] === `-${source}`) return SORT_DESCEND_VALUE;
      return '';
    },
    [filter],
  );

  useEffect(() => {
    activeOnJobModulePermission &&
      retrieveList({
        filter: {
          sorts: filterRef.current.sorts || [DEFAULT_SORT_JOB_APPLICATIONS],
          page: filterRef.current.page || DEFAULT_CURRENT_PAGE,
          size: filterRef.current.size || LIMIT_ITEM,
          filter: filterRef.current.filter,
        },
        isRefresh: true,
        keepFilter: true,
      });
  }, [retrieveList, activeOnJobModulePermission]);

  const columns = [
    {
      title: i18next.t('jobApplications.applicant'),
      dataIndex: 'applicantName',
      width: 300,
      sorter: true,
      render: (data, record) =>
        useFormat.formatApplicantName(record?.seeker, record),
      fixed: 'left',
      defaultSortOrder: getSorterOrder('seeker'),
    },
    {
      title: i18next.t('applications.applicationStatus'),
      dataIndex: 'applicationStatus',
      width: 220,
      sorter: true,
      render: (data, record) =>
        useFormat.formatApplicationStatus(record?.status),
      defaultSortOrder: getSorterOrder('applicationStatus'),
    },
    {
      title: i18next.t('jobApplications.appliedTime'),
      dataIndex: 'appliedTime',
      width: 200,
      sorter: true,
      render: (data, record) =>
        useFormat.formatData(formatDateApplication(record?.appliedTime)),
      defaultSortOrder: getSorterOrder('appliedTime'),
    },
    {
      title: i18next.t('jobApplications.jobMatchScore'),
      dataIndex: 'jobMatchScore',
      width: 250,
      sorter: true,
      render: (data, record) =>
        useFormat.formatProgressValue(Number(record?.post?.jobMatchScore)),
      defaultSortOrder: getSorterOrder('jobMatchScore'),
    },
    {
      title: i18next.t('jobApplications.jobReference'),
      dataIndex: 'jobReference',
      width: 200,
      sorter: true,
      render: (data, record) => (
        <span
          className={`${
            record?.post?.jobReference ? 'text-decoration-line' : ''
          } ellipsis-t-2`}
        >
          {formatNullString(record?.post?.jobReference)}
        </span>
      ),
      defaultSortOrder: getSorterOrder('jobReference'),
    },
    {
      title: i18next.t('jobApplications.campaign'),
      dataIndex: 'campaign',
      width: 200,
      sorter: true,
      render: (data, record) => (
        <span className="ellipsis-t-2">
          {formatNullString(record?.campaigns?.name)}
        </span>
      ),
      defaultSortOrder: getSorterOrder('campaign'),
    },
    {
      title: i18next.t('jobApplications.jobRole'),
      dataIndex: 'jobRole',
      width: 200,
      sorter: true,
      render: (data) => useFormat.formatData(data?.name),
      defaultSortOrder: getSorterOrder('jobRole'),
    },
    {
      title: i18next.t('jobApplications.jobTemplate'),
      dataIndex: 'jobTemplate',
      width: 200,
      sorter: true,
      render: (data) => useFormat.formatData(data),
      defaultSortOrder: getSorterOrder('jobTemplate'),
    },
    {
      title: i18next.t('jobApplications.jobTitle'),
      dataIndex: 'jobTitle',
      width: 200,
      sorter: true,
      render: (data, record) => useFormat.formatData(record?.post?.title),
      defaultSortOrder: getSorterOrder('jobTitle'),
    },
    {
      title: i18next.t('jobApplications.jobType'),
      dataIndex: 'jobType',
      width: 200,
      sorter: true,
      render: (data) => useFormat.formatJobType(data),
      defaultSortOrder: getSorterOrder('jobType'),
    },
    {
      title: i18next.t('jobApplications.workplace'),
      dataIndex: 'workplace',
      width: 200,
      sorter: true,
      render: (data) => useFormat.formatData(data?.name),
      defaultSortOrder: getSorterOrder('workplace'),
    },
    {
      title: i18next.t('jobApplications.salary'),
      dataIndex: 'salary',
      width: 200,
      sorter: true,
      render: (data) => useFormat.formatData(data?.displayText),
      defaultSortOrder: getSorterOrder('salary'),
    },
    {
      title: i18next.t('jobApplications.brand'),
      dataIndex: 'brand',
      width: 200,
      sorter: true,
      render: (data) => (
        <span className="ellipsis-t-2">{formatNullString(data)}</span>
      ),
      defaultSortOrder: getSorterOrder('brand'),
    },
    {
      title: i18next.t('jobApplications.suburb'),
      dataIndex: 'suburb',
      width: 200,
      sorter: true,
      render: (data, record) => (
        <span className="ellipsis-t-2">
          {formatNullString(record?.workplace?.suburb)}
        </span>
      ),
      defaultSortOrder: getSorterOrder('suburb'),
    },
    {
      title: i18next.t('jobApplications.state'),
      dataIndex: 'state',
      width: 200,
      sorter: true,
      render: (data, record) => (
        <span className="ellipsis-t-2">
          {formatNullString(record?.workplace?.state)}
        </span>
      ),
      defaultSortOrder: getSorterOrder('state'),
    },
    {
      title: i18next.t('jobApplications.highestQualification'),
      dataIndex: 'qualification',
      width: 250,
      sorter: true,
      render: (data) => (
        <span className="ellipsis-t-2">{formatNullString(data?.name)}</span>
      ),
      defaultSortOrder: getSorterOrder('qualification'),
    },
    {
      title: i18next.t('jobApplications.distanceToWorkplace'),
      dataIndex: 'distance',
      width: 250,
      sorter: true,
      render: (data) => (
        <span className="ellipsis-t-2">
          {`${formatNullString(data)}${data ? 'km' : ''}`}
        </span>
      ),
      defaultSortOrder: getSorterOrder('distance'),
    },
    {
      title: i18next.t('jobApplications.availability'),
      dataIndex: 'available',
      width: 200,
      sorter: true,
      render: (data, record) =>
        useFormat.formatAvailableStart(record?.availableStart),
      defaultSortOrder: getSorterOrder('available'),
    },
    {
      title: '',
      dataIndex: 'id',
      width: 80,
      fixed: 'right',
      render: useFormat.formatGroupAction,
    },
  ];

  const onChange = (e, filters, sorter) => {
    const formatSort =
      sorter && sorter.field && sorter.order
        ? `${sorter.order === SORT_DESCEND_VALUE ? '-' : ''}${sorter.field}`
        : null;

    const restFilter = {
      size: e.pageSize,
      page: e.current,
      sorts: [formatSort || DEFAULT_SORT_JOB_APPLICATIONS],
      filter: { ...filter.filter },
    };

    retrieveList({
      filter: restFilter,
      isRefresh: true,
      keepFilter: true,
    });
    setFilterData({ ...restFilter });
    pushQuery({
      'sorts[]': restFilter.sorts,
      ...restFilter,
    });
  };

  return (
    <JobApplicationListStyle>
      <TableCustom
        xScroll={2000}
        loading={loading}
        columns={columns}
        data={jobApplicationsData}
        onChange={onChange}
        pagination={{
          pageSize: filter?.size || LIMIT_ITEM,
          current: filter?.page || DEFAULT_CURRENT_PAGE,
          total: resourceData?.total,
        }}
      />
    </JobApplicationListStyle>
  );
};

JobApplicationList.propTypes = {
  jobApplicationsData: PropTypes.array,
  loading: PropTypes.bool,
  pushQuery: PropTypes.func,
  retrieveList: PropTypes.func,
  setFilterData: PropTypes.func,
};

export default JobApplicationList;

import React, { useEffect, useContext } from 'react';
import Row from 'antd/lib/row';
import Col from 'antd/lib/col';

import { RestInputContext } from 'components/RestInput/RestInputContext';
import RestInputItem from 'components/RestInput/RestInputItem';
import RestSelect from 'components/RestInput/RestSelect';
import { useCrmContactsConfigControllerGetCrmContactConfigQuery } from 'generated/apis';
import { ResourceFilter } from 'types';

interface Props {
  resourceFilter: ResourceFilter;
}

const ContactFilterForm = ({ resourceFilter }: Props) => {
  const { form } = useContext(RestInputContext);

  const { data } = useCrmContactsConfigControllerGetCrmContactConfigQuery();

  useEffect(() => {
    form.setFieldsValue({
      ...resourceFilter?.outsideFilter,
      filter: resourceFilter?.filter,
    });
  }, []); // eslint-disable-line

  return (
    <Row gutter={16}>
      <Col xl={5} lg={8} md={12} xs={24}>
        <RestInputItem
          source={['filter', 'fullName', '$like']}
          placeholder="contacts.parentGuardianName"
        />
      </Col>
      <Col xl={5} lg={8} md={12} xs={24}>
        <RestInputItem
          source={['filter', 'email', '$like']}
          placeholder="contacts.email"
        />
      </Col>
      <Col xl={5} lg={8} md={12} xs={24}>
        <RestInputItem
          source={['filter', 'phone', '$like']}
          placeholder="contacts.phone"
        />
      </Col>
      <Col xl={5} lg={8} md={12} xs={24}>
        <RestInputItem
          source={['filter', 'childName', '$like']}
          placeholder="applications.childName"
        />
      </Col>
      <Col xl={4} lg={8} md={12} xs={24}>
        <RestSelect
          source="activity"
          placeholder="common.activities"
          valueProp="value"
          titleProp="text"
          resourceData={data?.activity}
        />
      </Col>
    </Row>
  );
};

export default ContactFilterForm;

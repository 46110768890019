import styled from 'styled-components';

export const CreateTemplateActionWrapper = styled.div`
  width: 70%;
  height: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 auto;

  .start-btn {
    margin-bottom: 15px;
    width: 100%;
    padding: 30px 20px;
    justify-content: space-between;
    align-items: center;

    & > span {
      text-align: left;
    }

    & > span > span {
      margin-right: 10px;
    }

    .btn-content {
      overflow: hidden;
      text-overflow: ellipsis;
      width: 500px;
      white-space: nowrap;
      font-weight: bold;
    }

    &[disabled] {
      color: ${({ theme }) => theme.color.grayNeutral400};
      background-color: ${({ theme }) => theme.background.content};
    }
  }
`;

import styled from 'styled-components';

export const SubscriptionsListStyled = styled.div`
  color: white;
  padding-bottom: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: #e9f4ff url('/static/images/subscription-pricing/blue-bg.png')
    no-repeat;
  background-size: contain;
  width: 100%;

  .main-title {
    font-weight: normal;
    margin-top: 100px;
    max-width: 1000px;

    .ant-typography {
      color: white;
      font-size: 36px;
      font-weight: 700;
      line-height: 44px;
    }
  }

  .main-desc {
    font-size: 18px;
    margin: 16px auto 0;
    max-width: 940px;
  }

  .price-block-list {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    max-width: 95%;
    margin-top: 50px;
  }

  .see-how-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 40px;
    border-radius: 12px;
    background: #db147f;
    font-weight: 700;
    font-size: 16px;
    padding: 12px 24px;
    height: auto;

    a {
      color: white;
    }
    & > a > .anticon {
      font-size: 12px;
    }

    & > a:hover {
      color: white;
    }
  }

  @media only screen and (max-width: 1300px) {
    .main-title {
      max-width: 800px;
      & > .ant-typography {
        font-size: 28px;
      }
    }
  }

  @media only screen and (max-width: 1200px) {
    .price-block-list {
      overflow-x: scroll;
      max-width: 100%;
      padding: 0 30px;
    }
  }

  @media only screen and (max-width: 992px) {
    background: #e9f4ff url('/static/images/subscription-pricing/blue-bg.png')
      no-repeat;
    background-size: contain;
    .main-title {
      margin-top: 120px;
      max-width: 80%;
    }

    .main-desc {
      max-width: 80%;
    }
  }

  @media only screen and (max-width: 780px) {
    background: #e9f4ff url('/static/images/subscription-pricing/blue-bg.png')
      no-repeat;
    .main-title {
      font-weight: normal;
      margin-top: 100px;
      max-width: 630px;

      .ant-typography {
        color: white;
        font-size: 28px;
        font-weight: 700;
        line-height: 44px;
      }
    }
    .main-desc {
      font-size: 16px;
    }
  }

  @media only screen and (max-width: 530px) {
    .main-title {
      .ant-typography {
        font-size: 20px;
      }
    }
  }

  @media only screen and (max-width: 390px) {
    background: #e9f4ff
      url('/static/images/subscription-pricing/blue-bg-mobile.png') no-repeat;
    background-size: contain;
    .price-block-list {
      margin-top: 60px !important;
      overflow-x: scroll;
      display: block;
      ::-webkit-scrollbar {
        display: none;
      }
    }
    padding-top: 45px;

    .main-title {
      margin-top: 50px;
      width: 90%;

      .ant-typography {
        width: 95%;
        margin: 0 auto;
        text-align: center;
        font-size: 20px;
        line-height: 35px;
      }
    }

    .main-desc {
      font-size: 14px;
      width: 90%;
    }

    .main-title {
      margin-top: 50px;
      width: 90%;
    }

    .ant-typography {
      font-size: 22px;
    }

    .main-desc {
      font-size: 14px;
      width: 90%;
    }

    .price-block-list {
      margin-top: 0;
      width: 100%;
      max-width: 100%;
      padding: 0 15px;
    }
  }
`;

import { get } from 'api/utils';
import { CSVIcon, DownloadIcon } from 'components/common/SVGIcon';
import { EXPORT_STATUS } from 'configs/localData';
import i18next from 'i18next';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { ExportItem, setExportList } from 'redux/exportProgress/slice';
import { AnimateProgress } from './styles';

const INTERVAL_TIME = 5000;

const DownloadItem = (props: Partial<ExportItem>) => {
  const { name, refreshApi, status } = props;

  const dispatch = useDispatch();
  const [data, setData] = useState<Partial<ExportItem>>(props);

  const {
    url,
    params,
    prefixApi = null,
  } = useMemo(() => refreshApi, [refreshApi]);

  useEffect(() => {
    if (status === EXPORT_STATUS.complete) {
      return;
    }

    const interval = setInterval(() => {
      get(url, params, prefixApi).then((response) => {
        setData(response);
        if (response?.status === EXPORT_STATUS.complete) {
          clearInterval(interval);
          dispatch(setExportList(response));
        }
      });
    }, INTERVAL_TIME);

    return () => clearInterval(interval);
  }, [dispatch, url, prefixApi, params, status]);

  return (
    <div>
      <div>
        <div className="flex-center-start">
          <CSVIcon className="text-20 mr-5" />
          {name}
        </div>
      </div>
      <div className="flex-center-start mt-6">
        {data?.status === EXPORT_STATUS.complete && data?.url ? (
          <a
            href={data.url || '/#'}
            target="_blank"
            rel="noopener noreferrer"
            className="text-secondary-blue flex-center fw-bold"
            download={name}
          >
            <DownloadIcon className="text-18 mr-8" />
            {i18next.t('common.downloadFile')}
          </a>
        ) : (
          <>
            <AnimateProgress
              trailColor="#EBECF0"
              strokeColor="#3570DB"
              percent={20}
              showInfo={false}
            />
            <div className="ml-8">
              {data?.status ?? `${i18next.t('common.running')}`}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default DownloadItem;

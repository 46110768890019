import React from 'react';
import PropTypes from 'prop-types';
import i18next from 'i18next';
import { MailIcon, PhoneIcon } from 'components/common/SVGIcon';

const JobContactInfo = ({ record }) => (
  <div className="content-info-other">
    <div className="item-info">
      <PhoneIcon />
      <span>{record?.jobContactPhone || i18next.t('error.waitingUpdate')}</span>
    </div>
    <div className="item-info">
      <MailIcon />
      <span className="value-info-item">
        {record?.jobContactEmail || i18next.t('error.waitingUpdate')}
      </span>
    </div>
  </div>
);

JobContactInfo.propTypes = {
  record: PropTypes.object,
};

export default JobContactInfo;

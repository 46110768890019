import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import CardSection from 'components/common/CardSection';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { getAllSocialMediaChannels } from 'redux/centres/actions';
import { Avatar } from 'antd';
import { SOCIAL_MEDIA_CHANNEL_TYPE } from 'configs/localData/constant';
import i18next from 'i18next';
import { forEach } from 'lodash-es';
import {
  getSocialMediaChannelsData,
  getTotalSocialMediaChannelsValue,
} from 'redux/centres/selectors';
import { getATagHref } from 'utils/tools';
import SocialMediaChannelsModal from '../../Modals/SocialMediaChannelsModal';

const SocialMediaChannels = () => {
  const { id: centreId } = useParams();
  const modalRef = useRef();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      getAllSocialMediaChannels({ centreId, type: SOCIAL_MEDIA_CHANNEL_TYPE }),
    );
  }, [dispatch, centreId]);

  const socialMedialChannelsList = useSelector(getSocialMediaChannelsData);

  const totalSocialMedia = useSelector(getTotalSocialMediaChannelsValue);

  const initialValue = useMemo(() => {
    const res = {};

    forEach(socialMedialChannelsList, (item) => {
      if (item.id) {
        res[item.id] = item.externalLink;
      }
    });

    return res;
  }, [socialMedialChannelsList]);

  const onClickButton = useCallback(() => {
    modalRef.current.open(initialValue);
  }, [initialValue]);

  return (
    <>
      <CardSection
        isEdit
        title="centreDetail.socialMediaChannels"
        action="isEdit"
        onClickButton={onClickButton}
        permissionKey="myCentres"
      >
        {socialMedialChannelsList?.length === totalSocialMedia &&
          socialMedialChannelsList?.map((item, index) => (
            <div
              gutter={12}
              className="mb-20 flex items-center"
              key={Number(index)}
            >
              <div>
                <Avatar src={item?.icon} size={20} />
              </div>
              <div className="text-16 break-all ml-12">
                {item?.externalLink ? (
                  <a
                    href={getATagHref(item?.externalLink)}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-gray"
                  >
                    {item?.externalLink}
                  </a>
                ) : (
                  <div className="text-light-gray">
                    {`${item?.name} ${i18next.t('centreDetail.notUpdatedYet')}`}
                  </div>
                )}
              </div>
            </div>
          ))}
      </CardSection>
      <SocialMediaChannelsModal
        ref={modalRef}
        socialMedialChannelsList={socialMedialChannelsList}
      />
    </>
  );
};

export default SocialMediaChannels;

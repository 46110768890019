import React, { useEffect } from 'react';
import { Button, Col, Form, FormInstance, Input, Radio, Row } from 'antd';
import ModalCustom from 'components/common/ModalCustom';
import i18next from 'i18next';
import RestSelect from 'components/RestInput/RestSelect';
import { useGetStaffProfileConfigsQuery } from 'redux/@rtkQuery/educatorStaffProfile';
import { isNumber } from 'lodash-es';
import { OTHER } from 'containers/Centres/components/CentreTabs/EducatorStaffProfilesTab/constants';
import { EducationStaffProfile } from 'types/staffProfiles';

interface Props {
  formModalStep3: FormInstance;
  visible: boolean;
  title: string;
  isDisabled?: boolean;
  setVisible: (param: boolean) => void;
  editIndexCurrent: number;
  onSave: () => void;
  onSaveAddAnother: () => void;
  currentEducation?: EducationStaffProfile;
  isOther?: boolean;
  setIsOther?: (params: boolean) => void;
}

const Index = ({
  formModalStep3,
  visible,
  title,
  isDisabled,
  editIndexCurrent,
  setVisible,
  onSave,
  onSaveAddAnother,
  currentEducation,
  isOther,
  setIsOther,
}: Props) => {
  const { data } = useGetStaffProfileConfigsQuery();

  const onCancel = () => {
    setVisible(false);
  };

  const onChangeStudy = (value) => {
    if (value === OTHER) {
      formModalStep3.setFieldsValue({ otherFieldOfStudy: '' });
      setIsOther(true);
    } else setIsOther(false);
  };
  const qualificationOptions = data?.qualifications?.map((item) => ({
    ...item,
    value: `${item?.name}-${item?.rank}`,
  }));

  useEffect(() => {
    setIsOther(
      visible &&
        isNumber(editIndexCurrent) &&
        currentEducation?.fieldOfStudy === OTHER,
    );
  }, [editIndexCurrent, currentEducation, visible, setIsOther]);

  return (
    <ModalCustom
      visible={visible}
      title={i18next.t(title)}
      onCancel={onCancel}
      width={600}
      isDisabled={isDisabled}
      footer={
        <>
          {isNumber(editIndexCurrent) ? (
            <div className="flex justify-end">
              <Button type="primary" ghost onClick={onCancel} className="mr-12">
                {i18next.t('button.cancel')}
              </Button>
              <div>
                <Button type="primary" onClick={onSave}>
                  {i18next.t('button.save')}
                </Button>
              </div>
            </div>
          ) : (
            <div className="flex justify-between	">
              <Button type="primary" ghost onClick={onCancel}>
                {i18next.t('button.cancel')}
              </Button>
              <div>
                <Button type="primary" ghost onClick={onSave}>
                  {i18next.t('button.save')}
                </Button>

                <Button type="primary" onClick={onSaveAddAnother}>
                  {i18next.t('button.saveAddAnother')}
                </Button>
              </div>
            </div>
          )}
        </>
      }
    >
      <Form form={formModalStep3} layout="vertical">
        <RestSelect
          label={i18next.t('educatorStaffProfile.qualification')}
          source="name"
          placeholder="jobs.basicInformation.input.selectOne"
          resourceData={qualificationOptions}
          valueProp="value"
          titleProp="name"
          rules={[{ required: true, message: i18next.t('error.required') }]}
          autoComplete="off"
        />
        <Form.Item
          name="schoolName"
          colon={false}
          label={i18next.t(
            'educatorStaffProfile.schoolCollegeUniversityInstitution',
          )}
          rules={[
            {
              max: 250,
              message: i18next.t('error.maxLength', { max: 250 }),
            },
          ]}
        >
          <Input
            autoComplete="off"
            placeholder={i18next.t(
              'educatorStaffProfile.placeholder.enterSchoolName',
            )}
          />
        </Form.Item>

        <Row gutter={[24, 0]}>
          <Col span={isOther ? 12 : 24}>
            <RestSelect
              onChange={onChangeStudy}
              label={i18next.t('educatorStaffProfile.fieldOfStudy')}
              source="fieldOfStudy"
              placeholder="jobs.basicInformation.input.selectOne"
              resourceData={data?.fieldOfStudies ?? []}
              valueProp="name"
              titleProp="name"
              autoComplete="off"
            />
          </Col>
          <Col span={12}>
            {isOther && (
              <Form.Item
                name="otherFieldOfStudy"
                label={i18next.t('educatorStaffProfile.otherFieldOfStudy')}
                rules={[
                  { required: true, message: i18next.t('error.required') },
                ]}
              >
                <Input
                  autoComplete="off"
                  placeholder={i18next.t(
                    'educatorStaffProfile.placeholder.enterHere',
                  )}
                />
              </Form.Item>
            )}
          </Col>
        </Row>
        <Form.Item name="isCompleted" initialValue>
          <Radio.Group className="flex justify-between">
            <Row className="w-full">
              <Col span={12}>
                <Radio value>
                  {i18next.t('educatorStaffProfile.completed')}
                </Radio>
              </Col>
              <Col span={12}>
                <Radio value={false}>
                  {i18next.t('educatorStaffProfile.studying')}
                </Radio>
              </Col>
            </Row>
          </Radio.Group>
        </Form.Item>
      </Form>
    </ModalCustom>
  );
};

export default Index;

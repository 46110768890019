import {
  CHART_MAXIMUM_POINT,
  COMPARE_OPTIONS,
  DEFAULT_COMPARE_LAST_7_DAYS,
  DEFAULT_CURRENT_PAGE,
  DEFAULT_LAST_7_DAYS,
  LIMIT_CENTRES,
  RANGE_PICKER_OPTION_CUSTOM,
} from 'containers/MarketingMetrics/constants';
import { CHANNEL_OPTIONS } from 'configs/localData';
import { formatFromToTimeFilterSubmit } from 'utils/dataUtils';
import moment from 'moment';
import { getDateDiffByUnit, rangeLastYear } from 'utils/tools';
import {
  CHART_RESOLUTION,
  TimeRange,
} from 'redux/@rtkQuery/marketingMetris/type';
import { isEmpty } from 'lodash-es';

export const getCentreMetricsDefaultsFilter = () => {
  const comparedTime = formatFromToTimeFilterSubmit(
    DEFAULT_COMPARE_LAST_7_DAYS,
  );
  const filteredTime = formatFromToTimeFilterSubmit(DEFAULT_LAST_7_DAYS);

  const defaultTimeRange = {
    filteredStart: filteredTime.from,
    filteredEnd: filteredTime.to,
    comparedStart: comparedTime.from,
    comparedEnd: comparedTime.to,
  };

  return {
    filter: {
      channel: CHANNEL_OPTIONS.all.value,
      timeRange: defaultTimeRange,
      dateOption: RANGE_PICKER_OPTION_CUSTOM.last7Days.key,
      isCompare: 'true',
      compareOption: COMPARE_OPTIONS.precedingPeriod,
    },
    page: DEFAULT_CURRENT_PAGE,
    size: LIMIT_CENTRES,
  };
};

export const calculatorCompareDate = (value, compareOption) => {
  if (isEmpty(value[0])) return null;
  if (compareOption === COMPARE_OPTIONS.precedingPeriod) {
    const differenceDate = Math.abs(value[0].diff(value[1], 'days')) + 1;
    return [
      moment(value[0]).subtract(differenceDate, 'days'),
      moment(value[0]).subtract(1, 'days'),
    ];
  }
  return rangeLastYear(value);
};

export const getMaximumChartResolution = ({
  filteredStart,
  filteredEnd,
}: TimeRange = {}): CHART_RESOLUTION => {
  if (
    getDateDiffByUnit(filteredStart, filteredEnd, 'days') < CHART_MAXIMUM_POINT
  ) {
    return CHART_RESOLUTION.DAILY;
  }

  if (
    getDateDiffByUnit(filteredStart, filteredEnd, 'weeks') < CHART_MAXIMUM_POINT
  ) {
    return CHART_RESOLUTION.WEEKLY;
  }

  if (
    getDateDiffByUnit(filteredStart, filteredEnd, 'months') <
    CHART_MAXIMUM_POINT
  ) {
    return CHART_RESOLUTION.MONTHLY;
  }

  if (
    getDateDiffByUnit(filteredStart, filteredEnd, 'quarters') <
    CHART_MAXIMUM_POINT
  ) {
    return CHART_RESOLUTION.QUARTERLY;
  }

  return CHART_RESOLUTION.YEARLY;
};

import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import debounce from 'lodash/debounce';
import { Form } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import {
  retrieveReference as retrieveReferenceAction,
  retrieveReferenceInputDataVer2 as retrieveReferenceInputDataAction,
} from 'redux/referenceData/actions';
import { getRecordData } from 'utils/tools';
import { RestInputContext } from 'components/RestInput/RestInputContext';
import {
  DEFAULT_CURRENT_PAGE,
  LIMIT_ITEM,
} from 'containers/JobListings/constants';
import {
  getEnabledLoadMoreReferenceV2,
  getReferenceInputResource,
  getReferenceLoading,
  getReferenceResourceData,
} from '../../../redux/referenceData/selectors';

function ReferenceInputVer2(props) {
  const {
    record,
    children,
    source,
    setFieldsValue,
    searchKey,
    resource,
    dependency,
    style,
    filterKey,
    mappedBy,
    prefixUrl,
    initialValue,
    requestParams,
    requestApi,
    initialFilter,
  } = props;
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const referenceInputResource = useSelector((state) =>
    getReferenceInputResource(state, props),
  );

  const resourceData = initialValue
    ? [initialValue, ...referenceInputResource]
    : referenceInputResource;

  const loadingData = useSelector((state) => getReferenceLoading(state, props));
  const enabledLoadMore = useSelector((state) =>
    getEnabledLoadMoreReferenceV2(state, props),
  );
  const referenceData = useSelector((state) =>
    getReferenceResourceData(state, props),
  );

  const retrieveReference = useCallback(
    (data) =>
      dispatch(
        retrieveReferenceAction({
          resource: props.resource,
          ids: Array.isArray(data) ? data : [data],
          mappedBy,
          filterKey,
          customApiResource: props.customApiResource,
        }),
      ),
    [dispatch, filterKey, mappedBy, props.customApiResource, props.resource],
  );

  const retrieveList = useCallback(
    (filter, isRefresh) => {
      dispatch(
        retrieveReferenceInputDataAction({
          resource,
          data: {
            page: 1,
            size: 10,
            ...filter,
            ...props.initialFilter,
            filter: {
              ...props.initialFilter?.filter,
              ...filter?.filter,
            },
          },
          options: {
            primaryKey: mappedBy,
            isRefresh,
            customApiResource: props.customApiResource,
            prefixUrl,
            requestParams,
            requestApi,
          },
        }),
      );
    },
    [
      dispatch,
      mappedBy,
      prefixUrl,
      props.customApiResource,
      requestApi,
      requestParams,
      resource,
      props.initialFilter,
    ],
  );

  const onSearch = useCallback(
    (value) => {
      if (searchKey) {
        retrieveList({ [`filter[${searchKey}]`]: value }, true);
      }
    },
    [searchKey, retrieveList],
  );

  const onClear = useCallback(() => {
    retrieveList({ [`filter[${searchKey}]`]: {} }, true);
  }, [retrieveList, searchKey]);

  const debounceSearch = debounce(onSearch, 300);

  const retrieveListWaypoint = () => {
    if (enabledLoadMore) {
      retrieveList({
        page: referenceData?.page
          ? referenceData.page + 1
          : DEFAULT_CURRENT_PAGE,
        size: referenceData.size || LIMIT_ITEM,
        [`filter[${searchKey}]`]: referenceData?.filter[searchKey] || {},
      });
    }
  };

  useEffect(() => {
    if (getRecordData(record, source)) {
      retrieveReference(getRecordData(record, source));
    }
    retrieveList(
      initialFilter || { size: 10, page: 1, [`filter[${searchKey}]`]: {} },
      true,
    );
  }, [
    dependency,
    retrieveList,
    searchKey,
    retrieveReference,
    record,
    source,
    initialFilter,
  ]);

  const newChildren = React.cloneElement(children, {
    onSearch: (value) => {
      debounceSearch(value);
    },
    onClear,
    onBlur: debounce(onClear, 600),
    onEnter: () => retrieveListWaypoint(),
    searchKey,
    record,
    loading: loadingData,
    form,
    source,
    setFieldsValue,
    resourceData,
    resource,
    style,
  });
  return newChildren;
}

ReferenceInputVer2.propTypes = {
  resource: PropTypes.string.isRequired,
  resourceData: PropTypes.array,
  record: PropTypes.object,
  retrieveList: PropTypes.func,
  children: PropTypes.node,
  source: PropTypes.string,
  retrieveReference: PropTypes.func,
  setFieldsValue: PropTypes.func,
  form: PropTypes.object,
  searchKey: PropTypes.string,
  enabledLoadMore: PropTypes.bool,
  loadingData: PropTypes.bool,
  dependency: PropTypes.any,
  style: PropTypes.object,
  searchOutsideFilter: PropTypes.bool,
  resourceCustom: PropTypes.string,
  initialFilter: PropTypes.object,
};

function ReferenceInputWrapper(props) {
  return (
    <RestInputContext.Consumer>
      {({ record }) => <ReferenceInputVer2 record={record} {...props} />}
    </RestInputContext.Consumer>
  );
}

export default ReferenceInputWrapper;

import React, { useState, useEffect, useRef, useMemo } from 'react';
import PropTypes from 'prop-types';
import RestInputItem from 'components/RestInput/RestInputItem';
import {
  Checkbox,
  Col,
  Form,
  InputNumber,
  Radio,
  Row,
  Select,
  Space,
} from 'antd';
import RestSelect from 'components/RestInput/RestSelect';
import i18next from 'i18next';
import { QuestionCircleFilled } from '@ant-design/icons';
import {
  getRuleMinNumber,
  getRuleOnlyIntegerNumber,
} from 'utils/validateUtils';
import { getSalaryFormatter } from 'utils/tools';
import { useSelector } from 'react-redux';
import {
  getJobTemplateConfigSelector,
  getJobTemplatePayloadSelector,
} from 'redux/jobs/jobTemplates/selectors';
import { CASUAL_VALUE } from 'containers/JobTemplates/constants';
import { isEmpty } from 'lodash-es';
import { getDefaultPayTypeId } from 'containers/JobTemplates/utils';
import MinimumJobMatchModal from '../Modals/MinimumJobMatchModal';
import { BasicInformationGeneralFormStyles } from './styles';

const BasicInformationGeneralForm = ({ form, record }) => {
  const minJobMatchRef = useRef();

  const jobTemplateConfig = useSelector(getJobTemplateConfigSelector);
  const jobTemplatePayload = useSelector(getJobTemplatePayloadSelector);

  const selectedJobTypeName = jobTemplateConfig?.jobTypes?.find(
    (item) => item.value === jobTemplatePayload?.jobType,
  )?.name;

  const [discountId, setDiscountId] = useState(
    jobTemplatePayload?.discount?.typeId || record?.discount?.type?.id,
  );

  const [jobTypeId, setJobTypeId] = useState(jobTemplatePayload?.jobType);
  const [jobTypeName, setJobTypeName] = useState(
    selectedJobTypeName || record?.jobType?.name,
  );
  const [payTypeId, setPayTypeId] = useState(
    jobTemplatePayload?.salary?.payTypeId || record?.salary?.payType?.id,
  );

  const filterOption = (input, option) =>
    option.children.toLowerCase().includes(input.toLowerCase());

  const handleDiscountChange = (e) => {
    setDiscountId(e.target.value);
  };

  const handleJobTypeChange = (e) => {
    setJobTypeId(e.target.value);
  };

  const handlePayTypeChange = (value) => {
    setPayTypeId(value);
  };

  const onGetJobTypeName = (value) => {
    setJobTypeName(value);
  };

  const defaultPayTypeId = useMemo(
    () => getDefaultPayTypeId(jobTemplateConfig),
    [jobTemplateConfig],
  );

  useEffect(() => {
    if (!isEmpty(jobTemplateConfig) && !payTypeId && form) {
      setPayTypeId(defaultPayTypeId);

      form.setFieldsValue({
        salary: {
          payTypeId: defaultPayTypeId,
        },
      });
    }
  }, [jobTemplateConfig, form, payTypeId, defaultPayTypeId]);

  useEffect(() => {
    if (!isEmpty(jobTemplatePayload)) {
      setPayTypeId(jobTemplatePayload?.salary?.payTypeId);
      setDiscountId(jobTemplatePayload?.discount?.typeId);
      form.setFieldsValue(jobTemplatePayload);
    }
  }, [form, jobTemplatePayload]);

  return (
    <BasicInformationGeneralFormStyles>
      <RestInputItem
        required
        source="templateName"
        header="jobs.basicInformation.templateName"
        placeholder="jobs.basicInformation.input.enterHere"
      />

      <Row gutter={[20, 20]}>
        <Col xs={24} sm={12}>
          <RestInputItem
            required
            source="jobTitle"
            header="jobs.basicInformation.jobTitle"
            placeholder="jobs.basicInformation.input.enterHere"
          />
        </Col>
        <Col xs={24} sm={12}>
          <RestSelect
            isShowSearch
            isFilterOption
            source="jobRole"
            resourceData={jobTemplateConfig?.jobRoles}
            titleProp="name"
            valueProp="value"
            header="jobs.basicInformation.jobRole"
            placeholder="jobs.basicInformation.input.selectOne"
            required
          />
        </Col>
      </Row>

      <Form.Item
        label={i18next.t('jobs.basicInformation.jobType')}
        name="jobType"
        rules={[{ required: true, message: i18next.t('error.required') }]}
      >
        <Radio.Group
          onChange={handleJobTypeChange}
          value={jobTypeId}
          className="w-full"
        >
          <div className="column-items">
            {jobTemplateConfig?.jobTypes?.map((item) => (
              <div key={item?.value} className="break-inside-avoid mb-24">
                <Radio
                  value={item?.value}
                  onClick={() => onGetJobTypeName(item?.name)}
                >
                  {item?.name}
                </Radio>
              </div>
            ))}
          </div>
        </Radio.Group>
      </Form.Item>

      <Form.Item
        label={i18next.t('jobs.basicInformation.discounts')}
        name={['discount', 'typeId']}
        rules={[{ required: true, message: i18next.t('error.required') }]}
      >
        <Radio.Group onChange={handleDiscountChange} value={discountId}>
          <Space direction="vertical" size={12}>
            {jobTemplateConfig?.discounts?.map((item) => (
              <div className="flex items-center" key={item?.typeId}>
                <Radio value={item?.typeId} className="whitespace-nowrap">
                  {item?.name}
                </Radio>
                {item?.isIncludedInput && (
                  <Form.Item
                    name={
                      discountId === item?.typeId
                        ? ['discount', 'value']
                        : undefined
                    }
                    rules={getRuleOnlyIntegerNumber(
                      'input.discount.validateMsg.invalid',
                    )}
                    className="m-0"
                  >
                    <InputNumber
                      min={0}
                      max={100}
                      formatter={(value) => `${value}%`}
                      parser={(value) => value.replace('%', '')}
                      disabled={item?.typeId !== discountId}
                    />
                  </Form.Item>
                )}
              </div>
            ))}
          </Space>
        </Radio.Group>
      </Form.Item>
      <Row gutter={[20, 20]}>
        <Col xl={8}>
          <Form.Item
            label={i18next.t('jobs.basicInformation.showPayBy')}
            name={['salary', 'payTypeId']}
            rules={[{ required: true, message: i18next.t('error.required') }]}
            initialValue={payTypeId}
          >
            <Select
              placeholder={i18next.t('jobs.basicInformation.input.selectOne')}
              onChange={handlePayTypeChange}
              value={payTypeId}
              getPopupContainer={(trigger) => trigger.parentNode}
            >
              {jobTemplateConfig?.salary?.payTypes?.map((item) => (
                <Select.Option value={item?.value} key={item?.value}>
                  {item?.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>

      <Row align="middle" gutter={[20, 20]}>
        <div className="flex w-full">
          {payTypeId === defaultPayTypeId ? (
            <>
              <Col xl={5}>
                <Form.Item
                  label={i18next.t('jobs.basicInformation.minimum')}
                  name={['salary', 'min']}
                  validateFirst
                  rules={[
                    ...getRuleMinNumber('input.salary.validateMsg.invalid', 0),
                    { required: true, message: i18next.t('error.required') },
                  ]}
                >
                  <InputNumber
                    min={0}
                    prefix="$"
                    formatter={getSalaryFormatter}
                    className="w-full"
                  />
                </Form.Item>
              </Col>
              <Col className="mt-45 text-gray-neutral-500">
                <span>{i18next.t('jobs.basicInformation.to')}</span>
              </Col>
              <Col xl={5}>
                <Form.Item
                  label={i18next.t('jobs.basicInformation.maximum')}
                  name={['salary', 'max']}
                  validateFirst
                  rules={[
                    { required: true, message: i18next.t('error.required') },
                    () => ({
                      validator(_, value) {
                        const minValue = form.getFieldValue(['salary', 'min']);
                        if (value <= minValue) {
                          return Promise.reject(
                            new Error(
                              i18next.t(
                                'jobs.basicInformation.input.validateMinNumber',
                              ),
                            ),
                          );
                        }
                        return Promise.resolve();
                      },
                    }),
                  ]}
                >
                  <InputNumber
                    prefix="$"
                    formatter={getSalaryFormatter}
                    className="w-full"
                  />
                </Form.Item>
              </Col>
            </>
          ) : (
            <Col xl={8}>
              <Form.Item
                label={i18next.t('jobs.basicInformation.amount')}
                name={['salary', 'amount']}
                validateFirst
                rules={[
                  ...getRuleMinNumber('input.salary.validateMsg.invalid', 0),
                  { required: true, message: i18next.t('error.required') },
                ]}
              >
                <InputNumber
                  prefix="$"
                  formatter={getSalaryFormatter}
                  className="w-full"
                />
              </Form.Item>
            </Col>
          )}
          <Col xl={5}>
            <Form.Item
              label={i18next.t('jobs.basicInformation.rate')}
              name={['salary', 'payRateId']}
              rules={[{ required: true, message: i18next.t('error.required') }]}
            >
              <Select
                placeholder={i18next.t('jobs.basicInformation.input.selectOne')}
                className="w-full"
                getPopupContainer={(trigger) => trigger.parentNode}
              >
                {jobTemplateConfig?.salary?.payRates?.map((item) => (
                  <Select.Option value={item?.value} key={item?.value}>
                    {item?.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col xl={8} className="flex mt-6">
            <Form.Item
              name={['salary', 'isIncludesSuper']}
              valuePropName="checked"
              required
            >
              <Checkbox className="items-center mt-32">
                {i18next.t('jobs.basicInformation.includesSuper')}
              </Checkbox>
            </Form.Item>
            {jobTypeName === CASUAL_VALUE && (
              <Form.Item
                name={['salary', 'isIncludesCasualLoading']}
                valuePropName="checked"
                required
              >
                <Checkbox className="items-center mt-32 ml-16">
                  {i18next.t('jobs.basicInformation.includesCasualLoading')}
                </Checkbox>
              </Form.Item>
            )}
          </Col>
        </div>
      </Row>

      <Row gutter={[20, 20]}>
        <Col xs={24} md={12}>
          <RestSelect
            required
            isShowSearch
            isFilterOption
            source="experience"
            resourceData={jobTemplateConfig?.experiences}
            titleProp="name"
            valueProp="value"
            header="jobs.basicInformation.experience"
            placeholder="jobs.basicInformation.input.selectOne"
          />
        </Col>
        <Col xs={24} md={12}>
          <Form.Item
            label={i18next.t('jobs.basicInformation.languages')}
            name="languages"
          >
            <Select
              allowClear
              mode="multiple"
              showArrow
              showSearch
              filterOption={filterOption}
              placeholder={i18next.t(
                'jobs.basicInformation.input.selectLanguages',
              )}
            >
              {jobTemplateConfig?.languages?.map((item) => (
                <Select.Option value={item?.value} key={item?.value}>
                  {item?.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={[20, 20]}>
        <Col xs={24} md={12}>
          <RestSelect
            isShowSearch
            isFilterOption
            source="qualification"
            resourceData={jobTemplateConfig?.qualifications}
            titleProp="name"
            valueProp="value"
            header="jobs.basicInformation.minimumQualificationRequired"
            placeholder="jobs.basicInformation.input.selectQualification"
          />
        </Col>
        <Col xs={24} md={12}>
          <Form.Item
            label={i18next.t('jobs.basicInformation.workEligibility')}
            name="eligibility"
          >
            <Select
              mode="multiple"
              allowClear
              showArrow
              showSearch
              filterOption={filterOption}
              placeholder={i18next.t(
                'jobs.basicInformation.input.selectWorkEligibility',
              )}
            >
              {jobTemplateConfig?.eligibilities?.map((item) => (
                <Select.Option value={item?.value} key={item?.value}>
                  {item?.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>

      <Form.Item
        label={i18next.t('jobs.basicInformation.minimumJobMatch')}
        name="minJobMatch"
        tooltip={{
          icon: <QuestionCircleFilled />,
          onClick: () => minJobMatchRef.current.openModal?.(),
        }}
        rules={getRuleOnlyIntegerNumber(
          'input.minJobMatch.validateMsg.invalid',
        )}
      >
        <InputNumber
          min={0}
          max={100}
          formatter={(value) => `${value}%`}
          parser={(value) => value.replace('%', '')}
          className="w-full"
        />
      </Form.Item>
      <MinimumJobMatchModal ref={minJobMatchRef} />
    </BasicInformationGeneralFormStyles>
  );
};

BasicInformationGeneralForm.propTypes = {
  form: PropTypes.object,
  record: PropTypes.object,
};

export default BasicInformationGeneralForm;

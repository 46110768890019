import React from 'react';
import PropTypes from 'prop-types';
import i18next from 'i18next';
import ModuleAccessWarning from '../ModuleAccessWarning';

const JobModuleAccessWarning = ({ title }) => (
  <ModuleAccessWarning
    title={i18next.t(title)}
    headlineTextCustom={`${i18next.t('sideBar.jobs')} - ${i18next.t(title)}.`}
  />
);

JobModuleAccessWarning.propTypes = {
  title: PropTypes.string,
};

export default JobModuleAccessWarning;

import React from 'react';
import i18next from 'i18next';
import { Button, Typography } from 'antd';

interface Props {
  Icon: React.ElementType;
  description: string;
  buttonTitle: string;
  onAdd: () => void;
  className?: string;
  name?: string;
  disabled?: boolean;
}
const { Text } = Typography;

const EmptyContainer = ({
  Icon,
  description,
  onAdd,
  buttonTitle,
  className,
  name,
  disabled = false,
}: Props) => (
  <div className={`flex-center flex-col ${className}`}>
    <Icon className="text-100" />
    <Text className="text-gray-500 text-center w-350">
      {i18next.t(description, { name })}
    </Text>
    <Button
      type="primary"
      className="mt-16"
      onClick={onAdd}
      disabled={disabled}
    >
      {i18next.t(buttonTitle)}
    </Button>
  </div>
);

export default EmptyContainer;

import React from 'react';
import i18next from 'i18next';
import PropTypes from 'prop-types';
import { formatMoney } from 'utils/tools';
import RequiredDays from '../../Primatives/ApplicationItem/RequiredDays';
import { ServicesInfoStyles } from './styles';

const ServicesInfo = ({ child }) => (
  <ServicesInfoStyles>
    <div className="title-service">
      {i18next.t('applications.appliedServices')}
    </div>
    <RequiredDays requiredDays={child.requiredDays} />
    <div className="service-list-item">
      {child.centreServices?.map((item, index) => (
        <div key={String(index)} className="service-item-wrapper">
          <div className="service-item">
            {`${item?.serviceType?.name} - ${formatMoney(
              item.costPerDay,
            )} ${i18next.t('currencyUnit.text')}`}
          </div>

          {item?.serviceAgeGroup?.name && (
            <div className="age-group-service">
              {`(${item?.serviceAgeGroup?.name})`}
            </div>
          )}
        </div>
      ))}
    </div>
  </ServicesInfoStyles>
);

ServicesInfo.propTypes = {
  child: PropTypes.object,
};

ServicesInfo.defaultProps = {
  child: {},
};

export default ServicesInfo;

import React from 'react';
import PropTypes from 'prop-types';
import { isAudioFile, isImageFile, isVideoFile } from 'utils/fileUtils';
import i18next from 'i18next';
import { FileInboxIcon } from 'components/common/SVGIcon';
import { FileItemViewStyles } from './styles';

const FileItemView = ({ item, onPreviewUI }) => {
  const renderFile = () => {
    if (
      (typeof item?.type === 'string' && item.type?.includes('image')) ||
      isImageFile(item.url)
    ) {
      return (
        <img
          className="image-file"
          src={item?.url}
          alt=""
          onClick={() => onPreviewUI(item)}
          role="presentation"
        />
      );
    }

    if (
      (typeof item?.type === 'string' && item.type?.includes('audio')) ||
      isAudioFile(item?.url)
    ) {
      return (
        <audio controls>
          <source src={item?.url} type="audio/mpeg" />
          {i18next.t('error.notSupportAudio')}
          <track src="" kind="captions" srcLang="en" label="english_captions" />
        </audio>
      );
    }

    if (
      (typeof item?.type === 'string' && item.type?.includes('video')) ||
      isVideoFile(item?.url)
    ) {
      return (
        <video height="130" controls>
          <source src={item.url} type="video/mp4" />
          {i18next.t('error.notSupportVideo')}
          <track src="" kind="captions" srcLang="en" label="english_captions" />
        </video>
      );
    }
    return (
      <a
        className="icon-a-item"
        href={item?.url || ''}
        target="_blank"
        rel="noopener noreferrer"
      >
        <FileInboxIcon />
        <span className="file-name">{item?.name}</span>
      </a>
    );
  };

  return (
    <FileItemViewStyles className="file-item">
      {renderFile()}
    </FileItemViewStyles>
  );
};

FileItemView.propTypes = {
  item: PropTypes.object,
  onPreviewUI: PropTypes.func,
};

export default FileItemView;

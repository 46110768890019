import React, { useCallback, useMemo } from 'react';
import i18next from 'i18next';
import { useSelector, useDispatch } from 'react-redux';

import PropTypes from 'prop-types';
import { updateApplicationStatus } from 'redux/applications/actions';
import { applicationsSelectors } from 'redux/applications/selectors';
import RestShow from 'containers/rest/Show';
import DetailApplication from '../components/DetailApplication';
import ShowStyles from './styles';
import ArchiveButton from './ArchiveButton';

const ApplicationsShow = (props) => {
  const dispatch = useDispatch();

  const { match } = props;

  const currentId: string = match?.params?.id;

  const currentData = useSelector(applicationsSelectors.getCurrentData) || {};

  const customBreadcrumb = useMemo(
    () => [
      {
        title: i18next.t('applications.header'),
        path: '/applications/board',
      },
      {
        title: i18next.t('applications.detailTitle'),
        path: '#',
      },
      {
        title: `${currentData.careOption?.firstName || ''} ${
          currentData.careOption?.lastName || ''
        }`,
        path: '#',
      },
    ],
    [currentData.careOption],
  );

  const handleUpdateStatus = useCallback(
    (status, isReduceCountPending) => {
      currentId &&
        dispatch(
          updateApplicationStatus({
            id: currentId,
            status,
            isReduceCountPending,
          }),
        );
    },
    [currentId], // eslint-disable-line
  );

  return (
    <ShowStyles>
      <RestShow
        {...props}
        resource="applications"
        header="applications.detailTitle"
        customBreadcrumb={customBreadcrumb}
        extraAction={
          <ArchiveButton
            isArchived={!!currentData?.archivedReason}
            applicationId={currentId}
            applicationStatus={currentData?.status}
          />
        }
      >
        <DetailApplication handleUpdateStatus={handleUpdateStatus} />
      </RestShow>
    </ShowStyles>
  );
};

ApplicationsShow.propTypes = {
  match: PropTypes.object,
};

export default ApplicationsShow;

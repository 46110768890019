import React from 'react';
import Create from 'containers/rest/Create';
import moment from 'moment';

import Form from '../components/Form';

const CentresCreate = (props) => {
  const formatOnSubmit = ({ dateApplied, ...data }) => ({
    ...data,
    dateApplied: dateApplied
      ? moment(dateApplied).format('DD-MM-YYYY')
      : dateApplied,
  });

  return (
    <Create
      {...props}
      resource="centres"
      defaultOptions={{ isTrimStr: true }}
      formatOnSubmit={formatOnSubmit}
    >
      <Form />
    </Create>
  );
};

export default CentresCreate;

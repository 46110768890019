import { get, post, put } from './utils';

export async function getRepliesOfRating(id, data) {
  return get(`/admin/ratings/${id}/replies`, data);
}

export async function postReply(id, data) {
  return post(`/admin/ratings/${id}/replies`, data);
}

export async function disputeReviewApi(id, values) {
  return put(`/admin/ratings/${id}/dispute-request`, values);
}

import { Checkbox, Form, Space } from 'antd';
import { ADS_IDENTITY_KEY } from 'containers/Advertisement/constants';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAdsFormConfigSelector } from 'redux/advertisement/selectors';
import { setCreateAdPayload } from 'redux/advertisement/slice';
import { RootState } from 'redux/store';
import IdentityModal from './IdentityModal';
import Footer from './Footer';

const IdentityForm = ({ form, identityValues, isShowFooter }) => {
  const dispatch = useDispatch();
  const { adsIdentity } = useSelector(getAdsFormConfigSelector);
  const currentUserId = useSelector(
    (state: RootState) => state?.auth?.data?.id,
  );
  const adIdentityKeyList = JSON.parse(localStorage.getItem(ADS_IDENTITY_KEY));
  const disableShowAgain = adIdentityKeyList?.some(
    (item) => item?.currentUserId === currentUserId,
  );
  const [isChildcareRelated, setIsChildcareRelated] = useState(
    identityValues.isChildcareRelated && !disableShowAgain,
  );
  useEffect(() => form.resetFields(), [form, identityValues]);

  const showChildcareRelatedPopup = (e) => {
    if (
      e.target.checked &&
      e.target.id === adsIdentity?.[0]?.name &&
      !disableShowAgain
    ) {
      setIsChildcareRelated(true);
    } else {
      setIsChildcareRelated(false);
    }
  };

  const onNext = () => {
    form
      .validateFields(['isChildcareRelated', 'isSocialRelated'])
      .then((identity) => {
        dispatch(setCreateAdPayload(identity));
      });
  };

  return (
    <div>
      <Form form={form} initialValues={identityValues} onFinish={onNext}>
        <Space size={10} direction="vertical" className="mt-24">
          {adsIdentity?.map((item) => (
            <Form.Item
              noStyle
              name={item?.name}
              key={item?.name}
              valuePropName="checked"
            >
              <Checkbox
                onChange={showChildcareRelatedPopup}
                checked={identityValues[item?.name]}
              >
                {item?.description}
              </Checkbox>
            </Form.Item>
          ))}
        </Space>
        {isShowFooter && <Footer onNext={onNext} />}
      </Form>
      <IdentityModal
        visible={isChildcareRelated}
        setIsChildcareRelated={setIsChildcareRelated}
      />
    </div>
  );
};

export default IdentityForm;

import React from 'react';
import { Button, Card } from 'antd';
import { PropTypes } from 'prop-types';
import successImage from 'assets/images/image-success.png';
import { ShootingStarIcon } from 'components/common/SVGIcon';
import i18next from 'i18next';
import ModalCustom from 'components/common/ModalCustom';

const { Meta } = Card;

const SuccessListingStrengthModal = ({
  visible,
  onCancel,
  onCompleted,
  loading,
}) => (
  <ModalCustom
    visible={visible}
    onCancel={onCancel}
    footer={null}
    className="success-modal"
    loading={loading}
    closable={false}
    width={600}
  >
    <Card
      hoverable
      style={{ width: 240 }}
      cover={<img className="success-image" alt="example" src={successImage} />}
    >
      <Meta title={i18next.t('successModal.congratulation')} />
      <h3>
        {i18next.t('successModal.l1')}
        <span className="start-icon">
          <ShootingStarIcon />
        </span>
        {i18next.t('successModal.l2')}
      </h3>
      <Button
        className="w-full mt-16 btnOk"
        type="primary"
        onClick={onCompleted}
      >
        {i18next.t('button.OK')}
      </Button>
    </Card>
  </ModalCustom>
);

SuccessListingStrengthModal.propTypes = {
  visible: PropTypes.bool,
  onCancel: PropTypes.func,
  onCompleted: PropTypes.func,
  loading: PropTypes.bool,
};

export default SuccessListingStrengthModal;

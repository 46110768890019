import { isEmpty, groupBy, sumBy, mapValues } from 'lodash-es';
import { APPLICATION_STATUS_CONSTANT } from 'configs/localData';

const getTotalValue = (arr) =>
  sumBy(arr, (item) => item.careOption?.value || 0);

// total value multi care options
// return sumBy(arr, (item) => sumBy(item.careOptions, care => care.value || 0));
export const getSummaries = (data) => {
  const dataExceptDeclineCancel =
    data?.filter(
      (item) =>
        item.status !== APPLICATION_STATUS_CONSTANT.declined &&
        item.status !== APPLICATION_STATUS_CONSTANT.cancelled,
    ) || [];

  const totalApplications = dataExceptDeclineCancel.length || 0;

  const totalAccepted =
    data?.filter((item) => item.status === APPLICATION_STATUS_CONSTANT.accepted)
      ?.length || 0;

  const totalValue = getTotalValue(dataExceptDeclineCancel);

  return {
    totalApplications,
    totalAccepted,
    totalValue,
  };
};

export const formatBoardData = (data) => {
  const arrData = Object.values(data);
  const groupByStatus = groupBy(arrData, 'status') || {};
  const summaryStatus = mapValues(groupByStatus, (arr) => ({
    count: arr.length,
  }));

  return { data: groupByStatus, summaryStatus };
};

export const formatString = (value) => value?.trim()?.toLowerCase();

export const formatDate = (value) => new Date(value);

export const conditionParent = (valueFilter, additionalParentInfo = {}) => {
  const value = formatString(valueFilter);

  if (!value) return true;

  if (isEmpty(additionalParentInfo)) return false;

  const info = {
    firstName: formatString(additionalParentInfo?.firstName),
    lastName: formatString(additionalParentInfo?.lastName),
    email: formatString(additionalParentInfo?.email),
  };

  const firstLatsName = `${info.firstName || ''} ${info.lastName || ''}`;

  const lastFirstName = `${info.lastName || ''} ${info.firstName || ''}`;

  return (
    lastFirstName?.indexOf(value) > -1 ||
    firstLatsName?.indexOf(value) > -1 ||
    info.email?.indexOf(value) > -1
  );
};

export const conditionEmail = (valueFilter, email) => {
  const value = formatString(valueFilter);

  if (!value) {
    return true;
  }

  return email?.indexOf(value) > -1;
};

export const conditionStatus = (valueFilter, status) => {
  const value = formatString(valueFilter);

  if (!value) return true;

  return value === status.toLowerCase();
};

export const conditionArchivedReason = (valueFilter, archivedReason) => {
  if (!valueFilter) return true;

  return valueFilter === archivedReason;
};

export const conditionService = (valueFilter, careOption) => {
  if (!valueFilter) return true;

  if (isEmpty(careOption?.centreServices)) return false;

  return !careOption?.centreServices?.every(
    (service) => service.serviceTypeId !== valueFilter,
  );
};

export const conditionTime = (valueFilter, time) => {
  if (!valueFilter?.$gt || !valueFilter?.$lt) return true;

  const dateTime = formatDate(time);

  return (
    dateTime >= formatDate(valueFilter?.$gt) &&
    dateTime <= formatDate(valueFilter?.$lt)
  );
};

export const conditionChildName = (valueFilter, careOption) => {
  const value = formatString(valueFilter);

  if (!value) return true;

  if (isEmpty(careOption)) return false;

  const info = {
    firstName: formatString(careOption?.firstName),
    lastName: formatString(careOption?.lastName),
  };

  const firstLatsName = `${info.firstName || ''} ${info.lastName || ''}`;

  const lastFirstName = `${info.lastName || ''} ${info.firstName || ''}`;

  return firstLatsName.indexOf(value) > -1 || lastFirstName.indexOf(value) > -1;
};

export const conditionCentre = (valueFilter, centreId) =>
  !valueFilter ? true : centreId === valueFilter;

export const conditionAgeGroup = (valueFilter, centreServices) => {
  if (!valueFilter) return true;

  if (isEmpty(centreServices)) return false;

  return !centreServices?.every(
    (service) => service.serviceAgeGroupId !== valueFilter,
  );
};

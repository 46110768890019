import styled from 'styled-components';

const CustomerDetailStyles = styled.div`
  background: ${({ theme }) => theme.background.container};
  padding: 20px;
  margin: -20px !important;
  height: 100%;
  .row-customer-detail {
    display: flex;
    flex-wrap: wrap;
  }
  @media only screen and (max-width: 776px) {
    .row-customer-detail {
      display: flex;
      flex-wrap: wrap;
    }
  }
`;

export default CustomerDetailStyles;

import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { List } from 'antd';
import { ACTIVITY_TYPES } from 'configs/localData';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { formatDateDashboard } from 'utils/textUtils';
import i18next from 'i18next';

import UserInfo from 'components/RestField/UserInfo';
import ExtraAction from './ExtraAction';

const TaskItem = ({ item, isOverdue, handleCompleteTask }) => {
  const taskType = useMemo(
    () =>
      ACTIVITY_TYPES.find((activityType) => activityType.value === item.type) ||
      {},
    [item.type],
  );

  return (
    <List.Item>
      <List.Item.Meta
        avatar={
          taskType.icon ? (
            <taskType.icon className="task-item__icon" />
          ) : (
            <QuestionCircleOutlined className="task-item__icon" />
          )
        }
        title={
          <div className="task-item__title">
            <div className="task-item__left">
              <div className="task-item__name">
                {i18next.t(taskType.text || 'error.waitingUpdate')}
              </div>
              <div className="task-item__time">
                {formatDateDashboard(item.date)}
              </div>
            </div>
            {isOverdue ? (
              <div className="task-item__overdue-label">
                {i18next.t('text.overdue')}
              </div>
            ) : (
              <ExtraAction
                taskId={item.id}
                isCompleted={item.isCompleted}
                handleCompleteTask={handleCompleteTask}
              />
            )}
          </div>
        }
        description={
          <div className="task-item__description">
            <div className="task-item__subject">
              {item.subject || i18next.t('error.waitingUpdate')}
            </div>
            <UserInfo
              record={{
                id: item.applicationId,
                avatar: item.application?.childAvatar,
                fullName: `${item.application?.childFirstName || ''} ${
                  item.application?.childLastName || ''
                }`,
              }}
              prefixLink="/applications"
              suffixLink="show"
            />
          </div>
        }
      />
    </List.Item>
  );
};

TaskItem.propTypes = {
  item: PropTypes.object,
  isOverdue: PropTypes.bool,
  handleCompleteTask: PropTypes.func,
};

TaskItem.defaultProps = {
  item: {},
};

export default TaskItem;

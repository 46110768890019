import { ThemeDefault } from 'types';
import styled, { createGlobalStyle } from 'styled-components';
import { CLASS_UTILITY } from 'utils/css';

interface StyleProps {
  theme?: ThemeDefault;
}

const AppWrapper = styled.div`
  .wrapper-container {
    margin-left: auto;
    margin-right: auto;
    max-width: 1440px;
    position: relative;
    max-height: 900px;
  }

  .gradientBackground {
    background-image: ${({ theme }) =>
      `linear-gradient(90deg, ${theme.palette.lightPrimary}, ${theme.palette.primary})`};
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
  }
`;

export const GlobalStyle = createGlobalStyle`
  ${CLASS_UTILITY}
  .note {
    font-size: 12px;
    color: ${({ theme }) => theme.text.note};
  }

  .text-secondary-blue {
    color: ${({ theme }: StyleProps) => theme.color.blueSecondary};
  }

  .black {
    color: ${({ theme }: StyleProps) => theme.text.primary};
  }

  & > * {
    font-family: 'Mulish', sans-serif;
  }

  .ant-avatar {
    img {
      object-fit: cover !important;
    }
  }

  .reset-padding-overlay .ant-popover-inner-content {
    padding: 0 !important;
  }

  .anticon:before, .anticon:after  {
    display: block;
    font-family: 'anticon', 'kindicare',serif !important;
  }

  .text-gray {
    color: ${({ theme }: StyleProps) => theme.text.primary};
  }

  .text-light-gray {
    color: ${({ theme }: StyleProps) => theme.text.mainL3};
  }

  .text-primary {
    color: ${({ theme }: StyleProps) => theme.palette.primary};
  }

  .text-headerTable {
    color: ${({ theme }: StyleProps) => theme.palette.headerTable};
  }

  .text-gray-neutral-500 {
    color: ${({ theme }: StyleProps) => theme.text.grayNeutral500};
  }

  .text-dark-green {
    color: ${({ theme }: StyleProps) => theme.text.darkGreen};
  }

  .text-green {
    color: ${({ theme }: StyleProps) => theme.palette.green};
  }

  .text-blue {
    color: ${({ theme }: StyleProps) => theme.text.blue};
  }

  .text-pastel-pink {
    color: ${({ theme }: StyleProps) => theme.color.pastelPink};
  }

  .text-blue-info-default {
    color: ${({ theme }: StyleProps) =>
      theme.color.blueInformationDefault} !important;
  }

  .text-gray-300 {
    color: ${({ theme }: StyleProps) => theme.background.gray300};
  }

  .text-gray-700 {
    color: ${({ theme }: StyleProps) => theme.text.gray700};
  }

  .bg-primary {
    background: ${({ theme }: StyleProps) => theme.background.primary};
  }

  .bg-warning {
    background: ${({ theme }: StyleProps) => theme.background.warning};
  }

  .bg-error {
    background: ${({ theme }: StyleProps) => theme.background.error};
  }

  .bg-green {
    background: ${({ theme }: StyleProps) => theme.palette.green};
  }

  .bg-light-pink {
    background: ${({ theme }: StyleProps) => theme.color.lightPink};
  }

  .bg-light-green {
    background: ${({ theme }: StyleProps) => theme.background.lightGreen};
  }

  .bg-gray-700 {
    background: ${({ theme }: StyleProps) => theme.text.gray700};
  }

  .bg-n30 {
    background: ${({ theme }) => theme.boardColors.N30};
  }

  .border-green {
    border-color: ${({ theme }: StyleProps) => theme.palette.green};
  }

  .t-14px-1\\.57 {
    font: normal normal 14px/1.57 ${({ theme }: StyleProps) =>
      theme.fonts.primary};
  }

  .t-500-14px-1\\.57 {
    font: normal 500 14px/1.57 ${({ theme }: StyleProps) =>
      theme.fonts.primary};
  }

  .t-500-16px-1\\.5 {
    font: normal 500 16px/1.5 ${({ theme }: StyleProps) => theme.fonts.header};
  }

  .t-500-24px-1\\.17 {
    font: normal 500 16px/1.17 ${({ theme }: StyleProps) => theme.fonts.header};
  }

  .t-bold {
    font-weight: ${({ theme }: StyleProps) => theme.fontWeight.bold};
  }

  .ml-8px {
    margin-left: 8px;
  }

  .ant-card-body {
    padding-top: 20px
  }

  .text-error {
    color: ${({ theme }: StyleProps) => theme.color.redDark};
  }

  .text-gray-neutral-400 {
    color: ${({ theme }: StyleProps) => theme.color.grayNeutral400};
  }

  .text-blue-secondary {
     color: ${({ theme }: StyleProps) => theme.color.blueSecondary};
  }

  /* ------------------ Override antd---------------- */
  .ant-tag-blue {
    color: #32A4FC;
    background: #E9F4FF;
    border-color: #91d5ff;
  }

  .ant-tag-purple {
    color: #BF2CF3;
    background: #F3EAFF;
    border-color: #d3adf7;
  }

  .ant-tag-green {
    color: #36BF57;
    background: #EDF9F0;
    border-color: #b7eb8f;
  }

  .ant-tag-orange {
    color: #FB8429;
    background: #FFF4EC;
    border-color: #ffd591;
  }

  .ant-progress-inner {
    background-color: #E9ECF7;
  }

  .ant-pagination .ant-pagination-item-active a {
    color: #ffffff;
  }

  .ant-form-item-required::before {
    content: '' !important;
  }

  .ant-form-item-required::after {
    display: inline-block !important;
    margin-left: 4px !important;
    color: #f5222d;
    font-size: 14px;
    font-family: 'Mulish', sans-serif;
    line-height: 1;
    content: '*' !important;
  }

  .ant-pagination-disabled:hover a, .ant-pagination-disabled a {
    color: rgba(0, 0, 0, .4);
    border-color: rgba(212, 210, 244, .31);
    cursor: not-allowed;
  }

  .ant-pagination-prev .ant-pagination-item-link > .anticon, .ant-pagination-next .ant-pagination-item-link > .anticon {
    display: block;
  }

  .ant-table-thead > tr > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
    content: none !important;
  }

  .loading-select {
    text-align: center;
    margin: auto;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .loading-select-option {
    min-height: 0 !important;
    padding: 0 !important;
  }

  .ant-carousel .slick-dots li button {
    background: ${({ theme }) => theme.palette.primary};
  }

  .ant-carousel .slick-dots li.slick-active button {
    background: ${({ theme }) => theme.palette.primary};
  }

  .ant-modal-content {
    border-radius: ${({ theme }) => theme.borderRadius.modal};
  }

  .ant-modal-header {
    border-radius: ${({ theme }) =>
      `${theme.borderRadius.modal} ${theme.borderRadius.modal} 0 0`};
  }

  .ant-form-item-explain, .ant-form-item-extra {
    margin: 0 !important;
  }

  .ant-checkbox-inner {
    border-radius: ${({ theme }) => theme.borderRadius.checkbox};
  }

  .ant-notification-notice-description {
    word-break: break-word;
  }

  .dropdown-status {
    .span-option-status {
      background: none !important;
      color: currentColor !important;
    }
  }

  .ant-empty-img-default {
    max-width: 100%;
  }

  .full-content-absolute {
    position: absolute;
    top: ${({ theme }) => `${theme.contentWrapper.paddingNumber}px`};
    left: ${({ theme }) => `${theme.contentWrapper.paddingNumber}px`};
    right: ${({ theme }) => `${theme.contentWrapper.paddingNumber}px`};
    bottom: ${({ theme }) => `${theme.contentWrapper.paddingNumber}px`};
  }

  .full-content {
    min-height: ${({ theme }) =>
      `calc(100vh - 64px - ${
        Number(theme.contentWrapper.paddingNumber) * 2
      }px)`};
  }

  .wrapper-relative {
    flex-grow: 1;
    position: relative;
  }

  .wrapper-absolute {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    overflow: hidden;
  }
  
  .ant-notification-notice {
    padding: 16px;
    border-radius: 12px;
    .ant-notification-notice-message {
      font-size: 14px;
      margin-left: 32px;
      color: ${({ theme }) => theme.text.blackNeutral800}
    }
    .ant-notification-notice-description {
      margin-left: 32px;
      color:  ${({ theme }) => theme.text.grayNeutral500}
    }
    .ant-notification-notice-icon {
      font-size: 20px;
      margin-left: 0;
    }
    .ant-notification-notice-close-x {
      font-size: 12px;
    }
  }
  .ant-notification-notice-success {
    border-left: 4px solid #3EBD5C;
    .ant-notification-notice-icon {
      color: ${({ theme }) => theme.text.successDefault};
    }
  }
  .ant-notification-notice-error {
    border-left: 4px solid #E22B30;
    .ant-notification-notice-icon {
      font-size: 24px;
      path {
        fill: ${({ theme }) => theme.text.errorDefault};
      }
    }
    .anticon.ant-notification-notice-icon-error {
      color: ${({ theme }) => theme.text.errorDefault}
    }
  }
  
  .notification-warning {
    border-left: 4px solid #F98437;
    border-radius: 12px;

    .ant-notification-notice-close {
      top: 18px;
    }
  }

  .btn-second-primary {
    background: ${({ theme }) => theme.color.blueSecondary};
    border: none;
    color: #fff;

    &:hover, &:focus {
      background: ${({ theme }) => `${theme.color.blueSecondary}90`};
      color: #fff;
    }
  }

  .btn-line-primary {
    border-color: ${({ theme }) => theme.palette.primary};
    color: ${({ theme }) => theme.palette.primary};
  }

  .btn-second-primary-fill {
    background: ${({ theme }) => `${theme.color.blueSecondary}20`};
    border-color: transparent;
    color: ${({ theme }) => theme.color.blueSecondary};

    &:hover, &:focus {
      border-color: ${({ theme }) => theme.palette.primary};
    }
  }

  .btn-primary-fill {
    background: ${({ theme }) => `${theme.palette.primary}20`};
    border-color: transparent;
    color: ${({ theme }) => theme.palette.primary};

    &:hover, &:focus {
      border-color: ${({ theme }) => theme.palette.primary};
    }
  }

  .btn-info-fill {
    background: ${({ theme }) => `${theme.color.info}20`};
    border-color: transparent;
    color: ${({ theme }) => theme.color.info};
  }

  .btn-transparent {
    background: transparent;
    border: none;

    &:hover, &:focus {
      background: transparent;
    }
  }

  .link-custom-primary {
    color: ${({ theme }) => theme.text.primary} !important;

    &:hover,
    &:focus {
      color: ${({ theme }) => theme.palette.primary} !important;
    }

    display: inline-block;
  }

  .text-secondary {
    color: ${({ theme }) => theme.text.mainL2};
  }

  .box-wrapper {
    border-radius: 8px;
    background: #fff;
    padding: 20px;
  }

  .title-table {
    font-weight: bold;
    font-size: 20px;
    line-height: 32px;
  }

  .success-modal {
    .ant-modal-body {
      padding: 0px;
    }

    .ant-card {
      border: none;
      width: 100% !important;
    }

    .ant-card-body {
      padding: 32px !important;
    }

    .ant-card-cover {
      background-color: #F9EAF7;
    }

    .ant-card-hoverable:hover {
      box-shadow: none;
    }

    .start-icon {
      margin-left: 8px;
      margin-right: 8px;
    }

    .ant-card-meta-title {
      font-style: normal;
      font-weight: 700;
      font-size: 24px;
      line-height: 32px;
      color: #2D1F21;
    }

    h3 {
      margin-top: 12px;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 32px;
      color: #2D1F21;
    }

    .btnOk {
      font-weight: bold;
      font-size: 16px;
    }

    .ant-card-bordered .ant-card-cover {
      margin-top: 0px;
      margin-right: 0px;
      margin-left: 0px;
      border-radius: 10px 10px 0 0;
    }
  }

  .select-checkbox {
    .ant-checkbox-wrapper {
      margin-right: 8px;

      &:not(.checkbox-select-all) {
        pointer-events: none;
      }
    }

    .ant-select-item-option-content {
      display: flex;
      align-items: center;
    }

    &.select-not-infinite {
      .ant-select-item-option-selected {
        flex-direction: row-reverse;
      }
    }

    .ant-input-affix-wrapper {
      margin: 8px;
      width: calc(100% - 16px);
    }

    .ant-input-clear-icon-hidden ~ .anticon {
      display: inline-block;
    }

    .ant-input-suffix {
      color: #98A2B3;
      font-size: 14px;
    }

    .rc-virtual-list-holder {
      &::-webkit-scrollbar {
        width: 6px;
      }

      &::-webkit-scrollbar-thumb {
        background: ${({ theme }) => theme.boardColors.N30};
        border-radius: 100px;
      }
    }
  }

  .ant-scrolling-effect {
    width: 100% !important;
  }
  .ant-modal-confirm-btns, .ant-modal-footer {
    .ant-btn-default{
      color: ${({ theme }) => theme.palette.primary} !important;
      border-color: ${({ theme }) => theme.palette.primary};
    }
  }

  .ant-modal-confirm-title {
    font-weight: 600 !important;
  }

  .ant-form-item-label > label {
    color: ${({ theme }) => theme.text.blackNeutral800};
    font-weight: 700;
    font-size: 16px;
  }

  .claim-centre-search-bar {
    .ant-select-item.ant-select-item-option {
      .ant-select-item-option-content {
        padding: 4px 8px;
      }
      @media only screen and (min-width: 2000px) {
        font-size: 20px;
      }
      @media only screen and (min-width: 2400px) {
        .ant-select-item-option-content {
          padding: 12px 8px;
        }
        font-size: 24px;
      }
      @media only screen and (min-width: 3400px) {
        .ant-select-item-option-content {
          padding: 12px 8px;
        }
        font-size: 28px;
      }
      @media only screen and (min-width: 3800px) {
        .ant-select-item-option-content {
          padding: 16px 12px;
        }
        font-size: 30px;
      }
    }
  }
  .upload-file-wrap {
    .ant-modal-confirm-btns .ant-btn {
      font-weight: bold;
      border-radius: 12px;
    }
  }
  .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
    background: #ffe6ef;
    &:hover {
      background: #f5f5f5;
    }
  }
  .program-name-service-form {
    .ant-select-item-empty {
      overflow: auto;
      max-height: 400px;
      padding: 0 !important;
    }
  }
  
  .program-delete-modal-wrapper {
    .ant-modal-confirm-title {
      font-size: 20px;
      font-weight: 600;
      line-height: 30px;
      display: block;
      margin-top: -4px;
      color: ${({ theme }) => theme.text.blackNeutral800};
    };
    
    .ant-modal-confirm-content {
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      color: ${({ theme }) => theme.text.gray700};
    }
    
    .ant-btn-default, .ant-btn-primary {
      font-size: 14px;
      font-weight: 700;
    }
  }
  .confirm-update-program {
    .ant-modal-confirm-body {
      .ant-modal-confirm-title {
        color: ${({ theme }) => theme.text.blackNeutral800};
        font-size: 20px;
      }
      .ant-modal-confirm-content {
        color: ${({ theme }) => theme.text.gray700};
        font-size: 16px;
      }
    }
    .ant-modal-confirm .ant-modal-confirm-btns .ant-btn + .ant-btn {
      font-weight: 700;
    } 
  }
`;

export default AppWrapper;

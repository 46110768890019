import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { getCentreId } from 'redux/auth/selectors';
import useCheckPermission from 'components/common/CheckPermissions/useCheckPermission';
import { RootState } from 'redux/store';
import { upgradeModal } from './UpgradeSubscriptionModal';

const CheckPermission = ({ ...props }) => {
  const {
    children,
    permissionKey,
    fallback,
    isDefaultVisibleModal,
    action,
    isDisabled,
    isSkipSubscription,
    isSkipUserRole,
    packageName,
    ...rest
  } = props;
  const centreId = useSelector(getCentreId);

  const isLoadedConfig = useSelector(
    (state: RootState) => state.config.isLoaded,
  );

  const { isCan, isCanSubscription } = useCheckPermission({
    permissionKey,
    action,
    isSkipSubscription,
    isSkipUserRole,
    packageName,
  });

  useEffect(() => {
    if (isLoadedConfig && !isCan && isDefaultVisibleModal) {
      upgradeModal.open(isCanSubscription);
    }
  }, [isLoadedConfig, centreId, isCan]); // eslint-disable-line

  const childrenWithProps = useCallback(
    (extraProp = undefined) =>
      React.Children.map(children, (child = {}) => {
        if (React.isValidElement(child)) {
          return React.cloneElement(child, {
            ...(child.props as Record<string, unknown>),
            ...rest,
            ...extraProp,
          });
        }

        return child;
      }),
    [children], // eslint-disable-line
  );

  if (isCan) return childrenWithProps();

  if (isCanSubscription && isDisabled) {
    return childrenWithProps({ disabled: true });
  }

  if (fallback) {
    return React.cloneElement(fallback, {
      ...fallback.props,
      ...rest,
    });
  }

  if (isDisabled) {
    return childrenWithProps({ disabled: true });
  }

  return null;
};

CheckPermission.propTypes = {
  permissionKey: PropTypes.string,
  action: PropTypes.string,
  fallback: PropTypes.any,
  children: PropTypes.node,
  isDisabled: PropTypes.bool,
  isSkipUserRole: PropTypes.bool,
  isSkipSubscription: PropTypes.bool,
  packageName: PropTypes.string,
  isDefaultVisibleModal: PropTypes.bool,
  menu: PropTypes.object,
  collapsed: PropTypes.bool,
};

export default CheckPermission;

import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { Checkbox, Form, Input, Skeleton } from 'antd';
import i18n from 'i18next';
import { useTranslation } from 'react-i18next';
import {
  login,
  registerClientWithToken,
  verifyRegisterToken,
} from 'redux/auth/actions';
import { validateRegex } from 'utils/validateUtils';
import { isEmpty } from 'lodash-es';
import {
  Description,
  FirstNameLastNameWrapper,
  LoginNowStyle,
  RegisterWrapper,
} from './style';
import FooterRegister from './FooterRegister';
import StepTitle from '../StepTitle';

const FormItem = Form.Item;

const ClaimCentreRegister = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const registerUser = useSelector((state) => state.auth.registerUser);
  const token = useSelector((state) => state.router?.location?.query?.token);
  const [isAgree, setIsAgree] = useState(false);

  const onFinish = async ({ confirm, ...values }) => {
    if (values.password === confirm && values.password.length >= 6) {
      try {
        await dispatch(registerClientWithToken({ ...values, token }));
        await dispatch(
          login({ email: values?.email, password: values?.password }),
        );
        history.push('/centres/board');
      } catch (error) {
        throw Error(error);
      }
    }
  };

  const compareToFirstPassword = (rule, value, callback) => {
    if (value && value !== form.getFieldValue('password')) {
      callback(i18n.t('input.confirmPassword.validateMsg.match'));
    } else {
      callback();
    }
  };

  useEffect(() => {
    if (token) {
      dispatch(verifyRegisterToken({ token })).then((response) => {
        if (response?.error) {
          history.push('/login');
        }
      });
    }
  }, [dispatch, token, history]);

  const onChangeAgreePolicy = (e) => {
    setIsAgree(e.target.checked);
  };

  const redirectToLogin = () => {
    history.push('/login');
  };

  if (isEmpty(registerUser)) return <Skeleton />;

  return (
    <RegisterWrapper>
      <StepTitle stepNumber={3} title="centresClaim.completeYourClaim" />
      <Description className="text-black-base">
        <div>{t('centresClaim.youAreAlmostDone')}</div>
        <div>{t('centresClaim.remember')}</div>
        <LoginNowStyle className="flex lex-row">
          <span>
            {t('centresClaim.ifYouAlreadyHaveAnExistingKindiCareAccount')},
          </span>
          <span
            onClick={redirectToLogin}
            aria-hidden="true"
            className="text-primary ml-6 cursor-pointer"
          >
            {t('centresClaim.loginInNow')}
          </span>
        </LoginNowStyle>
      </Description>
      <Form
        onFinish={onFinish}
        form={form}
        layout="vertical"
        initialValues={registerUser?.sender}
      >
        <FirstNameLastNameWrapper className="flex w-full">
          <Form.Item
            className="w-full pr-10"
            name="firstName"
            label={i18n.t('input.firstName.placeholder')}
            rules={[
              {
                required: true,
                message: i18n.t('input.firstName.validateMsg.required'),
              },
            ]}
          >
            <Input />
          </Form.Item>
          <FormItem
            className="w-full pl-10"
            name="lastName"
            label={i18n.t('input.lastName.placeholder')}
            rules={[
              {
                required: true,
                message: i18n.t('input.lastName.validateMsg.required'),
              },
            ]}
          >
            <Input />
          </FormItem>
        </FirstNameLastNameWrapper>

        <FormItem
          name="email"
          label={i18n.t('input.email.placeholder')}
          rules={[
            {
              required: true,
              message: i18n.t('input.email.validateMsg.required'),
            },
            {
              type: 'email',
              message: i18n.t('input.email.validateMsg.invalid'),
            },
          ]}
        >
          <Input />
        </FormItem>
        <FormItem
          name="password"
          label={i18n.t('input.password.placeholder')}
          rules={[
            {
              required: true,
              message: i18n.t('input.password.validateMsg.required'),
            },
            {
              pattern: validateRegex.password,
              message: i18n.t('input.password.validateMsg.pattern'),
            },
          ]}
        >
          <Input.Password type="password" />
        </FormItem>
        <FormItem
          hasFeedback
          name="confirm"
          label={i18n.t('input.confirmPassword.placeholder')}
          rules={[
            {
              required: true,
              message: i18n.t('input.confirmPassword.validateMsg.required'),
            },
            { validator: compareToFirstPassword },
          ]}
        >
          <Input.Password type="password" />
        </FormItem>
        <FormItem>
          <Checkbox checked={isAgree} onChange={onChangeAgreePolicy}>
            {i18n.t('centresClaim.acceptTheKindiCare')}
          </Checkbox>
        </FormItem>
        <FooterRegister isAgree={isAgree} />
      </Form>
    </RegisterWrapper>
  );
};

export default ClaimCentreRegister;

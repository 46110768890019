import React, { useCallback } from 'react';
import i18next from 'i18next';
import TableCustom from 'components/common/TableCustom';
import { useHistory, useLocation } from 'react-router';
import { getFilterFromUrl, getSearchUrl } from 'utils/tools';
import { useSelector } from 'react-redux';
import { getJobListingViewByJobRole } from 'redux/jobs/jobListings/selectors';
import {
  DEFAULT_CURRENT_PAGE,
  DEFAULT_SORT_ROLE,
  LIMIT_ITEM,
  DEFAULT_SORT_INDIVIDUAL,
} from 'containers/JobListings/constants';
import {
  SORT_ASCEND_VALUE,
  SORT_DESCEND_VALUE,
} from 'configs/localData/constant';
import PropTypes from 'prop-types';
import EditButton from 'components/RestActions/EditButton';
import { RoleListTableStyle } from './styles';
import useFormatFnc from '../../useFormat';

const TableView = ({ jobRoles, loading, pushQuery, retrieveList }) => {
  const useFormat = useFormatFnc();
  const { resourceData } = useSelector(getJobListingViewByJobRole) || {};
  const { search } = useLocation();
  const { filter } = getFilterFromUrl(search);
  const { push } = useHistory();

  const getSorterOrder = useCallback(
    (source) => {
      if (filter.sorts?.[0] === source) return SORT_DESCEND_VALUE;
      if (filter.sorts?.[0] === `-${source}`) return SORT_ASCEND_VALUE;
      return '';
    },
    [filter],
  );

  const columns = [
    {
      title: i18next.t('jobListings.jobRole'),
      dataIndex: 'name',
      sorter: true,
      render: (data, record) => useFormat.formatJobRoleName(data, record),
      defaultSortOrder: getSorterOrder('name'),
    },
    {
      title: `# ${i18next.t('jobListings.jobTemplates')}`,
      dataIndex: 'jobTemplates',
      sorter: true,
      render: (data) => <span>{data}</span>,
      defaultSortOrder: getSorterOrder('jobTemplates'),
    },
    {
      title: `# ${i18next.t('jobListings.jobListings')}`,
      dataIndex: 'jobListings',
      render: (data) => <span>{data}</span>,
      sorter: true,
      defaultSortOrder: getSorterOrder('jobListings'),
    },
    {
      title: `# ${i18next.t('jobListings.workplaces')}`,
      dataIndex: 'workplaces',
      sorter: true,
      render: (data) => <span>{data}</span>,
      defaultSortOrder: getSorterOrder('workplaces'),
    },
    {
      title: `# ${i18next.t('jobListings.applications')}`,
      dataIndex: 'applications',
      render: (data, record) => useFormat.formatApplicationAction(data, record),
      sorter: true,
      defaultSortOrder: getSorterOrder('applications'),
    },
    {
      title: '',
      dataIndex: 'id',
      fixed: 'right',
      width: 80,
      render: (data) => (
        <EditButton
          isView
          source="show"
          onClickCustom={() => {
            push(
              `/job-listings/individual/list?${getSearchUrl({
                page: DEFAULT_CURRENT_PAGE,
                sorts: [DEFAULT_SORT_INDIVIDUAL],
                filter: {
                  jobRoleId: data,
                },
              })}`,
            );
          }}
        />
      ),
    },
  ];

  const onChange = (e, filters, sorter) => {
    const formatSort =
      sorter && sorter.field && sorter.order
        ? `${sorter.order === SORT_DESCEND_VALUE ? '-' : ''}${sorter.field}`
        : null;

    const restFilter = {
      size: e.pageSize,
      page: e.current,
      sorts: [formatSort || DEFAULT_SORT_ROLE],
      filter: { ...filter.filter },
    };

    retrieveList({
      filter: restFilter,
      isRefresh: true,
      keepFilter: true,
    });
    pushQuery({
      'sorts[]': restFilter.sorts,
      ...restFilter,
    });
  };

  return (
    <RoleListTableStyle>
      <TableCustom
        onChange={onChange}
        columns={columns}
        data={jobRoles}
        loading={loading}
        pagination={{
          pageSize: filter?.size || LIMIT_ITEM,
          current: filter?.page || DEFAULT_CURRENT_PAGE,
          total: resourceData?.total,
        }}
      />
    </RoleListTableStyle>
  );
};

TableView.propTypes = {
  jobRoles: PropTypes.array,
  loading: PropTypes.bool,
  pushQuery: PropTypes.func,
  retrieveList: PropTypes.func,
};

export default TableView;

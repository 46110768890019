import React, { useMemo, useCallback } from 'react';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Legend,
  ReferenceLine,
  Label,
} from 'recharts';
import { formatDate, formatMoney, getBlurOpacity } from 'utils/tools';
import moment from 'moment';
import TooltipContent from './TooltipContent';
import CustomXAxisTick from './CustomXAxisTick';

export interface LineType {
  value: string;
  fillColor: string;
  label: string;
}

interface ReferenceLineType {
  year: number;
  dateInXAxis: string;
}

interface Data {
  date: string;
}

interface Props<DataType> {
  data?: DataType[];
  dataLines?: LineType[];
  selectedLines?: string[];
  width?: string | number;
  height?: string | number;
  heightXAxis?: number;
  isShowLegend?: boolean;
  LegendComponent?: React.ElementType;
  TooltipComponent?: React.ElementType;
  activeLine: string;
  setActiveLine: React.Dispatch<React.SetStateAction<string>>;
}

function MetricsLineChart<DataType extends Data>({
  data,
  dataLines,
  selectedLines,
  activeLine,
  setActiveLine,
  width = '100%',
  height = 400,
  heightXAxis = 50,
  LegendComponent,
  TooltipComponent = TooltipContent,
  isShowLegend = false,
}: Props<DataType>) {
  const onMouseLeaveLine = () => {
    activeLine && setActiveLine(null);
  };

  const getDate = useCallback((date: string) => {
    if (date?.includes('-')) {
      return date.split('-')[0];
    }
    return date;
  }, []);

  const referenceLines = useMemo(
    () =>
      data?.reduce<ReferenceLineType[]>((acc, curr) => {
        const date = getDate(curr.date);
        const year = moment(date).year();
        const isYearExisted =
          acc.findIndex((item) => item.year === year) !== -1;

        if (isYearExisted) {
          return acc;
        }
        return [...acc, { year, dateInXAxis: curr.date }];
      }, []),
    [data, getDate],
  );

  return (
    <div>
      <ResponsiveContainer width={width} height={height}>
        <LineChart
          data={data}
          margin={{
            top: 30,
            right: 30,
            left: 15,
            bottom: 5,
          }}
          onMouseLeave={onMouseLeaveLine}
        >
          <CartesianGrid
            vertical={false}
            strokeOpacity={1}
            strokeDasharray="3 1.5"
            stroke="#E9E9EB"
          />
          <XAxis
            dataKey="date"
            tickFormatter={(date) => formatDate(getDate(date), 'DD MMM')}
            height={heightXAxis}
            tickMargin={18}
            axisLine={false}
            tickLine={false}
            padding={{ left: 20 }}
            tick={CustomXAxisTick}
          />
          <YAxis
            tickFormatter={(data) => formatMoney(data)}
            axisLine={false}
            tickLine={false}
            tickMargin={6}
            type="number"
          />
          <Tooltip
            wrapperStyle={{ pointerEvents: 'auto' }}
            content={<TooltipComponent />}
          />
          {isShowLegend && (
            <Legend
              verticalAlign="bottom"
              wrapperStyle={{ marginTop: 42 }}
              align="center"
              layout="horizontal"
              content={<LegendComponent />}
            />
          )}

          {dataLines?.map((item) => (
            <Line
              type="linear"
              key={item.value}
              dataKey={item.value}
              stroke={item.fillColor}
              name={item.label}
              dot={false}
              activeDot={{
                r: 6,
                onMouseEnter: () => setActiveLine(item.value),
                onMouseLeave: onMouseLeaveLine,
              }}
              strokeWidth={2}
              opacity={getBlurOpacity(activeLine, item.value)}
              hide={!selectedLines.includes(item.value)}
              style={{ transition: 'opacity 0.3s ease-in-out' }}
            />
          ))}

          {referenceLines?.length > 1 &&
            referenceLines.map(({ dateInXAxis, year }) => (
              <ReferenceLine
                x={dateInXAxis}
                stroke="#E9E9EB"
                strokeDasharray="3 1.5"
              >
                <Label value={year} position="top" fill="#758098" offset={16} />
              </ReferenceLine>
            ))}
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
}

export default React.memo(MetricsLineChart);

import { Footer } from 'antd/lib/layout/layout';
import styled from 'styled-components';

export const FooterStyles = styled(Footer)`
  background: ${({ theme }) => theme.footer.background};
  .footer-mobile {
    display: none;
  }

  @media only screen and (max-width: 640px) {
    .footer-desktop {
      display: none;
    }

    .footer-mobile {
      display: block;
      height: 60px;
      position: fixed;
      left: 0px;
      right: 0px;
      bottom: 0px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      background: ${({ theme }) => theme.background.content};
      box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.15);
      transition: all 0.5s ease 0.2s;
      a {
        text-align: center;
        flex: 1;
      }
      .tabIcon {
        font-size: 25px;
      }
      .active .anticon {
        color: ${({ theme }) => theme.palette.primary};
      }
    }
  }
`;

export const FooterMenuStyles = styled.div`
  width: 100%;
  font-size: 16px;
  font-weight: 500;
  .ant-space-item {
    padding: 5px 0;
    color: ;
  }
  color: ${({ theme }) => theme.text.primary};
  a {
    color: ${({ theme }) => theme.text.gray700};
    &:hover {
      color: ${({ theme }) => theme.palette.primary};
    }
  }
  .footer-desktop__row {
    margin-bottom: -10px !important;
  }

  .footer-email {
    color: ${({ theme }) => theme.palette.primary};
    word-break: break-all;
  }
`;

export const PublicFooterStyles = styled.div`
  border-top: 1px solid #ebecf0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 50px;

  .public-footer {
    &__row {
      width: 100%;
    }
    &__col-right {
      display: flex;
      align-items: center;
    }
    &__col-left {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }
    &__logo {
      margin-bottom: 14px;
      img {
        height: 100%;
        object-fit: contain;
      }
    }
    &__social {
      display: flex;

      & > div {
        margin: 0 7px;
      }

      & > div:first-child {
        margin-left: 0;
      }

      .social-icon {
        width: 40px;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid #39a4fc;
        border-radius: 50%;
        .anticon {
          color: #39a4fc;
          font-size: 14px;
        }
      }
    }
  }

  @media (max-width: 1250px) {
    padding: 20px 30px;
  }

  @media (max-width: 991px) {
    .public-footer__col-left {
      align-items: center;
    }
  }

  @media (max-width: 425px) {
    padding: 10px 10px;
    .public-footer__col-left {
      align-items: center;
    }
  }
`;

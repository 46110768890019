import React, { useRef } from 'react';
import CardSection from 'components/common/CardSection';
import PropTypes from 'prop-types';
import ReadMore from 'components/common/ReadMore';
import CandidateResponsibilitiesModal from '../../Modals/CandidateResponsibilitiesModal';

const WhatYouWillBeDoing = ({ candidateResponsibilities, disabledAction }) => {
  const candidateResponsibilitiesModalRef = useRef();

  const openCandidateResponsibilitiesModal = () => {
    candidateResponsibilitiesModalRef?.current &&
      candidateResponsibilitiesModalRef.current.toggleModal();
  };

  return (
    <>
      <CardSection
        isEdit
        onClickButton={openCandidateResponsibilitiesModal}
        title="jobs.moreAboutTheJob.whatYouWillBeDoing"
        action="isEdit"
        disabled={disabledAction}
      >
        <ReadMore
          text={candidateResponsibilities}
          readMoreCharacterLimit={500}
          showLessButton
          isCheckInsertLink
        />
      </CardSection>
      <CandidateResponsibilitiesModal
        ref={candidateResponsibilitiesModalRef}
        candidateResponsibilities={candidateResponsibilities}
      />
    </>
  );
};

WhatYouWillBeDoing.propTypes = {
  candidateResponsibilities: PropTypes.string,
  disabledAction: PropTypes.bool,
};

export default WhatYouWillBeDoing;

import styled from 'styled-components';

export const JobStatusWrapper = styled.div`
  .status-container {
    display: flex;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    justify-content: center;
    border-radius: 6px;
    padding: 5px 12px;
    &__open {
      background: ${({ theme }) => theme.background.successSoft};
      color: ${({ theme }) => theme.text.successDefault};
    }
    &__closed {
      background: ${({ theme }) => theme.background.gray200};
      color: ${({ theme }) => theme.text.black};
    }
  }
`;

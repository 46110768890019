import React from 'react';
import i18next from 'i18next';
import { JOB_STATUS } from 'configs/localData/constant';
import PropTypes from 'prop-types';
import { JobStatusWrapper } from './styles';

const JobListingStatus = ({ status }) => {
  const getStatus = (status) => {
    switch (status) {
      case JOB_STATUS.open:
        return (
          <div className=" status-container status-container__open">
            {i18next.t('jobListings.jobItem.status.open')}
          </div>
        );

      case JOB_STATUS.closed:
        return (
          <div className="status-container status-container__closed">
            {i18next.t('jobListings.jobItem.status.closed')}
          </div>
        );

      default:
        return <div />;
    }
  };
  return <JobStatusWrapper>{getStatus(status)}</JobStatusWrapper>;
};

JobListingStatus.propTypes = {
  status: PropTypes.string,
};

export default JobListingStatus;

import React from 'react';
import i18next from 'i18next';
import PropTypes from 'prop-types';
import TitleTooltip from 'components/common/TitleTooltip';
import { Wrapper, TopContainer, BottomContainer } from './styles';

const JobRole = ({ job, handleRedirectDetail }) => {
  const {
    applications,
    name,
    workplaces,
    jobListings,
    jobTemplates,
    newApplications,
  } = job;

  return (
    <Wrapper onClick={handleRedirectDetail}>
      <TopContainer>
        <div className="flex justify-between w-full">
          <TitleTooltip title={name} rows={2} />
        </div>

        <div className="flex ">
          <div className="flex flex-direction-column items-center">
            <div className="count">{jobTemplates}</div>
            <div className="count">{jobListings}</div>
            <div className="count">{workplaces}</div>
          </div>
          <div>
            <p className="title-default">
              {i18next.t('jobListings.jobTemplates')}
            </p>

            <p className="title-default">
              {i18next.t('jobListings.jobListings')}
            </p>

            <p className="title-default">
              {i18next.t('jobListings.workplaces')}
            </p>
          </div>
        </div>
      </TopContainer>
      <BottomContainer>
        <div className="application-left">
          <div className="application-line-left" />
          <div className="application-center">
            <div className="application-title">
              {i18next.t('jobListings.jobListingDetails.totalApplications')}
            </div>
            <div className="count">{applications}</div>
          </div>
        </div>
        <div className="application-right">
          <div className="application-line-right" />
          <div className="application-center">
            <div className="application-title">
              {i18next.t('jobListings.jobListingDetails.newApplications')}
            </div>
            <div className="count">{newApplications}</div>
          </div>
        </div>
      </BottomContainer>
    </Wrapper>
  );
};

JobRole.propTypes = {
  job: PropTypes.shape({
    applications: PropTypes.number,
    newApplications: PropTypes.number,
    id: PropTypes.string,
    jobListings: PropTypes.string,
    jobTemplates: PropTypes.string,
    name: PropTypes.string,
    workplaces: PropTypes.string,
  }),
  handleRedirectDetail: PropTypes.func,
};

export default JobRole;

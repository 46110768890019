export const JOB_TEMPLATE_BANNER_VALUE = 'jobTemplateBanner';

export const JOB_EXISTING_TEMPLATE_VALUE = 'jobExistingTemplate';

export const JOB_TEMPLATE_STEPS = [
  {
    title: 'jobs.createJobTemplateStep.basicInformation',
  },
  {
    title: 'jobs.createJobTemplateStep.moreAboutTheJob',
  },
  {
    title: 'jobs.createJobTemplateStep.jobFeatures',
  },
  {
    title: 'jobs.createJobTemplateStep.certifications',
  },
  {
    title: 'jobs.createJobTemplateStep.jobRequirementsSkills',
  },
  {
    title: 'jobs.createJobTemplateStep.immunisationRequirements',
  },
  {
    title: 'jobs.createJobTemplateStep.jobApplicationSettings',
  },
];

export const CREATE_JOB_TEMPLATE_FINAL_STEP = 6;

export const CREATE_NEW_TEMPLATE = 'new';

export const CREATE_EXISTING_TEMPLATE = 'existing';

export const RANGE_VALUE = 'Range';

export const CASUAL_VALUE = 'Casual';

export const IS_PREFERRED = 'isPreferred';

export const IS_REQUIRED = 'isRequired';

export const NO_DISCOUNTS = 'No Discounts';

export const PREFIX_ID_KEY = 'id_';

export const NO_EXPERIENCE_REQUIRED_NAME = 'No experience required';

import React from 'react';
import PropTypes from 'prop-types';
import EditButton from 'components/RestActions/EditButton';
import { useHistory } from 'react-router';

const GroupActionApplication = ({ id }) => {
  const { push } = useHistory();

  const handleRedirect = () => {
    push(`/applications/${id}/show`);
  };
  return <EditButton isView onClickCustom={handleRedirect} />;
};

GroupActionApplication.propTypes = {
  id: PropTypes.string,
};

export default GroupActionApplication;

import React from 'react';
import PropTypes from 'prop-types';
import { Button, Col, Row } from 'antd';
import { useSelector } from 'react-redux';
import i18next from 'i18next';
import { debounce } from 'lodash-es';

import { validateRegex } from 'utils/validateUtils';
import { KEYS_GET_GEO_MAP_REQUIRED } from 'configs/localData/constant';

import RestInputItem from 'components/RestInput/RestInputItem';
import RestSelect from 'components/RestInput/RestSelect';
import SuburbInput from './SuburbInput';
import { AddressFullFormStyles } from './styles';

const AddressFullForm = ({
  initialValue,
  form,
  setGeoAddressObj,
  isAutoAddress,
  onClick,
  disabled = false,
  required,
  isWrongAddress,
}) => {
  const stateOfAustralia = useSelector(
    (state) => state.config.data?.stateOfAustralia,
  );

  const handleGetGeoWhenChangeAddress = debounce(() => {
    const values = form.getFieldsValue();

    if (KEYS_GET_GEO_MAP_REQUIRED.every((key) => values?.[key])) {
      setGeoAddressObj(values);
    }
  }, 1000);

  return (
    <AddressFullFormStyles>
      <Row gutter={16}>
        <Col md={12} xs={24}>
          <RestInputItem
            required={required}
            isValidatorEmpty
            source="street"
            header="input.streetAddress.name"
            placeholder="input.streetAddress.name"
            onChange={handleGetGeoWhenChangeAddress}
            disabled={disabled}
          />
        </Col>

        <Col md={12} xs={24}>
          <SuburbInput
            initialValue={initialValue}
            required={required}
            disabled={disabled}
            handleGetGeoWhenChangeAddress={handleGetGeoWhenChangeAddress}
          />
        </Col>

        <Col md={12} xs={24}>
          <RestInputItem
            required={required}
            source="postCode"
            header="centres.postCode"
            placeholder="centres.postCode"
            rules={[
              {
                pattern: validateRegex.postCode,
                message: i18next.t('input.postCode.validateMsg.invalid'),
              },
            ]}
            onChange={handleGetGeoWhenChangeAddress}
            disabled={disabled}
          />
        </Col>

        <Col md={12} xs={24}>
          <RestSelect
            required={required}
            source="state"
            header="input.state.name"
            placeholder="input.state.placeholder"
            resourceData={stateOfAustralia || []}
            onChange={handleGetGeoWhenChangeAddress}
            disabled={disabled}
          />
        </Col>
      </Row>
      {isWrongAddress && (
        <div
          className={`error-overlay ${
            isWrongAddress ? 'flex items-center fill-address' : 'd-none'
          }`}
        >
          <div className="error-text">
            {i18next.t('priceBenchmarking.wrongAddress')}
          </div>
        </div>
      )}

      {isAutoAddress && (
        <Button
          className="address-full-form-button"
          type="link"
          onClick={onClick}
          disabled={disabled}
        >
          {i18next.t('priceBenchmarking.tryAutoAddress')}
        </Button>
      )}
    </AddressFullFormStyles>
  );
};

AddressFullForm.propTypes = {
  form: PropTypes.object,
  setGeoAddressObj: PropTypes.func,
  isAutoAddress: PropTypes.bool,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  required: PropTypes.bool,
  initialValue: PropTypes.any,
  isWrongAddress: PropTypes.bool,
};

export default AddressFullForm;

import React from 'react';
import PropTypes from 'prop-types';
import { LegendLabelWrapper } from 'containers/CompanyBenchmarking/components/CompareRatingBarChart/LegendLabel/styles';
import {
  ALL_COMPANIES_COLOR,
  ALL_COMPANIES_DATA_KEY,
  LEGEND_LABEL_TYPE,
  MAX_LABEL_LENGTH,
  SELECTED_COMPANY_COLOR,
  SELECTED_COMPANY_DATA_KEY,
} from 'containers/CompanyBenchmarking/localData/constants';
import { Tooltip } from 'antd';
import { splitStringByLength } from 'containers/CompanyBenchmarking/utils/dataUtils';
import Content from 'containers/CompanyBenchmarking/components/CompareRatingBarChart/LegendLabel/LabelItem/Content';

const LegendLabel = ({
  payload,
  isEmptyCompany,
  userCompaniesRatingAvg,
  allCompaniesRatingAvg,
}) => {
  if (isEmptyCompany && payload?.length > 0) {
    payload.splice(1, 1);
  }

  const initItem = {
    isShowTooltip: true,
    type: LEGEND_LABEL_TYPE.SQUARE,
  };

  const transformedPayload = payload.map((item) => {
    let transformedItem = item;
    if (item.dataKey === ALL_COMPANIES_DATA_KEY) {
      transformedItem = {
        ...item,
        ...initItem,
      };
    }

    if (item.dataKey === SELECTED_COMPANY_DATA_KEY) {
      transformedItem = {
        ...item,
        ...initItem,
        rawValue: item.value,
        value: splitStringByLength(item.value, MAX_LABEL_LENGTH),
        tooltipTitle: item.value,
      };
    }
    return transformedItem;
  });

  const avgAllPayload = transformedPayload.map((item) => {
    const color =
      item.dataKey === ALL_COMPANIES_DATA_KEY
        ? ALL_COMPANIES_COLOR
        : SELECTED_COMPANY_COLOR;
    const value = splitStringByLength(
      `${item.value} Average`,
      MAX_LABEL_LENGTH,
    );
    const avgRating =
      item.dataKey === ALL_COMPANIES_DATA_KEY
        ? `: ${allCompaniesRatingAvg}`
        : `: ${userCompaniesRatingAvg}`;

    return {
      ...item,
      value,
      type: LEGEND_LABEL_TYPE.DASHED,
      isShowTooltip: item.dataKey !== ALL_COMPANIES_DATA_KEY,
      color,
      avgRating,
      tooltipTitle: `${item.rawValue} Average${avgRating}`,
    };
  });

  const legendLabels = [...transformedPayload, ...avgAllPayload];

  return (
    <LegendLabelWrapper>
      {legendLabels.map((item, index) => (
        <div key={Number(index)}>
          {item.isShowTooltip ? (
            <div className="legend-item">
              <Tooltip title={item.tooltipTitle}>
                <div>
                  <Content item={item} />
                </div>
              </Tooltip>
            </div>
          ) : (
            <div className="legend-item">
              <Content item={item} />
            </div>
          )}
        </div>
      ))}
    </LegendLabelWrapper>
  );
};

LegendLabel.propTypes = {
  payload: PropTypes.array,
  isEmptyCompany: PropTypes.bool,
  allCompaniesRatingAvg: PropTypes.string,
  userCompaniesRatingAvg: PropTypes.string,
};

export default LegendLabel;

import React from 'react';
import { Row, Col } from 'antd';
import {
  TotalEarningIcon,
  TotalTransactionIcon,
  TotalTransactionValueIcon,
  TotalTransactionYearIcon,
} from 'components/common/SVGIcon';
import SummaryCard from 'components/common/SummaryCardCustom';
import { useDispatch, useSelector } from 'react-redux';
import { getTransactionsSummary } from 'redux/transactions/actions';
import { getTransactionsSummarySelector } from 'redux/transactions/selectors';

const Summaries = () => {
  const dispatch = useDispatch();
  const summaries = useSelector(getTransactionsSummarySelector);
  const summariesMap = [
    {
      title: 'transactions.summaries.totalTransaction',
      count: summaries?.totalTransaction,
      IconComponent: TotalTransactionIcon,
    },
    {
      title: 'transactions.summaries.totalValueTransaction',
      count: summaries?.totalValueTransaction,
      IconComponent: TotalTransactionValueIcon,
      isPerAnnum: true,
    },
    {
      title: 'transactions.summaries.totalValueTransactionInMonth',
      count: summaries?.totalValueTransactionInMonth,
      IconComponent: TotalEarningIcon,
      isPerAnnum: true,
    },
    {
      title: 'transactions.summaries.totalValueTransactionInYear',
      count: summaries?.totalValueTransactionInYear,
      IconComponent: TotalTransactionYearIcon,
    },
  ];

  React.useEffect(() => {
    dispatch(getTransactionsSummary());
  }, [dispatch]);

  return (
    <Row gutter={[25, 25]} style={{ marginBottom: 24 }}>
      {summariesMap.map((item, index) => (
        <Col lg={6} md={6} xs={24} key={String(index)}>
          <SummaryCard {...item} />
        </Col>
      ))}
    </Row>
  );
};

export default Summaries;

import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import i18next from 'i18next';
import { InputNumber } from 'antd';
import RestInputItem from 'components/RestInput/RestInputItem';
import RestFormDateTimePicker from 'components/RestInput/RestDateTimePicker';
import { RestInputContext } from 'components/RestInput/RestInputContext';
import moment from 'moment';
import {
  DEFAULT_DEADLINE_DAYS_DURATION,
  MAX_DEADLINE_DAYS,
  MIN_DEADLINE_DAYS,
} from 'configs/localData/constant';
import { compact, flatten } from 'lodash-es';

const DeadlineDurationForm = ({
  prefixSource,
  daySource,
  startDateSource,
  endDateSource,
  ...props
}) => {
  const today = moment().startOf('day');
  const { form } = useContext(RestInputContext);

  const defaultValue = {
    days: DEFAULT_DEADLINE_DAYS_DURATION,
    fromDate: today,
    endDate: moment(today).add(DEFAULT_DEADLINE_DAYS_DURATION - 1, 'days'),
  };

  const onDaysChange = (value) => {
    form.setFields([
      {
        name: flatten(compact([prefixSource, endDateSource])),
        value: value ? moment(today).add(value - 1, 'days') : undefined,
      },
    ]);
  };

  const onEndDateChange = (value) => {
    const endDate = moment(value).endOf('day');
    const numberOfDays = endDate.diff(today, 'days') + 1;

    form.setFields([
      {
        name: flatten(compact([prefixSource, daySource])),
        value: value ? numberOfDays : null,
      },
    ]);
  };

  const disabledToDate = (current) => {
    if (!current) {
      return false;
    }

    return (
      current < today ||
      current > moment(today).add(MAX_DEADLINE_DAYS - 1, 'days')
    );
  };

  return (
    <div className="flex items-center">
      <RestInputItem
        source={daySource}
        ContentComponent={InputNumber}
        defaultValue={defaultValue.days}
        onChange={onDaysChange}
        className="deadline-day"
        validateFirst
        required
        rules={[
          {
            validator(_, value) {
              if (!Number.isInteger(value)) {
                return Promise.reject(
                  i18next.t('input.deadline.validateMsg.invalid'),
                );
              }
              if (value < MIN_DEADLINE_DAYS) {
                return Promise.reject(
                  i18next.t('input.deadline.validateMsg.min'),
                );
              }
              if (value > MAX_DEADLINE_DAYS) {
                return Promise.reject(
                  i18next.t('input.deadline.validateMsg.max'),
                );
              }
              return Promise.resolve();
            },
          },
        ]}
        {...props}
      />

      <span className="mr-24 text-16 fw-400">
        {i18next.t('jobListings.labels.days')}
      </span>

      <RestFormDateTimePicker
        source={startDateSource}
        initialValue={defaultValue.fromDate}
        header="jobListings.labels.from"
        disabled
      />

      <RestFormDateTimePicker
        source={endDateSource}
        initialValue={defaultValue.endDate}
        header="jobListings.labels.to"
        disabledDate={disabledToDate}
        onChange={onEndDateChange}
        required
      />
    </div>
  );
};

DeadlineDurationForm.propTypes = {
  daySource: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  startDateSource: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  endDateSource: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  prefixSource: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
};

export default DeadlineDurationForm;

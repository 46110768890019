import { DeleteOutlined } from '@ant-design/icons';
import { Button, Tooltip } from 'antd';
import I18n from 'i18next';
import React from 'react';

interface Props {
  handleDelete?: () => void;
  disabled?: boolean;
  iconCustom?: JSX.Element;
}

const DeleteButtonWrapper = ({
  handleDelete,
  disabled,
  iconCustom = <DeleteOutlined />,
}: Props) => (
  <Tooltip title={disabled ? '' : I18n.t('button.delete')}>
    <div className="delete-action-wrapper">
      <Button disabled={disabled} icon={iconCustom} onClick={handleDelete} />
    </div>
  </Tooltip>
);

export default DeleteButtonWrapper;

import React from 'react';
import PropTypes from 'prop-types';
import { Col, Image, Row, Space, Typography } from 'antd';
import i18next from 'i18next';

const JobFeatures = ({ features }) => (
  <div>
    <Typography.Text className="fw-bold text-20">
      {i18next.t('jobListings.jobFeatures')}
    </Typography.Text>
    <Row align="middle">
      {features?.map((item, index) => (
        <Col lg={8} sm={12} xs={24} key={String(index)} className="mt-16">
          <Space>
            <Image src={item?.iconUrl} width={24} preview={false} />
            <Typography.Text>{item?.name}</Typography.Text>
          </Space>
        </Col>
      ))}
    </Row>
  </div>
);

JobFeatures.propTypes = {
  features: PropTypes.array,
};

export default JobFeatures;

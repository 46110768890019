import React from 'react';
import PropTypes from 'prop-types';
import i18next from 'i18next';

import { downloadFileByURLWithAxios } from 'utils/fileUtils';

import { Button } from 'antd';
import CardHeader from './CardHeader';
import CardStyles from './styles';

const ResourceCardItem = ({ item }) => (
  <CardStyles
    title={<CardHeader year={item?.year} />}
    className="resource-card shadow-2 h-full"
    bordered={false}
    hoverable
  >
    {item?.value ? (
      <div className="card-item__link-award flex-center">
        <img alt="award" className="card-item__image-award" src={item?.value} />
      </div>
    ) : null}

    <Button
      onClick={() =>
        downloadFileByURLWithAxios({ name: item?.name, url: item?.value })
      }
      className="card-item__btn-download fw-bold text-16 mb-8"
    >
      {i18next.t('resources.digitalTileDownload')}
    </Button>
  </CardStyles>
);

ResourceCardItem.propTypes = {
  item: PropTypes.object,
};

export default ResourceCardItem;

import React from 'react';
import PropTypes from 'prop-types';
import { Divider } from 'antd';
import i18next from 'i18next';
import { formatBirthday } from 'utils/textUtils';
import { InformationCardWrapper } from './styles';

const ParentItemCard = ({ record, index }) => (
  <InformationCardWrapper>
    <div className="title-card-info">
      {i18next.t('applications.parentDetail', { index })}
    </div>
    <Divider />
    <div className="content-info">
      <div className="info-item">
        <span className="title-info-item">{i18next.t('users.name')}</span>
        <span className="value-info-item">
          {`${record.firstName || ''} ${record.lastName || ''}`}
        </span>
      </div>

      {record.dateOfBirth && (
        <div className="info-item">
          <span className="title-info-item">{i18next.t('users.DOB')}</span>
          <span className="value-info-item">
            {formatBirthday(record.dateOfBirth)}
          </span>
        </div>
      )}

      {record.centrelinkCRN && (
        <div className="info-item">
          <span className="title-info-item">
            {i18next.t('users.centreLinkCRN')}
          </span>
          <span className="value-info-item">{record.centrelinkCRN}</span>
        </div>
      )}

      {record.phoneNumber && (
        <div className="info-item">
          <span className="title-info-item">
            {i18next.t('users.phoneNumber')}
          </span>
          <span className="value-info-item">{record.phoneNumber}</span>
        </div>
      )}

      {record.email && (
        <div className="info-item">
          <span className="title-info-item">{i18next.t('users.email')}</span>
          <span className="value-info-item">{record.email}</span>
        </div>
      )}

      {record.address && (
        <div className="info-item">
          <span className="title-info-item">{i18next.t('users.address')}</span>
          <span className="value-info-item">{record.address}</span>
        </div>
      )}

      {record.suburb && (
        <div className="info-item">
          <span className="title-info-item">{i18next.t('users.suburb')}</span>
          <span className="value-info-item">{record.suburb}</span>
        </div>
      )}

      {record.postCode && (
        <div className="info-item">
          <span className="title-info-item">{i18next.t('users.postCode')}</span>
          <span className="value-info-item">{record.postCode}</span>
        </div>
      )}

      {record.state && (
        <div className="info-item">
          <span className="title-info-item">{i18next.t('users.state')}</span>
          <span className="value-info-item">{record.state}</span>
        </div>
      )}
    </div>
  </InformationCardWrapper>
);

ParentItemCard.propTypes = {
  record: PropTypes.object,
  index: PropTypes.number,
};

export default ParentItemCard;

import React from 'react';
import i18next from 'i18next';
import PropTypes from 'prop-types';
import { Modal } from 'antd';
import { BorderOutlined, CheckOutlined } from '@ant-design/icons';
import { useDispatch } from 'react-redux';
import { doneTask } from 'redux/applications/actions';

const ExtraAction = ({ isCompleted, taskId, handleCompleteTask }) => {
  const dispatch = useDispatch();

  const showConfirm = () => {
    Modal.confirm({
      title: i18next.t('task.confirm'),
      onOk: () => {
        taskId &&
          dispatch(doneTask(taskId)).then(() => {
            handleCompleteTask(taskId);
          });
      },
    });
  };

  return (
    <div className="task-item__extra-action">
      {isCompleted ? (
        <CheckOutlined className="task-item__icon-complete" />
      ) : (
        <BorderOutlined
          className="task-item__icon-square"
          onClick={showConfirm}
        />
      )}
    </div>
  );
};

ExtraAction.propTypes = {
  isCompleted: PropTypes.bool,
  taskId: PropTypes.string,
  handleCompleteTask: PropTypes.func,
};

export default ExtraAction;

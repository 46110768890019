import React from 'react';
import { Typography } from 'antd';
import { DotIconFill } from 'components/common/SVGIcon';
import EducationStatus from 'containers/Centres/components/CentreTabs/EducatorStaffProfilesTab/components/EducationStatus';
import { OTHER } from '../../../../constants';

const { Text } = Typography;

interface Props {
  name?: string;
  schoolName?: string;
  isCompleted?: boolean;
  fieldOfStudy?: string;
  otherFieldOfStudy?: string;
}

const EducationItem = ({
  name,
  schoolName,
  isCompleted,
  fieldOfStudy,
  otherFieldOfStudy,
}: Props) => {
  const isNotEmptyData = schoolName && fieldOfStudy;
  return (
    <div className="text-16">
      <Text className="fw-bold">{name}</Text>
      <div className="flex fw-400 my-6">
        <div>{fieldOfStudy === OTHER ? otherFieldOfStudy : fieldOfStudy}</div>
        {isNotEmptyData && <DotIconFill className="flex-center px-8" />}
        <Text className="text-16">{schoolName}</Text>
      </div>
      <EducationStatus isCompleted={isCompleted} />
    </div>
  );
};
export default EducationItem;

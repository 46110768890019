import React, { useEffect, useRef, useState } from 'react';
import {
  Col,
  Form,
  Row,
  Skeleton,
  Typography,
  FormInstance,
  Input,
} from 'antd';
import {
  LeftContainerStyle,
  RightContainerStyle,
} from 'containers/Advertisement/Create/Steps/AdCreative/styles';
import { StepTitle } from 'containers/Advertisement/Create/Steps/styles';
import i18next from 'i18next';
import LabelWithTooltip from 'components/form/Label/LabelWithTooltip';
import CallingButtonField from 'containers/Advertisement/Create/Steps/AdCreative/CallingButtonField';
import AdsMediaUpload from 'containers/Advertisement/Create/Steps/AdCreative/AdsMediaUpload';
import AdsThumbnailCard from 'containers/Advertisement/components/AdsThumbnailCard';
import AddNewCallingButtonModal from 'containers/Advertisement/Create/Steps/AdCreative/AddNewCallingButtonModal';
import RestInputItem from 'components/RestInput/RestInputItem';
import { useDispatch, useSelector } from 'react-redux';
import {
  getAdsFormConfigLoadingSelector,
  getCallingButtonListSelector,
} from 'redux/advertisement/selectors';
import { validateRegex } from 'utils/validateUtils';
import { AdsType, CallingButtonResult } from 'api/advertisement/type';
import { getCallingButtonList } from 'redux/advertisement/actions';
import {
  DESCRIPTION_LIMIT,
  HEADLINE_LIMIT,
} from 'containers/Advertisement/constants';
import { CustomLabelStyle } from 'components/form/Label/styles';

const { TextArea } = Input;

export interface AdCreativeInitialValues {
  headline: string;
  description: string;
  callingButton: string;
  destination: string;
}

interface AdCreativeFormProps {
  form: FormInstance;
  initialValues: AdCreativeInitialValues;
  initialImages: { type: string; url: string }[];
  currentAdType: AdsType;
  onNext?: () => void;
  disabledTitle?: boolean;
}

const AdCreativeForm: React.FC<AdCreativeFormProps> = ({
  form,
  initialValues,
  initialImages,
  currentAdType,
  onNext,
  disabledTitle,
}) => {
  const dispatch = useDispatch();
  const modalRef = useRef<ModalRef>();
  const loading = useSelector(getAdsFormConfigLoadingSelector);
  const callingButtonsList = useSelector(getCallingButtonListSelector);
  const [selectedCallingButton, setSelectedCallingButton] =
    useState<CallingButtonResult>();

  useEffect(() => {
    dispatch(getCallingButtonList());
  }, [dispatch]);

  useEffect(() => {
    if (callingButtonsList.results && !selectedCallingButton) {
      if (initialValues.callingButton) {
        setSelectedCallingButton(
          callingButtonsList.results.find(
            (v) => v.id === initialValues.callingButton,
          ),
        );
      } else {
        setSelectedCallingButton(callingButtonsList.results[0]);
      }
    }
  }, [initialValues, callingButtonsList.results, selectedCallingButton]);

  const getDestinationRule = () => {
    if (selectedCallingButton?.destinationType) {
      switch (selectedCallingButton?.destinationType) {
        case 'Email': {
          return [
            {
              type: 'email',
              message: i18next.t('input.email.validateMsg.invalid'),
            },
          ];
        }
        case 'Phone Number': {
          return [
            {
              pattern: validateRegex.phone,
              message: i18next.t('error.phone'),
            },
            {
              max: 20,
              message: i18next.t('input.phoneNumber.validateMsg.maxLength', {
                max: 20,
              }),
            },
            {
              min: 6,
              message: i18next.t('input.phoneNumber.validateMsg.minLength', {
                min: 6,
              }),
            },
          ];
        }
        case 'Link': {
          return [
            {
              pattern: validateRegex.website,
              message: i18next.t('input.website.validateMsg.invalid'),
            },
          ];
        }
        default:
          return [];
      }
    }
  };

  return (
    <div>
      <Skeleton loading={loading} active>
        <Form
          scrollToFirstError
          layout="vertical"
          form={form}
          initialValues={initialValues}
          onFinish={onNext}
        >
          <Row gutter={[8, 8]}>
            <Col xl={14} md={12} xs={24}>
              <LeftContainerStyle>
                {!disabledTitle && (
                  <StepTitle>
                    {i18next.t('advertisement.steps.step2')}
                  </StepTitle>
                )}

                <LabelWithTooltip
                  label={i18next.t('advertisement.headline')}
                  tooltip={i18next.t('advertisement.tooltipMessage.maximum', {
                    max: HEADLINE_LIMIT,
                  })}
                />
                <RestInputItem
                  required
                  source="headline"
                  placeholder="advertisement.input.headline"
                  rules={[
                    {
                      max: HEADLINE_LIMIT,
                      message: i18next.t(
                        'advertisement.tooltipMessage.maximum',
                        {
                          max: HEADLINE_LIMIT,
                        },
                      ),
                    },
                  ]}
                />

                <LabelWithTooltip
                  label={i18next.t('advertisement.description')}
                  tooltip={i18next.t('advertisement.tooltipMessage.maximum', {
                    max: DESCRIPTION_LIMIT,
                  })}
                />
                <RestInputItem
                  required
                  source="description"
                  placeholder="advertisement.input.description"
                  ContentComponent={TextArea}
                  autoSize={{ minRows: 3 }}
                  rules={[
                    {
                      max: DESCRIPTION_LIMIT,
                      message: i18next.t(
                        'advertisement.tooltipMessage.maximum',
                        {
                          max: DESCRIPTION_LIMIT,
                        },
                      ),
                    },
                  ]}
                />
                <CallingButtonField
                  form={form}
                  selectedCallingButton={selectedCallingButton}
                  setSelectedCallingButton={(item) => {
                    setSelectedCallingButton(item);
                    form.setFieldsValue({ destination: '' });
                  }}
                />

                <div className={'mb-20'}>
                  <Typography.Text
                    className="text-primary text-16 fw-bold cursor-pointer"
                    onClick={() =>
                      modalRef.current && modalRef.current.toggleModal()
                    }
                  >
                    {i18next.t('advertisement.button.addNewButton')}
                  </Typography.Text>
                </div>

                <div>
                  <CustomLabelStyle className={'text-16 fw-bold'}>
                    {i18next.t('advertisement.destination')}
                  </CustomLabelStyle>
                  <p className={'text-14 mb-8'}>
                    {selectedCallingButton?.destinationType}
                  </p>
                </div>
                <RestInputItem
                  required
                  source="destination"
                  placeholder={`${i18next.t(
                    'advertisement.input.placeholder',
                  )} ${selectedCallingButton?.destinationType.toLowerCase()}`}
                  isDisableLabel
                  rules={getDestinationRule()}
                />

                <div className={'mb-8'}>
                  <CustomLabelStyle className={'text-16 fw-bold'}>
                    {i18next.t('text.uploadMedia')}
                  </CustomLabelStyle>
                  {currentAdType.id === '3' && (
                    <p className={'text-main-l3 text-14'}>
                      {i18next.t('text.uploadAndDrag')}
                    </p>
                  )}
                </div>

                <AdsMediaUpload
                  form={form}
                  initialImages={initialImages}
                  adType={currentAdType}
                />
              </LeftContainerStyle>
            </Col>
            <Col xl={10} md={12} xs={24}>
              <RightContainerStyle>
                <h1 className={'text-24 fw-700 text-primary'}>
                  {i18next.t('advertisement.adPreview')}
                </h1>
                {Array.from({ length: 2 }).map((value, index) => (
                  <div className={index === 1 ? 'pt-32' : ''} key={index}>
                    <Form.Item shouldUpdate>
                      {({ getFieldValue }) => (
                        <AdsThumbnailCard
                          maxImagesNumber={currentAdType.maxImagesNumber}
                          maxVideosNumber={currentAdType.maxVideosNumber}
                          key={index}
                          isForJobSeeker={index === 1}
                          title={getFieldValue('headline')}
                          description={getFieldValue('description')}
                          callingButton={selectedCallingButton?.name}
                          mediaItems={getFieldValue('mediaList')}
                        />
                      )}
                    </Form.Item>
                  </div>
                ))}
              </RightContainerStyle>
            </Col>
          </Row>
        </Form>
      </Skeleton>
      <AddNewCallingButtonModal
        ref={modalRef}
        destinationType={callingButtonsList.callingButtonTypes ?? []}
      />
    </div>
  );
};

export default AdCreativeForm;

import { get, post, put } from './utils';

export async function readMessageApi(id) {
  return put(`/admin/inboxes/${id}/read`);
}

export async function postMessageApi(id, data) {
  return post(`/admin/inboxes/${id}/messages`, data);
}

export async function getMessagesApi(id, data) {
  return get(`/admin/inboxes/${id}/messages`, data);
}

export async function seenMessagesApi(id, data) {
  return put(`/admin/inboxes/${id}/read`, data);
}

export async function getTotalUnreadEnquiresApi(params) {
  return get('/admin/inboxes/total-unread-enquiries', params);
}

export async function archiveEnquiryApi(id, data) {
  return put(`/admin/inboxes/${id}/archive`, data);
}

export async function getSummariesEnquiryApi(data) {
  return get(`/admin/inboxes/summaries`, data);
}

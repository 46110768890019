import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { DatePicker, TimePicker } from 'antd';
import i18next from 'i18next';
import FormItemUI from 'components/form/FormItem';
import { DateTimePickerWrapper } from './styles';

const FormDatePicker = ({
  isShowTime,
  formatDate,
  allowClear,
  showTime,
  placeholder,
  ...props
}) => {
  const {
    required,
    defaultValue,
    initialValue,
    formOptions,
    source,
    form,
    disabled,
  } = props;
  const values =
    (defaultValue || initialValue) && !form?.getFieldValue(source)
      ? moment(defaultValue || initialValue)
      : form?.getFieldValue(source);

  const getValueFromEvent = (value) => {
    const e = value?.toISOString();
    props.formOptions &&
      props.formOptions.getValueFromEvent &&
      props.formOptions.getValueFromEvent(e);
    return e;
  };
  return (
    <DateTimePickerWrapper>
      <FormItemUI
        {...props}
        formOptions={{
          getValueFromEvent,
          normalize: (value) => value && moment(value),
        }}
        ruleType="object"
        defaultValue={
          defaultValue || initialValue
            ? moment(defaultValue || initialValue)
            : undefined
        }
        className="title"
        required={required}
        name={source}
      >
        <DatePicker
          showTime={showTime}
          allowClear={allowClear}
          disabled={disabled}
          format={formatDate}
          className="viewDatePicker"
          placeholder={i18next.t(placeholder)}
        />
      </FormItemUI>
      {isShowTime && (
        <TimePicker
          disabled={disabled}
          onChange={(newDate) => {
            form.setFieldsValue({
              [source]: newDate,
            });
            formOptions.getValueFromEvent &&
              formOptions.getValueFromEvent(newDate?.toISOString());
          }}
          style={{ marginBottom: 10 }}
          value={values}
          format="HH:mm"
          allowClear={false}
          className="viewTimePicker"
        />
      )}
    </DateTimePickerWrapper>
  );
};

FormDatePicker.propTypes = {
  source: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  header: PropTypes.any,
  required: PropTypes.bool,
  requiredMessage: PropTypes.node,
  icon: PropTypes.string,
  form: PropTypes.object,
  defaultValue: PropTypes.any,
  initialValue: PropTypes.object,
  formOptions: PropTypes.object,
  disabled: PropTypes.bool,
  isShowTime: PropTypes.bool,
  formatDate: PropTypes.string,
  allowClear: PropTypes.bool,
  showTime: PropTypes.bool,
  placeholder: PropTypes.string,
};

FormDatePicker.defaultProps = {
  isShowTime: false,
  formOptions: {},
  formatDate: 'DD MMM YYYY',
  allowClear: true,
};

export default FormDatePicker;

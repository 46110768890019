import React from 'react';
import i18next from 'i18next';
import { Button, Modal } from 'antd';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { delDocument } from 'redux/applications/actions';

const { confirm } = Modal;

const DeleteButton = ({ id }) => {
  const dispatch = useDispatch();

  const handleDelete = () => {
    id && dispatch(delDocument({ id }));
  };

  const showConfirm = () => {
    confirm({
      title: i18next.t('documents.titleConfirmDelete'),
      content: i18next.t('documents.contentConfirmDelete'),
      onOk() {
        handleDelete();
      },
    });
  };

  return <Button onClick={showConfirm}>{i18next.t('button.delete')}</Button>;
};

DeleteButton.propTypes = {
  id: PropTypes.string,
};

export default DeleteButton;

import React from 'react';
import PropTypes from 'prop-types';
import { CustomTooltipWrapper } from './styles';

const CustomTooltip = ({
  payload,
  isEmptyCompany,
  allDataKey,
  userDataKey,
}) => {
  const ratingPoint = payload?.[0]?.payload?.rating;
  if (isEmptyCompany && payload?.length > 0) {
    payload.splice(1, 1);
  }

  const getTooltipValue = (total, percent) =>
    total == null ? `${percent}%` : `${total} (${percent}%)`;

  return (
    <CustomTooltipWrapper>
      <div className="tooltip-header">{`Rating ${ratingPoint}`}</div>
      <div className="content-tooltip">
        {payload?.map((item, i) => (
          <div className="value-item" key={String(i)}>
            <div
              className="square-tooltip"
              style={{
                backgroundColor: item?.payload?.fill || item?.color,
              }}
            />
            <div className="text-value">
              <span>{item.name}</span>
            </div>
            <strong className="strong-value whitespace-nowrap">
              {item?.dataKey === allDataKey
                ? getTooltipValue(
                    item?.payload?.allTotal,
                    item?.payload?.[allDataKey],
                  )
                : getTooltipValue(
                    item?.payload?.userTotal,
                    item?.payload?.[userDataKey],
                  )}
            </strong>
          </div>
        ))}
      </div>
    </CustomTooltipWrapper>
  );
};

CustomTooltip.propTypes = {
  payload: PropTypes.array,
  isEmptyCompany: PropTypes.bool,
  allDataKey: PropTypes.string,
  userDataKey: PropTypes.string,
};

export default CustomTooltip;

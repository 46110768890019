import theme from 'configs/theme';
import React from 'react';
import { Text } from 'recharts';

const CustomXAxisTick = ({ x, y, payload, tickFormatter }) => {
  if (payload && payload.value) {
    return (
      <Text
        fontSize={12}
        width={12}
        lineHeight={16}
        x={x}
        y={y}
        fill={theme.text.grayNeutral500}
        textAnchor="middle"
        verticalAnchor="start"
      >
        {tickFormatter(payload.value)}
      </Text>
    );
  }
  return null;
};

export default CustomXAxisTick;

import styled, { css } from 'styled-components';
import { ComparePercentageProps } from './ComparePercentage';

interface CardStylesProps {
  isSelected: boolean;
  color: string;
}

export const SummaryMetricCardContainer = styled.div<CardStylesProps>`
  cursor: pointer;
  position: relative;
  display: flex;
  padding: 16px;
  background: #ffffff;
  border-radius: 8px;
  min-height: 84px;
  min-width: 268px;
  border-top: 4px solid transparent;
  transition: box-shadow 0.2s, border 0.1s;
  z-index: 1;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 8px;
    border: 1px solid #ebecf0;
    z-index: -1;
    transition: box-shadow 0.2s;
  }

  &:hover {
    ::after {
      box-shadow: 0px 0px 1px rgba(12, 26, 75, 0.1),
        0px 4px 20px -2px rgba(50, 50, 71, 0.08);
    }
  }

  ${({ isSelected, color }) =>
    isSelected &&
    css`
      border-top: 4px solid ${color};
      box-shadow: 0px 0px 1px rgba(12, 26, 75, 0.1),
        0px 4px 20px -2px rgba(50, 50, 71, 0.08);

      ::after,
      :hover::after {
        box-shadow: none;
        border-radius: 0;
        border: none;
      }
    `}
`;

export const ComparePercentageStyles = styled.div<
  Pick<ComparePercentageProps, 'hasDashBorder' | 'color'>
>`
  display: inline-flex;
  align-items: center;
  color: ${({ color }) => color || '#38465C'};
  border-bottom: ${({ hasDashBorder, color }) =>
    hasDashBorder ? `1px dashed ${color}` : 'none'};
  font-size: 14px;
  line-height: 24px;
`;

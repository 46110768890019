import React from 'react';
import PropType from 'prop-types';
import { Col, Divider, Row, Tooltip, Typography } from 'antd';
import i18next from 'i18next';
import { CampaignCardStyles } from './styles';

const CampaignCard = ({ campaign, handleRedirectDetail }) => (
  <CampaignCardStyles className="flex flex-col" onClick={handleRedirectDetail}>
    <Tooltip title={campaign?.name}>
      <Typography.Title level={4} className="text-16 m-0 ellipsis-t-2">
        {campaign?.name}
      </Typography.Title>
    </Tooltip>

    <Row gutter={[12, 12]}>
      <Col className="flex gap-8" span={12}>
        <div className="rounded-4 tag">{campaign?.jobRoles}</div>
        <Typography.Text>{i18next.t('jobListings.jobRoles')}</Typography.Text>
      </Col>
      <Col className="flex gap-8" span={12}>
        <div className="rounded-4 tag">{campaign?.jobListings}</div>
        <Typography.Text>
          {i18next.t('jobListings.jobListings')}
        </Typography.Text>
      </Col>
      <Col className="flex gap-8" span={12}>
        <div className="rounded-4 tag">{campaign?.jobTemplates}</div>
        <Typography.Text>
          {i18next.t('jobListings.jobTemplates')}
        </Typography.Text>
      </Col>
      <Col className="flex gap-8" span={12}>
        <div className="rounded-4 tag">{campaign?.workplaces}</div>
        <Typography.Text>{i18next.t('jobListings.workplaces')}</Typography.Text>
      </Col>
    </Row>

    <Divider className="my-4" />

    <Row gutter={20}>
      <Col span={12} className="statistic">
        <div className="decor-line decor-line--green" />
        <div className="flex flex-col gap-10">
          <Typography.Text className="text-12 fw-bold text-gray-500">
            {i18next.t('jobListings.jobListingDetails.totalApplications')}
          </Typography.Text>
          <Typography.Text className="text-20 fw-bold text-neutral-800">
            {campaign?.applications}
          </Typography.Text>
        </div>
      </Col>
      <Col span={12} className="statistic">
        <div className="decor-line decor-line--pink" />
        <div className="flex flex-col gap-10">
          <Typography.Text className="text-12 fw-bold text-gray-500">
            {i18next.t('jobListings.jobListingDetails.newApplications')}
          </Typography.Text>
          <Typography.Text className="text-20 fw-bold text-neutral-800">
            {campaign?.newApplications}
          </Typography.Text>
        </div>
      </Col>
    </Row>

    <Typography.Text className="text-gray-500">
      {i18next.t('jobs.jobTemplates.lastUpdatedTime', {
        time: campaign?.latestUpdatedAt,
      })}
    </Typography.Text>
  </CampaignCardStyles>
);

export default CampaignCard;

CampaignCard.propTypes = {
  campaign: PropType.object,
  handleRedirectDetail: PropType.func,
};

import React from 'react';
import Col from 'antd/lib/col';
import i18next from 'i18next';
import { PREFIX_URL } from 'configs/localData/constant';
import Row from 'antd/lib/row';
import { useSelector } from 'react-redux';
import { getCentreId, getCentresOfUser } from 'redux/auth/selectors';
import { getStateOfAustralia } from 'redux/config/selectors';
import RestSelect from 'components/RestInput/RestSelect';
import { CHANNEL_OPTIONS } from 'configs/localData';
import ReferenceInput from 'containers/rest/ReferenceInput';
import RestRangePickerInput from 'components/RestInput/RestRangePickerInput';
import moment from 'moment';
import RangePickerCompare from '../RangePickerCompare';

const MarketingMetricsFilter: React.FC = () => {
  const centreId = useSelector(getCentreId);
  const stateOfAustralia = useSelector(getStateOfAustralia);
  const centresData = useSelector(getCentresOfUser);
  const today = moment().startOf('day');
  const startDateMarketingReport = '2021/10/31';
  const disabledRangeTime = (current) => {
    if (current > moment(today) || current < moment(startDateMarketingReport)) {
      return true;
    }
    return false;
  };

  return (
    <Row gutter={16} className="row-filter">
      <Col xl={3} md={12} xs={12}>
        <RestSelect
          source="channel"
          placeholder={i18next.t('marketingMetrics.channel')}
          valueProp="value"
          titleProp="label"
          resourceData={Object.values(CHANNEL_OPTIONS) || []}
          isShowTooltip
          autoComplete="off"
        />
      </Col>
      <Col xl={4} md={12} xs={12}>
        <RestSelect
          source="centreId"
          placeholder={i18next.t('centres.centreName')}
          valueProp="id"
          titleProp="name"
          resourceData={centresData || []}
          isShowTooltip
          autoComplete="off"
        />
      </Col>
      <Col xl={4} md={12} xs={12}>
        <ReferenceInput
          prefixUrl={PREFIX_URL.admin}
          resource="users/me/providers/basic-info"
          source="providerId"
          searchKey="name"
          notLikeFilter
          initialFilter={{ orderBy: 'name' }}
          autoComplete="off"
        >
          <RestSelect
            titleProp="name"
            valueProp="id"
            placeholder="input.provider.name"
            isFilterOption={false}
            isShowTooltip
          />
        </ReferenceInput>
      </Col>
      <Col xl={4} md={12} xs={12}>
        <ReferenceInput
          prefixUrl={PREFIX_URL.admin}
          source="brandId"
          resource="brands"
          searchKey="brand.name"
          dependency={centreId}
          initialFilter={{ orderBy: 'name' }}
          autoComplete="off"
        >
          <RestSelect
            titleProp="name"
            valueProp="id"
            placeholder="centres.brandTitle"
            isFilterOption={false}
            isShowTooltip
          />
        </ReferenceInput>
      </Col>
      <Col xl={3} md={12} xs={12}>
        <RestSelect
          source="state"
          placeholder="centres.state"
          resourceData={stateOfAustralia || []}
          isShowTooltip
          autoComplete="off"
        />
      </Col>
      <Col xl={6} md={12} xs={12}>
        <RangePickerCompare
          source="rangeDate"
          isShowTooltip
          disabledDate={disabledRangeTime}
        />
      </Col>
      <RestRangePickerInput
        className="d-none"
        source="compareDate"
        sourceGt="rangeDate.from"
        sourceLt="rangeDate.to"
        isShowTooltip
        placeholder={undefined}
      />
    </Row>
  );
};

export default MarketingMetricsFilter;

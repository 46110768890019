import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import i18next from 'i18next';

import CardSection from 'components/common/CardSection';
import { getUrlIfyMessage } from 'utils/dataUtils';
import { CentreDescriptionStyles } from './styles';
import DescriptionsModal from '../../Modals/DescriptionModal';

const CentreDescription = ({ shortDescription }) => {
  const [visibleModal, setVisibleModal] = useState(false);

  const onClickButton = useCallback(() => {
    setVisibleModal(true);
  }, []);

  const onCancel = useCallback(() => {
    setVisibleModal(false);
  }, []);

  return (
    <>
      <CardSection
        isEdit
        onClickButton={onClickButton}
        title="centreDetail.centreDescription"
        permissionKey="myCentres"
        action="isEdit"
      >
        <CentreDescriptionStyles
          dangerouslySetInnerHTML={{
            __html:
              getUrlIfyMessage(shortDescription) ||
              i18next.t('error.waitingUpdate'),
          }}
        />
      </CardSection>
      <DescriptionsModal
        visible={visibleModal}
        onCancel={onCancel}
        record={{ shortDescription }}
      />
    </>
  );
};

CentreDescription.propTypes = {
  shortDescription: PropTypes.string,
};

export default CentreDescription;

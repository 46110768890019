import React, { useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import CompareRatingBarChart from 'components/charts/CompareRatingBarChart';
import i18next from 'i18next';
import { compact } from 'lodash-es';
import { useSelector } from 'react-redux';
import { getBrandBenchmarkingCompareRatingSelector } from 'redux/brandBenchmarking/selectors';

const BrandCompareRatingChart = ({
  selectedCompanyName,
  selectedState,
  selectedBrandName,
}) => {
  const chartRef = useRef();
  const compareRatingData = useSelector(
    getBrandBenchmarkingCompareRatingSelector,
  );

  const handleScroll = useCallback(() => {
    chartRef.current.handleImperativeScroll();
  }, []);

  const chartTitle = i18next.t('brandBenchmarking.compareChart.title', {
    selectedCompanyName,
    selectedBrandName,
    selectedState,
  });

  const chartDefaultTitle = i18next.t(
    'brandBenchmarking.compareChart.defaultTitle',
    {
      selectedState,
    },
  );

  const objectCompareBarName = compact([
    selectedCompanyName,
    selectedBrandName,
  ]).join(' - ');

  return (
    <CompareRatingBarChart
      ref={chartRef}
      handleScroll={handleScroll}
      allLabel={i18next.t('brandBenchmarking.allBrands')}
      objectCompareBarName={objectCompareBarName}
      data={compareRatingData}
      chartTitle={chartTitle}
      chartDefaultTitle={chartDefaultTitle}
      allDataKey="allBrandsProportion"
      userDataKey="userBrandsProportion"
    />
  );
};

BrandCompareRatingChart.propTypes = {
  selectedCompanyName: PropTypes.string,
  selectedBrandName: PropTypes.string,
  selectedState: PropTypes.string,
};

export default BrandCompareRatingChart;

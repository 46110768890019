import React from 'react';
import { StepFooter } from 'components/RestLayout/StepsCreateLayout/styles';
import { Button } from 'antd';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

export interface FooterProps {
  isAgree: boolean;
  loading?: boolean;
}

export const ButtonStyle = styled(Button)`
  --font-size: 16px;
  font-size: var(--font-size);

  @media only screen and (min-width: 2000px) {
    --font-size: 20px;
    padding: 28px 30px;
    display: flex;
    align-items: center;
  }

  @media only screen and (min-width: 2400px) {
    --font-size: 25px;
    padding: 34px;
    border-radius: 15px;
  }

  @media only screen and (min-width: 3000px) {
    --font-size: 30px;
    padding: 40px;
    border-radius: 20px;
  }

  @media only screen and (min-width: 3400px) {
    --font-size: calc(22px + 0.390625vw);
    padding: 45px;
  }
`;

const FooterRegister = ({ isAgree, loading }: FooterProps) => {
  const { t } = useTranslation();
  return (
    <StepFooter className="flex-center">
      <ButtonStyle
        type="primary"
        disabled={!isAgree}
        htmlType="submit"
        loading={loading}
      >
        {t('register.completeYourClaim')}
      </ButtonStyle>
    </StepFooter>
  );
};

export default FooterRegister;

import styled from 'styled-components';

const MarketingTabStyles = styled.div`
  .row-centre-info-tab {
    display: flex;
    align-items: stretch;
    flex-wrap: wrap;
  }
  .row-right-centre-info {
    margin-bottom: -12px !important;
  }
`;

export default MarketingTabStyles;

export const MarketingTableWrapper = styled.div`
  padding: 15px;
  background: white;
  border-radius: 8px;
  height: 100%;

  .title {
    font-weight: bold;
    font-size: 20px;
    line-height: 25px;
    margin-bottom: 20px;
  }
`;

export const ResultSignUpStyles = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  .ant-result {
    max-width: 800px;
    .ant-result-subtitle {
      margin-top: 15px;
      font-size: 16px;
    }
  }
`;

import React from 'react';
import { CrownFilled, UserOutlined } from '@ant-design/icons';
import { Avatar } from 'antd';

import { upgradeModal } from 'components/common/CheckPermissions/UpgradeSubscriptionModal';

const ContactUserFallback = ({ currentCentreId }) => {
  const onClickAvatar = () => {
    upgradeModal.open(null, currentCentreId);
  };
  return (
    <div onClick={onClickAvatar} role="presentation">
      <CrownFilled className="absolute text-warning z-10 top-4" />
      <Avatar size={45} alt="avatar" icon={<UserOutlined />} />
    </div>
  );
};

export default ContactUserFallback;

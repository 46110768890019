import React from 'react';

import {
  TotalOpenApplicationIcon,
  TotalWaitlistIcon,
  TotalWaitlistValueIcon,
  TotalEarningIcon,
} from 'components/common/SVGIcon';
import SummaryCardDetail from 'components/common/SummaryCardDetail';
import { useSelector } from 'react-redux';

const SummaryApplication = () => {
  const summaries =
    useSelector((state) => state.contacts.summaryDetail?.applicationSummary) ||
    {};

  const data = [
    {
      title: 'centreDetail.openApplication',
      value: summaries.openApplications,
      IconComponent: TotalOpenApplicationIcon,
    },
    {
      title: 'centreDetail.openApplicationValue',
      value: summaries.openApplicationsValue,
      IconComponent: TotalEarningIcon,
      isPerAnnum: true,
    },
    {
      title: 'centreDetail.totalWaitlisted',
      value: summaries.waitListedApplications,
      IconComponent: TotalWaitlistIcon,
    },
    {
      title: 'centreDetail.waitlistValue',
      value: summaries.waitListedApplicationsValue,
      IconComponent: TotalWaitlistValueIcon,
      isPerAnnum: true,
    },
  ];
  return (
    <SummaryCardDetail title="centreDetail.applicationSummary" data={data} />
  );
};

export default SummaryApplication;

import React from 'react';
import { Button } from 'antd';
import { useTranslation } from 'react-i18next';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { MessageIcon } from 'components/common/SVGIcon';
import { ClaimSideCoverContainer, CoverText, GroupButton } from './styles';

interface Props {
  currentStep: number;
}

const ClaimSideCover = ({ currentStep }: Props) => {
  const { t } = useTranslation();
  const title = currentStep === 2 ? 'Complete your claim' : 'Find your centre';
  return (
    <ClaimSideCoverContainer>
      <CoverText>{t(title)}</CoverText>
      {currentStep !== 2 && (
        <GroupButton>
          <Button
            target={'_blank'}
            href={`${process.env.REACT_APP_RW_URL}/contact-us?reason=Book+a+Demo`}
            className="btn-book-demo fw-700"
            icon={<MessageIcon />}
          >
            {t('button.bookADemo')}
          </Button>
          <Button
            target={'_blank'}
            href={`${process.env.REACT_APP_RW_URL}/for-childcare-providers`}
            icon={<ExclamationCircleOutlined />}
            className="btn-learn-more fw-700"
          >
            {t('button.learnMore')}
          </Button>
        </GroupButton>
      )}
    </ClaimSideCoverContainer>
  );
};

export default ClaimSideCover;

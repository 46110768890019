import React from 'react';
import PropTypes from 'prop-types';
import {
  CloseCircleOutlined,
  LoadingOutlined,
  ExclamationCircleOutlined,
  FileOutlined,
} from '@ant-design/icons';
import { isImageFile, getExtensionFile } from 'utils/fileUtils';
import { FILE_TYPES } from 'configs/localData';
import { UploadFileItemStyles } from './styles';

const UploadFileItem = ({ item, deleteImage }) => {
  const restFileItem = FILE_TYPES.find(
    (file) =>
      file.value.toLocaleLowerCase() ===
      getExtensionFile(item.url || item.response)?.toLocaleLowerCase(),
  );

  const handleDeletedFile = (e, item) => {
    e.stopPropagation();
    deleteImage(item);
  };

  return (
    <UploadFileItemStyles className="uploadImage">
      {item.status === 'error' && (
        <ExclamationCircleOutlined className="loading error" />
      )}
      {item.status !== 'error' && item.status !== 'done' && !item.url && (
        <LoadingOutlined className="loading" />
      )}
      {(typeof item?.type === 'string' && item.type?.includes('image')) ||
      isImageFile(item.url) ? (
        <img className="image" src={item.url || item?.response} alt="" />
      ) : (
        <div className="icon-div-item">
          {restFileItem ? (
            <restFileItem.icon
              style={{
                color: restFileItem.color,
              }}
              className="icon-file"
            />
          ) : (
            <FileOutlined className="icon-file" />
          )}
        </div>
      )}
      <CloseCircleOutlined
        className="remove-button"
        onClick={(e) => handleDeletedFile(e, item)}
      />
    </UploadFileItemStyles>
  );
};
UploadFileItem.propTypes = {
  item: PropTypes.object,
  deleteImage: PropTypes.func,
};

export default UploadFileItem;

import { PREFIX_API_VER_2 } from 'configs/localData/constant';
import { post, get, put } from './utils';

export const changeEmailApi = (id, values) =>
  post(`/web/centres/${id}/changeEmail`, values);

export const claimCentreApi = (id, values) =>
  post(`/web/centres/${id}/claim`, values);

export const disputeCentreApi = (id, values) =>
  post(`/web/centres/${id}/dispute`, values);

export const signUpMerchantApi = ({ id, ...data }) =>
  post(`/admin/centres/${id}/signUpMerchant`, data);

export const signUpMerchantCallbackApi = ({ id, ...data }) =>
  post(`/admin/centres/${id}/signUpMerchant/callback`, data);

export const getSummaryDetailCentreApi = (id) =>
  get(`/admin/centres/${id}/summary`);

export const getRatingsCentreApi = (id, data) =>
  get(`/admin/centres/${id}/ratings`, data);

export const getAllCentresApi = (data) => get('/admin/users/me/centres', data);

export const resendVerificationEmailApi = (id, values) =>
  post(`/web/centres/${id}/resend-verification-email`, values);

export const getAllCentresOfUserApi = () => get('/admin/centres/all-of-user');

export const changeFinishedStepApi = (id, data) =>
  put(`/admin/centres/${id}/finishedStep`, data);

export const getAppSettingsCentreApi = (id, data) =>
  get(`/admin/centres/${id}/app-settings`, data);

export const editAppSettingsCentreByKeyApi = (id, key, data) =>
  put(`/admin/centres/${id}/app-settings/${key}`, data);

export const getAllSocialMediaChannelsApi = (data) =>
  get(`/crm/centres/details/cta-buttons`, data, PREFIX_API_VER_2);

export const editSocialMediaChannelsApi = (id, data) =>
  put(
    `/crm/centres/details/cta-buttons?centreId=${id}`,
    data,
    PREFIX_API_VER_2,
  );

export const getKindiCareRatingSummaryApi = (id, data) =>
  get(
    `/crm/centres/details/rating-summary?centreId=${id}`,
    data,
    PREFIX_API_VER_2,
  );

export const getVirtualTourPreviewApi = (data) =>
  get(`/public/virtual-tour-preview`, data, PREFIX_API_VER_2);

export const addCentreVirtualTourApi = (id, data) =>
  post(
    `/crm/centres/details/virtual-tour?centreId=${id}`,
    data,
    PREFIX_API_VER_2,
  );

export const getCentreVirtualTourApi = (id, data) =>
  get(
    `/crm/centres/details/virtual-tour?centreId=${id}`,
    data,
    PREFIX_API_VER_2,
  );

import React from 'react';
import { getTasksUpcomingApi } from 'api/dashboard';

import CardCommon from '../CardCommon';
import ListTask from './ListTasks';

const UpcomingTasks = () => (
  <CardCommon title="task.upcomingTasks" isLightBackground={false}>
    <ListTask action={getTasksUpcomingApi} />
  </CardCommon>
);

export default UpcomingTasks;

import PropTypes from 'prop-types';
import { TableActionStyled } from 'containers/JobTemplates/styles';
import React from 'react';
import i18next from 'i18next';
import useFormat from 'containers/JobTemplates/useFormat';
import { Switch } from 'antd';
import useRolesJobTemplates from 'containers/Users/hooks/useRoleJobTemplates';

const TableToggle = ({
  dataSource,
  categoryName,
  onSwitchChange,
  ...props
}) => {
  const useFormatFnc = useFormat();
  const disabledAction = useRolesJobTemplates();

  const columns = [
    {
      title: categoryName || i18next.t('statuses.name'),
      dataIndex: 'name',
      key: 'name',
      render: useFormatFnc.formatCategoryName,
    },
    {
      title: i18next.t('common.status'),
      key: 'action',
      dataIndex: 'value',
      width: 80,
      render: (value, record, index) =>
        onSwitchChange ? (
          <Switch
            checked={value}
            onChange={(checked) => onSwitchChange(checked, record)}
            disabled={disabledAction}
          />
        ) : (
          useFormatFnc.formatStatusRow(value, record, index)
        ),
    },
  ];

  return (
    <TableActionStyled
      columns={columns}
      dataSource={dataSource}
      pagination={false}
      rowKey="id"
      {...props}
    />
  );
};

TableToggle.propTypes = {
  dataSource: PropTypes.array,
  categoryName: PropTypes.string,
  onSwitchChange: PropTypes.func,
  disabled: PropTypes.bool,
};

export default TableToggle;

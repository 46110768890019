import React from 'react';
import { Droppable } from 'react-beautiful-dnd';
import PropTypes from 'prop-types';

import {
  ScrollContainer,
  Wrapper,
} from 'containers/Applications/components/Primatives/styles';
import { Skeleton } from 'antd';
import { Waypoint } from 'react-waypoint';
import InnerList from './InnerList';

const JobApplicationBoardList = (props) => {
  const {
    ignoreContainerClipping,
    internalScroll,
    scrollContainerStyle,
    isDropDisabled,
    isCombineEnabled,
    listId,
    listType,
    style,
    data,
    title,
    colors,
    isDragDisabledItem,
    isShowWaypoint,
    handleLoadMore,
    loading,
  } = props;

  return (
    <Droppable
      droppableId={listId}
      title={title}
      type={listType}
      ignoreContainerClipping={ignoreContainerClipping}
      isDropDisabled={isDropDisabled}
      isCombineEnabled={isCombineEnabled}
    >
      {(dropProvided, dropSnapshot) => (
        <Wrapper
          style={style}
          isDraggingOver={dropSnapshot.isDraggingOver}
          isDropDisabled={isDropDisabled}
          isDraggingFrom={Boolean(dropSnapshot.draggingFromThisWith)}
          {...dropProvided.droppableProps}
        >
          {internalScroll ? (
            <ScrollContainer style={scrollContainerStyle}>
              <InnerList
                applications={data}
                title={title}
                dropProvided={dropProvided}
                colors={colors}
                isDragDisabledItem={isDragDisabledItem}
                handleLoadMore={handleLoadMore}
                isShowWaypoint={isShowWaypoint}
              />
              {loading && (
                <div className="flex-center">
                  <Skeleton active />
                </div>
              )}
              <div>
                <Waypoint
                  onEnter={isShowWaypoint ? handleLoadMore : undefined}
                />
              </div>
            </ScrollContainer>
          ) : (
            <InnerList
              applications={data}
              title={title}
              dropProvided={dropProvided}
              colors={colors}
              isDragDisabledItem={isDragDisabledItem}
              handleLoadMore={handleLoadMore}
              isShowWaypoint={isShowWaypoint}
            />
          )}
        </Wrapper>
      )}
    </Droppable>
  );
};

JobApplicationBoardList.propTypes = {
  ignoreContainerClipping: PropTypes.bool,
  internalScroll: PropTypes.bool,
  scrollContainerStyle: PropTypes.object,
  isDropDisabled: PropTypes.bool,
  isCombineEnabled: PropTypes.bool,
  listId: PropTypes.string,
  listType: PropTypes.string,
  style: PropTypes.object,
  data: PropTypes.array,
  title: PropTypes.string,
  useClone: PropTypes.bool,
  colors: PropTypes.object,
  isDragDisabledItem: PropTypes.bool,
  handleLoadMore: PropTypes.func,
  isShowWaypoint: PropTypes.bool,
  loading: PropTypes.bool,
};

JobApplicationBoardList.defaultProps = {
  listId: 'LIST',
  data: [],
};

export default JobApplicationBoardList;

import React, { useState } from 'react';
import { Button, Checkbox, Space, Typography } from 'antd';
import ModalCustom from 'components/common/ModalCustom';
import i18next from 'i18next';
import { WarningIcon } from 'components/common/SVGIcon';
import { RootState } from 'redux/store';
import { useSelector } from 'react-redux';

interface Props {
  visible: boolean;
  setVisible?: (params: boolean) => void;
  resource?: string;
  handleSave?: () => void;
  title?: string;
  content?: string;
}

const { Text } = Typography;

const SaveAndExitModal = ({
  visible,
  setVisible,
  resource,
  handleSave,
  title,
  content,
}: Props) => {
  const currentUserId = useSelector(
    (state: RootState) => state?.auth?.data?.id,
  );
  const [isNoAskAgain, setIsNoAskAgain] = useState(false);
  const userNoAskAgainList = JSON.parse(localStorage.getItem(resource)) || [];

  const onchange = (e) => {
    setIsNoAskAgain(e.target.checked);
  };

  const onSaveAndExit = () => {
    if (isNoAskAgain) {
      localStorage.setItem(
        resource,
        JSON.stringify([...userNoAskAgainList, { userId: currentUserId }]),
      );
    }
    handleSave();
  };

  return (
    <ModalCustom
      closable={false}
      visible={visible}
      width={500}
      footer={[
        <Space key="footer-ask-again" className="w-415 justify-between">
          <Checkbox className="text-16 pb-6" onChange={onchange}>
            {i18next.t('common.noAskAgain')}
          </Checkbox>
          <div>
            <Button
              onClick={() => {
                setVisible(false);
              }}
            >
              {i18next.t('button.cancel')}
            </Button>
            <Button type="primary" onClick={onSaveAndExit}>
              {i18next.t('button.saveAndExit')}
            </Button>
          </div>
        </Space>,
      ]}
    >
      <div className="pt-24 flex">
        <WarningIcon className="text-24 pt-6 pr-16" />
        <div>
          {title && <Text className="fw-bold text-20 d-block">{title}</Text>}
          {content && <Text className="text-gray-700 text-16">{content}</Text>}
        </div>
      </div>
    </ModalCustom>
  );
};

export default SaveAndExitModal;

import { Form } from 'antd';
import ModalCustom from 'components/common/ModalCustom';
import { RestInputContext } from 'components/RestInput/RestInputContext';
import {
  CentreServiceControllerUpdateOneApiArg,
  CentreServiceView,
  UpdateCentreServiceDto,
  useCentreServiceControllerUpdateOneMutation,
} from 'generated/apis';
import { isEmpty, isNil, omitBy } from 'lodash-es';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { differenceTwoObj } from 'utils/tools';
import ServiceForm from './ServiceForm';

interface Props {
  isProgramEdit?: boolean;
  isProgramCreate?: boolean;
  record?: CentreServiceView;
  editModalVisible?: boolean;
  onClose?: () => void;
}

const EditServiceModal = ({
  isProgramEdit,
  isProgramCreate,
  record,
  editModalVisible,
  onClose,
}: Props) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const { validateFields, resetFields } = form;
  const [updateService, { isLoading }] =
    useCentreServiceControllerUpdateOneMutation();

  const onOk = () => {
    validateFields().then(async (values: UpdateCentreServiceDto) => {
      const rest = {
        ...omitBy(differenceTwoObj(values, record), isNil),
        programId: values.programId ?? null,
      };
      if (!isEmpty(rest) && record.id) {
        const service = {
          serviceId: record.id,
          updateCentreServiceDto: rest,
        } as CentreServiceControllerUpdateOneApiArg;
        await updateService(service);
      }
      handleCancel();
    });
  };

  const handleCancel = () => {
    onClose();
    resetFields();
  };

  return (
    <ModalCustom
      title={t('centreServices.editPage')}
      visible={editModalVisible}
      onCancel={handleCancel}
      onOk={onOk}
      loading={isLoading}
      isScrollY
      width={822}
    >
      <Form
        form={form}
        layout="vertical"
        initialValues={{ ...record, programId: record.programId ?? undefined }}
      >
        <RestInputContext.Provider value={{ form, record }}>
          <ServiceForm
            isEdit
            form={form}
            isProgramEdit={isProgramEdit}
            isProgramCreate={isProgramCreate}
          />
        </RestInputContext.Provider>
      </Form>
    </ModalCustom>
  );
};

export default EditServiceModal;

import React from 'react';
import PropTypes from 'prop-types';
import { APPLICATION_STATUS_CONSTANT } from 'configs/localData';

import CheckPermission from 'components/common/CheckPermissions';
import DeclineButton from './DeclineButton';
import FallbackBtn from './FallbackBtn';
import { ButtonActionStyles } from './styles';

const ButtonAction = ({ child, handleUpdateStatus, status }) =>
  status === APPLICATION_STATUS_CONSTANT.declined ? null : (
    <ButtonActionStyles>
      <CheckPermission
        permissionKey="applications"
        action="isEdit"
        isDisabled
        fallback={<FallbackBtn />}
      >
        <DeclineButton child={child} handleUpdateStatus={handleUpdateStatus} />
      </CheckPermission>
    </ButtonActionStyles>
  );
ButtonAction.propTypes = {
  child: PropTypes.object,
  handleUpdateStatus: PropTypes.func,
  status: PropTypes.string,
};

ButtonAction.defaultProps = {
  child: {},
  handleUpdateStatus: () => null,
};

export default ButtonAction;

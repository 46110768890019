import React from 'react';
import { BoardIcon, ListIcon } from 'components/common/SVGIcon';
import PropTypes from 'prop-types';
import i18next from 'i18next';
import { DISPLAY_TYPES } from 'configs/localData/constant';
import { Wrapper, DisplayType } from './styles';

const DisplayTypes = ({ type, handleUpdateViewType }) => (
  <Wrapper>
    <DisplayType
      isSelected={type === DISPLAY_TYPES.board}
      onClick={() => handleUpdateViewType(DISPLAY_TYPES.board)}
    >
      <BoardIcon />
      <p>{i18next.t('text.board')}</p>
    </DisplayType>
    <DisplayType
      isSelected={type === DISPLAY_TYPES.list}
      onClick={() => handleUpdateViewType(DISPLAY_TYPES.list)}
    >
      <ListIcon />
      <p>{i18next.t('text.list')}</p>
    </DisplayType>
  </Wrapper>
);
DisplayTypes.propTypes = {
  type: PropTypes.string,
  handleUpdateViewType: PropTypes.func,
};
export default DisplayTypes;

import { configureStore } from '@reduxjs/toolkit';
import { createBrowserHistory } from 'history';
import { persistStore } from 'redux-persist';
import { baseApi } from 'redux/@rtkQuery/baseApi';
import { setupListeners } from '@reduxjs/toolkit/query';
import reducers from 'redux/reducers';

export const history = createBrowserHistory();

const store = configureStore({
  reducer: reducers(history),
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat(baseApi.middleware),
});

export const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;

setupListeners(store.dispatch);

export default store;

import styled from 'styled-components';

export const LegendBarChartStyle = styled.div`
  .legend-bar-row {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  @media only screen and (min-width: 1200px) and (max-width: 1800px) {
    .legend-bar-row {
      display: inline-block;
      vertical-align: middle;
      padding-top: 16px;
    }
  }
`;

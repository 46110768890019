import React from 'react';
import PropTypes from 'prop-types';
import { Draggable } from 'react-beautiful-dnd';

import JobApplicationItem from './JobApplicationItem';

const InnerApplicationList = ({ applications, colors, isDragDisabledItem }) =>
  applications.map((application, index) => (
    <Draggable
      key={application.id}
      draggableId={application.id}
      index={index}
      isDragDisabled={isDragDisabledItem}
    >
      {(dragProvided, dragSnapshot) => (
        <JobApplicationItem
          colors={colors}
          key={application.id}
          application={application}
          isDragging={dragSnapshot.isDragging}
          isGroupedOver={Boolean(dragSnapshot.combineTargetFor)}
          provided={dragProvided}
        />
      )}
    </Draggable>
  ));

InnerApplicationList.propTypes = {
  applications: PropTypes.array,
  colors: PropTypes.object,
  isDragDisabledItem: PropTypes.bool,
};

InnerApplicationList.defaultProps = {};

export default React.memo(InnerApplicationList);

import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Form, Input, Button } from 'antd';
import i18next from 'i18next';
import { postReply } from 'api/ratings';

import { SendIcon } from 'components/common/SVGIcon';
import { FormCommentStyles } from './styles';

const { TextArea } = Input;

const CommentForm = ({ id, handleChangeReplies }) => {
  const [form] = Form.useForm();

  const [loading, setLoading] = useState(false);

  const { validateFields, resetFields } = form;

  const handleReply = useCallback(
    (data) => {
      if (data?.comment?.trim() && id) {
        setLoading(true);
        postReply(id, data)
          .then((response) => {
            handleChangeReplies(response);
            setLoading(false);
            resetFields();
          })
          .catch(() => setLoading(false));
      }
    },
    [id, handleChangeReplies, resetFields],
  );

  const handlePressEnter = useCallback(
    (evt) => {
      if (evt.keyCode === 13 && !evt.shiftKey) {
        evt.preventDefault();
        validateFields().then((values) => {
          handleReply(values);
        });
      }
    },
    [handleReply, validateFields],
  );

  return (
    <FormCommentStyles>
      <Form form={form} onFinish={handleReply} layout="inline">
        <div className="content-form-reply">
          <Form.Item noStyle name="comment">
            <TextArea
              placeholder={i18next.t('messages.content')}
              onPressEnter={handlePressEnter}
              autoSize={{ minRows: 1, maxRows: 3 }}
              bordered={false}
            />
          </Form.Item>
          <Form.Item noStyle>
            <Button
              className="btn-reply"
              htmlType="submit"
              loading={loading}
              icon={<SendIcon />}
            />
          </Form.Item>
        </div>
      </Form>
    </FormCommentStyles>
  );
};

CommentForm.propTypes = {
  id: PropTypes.string,
  handleChangeReplies: PropTypes.func,
};

CommentForm.defaultProps = {
  handleChangeReplies: () => {},
};

export default CommentForm;

import React, { useState, useEffect, useMemo } from 'react';
import { Result, Button } from 'antd';
import i18next from 'i18next';
import { useHistory } from 'react-router';
import {
  getCentreId,
  getCentreLevelCRMPackageName,
} from 'redux/auth/selectors';
import { useDispatch, useSelector } from 'react-redux';
import { setCentreId } from 'redux/auth/slice';
import { ModalStyles } from './styles';

const UpgradeSubscriptionModal = () => {
  const history = useHistory();
  const { location } = history;
  const dispatch = useDispatch();

  const [visible, setVisible] = useState(false);
  const [isCanSubscription, setIsCanSubscription] = useState(false);
  const [currentCentreId, setCurrentCentreId] = useState(null);

  const onCancel = () => {
    setVisible(false);
    setIsCanSubscription(false);
    setCurrentCentreId(null);
  };

  const packageName = useSelector(
    (state) => state?.centres?.currentData?.CRMPackage?.name,
  );

  const centreLevelCRMPackageName = useSelector(getCentreLevelCRMPackageName);

  const centreId = useSelector(getCentreId);

  const { push } = useHistory();

  const subTitle = useMemo(
    () => (
      <div className="subscription-description">
        {i18next.t('error.errorSubscriptionPermission.l1')}
        <b>
          {centreId || currentCentreId
            ? centreLevelCRMPackageName
            : packageName}
        </b>
        {i18next.t('error.errorSubscriptionPermission.l2')}
        <p className="subscription-description__end">
          {i18next.t('error.errorSubscriptionPermission.l3')}
        </p>
      </div>
    ),
    [centreId, centreLevelCRMPackageName, packageName, currentCentreId],
  );

  useEffect(() => {
    window.addEventListener('trigger-upgrade-modal', (e) => {
      setVisible(true);
      e.detail.isCanSubscription &&
        setIsCanSubscription(e.detail.isCanSubscription);
      e.detail.currentCentreId && setCurrentCentreId(e.detail.currentCentreId);
    });
    return () => {
      window.removeEventListener('trigger-upgrade-modal', () => {
        setVisible(false);
        setIsCanSubscription(false);
        setCurrentCentreId(null);
      });
    };
  }, []);

  const handleClick = () => {
    setVisible(false);
    push('/subscriptions');
    currentCentreId && dispatch(setCentreId(currentCentreId));
  };

  const contentUpgradeSubscription = () => (
    <Button onClick={handleClick} type="primary">
      {i18next.t('button.goToSubscriptions')}
    </Button>
  );

  const contentUpgradeSubscriptionForAutoResponse = () => (
    <div>
      <div className="subscription-description">
        {i18next.t('error.errorSubscriptionPermissionMultiCentre.l1')}
        <p className="subscription-description__end">
          {i18next.t('error.errorSubscriptionPermissionMultiCentre.l2')}
        </p>
      </div>
      <Button onClick={handleClick} type="primary">
        {i18next.t('button.goToSubscriptions')}
      </Button>
    </div>
  );

  return (
    <ModalStyles
      visible={visible}
      footer={null}
      onCancel={onCancel}
      destroyOnClose
    >
      {isCanSubscription ? (
        <Result
          status="warning"
          title={i18next.t('error.errorRolePermissionTitle')}
          subTitle={i18next.t('error.errorRolePermission')}
        />
      ) : (
        <Result
          status="warning"
          title={i18next.t('error.errorSubscriptionPermissionTitle')}
          subTitle={
            location.pathname !== '/enquiries/automatedResponses' && subTitle
          }
          extra={
            location.pathname === '/enquiries/automatedResponses'
              ? contentUpgradeSubscriptionForAutoResponse()
              : contentUpgradeSubscription()
          }
        />
      )}
    </ModalStyles>
  );
};

export default UpgradeSubscriptionModal;

export const upgradeModal = {
  open: (isCanSubscription, currentCentreId) => {
    const event = new CustomEvent('trigger-upgrade-modal', {
      detail: {
        isCanSubscription,
        currentCentreId,
      },
    });
    window.dispatchEvent(event);
  },
};

import React from 'react';
import i18next from 'i18next';
import PropTypes from 'prop-types';
import { InformationWrapper } from './styles';
import ParentItemCard from './ParentItemCard';

const ParentInfo = ({ parentInfo, additionalParentInfo }) => (
  <InformationWrapper>
    <div className="title-info">
      {i18next.t('applications.parentInformation')}
    </div>
    <div className="content-info-wrapper">
      <ParentItemCard record={parentInfo} index={1} />
      {additionalParentInfo && (
        <ParentItemCard record={additionalParentInfo} index={2} />
      )}
    </div>
  </InformationWrapper>
);

ParentInfo.propTypes = {
  parentInfo: PropTypes.object,
  additionalParentInfo: PropTypes.object,
};

ParentInfo.defaultProps = {
  parentInfo: {},
  additionalParentInfo: {},
};

export default ParentInfo;

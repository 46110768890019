import CardCustom from 'components/common/CardCustom';
import { BriefcaseFilledIcon, DotIcon } from 'components/common/SVGIcon';
import React from 'react';
import PropTypes from 'prop-types';
import CardCustomContent from '../../CardContentCustom';
import WorkExperienceContent from '../../CardContentCustom/WorkExperienceContent';

const MyWorkExperience = ({ workExperience }) => (
  <div className="mb-32">
    <CardCustom
      title="jobApplications.generalInfo.myWorkExperience"
      Icon={BriefcaseFilledIcon}
    >
      <div>
        {workExperience?.map((item, index) => (
          <CardCustomContent Icon={DotIcon} isProgress key={Number(index)}>
            <WorkExperienceContent item={item} />
          </CardCustomContent>
        ))}
      </div>
    </CardCustom>
  </div>
);

MyWorkExperience.propTypes = {
  workExperience: PropTypes.array,
};

export default MyWorkExperience;

import React, { useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import i18next from 'i18next';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import TableCustom from 'components/common/TableCustom';
import DeleteButton from 'components/RestActions/DeleteButton';

import { getQueryParamsWhenChangeTable } from 'utils/tableUtils';
import { companiesOfUserSelectors } from 'redux/companiesOfUser/selectors';
import { delCompaniesOfUser } from 'redux/companiesOfUser/actions';
import ProvidersOfCompanyField from 'components/ProvidersViewAll/ProvidersField';
import ProvidersModal from 'components/ProvidersViewAll/ProvidersModal';
import { getProvidersOfCompany } from 'redux/providersOfCompany/actions';

const CompaniesUserTable = ({ retrieveList }) => {
  const dispatch = useDispatch();

  const providersUserModalRef = useRef();
  const { id } = useParams();

  const openProvidersOfCompanyModal = useCallback((record) => {
    providersUserModalRef.current.open(record);
  }, []);

  const formatProviderName = useCallback(
    (data, record) => (
      <ProvidersOfCompanyField
        data={record}
        handleClickViewAll={() => openProvidersOfCompanyModal(record)}
      />
    ),
    [openProvidersOfCompanyModal],
  );

  const resourceFilter =
    useSelector(companiesOfUserSelectors.getResourceData) || {};

  const loading = useSelector(companiesOfUserSelectors.getLoading);

  const data = useSelector(companiesOfUserSelectors.getDataArr);

  const deleteCompanyOfUser = (companyId) =>
    dispatch(
      delCompaniesOfUser({
        id,
        companyId,
      }),
    );

  const columns = [
    {
      title: i18next.t('users.companyName'),
      dataIndex: 'name',
      sorter: true,
    },
    {
      title: i18next.t('providers.title'),
      dataIndex: 'firstProviderName',
      render: formatProviderName,
    },
    {
      title: i18next.t('providers.numberOfCentres'),
      dataIndex: 'totalCentres',
      sorter: true,
    },
    {
      title: '',
      dataIndex: 'id',
      width: 80,
      fixed: 'right',
      render: (id, record) => (
        <DeleteButton
          resource="companyUser"
          permissionKey="abilityChangeUserPermissions"
          action="isEdit"
          isSkipSubscription
          customMessage="companyUser.confirmDeleteCompany"
          record={record}
          deleteItem={deleteCompanyOfUser}
        />
      ),
    },
  ];

  const onChange = (e, filters, sorter) => {
    const queryParams = getQueryParamsWhenChangeTable(e, filters, sorter);

    retrieveList({
      filter: queryParams,
      isRefresh: true,
      keepFilter: true,
    });
  };

  return (
    <div>
      <TableCustom
        xScroll={600}
        onChange={onChange}
        data={data}
        columns={columns}
        loading={loading}
        isResetStyle
        pagination={{
          pageSize: resourceFilter.limit,
          current: resourceFilter.offset / resourceFilter.limit + 1 || 1,
          total: resourceFilter.total,
        }}
      />
      <ProvidersModal
        ref={providersUserModalRef}
        retrieveListAction={getProvidersOfCompany}
      />
    </div>
  );
};

CompaniesUserTable.propTypes = {
  retrieveList: PropTypes.func,
};

CompaniesUserTable.defaultProps = {
  retrieveList: () => null,
};

export default CompaniesUserTable;

import React, { useCallback, useState } from 'react';
import i18next from 'i18next';
import { Button, Col, Row, Typography } from 'antd';
import PropTypes from 'prop-types';

import { ShootingStarIcon } from 'components/common/SVGIcon';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import { editCentres } from 'redux/centres/actions';
import { LISTING_STRENGTH_CONTENT } from 'configs/localData';
import { ListingStrengthStyles } from './styles';
import ProgressStepDone from './ProgressStepDone';
import GetStarted from './GetStarted';
import ViewListModal from './ViewListModal';
import StepContent from './StepContent';

const ListingStrengthCard = ({ record }) => {
  const dispatch = useDispatch();

  const { id } = useParams();
  const handleGetStarted = useCallback(() => {
    dispatch(
      editCentres({
        data: { id, isStartListing: true },
        options: {
          isShowSuccessNoti: false,
          isCheckError: false,
        },
      }),
    );
  }, [dispatch, id]);
  const [visible, setVisible] = useState(false);

  const showModal = () => {
    setVisible(true);
  };

  const onCancel = () => {
    setVisible(false);
  };

  const centreStep = record?.centreStep.map((item) => ({
    ...item,
    ...LISTING_STRENGTH_CONTENT.find((value) => value.step === item?.step),
  }));

  return (
    <ListingStrengthStyles>
      <div className="header-info">
        <Row align="middle" justify="space-between">
          <Col>
            <div className="flex center-item">
              <span className="header-info__text">
                {i18next.t('listingStrength.titleHeader')}
              </span>
              <ShootingStarIcon />
              <Button
                className="ml-16 border-none btn-view-list"
                onClick={() => showModal()}
              >
                <Typography.Text underline className="btn-view-list">
                  {i18next.t('button.viewList')}
                </Typography.Text>
              </Button>
            </div>
          </Col>
        </Row>

        <ProgressStepDone centreStep={centreStep} />
      </div>
      {record?.isStartListing ? (
        <StepContent record={record} centreStep={centreStep} />
      ) : (
        <GetStarted handleGetStarted={handleGetStarted} record={record} />
      )}
      <ViewListModal
        visible={visible}
        onCancel={onCancel}
        record={record}
        centreStep={centreStep}
      />
    </ListingStrengthStyles>
  );
};

ListingStrengthCard.propTypes = {
  record: PropTypes.object,
};

export default ListingStrengthCard;

import React from 'react';
import { Avatar, Divider } from 'antd';
import { useSelector } from 'react-redux';
import { PictureFilled } from '@ant-design/icons';
import { isEmpty } from 'lodash-es';
import EditCustomBtn from 'components/RestActions/EditCustomBtn';
import { useHistory } from 'react-router';
import ParentInfo from './ParentInfo';

const ContactInfo = () => {
  const record = useSelector((state) => state.contacts.currentData);
  const { push } = useHistory();

  const onOpenEdit = () => {
    record?.id && push(`#contacts/${record.id}/edit`);
  };

  return (
    <div className="contact-info">
      {record.customerType === 'MANUAL' && (
        <EditCustomBtn
          permissionKey="contacts"
          action="isEdit"
          onClickButton={onOpenEdit}
        />
      )}

      <div className="contact-info__thumbnail">
        <Avatar
          shape="square"
          icon={<PictureFilled />}
          src={record?.avatar}
          size={246}
        />
      </div>

      <div className="contact-info__content">
        <ParentInfo title="contacts.parentGuardianContact" item={record} />

        {!isEmpty(record?.additionalParentInfo) && (
          <>
            <Divider />
            <ParentInfo
              title="contacts.parentGuardian2"
              item={record?.additionalParentInfo}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default ContactInfo;

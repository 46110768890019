import React, { useCallback, useEffect, useRef, useState } from 'react';
import i18next from 'i18next';
import { Space, Modal } from 'antd';
import { useHistory, useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import {
  clearListEnquiries,
  getAllEnquiries,
  getSummariesEnquiry,
} from 'redux/enquiries/actions';
import { enquiriesSelectors } from 'redux/enquiries/selectors';
import { getCentreId } from 'redux/auth/selectors';
import CustomButton from 'components/RestActions/CustomButton';
import { TwinkleIcon } from 'components/common/SVGIcon';
import { ENQUIRIES_STATUS_CONST } from 'configs/localData';
import { ENQUIRY_MODE_KEY } from 'configs/localData/constant';
import { getByKeyAppSettingsOfCentre } from 'redux/appSettings/actions';
import PageTitle from '../../../components/common/PageTitle';
import ListStyles from './styles';
import Filter from '../components/FilterOther';
import SettingEnquiryBtn from './SettingEnquiryBtn';
import EnquirySettingModal from '../components/EnquirySettingModal';
import ChatBoxList from './ChatBoxList';
import { EnquiryProvider } from './context';

const getDataFilter = (resourceFilter, filter, isInitial) => ({
  ...(!isInitial && { q: resourceFilter?.q }),
  ...filter,
  filter: {
    enquiryTypeId: {
      $exists: true,
    },
    archivedAt: { $exists: false },
    ...(!isInitial && { ...resourceFilter?.filter }),
    ...filter?.filter,
  },
});

const List = () => {
  const dispatch = useDispatch();

  const settingModalRef = useRef();

  const [isMounted, setIsMounted] = useState(false);

  const { id: inboxId, tab } = useParams();

  const { push } = useHistory();

  const resourceFilter = useSelector(enquiriesSelectors.getFilters) || {};

  const centreId = useSelector(getCentreId);

  const retrieveList = useCallback(
    ({ filter = {}, isRefresh, isInitial }) => {
      dispatch(
        getAllEnquiries({
          data: getDataFilter(resourceFilter, filter, isInitial),
          options: {
            isRefresh,
            customApiResource: 'inboxes',
            isOrderBy: false,
          },
        }),
      );
    },
    [resourceFilter], // eslint-disable-line
  );

  const getSummaries = ({ filter, isInitial }) => {
    dispatch(
      getSummariesEnquiry({
        data: getDataFilter(resourceFilter, filter, isInitial),
      }),
    );
  };

  const getListAndSummary = (filter, isInitial = false) => {
    retrieveList({
      filter,
      isRefresh: true,
      isInitial,
    });
    getSummaries({ filter, isInitial });
  };

  const retrieveListTab = ({
    centreHasNewMessage,
    centreResponded,
    archived = false,
    isInitial,
  }) => {
    retrieveList({
      filter: {
        filter: {
          centreHasNewMessage,
          centreResponded,
          archivedAt: { $exists: archived },
        },
      },
      isRefresh: true,
      isInitial,
    });
  };

  const handleGetAllWithTab = (isInitial) => {
    switch (tab) {
      case ENQUIRIES_STATUS_CONST.unread.key:
        retrieveListTab({ centreHasNewMessage: true, isInitial });
        break;

      case ENQUIRIES_STATUS_CONST.read.key:
        retrieveListTab({ centreHasNewMessage: false, isInitial });
        break;

      case ENQUIRIES_STATUS_CONST.responded.key:
        retrieveListTab({ centreResponded: true, isInitial });
        break;

      case ENQUIRIES_STATUS_CONST.notResponded.key:
        retrieveListTab({ centreResponded: false, isInitial });
        break;

      case ENQUIRIES_STATUS_CONST.archived.key:
        retrieveListTab({ archived: true, isInitial });
        break;

      default:
        retrieveListTab({ isInitial });
        break;
    }
  };

  useEffect(() => {
    getSummaries({ isInitial: true });
    handleGetAllWithTab({ isInitial: true });
    setIsMounted(true);
    centreId &&
      dispatch(
        getByKeyAppSettingsOfCentre({ centreId, key: ENQUIRY_MODE_KEY }),
      );
  }, [centreId]); // eslint-disable-line

  useEffect(() => {
    isMounted && handleGetAllWithTab();
  }, [tab]); // eslint-disable-line

  useEffect(
    () => () => {
      dispatch(clearListEnquiries());
    },
    [], // eslint-disable-line
  );

  const handleClickAutomateResponse = () => {
    push('/enquiries/automatedResponses');
  };

  const openSettingModal = () => {
    if (centreId) {
      settingModalRef.current.open();
    } else {
      Modal.warning({
        title: i18next.t('enquiriesSettings.title'),
        content: i18next.t('enquiriesSettings.warningSelectCentre'),
      });
    }
  };

  return (
    <EnquiryProvider>
      <ListStyles className="full-content">
        <PageTitle
          extraAction={
            <Space size={12}>
              <CustomButton
                buttonProps={{
                  icon: <TwinkleIcon />,
                  className: 'btn-second-primary',
                }}
                title="button.automatedResponses"
                handleClick={handleClickAutomateResponse}
              />
              <SettingEnquiryBtn openSettingModal={openSettingModal} />
            </Space>
          }
        >
          {i18next.t('enquiries.header')}
        </PageTitle>
        <Filter getListAndSummary={getListAndSummary} />
        <ChatBoxList
          inboxId={inboxId}
          tab={tab}
          getListAndSummary={getListAndSummary}
          retrieveList={retrieveList}
          getSummaries={getSummaries}
        />

        <EnquirySettingModal ref={settingModalRef} />
      </ListStyles>
    </EnquiryProvider>
  );
};

export default List;

import React, { useEffect } from 'react';
import { useLocation, useParams } from 'react-router';
import i18next from 'i18next';

import { useDispatch, useSelector } from 'react-redux';
import { getCentreId } from 'redux/auth/selectors';
import { getAllContacts } from 'redux/contacts/actions';

import { getQueryParams } from 'utils/tools';

import PageTitle from 'components/common/PageTitle';
import TableView from '../components/TableView';
import HeaderFilter from './HeaderFilter';
import ExportExcel from '../components/ExportExcel';
import ListStyles from './styles';
import GridView from '../components/GridView';

const ContactsList = () => {
  const { model } = useParams();

  const activeTab = model || 'board';

  const dispatch = useDispatch();

  const { search } = useLocation();

  const centreId = useSelector(getCentreId);

  const resourceFilter = getQueryParams(search);

  const retrieveList = (filter = {}, isRefresh = true) => {
    dispatch(
      getAllContacts({
        data: { orderBy: 'userFullName', ...filter },
        options: { isRefresh },
      }),
    );
  };

  useEffect(() => {
    retrieveList(resourceFilter);
  }, [centreId, search]); // eslint-disable-line

  return (
    <ListStyles>
      <PageTitle>{i18next.t('contacts.header')}</PageTitle>

      <HeaderFilter activeTab={activeTab} resourceFilter={resourceFilter} />
      {activeTab === 'board' ? (
        <GridView resourceFilter={resourceFilter} retrieveList={retrieveList} />
      ) : (
        <>
          <div className="flex justify-end mb-20">
            <ExportExcel />
          </div>
          <div className="container-contact">
            <TableView />
          </div>
        </>
      )}
    </ListStyles>
  );
};

export default ContactsList;

import { createAsyncThunk } from '@reduxjs/toolkit';
import { apiWrapper } from 'utils/reduxUtils';
import { makeActions } from 'redux/crudCreator/actions';
import {
  updateApplicationStatusApi,
  getApplicationPendingCountApi,
  getAllApplicationsNotPagingApi,
  getAllInformationRequestApi,
  createInformationRequestApi,
  editInformationRequestApi,
  delInformationRequestApi,
  getAllDocumentsApi,
  createDocumentApi,
  delDocumentApi,
  getAllActivitiesApi,
  createActivityApi,
  editActivityApi,
  doneTaskApi,
  updateApplicationArchiveStatusApi,
} from 'api/applications';
import {
  InformationRequestPayload,
  ApplicationPayload,
  ApplicationState,
  DocumentsPayload,
  ActivitiesAndTasksPayload,
  ArchiveApplicationPayload,
} from 'types/applications';
import { addNewMessageAfterCreateInfoRequest } from '../enquiries/actions';

import { convertDataApplications } from './dataProvider';
import { convertRequestParams } from '../crudCreator/dataProvider';

export const MODEL_NAME = 'applications';
export const applicationsActions = makeActions(MODEL_NAME);

export const getAllApplications = applicationsActions.getAll;
export const editApplications = applicationsActions.edit;
export const createApplications = applicationsActions.create;
export const getByIdApplications = applicationsActions.getDataById;
export const clearCurrentApplications = applicationsActions.clearCurrent;

export const getApplicationPendingCount = createAsyncThunk(
  'applications/getApplicationPendingCount',
  async (_, thunkAPI) => {
    try {
      const response = await apiWrapper({}, getApplicationPendingCountApi);
      if (response) {
        return response;
      }
      return thunkAPI.rejectWithValue(response);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  },
);

export const updateApplicationStatus = createAsyncThunk<
  unknown,
  ApplicationPayload
>('applications/updateApplicationStatus', async (payload = {}, thunkAPI) => {
  try {
    const { id, status } = payload;
    const response = await apiWrapper(
      {},
      updateApplicationStatusApi,
      id,
      status,
    );
    if (response) {
      return response;
    }
    return thunkAPI.rejectWithValue(response);
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});

export const getAllApplicationsNotPaging = createAsyncThunk<
  unknown,
  ApplicationPayload,
  { state: ApplicationState }
>(
  'applications/getAllApplicationsNotPaging',
  async (payload = {}, thunkAPI) => {
    try {
      const { options = {} } = payload;

      const { limit } = thunkAPI.getState().applications;

      const response = await apiWrapper({}, getAllApplicationsNotPagingApi);

      const { results } = convertDataApplications(response);

      if (results.data) {
        return {
          data: {
            numberOfPages: Math.ceil(results.total / limit),
            ...results,
          },
          options,
        };
      }
      return thunkAPI.rejectWithValue({ data: response, options });
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  },
);

export const getAllInformationRequests = createAsyncThunk<
  unknown,
  InformationRequestPayload,
  { state: ApplicationState }
>('applications/getAllInformationRequest', async (payload = {}, thunkApi) => {
  try {
    const { data = {}, options = {} } = payload;

    const { limit, offset, filter } =
      thunkApi.getState().applications.infoRequest?.resourceData || {};
    const { id, ...restData } = data;

    const convertRequest = convertRequestParams('GET_ALL', {
      limit,
      offset,
      filter,
      ...restData,
    });

    const response = await apiWrapper(
      {},
      getAllInformationRequestApi,
      id,
      convertRequest,
    );

    return {
      data: {
        numberOfPages: Math.ceil(response.total / limit),
        ...response,
      },
      options,
    };
  } catch (error) {
    return thunkApi.rejectWithValue({ id: payload.data.id, data: error });
  }
});

export const createInformationRequest = createAsyncThunk<
  unknown,
  InformationRequestPayload
>('applications/createInformationRequest', async (payload = {}, thunkApi) => {
  try {
    const { data, id } = payload;
    const response = await apiWrapper(
      { isShowSuccessNoti: true },
      createInformationRequestApi,
      id,
      data,
    );
    thunkApi.dispatch(addNewMessageAfterCreateInfoRequest(response.newMessage));

    const informationRequest = {
      ...response.informationRequest,
      message: {
        id: response.newMessage?.id,
        message: response.newMessage?.message,
      },
    };

    return {
      data: informationRequest,
      newMessage: response.newMessage,
      applicationDocuments: response.applicationDocuments || [],
    };
  } catch (error) {
    return thunkApi.rejectWithValue({ data: error, id: payload.id });
  }
});

export const editInformationRequest = createAsyncThunk<
  unknown,
  InformationRequestPayload
>('applications/editInformationRequest', async (payload = {}, thunkApi) => {
  try {
    const { data, id } = payload;

    const response = await apiWrapper(
      { isShowSuccessNoti: true },
      editInformationRequestApi,
      id,
      data,
    );
    return { data: response };
  } catch (error) {
    return thunkApi.rejectWithValue({ data: error, id: payload.id });
  }
});

export const delInformationRequest = createAsyncThunk<
  unknown,
  InformationRequestPayload
>('applications/delInformationRequest', async (payload = {}, thunkApi) => {
  try {
    const { id } = payload;

    await apiWrapper({ isShowSuccessNoti: true }, delInformationRequestApi, id);

    return { id };
  } catch (error) {
    return thunkApi.rejectWithValue({
      data: error,
      id: payload.id,
      messageId: payload.messageId,
    });
  }
});

export const getAllDocuments = createAsyncThunk<
  unknown,
  DocumentsPayload,
  { state: ApplicationState }
>('applications/getAllDocuments', async (payload = {}, thunkApi) => {
  try {
    const { data = {}, options = {} } = payload;

    const { limit, offset, filter } =
      thunkApi.getState().applications.documents?.resourceData || {};
    const { id, ...restData } = data;

    const convertRequest = convertRequestParams('GET_ALL', {
      limit,
      offset,
      filter,
      ...restData,
    });

    const response = await apiWrapper(
      {},
      getAllDocumentsApi,
      id,
      convertRequest,
    );

    return {
      data: {
        numberOfPages: Math.ceil(response.total / limit),
        ...response,
      },
      options,
    };
  } catch (error) {
    return thunkApi.rejectWithValue({ id: payload.data.id, data: error });
  }
});

export const createDocument = createAsyncThunk<unknown, DocumentsPayload>(
  'applications/createDocument',
  async (payload = {}, thunkApi) => {
    try {
      const { data, id } = payload;
      const response = await apiWrapper(
        { isShowSuccessNoti: true },
        createDocumentApi,
        id,
        data,
      );

      return { data: response };
    } catch (error) {
      return thunkApi.rejectWithValue({ data: error, id: payload.id });
    }
  },
);

export const delDocument = createAsyncThunk<unknown, { id?: string }>(
  'applications/delDocument',
  async (payload = {}, thunkApi) => {
    try {
      const { id } = payload;

      await apiWrapper({ isShowSuccessNoti: true }, delDocumentApi, id);

      return { id };
    } catch (error) {
      return thunkApi.rejectWithValue({
        data: error,
        id: payload.id,
      });
    }
  },
);

export const getAllActivities = createAsyncThunk<
  unknown,
  ActivitiesAndTasksPayload
>('applications/getAllActivities', async (payload = {}, thunkApi) => {
  try {
    const { data, id } = payload;
    const response = await apiWrapper({}, getAllActivitiesApi, id, {
      ...data,
      filter: JSON.stringify({ isTask: false }),
    });
    return { data: response };
  } catch (error) {
    return thunkApi.rejectWithValue({ data: error });
  }
});

export const createActivity = createAsyncThunk<
  unknown,
  ActivitiesAndTasksPayload
>('applications/createActivity', async (payload = {}, thunkApi) => {
  try {
    const { data, id } = payload;
    const response = await apiWrapper(
      { isShowSuccessNoti: true },
      createActivityApi,
      id,
      data,
    );
    return { data: response };
  } catch (error) {
    return thunkApi.rejectWithValue({ data: error });
  }
});

export const editActivity = createAsyncThunk<
  unknown,
  ActivitiesAndTasksPayload
>('applications/editActivity', async (payload = {}, thunkApi) => {
  try {
    const { data, id } = payload;
    const response = await apiWrapper(
      { isShowSuccessNoti: true },
      editActivityApi,
      id,
      data,
    );
    return { data: response };
  } catch (error) {
    return thunkApi.rejectWithValue({ data: error });
  }
});

export const getAllTasks = createAsyncThunk<unknown, ActivitiesAndTasksPayload>(
  'applications/getAllTasks',
  async (payload = {}, thunkApi) => {
    try {
      const { data, id } = payload;
      const response = await apiWrapper(
        { isShowSuccessNoti: false },
        getAllActivitiesApi,
        id,
        { ...data, filter: JSON.stringify({ isTask: true }) },
      );
      return { data: response };
    } catch (error) {
      return thunkApi.rejectWithValue({ data: error });
    }
  },
);

export const createTask = createAsyncThunk<unknown, ActivitiesAndTasksPayload>(
  'applications/createTask',
  async (payload = {}, thunkApi) => {
    try {
      const { data, id } = payload;
      const response = await apiWrapper(
        { isShowSuccessNoti: true },
        createActivityApi,
        id,
        { ...data, isTask: true },
      );
      return { data: response };
    } catch (error) {
      return thunkApi.rejectWithValue({ data: error });
    }
  },
);

export const editTask = createAsyncThunk<unknown, ActivitiesAndTasksPayload>(
  'applications/editTask',
  async (payload = {}, thunkApi) => {
    try {
      const { data, id } = payload;
      const response = await apiWrapper(
        { isShowSuccessNoti: true },
        editActivityApi,
        id,
        data,
      );
      return { data: response };
    } catch (error) {
      return thunkApi.rejectWithValue({ data: error });
    }
  },
);

export const doneTask = createAsyncThunk(
  'applications/doneTask',
  async (id, thunkApi) => {
    try {
      const response = await apiWrapper(
        { isShowSuccessNoti: true },
        doneTaskApi,
        id,
      );
      return { data: response };
    } catch (error) {
      return thunkApi.rejectWithValue({ data: error });
    }
  },
);

export const getLatestActivities = createAsyncThunk(
  'applications/getLatestActivities',
  async (id, thunkApi) => {
    try {
      const response = await apiWrapper({}, getAllActivitiesApi, id, {
        orderBy: '-createdAt',
        limit: 1,
        offset: 0,
        filter: JSON.stringify({ isTask: false }),
      });
      return { data: response };
    } catch (error) {
      return thunkApi.rejectWithValue({ data: error });
    }
  },
);

export const getLatestTask = createAsyncThunk(
  'applications/getLatestTask',
  async (id, thunkApi) => {
    try {
      const response = await apiWrapper({}, getAllActivitiesApi, id, {
        orderBy: '-createdAt',
        limit: 1,
        offset: 0,
        filter: JSON.stringify({ isTask: true }),
      });
      return { data: response };
    } catch (error) {
      return thunkApi.rejectWithValue({ data: error });
    }
  },
);

export const updateApplicationArchiveStatus = createAsyncThunk<
  any,
  ArchiveApplicationPayload
>('applications/updateApplicationArchiveStatus', async (payload, thunkApi) => {
  try {
    const response = await apiWrapper(
      { isShowSuccessNoti: true },
      updateApplicationArchiveStatusApi,
      payload?.id,
      payload?.body,
    );

    if (payload?.refreshList) {
      await thunkApi.dispatch(getAllApplicationsNotPaging({}));
    }
    return { data: response };
  } catch (error) {
    return thunkApi.rejectWithValue({ data: error, id: payload.id });
  }
});

import React, { useEffect } from 'react';
import { Button, Col, Row, Typography } from 'antd';
import i18next from 'i18next';
import { useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { getApplicationDeclinedReasons } from 'redux/config/selectors';

import {
  getJobApplicationProfile,
  updateJobApplicationStatus,
} from 'redux/jobs/jobApplications/actions';
import {
  updateApplicationStatus,
  updateTotalSubmittedJobApplication,
} from 'redux/jobs/jobApplications/slice';
import {
  getDataJobApplicationProfile,
  getErrorApplicationProfile,
  getLoadingJobApplicationProfile,
} from 'redux/jobs/jobApplications/selectors';
import useRouter from 'hooks/useRouter';
import { ContentNotAvailableIcon } from 'components/common/SVGIcon';
import JobApplicationInfo from '../JobApplicationInfo';
import JobApplicationTabs from '../JobApplicationTabs';
import JobListingCard from '../JobListingCard';

const JobApplicationDetail = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const summary = useSelector(getDataJobApplicationProfile) ?? {};
  const applicationDeclinedReasons = useSelector(getApplicationDeclinedReasons);
  const totalSubmitted = useSelector(
    (state) => state.jobApplications.totalSubmitted,
  );
  const error = useSelector(getErrorApplicationProfile);
  const loading = useSelector(getLoadingJobApplicationProfile);
  const { history } = useRouter();

  useEffect(() => {
    if (id) dispatch(getJobApplicationProfile({ id }));
  }, [id, dispatch]);

  const updateStatus = async ({ applicationId, status, reason }) => {
    if (status) {
      const params = {
        applicationId,
        statusId: status,
        declinedReasonId: reason,
      };

      Object.keys(params).forEach(
        (key) => params[key] === undefined && delete params[key],
      );

      const response = await dispatch(updateJobApplicationStatus(params));
      if (response?.payload?.affected) {
        if (summary.status.id === '1') {
          dispatch(
            updateTotalSubmittedJobApplication({ total: totalSubmitted - 1 }),
          );
        }
        dispatch(
          updateApplicationStatus({
            status,
            reason: applicationDeclinedReasons?.find(
              (item) => item?.id === reason,
            )?.reason,
          }),
        );
      }
    }
  };

  const handleViewAllJobApplications = () => {
    history.push('/job-applications/board');
  };

  const isNotAvailable = !loading && !!error;

  return (
    <div className="mt-32">
      {isNotAvailable ? (
        <div className={'flex-center w-full h-60vh flex-direction-column'}>
          <ContentNotAvailableIcon />
          <Typography.Text className="text-16">
            {i18next.t('error.contentIsNotAvailable')}
          </Typography.Text>
          <Button
            type="primary"
            htmlType="submit"
            onClick={handleViewAllJobApplications}
            className={'mt-24'}
          >
            {i18next.t('button.viewAllJobApplications')}
          </Button>
        </div>
      ) : (
        <Row gutter={24} className="row-customer-detail">
          <Col xl={6}>
            <Row gutter={24} className="mb-24">
              <Col span={24}>
                <JobApplicationInfo
                  currentData={summary}
                  updateStatus={updateStatus}
                />
              </Col>
              <Col span={24} className="mt-24 mb-8">
                <Typography.Text className="text-20 fw-bold">
                  {i18next.t('jobListings.title')}
                </Typography.Text>
                <JobListingCard record={summary} />
              </Col>
            </Row>
          </Col>
          <Col xl={18} className="w-full">
            <JobApplicationTabs />
          </Col>
        </Row>
      )}
    </div>
  );
};

export default JobApplicationDetail;

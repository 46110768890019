import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Avatar, Space } from 'antd';
import { PictureOutlined } from '@ant-design/icons';
import { getFeaturedImage } from 'utils/dataUtils';
import TagCustom from 'components/common/TagCustom';
import { BannerCentreItemStyles } from './styles';
import CoverImageRatio from '../CoverImageRatio';
import KindiRating from '../KindiRating';

const BannerCentreItem = ({ item, showLogo, logoSize = 60, fullWidth }) => {
  const tagFeature = useMemo(() => {
    if (item?.isFeatured) {
      return (
        <TagCustom
          color="#fff"
          backgroundColor="var(--primary)"
          title="centres.featured"
        />
      );
    }
    if (item?.isSpecialOffer) {
      return (
        <TagCustom
          color="#fff"
          backgroundColor="var(--primary)"
          title="centres.specialOffer"
        />
      );
    }
    return null;
  }, [item]);
  return (
    <BannerCentreItemStyles fullWidth={fullWidth}>
      <div className="centre-item__cover-wrapper">
        <CoverImageRatio
          fullWidth={fullWidth}
          src={item?.featuredImageUrl || getFeaturedImage(item?.images)}
        />

        <Space wrap className="centre-item__group-tag">
          <KindiRating
            className="block-btn centre-item__rating-btn"
            kindiCareRating={item?.kindiCareRating}
            kindiCareRatingImage={item?.kindiCareRatingImage}
          />
          {tagFeature}
        </Space>

        {item?.NQSRType?.image && (
          <img
            className="centre-item__NQSRType-img"
            alt="kindicare"
            src={item?.NQSRType?.image}
          />
        )}
        {showLogo && !!item?.brand?.logo && (
          <Avatar
            className="centre-item__logo"
            alt="kindicare"
            src={item?.brand?.logo}
            size={logoSize}
            icon={<PictureOutlined />}
          />
        )}
      </div>
    </BannerCentreItemStyles>
  );
};

BannerCentreItem.propTypes = {
  item: PropTypes.object,
  showLogo: PropTypes.bool,
  logoSize: PropTypes.number,
  fullWidth: PropTypes.bool,
};

export default BannerCentreItem;

import React, { useCallback } from 'react';
import i18next from 'i18next';
import { formatNightDayTable } from 'utils/formatFieldUtils';
import { getFilterFromUrl } from 'utils/tools';
import { useLocation } from 'react-router-dom';
import {
  DEFAULT_CURRENT_PAGE,
  LIMIT_ITEM,
} from 'containers/JobListings/constants';
import {
  SORT_ASCEND_VALUE,
  SORT_DESCEND_VALUE,
} from 'configs/localData/constant';
import TableCustom from 'components/common/TableCustom';
import { SortOrder } from 'antd/lib/table/interface';
import { Empty } from 'antd';
import iconEmpty from 'assets/images/shape-icon-empty.png';

import {
  LeadActivitiesPayload,
  LeadActivitiesResponse,
} from 'types/leadActivities';
import { isEqual } from 'lodash-es';
import { DEFAULT_SORT_LEAD_ACTIVITIES } from 'containers/LeadActivities/constants';
import useFormatFnc from './useFormat';
import { LeadActivitiesListStyle } from './styles';

interface Props {
  data: LeadActivitiesResponse;
  setPayload: (params: LeadActivitiesPayload) => void;
  pushQuery?: (params: LeadActivitiesPayload) => void;
  loading: boolean;
}

const LeadActivitiesTable = ({
  data,
  setPayload,
  pushQuery,
  loading,
}: Props) => {
  const { search } = useLocation();
  const useFormat = useFormatFnc();
  const { filter: query } = getFilterFromUrl(search);

  const getSorterOrder = useCallback(
    (source): SortOrder => {
      if (isEqual(query.sorts, [`${source}`])) {
        return SORT_ASCEND_VALUE;
      }
      if (isEqual(query.sorts, [`-${source}`])) {
        return SORT_DESCEND_VALUE;
      }
      return null;
    },
    [query],
  );

  const onChange = (e, _, sorter) => {
    const formatSort =
      sorter &&
      sorter.field &&
      sorter.order &&
      `${sorter.order === SORT_DESCEND_VALUE ? '-' : ''}${sorter.field}`;

    const restFilter: LeadActivitiesPayload = {
      size: e.pageSize || 10,
      page: e.current || 1,
      sorts: [formatSort || DEFAULT_SORT_LEAD_ACTIVITIES],
      filter: { ...query.filter },
    };

    pushQuery({
      ...restFilter,
      'sorts[]': restFilter.sorts,
    });
    setPayload({ ...restFilter });
  };

  const columns = [
    {
      title: i18next.t('leadActivities.table.header.userName'),
      dataIndex: 'userName',
      sorter: true,
      width: 300,
      defaultSortOrder: getSorterOrder('userName'),
      fixed: 'left',
      render: (_, record) => useFormat.formatUserName(record?.user, record),
    },
    {
      title: i18next.t('leadActivities.table.header.userPhone'),
      dataIndex: ['user', 'phoneNumber'],
      width: 180,
      defaultSortOrder: getSorterOrder('user.phone'),
      render: (data, record) => useFormat.formatPhoneNumber(data, record),
    },
    {
      title: i18next.t('leadActivities.table.header.clickThroughType'),
      dataIndex: 'clickThroughType',
      sorter: true,
      width: 250,
      defaultSortOrder: getSorterOrder('clickThroughType'),
      render: (_, record) =>
        useFormat.formatTagType(record?.clickThrough) || '-',
    },
    {
      title: i18next.t('leadActivities.table.header.centreName'),
      dataIndex: 'centreName',
      sorter: true,
      width: 300,
      defaultSortOrder: getSorterOrder('centreName'),
      render: (_, record) => useFormat.formatCentreName(record?.centre),
    },
    {
      title: i18next.t('leadActivities.table.header.providerName'),
      dataIndex: 'providerName',
      sorter: true,
      width: 300,
      defaultSortOrder: getSorterOrder('providerName'),
      render: (value) => useFormat.formatData(value),
    },
    {
      title: i18next.t('leadActivities.table.header.brand'),
      dataIndex: 'brandName',
      sorter: true,
      width: 250,
      defaultSortOrder: getSorterOrder('brandName'),
      render: (value) => useFormat.formatData(value),
    },
    {
      title: i18next.t('leadActivities.table.header.subscription'),
      dataIndex: 'subscriptionRank',
      sorter: true,
      width: 230,
      defaultSortOrder: getSorterOrder('subscriptionRank'),
      render: (_, record) =>
        useFormat.formatTagType(record?.subscription) || '-',
    },
    {
      title: i18next.t('leadActivities.table.header.clickThroughDate'),
      dataIndex: 'clickThroughDate',
      sorter: true,
      width: 250,
      defaultSortOrder: getSorterOrder('clickThroughDate'),
      render: (_, record) =>
        formatNightDayTable(record?.clickThrough?.date) || '-',
    },
    {
      title: '',
      dataIndex: 'id',
      width: 80,
      fixed: 'right',
      render: (_, record) => useFormat.formatGroupAction(record),
    },
  ];

  return (
    <LeadActivitiesListStyle>
      <TableCustom
        columns={columns}
        data={data?.results ?? []}
        xScroll={2000}
        yScroll={600}
        onChange={onChange}
        className="table-wrapper"
        pagination={{
          pageSize: data?.paging?.size || LIMIT_ITEM,
          current: data?.paging?.page || DEFAULT_CURRENT_PAGE,
          total: data?.paging?.total,
        }}
        isSorter={false}
        isSetRowKeyId
        isResetStyle={false}
        loading={loading}
        locale={{
          emptyText: (
            <Empty
              image={iconEmpty}
              imageStyle={{ height: 160 }}
              className="text-gray-500 mt-70 mb-48"
              description={i18next.t('error.noDataFound')}
            />
          ),
        }}
      />
    </LeadActivitiesListStyle>
  );
};

export default LeadActivitiesTable;

import { getNotificationCountApi } from 'api/notifications';
import { putApi } from 'api/crud';
import { apiWrapper } from 'utils/reduxUtils';
import { createAsyncThunk, createAction } from '@reduxjs/toolkit';
import { makeActions } from 'redux/crudCreator';

export const updateNotifications = createAction('updateNotifications');
export const notificationsActions = makeActions('notifications');

export const getNotificationCount = createAsyncThunk(
  'notifications/getNotificationCount',
  async (payload, thunkAPI) => {
    try {
      const response = await apiWrapper(null, getNotificationCountApi);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue();
    }
  },
);

export const seenNotification = createAsyncThunk(
  'notifications/seenNotification',
  async (payload, thunkAPI) => {
    try {
      const response = await apiWrapper(
        { isShowProgress: false, isShowSuccessNoti: false },
        putApi,
        'notifications',
        payload.id,
        { isSeen: payload.isSeen },
      );
      thunkAPI.dispatch(getNotificationCount());
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue();
    }
  },
);

import React from 'react';
import PropTypes from 'prop-types';
import i18next from 'i18next';
import { Avatar, Space } from 'antd';
import { KEYS_DAYS_IN_WEEK } from 'configs/localData';
import { RequiredDaysStyles } from './styles';

const RequiredDays = ({ requiredDays }) => {
  const getClassActive = (value) =>
    requiredDays.includes(value) ? 'avatar-active' : 'avatar-disabled';

  return (
    <RequiredDaysStyles>
      <Space size={6} align="centre">
        {KEYS_DAYS_IN_WEEK.map((item, index) => (
          <Avatar
            key={String(index)}
            className={`day-item ${getClassActive(item.value)}`}
          >
            {i18next.t(item.text)}
          </Avatar>
        ))}
      </Space>
    </RequiredDaysStyles>
  );
};

RequiredDays.propTypes = {
  requiredDays: PropTypes.array,
};

RequiredDays.defaultProps = {
  requiredDays: [],
};

export default RequiredDays;

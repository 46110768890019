import React, { useState } from 'react';
import CardSection from 'components/common/CardSection';
import { isEmpty } from 'lodash-es';
import { NoDataIcon } from 'components/common/SVGIcon';
import i18next from 'i18next';
import renderHTML from 'react-render-html';
import { VirtualTourStyles } from 'containers/Centres/components/CentreTabs/SummaryTab/styles';
import useRolesCompanyProfile from 'containers/CompanyProfile/hooks/useRolesCompanyProfile';
import { getCompanyVirtualTourSelector } from 'redux/jobs/companyProfile/selectors';
import VirtualTourModal, {
  ResponseData,
} from 'containers/Centres/components/Modals/VirtualTourModal';
import { useAppSelector } from 'redux/hooks';

const CompanyVirtualTour = () => {
  const disabledAction = useRolesCompanyProfile();
  const [visibleModal, setVisibleModal] = useState(false);
  const companyVirtualTourData: ResponseData[] = useAppSelector(
    getCompanyVirtualTourSelector,
  );

  const onClickButton = () => {
    setVisibleModal(true);
  };

  const onCancel = () => {
    setVisibleModal(false);
  };

  return (
    <>
      <CardSection
        isEdit
        onClickButton={onClickButton}
        title="jobs.companyProfile.companyVirtualTour"
        action="isEdit"
        disabled={disabledAction}
      >
        <div>
          {!isEmpty(companyVirtualTourData) ? (
            companyVirtualTourData?.map((item, index) => (
              <VirtualTourStyles className="flex-center mb-12">
                <div key={index}>{renderHTML(item?.value)}</div>
              </VirtualTourStyles>
            ))
          ) : (
            <div className="flex-col flex-center">
              <NoDataIcon />
              <div>{i18next.t('centreDetail.emptyVirtualTour')}</div>
            </div>
          )}
        </div>
      </CardSection>
      {companyVirtualTourData && (
        <VirtualTourModal
          onCancel={onCancel}
          visible={visibleModal}
          record={{
            data: companyVirtualTourData,
          }}
          isCompanyVirtualTour
        />
      )}
    </>
  );
};

export default CompanyVirtualTour;

import React, { useCallback, useRef, useState } from 'react';
import PageTitle from 'components/common/PageTitle';
import CreateProgramButton from 'containers/ProgramsAndLearning/components/CreateProgramButton';
import { InfoFilledIcon } from 'components/common/SVGIcon';
import { useHistory, useLocation } from 'react-router';
import { convertSearchUrl, getFilterFromUrl } from 'utils/tools';
import {
  useProgramPermissionControllerGetProgramPermissionQuery,
  ProgramsControllerGetProgramListApiArg,
  useProgramsControllerGetProgramListQuery,
} from 'generated/apis';
import { isEmpty, omit } from 'lodash-es';
import { Spin } from 'antd';
import { Waypoint } from 'react-waypoint';
import NoTemplates from 'containers/JobTemplates/components/NoTemplates';
import { DEFAULT_CURRENT_PAGE } from 'configs/localData/constant';
import { useTranslation } from 'react-i18next';
import Filter from './components/Filter';
import { ProgramsLearningInfoStyles } from './styles';
import { PROGRAMS_AND_LEARNING_LIMIT } from './constants';
import ProgramsAndLearningBoard from './components/ProgramsLearningBoard';

const ProgramsAndLearningContainer = () => {
  const { push } = useHistory();
  const { search } = useLocation();
  const { filter: query } = getFilterFromUrl(search);
  const { t } = useTranslation();

  const pageRef = useRef(DEFAULT_CURRENT_PAGE);

  const [payload, setPayload] =
    useState<ProgramsControllerGetProgramListApiArg>({
      ...query,
      page: query?.page || DEFAULT_CURRENT_PAGE,
      size: PROGRAMS_AND_LEARNING_LIMIT,
      createdAt: query?.createdAt,
      updatedAt: query?.updatedAt,
    });

  const { data, isLoading, isFetching } =
    useProgramsControllerGetProgramListQuery({
      ...payload,
      ...query,
      size: PROGRAMS_AND_LEARNING_LIMIT,
      page: pageRef.current || DEFAULT_CURRENT_PAGE,
    });

  const { data: permission } =
    useProgramPermissionControllerGetProgramPermissionQuery();

  const resetPage = () => {
    pageRef.current = DEFAULT_CURRENT_PAGE;
  };

  const enableLoadMore =
    !isLoading && !isFetching && data?.results?.length < data?.paging?.total;

  const handleEnterWaypoint = useCallback(() => {
    const filterParams = {
      ...query,
      createdAt: query?.createdAt && {
        ...query?.createdAt,
      },
      updatedAt: query?.updatedAt && {
        ...query?.updatedAt,
      },
      page: (pageRef.current += 1),
    };

    setPayload({ ...filterParams });
    push({
      search: `?${convertSearchUrl({
        ...omit(filterParams, ['page']),
      })}`,
    });
  }, [push, query]);

  return (
    <>
      <div className="flex flex-row">
        <PageTitle>{t('programLearning.header')}</PageTitle>
        <CreateProgramButton isCreate={permission?.result?.isCreate} />
      </div>
      <Filter resetPage={resetPage} />
      <ProgramsLearningInfoStyles className="rounded-8 mb-24 fw-500 flex items-center">
        <span className="text-blue-info-default text-20">
          <InfoFilledIcon />
        </span>
        <span className="ml-10 text-16 text-black-base">
          {t('programLearning.programLearningInfo')}
        </span>
      </ProgramsLearningInfoStyles>
      {!isLoading && !isFetching && isEmpty(data?.results) ? (
        <div className="flex-center flex-direction-column">
          <NoTemplates
            isShowCreateButton={false}
            title="programLearning.noPrograms"
            description="programLearning.noProgramsDescription"
          />
        </div>
      ) : (
        <div>
          <ProgramsAndLearningBoard
            isEdit={permission?.result?.isEdit}
            data={data}
          />
        </div>
      )}
      {isFetching && (
        <div className="flex-center mt-12">
          <Spin />
        </div>
      )}
      {enableLoadMore && <Waypoint onEnter={handleEnterWaypoint} />}
    </>
  );
};

export default ProgramsAndLearningContainer;

import React from 'react';
import { Menu, Dropdown } from 'antd';
import { DashOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';

import GroupActionsStyles, { OverlayStyles } from './styles';
import DeleteDocument from '../DeleteDocument';
import DownloadButton from './DownloadButton';
import OpenFileButton from './OpenFileButton';

const GroupActions = ({ id, file }) => {
  const menu = (
    <OverlayStyles>
      <Menu.Item key="0">
        <OpenFileButton file={file} />
      </Menu.Item>
      <Menu.Item key="1">
        <DownloadButton file={file} />
      </Menu.Item>
      <Menu.Item key="3">
        <DeleteDocument id={id} />
      </Menu.Item>
    </OverlayStyles>
  );

  return (
    <GroupActionsStyles className="group-actions">
      <Dropdown overlay={menu} trigger={['click']}>
        <DashOutlined onClick={(e) => e.preventDefault()} />
      </Dropdown>
    </GroupActionsStyles>
  );
};

GroupActions.propTypes = {
  id: PropTypes.string,
  file: PropTypes.object,
};

export default GroupActions;

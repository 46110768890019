import React from 'react';
import PropTypes from 'prop-types';
import { Space, Button } from 'antd';
import i18next from 'i18next';
import { ALPHABET } from 'configs/localData';
import { useHistory } from 'react-router';
import { convertSearchUrl } from 'utils/tools';

import AlphabetBarStyles from './styles';

const AlphabetBar = ({ resourceFilter }) => {
  const { push } = useHistory();

  const selectedValue = resourceFilter?.outsideFilter?.beginLetter || null;

  const onSelected = (value) => {
    if (value !== selectedValue) {
      push({
        search: `?${convertSearchUrl({
          ...resourceFilter,
          offset: 0,
          outsideFilter: {
            ...resourceFilter.outsideFilter,
            beginLetter: value,
          },
        })}`,
      });
    }
  };

  return (
    <AlphabetBarStyles>
      <Space wrap>
        <Button
          type={selectedValue ? 'default' : 'primary'}
          onClick={() => onSelected(null)}
        >
          {i18next.t('common.all')}
        </Button>
        {ALPHABET.map((value) => (
          <Button
            key={value}
            type={value === selectedValue ? 'primary' : 'default'}
            onClick={() => onSelected(value)}
          >
            {value}
          </Button>
        ))}
      </Space>
    </AlphabetBarStyles>
  );
};

AlphabetBar.propTypes = {
  resourceFilter: PropTypes.object,
};

export default AlphabetBar;

import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;

  .text {
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: ${({ theme }) => theme.text.blackNeutral800};
    width: 100%;
  }

  .ant-typography-expand {
    display: none;
  }

  .ant-typography {
    margin: 0;
  }
`;

import React from 'react';
import PropTypes from 'prop-types';

const Dashed = ({ color }) => (
  <svg
    width="32"
    height="3"
    viewBox="0 0 32 3"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <line
      y1="1.5"
      x2="32"
      y2="1.5"
      stroke={color}
      strokeWidth="3"
      strokeDasharray="4 4"
    />
  </svg>
);

Dashed.propTypes = {
  color: PropTypes.string,
};

export default Dashed;

import React, {
  useCallback,
  useContext,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Form, Modal, notification, Upload } from 'antd';
import i18next from 'i18next';
import { useDispatch } from 'react-redux';
import { last, omit, remove, takeRight } from 'lodash-es';
import { getUrl, uploadMedia } from 'api/uploadMedia';
import { logoutSuccess } from 'redux/auth/slice';
import { getRecordData } from 'utils/tools';
import { UploadIcon, WarningIcon } from 'components/common/SVGIcon';
import { RestInputContext } from 'components/RestInput/RestInputContext';
import { resizeImageFile } from 'utils/fileUtils';
import { Rule } from 'rc-field-form/lib/interface';
import { UploadRequestOption as RcCustomRequestOptions } from 'rc-upload/lib/interface';
import UploadImageItem from 'components/RestInput/RestUploadFile/UploadImageItem';
import { FormPhotosStyles } from 'components/RestInput/RestUploadFile/styles';
import { UploadFile } from 'antd/lib/upload/interface';
import SortableGrid from 'components/RestInput/RestUploadFile/SortableGrid';
import {
  formatNewFileListWithAttachVideoUrl,
  formatNewFileListWithFeature,
  formatYoutubeFile,
  makeFileList,
} from 'components/RestInput/RestUploadFile/utils';
import ModalPreview from './ModalPreview';
import URLVideoForm from './URLVideoForm';

interface RecordType {
  [key: string]: string | { type?: string; url?: string }[];
}
export interface UploadFileCustom extends UploadFile {
  featured?: boolean;
  value?: string;
  source?: string;
  key?: string;
}

export interface ResultFile {
  key?: string;
  url?: string;
  type?: string;
  featured?: boolean;
  value?: string;
  source?: string;
}

interface Props {
  record?: RecordType;
  source?: string;
  setIsDisabled?: (isDisabled?: boolean) => void;
  multiple?: boolean;
  accept?: string;
  label?: string;
  placeholder?: string;
  youtubeLinkLabel?: string;
  youtubeLinkPlaceholder?: string;
  formatResult?: (results: UploadFileCustom | UploadFileCustom[]) => void;
  onlyShowImg?: boolean;
  required?: boolean;
  rules?: Rule[];
  isSetFeature?: boolean;
  isAttachURLVideo?: boolean;
  isDrapDrop?: boolean;
  prefixKey?: string;
  itemScale?: number;
  isNoneBorderRadius?: boolean;
  isDisableLabel?: boolean;
  maxCount?: number;
  isPreSignUrl?: boolean;
  limitVideoNumbers?: number;
  isShowConfirm?: boolean;
  replaceConfirmMessage?: string;
  getCustomPreSignedUrl?: (fileName?: string) => Promise<void>;
}

interface ModalRef {
  toggleModal: (url: string) => void;
}

const RestUploadFile = ({
  record,
  source,
  setIsDisabled,
  multiple = true,
  accept = 'image/*',
  label = 'text.uploadImage',
  placeholder,
  youtubeLinkLabel,
  youtubeLinkPlaceholder,
  formatResult,
  onlyShowImg,
  required,
  rules = [],
  isSetFeature,
  isAttachURLVideo,
  isDrapDrop,
  prefixKey,
  itemScale,
  isNoneBorderRadius,
  isDisableLabel = false,
  maxCount,
  isPreSignUrl,
  limitVideoNumbers = 0,
  isShowConfirm,
  replaceConfirmMessage,
  getCustomPreSignedUrl,
}: Props) => {
  const dispatch = useDispatch();

  const { form } = useContext(RestInputContext);

  const modalPreviewRef = useRef<ModalRef>();

  const initialValue = useMemo(
    () => getRecordData(record, source),
    [record, source],
  );

  const [fileList, setFileList] = useState<UploadFileCustom[]>(
    makeFileList(initialValue) || [],
  );
  const [replacingFileList, setReplacingFileList] = useState([]);

  const customRequest = async ({
    onSuccess,
    onError,
    file,
  }: RcCustomRequestOptions) => {
    const compressedFile = await resizeImageFile(file);

    if (compressedFile) {
      try {
        const responseS3 =
          isPreSignUrl && getCustomPreSignedUrl
            ? await getCustomPreSignedUrl(compressedFile.name)
            : await getUrl(
                `${prefixKey || ''}${compressedFile.name}`,
                compressedFile.type,
              );

        const response = await uploadMedia(
          responseS3.uploadUrl,
          compressedFile,
        );
        if (response?.status === 200) {
          onSuccess(responseS3.url, compressedFile);
        } else {
          onError(null, { status: 'done' });
        }
      } catch (error) {
        onError(null, { status: 'done' });
        if (error.code === 401) {
          dispatch(logoutSuccess());
          notification.error({
            message: i18next.t('error.title'),
            description: i18next.t('error.error401'),
            duration: 2,
            placement: 'topRight',
          });
        } else
          notification.error({
            message: i18next.t('error.title'),
            description: i18next.t('error.description'),
            placement: 'topRight',
            duration: 2,
          });
      }
    } else onError(null, { status: 'done' });
  };

  const onCustomReplacingFile = (url, file, status) => {
    const _updatedList = [];
    setFileList((prev) =>
      prev.map((v) => {
        let value = v;
        if (v.uid === file.uid) {
          value = Object.assign(file, {
            response: url,
            status: status ?? 'done',
          });
        }
        _updatedList.push(value);
        return value;
      }),
    );
    form.setFieldsValue({
      [source]: multiple
        ? formatMultiFileResult(_updatedList)
        : formatValueFile(_updatedList),
    });
  };

  const getFileTypes = (files: UploadFileCustom[]): string => {
    let containsImages = false;
    let containsVideos = false;
    const isMultiple = files.length > 1;

    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      if (file.type.includes('image')) {
        containsImages = true;
      } else {
        containsVideos = true;
      }

      if (containsImages && containsVideos) {
        break;
      }
    }

    if (containsImages && !containsVideos) {
      return isMultiple ? 'Images' : 'Image';
    }
    if (!containsImages && containsVideos) {
      return isMultiple ? 'Videos' : 'Video';
    }
    if (containsImages && containsVideos) {
      return 'Images & Videos';
    }
    return '';
  };

  const onReplaceConfirm = (currentFileList) => {
    Modal.destroyAll();
    const fileType = getFileTypes(currentFileList);

    Modal.confirm({
      title: i18next.t('popup.replaceFileTitle', {
        fileType,
      }),
      content: replaceConfirmMessage,
      okText: i18next.t('button.replace'),
      icon: <WarningIcon />,
      wrapClassName: 'upload-file-wrap',
      onOk: async () => {
        let _index = 0;
        const _list = currentFileList.reverse().map((v) => {
          if (_index < replacingFileList.length) {
            const replaceFile = replacingFileList[_index];
            const currentType = v.type.includes('image') ? 'image' : 'video';
            if (replaceFile?.type.includes(currentType)) {
              _index += 1;
              return Object.assign(replaceFile, {
                status: 'uploading',
              });
            }
          }
          return v;
        });
        setFileList(_list.reverse());
        await Promise.all(
          replacingFileList.map((file) => {
            const onSuccess = (url, _uploadedFile) =>
              onCustomReplacingFile(url, _uploadedFile, 'done');
            const onError = (url) => onCustomReplacingFile(url, file, 'error');
            return customRequest({
              onSuccess,
              onError,
              file,
              action: '',
              method: 'POST',
            });
          }),
        );
        setReplacingFileList([]);
      },
      onCancel: () => setReplacingFileList([]),
    });
  };

  const onChangeUpload = (e) => {
    if (
      isShowConfirm &&
      form.getFieldValue(source).length >= maxCount &&
      replacingFileList.length > 0
    ) {
      const uploadingImagesLength = [...fileList, e.file].filter((v) =>
        v?.type?.includes('image'),
      ).length;
      const limitImageCount = maxCount - limitVideoNumbers;

      const enableReplaceCurrentImage =
        e.file.type.includes('image') &&
        uploadingImagesLength >= limitImageCount;

      if (enableReplaceCurrentImage) {
        onReplaceConfirm(fileList);
      } else {
        onReplaceConfirm(e.fileList);
      }
    }

    const _list: UploadFileCustom[] = e.fileList.map((v, index) => {
      if (fileList[index]) {
        return Object.assign(
          omit(fileList[index], ['lastModifiedDate', 'lastModified']),
          {
            ...v,
          },
        );
      }
      return v;
    });
    const _customFileList = _list.filter(
      (v) => v.status === 'uploading' || v.status === 'done',
    );
    isDisableButtonSubmit(_customFileList);
    if (multiple) {
      setFileList(_customFileList);
    } else {
      setFileList(takeRight(_customFileList));
    }
    const _checkFileType = e.fileList.every(
      (v) => v.type?.includes('image') || v.type?.includes('video'),
    );

    if (_checkFileType && accept !== '*') {
      form.setFieldsValue({
        [source]: multiple
          ? formatMultiFileResult(_customFileList)
          : formatValueFile(_customFileList),
      });
    }
  };

  const formatMultiFileResult = useCallback(
    (results) =>
      results?.map((data) => {
        const item: ResultFile = {
          key: data.name || data.key,
          url: data.response || data.url,
          type: data.type,
        };
        if (isSetFeature) item.featured = data.featured;
        if (isAttachURLVideo) {
          item.source = data.source;
        }
        return item;
      }),
    [isSetFeature, isAttachURLVideo],
  );

  const formatValueFile = useCallback(
    (results: UploadFileCustom[]) => {
      if (multiple) {
        return formatResult
          ? formatResult(results)
          : formatMultiFileResult(results);
      }

      const newFile = last(results);
      if (newFile) {
        return formatResult
          ? formatResult(newFile)
          : {
              key: newFile.name || newFile.key,
              url: newFile.response || newFile.url,
              type: newFile.type,
            };
      }
      return {};
    },
    [formatResult, multiple, formatMultiFileResult],
  );

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return formatValueFile(e);
    }
    return formatValueFile(e?.fileList);
  };

  const isDisableButtonSubmit = (newFileList) => {
    let isDisabled =
      newFileList &&
      newFileList.length >= 0 &&
      newFileList?.filter((i) => {
        if (i.url !== undefined) return false;
        if (multiple && i.response) return i.status === 'error';
        return i.response === undefined;
      }).length > 0;

    if (!multiple && !isDisabled)
      isDisabled = newFileList[newFileList.length - 1]?.status === 'error';

    !!setIsDisabled && setIsDisabled(isDisabled);
  };

  const deleteImage = (index) => {
    const newList = remove(fileList, (obj, idx) => idx !== index);
    setFileList(newList);
    form.setFieldsValue({ [source]: formatValueFile(newList) });
  };

  const handleDelete = (index) => {
    if (isShowConfirm) {
      const isVideoFile = form
        .getFieldValue(source)
        [index]?.type?.includes('video');
      Modal.confirm({
        title: i18next.t('popup.removeFileTitle', {
          fileType: isVideoFile ? 'Video' : 'Image',
        }),
        content: i18next.t('popup.removeFileConfirm', {
          fileType: isVideoFile ? 'video' : 'image',
        }),
        onOk: () => {
          deleteImage(index);
        },
        okText: i18next.t('button.remove'),
        icon: <WarningIcon />,
        wrapClassName: 'upload-file-wrap',
      });
    } else {
      deleteImage(index);
    }
  };

  const onSetFeature = useCallback(
    (index) => {
      const newFileListObj = formatNewFileListWithFeature(fileList, index);
      setFileList(newFileListObj.fileList);
      form.setFieldsValue({
        [source]: newFileListObj.fileListFormValue,
      });
    },
    [source, fileList, form],
  );

  const onPreviewImage = (url) => {
    modalPreviewRef.current && modalPreviewRef.current.toggleModal(url);
  };

  const handleFileListWithUrl = (url, fileList) => {
    const newFileListObj = formatNewFileListWithAttachVideoUrl(fileList, url);
    setFileList(newFileListObj.fileList);

    form.setFieldsValue({
      [source]: newFileListObj.fileListFormValue,
    });
  };

  const addURLVideo = (url) => {
    const numberOfVideos = form
      .getFieldValue(source)
      .filter((v) => v?.type?.includes('video')).length;

    if (
      isShowConfirm &&
      limitVideoNumbers &&
      numberOfVideos >= limitVideoNumbers
    ) {
      Modal.confirm({
        title: i18next.t('popup.replaceFileTitle', {
          fileType: 'Video',
        }),
        content: i18next.t('popup.replaceFileConfirm', {
          fileType: 'video',
        }),
        okText: i18next.t('button.replace'),
        icon: <WarningIcon />,
        onOk: () => {
          const lastVideoIndex = form
            .getFieldValue(source)
            .findLastIndex((v) => v.type.includes('video'));
          fileList[lastVideoIndex] = formatYoutubeFile(url);
          setFileList(fileList);
          form.setFieldsValue({
            [source]: formatValueFile(fileList),
          });
        },
        wrapClassName: 'upload-file-wrap',
      });
    } else {
      handleFileListWithUrl(url, fileList);
    }
  };

  const onChangeSortable = (newList) => {
    setFileList(newList);
    form.setFieldsValue({ [source]: formatValueFile(newList) });
  };

  const enableFileToUpload = (file, _fileList, type, limitNumber) => {
    const list = [...fileList, ..._fileList].filter((v) =>
      v.type.includes(type),
    );
    if (list.length > limitNumber) {
      const index = list.findIndex((v) => v.uid === file.uid);
      return index < limitNumber;
    }
    return true;
  };

  const handleBeforeUpload = async (file, _fileList) => {
    if (maxCount) {
      const limitImageCount = maxCount - limitVideoNumbers;
      const checkVideoList = enableFileToUpload(
        file,
        _fileList,
        'video',
        limitVideoNumbers,
      );

      const checkImageList = enableFileToUpload(
        file,
        _fileList,
        'image',
        limitImageCount,
      );

      const uploadingImagesLength = [...fileList, file].filter((v) =>
        v?.type?.includes('image'),
      ).length;

      const checkImage =
        file.type.includes('image') && uploadingImagesLength > limitImageCount;

      if (
        isShowConfirm &&
        (form.getFieldValue(source).length >= maxCount || checkImage)
      ) {
        setReplacingFileList((prev) => [...prev, file]);
        return false;
      }
      if (
        limitVideoNumbers > 0 &&
        limitVideoNumbers !== maxCount &&
        !checkVideoList
      ) {
        return false;
      }
      if (!checkImageList) {
        return false;
      }
    }
    return true;
  };

  return (
    <FormPhotosStyles>
      <Form.Item
        className="form-item-upload-image"
        label={isDisableLabel ? null : i18next.t(label)}
        name={source}
        initialValue={initialValue}
        getValueFromEvent={normFile}
        rules={[
          { required, message: i18next.t('error.requiredFile') },
          ...rules,
        ]}
      >
        <Upload.Dragger
          multiple={multiple}
          accept={accept}
          showUploadList={false}
          name="files"
          fileList={fileList}
          beforeUpload={handleBeforeUpload}
          customRequest={customRequest}
          onChange={onChangeUpload}
          maxCount={maxCount}
          data-testid="upload-dragger"
        >
          <>
            <p className="ant-upload-drag-icon">
              <UploadIcon />
            </p>
            {placeholder ? (
              <p className="text-14 text-main-l3 p-16 whitespace-pre-line">
                {placeholder}
              </p>
            ) : (
              <p className="ant-upload-text">{i18next.t(label)}</p>
            )}
          </>
        </Upload.Dragger>
      </Form.Item>

      {isAttachURLVideo && (
        <URLVideoForm
          form={form}
          addURLVideo={addURLVideo}
          label={youtubeLinkLabel}
          placeholder={youtubeLinkPlaceholder}
        />
      )}

      {isDrapDrop ? (
        <SortableGrid
          fileList={fileList}
          onChangeSortable={onChangeSortable}
          imageItemProps={{
            deleteImage: handleDelete,
            onlyShowImg,
            isSetFeature,
            onSetFeature,
            onPreviewImage,
            isAttachURLVideo,
            itemScale,
            isNoneBorderRadius,
          }}
        />
      ) : (
        <div className="file-list-view">
          {fileList?.map((file, index) => (
            <UploadImageItem
              key={String(index)}
              item={file}
              deleteImage={handleDelete}
              index={index}
              onlyShowImg={onlyShowImg}
              isSetFeature={isSetFeature}
              onSetFeature={onSetFeature}
              onPreviewImage={onPreviewImage}
              isAttachURLVideo={isAttachURLVideo}
              itemScale={itemScale}
              isNoneBorderRadius={isNoneBorderRadius}
            />
          ))}
        </div>
      )}
      <ModalPreview ref={modalPreviewRef} />
    </FormPhotosStyles>
  );
};

export default RestUploadFile;

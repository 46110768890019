import { makeCRUDSlice } from 'redux/crudCreator';
import { INITIAL_STATE_EXTRA } from 'redux/crudCreator/slice';
import {
  MODEL_NAME,
  contactsActions,
  getSummaryDetailContact,
  getAllApplicationsOfContact,
  getAllEnquiriesOfContact,
} from './actions';

const getSummaryDetailContactPending = (state) => {
  state.summaryDetail = {};
};

const getSummaryDetailContactSuccess = (state, { payload }) => {
  state.summaryDetail = payload;
};

const getAllApplicationOfContactPending = (
  state,
  {
    meta: {
      arg: { data },
    },
  },
) => {
  state.applications = {
    resourceData: {
      ...INITIAL_STATE_EXTRA.resourceData,
      ...data,
    },
    data: [],
    loading: true,
  };
};

const getAllApplicationOfContactSuccess = (state, { payload: { data } }) => {
  const applicationData = state.applications.data;

  state.applications.data = [...applicationData, ...data?.results];

  state.applications.resourceData.total = data?.total || 0;
  state.applications.resourceData.numberOfPages = data?.numberOfPages;

  state.applications.loading = false;
  state.error = null;
};

const getAllApplicationOfContactFail = (state, { payload }) => {
  state.error = payload?.data;
  state.applications.loading = false;
};

const getAllEnquiriesOfContactPending = (
  state,
  {
    meta: {
      arg: { data },
    },
  },
) => {
  state.enquiries = {
    resourceData: {
      ...INITIAL_STATE_EXTRA.resourceData,
      ...data,
    },
    data: [],
    loading: true,
  };
};

const getAllEnquiriesOfContactSuccess = (state, { payload: { data } }) => {
  const enquiriesData = state.enquiries.data;

  state.enquiries.data = [...enquiriesData, ...data?.results];

  state.enquiries.resourceData.total = data?.total || 0;
  state.enquiries.resourceData.numberOfPages = data?.numberOfPages;

  state.enquiries.loading = false;
  state.error = null;
};

const getAllEnquiriesOfContactFail = (state, { payload }) => {
  state.error = payload?.data;
  state.enquiries.loading = false;
};

const slice = makeCRUDSlice(MODEL_NAME, contactsActions, {
  [getSummaryDetailContact.pending]: getSummaryDetailContactPending,
  [getSummaryDetailContact.fulfilled]: getSummaryDetailContactSuccess,

  [getAllApplicationsOfContact.pending]: getAllApplicationOfContactPending,
  [getAllApplicationsOfContact.fulfilled]: getAllApplicationOfContactSuccess,
  [getAllApplicationsOfContact.rejected]: getAllApplicationOfContactFail,

  [getAllEnquiriesOfContact.pending]: getAllEnquiriesOfContactPending,
  [getAllEnquiriesOfContact.fulfilled]: getAllEnquiriesOfContactSuccess,
  [getAllEnquiriesOfContact.rejected]: getAllEnquiriesOfContactFail,
});

export default slice.reducer;

import {
  IMPERSONATE_SESSION_KEY,
  REFRESH_TOKEN_KEY,
  SESSION_TOKEN_KEY,
} from 'configs/localData/constant';

export const getSessionToken = () => {
  if (localStorage) {
    return localStorage.getItem(SESSION_TOKEN_KEY);
  }
};

export const setSessionToken = (accessToken: string) => {
  if (localStorage) {
    localStorage.setItem(SESSION_TOKEN_KEY, accessToken);
  }
};

export const clearSessionToken = () => {
  if (localStorage) {
    localStorage.removeItem(SESSION_TOKEN_KEY);
  }
};

export const getRefreshToken = () => {
  if (localStorage) {
    return localStorage.getItem(REFRESH_TOKEN_KEY);
  }
};

export const setRefreshToken = (refreshToken: string) => {
  if (localStorage) {
    localStorage.setItem(REFRESH_TOKEN_KEY, refreshToken);
  }
};

export const clearRefreshToken = () => {
  if (localStorage) {
    localStorage.removeItem(REFRESH_TOKEN_KEY);
  }
};

export const getImpersonateSessionToken = () => {
  if (sessionStorage) {
    return sessionStorage.getItem(IMPERSONATE_SESSION_KEY);
  }
};

import React, { useMemo, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import i18next from 'i18next';
import TableCustom from 'components/common/TableCustom';
import { formatNightDayTable } from 'utils/formatFieldUtils';
import EditButton from 'components/RestActions/EditButton';
import { useHistory, useLocation } from 'react-router';
import CreateCampaignModal from 'containers/JobListings/components/Modals/CreateCampaignModal';
import { Space } from 'antd';
import {
  DEFAULT_CURRENT_PAGE,
  DEFAULT_SORT_CAMPAIGN,
  DEFAULT_SORT_INDIVIDUAL,
  LIMIT_ITEM,
  OTHER_VALUE,
} from 'containers/JobListings/constants';
import { useSelector } from 'react-redux';
import { getJobListingViewByCampaign } from 'redux/jobs/jobListings/selectors';
import { getFilterFromUrl, getSearchUrl } from 'utils/tools';
import {
  SORT_ASCEND_VALUE,
  SORT_DESCEND_VALUE,
} from 'configs/localData/constant';
import useCheckJobListingPermission from 'containers/JobListings/hooks/useCheckJobListingPermission';
import { capitalize } from 'lodash-es';
import useFormatFnc from '../../useFormat';
import { CampaignListTableStyle } from './styles';

const CampaignListTable = ({
  jobCampaignData,
  retrieveList,
  pushQuery,
  loading,
}) => {
  const useFormat = useFormatFnc();
  const { push } = useHistory();

  const campaignModalRef = useRef();

  const openEditCampaignModal = (values) => {
    campaignModalRef.current.open(values);
  };
  const { canModifyCampaign } = useCheckJobListingPermission();

  const { search } = useLocation();
  const { filter } = getFilterFromUrl(search);
  const { resourceData } = useSelector(getJobListingViewByCampaign) || {};

  const getSorterOrder = useCallback(
    (source) => {
      if (filter.sorts?.[0] === source) return SORT_ASCEND_VALUE;
      if (filter.sorts?.[0] === `-${source}`) return SORT_DESCEND_VALUE;
      return '';
    },
    [filter],
  );

  const columns = useMemo(
    () => [
      {
        title: i18next.t('jobListings.campaign'),
        dataIndex: 'name',
        width: 350,
        sorter: true,
        fixed: 'left',
        render: (data, record) => useFormat.formatCampaignName(data, record),
        defaultSortOrder: getSorterOrder('name'),
      },
      {
        title: `# ${i18next.t('jobListings.jobRoles')}`,
        dataIndex: 'jobRoles',
        width: 250,
        sorter: true,
        defaultSortOrder: getSorterOrder('jobRoles'),
      },
      {
        title: `# ${i18next.t('jobListings.jobListings')}`,
        dataIndex: 'jobListings',
        width: 250,
        sorter: true,
        defaultSortOrder: getSorterOrder('jobListings'),
      },
      {
        title: `# ${i18next.t('jobListings.jobTemplates')}`,
        dataIndex: 'jobTemplates',
        width: 250,
        sorter: true,
        defaultSortOrder: getSorterOrder('jobTemplates'),
      },
      {
        title: `# ${i18next.t('jobListings.workplaces')}`,
        dataIndex: 'workplaces',
        width: 250,
        sorter: true,
        defaultSortOrder: getSorterOrder('workplaces'),
      },
      {
        title: `# ${i18next.t('jobListings.applications')}`,
        dataIndex: 'applications',
        width: 200,
        render: (data, record) =>
          useFormat.formatApplicationAction(data, record),
        sorter: true,
        defaultSortOrder: getSorterOrder('applications'),
      },
      {
        title: i18next.t('jobListings.lastUpdated'),
        dataIndex: 'lastUpdatedAt',
        width: 220,
        sorter: true,
        render: (data) => formatNightDayTable(data),
        defaultSortOrder: getSorterOrder('lastUpdatedAt'),
      },
      {
        title: '',
        dataIndex: 'name',
        width: 80,
        fixed: 'right',
        render: (data, record) => (
          <Space>
            <EditButton
              isView
              title="jobListings.viewCampaign"
              action="isView"
              source="show"
              onClickCustom={() => {
                push(
                  `/job-listings/individual/list?${getSearchUrl({
                    page: DEFAULT_CURRENT_PAGE,
                    sorts: [DEFAULT_SORT_INDIVIDUAL],
                    filter: {
                      campaign: data,
                      campaignId: record?.id,
                    },
                  })}`,
                );
              }}
            />
            <EditButton
              title="jobListings.editCampaign"
              action="isEdit"
              onClickCustom={() => openEditCampaignModal(record)}
              disabled={data === capitalize(OTHER_VALUE) || !canModifyCampaign}
            />
          </Space>
        ),
      },
    ],
    [useFormat, push, getSorterOrder, canModifyCampaign],
  );

  const onChange = (e, filters, sorter) => {
    const formatSort =
      sorter && sorter.field && sorter.order
        ? `${sorter.order === SORT_DESCEND_VALUE ? '-' : ''}${sorter.field}`
        : null;

    const restFilter = {
      size: e.pageSize,
      page: e.current,
      sorts: [formatSort || DEFAULT_SORT_CAMPAIGN],
      filter: { ...filter.filter },
    };

    retrieveList({
      filter: restFilter,
      isRefresh: true,
      keepFilter: true,
    });
    pushQuery({
      'sorts[]': restFilter.sorts,
      ...restFilter,
    });
  };

  return (
    <CampaignListTableStyle>
      <TableCustom
        xScroll={2000}
        data={jobCampaignData}
        columns={columns}
        loading={loading}
        onChange={onChange}
        pagination={{
          pageSize: filter?.size || LIMIT_ITEM,
          current: filter?.page || DEFAULT_CURRENT_PAGE,
          total: resourceData?.total,
        }}
      />
      <CreateCampaignModal ref={campaignModalRef} isRefreshCampaignList />
    </CampaignListTableStyle>
  );
};

CampaignListTable.propTypes = {
  jobCampaignData: PropTypes.array,
  retrieveList: PropTypes.func,
  pushQuery: PropTypes.func,
  loading: PropTypes.bool,
};

CampaignListTable.defaultProps = {
  retrieveList: () => null,
  pushQuery: () => null,
};

export default CampaignListTable;

import { ROLES_CONST } from 'configs/localData/permissions';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { getCurrentRole } from 'redux/auth/selectors';
import { checkRole } from 'utils/tools';

const useCheckPermissionListingPerformance = () => {
  const currentRole = useSelector(getCurrentRole);

  const isViewLeadActivities = useMemo(
    () =>
      checkRole(
        [
          ROLES_CONST.companyAdmin,
          ROLES_CONST.providerAdmin,
          ROLES_CONST.centreAdmin,
        ],
        currentRole,
      ),
    [currentRole],
  );

  return { isViewLeadActivities };
};

export default useCheckPermissionListingPerformance;

import React, { useState } from 'react';
import i18next from 'i18next';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Row, Col, Button } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import { SERVICE_AGE_GROUPS, VACANCY_COD } from 'configs/localData';
import ServiceFrom from './ServiceForm';
import ServicesTabStyles from './styles';

const ServicesTab = () => {
  const responsive = {
    label: {
      xl: 4,
      lg: 6,
      md: 8,
      xs: 24,
    },
    value: {
      xl: 20,
      lg: 18,
      md: 16,
      xs: 24,
    },
  };

  const [isEditFeature, setIsEditFeature] = useState(false);

  const currentData = useSelector((state) => state.centres.currentData);

  const ageGroupItem = SERVICE_AGE_GROUPS.find(
    (item) => item.value === currentData?.serviceAgeGroup,
  );

  const vacancyItem = VACANCY_COD.find(
    (item) => item.value === currentData?.vacancy_cod,
  );

  const onToggle = () => {
    setIsEditFeature(!isEditFeature);
  };

  return (
    <ServicesTabStyles className="tab-child">
      {isEditFeature ? (
        <ServiceFrom record={currentData} onCancel={onToggle} />
      ) : (
        <div>
          <div className="div-button-action div-button-edit">
            <Button icon={<EditOutlined />} onClick={onToggle} />
          </div>
          <Row gutter={24}>
            <Col {...responsive.label} className="col-label">
              {i18next.t('centres.service')}
            </Col>
            <Col {...responsive.value} className="col-value">
              {currentData?.serviceName || i18next.t('error.waitingUpdate')}
            </Col>
            <Col {...responsive.label} className="col-label">
              {i18next.t('centres.ageGroup')}
            </Col>
            <Col {...responsive.value} className="col-value">
              {ageGroupItem?.text
                ? i18next.t(ageGroupItem.text)
                : i18next.t('error.waitingUpdate')}
            </Col>
            <Col {...responsive.label} className="col-label">
              {i18next.t('centres.costPerDay')}
            </Col>
            <Col {...responsive.value} className="col-value">
              {`${currentData?.costPerDay || 0} ${i18next.t(
                'currencyUnit.sign',
              )}`}
            </Col>
            <Col {...responsive.label} className="col-label">
              {i18next.t('centres.vacancy')}
            </Col>
            <Col {...responsive.value} className="col-value">
              {vacancyItem ? (
                <span style={{ color: vacancyItem.color }}>
                  {i18next.t(vacancyItem.text)}
                </span>
              ) : (
                i18next.t('currencyUnit.sign')
              )}
            </Col>
          </Row>
        </div>
      )}
    </ServicesTabStyles>
  );
};

ServicesTab.propTypes = {
  record: PropTypes.object,
};

export default ServicesTab;

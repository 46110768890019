import React, { useState, useEffect } from 'react';
import { Button, Form, Spin } from 'antd';
import i18next from 'i18next';
import PropTypes from 'prop-types';
import {
  getApplicationSettingsTab,
  getValueFormSubmit,
} from 'containers/JobTemplates/utils';
import { useParams } from 'react-router';
import { useDispatch } from 'react-redux';
import { editJobTemplate } from 'redux/jobs/jobTemplates/actions';
import { isEmpty } from 'lodash-es';
import { IS_PREFERRED, IS_REQUIRED } from 'containers/JobTemplates/constants';
import TableRadio from '../../TableRadio';
import { JobApplicationSettingsTabStyles } from './styles';

const JobApplicationSettingsTab = ({ currentData, disabledAction }) => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const [form] = Form.useForm();
  const [isEditing, setIsEditing] = useState(false);
  const [loading, setLoading] = useState(false);
  const [applicationSettings, setApplicationSettings] = useState([]);

  const updateApplicationSettings = (data) =>
    dispatch(
      editJobTemplate({
        id,
        data,
      }),
    );

  const onFinish = async ({ eligibility, ...values }) => {
    const eligibilityData = applicationSettings?.find(
      (item) => item?.category === 'eligibility',
    );

    const transformedData = {
      ...getValueFormSubmit('experience', values),
      ...getValueFormSubmit('qualification', values),
      ...getValueFormSubmit('certifications', values),
      ...getValueFormSubmit('immunisations', values),
      ...getValueFormSubmit('languages', values),
      ...getValueFormSubmit('skills', values),
      ...(!isEmpty(eligibilityData) && {
        eligibility: {
          ids: eligibilityData?.ids,
          isPreferred: eligibility === IS_PREFERRED,
          isRequired: eligibility === IS_REQUIRED,
        },
      }),
    };

    setLoading(true);
    await updateApplicationSettings(transformedData);
    setLoading(false);
    setIsEditing(false);
  };

  const onFieldsChange = () => {
    setIsEditing(true);
  };

  const onCancelEdit = () => {
    setIsEditing(false);
    form.resetFields();
  };

  useEffect(() => {
    setApplicationSettings(getApplicationSettingsTab(currentData));
  }, [currentData]);

  return (
    <JobApplicationSettingsTabStyles>
      <Spin spinning={loading}>
        <Form form={form} onFinish={onFinish} onFieldsChange={onFieldsChange}>
          {isEditing && (
            <div className="action-wrapper">
              <Button
                className="btn-cancel"
                onClick={onCancelEdit}
                disabled={disabledAction}
              >
                {i18next.t('button.cancel')}
              </Button>
              <Button
                type="primary"
                htmlType="submit"
                disabled={disabledAction}
              >
                {i18next.t('button.save')}
              </Button>
            </div>
          )}
          <TableRadio
            dataSource={applicationSettings}
            rowKey={({ category, id }) => `${category}:${id}`}
          />
        </Form>
      </Spin>
    </JobApplicationSettingsTabStyles>
  );
};

JobApplicationSettingsTab.propTypes = {
  disabledAction: PropTypes.bool,
  currentData: PropTypes.object,
};

export default JobApplicationSettingsTab;

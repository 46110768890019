import { crudSelectorsCustom } from 'redux/crudCreatorCustom/selector';
import { createSelector } from 'reselect';
import { MODEL_NAME } from './actions';

export const companiesOfUserSelectors = crudSelectorsCustom(MODEL_NAME);

const getRawOwnedCompaniesOfAllUserRoles = (state) =>
  state?.companiesOfUser?.ownedCompanies;

export const ownedCompaniesOfAllUserRolesSelector = createSelector(
  [getRawOwnedCompaniesOfAllUserRoles],
  (data) => data || [],
);

import React from 'react';
import { Col, Row, Spin } from 'antd';
import PropTypes from 'prop-types';
import { useHistory, useLocation } from 'react-router';
import { getFilterFromUrl, getSearchUrl } from 'utils/tools';
import { getJobListingViewByCampaign } from 'redux/jobs/jobListings/selectors';
import { useSelector } from 'react-redux';
import {
  DEFAULT_CURRENT_PAGE,
  DEFAULT_SORT_CAMPAIGN,
  DEFAULT_SORT_INDIVIDUAL,
  LIMIT_ITEM,
} from 'containers/JobListings/constants';
import { Waypoint } from 'react-waypoint';
import CampaignCard from './CampaignCard';

const CampaignBoardView = ({ jobCampaignData, retrieveList, loading }) => {
  const { push } = useHistory();
  const { search } = useLocation();
  const { filter } = getFilterFromUrl(search);
  const { resourceData } = useSelector(getJobListingViewByCampaign) || {};

  const handleEnterWaypoint = () => {
    const restFilter = {
      page: resourceData.page ? resourceData.page + 1 : DEFAULT_CURRENT_PAGE,
      size: LIMIT_ITEM,
      sorts: [DEFAULT_SORT_CAMPAIGN],
      filter: { ...filter.filter },
    };

    if (resourceData.total / resourceData.page >= LIMIT_ITEM && !loading) {
      retrieveList({
        filter: restFilter,
        isRefresh: false,
        keepFilter: false,
      });
    }
  };

  return (
    <>
      <Row gutter={[24, 24]}>
        {jobCampaignData?.map((campaign) => (
          <Col
            key={campaign.id}
            xxl={6}
            xl={8}
            lg={12}
            md={12}
            xs={24}
            className="flex"
          >
            <CampaignCard
              className="flex-1"
              campaign={campaign}
              handleRedirectDetail={() => {
                push(
                  `/job-listings/individual/list?${getSearchUrl({
                    page: DEFAULT_CURRENT_PAGE,
                    sorts: [DEFAULT_SORT_INDIVIDUAL],
                    filter: {
                      campaign: campaign?.name,
                      campaignId: campaign?.id,
                    },
                  })}`,
                );
              }}
            />
          </Col>
        ))}
      </Row>
      {loading && (
        <div className="flex justify-center pt-40">
          <Spin />
        </div>
      )}
      <Waypoint onEnter={handleEnterWaypoint} />
    </>
  );
};

export default CampaignBoardView;

CampaignBoardView.propTypes = {
  jobCampaignData: PropTypes.array,
  loading: PropTypes.bool,
  retrieveList: PropTypes.func,
};

CampaignBoardView.defaultProps = {
  jobCampaignData: [],
};

import React, { useState, forwardRef, useImperativeHandle } from 'react';
import i18next from 'i18next';
import Radio from 'antd/lib/radio';
import Space from 'antd/lib/space';
import Button from 'antd/lib/button';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { archiveEnquiry, clearCurrentEnquiries } from 'redux/enquiries/actions';
import { DEFAULT_INBOX_ID, REASON_ARCHIVE_ENQUIRY } from 'configs/localData';
import ModalCustom from 'components/common/ModalCustom';
import ArchiveModalStyles from './styles';

const ArchiveModal = forwardRef((props, ref) => {
  const [visible, setVisible] = useState();
  const [inboxId, setInboxId] = useState();
  const [loading, setLoading] = useState(false);
  const [reasonValue, setReasonValue] = useState(
    REASON_ARCHIVE_ENQUIRY[0].value,
  );
  const { replace } = useHistory();

  const { tab } = useParams();

  const dispatch = useDispatch();

  useImperativeHandle(
    ref,
    () => ({
      open: (id) => {
        setVisible(true);
        setInboxId(id);
      },
    }),
    [],
  );

  const onChange = (e) => {
    setReasonValue(e.target.value);
  };

  const onCancel = () => {
    setVisible(false);
  };

  const onOk = () => {
    setLoading(true);
    dispatch(
      archiveEnquiry({ id: inboxId, data: { archivedReason: reasonValue } }),
    )
      .then(({ payload }) => {
        if (payload?.id) {
          onCancel();
          dispatch(clearCurrentEnquiries());
          replace(`/enquiries/${tab}/${DEFAULT_INBOX_ID}`);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <ModalCustom
      visible={visible}
      title={i18next.t('input.reason.name')}
      onCancel={onCancel}
      footer={null}
      resetPaddingBottomBody={false}
    >
      <ArchiveModalStyles>
        <div className="archive-desc text-secondary mb-20">
          {i18next.t('enquiries.reasonArchiveDesc')}
        </div>
        <Radio.Group onChange={onChange} value={reasonValue}>
          <Space direction="vertical" size={10}>
            {REASON_ARCHIVE_ENQUIRY.map((item) => (
              <Radio key={item.value} value={item.value}>
                {i18next.t(item.text)}
              </Radio>
            ))}
          </Space>
        </Radio.Group>

        <Button
          onClick={onOk}
          type="primary w-full mt-20 fw-600"
          loading={loading}
        >
          {i18next.t('button.archive')}
        </Button>
      </ArchiveModalStyles>
    </ModalCustom>
  );
});

export default ArchiveModal;

import { Button, Card, Col, Modal, Row } from 'antd';
import TagCustom from 'components/common/TagCustom';
import React, { useState } from 'react';
import i18next from 'i18next';
import {
  EditFilledIcon,
  ProfileUserIcon,
  TrashFilledIcon,
} from 'components/common/SVGIcon';
import { isEmpty, tail } from 'lodash-es';
import {
  Program,
  useProgramsControllerDeleteOneProgramMutation,
} from 'generated/apis';
import renderHTML from 'react-render-html';
import ImageError from 'components/common/ImageError';
import theme from 'configs/theme';
import CreateProgramModal from 'containers/ProgramsAndLearning/CreateProgramModal';
import { useTranslation } from 'react-i18next';
import { ExclamationCircleFilled } from '@ant-design/icons';
import moment from 'moment';
import { ProgramLearningCardStyles } from './styles';
import { DEFAULT_MODAL_WIDTH } from '../../constants';

interface Props extends Program {
  onClick: () => void;
  isEdit: boolean;
}

const ProgramsLearningCard = ({ onClick, isEdit, ...props }: Props) => {
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const {
    id: programId,
    ageGroup,
    image,
    name,
    updatedAt,
    serviceType,
  } = props;

  const moreAgeGroupArr = tail(ageGroup);

  const allMoreAgeGroupName = moreAgeGroupArr
    ?.flatMap((item) => `<div>${item.name}</div>`)
    .join(' ');

  const [deleteProgram] = useProgramsControllerDeleteOneProgramMutation();

  const openEditProgramModal = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsModalOpen(true);
  };

  const closeEditProgramModal = () => {
    setIsModalOpen(false);
  };

  const handleDeleteProgram = (e) => {
    e.preventDefault();
    e.stopPropagation();
    Modal.confirm({
      wrapClassName: 'program-delete-modal-wrapper',
      icon: <ExclamationCircleFilled />,
      title: i18next.t('programLearning.confirm.delete.title'),
      okText: i18next.t('programLearning.confirm.delete.btnOkText'),
      cancelText: i18next.t('programLearning.confirm.delete.btnCancelText'),
      content: i18next.t('programLearning.confirm.delete.message'),
      onOk: () =>
        deleteProgram({
          programId,
        }).then(() => {
          setIsModalOpen(false);
        }),
      width: DEFAULT_MODAL_WIDTH,
    });
  };

  return (
    <ProgramLearningCardStyles className="h-full">
      <Card
        onClick={onClick}
        className="h-full"
        hoverable
        cover={
          image ? (
            <img
              height={150}
              className="object-cover"
              alt="example"
              src={image}
            />
          ) : (
            <ImageError />
          )
        }
      >
        <div className="flex items-center absolute top-0 right-0 p-12">
          <Button
            disabled={!isEdit}
            size="small"
            type="primary"
            shape="circle"
            className="mr-8 text-12"
            icon={<EditFilledIcon />}
            onClick={(e) => openEditProgramModal(e)}
          />
          <Button
            disabled={!isEdit}
            size="small"
            shape="circle"
            className="text-gray-neutral-500 text-16"
            icon={<TrashFilledIcon />}
            onClick={(e) => handleDeleteProgram(e)}
          />
        </div>
        <div>
          <div className="fw-400 text-12 text-gray-500">
            {updatedAt &&
              `${t('programLearning.lastUpdated')}: ${moment(
                updatedAt,
              ).fromNow()}`}
          </div>
          <div className="text-18 fw-bold ellipsis-t-2 leading-7 mt-8 text-black-base">
            {name}
          </div>
          <div className="fw-bold text-14 ellipsis-t-1 leading-6 mt-8 text-gray-700">
            {serviceType?.name}
          </div>
          <div className="flex mt-8">
            {!isEmpty(ageGroup) && (
              <>
                <div className="text-primary mr-8 mt-4 text-16">
                  {<ProfileUserIcon />}
                </div>
                <Row gutter={[8, 8]}>
                  <Col>
                    <TagCustom
                      className="text-14"
                      text={ageGroup?.[0]?.name}
                      backgroundColor={ageGroup?.[0]?.backgroundColor}
                      color={ageGroup?.[0]?.textColor}
                    />
                  </Col>
                  <Col>
                    {ageGroup?.length > 1 && (
                      <TagCustom
                        isShowTooltip
                        titleTooltipCustom={renderHTML(allMoreAgeGroupName)}
                        text={`+${moreAgeGroupArr?.length} ${t(
                          'programLearning.more',
                        )}`}
                        className="text-14"
                        backgroundColor={theme.color.lightPink}
                        color={theme.palette.primary}
                      />
                    )}
                  </Col>
                </Row>
              </>
            )}
          </div>
        </div>
      </Card>
      <CreateProgramModal
        visible={isModalOpen}
        onClose={closeEditProgramModal}
        inEditMode
        initialValues={props}
      />
    </ProgramLearningCardStyles>
  );
};

export default ProgramsLearningCard;

import React from 'react';
import i18next from 'i18next';
import PropTypes from 'prop-types';
import { formatMoney } from 'utils/tools';
import { FooterWrapper } from './styles';

const Footer = ({ centreServices }) => (
  <FooterWrapper>
    {centreServices?.map((item, index) => (
      <div key={String(index)} className="service-wrapper">
        <div className="service-item">
          <div className="service-name">
            {item?.serviceType?.name || i18next.t('error.waitingUpdate')}
          </div>
          <div className="fee-value">
            {`${formatMoney(item?.costPerDay)} ${i18next.t(
              'currencyUnit.text',
            )}`}
          </div>
        </div>
        {item.serviceAgeGroup?.name && (
          <div className="age-group-service">
            {`(${item?.serviceAgeGroup?.name})`}
          </div>
        )}
      </div>
    ))}
  </FooterWrapper>
);
Footer.propTypes = {
  centreServices: PropTypes.array,
  // fee: PropTypes.number,
};

Footer.defaultProps = {
  centreServices: [],
};

export default Footer;

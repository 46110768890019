import React from 'react';
import { xor } from 'lodash-es';
import PropTypes from 'prop-types';
import UploadFileItem from './UploadFileItem';
import { useFilesMessage } from './context';

import { PreviewFilesStyles } from './styles';

const PreviewFiles = ({ source, setFieldsValue }) => {
  const { fileList, setFileList } = useFilesMessage();

  const deleteImage = (item) => {
    const results = xor(fileList, [item]);

    setFileList(results);

    setFieldsValue &&
      setFieldsValue({
        [source]: results.map((e) => ({
          name: e.name,
          type: e.type,
          url: e.response || e.url,
          status: e.status,
        })),
      });
  };

  return (
    <PreviewFilesStyles className="preview-files">
      {fileList.map((item, index) => (
        <UploadFileItem
          key={String(index)}
          index={index}
          deleteImage={deleteImage}
          item={item}
        />
      ))}
    </PreviewFilesStyles>
  );
};

PreviewFiles.propTypes = {
  source: PropTypes.string,
  setFieldsValue: PropTypes.func,
};

export default PreviewFiles;

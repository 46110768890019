import { Avatar } from 'antd';
import CardSection from 'components/common/CardSection';
import { SOCIAL_MEDIA_CHANNEL_TYPE } from 'configs/localData/constant';
import i18next from 'i18next';
import { forEach } from 'lodash-es';
import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getIDCompanyProfileSelector,
  getSocialMediaChannelsData,
  getTotalSocialMediaChannelsValue,
} from 'redux/jobs/companyProfile/selectors';
import { getCompanyProfileSocialMediaChannels } from 'redux/jobs/companyProfile/actions';
import { getATagHref } from 'utils/tools';
import useRolesCompanyProfile from 'containers/CompanyProfile/hooks/useRolesCompanyProfile';
import SocialMediaChannelsModal from '../../Modals/SocialMediaChannelsModal';

const SocialMediaChannels = () => {
  const modalRef = useRef();
  const dispatch = useDispatch();
  const disabledAction = useRolesCompanyProfile();

  const companyId = useSelector(getIDCompanyProfileSelector);
  const socialMedialChannelsList = useSelector(getSocialMediaChannelsData);
  const totalSocialMedia = useSelector(getTotalSocialMediaChannelsValue);

  useEffect(() => {
    companyId &&
      dispatch(
        getCompanyProfileSocialMediaChannels({
          companyId,
          type: SOCIAL_MEDIA_CHANNEL_TYPE,
        }),
      );
  }, [dispatch, companyId]);

  const initialValue = useMemo(() => {
    const res = {};

    forEach(socialMedialChannelsList, (item) => {
      if (item?.id) {
        res[item?.id] = item?.externalLink;
      }
    });

    return res;
  }, [socialMedialChannelsList]);

  const onClickButton = useCallback(() => {
    modalRef.current.open(initialValue);
  }, [initialValue]);

  return (
    <>
      <CardSection
        isEdit
        title="jobs.companyProfile.modal.socialMediaChannels.title"
        onClickButton={onClickButton}
        disabled={disabledAction}
      >
        {socialMedialChannelsList?.length === totalSocialMedia &&
          socialMedialChannelsList?.map((item, index) => (
            <div
              gutter={12}
              className="mb-20 flex items-center"
              key={Number(index)}
            >
              <div>
                <Avatar src={item?.icon} size={20} />
              </div>
              <div className="text-16 break-all ml-12">
                {item?.externalLink ? (
                  <a
                    href={getATagHref(item?.externalLink)}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-gray"
                  >
                    {item?.externalLink}
                  </a>
                ) : (
                  <div className="text-light-gray">
                    {`${item?.name} ${i18next.t(
                      'jobs.companyProfile.modal.socialMediaChannels.notUpdatedYet',
                    )}`}
                  </div>
                )}
              </div>
            </div>
          ))}
      </CardSection>
      <SocialMediaChannelsModal
        ref={modalRef}
        socialMedialChannelsList={socialMedialChannelsList}
      />
    </>
  );
};

export default SocialMediaChannels;

import React from 'react';
import i18next from 'i18next';
import List from 'containers/rest/List';
import RestFieldItem from 'components/RestField/RestFieldItem';
import ActionGroup from 'components/RestActions/ActionGroup';
import DeleteButton from 'components/RestActions/DeleteButton';
import EditButton from 'components/RestActions/EditButton';

const Customers = (props) => {
  const breadcrumbList = [
    { path: '/config', title: i18next.t('settings.header') },
    { path: '/config/roles', title: i18next.t('roles.header') },
  ];
  return (
    <List
      widthTable="1000px"
      {...props}
      redirects={{
        edit: 'screen',
        create: 'screen',
      }}
      createPermissions={['role:create', 'role:*']}
      breadcrumbCustom={breadcrumbList}
      resource="roles"
      hasSearch={false}
      rootPath="/config"
    >
      <RestFieldItem source="id" header="roles.id" />
      <RestFieldItem source="name" header="roles.name" />
      <RestFieldItem source="description" header="roles.description" />
      <ActionGroup widthAction={80}>
        <EditButton />
        <DeleteButton />
      </ActionGroup>
    </List>
  );
};

export default Customers;

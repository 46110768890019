import styled from 'styled-components';

export const JobApplicationSettingsTabStyles = styled.div`
  .action-wrapper {
    background-color: ${({ theme }) => theme.background.content};
    text-align: right;
    padding: 12px 12px 0 12px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;

    .ant-btn {
      margin-left: 12px;
    }
  }

  .btn-cancel {
    background: ${({ theme }) => theme.background.content};
    color: ${({ theme }) => theme.palette.primary};
    border: 1px solid ${({ theme }) => theme.palette.primary};
    box-sizing: border-box;
  }

  .ant-table-wrapper {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border: none;
  }

  .ant-table-tbody > tr > td {
    border-bottom: 1px solid ${({ theme }) => theme.background.gray};
  }
`;

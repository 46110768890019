import * as React from 'react';
import { Image, Switch } from 'antd';
import TagCustom from 'components/common/TagCustom';
import { formatNullString } from 'utils/tools';
import { formatNightDayTable } from 'utils/formatFieldUtils';
import {
  EXISTING_AUDIENCE_TAB_KEY,
  NEW_AUDIENCE_TAB_KEY,
} from 'containers/Advertisement/constants';

interface ButtonStatus {
  value: string;
  color: string;
  backgroundColor: string;
}

const useFormat = () => {
  const viewThumbnail = ({
    url = '',
    type,
    thumbnail,
  }: {
    url: string;
    type: string;
    thumbnail: string;
  }) => (
    <>
      {type === 'image' || thumbnail ? (
        <Image
          className="rounded-4 object-cover"
          preview={false}
          width={92}
          height={92}
          src={thumbnail || url}
        />
      ) : (
        <video width="92" height="92" className="rounded-4 object-cover">
          <source src={url} type="video/mp4" />
          <track src="" kind="captions" srcLang="en" label="english_captions" />
        </video>
      )}
    </>
  );

  const StatusButton = (params: ButtonStatus) => (
    <TagCustom
      className="fw-bold"
      color={params?.color}
      backgroundColor={params?.backgroundColor}
      text={params?.value}
      isBorder={false}
    />
  );

  const switchActive = (isActive: boolean, onChange?: () => void) => (
    <Switch defaultChecked={isActive} onChange={onChange} />
  );

  const formatAvailableStart = (data) =>
    formatNullString(formatNightDayTable(data));

  const useConvertCreateData = ({
    type,
    headline,
    description,
    callingButton,
    mediaList,
    isSocialRelated,
    isChildcareRelated,
    targetedAudience,
    duration,
  }) => ({
    type: type?.id,
    headline,
    description,
    callingButton: callingButton?.id,
    destination: callingButton?.destination,
    mediaList,
    isSocialRelated,
    isChildcareRelated,
    targetedAudience: {
      ...targetedAudience,
      id: targetedAudience?.id,
      isShared: targetedAudience?.isShared,
      userLocation: targetedAudience?.userLocation,
      userType: targetedAudience?.userType,
      userLocationValues: targetedAudience?.userLocationValue,
    },
    duration: {
      from: duration?.startTime,
      to: duration?.endTime,
      fromTime: duration?.startTime,
      endTime: duration?.endTime,
      durationDays: duration?.durationDays,
      durationHours: duration?.durationHours,
    },
    audienceActiveTab: targetedAudience?.isShared
      ? EXISTING_AUDIENCE_TAB_KEY
      : NEW_AUDIENCE_TAB_KEY,
  });

  return {
    viewThumbnail,
    StatusButton,
    formatAvailableStart,
    switchActive,
    useConvertCreateData,
  };
};
export default useFormat;

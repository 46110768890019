import React from 'react';
import { useSelector } from 'react-redux';
import Divider from 'antd/lib/divider';
import CardSection from 'components/common/CardSection';
import EmptyData from 'components/common/EmptyData';
import ChildrenItem from './ChildrenItem';
import { ChildrenInfoStyles } from './styles';

const ChildrenInfo = () => {
  const data = useSelector((state) => state.contacts.currentData?.children);

  return (
    <CardSection title="children.childrenInformation">
      <ChildrenInfoStyles>
        {data?.length ? (
          data?.map((child, idx) => (
            <React.Fragment key={String(idx)}>
              <ChildrenItem child={child} />
              <Divider />
            </React.Fragment>
          ))
        ) : (
          <EmptyData />
        )}
      </ChildrenInfoStyles>
    </CardSection>
  );
};

export default ChildrenInfo;

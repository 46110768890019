import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import i18next from 'i18next';

const PricesLastUpdated = ({ date }) => {
  const convertedDate =
    moment(date).format('DD MMMM YYYY') !== 'Invalid date'
      ? moment(date).format('DD MMMM YYYY')
      : i18next.t('error.waitingUpdate');
  return (
    <>
      {`${i18next.t('hoverCardCentre.pricesLastUpdated')} 
      ${convertedDate}
    `}
    </>
  );
};

PricesLastUpdated.propTypes = {
  date: PropTypes.string,
};
export default PricesLastUpdated;

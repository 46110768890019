import React from 'react';
import { CustomTooltipWrapper } from 'containers/CompanyBenchmarking/components/CompareRatingBarChart/CustomTooltip/styles';
import PropTypes from 'prop-types';
import { ALL_COMPANIES_DATA_KEY } from 'containers/CompanyBenchmarking/localData/constants';

const CustomTooltip = ({ payload, isEmptyCompany }) => {
  const ratingPoint = payload?.[0]?.payload?.rating;
  if (isEmptyCompany && payload?.length > 0) {
    payload.splice(1, 1);
  }
  return (
    <CustomTooltipWrapper>
      <div className="tooltip-header">{`Rating ${ratingPoint}`}</div>
      <div className="content-tooltip">
        {payload?.map((item, i) => (
          <div className="value-item" key={String(i)}>
            <div
              className="square-tooltip"
              style={{
                backgroundColor: item?.payload?.fill || item?.color,
              }}
            />
            <div className="text-value">
              <span>{item.name}</span>
            </div>
            <strong className="strong-value">
              {item?.dataKey === ALL_COMPANIES_DATA_KEY
                ? item?.payload?.allCompaniesProportion
                : item?.payload?.userCompaniesProportion}
              %
            </strong>
          </div>
        ))}
      </div>
    </CustomTooltipWrapper>
  );
};

CustomTooltip.propTypes = {
  payload: PropTypes.array,
  isEmptyCompany: PropTypes.bool,
};

export default CustomTooltip;

import React from 'react';
import { Row, Col, Spin } from 'antd';
import PropTypes from 'prop-types';
import { Waypoint } from 'react-waypoint';
import { useLocation } from 'react-router';
import { useSelector } from 'react-redux';
import { contactsSelectors } from 'redux/contacts/selectors';

import EmptyData from 'components/common/EmptyData';
import ContactItem from '../BoardView/ContactItem';

const GridList = ({ retrieveList }) => {
  const { pathname } = useLocation();

  const data = useSelector(contactsSelectors.getDataArr);
  const loading = useSelector((state) => state.contacts.loading);
  const enabledLoadMore = useSelector(contactsSelectors.enabledLoadMore);

  const handleLoadMore = () => {
    retrieveList({}, false);
  };

  return (
    <div className="grid-list">
      {data?.length ? (
        <Row gutter={[24, 24]}>
          {data.map((item, idx) => (
            <Col key={String(idx)} xl={6} lg={8} md={12} xs={24}>
              <ContactItem item={item} pathname={pathname} />
            </Col>
          ))}
        </Row>
      ) : (
        <EmptyData />
      )}
      {loading && (
        <div className="flex-center w-full">
          <Spin />
        </div>
      )}
      {enabledLoadMore && (
        <div style={{ height: 1 }}>
          <Waypoint onEnter={handleLoadMore} />
        </div>
      )}
    </div>
  );
};

GridList.propTypes = {
  retrieveList: PropTypes.func,
};

export default GridList;

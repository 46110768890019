import styled from 'styled-components';

const ListStyles = styled.div`
  .ant-empty {
    width: 100%;
  }
  .ant-result {
    text-align: center;
    width: 100%;
  }
`;

export default ListStyles;

export const EULAModalStyles = styled.div``;

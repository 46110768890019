import styled from 'styled-components';

export const JobDetailStyles = styled.div`
  background: ${({ theme }) => theme.background.container};
  height: 100%;
  .btn-return {
    position: fixed;
    bottom: 6%;
    left: 40%;
    z-index: 999;
  }
`;

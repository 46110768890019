import React from 'react';
import { Row, Col } from 'antd';
import {
  TotalAcceptedIcon,
  TotalOpenJobApplicationsIcon,
  TotalTemPlateIcon,
} from 'components/common/SVGIcon';
import SummaryCard from 'components/common/SummaryCardCustom';
import { useSelector } from 'react-redux';
import { getSummaryJobApplicationsCard } from 'redux/jobs/jobApplications/selectors';

const Summaries = () => {
  const summariesList = useSelector(getSummaryJobApplicationsCard) || {};

  const summaries = [
    {
      title: 'jobApplications.summaries.totalOpenJobApplications',
      count: summariesList?.totalApplication,
      IconComponent: TotalTemPlateIcon,
    },
    {
      title: 'jobApplications.summaries.totalAccepted',
      count: summariesList?.totalJobOpen,
      IconComponent: TotalOpenJobApplicationsIcon,
    },
    {
      title: 'jobApplications.summaries.totalJobListingsValue',
      count: summariesList?.totalEmployee,
      IconComponent: TotalAcceptedIcon,
    },
  ];

  return (
    <Row gutter={[25, 25]} className="mb-24">
      {summaries.map((item, index) => (
        <Col lg={8} md={24} xs={24} key={String(index)}>
          <SummaryCard {...item} isTitleTooltip />
        </Col>
      ))}
    </Row>
  );
};

export default Summaries;

import React, { useState } from 'react';
import { flatten, compact } from 'lodash-es';
import Create from 'containers/rest/Create';
import RoleDetail from '../components/RoleDetail';

const CustomersCreate = (props) => {
  const [permissions, setPermissions] = useState({});
  return (
    <Create
      {...props}
      resource="roles"
      formatOnSubmit={(values) => ({
        ...values,
        permissionIds: permissions?.['*']?.checkedAll
          ? permissions?.['*']?.checkedList
          : compact(
              flatten(
                Object.keys(permissions).map((permission) =>
                  permissions[permission].checkedAll
                    ? [permissions[permission].checkedAllId]
                    : permissions[permission].checkedList,
                ),
              ),
            ),
      })}
    >
      <RoleDetail permissions={permissions} setPermissions={setPermissions} />
    </Create>
  );
};

export default CustomersCreate;

import React, { useCallback, useState } from 'react';
import { Form } from 'antd';
import i18next from 'i18next';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { createDocument } from 'redux/applications/actions';
import { RestInputContext } from 'components/RestInput/RestInputContext';

import ModalCustom from 'components/common/ModalCustom';
import RestUploadFile from 'components/RestInput/RestUploadFile';

const CreateModal = ({ visible, onCancel, applicationId }) => {
  const [form] = Form.useForm();

  const [isDisabled, setIsDisabled] = useState(false);
  const [loading, setLoading] = useState(false);

  const { validateFields, resetFields } = form;

  const dispatch = useDispatch();

  const handleCancel = useCallback(() => {
    onCancel();
    resetFields();
  }, [onCancel, resetFields]);

  const onOk = useCallback(() => {
    validateFields().then((values) => {
      setLoading(true);
      dispatch(createDocument({ id: applicationId, data: values }))
        .then(({ payload }) => {
          setLoading(false);
          if (payload?.data?.id) handleCancel();
        })
        .catch(() => setLoading(false));
    });
  }, [validateFields, applicationId, handleCancel]); // eslint-disable-line

  const formatResultFile = (file) => ({
    name: file.name,
    url: file.response || file.url,
    type: file.type,
  });

  return (
    <ModalCustom
      title={i18next.t('documents.titleNewDocument')}
      okText="button.upload"
      visible={visible}
      isDisabled={isDisabled}
      onCancel={handleCancel}
      onOk={onOk}
      loading={loading}
    >
      <Form form={form} layout="vertical">
        <RestInputContext.Provider value={{ form }}>
          <RestUploadFile
            setIsDisabled={setIsDisabled}
            record={{}}
            source="file"
            accept={null}
            multiple={false}
            label="text.uploadFile"
            formatResult={formatResultFile}
            required
          />
        </RestInputContext.Provider>
      </Form>
    </ModalCustom>
  );
};

CreateModal.propTypes = {
  visible: PropTypes.bool,
  onCancel: PropTypes.func,
  applicationId: PropTypes.string,
};

CreateModal.defaultProps = {
  onCancel: () => null,
};

export default CreateModal;

import React, { useState } from 'react';
import { flatten, compact } from 'lodash-es';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import i18next from 'i18next';
import crudSelectors from 'redux/crudSelectors';
import Edit from 'containers/rest/Edit';
import RoleDetail from '../components/RoleDetail';

const CustomersEdit = (props) => {
  const [permissions, setPermissions] = useState({});
  const currentData = useSelector(crudSelectors.roles.getCurrentData);
  const location = useLocation();
  const breadCrumb = [
    {
      title: i18next.t('settings.header'),
      path: '/config',
    },
    {
      title: i18next.t('roles.header'),
      path: '/config/roles',
    },
    {
      title: currentData?.name,
      path: location.pathname,
    },
  ];
  return (
    <Edit
      {...props}
      resource="roles"
      breadcrumbCustom={breadCrumb}
      formatOnSubmit={(values) => ({
        ...values,
        permissionIds: permissions?.['*']?.checkedAll
          ? permissions?.['*']?.checkedList
          : compact(
              flatten(
                Object.keys(permissions).map((permission) =>
                  permissions[permission].checkedAll
                    ? [permissions[permission].checkedAllId]
                    : permissions[permission].checkedList,
                ),
              ),
            ),
      })}
    >
      <RoleDetail permissions={permissions} setPermissions={setPermissions} />
    </Edit>
  );
};

export default CustomersEdit;

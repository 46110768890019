import styled from 'styled-components';

const ResultSignUpStyles = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  .ant-result {
    max-width: 800px;
    .ant-result-subtitle {
      margin-top: 15px;
      font-size: 16px;
    }
  }
`;

export default ResultSignUpStyles;

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface ExportItem {
  id: string;
  name: string;
  status: string;
  url?: string;
  refreshApi: {
    url: string;
    params: object;
    prefixApi?: string;
  };
}

interface InitialState {
  visible: boolean;
  exportList: Partial<ExportItem>[];
}

const initialState: InitialState = {
  visible: false,
  exportList: [],
};

const exportProgressSlice = createSlice({
  name: 'exportProgress',
  initialState,
  reducers: {
    setExportList: (
      state,
      { payload: exportItem }: PayloadAction<Partial<ExportItem>>,
    ) => {
      const existExportIndex = state.exportList.findIndex(
        (item) => item.id === exportItem?.id,
      );

      if (existExportIndex !== -1) {
        state.exportList[existExportIndex] = {
          ...state.exportList[existExportIndex],
          ...exportItem,
        };
        return;
      }

      state.exportList = [exportItem, ...state.exportList];
    },
    setVisibleDownloadPopup: (
      state,
      { payload: isVisible }: PayloadAction<boolean>,
    ) => {
      state.visible = isVisible;
    },
    resetAllExportProgress: () => initialState,
  },
});

export const {
  setExportList,
  resetAllExportProgress,
  setVisibleDownloadPopup,
} = exportProgressSlice.actions;

export default exportProgressSlice.reducer;

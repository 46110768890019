import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  getAllAdsApi,
  getAdsFormConfigApi,
  addNewCallingButtonApi,
  createAdApi,
  deleteCallingButtonApi,
  editCallingButtonApi,
  getAdAudiencesApi,
  getAdDetailApi,
  closeAdsApi,
  getCallingButtonListApi,
  getUsedCallingButtonCountApi,
  editAdApi,
  createAdAudienceApi,
  deleteAdAudienceApi,
  editAdAudienceApi,
  deleteAdApi,
} from 'api/advertisement';
import {
  AdsListParams,
  AudienceItem,
  CreatePayload,
  UpsertAudienceBody,
  EditPayload,
} from 'api/advertisement/type';
import { apiWrapper } from 'utils/reduxUtils';

export const MODEL_NAME = 'advertisement';

export interface CloseAdsPayload {
  id?: string;
}

export const getAdsFormConfig = createAsyncThunk(
  `${MODEL_NAME}/getAdsFormConfig`,
  async (_, thunkAPI) => {
    try {
      const response = await apiWrapper(
        {
          isShowProgress: false,
          isShowSuccessNoti: false,
          isCheckError: false,
        },
        getAdsFormConfigApi,
      );
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  },
);

export const getAdAudiences = createAsyncThunk<
  ListResponse<AudienceItem>,
  ParamsGetList
>(`${MODEL_NAME}/getAdAudiences`, async (payload, thunkAPI) => {
  try {
    const response = await apiWrapper(
      {
        isShowProgress: false,
        isShowSuccessNoti: false,
        isCheckError: false,
      },
      getAdAudiencesApi,
      payload.data,
    );
    return response;
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});

export const createAdAudience = createAsyncThunk(
  `${MODEL_NAME}/createAdAudience`,
  async (payload: UpsertAudienceBody, thunkAPI) => {
    try {
      const response = await apiWrapper(
        {
          isShowProgress: false,
          isShowSuccessNoti: true,
          isCheckError: false,
        },
        createAdAudienceApi,
        payload,
      );
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  },
);

export const getCallingButtonList = createAsyncThunk(
  `${MODEL_NAME}/getCallingButtonList`,
  async (_, thunkAPI) => {
    try {
      return await apiWrapper(
        {
          isShowProgress: false,
          isShowSuccessNoti: false,
          isCheckError: false,
        },
        getCallingButtonListApi,
      );
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  },
);

interface AddCallingButtonPayload {
  name: string;
  destinationType: string;
}

export const addCallingButton = createAsyncThunk(
  `${MODEL_NAME}/addCallingButton`,
  async (payload: AddCallingButtonPayload, thunkAPI) => {
    try {
      const response = await apiWrapper(
        {
          isShowProgress: false,
          isShowSuccessNoti: false,
          isCheckError: false,
        },
        addNewCallingButtonApi,
        payload,
      );
      if (response) {
        return response;
      }
      return thunkAPI.rejectWithValue(response);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  },
);

export const deleteAdAudience = createAsyncThunk(
  `${MODEL_NAME}/deleteAdAudience`,
  async (id: string, thunkAPI) => {
    try {
      const response = await apiWrapper(
        {
          isShowProgress: false,
          isShowSuccessNoti: true,
          isCheckError: false,
        },
        deleteAdAudienceApi,
        id,
      );
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  },
);

interface EditCallingButtonPayload extends AddCallingButtonPayload {
  callingButtonId: string;
}

export const editCallingButton = createAsyncThunk(
  `${MODEL_NAME}/editCallingButton`,
  async (payload: EditCallingButtonPayload, thunkAPI) => {
    try {
      const { callingButtonId, name, destinationType } = payload;
      const response = await apiWrapper(
        {
          isShowProgress: false,
          isShowSuccessNoti: false,
          isCheckError: false,
        },
        editCallingButtonApi,
        { callingButtonId },
        { name, destinationType },
      );
      if (response) {
        return response;
      }
      return thunkAPI.rejectWithValue(response);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  },
);

export const editAdAudience = createAsyncThunk(
  `${MODEL_NAME}/editAdAudience`,
  async ({ id, body }: { id: string; body: UpsertAudienceBody }, thunkAPI) => {
    try {
      const response = await apiWrapper(
        {
          isShowProgress: false,
          isShowSuccessNoti: true,
          isCheckError: false,
        },
        editAdAudienceApi,
        id,
        body,
      );
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  },
);

interface DeleteCallingButtonPayload {
  callingButtonId: string;
}

export const deleteCallingsButton = createAsyncThunk(
  `${MODEL_NAME}/deleteCallingButton`,
  async (payload: DeleteCallingButtonPayload, thunkAPI) => {
    try {
      const response = await apiWrapper(
        {
          isShowProgress: false,
          isShowSuccessNoti: false,
          isCheckError: false,
        },
        deleteCallingButtonApi,
        payload,
      );
      if (response) {
        return response;
      }
      return thunkAPI.rejectWithValue(response);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  },
);

export const getUsedCallingButtonCount = createAsyncThunk(
  `${MODEL_NAME}/getUsedCallingButtonCount`,
  async (payload: DeleteCallingButtonPayload, thunkAPI) => {
    try {
      const response = await apiWrapper(
        {
          isShowProgress: false,
          isShowSuccessNoti: false,
          isCheckError: false,
        },
        getUsedCallingButtonCountApi,
        payload,
      );
      if (response) {
        return response;
      }
      return thunkAPI.rejectWithValue(response);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  },
);

export const createAd = createAsyncThunk(
  `${MODEL_NAME}/createAd`,
  async (payload: CreatePayload, thunkAPI) => {
    try {
      const response = await apiWrapper(
        {
          isShowProgress: false,
          isShowSuccessNoti: true,
          isCheckError: false,
        },
        createAdApi,
        payload,
      );
      if (response) {
        return response;
      }
      return thunkAPI.rejectWithValue(response);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  },
);

export const getAdsList = createAsyncThunk(
  `${MODEL_NAME}/getAdsList`,
  async (params: AdsListParams, thunkAPI: any) => {
    try {
      const { size, page, filter, sorts } =
        thunkAPI.getState().advertisement?.resourceData || {};
      const response = await apiWrapper(
        {
          isShowProgress: false,
          isShowSuccessNoti: false,
          isCheckError: false,
        },
        getAllAdsApi,
        {
          size,
          page,
          filter,
          sorts,
          ...params?.data,
        },
      );
      return {
        data: {
          ...response,
        },
        options: params.options,
      };
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  },
);

export const getAdDetail = createAsyncThunk(
  `${MODEL_NAME}/getAdDetail`,
  async (id: string | number, thunkAPI) => {
    try {
      const response = await apiWrapper(
        {
          isShowProgress: false,
          isShowSuccessNoti: false,
          isCheckError: false,
        },
        getAdDetailApi,
        id,
      );
      if (response) {
        return response;
      }
      return thunkAPI.rejectWithValue(response);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  },
);

export const editAd = createAsyncThunk(
  `${MODEL_NAME}/editAd`,
  async (payload: EditPayload, thunkAPI) => {
    try {
      const { adsPostId, ...rest } = payload;
      const response = await apiWrapper(
        {
          isShowSuccessNoti: true,
          isShowProgress: false,
          isCheckError: false,
        },
        editAdApi,
        { adsPostId },
        rest,
      );
      return { data: response };
    } catch (error) {
      return thunkAPI.rejectWithValue({ data: error });
    }
  },
);

export const closeAds = createAsyncThunk(
  `${MODEL_NAME}/closeAds`,
  async (payload: CloseAdsPayload, thunkAPI) => {
    try {
      const response = await apiWrapper(
        {
          isShowSuccessNoti: true,
          isShowProgress: false,
          isCheckError: false,
        },
        closeAdsApi,
        payload,
      );
      if (response) {
        return response;
      }
      return thunkAPI.rejectWithValue(response);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  },
);

export const deleteAd = createAsyncThunk(
  `${MODEL_NAME}/deleteAd`,
  async (id: string, thunkAPI) => {
    try {
      const response = await apiWrapper(
        {
          isShowProgress: false,
          isShowSuccessNoti: true,
          isCheckError: false,
        },
        deleteAdApi,
        id,
      );
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  },
);

import styled from 'styled-components';

export const CompareRatingBarChartWrapper = styled.div`
  background: ${({ theme }) => theme.background.content};
  width: 100%;
  border: 1px solid #ebecf0;
  border-radius: 16px;
  padding-top: 25px;
  gap: 48px;
  margin-top: 25px;

  .recharts-surface {
    cursor: pointer;
  }
  .chart-header {
    padding: 0 120px;
  }

  .chart-body {
    padding-left: 20px;
    overflow-x: auto;
    overflow-y: hidden;
    margin-bottom: 15px;
    padding-bottom: 15px;
  }

  .custom-row {
    padding: 0 20px;
  }

  @media (max-width: 1565px) {
    .custom-row {
      padding: 0 30px;
    }

    .chart-header {
      padding: 0 50px;
    }

    .chart-body {
      overflow-x: scroll;
      overflow-y: hidden;
    }
  }

  .current-date {
    display: block;
    color: #857e7f;
  }

  .chart-title {
    font-weight: bold;
    padding-top: 30px;
  }

  .recharts-surface {
    border-radius: 3px;
  }
`;

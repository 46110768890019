import React, { useMemo, useCallback, useEffect } from 'react';
import { Timeline } from 'antd';
import i18next from 'i18next';
import { isEmpty } from 'lodash-es';
import moment from 'moment';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { UnorderedListOutlined, PaperClipOutlined } from '@ant-design/icons';
import {
  APPLICATION_STATUS,
  APPLICATION_STATUS_CONSTANT,
} from 'configs/localData';

import { formatDateTimeline } from 'utils/textUtils';
import { getLatestActivities, getLatestTask } from 'redux/applications/actions';
import useIsSenderMessage from 'hooks/useIsSenderMessage';

import { FileIcon, MailIcon } from 'components/common/SVGIcon';
import { ActivitiesStyles } from './styles';

const Activities = ({ applicationStatuses, id }) => {
  const dispatch = useDispatch();

  const latestMessage = useSelector(
    (state) => state.enquiries.currentData?.latestMessage,
  );

  const isSender = useIsSenderMessage(latestMessage?.senderId);

  const latestActivity = useSelector(
    (state) => state.applications.latestActivity,
  );

  const latestTask = useSelector((state) => state.applications.latestTask);

  const latestMessageCreatedAt = latestMessage?.createdAt;

  useEffect(() => {
    if (id) {
      dispatch(getLatestActivities(id));
      dispatch(getLatestTask(id));
    }
  }, [id, dispatch]);

  const getTextStatus = useCallback((status) => {
    const statusItem = APPLICATION_STATUS.find((item) => item.value === status);

    return statusItem?.text ? i18next.t(statusItem.text) : status;
  }, []);

  const getContentApplicationStatus = useCallback(
    (status) => {
      if (status === APPLICATION_STATUS_CONSTANT.submitted)
        return i18next.t('applications.timeline.submittedApplicationStatus');

      return i18next.t('applications.timeline.contentApplicationStatus', {
        status: getTextStatus(status),
      });
    },
    [getTextStatus],
  );

  const contentMailbox = useMemo(() => {
    if (isEmpty(latestMessage))
      return i18next.t('applications.timeline.noNewMessage');

    return isSender
      ? i18next.t('applications.timeline.newSenderMailbox', {
          name: `${latestMessage?.receiverFirstName || ''} ${
            latestMessage?.receiverLastName || ''
          }`,
        })
      : i18next.t('applications.timeline.newReceivedMailbox', {
          name: `${latestMessage?.senderFirstName || ''} ${
            latestMessage?.senderLastName || ''
          }`,
        });
  }, [latestMessage, isSender]);

  const activitiesApplicationStatus = useMemo(
    () =>
      applicationStatuses?.map((item) => ({
        content: getContentApplicationStatus(item.currentStatus),
        time: item.createdAt,
      })) || [
        {
          time: null,
          content: i18next.t('applications.timeline.noChangeStatus'),
        },
      ],
    [applicationStatuses, getContentApplicationStatus],
  );

  const activitiesMailbox = useMemo(
    () => [
      {
        time: latestMessageCreatedAt,
        content: contentMailbox,
      },
    ],
    [latestMessageCreatedAt, contentMailbox],
  );

  const activitiesOfActivity = useMemo(
    () => [
      {
        time: latestActivity?.updatedAt,
        content: latestActivity?.subject,
      },
    ],
    [latestActivity],
  );

  const activitiesOfTask = useMemo(
    () => [
      {
        time: latestTask?.updatedAt,
        content: latestTask?.subject,
      },
    ],
    [latestTask],
  );

  const SUMMARIES = [
    {
      title: 'applications.applicationStatus',
      IconComponent: FileIcon,
      color: '#DB147F',
      activities: activitiesApplicationStatus,
    },
    {
      title: 'applications.tabs.mailbox',
      IconComponent: MailIcon,
      color: '#459EFF',
      activities: activitiesMailbox,
    },
    {
      title: 'applications.tabs.tasks',
      IconComponent: PaperClipOutlined,
      color: '#FB8429',
      activities: activitiesOfTask,
    },
    {
      title: 'applications.tabs.activities',
      IconComponent: UnorderedListOutlined,
      color: '#36BF57',
      activities: activitiesOfActivity,
    },
  ];

  //  Sort order on the timeline
  SUMMARIES.sort((a, b) => {
    if (!a.activities?.[0]?.time && !b.activities?.[0]?.time) return 0;

    if (!b.activities?.[0]?.time) return -1;

    if (!a.activities?.[0]?.time) return 1;

    return moment(a.activities[0].time).isAfter(moment(b.activities[0].time))
      ? -1
      : 1;
  });

  return (
    <ActivitiesStyles>
      <div className="title-summary">{i18next.t('text.today')}</div>
      <Timeline>
        {SUMMARIES.map((summary, index) => (
          <Timeline.Item
            dot={
              <summary.IconComponent
                className="wrapper-icon"
                style={{ color: summary.color }}
              />
            }
            color={summary.color}
            key={String(index)}
          >
            <div className="timeline-wrapper">
              {!summary.activities?.length ? (
                <div className="info-timeline">
                  <div className="title-timeline">
                    {i18next.t(summary.title)}
                  </div>
                  <div className="content-timeline">
                    {i18next.t('error.noActivity')}
                  </div>
                </div>
              ) : (
                summary.activities?.map((activity, index) => (
                  <div key={String(index)} className="timeline-item-wrapper">
                    <div className="timeAt-timeline">
                      {formatDateTimeline(activity.time)}
                    </div>
                    <div className="info-timeline">
                      <div className="title-timeline">
                        {i18next.t(summary.title)}
                      </div>
                      <div className="content-timeline">
                        {activity.content || i18next.t('error.noActivity')}
                      </div>
                    </div>
                  </div>
                ))
              )}
            </div>
          </Timeline.Item>
        ))}
      </Timeline>
    </ActivitiesStyles>
  );
};

Activities.propTypes = {
  applicationStatuses: PropTypes.array,
  id: PropTypes.string,
};

Activities.defaultProps = {
  applicationStatuses: [],
};

export default Activities;

import React from 'react';
import { Typography } from 'antd';

const { Text } = Typography;

interface Props {
  name?: string;
  company?: string;
  year?: number;
}

const AwardItem = ({ name, year, company }: Props) => (
  <div className="flex flex-col gap-8">
    <Text className="text-16 fw-bold">{name}</Text>
    <Text className="text-16">{company}</Text>
    <Text className="txt-neutral-500">{year}</Text>
  </div>
);

export default AwardItem;

import React from 'react';
import PropTypes from 'prop-types';
import i18next from 'i18next';
import { Button } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

import CheckPermission from 'components/common/CheckPermissions';
import CreateBtnFallback from './CreateBtnFallback';
import { CreateButtonStyles } from './styles';

const CreateButton = ({
  header,
  gotoCreatePage,
  source,
  permissionKey,
  action,
  onClickCustom,
  isSkipSubscription,
  packageName,
  isShowIcon = true,
  isPrimaryColor,
  disabled,
}) => {
  const handleOnClick = () => {
    if (onClickCustom) {
      onClickCustom();
    } else {
      gotoCreatePage();
    }
  };
  return (
    <CreateButtonStyles
      className="create-div-button"
      isPrimaryColor={isPrimaryColor}
    >
      <CheckPermission
        packageName={packageName}
        permissionKey={permissionKey}
        action={action}
        isSkipSubscription={isSkipSubscription}
        isDisabled
        fallback={<CreateBtnFallback header={header} />}
      >
        <Button
          source={source}
          onClick={handleOnClick}
          icon={isShowIcon && <PlusOutlined />}
          disabled={disabled}
        >
          {i18next.t(header)}
        </Button>
      </CheckPermission>
    </CreateButtonStyles>
  );
};

CreateButton.propTypes = {
  gotoCreatePage: PropTypes.func,
  source: PropTypes.string,
  onClickCustom: PropTypes.func,
  header: PropTypes.string,
  permissionKey: PropTypes.string,
  action: PropTypes.string,
  isSkipSubscription: PropTypes.bool,
  packageName: PropTypes.string,
  isShowIcon: PropTypes.bool,
  isPrimaryColor: PropTypes.bool,
  disabled: PropTypes.bool,
};

CreateButton.defaultProps = {
  source: 'create',
  header: 'button.create',
};

export default CreateButton;

import { Divider, Skeleton } from 'antd';
import PageTitle from 'components/common/PageTitle';
import i18next from 'i18next';
import { isEmpty } from 'lodash-es';
import React from 'react';
import { useSelector } from 'react-redux';
import {
  getDataJobTemplateDetail,
  getLoadingJobTemplateDetail,
} from 'redux/jobs/jobTemplates/selectors';
import AboutTheJob from './AboutTheJob';
import AboutYou from './AboutYou';
import JobFeatures from './JobFeatures';
import JobHighlightsAndBenefits from './JobHighlightsAndBenefits';
import SnapShootJobTemplate from './SnapShootJobTemplate';
import { PreviewStepWrapper } from './styles';
import WhatYouWillBeDoing from './WhatYouWillBeDoing';

const PreviewStep = () => {
  const currentJobTemplate = useSelector(getDataJobTemplateDetail) || {};
  const loading = useSelector(getLoadingJobTemplateDetail);

  return (
    <Skeleton loading={loading}>
      <PreviewStepWrapper>
        <PageTitle className="step-title">
          {i18next.t('jobListings.steps.step1')}
        </PageTitle>
        <SnapShootJobTemplate currentJobTemplate={currentJobTemplate} />
        <Divider />
        <JobHighlightsAndBenefits benefits={currentJobTemplate?.benefits} />
        <Divider />
        {!isEmpty(currentJobTemplate?.features) && (
          <>
            <JobFeatures features={currentJobTemplate?.features} />
            <Divider />
          </>
        )}

        <AboutTheJob aboutTheJob={currentJobTemplate?.aboutTheJob} />
        <Divider />
        <WhatYouWillBeDoing
          candidateResponsibilities={
            currentJobTemplate?.candidateResponsibilities
          }
        />
        <Divider />
        <AboutYou currentJobTemplate={currentJobTemplate} />
      </PreviewStepWrapper>
    </Skeleton>
  );
};

export default PreviewStep;

import React from 'react';
import Edit from 'containers/rest/Edit';
import Form from '../components/Form';

const ContactsEdit = (props) => (
  <Edit {...props} isClearCurrent={false} resource="contacts">
    <Form />
  </Edit>
);

export default ContactsEdit;

import React from 'react';
import { Steps } from 'antd';
import i18next from 'i18next';
import { VerticalSteps } from './styles';

const CREATE_AD_STEPS = [
  {
    title: 'advertisement.typeOfAd',
  },
  {
    title: 'advertisement.adCreative',
  },
  {
    title: 'advertisement.identity',
  },
  {
    title: 'advertisement.targetedAudience',
  },
  {
    title: 'advertisement.duration',
  },
];

interface CreateAdStepsProps {
  currentStep?: number;
  onChange?: (current: number) => void;
}

const CreateAdSteps = ({ currentStep, onChange }: CreateAdStepsProps) => (
  <VerticalSteps direction="vertical" current={currentStep} onChange={onChange}>
    {CREATE_AD_STEPS.map((item, index) => (
      <Steps.Step
        title={i18next.t(item?.title)}
        key={Number(index)}
        className="mb-30"
      />
    ))}
  </VerticalSteps>
);

export default CreateAdSteps;

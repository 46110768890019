import React from 'react';
import PropTypes from 'prop-types';
import { Avatar } from 'antd';
import { PictureFilled } from '@ant-design/icons';

const JobListingImageInfo = ({ images }) => (
  <div className="avatar-job">
    <Avatar shape="square" icon={<PictureFilled />} src={images} size={246} />
  </div>
);

JobListingImageInfo.propTypes = {
  images: PropTypes.string,
};

export default JobListingImageInfo;

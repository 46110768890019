import React from 'react';
import { JobTemplateItemWrapperStyles } from 'containers/JobTemplates/components/ExistingJobTemplateGrid/JobTemplateItem/styles';
import { Card, Tag } from 'antd';
import Meta from 'antd/lib/card/Meta';
import { BriefcaseIcon, DollarSquareIcon } from 'components/common/SVGIcon';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router';
import { getFilterFromUrl } from 'utils/tools';
import i18next from 'i18next';

const JobTemplateItem = ({
  template = {},
  selectingTemplateId,
  setSelectingTemplateId,
}) => {
  const {
    templateName,
    salary,
    discount,
    jobTitle,
    jobType,
    formattedUpdatedAt,
  } = template;

  const { search } = useLocation();
  const { filter } = getFilterFromUrl(search);

  const handleSelectTemplate = () => {
    if (template?.id !== selectingTemplateId) {
      setSelectingTemplateId(template?.id);
    }
  };

  return (
    <JobTemplateItemWrapperStyles>
      <Card
        bordered
        className={
          template?.id === selectingTemplateId ||
          `${template?.id}` === `${filter?.id}`
            ? 'template-card-selected'
            : ''
        }
        hoverable
        onClick={handleSelectTemplate}
      >
        <Meta
          title={templateName}
          description={jobTitle}
          className="template-card-meta"
        />
        <div className="template-card-content">
          <DollarSquareIcon />
          <span className="template-card-salary-text">
            {salary?.displayText}
          </span>
        </div>
        <div className="template-card-content">
          <div className="template-card-discount">
            {discount && (
              <span>
                {discount?.type?.iconUrl && (
                  <>
                    <img src={discount?.type?.iconUrl} alt="ic" width={14} />
                    {discount?.value}
                    <span className="dot-circle">&#9679;</span>
                  </>
                )}
              </span>
            )}
            {jobType?.name ? (
              <Tag>
                <BriefcaseIcon />
                {jobType?.name}
              </Tag>
            ) : null}
          </div>
        </div>
        <div className="template-card-content">
          <span className="template-card-last-updated">
            {i18next.t('jobs.jobTemplates.lastUpdatedTime', {
              time: formattedUpdatedAt,
            })}
          </span>
        </div>
      </Card>
    </JobTemplateItemWrapperStyles>
  );
};

JobTemplateItem.propTypes = {
  template: PropTypes.object,
  selectingTemplateId: PropTypes.string,
  setSelectingTemplateId: PropTypes.func,
};

export default JobTemplateItem;

import React from 'react';
import i18next from 'i18next';
import { Button } from 'antd';
import PropTypes from 'prop-types';
import { handleViewFile } from 'utils/fileUtils';
import { useViewFileModal } from '../ViewFileModal/context';

const OpenFileButton = ({ file }) => {
  const { toggleModal, setFile } = useViewFileModal();

  const handleClick = () => {
    handleViewFile(file, (result) => {
      if (result?.visible) {
        toggleModal();
        setFile(result.data);
      }
    });
  };

  return <Button onClick={handleClick}>{i18next.t('button.open')}</Button>;
};

OpenFileButton.propTypes = {
  file: PropTypes.object,
};

export default OpenFileButton;

import {
  DEFAULT_CURRENT_PAGE,
  DEFAULT_SORT_JOB_TEMPLATE,
  LIMIT_ITEM,
  VIEW_TYPE_JOB_LISTINGS,
} from 'containers/JobListings/constants';
import Summaries from 'containers/JobListings/Tabs/JobTemplateView/Summaries';
import { isEmpty } from 'lodash-es';
import React, { useCallback, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router';
import {
  getAllJobListings,
  getSummaryJobListing,
} from 'redux/jobs/jobListings/actions';
import { getJobListingViewByJobTemplate } from 'redux/jobs/jobListings/selectors';
import { getFilterFromUrl, getSearchUrl } from 'utils/tools';
import NoJobListings from '../NoJobListings';
import JobTemplateBoard from './BoardView';
import Filter from './Filter';
import JobTemplateTable from './TableView';

const JobTemplateView = () => {
  const dispatch = useDispatch();
  const { location, push } = useHistory();

  const { search } = useLocation();
  const { filter } = getFilterFromUrl(search);
  const filterRef = useRef(filter);
  const { tabMode, viewMode } = useParams();

  const { data: jobTemplateData, loading } = useSelector(
    getJobListingViewByJobTemplate,
  );

  const retrieveList = useCallback(
    ({ filter = {}, isRefresh, keepFilter }) => {
      dispatch(
        getAllJobListings({
          data: {
            ...filter,
            viewType: VIEW_TYPE_JOB_LISTINGS?.TEMPLATE,
          },
          options: {
            keepFilter,
            isRefresh,
          },
        }),
      );
      dispatch(
        getSummaryJobListing({
          ...filter,
          viewType: VIEW_TYPE_JOB_LISTINGS.TEMPLATE,
        }),
      );
    },
    [dispatch],
  );

  useEffect(() => {
    if (tabMode === VIEW_TYPE_JOB_LISTINGS?.TEMPLATE) {
      retrieveList({
        filter: {
          sorts: filterRef.current.sorts || [DEFAULT_SORT_JOB_TEMPLATE],
          page: filterRef.current.page || DEFAULT_CURRENT_PAGE,
          size: filterRef.current.size || LIMIT_ITEM,
          filter: filterRef.current.filter,
        },
        isRefresh: true,
        keepFilter: true,
      });
    }
  }, [retrieveList, tabMode]);

  const pushQuery = useCallback(
    (searchStr) => {
      push(`${location.pathname}?${getSearchUrl(searchStr)}`);
    },
    [location.pathname, push],
  );

  const onViewModeChange = (mode) => {
    push(`/job-listings/template/${mode}`);
  };

  const content =
    viewMode === 'board' ? (
      <JobTemplateBoard
        jobTemplateData={jobTemplateData}
        retrieveList={retrieveList}
        pushQuery={pushQuery}
        loading={loading}
      />
    ) : (
      <JobTemplateTable
        jobTemplateData={jobTemplateData}
        retrieveList={retrieveList}
        pushQuery={pushQuery}
        loading={loading}
      />
    );

  return (
    <div>
      <Summaries />
      <Filter
        retrieveList={retrieveList}
        pushQuery={pushQuery}
        onViewChange={onViewModeChange}
        viewMode={viewMode}
      />
      {jobTemplateData?.length || loading || !isEmpty(filter?.filter) ? (
        content
      ) : (
        <NoJobListings
          title="jobListings.noJobListings"
          nameButton="jobListings.buttons.createJobListingOnOnceLine"
          description="jobListings.noJobListingsDesc"
          isShowCreateJobListing
        />
      )}
    </div>
  );
};

export default JobTemplateView;

import React from 'react';
import i18next from 'i18next';
import { Result } from 'antd';
import { useSelector } from 'react-redux';
import { getCentreId } from 'redux/auth/selectors';
import { ROLES_CONST } from 'configs/localData/permissions';

import Edit from 'containers/rest/Edit';
import { omit } from 'lodash-es';
import CreateEditStyles from '../styles';
import Form from '../components/Form';

const UsersEdit = (props) => {
  const centreId = useSelector(getCentreId);
  const currentData = useSelector((state) => state.users.currentData) || {};

  const breadcrumbCustom = [
    {
      title: i18next.t('sideBar.settings'),
      path: '/config',
    },
    {
      title: i18next.t('users.header'),
      path: '/config/users',
    },
    {
      title: i18next.t('users.editPage'),
    },
  ];

  const formatOnSubmit = (values) => omit(values, 'email');

  const isWarning =
    centreId &&
    [
      ROLES_CONST.providerStaff,
      ROLES_CONST.multiCentresAdmin,
      ROLES_CONST.multiCentresStaff,
    ].includes(currentData.role?.name);

  return (
    <CreateEditStyles>
      <Edit
        {...props}
        resource="users"
        defaultOptions={{ suffixResourceApi: 'centreUsers' }}
        formatOnSubmit={formatOnSubmit}
        breadcrumbCustom={breadcrumbCustom}
        header="users.editPage"
        isResetStyle
        okText="button.editUser"
        isShowAction={!isWarning}
        formProps={{ preserve: false }}
      >
        <div>
          <div className="create-user-title">
            {i18next.t('users.editUserInformation')}
          </div>
          {isWarning ? (
            <Result
              status="warning"
              title={i18next.t('users.warningSelectCentreHeader')}
            />
          ) : (
            <Form isEdit />
          )}
        </div>
      </Edit>
    </CreateEditStyles>
  );
};

export default UsersEdit;

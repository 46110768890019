import React from 'react';
import PropTypes from 'prop-types';
import { Button, Col, Layout, Row } from 'antd';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import i18next from 'i18next';
import { useSelector } from 'react-redux';
import {
  getJobTemplateCurrentStepSelector,
  getLoadingCreateJobTemplate,
} from 'redux/jobs/jobTemplates/selectors';
import { CREATE_JOB_TEMPLATE_FINAL_STEP } from 'containers/JobTemplates/constants';

const JobTemplateFooter = ({ handleGoToNextStep, handleGoToPrevStep }) => {
  const currentStep = useSelector(getJobTemplateCurrentStepSelector);
  const createLoading = useSelector(getLoadingCreateJobTemplate);

  const isFinalStep = currentStep === CREATE_JOB_TEMPLATE_FINAL_STEP;

  const isDisabledPublish = isFinalStep && createLoading;

  return (
    <Layout.Footer>
      <Row justify="space-between">
        <Col>
          <Button
            onClick={() => handleGoToPrevStep()}
            icon={<LeftOutlined />}
            className="btn-back"
          >
            {i18next.t('button.back')}
          </Button>
        </Col>
        <Col>
          <Button
            type="primary"
            onClick={() => handleGoToNextStep()}
            disabled={isDisabledPublish}
          >
            {isFinalStep
              ? i18next.t('button.publish')
              : i18next.t('button.next')}
            <RightOutlined />
          </Button>
        </Col>
      </Row>
    </Layout.Footer>
  );
};

JobTemplateFooter.propTypes = {
  handleGoToPrevStep: PropTypes.func,
  handleGoToNextStep: PropTypes.func,
};

export default JobTemplateFooter;

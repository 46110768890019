import React from 'react';
import Create from 'containers/rest/Create';
import Form from '../components/Form';

const EnquiriesCreate = (props) => (
  <Create {...props} resource="enquiries">
    <Form />
  </Create>
);

export default EnquiriesCreate;

import React from 'react';
import { Row, Col } from 'antd';
import { isEmpty } from 'lodash-es';
import PieChartCustom from 'components/charts/PieChartCustom';
import PropTypes from 'prop-types';
import CardCustom from 'containers/CompanyBenchmarking/components/CardCustom';
import RatingTypesSection from '../RatingTypesSection';
import { PieChartRatingStyles } from '../KindicareRatingPieChart/styles';

const NQSRatingPieChart = ({ title, resultNQSRating }) =>
  !isEmpty(resultNQSRating) && (
    <CardCustom title={title}>
      <PieChartRatingStyles>
        <Row align="middle" className="nqs-rating-style">
          <Col xxl={10} xl={24} sm={11} xs={24}>
            <PieChartCustom
              data={resultNQSRating}
              innerRadius={0}
              paddingAngle={0}
              isShowLabel={false}
              isShowLegend={false}
              cy="50%"
              cx="50%"
              height={200}
            />
          </Col>
          <Col
            xxl={14}
            xl={24}
            sm={13}
            xs={24}
            className="flex-column-center p-0"
          >
            <RatingTypesSection data={resultNQSRating} />
          </Col>
        </Row>
      </PieChartRatingStyles>
    </CardCustom>
  );

NQSRatingPieChart.propTypes = {
  title: PropTypes.string,
  resultNQSRating: PropTypes.array,
};

export default NQSRatingPieChart;

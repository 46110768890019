import React from 'react';
import PropTypes from 'prop-types';
import { Circle, DotCircleContainer } from './styles';

const DotCircle = ({ size, color, position, spaceSize, className }) => (
  <DotCircleContainer
    position={position}
    spaceSize={spaceSize}
    className={className}
  >
    <Circle size={size} color={color} />
  </DotCircleContainer>
);

DotCircle.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
  position: PropTypes.oneOf(['middle', 'bottom']),
  spaceSize: PropTypes.number,
  className: PropTypes.string,
};

DotCircle.defaultProps = {
  position: 'middle',
};

export default DotCircle;

import React from 'react';
import CheckPermissions from 'components/common/CheckPermissions';
import DeleteBtnFallback from './DuplicateBtnFallback';
import { DuplicateButtonStyles } from './styles';
import DuplicateButtonWrapper from './DuplicateButtonWrapper';

interface Props {
  permissionKey?: string;
  action?: string;
  onClickCustom?: () => void;
  disabled?: boolean;
  isSkipSubscription?: boolean;
  packageName?: string;
}

const DuplicateButton = ({
  permissionKey,
  action,
  onClickCustom,
  disabled,
  isSkipSubscription,
  packageName,
}: Props) => {
  const handleDuplicate = () => {
    onClickCustom();
  };
  return (
    <DuplicateButtonStyles className="delete-div-button">
      <CheckPermissions
        packageName={packageName}
        permissionKey={permissionKey}
        action={action}
        isSkipSubscription={isSkipSubscription}
        isDisabled
        fallback={<DeleteBtnFallback />}
      >
        <DuplicateButtonWrapper
          disabled={disabled}
          handleDuplicate={handleDuplicate}
        />
      </CheckPermissions>
    </DuplicateButtonStyles>
  );
};

export default DuplicateButton;

import styled from 'styled-components';

export const CompanyRestInfoStyles = styled.div`
  .wrapper-info {
    width: 100%;
    .ant-space-item {
      width: 100%;
    }
  }
`;

import { Form } from 'antd';
import { BasicInformationStyle } from 'containers/JobTemplates/styles';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setJobTemplatePayload } from 'redux/jobs/jobTemplates/slice';
import useWindowAutoScroll from 'hooks/useWindowAutoScroll';
import { isEmpty } from 'lodash-es';
import { getJobTemplateConfigSelector } from 'redux/jobs/jobTemplates/selectors';
import { getDefaultPayTypeId } from 'containers/JobTemplates/utils';
import BasicInformationGeneralForm from '../../BasicInformationGeneralForm';

const BasicInformationForm = ({ form, initTemplate }) => {
  useWindowAutoScroll();
  const dispatch = useDispatch();

  const config = useSelector(getJobTemplateConfigSelector);

  const defaultPayTypeId = getDefaultPayTypeId(config);

  const onFinish = ({ discount, eligibility, ...values }) => {
    dispatch(
      setJobTemplatePayload({
        ...values,
        discount: {
          ...discount,
          value: discount?.value ?? 0,
        },
        eligibility: isEmpty(eligibility) ? undefined : eligibility,
      }),
    );
  };

  useEffect(() => {
    form.resetFields();
    if (!isEmpty(initTemplate)) {
      form.setFieldsValue(initTemplate);
    }
  }, [form, initTemplate]);

  return (
    <BasicInformationStyle>
      <Form
        scrollToFirstError
        layout="vertical"
        form={form}
        onFinish={onFinish}
        initialValues={{
          salary: {
            isIncludesCasualLoading: false,
            isIncludesSuper: false,
            payTypeId: defaultPayTypeId,
          },
          minJobMatch: 0,
        }}
      >
        <BasicInformationGeneralForm form={form} record={initTemplate} />
      </Form>
    </BasicInformationStyle>
  );
};

BasicInformationForm.propTypes = {
  form: PropTypes.object,
  initTemplate: PropTypes.object,
};

export default BasicInformationForm;

import { createSlice } from '@reduxjs/toolkit';
import { getAllResources } from './actions';

export const initialState = {
  data: [],
};

const getAllResourcesPending = (state) => {
  state.loading = true;
};

const getAllResourcesSuccess = (state, { payload }) => {
  state.loading = false;
  state.data = payload;
};

const getAllResourcesFail = (state, { payload }) => {
  state.loading = false;
  state.error = payload;
};

const { reducer } = createSlice({
  name: 'Resources',
  initialState,
  extraReducers: {
    [getAllResources.pending]: getAllResourcesPending,
    [getAllResources.fulfilled]: getAllResourcesSuccess,
    [getAllResources.rejected]: getAllResourcesFail,
  },
});

export default reducer;

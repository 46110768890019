import { Button, Form, FormInstance } from 'antd';
import i18next from 'i18next';
import React, { useState } from 'react';
import FormEditAudience from 'containers/Advertisement/components/forms/FormEditAudience';
import { TargetedAudience, UpsertAudienceBody } from 'api/advertisement/type';
import { useDispatch } from 'react-redux';
import { createAdAudience } from 'redux/advertisement/actions';
import SaveAudienceModal from './SaveAudienceModal';

interface Props {
  form: FormInstance;
  onFinish?: () => void;
  afterSavedAudience?: () => void;
  initialValues?: TargetedAudience;
}

const NewAudience = ({
  form,
  onFinish,
  afterSavedAudience,
  initialValues,
}: Props) => {
  const dispatch = useDispatch();
  const [saveAudienceVisible, setSaveAudienceVisible] = useState(false);
  const [loading, setLoading] = useState(false);

  const onCancelSaveAudience = () => setSaveAudienceVisible(false);

  const onSaveAudience = () => {
    form
      .validateFields([
        'userType',
        'userLocation',
        'userLocationValues',
        'name',
      ])
      .then(async (values: UpsertAudienceBody) => {
        setLoading(true);
        try {
          await dispatch(createAdAudience(values));
          afterSavedAudience();
        } finally {
          setLoading(false);
          form.resetFields();
          onCancelSaveAudience();
        }
      });
  };

  return (
    <Form
      form={form}
      onFinish={onFinish}
      layout="vertical"
      wrapperCol={{ span: 24 }}
      initialValues={initialValues}
    >
      <FormEditAudience form={form} />
      <Button
        onClick={() => setSaveAudienceVisible(true)}
        className="btn-line-primary fw-bold mt-32"
      >
        {i18next.t('advertisement.saveAudience')}
      </Button>
      <SaveAudienceModal
        onOk={onSaveAudience}
        visible={saveAudienceVisible}
        onCancel={onCancelSaveAudience}
        loading={loading}
      />
    </Form>
  );
};
export default NewAudience;

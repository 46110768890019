import React from 'react';
import { useHistory } from 'react-router';
import EditButton from 'components/RestActions/EditButton';
import { Space, Tag, Tooltip } from 'antd';
import { BriefcaseIcon, RoundOpenInNewIcon } from 'components/common/SVGIcon';
import { JOB_TEMPLATE_STATUS } from 'configs/localData';
import TagCustom from 'components/common/TagCustom';
import { isEmpty } from 'lodash-es';
import { getSearchUrl } from 'utils/tools';
import { DEFAULT_SORT_JOB_APPLICATIONS } from 'containers/JobApplications/constants';
import { DEFAULT_CURRENT_PAGE, DEFAULT_SORT_INDIVIDUAL } from './constants';

const useFormat = () => {
  const { push } = useHistory();

  const formatJobTemplateName = (data, record) => {
    const handleClick = () => {
      push(
        `/job-listings/individual/list?${getSearchUrl({
          page: DEFAULT_CURRENT_PAGE,
          sorts: [DEFAULT_SORT_INDIVIDUAL],
          filter: {
            templateName: data,
            templateId: record?.id,
          },
        })}`,
      );
    };
    return (
      <div className="t-bold black ellipsis-t-2 link-class cursor-pointer">
        <Tooltip onClick={handleClick} title={data}>
          {data}
        </Tooltip>
      </div>
    );
  };

  const formatJobType = (data) =>
    !isEmpty(data) && (
      <Tag icon={<BriefcaseIcon />} className="text-14 fw-600">
        {data}
      </Tag>
    );

  const formatJobTemplateStatus = (data) => {
    const item = JOB_TEMPLATE_STATUS.find((item) => item.value === data);
    if (!item) return data;
    return (
      <TagCustom
        className="fw-bold"
        color={item.color}
        backgroundColor={item.backgroundColor}
        text={item.text}
      />
    );
  };

  const formatDiscount = (data) =>
    data && (
      <Tooltip title={data?.type?.name || ''}>
        <div className="flex items-center">
          {data?.type?.iconUrl && (
            <img
              src={data?.type?.iconUrl}
              alt=""
              width={18}
              height={18}
              className="mr-10"
            />
          )}
          <span>{data?.value || ''}</span>
        </div>
      </Tooltip>
    );

  const formatGroupAction = (data, record) => (
    <div className="flex-center-between">
      <EditButton
        isView
        title="jobs.jobTemplates.viewTemplate"
        source="show"
        onClickCustom={() => {
          push(
            `/job-listings/individual/list?${getSearchUrl({
              page: DEFAULT_CURRENT_PAGE,
              sorts: [DEFAULT_SORT_INDIVIDUAL],
              filter: {
                templateName: data,
                templateId: record?.id,
              },
            })}`,
          );
        }}
      />
    </div>
  );

  const formatJobTemplateApplicationAction = (data, record) => {
    const handleClick = () => {
      window.open(
        `/job-applications/board?${getSearchUrl({
          page: DEFAULT_CURRENT_PAGE,
          sorts: [DEFAULT_SORT_JOB_APPLICATIONS],
          filter: {
            jobTemplate: record?.templateName,
            jobTemplateId: record?.id,
          },
        })}`,
      );
    };
    return (
      <Space onClick={handleClick} className="cursor-pointer">
        <span className="mr-8 fw-bold text-decoration-line">{data}</span>
        <RoundOpenInNewIcon />
      </Space>
    );
  };

  return {
    formatJobTemplateName,
    formatJobType,
    formatJobTemplateStatus,
    formatDiscount,
    formatGroupAction,
    formatJobTemplateApplicationAction,
  };
};

export default useFormat;

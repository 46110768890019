import React, { useRef } from 'react';
import { Button } from 'antd';
import { useParams } from 'react-router';
import i18next from 'i18next';
import { ExclamationCircleFilled } from '@ant-design/icons';
import LinkEmail from 'components/common/LinkEmail';
import { SUPPORT_EMAIL } from 'configs/localData/constant';
import { ResendBoxStyles } from './styles';
import ResendEmailModal from '../CentreModal/ResendEmailModal';

interface ResendEmailModal {
  openModal: () => void;
}

const ResendVerificationEmail = () => {
  const { id } = useParams();
  const resendEmailModalRef = useRef<ResendEmailModal>();

  const onClickResend = () => {
    resendEmailModalRef?.current && resendEmailModalRef.current.openModal();
  };

  return (
    <ResendBoxStyles>
      <div>
        <div className="header flex-center-start mb-12">
          <ExclamationCircleFilled className="text-20 text-secondary-blue mr-10" />
          <h5 className="text-16 fw-bold text-secondary-blue">
            {i18next.t('centresClaim.receiveEmail.l1')}
          </h5>
        </div>
        <p className="black">
          {i18next.t('centresClaim.receiveEmail.l2')}
          <LinkEmail email={SUPPORT_EMAIL} />
        </p>
      </div>
      <Button
        size="large"
        type="primary"
        onClick={onClickResend}
        className="ml-12"
      >
        {i18next.t('button.resendVerificationEmail')}
      </Button>

      <ResendEmailModal id={id} ref={resendEmailModalRef} />
    </ResendBoxStyles>
  );
};

export default ResendVerificationEmail;

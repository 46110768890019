import React, { useCallback, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { getCurrentEnquiry } from 'redux/enquiries/selectors';
import {
  getMessages,
  getByIdEnquiries,
  clearCurrentEnquiries,
} from 'redux/enquiries/actions';
import { DEFAULT_INBOX_ID } from 'configs/localData';

import EmptyData from 'components/common/EmptyData';

import i18next from 'i18next';
import MessageDetailStyles from './styles';
import MessageHeader from './MessageHeader';
import MessageBody from './MessageBody';
import MessageFooter from './MessageFooter';
import ArchiveModal from '../ArchiveModal';

const MessageDetail = ({ inboxId, getSummaries }) => {
  const dispatch = useDispatch();
  const currentMessage = useSelector(getCurrentEnquiry) || {};
  const archiveModalRef = useRef();

  useEffect(() => {
    if (inboxId && inboxId !== DEFAULT_INBOX_ID) {
      dispatch(
        getByIdEnquiries({
          data: { id: inboxId },
          options: { isRequestApi: true, customApiResource: 'inboxes' },
        }),
      );
    }
    return () => {
      dispatch(clearCurrentEnquiries());
    };
  }, [inboxId, dispatch]);

  const retrieveList = useCallback(
    (data, isRefresh) =>
      new Promise((resolve) => {
        if (inboxId && inboxId !== DEFAULT_INBOX_ID) {
          resolve(
            dispatch(
              getMessages({
                data: { id: inboxId, orderBy: '-createdAt', ...data },
                options: { isRefresh },
              }),
            ),
          );
        }
      }),
    [inboxId, dispatch],
  );

  const openArchiveModal = () => {
    archiveModalRef?.current && archiveModalRef.current.open(currentMessage.id);
  };

  return (
    <MessageDetailStyles className="message-detail">
      {currentMessage.id ? (
        <>
          <MessageHeader
            user={currentMessage.user}
            enquiryType={currentMessage.enquiryType}
            id={inboxId}
            centreId={currentMessage.centreId}
            isShowArchive={!currentMessage.archivedAt}
            archivedReason={currentMessage.archivedReason}
            openArchiveModal={openArchiveModal}
            getSummaries={getSummaries}
          />
          <MessageBody
            listMessages={currentMessage.listMessages}
            resourceData={currentMessage.resourceData}
            retrieveList={retrieveList}
          />
          <MessageFooter
            id={inboxId}
            isUploadFile
            centreResponded={currentMessage.centreResponded}
          />
        </>
      ) : (
        <EmptyData
          className="mt-20"
          description={i18next.t('enquiries.notSelectedEnquiry')}
        />
      )}

      <ArchiveModal ref={archiveModalRef} />
    </MessageDetailStyles>
  );
};

MessageDetail.propTypes = {
  inboxId: PropTypes.string,
  getSummaries: PropTypes.func,
};

MessageDetail.propTypes = {
  inboxId: PropTypes.string,
};

export default MessageDetail;

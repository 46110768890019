import styled from 'styled-components';
import ModalCustom from 'components/common/ModalCustom';
import theme from 'configs/theme';

export const ModalCustomStyle = styled(ModalCustom)`
  background-color: transparent;
  .ant-modal-body {
    background-color: ${theme.background.container};
    padding: 24px !important;
  }
  .ant-modal-footer {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    .ant-btn.cancel-button {
      color: ${theme.background.content} !important;
      background-color: ${theme.background.primary};
    }
  }
`;

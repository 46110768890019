import { get } from 'api/utils';
import { PREFIX_API_VER_2 } from 'configs/localData/constant';
import { useState, useEffect, useCallback } from 'react';
import { debounce } from 'lodash-es';

const BROWSER_OFFSET = 10;

interface Props {
  apiUrl: string;
  prefixApi?: string;
  searchProp?: string;
  variables?: object;
}

const useInfiniteLoadQuery = ({
  apiUrl,
  prefixApi = PREFIX_API_VER_2,
  searchProp = 'q',
  variables,
}: Props) => {
  const [data, setData] = useState<unknown[]>([]);
  const [initialData, setInitialData] = useState<ListResponse<unknown>>();
  const [pagination, setPagination] = useState({
    page: 1,
    size: 10,
    total: 10,
  });
  const [pageNumber, setPageNumber] = useState(1);
  const [loading, setLoading] = useState(false);
  const [isSearching, setIsSearching] = useState(false);

  const hasMore =
    pagination.page < Math.ceil(pagination.total / pagination.size);

  const retrieveList = useCallback(
    async (queryParams) => {
      setLoading(true);
      try {
        const response = await get(
          apiUrl,
          {
            page: 1,
            size: 10,
            ...queryParams,
          },
          prefixApi,
        );

        return response;
      } catch (error) {
        return Promise.reject();
      } finally {
        setLoading(false);
      }
    },
    [apiUrl, prefixApi],
  );

  const retrieveListFirstTime = useCallback(() => {
    setData(initialData?.results || []);
    setPagination(initialData?.paging);
    setPageNumber(2);
  }, [initialData]);

  useEffect(() => {
    retrieveList(variables).then(({ results, paging }) => {
      setData(results);
      setPageNumber(2);
      setPagination(paging);
      setInitialData({ results, paging });
    });
  }, [retrieveList, variables]);

  const loadMore = (event: React.UIEvent) => {
    if (!hasMore) return;
    const target = event.target as HTMLDivElement;
    if (
      !loading &&
      target.scrollTop + target.offsetHeight <=
        target.scrollHeight + BROWSER_OFFSET &&
      target.scrollTop + target.offsetHeight >=
        target.scrollHeight - BROWSER_OFFSET
    ) {
      retrieveList({
        page: pageNumber,
      }).then((data) => {
        setPageNumber((currentPage) => currentPage + 1);
        setData((oldData) => [...oldData, ...(data?.results || [])]);
        setPagination(data.paging);
      });
    }
  };

  const onSearch = debounce((value: string) => {
    if (!value) {
      setIsSearching(false);
    } else {
      setIsSearching(true);
    }

    retrieveList({
      ...variables,
      page: 1,
      size: 10,
      filter: {
        [searchProp]: value,
      },
    }).then((data) => {
      setPageNumber(1);
      setData(data?.results ?? []);
      setPagination(data.paging);
    });
  }, 400);

  const onBlur = () => setIsSearching(false);

  return {
    data,
    loading,
    onSearch,
    loadMore,
    onBlur,
    retrieveListFirstTime,
    setData,
    setPageNumber,
    setPagination,
    hasMore,
    pageNumber,
    isSearching,
  };
};

export default useInfiniteLoadQuery;

import styled from 'styled-components';

const LayoutContentWrapper = styled.div`
  width: 100%;
  display: flex;
  height: 100%;
  flex: 1;
  flex-direction: column;
`;

export { LayoutContentWrapper };

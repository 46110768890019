import { createSlice } from '@reduxjs/toolkit';
import {
  COMPARE_OPTIONS,
  RANGE_PICKER_OPTION_CUSTOM,
} from 'containers/MarketingMetrics/constants';
import { MODEL_NAME } from './actions';

interface InitialState {
  loading: boolean;
  isCompare: boolean;
  dateOption?: string;
  compareOption: string;
}

const initialState: InitialState = {
  loading: false,
  isCompare: true,
  dateOption: RANGE_PICKER_OPTION_CUSTOM.last7Days.key,
  compareOption: COMPARE_OPTIONS.precedingPeriod,
};

const marketingMetrics = createSlice({
  name: MODEL_NAME,
  initialState,
  reducers: {
    setDateOption: (state, { payload }) => {
      state.dateOption = payload;
    },
    setIsCompare: (state, { payload }) => {
      state.isCompare = payload;
    },

    setCompareOption: (state, { payload }) => {
      state.compareOption = payload;
    },

    setDateDefaultWhenClean: (state) => {
      state.dateOption = RANGE_PICKER_OPTION_CUSTOM.last7Days.key;
      state.isCompare = true;
      state.compareOption = COMPARE_OPTIONS.precedingPeriod;
    },
  },
});

export const {
  setDateDefaultWhenClean,
  setDateOption,
  setIsCompare,
  setCompareOption,
} = marketingMetrics.actions;
export default marketingMetrics.reducer;

import React from 'react';
import PropTypes from 'prop-types';
import { Radio } from 'antd';
import i18next from 'i18next';

import { LayoutListIcon, MapViewIcon } from 'components/common/SVGIcon';
import ViewModeSwitcherStyles from 'components/common/ViewModeSwitcher/styles';

const ViewModeSwitcher = ({ onChange, activeTab }) => (
  <ViewModeSwitcherStyles>
    <Radio.Group
      onChange={onChange}
      value={activeTab}
      className="group-tab mb-16 mt-32"
    >
      <Radio.Button value="list">
        <div>
          <LayoutListIcon className="icon-tab" />
          {i18next.t('text.list')}
        </div>
      </Radio.Button>
      <Radio.Button value="mapView">
        <div className="title-tab">
          <MapViewIcon className="icon-tab" />
          {i18next.t('text.mapView')}
        </div>
      </Radio.Button>
    </Radio.Group>
  </ViewModeSwitcherStyles>
);

ViewModeSwitcher.propTypes = {
  onChange: PropTypes.func,
  activeTab: PropTypes.string,
};

export default ViewModeSwitcher;

import React from 'react';
import { Avatar, Button, Space, Tooltip, Typography } from 'antd';
import TagCustom from 'components/common/TagCustom';
import { EyeOutlined, UserOutlined } from '@ant-design/icons';
import { formatNullString } from 'utils/tools';
import CheckPermission from 'components/common/CheckPermissions';
import { isEmpty } from 'lodash-es';
import i18next from 'i18next';
import { PERMISSION_KEY } from 'configs/localData';
import ContactUserFallback from './ContactUserFallback';
import ViewBtnFallback from './ViewBtnFallback';
import { ViewButtonStyles } from './styles';

const useFormat = () => {
  const formatPhoneNumber = (data, record) => (
    <div className={record?.isBlur ? 'blur' : ''}>{formatNullString(data)}</div>
  );

  const formatUserName = (data, record) => {
    const handleViewContactDetails = () => {
      if (!isEmpty(record?.contactId) && !record?.isBlur) {
        return window.open(`/contacts/list/${record?.contactId}/show`);
      }
      return null;
    };

    return (
      <div className="cursor-pointer flex items-center text-black">
        <Space>
          <CheckPermission
            permissionKey={PERMISSION_KEY.leadActivities}
            action="isView"
            packageName={record?.subscription?.name}
            isDisabled
            fallback={
              <ContactUserFallback currentCentreId={record?.centre?.centreId} />
            }
          >
            <Avatar
              size={45}
              src={data?.avatar}
              alt="avatar"
              icon={<UserOutlined />}
            />
          </CheckPermission>
          <div className={`text-14 ${record?.isBlur ? 'blur' : ''}`}>
            {isEmpty(data?.email) && isEmpty(data?.fullName) ? (
              <div>
                <Typography.Text className="fw-bold">
                  {i18next.t('leadActivities.nonLoggedInUser')}
                </Typography.Text>
              </div>
            ) : (
              <div>
                <Tooltip title={data?.fullName}>
                  <div
                    role="presentation"
                    className={`ellipsis-t-2 fw-bold  ${
                      record?.contactId && !record?.isBlur && 'link-class'
                    } `}
                    onClick={handleViewContactDetails}
                  >
                    {data?.fullName || ''}
                  </div>
                </Tooltip>
                <Tooltip title={data?.email}>
                  <span className="ellipsis-t-1 text-gray-500 fw-400">
                    {data?.email}
                  </span>
                </Tooltip>
              </div>
            )}
          </div>
        </Space>
      </div>
    );
  };

  const formatCentreName = (data) => {
    const handleViewCentreDetails = () => {
      window.open(`/centres/${data?.centreId}/show/summary`);
    };

    return (
      <div
        role="presentation"
        className="cursor-pointer flex items-center"
        onClick={handleViewCentreDetails}
      >
        <Space>
          <Avatar size={48} src={data?.logo} alt="" icon={<UserOutlined />} />
          <Tooltip title={data?.centreName}>
            <div className="t-bold black ellipsis-t-2 link-class">
              {`${data?.centreName || ''}`}
            </div>
          </Tooltip>
        </Space>
      </div>
    );
  };

  const formatTagType = (data) =>
    data?.name && (
      <TagCustom
        className="fw-bold"
        color={data?.textColor}
        backgroundColor={data?.backgroundColor}
        text={data?.name}
        isBorder={data?.isBorder}
        isShowTooltip
      />
    );

  const formatGroupAction = (record) => {
    const handleViewContactDetails = () => {
      window.open(`/contacts/list/${record?.contactId}/show`);
    };
    return (
      <ViewButtonStyles contactId={record?.contactId}>
        <CheckPermission
          packageName={record?.subscription?.name}
          isDisabled
          permissionKey={PERMISSION_KEY.leadActivities}
          action="isView"
          fallback={
            <ViewBtnFallback
              currentCentreId={record?.centre?.centreId}
              contactId={record?.contactId}
            />
          }
        >
          <Tooltip
            placement="topRight"
            title={
              !record?.contactId &&
              i18next.t('leadActivities.userNotExistContacts')
            }
            overlayInnerStyle={{ maxWidth: 140, textAlign: 'center' }}
          >
            <Button
              disabled={!record?.contactId}
              icon={<EyeOutlined />}
              onClick={handleViewContactDetails}
            />
          </Tooltip>
        </CheckPermission>
      </ViewButtonStyles>
    );
  };

  const formatData = (data) => (
    <Tooltip title={data}>
      <span className="ellipsis-t-2">{formatNullString(data)}</span>
    </Tooltip>
  );

  return {
    formatUserName,
    formatTagType,
    formatGroupAction,
    formatCentreName,
    formatPhoneNumber,
    formatData,
  };
};

export default useFormat;

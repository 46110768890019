import { Button } from 'antd';
import styled from 'styled-components';
import ModalCustom from 'components/common/ModalCustom';

export const CreateAdStyled = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background: radial-gradient(
    85.48% 229.42% at 1.95% 3.59%,
    rgba(255, 255, 255, 0.6) 0%,
    rgba(255, 255, 255, 0.13) 100%
  );
  backdrop-filter: blur(30px);
  border-radius: 24px;
  font-weight: 700;
  font-size: 36px;
  line-height: 44px;
  letter-spacing: -0.02em;
  color: ${({ theme }) => theme.palette.primary};

  @media only screen and (max-width: 992px) {
    display: none;
  }
`;

export const CreateAdOptionsWrapper = styled.div`
  width: 70%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin: 0 auto;
`;

export const OptionButton = styled(Button)`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  min-height: 72px;
  border: 1px solid #ebecf0;
  border-radius: 12px;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: ${({ theme }) => theme.text.blackNeutral800};
  transition: all 0.3s;

  .anticon {
    color: #38465c;
  }

  .arrow-icon {
    margin-left: auto !important;
  }

  &:hover .anticon {
    color: ${({ theme }) => theme.palette.primary};
  }
`;

export const SaveAndExitModalStyle = styled(ModalCustom)`
  .ant-modal-footer button {
    min-width: fit-content;
  }
`;

import React from 'react';
import PropTypes from 'prop-types';
import Icon from '@ant-design/icons';
import { ReactComponent as ICError } from 'assets/icons/ic-error.svg';
import { ReactComponent as ICWarning } from 'assets/icons/ic-warning.svg';

import { ReactComponent as ICProgramsLearning } from 'assets/icons/ic-programs-learning.svg';
import { ICTicked } from 'assets/icons/ic-ticket';
import { ReactComponent as ICYoutubeFill } from 'assets/icons/ic-youtube-fill.svg';
import { ReactComponent as ICPhoneRight } from 'assets/icons/ic-phone-right.svg';
import { ReactComponent as ICUnderline } from 'assets/icons/ic-underline.svg';
import { ReactComponent as ICCustomer } from '../../../assets/icons/ic-customer.svg';
import { ReactComponent as Dashboard } from '../../../assets/icons/ic-dashboard.svg';
import { ReactComponent as ICSettingFill } from '../../../assets/icons/ic-setting.svg';
import { ReactComponent as Reservations } from '../../../assets/icons/ic-severvations.svg';

import { ReactComponent as User } from '../../../assets/icons/ic-user.svg';
import { ReactComponent as Users } from '../../../assets/icons/ic-users.svg';
import { ReactComponent as Bookings } from '../../../assets/icons/group-3.svg';
import { ReactComponent as CheckIn } from '../../../assets/icons/ic-user-checkin.svg';
import { ReactComponent as Delivery } from '../../../assets/icons/delivery.svg';
import { ReactComponent as Pharmacy } from '../../../assets/icons/pharmacy.svg';
import { ReactComponent as Preparing } from '../../../assets/icons/preparing.svg';
import { ReactComponent as Sending } from '../../../assets/icons/sending.svg';
import { ReactComponent as Facebook } from '../../../assets/icons/ic-facebook.svg';
import { ReactComponent as Linkedin } from '../../../assets/icons/ic-linkedin.svg';
import { ReactComponent as TotalApplications } from '../../../assets/icons/ic-total-applications.svg';
import { ReactComponent as WaitingResponse } from '../../../assets/icons/ic-waiting-response.svg';
import { ReactComponent as TotalAccepted } from '../../../assets/icons/ic-total-accepted.svg';
import { ReactComponent as TotalValue } from '../../../assets/icons/ic-total-value.svg';
import { ReactComponent as LayoutList } from '../../../assets/icons/ic-layout-list.svg';
import { ReactComponent as LayoutBoard } from '../../../assets/icons/ic-layout-board.svg';
import { ReactComponent as Sorter } from '../../../assets/icons/ic-sorter.svg';
import { ReactComponent as Enquiries } from '../../../assets/icons/ic-enquiries.svg';
import { ReactComponent as File } from '../../../assets/icons/ic-file.svg';
import { ReactComponent as ArrowDown } from '../../../assets/icons/ic-arrow-down.svg';
import { ReactComponent as ArrowUp } from '../../../assets/icons/ic-arrow-up.svg';
import { ReactComponent as Mail } from '../../../assets/icons/ic-mail.svg';
import { ReactComponent as Women } from '../../../assets/icons/ic-women.svg';
import { ReactComponent as Send } from '../../../assets/icons/ic-send.svg';
import { ReactComponent as Attachment } from '../../../assets/icons/ic-attachment.svg';
import { ReactComponent as Refresh } from '../../../assets/icons/ic-refresh.svg';
import { ReactComponent as Centre } from '../../../assets/icons/ic-centre.svg';
import { ReactComponent as TotalCentres } from '../../../assets/icons/ic-total-centres.svg';
import { ReactComponent as TotalPlaces } from '../../../assets/icons/ic-total-places.svg';
import { ReactComponent as TotalEarning } from '../../../assets/icons/ic-total-earning.svg';
import { ReactComponent as TotalWaitlistValue } from '../../../assets/icons/ic-waitlist-value.svg';
import { ReactComponent as Child } from '../../../assets/icons/ic-child.svg';
import { ReactComponent as Service } from '../../../assets/icons/ic-service.svg';
import { ReactComponent as KindiCare } from '../../../assets/icons/ic-kindi-care.svg';
import { ReactComponent as Waitlist } from '../../../assets/icons/ic-waitlist.svg';
import { ReactComponent as ICCentreType } from '../../../assets/icons/ic-centre-type.svg';
import { ReactComponent as ICPhone } from '../../../assets/icons/ic-phone.svg';
import { ReactComponent as ICMousePointer } from '../../../assets/icons/ic-mouse-pointer.svg';
import { ReactComponent as ICBookTour } from '../../../assets/icons/ic-book-tour.svg';
import { ReactComponent as ICTotalEnquiries } from '../../../assets/icons/ic-total-enquiries.svg';
import { ReactComponent as ICTotalMoreInfo } from '../../../assets/icons/ic-total-more-info.svg';
import { ReactComponent as ICTotalCheckVacancies } from '../../../assets/icons/ic-total-check-vacancies.svg';
import { ReactComponent as ICFeeInformation } from '../../../assets/icons/ic-fee-information.svg';
import { ReactComponent as ICTotalOpenApplication } from '../../../assets/icons/ic-total-open-application.svg';
import { ReactComponent as ICTotalWaitlist } from '../../../assets/icons/ic-total-waitlist.svg';
import { ReactComponent as ICEdit } from '../../../assets/icons/ic-edit.svg';
import { ReactComponent as ICFilter } from '../../../assets/icons/ic-filter.svg';
import { ReactComponent as ICReply } from '../../../assets/icons/ic-reply.svg';
import { ReactComponent as ICCloseFill } from '../../../assets/icons/ic-close-fill.svg';
import { ReactComponent as ICUpload } from '../../../assets/icons/ic-upload.svg';
import { ReactComponent as ICIncomingCall } from '../../../assets/icons/ic-incoming-call.svg';
import { ReactComponent as ICOutgoingCall } from '../../../assets/icons/ic-outgoing-call.svg';
import { ReactComponent as ICNote } from '../../../assets/icons/ic-note.svg';
import { ReactComponent as ICEmail } from '../../../assets/icons/ic-email.svg';
import { ReactComponent as ICOther } from '../../../assets/icons/ic-other.svg';
import { ReactComponent as ICInterview } from '../../../assets/icons/ic-interview.svg';
import { ReactComponent as ICVisitTour } from '../../../assets/icons/ic-visit-tour.svg';
import { ReactComponent as ICExcel } from '../../../assets/icons/ic-excel.svg';
import { ReactComponent as ICTotalTransaction } from '../../../assets/icons/ic-total-transaction.svg';
import { ReactComponent as ICTotalTransactionValue } from '../../../assets/icons/ic-total-transaction-value.svg';
import { ReactComponent as ICTotalTransactionYear } from '../../../assets/icons/ic-total-transaction-year.svg';
import { ReactComponent as ICFileInbox } from '../../../assets/icons/ic-file-inbox.svg';
import { ReactComponent as ICReport } from '../../../assets/icons/ic-report.svg';
import { ReactComponent as ICMarketing } from '../../../assets/icons/ic-marketing.svg';
import { ReactComponent as ICTransactions } from '../../../assets/icons/ic-transactions.svg';
import { ReactComponent as ICFlag } from '../../../assets/icons/ic-flag.svg';
import { ReactComponent as ICTotalActivities } from '../../../assets/icons/ic-total-activities.svg';
import { ReactComponent as ICResource } from '../../../assets/icons/ic-resource.svg';
import { ReactComponent as ICQuality } from '../../../assets/icons/ic-quality.svg';
import { ReactComponent as ICMoon } from '../../../assets/icons/ic-moon.svg';
import { ReactComponent as ICSun } from '../../../assets/icons/ic-sun.svg';
import { ReactComponent as ICTwinkle } from '../../../assets/icons/ic-twinkle.svg';
import { ReactComponent as ICDollarCircleFill } from '../../../assets/icons/ic-dollar-circle-fill.svg';
import { ReactComponent as ICFileFill } from '../../../assets/icons/ic-file-fill.svg';
import { ReactComponent as ICFlagFill } from '../../../assets/icons/ic-flag-fill.svg';
import { ReactComponent as ICMore } from '../../../assets/icons/ic-more.svg';
import { ReactComponent as ICVacanciesFill } from '../../../assets/icons/ic-vacancies-fill.svg';
import { ReactComponent as ICFaceCircle } from '../../../assets/icons/ic-face-circle.svg';
import { ReactComponent as ICBirthdayCircle } from '../../../assets/icons/ic-birthday-circle.svg';
import { ReactComponent as ICCalendarCircle } from '../../../assets/icons/ic-calendar-circle.svg';
import { ReactComponent as ICGenderCircle } from '../../../assets/icons/ic-gender-circle.svg';
import { ReactComponent as ICArchive } from '../../../assets/icons/ic-archive.svg';
import { ReactComponent as ICSettingOutline } from '../../../assets/icons/ic-setting-outline.svg';
import { ReactComponent as ICContact } from '../../../assets/icons/ic-contact.svg';
import { ReactComponent as ICContactOutline } from '../../../assets/icons/ic-contact-outline.svg';
import { ReactComponent as ICPreferredContactMethodOutline } from '../../../assets/icons/ic-preferred-contact-method.svg';
import { ReactComponent as ICRole } from '../../../assets/icons/ic-role.svg';
import { ReactComponent as ICJobTitle } from '../../../assets/icons/ic-job-title.svg';
import { ReactComponent as ICPromotion } from '../../../assets/icons/promotion-tag.svg';
import { ReactComponent as ICShootingStar } from '../../../assets/icons/ic-shooting-star.svg';
import { ReactComponent as ICStarResult } from '../../../assets/icons/ic-star-result.svg';
import { ReactComponent as ICEditListingStrength } from '../../../assets/icons/ic-edit-listing-strength.svg';
import { ReactComponent as ICReturn } from '../../../assets/icons/ic-return.svg';
import { ReactComponent as ICCheckDone } from '../../../assets/icons/ic-check-done.svg';
import { ReactComponent as ICCheckcircleUnCheck } from '../../../assets/icons/ic-checkcircle-uncheck.svg';
import { ReactComponent as ICCheckCircleChecked } from '../../../assets/icons/ic-checkcircle-checked.svg';
import { ReactComponent as ICCheckcircle } from '../../../assets/icons/ic-checkcircle.svg';
import { ReactComponent as ICPreferredContact } from '../../../assets/icons/ic-preferred-contact.svg';
import { ReactComponent as ICInsights } from '../../../assets/icons/ic-insights.svg';
import { ReactComponent as ICTotalPrice } from '../../../assets/icons/ic-total-price.svg';
import { ReactComponent as ICTotalRevenue } from '../../../assets/icons/ic-total-revenue.svg';
import { ReactComponent as ICTotalYourCentres } from '../../../assets/icons/ic-total-your-centres.svg';
import { ReactComponent as ICTotalApprovedPlaces } from '../../../assets/icons/ic-total-approved-places.svg';
import { ReactComponent as ICLocation } from '../../../assets/icons/ic-location.svg';
import { ReactComponent as ICMapView } from '../../../assets/icons/ic-map-view.svg';
import { ReactComponent as ICBrandDefault } from '../../../assets/icons/ic-brand-default.svg';
import { ReactComponent as ICOpenNewTab } from '../../../assets/icons/ic-new-tab.svg';
import { ReactComponent as ICChildcareServiceType } from '../../../assets/icons/ic-childcare-service-type.svg';
import { ReactComponent as ICProvider } from '../../../assets/icons/ic-provider.svg';
import { ReactComponent as ICCompany } from '../../../assets/icons/ic-company.svg';
import { ReactComponent as ICTagBrand } from '../../../assets/icons/ic-tag-brands.svg';
import { ReactComponent as ICAward } from '../../../assets/icons/ic-award.svg';
import { ReactComponent as ICBrief } from '../../../assets/icons/ic-brief.svg';
import { ReactComponent as ICBriefCase } from '../../../assets/icons/ic-briefcase.svg';
import { ReactComponent as ICCopy } from '../../../assets/icons/ic-copy.svg';
import { ReactComponent as ICEducatorDiscount } from '../../../assets/icons/ic-educator-discount.svg';
import { ReactComponent as ICFormatCircle } from '../../../assets/icons/ic-format-circle.svg';
import { ReactComponent as ICLanguage } from '../../../assets/icons/ic-language.svg';
import { ReactComponent as ICPersonalCard } from '../../../assets/icons/ic-personal-card.svg';
import { ReactComponent as ICTeacher } from '../../../assets/icons/ic-teacher.svg';
import { ReactComponent as ICDollarSquare } from '../../../assets/icons/ic-dollar-quare.svg';
import { ReactComponent as ICDiscountPercentage } from '../../../assets/icons/ic-discount-percentage.svg';
import { ReactComponent as ICNoData } from '../../../assets/icons/ic-no-data.svg';
import { ReactComponent as ICABN } from '../../../assets/icons/ic-abn.svg';
import { ReactComponent as ICACN } from '../../../assets/icons/ic-acn.svg';
import { ReactComponent as ICTotalOpenJobApplications } from '../../../assets/icons/ic-total-open-job-applications.svg';
import { ReactComponent as ICTotalJobListingsValue } from '../../../assets/icons/ic-total-job-listings-value.svg';
import { ReactComponent as ICCloseCircle } from '../../../assets/icons/ic-close-circle.svg';
import { ReactComponent as ICTickCircle } from '../../../assets/icons/ic-tick-circle.svg';
import { ReactComponent as ICPersonalCardV2 } from '../../../assets/icons/ic-personalcard.svg';
import { ReactComponent as ICMessage } from '../../../assets/icons/ic-message.svg';
import { ReactComponent as ICLocationV2 } from '../../../assets/icons/ic-location-v2.svg';
import { ReactComponent as ICCall } from '../../../assets/icons/ic-call.svg';
import { ReactComponent as ICTableCalendarEvent } from '../../../assets/icons/ic-tabler-calendar-event.svg';
import { ReactComponent as ICEducatorDiscountFill } from '../../../assets/icons/ic-educator-discount-fill.svg';
import { ReactComponent as ICBriefCaseFilled } from '../../../assets/icons/ic-briefcase-filled.svg';
import { ReactComponent as ICDot } from '../../../assets/icons/ic-dot.svg';
import { ReactComponent as ICTeacherFilled } from '../../../assets/icons/ic-teacher-filled.svg';
import { ReactComponent as ICBookMark } from '../../../assets/icons/ic-bookmark.svg';
import { ReactComponent as ICHealth } from '../../../assets/icons/ic-health.svg';
import { ReactComponent as ICLampCharge } from '../../../assets/icons/ic-lamp-charge.svg';
import { ReactComponent as ICNebulas } from '../../../assets/icons/ic-nebulas.svg';
import { ReactComponent as ICShieldTick } from '../../../assets/icons/ic-shield-tick.svg';
import { ReactComponent as ICPenFilled } from '../../../assets/icons/ic-pen-filled.svg';
import { ReactComponent as ICCallGreen } from '../../../assets/icons/ic-call-green.svg';
import { ReactComponent as ICCallOrange } from '../../../assets/icons/ic-call-orange.svg';
import { ReactComponent as ICPdf } from '../../../assets/icons/ic-pdf.svg';
import { ReactComponent as ICImportOutline } from '../../../assets/icons/ic-import-outline.svg';
import { ReactComponent as ICExportOutline } from '../../../assets/icons/ic-export-outline.svg';
import { ReactComponent as ICTrashOutline } from '../../../assets/icons/ic-trash-outline.svg';
import { ReactComponent as ICCloseSquareOutline } from '../../../assets/icons/ic-close-square.svg';
import { ReactComponent as ICTotalCampaign } from '../../../assets/icons/ic-total-campaign.svg';
import { ReactComponent as ICTotalActive } from '../../../assets/icons/ic-total-active.svg';
import { ReactComponent as ICTotalIndividual } from '../../../assets/icons/ic-total-individual.svg';
import { ReactComponent as ICTotalJobRole } from '../../../assets/icons/ic-total-job-role.svg';
import { ReactComponent as ICTotalOpen } from '../../../assets/icons/ic-total-open.svg';
import { ReactComponent as ICTotalTemPlate } from '../../../assets/icons/ic-total-template.svg';
import { ReactComponent as ICTotalWorkplace } from '../../../assets/icons/ic-total-workplace.svg';
import { ReactComponent as ICDelete } from '../../../assets/icons/ic-delete.svg';
import { ReactComponent as ICMove } from '../../../assets/icons/ic-move.svg';
import { ReactComponent as ICSearch } from '../../../assets/icons/ic-search.svg';
import { ReactComponent as ICCopyFill } from '../../../assets/icons/ic-copy-fill.svg';
import { ReactComponent as ICUserTag } from '../../../assets/icons/ic-user-tag.svg';
import { ReactComponent as ICShop } from '../../../assets/icons/ic-shop.svg';
import { ReactComponent as ICLocationFill } from '../../../assets/icons/ic-location-fill.svg';
import { ReactComponent as ICFolderOpen } from '../../../assets/icons/ic-folder-open.svg';
import { ReactComponent as ICDocumentText } from '../../../assets/icons/ic-document-text.svg';
import { ReactComponent as ICClock } from '../../../assets/icons/ic-clock.svg';
import { ReactComponent as ICTimer } from '../../../assets/icons/ic-timer.svg';
import { ReactComponent as ICInfo } from '../../../assets/icons/ic-info.svg';
import { ReactComponent as ICClockFill } from '../../../assets/icons/ic-clock-fill.svg';
import { ReactComponent as ICBoardView } from '../../../assets/icons/ic-board-view.svg';
import { ReactComponent as ICListView } from '../../../assets/icons/ic-list-view.svg';
import { ReactComponent as ICBoard } from '../../../assets/icons/ic-board.svg';
import { ReactComponent as ICList } from '../../../assets/icons/ic-list.svg';
import { ReactComponent as ICBriefCaseOutLine } from '../../../assets/icons/ic-briefcase-outline.svg';
import { ReactComponent as ICEducationGreen } from '../../../assets/icons/ic-education-green.svg';
import { ReactComponent as ICPriceGreen } from '../../../assets/icons/ic-price-green.svg';
import { ReactComponent as ICLocationGreen } from '../../../assets/icons/ic-location-green.svg';
import { ReactComponent as ICPaperPlan } from '../../../assets/icons/ic-paper-plan.svg';
import { ReactComponent as ICVideoFill } from '../../../assets/icons/ic-video-fill.svg';
import { ReactComponent as ICDocumentFill } from '../../../assets/icons/ic-document-fill.svg';
import { ReactComponent as ICDocumentTextFill } from '../../../assets/icons/ic-document-text-fill.svg';
import { ReactComponent as ICRoundOpenInNew } from '../../../assets/icons/ic-round-open-in-new.svg';
import { ReactComponent as ICDuplicate } from '../../../assets/icons/ic-duplicate.svg';
import { ReactComponent as ICContentNotAvailable } from '../../../assets/icons/ic-content-not-available.svg';
import { ReactComponent as ICQuestionFill } from '../../../assets/icons/ic-question-fill.svg';
import { ReactComponent as ICAwardCircle } from '../../../assets/icons/ic-award-circle.svg';
import { ReactComponent as ICDeleteWithoutCircle } from '../../../assets/icons/ic-delete-without-circle.svg';
import { ReactComponent as ICEducation } from '../../../assets/icons/ic-education.svg';
import { ReactComponent as ICBookCircle } from '../../../assets/icons/ic-book-circle.svg';
import { ReactComponent as ICDotFill } from '../../../assets/icons/ic-dot-fill.svg';
import { ReactComponent as ICLoading } from '../../../assets/icons/ic-loading.svg';
import { ReactComponent as ICTickChoose } from '../../../assets/icons/ic-tick-choose.svg';
import { ReactComponent as ICCSV } from '../../../assets/icons/ic-csv.svg';
import { ReactComponent as ICDownload } from '../../../assets/icons/ic-download.svg';
import {
  ReactComponent as ICOpenJobNewTab,
  ReactComponent as ICOpenNewCentreTab,
  ReactComponent as ICExport,
} from '../../../assets/icons/ic-export.svg';
import { ReactComponent as ICCollapse } from '../../../assets/icons/ic-collapse.svg';
import { ReactComponent as ICPerson } from '../../../assets/icons/ic-person.svg';
import { ReactComponent as ICAwardWithoutCircle } from '../../../assets/icons/ic-award-without-circle.svg';
import { ReactComponent as ICAddAvatarPlaceholder } from '../../../assets/icons/ic-add-avatar-placeholder.svg';
import { ReactComponent as ICDocumentBold } from '../../../assets/icons/ic-document-bold.svg';
import { ReactComponent as IAwardFill } from '../../../assets/icons/ic-award-filled.svg';
import { ReactComponent as ICTrash } from '../../../assets/icons/ic-trash.svg';
import { ReactComponent as ICEditRounded } from '../../../assets/icons/ic-edit-rounded.svg';
import { ReactComponent as ICImageDefault } from '../../../assets/icons/ic-image-default.svg';
import { ReactComponent as ICVideoVertical } from '../../../assets/icons/ic-video-vertical.svg';
import { ReactComponent as ICEditLight } from '../../../assets/icons/ic-edit-light.svg';
import { ReactComponent as ICDeleteLight } from '../../../assets/icons/ic-delete-light.svg';
import { ReactComponent as ICBook } from '../../../assets/icons/ic-book-filled.svg';
import { ReactComponent as ICCircleGroup } from '../../../assets/icons/ic-circle-group.svg';
import { ReactComponent as ICQuote } from '../../../assets/icons/ic-quote.svg';
import { ReactComponent as ICCompanyRaw } from '../../../assets/icons/ic-company-raw.svg';
import { ReactComponent as ICSENumber } from '../../../assets/icons/ic-se-number.svg';
import { ReactComponent as ICCentreBased } from '../../../assets/icons/ic-centre-based.svg';
import { ReactComponent as IDocumentPlaceholder } from '../../../assets/icons/ic-document-placeholder.svg';
import { ReactComponent as IAvatarPlaceholder } from '../../../assets/icons/ic-avatar-placeholder.svg';
import { ReactComponent as ICRightArrow } from '../../../assets/icons/ic-right-arrow.svg';
import { ReactComponent as ICYellowMagicStar } from '../../../assets/icons/ic-yellow-magic-star.svg';
import { ReactComponent as ICProfileUser } from '../../../assets/icons/ic-profile-user.svg';
import { ReactComponent as ICEditFilled } from '../../../assets/icons/ic-edit-filled.svg';
import { ReactComponent as ICInfoFilled } from '../../../assets/icons/ic-info-filled.svg';
import { ReactComponent as ICGallerySlash } from '../../../assets/icons/ic-gallery-slash.svg';
import { ReactComponent as ICTrashFilled } from '../../../assets/icons/ic-trash-filled.svg';
import { ReactComponent as ICHandHeart } from '../../../assets/icons/ic-hand-heart.svg';
import { ReactComponent as ICCalendar } from '../../../assets/icons/ic-calendar.svg';
import { ReactComponent as ICArrowRight } from '../../../assets/icons/ic-arrow-right.svg';
import { ReactComponent as ICCloseOutline } from '../../../assets/icons/ic-close-outline.svg';
import { ReactComponent as ICRightOutline } from '../../../assets/icons/ic-right-outline.svg';
import { ReactComponent as ICSparkle } from '../../../assets/icons/ic-sparkle.svg';
import { ReactComponent as ICArchiveOutline } from '../../../assets/icons/ic-archive-outline.svg';
import { ReactComponent as ICArchiveButton } from '../../../assets/icons/ic-archive-button.svg';
import { ReactComponent as ICUnarchive } from '../../../assets/icons/ic-unarchive.svg';
import { ReactComponent as ICEye } from '../../../assets/icons/ic-eye.svg';

const IconWrapper = ({ SVGComponent, svgProps, ...props }) => (
  <Icon
    {...props}
    component={() => (
      <SVGComponent {...svgProps} fill={props?.fill || 'none'} />
    )}
  />
);

IconWrapper.propTypes = {
  SVGComponent: PropTypes.any,
  svgProps: PropTypes.object,
};

IconWrapper.defaultProps = {
  svgProps: {},
};

export const CustomerIcon = (props) => (
  <Icon {...props} component={ICCustomer} />
);

export const DashBoardIcon = (props) => (
  <Icon {...props} component={Dashboard} />
);

export const SettingFillIcon = (props) => (
  <Icon {...props} component={ICSettingFill} />
);

export const ReservationsIcon = (props) => (
  <Icon {...props} component={Reservations} />
);

export const UserIcon = (props) => <Icon {...props} component={User} />;

export const UsersIcon = (props) => <Icon {...props} component={Users} />;

export const BookingsIcon = (props) => <Icon {...props} component={Bookings} />;

export const CheckInIcon = (props) => <Icon {...props} component={CheckIn} />;

export const DeliveryIcon = (props) => <Icon {...props} component={Delivery} />;

export const PharmacyIcon = (props) => <Icon {...props} component={Pharmacy} />;

export const PreparingIcon = (props) => (
  <Icon {...props} component={Preparing} />
);

export const SendingIcon = (props) => <Icon {...props} component={Sending} />;

export const FacebookIcon = (props) => <Icon {...props} component={Facebook} />;

export const LinkedinIcon = (props) => <Icon {...props} component={Linkedin} />;

export const TotalApplicationsIcon = (props) => (
  <Icon {...props} component={() => <TotalApplications fill="none" />} />
);

export const TotalValueIcon = (props) => (
  <Icon {...props} component={() => <TotalValue fill="none" />} />
);

export const TotalPriceIcon = (props) => (
  <Icon {...props} component={() => <ICTotalPrice fill="none" />} />
);

export const TotalRevenueIcon = (props) => (
  <Icon {...props} component={() => <ICTotalRevenue fill="none" />} />
);

export const TotalYourCentresIcon = (props) => (
  <Icon {...props} component={() => <ICTotalYourCentres fill="none" />} />
);

export const TotalApprovedPlacesIcon = (props) => (
  <Icon {...props} component={() => <ICTotalApprovedPlaces fill="none" />} />
);

export const TotalAcceptedIcon = (props) => (
  <Icon {...props} component={() => <TotalAccepted fill="none" />} />
);

export const WaitingResponseIcon = (props) => (
  <Icon {...props} component={() => <WaitingResponse fill="none" />} />
);

export const LayoutListIcon = (props) => (
  <Icon {...props} component={() => <LayoutList fill="none" />} />
);

export const LayoutBoardIcon = (props) => (
  <IconWrapper {...props} SVGComponent={LayoutBoard} />
);

export const SorterIcon = (props) => (
  <IconWrapper {...props} SVGComponent={Sorter} />
);

export const EnquiriesIcon = (props) => (
  <IconWrapper {...props} SVGComponent={Enquiries} />
);

export const FileIcon = (props) => (
  <IconWrapper {...props} SVGComponent={File} />
);

export const ArrowDownIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ArrowDown} />
);

export const ArrowUpIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ArrowUp} />
);

export const MailIcon = (props) => (
  <IconWrapper {...props} SVGComponent={Mail} />
);

export const WomenIcon = (props) => (
  <IconWrapper {...props} SVGComponent={Women} />
);

export const SendIcon = (props) => (
  <IconWrapper {...props} SVGComponent={Send} />
);

export const AttachmentIcon = (props) => (
  <IconWrapper {...props} SVGComponent={Attachment} />
);

export const RefreshIcon = (props) => (
  <IconWrapper {...props} SVGComponent={Refresh} />
);

export const CentreIcon = (props) => (
  <IconWrapper {...props} SVGComponent={Centre} />
);

export const TotalCentresIcon = (props) => (
  <IconWrapper {...props} SVGComponent={TotalCentres} />
);

export const TotalPlacesIcon = (props) => (
  <IconWrapper {...props} SVGComponent={TotalPlaces} />
);

export const TotalEarningIcon = (props) => (
  <IconWrapper {...props} SVGComponent={TotalEarning} />
);

export const TotalWaitlistValueIcon = (props) => (
  <IconWrapper {...props} SVGComponent={TotalWaitlistValue} />
);

export const KindiCareIcon = (props) => (
  <IconWrapper {...props} SVGComponent={KindiCare} />
);

export const WaitlistIcon = (props) => (
  <IconWrapper {...props} SVGComponent={Waitlist} />
);

export const ChildIcon = (props) => (
  <IconWrapper {...props} SVGComponent={Child} />
);

export const ServiceIcon = (props) => (
  <IconWrapper {...props} SVGComponent={Service} />
);

export const CentreTypeIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICCentreType} />
);

export const PhoneIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICPhone} />
);

export const MousePointerIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICMousePointer} />
);

export const TotalEnquiriesIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICTotalEnquiries} />
);

export const TotalMoreInfoIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICTotalMoreInfo} />
);

export const TotalCheckVacanciesIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICTotalCheckVacancies} />
);

export const BookTourIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICBookTour} />
);

export const FeeInformationIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICFeeInformation} />
);

export const TotalOpenApplicationIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICTotalOpenApplication} />
);

export const TotalWaitlistIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICTotalWaitlist} />
);

export const EditIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICEdit} />
);

export const FilterIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICFilter} />
);

export const ReplyIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICReply} />
);

export const CloseFillIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICCloseFill} />
);

export const UploadIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICUpload} />
);

export const IncomingCallIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICIncomingCall} />
);

export const OutgoingCallIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICOutgoingCall} />
);

export const NoteIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICNote} />
);

export const EmailIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICEmail} />
);

export const OtherIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICOther} />
);

export const InterviewIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICInterview} />
);

export const VisitTourIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICVisitTour} />
);

export const ExcelIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICExcel} />
);

export const TotalTransactionIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICTotalTransaction} />
);

export const TotalTransactionValueIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICTotalTransactionValue} />
);

export const TotalTransactionYearIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICTotalTransactionYear} />
);
export const FileInboxIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICFileInbox} />
);

export const ReportsIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICReport} />
);

export const MarketingIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICMarketing} />
);

export const TransactionsIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICTransactions} />
);

export const FlagIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICFlag} />
);

export const TotalActivitiesIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICTotalActivities} />
);

export const ResourceIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICResource} />
);

export const QualityIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICQuality} />
);

export const MoonIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICMoon} />
);

export const SunIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICSun} />
);

export const TwinkleIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICTwinkle} />
);

export const DollarCircleFillIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICDollarCircleFill} />
);

export const FileFillIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICFileFill} />
);

export const FlagFillIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICFlagFill} />
);

export const MoreIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICMore} />
);

export const VacanciesFillIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICVacanciesFill} />
);

export const FaceCircleIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICFaceCircle} />
);

export const BirthdayCircleIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICBirthdayCircle} />
);

export const CalendarCircleIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICCalendarCircle} />
);

export const GenderCircleIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICGenderCircle} />
);

export const ArchiveIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICArchive} />
);

export const SettingOutlineIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICSettingOutline} />
);

export const ContactIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICContact} />
);

export const ContactOutlineIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICContactOutline} />
);

export const PreferredContactMethodOutlineIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICPreferredContactMethodOutline} />
);

export const RoleIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICRole} />
);

export const JobTitleIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICJobTitle} />
);

export const TagPromotionIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICPromotion} />
);

export const ShootingStarIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICShootingStar} />
);

export const StarResultIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICStarResult} />
);

export const EditListingStrengthIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICEditListingStrength} />
);

export const ReturnIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICReturn} />
);

export const CheckDoneIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICCheckDone} />
);

export const CheckCircleUncheckIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICCheckcircleUnCheck} />
);

export const CheckCircleCheckedIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICCheckCircleChecked} />
);

export const CheckCircleIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICCheckcircle} />
);
export const PreferredContactIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICPreferredContact} />
);

export const InsightsIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICInsights} />
);

export const LocationIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICLocation} />
);

export const MapViewIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICMapView} />
);

export const BrandDefaultIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICBrandDefault} />
);

export const OpenNewTabIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICOpenNewTab} />
);

export const ChildcareServiceTypeIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICChildcareServiceType} />
);

export const ProviderIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICProvider} />
);

export const CompanyIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICCompany} />
);

export const TagBrandIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICTagBrand} />
);

export const AwardIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICAward} />
);

export const BriefIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICBrief} />
);

export const BriefcaseIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICBriefCase} />
);

export const CopyIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICCopy} />
);

export const EducatorDiscountIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICEducatorDiscount} />
);

export const FormatCircleIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICFormatCircle} />
);

export const LanguageIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICLanguage} />
);

export const PersonalCardIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICPersonalCard} />
);

export const TeacherIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICTeacher} />
);

export const DollarSquareIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICDollarSquare} />
);

export const DiscountPercentageIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICDiscountPercentage} />
);

export const NoDataIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICNoData} />
);

export const ABNIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICABN} />
);

export const ACNIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICACN} />
);

export const TotalOpenJobApplicationsIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICTotalOpenJobApplications} />
);

export const TotalJobListingsValueIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICTotalJobListingsValue} />
);

export const CloseCircleIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICCloseCircle} />
);
export const TickCircleIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICTickCircle} />
);
export const PersonalCardV2Icon = (props) => (
  <IconWrapper {...props} SVGComponent={ICPersonalCardV2} />
);
export const MessageIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICMessage} />
);
export const LocationV2Icon = (props) => (
  <IconWrapper {...props} SVGComponent={ICLocationV2} />
);
export const CallIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICCall} />
);
export const TableCalendarEventIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICTableCalendarEvent} />
);

export const EducatorDiscountFillIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICEducatorDiscountFill} />
);

export const BriefcaseFilledIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICBriefCaseFilled} />
);

export const DotIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICDot} />
);

export const TeacherFilledIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICTeacherFilled} />
);

export const BookMarkIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICBookMark} />
);

export const HealthIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICHealth} />
);

export const LampChargeIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICLampCharge} />
);

export const NebulasIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICNebulas} />
);

export const ShieldTickIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICShieldTick} />
);

export const PenFilledIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICPenFilled} />
);

export const CallGreenIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICCallGreen} />
);

export const CallOrangeIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICCallOrange} />
);

export const PdfIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICPdf} />
);

export const ImportOutlineIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICImportOutline} />
);

export const ExportOutlineIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICExportOutline} />
);

export const TrashOutlineIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICTrashOutline} />
);

export const CloseSquareOutlineIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICCloseSquareOutline} />
);

export const TotalCampaignIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICTotalCampaign} />
);

export const TotalActiveIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICTotalActive} />
);

export const TotalIndividualIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICTotalIndividual} />
);

export const TotalJobRoleIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICTotalJobRole} />
);

export const TotalOpenIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICTotalOpen} />
);

export const TotalTemPlateIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICTotalTemPlate} />
);

export const TotalWorkplaceIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICTotalWorkplace} />
);

export const InfoIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICInfo} />
);

export const DeleteIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICDelete} />
);

export const MoveIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICMove} />
);

export const SearchIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICSearch} />
);
export const CopyFillIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICCopyFill} />
);

export const UserTagIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICUserTag} />
);

export const ShopIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICShop} />
);

export const LocationFillIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICLocationFill} />
);

export const FolderOpenIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICFolderOpen} />
);

export const DocumentTextIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICDocumentText} />
);

export const ClockIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICClock} />
);

export const TimerIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICTimer} />
);

export const ClockFillIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICClockFill} />
);

export const BoardViewIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICBoardView} />
);

export const ListViewIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICListView} />
);
export const ListIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICList} />
);

export const BoardIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICBoard} />
);

export const BriefCaseOutLineIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICBriefCaseOutLine} />
);

export const EducationGreenIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICEducationGreen} />
);

export const PriceGreenIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICPriceGreen} />
);

export const LocationGreenIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICLocationGreen} />
);

export const PaperPlanIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICPaperPlan} />
);

export const VideoFilIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICVideoFill} />
);

export const DocumentFilIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICDocumentFill} />
);

export const DocumentTextFillIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICDocumentTextFill} />
);

export const RoundOpenInNewIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICRoundOpenInNew} />
);

export const DuplicateIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICDuplicate} />
);

export const TrashIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICTrash} />
);

export const EditRoundedIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICEditRounded} />
);

export const ContentNotAvailableIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICContentNotAvailable} />
);

export const QuestionFillIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICQuestionFill} />
);

export const AwardCircleIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICAwardCircle} />
);

export const DeleteWithoutCircleIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICDeleteWithoutCircle} />
);

export const EducationIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICEducation} />
);

export const BookCircleIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICBookCircle} />
);

export const DotIconFill = (props) => (
  <IconWrapper {...props} SVGComponent={ICDotFill} />
);

export const LoadingIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICLoading} />
);

export const TickChooseIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICTickChoose} />
);

export const CSVIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICCSV} />
);

export const DownloadIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICDownload} />
);

export const OpenJobNewTabIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICOpenJobNewTab} />
);

export const OpenNewCentreTab = (props) => (
  <IconWrapper {...props} SVGComponent={ICOpenNewCentreTab} />
);

export const PersonFillIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICPerson} />
);

export const AwardWithoutCircleIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICAwardWithoutCircle} />
);

export const AddAvatarPlaceholderIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICAddAvatarPlaceholder} />
);

export const DocumentBoldIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICDocumentBold} />
);

export const AwardFillIcon = (props) => (
  <IconWrapper {...props} SVGComponent={IAwardFill} />
);

export const ExportIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICExport} />
);

export const ImageDefaultIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICImageDefault} />
);

export const VideoVerticalIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICVideoVertical} />
);

export const EditLightIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICEditLight} />
);

export const DeleteLightIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICDeleteLight} />
);

export const CollapseSidebarIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICCollapse} />
);

export const ProgramsLearningIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICProgramsLearning} />
);

export const BookFillIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICBook} />
);

export const DocumentPlaceholderIcon = (props) => (
  <IconWrapper {...props} SVGComponent={IDocumentPlaceholder} />
);

export const AvatarPlaceholderIcon = (props) => (
  <IconWrapper {...props} SVGComponent={IAvatarPlaceholder} />
);

export const WarningIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICWarning} />
);

export const ErrorIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICError} />
);

export const TickedIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICTicked} />
);

export const RightArrowIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICRightArrow} />
);

export const YellowMagicStarIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICYellowMagicStar} />
);

export const CircleGroupIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICCircleGroup} />
);

export const QuoteIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICQuote} />
);

export const UnderlineIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICUnderline} />
);

export const YoutubeFillIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICYoutubeFill} />
);

export const CompanyRawIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICCompanyRaw} />
);

export const SENumberIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICSENumber} />
);

export const CentreBasedTypeIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICCentreBased} />
);

export const PhoneRightIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICPhoneRight} />
);

export const CloseXIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICCloseOutline} />
);

export const ProfileUserIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICProfileUser} />
);

export const EditFilledIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICEditFilled} />
);

export const InfoFilledIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICInfoFilled} />
);

export const GallerySlashIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICGallerySlash} />
);

export const TrashFilledIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICTrashFilled} />
);

export const HandHeartIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICHandHeart} />
);

export const CalendarIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICCalendar} />
);

export const ArrowRightIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICArrowRight} />
);

export const RightOutlineIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICRightOutline} />
);

export const SparkleIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICSparkle} />
);

export const ArchiveOutlineIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICArchiveOutline} />
);

export const UnArchivedIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICUnarchive} />
);

export const ArchiveButtonIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICArchiveButton} />
);

export const EyeIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICEye} />
);

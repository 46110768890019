import React, { useCallback } from 'react';
import i18next from 'i18next';
import { useHistory, useParams } from 'react-router';
import { Tabs } from 'antd';
import { JOB_LISTING_DETAIL_TABS } from 'containers/JobListings/constants';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { getDataJobListingDetail } from 'redux/jobs/jobListings/selectors';
import JobDetailsTab from './JobDetailsTab';
import ApplicationsTab from './ApplicationsTab';
import ApplicationsTitleTab from './ApplicationsTab/components/ApplicationsTitleTab';

const { TabPane } = Tabs;

const JobListingsTab = ({ currentData }) => {
  const { push } = useHistory();

  const { id, modelDetail } = useParams();

  const totalApplications = useSelector(
    getDataJobListingDetail,
  )?.totalApplications;

  const TABS = [
    {
      key: JOB_LISTING_DETAIL_TABS.JOB_DETAILS,
      title: i18next.t('jobListings.jobListingDetails.jobDetails'),
      TabComponent: JobDetailsTab,
    },
    {
      key: JOB_LISTING_DETAIL_TABS.APPLICATIONS,
      title: <ApplicationsTitleTab count={totalApplications} />,
      TabComponent: ApplicationsTab,
    },
  ];

  const onChange = useCallback(
    (key) => {
      push(`/job-listings/${id}/show/${key}`);
    },
    [push, id],
  );

  return (
    <Tabs
      activeKey={modelDetail || JOB_LISTING_DETAIL_TABS.JOB_DETAILS}
      onChange={onChange}
    >
      {TABS.map(({ title, key, TabComponent, ...tab }) => (
        <TabPane tab={title} key={key}>
          <TabComponent {...tab} record={{}} currentData={currentData} />
        </TabPane>
      ))}
    </Tabs>
  );
};

JobListingsTab.propTypes = {
  currentData: PropTypes.object,
};

export default JobListingsTab;

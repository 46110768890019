import { Form, Radio, Switch } from 'antd';
import useRolesJobTemplates from 'containers/Users/hooks/useRoleJobTemplates';
import i18next from 'i18next';
import React from 'react';
import { IS_PREFERRED, IS_REQUIRED, PREFIX_ID_KEY } from './constants';

const useFormat = () => {
  const disabledAction = useRolesJobTemplates();
  const formatCategoryName = (name, { iconUrl }) => (
    <div className="text-16 fw-500 flex">
      <div>
        {iconUrl && (
          <img src={iconUrl} alt="icon" className="mr-10" width={21} />
        )}
      </div>
      <div>{name}</div>
    </div>
  );

  const formatHeaderTitle = () => (
    <div className="flex justify-between">
      <div>{i18next.t('common.required')}</div>
      <div>{i18next.t('common.preferred')}</div>
    </div>
  );

  const formatStatusRow = (value, { id }) => (
    <Form.Item name={`${PREFIX_ID_KEY}${String(id)}`} valuePropName="checked">
      <Switch />
    </Form.Item>
  );

  const formatRadioGroup = (id, { category, value }) => {
    let formItemName = [category, id];

    if (category === 'eligibility') {
      formItemName = category;
    }
    return (
      <Form.Item name={formItemName} initialValue={value}>
        <Radio.Group disabled={disabledAction}>
          <Radio value={IS_REQUIRED} />
          <Radio value={IS_PREFERRED} />
        </Radio.Group>
      </Form.Item>
    );
  };

  return {
    formatCategoryName,
    formatHeaderTitle,
    formatStatusRow,
    formatRadioGroup,
  };
};

export default useFormat;

import React from 'react';
import { Button, Col, Row } from 'antd';
import i18next from 'i18next';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import styled, { css } from 'styled-components';
import { StepFooter } from 'components/RestLayout/StepsCreateLayout/styles';

interface FooterProps {
  onBack?: () => Promise<void> | void;
  onNext?: () => Promise<void> | void;
  isClaim?: boolean;
  disabledNext?: boolean;
}

const ClaimFooter = ({
  onNext,
  onBack,
  isClaim,
  disabledNext,
}: FooterProps) => (
  <StepFooter>
    <FooterStyles isClaim={isClaim} className="claim-footer">
      <Row justify="space-between" gutter={16}>
        <Col span={12}>
          <Button
            onClick={onBack}
            icon={<LeftOutlined />}
            className="btn-line-primary"
          >
            {i18next.t('button.back')}
          </Button>
        </Col>
        <Col span={12}>
          <Button type="primary" onClick={onNext} disabled={disabledNext}>
            {i18next.t(isClaim ? 'centresClaim.claim' : 'button.dispute')}
            <RightOutlined />
          </Button>
        </Col>
      </Row>
    </FooterStyles>
  </StepFooter>
);

const FooterStyles = styled.div<Partial<FooterProps>>`
  .ant-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 700;
    font-size: 16px;
    padding: 12px 24px;
    border-radius: 12px;
  }

  .ant-btn-default.btn-line-primary {
    border-color: #db147f;
    color: #db147f;
  }

  .ant-btn-primary {
    margin-left: auto;
  }

  @media only screen and (max-width: 576px) {
    .ant-btn {
      width: 100%;
    }

    .anticon {
      font-size: 12px;
    }
  }

  @media only screen and (min-width: 1921px) {
    .ant-btn {
      font-size: 18px;
      padding: 12px 24px;
      height: unset !important;
    }
  }

  @media only screen and (min-width: 2420px) {
    .ant-btn {
      font-size: 24px;
      padding: 18px 28px;
    }
  }

  @media only screen and (min-width: 3000px) {
    .ant-btn {
      font-size: 28px;
      padding: 18px 42px;
    }
  }

  ${({ isClaim }) =>
    !isClaim &&
    css`
      .ant-btn-default.btn-line-primary {
        border-color: #32a4fc;
        color: #32a4fc;
      }
      .ant-btn-primary:not(:disabled) {
        border-color: #32a4fc;
        background: #32a4fc;
      }
    `}
`;

export default ClaimFooter;

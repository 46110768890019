import { useMemo } from 'react';
import { get } from 'lodash-es';
import { useSelector } from 'react-redux';
import {
  getSubscriptionsConfig,
  getRolePermissionsConfig,
} from 'redux/config/selectors';
import {
  getCentreLevelCRMPackageName,
  getCentreId,
  getMultiCentreLevelCRMPackageName,
  getCurrentRole,
} from 'redux/auth/selectors';
import { PERMISSION_KEY } from 'configs/localData';

interface useCheckPermissionParams {
  permissionKey?: string;
  action?: string;
  isSkipUserRole?: boolean;
  isSkipSubscription?: string;
  packageName?: string;
}

const useCheckPermission = ({
  permissionKey,
  action,
  isSkipUserRole,
  isSkipSubscription,
  packageName,
}: useCheckPermissionParams) => {
  const centreId = useSelector(getCentreId);

  const centreLevelCRMPackageName = useSelector(getCentreLevelCRMPackageName);

  const multiCentreLevelCRMPackageName = useSelector(
    getMultiCentreLevelCRMPackageName,
  );

  const { featureCentreLevel, featureMultiCentre } =
    useSelector(getSubscriptionsConfig) || {};

  const currentRole = useSelector(getCurrentRole);

  const rolePermissionsConfig = useSelector(getRolePermissionsConfig);

  const isCanRolePermission = useMemo(() => {
    if (!currentRole) return false;

    if (!permissionKey || !action || isSkipUserRole) return true;

    if (
      !centreId &&
      !get(rolePermissionsConfig?.[currentRole], [
        'manageMoreThan1Centre',
        action,
      ])
    ) {
      return false;
    }

    return get(rolePermissionsConfig?.[currentRole], [permissionKey, action]);
  }, [
    permissionKey,
    action,
    isSkipUserRole,
    centreId,
    rolePermissionsConfig,
    currentRole,
  ]);

  const isCanSubscription = useMemo(() => {
    if (!permissionKey || !action || isSkipSubscription) return true;
    if (permissionKey === PERMISSION_KEY.leadActivities) {
      return get(featureMultiCentre?.[packageName], [permissionKey, action]);
    }
    if (!centreId) {
      if (packageName?.length > 0) {
        return get(featureMultiCentre?.[packageName], [permissionKey, action]);
      }
      return get(featureMultiCentre?.[multiCentreLevelCRMPackageName], [
        permissionKey,
        action,
      ]);
    }
    return get(featureCentreLevel?.[centreLevelCRMPackageName], [
      permissionKey,
      action,
    ]);
  }, [
    permissionKey,
    action,
    isSkipSubscription,
    centreId,
    featureMultiCentre,
    featureCentreLevel,
    centreLevelCRMPackageName,
    multiCentreLevelCRMPackageName,
    packageName,
  ]);

  return {
    isCan: isCanRolePermission && isCanSubscription,
    isCanSubscription,
  };
};

export default useCheckPermission;

import styled from 'styled-components';

export const ListTaskStyles = styled.div`
  .ant-list-split .ant-list-item {
    border: none !important;
  }

  .ant-list-vertical .ant-list-item-meta {
    margin-bottom: 0 !important;
  }

  .task-item {
    &__icon {
      font-size: 44px;
    }
    &__title {
      display: flex;
      justify-content: space-between;
    }
    &__name {
      font-weight: bold;
      font-size: 16px;
      line-height: 20px;
      margin-bottom: 5px;
    }
    &__time {
      font-weight: bold;
      font-size: 14px;
      line-height: 22px;
      color: ${({ theme }) => theme.text.mainL5};
    }
    &__overdue-label {
      font-weight: bold;
      font-size: 14px;
      line-height: 22px;
      color: #e62828;
    }

    &__extra-action {
      font-size: 24px;
    }

    &__icon-square {
      color: #e5ecf7;
    }
    &__icon-complete {
      color: ${({ theme }) => theme.color.blueSecondary};
    }
    &__subject {
      margin-bottom: 8px;
    }
  }
`;

import { createSlice } from '@reduxjs/toolkit';
import {
  getAllCustomFail,
  getAllCustomPending,
  getAllCustomSuccess,
  INITIAL_STATE_CUSTOM,
  resetResourceDataCustom,
} from 'redux/crudCreatorCustom/utils';
import { getCompaniesOfMeBasic } from './actions';

export const initialState = {
  ...INITIAL_STATE_CUSTOM,
};

const { actions, reducer } = createSlice({
  name: 'CompaniesOfMeBasic',
  initialState,
  reducers: {
    resetResourceFilterCompaniesOfMeBasic: resetResourceDataCustom,
  },
  extraReducers: {
    [getCompaniesOfMeBasic.pending]: getAllCustomPending,
    [getCompaniesOfMeBasic.fulfilled]: getAllCustomSuccess,
    [getCompaniesOfMeBasic.rejected]: getAllCustomFail,
  },
});

export const { resetResourceFilterCompaniesOfMeBasic } = actions;

export default reducer;

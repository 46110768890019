import React from 'react';
import { Col, Row, Typography } from 'antd';
import RestInputItem from 'components/RestInput/RestInputItem';
import i18next from 'i18next';
import { validateRegex } from 'utils/validateUtils';

const JobContactInfo = () => (
  <div>
    <Typography.Title level={4}>
      {i18next.t('jobs.companyProfile.jobContactInfo')}
    </Typography.Title>
    <Row gutter={[40, 40]}>
      <Col span={12}>
        <RestInputItem
          source="jobContactPhone"
          header="input.phoneNumber.name"
          placeholder="input.phoneNumber.name"
          rules={[
            {
              pattern: validateRegex.phone,
              message: i18next.t('input.phoneNumber.validateMsg.invalid'),
            },
          ]}
        />
      </Col>
      <Col span={12}>
        <RestInputItem
          source="jobContactEmail"
          header="input.email.name"
          placeholder="input.email.name"
          rules={[
            {
              type: 'email',
              message: i18next.t('input.email.validateMsg.invalid'),
            },
          ]}
        />
      </Col>
    </Row>
  </div>
);

export default JobContactInfo;

import { createSelector } from 'reselect';
import { CRUDSelectors } from '../crudCreator/selectors';
import { MODEL_NAME } from './actions';

const getMessages = (state) => state.enquiries.data;
const getCurrentMessages = (state) => state.enquiries.currentData;
const getCurrentMessageId = (state) => state.enquiries.currentId;
const getEnquiries = (name) => (state) => state.enquiries[name];

const getSummaries = (state) => state.enquiries.additionData;

export const enquiriesSummarySelector = createSelector(
  [getSummaries],
  (additionData) => additionData?.summary || {},
);

export const enquiriesSelector = createSelector(
  [getEnquiries],
  (enquiries) => enquiries,
);

export const getCurrentEnquiry = createSelector(
  [getCurrentMessages],
  (data) => data || {},
);

export const totalMessagesSelector = createSelector(
  [getCurrentMessages],
  (currentMessage) => currentMessage?.resourceData?.total || 0,
);

export const resourceDataMessagesSelector = createSelector(
  [getCurrentMessages],
  (currentMessage) => currentMessage?.resourceData || {},
);

export const listMessagesSelector = createSelector(
  [getCurrentMessages],
  (currentMessage) => currentMessage?.listMessages || [],
);

export const currentMessagesSelector = createSelector(
  [getMessages, getCurrentMessages, getCurrentMessageId],
  (messages, currentMessage, currentId) =>
    messages && currentMessage && currentId
      ? {
          ...messages[currentId],
          ...currentMessage,
        }
      : null,
);

export const enquiriesSelectors = new CRUDSelectors(MODEL_NAME);

import EditCustomBtn from 'components/RestActions/EditCustomBtn';
import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import useCheckJobListingPermission from 'containers/JobListings/hooks/useCheckJobListingPermission';
import JobListingDetailModal from '../Modals/JobListingDetailModal';
import JobListingImageInfo from './JobListingImageInfo';
import { JobListingInfoStyles } from './styles';
import JobListingGeneralInfo from './JobListingGeneralnfo';

const JobListingInfo = ({ currentData }) => {
  const jobListingDetailModalRef = useRef();

  const { canModifyJobListing } = useCheckJobListingPermission();

  const openJobListingDetailModal = (values) => {
    jobListingDetailModalRef.current.open(values);
  };

  return (
    <JobListingInfoStyles>
      <EditCustomBtn
        action="isEdit"
        className="text-right"
        onClickButton={() => openJobListingDetailModal(currentData)}
        disabled={!canModifyJobListing}
      />
      <JobListingImageInfo images={currentData?.defaultImage} />
      <JobListingGeneralInfo record={currentData} />
      <JobListingDetailModal
        ref={jobListingDetailModalRef}
        record={currentData}
      />
    </JobListingInfoStyles>
  );
};

JobListingInfo.propTypes = {
  currentData: PropTypes.object,
};

export default JobListingInfo;

import React, { forwardRef, useImperativeHandle, useState } from 'react';
import ModalCustom from 'components/common/ModalCustom';
import i18next from 'i18next';
import FormEditAudience from 'containers/Advertisement/components/forms/FormEditAudience';
import { Form } from 'antd';
import RestInputItem from 'components/RestInput/RestInputItem';
import { Store } from 'antd/lib/form/interface';
import { editAdAudience } from 'redux/advertisement/actions';
import { AudienceItem } from 'api/advertisement/type';
import { useDispatch } from 'react-redux';

export interface EditAudienceModalRef {
  toggleVisible: (data: AudienceItem) => void;
}

const EditAudienceModal = forwardRef((props, ref) => {
  const dispatch = useDispatch();
  const [visible, setVisible] = useState(false);
  const [audienceId, setAudienceId] = useState('');

  const [form] = Form.useForm();

  useImperativeHandle(
    ref,
    () => ({
      toggleVisible: (data: AudienceItem) => {
        setVisible(true);
        setAudienceId(data.id);
        form.setFieldsValue(data);
      },
    }),
    [form],
  );

  const onCancel = () => setVisible(false);

  const onFinish = (values: Store) => {
    dispatch(editAdAudience({ id: audienceId, body: values }));
    onCancel();
    form.resetFields();
  };

  return (
    <ModalCustom
      visible={visible}
      title={i18next.t('advertisement.editAudience')}
      onCancel={() => setVisible(false)}
      resetPaddingBottomBody
      resetPaddingTopBody
      onOk={form.submit}
      okText="button.ok"
      width={450}
    >
      <Form form={form} onFinish={onFinish} colon={false}>
        <RestInputItem
          source="name"
          required
          isValidatorEmpty
          maxLength={250}
          className="mb-0"
          header="advertisement.nameAudience"
          placeholder="input.audienceType.placeholder"
        />
        <FormEditAudience form={form} />
      </Form>
    </ModalCustom>
  );
});

export default EditAudienceModal;

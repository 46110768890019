import React from 'react';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { Button, Col, Row } from 'antd';
import { StepFooter } from 'components/RestLayout/StepsCreateLayout/styles';
import i18next from 'i18next';
import { useDispatch, useSelector } from 'react-redux';
import { getCurrentStepStaffProfile } from 'redux/staffProfile/selector';
import { goToPrevStep } from 'redux/staffProfile/slice';
import { PROFILE_STEP } from 'containers/Centres/components/CentreTabs/EducatorStaffProfilesTab/constants';
import { NextButtonStyle } from 'containers/Centres/components/CentreTabs/EducatorStaffProfilesTab/Create/styles';

interface FooterProps {
  onBack?: () => Promise<void> | void;
  onNext?: () => Promise<void> | void;
  isSubmitLoading?: boolean;
  isDisableNextButton?: boolean;
}

const Footer = ({
  onBack,
  onNext,
  isSubmitLoading,
  isDisableNextButton,
}: FooterProps) => {
  const currentStep = useSelector(getCurrentStepStaffProfile);
  const dispatch = useDispatch();

  const handleNext = async () => {
    onNext && (await onNext());
  };

  const handleBack = async () => {
    onBack && (await onBack());
    dispatch(goToPrevStep());
  };

  return (
    <StepFooter>
      <Row justify="space-between">
        <Col>
          <Button
            onClick={handleBack}
            icon={<LeftOutlined />}
            className="btn-line-primary"
          >
            {i18next.t('button.back')}
          </Button>
        </Col>
        <Col>
          <NextButtonStyle
            type="primary"
            onClick={handleNext}
            loading={isSubmitLoading}
            disabled={isDisableNextButton}
          >
            {currentStep === PROFILE_STEP.AWARDS
              ? i18next.t('button.publish')
              : i18next.t('button.next')}
            {currentStep !== PROFILE_STEP.AWARDS && <RightOutlined />}
          </NextButtonStyle>
        </Col>
      </Row>
    </StepFooter>
  );
};

export default Footer;

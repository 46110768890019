import React from 'react';
import PropTypes from 'prop-types';
import { Form, Button, Upload, notification, Input } from 'antd';
import i18next from 'i18next';
import { getUrl, uploadMedia } from 'api/uploadMedia';
import { useDispatch } from 'react-redux';
import { logoutSuccess } from 'redux/auth/slice';
import { AttachmentIcon } from 'components/common/SVGIcon';
import { resizeImageFile } from 'utils/fileUtils';
import { useFilesMessage } from './context';

import UploadFileMessageStyles from './styles';

const UploadFileMessage = ({ form, source, setIsDisabledSubmit }) => {
  const dispatch = useDispatch();

  const { fileList, setFileList } = useFilesMessage();

  const customRequest = async ({ onSuccess, onError, file }) => {
    const compressedFile = await resizeImageFile(file);

    if (compressedFile) {
      try {
        const responseS3 = await getUrl(
          compressedFile.name,
          compressedFile.type,
        );

        const response = await uploadMedia(
          responseS3.uploadUrl,
          compressedFile,
        );
        if (response?.status === 200) {
          onSuccess(responseS3.url, compressedFile);
        } else {
          onError(null, { status: 'done' });
        }
      } catch (error) {
        onError(null, { status: 'done' });
        if (error.code === 401) {
          dispatch(logoutSuccess());
          notification.error({
            message: i18next.t('error.title'),
            description: i18next.t('error.error401'),
            duration: 2,
            position: 'tr',
          });
        } else
          notification.error({
            message: i18next.t('error.title'),
            description: i18next.t('error.description'),
            position: 'tr',
            duration: 2,
          });
      }
    } else onError(null, { status: 'done' });
  };

  const setFieldAfterUploadChange = (results) => {
    const formattedData = results?.map((data) => ({
      name: data.name,
      type: data.type,
      url: data.response || data.url,
      status: data.status,
    }));

    form &&
      form.setFieldsValue({
        [source]: formattedData,
      });
  };
  const onChangeUpload = (e) => {
    setFileList(e.fileList);
    isDisableButtonSubmit(e.fileList);
    setFieldAfterUploadChange(e.fileList);
  };

  const isDisableButtonSubmit = (newFileList) => {
    const isDisabled =
      newFileList &&
      newFileList.length >= 0 &&
      newFileList?.filter((i) => {
        if (i.id !== undefined) return false;
        return i.response === undefined;
      }).length > 0;

    setIsDisabledSubmit(isDisabled);
  };

  return (
    <UploadFileMessageStyles>
      <Form.Item name={source}>
        <Input style={{ display: 'none' }} />
      </Form.Item>
      <Upload
        multiple
        customRequest={customRequest}
        onChange={onChangeUpload}
        showUploadList={false}
        fileList={fileList}
      >
        <Button className="button-upload">
          <AttachmentIcon />
        </Button>
      </Upload>
    </UploadFileMessageStyles>
  );
};

UploadFileMessage.propTypes = {
  form: PropTypes.object,
  source: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  setIsDisabledSubmit: PropTypes.func,
};

export default UploadFileMessage;

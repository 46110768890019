import React, { forwardRef, useImperativeHandle, useState } from 'react';
import i18next from 'i18next';
import ModalCustom from 'components/common/ModalCustom';
import { useSelector } from 'react-redux';
import { CentresNotifyModalStyles } from './styles';
import CentreHeader from './CentreHeader';
import CentreList from './CentreList';

const CentresNotifyModal = forwardRef((_props, ref) => {
  const [visible, setVisible] = useState(false);
  const [notifyId, setNotifyId] = useState();

  const notify =
    useSelector((state) => state.notificationsAndAlerts.data?.[notifyId]) || {};

  useImperativeHandle(
    ref,
    () => ({
      open: (data) => {
        setVisible(true);
        setNotifyId(data);
      },
    }),
    [],
  );

  const onCancel = () => setVisible(false);

  return (
    <ModalCustom
      visible={visible}
      title={i18next.t('notificationsAndAlerts.notification')}
      footer={null}
      onCancel={onCancel}
      resetPaddingBottomBody={false}
      resetPaddingTopBody={false}
      width={700}
      isScrollY
    >
      <CentresNotifyModalStyles>
        <CentreHeader notify={notify} />
        <CentreList
          centreAppSettings={notify?.centreAppSettings}
          keyNotify={notify.key}
        />
      </CentresNotifyModalStyles>
    </ModalCustom>
  );
});

export default CentresNotifyModal;

import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Comment, Avatar } from 'antd';
import { UserOutlined } from '@ant-design/icons';

import ActionsComment from './ActionsComment';
import HeaderComment from './HeaderComment';

import { CommentItemStyles } from './styles';
import ReliesSection from '../ReliesSection';

const CommentItem = ({ item, openDisputeModal }) => {
  const [visibleReply, setVisibleReply] = useState(false);
  const onToggle = useCallback(
    () => setVisibleReply(!visibleReply),
    [visibleReply],
  );

  return (
    <CommentItemStyles>
      <Comment
        actions={[
          <ActionsComment
            onVisibleReply={onToggle}
            isInternalRating={item.isInternalRating}
            openDisputeModal={openDisputeModal}
            isShowDispute={!item.disputation?.status}
          />,
        ]}
        // actions={[<ActionsComment onVisibleReply={onToggle} />]}
        author={
          <HeaderComment
            authorName={
              item.authorName ||
              `${item.user?.lastName} ${item.user?.firstName}`
            }
            rating={item.rating}
            createdAt={item.createdAt}
            isInternalRating={item.isInternalRating}
            isRecommended={item.isRecommended}
          />
        }
        avatar={
          <Avatar
            size={60}
            src={item.avatar?.url || item.user?.avatar}
            alt=""
            icon={<UserOutlined />}
          />
        }
        content={<p className="comment-message">{item.comment}</p>}
      >
        {item.isInternalRating ? (
          <ReliesSection
            id={item?.id}
            latestReplyReview={item?.latestReplyReview}
            totalReplies={item?.totalReplies}
            visibleReply={visibleReply}
          />
        ) : null}
      </Comment>
    </CommentItemStyles>
  );
};

CommentItem.propTypes = {
  item: PropTypes.object,
  openDisputeModal: PropTypes.func,
};

CommentItem.defaultProps = {
  item: {},
};

export default CommentItem;

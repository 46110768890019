import React, { useEffect, useMemo } from 'react';
import RestShow from 'containers/rest/Show';
import i18next from 'i18next';
import { useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';

import { getSummaryDetailContact } from 'redux/contacts/actions';
import { getCentreId } from 'redux/auth/selectors';

import ContactDetail from '../components/ContactDetail';

const ContactsShow = () => {
  const { id, model } = useParams();

  const centreId = useSelector(getCentreId);
  const currentData = useSelector((state) => state.contacts.currentData);

  const dispatch = useDispatch();

  const customBreadcrumb = useMemo(
    () => [
      {
        title: i18next.t('contacts.header'),
        path: `/contacts/${model || 'board'}`,
      },
      {
        title: i18next.t('contacts.titleDetail'),
      },
      {
        title: `${currentData.firstName} ${currentData.lastName}`,
      },
    ],
    [currentData.firstName, model, currentData.lastName],
  );

  useEffect(() => {
    id && dispatch(getSummaryDetailContact(id));
  }, [centreId, dispatch, id]);

  return (
    <RestShow
      isResetStyle
      hasEdit={false}
      resource="contacts"
      customBreadcrumb={customBreadcrumb}
      header="contacts.titleDetail"
      defaultOptions={{ suffixUrl: '/details' }}
    >
      <ContactDetail />
    </RestShow>
  );
};

export default ContactsShow;

import React from 'react';

import { Col, Row, Typography } from 'antd';
import i18next from 'i18next';
import RestInputItem from 'components/RestInput/RestInputItem';
import { validateRegex } from 'utils/validateUtils';

const CentreContactForm = () => (
  <div>
    <Typography.Title level={5} className="centre-form__title-section">
      {i18next.t('centres.centreContact')}
    </Typography.Title>
    <Row gutter={16}>
      <Col md={12} xs={24}>
        <RestInputItem
          required
          source="adminEmail"
          header="centres.adminEmail"
          placeholder="centres.adminEmail"
          rules={[
            {
              type: 'email',
              message: i18next.t('input.email.validateMsg.invalid'),
            },
          ]}
        />
      </Col>

      <Col md={12} xs={24}>
        <RestInputItem
          required
          source="enquiryEmail"
          header="input.notificationEmail.name"
          placeholder="input.notificationEmail.name"
          rules={[
            {
              type: 'email',
              message: i18next.t('input.email.validateMsg.invalid'),
            },
          ]}
        />
      </Col>

      <Col md={12} xs={24}>
        <RestInputItem
          required
          source="email"
          header="input.ACECQARegisteredEmail.name"
          placeholder="input.ACECQARegisteredEmail.name"
          rules={[
            {
              type: 'email',
              message: i18next.t('input.email.validateMsg.invalid'),
            },
          ]}
        />
      </Col>

      <Col md={12} xs={24}>
        <RestInputItem
          required
          source="phone"
          header="centres.phone"
          placeholder="centres.phone"
          rules={[
            {
              pattern: validateRegex.phone,
              message: i18next.t('error.phone'),
            },
          ]}
        />
      </Col>

      <Col md={12} xs={24}>
        <RestInputItem
          source="mobilePhone"
          header="centres.mobilePhone"
          placeholder="centres.mobilePhone"
          rules={[
            {
              pattern: validateRegex.phone,
              message: i18next.t('error.phone'),
            },
          ]}
        />
      </Col>

      <Col md={12} xs={24}>
        <RestInputItem
          source="fax"
          header="centres.fax"
          placeholder="centres.fax"
        />
      </Col>
      <Col md={12} xs={24}>
        <RestInputItem
          source="website"
          header="centres.website"
          placeholder="centres.website"
          rules={[
            {
              pattern: validateRegex.website,
              message: i18next.t('input.website.validateMsg.invalid'),
            },
          ]}
        />
      </Col>
    </Row>
  </div>
);

export default CentreContactForm;

import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import i18next from 'i18next';
import { Divider, Row, Col, Tooltip } from 'antd';
import { CloseCircleOutlined } from '@ant-design/icons';
import { formatMoney } from 'utils/tools';
import { calculateAvgPriceAllAgeGroup } from 'utils/dataUtils';
import { SummaryCardStyles, SharePercent, SummaryPercent } from './styles';

const SummaryCard = ({
  IconComponent,
  summaryTitle,
  summaryValue,
  summaryTotalTitle,
  summaryTotalValue,
  isCheckPrice,
  isShareValue,
  isNoUserCentre,
  isMoney,
}) => {
  const priceAllAge = calculateAvgPriceAllAgeGroup(
    summaryValue,
    summaryTotalValue,
  );

  const countValue = useMemo(() => {
    let component;
    if (isShareValue) {
      const percent = Math.abs((summaryValue / summaryTotalValue) * 100);
      const result = percent ? `${percent.toFixed(2)}` : '0.00';
      component = (
        <SharePercent
          placement="top"
          title={`${result}${i18next.t('priceBenchmarking.summaries.share')}`}
          className="ellipsis-t price fw-600"
        >
          {result}
          {i18next.t('priceBenchmarking.summaries.share')}
        </SharePercent>
      );
    }

    if (isCheckPrice) {
      component = (
        <Tooltip
          placement="top"
          title={`${priceAllAge.result}${priceAllAge.status}`}
          className="ellipsis-t"
        >
          {isNoUserCentre ? null : (
            <SummaryPercent
              type={priceAllAge.status === 'lower' ? 'success' : 'danger'}
            >
              {priceAllAge.result}
              {priceAllAge.status}
            </SummaryPercent>
          )}
        </Tooltip>
      );
    }
    return component;
  }, [
    isCheckPrice,
    isShareValue,
    summaryValue,
    summaryTotalValue,
    isNoUserCentre,
    priceAllAge,
  ]);

  return (
    <SummaryCardStyles>
      <Row gutter={[25, 25]}>
        <Col span={5}>
          <IconComponent />
        </Col>
        <Col span={19}>
          <div className="ml-8">
            <Tooltip
              placement="top"
              title={i18next.t(summaryTitle)}
              className="ellipsis-t fw-bold leading-tight text-14 d-block"
            >
              {i18next.t(summaryTitle)}
            </Tooltip>
            <div className="flex-center-start">
              {isMoney ? (
                <div className="fw-bold text-20 leading-normal pr-10">
                  {isNoUserCentre
                    ? '-'
                    : `${i18next.t('currencyUnit.sign')}${formatMoney(
                        summaryValue,
                        3,
                        true,
                      )}`}
                </div>
              ) : (
                <div className="ellipsis-t fw-bold text-20 leading-normal pr-10">
                  {formatMoney(summaryValue)}
                </div>
              )}
              {summaryTotalValue === 0 ? null : countValue}
            </div>
            <Divider className="m-0 p-0 mt-10" />
          </div>
          <div className="ml-8 mt-10">
            <Tooltip placement="top" title={i18next.t(summaryTotalTitle)}>
              <div className="ellipsis-t fw-bold leading-tight text-14">
                {i18next.t(summaryTotalTitle)}
              </div>
            </Tooltip>
            {isMoney ? (
              <div className="ellipsis-t fw-bold text-20 leading-normal">
                {i18next.t('currencyUnit.sign')}
                {formatMoney(summaryTotalValue, 3, true)}
              </div>
            ) : (
              <div className="ellipsis-t fw-bold text-20 leading-normal">
                {formatMoney(summaryTotalValue)}
              </div>
            )}
          </div>
        </Col>
      </Row>
    </SummaryCardStyles>
  );
};

SummaryCard.propTypes = {
  IconComponent: PropTypes.any,
  isMoney: PropTypes.bool,
  summaryTitle: PropTypes.string,
  summaryValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  summaryTotalTitle: PropTypes.string,
  summaryTotalValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  isCheckPrice: PropTypes.bool,
  isShareValue: PropTypes.bool,
  isNoUserCentre: PropTypes.bool,
};

SummaryCard.defaultProps = {
  IconComponent: CloseCircleOutlined,
};

export default SummaryCard;

import { Form } from 'antd';

import ModalCustom from 'components/common/ModalCustom';
import { RestInputContext } from 'components/RestInput/RestInputContext';
import {
  CreateCentreServiceDto,
  useCentreServiceControllerCreateOneMutation,
} from 'generated/apis';
import { isNil, omitBy } from 'lodash-es';
import React, { useCallback } from 'react';
import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import ServiceForm from './ServiceForm';

interface Props {
  visible?: boolean;
  onCancel?: () => void;
  isProgramEdit?: boolean;
  isProgramCreate?: boolean;
}

const CreateServiceModal = ({
  visible,
  onCancel,
  isProgramEdit,
  isProgramCreate,
}: Props) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const { id } = useParams();
  const { validateFields, resetFields } = form;
  const [createService, { isLoading }] =
    useCentreServiceControllerCreateOneMutation();

  const onOk = () => {
    validateFields().then(async (value: CreateCentreServiceDto) => {
      const service = omitBy(
        {
          centreId: id,
          roomName: value.roomName?.trim() ?? null,
          capacity: value.capacity ?? null,
          serviceTypeId: value.serviceTypeId,
          serviceAgeGroupId: value.serviceAgeGroupId,
          sessionTypeId: value.sessionTypeId,
          programId: value.programId ?? null,
          costPerDay: value.costPerDay ?? null,
        },
        isNil,
      ) as CreateCentreServiceDto;

      await createService({
        createCentreServiceDto: service,
      });
      handleOnCancel();
    });
  };

  const handleOnCancel = useCallback(() => {
    onCancel();
    resetFields();
  }, [onCancel, resetFields]);

  return (
    <ModalCustom
      title={t('centreServices.createPage')}
      visible={visible}
      onCancel={handleOnCancel}
      onOk={onOk}
      loading={isLoading}
      okText="button.create"
      width={822}
      isScrollY
    >
      <Form form={form} layout="vertical">
        <RestInputContext.Provider value={{ form }}>
          <ServiceForm
            form={form}
            isProgramCreate={isProgramCreate}
            isProgramEdit={isProgramEdit}
          />
        </RestInputContext.Provider>
      </Form>
    </ModalCustom>
  );
};

export default CreateServiceModal;

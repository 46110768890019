import React from 'react';
import i18next from 'i18next';
import RestInputItem from 'components/RestInput/RestInputItem';
import RestAvatarInput from 'components/RestInput/RestAvatarInput';
import { validateRegex } from 'utils/validateUtils';

const ContactsForm = () => (
  <div>
    <div className="flex-center mb-10">
      <RestAvatarInput
        source="avatar"
        className="avatar-section"
        style={{
          width: 150,
          height: 150,
        }}
      />
    </div>

    <RestInputItem
      required
      source="fullName"
      placeholder="contacts.fullName"
      header="contacts.fullName"
    />
    <RestInputItem
      required
      source="email"
      placeholder="contacts.email"
      header="contacts.email"
      rules={[
        {
          type: 'email',
          message: i18next.t('contacts.rules.email'),
        },
      ]}
    />
    <RestInputItem
      required
      source="address"
      placeholder="contacts.address"
      header="contacts.address"
    />
    <RestInputItem
      required
      source="phone"
      placeholder="contacts.phone"
      header="contacts.phone"
      rules={[
        {
          pattern: validateRegex.phone,
          message: i18next.t('contacts.rules.phone'),
        },
      ]}
    />
  </div>
);

export default ContactsForm;

import React from 'react';
import { Col, Form, InputNumber, Row, TimePicker } from 'antd';
import i18next from 'i18next';
import moment, { Moment } from 'moment';
import RestInputItem from 'components/RestInput/RestInputItem';
import {
  DEFAULT_DEADLINE_HOURS_DURATION,
  MAX_DEADLINE_DAYS,
  MAX_DEADLINE_HOURS,
  MIN_DEADLINE_DAYS,
} from 'configs/localData/constant';
import RestFormDateTimePicker from 'components/RestInput/RestDateTimePicker';
import { isValidDurationDays, isValidDurationHours } from 'utils/dataUtils';
import styled from 'styled-components';

const { Item } = Form;

const DateTimeDurationForm = (props) => {
  const { form, initialValues } = props;
  const today = moment().startOf('day');
  const { durationDays, durationHours, endTime, startTime } = initialValues;

  const handleChangeEndDate = (value: Moment) => {
    const fromDate = form.getFieldValue('from') || moment(today);
    const endDate = moment(value).endOf('day');

    const { fromTime, endTime, durationHours } = form.getFieldsValue([
      'fromTime',
      'endTime',
      'durationHours',
    ]);

    const fromDateTime = fromDate.clone();
    const endDateTime = endDate.clone();
    if (fromTime && endTime) {
      fromDateTime.hour(fromTime.get('hour')).minute(fromTime.get('minute'));
      endDateTime.hour(endTime.get('hour')).minute(endTime.get('minute'));
    }

    const countHours = endDateTime.diff(fromDateTime, 'hours');
    const countDays = countHours > 0 ? (countHours - durationHours) / 24 : 0;

    const _durationHours = countHours === 0 ? countHours : durationHours;
    const isInvalid = countDays === 0 && _durationHours === 0;

    const errors = [
      {
        name: 'durationHours',
        errors: isInvalid
          ? [i18next.t('advertisement.validateMsg.hour.invalid')]
          : [],
      },
      {
        name: 'durationDays',
        errors: isInvalid
          ? [i18next.t('advertisement.validateMsg.day.invalid')]
          : [],
      },
    ];
    if (value && fromDate) {
      form.setFieldsValue({
        durationDays: countDays,
        durationHours: _durationHours,
      });
    }
    form.setFields([...errors]);
  };

  const handleChangeFromTime = (fromTime: Moment) => {
    const durationHours = form.getFieldValue('durationHours');
    let durationDays = form.getFieldValue('durationDays');
    const fromDate = form.getFieldValue('from');

    if (durationDays >= 0 && isValidDurationHours(durationHours) && fromTime) {
      form.setFieldsValue({
        endTime: moment(fromTime).add(durationHours, 'hours'),
        fromTime: moment(fromTime),
      });

      if (
        fromTime.get('hour') >= 23 ||
        fromTime.get('hour') + durationHours >= 24
      ) {
        durationDays += 1;
      }

      form.setFieldsValue({
        to: moment(fromDate).add(durationDays, 'days'),
      });
    }
  };

  const handleChangeDurationDays = (dayValue) => {
    const {
      from: fromDate,
      durationHours: countHours,
      fromTime,
    } = form.getFieldsValue(['from', 'durationHours', 'fromTime']);
    const isFieldError = dayValue >= MAX_DEADLINE_DAYS && countHours;
    const durationHoursField = {
      name: 'durationHours',
      errors:
        isFieldError || (dayValue === 0 && countHours === 0)
          ? [i18next.t('advertisement.validateMsg.hour.invalid')]
          : [],
    };
    form.setFields([durationHoursField]);

    if (isValidDurationDays(dayValue) && fromDate) {
      let extraDays = 0;
      const fromTimeHour = fromTime.get('hour');
      if (
        countHours > 0 &&
        (fromTimeHour >= 23 || fromTimeHour + countHours >= 23)
      ) {
        extraDays += 1;
      }
      form.setFieldsValue({
        to: moment(fromDate).add(dayValue + extraDays, 'days'),
      });
    } else {
      form.setFieldsValue({
        from: today,
        to: undefined,
      });
    }
  };

  const handleChangeDurationHours = (hoursValue: number) => {
    const fieldError = form.getFieldError('durationDays');
    let countDays = form.getFieldValue('durationDays');
    const {
      fromTime,
      from: fromDate,
      endTime,
    } = form.getFieldsValue(['fromTime', 'from', 'endTime']);
    const overTimeErrorMsg = i18next.t('advertisement.validateMsg.day.invalid');

    if (
      (countDays === MAX_DEADLINE_DAYS && hoursValue > 0) ||
      (hoursValue === 0 && countDays === 0)
    ) {
      form.setFields([{ name: 'durationDays', errors: [overTimeErrorMsg] }]);
    } else {
      const index = fieldError.indexOf(overTimeErrorMsg);
      if (index !== -1) {
        fieldError.splice(index, 1);
        form.setFields([{ name: 'durationDays', errors: [] }]);
      }
    }
    if (isValidDurationHours(hoursValue) && fromTime) {
      if (
        hoursValue === MAX_DEADLINE_HOURS &&
        endTime.hour() === MAX_DEADLINE_HOURS
      ) {
        countDays += 1;
      }
      form.setFieldsValue({
        to: moment(fromDate)
          .add(countDays, 'days')
          .hour(fromTime.get('hour'))
          .minute(fromTime.get('minute'))
          .add(hoursValue, 'hours'),
        endTime: moment(fromTime).add(hoursValue, 'hours'),
      });
    } else {
      form.setFieldsValue({
        to: moment(fromDate).add(countDays, 'days'),
      });
    }
  };

  const handleChangeFromDate = (fromDate: Moment) => {
    const {
      durationDays,
      durationHours: countHours,
      fromTime,
    } = form.getFieldsValue([
      'durationDays',
      'durationHours',
      'fromTime',
      'endTime',
    ]);
    let extraDay = 0;
    let isEnoughtoAddDay = false;

    if (
      fromTime.get('hour') + countHours === 0 ||
      countHours + fromTime.get('hour') >= 24
    ) {
      isEnoughtoAddDay = true;
    }

    if (durationDays >= 0 && fromDate && isValidDurationDays(durationDays)) {
      if (fromTime.get('hour') >= 23 || isEnoughtoAddDay) {
        extraDay += 1;
      }

      form.setFieldsValue({
        to: moment(fromDate).add(durationDays + extraDay, 'days'),
      });
    }
  };

  const getDisabledFromDate = (currentDate) =>
    currentDate ? currentDate < today : false;

  const getDisabledEndDate = (currentDate) => {
    const fromDate = form.getFieldValue('from') || moment(today);
    return currentDate
      ? currentDate < moment(fromDate) ||
          currentDate > moment(fromDate).add(MAX_DEADLINE_DAYS, 'days')
      : false;
  };

  const getDurationDaysRules = () => [
    ({ getFieldValue }) => ({
      validator() {
        return new Promise<void>((resolve, reject) => {
          const { durationHours, durationDays } = getFieldValue();
          if (durationHours === 0 && durationDays === 0) {
            reject(i18next.t('advertisement.validateMsg.day.invalid'));
          }
          if (!Number.isInteger(durationDays)) {
            return reject(i18next.t('advertisement.validateMsg.day.beInteger'));
          }
          if (durationDays < MIN_DEADLINE_DAYS) {
            return reject(
              i18next.t('advertisement.validateMsg.day.greaterThan0'),
            );
          }
          if (durationDays > MAX_DEADLINE_DAYS) {
            return reject(
              i18next.t('advertisement.validateMsg.day.cannotExceed365'),
            );
          }
          return resolve();
        });
      },
    }),
  ];

  const getDuratioHoursRules = () => [
    ({ getFieldValue }) => ({
      validator() {
        return new Promise<void>((resolve, reject) => {
          const { durationHours, durationDays } = getFieldValue();
          if (
            Number.isNaN(Number(durationHours)) ||
            (durationHours === 0 && durationDays === 0) ||
            (durationDays >= MAX_DEADLINE_DAYS && durationHours > 0)
          ) {
            reject(i18next.t('advertisement.validateMsg.hour.invalid'));
          }
          if (durationHours < DEFAULT_DEADLINE_HOURS_DURATION) {
            reject(i18next.t('advertisement.validateMsg.hour.greaterThan0'));
          }
          if (durationHours > MAX_DEADLINE_HOURS) {
            reject(i18next.t('advertisement.validateMsg.hour.cannotExceed24'));
          }
          return resolve();
        });
      },
    }),
  ];

  return (
    <DateTimeDurationFormStyles>
      <Form
        form={form}
        initialValues={{
          durationDays,
          durationHours,
          endTime: moment(endTime),
          from: moment(startTime),
          fromTime: moment(startTime),
          to: moment(endTime),
        }}
      >
        <Row gutter={[16, 16]} className="flex-align-baseline">
          <Col md={10} lg={7} xl={5} xxl={5}>
            <RestInputItem
              source="durationDays"
              ContentComponent={InputNumber}
              onChange={handleChangeDurationDays}
              validateFirst
              required
              rules={getDurationDaysRules()}
              className="w-full"
            />
          </Col>
          <Col xl={2} xxl={2}>
            <p>{i18next.t('advertisement.unit.days')}</p>
          </Col>
          <Col md={10} lg={7} xl={5} xxl={5}>
            <RestInputItem
              source="durationHours"
              ContentComponent={InputNumber}
              onChange={handleChangeDurationHours}
              validateFirst
              required
              rules={getDuratioHoursRules()}
              className="w-full"
            />
          </Col>
          <Col xl={2} xxl={2}>
            <p>{i18next.t('advertisement.unit.hours')}</p>
          </Col>
        </Row>
        <Row gutter={[1, 1]} className="mt-12 items-start">
          <Col xl={6} md={7} xs={10}>
            <RestFormDateTimePicker
              required
              header="From"
              source="from"
              onChange={handleChangeFromDate}
              disabledDate={getDisabledFromDate}
              isShowTooltip={false}
            />
          </Col>
          <Col
            xl={3}
            md={5}
            xs={12}
            lg={4}
            className="ml-8 flex align-items-end"
          >
            <Item name="fromTime">
              <TimePicker
                onChange={handleChangeFromTime}
                format="hh:mm A"
                allowClear={false}
              />
            </Item>
          </Col>

          <Col xl={6} md={7} xs={8} className="ml-12">
            <RestFormDateTimePicker
              required
              header="to"
              source="to"
              onChange={handleChangeEndDate}
              disabledDate={getDisabledEndDate}
              isShowTooltip={false}
            />
          </Col>
          <Col
            xl={3}
            md={5}
            xs={12}
            lg={4}
            className="ml-8 flex align-items-end"
          >
            <Item name="endTime">
              <TimePicker format="hh:mm A" disabled />
            </Item>
          </Col>
        </Row>
      </Form>
    </DateTimeDurationFormStyles>
  );
};

export const DateTimeDurationFormStyles = styled.div`
  .ant-form-item-explain-error {
    font-size: 13px;
  }

  .ant-form-item {
    flex-direction: row;
  }
`;

export default DateTimeDurationForm;

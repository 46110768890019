import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Typography } from 'antd';
import ReadMore from 'components/common/ReadMore';
import { isEmpty } from 'lodash-es';
import DiamondPoint from 'components/common/DiamondPoint';
import i18next from 'i18next';
import { getApplicationSettingsTab } from 'containers/JobTemplates/utils';
import { IS_PREFERRED, IS_REQUIRED } from 'containers/JobTemplates/constants';

const AboutYou = ({ currentJobTemplate }) => {
  const [applicationSettings, setApplicationSettings] = useState([]);

  useEffect(() => {
    setApplicationSettings(getApplicationSettingsTab(currentJobTemplate));
  }, [currentJobTemplate]);

  const preferredList = applicationSettings.filter(
    (item) => item?.value === IS_PREFERRED,
  );

  const requiredList = applicationSettings.filter(
    (item) => item?.value === IS_REQUIRED,
  );

  return (
    <div>
      <div className="leading-loose">
        {currentJobTemplate?.aboutCandidate && (
          <>
            <Typography.Text className="fw-bold text-20 mr-10">
              {i18next.t('jobs.moreAboutTheJob.aboutYou')}
            </Typography.Text>
            <ReadMore
              text={currentJobTemplate?.aboutCandidate || ''}
              readMoreCharacterLimit={500}
              showLessButton
              isCheckInsertLink
              showLessText="button.readLess"
            />
          </>
        )}
        {!isEmpty(requiredList) && (
          <>
            <Typography.Text className="fw-bold text-16 mr-10 text-gray-500">
              {i18next.t('common.required')}
            </Typography.Text>
            {requiredList?.map((item, index) => (
              <div className="flex mt-12" key={String(index)}>
                <div className="mt-12">
                  <DiamondPoint />
                </div>
                <div className="ml-12 leading-loose text-16 break-all">
                  {item?.name}
                </div>
              </div>
            ))}
          </>
        )}

        {!isEmpty(preferredList) && (
          <div>
            <Typography.Text className="fw-bold text-16 mr-10 text-gray-500">
              {i18next.t('common.preferred')}
            </Typography.Text>
            {preferredList?.map((item, index) => (
              <div className="flex mt-12" key={String(index)}>
                <div className="mt-12">
                  <DiamondPoint />
                </div>
                <div className="ml-12 leading-loose text-16 break-all">
                  {item?.name}
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

AboutYou.propTypes = {
  currentJobTemplate: PropTypes.object,
};

export default AboutYou;

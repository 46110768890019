import React from 'react';
import { chunk } from 'lodash-es';
import { Col, Row } from 'antd';
import { FeatureItemResponse } from 'types/centreFeatures';
import FeatureItem from './FeatureItem';
import { ColumnFeatureStyles } from './styles';

interface Props {
  features: FeatureItemResponse[];
  CRMPackageName: string;
}

const FeatureCategoryItem = ({ features, CRMPackageName }: Props) => {
  const featuresByColumns = chunk(features, Math.ceil(features?.length / 2));

  return (
    <ColumnFeatureStyles className="relative py-4">
      <Row gutter={80}>
        {featuresByColumns?.map(
          (item: FeatureItemResponse[], index: number) => (
            <Col span={12} key={index} className="leading-16">
              {item?.map((feature) => (
                <FeatureItem
                  checked={feature?.enable}
                  item={feature}
                  CRMPackageName={CRMPackageName}
                />
              ))}
            </Col>
          ),
        )}
      </Row>
    </ColumnFeatureStyles>
  );
};

export default FeatureCategoryItem;

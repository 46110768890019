import React from 'react';
import PropTypes from 'prop-types';

const Square = ({ color }) => (
  <svg
    className="recharts-surface svg-custom-style"
    width="14"
    height="14"
    viewBox="0 0 32 32"
    version="1.1"
  >
    <path
      fill={color}
      cx="16"
      cy="16"
      type="square"
      className="recharts-symbols"
      transform="translate(16, 16)"
      d="M-16,-16h32v32h-32Z"
    />
  </svg>
);

Square.propTypes = {
  color: PropTypes.string,
};

export default Square;

import React, { useMemo, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import i18next from 'i18next';
import { VALUE_ROOT_OPEN_TIME } from 'configs/localData';
import { isEmpty } from 'lodash-es';
import { Button, Form, Space } from 'antd';

import { isEqualTwoListKeysByTime } from 'utils/tools';

import EditCustomBtn from 'components/RestActions/EditCustomBtn';
import HolidayHourForm from './HolidayHourForm';
import { handleFormatOpenTime } from '../utils';
import { useCentre } from '../../../../Show/context';
import { SectionHourStyles } from '../styles';

const HolidayHour = ({ openTime, record }) => {
  const [form] = Form.useForm();

  const { validateFields, resetFields } = form;
  const { updateCentre } = useCentre();

  const isDifferent = useMemo(() => {
    if (isEmpty(openTime)) return false;

    return !isEqualTwoListKeysByTime(
      VALUE_ROOT_OPEN_TIME.arrRootHoliday1,
      VALUE_ROOT_OPEN_TIME.arrRootHoliday2,
      openTime,
    );
  }, [openTime]);

  const [isEdit, setIsEdit] = useState(false);

  const onClickButton = useCallback(() => {
    setIsEdit(true);
  }, []);

  const onSubmit = useCallback(() => {
    validateFields().then((values) => {
      const newOpenTime = handleFormatOpenTime({
        ...values,
        oldOpenTime: openTime,
      });

      updateCentre({ openTime: newOpenTime }).then(({ payload }) => {
        if (payload?.data?.id) {
          handleCancel();
        }
      });
    });
  }, [validateFields, updateCentre, openTime]); // eslint-disable-line

  const handleCancel = useCallback(() => {
    setIsEdit(false);
    resetFields();
  }, []); // eslint-disable-line
  return (
    <SectionHourStyles>
      <div className="header-section">
        <span className="name-section">{i18next.t('seasonType.holiday')}</span>
        {isEdit ? (
          <Space size={20}>
            <Button className="cancel-button" onClick={handleCancel}>
              {i18next.t('button.cancel')}
            </Button>
            <Button type="primary" onClick={onSubmit}>
              {i18next.t('button.save')}
            </Button>
          </Space>
        ) : (
          <EditCustomBtn
            packageName={record?.CRMPackage?.name}
            permissionKey="myCentres"
            action="isEdit"
            onClickButton={onClickButton}
          />
        )}
      </div>
      <Form form={form}>
        <HolidayHourForm
          isEdit={isEdit}
          openTime={openTime}
          isDifferent={isDifferent}
        />
      </Form>
    </SectionHourStyles>
  );
};

HolidayHour.propTypes = {
  openTime: PropTypes.object,
  record: PropTypes.object,
};

HolidayHour.defaultProps = {
  openTime: {},
};

export default HolidayHour;

import PropTypes from 'prop-types';
import React from 'react';
import { Tooltip, Typography } from 'antd';
import i18next from 'i18next';

const JobTemplateDetailItem = ({ icon, value, title, className }) => (
  <div className="flex items-center leading-9">
    <span className="text-green text-18 flex-center">{icon}</span>
    <Tooltip title={i18next.t(title)}>
      <Typography.Text className={`${className} ml-8`} ellipsis>
        {value || i18next.t('error.waitingUpdate')}
      </Typography.Text>
    </Tooltip>
  </div>
);

JobTemplateDetailItem.propTypes = {
  icon: PropTypes.element,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  title: PropTypes.string,
  className: PropTypes.string,
};

export default JobTemplateDetailItem;

import React, { useEffect, useState } from 'react';
import { Avatar } from 'antd';
import PropTypes from 'prop-types';
import { UserOutlined } from '@ant-design/icons';
import CardSection from 'components/common/CardSection';
import { BusinessCardStyles } from './styles';
import UploadBusinessLogoModal from '../../Modals/UploadBusinessLogoModal';

const BusinessCard = ({ logo, brandLogo }) => {
  const [visibleModal, setVisibleModal] = React.useState(false);

  const [disabled, setDisabled] = useState(false);

  useEffect(() => {
    if (brandLogo) {
      setDisabled(true);
    }
  }, [brandLogo]);

  const onClickButton = () => {
    setVisibleModal(true);
  };

  const onCancel = () => {
    setVisibleModal(false);
  };

  return (
    <CardSection
      isEdit
      title="centreDetail.brandLogo"
      permissionKey="myCentres"
      action="isEdit"
      onClickButton={onClickButton}
      disabled={disabled}
    >
      <BusinessCardStyles>
        <div className="content-business-logo">
          <Avatar size={160} src={logo} icon={<UserOutlined />} />
        </div>
      </BusinessCardStyles>
      <UploadBusinessLogoModal
        logo={{ logo }}
        onCancel={onCancel}
        visible={visibleModal}
        brandLogo={brandLogo}
      />
    </CardSection>
  );
};

BusinessCard.propTypes = {
  logo: PropTypes.string,
  brandLogo: PropTypes.string,
};

export default BusinessCard;

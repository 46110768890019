import React from 'react';
import { Avatar } from 'antd';
import { useSelector } from 'react-redux';
import { UserOutlined } from '@ant-design/icons';

import { HeaderWrapper } from './styles';

const Header = () => {
  const user = useSelector((state) => state.enquiries.currentData?.user) || {};

  return (
    <HeaderWrapper>
      <Avatar size={40} src={user.avatar} icon={<UserOutlined />} />
      <span className="name-user">
        {`${user.firstName || ''} ${user.lastName || ''}`}
      </span>
    </HeaderWrapper>
  );
};

Header.defaultProps = {};

export default Header;

import styled from 'styled-components';

export const CardCustomStyles = styled.div`
  .ant-card-hoverable:hover {
    box-shadow: none;
  }
  .ant-card-body {
    padding: 12px;
  }
`;

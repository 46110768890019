import { Checkbox } from 'antd';
import styled from 'styled-components';

export const MetricCheckboxGroup = styled(Checkbox.Group)`
  --metric-item-width: 16.67%;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 8px;

  .ant-checkbox-wrapper {
    flex-basis: var(--metric-item-width);
    max-width: var(--metric-item-width);

    align-items: center;
    transition: opacity 0.3s ease-in-out;

    & + .ant-checkbox-wrapper {
      margin-left: 0;
    }
  }

  .ant-checkbox {
    top: 0;
  }

  .ant-checkbox-inner {
    width: 16px;
    height: 16px;

    &::after {
      width: 5px;
      height: 8px;
    }
  }

  @media only screen and (max-width: 1500px) {
    --metric-item-width: 20%;
  }
  @media only screen and (max-width: 1200px) {
    --metric-item-width: 25%;
  }
  @media only screen and (max-width: 992px) {
    --metric-item-width: 33.33%;
  }
  @media only screen and (max-width: 576px) {
    --metric-item-width: 50%;
  }
`;

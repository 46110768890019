import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Draggable } from 'react-beautiful-dnd';
import { useDispatch } from 'react-redux';
import { DEFAULT_SORT_JOB_APPLICATIONS } from 'containers/JobApplications/constants';
import { updateTotalSubmittedJobApplication } from 'redux/jobs/jobApplications/slice';
import Header from './Header';
import { ContainerColumn } from './styles';
import JobApplicationBoardList from '../../ListItemStatus/JobApplicationBoardList';

const initialPaging = {
  page: 1,
  size: 10,
};
const Column = ({
  item,
  listId,
  title,
  index,
  isScrollable,
  isCombineEnabled,
  useClone,
  numberColumn,
  lineColor,
  colors,
  isDragDisabledColumn,
  isDragDisabledItem,
  getAllDataAction,
  isApplicationOfAJob,
  jobPostId,
  updateNewStatus,
  filterData = {},
}) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState();

  const [dataBoard, setDataBoard] = useState({
    data: [],
    total: 0,
    numberOfPages: 0,
  });

  const [paging, setPaging] = useState(initialPaging);

  const retrieveList = useCallback(
    (filter = {}, isRefresh) => {
      setLoading(true);
      dispatch(
        isApplicationOfAJob
          ? getAllDataAction({
              data: {
                ...filter,
                sorts: filterData.sorts || [DEFAULT_SORT_JOB_APPLICATIONS],
                filter: {
                  ...filterData.filter,
                  status: item?.application_id,
                  jobPostId,
                },
              },
              options: {
                isSuccess: true,
                isRefresh,
              },
            })
          : getAllDataAction({
              data: {
                ...filter,
                sorts: filterData.sorts || [DEFAULT_SORT_JOB_APPLICATIONS],
                filter: {
                  ...filterData.filter,
                  status: item?.application_id,
                },
              },
              options: {
                isSuccess: true,
                isRefresh,
              },
            }),
      )
        .then(({ payload }) => {
          if (payload?.options?.isSuccess) {
            const total = payload.data?.paging?.total || 0;
            if (listId === '1') {
              dispatch(updateTotalSubmittedJobApplication({ total }));
            }
            setDataBoard({
              data: isRefresh
                ? payload.data.results || []
                : [...dataBoard.data, ...(payload.data.results || [])],
              total,
              numberOfPages: Math.ceil(
                payload.data?.paging?.total / payload.data?.paging?.size,
              ),
            });
          }
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [
      dataBoard.data,
      dispatch,
      item,
      getAllDataAction,
      isApplicationOfAJob,
      jobPostId,
      filterData,
      listId,
    ],
  );
  useEffect(() => {
    setDataBoard({
      data: [],
      total: 0,
      numberOfPages: 0,
    });
    setPaging(initialPaging);
    retrieveList(initialPaging, true);
  }, [updateNewStatus, filterData]); // eslint-disable-line

  const isShowWaypoint = !loading && paging.page < dataBoard.numberOfPages;

  const handleLoadMore = () => {
    const newPaging = {
      ...paging,
      page: paging.page + 1,
    };
    setPaging(newPaging);

    retrieveList(newPaging, false);
  };

  return (
    <Draggable
      draggableId={listId}
      index={index}
      isDragDisabled={isDragDisabledColumn}
    >
      {(provided, snapshot) => (
        <ContainerColumn
          ref={provided.innerRef}
          {...provided.draggableProps}
          numberColumn={numberColumn}
        >
          <Header
            isDragging={snapshot.isDragging}
            {...provided.dragHandleProps}
            title={title}
            lineColor={lineColor}
            count={dataBoard.total}
          />
          <JobApplicationBoardList
            count={dataBoard.count}
            listId={listId}
            title={title}
            listType="JOB_APPLICATION"
            internalScroll={isScrollable}
            isCombineEnabled={Boolean(isCombineEnabled)}
            useClone={Boolean(useClone)}
            colors={colors}
            isDragDisabledItem={isDragDisabledItem}
            loading={loading}
            handleLoadMore={handleLoadMore}
            isShowWaypoint={isShowWaypoint}
            data={dataBoard.data}
          />
        </ContainerColumn>
      )}
    </Draggable>
  );
};

Column.propTypes = {
  listId: PropTypes.string,
  title: PropTypes.string,
  getAllDataAction: PropTypes.func,
  index: PropTypes.number,
  isScrollable: PropTypes.bool,
  isCombineEnabled: PropTypes.bool,
  useClone: PropTypes.bool,
  numberColumn: PropTypes.number,
  lineColor: PropTypes.string,
  colors: PropTypes.object,
  isDragDisabledColumn: PropTypes.bool,
  isDragDisabledItem: PropTypes.bool,
  summaryStatus: PropTypes.object,
  item: PropTypes.object,
  isApplicationOfAJob: PropTypes.bool,
  jobPostId: PropTypes.string,
  updateNewStatus: PropTypes.string,
  filterData: PropTypes.object,
};

Column.defaultProps = {
  summaryStatus: {},
};

export default Column;

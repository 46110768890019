import { getDeviceId } from 'utils/tools';
import axios from 'axios';
import { CurrentLocation } from 'types';

const TIME_OUT = 20000;

export async function eventTrackingApi(params: {
  eventName: string;
  eventData?: object;
  geolocation: CurrentLocation;
}) {
  const eventPayload = {
    ...params,
    platform: 'crm',
    deviceId: getDeviceId(),
    eventTime: new Date().toISOString(),
    eventData: JSON.stringify({ ...params?.eventData } || {}),
  };
  return axios.post(process.env.REACT_APP_TRACKING_API_URL, eventPayload, {
    timeout: TIME_OUT,
  });
}

import { uniqBy, remove, findIndex } from 'lodash-es';
import { makeCRUDSlice } from 'redux/crudCreator';
import {
  MODEL_NAME,
  centresActions,
  claimCentre,
  signUpMerchant,
  getSummaryDetailCentre,
  getRatingsCentre,
  searchHeaderCentre,
  createCentreService,
  editCentreService,
  delCentreService,
  delFeaturedMarketing,
  disputeReview,
  changeFinishedStep,
  editAppSettingsCentre,
  getAppSettingsCentre,
  editSocialMediaChannels,
  getAllSocialMediaChannels,
  getKindiCareRatingSummary,
  getCentreVirtualTour,
  addCentreVirtualTour,
} from './actions';

const INITIAL_STATE_RATINGS = {
  resourceData: {
    filter: {},
    offset: 0,
    page: 1,
    limit: 10,
    total: 0,
    numberOfPages: 1,
    sort: '',
  },
  data: [],
  socialMedia: [],
  totalSocialMedia: 0,
};

const claimCentreSuccess = (state, { payload }) => {
  state.currentData = {
    ...state.currentData,
    claimStatus: 'PENDING',
    latestClaimCentre: {
      isAdminEmailUsed: payload?.isAdminEmailUsed,
    },
  };
};

const getSummaryDetailCentreSuccess = (state, { payload }) => {
  state.summaryDetail = payload;
};

const getRatingsCentrePending = (
  state,
  {
    meta: {
      arg: { data, options = {} },
    },
  },
) => {
  state.ratingsLoading = true;

  if (options.isRefresh) {
    state.ratings = {
      resourceData: {
        ...INITIAL_STATE_RATINGS.resourceData,
        ...data,
      },
      data: [],
    };
  } else {
    const resourceData = state.ratings.resourceData || {};

    state.ratings.resourceData = {
      ...resourceData,
      offset: resourceData.offset + resourceData.limit || 0,
      ...data,
    };
  }
};

const getRatingsCentreSuccess = (state, { payload: { data } }) => {
  const ratingsData = state.ratings.data;

  state.ratings.data = uniqBy([...ratingsData, ...data?.results], 'id');

  state.ratings.resourceData = {
    ...state.ratings.resourceData,
    total: data?.total || 0,
    numberOfPages: data.numberOfPages,
  };

  state.ratingsLoading = false;
  state.error = null;
};

const getRatingsCentreFail = (state, { payload: { data } }) => {
  state.error = data;
  state.ratingsLoading = false;
};

const searchHeaderCentrePending = (
  state,
  {
    meta: {
      arg: { data },
    },
  },
) => {
  state.loading = true;
  state.q = data.q;
};

const searchHeaderCentreSuccess = (state, { payload: { data } }) => {
  state.loading = false;
  state.ids = data.ids;
  state.data = data.data;
  state.additionData = data.additionData;
  state.total = data.total;
  state.numberOfPages = data.numberOfPages;
};

const searchHeaderCentreFail = (state, { payload: { data } }) => {
  state.error = data;
  state.loading = false;
};

const createCentreServicePending = (state) => {
  state.createCentreServiceLoading = true;
};

const createCentreServiceSuccess = (state, { payload: { data } }) => {
  state.createCentreServiceLoading = false;
  state.error = null;
  state.currentData.services = [data, ...state.currentData.services];
};

const createCentreServiceFail = (state, { data }) => {
  state.createCentreServiceLoading = false;
  state.error = data;
};

const editCentreServicePending = (
  state,
  {
    meta: {
      arg: { data },
    },
  },
) => {
  state.centreServiceItemLoading = {
    ...state.centreServiceItemLoading,
    [data.id]: true,
  };
};

const editCentreServiceSuccess = (state, { payload: { data } }) => {
  state.error = null;

  state.currentData.services = state.currentData.services?.map((item) =>
    item.id === data?.id ? { ...item, ...data } : item,
  );

  state.centreServiceItemLoading[data.id] = false;
};

const editCentreServiceFail = (state, { payload: { data, id } }) => {
  state.centreServiceItemLoading[id] = false;
  state.error = data;
};

const delCentreServicePending = (state, { meta: { arg: payload } }) => {
  state.centreServiceItemLoading = {
    ...state.centreServiceItemLoading,
    [payload]: true,
  };
};

const delCentreServiceSuccess = (state, { payload: { id } }) => {
  state.error = null;
  state.currentData.services = remove(
    state.currentData.services,
    (obj) => obj.id !== id,
  );
  state.centreServiceItemLoading[id] = false;
};

const delCentreServiceFail = (state, { payload: { data, id } }) => {
  state.centreServiceItemLoading[id] = false;
  state.error = data;
};

const delFeaturedMarketingSuccess = (state, { payload: { marketingId } }) => {
  state.error = null;
  state.currentData.marketingIds = state.currentData.marketingIds?.filter(
    (id) => id !== marketingId,
  );
};

const disputeReviewSuccess = (state, { payload }) => {
  const matchIdx = findIndex(state.ratings.data, ['id', payload?.id]);
  if (matchIdx !== -1) {
    state.ratings.data[matchIdx] = payload;
  }
};
const getAppSettingsCentreSuccess = (state, { payload }) => {
  state.appSettings = payload;
};

const editAppSettingsCentreSuccess = (state, { payload }) => {
  state.appSettings = uniqBy(
    [...payload.data, ...(state.appSettings || [])],
    'id',
  );
};

const changeFinishedStepSuccess = (state, { payload }) => {
  state.currentData.isFinishedStep = payload.isFinishedStep;
};

const getSocialMediaChannelsSuccess = (state, { payload }) => {
  state.socialMedia = payload.buttons;
  state.totalSocialMedia = payload.total;
};

const editSocialMediaChannelsSuccess = (state, { payload }) => {
  state.socialMedia = uniqBy(
    [...payload.results, ...(state.socialMedia || [])],
    'id',
  );
};

const getKindiCareRatingSummaryPending = (state) => {
  state.loadingKindiCareRatingSummary = true;
};

const getKindiCareRatingSummaryFulfilled = (
  state,
  { payload: { kindiRatingSummary } },
) => {
  state.currentData.kindiRatingSummary = { ...kindiRatingSummary };
};

const getKindiCareRatingSummaryFailed = (state, { payload: { data } }) => {
  state.error = data;
  state.loadingKindiCareRatingSummary = false;
};

const getCentreVirtualTourSuccess = (state, { payload }) => {
  state.centreVirtualTour = payload?.results;
};

const addCentreVirtualTourSuccess = (state, { payload }) => {
  state.centreVirtualTour = payload.results;
};

const slice = makeCRUDSlice(MODEL_NAME, centresActions, {
  [claimCentre.fulfilled]: claimCentreSuccess,
  [signUpMerchant.pending]: (state) => {
    state.loadingMerchant = true;
  },
  [signUpMerchant.fulfilled]: (state) => {
    state.loadingMerchant = false;
  },
  [signUpMerchant.rejected]: (state) => {
    state.loadingMerchant = false;
  },

  [getSummaryDetailCentre.fulfilled]: getSummaryDetailCentreSuccess,

  [getRatingsCentre.pending]: getRatingsCentrePending,
  [getRatingsCentre.fulfilled]: getRatingsCentreSuccess,
  [getRatingsCentre.rejected]: getRatingsCentreFail,

  [searchHeaderCentre.pending]: searchHeaderCentrePending,
  [searchHeaderCentre.fulfilled]: searchHeaderCentreSuccess,
  [searchHeaderCentre.rejected]: searchHeaderCentreFail,

  [createCentreService.pending]: createCentreServicePending,
  [createCentreService.fulfilled]: createCentreServiceSuccess,
  [createCentreService.rejected]: createCentreServiceFail,

  [editCentreService.pending]: editCentreServicePending,
  [editCentreService.fulfilled]: editCentreServiceSuccess,
  [editCentreService.rejected]: editCentreServiceFail,

  [delCentreService.pending]: delCentreServicePending,
  [delCentreService.fulfilled]: delCentreServiceSuccess,
  [delCentreService.rejected]: delCentreServiceFail,

  [delFeaturedMarketing.fulfilled]: delFeaturedMarketingSuccess,

  [disputeReview.fulfilled]: disputeReviewSuccess,

  [changeFinishedStep.fulfilled]: changeFinishedStepSuccess,

  [getAppSettingsCentre.fulfilled]: getAppSettingsCentreSuccess,

  [editAppSettingsCentre.fulfilled]: editAppSettingsCentreSuccess,

  [getAllSocialMediaChannels.fulfilled]: getSocialMediaChannelsSuccess,

  [editSocialMediaChannels.fulfilled]: editSocialMediaChannelsSuccess,

  [getKindiCareRatingSummary.pending]: getKindiCareRatingSummaryPending,
  [getKindiCareRatingSummary.fulfilled]: getKindiCareRatingSummaryFulfilled,
  [getKindiCareRatingSummary.rejected]: getKindiCareRatingSummaryFailed,

  [getCentreVirtualTour.fulfilled]: getCentreVirtualTourSuccess,
  [addCentreVirtualTour.fulfilled]: addCentreVirtualTourSuccess,
});

export default slice.reducer;

import { values } from 'lodash-es';
import { CRUDSelectors } from 'redux/crudCreator';
import { createSelector } from 'reselect';
import { MODEL_NAME } from './actions';

const getRawPrimaryChildCareService = (state) =>
  state.config?.data?.primaryChildCareService;

const getRawNationalStates = (state) => state.config?.data?.stateOfAustralia;

const getServiceApprovalsData = (state) =>
  state?.companyBenchmarking?.userCompanyBenchmarkingChart?.serviceApprovals;

const getNQSRating = (state) =>
  state.companyBenchmarking?.userCompanyBenchmarkingChart?.nqsRating;

const getKindicareRating = (state) =>
  state.companyBenchmarking?.userCompanyBenchmarkingChart?.kindicareRating;

const getTotalStatictsData = (state) =>
  state.companyBenchmarking.summary?.totalStaticts;

const getUserStatictisData = (state) =>
  state.companyBenchmarking.summary?.userStatictis;

const getRawCompanyBenchmarkingCompareRating = (state) =>
  state?.companyBenchmarking?.compareRatingChart;

const getCompanyBenchmarkSummary = (state) =>
  state?.companyBenchmarking?.summary;

const getCompanyBenchmarkCharts = (state) =>
  state?.companyBenchmarking?.userCompanyBenchmarkingChart;

export const getActivePrimaryChildCareServices = createSelector(
  [getRawPrimaryChildCareService],
  (data) => data?.filter((data) => data?.isActive === true) || [],
);

export const getNationalStates = createSelector(
  [getRawNationalStates],
  (data) => data || [],
);

export const getDataServiceApprovals = createSelector(
  getServiceApprovalsData,
  (data) => data,
);

export const getCompanyBenchmarkingNSQRating = createSelector(
  [getNQSRating],
  (data) => data || [],
);

export const getCompanyBenchmarkingKindicareRating = createSelector(
  [getKindicareRating],
  (data) => data || [],
);

export const getTotalStatictsOfCompanyBenchmarking = createSelector(
  getTotalStatictsData,
  (data) => data,
);

export const getUserStatictisOfCompanyBenchmarking = createSelector(
  getUserStatictisData,
  (data) => data,
);

const getRawData = (state) => state.companyBenchmarking.data;

const getResourceCompanyBenchmarkingData = (state) =>
  state.companyBenchmarking.resourceData;

export const getResourceDataCompanyBenchmarkingSelector = createSelector(
  [getResourceCompanyBenchmarkingData],
  (data) => data,
);

export const getDataCompanyBenchmarking = createSelector([getRawData], (data) =>
  values(data),
);

const getRawAverageData = (state) =>
  state?.companyBenchmarking?.averageAllCompany;

export const getAverageDataSelector = createSelector(
  [getRawAverageData],
  (data) => data,
);

export const companyBenchmarkingSelectors = new CRUDSelectors(MODEL_NAME);

export const getCompanyBenchmarkingCompareRatingSelector = createSelector(
  [getRawCompanyBenchmarkingCompareRating],
  (data) => data || {},
);

export const getCompanyBenchmarkSummarySelector = createSelector(
  [getCompanyBenchmarkSummary],
  (data) => data || {},
);

export const getCompanyBenchmarkChartsSelector = createSelector(
  [getCompanyBenchmarkCharts],
  (data) => data || {},
);

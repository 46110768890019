import React from 'react';
import { Modal, Select } from 'antd';
import i18next from 'i18next';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import { editRoleUser } from 'redux/users/actions';

import { ArrowDownIcon } from 'components/common/SVGIcon';
import useRolesData from 'containers/Users/hooks/useRolesData';
import { DropDownWrapper } from './styles';

const { Option } = Select;

const UserRoleSelect = ({ roleId, disabled }) => {
  const rolesDefault = useRolesData();
  const dispatch = useDispatch();
  const { id } = useParams();

  const handleChange = (value) => {
    Modal.confirm({
      title: i18next.t('users.userRoleConfirmTitle'),
      content: i18next.t('users.userRoleConfirmContent'),
      onOk: () =>
        dispatch(
          editRoleUser({
            id,
            data: { roleId: value },
            role: rolesDefault?.find((item) => item.id === value),
          }),
        ),
    });
  };

  return (
    <DropDownWrapper
      value={roleId}
      style={{ width: '100%' }}
      onChange={handleChange}
      suffixIcon={<ArrowDownIcon style={{ pointerEvents: 'none' }} />}
      dropdownClassName="dropdown-status"
      disabled={disabled}
    >
      {rolesDefault?.map((item) => (
        <Option key={item.id} value={item.id}>
          {item.description}
        </Option>
      ))}
    </DropDownWrapper>
  );
};

UserRoleSelect.propTypes = {
  roleId: PropTypes.string,
  disabled: PropTypes.bool,
};

UserRoleSelect.defaultProps = {};

export default UserRoleSelect;

import React, { useEffect, useState } from 'react';

import i18next from 'i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  getAllAutomatedResponses,
  getAllAutomatedResponsesAllCentres,
} from 'redux/automatedResponses/actions';
import List from 'antd/lib/list';

import { changeFinishedStep } from 'redux/centres/actions';
import { useHistory } from 'react-router';
import SuccessListingStrengthModal from 'containers/Centres/components/CentreDetail/SuccessListingStrengthModal';
import AutomatedItem from './AutomatedItem';
import AutomatedListStyles from './styles';

const AutomatedList = () => {
  const dispatch = useDispatch();

  const centreId = useSelector((state) => state.auth.centreId);
  const data = useSelector((state) => state.automatedResponses.data);
  const loading = useSelector((state) => state.automatedResponses.loading);

  const centreCurrent = useSelector((state) => state.centres?.currentData);

  const [visible, setVisible] = useState(false);

  const { push } = useHistory();
  useEffect(() => {
    if (centreCurrent?.isFinishedStep) {
      setVisible(true);
    }
  }, [centreCurrent.isFinishedStep]);

  const onCancel = () => {
    setVisible(false);
  };

  const onCompleted = () => {
    dispatch(
      changeFinishedStep({
        centreId: centreCurrent?.id,
        data: { isFinishedStep: false },
      }),
    ).then(({ payload }) => {
      if (payload) {
        push(`/centres/${centreCurrent?.id}/show/summary`);
        onCancel();
      }
    });
  };

  useEffect(() => {
    if (centreId) {
      dispatch(getAllAutomatedResponses({ id: centreId }));
    } else {
      dispatch(getAllAutomatedResponsesAllCentres());
    }
  }, [centreId, dispatch]);

  return (
    <AutomatedListStyles className="automated-list">
      <div className="automated__title automated-list__title">
        {i18next.t('automatedResponses.header')}
      </div>

      <List
        className="automated-list__list"
        loading={loading}
        itemLayout="horizontal"
        dataSource={data}
        renderItem={(item) => (
          <List.Item>
            <AutomatedItem item={item} centreId={centreId} />
          </List.Item>
        )}
      />

      {centreCurrent?.isFinishedStep && (
        <SuccessListingStrengthModal
          visible={visible}
          onCancel={onCancel}
          onCompleted={onCompleted}
          loading={loading}
        />
      )}
    </AutomatedListStyles>
  );
};

export default AutomatedList;

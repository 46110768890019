import React from 'react';
import { useHistory } from 'react-router';
import { Button, Form } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import ReferenceInput from 'containers/rest/ReferenceInput';
import RestSelect from 'components/RestInput/RestSelect';
import { PREFIX_URL } from 'configs/localData/constant';
import { SearchBarStyles } from './styles';

interface Props {
  disabled?: boolean;
  className?: string;
}

const SearchBar = ({ disabled, className }: Props) => {
  const history = useHistory();
  const onChange = (value) => {
    if (value) {
      history.push(`/claim-centres/${value}`);
    }
  };

  return (
    <SearchBarStyles>
      <Form>
        <ReferenceInput
          source="centreId"
          resource="centres"
          searchKey="q"
          prefixUrl={PREFIX_URL.public}
        >
          <RestSelect
            titleProp="name"
            valueProp="id"
            disabled={disabled}
            placeholder="input.centres"
            isFilterOption={false}
            onChange={onChange}
            className={className}
            allowClear={false}
            suffixIcon={
              <Button
                className="btn-search-bar"
                shape="circle"
                type="primary"
                icon={<SearchOutlined />}
              />
            }
            formatText={(data, record) =>
              `${data} - ${record?.displayAddress || ''}`
            }
            dropdownClassName="claim-centre-search-bar"
          />
        </ReferenceInput>
      </Form>
    </SearchBarStyles>
  );
};

export default SearchBar;

import React from 'react';
import EditButton from 'components/RestActions/EditButton';
import { Avatar, Progress, Tag, Tooltip } from 'antd';
import { BriefcaseIcon } from 'components/common/SVGIcon';
import TagCustom from 'components/common/TagCustom';
import { isEmpty } from 'lodash-es';
import { UserOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router';
import { formatNullString } from 'utils/tools';
import i18next from 'i18next';
import { Link } from 'react-router-dom';
import { formatDateApplication } from 'utils/textUtils';
import { CustomerFieldStyles } from './styles';

const useFormat = () => {
  const { push } = useHistory();

  const formatData = (data) => (
    <Tooltip title={data}>
      <span className="ellipsis-t-2">{formatNullString(data)}</span>
    </Tooltip>
  );

  const formatApplicantName = (data, record) => (
    <CustomerFieldStyles>
      <Avatar size={45} src={data?.avatar} alt="" icon={<UserOutlined />} />
      <Link to={`/job-applications/${record?.id}/show/summary`}>
        <div className="flex flex-row">
          <span className="t-bold black ellipsis-t-2 link-class">
            {`${data?.firstname || ''} ${data?.lastname || ''}`}
          </span>
          {data?.pronouns && (
            <span className="ml-6 text-gray-500 fw-400">{data?.pronouns}</span>
          )}
        </div>
      </Link>
    </CustomerFieldStyles>
  );

  const formatProgressValue = (data) => (
    <Progress strokeColor="#2BADA7" percent={data} status="active" showInfo />
  );

  const formatJobType = (data) =>
    !isEmpty(data) && (
      <Tag icon={<BriefcaseIcon />} className="text-14 fw-600">
        {data?.name}
      </Tag>
    );

  const formatApplicationStatus = (data) => (
    <TagCustom
      className="fw-bold"
      color={data?.textColor}
      backgroundColor={data?.backgroundColer}
      text={data?.name}
      isBorder
    />
  );

  const formatGroupAction = (id) => (
    <div className="flex-center-between">
      <EditButton
        isView
        title="jobs.jobApplications.viewApplication"
        source="show"
        onClickCustom={() => {
          push(`/job-applications/${id}/show/summary`);
        }}
      />
    </div>
  );

  const formatAvailableStart = (data) => {
    if (data?.isAvailableStart) {
      return i18next.t('jobListings.immediateStart');
    }
    return formatNullString(formatDateApplication(data?.specificStartDate));
  };

  return {
    formatData,
    formatApplicantName,
    formatJobType,
    formatApplicationStatus,
    formatGroupAction,
    formatProgressValue,
    formatAvailableStart,
  };
};

export default useFormat;

import { del, post } from './utils';

export async function enableFeaturedMarketingApi(centreId, marketingId, data) {
  return post(`/admin/centres/${centreId}/marketings/${marketingId}`, data);
}

export async function callbackFeaturedMarketingApi(
  centreId,
  marketingId,
  data,
) {
  return post(
    `/admin/centres/${centreId}/marketings/${marketingId}/callback`,
    data,
  );
}

export async function delFeaturedMarketingApi(centreId, marketingId) {
  return del(`/admin/centres/${centreId}/marketings/${marketingId}`);
}

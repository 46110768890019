import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { SorterIcon } from '../SVGIcon';
import { TitleSorterWrapper } from './styles';

const TitleSorter = ({ title, sortDesc }) => {
  const classSorterIcon = useMemo(() => {
    if (sortDesc === 'ascend') return 'asc-sorter-icon';

    if (sortDesc === 'descend') return 'desc-sorter-icon';

    return '';
  }, [sortDesc]);

  return (
    <TitleSorterWrapper>
      <span className="title-header">{title}</span>
      <SorterIcon className={`icon-sorter ${classSorterIcon}`} />
    </TitleSorterWrapper>
  );
};

TitleSorter.propTypes = {
  title: PropTypes.string,
  sortDesc: PropTypes.string,
};

TitleSorter.defaultProps = {};

export default TitleSorter;

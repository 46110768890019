import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import PublicLayout from '../../layout/PublicLayout';
import LoginForm from '../../containers/Login';
import LoginWrapper from './styles';

export default function Login() {
  const { search } = useLocation();
  const history = useHistory();

  const handleAfterLogin = () => {
    if (search) {
      const link = search.slice(6);
      history.push(link);
    }
  };

  return (
    <PublicLayout>
      <LoginWrapper>
        <LoginForm handleAfterLogin={handleAfterLogin} />
      </LoginWrapper>
    </PublicLayout>
  );
}

import React, { useMemo, useEffect } from 'react';
import i18next from 'i18next';
import { useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import {
  getAppSettingsCentre,
  getCentreVirtualTour,
  getSummaryDetailCentre,
} from 'redux/centres/actions';
import RestShow from 'containers/rest/Show';
import { getLinkCentreDetailRichweb } from 'utils/dataUtils';
import { Button, Space } from 'antd';
import { OpenNewTabIcon } from 'components/common/SVGIcon';
import CentreDetail from '../components/CentreDetail';
import { CentreProvider } from './context';
import ShowStyles from './styles';

const CentresShow = (props) => {
  const dispatch = useDispatch();

  const { id } = useParams();
  const currentData = useSelector((state) => state.centres.currentData) || {};

  const customBreadcrumb = useMemo(
    () => [
      {
        title: i18next.t('centres.header'),
        path: '/centres',
      },
      {
        title: i18next.t('centres.titleDetail'),
        path: '#',
      },
      {
        title: currentData?.name || i18next.t('error.waitingUpdate'),
        path: '#',
      },
    ],
    [currentData.name],
  );

  useEffect(() => {
    if (id) {
      dispatch(getSummaryDetailCentre(id));
      dispatch(getAppSettingsCentre(id));
      dispatch(getCentreVirtualTour({ centreId: id }));
    }
  }, [id, dispatch]);

  return (
    <ShowStyles>
      <CentreProvider>
        <RestShow
          {...props}
          resource="centres"
          extraAction={
            <Button className="btn-line-primary">
              <a
                href={getLinkCentreDetailRichweb(currentData)}
                target="_blank"
                rel="noopener noreferrer"
              >
                <Space className="fw-bold">
                  <OpenNewTabIcon />
                  {i18next.t('button.viewAsParent')}
                </Space>
              </a>
            </Button>
          }
          customBreadcrumb={customBreadcrumb}
          header="centres.titleDetail"
        >
          <CentreDetail centreId={id} />
        </RestShow>
      </CentreProvider>
    </ShowStyles>
  );
};

export default CentresShow;

import React, { useEffect } from 'react';
import i18next from 'i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';

import PageTitle from 'components/common/PageTitle';
import CustomBreadcrumb from 'components/common/Breadcrumb';
import { getByIdUsers } from 'redux/users/actions';
import CreateEditStyles from './styles';
import FormAddMoreProviders from './components/FormAddMoreProviders';

const AddMoreProviders = () => {
  const currentData = useSelector((state) => state.users.currentData) || {};
  const { id } = useParams();
  const dispatch = useDispatch();

  const breadcrumb = [
    {
      title: i18next.t('sideBar.config'),
      path: '/config',
    },
    {
      title: i18next.t('users.header'),
      path: '/config/users',
    },
    {
      title: `${currentData?.firstName || ''} ${currentData?.lastName || ''}`,
      path: `/config/users/${id}/show`,
    },
    {
      title: i18next.t('users.addMoreProviders'),
      path: '#',
    },
  ];

  useEffect(() => {
    dispatch(getByIdUsers({ data: { id } }));
  }, [dispatch, id]);

  return (
    <CreateEditStyles>
      <CustomBreadcrumb data={breadcrumb} />
      <PageTitle>{i18next.t('users.titleDetail')}</PageTitle>
      <div className="add-more-content">
        <div className="add-more-title">
          {i18next.t('users.addMoreProviders')}
        </div>
        <FormAddMoreProviders />
      </div>
    </CreateEditStyles>
  );
};

export default AddMoreProviders;

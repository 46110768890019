import React, { useCallback, useMemo, useRef } from 'react';
import i18next from 'i18next';
import { sortByStatus, sortString, sortTime } from 'utils/sortUtils';
import TableCustom from 'components/common/TableCustom';
import { useAppSelector } from 'redux/hooks';
import { useParams } from 'react-router';
import { TABS_MODE_APPLICATION } from 'containers/Applications/List';
import useFormatFnc from '../../List/useFormat';
import { useApplicationList } from '../../List/context';
import ArchiveApplicationReasonModal from '../ArchiveApplicationReasonModal';

const TableView = () => {
  const refArchiveModal = useRef<{ open: (id: string) => void }>();
  const { tableData } = useApplicationList();
  const { model } = useParams();
  const isArchivedViewMode = model === TABS_MODE_APPLICATION.archived.key;

  const loading = useAppSelector((state) => state.applications.loading);

  const useFormat = useFormatFnc();

  const formatChild = useCallback((a, b) => {
    const item1 = {
      fullName: `${a.careOption?.firstName || ''} ${
        a.careOption?.lastName || ''
      }`,
    };

    const item2 = {
      fullName: `${b.careOption?.firstName || ''} ${
        b.careOption?.lastName || ''
      }`,
    };

    return sortString(item1, item2, 'fullName');
  }, []);

  const openArchiveModal = (id: string) => {
    refArchiveModal?.current && refArchiveModal.current.open(id);
  };

  const columns = useMemo(
    () => [
      {
        title: i18next.t('applications.status'),
        dataIndex: 'status',
        render: useFormat.formatStatus,
        sorter: sortByStatus,
      },
      ...(isArchivedViewMode
        ? [
            {
              title: i18next.t('applications.archiveReason'),
              dataIndex: 'archivedReason',
            },
          ]
        : []),
      {
        title: i18next.t('applications.child'),
        dataIndex: 'child',
        render: useFormat.formatSender,
        width: '20%',
        sorter: formatChild,
      },
      {
        title: i18next.t('applications.appliedTime'),
        dataIndex: 'submittedAt',
        render: useFormat.formatTime,
        sorter: (a, b) => sortTime(a, b, 'submittedAt'),
      },
      {
        title: i18next.t('applications.contractValue'),
        dataIndex: ['careOption', 'value'],
        render: useFormat.formatContractValue,
        sorter: (a, b) => a.careOption.value - b.careOption.value,
      },
      {
        title: i18next.t('applications.startDay'),
        dataIndex: ['careOption', 'startDay'],
        render: useFormat.formatTime,
        sorter: (a, b) => sortTime(a, b, ['careOption', 'startDay']),
      },
      {
        title: i18next.t('applications.service'),
        dataIndex: ['careOption', 'centreServices'],
        render: useFormat.formatServices,
      },
      {
        title: i18next.t('documents.actions'),
        dataIndex: 'id',
        fixed: 'right',
        width: 90,
        render: (id, record) =>
          useFormat.renderAction(id, record, openArchiveModal),
      },
    ],
    [useFormat, formatChild, isArchivedViewMode],
  );

  const onRow = useCallback(
    (record = {}) => ({
      onDoubleClick: () => {
        record.id && useFormat.handleClickRecord(record.id);
      },
    }),
    [useFormat],
  );

  return (
    <>
      <TableCustom
        isSorter
        onRow={onRow}
        columns={columns}
        data={tableData}
        loading={loading}
        childrenColumnName="application"
      />
      <ArchiveApplicationReasonModal ref={refArchiveModal} />
    </>
  );
};

export default TableView;

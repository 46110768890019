import { createSlice } from '@reduxjs/toolkit';
import {
  getAllCustomFail,
  getAllCustomPending,
  getAllCustomSuccess,
  INITIAL_STATE_CUSTOM,
  resetResourceDataCustom,
} from 'redux/crudCreatorCustom/utils';
import { getProvidersOfMeBasic } from './actions';

export const initialState = {
  ...INITIAL_STATE_CUSTOM,
};

const { actions, reducer } = createSlice({
  name: 'ProvidersOfMeBasic',
  initialState,
  reducers: {
    resetResourceFilterCentresOfProviders: resetResourceDataCustom,
  },
  extraReducers: {
    [getProvidersOfMeBasic.pending]: getAllCustomPending,
    [getProvidersOfMeBasic.fulfilled]: getAllCustomSuccess,
    [getProvidersOfMeBasic.rejected]: getAllCustomFail,
  },
});

export const { resetResourceFilterCentresOfProviders } = actions;

export default reducer;

import { FORMAT_DEFAULT_MONEY } from 'configs/localData/constant';
import i18next from 'i18next';
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { formatMoney, getFilterFromUrl } from 'utils/tools';
import { Table } from 'antd';
import { formatDateUpdatePrice } from 'utils/textUtils';
import { useLocation } from 'react-router';
import useFormatFnc from './useFormat';

const AverageAllCentres = ({ averageAllCentre, serviceAgeGroupCurrent }) => {
  const useFormat = useFormatFnc();
  const { search } = useLocation();
  const outsideFilter = getFilterFromUrl(search);
  const filter = outsideFilter?.filter;

  const ageGroupAverageData = [
    {
      value: `$${formatMoney(averageAllCentre?.average0to12months, 3, true)}`,
      type: '0to12months',
    },
    {
      value: `$${formatMoney(averageAllCentre?.average13to24months, 3, true)}`,
      type: '13to24months',
    },
    {
      value: `$${formatMoney(averageAllCentre?.average25to36months, 3, true)}`,
      type: '25to36months',
    },
    {
      value: `$${formatMoney(averageAllCentre?.average36monthsplus, 3, true)}`,
      type: '36monthsplus',
    },
    {
      value: `$${formatMoney(averageAllCentre?.averageSchoolage, 3, true)}`,
      type: 'schoolage',
    },
  ];

  const getAgeGroupAverage = () => {
    if (filter.serviceAgeGroupId === 'all') {
      return ageGroupAverageData;
    }
    return ageGroupAverageData.filter(
      (item) => item.type === serviceAgeGroupCurrent?.type,
    );
  };

  const averageData = useMemo(
    () => [
      {
        value: '',
      },
      {
        value: i18next.t('priceBenchmarking.averageAllCentres'),
      },
      {
        value: '',
      },
      {
        value: '',
      },
      {
        value: `${
          averageAllCentre?.avegareDistance || FORMAT_DEFAULT_MONEY
        } km`,
      },
      {
        value: averageAllCentre?.avegareCapacity || 0,
      },
      {
        value: useFormat.formatMarketShare(
          averageAllCentre?.avegareMarketShare,
        ),
      },
      {
        value: `$${formatMoney(
          averageAllCentre?.avegareRevenuePotential,
          3,
          true,
        )}`,
      },
      {
        value: useFormat.formatKindiRating(
          averageAllCentre?.averageKindiCareRating,
        ),
      },
      {
        value: useFormat.formatNQSRating(averageAllCentre?.averageNQSRating),
      },
      ...getAgeGroupAverage(),
      {
        value: `$${formatMoney(averageAllCentre?.averageAllAgeGroup, 3, true)}`,
      },
      {
        value: formatDateUpdatePrice(
          averageAllCentre?.avegarePricesLastUpdated,
        ),
      },
      {
        value: '',
      },
    ],
    [averageAllCentre, serviceAgeGroupCurrent], // eslint-disable-line
  );

  return (
    <Table.Summary fixed>
      <Table.Summary.Row className="summary-row">
        {averageData?.map((item, index) => (
          <Table.Summary.Cell index={index} key={Number(index)}>
            {item?.value}
          </Table.Summary.Cell>
        ))}
      </Table.Summary.Row>
    </Table.Summary>
  );
};
AverageAllCentres.propTypes = {
  averageAllCentre: PropTypes.object,
  serviceAgeGroupCurrent: PropTypes.object,
};
export default AverageAllCentres;

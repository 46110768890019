import React, { useState, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import i18next from 'i18next';
import { useDispatch } from 'react-redux';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import { Modal } from 'antd';
import {
  APPLICATION_STATUS_BOARD,
  APPLICATION_STATUS_CONSTANT,
} from 'configs/localData';
import { updateApplicationStatus } from 'redux/applications/actions';
import Column from './Column';
import reorder, { reorderDataMap } from '../BoardList/utils';
import BoardStyles, { ContainerScroll, ContainerBoard } from './styles';

const getItemStatus = (value) =>
  APPLICATION_STATUS_BOARD.find((item) => item.value === value);

const Board = ({
  initial,
  summaryStatus,
  applicationStatus,
  containerHeight,
  useClone,
  isCombineEnabled,
  withScrollableColumns,
  isDragDisabledColumn,
  isDragDisabledItem,
}) => {
  const dispatch = useDispatch();
  const [columns, setColumns] = useState(initial);

  useEffect(() => {
    setColumns(initial);
  }, [initial]);

  const [ordered, setOrdered] = useState(applicationStatus);

  const numberColumn = useMemo(
    () => applicationStatus?.length || 1,
    [applicationStatus],
  );

  const orderItemsOfColumn = (source, destination) => {
    const data = reorderDataMap({
      dataMap: columns,
      source,
      destination,
    });

    setColumns(data.dataMap);
  };

  const updateStatus = ({
    applicationId,
    status,
    isReduceCountPending,
    source,
    destination,
  }) =>
    dispatch(
      updateApplicationStatus({
        id: applicationId,
        status,
        isReduceCountPending,
      }),
    ).then(({ payload }) => {
      if (payload?.id) {
        orderItemsOfColumn(source, destination);
      }
    });

  const confirmChangeStatus = ({
    source,
    destination,
    statusObj,
    applicationId,
    isReduceCountPending,
  }) => {
    Modal.confirm({
      title: i18next.t('applications.confirmTitle'),
      content: i18next.t('applications.confirmContent', {
        beforeStatus: statusObj.beforeStatus,
        endStatus: statusObj.endStatus,
      }),
      onOk: () =>
        updateStatus({
          applicationId,
          status: destination.droppableId,
          isReduceCountPending,
          source,
          destination,
        }),
    });
  };

  const handleChangeStatus = ({ source, destination, applicationId }) => {
    const beforeItem = getItemStatus(source.droppableId) || {};
    const endItem = getItemStatus(destination.droppableId) || {};

    const statusObj = {
      beforeStatus: beforeItem.text
        ? i18next.t(beforeItem.text)
        : source.droppableId,
      endStatus: endItem.text
        ? i18next.t(endItem.text)
        : destination.droppableId,
    };

    confirmChangeStatus({
      source,
      destination,
      statusObj,
      applicationId,
      isReduceCountPending:
        beforeItem.value === APPLICATION_STATUS_CONSTANT.submitted,
    });
  };

  const onDragEnd = (result = {}) => {
    if (result.combine) {
      if (result.type === 'COLUMN') {
        const shallow = [...ordered];
        shallow.splice(result.source.index, 1);
        setOrdered(shallow);
        return;
      }

      const column = columns[result.source.droppableId];
      const withArrRemoved = [...column];
      withArrRemoved.splice(result.source.index, 1);
      const restColumns = {
        ...columns,
        [result.source.droppableId]: withArrRemoved,
      };
      setColumns(restColumns);
      return;
    }

    if (!result.destination) {
      return;
    }

    const source = result?.source;
    const destination = result?.destination;

    if (source.droppableId !== destination.droppableId) {
      handleChangeStatus({
        source,
        destination,
        applicationId: result.draggableId,
      });
      return;
    }

    if (
      source.droppableId === destination.droppableId &&
      source.index === destination.index
    ) {
      return;
    }

    if (result.type === 'COLUMN') {
      const restOrdered = reorder(ordered, source.index, destination.index);

      setOrdered(restOrdered);

      return;
    }

    orderItemsOfColumn(source, destination);
  };

  const board = (
    <Droppable
      droppableId="board"
      type="COLUMN"
      direction="horizontal"
      ignoreContainerClipping={Boolean(containerHeight)}
      isCombineEnabled={isCombineEnabled}
    >
      {(provided) => (
        <ContainerBoard ref={provided.innerRef} {...provided.droppableProps}>
          {ordered.map((item, index) => (
            <Column
              key={item.value}
              listId={item.value}
              index={index}
              title={i18next.t(item.text)}
              lineColor={item.color}
              colors={item.colors}
              applications={columns[item.value]}
              isScrollable={withScrollableColumns}
              isCombineEnabled={isCombineEnabled}
              useClone={useClone}
              numberColumn={numberColumn}
              isDragDisabledColumn={isDragDisabledColumn}
              isDragDisabledItem={isDragDisabledItem}
              summaryStatus={summaryStatus[item.value]}
            />
          ))}
          {provided.placeholder}
        </ContainerBoard>
      )}
    </Droppable>
  );

  return (
    <BoardStyles className="board-canvas" containerHeight={containerHeight}>
      <DragDropContext onDragEnd={onDragEnd}>
        {withScrollableColumns ? (
          <ContainerScroll>{board}</ContainerScroll>
        ) : (
          <div className="container-scroll-x">{board}</div>
        )}
      </DragDropContext>
    </BoardStyles>
  );
};

Board.propTypes = {
  initial: PropTypes.object,
  applicationStatus: PropTypes.array,
  containerHeight: PropTypes.string,
  useClone: PropTypes.bool,
  isCombineEnabled: PropTypes.bool,
  withScrollableColumns: PropTypes.bool,
  isDragDisabledColumn: PropTypes.bool,
  isDragDisabledItem: PropTypes.bool,
  summaryStatus: PropTypes.object,
};

Board.defaultProps = {
  initial: {},
  summaryStatus: {},
  applicationStatus: [],
};

export default Board;

import React, { useContext, useEffect } from 'react';
import { Col, Row } from 'antd';
import RestInputItem from 'components/RestInput/RestInputItem';
import RestSelect from 'components/RestInput/RestSelect';
import i18next from 'i18next';
import { JOB_TEMPLATE_STATUS } from 'configs/localData';
import RestRangePickerInput from 'components/RestInput/RestRangePickerInput';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { getFilterFromUrl } from 'utils/tools';
import { useLocation } from 'react-router-dom';
import { RestInputContext } from 'components/RestInput/RestInputContext';

const FilterForm = () => {
  const { form } = useContext(RestInputContext);
  const { search } = useLocation();
  const { filter } = getFilterFromUrl(search);
  const jobRolesData = useSelector(
    (state) => state.jobTemplates.jobTemplatesConfig.data?.jobRoles,
  );

  const jobTypesData = useSelector(
    (state) => state.jobTemplates.jobTemplatesConfig.data?.jobTypes,
  );

  useEffect(() => {
    const { lastUpdated, ...restFilter } = filter?.filter || {};

    form.setFieldsValue({
      lastUpdated: Object.values(lastUpdated || {}).map((item) => moment(item)),
      ...restFilter,
    });
  }, [filter, form]);

  return (
    <Row gutter={16}>
      <Col lg={4} md={12} xs={24}>
        <RestInputItem
          source="jobTemplate"
          placeholder="jobs.jobTemplates.title"
          isShowTooltip
        />
      </Col>
      <Col lg={4} md={12} xs={24}>
        <RestInputItem
          source="jobTitle"
          placeholder="jobs.jobTemplates.jobTitle"
          isShowTooltip
        />
      </Col>
      <Col lg={4} md={12} xs={24}>
        <RestSelect
          source="jobRole"
          placeholder="jobs.jobTemplates.jobRole"
          resourceData={jobRolesData}
          valueProp="value"
          titleProp="name"
          isShowTooltip
        />
      </Col>
      <Col lg={4} md={12} xs={24}>
        <RestSelect
          source="jobType"
          placeholder="jobs.jobTemplates.jobType"
          isShowTooltip
          resourceData={jobTypesData}
          valueProp="value"
          titleProp="name"
          formatText={(data) => i18next.t(data)}
        />
      </Col>
      <Col lg={4} md={12} xs={24}>
        <RestSelect
          source="status"
          placeholder="jobs.jobTemplates.status"
          isShowTooltip
          resourceData={JOB_TEMPLATE_STATUS}
          valueProp="value"
          titleProp="text"
          formatText={(data) => i18next.t(data)}
        />
      </Col>
      <Col lg={4} md={12} xs={24}>
        <RestRangePickerInput
          source="lastUpdated"
          placeholder="jobs.jobTemplates.lastUpdated"
          isShowTooltip
          sourceGt="lastUpdated.from"
          sourceLt="lastUpdated.to"
        />
      </Col>
    </Row>
  );
};

export default FilterForm;

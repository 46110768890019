import styled from 'styled-components';
import { Select } from 'antd';

export const DropdownStatusInfoStyles = styled.div`
  .select-status-fallback-wrapper {
    display: inline-flex;
    position: relative;
    .ant-select-selector {
      pointer-events: none;
    }
    .action-feature-icon {
      position: absolute;
      top: -12px;
      right: -9px;
      font-size: 20px;
      color: ${({ theme }) => theme.subscriptions.colorIcon};
    }
  }
`;

export const DropDownWrapper = styled(Select)`
  .span-option-status {
    border-radius: 8px;
    font-weight: 600;
  }
  .ant-select-arrow {
    color: ${({ theme }) => theme.palette.primary};
  }
  .ant-select-selector {
    border: 1px solid #f2f2f2 !important;
    border-radius: 8px;
  }
  &.ant-select-focused .ant-select-selector {
    border-color: ${({ theme }) => theme.palette.primary} !important;
  }
  .option-div-item {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
`;

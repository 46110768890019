import React from 'react';
import PropTypes from 'prop-types';
import i18next from 'i18next';
import { formatDate } from 'utils/textUtils';

import { EnvironmentOutlined, CalendarOutlined } from '@ant-design/icons';
import useCRMPackageOfCentre from 'hooks/useCRMPackageOfCentre';
import { formatSubscription } from 'utils/formatFieldUtils';

import {
  CentreTypeIcon,
  ChildcareServiceTypeIcon,
  KindiCareIcon,
  ProviderIcon,
} from 'components/common/SVGIcon';
import { CENTRE_STATUS_VALUE } from 'configs/localData';
import { Tooltip } from 'antd';

const GeneralInfo = ({ record }) => {
  const name =
    useCRMPackageOfCentre(record?.id)?.name || record?.CRMPackage?.name;

  return (
    <div className="item-info-other">
      <div className="title-info-other">{i18next.t('centres.generalInfo')}</div>
      <div className="content-info-other">
        <div className="address-section item-info">
          <EnvironmentOutlined />
          {record?.displayAddress ? (
            <Tooltip title={i18next.t('centres.address')}>
              <span className="value-info-item">
                {record?.displayAddress?.trim()}
              </span>
            </Tooltip>
          ) : (
            <span className="value-info-item">
              {i18next.t('error.waitingUpdate')}
            </span>
          )}
        </div>
        <div className="item-info">
          <ProviderIcon />
          {record?.businessName ? (
            <Tooltip title={i18next.t('centres.providerName')}>
              <span className="value-info-item">{record?.provider?.name}</span>
            </Tooltip>
          ) : (
            <span className="value-info-item">
              {i18next.t('error.waitingUpdate')}
            </span>
          )}
        </div>
        <div className="item-info">
          <ChildcareServiceTypeIcon />
          {record?.primaryChildCareServiceId ? (
            <Tooltip title={i18next.t('centres.childcareServiceType')}>
              <span className="value-info-item">
                {record?.primaryChildCareService?.name}
              </span>
            </Tooltip>
          ) : (
            <span className="value-info-item">
              {i18next.t('error.waitingUpdate')}
            </span>
          )}
        </div>
        <div className="item-info">
          <CentreTypeIcon />
          {record?.type ? (
            <Tooltip title={i18next.t('centres.centreType')}>
              <span className="value-info-item">{record?.type}</span>
            </Tooltip>
          ) : (
            <span className="value-info-item">
              {i18next.t('error.waitingUpdate')}
            </span>
          )}
        </div>
        <div className="item-info">
          <CalendarOutlined />
          <span className="value-info-item">
            {record?.dateApplied ? (
              <Tooltip title={i18next.t('centres.dateApplied')}>
                <span className="value-info-item">
                  {formatDate(record?.dateApplied)}
                </span>
              </Tooltip>
            ) : (
              <span className="value-info-item">
                {i18next.t('error.waitingUpdate')}
              </span>
            )}
          </span>
        </div>
        {record?.claimStatus === CENTRE_STATUS_VALUE.claimed && (
          <div className="item-info">
            <KindiCareIcon />
            {formatSubscription(name)}
          </div>
        )}
      </div>
    </div>
  );
};

GeneralInfo.propTypes = {
  record: PropTypes.object,
};

export default GeneralInfo;

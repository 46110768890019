import styled from 'styled-components';

export const ModalContentStyles = styled.div`
  padding-top: 25px;
  margin-left: -24px;
  margin-right: -24px;

  .ant-carousel {
    height: 100%;

    .slick-slide {
      & > div {
        & > div {
          display: flex !important;
          justify-content: center;
          align-items: center;

          img {
            object-fit: contain;
          }
        }
      }
    }
  }

  .left-arrow,
  .right-arrow {
    position: absolute;
    top: 50%;
    font-size: 28px;
    z-index: 9;
    color: #ccc;
    opacity: 0.75;
  }

  .left-arrow {
    left: 10px;
  }

  .right-arrow {
    right: 10px;
  }
  .iframe-video {
    width: 100%;
    height: 500px;
  }
`;

const GridPhotosWrapper = styled.div`
  flex-wrap: wrap;

  .thumbnail {
    width: 100%:
  }

  .images {
    display: flex;
  }

  .imageCount {
    width: ${({ width }) => `${width}px`};
    height: ${({ height }) => `${height}px`};
    max-width: 100%;
    border-radius: ${({ borderRadius }) => borderRadius};
    position: relative;
    display: inline-block;
    .overlay {
      position: absolute;
      background: rgba(0,0,0, 0.4);
      top: 0px;
      left: 0px;
      right: 0px;
      bottom: 0px;
      color: white;
      align-items: center;
      justify-content: center;
      display: flex;
      font-size: 20px;
      border-radius: ${({ borderRadius }) => borderRadius};
      width: ${({ width }) => `${width}px`};
      height: ${({ height }) => `${height}px`};
      max-width: 100%;
    }

    .overlay2 {
      position: absolute;
      top: 0px;
      left: 0px;
      right: 5px;
      bottom: 0px;
      width: ${({ width }) => `${width}px`};
      height: ${({ height }) => `${height}px`};
      max-width: 100%;
      display: flex;
      z-index: 2;
      align-items: center;
      justify-content: center;
      border-radius: ${({ borderRadius }) => borderRadius};
      background: rgba(0, 0, 0, 0.3);
      visibility: hidden;
      &:hover {
        visibility: visible;
      }

      .anticon, .overlay-text {
        color: #fff;
        font-size: 30px;
        font-weight: 600;
        cursor: pointer;
      }
    }

    .show-overlay {
      visibility: visible;
    }

    &:hover {
      .overlay2 {
        visibility: visible;
      }

      }

    span {
      position: absolute;
      background: transparent;
    }
  }

  .image {
    width: ${({ width }) => `${width}px`};
    height: ${({ height }) => `${height}px`};
    max-width: 100%;
    border-radius: ${({ borderRadius }) => borderRadius};
    object-fit: cover;
  }
`;

export default GridPhotosWrapper;

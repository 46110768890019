import React from 'react';
import PropTypes from 'prop-types';
import i18next from 'i18next';
import SwitchAllCentre from '../SwitchAllCentre';

const CentreHeader = ({ notify }) => (
  <div>
    <div className="centre-notify__desc">{notify?.description}</div>
    <div className="centre-list__header flex-center-between">
      <div className="centre-list__header-name">
        {i18next.t('centres.header')}
      </div>
      <div className="centre-list__header-action">
        {i18next.t('common.enableAll')}
        <SwitchAllCentre item={notify} />
      </div>
    </div>
  </div>
);

CentreHeader.propTypes = {
  notify: PropTypes.object,
};

export default CentreHeader;

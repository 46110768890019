import styled from 'styled-components';

export const ApplicationDeadlineStepStyles = styled.div`
  .deadline-day {
    .ant-form-item-control {
      position: relative;
    }

    .ant-form-item-explain {
      position: absolute;
      min-width: 365px;
    }
  }

  .ant-form-item-required::after {
    content: '' !important;
  }

  .ant-form-item-label label {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: ${({ theme }) => theme.text.mainL1};
  }

  .btn-add-more {
    color: ${({ theme }) => theme.palette.primary} !important;
    font-weight: 600;
    margin-left: 42px;
    &:hover,
    &:focus {
      background: none;
    }

    span {
      text-decoration: underline;
    }
  }

  .list-exception {
    margin-left: 12px;
  }

  .exception-index {
    position: relative;
    top: 9px;
    margin-right: 24px;
  }

  .anticon {
    color: ${({ theme }) => theme.text.grayNeutral500};
  }
`;

export const ExceptionListItem = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 16px;
  width: 100%;
  .ant-form-item-explain-error {
    margin-top: 12px;
  }
  .ant-form-item-with-help .ant-form-item-explain {
    min-height: 0;
    height: 0;
  }
`;

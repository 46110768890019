import React, { useState } from 'react';
import i18next from 'i18next';
import { Button, Checkbox, Space, Typography } from 'antd';
import { SaveAndExitModalStyle } from 'containers/Advertisement/Create/styles';
import { ExitConfirmModalProps } from 'containers/Advertisement/Create/SaveExitConfirmModal';
import { NO_ASK_EXIT_CONFIRM } from 'containers/Advertisement/constants';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';

const ExitConfirmModal = ({
  isVisible,
  onExit,
  onCancel,
}: ExitConfirmModalProps) => {
  const [isNotAskAgain, setIsNotAskAgain] = useState(false);
  const currentUserId = useSelector(
    (state: RootState) => state?.auth?.data?.id,
  );

  const onOk = async () => {
    if (isNotAskAgain) {
      localStorage.setItem(NO_ASK_EXIT_CONFIRM, currentUserId);
    }
    onExit();
  };

  const handleNotAskAgain = (e) => {
    setIsNotAskAgain(e.target.checked);
  };

  const handleCancel = () => {
    setIsNotAskAgain(false);
    onCancel();
  };

  return (
    <SaveAndExitModalStyle
      isShowCancel
      isScrollY={false}
      loading={false}
      isDisabled={false}
      width={560}
      okText={i18next.t('button.ok')}
      cancelText={i18next.t('button.cancel')}
      className="text-16"
      resetPaddingTopBody={false}
      resetPaddingBottomBody={false}
      visible={isVisible}
      closable={false}
      footer={[
        <Space className="flex-center-between" key={1}>
          <Checkbox
            className="text-16"
            checked={isNotAskAgain}
            onChange={handleNotAskAgain}
          >
            {i18next.t('common.noAskAgain')}
          </Checkbox>
          <div>
            <Button onClick={handleCancel}>{i18next.t('button.cancel')}</Button>
            <Button type="primary" onClick={onOk}>
              {i18next.t('button.exit')}
            </Button>
          </div>
        </Space>,
      ]}
    >
      <Typography.Text className="text-16">
        {i18next.t('advertisement.onlyExitConfirmMsg')}
      </Typography.Text>
    </SaveAndExitModalStyle>
  );
};

export default ExitConfirmModal;

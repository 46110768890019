import React, { useCallback, useEffect, useRef, useState } from 'react';
import { validateRegex } from 'utils/validateUtils';
import i18next from 'i18next';

interface Props {
  text: string;
  className?: string;
  maxLine: number;
  trimRight?: boolean;
  moreButtonText?: string;
  lessButtonText?: string;
}

const ELLIPSES = '…';
const CHARACTER_TYPE = {
  WORDS: 'words',
  LETTERS: 'letters',
};

const targetStyleProps = [
  'box-sizing',
  'width',
  'font-size',
  'font-weight',
  'font-family',
  'font-style',
  'letter-spacing',
  'text-indent',
  'white-space',
  'word-break',
  'overflow-wrap',
  'padding-left',
  'padding-right',
];

const LineEllipsis = ({
  text,
  className,
  maxLine = 1,
  trimRight = true,
  moreButtonText = 'button.readMore',
  lessButtonText = 'button.readLess',
}: Props) => {
  const lineEllipsisRef = useRef<HTMLDivElement>(document.createElement('div'));
  const targetRef = useRef<HTMLDivElement>();
  const [clamped, setClamped] = useState(false);
  const [plainText, setPlainText] = useState<string>(text);
  const [ellipsisText, setEllipsisText] = useState<string>(text);
  const [isMounted, setIsMounted] = useState(false);
  const [isEllipsis, setIsEllipsis] = useState(true);
  const units = useRef<string[]>([]);
  const textRef = useRef<string>(text);

  const putEllipsis = useCallback(
    (indexes: number[]) => {
      if (indexes.length <= maxLine) {
        setIsEllipsis(false);
        return -1;
      }
      const lastIndex = indexes[maxLine];
      units.current = units.current.slice(0, lastIndex);
      const maxOffsetTop = (
        lineEllipsisRef.current.children[lastIndex] as HTMLElement
      ).offsetTop;
      lineEllipsisRef.current.innerHTML = `${units.current
        .map((c) => `<span class='lines-ellipsis-unit'>${c}</span>`)
        .join(
          '',
        )}<wbr><span class="">${ELLIPSES}</span><span class='text-primary cursor-pointer'> ${i18next.t(
        moreButtonText,
      )}</span>`;

      const ellipsisElement = lineEllipsisRef.current
        .lastElementChild as HTMLElement;
      let preUnitElement = prevSibling(ellipsisElement, 2);
      while (
        preUnitElement &&
        (ellipsisElement.offsetTop > maxOffsetTop ||
          ellipsisElement.offsetHeight > preUnitElement.offsetHeight ||
          ellipsisElement.offsetTop > preUnitElement.offsetTop)
      ) {
        lineEllipsisRef.current.removeChild(preUnitElement);
        preUnitElement = prevSibling(ellipsisElement, 2);
        units.current.pop();
      }
      return units.current.length;
    },
    [maxLine, moreButtonText],
  );

  const prevSibling = (node: HTMLElement, count: number) => {
    let _node: HTMLElement = node;
    let _count = count;
    while (node && _count > 0) {
      _node = node.previousElementSibling as HTMLElement;
      _count -= 1;
    }
    return _node;
  };

  const calcIndexes = useCallback(() => {
    const indexes = [0];
    let elt: HTMLElement = lineEllipsisRef.current
      .firstElementChild as HTMLElement;
    if (!elt) return indexes;

    let index = 0;
    let line = 1;
    let nextElement = elt.nextElementSibling as HTMLElement;
    let { offsetTop } = elt;
    while (nextElement) {
      if (nextElement.offsetTop > offsetTop) {
        line += 1;
        indexes.push(index);
        offsetTop = nextElement.offsetTop;
      }
      index += 1;
      elt = nextElement;
      nextElement = nextElement.nextElementSibling as HTMLElement;
      if (line > maxLine) {
        break;
      }
    }
    return indexes;
  }, [maxLine]);

  const initCanvas = useCallback(() => {
    lineEllipsisRef.current.className = `lines-ellipsis-canvas ${className}`;
    lineEllipsisRef.current.setAttribute('aria-hidden', 'true');
    copyAllStylesFromTarget();
    document.body.appendChild(lineEllipsisRef.current);
  }, [className]);

  const separateTextToUnits = (text: string) => {
    const textType = validateRegex.latinLetters.test(text)
      ? CHARACTER_TYPE.WORDS
      : CHARACTER_TYPE.LETTERS;
    switch (textType) {
      case CHARACTER_TYPE.WORDS:
        units.current = text.split(validateRegex.wordCharacter);
        break;
      case CHARACTER_TYPE.LETTERS:
        units.current = Array.from(text);
        break;
      default:
    }
  };

  const copyAllStylesFromTarget = () => {
    const targetStyle = window.getComputedStyle(targetRef.current);
    targetStyleProps.forEach((key) => {
      lineEllipsisRef.current.style[key] = targetStyle[key];
    });
  };

  const reflow = useCallback(() => {
    separateTextToUnits(plainText);
    lineEllipsisRef.current.innerHTML = units.current
      .map((c) => `<span class='lines-ellipsis-unit'>${c}</span>`)
      .join('');
    const ellipsisIndex = putEllipsis(calcIndexes());
    const clamped = ellipsisIndex > -1;
    const ellipsisText = units.current.slice(0, ellipsisIndex).join('');
    setClamped(clamped);
    setPlainText(clamped ? ellipsisText : plainText);
    setEllipsisText(ellipsisText);
    setIsMounted(true);
  }, [calcIndexes, plainText, putEllipsis]);

  useEffect(() => {
    if (text !== textRef.current && isMounted) {
      textRef.current = text;
      setPlainText(text);
      setEllipsisText(text);
      setIsMounted(false);
      setClamped(false);
      setIsEllipsis(true);
    }
  }, [text, isMounted]);

  useEffect(() => {
    if (isMounted) return;
    initCanvas();
    reflow();
    return () => {
      lineEllipsisRef.current.parentNode.removeChild(lineEllipsisRef.current);
      lineEllipsisRef.current = document.createElement('div');
    };
  }, [initCanvas, isMounted, reflow]);

  const handleReadMore = () => {
    setPlainText(text);
    setClamped(false);
  };

  const handleReadLess = () => {
    setClamped(true);
    setPlainText(ellipsisText);
  };

  return (
    <div ref={targetRef} className={className}>
      {clamped && trimRight
        ? plainText.replace(validateRegex.spaceCharacter, '')
        : plainText}
      <wbr />
      {isEllipsis &&
        (clamped ? (
          <>
            <span>{`${ELLIPSES}`}</span>
            <span
              role="presentation"
              className="text-primary cursor-pointer"
              onClick={handleReadMore}
            >{` ${i18next.t(moreButtonText)}`}</span>
          </>
        ) : (
          <span
            role="presentation"
            className="text-primary cursor-pointer"
            onClick={handleReadLess}
          >{` ${i18next.t(lessButtonText)}`}</span>
        ))}
    </div>
  );
};

export default LineEllipsis;

import React, { useImperativeHandle, forwardRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'antd';
import JobTemplateModal from '.';
import JobHighlightBenefit from '../StepContent/MoreAboutJobForm/JobHighlightBenefit';

const JobHighlightsBenefitsModal = forwardRef(({ record }, ref) => {
  const [visible, setVisible] = useState();
  const [form] = Form.useForm();

  useImperativeHandle(
    ref,
    () => ({
      toggleModal: () => {
        setVisible(true);
      },
    }),
    [],
  );

  const handleCancel = () => {
    setVisible(false);
    form.resetFields();
  };

  return (
    <JobTemplateModal
      title="jobs.moreAboutTheJob.jobHighLightsAndBenefits"
      visible={visible}
      onCancel={handleCancel}
      width={1000}
      destroyOnClose
      forceRender
    >
      <JobHighlightBenefit initialValue={record} />
    </JobTemplateModal>
  );
});

JobHighlightsBenefitsModal.propTypes = {
  record: PropTypes.array,
};

export default JobHighlightsBenefitsModal;

import TableCustom from 'components/common/TableCustom';
import {
  SORT_ASCEND_VALUE,
  SORT_DESCEND_VALUE,
} from 'configs/localData/constant';
import i18next from 'i18next';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { getResourceDataJobTemplate } from 'redux/jobs/jobTemplates/selectors';
import { formatNightDayTable } from 'utils/formatFieldUtils';
import { getFilterFromUrl } from 'utils/tools';
import {
  DEFAULT_CURRENT_PAGE,
  DEFAULT_SORT_JOB_TEMPLATE,
  LIMIT_ITEM,
} from '../LocalData/constant';
import { JobTemplateListStyle } from './styles';
import useFormatFnc from './useFormat';

const JobTemplateList = ({
  jobTemplateData,
  loading,
  pushQuery,
  retrieveList,
}) => {
  const useFormat = useFormatFnc();
  const { search } = useLocation();
  const { filter } = getFilterFromUrl(search);

  const resourceData = useSelector(getResourceDataJobTemplate);

  const getSorterOrder = useCallback(
    (source) => {
      if (filter.sorts?.[0] === source) return SORT_ASCEND_VALUE;
      if (filter.sorts?.[0] === `-${source}`) return SORT_DESCEND_VALUE;
      return '';
    },
    [filter],
  );

  const columns = [
    {
      title: i18next.t('jobs.jobTemplates.title'),
      dataIndex: 'templateName',
      width: '15%',
      sorter: true,
      render: (data, record) => useFormat.formatJobTemplateName(data, record),
      defaultSortOrder: getSorterOrder('templateName'),
    },
    {
      title: i18next.t('jobs.jobTemplates.jobTitle'),
      dataIndex: 'jobTitle',
      width: 150,
      sorter: true,
      render: (data) => <span className="ellipsis-t-2">{data}</span>,
      defaultSortOrder: getSorterOrder('jobTitle'),
    },
    {
      title: i18next.t('jobs.jobTemplates.jobRole'),
      dataIndex: 'jobRole',
      width: 150,
      sorter: true,
      render: (data) => <span className="ellipsis-t-2">{data?.name}</span>,
      defaultSortOrder: getSorterOrder('jobRole'),
    },
    {
      title: i18next.t('jobs.jobTemplates.jobType'),
      dataIndex: 'jobType',
      width: 150,
      sorter: true,
      render: useFormat.formatJobType,
      defaultSortOrder: getSorterOrder('jobRole'),
    },
    {
      title: i18next.t('jobs.jobTemplates.salary'),
      dataIndex: 'salary',
      width: 150,
      sorter: true,
      render: (data) => <span>{data?.displayText}</span>,
      defaultSortOrder: getSorterOrder('salary'),
    },
    {
      title: i18next.t('jobs.jobTemplates.discount'),
      dataIndex: 'discount',
      width: 150,
      sorter: true,
      render: useFormat.formatDiscount,
      defaultSortOrder: getSorterOrder('discount'),
    },
    {
      title: i18next.t('jobs.jobTemplates.status'),
      dataIndex: 'status',
      width: 150,
      sorter: true,
      render: useFormat.formatJobTemplateStatus,
      defaultSortOrder: getSorterOrder('status'),
    },
    {
      title: i18next.t('jobs.jobTemplates.lastUpdated'),
      dataIndex: 'lastUpdated',
      width: 150,
      sorter: true,
      render: (data, record) => formatNightDayTable(record?.updatedAt),
      defaultSortOrder: getSorterOrder('lastUpdated'),
    },
    {
      title: '',
      dataIndex: 'id',
      width: 80,
      fixed: 'right',
      render: useFormat.formatGroupAction,
    },
  ];

  const onChange = (e, filters, sorter) => {
    const formatSort =
      sorter && sorter.field && sorter.order
        ? `${sorter.order === SORT_DESCEND_VALUE ? '-' : ''}${sorter.field}`
        : null;

    const restFilter = {
      size: e.pageSize,
      page: e.current,
      sorts: [formatSort || DEFAULT_SORT_JOB_TEMPLATE],
      filter: { ...filter.filter },
    };

    retrieveList({
      filter: restFilter,
      isRefresh: true,
      keepFilter: true,
    });
    pushQuery({
      'sorts[]': restFilter.sorts,
      ...restFilter,
    });
  };

  return (
    <JobTemplateListStyle>
      <TableCustom
        xScroll={1800}
        columns={columns}
        data={jobTemplateData}
        onChange={onChange}
        loading={loading}
        pagination={{
          pageSize: filter?.size || LIMIT_ITEM,
          current: filter?.page || DEFAULT_CURRENT_PAGE,
          total: resourceData?.total,
        }}
      />
    </JobTemplateListStyle>
  );
};

JobTemplateList.propTypes = {
  jobTemplateData: PropTypes.array,
  loading: PropTypes.bool,
  pushQuery: PropTypes.func,
  retrieveList: PropTypes.func,
};

export default JobTemplateList;

import styled from 'styled-components';

export const PieChartRatingStyles = styled.div`
  .recharts-legend-wrapper {
    display: none !important;
  }
  .kindicare-rating-style {
    margin-top: 16px;
  }
  .nqs-rating-style {
    margin-top: 16px;
  }
  @media only screen and (min-width: 1600px) {
    .kindicare-rating-style {
      margin-top: 70px;
    }
    .nqs-rating-style {
      margin-top: 40px;
    }
  }
`;

export const TypesRatingStyles = styled.div`
  display: flex;
  flex-direction: column;
  .rating-type-item {
    &__image {
      background: #ffffff;
      border: 1px solid #ebecf0;
      box-sizing: border-box;
      border-radius: 8px;
      height: 38px;
      width: 60px;
      object-fit: cover;
    }
  }
`;

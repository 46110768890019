import styled from 'styled-components';

export const CommentItemStyles = styled.div`
  .ant-comment-avatar img {
    width: 100% !important;
    height: 100% !important;
  }

  .comment-message {
    font-size: 16px;
    line-height: 26px;
  }
  .ant-comment-actions {
    margin-top: 5px;
  }
  .ant-comment-content-author-name {
    flex: 1;
  }
`;

export const HeaderCommentStyles = styled.div`
  .title-header {
    margin-bottom: 5px;
    display: flex;
    justify-content: space-between;
    .name-user {
      font-weight: bold;
      font-size: 16px;
      line-height: 20px;
      color: ${({ theme }) => theme.text.mainL1};
      margin-right: 20px;
    }
    .ant-rate {
      color: ${({ theme }) => theme.palette.primary};
    }
  }
  .time-comment {
    font-size: 14px;
    line-height: 22px;
    margin-bottom: 3px;
  }
`;

export const ActionCommentStyles = styled.div`
  .ant-btn[disabled],
  .ant-btn[disabled]:hover,
  .ant-btn[disabled]:focus,
  .ant-btn[disabled]:active {
    background-color: #fff;
    color: gray;
  }
  .ant-btn {
    background: transparent;
    outline: none;
    box-shadow: none;
    border: none;
    height: 32px;
    padding: 0;
    font-size: 16px;
  }
  .ant-btn:active {
    outline: none;
    box-shadow: none;
  }
  .reply-button {
    color: ${({ theme }) => theme.color.blueSecondary};
  }
  .recommend-button,
  .dispute-btn {
    color: ${({ theme }) => theme.palette.primary};
  }
  .custom-button-wrapper {
    .action-feature-icon {
      position: absolute;
      top: -3px;
      right: -14px;
      font-size: 16px;
      color: ${({ theme }) => theme.subscriptions.colorIcon};
    }
  }
`;

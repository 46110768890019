import React from 'react';
import { formatMoney } from 'utils/textUtils';
import List from 'containers/rest/List';
import RestFieldItem from 'components/RestField/RestFieldItem';
import UserInfo from 'components/RestField/UserInfo';
import ServiceApplied from '../components/ServiceApplied';
import ExportExcel from '../components/ExportExcel';
import Filter from '../components/Filter';
import Summaries from '../components/SummaryRow';

const TransactionsList = (props) => (
  <List
    {...props}
    resource="transactions"
    hasSearch={false}
    hasCreate={false}
    widthTable="1000px"
    summaryRow={<Summaries />}
    customActions={<ExportExcel />}
    filter={<Filter />}
  >
    <RestFieldItem
      source="applicationName"
      header="transactions.applicationName"
      format={(data, record) => (
        <UserInfo
          record={{
            id: record.id,
            fullName: `${data.firstName} ${data.lastName}`,
            avatar: data.avatar,
            gender: data.gender,
          }}
          isShowGender
          prefixLink="applications"
          suffixLink="show"
        />
      )}
    />
    <RestFieldItem
      source="serviceApplied"
      header="transactions.serviceApplied"
      format={(data) => (
        <ServiceApplied
          serviceTypes={data.serviceTypes}
          requiredDays={data.requiredDays}
        />
      )}
    />
    <RestFieldItem
      source="feeExGST"
      header="transactions.feeExGST"
      format={(data) => formatMoney(data)}
      sorter
    />
    <RestFieldItem
      source="GST"
      header="transactions.GST"
      format={(data) => formatMoney(data)}
      sorter
    />
    <RestFieldItem
      source="feeIncGST"
      header="transactions.feeIncGST"
      format={(data) => formatMoney(data)}
      sorter
    />
  </List>
);

export default TransactionsList;

import React from 'react';
import { getQueryParams } from 'utils/tools';
import { useLocation } from 'react-router-dom';
import { useGetMarketingMetricsSummaryQuery } from 'redux/@rtkQuery/marketingMetris';
import { isEmpty, omit } from 'lodash-es';
import { Skeleton } from 'antd';
import { ChartMarketingMetricsPayload } from 'redux/@rtkQuery/marketingMetris/type';
import { IGNORE_SEARCH_URL_PARAMS } from 'containers/MarketingMetrics/constants';
import SummaryMetricCard from '../SummaryMetricCard';
import { CardSpace } from './styles';
import { SpinWrapper } from '../KeyMetrics/style';

interface Props {
  onCardClick: (name: string) => void;
  selectedLines: string[];
}

const KeyMetricCards = ({ onCardClick, selectedLines }: Props) => {
  const { search } = useLocation();
  const filter = getQueryParams(search)?.filter;

  const queryParams = omit<ChartMarketingMetricsPayload>(
    filter,
    IGNORE_SEARCH_URL_PARAMS,
  );

  const { data, isLoading, isFetching } = useGetMarketingMetricsSummaryQuery(
    queryParams,
    {
      skip: isEmpty(filter),
    },
  );

  return isLoading ? (
    <Skeleton />
  ) : (
    <SpinWrapper spinning={isFetching}>
      <CardSpace size={[18, 18]} wrap>
        {data?.result?.map((item) => (
          <SummaryMetricCard
            key={item.id}
            isSelected={selectedLines.includes(item.id)}
            onClick={() => onCardClick(item.id)}
            {...item}
          />
        ))}
      </CardSpace>
    </SpinWrapper>
  );
};

export default KeyMetricCards;

import { Avatar, Col, Divider, Row, Skeleton, Tag, Tooltip } from 'antd';
import React from 'react';
import {
  CallIcon,
  CloseCircleIcon,
  LanguageIcon,
  LocationV2Icon,
  MessageIcon,
  PersonalCardV2Icon,
  TableCalendarEventIcon,
  TickCircleIcon,
} from 'components/common/SVGIcon';
import { JOB_APPLICATION_STATUS_CONST_ITEM } from 'configs/localData';
import i18next from 'i18next';
import { isEmpty } from 'lodash-es';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { getLoadingJobApplicationProfile } from 'redux/jobs/jobApplications/selectors';
import { formatBirthday, formatDateApplication } from 'utils/textUtils';
import { UserOutlined } from '@ant-design/icons';
import JobApplicationGeneralInfoItem from '../InfoItem';
import AvailableDays from './AvailableDays';
import ButtonAction from './ButtonAction';
import DropdownStatusApplication from './DropdownStatusApplication';
import JobMatchProgressCircle from './JobMatchProgressCircle';
import { JobApplicationInfoStyles } from './styles';

const JobApplicationInfo = ({ currentData = {}, updateStatus }) => {
  const loading = useSelector(getLoadingJobApplicationProfile);
  const { seeker, jobListing, availableDays, status, available, createdAt } =
    currentData;
  const { id } = useParams();

  return (
    <Skeleton loading={loading}>
      <JobApplicationInfoStyles>
        <div className="flex-center avatar-style">
          <Avatar src={seeker?.avatar} size={100} icon={<UserOutlined />} />
        </div>
        <div>
          <span className="fw-bold text-20">
            <Row>
              <Col span={18}>
                <div className="flex flex-row">
                  <span className="break-all">
                    <span className="text-24">
                      {seeker?.firstname
                        ? `${seeker?.firstname} ${seeker?.lastname ?? ''}`
                        : i18next.t('error.waitingUpdate')}
                    </span>
                    {seeker?.pronouns && (
                      <span className="ml-6 text-gray-500 fw-400 text-14">
                        {`(${seeker?.pronouns})`}
                      </span>
                    )}
                  </span>
                </div>
              </Col>
              <Col span={6} className="flex justify-end pt-6">
                <JobMatchProgressCircle
                  score={Number(jobListing?.jobMatchScore) ?? 0}
                  unit="%"
                />
              </Col>
            </Row>
          </span>
        </div>
        {seeker?.birthday && (
          <JobApplicationGeneralInfoItem
            title="jobs.basicInformation.qualification"
            icon={<TableCalendarEventIcon />}
            content={formatBirthday(seeker?.birthday)}
            className="text-16"
          />
        )}
        {seeker?.fullAddress && (
          <JobApplicationGeneralInfoItem
            title="jobs.basicInformation.experience"
            icon={<LocationV2Icon />}
            content={seeker?.fullAddress}
            className="text-16"
          />
        )}
        {!isEmpty(seeker?.languages) && (
          <Tooltip
            title={i18next.t('jobs.basicInformation.languages')}
            className="flex text-16 mt-16"
          >
            <div className="text-green text-18">
              <LanguageIcon />
            </div>
            <div className="ml-8">
              {seeker?.languages?.map((item, index) => (
                <Tag key={String(index)} className="fw-600 text-14">
                  {item?.name}
                </Tag>
              ))}
            </div>
          </Tooltip>
        )}
        {seeker?.phone && (
          <JobApplicationGeneralInfoItem
            title="jobs.basicInformation.experience"
            icon={<CallIcon />}
            content={seeker?.phone}
            className="text-16"
          />
        )}
        {seeker?.email && (
          <JobApplicationGeneralInfoItem
            title="jobs.basicInformation.experience"
            icon={<MessageIcon />}
            content={seeker?.email}
            className="text-16 text-green"
          />
        )}
        {seeker?.workEligibility && (
          <JobApplicationGeneralInfoItem
            title="jobs.basicInformation.workEligibility"
            icon={<PersonalCardV2Icon />}
            content={seeker?.workEligibility}
            className="text-16"
          />
        )}
        <JobApplicationGeneralInfoItem
          title="jobs.basicInformation.experience"
          icon={
            seeker?.isOpenForNewJob ? <TickCircleIcon /> : <CloseCircleIcon />
          }
          content={i18next.t('jobApplications.profile.OpenForNewJob')}
          className="text-16"
        />

        <JobApplicationGeneralInfoItem
          title="jobs.basicInformation.experience"
          icon={
            seeker?.isLookingForNewJob ? (
              <TickCircleIcon />
            ) : (
              <CloseCircleIcon />
            )
          }
          content={i18next.t('jobApplications.profile.LookingForNewJob')}
          className="text-16"
        />
        <Divider />
        <Row gutter={16} className="row-info-application">
          <Col span={10} className="title-item-col">
            <div>{i18next.t('applications.currentStatus')}</div>
          </Col>
          <Col span={14} className="value-item-col">
            <div>
              <DropdownStatusApplication
                status={status?.id}
                disabled={
                  status?.id ===
                    JOB_APPLICATION_STATUS_CONST_ITEM.cancelled.indexOrder.toString() ||
                  jobListing?.status === 'Deleted'
                }
                handleUpdateStatus={(status, reason) => {
                  updateStatus({ applicationId: id, status, reason });
                }}
              />
            </div>
          </Col>
          {status?.id ===
            JOB_APPLICATION_STATUS_CONST_ITEM.declined.indexOrder.toString() && (
            <>
              <Col span={10} className="title-item-col">
                <div>{i18next.t('jobApplications.declinedReason')}</div>
              </Col>
              <Col span={14} className="value-item-col">
                <span>{seeker?.declinedReason}</span>
              </Col>
            </>
          )}
          <Col span={10} className="title-item-col">
            <div>{i18next.t('applications.applied')}</div>
          </Col>
          <Col span={14} className="value-item-col">
            <div>{formatDateApplication(createdAt)}</div>
          </Col>
          {!isEmpty(available) && (
            <>
              <Col span={10} className="title-item-col">
                <div>{i18next.t('jobApplications.availability')}</div>
              </Col>
              {available?.isImmediateStart ? (
                <Col span={14} className="value-item-col">
                  <span>{i18next.t('jobListings.immediateStart')}</span>
                </Col>
              ) : (
                <Col span={14} className="value-item-col">
                  <div>{formatDateApplication(available)}</div>
                </Col>
              )}
            </>
          )}
          {!!availableDays?.length && (
            <>
              <Col span={24} className="title-item-col">
                <div>
                  {i18next.t('jobApplications.generalInfo.availableDays')}
                </div>
              </Col>
              <Col span={24} className="value-item-col">
                <AvailableDays availableDays={availableDays} />
              </Col>
            </>
          )}
        </Row>
        {status?.id !==
          JOB_APPLICATION_STATUS_CONST_ITEM.declined.indexOrder.toString() && (
          <ButtonAction
            handleUpdateStatus={(statusId, reason) => {
              updateStatus({
                applicationId: id,
                status:
                  JOB_APPLICATION_STATUS_CONST_ITEM.declined.indexOrder.toString(),
                reason,
              });
            }}
          />
        )}
      </JobApplicationInfoStyles>
    </Skeleton>
  );
};

JobApplicationInfo.propTypes = {
  currentData: PropTypes.object,
  updateStatus: PropTypes.func,
};

export default JobApplicationInfo;

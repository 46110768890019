import { PREFIX_API_VER_2 } from 'configs/localData/constant';
import {
  CentreFeatureResponse,
  UpdateFeaturePayload,
  UpdateFeatureResponse,
} from 'types/centreFeatures';
import { baseApi } from '../baseApi';
import { CENTRE_FEATURES_TAG } from '../tagTypes';

export const centreFeaturesApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getCentreFeatures: builder.query<
      CentreFeatureResponse,
      { centreId: string }
    >({
      query: (params) => ({
        url: `/${PREFIX_API_VER_2}/crm/centre/details/features`,
        params,
      }),
      providesTags: [CENTRE_FEATURES_TAG],
    }),
    updateCentreFeatures: builder.mutation<
      UpdateFeatureResponse,
      UpdateFeaturePayload
    >({
      query(data) {
        return {
          url: `/${PREFIX_API_VER_2}/crm/centre/details/features`,
          method: 'PUT',
          body: data,
        };
      },
      extraOptions: { isShowSuccess: true },
      invalidatesTags: [CENTRE_FEATURES_TAG],
    }),
  }),
});

export const { useGetCentreFeaturesQuery, useUpdateCentreFeaturesMutation } =
  centreFeaturesApi;

import * as React from 'react';
import TagCustom from 'components/common/TagCustom';
import { formatNullString } from 'utils/tools';
import { formatNightDayTable } from 'utils/formatFieldUtils';
import { CSVIcon } from 'components/common/SVGIcon';
import { DELIVERY_STATUS } from 'configs/localData/constant';
import { Typography } from 'antd';
import { FileNameText } from './style';

const { Text } = Typography;

interface ButtonStatus {
  value: string;
  color: string;
  backgroundColor: string;
}

const handleDownload = (url) => {
  url && window.open(url);
};

const useFormat = () => {
  const formatViewTitle = (title: string, status: string, url: string) => (
    <div
      className="flex items-center"
      onClick={() => handleDownload(url)}
      onKeyDown={() => handleDownload(url)}
      role="presentation"
    >
      <CSVIcon className="pr-10 text-18" />
      {status !== DELIVERY_STATUS.Running ? (
        <FileNameText className="text-decoration-line fw-bold cursor-pointer">
          {title}
        </FileNameText>
      ) : (
        <Text className="text-gray-400">{title}</Text>
      )}
    </div>
  );

  const StatusTag = (params: ButtonStatus) => (
    <TagCustom
      className="fw-bold"
      color={params?.color}
      backgroundColor={params?.backgroundColor}
      text={params?.value}
      isBorder={false}
    />
  );

  const formatAvailableStart = (data) =>
    formatNullString(formatNightDayTable(data));

  return {
    formatViewTitle,
    StatusTag,
    formatAvailableStart,
  };
};

export default useFormat;

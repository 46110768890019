import React from 'react';
import { Col, Row } from 'antd';
import Meta from 'antd/lib/card/Meta';
import {
  ExportIcon,
  ImageDefaultIcon,
  VideoVerticalIcon,
} from 'components/common/SVGIcon';
import i18next from 'i18next';
import { UploadOutlined } from '@ant-design/icons';
import { isEmpty } from 'lodash-es';
import { getYoutubeEmbedURL } from 'utils/tools';
import TagCustom from 'components/common/TagCustom';
import { AdsItemResponse } from 'api/advertisement/type';
import {
  IconDowloadStyle,
  ImageDefaultStyle,
  SkeletonStyle,
  SponsoredTextStyle,
  ThumnailCardStyle,
} from '../Create/Steps/TypeOfAd/styles';
import Slide from '../Create/Steps/TypeOfAd/Slide';
import TargetedAudienceItem from './items/TargetedAudienceItem';

interface AdsThumbnailCardProps {
  mediaItems?: UploadMediaProps[];
  maxImagesNumber?: number;
  maxVideosNumber?: number;
  isForJobSeeker?: boolean;
  title?: string;
  description?: string;
  callingButton?: string;
  isStyleCard?: boolean;
  isShowTopAction?: boolean;
  isExistingCard?: boolean;
  item?: AdsItemResponse;
  isShowThumbnail?: boolean;
}

const imageUrlArrDefault: number[] = Array.from({ length: 6 });

const AdsThumbnailCard = ({
  mediaItems,
  maxImagesNumber,
  maxVideosNumber,
  isForJobSeeker = false,
  title,
  description,
  callingButton,
  isStyleCard = true,
  isShowTopAction = true,
  isExistingCard = false,
  item,
  isShowThumbnail = false,
}: AdsThumbnailCardProps) => {
  const renderMediaItems = (mediaItem: UploadMediaProps) => {
    const youtubeLink = getYoutubeEmbedURL(mediaItem?.url);
    if (mediaItem.source === 'youtube' || youtubeLink) {
      return (
        <iframe
          title="iframe-video-item"
          frameBorder="0"
          className="iframe-video object-cover"
          marginWidth={0}
          marginHeight={0}
          allowFullScreen
          src={youtubeLink}
        />
      );
    }
    return (
      <video className="iframe-video object-cover" src={mediaItem.url}>
        <source src={mediaItem.url} type="video/mp4" />
        {i18next.t('error.notSupportVideo')}
        <track src="" kind="captions" srcLang="en" label="english_captions" />
      </video>
    );
  };

  const renderIconDefault = () => {
    if (mediaItems?.length && isShowThumbnail) {
      if (mediaItems[0]?.type === 'image') {
        return (
          <img
            className="w-full h-full rounded-top-left-right-radius-8"
            src={mediaItems[0]?.url}
            alt={''}
          />
        );
      }
      return renderMediaItems(mediaItems[0]);
    }

    if (maxImagesNumber === 1) {
      return (
        <div className="flex-center h-full rounded-top-left-right-radius-8">
          {mediaItems?.length && mediaItems[0]?.url ? (
            <img
              className="w-full h-full rounded-top-left-right-radius-8 object-cover"
              src={mediaItems[0]?.url}
              alt={''}
            />
          ) : (
            <ImageDefaultIcon className="text-24" />
          )}
        </div>
      );
    }
    if (maxImagesNumber === 0 && maxVideosNumber === 1) {
      return (
        <div className="flex-center h-full rounded-top-left-right-radius-8">
          {mediaItems?.length && mediaItems[0]?.url ? (
            renderMediaItems(mediaItems[0])
          ) : (
            <VideoVerticalIcon className="text-24" />
          )}
        </div>
      );
    }

    return (
      <div className={'rounded-top-left-right-radius-8'}>
        <Slide
          mediaItems={(mediaItems as UploadMediaProps[]) || imageUrlArrDefault}
        />
      </div>
    );
  };
  return (
    <ThumnailCardStyle
      isStyleCard={isStyleCard}
      className="bg-white w-full rounded-12 relative"
    >
      <ImageDefaultStyle>{renderIconDefault()}</ImageDefaultStyle>
      <div className="absolute top-0 w-full p-12 rounded-top-left-right-radius-8">
        {isShowTopAction && (
          <Row justify="space-between">
            <Col>
              <SponsoredTextStyle className="fw-bold text-gray-700 bg-gray-300 text-12 rounded-8 p-8">
                {i18next.t('advertisement.sponsored')}
              </SponsoredTextStyle>
            </Col>
            <Col>
              <IconDowloadStyle className="bg-gray-300 rounded-8 ">
                <UploadOutlined className="text-gray-700 text-14" />
              </IconDowloadStyle>
            </Col>
          </Row>
        )}
      </div>

      <Row
        align="middle"
        justify="space-between"
        className={`${
          isForJobSeeker ? 'bg-light-green' : 'bg-light-pink'
        } py-4 px-12`}
      >
        <Col>
          <strong
            className={`${
              isForJobSeeker ? 'text-green' : 'text-primary'
            } text-11`}
          >
            {!isEmpty(callingButton)
              ? callingButton.toUpperCase()
              : `${i18next.t('advertisement.shopNow').toUpperCase()}`}
          </strong>
        </Col>
        <Col>
          <ExportIcon
            className={`${
              isForJobSeeker ? 'text-green' : 'text-primary'
            } text-11`}
          />
        </Col>
      </Row>
      <div className="p-12">
        {isExistingCard ? (
          <Meta
            className="text-12"
            title={title}
            description={
              <div>
                {item?.identities?.map((identity, index) => (
                  <TagCustom
                    key={index}
                    className="fw-bold mr-10"
                    color={identity?.color}
                    backgroundColor={identity?.backgroundColor}
                    text={identity?.value}
                    isBorder={false}
                  />
                ))}
                <Row gutter={24} className="text-14 mt-10">
                  <Col className="text-black-base">{`${i18next.t(
                    'advertisement.targetedAudience',
                  )}:`}</Col>
                  <Col>
                    <TargetedAudienceItem
                      userType={item?.targetedAudience?.userType}
                      userLocation={item?.targetedAudience?.userLocation}
                      userLocationValues={
                        item?.targetedAudience?.userLocationValues
                      }
                    />
                  </Col>
                </Row>
              </div>
            }
          />
        ) : (
          <Meta
            className="text-12"
            title={
              !isEmpty(title) ? (
                title
              ) : (
                <SkeletonStyle className="rounded-12 mt-6" />
              )
            }
            description={
              !isEmpty(description) ? (
                description
              ) : (
                <SkeletonStyle isShowDescription className="rounded-12 mb-4" />
              )
            }
          />
        )}
      </div>
    </ThumnailCardStyle>
  );
};

export default AdsThumbnailCard;

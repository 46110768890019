import { createSlice } from '@reduxjs/toolkit';
import {
  getAllCustomFail,
  getAllCustomPending,
  getAllCustomSuccess,
  INITIAL_STATE_CUSTOM,
  resetResourceDataCustom,
} from 'redux/crudCreatorCustom/utils';
import { getCompaniesOfUser, getOwnedCompaniesOfAllUserRoles } from './actions';

export const initialState = {
  ...INITIAL_STATE_CUSTOM,
};

const getOwnedCompaniesOfAllUserRolesSuccess = (
  state,
  { payload: { data } },
) => {
  state.isLoadingOwnedCompanies = false;
  state.ownedCompanies = data?.results;
};

const getOwnedCompaniesOfAllUserRolesPending = (state) => {
  state.isLoadingOwnedCompanies = true;
};

const getOwnedCompaniesOfAllUserRolesFail = (state) => {
  state.isLoadingOwnedCompanies = false;
};

const { actions, reducer } = createSlice({
  name: 'CompaniesOfUser',
  initialState,
  reducers: {
    resetResourceFilterCompaniesOfUser: resetResourceDataCustom,
  },
  extraReducers: {
    [getCompaniesOfUser.pending]: getAllCustomPending,
    [getCompaniesOfUser.fulfilled]: getAllCustomSuccess,
    [getCompaniesOfUser.rejected]: getAllCustomFail,

    [getOwnedCompaniesOfAllUserRoles.pending]:
      getOwnedCompaniesOfAllUserRolesPending,
    [getOwnedCompaniesOfAllUserRoles.fulfilled]:
      getOwnedCompaniesOfAllUserRolesSuccess,
    [getOwnedCompaniesOfAllUserRoles.rejected]:
      getOwnedCompaniesOfAllUserRolesFail,
  },
});

export const { resetResourceFilterCompaniesOfUser } = actions;

export default reducer;

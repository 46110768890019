import { createSelector } from 'reselect';

const getUserPermissions = (state) => state.auth.permissions;
const getRawCentresOfUser = (state) => state.auth?.data?.centres;
const getRawCentreId = (state) => state.auth.centreId;
const getRawCentreIds = (state) => state.auth.centreIds;
const getRawCentreLevelCRMPackageName = (state) =>
  state.auth.centreLevelCRMPackageName;
const getRawCentreLevelCRMPackageSelected = (state) =>
  state.auth.centreLevelCRMPackageSelected;
const getRawCurrentRole = (state) => state.auth.role;

const getRawMultiCentreLevelCRMPackageName = (state) =>
  state.auth.multiCentreLevelCRMPackageName;

const getRawLowestCentreLevelCRMPackageName = (state) =>
  state.auth.lowestCentreLevelCRMPackage;

const getRawCentresObjKeyById = (state) => state.auth.centresObjKeyById;

const getRawIsAccessInsightFeature = (state) =>
  state.auth.data?.isAccessInsightFeature;

const getRawCompaniesOfUser = (state) => state?.auth?.data?.companies;

const getRawIsActiveOnJobModule = (state) =>
  state.auth?.data?.isActiveOnJobModule;

const getRawIsActiveOnAds = (state) => state.auth?.data?.isActiveOnAds;

const getRawIsRoleDetail = (state) => state.auth?.data?.role;

export const userPermissionsSelector = createSelector(
  [getUserPermissions],
  (permissions) => permissions?.map((permission) => permission.policy) || null,
);

export const getCentresOfUser = createSelector(
  [getRawCentresOfUser],
  (data) => data,
);

export const getAllCompaniesOfUser = createSelector(
  [getRawCompaniesOfUser],
  (data) => data || [],
);

export const getCentreId = createSelector([getRawCentreId], (data) => data);

export const getCentreIds = createSelector([getRawCentreIds], (data) => data);

export const getCentreLevelCRMPackageName = createSelector(
  [getRawCentreLevelCRMPackageName],
  (data) => data,
);

export const getCentreLevelCRMPackageSelected = createSelector(
  [getRawCentreLevelCRMPackageSelected],
  (data) => data || {},
);

export const getMultiCentreLevelCRMPackageName = createSelector(
  [getRawMultiCentreLevelCRMPackageName],
  (data) => data,
);

export const getLowestCentreLevelCRMPackageName = createSelector(
  [getRawLowestCentreLevelCRMPackageName],
  (data) => data,
);

export const getCentresObjKeyById = createSelector(
  [getRawCentresObjKeyById],
  (data) => data,
);

export const getCurrentRole = createSelector(
  [getRawCurrentRole],
  (currentRole) => currentRole,
);

export const getCentresOfUserByCentreSelected = createSelector(
  [getRawCentresOfUser, getRawCentreId],
  (data, centreId) =>
    centreId ? [data?.find((item) => item.id === centreId)] : data,
);

export const getInsightsPermission = createSelector(
  [getRawIsAccessInsightFeature],
  (data) => data,
);

export const getActiveOnJobModulePermission = createSelector(
  [getRawIsActiveOnJobModule],
  (data) => data,
);

export const getActiveOnAdsPermission = createSelector(
  [getRawIsActiveOnAds],
  (data) => data,
);

export const getRoleDetail = createSelector(
  [getRawIsRoleDetail],
  (data) => data,
);

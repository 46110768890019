import { Button } from 'antd';
import useCheckJobListingPermission from 'containers/JobListings/hooks/useCheckJobListingPermission';
import i18next from 'i18next';
import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import CreateCampaignModal from '../Modals/CreateCampaignModal';

const ButtonCreateCampaign = ({ isRefreshCampaignList }) => {
  const { canModifyCampaign } = useCheckJobListingPermission();
  const campaignModalRef = useRef();

  const openCreateCampaignModal = () => {
    campaignModalRef.current.open();
  };

  return (
    <>
      <Button
        className="btn-line-primary t-bold"
        disabled={!canModifyCampaign}
        onClick={openCreateCampaignModal}
      >
        {i18next.t('jobListings.buttons.createCampaign')}
      </Button>
      <CreateCampaignModal
        ref={campaignModalRef}
        isRefreshCampaignList={isRefreshCampaignList}
      />
    </>
  );
};

ButtonCreateCampaign.propTypes = {
  isRefreshCampaignList: PropTypes.bool,
};

export default ButtonCreateCampaign;

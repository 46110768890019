import styled from 'styled-components';

const DetailApplicationStyles = styled.div`
  height: 100%;
  .row-detail-application {
    height: 100%;
  }
`;

export default DetailApplicationStyles;

import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  getAllApplicationsOfContactApi,
  getAllEnquiriesOfContactApi,
  getContactsBoardApi,
  getSummaryDetailContactApi,
} from 'api/contacts';
import { makeActions } from 'redux/crudCreator/actions';
import { convertRequestParams } from 'redux/crudCreator/dataProvider';
import { apiWrapper } from 'utils/reduxUtils';

export const MODEL_NAME = 'contacts';
export const contactsActions = makeActions(MODEL_NAME);

export const getAllContacts = contactsActions.getAll;
export const editContacts = contactsActions.edit;
export const createContacts = contactsActions.create;
export const getByIdContacts = contactsActions.getDataById;

export const getSummaryDetailContact = createAsyncThunk(
  'contacts/getSummaryDetailContact',
  async (payload = {}, thunkAPI) => {
    try {
      const response = await apiWrapper(
        {},
        getSummaryDetailContactApi,
        payload,
      );
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  },
);

export const getAllApplicationsOfContact = createAsyncThunk(
  'contacts/getAllApplicationsOfContact',
  async (payload = {}, thunkApi) => {
    try {
      const { limit, offset, filter } =
        thunkApi.getState().contacts.applications?.resourceData || {};

      const convertRequest = convertRequestParams('GET_ALL', {
        limit,
        offset,
        filter,
        ...payload.data,
      });

      const response = await apiWrapper(
        {},
        getAllApplicationsOfContactApi,
        payload.id,
        convertRequest,
      );

      return {
        data: {
          numberOfPages: Math.ceil(response.total / limit),
          ...response,
        },
        options: payload.options,
      };
    } catch (error) {
      return thunkApi.rejectWithValue({ id: payload.data.id, data: error });
    }
  },
);

export const getAllEnquiriesOfContact = createAsyncThunk(
  'contacts/getAllEnquiriesOfContact',
  async (payload = {}, thunkApi) => {
    try {
      const { limit, offset, filter } =
        thunkApi.getState().contacts.enquiries?.resourceData || {};

      const convertRequest = convertRequestParams('GET_ALL', {
        limit,
        offset,
        filter,
        ...payload.data,
      });

      const response = await apiWrapper(
        {},
        getAllEnquiriesOfContactApi,
        payload.id,
        convertRequest,
      );

      return {
        data: {
          numberOfPages: Math.ceil(response.total / limit),
          ...response,
        },
        options: payload.options,
      };
    } catch (error) {
      return thunkApi.rejectWithValue({ id: payload.data.id, data: error });
    }
  },
);

export const getContactsBoard = createAsyncThunk(
  'contacts/getContactsBoard',
  async (payload = {}, thunkApi) => {
    try {
      const convertRequest = convertRequestParams('GET_ALL', payload);

      const response = await apiWrapper(
        {},
        getContactsBoardApi,
        convertRequest,
      );

      return { data: response, success: true };
    } catch (error) {
      return thunkApi.rejectWithValue();
    }
  },
);

import { Button, Col, Radio, Row } from 'antd';
import axios from 'axios';
import RestGoogleAutocomplete from 'components/RestInput/RestGoogleAutocomplete';
import { RestInputContext } from 'components/RestInput/RestInputContext';
import RestSelect from 'components/RestInput/RestSelect';
import AddressFullForm from 'containers/Centres/components/Form/AddressFullForm';
import i18next from 'i18next';
import React, {
  useContext,
  useEffect,
  useState,
  forwardRef,
  useImperativeHandle,
} from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import PropTypes from 'prop-types';
import {
  getAddressCentreToGetGeo,
  isValidAddress,
  splitAddressFromGoogleMap,
} from 'utils/dataUtils';
import { getFilterFromUrl } from 'utils/tools';
import { CentreNameAddressStyles } from './styles';

const CentreAddressSelectForm = forwardRef(
  ({ setIsDisableBtn, isWrongAddress, setIsWrongAddress }, ref) => {
    useImperativeHandle(ref, () => ({
      resetFullForm: () => {
        setAddressForm(null);
      },
    }));

    const { form } = useContext(RestInputContext);
    const { search } = useLocation();
    const outsideFilter = getFilterFromUrl(search);
    const { addressL1, city, country, postCode, state } = outsideFilter?.filter;
    const [addressForm, setAddressForm] = useState();

    const [isFullAddress, setIsFullAddress] = useState();
    const [value, setValue] = useState(
      localStorage.getItem('optionSelectBenchmarking') || 'centreName',
    );
    const [addressBenchmarking, setAddressBenchmarking] = useState();

    useEffect(() => {
      form.setFieldsValue({
        addressL1,
        country,
        city,
        postCode,
        state,
      });
    }, []); // eslint-disable-line

    const onChange = (e) => {
      setValue(e?.target?.value);
      localStorage.setItem('optionSelectBenchmarking', e?.target?.value);
      if (value !== e?.target?.value) {
        if (e?.target?.value === 'centreName') {
          form.setFieldsValue({
            displayAddress: null,
            postCode: null,
            state: null,
            city: null,
            addressL1: null,
          });
          setIsWrongAddress(false);
          setIsDisableBtn(false);
        } else {
          form.setFieldsValue({ centreId: null });
        }
      }
    };

    const onSelectAddress = ({ addressObj }) => {
      const addressFields = ['addressL1', 'city', 'postCode', 'state'];
      addressFields.forEach((item) => {
        if (!addressObj[item]) {
          form.setFieldsValue({ item: null });
        }
      });
      setAddressForm(addressObj);
      form.setFieldsValue(addressObj);
      setAddressBenchmarking(addressBenchmarking);
      if (isValidAddress(addressObj)) {
        form.validateFields(['displayAddress']);
      }
    };

    const centresData = useSelector((state) => state?.auth?.data?.centres);

    useEffect(() => {
      if (addressBenchmarking) {
        axios(
          `https://maps.googleapis.com/maps/api/geocode/json?components=country:AU&address=${getAddressCentreToGetGeo(
            addressBenchmarking,
          )}&key=${process.env.REACT_APP_GOOGLE_MAP_API_KEY}`,
        ).then((res) => {
          const addressObj = splitAddressFromGoogleMap(
            res?.data?.results?.[0]?.address_components,
          );

          if (
            addressObj.city?.toLowerCase() ===
              addressBenchmarking.city?.toLowerCase() &&
            addressObj.postCode === addressBenchmarking.postCode &&
            addressObj.state?.toLowerCase() ===
              addressBenchmarking.state?.toLowerCase() &&
            addressObj.addressL1?.toLowerCase() ===
              addressBenchmarking.addressL1?.toLowerCase()
          ) {
            setIsWrongAddress(false);
            setIsDisableBtn(false);
          } else {
            setIsWrongAddress(true);
            setIsDisableBtn(true);
          }
        });
      }
    }, [addressBenchmarking]); // eslint-disable-line

    const tryFullAddressForm = () => {
      setIsFullAddress(true);
      setAddressBenchmarking(addressForm);
      form.setFieldsValue(addressForm);
      if (isWrongAddress) {
        setIsDisableBtn(true);
      }
    };

    return (
      <CentreNameAddressStyles>
        <Radio.Group
          className="w-full"
          value={value}
          onChange={onChange}
          required
        >
          <Row gutter={32}>
            <Col xl={12} lg={12} md={24} xs={24}>
              <Radio value="centreName">
                {i18next.t('centres.centreName')}
              </Radio>
              <RestSelect
                required={value !== 'address'}
                source="centreId"
                placeholder={i18next.t('centres.centreName')}
                valueProp="id"
                titleProp="name"
                resourceData={centresData || []}
                disabled={value !== 'centreName'}
              />
            </Col>
            <Col xl={12} lg={12} md={24} xs={24}>
              <Radio value="address">{i18next.t('centres.address')}</Radio>
              {!isFullAddress && (
                <>
                  <RestGoogleAutocomplete
                    source="displayAddress"
                    placeholder={i18next.t('centres.address')}
                    required={value !== 'centreName'}
                    disabled={value !== 'address'}
                    onSelectAddress={onSelectAddress}
                    rules={[
                      ({ getFieldsValue }) => ({
                        validator(_, value) {
                          if (
                            !value ||
                            isValidAddress(
                              getFieldsValue([
                                'addressL1',
                                'city',
                                'postCode',
                                'state',
                              ]),
                            )
                          ) {
                            return Promise.resolve();
                          }
                          return Promise.reject(
                            new Error(i18next.t('centres.errorDisplayAddress')),
                          );
                        },
                      }),
                    ]}
                  />
                  <div className="flex items-center fill-address">
                    <div className="location-question">
                      {i18next.t('centres.notCorrectAddress')}
                    </div>
                    <Button
                      className="address-full-form-button"
                      type="link"
                      onClick={() => tryFullAddressForm()}
                      disabled={value !== 'address'}
                    >
                      {i18next.t('button.tryFullForm')}
                    </Button>
                  </div>
                </>
              )}
              <div className={isFullAddress ? '' : 'd-none'}>
                <AddressFullForm
                  form={form}
                  isAutoAddress
                  initialValue={{ city }}
                  onClick={() => {
                    setIsFullAddress(false);
                    setIsDisableBtn(false);
                  }}
                  setGeoAddressObj={setAddressBenchmarking}
                  disabled={value !== 'address'}
                  required={value !== 'centreName'}
                  isWrongAddress={isWrongAddress}
                />
              </div>
            </Col>
          </Row>
        </Radio.Group>
      </CentreNameAddressStyles>
    );
  },
);

CentreAddressSelectForm.propTypes = {
  setIsDisableBtn: PropTypes.func,
  setIsWrongAddress: PropTypes.func,
  isWrongAddress: PropTypes.bool,
};

export default CentreAddressSelectForm;

import styled from 'styled-components';

export const CustomLabelStyle = styled.label`
  &:after {
    display: inline-block !important;
    margin-left: 4px !important;
    color: #f5222d;
    font-size: 14px;
    line-height: 1;
    content: '*' !important;
  }
`;

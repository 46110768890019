import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button, Form, Image, Layout } from 'antd';
import jobTemplateBannerPath from 'assets/images/job-template-banner.png';
import kindiCareJobLogo from 'assets/images/kc-job-logo.png';
import {
  CREATE_JOB_TEMPLATE_FINAL_STEP,
  JOB_EXISTING_TEMPLATE_VALUE,
  JOB_TEMPLATE_BANNER_VALUE,
} from 'containers/JobTemplates/constants';
import { useDispatch, useSelector } from 'react-redux';
import {
  getDataJobTemplateDetail,
  getJobTemplateCurrentStepSelector,
  getJobTemplatePayloadSelector,
} from 'redux/jobs/jobTemplates/selectors';
import {
  goToNextStep,
  goToPrevStep,
  goToStepCreateTemplate,
  resetCreateJobTemplateStep,
  resetJobTemplatePayload,
  setJobTemplatePayload,
  setSelectedTemplate,
} from 'redux/jobs/jobTemplates/slice';
import i18next from 'i18next';
import {
  getJobTemplateConfig,
  getJobTemplatesDetail,
} from 'redux/jobs/jobTemplates/actions';
import ExistingJobTemplateGrid from 'containers/JobTemplates/components/ExistingJobTemplateGrid';
import { useLocation } from 'react-router-dom';
import { getFilterFromUrl } from 'utils/tools';
import { transformDataStructureStepsForm } from 'containers/JobTemplates/utils';
import { BasicInfoBannerWrapper } from './styles';
import JobTemplateFooter from './JobTemplateFooter';
import JobTemplateSteps from './JobTemplateSteps';
import JobFeaturesForm from '../StepContent/JobFeaturesForm';
import JobRequirementSkillsForm from '../StepContent/JobRequirementSkillsForm';
import CertificationsForm from '../StepContent/CertificationsForm';
import ImmunisationForm from '../StepContent/ImmunisationForm';
import JobApplicationForm from '../StepContent/JobApplicationForm';
import CreateTemplateAction from '../StepContent/CreateTemplateAction';
import BasicInformationForm from '../StepContent/BasicInformationForm';
import MoreAboutJobForm from '../StepContent/MoreAboutJobForm';

const { Header, Sider, Content } = Layout;

const CreateJobTemplateBanner = ({ match, history }) => {
  const dispatch = useDispatch();
  const { search } = useLocation();
  const {
    filter: { id: existTemplateId },
  } = getFilterFromUrl(search);

  const jobTemplateUrlParam = match?.params?.model;
  const currentStep = useSelector(getJobTemplateCurrentStepSelector);
  const existingTemplate = useSelector(getDataJobTemplateDetail);
  const templatePayload = useSelector(getJobTemplatePayloadSelector);

  const initTemplate = transformDataStructureStepsForm(existingTemplate);

  const [formBasic] = Form.useForm();
  const [formAboutJob] = Form.useForm();
  const [formFeatures] = Form.useForm();
  const [formRequirementSkills] = Form.useForm();
  const [formCertifications] = Form.useForm();
  const [formImmunisation] = Form.useForm();
  const [formApplication] = Form.useForm();

  const { content, form } = (() => {
    if (jobTemplateUrlParam === JOB_TEMPLATE_BANNER_VALUE) {
      return { content: <CreateTemplateAction /> };
    }

    if (jobTemplateUrlParam === JOB_EXISTING_TEMPLATE_VALUE) {
      return { content: <ExistingJobTemplateGrid /> };
    }

    switch (currentStep) {
      case 0:
        return {
          content: <BasicInformationForm form={formBasic} />,
          form: formBasic,
        };
      case 1:
        return {
          content: <MoreAboutJobForm form={formAboutJob} />,
          form: formAboutJob,
        };
      case 2:
        return {
          content: <JobFeaturesForm form={formFeatures} />,
          form: formFeatures,
        };
      case 3:
        return {
          content: <CertificationsForm form={formCertifications} />,
          form: formCertifications,
        };
      case 4:
        return {
          content: <JobRequirementSkillsForm form={formRequirementSkills} />,
          form: formRequirementSkills,
        };
      case 5:
        return {
          content: <ImmunisationForm form={formImmunisation} />,
          form: formImmunisation,
        };
      case 6:
        return {
          content: <JobApplicationForm form={formApplication} />,
          form: formApplication,
        };
      default:
        return {};
    }
  })();

  const resetStepAndPayload = () => {
    dispatch(resetJobTemplatePayload());
    dispatch(resetCreateJobTemplateStep());
  };

  const handleSubmitForm = (callback) => {
    form
      .validateFields()
      .then(() => {
        form.submit();
        callback();
      })
      .catch(() => {});
  };

  const handleJumpStep = (newStep) => {
    if (currentStep === CREATE_JOB_TEMPLATE_FINAL_STEP) {
      dispatch(goToStepCreateTemplate(newStep));
      return;
    }
    handleSubmitForm(() => dispatch(goToStepCreateTemplate(newStep)));
  };

  const handleGoToPrevStep = () => {
    if (
      currentStep === 0 ||
      jobTemplateUrlParam === JOB_EXISTING_TEMPLATE_VALUE
    ) {
      resetStepAndPayload();
      history.push('/jobTemplates/create/jobTemplateBanner');
      return;
    }
    dispatch(goToPrevStep());
  };

  const handleGoToNextStep = () => {
    if (jobTemplateUrlParam === JOB_EXISTING_TEMPLATE_VALUE) {
      const selectedTemplates = document.getElementsByClassName(
        'template-card-selected',
      );
      if (selectedTemplates?.length > 0) {
        resetStepAndPayload();
        dispatch(setSelectedTemplate(true));
        dispatch(getJobTemplatesDetail({ id: existTemplateId })).then(
          ({ payload }) => {
            const initPayload = transformDataStructureStepsForm(
              payload?.result,
            );

            dispatch(setJobTemplatePayload(initPayload));
          },
        );
        history.push(`/jobTemplates/create/step?id=${existTemplateId}`);
      } else {
        dispatch(setSelectedTemplate(false));
      }
    } else {
      handleSubmitForm(() => dispatch(goToNextStep()));
    }
  };

  const handleExitCreateTemplate = () => {
    history.push('/jobTemplates');
    resetStepAndPayload();
  };

  useEffect(() => {
    dispatch(getJobTemplateConfig());
  }, [dispatch]);

  useEffect(() => {
    if (existTemplateId) {
      dispatch(getJobTemplatesDetail({ id: existTemplateId }));
    }
  }, [dispatch, existTemplateId]);

  return (
    <BasicInfoBannerWrapper>
      <Sider width="30%" breakpoint="lg">
        {jobTemplateUrlParam !== JOB_TEMPLATE_BANNER_VALUE &&
        jobTemplateUrlParam !== JOB_EXISTING_TEMPLATE_VALUE ? (
          <JobTemplateSteps
            currentStep={currentStep}
            onChange={handleJumpStep}
          />
        ) : (
          <div className="banner-container flex flex-center h-full w-full">
            <div className="banner-content">
              <div className="banner-content-title">
                <span
                  dangerouslySetInnerHTML={{
                    __html: i18next.t('jobs.createJobTemplateStep.bannerTitle'),
                  }}
                />
              </div>
              <div className="banner-content-img">
                <Image
                  preview={false}
                  src={jobTemplateBannerPath}
                  alt="banner-img"
                />
              </div>
            </div>
          </div>
        )}
      </Sider>
      <Layout>
        <Header>
          <div className="text-center kc-job-logo">
            <img src={kindiCareJobLogo} alt="kc-job-logo" />
          </div>
          <div className="btn-exit-wrapper">
            <Button
              type="text"
              className="btn-exit fw-bold"
              onClick={handleExitCreateTemplate}
            >
              {i18next.t('button.exit')}
            </Button>
          </div>
        </Header>
        <Content className="p-64">
          {React.cloneElement(content, {
            initTemplate: existTemplateId
              ? { ...initTemplate, ...templatePayload }
              : templatePayload,
          })}
        </Content>
        {jobTemplateUrlParam !== JOB_TEMPLATE_BANNER_VALUE ||
        jobTemplateUrlParam === JOB_EXISTING_TEMPLATE_VALUE ? (
          <JobTemplateFooter
            handleGoToNextStep={handleGoToNextStep}
            handleGoToPrevStep={handleGoToPrevStep}
          />
        ) : null}
      </Layout>
    </BasicInfoBannerWrapper>
  );
};

CreateJobTemplateBanner.propTypes = {
  match: PropTypes.object,
  history: PropTypes.object,
};

export default CreateJobTemplateBanner;

import { PREFIX_API_VER_2 } from 'configs/localData/constant';
import { get, post, put } from './utils';

export const getCompanyProfileApi = (data) =>
  get(`/crm/companies/details/basic-info`, data, PREFIX_API_VER_2);

export const editAboutUsCardApi = (data) =>
  put(`/crm/companies/details/about-us`, data, PREFIX_API_VER_2);

export const editEducatorandStaffDiscountsApi = (data) =>
  put(`/crm/companies/details/job-discount`, data, PREFIX_API_VER_2);

export const getAllSocialMediaChannelsApi = (data) =>
  get(`/crm/companies/details/cta-buttons`, data, PREFIX_API_VER_2);

export const editSocialMediaChannelsApi = (id, data) =>
  put(
    `/crm/companies/details/cta-buttons?companyId=${id}`,
    data,
    PREFIX_API_VER_2,
  );

export const updateCompanyProfileMediaApi = (companyId, data) =>
  put(
    `/crm/companies/details/media?companyId=${companyId}`,
    data,
    PREFIX_API_VER_2,
  );

export const editCompanyProfileApi = (data) =>
  put(`/crm/companies/details/basic-info`, data, PREFIX_API_VER_2);

export const addCompanyVirtualTourApi = (id, data) =>
  post(
    `/crm/companies/details/virtual-tour?companyId=${id}`,
    data,
    PREFIX_API_VER_2,
  );

export const getCompanyVirtualTourApi = (id, data) =>
  get(
    `/crm/companies/details/virtual-tour?companyId=${id}`,
    data,
    PREFIX_API_VER_2,
  );

import React from 'react';
import PropTypes from 'prop-types';
import { Avatar } from 'antd';
import i18next from 'i18next';
import CompanyDefaultLogo from 'assets/icons/company-default-logo.svg';

const CompanyImageInfo = ({ logo }) => (
  <div className="avatar-company">
    <Avatar src={logo || CompanyDefaultLogo} size={120}>
      {i18next.t('companies.logo')}
    </Avatar>
  </div>
);

CompanyImageInfo.propTypes = {
  logo: PropTypes.string,
};

export default CompanyImageInfo;

import { makeCRUDSlice } from 'redux/crudCreator';
import {
  getTransactionsSummary,
  MODEL_NAME,
  transactionsActions,
} from './actions';

const getSummaryPeding = (state) => {
  state.summary = {
    totalTransaction: 0,
    totalValueTransaction: 0,
    totalValueTransactionInMonth: 0,
    totalValueTransactionInYear: 0,
  };
};

const getSummarySuccess = (state, { payload }) => {
  state.summary = payload.data;
};

const slice = makeCRUDSlice(MODEL_NAME, transactionsActions, {
  [getTransactionsSummary.pending]: getSummaryPeding,
  [getTransactionsSummary.fulfilled]: getSummarySuccess,
});

export default slice.reducer;

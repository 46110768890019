import React, { useCallback, useMemo, useRef } from 'react';
import i18next from 'i18next';
import { isEmpty } from 'lodash-es';
import { Row, Skeleton } from 'antd';
import { useSelector } from 'react-redux';
import { getLoadingConfig, getSubscriptionsArr } from 'redux/config/selectors';
import { getCentreId } from 'redux/auth/selectors';

import PageTitle from 'components/common/PageTitle';
import EmptyData from 'components/common/EmptyData';
import SubscriptionsList from 'containers/Subscriptions/components/SubscriptionsList';
import ListStyles from './styles';
import ReminderSelectCentre from './ReminderSelectCentre';
import EULAModal from './EULAModal';

const List = () => {
  const data = useSelector(getSubscriptionsArr);

  const loading = useSelector(getLoadingConfig);

  const centreId = useSelector(getCentreId);

  const EULAModalRef = useRef();

  const onClickSignup = useCallback(
    (data) => {
      EULAModalRef?.current && EULAModalRef.current.openModal(data);
    },
    [EULAModalRef],
  );

  const content = useMemo(() => {
    if (!centreId) return <ReminderSelectCentre />;

    if (loading) return <Skeleton active />;

    if (isEmpty(data)) return <EmptyData />;

    return (
      <SubscriptionsList
        onClickSignup={onClickSignup}
        subscriptionData={data}
      />
    );
  }, [loading, data, centreId, onClickSignup]);

  return (
    <ListStyles>
      <PageTitle>{i18next.t('subscriptions.header')}</PageTitle>
      <Row gutter={[24, 24]} className="list-subscriptions">
        {content}
      </Row>
      <EULAModal ref={EULAModalRef} />
    </ListStyles>
  );
};

export default List;

import { createSelector } from '@reduxjs/toolkit';
import { AdsType, CallingButton, IAdsFormConfig } from 'api/advertisement/type';
import { RootState } from 'redux/store';

const selectAdvertisement = (state: RootState) => state.advertisement;

export const getCurrentStepCreateAd = createSelector(
  selectAdvertisement,
  (advertisement) => advertisement.currentStep,
);

export const getCreateAdPayload = createSelector(
  selectAdvertisement,
  (advertisement) => advertisement.createPayload,
);

export const getAdsFormConfigSelector = createSelector(
  selectAdvertisement,
  (advertisement) => advertisement.config as IAdsFormConfig,
);

export const getSelectedAdTypeSelector = createSelector(
  selectAdvertisement,
  (advertisement) => advertisement.adType as AdsType,
);

export const getAdsFormConfigLoadingSelector = createSelector(
  selectAdvertisement,
  (advertisement) => advertisement.formConfigloading,
);

export const getDetailAdsLoadingSelector = createSelector(
  selectAdvertisement,
  (advertisement) => advertisement.detailLoading,
);

export const getAdsListSelector = createSelector(
  selectAdvertisement,
  (advertisement) => advertisement.data,
);

export const getAdsListLoadingSelector = createSelector(
  selectAdvertisement,
  (advertisement) => advertisement.loading,
);

export const getAdAudiencesSelector = createSelector(
  selectAdvertisement,
  (advertisement) => advertisement.audiences,
);

export const getCallingButtonListSelector = createSelector(
  selectAdvertisement,
  (advertisement) => advertisement.callingButton as CallingButton,
);

export const getAdDurationValues = createSelector(
  selectAdvertisement,
  (advertisement) => advertisement.createPayload.duration,
);

export const getAdsResourceDataSelector = createSelector(
  selectAdvertisement,
  (advertisement) => advertisement.resourceData,
);

export const enabledLoadMoreExistingAd = createSelector(
  selectAdvertisement,
  ({ loading, resourceData }) => {
    const {
      page: currentPage = 1,
      size = 10,
      total: totalRecords = 0,
    } = resourceData;
    const totalPage: number = Math.ceil(Number(totalRecords) / Number(size));

    return !loading && Number(currentPage) < totalPage;
  },
);

export const hasSelectedAdSelector = createSelector(
  selectAdvertisement,
  (advertisement) => advertisement.hasSelectedAd,
);

export const getAdDetailData = createSelector(
  selectAdvertisement,
  (advertisement) => advertisement.currentData,
);

import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { centresSelectors } from 'redux/centres/selectors';
import FilterCustom from 'components/common/FilterCustom';
import CentreFilterForm from './FilterForm';

const CentreFilterCustom = ({ retrieveList, pushQuery }) => {
  const resourceFilter = useSelector(centresSelectors.getFilters) || {};

  const onSubmitFilter = ({ serviceTypeId, name, ...values }) => {
    const restFilter = {
      filter: {
        ...resourceFilter.filter,
        serviceTypeId: serviceTypeId ? { $in: [serviceTypeId] } : undefined,
        ...values,
      },
    };

    if (name) {
      restFilter.filter['centre.name'] = { $like: name };
    }
    retrieveList({
      filter: restFilter,
      isRefresh: true,
    });

    pushQuery(restFilter);
  };

  const onClearFilter = () => {
    retrieveList({
      filter: { orderBy: resourceFilter.orderBy },
      isRefresh: true,
    });
    pushQuery({ orderBy: resourceFilter.orderBy });
  };

  return (
    <FilterCustom onSubmitFilter={onSubmitFilter} onClearFilter={onClearFilter}>
      <CentreFilterForm />
    </FilterCustom>
  );
};

CentreFilterCustom.propTypes = {
  retrieveList: PropTypes.func,
  pushQuery: PropTypes.func,
};

CentreFilterCustom.defaultProps = {
  retrieveList: () => null,
  pushQuery: () => null,
};

export default CentreFilterCustom;

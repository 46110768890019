import React from 'react';
import PropTypes from 'prop-types';

import CheckPermission from 'components/common/CheckPermissions';
import DropdownFallback from './DropdownFallback';
import { DropdownStatusApplicationStyles } from './styles';
import DropdownStatus from './DropdownStatus';

const DropdownStatusApplication = (props) => (
  <DropdownStatusApplicationStyles>
    <CheckPermission
      permissionKey="applications"
      action="isEdit"
      isDisabled
      fallback={<DropdownFallback status={props.status} />}
    >
      <DropdownStatus {...props} />
    </CheckPermission>
  </DropdownStatusApplicationStyles>
);

DropdownStatusApplication.propTypes = {
  status: PropTypes.string,
};

export default DropdownStatusApplication;

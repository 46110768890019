import React from 'react';
import i18next from 'i18next';
import CustomButton from 'components/RestActions/CustomButton';

interface props {
  record?: any;
  handleGetStarted: (event: React.MouseEvent<HTMLElement>) => void;
}

const GetStarted = ({ handleGetStarted, record }: props) => (
  <div className="content-step">
    <div className="flex-center-between">
      <div style={{ width: '80%' }} className="start-content">
        {i18next.t('listingStrength.getStartedInfo')}
      </div>
      <div>
        <CustomButton
          isSkipSubscription
          permissionKey="centreListingStrength"
          action="isEdit"
          packageName={record?.CRMPackage?.name}
          buttonProps={{
            className: 'btn-second-primary content-step__btn-update',
          }}
          title={i18next.t('button.getStarted')}
          handleClick={handleGetStarted}
        />
      </div>
    </div>
  </div>
);

export default GetStarted;

import React, { useRef } from 'react';
import { ExclamationCircleFilled } from '@ant-design/icons';
import { Button, Modal, Tooltip } from 'antd';
import { ArchiveButtonIcon, UnArchivedIcon } from 'components/common/SVGIcon';
import { APPLICATION_STATUS_CONSTANT } from 'configs/localData';
import i18next from 'i18next';
import styled from 'styled-components';
import { updateApplicationArchiveStatus } from 'redux/applications/actions';
import { useAppDispatch } from 'redux/hooks';
import ArchiveApplicationReasonModal from '../components/ArchiveApplicationReasonModal';

const { confirm } = Modal;

interface Props {
  isArchived: boolean;
  applicationStatus: string;
  applicationId: string;
}

const ArchiveButton = ({
  isArchived,
  applicationStatus,
  applicationId,
}: Props) => {
  const dispatch = useAppDispatch();
  const refArchiveModal = useRef<{ open: (id: string) => void }>();

  const disabledUnarchive =
    isArchived &&
    (applicationStatus === APPLICATION_STATUS_CONSTANT.declined ||
      applicationStatus === APPLICATION_STATUS_CONSTANT.cancelled);

  const handleClick = () => {
    if (!isArchived) {
      refArchiveModal?.current && refArchiveModal.current.open(applicationId);
      return;
    }

    confirm({
      title: i18next.t('applications.unarchiveApplication'),
      content: i18next.t('applications.unarchiveDesc'),
      icon: <ExclamationCircleFilled />,
      okText: i18next.t('button.unarchive'),
      onOk: () => {
        dispatch(
          updateApplicationArchiveStatus({
            id: applicationId,
            body: {
              archivedReason: null,
            },
            refreshList: false,
          }),
        );
      },
      okButtonProps: {
        className: 'fw-bold',
      },
      cancelButtonProps: {
        className: 'fw-bold',
      },
    });
  };

  return (
    <Wrapper>
      <Tooltip
        title={
          disabledUnarchive ? i18next.t('applications.disabledArchive') : ''
        }
        placement="bottomRight"
      >
        <Button
          className="btn-line-primary fw-bold"
          disabled={disabledUnarchive}
          onClick={handleClick}
          type="default"
          icon={isArchived ? <UnArchivedIcon /> : <ArchiveButtonIcon />}
        >
          {isArchived
            ? i18next.t('button.unarchive')
            : i18next.t('button.archive')}
        </Button>
      </Tooltip>
      <ArchiveApplicationReasonModal ref={refArchiveModal} />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  .ant-btn[disabled] {
    font-weight: 700;
    color: #98a2b3;
    border-color: #98a2b3;
    background: none !important;
  }
`;

export default ArchiveButton;

import React from 'react';
import PropTypes from 'prop-types';
import Space from 'antd/lib/space';

const Children = ({ data }) => (
  <div>
    <Space direction="vertical">
      {data?.map((item, idx) => (
        <div className="text-primary" key={String(idx)}>
          {`${item.firstName || ''} ${item.lastName || ''}`}
        </div>
      ))}
    </Space>
  </div>
);

Children.propTypes = {
  data: PropTypes.array,
};

export default Children;

import React from 'react';
import PropTypes from 'prop-types';
import { formatMoney } from 'utils/tools';
import { HeaderColumn } from './styles';

const Header = ({ title, count, ...props }) => {
  const { isDragging, lineColor } = props;

  return (
    <HeaderColumn isDragging={isDragging} lineColor={lineColor} {...props}>
      <div className="line-color" />
      <div className="title-header">{`${title} (${formatMoney(count)})`}</div>
    </HeaderColumn>
  );
};

Header.propTypes = {
  isDragging: PropTypes.bool,
  dragHandleProps: PropTypes.object,
  title: PropTypes.string,
  count: PropTypes.number,
  lineColor: PropTypes.string,
};

export default Header;

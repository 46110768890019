import styled from 'styled-components';

const ForgotPasswordStyleWrapper = styled.div`
  .isoLoginContentWrapper {
    width: 100%;
    height: 100%;
    overflow-y: auto;
    z-index: 10;
    position: relative;
  }

  .isoLoginContent {
    min-height: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
    background-color: transparent;

    @media only screen and (max-width: 767px) {
      width: 100%;
    }

    .div-logo {
      text-align: center;
      margin-bottom: 30px;
    }
    .success-icon-div {
      text-align: center;
      margin-bottom: 20px;
      .anticon {
        font-size: 50px;
        color: ${({ theme }) => theme.color.green};
      }
    }

    .isoLogoWrapper {
      width: 100%;
      display: flex;
      margin-bottom: 50px;
      margin-right: 10px;
      justify-content: center;
      flex-shrink: 0;
      text-align: center;
    }

    .isoSignInForm {
      width: 100%;
      display: flex;
      flex-shrink: 0;
      flex-direction: column;
      margin-top: 30px;
      .isoInputWrapper {
        margin-bottom: 20px;
        &:last-of-type {
          margin-bottom: 0;
        }

        input {
          &::-webkit-input-placeholder {
            color: ${({ theme }) => theme.text.empty};
          }

          &:-moz-placeholder {
            color: ${({ theme }) => theme.text.empty};
          }

          &::-moz-placeholder {
            color: ${({ theme }) => theme.text.empty};
          }
          &:-ms-input-placeholder {
            color: ${({ theme }) => theme.text.empty};
          }
        }
      }
    }
  }

  .buttonWrapper {
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    button {
      width: 100%;
      font-weight: 600;
    }
  }

  .txtDescription {
    margin-top: 30px;
  }
  .txtError {
    margin-top: 20px;
    text-align: center;
    color: ${({ theme }) => theme.alert.error};
  }
`;

export default ForgotPasswordStyleWrapper;

export const GuideContentStyles = styled.div`
  text-align: center;
  margin-bottom: 30px;
  .guide-content__username {
    font-weight: bold;
    color: ${({ theme }) => theme.color.blueSecondary};
  }
`;

import React, { useEffect } from 'react';
import { Form, Space } from 'antd';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { setJobTemplatePayload } from 'redux/jobs/jobTemplates/slice';
import { getJobTemplateConfigSelector } from 'redux/jobs/jobTemplates/selectors';
import useWindowAutoScroll from 'hooks/useWindowAutoScroll';
import { cleanObject } from 'utils/dataUtils';
import { isEmpty } from 'lodash-es';
import TableToggle from '../../TableToggle';

const JobFeaturesForm = ({ form, initTemplate }) => {
  useWindowAutoScroll();
  const dispatch = useDispatch();
  const { features = [] } = useSelector(getJobTemplateConfigSelector);

  const onFinish = (features) => {
    dispatch(setJobTemplatePayload({ features: cleanObject(features) }));
  };

  useEffect(() => {
    form.resetFields();
    if (!isEmpty(initTemplate?.features)) {
      form.setFieldsValue(initTemplate?.features);
    }
  }, [form, initTemplate]);

  return (
    <div>
      <Form form={form} onFinish={onFinish}>
        <Space direction="vertical" size={24} className="w-full">
          {features?.map((categoryItem) => (
            <TableToggle
              dataSource={categoryItem?.items}
              categoryName={categoryItem?.category}
              key={categoryItem?.category}
            />
          ))}
        </Space>
      </Form>
    </div>
  );
};

JobFeaturesForm.propTypes = {
  form: PropTypes.object,
  initTemplate: PropTypes.object,
};

export default JobFeaturesForm;

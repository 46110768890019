import { useSelector } from 'react-redux';

const useIsSenderMessage = (lastMessageSenderId) => {
  const centreIdOfInbox = useSelector(
    (state) => state.enquiries.currentData?.centreId,
  );
  return centreIdOfInbox === lastMessageSenderId;
};

export default useIsSenderMessage;

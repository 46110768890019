import React from 'react';
import { Form, Input } from 'antd';
import PropTypes from 'prop-types';
import i18next from 'i18next';

import CentreModal from '.';

const CentreInformationModal = ({ visible, record, onCancel }) => (
  <CentreModal
    title="centreDetail.titleCentreInformationModal"
    visible={visible}
    onCancel={onCancel}
  >
    <Form.Item
      name="shortDescription"
      label={i18next.t('centreDetail.centreDescription')}
      initialValue={record.shortDescription}
    >
      <Input.TextArea
        placeholder={i18next.t('centreDetail.centreDescription')}
        rows={10}
      />
    </Form.Item>
  </CentreModal>
);

CentreInformationModal.propTypes = {
  record: PropTypes.object,
  visible: PropTypes.bool,
  onCancel: PropTypes.func,
};

export default CentreInformationModal;

import { notification } from 'antd';
import i18next from 'i18next';
import { logoutSuccess } from 'redux/auth/slice';
import store from 'redux/store';
import { PREFIX_API_VER_2 } from 'configs/localData/constant';
import { isNil, omitBy } from 'lodash-es';
import { post } from './utils';

const CLIENT_ID = '50b73e2dc3f6fb5';

export async function getPreSignedAdsUrl(filename, adsPostId) {
  return post(
    '/crm/ads/posts/s3-presigned-url',
    omitBy({ filename, adsPostId }, isNil),
    PREFIX_API_VER_2,
  );
}

export async function getUrl(key, type) {
  return post('/admin/signedUrlS3', { key, type });
}

export async function uploadMedia(url, data) {
  return fetch(url, {
    method: 'PUT',
    body: data,
    headers: {
      Accept: 'application/json',
      'Content-Type': data.type,
    },
  });
}

export async function uploadMediaImgur(data) {
  return fetch('https://api.imgur.com/3/image', {
    method: 'POST',
    headers: {
      Authorization: `Client-ID ${CLIENT_ID}`,
    },
    body: data,
  }).then((res) => res.json());
}

export const imageUploadEditorHandler = async (
  blobInfo,
  success,
  failure,
  prefixKey,
) => {
  try {
    const responseS3 = await getUrl(
      `${prefixKey || ''}${blobInfo.filename()}`,
      'image',
    );

    const response = await uploadMedia(responseS3.uploadUrl, blobInfo.blob());
    if (response?.status === 200) {
      success(responseS3.url);
    } else {
      failure(i18next.t('error.description'));
    }
  } catch (error) {
    failure(error.message || i18next.t('error.description'));
    if (error.code === 401) {
      store.dispatch(logoutSuccess());
      notification.error({
        message: i18next.t('error.title'),
        description: i18next.t('error.error401'),
        duration: 2,
        position: 'tr',
      });
    }
  }
};

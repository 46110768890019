import {
  DEFAULT_CURRENT_PAGE,
  LIMIT_ITEM,
} from 'containers/JobListings/constants';
import { formatFromToTimeFilterSubmit } from 'utils/dataUtils';
import { DEFAULT_FILTER } from './constants';

export const getLeadActivitiesDefaultsFilter = () => {
  const clickThroughDate = formatFromToTimeFilterSubmit(
    DEFAULT_FILTER?.clickThroughDate,
  );

  return {
    filter: {
      channel: DEFAULT_FILTER.channel,
      clickThroughDate,
    },
    page: DEFAULT_CURRENT_PAGE,
    size: LIMIT_ITEM,
  };
};

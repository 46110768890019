import React from 'react';
import { QuestionFillIcon } from 'components/common/SVGIcon';
import { Tooltip } from 'antd';

interface TitleWithTooltipProps {
  tooltip: string;
  title: string;
}
const TitleWithTooltip = ({ tooltip, title }: TitleWithTooltipProps) => (
  <div>
    <span className="title-header">{title}</span>
    <Tooltip placement="top" title={tooltip}>
      <QuestionFillIcon className="ml-6" />
    </Tooltip>
  </div>
);

export default TitleWithTooltip;

import React from 'react';
import { ComparePercentageStyles } from './styles';

export interface ComparePercentageProps {
  text?: string;
  color?: string;
  icon?: string;
  className?: string;
  hasDashBorder?: boolean;
}

const ComparePercentage = ({
  text,
  color,
  icon,
  className,
  hasDashBorder = true,
}: ComparePercentageProps) => (
  <ComparePercentageStyles
    hasDashBorder={icon ? hasDashBorder : false}
    color={color}
    className={className ?? ''}
  >
    {text}
    {!!icon && <img width={20} src={icon} alt="compare state" />}
  </ComparePercentageStyles>
);

export default ComparePercentage;

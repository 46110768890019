import React, { useCallback } from 'react';
import { BriefcaseIcon, RoundOpenInNewIcon } from 'components/common/SVGIcon';
import { INDIVIDUAL_JOB_LISTING_STATUS } from 'configs/localData';
import i18next from 'i18next';
import { Space, Tag } from 'antd';
import { formatDateApplication } from 'utils/textUtils';
import { getSearchUrl } from 'utils/tools';
import { useHistory } from 'react-router';
import {
  DEFAULT_CURRENT_PAGE,
  JOB_LISTING_DETAIL_TABS,
} from 'containers/JobListings/constants';
import { DEFAULT_SORT_JOB_APPLICATIONS } from 'containers/JobApplications/constants';

const useFormat = () => {
  const { push } = useHistory();

  const formatApplicationAction = (data, record) => {
    const handleClick = () => {
      window.open(
        `/job-applications/board?${getSearchUrl({
          page: DEFAULT_CURRENT_PAGE,
          sorts: [DEFAULT_SORT_JOB_APPLICATIONS],
          filter: {
            jobReference: record?.jobReference,
          },
        })}`,
      );
    };
    return (
      <Space onClick={handleClick} className="cursor-pointer">
        <span className="mr-8 fw-bold text-decoration-line">{data}</span>
        <RoundOpenInNewIcon />
      </Space>
    );
  };

  const formatReference = (data, record) => {
    const handleClick = () => {
      push(
        `/job-listings/${record?.id}/show/${JOB_LISTING_DETAIL_TABS.JOB_DETAILS}`,
      );
    };
    return (
      <div
        className="t-bold black ellipsis-t-2 link-class text-decoration-line cursor-pointer"
        role="presentation"
        onClick={handleClick}
      >
        {data}
      </div>
    );
  };

  const formatStatus = useCallback((data) => {
    const restItem =
      INDIVIDUAL_JOB_LISTING_STATUS?.find((item) => item.value === data) || {};

    return restItem ? (
      <Tag
        className="t-bold"
        style={{
          color: restItem.color,
          backgroundColor: restItem.backgroundColor,
          border: 'transparent',
          padding: '2px 10px',
        }}
      >
        {i18next.t(restItem.text)}
      </Tag>
    ) : (
      i18next.t('error.waitingUpdate')
    );
  }, []);

  const formatType = (data) => (
    <Tag icon={<BriefcaseIcon />} className="t-bold">
      {data}
    </Tag>
  );

  const formatStartDate = (data, record) => {
    if (record?.isStartImmediate) {
      return i18next.t('jobListings.immediateStart');
    }
    return formatDateApplication(data);
  };

  return {
    formatApplicationAction,
    formatReference,
    formatStatus,
    formatType,
    formatStartDate,
  };
};

export default useFormat;

import styled from 'styled-components';

export const PieChartStyles = styled.div`
  height: 100%;
  .recharts-wrapper {
    width: 100% !important;
    height: 100% !important;
    display: flex;
    flex-direction: column;
  }
  .recharts-responsive-container {
    height: 100% !important;
  }
  .recharts-default-legend > li {
    margin: 5px;
  }
  .no-data {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .recharts-legend-wrapper {
    position: unset !important;
    margin-top: 10px;
  }

  @media only screen and (max-width: 992px) {
    .recharts-wrapper {
      flex-direction: column;
      & > .recharts-surface {
        transform: none;
      }
    }

    .recharts-legend-wrapper {
      max-width: 100% !important;
      margin-top: 20px;
      position: unset !important;
      .recharts-default-legend {
        flex-direction: row;
      }
    }
  }
`;

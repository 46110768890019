import React, { useEffect, useCallback, useRef } from 'react';

import { isEmpty } from 'lodash-es';
import { Button, Skeleton } from 'antd';
import i18next from 'i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import {
  getRatingsSelector,
  getRatingsLoadingSelector,
  enabledLoadMoreRatingsSelector,
} from 'redux/centres/selectors';
import { getRatingsCentre } from 'redux/centres/actions';

import EmptyData from 'components/common/EmptyData';
import CardSection from 'components/common/CardSection';
import CommentItem from './CommentItem';
import DisputeModal from '../DisputeModal';

const ReviewsSection = () => {
  const dispatch = useDispatch();

  const { id } = useParams();

  const disputeModalRef = useRef();

  const ratings = useSelector(getRatingsSelector);

  const loading = useSelector(getRatingsLoadingSelector);

  const enabledLoadMore = useSelector(enabledLoadMoreRatingsSelector);

  const retrieveList = useCallback(
    (isRefresh, filter) => {
      id &&
        dispatch(
          getRatingsCentre({
            data: { id, orderBy: '-createdAt', ...filter },
            options: { isRefresh },
          }),
        );
    },
    [id], // eslint-disable-line
  );

  useEffect(() => {
    retrieveList(true, { limit: 10, offset: 0 });
  }, [id]); // eslint-disable-line

  const handleViewMoreReview = useCallback(() => {
    retrieveList();
  }, [retrieveList]);

  const openDisputeModal = (reviewId) => {
    disputeModalRef.current.open(reviewId);
  };

  return (
    <CardSection title="centreDetail.reviews">
      <div>
        {!loading && isEmpty(ratings) ? (
          <EmptyData />
        ) : (
          <div>
            {ratings.map((item, index) => (
              <CommentItem
                openDisputeModal={() => openDisputeModal(item?.id)}
                key={String(index)}
                item={item}
              />
            ))}
          </div>
        )}
        {loading && <Skeleton active avatar />}
        {enabledLoadMore && (
          <Button type="link" onClick={handleViewMoreReview}>
            {i18next.t('button.viewMoreReviews')}
          </Button>
        )}
      </div>

      <DisputeModal ref={disputeModalRef} />
    </CardSection>
  );
};

export default ReviewsSection;

import React, { useState, useImperativeHandle, forwardRef } from 'react';
import { getYoutubeEmbedURL } from 'utils/tools';
import renderHTML from 'react-render-html';
import { VirtualTourStyles } from 'containers/Centres/components/CentreTabs/SummaryTab/styles';
import i18next from 'i18next';
import PreviewModal from 'components/common/PreviewModal';
import { ModalContentPreviewStyles } from './styles';

interface Props {
  isShowVirtualTour?: boolean;
}
interface ImagePreviewState {
  url?: string;
  type?: string;
}

const ModalPreview = forwardRef(({ isShowVirtualTour }: Props, ref) => {
  const [visible, setVisible] = useState(false);

  const [imagePreview, setImagePreview] = useState<ImagePreviewState>({});

  useImperativeHandle(ref, () => ({
    toggleModal: (file) => {
      setVisible(true);
      setImagePreview(file);
    },
  }));

  const handleCancel = () => setVisible(false);

  const getImage = () => {
    if (isShowVirtualTour && imagePreview?.url) {
      return (
        <VirtualTourStyles>{renderHTML(imagePreview?.url)}</VirtualTourStyles>
      );
    }
    if (imagePreview?.type?.includes('video')) {
      const urlVideo = getYoutubeEmbedURL(imagePreview?.url);
      if (urlVideo) {
        return (
          <iframe
            className="iframe-video"
            frameBorder="0"
            allowFullScreen
            src={urlVideo}
            width="100%"
            height={500}
            title={urlVideo}
          />
        );
      }
      return (
        <video
          controls
          className="iframe-video"
          width="100%"
          height={500}
          src={imagePreview?.url}
        >
          <source src={imagePreview.url} type="video/mp4" />
          {i18next.t('error.notSupportVideo')}
          <track src="" kind="captions" srcLang="en" label="english_captions" />
        </video>
      );
    }
    return <img alt="kindi" className="w-full" src={imagePreview?.url} />;
  };

  return (
    <PreviewModal
      visible={visible}
      footer={null}
      onCancel={handleCancel}
      width={800}
    >
      <ModalContentPreviewStyles>{getImage()}</ModalContentPreviewStyles>
    </PreviewModal>
  );
});

export default ModalPreview;

import React, { useCallback, useEffect, useRef } from 'react';
import { Col, Empty, Row } from 'antd';
import PageTitle from 'components/common/PageTitle';
import i18next from 'i18next';
import { useLocation } from 'react-router-dom';
import { getFilterFromUrl, getSearchUrl } from 'utils/tools';
import { useHistory } from 'react-router';
import { getAdsList } from 'redux/advertisement/actions';
import { useDispatch, useSelector } from 'react-redux';
import {
  getAdsListLoadingSelector,
  getAdsListSelector,
} from 'redux/advertisement/selectors';
import CustomBreadcrumb from 'components/common/Breadcrumb';
import { getActiveOnAdsPermission } from 'redux/auth/selectors';
import { AdsResourceParams } from 'api/advertisement/type';
import ModuleAccessWarning from 'components/common/ModuleAccessWarning';
import CreateButton from 'components/common/CreateButton';
import Filter from '../components/Filter';
import AdvertisementList from '../components/AdvertisementList';
import {
  DEFAULT_CURRENT_PAGE,
  DEFAULT_SORT_ADVERTISEMENT,
  LIMIT_ITEM,
} from '../constants';
import useModifyAdsPermission from '../hooks/useModifyAdsPermission';

export interface RetrieveList {
  isRefresh?: boolean;
  keepFilter?: boolean;
  filter?: AdsResourceParams;
}

const List = () => {
  const dispatch = useDispatch();
  const { location, push } = useHistory();
  const { search } = useLocation();
  const { filter } = getFilterFromUrl(search);
  const filterRef = useRef(filter);
  const advertisement = useSelector(getAdsListSelector);
  const loading = useSelector(getAdsListLoadingSelector);
  const isActiveAds = useSelector(getActiveOnAdsPermission);
  const { canModifyAds } = useModifyAdsPermission();

  const customBreadcrumb = [
    {
      title: i18next.t('sideBar.marketing'),
      path: '#',
    },
    {
      title: i18next.t('advertisement.title'),
      path: '#',
    },
  ];

  const retrieveList = useCallback(
    ({ filter = {}, isRefresh, keepFilter = true }: RetrieveList) => {
      dispatch(
        getAdsList({
          data: {
            ...filter,
          },
          options: {
            keepFilter,
            isRefresh,
          },
        }),
      );
    },
    [dispatch],
  );

  useEffect(() => {
    retrieveList({
      filter: {
        sorts: filterRef.current.sorts || [DEFAULT_SORT_ADVERTISEMENT],
        page: filterRef.current.page || DEFAULT_CURRENT_PAGE,
        size: filterRef.current.size || LIMIT_ITEM,
        filter: filterRef.current.filter,
      },
      isRefresh: true,
      keepFilter: true,
    });
  }, [retrieveList]);

  const pushQuery = useCallback(
    (searchStr) => {
      push(`${location.pathname}?${getSearchUrl(searchStr)}`);
    },
    [location.pathname, push],
  );

  const handleRedirectCreate = () => {
    push({
      pathname: '/advertisement/create/start',
    });
  };

  return (
    <div>
      {isActiveAds ? (
        <>
          <CustomBreadcrumb data={customBreadcrumb} />

          <Row justify="space-between">
            <Col>
              <PageTitle>{i18next.t('advertisement.title')}</PageTitle>
            </Col>
            <Col>
              <CreateButton
                handleCreate={handleRedirectCreate}
                text={i18next.t('button.createAd')}
                isCheckCreatePermission={canModifyAds}
              />
            </Col>
          </Row>
          <Filter retrieveList={retrieveList} pushQuery={pushQuery} />
          {advertisement ? (
            <AdvertisementList
              advertisements={advertisement}
              loading={loading}
              pushQuery={pushQuery}
              retrieveList={retrieveList}
            />
          ) : (
            <Empty />
          )}
        </>
      ) : (
        <ModuleAccessWarning title={i18next.t('advertisement.title')} />
      )}
    </div>
  );
};

export default List;

import React from 'react';
import { Layout } from 'antd';
import i18next from 'i18next';
import {
  ExitButton,
  StepContent,
  StepHeader,
  StepSider,
  Wrapper,
} from './styles';

interface StepsCreateLayoutProps {
  SiderComponent: React.ReactNode;
  CustomHeader?: React.ReactNode;
  children: React.ReactNode;
  logo?: string;
  textHeaderAction?: string;
  backgroundSider?: string;
  onExit?: () => void;
}

const StepsCreateLayout = ({
  SiderComponent,
  CustomHeader,
  children,
  logo,
  backgroundSider,
  textHeaderAction = 'button.exit',
  onExit,
}: StepsCreateLayoutProps) => (
  <Wrapper className="step-create-wrapper">
    <StepSider
      breakpoint="lg"
      $backgroundSider={backgroundSider}
      className="steps-sider"
    >
      {SiderComponent}
    </StepSider>
    <Layout>
      <StepHeader>
        {CustomHeader || (
          <>
            <div className="text-center">
              <img src={logo} width={130} alt="kindiCare logo" />
            </div>
            <ExitButton type="text" className="text-primary" onClick={onExit}>
              {i18next.t(textHeaderAction)}
            </ExitButton>
          </>
        )}
      </StepHeader>
      <StepContent>{children}</StepContent>
    </Layout>
  </Wrapper>
);

export default StepsCreateLayout;

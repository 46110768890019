import React from 'react';
import { Button } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

interface Props {
  isShowIcon?: boolean;
  handleCreate: () => void;
  text: string;
  isCheckCreatePermission: boolean;
}

const CreateAdsButton = ({
  isShowIcon = true,
  handleCreate,
  text,
  isCheckCreatePermission,
}: Props) => (
  <div>
    <Button
      type="primary"
      className="t-bold"
      disabled={!isCheckCreatePermission}
      onClick={handleCreate}
      icon={isShowIcon ? <PlusOutlined /> : undefined}
    >
      {text}
    </Button>
  </div>
);

export default CreateAdsButton;

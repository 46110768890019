import React, { useState } from 'react';
import i18next from 'i18next';
import Footer from 'containers/Centres/components/CentreTabs/EducatorStaffProfilesTab/Create/Footer';
import { CurrentStepTitle } from 'containers/Centres/components/CentreTabs/EducatorStaffProfilesTab/Create/Steps/styles';
import { PlusCircleFilled } from '@ant-design/icons';
import { Form, notification } from 'antd';
import {
  useCreateStaffProfileMutation,
  useEditStaffProfilesMutation,
} from 'redux/@rtkQuery/educatorStaffProfile';
import { getPayloadStaffProfile } from 'redux/staffProfile/selector';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useHistory } from 'react-router';
import {
  resetStaffProfilePayload,
  setStaffProfilePayload,
} from 'redux/staffProfile/slice';
import { formatDate } from 'utils/tools';
import { isNumber, sortBy } from 'lodash-es';
import AwardsContainer from './AwardsContainer';

const Awards = () => {
  const [visible, setVisible] = useState(false);
  const [editIndexCurrent, setEditIndexCurrent] = useState(null);
  const [formModalStep4] = Form.useForm();
  const [createStaffProfile, { isLoading }] = useCreateStaffProfileMutation();
  const [editStaffProfile] = useEditStaffProfilesMutation();
  const staffProfilePayload = useSelector(getPayloadStaffProfile) || {};
  const { centreId } = useParams();
  const { push } = useHistory();
  const awards = staffProfilePayload?.awards || [];
  const dispatch = useDispatch();

  const openAddModal = () => {
    formModalStep4.resetFields();
    setVisible(true);
    setEditIndexCurrent(null);
  };

  const handlePublic = async () => {
    const [jobRole, jobRankId] =
      staffProfilePayload?.basicInfo?.jobRole?.split('-');
    const body = {
      ...staffProfilePayload,
      basicInfo: {
        ...staffProfilePayload?.basicInfo,
        jobRole,
        jobRankId: Number(jobRankId),
      },
      centreId,
      isDraft: false,
    };

    if (staffProfilePayload?.isDraft && staffProfilePayload.id) {
      await editStaffProfile({ ...body, currentStep: -1 });
    } else {
      await createStaffProfile(body);
    }
    dispatch(resetStaffProfilePayload());
    push(`/centres/${centreId}/show/educator-staff-profiles`);
  };

  const onSave = async () => {
    formModalStep4.submit();
    const values = await formModalStep4.validateFields();
    const formNewValue = { ...values, year: formatDate(values?.year, 'YYYY') };

    let payloadList = [...awards];

    if (isNumber(editIndexCurrent)) {
      if (payloadList[editIndexCurrent]?.year === formNewValue?.year) {
        payloadList[editIndexCurrent] = formNewValue;
      } else {
        payloadList.splice(editIndexCurrent, 1);
        payloadList = [...payloadList, formNewValue];
        payloadList = sortBy(payloadList, 'year').reverse();
      }
    } else {
      payloadList = [...payloadList, formNewValue];
      payloadList = sortBy(payloadList, 'year').reverse();
    }

    dispatch(setStaffProfilePayload({ awards: payloadList }));
    notification.success({
      message: i18next.t('success.title'),
      description: i18next.t('success.description'),
    });
    setVisible(false);
  };

  const onSaveAddAnother = () => {
    formModalStep4.validateFields().then((values) => {
      let payloadList = [
        ...awards,
        {
          ...values,
          year: formatDate(values.year, 'YYYY'),
        },
      ];

      payloadList = sortBy(payloadList, 'year').reverse();

      dispatch(
        setStaffProfilePayload({
          awards: payloadList,
        }),
      );
      notification.success({
        message: i18next.t('success.title'),
        description: i18next.t('success.description'),
      });
      formModalStep4.resetFields();
    });
  };

  return (
    <div>
      <div className="flex-center-between mb-24">
        <CurrentStepTitle>
          {i18next.t('educatorStaffProfile.awardsOptional')}
        </CurrentStepTitle>
        <PlusCircleFilled
          className="text-primary text-32"
          onClick={openAddModal}
        />
      </div>
      <AwardsContainer
        visible={visible}
        editIndexCurrent={editIndexCurrent}
        setVisible={setVisible}
        setEditIndexCurrent={setEditIndexCurrent}
        formModalStep4={formModalStep4}
        openAddModal={openAddModal}
        onSave={onSave}
        onSaveAddAnother={onSaveAddAnother}
      />
      <Footer onNext={handlePublic} isSubmitLoading={isLoading} />
    </div>
  );
};

export default Awards;

import React from 'react';
import i18next from 'i18next';
import PropTypes from 'prop-types';
import { getATagHref } from 'utils/tools';
import { Tooltip } from 'antd';
import { ABNIcon, ACNIcon, MousePointerIcon } from 'components/common/SVGIcon';
import { EnvironmentOutlined } from '@ant-design/icons';

const GeneralInfo = ({ record }) => (
  <div className="company-info">
    <div className="title-info mb-20">
      {i18next.t('jobs.companyProfile.generalInformation')}
    </div>
    <div className="content-info-other">
      <div className="item-info">
        <EnvironmentOutlined className="flex-start" />
        <Tooltip title={i18next.t('jobs.companyProfile.address')}>
          <span>{record?.address || i18next.t('error.waitingUpdate')}</span>
        </Tooltip>
      </div>
      <div className="item-info">
        <ACNIcon className="flex-start" />
        <Tooltip title={i18next.t('jobs.companyProfile.acnNumber')}>
          <span>{record?.australianCompanyNumber}</span>
        </Tooltip>
      </div>
      <div className="item-info">
        <ABNIcon className="flex-start" />
        <Tooltip title={i18next.t('jobs.companyProfile.abnNumber')}>
          <span>{record?.australianBusinessNumber}</span>
        </Tooltip>
      </div>
      <div className="item-info value-info-item">
        <MousePointerIcon className="flex-start" />
        <Tooltip title={i18next.t('jobs.companyProfile.website')}>
          <a
            href={getATagHref(record?.website)}
            target="_blank"
            rel="noopener noreferrer"
          >
            {record?.website || i18next.t('error.waitingUpdate')}
          </a>
        </Tooltip>
      </div>
    </div>
  </div>
);

GeneralInfo.propTypes = {
  record: PropTypes.object,
};

export default GeneralInfo;

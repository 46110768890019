import React, { useMemo } from 'react';
import i18next from 'i18next';
import PropTypes from 'prop-types';

import { Button } from 'antd';
import { ROLES_CONST } from 'configs/localData/permissions';
import { Link } from 'react-router-dom';

const CentresUserField = ({ data, handleClickViewAll, roleName }) => {
  const isAllCentre = useMemo(
    () =>
      [
        ROLES_CONST.companyAdmin,
        ROLES_CONST.companyStaff,
        ROLES_CONST.providerAdmin,
        ROLES_CONST.providerStaff,
      ].includes(roleName),
    [roleName],
  );

  return isAllCentre ? (
    <Button
      size="small"
      type="link"
      onClick={handleClickViewAll}
      className="mt-10 text-black"
    >
      {i18next.t('centres.allCentres')}
    </Button>
  ) : (
    <div>
      <Link
        to={`/centres/${data?.[0]?.id}/show`}
        className="d-block link-custom-primary centre-name"
      >
        {data?.[0]?.name}
      </Link>

      {data?.length > 1 && (
        <Button
          size="small"
          type="dashed"
          onClick={handleClickViewAll}
          className="mt-10"
        >
          {i18next.t('button.viewAll')}
        </Button>
      )}
    </div>
  );
};

CentresUserField.propTypes = {
  data: PropTypes.array,
  handleClickViewAll: PropTypes.func,
  roleName: PropTypes.string,
};

export default React.memo(CentresUserField);

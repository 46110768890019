import React from 'react';
import { useSelector } from 'react-redux';
import { getAllJobApplications } from 'redux/jobs/jobApplications/actions';
import { getJobApplicationStatus } from 'redux/config/selectors';
import PropTypes from 'prop-types';
import { getActiveOnJobModulePermission } from 'redux/auth/selectors';
import Board from './Board';

const BoardList = ({ isApplicationOfAJob, jobPostId, filterData }) => {
  const jobApplicationStatus = useSelector(getJobApplicationStatus);
  const activeOnJobModulePermission = useSelector(
    getActiveOnJobModulePermission,
  );

  return (
    <>
      {activeOnJobModulePermission && (
        <Board
          filterData={filterData}
          jobPostId={jobPostId}
          isApplicationOfAJob={isApplicationOfAJob}
          getAllDataAction={getAllJobApplications}
          applicationStatus={jobApplicationStatus}
          withScrollableColumns
          isDragDisabledColumn
        />
      )}
    </>
  );
};

BoardList.propTypes = {
  isApplicationOfAJob: PropTypes.bool,
  jobPostId: PropTypes.string,
  filterData: PropTypes.object,
};

export default BoardList;

import { createAsyncThunk } from '@reduxjs/toolkit';
import { getTransactionsSummaryApi } from 'api/transactions';
import { makeActions } from 'redux/crudCreator/actions';
import { apiWrapper } from 'utils/reduxUtils';

export const MODEL_NAME = 'transactions';
export const transactionsActions = makeActions(MODEL_NAME);

export const getAllTransactions = transactionsActions.getAll;
export const editTransactions = transactionsActions.edit;
export const createTransactions = transactionsActions.create;
export const getByIdTransactions = transactionsActions.getDataById;

export const getTransactionsSummary = createAsyncThunk(
  `${MODEL_NAME}/getSummary`,
  async (payload, thunkAPI) => {
    try {
      const response = await apiWrapper({}, getTransactionsSummaryApi);
      return { data: response };
    } catch (error) {
      return thunkAPI.rejectWithValue({ data: error });
    }
  },
);

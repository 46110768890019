import React, { useState } from 'react';
import CardSection from 'components/common/CardSection';
import { Col, Row } from 'antd';
import i18next from 'i18next';
import EducatorAndStaffDiscountsModal from 'containers/CompanyProfile/components/Modals/EducatorAndStaffDiscountsModal';
import useRolesCompanyProfile from 'containers/CompanyProfile/hooks/useRolesCompanyProfile';
import { useSelector } from 'react-redux';
import { getEducatorandStaffDiscountsSelector } from 'redux/jobs/companyProfile/selectors';

const DiscountsCard = () => {
  const educatorAndStaffDiscountsData = useSelector(
    getEducatorandStaffDiscountsSelector,
  );
  const { educatorDiscount, staffDiscount } = educatorAndStaffDiscountsData;
  const [visible, setVisible] = useState(false);
  const disabledAction = useRolesCompanyProfile();
  const onOpen = () => {
    setVisible(true);
  };

  const onCancel = () => {
    setVisible(false);
  };
  return (
    <div className="text-16">
      <CardSection
        onClickButton={onOpen}
        isEdit
        title="jobs.companyProfile.card.educatorAndStaffDiscount"
        disabled={disabledAction}
      >
        <Row className="mb-32">
          <Col span={12}>
            {i18next.t(
              'jobs.companyProfile.modal.educatorAndStaffDiscount.educatorDiscount',
            )}
          </Col>
          <Col span={12}>
            <strong>{educatorDiscount ? `${educatorDiscount}%` : ''}</strong>
          </Col>
        </Row>
        <Row className="mb-32">
          <Col span={12}>
            {i18next.t(
              'jobs.companyProfile.modal.educatorAndStaffDiscount.staffDiscount',
            )}
          </Col>
          <Col span={12}>
            <strong>{staffDiscount ? `${staffDiscount}%` : ''}</strong>
          </Col>
        </Row>
      </CardSection>
      <EducatorAndStaffDiscountsModal
        visible={visible}
        onCancel={onCancel}
        educatorDiscount={educatorDiscount}
        staffDiscount={staffDiscount}
      />
    </div>
  );
};

export default DiscountsCard;

import React from 'react';
import {
  StepTitleStyle,
  VerticalSteps,
} from 'containers/Centres/components/CentreTabs/EducatorStaffProfilesTab/Create/Steps/styles';
import { Steps } from 'antd';
import i18next from 'i18next';
import { CREATE_PROFILE_STEPS } from 'containers/Centres/components/CentreTabs/EducatorStaffProfilesTab/constants';

interface CreateProfileStepProps {
  currentStep?: number;
  onChange?: (current: number) => void;
}

const CreateProfileSteps = ({
  currentStep,
  onChange,
}: CreateProfileStepProps) => (
  <VerticalSteps direction="vertical" current={currentStep} onChange={onChange}>
    {CREATE_PROFILE_STEPS.map(({ title, required }, index) => (
      <Steps.Step
        title={
          <StepTitleStyle required={required}>
            {i18next.t(title)}
          </StepTitleStyle>
        }
        key={`create-profile-step-${index}`}
        className={'mb-30'}
      />
    ))}
  </VerticalSteps>
);

export default CreateProfileSteps;

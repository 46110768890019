import { createSlice } from '@reduxjs/toolkit';
import { getEULAData } from 'redux/legalDocuments/actions';

export const initialState = {
  EULA: {
    data: null,
    error: null,
    isLoading: false,
  },
};

const getEULADataPending = (state) => {
  state.EULA.isLoading = true;
};

const getEULADataFullfilled = (state, { payload }) => {
  state.EULA.isLoading = false;
  state.EULA.data = payload;
};

const getEULADataRejected = (state, { payload }) => {
  state.EULA.isLoading = false;
  state.EULA.error = payload;
};

const { reducer } = createSlice({
  name: 'EULA',
  initialState,
  extraReducers: {
    [getEULAData.pending]: getEULADataPending,
    [getEULAData.fulfilled]: getEULADataFullfilled,
    [getEULAData.rejected]: getEULADataRejected,
  },
});

export default reducer;

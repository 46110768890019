import { Col, Row } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { getJobTemplateConfigSelector } from 'redux/jobs/jobTemplates/selectors';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import i18next from 'i18next';
import { editJobTemplate } from 'redux/jobs/jobTemplates/actions';
import { useParams } from 'react-router';
import { remove } from 'lodash-es';
import TableToggle from '../../TableToggle';

const ImmunisationRequirementsTab = ({ currentData }) => {
  const dispatch = useDispatch();
  const { id } = useParams();

  const [activeImmunisations, setActiveImmunisations] = useState(
    currentData?.immunisations || [],
  );

  const immunisationsList = useSelector(
    getJobTemplateConfigSelector,
  )?.immunisations;

  const immunisationsData = immunisationsList?.map((immunisation) => ({
    ...immunisation,
    ...currentData?.immunisations?.find((e) => e?.id === immunisation?.id),
  }));

  useEffect(() => {
    if (currentData?.immunisations?.length > 0) {
      setActiveImmunisations(currentData?.immunisations);
    }
  }, [currentData.immunisations]);

  const updateImmunisation = useCallback(
    (immunisations) => {
      dispatch(
        editJobTemplate({
          id,
          data: {
            immunisations,
          },
        }),
      );
    },
    [dispatch, id],
  );

  const handleToggleOnCertification = (immunisations) => {
    const newImmunisation = [
      ...activeImmunisations,
      {
        ...immunisations,
        isPreferred: true,
        isRequired: false,
      },
    ];
    setActiveImmunisations(newImmunisation);
    updateImmunisation(newImmunisation);
  };

  const handleToggleOffCertification = (immunisation) => {
    const newImmunisation = remove(
      [...activeImmunisations],
      (i) => i?.id !== immunisation?.id,
    );
    setActiveImmunisations(newImmunisation);
    updateImmunisation(newImmunisation);
  };

  const onSwitchChangeImmunisations = (checked, immunisations) => {
    checked
      ? handleToggleOnCertification(immunisations)
      : handleToggleOffCertification(immunisations);
  };

  return (
    <Row wrap gutter={[24, 24]}>
      <Col xs={24} xl={12}>
        <TableToggle
          dataSource={immunisationsData}
          onSwitchChange={onSwitchChangeImmunisations}
          categoryName={i18next.t(
            'jobs.createJobTemplateStep.immunisationRequirements',
          )}
        />
      </Col>
    </Row>
  );
};

ImmunisationRequirementsTab.propTypes = {
  currentData: PropTypes.object,
};

export default ImmunisationRequirementsTab;

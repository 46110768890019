import { get } from 'lodash-es';

interface AppSetting {
  createdAt?: string;
  data?: object;
  description?: string;
  id?: string;
  isActive?: boolean;
  isCustomisable?: boolean;
  key?: string;
  lastValue?: any;
  name?: string;
  type?: string;
  updatedAt?: string;
  value?: any;
}

export const filterSelect = (value, record, key) => get(record, key) === value;

export const formatLabelTable = (key, appSettings) => {
  const { value, type }: AppSetting =
    appSettings?.find((item) => item?.key === key) || {};
  if (type === 'boolean') {
    return value?.toLowerCase().trim() === 'true';
  }
  return value || '';
};

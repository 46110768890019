import { Form } from 'antd';
import IdentityForm from 'containers/Advertisement/Create/Steps/Identity/IdentityForm';
import React, { forwardRef, useImperativeHandle, useState } from 'react';
import AdModal from '.';

interface IdentityModalProps {
  identityValues?: {
    isChildcareRelated?: boolean;
    isSocialRelated?: boolean;
  };
}

const IdentityModal = forwardRef(
  ({ identityValues }: IdentityModalProps, ref) => {
    const [form] = Form.useForm();
    const [visible, setVisible] = useState<boolean>(false);

    const handleCancel = () => {
      setVisible(false);
      form.resetFields();
    };

    useImperativeHandle(
      ref,
      () => ({
        toggleModal: () => {
          setVisible(true);
        },
      }),
      [],
    );

    return (
      <AdModal
        visible={visible}
        onCancel={handleCancel}
        title="advertisement.identityOptional"
        form={form}
      >
        <IdentityForm
          form={form}
          isShowFooter={false}
          identityValues={identityValues}
        />
      </AdModal>
    );
  },
);

export default IdentityModal;

import React, { useState } from 'react';
import i18next from 'i18next';
import { Button } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { useParams } from 'react-router';
import { useDispatch } from 'react-redux';
import { createActivity } from 'redux/applications/actions';
import ActivityModal from './Modal';

const CreateActivityButton = () => {
  const { id } = useParams();
  const [visible, setVisible] = useState(false);
  const dispatch = useDispatch();

  const toggleModal = () => {
    setVisible(!visible);
  };

  const onOk = (value) => dispatch(createActivity({ id, data: value }));

  return (
    <>
      <Button
        className="add-button"
        onClick={toggleModal}
        icon={<PlusOutlined />}
      >
        {i18next.t('button.create')}
      </Button>
      <ActivityModal
        visible={visible}
        onOk={onOk}
        onCancel={toggleModal}
        toggleModal={toggleModal}
        okText="button.create"
      />
    </>
  );
};

export default CreateActivityButton;

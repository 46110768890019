import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { setCurrentDataAutomatedResponse } from 'redux/automatedResponses/slice';
import {
  updateAutomatedResponses,
  updateAutomatedResponsesAllCentres,
} from 'redux/automatedResponses/actions';
import i18next from 'i18next';
import SwitchAction from 'components/RestActions/SwitchAction';
import { getByIdCentres } from 'redux/centres/actions';
import { CRM_PACKAGES } from 'configs/localData';

const AutomatedItem = ({ item, centreId }) => {
  const [loading, setLoading] = useState();
  const dispatch = useDispatch();

  const packageName = useSelector(
    (state) => state.auth?.lowestCentreLevelCRMPackage,
  );

  const automatedKey = useSelector(
    (state) => state.automatedResponses.currentData.key,
  );

  const actionHandle = useMemo(
    () =>
      centreId ? updateAutomatedResponses : updateAutomatedResponsesAllCentres,
    [centreId],
  );

  const handleUpdate = (checked) => {
    setLoading(true);
    return dispatch(
      actionHandle({
        centreId,
        id: item.id,
        key: item.key,
        data: { isActive: checked },
      }),
    ).finally(() => {
      centreId &&
        dispatch(
          getByIdCentres({
            data: { id: centreId },
            options: { isRequestApi: true },
          }),
        );
      setLoading(false);
    });
  };

  const onSelectedItem = () => {
    if (item.key && automatedKey !== item.id) {
      dispatch(setCurrentDataAutomatedResponse(item));
    }
  };

  const handleChange = (checked, e) => {
    e.stopPropagation();
    if (centreId) {
      handleUpdate(checked);
    } else {
      Modal.confirm({
        title: i18next.t('automatedResponses.header'),
        content: i18next.t('automatedResponses.confirmToggleAllCentre'),
        okText: i18next.t('text.yes'),
        okCancel: i18next.t('text.no'),
        onOk() {
          return handleUpdate(checked);
        },
      });
    }
  };

  return (
    <div className="w-full">
      <div
        className={`automated-item flex-center-between w-full ${
          automatedKey === item.key ? 'automated-item-selected' : ''
        }`}
        onClick={onSelectedItem}
        role="presentation"
      >
        <span className="automated-item__name">{item.name || item.key}</span>
        <SwitchAction
          packageName={packageName}
          checked={item.isActive}
          onChange={handleChange}
          loading={loading}
          permissionKey="automatedResponses"
          action="isEdit"
        />
      </div>
      <div className="mx-20 mb-4">
        {!centreId &&
          !item?.isActive &&
          (packageName === CRM_PACKAGES.KINDICARE_PREMIUM ||
            packageName === CRM_PACKAGES.KINDICARE_ENTERPRISE) && (
            <span className="txt-message">
              {i18next.t('automatedResponses.messagesApplySwitchAllCenters')}
            </span>
          )}
      </div>
    </div>
  );
};

AutomatedItem.propTypes = {
  item: PropTypes.object,
  centreId: PropTypes.string,
};

export default AutomatedItem;

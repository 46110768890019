import React from 'react';
import { Row, Col } from 'antd';
import { useSelector } from 'react-redux';
import { SearchOutlined } from '@ant-design/icons';
import { PREFIX_URL } from 'configs/localData/constant';

import RestInputItem from 'components/RestInput/RestInputItem';
import RestSelect from 'components/RestInput/RestSelect';
import ReferenceInputWrapper from 'containers/rest/ReferenceInput';

const CentreFilterForm = () => {
  const centreTypes = useSelector((state) => state.config.data?.CentreType);

  const stateOfAustralia = useSelector(
    (state) => state.config.data?.stateOfAustralia,
  );

  return (
    <Row gutter={16}>
      <Col xl={6} lg={6} md={12} xs={12}>
        <ReferenceInputWrapper
          prefixUrl={PREFIX_URL.admin}
          resource="users/me/providers/basic-info"
          source="providerId"
          searchKey="name"
          notLikeFilter
          initialFilter={{ orderBy: 'name' }}
        >
          <RestSelect
            titleProp="name"
            valueProp="id"
            placeholder="input.provider.placeholder"
            isFilterOption={false}
          />
        </ReferenceInputWrapper>
      </Col>

      <Col xl={6} lg={6} md={12} xs={12}>
        <RestInputItem
          source="cityOrPostCodeOrName"
          placeholder="users.centreFilterQuery"
          isShowTooltip
          inputProps={{ prefix: <SearchOutlined /> }}
        />
      </Col>

      <Col xl={6} lg={6} md={12} xs={12}>
        <RestSelect
          resourceData={centreTypes || []}
          header="centres.centreServiceType"
          source="type"
          placeholder="centres.centreServiceType"
          isShowTooltip
        />
      </Col>
      <Col xl={6} lg={6} md={12} xs={12}>
        <RestSelect
          source="state"
          header="centres.state"
          placeholder="centres.state"
          resourceData={stateOfAustralia || []}
          isShowTooltip
        />
      </Col>
    </Row>
  );
};

export default CentreFilterForm;

import { Col, Row, Typography } from 'antd';
import RestInputItem from 'components/RestInput/RestInputItem';
import React from 'react';
import i18next from 'i18next';
import RestSelect from 'components/RestInput/RestSelect';
import { useSelector } from 'react-redux';
import { validateRegex } from 'utils/validateUtils';
import { CompanyFormStyles } from './styles';

const CompanyProfileInfo = () => {
  const companyType = useSelector((state) => state?.config?.data?.companyType);

  return (
    <CompanyFormStyles>
      <Typography.Title level={4}>
        {i18next.t('jobs.companyProfile.modal.generalInfo.companyInformation')}
      </Typography.Title>
      <Row gutter={[40]}>
        <Col span={12}>
          <RestInputItem
            disabled
            source="name"
            header="jobs.companyProfile.companyName"
            placeholder="jobs.companyProfile.companyName"
            isValidatorEmpty
          />
        </Col>
        <Col span={12}>
          <RestSelect
            header="jobs.companyProfile.companyType"
            source="typeId"
            resourceData={companyType || []}
            valueProp="id"
            titleProp="name"
            disabled
            placeholder=""
          />
        </Col>
        <Col span={12}>
          <RestInputItem
            disabled
            source="australianCompanyNumber"
            header="jobs.companyProfile.acnNumber"
            isValidatorEmpty
          />
        </Col>
        <Col span={12}>
          <RestInputItem
            disabled
            source="australianBusinessNumber"
            header="jobs.companyProfile.abnNumber"
            isValidatorEmpty
          />
        </Col>
        <Col span={24}>
          <RestInputItem
            source="website"
            header="jobs.companyProfile.website"
            placeholder="jobs.companyProfile.website"
            isValidatorEmpty
            rules={[
              {
                pattern: validateRegex.website,
                message: i18next.t('input.website.validateMsg.invalid'),
              },
            ]}
          />
        </Col>
      </Row>
    </CompanyFormStyles>
  );
};

export default CompanyProfileInfo;

import { LGAActions as LGA } from './LGA/actions';
import { brandsActions as brands } from './brands/actions';
import { regionsActions as regions } from './regions/actions';
import { contactsActions as contacts } from './contacts/actions';
import { transactionsActions as transactions } from './transactions/actions';
import { applicationsActions as applications } from './applications/actions';
import { centreServicesActions as centreServices } from './centreServices/actions';
import { enquiriesActions as enquiries } from './enquiries/actions';
import { centresActions as centres } from './centres/actions';
import { usersActions as users } from './users/actions';
import { customersActions as customers } from './customers/actions';
import { rolesActions as roles } from './roles/actions';
import { subscriptionsActions as subscriptions } from './subscriptions/actions';
import { notificationsActions as notifications } from './notifications/actions';
import { notificationsAndAlertsActions as notificationsAndAlerts } from './notificationsAndAlerts/actions';
import { priceBenchmarkingActions as priceBenchmarking } from './priceBenchmarking/actions';

export default {
  brands,
  LGA,
  regions,
  contacts,
  subscriptions,
  transactions,
  customers,
  applications,
  centreServices,
  enquiries,
  centres,
  users,
  roles,
  notifications,
  notificationsAndAlerts,
  priceBenchmarking,
};

import styled from 'styled-components';

export const GroupWrapper = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  button {
    margin-right: 5px;
  }
  .iconSetting {
    font-size: 20px;
    cursor: pointer;
  }
`;

export const ContentStyles = styled.div`
  background: #fff;
  background-clip: padding-box;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  padding: 5px;
  & > div:not(:last-child) {
    margin-bottom: 10px;
  }
`;

import React from 'react';
import { Form, FormInstance } from 'antd';
import { RestInputContext } from 'components/RestInput/RestInputContext';
import i18next from 'i18next';
import RestUploadFile from 'components/RestInput/RestUploadFile';
import { AdsType } from 'api/advertisement/type';
import { getPreSignedAdsUrl } from 'api/uploadMedia';
import { useParams } from 'react-router-dom';

interface UploadProps {
  accept?: string;
  placeholder?: string;
  maxFileSize?: number;
  replaceConfirmMessage?: string;
}

const AdsMediaUpload: React.FC<{
  form: FormInstance;
  initialImages: { type: string; url: string }[];
  adType: AdsType;
}> = ({ form, initialImages, adType }) => {
  const { id } = useParams();

  const getUploadPlaceholder = (isIgnoreVideoAccept?: boolean): UploadProps => {
    if (isIgnoreVideoAccept) {
      return {
        placeholder:
          'Upload up to 6 photos in JPG, JPEG or PNG format (1280 x 720 px) and a video in MP4 and MOV format (Max size 25 MB)',
        accept: 'image/jpg, image/jpeg, image/png',
        maxFileSize: adType.maxImagesNumber,
        replaceConfirmMessage: i18next.t('popup.replaceMultipleFilesConfirm'),
      };
    }
    switch (adType.id) {
      case '1':
        return {
          placeholder:
            'Upload a photo\n File types: JPG, JPEG or PNG (1280 x 720 px)',
          accept: 'image/jpg, image/jpeg, image/png',
          maxFileSize: adType.maxImagesNumber,
          replaceConfirmMessage: i18next.t('popup.replaceFileConfirm', {
            fileType: 'image',
          }),
        };
      case '2':
        return {
          placeholder: 'Upload a video\n File types: MP4, MOV (Max size 25 MB)',
          accept: 'video/mov, video/mp4, video/quicktime',
          maxFileSize: adType.maxVideosNumber,
          replaceConfirmMessage: i18next.t('popup.replaceFileConfirm', {
            fileType: 'video',
          }),
        };
      default:
        return {
          placeholder:
            'Upload up to 6 photos in JPG, JPEG or PNG format (1280 x 720 px) and a video in MP4 and MOV format (Max size 25 MB)',
          accept:
            'image/jpg, image/jpeg, image/png, video/mov, video/mp4, video/quicktime',
          maxFileSize: adType.maxImagesNumber + adType.maxVideosNumber,
          replaceConfirmMessage: i18next.t('popup.replaceMultipleFilesConfirm'),
        };
    }
  };

  const uploadRules = () => [
    ({ getFieldValue }) => ({
      validator() {
        return new Promise<void>((resolve, reject) => {
          const { length } = getFieldValue('mediaList');
          if (adType.id === '3' && length > 0 && length < 2) {
            return reject(new Error(i18next.t('error.errorAtLeast2Files')));
          }
          return resolve();
        });
      },
    }),
  ];

  return (
    <RestInputContext.Provider
      value={{
        form,
      }}
    >
      <Form.Item shouldUpdate>
        {({ getFieldValue }) => {
          const _isIgnoreVideoAccept =
            adType.id === '3' &&
            getFieldValue('mediaList')?.filter((i) => i.type?.includes('video'))
              .length >= adType.maxVideosNumber;
          return (
            <RestUploadFile
              placeholder={getUploadPlaceholder().placeholder}
              youtubeLinkLabel={'Or Youtube link'}
              youtubeLinkPlaceholder={'Enter Youtube link'}
              source="mediaList"
              record={{ mediaList: initialImages }}
              required
              onlyShowImg
              isAttachURLVideo={adType.id !== '1'}
              isDrapDrop
              itemScale={9 / 16}
              accept={getUploadPlaceholder(_isIgnoreVideoAccept).accept}
              isNoneBorderRadius
              isDisableLabel
              maxCount={getUploadPlaceholder().maxFileSize}
              limitVideoNumbers={adType.maxVideosNumber}
              isPreSignUrl
              getCustomPreSignedUrl={async (fileName) => {
                const response = await getPreSignedAdsUrl(fileName, id);
                return response;
              }}
              isShowConfirm
              replaceConfirmMessage={
                getUploadPlaceholder().replaceConfirmMessage
              }
              rules={uploadRules()}
            />
          );
        }}
      </Form.Item>
    </RestInputContext.Provider>
  );
};

export default AdsMediaUpload;

import React from 'react';
import { Row, Col } from 'antd';
import { BLOCK_USER_TYPES } from 'configs/localData';
import i18next from 'i18next';

import RestInputItem from 'components/RestInput/RestInputItem';
import RestSelect from 'components/RestInput/RestSelect';
import useRolesData from '../../hooks/useRolesData';

const Filter = () => {
  const rolesData = useRolesData();
  return (
    <div>
      <Row gutter={16}>
        <Col lg={6} md={12} xs={12}>
          <RestInputItem
            source={['outsideFilter', 'fullName']}
            placeholder="users.name"
            isShowTooltip
          />
        </Col>
        <Col lg={6} md={12} xs={12}>
          <RestInputItem
            source={['outsideFilter', 'email']}
            placeholder="users.email"
            isShowTooltip
          />
        </Col>
        <Col lg={6} md={12} xs={12}>
          <RestSelect
            source="roleId"
            placeholder="users.role"
            resourceData={rolesData}
            valueProp="id"
            titleProp="description"
            isShowTooltip
          />
        </Col>
        <Col lg={6} md={12} xs={12}>
          <RestSelect
            source="isBlocked"
            placeholder="users.blocked"
            resourceData={BLOCK_USER_TYPES}
            valueProp="value"
            titleProp="text"
            formatText={(text) => i18next.t(text)}
            isShowTooltip
          />
        </Col>
      </Row>
    </div>
  );
};

export default Filter;

import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'antd';
import CustomerInfo from '../CustomersInfo';
import CustomerDetailStyles from './styles';
import ApplicationsList from '../../../Applications/List';

const CustomerDetail = ({ customerId, record }) => (
  <CustomerDetailStyles>
    <Row gutter={24} className="row-customer-detail">
      <Col xl={6} lg={6} md={24} xs={24}>
        <CustomerInfo record={record} />
      </Col>
      <Col xl={18} lg={18} md={24} xs={24}>
        <ApplicationsList isCustomer customerId={customerId} />
      </Col>
    </Row>
  </CustomerDetailStyles>
);

CustomerDetail.propTypes = {
  record: PropTypes.object,
  customerId: PropTypes.string,
};

export default CustomerDetail;

import { Spin } from 'antd';
import React from 'react';
import {
  DEFAULT_CURRENT_PAGE,
  DEFAULT_SORT_INDIVIDUAL,
  DEFAULT_SORT_JOB_TEMPLATE,
  LIMIT_ITEM,
} from 'containers/JobListings/constants';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import { Waypoint } from 'react-waypoint';
import { getJobListingViewByJobTemplate } from 'redux/jobs/jobListings/selectors';
import { getFilterFromUrl, getSearchUrl } from 'utils/tools';
import JobTemplateCard from './JobTemplateCard';
import { JobList } from './styles';

const JobTemplateBoard = ({ jobTemplateData, loading, retrieveList }) => {
  const { push } = useHistory();
  const { search } = useLocation();
  const { filter } = getFilterFromUrl(search);
  const { resourceData } = useSelector(getJobListingViewByJobTemplate) || {};

  const handleEnterWaypoint = () => {
    const restFilter = {
      page: resourceData.page ? resourceData.page + 1 : DEFAULT_CURRENT_PAGE,
      size: LIMIT_ITEM,
      sorts: [DEFAULT_SORT_JOB_TEMPLATE],
      filter: { ...filter.filter },
    };

    if (resourceData.total / resourceData.page >= LIMIT_ITEM && !loading) {
      retrieveList({
        filter: restFilter,
        isRefresh: false,
        keepFilter: false,
      });
    }
  };

  return (
    <>
      <JobList>
        {jobTemplateData?.map((item) => (
          <JobTemplateCard
            data={item}
            handleRedirectDetail={() => {
              push(
                `/job-listings/individual/list?${getSearchUrl({
                  page: DEFAULT_CURRENT_PAGE,
                  sorts: [DEFAULT_SORT_INDIVIDUAL],
                  filter: {
                    templateName: item?.templateName,
                    templateId: item?.id,
                  },
                })}`,
              );
            }}
          />
        ))}
      </JobList>

      {loading && (
        <div className="flex justify-center pt-40">
          <Spin />
        </div>
      )}
      <Waypoint onEnter={handleEnterWaypoint} />
    </>
  );
};
export default JobTemplateBoard;

JobTemplateBoard.propTypes = {
  jobTemplateData: PropTypes.array,
  loading: PropTypes.bool,
  retrieveList: PropTypes.func,
};

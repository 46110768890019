import React from 'react';
import { Modal, Select } from 'antd';
import i18next from 'i18next';
import PropTypes from 'prop-types';
import { APPLICATION_STATUS } from 'configs/localData';
import { ArrowDownIcon } from 'components/common/SVGIcon';
import { DropDownWrapper } from './styles';

const { Option } = Select;

const getItemStatus = (status) =>
  APPLICATION_STATUS.find((item) => item.value === status);

const getStatusText = (status) => {
  const statusItem = getItemStatus(status);
  return statusItem?.text ? i18next.t(statusItem.text) : status;
};

const DropdownStatus = ({ status, handleUpdateStatus, child, disabled }) => {
  const getObjConfirm = (currentStatus, newStatus) => ({
    childName: `${child.firstName || ''} ${child.lastName || ''}`,
    beforeStatus: getStatusText(currentStatus),
    endStatus: getStatusText(newStatus),
  });

  const handleChange = (value) => {
    Modal.confirm({
      title: i18next.t('applications.confirmTitle'),
      content: i18next.t(
        'applications.confirmStatus',
        getObjConfirm(status, value),
      ),
      onOk: () => {
        handleUpdateStatus(value);
      },
    });
  };

  return (
    <DropDownWrapper
      value={status}
      style={{ width: '100%' }}
      onChange={handleChange}
      suffixIcon={<ArrowDownIcon style={{ pointerEvents: 'none' }} />}
      dropdownClassName="dropdown-status"
      disabled={disabled}
    >
      {APPLICATION_STATUS.map((item) => (
        <Option key={item.value} value={item.value}>
          <div>
            <span
              className="span-option-status"
              style={{
                color: item.color,
                background: item.backgroundColor,
              }}
            >
              {i18next.t(item.text)}
            </span>
          </div>
        </Option>
      ))}
    </DropDownWrapper>
  );
};

DropdownStatus.propTypes = {
  status: PropTypes.string,
  handleUpdateStatus: PropTypes.func,
  child: PropTypes.object,
  disabled: PropTypes.bool,
};

DropdownStatus.defaultProps = {
  handleUpdateStatus: () => null,
  child: {},
};

export default DropdownStatus;

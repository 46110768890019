import React, { useRef } from 'react';
import CardSection from 'components/common/CardSection';
import DiamondPoint from 'components/common/DiamondPoint';
import PropTypes from 'prop-types';
import JobHighlightsBenefitsModal from '../../Modals/JobHighlightsBenefitsModal';

const JobHighlightsBenefits = ({ benefits, disabledAction }) => {
  const jobHighlightsBenefitsRef = useRef();

  const openJobHighlightsBenefitsModal = () => {
    jobHighlightsBenefitsRef?.current &&
      jobHighlightsBenefitsRef.current.toggleModal();
  };
  return (
    <>
      <CardSection
        isEdit
        onClickButton={openJobHighlightsBenefitsModal}
        title="jobs.moreAboutTheJob.jobHighLightsAndBenefits"
        action="isEdit"
        disabled={disabledAction}
      >
        <div>
          {benefits?.map((item, index) => (
            <div className="flex" key={String(index)}>
              <div className="mt-12">
                <DiamondPoint />
              </div>
              <div className="ml-12 leading-loose text-16 break-all">
                {item}
                <p className="mt-10" />
              </div>
            </div>
          ))}
        </div>
      </CardSection>
      <JobHighlightsBenefitsModal
        ref={jobHighlightsBenefitsRef}
        record={benefits}
      />
    </>
  );
};

JobHighlightsBenefits.propTypes = {
  benefits: PropTypes.any,
  disabledAction: PropTypes.bool,
};

export default JobHighlightsBenefits;

import React from 'react';
import { PriceCardStyled } from './styles';
import FeatureItem from '../FeatureItem';
import RecommendsTag from '../RecommendsTag';

const PriceBlock = ({ priceDetail, subscriptionItem, onClickSignup }) => {
  const cardProps = {
    mainColor: priceDetail.mainColor,
    isRecommend: priceDetail.isRecommend,
    secondColor: priceDetail.secondColor,
  };
  return (
    <PriceCardStyled
      {...cardProps}
      title={
        <div className="subscription-header">
          <h1 className="subscription-title">{priceDetail.title}</h1>
          <span className="subscription-price">
            {priceDetail.unit === '' ? '' : <sup>$</sup>}
            {priceDetail.price}
          </span>
          <span className="subscription-unit">{priceDetail.unit}</span>
        </div>
      }
    >
      <h4 className="accessible-title">{priceDetail.accessibleTitle}</h4>
      <FeatureItem
        priceDetail={priceDetail}
        subscriptionItem={subscriptionItem}
        onClickSignup={onClickSignup}
      />
      {priceDetail.isRecommend && <RecommendsTag style={undefined} />}
    </PriceCardStyled>
  );
};

export default PriceBlock;

import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  delCompaniesOfUserApi,
  getCompaniesOfUserApi,
  postCompaniesOfUserApi,
} from 'api/user';
import { getAllCustom } from 'redux/crudCreatorCustom/actions';
import { apiWrapper } from 'utils/reduxUtils';
import { getOwnedCompaniesOfAllUserRolesApi } from 'api/companies';

export const MODEL_NAME = 'companiesOfUser';

export const getCompaniesOfUser = getAllCustom(
  MODEL_NAME,
  getCompaniesOfUserApi,
);

export const postCompaniesOfUser = createAsyncThunk(
  'companiesOfUser/postCompaniesOfUser',
  async (payload = {}, thunkApi) => {
    try {
      const { id, data = {}, options } = payload;

      const response = await apiWrapper({}, postCompaniesOfUserApi, id, data);

      return { data: response, options, success: true };
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  },
);

export const delCompaniesOfUser = createAsyncThunk(
  'companiesOfUser/delCompaniesOfUser',
  async (payload = {}, thunkApi) => {
    try {
      const { id, companyId, options = {} } = payload;

      const { total, offset, limit } =
        thunkApi.getState().companiesOfUser?.resourceData || {};

      await apiWrapper({}, delCompaniesOfUserApi, id, { companyId });

      thunkApi.dispatch(
        getCompaniesOfUser({
          id,
          data: {
            offset: offset > 0 && total % offset <= 1 ? offset - limit : offset,
            orderBy: 'name',
          },
          options: { isRefresh: true, keepFilter: true },
        }),
      );

      return { companyId, options };
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  },
);

export const getOwnedCompaniesOfAllUserRoles = createAsyncThunk(
  `${MODEL_NAME}/companiesOfAllUserRoles`,
  async (payload, thunkAPI) => {
    try {
      const response = await apiWrapper(
        {},
        getOwnedCompaniesOfAllUserRolesApi,
        payload,
      );
      return { data: response };
    } catch (error) {
      return thunkAPI.rejectWithValue({ data: error });
    }
  },
);

import React from 'react';
import BrandBenchmarkingContainer from 'containers/BrandBenchmarking';
import { getInsightsPermission } from 'redux/auth/selectors';
import { Result } from 'antd';
import i18next from 'i18next';
import PageTitle from 'components/common/PageTitle';

import { useSelector } from 'react-redux';

const BrandBenchmarking = () => {
  const insightsPermission = useSelector(getInsightsPermission);

  return !insightsPermission ? (
    <div>
      <PageTitle>
        {`${i18next.t('sideBar.insights.title')} - ${i18next.t(
          'sideBar.insights.brandBenchmarking',
        )}`}
      </PageTitle>
      <Result
        status="warning"
        title={
          <>
            {`${i18next.t('insights.accessWarning')} ${i18next.t(
              'sideBar.insights.title',
            )} - ${i18next.t('sideBar.insights.brandBenchmarking')}.`}
            <br />
            {i18next.t('insights.pleaseContact')}
            <a
              rel="noopener noreferrer"
              target="_blank"
              style={{ color: '#459EFF', textDecoration: 'unset' }}
              href="https://kindicare.com/contact-us/"
            >
              {i18next.t('insights.customerCare')}
            </a>
            {i18next.t('insights.forMoreHelp')}
          </>
        }
      />
    </div>
  ) : (
    <BrandBenchmarkingContainer />
  );
};

export default BrandBenchmarking;

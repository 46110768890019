import styled from 'styled-components';
import { Layout } from 'antd';

const PrivateLayoutWrapper = styled(Layout)`
  min-height: 100vh;

  .mainView {
    height: 100vh;
    margin-left: 72px;
    overflow: hidden;
    transition: all 0.3s ease 0s;
    background: ${({ theme }) => theme.background.container};
  }

  .main-container {
    overflow-y: auto;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }

  .ant-anchor-wrapper {
    margin-left: 0px;
    padding-left: 0px;
  }

  .mainWithoutSidebar {
    margin-left: 0px;

    .content {
      padding: 0px;
    }

    .ant-layout {
      height: 100%;
    }
  }

  .content {
    padding: ${({ theme }) => `${theme.contentWrapper.paddingNumber}px`};
    flex: 1;
    position: relative;
  }

  .trigger {
    font-size: 20px;
    padding: 5px;
    cursor: pointer;
    transition: color 0.2s;
    margin-right: 10px;

    &:hover {
      color: ${({ theme }) => theme.palette.primary};
    }

    @media only screen and (max-width: 640px) {
      color: ${({ theme }) => theme.palette.primary};
    }
  }

  .triggerMobile {
    font-size: 20px;
    line-height: 64px;
    cursor: pointer;
    color: ${({ theme }) => theme.palette.primary};
    transition: color 0.2s;
    position: fixed;
    top: 0px;
    left: 20px;
    z-index: 2;
    display: none;

    &:hover {
      color: ${({ theme }) => theme.palette.primary};
    }

    @media only screen and (max-width: 640px) {
      display: block;
    }
  }

  .logo {
    height: 80px;
    display: flex;
    align-items: center;
    position: relative;
    justify-content: center;

    img {
      height: 40px;
      width: auto;
      object-fit: contain;
    }

    .fullLogo {
      width: 100%;
      padding: 0 15px;
      position: absolute;
      opacity: 0;
      transition: all 0.2s;
    }
  }

  .header {
    z-index: 1;
    background: ${({ theme }) => theme.background.container};
    padding: 0 24px;
    display: flex;
    justify-content: space-between;
    transition: all 0.5s ease 0.2s;
    @media only screen and (max-width: 640px) {
      display: inherit;
    }

    .title {
      display: none;
      opacity: 0;
      transition: opacity 0.2s;
      text-align: center;
      @media only screen and (max-width: 640px) {
        opacity: 1;
        display: inline-block;
        padding-left: 20px;
        font-size: 20px;
        font-weight: 500;
        width: 100%;
      }
    }

    & .ant-avatar-lg.ant-avatar-icon {
      .anticon {
        font-size: 24px;
      }
    }
  }

  .mainContent {
    padding: 20px;
    background: #fff;
    min-height: 280px;
  }

  #collapsedTracker {
    width: 0px;
    height: 0px;
    position: absolute;
  }

  #collapsedTracker:checked ~ .sidebar,
  #collapsedTracker:checked ~ .sider-wrapper {
    .logo {
      .fullLogo {
        opacity: 1;
      }
    }
  }

  #collapsedTracker:checked ~ .mainView {
    margin-left: 260px !important;
  }

  @media only screen and (max-width: 640px) {
    .sider-wrapper-true {
      aside {
        display: none;
      }
    }

    .sidebar {
      position: fixed;
      z-index: 9999;
      height: 100vh;
    }

    .mainView {
      margin-left: 0px;
      z-index: 1;
    }

    .overlay {
      z-index: 9998;
      position: fixed;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      opacity: 0;
      pointer-events: none;
      background: rgba(0, 0, 0, 0.5);
      transition: all 0.5s ease 0s;
    }

    #collapsedTracker:checked ~ .sidebar {
      left: 0px;
    }

    #collapsedTracker:checked ~ .mainView {
      margin-left: 0px !important;
      z-index: 1;
    }

    #collapsedTracker:checked ~ .overlay {
      opacity: 1;
      pointer-events: auto;
    }
  }

  * {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  div::-webkit-scrollbar-thumb {
    border-radius: 3px !important;
    background: ${({ theme }) => theme.scrollbar.thumb};
  }

  div::-webkit-scrollbar-track {
    background: ${({ theme }) => theme.scrollbar.track};
  }

  div::-webkit-scrollbar-thumb:hover {
    border-radius: 3px !important;
    background: ${({ theme }) => theme.scrollbar.thumb};
  }

  div::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    background: ${({ theme }) => theme.scrollbar.track};
  }

  .ant-table-tbody {
    background: ${({ theme }) => theme.background.content};
  }
`;

export default PrivateLayoutWrapper;

export const WarningEnvironmentStyles = styled.div`
  color: #ffffff;
  padding: 8px 20px;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
`;

export const ImpersonationStyles = styled.div`
  background: #feefef;
  color: #e62828;
  padding: 5px 20px;
  font-weight: bold;

  .close-btn {
    color: #e62828;
  }
`;

import styled from 'styled-components';
import { getBackgroundColorApplicationList } from './utils';

export const DropZone = styled.div`
  min-height: 50px;
`;

export const TitleWrapper = styled.h4`
  padding: 8px;
  transition: background-color ease 0.2s;
  flex-grow: 1;
  user-select: none;
  position: relative;

  &:focus {
    outline: 2px solid #998dd9;
    outline-offset: 2px;
  }
`;

export const Wrapper = styled.div`
  margin-bottom: 0;
  min-height: 0;

  background-color: ${(props) =>
    getBackgroundColorApplicationList(
      props.isDraggingOver,
      props.isDraggingFrom,
    )};
  display: flex;
  flex-direction: column;
  opacity: ${({ isDropDisabled }) => (isDropDisabled ? 0.5 : 'inherit')};
  transition: background-color 0.2s ease, opacity 0.1s ease;
  user-select: none;
  width: 100%;
`;

export const ScrollContainer = styled.div`
  overflow-x: hidden;
  overflow-y: auto;
`;

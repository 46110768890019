import React from 'react';
import PropTypes from 'prop-types';

const SkillContent = ({ item }) => (
  <div className="text-16 pb-20 flex flex-col	justify-between">
    <strong>{item?.name}</strong>
    <p className="text-gray-500">
      <span>
        {item?.type}
        <span className="text-10 ml-10 mr-10 text-gray-500">&#9679;</span>
        {item?.level}
      </span>
    </p>
  </div>
);

SkillContent.propTypes = {
  item: PropTypes.object,
};

export default SkillContent;

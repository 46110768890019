import { createSlice } from '@reduxjs/toolkit';
import { PROFILE_STEP } from 'containers/Centres/components/CentreTabs/EducatorStaffProfilesTab/constants';
import { StaffProfileModel } from 'types/staffProfiles';

interface InitialState {
  currentStep: number;
  staffProfilePayload: StaffProfileModel;
}

const initialState: InitialState = {
  currentStep: PROFILE_STEP.BASIC_INFORMATION,
  staffProfilePayload: {},
};

const staffProfile = createSlice({
  name: 'staffProfile',
  initialState,
  reducers: {
    goToStep: (state, { payload: step }) => {
      if (step >= 0 && step <= PROFILE_STEP.AWARDS) {
        state.currentStep = step;
      }
    },
    goToNextStep: (state) => {
      const newStep = state.currentStep + 1;
      if (newStep > 0 && newStep <= PROFILE_STEP.AWARDS) {
        state.currentStep = newStep;
      }
    },
    goToPrevStep: (state) => {
      const newStep = state.currentStep - 1;
      if (newStep >= 0 && newStep <= PROFILE_STEP.AWARDS) {
        state.currentStep = newStep;
      }
    },
    setStaffProfilePayload: (state, { payload }) => {
      state.staffProfilePayload = {
        ...state.staffProfilePayload,
        ...payload,
      };
    },
    resetStaffProfilePayload: (state) => {
      state.currentStep = PROFILE_STEP.BASIC_INFORMATION;
      state.staffProfilePayload = initialState.staffProfilePayload;
    },
  },
});

export const {
  goToStep,
  goToNextStep,
  goToPrevStep,
  setStaffProfilePayload,
  resetStaffProfilePayload,
} = staffProfile.actions;

export default staffProfile.reducer;

import React, { useCallback } from 'react';
import i18next from 'i18next';
import TableCustom from 'components/common/TableCustom';
import DeleteButton from 'components/RestActions/DeleteButton';
import {
  DownloadIcon,
  NoDataIcon,
  RefreshIcon,
} from 'components/common/SVGIcon';
import {
  useDeleteExportLibraryMutation,
  useGetExportLibraryQuery,
} from 'redux/@rtkQuery/marketingMetris';
import { useLocation } from 'react-router-dom';
import { getFilterFromUrl, getSearchUrl, isRequestFailed } from 'utils/tools';
import {
  DELIVERY_STATUS,
  ORDER_BY_TYPE,
  SORT_ASCEND_VALUE,
  SORT_DESCEND_VALUE,
} from 'configs/localData/constant';
import { useHistory } from 'react-router';
import { Modal, Typography, notification, Tooltip, Button } from 'antd';
import {
  DEFAULT_CURRENT_PAGE,
  DEFAULT_SORT_EXPORT_LIBRARY,
} from 'containers/MarketingMetrics/constants';
import { isEmpty } from 'lodash-es';
import { DownloadBtnStyle, RefreshButton } from './style';
import useFormatFunc from './useFormat';

const { confirm } = Modal;
const { Text } = Typography;

const ExportLibrary = () => {
  const LIMIT_ITEM = 10;
  const useFormat = useFormatFunc();
  const { search } = useLocation();
  const { filter } = getFilterFromUrl(search);
  const { location, push } = useHistory();
  const { data, isLoading, refetch, isFetching } =
    useGetExportLibraryQuery(filter);
  const [deleteExportLibrary] = useDeleteExportLibraryMutation();

  const handleDownload = (url) => {
    url && window.open(url);
  };

  const onDelete = async (id) => {
    const res = await deleteExportLibrary(id);
    if (isRequestFailed(res)) {
      notification.error({
        message: i18next.t('error.title'),
        description: i18next.t('error.description'),
      });
    } else {
      notification.success({
        message: i18next.t('success.title'),
        description: i18next.t('success.description'),
      });
      refetch();
    }
  };

  const handleDelete = (records) => {
    confirm({
      title: i18next.t('resources.deleteFile'),
      content: i18next.t('resources.deleteMessage'),
      okText: i18next.t('button.delete'),
      cancelText: i18next.t('button.cancel'),
      onOk: () => {
        onDelete(records?.id);
      },
    });
  };

  const getSorterOrder = useCallback(
    (source) => {
      if (filter.sorts?.[0] === source) return SORT_ASCEND_VALUE;
      if (filter.sorts?.[0] === `-${source}`) return SORT_DESCEND_VALUE;
      return '';
    },
    [filter],
  );

  const columns = [
    {
      title: i18next.t('resources.exportFile'),
      dataIndex: 'name',
      key: 'name',
      width: 420,
      render: (value, record) =>
        useFormat.formatViewTitle(value, record?.status, record?.url),
    },
    {
      title: i18next.t('resources.type'),
      dataIndex: 'type',
      key: 'type',
      sorter: true,
      defaultSortOrder: getSorterOrder('type'),
    },
    {
      title: i18next.t('resources.status'),
      dataIndex: 'status',
      key: 'status',
      sorter: true,
      render: (_, record) => useFormat.StatusTag(record?.statusLayout),
      defaultSortOrder: getSorterOrder('status'),
    },
    {
      title: i18next.t('resources.exportTime'),
      dataIndex: 'exportTime',
      key: 'exportTime',
      sorter: true,
      render: (value) => useFormat.formatAvailableStart(value),
      defaultSortOrder: getSorterOrder('exportTime'),
    },
    {
      dataIndex: 'id',
      fixed: 'right',
      width: 150,
      render: (_, record) => (
        <div className="flex items-center text-24">
          {record?.status !== DELIVERY_STATUS.Running && (
            <>
              <Tooltip title={i18next.t('button.download')}>
                <DownloadBtnStyle
                  className="cursor-pointer mr-10"
                  onClick={() => handleDownload(record?.url)}
                  icon={<DownloadIcon className="text-gray-button" />}
                  type="link"
                />
              </Tooltip>
              <DeleteButton
                action="isView"
                record={record}
                onClickCustom={() => {
                  handleDelete(record);
                }}
              />
            </>
          )}
        </div>
      ),
    },
  ];

  const pushQuery = useCallback(
    (searchStr) => {
      push(`${location.pathname}?${getSearchUrl(searchStr)}`);
    },
    [location.pathname, push],
  );

  const onChange = (e, _, sorter) => {
    let sortKey;
    let sortOrder;
    if (sorter && sorter.field && sorter.order) {
      sortKey = sorter.field || DEFAULT_SORT_EXPORT_LIBRARY;
      sortOrder =
        sorter.order === SORT_ASCEND_VALUE
          ? ORDER_BY_TYPE.ASC
          : ORDER_BY_TYPE.DESC;
    }
    const restFilter = {
      size: e.pageSize,
      page: e.current,
      sortKey: sortKey || DEFAULT_SORT_EXPORT_LIBRARY,
      sortOrder: sortOrder || ORDER_BY_TYPE.DESC,
      filter: { ...filter.filter },
    };
    pushQuery({
      ...restFilter,
    });
    refetch();
  };

  return (
    <div>
      <div className="relative">
        {!isEmpty(data?.results) && (
          <RefreshButton className="absolute right-0 bottom-5">
            <Button
              onClick={refetch}
              icon={<RefreshIcon />}
              type="primary"
              ghost
            >
              <Text className="fw-bold text-14 text-primary">
                {i18next.t('resources.refresh')}
              </Text>
            </Button>
          </RefreshButton>
        )}
      </div>

      {isEmpty(data?.results) ? (
        <div className="flex-direction-column flex-center mt-70">
          <NoDataIcon />
          <Text className="fw-bold text-18">
            {i18next.t('resources.noFiles')}
          </Text>
          <Text className="text-gray-500">
            {i18next.t('resources.noFilesDescription')}
          </Text>
        </div>
      ) : (
        <TableCustom
          columns={columns}
          loading={isLoading || isFetching}
          data={data?.results}
          xScroll={undefined}
          isSorter={false}
          isSetRowKeyId={undefined}
          pagination={{
            pageSize: filter?.size || LIMIT_ITEM,
            current: filter?.page || DEFAULT_CURRENT_PAGE,
            total: data?.paging?.total || 0,
          }}
          onChange={onChange}
          isResetStyle={undefined}
          className={undefined}
        />
      )}
    </div>
  );
};

export default ExportLibrary;

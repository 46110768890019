import React from 'react';
import { BellOutlined, HomeOutlined, UserOutlined } from '@ant-design/icons';
import { Badge } from 'antd';
import { Link, useLocation } from 'react-router-dom';
import FooterDefault from './FooterDefault';
import { FooterStyles } from './styles';

const MENU_MOBILE = [
  {
    key: 'home',
    text: 'Home',
    url: '/',
    icon: HomeOutlined,
  },
  {
    key: 'notifications',
    text: 'Notifications',
    url: '/notifications',
    icon: BellOutlined,
  },
  {
    key: 'profile',
    text: 'Profile',
    url: '/profile',
    icon: UserOutlined,
  },
];

const FooterLayout = () => {
  const location = useLocation();

  return (
    <FooterStyles>
      <FooterDefault />
      <div className="footer-mobile">
        {MENU_MOBILE.map((tab) => (
          <Link
            to={tab.url}
            key={tab.key}
            className={tab.url === location.pathname ? 'active' : 'not-active'}
          >
            {tab.count ? (
              <Badge count={tab.count}>
                <tab.icon className="footer-mobile-icon" />
              </Badge>
            ) : (
              <tab.icon className="footer-mobile-icon" />
            )}
          </Link>
        ))}
      </div>
    </FooterStyles>
  );
};

export default FooterLayout;

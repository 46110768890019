import { Col, Row, Skeleton } from 'antd';
import EmptyData from 'components/common/EmptyData';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAllResources } from 'redux/resources/actions';
import { uniqWith } from 'lodash-es';
import { CRM_PACKAGES } from 'configs/localData';
import { getCentreId, getCentresOfUser } from 'redux/auth/selectors';
import { formatToLowercaseOriginal } from 'utils/textUtils';
import {
  getResourcesDataSelector,
  getResourcesLoadingSelector,
} from 'redux/resources/selectors';
import { useTranslation } from 'react-i18next';
import ResourceCardItem from './ResourceCardItem';
import awards2022Data from './_award2022Data.json';
import awards2023Data from './_award2023Data.json';

const Resources = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const myCentres = useSelector(getCentresOfUser);
  const KCAwards2021 = useSelector(getResourcesDataSelector);
  const loading = useSelector(getResourcesLoadingSelector);
  const centreId = useSelector(getCentreId);

  const awardsData = [...awards2022Data, ...awards2023Data];

  const centresAward = awardsData.filter((awardCentre) =>
    myCentres.some((centre) => {
      const centreSubscriptionName =
        centre?.centreSubscription?.CRMPackage?.name;
      if (centreId) {
        return (
          formatToLowercaseOriginal(awardCentre?.name) ===
            formatToLowercaseOriginal(centre?.name) &&
          centreSubscriptionName !== CRM_PACKAGES.KINDICARE_BASIC &&
          centre?.id === centreId
        );
      }
      return (
        formatToLowercaseOriginal(awardCentre?.name) ===
          formatToLowercaseOriginal(centre?.name) &&
        centreSubscriptionName !== CRM_PACKAGES.KINDICARE_BASIC
      );
    }),
  );

  const uniqueAwardCertificates = uniqWith(
    centresAward,
    function (arrVal, othVal) {
      return (
        arrVal.awardCertificate === othVal.awardCertificate &&
        arrVal.year === othVal.year
      );
    },
  );

  const KCAwards = uniqueAwardCertificates?.map((item) => {
    const year = item?.year;
    if (item?.awardCertificate === 'Outstanding') {
      return {
        name: t('resources.badgeOutstandingCentre', {
          year,
        }),
        value: `https://files.kindicare.com/uploads/static/assets/images/awards/${year}/outstanding.png`,
        year,
      };
    }
    return {
      name: t('resources.badgeExcellentCentre', {
        year,
      }),
      value: `https://files.kindicare.com/uploads/static/assets/images/awards/${year}/excellent.png`,
      year,
    };
  });

  const data = KCAwards2021.concat(KCAwards);

  useEffect(() => {
    dispatch(getAllResources());
  }, [dispatch]);

  return (
    <Skeleton loading={loading} active>
      <Row gutter={[20, 20]}>
        {data?.length ? (
          data?.map((item, idx) => (
            <React.Fragment key={String(idx)}>
              <Col xxl={6} xl={8} lg={12} md={12} xs={24}>
                <ResourceCardItem item={item} />
              </Col>
            </React.Fragment>
          ))
        ) : (
          <EmptyData />
        )}
      </Row>
    </Skeleton>
  );
};

export default Resources;

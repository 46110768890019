import i18next from 'i18next';
import { compact } from 'lodash-es';

export const summaryTitle = (
  pathname,
  primaryChildCareServices,
  nationalStates,
  ownedCompanies,
  filter,
  brandOfCompany,
) => {
  const primaryChildcareSelectedName =
    primaryChildCareServices?.find(
      (item) => item?.id === filter?.primaryChildCareServiceId,
    )?.name ||
    i18next.t('companyBenchmarking.filterForm.allPrimaryChildCareService');

  const stateSelected =
    nationalStates?.find((item) => item === filter?.state) ||
    i18next.t('companyBenchmarking.filterForm.allStates');

  const companySelected = ownedCompanies?.find(
    (item) => item?.id === filter?.companyId,
  )?.name;

  return compact([
    companySelected,
    brandOfCompany,
    primaryChildcareSelectedName,
    stateSelected,
  ]).join(', ');
};

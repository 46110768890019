import React, { useCallback } from 'react';
import { formatMoney } from 'utils/tools';

const useFormat = ({ keyTotal, formatValueTooltip, isShowLabelLine }) => {
  const formatValue = useCallback(
    (value, item) => {
      if (formatValueTooltip)
        return formatValueTooltip(value, item.payload[keyTotal]);
      return item?.payload?.[keyTotal]
        ? `${formatMoney(item.payload[keyTotal])} (${value?.toFixed(2)}%)`
        : `${value?.toFixed(2)}%`;
    },
    [keyTotal, formatValueTooltip],
  );

  const formatLabel = useCallback(
    (data) => {
      const { cx, cy, midAngle, outerRadius, value, fill } = data;

      if (!value) return '';

      if (!isShowLabelLine) return `${value}%`;

      const RADIAN = Math.PI / 180;
      const sin = Math.sin(-RADIAN * midAngle);
      const cos = Math.cos(-RADIAN * midAngle);
      const mx = cx + (outerRadius + 30) * cos;
      const my = cy + (outerRadius + 30) * sin;
      const ex = mx + (cos >= 0 ? 1 : -1) * 22;
      const ey = my;
      const textAnchor = cos >= 0 ? 'start' : 'end';
      return (
        <text
          x={ex + (cos >= 0 ? 1 : -1) * 12}
          y={ey}
          textAnchor={textAnchor}
          fill={fill}
        >
          {`${value}%`}
        </text>
      );
    },
    [isShowLabelLine],
  );

  const formatLineLabel = (data) => {
    const { cx, cy, midAngle, outerRadius, fill, stroke, value } = data;

    if (!value) return '';

    const RADIAN = Math.PI / 180;

    const sin = Math.sin(-RADIAN * midAngle);
    const cos = Math.cos(-RADIAN * midAngle);
    const sx = cx + (outerRadius + 10) * cos;
    const sy = cy + (outerRadius + 10) * sin;
    const mx = cx + (outerRadius + 30) * cos;
    const my = cy + (outerRadius + 30) * sin;
    const ex = mx + (cos >= 0 ? 1 : -1) * 22;
    const ey = my;

    return (
      <path
        d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`}
        stroke={stroke}
        fill={fill}
      />
    );
  };

  return {
    formatValue,
    formatLabel,
    formatLineLabel,
  };
};

export default useFormat;

import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import GoogleMapReact from 'google-map-react';
import { isEmpty } from 'lodash-es';
import axios from 'axios';
import MarkerIcon from 'components/GoogleMap/MarkerIcon';
import { LocationIcon } from 'components/common/SVGIcon';
import { getFilterFromUrl } from 'utils/tools';
import { useLocation } from 'react-router';
import { nanoid } from '@reduxjs/toolkit';
import MarketDefault from 'components/GoogleMap/MarketDefault';
import KindiCareWatermark from 'components/KindiCareWatermark';
import { MapMultiMarketStyles } from './styles';

const defaultGeoAU = {
  lat: -25.274398,
  lng: 133.775136,
};

const MapMultiMarket = ({
  data,
  height = '400px',
  actions,
  ContentComponentPopover,
  allAverageData,
}) => {
  const [geoLocation, setGeoLocation] = useState(defaultGeoAU);
  const { search } = useLocation();
  const outsideFilter = getFilterFromUrl(search);
  const storedPointer = useRef({});

  const createMapOptions = () => ({
    fullscreenControl: true,
    gestureHandling: 'cooperative',
  });

  const handleRenderWatermark = (map, maps) => {
    const watermarkComponent = document.getElementById('watermark-container');
    const position = maps.ControlPosition.RIGHT_TOP;
    map.controls[position].push(watermarkComponent);
  };

  useEffect(() => {
    if (data?.length) {
      if (isEmpty(data[0]?.geolocation) && data[0]?.displayAddress) {
        axios(
          `https://maps.googleapis.com/maps/api/geocode/json?address=${data[0]?.displayAddress}&key=${process.env.REACT_APP_GOOGLE_MAP_API_KEY}`,
        ).then((res) =>
          setGeoLocation({
            lat: res?.data?.results?.[0]?.geometry.location.lat,
            lng: res?.data?.results?.[0]?.geometry.location.lng,
          }),
        );
      } else {
        setGeoLocation({
          lat: data[0]?.geolocation?.latitude || '',
          lng: data[0]?.geolocation?.longitude || '',
        });

        if (outsideFilter?.filter?.displayAddress) {
          setGeoLocation({
            lat: outsideFilter?.filter?.latitude || '',
            lng: outsideFilter?.filter?.longitude || '',
          });

          const addressLocation = {
            id: nanoid(),
            isMatchedAddress: true,
            displayAddress: outsideFilter?.filter?.displayAddress,
            geolocation: {
              latitude: outsideFilter?.filter?.latitude,
              longitude: outsideFilter?.filter?.longitude,
            },
          };
          data.unshift(addressLocation);
        }
      }
    }
  }, [
    data,
    outsideFilter.filter.displayAddress,
    outsideFilter.filter.latitude,
    outsideFilter.filter.longitude,
  ]);

  return (
    <MapMultiMarketStyles
      className="map-multi-market-wrapper"
      style={{ width: '100%' }}
    >
      <div id="watermark-container">
        <KindiCareWatermark />
      </div>
      <div className="map-multi-market" style={{ height }}>
        <GoogleMapReact
          bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_MAP_API_KEY }}
          center={geoLocation}
          defaultZoom={13}
          options={createMapOptions}
          yesIWantToUseGoogleMapApiInternals
          onGoogleApiLoaded={({ map, maps }) =>
            handleRenderWatermark(map, maps)
          }
        >
          {(() => {
            if (data?.length) {
              return data.map((item, index) => {
                const condition =
                  Number(item?.averageAllAgeGroup) >
                  Number(allAverageData?.averageAllAgeGroup);
                const color = condition ? '#E62828' : '#36BF57';
                const backgroundColor = condition ? '#FEEFEF' : '#EDF9F0';
                const borderColor = condition ? '#e6282866' : '#36bf5766';
                const hasSelectedCentre =
                  outsideFilter?.filter?.centreId === item?.id;
                const colorStyles = {
                  color,
                  backgroundColor,
                  borderColor,
                };
                if (item.isMatchedAddress) {
                  storedPointer.current = {
                    latitude: item?.geolocation?.latitude,
                    longitude: item?.geolocation?.longitude,
                  };
                }
                if (index > 0) {
                  if (
                    storedPointer.current.latitude ===
                      item?.geolocation?.latitude &&
                    storedPointer.current.longitude ===
                      item?.geolocation?.longitude
                  ) {
                    return (
                      <MarkerIcon
                        key={item?.id}
                        lat={item?.geolocation?.latitude}
                        lng={item?.geolocation?.longitude}
                        item={item}
                        ContentComponentPopover={ContentComponentPopover}
                        allAverageData={allAverageData}
                        Icon={LocationIcon}
                        hasSelectedCentre={hasSelectedCentre}
                        colorStyles={colorStyles}
                        equalLocation
                      />
                    );
                  }
                }
                return (
                  <MarkerIcon
                    key={item?.id}
                    lat={item?.geolocation?.latitude}
                    lng={item?.geolocation?.longitude}
                    item={item}
                    ContentComponentPopover={ContentComponentPopover}
                    allAverageData={allAverageData}
                    Icon={LocationIcon}
                    hasSelectedCentre={hasSelectedCentre}
                    colorStyles={colorStyles}
                  />
                );
              });
            }
            return '';
          })()}
        </GoogleMapReact>
      </div>
      {actions}
    </MapMultiMarketStyles>
  );
};

MapMultiMarket.propTypes = {
  data: PropTypes.array,
  height: PropTypes.string,
  actions: PropTypes.any,
  ContentComponentPopover: PropTypes.any,
  allAverageData: PropTypes.object,
};

MarketDefault.propTypes = {
  Icon: PropTypes.any,
  displayAddress: PropTypes.string,
  equalLocation: PropTypes.bool,
};

export default MapMultiMarket;

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import ModalCustom from 'components/common/ModalCustom';
import i18next from 'i18next';
import FormNormalStyles from 'containers/Centres/components/Modals/styles';
import { Form } from 'antd';
import { RestInputContext } from 'components/RestInput/RestInputContext';
import { editJobTemplate } from 'redux/jobs/jobTemplates/actions';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';

const JobTemplateModal = ({
  initialValues,
  onCancel,
  title,
  children,
  record,
  ...props
}) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const { id } = useParams();

  const handleCancel = () => {
    onCancel();
    form.resetFields();
  };

  const onOk = () => {
    form.validateFields().then((values) => {
      setLoading(true);
      dispatch(
        editJobTemplate({
          id,
          data: {
            ...values,
            discount: values?.discount
              ? {
                  ...values?.discount,
                  typeId: values?.discount?.typeId,
                  value: values?.discount?.value ?? 0,
                }
              : undefined,
            experience: values?.experience
              ? {
                  id: values?.experience,
                  isPreferred: record?.experience?.isPreferred ?? true,
                  isRequired: record?.experience?.isRequired ?? false,
                }
              : undefined,

            languages: values?.languages?.map((id) => ({
              id,
              isPreferred:
                record?.languages?.find((item) => item.id === id)
                  ?.isPreferred ?? true,
              isRequired:
                record?.languages?.find((item) => item.id === id)?.isRequired ??
                false,
            })),

            qualification: values?.qualification
              ? {
                  id: values?.qualification,
                  isPreferred: record?.qualification?.isPreferred ?? true,
                  isRequired: record?.qualification?.isRequired ?? false,
                }
              : undefined,

            eligibility: values?.eligibility
              ? {
                  ids: values?.eligibility,
                  isPreferred: record?.eligibility?.isPreferred ?? true,
                  isRequired: record?.eligibility?.isRequired ?? false,
                }
              : undefined,
          },
        }),
      )
        .then(({ payload }) => {
          if (payload?.data?.result?.id) {
            handleCancel();
          }
        })
        .finally(() => {
          setLoading(false);
        });
    });
  };

  useEffect(() => {
    form.setFieldsValue({
      ...record,
      languages: record?.languages?.map((item) => item?.id),
      eligibility: record?.eligibility?.ids,
      jobType: record?.jobType?.id,
      jobRole: record?.jobRole?.id,
      experience: record?.experience?.id,
      qualification: record?.qualification?.id,
      discount: {
        typeId: record?.discount?.type?.id,
        value: record?.discount?.value,
      },
      salary: {
        payTypeId: record?.salary?.payType?.id,
        payRateId: record?.salary?.payRate?.id,
        ...record?.salary,
      },
    });
  }, [record, form]);

  return (
    <ModalCustom
      title={i18next.t(title)}
      onCancel={handleCancel}
      onOk={onOk}
      loading={loading}
      {...props}
    >
      <FormNormalStyles>
        <Form
          form={form}
          layout="vertical"
          scrollToFirstError
          initialValues={initialValues}
        >
          <RestInputContext.Provider value={{ form, record }}>
            {children}
          </RestInputContext.Provider>
        </Form>
      </FormNormalStyles>
    </ModalCustom>
  );
};

JobTemplateModal.propTypes = {
  title: PropTypes.string,
  record: PropTypes.object,
  onCancel: PropTypes.func,
  children: PropTypes.node,
  initialValues: PropTypes.object,
};

export default JobTemplateModal;

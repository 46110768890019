import i18next from 'i18next';
import React from 'react';
import PropTypes from 'prop-types';
import { EMAIL_CONTACT } from 'configs/localData/constant';

import AuthContactStyles from './styles';

interface Props {
  text: string;
  className: string;
}

const AuthContact = ({ text, className }: Props) => (
  <AuthContactStyles className={`${className} auth-contact`}>
    <div>
      <span>{i18next.t('auth.contactText.l0')} </span>
      {''}
      {i18next.t(text)}
      <a className="auth-contact__link" href={`mailto:${EMAIL_CONTACT}`}>
        {i18next.t('auth.contactText.l2')}
      </a>
    </div>
  </AuthContactStyles>
);

AuthContact.propTypes = {
  text: PropTypes.string,
  className: PropTypes.string,
};

export default AuthContact;

import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'antd';
import {
  enableOneCentreWhenMultiCentreNotificationAndAlert,
  enableOneNotificationAndAlert,
} from 'redux/notificationsAndAlerts/actions';
import { useDispatch } from 'react-redux';
import i18next from 'i18next';
import { ENABLE_TYPES_CONST } from 'configs/localData/constant';
import SwitchAction from 'components/RestActions/SwitchAction';

const { confirm } = Modal;

const SwitchOneCentre = ({ item, isSelectedCentre, keyNotify }) => {
  const [loading, setLoading] = useState();
  const dispatch = useDispatch();

  const actionSwitch = useMemo(
    () =>
      isSelectedCentre
        ? enableOneNotificationAndAlert
        : enableOneCentreWhenMultiCentreNotificationAndAlert,
    [isSelectedCentre],
  );

  const handleEnable = async (checked) => {
    setLoading(true);

    return Promise.all([
      new Promise((resolve) => setTimeout(resolve, 600)),
      dispatch(
        actionSwitch({
          key: keyNotify || item.key,
          data: {
            key: keyNotify || item.key,
            centreIds: [item.centre?.id],
            status: checked
              ? ENABLE_TYPES_CONST.active.value
              : ENABLE_TYPES_CONST.inactive.value,
          },
        }),
      ),
    ]).finally(() => {
      setLoading(false);
    });
  };

  const showConfirm = (checked) => {
    confirm({
      title: i18next.t('notificationsAndAlerts.title'),
      content: i18next.t(
        checked
          ? 'notificationsAndAlerts.enableOneCentreConfirmDesc'
          : 'notificationsAndAlerts.disableOneCentreConfirmDesc',
      ),
      onOk() {
        return handleEnable(checked);
      },
    });
  };

  return (
    <SwitchAction
      checked={item.data?.status === ENABLE_TYPES_CONST.active.value}
      onChange={showConfirm}
      loading={loading}
      permissionKey="myCentres"
      action="isEdit"
    />
  );
};

SwitchOneCentre.propTypes = {
  item: PropTypes.object,
  isSelectedCentre: PropTypes.bool,
  keyNotify: PropTypes.string,
};

export default SwitchOneCentre;

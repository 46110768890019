import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'antd';

import { useHistory } from 'react-router';
import { convertSearchUrl } from 'utils/tools';
import { omit } from 'lodash-es';
import ViewModeSwitcher from 'components/common/ViewModeSwitcher';
import FilterCustom from '../components/FilterCustom';

const HeaderFilter = ({ activeTab, resourceFilter }) => {
  const { push } = useHistory();

  const onChange = (e) => {
    const filter = omit(resourceFilter, [
      'limit',
      'offset',
      'outsideFilter.beginLetter',
    ]);
    push({
      pathname: `/contacts/${e.target.value}`,
      search: `?${convertSearchUrl(filter)}`,
    });
  };

  return (
    <div>
      <Row gutter={24}>
        <Col xxl={4} xl={4} lg={6} md={24} xs={24}>
          <ViewModeSwitcher onChange={onChange} activeTab={activeTab} />
        </Col>
        <Col xxl={20} xl={20} lg={18} md={24} xs={24}>
          <FilterCustom resourceFilter={resourceFilter} />
        </Col>
      </Row>
    </div>
  );
};

HeaderFilter.propTypes = {
  activeTab: PropTypes.string,
  resourceFilter: PropTypes.object,
};

HeaderFilter.defaultProps = {};

export default HeaderFilter;

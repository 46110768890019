import React, { ReactNode, useState } from 'react';
import { FormInstance } from 'antd';
import i18next from 'i18next';

import ModalCustom from 'components/common/ModalCustom';
import { editAd, getAdDetail } from 'redux/advertisement/actions';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';

interface AdModalProp {
  onSave?: () => void;
  onCancel: () => void;
  title: string;
  children: ReactNode;
  visible: boolean;
  form: FormInstance;
  width?: number;
  hasConvert?: boolean;
}

interface AdModalData {
  id?: string | number;
}

const AdModal = ({
  onSave,
  onCancel,
  title,
  children,
  visible,
  form,
  width,
  hasConvert,
}: AdModalProp) => {
  const dispatch = useDispatch();
  const { id }: AdModalData = useParams();
  const [loading, setLoading] = useState(false);

  const handleSave = () => {
    onSave && onSave();
    form
      .validateFields()
      .then(async (values) => {
        let payload = { ...values };
        if (hasConvert) {
          const { from, to, endTime, fromTime } = values;
          if (from && to) {
            from.hour(fromTime.get('hour')).minute(fromTime.get('minute'));
            to.hour(endTime.get('hour')).minute(endTime.get('minute'));
          }
          payload = {
            duration: {
              from,
              to,
            },
          };
        }
        setLoading(true);
        await dispatch(
          editAd({
            adsPostId: id,
            ...payload,
          }),
        );
        dispatch(getAdDetail(id));
        handleCancel();
      })
      .finally(() => setLoading(false));
  };

  const handleCancel = () => {
    onCancel();
    form.resetFields();
  };

  return (
    <ModalCustom
      visible={visible}
      title={i18next.t(title)}
      onCancel={handleCancel}
      onOk={handleSave}
      loading={loading}
      width={width}
    >
      {children}
    </ModalCustom>
  );
};

export default AdModal;

import React from 'react';
import PropTypes from 'prop-types';
import { Progress } from 'antd';
import { StarResultIcon } from 'components/common/SVGIcon';
import { LISTING_STEP } from 'configs/localData/constant';

const ProgressStepDone = ({ centreStep }) => {
  const stepDone = centreStep.filter((item) => item?.percent === 100).length;

  return (
    <div className="flex items-center ">
      <Progress
        showInfo={false}
        className="w-full mb-8"
        steps={LISTING_STEP}
        percent={(stepDone * 100) / LISTING_STEP}
      />
      <StarResultIcon className="icon-star" />
    </div>
  );
};

ProgressStepDone.propTypes = {
  centreStep: PropTypes.array,
};

export default ProgressStepDone;

import styled from 'styled-components';
import { Tooltip, Typography } from 'antd';

export const SummaryCardStyles = styled.div`
  background: #fff;
  padding: 16px;
  border-radius: 16px;
`;

export const SharePercent = styled(Tooltip)`
  color: #32a4fc;
`;

export const SummaryPercent = styled(Typography.Text)`
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  margin: 0px !important;
`;

import i18next from 'i18next';
import moment from 'moment';
import { forEach } from 'lodash-es';
import { KEYS_MON_TO_FRI } from 'configs/localData';

export const FORMAT = 'HH:mm';

export const formatHour = (time) =>
  time ? moment.utc(time).format(FORMAT) : i18next.t('error.waitingUpdate');

export const formatHourEdit = (time) => (time ? moment.utc(time) : null);

export const responsiveColHour = {
  xl: 8,
  lg: 12,
  md: 12,
  xs: 24,
};

const formatValuesExceptWeekend = (valueObj, key) => {
  switch (key) {
    case 'monToFri_morning_schoolTerm':
      return {
        mon_morning_schoolTerm: valueObj,
        tue_morning_schoolTerm: valueObj,
        wed_morning_schoolTerm: valueObj,
        thu_morning_schoolTerm: valueObj,
        fri_morning_schoolTerm: valueObj,
      };
    case 'monToFri_afternoon_schoolTerm':
      return {
        mon_afternoon_schoolTerm: valueObj,
        tue_afternoon_schoolTerm: valueObj,
        wed_afternoon_schoolTerm: valueObj,
        thu_afternoon_schoolTerm: valueObj,
        fri_afternoon_schoolTerm: valueObj,
      };
    case 'monToFri_normal':
      return {
        mon_normal: valueObj,
        tue_normal: valueObj,
        wed_normal: valueObj,
        thu_normal: valueObj,
        fri_normal: valueObj,
      };
    case 'monToFri_holiday':
      return {
        mon_holiday: valueObj,
        tue_holiday: valueObj,
        wed_holiday: valueObj,
        thu_holiday: valueObj,
        fri_holiday: valueObj,
      };

    default:
      return {};
  }
};

export const handleFormatOpenTime = ({ openTime, oldOpenTime }) => {
  let openTimeObj = {};

  forEach(openTime, (item, key) => {
    const restObject = {
      ...item,
      start: item.start
        ? moment.utc(item.start.format(FORMAT), FORMAT).valueOf()
        : null,
      end: item.end
        ? moment.utc(item.end.format(FORMAT), FORMAT).valueOf()
        : null,
    }; // format utc để hiển thị giá trị default trả về đúng

    if (KEYS_MON_TO_FRI.includes(key)) {
      openTimeObj = {
        ...openTimeObj,
        ...formatValuesExceptWeekend(restObject, key),
      };
    } else {
      openTimeObj[key] = restObject;
    }
  });

  return {
    ...oldOpenTime,
    ...openTimeObj,
  };
};

import { Tabs } from 'antd';
import JobModuleAccessWarning from 'components/common/JobModuleAccessWarning';
import PageTitle from 'components/common/PageTitle';
import i18next from 'i18next';
import React, { useRef } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { getActiveOnJobModulePermission } from 'redux/auth/selectors';
import ButtonCreateCampaign from '../components/buttons/ButtonCreateCampaign';
import ButtonCreateJobListing from '../components/buttons/ButtonCreateJobListing';
import CreateCampaignModal from '../components/Modals/CreateCampaignModal';
import { TAB_VIEW_DEFAULT } from '../constants';
import CampaignView from '../Tabs/CampaignView';
import IndividualJobListingView from '../Tabs/IndividualJobListingView';
import JobRoleView from '../Tabs/JobRoleView';
import JobTemplateView from '../Tabs/JobTemplateView';
import { JobListingsListStyles } from './styles';

const { TabPane } = Tabs;

const JobListingsList = () => {
  const { push } = useHistory();
  const { tabMode } = useParams();
  const hasAccessPermission = useSelector(getActiveOnJobModulePermission);
  const campaignModalRef = useRef();

  const TABS = [
    {
      key: 'campaign',
      title: 'jobListings.tabs.viewByCampaign',
      TabComponent: CampaignView,
    },
    {
      key: 'role',
      title: 'jobListings.tabs.viewByJobRole',
      TabComponent: JobRoleView,
    },
    {
      key: 'template',
      title: 'jobListings.tabs.viewByJobTemplate',
      TabComponent: JobTemplateView,
    },
    {
      key: 'individual',
      title: 'jobListings.tabs.viewByJobListing',
      TabComponent: IndividualJobListingView,
    },
  ];

  const onChange = (key) => {
    push(`/job-listings/${key}/board`);
  };

  return hasAccessPermission ? (
    <JobListingsListStyles>
      <PageTitle extraAction={<ButtonCreateJobListing />}>
        {i18next.t('jobListings.title')}
      </PageTitle>

      <Tabs
        destroyInactiveTabPane
        activeKey={tabMode || TAB_VIEW_DEFAULT}
        onChange={onChange}
        tabBarExtraContent={
          tabMode === TAB_VIEW_DEFAULT && (
            <ButtonCreateCampaign isRefreshCampaignList />
          )
        }
      >
        {TABS.map(({ title, key, TabComponent, ...tab }) => (
          <TabPane tab={i18next.t(title)} key={key}>
            <TabComponent {...tab} />
          </TabPane>
        ))}
      </Tabs>
      <CreateCampaignModal ref={campaignModalRef} />
    </JobListingsListStyles>
  ) : (
    <JobModuleAccessWarning title="jobListings.title" />
  );
};

export default JobListingsList;

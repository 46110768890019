import FilterCustom from 'components/common/FilterCustom';
import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import { getFilterFromUrl } from 'utils/tools';
import {
  DEFAULT_CURRENT_PAGE,
  DEFAULT_SORT_ROLE,
} from 'containers/JobListings/constants';
import DisplayTypes from 'components/common/DisplayTypes';
import FilterForm from './FilterForm';

const Filter = ({ retrieveList, pushQuery, type, handleUpdateViewType }) => {
  const { search } = useLocation();
  const { filter } = getFilterFromUrl(search);

  const onSubmitFilter = (value) => {
    const restFilter = {
      size: filter.size,
      page: DEFAULT_CURRENT_PAGE,
      sorts: filter.sorts || [DEFAULT_SORT_ROLE],
      filter: {
        ...value,
      },
    };
    retrieveList({
      filter: { ...restFilter },
      isRefresh: true,
    });
    pushQuery({
      'sorts[]': filter.sorts,
      ...restFilter,
    });
  };

  const onClearFilter = useCallback(() => {
    retrieveList({
      filter: {
        size: filter.size,
        page: filter.page || DEFAULT_CURRENT_PAGE,
        sorts: filter.sorts || [DEFAULT_SORT_ROLE],
      },
      isRefresh: true,
    });
    pushQuery({
      'sorts[]': filter.sorts,
      size: filter.size,
      page: filter.page,
    });
  }, [retrieveList, filter, pushQuery]);

  return (
    <div>
      <FilterCustom
        onSubmitFilter={onSubmitFilter}
        onClearFilter={onClearFilter}
        responsiveFilter={{
          xxl: 20,
          xl: 18,
          lg: 24,
          md: 24,
          xs: 24,
        }}
        responsiveAction={{
          xxl: 4,
          xl: 6,
          lg: 24,
          md: 24,
          xs: 24,
        }}
      >
        <div className="flex">
          <DisplayTypes
            type={type}
            handleUpdateViewType={handleUpdateViewType}
          />
          <FilterForm />
        </div>
      </FilterCustom>
    </div>
  );
};

Filter.propTypes = {
  retrieveList: PropTypes.func,
  pushQuery: PropTypes.func,
  type: PropTypes.string,
  handleUpdateViewType: PropTypes.func,
};

export default Filter;

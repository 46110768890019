import { Button, Col, Divider, Progress, Row, Space } from 'antd';
import ModalCustom from 'components/common/ModalCustom';
import {
  CheckCircleIcon,
  CheckCircleUncheckIcon,
  OpenNewCentreTab,
} from 'components/common/SVGIcon';
import EditButton from 'components/RestActions/EditButton';
import { LISTING_STRENGTH_CONTENT } from 'configs/localData';
import { LISTING_STEP } from 'configs/localData/constant';
import i18next from 'i18next';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { getAllAutomatedResponses } from 'redux/automatedResponses/actions';
import { getLinkCentreDetailRichweb } from 'utils/dataUtils';
import { ListingStrengthStyles, ScrollCentreBoxStyles } from './styles';

interface CentreStep {
  step?: number | string;
  key?: string | null;
  title?: string;
  Component: React.ComponentType<unknown>;
  percent?: number;
  description?: string;
}

interface CRMPackageType {
  id?: string;
  name: string;
  price: number;
  priceId: string;
  rank: number;
}

interface ModalRef {
  toggleModal: () => void;
}

interface Props {
  visible: boolean;
  onCancel: () => void;
  record: {
    CRMPackage: CRMPackageType;
    brand: {
      logo: string;
    };
  };
  centreStep: CentreStep[];
}

const SKIP_CHECK_SUBSCRIPTION = 1;

const ViewListModal = ({ visible, onCancel, record, centreStep }: Props) => {
  const stepDone = centreStep?.filter((item) => item?.percent === 100).length;

  const [currentIndex, setCurrentIndex] = useState(0);
  const dispatch = useDispatch();

  const { push } = useHistory();

  const { id: centreId } = useParams();

  const modalRef = useRef<ModalRef>();

  const { key, Component } = LISTING_STRENGTH_CONTENT[currentIndex];

  const [disabled, setDisabled] = useState(false);

  useEffect(() => {
    if (record?.brand?.logo) {
      setDisabled(true);
    }
  }, [record?.brand?.logo]); // eslint-disable-line

  const onClickUpdateButton = useCallback(
    (index) => {
      const { key: currentKey } = LISTING_STRENGTH_CONTENT[index];
      if (currentKey !== null) {
        if (currentKey === 'automatedResponses') {
          push({
            pathname: `/enquiries/${currentKey}`,
            state: {
              isUpdateStep11: true,
              centreId,
            },
          });
          dispatch(getAllAutomatedResponses({ id: centreId }));
        } else {
          push(`/centres/${centreId}/show/${currentKey}`);
          onCancel();
        }
      } else {
        modalRef.current && modalRef.current.toggleModal();
      }
    },
    [push, centreId, dispatch, onCancel],
  );

  return (
    <>
      <ModalCustom
        title={
          <div>
            <span>{i18next.t('listingStrength.yourListingStrength')}</span>
            <span className="ml-16 title-header-item">{`${stepDone}/${LISTING_STEP}`}</span>
          </div>
        }
        visible={visible}
        onCancel={onCancel}
        width={700}
        footer={
          <Button className="btn-line-primary fw-bold">
            <a
              href={getLinkCentreDetailRichweb(record)}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Space className="fw-bold">
                <OpenNewCentreTab />
                {i18next.t('button.viewAsParent')}
              </Space>
            </a>
          </Button>
        }
      >
        <ListingStrengthStyles>
          <Progress
            steps={LISTING_STEP}
            percent={(stepDone * 100) / LISTING_STEP}
            showInfo={false}
            className="mt-16 w-full mb-20"
          />
          <ScrollCentreBoxStyles>
            {centreStep?.map((item, index) => (
              <div key={Number(index)}>
                <Row gutter={[30, 0]} className="flex-center-between mt-20">
                  <Col span={22} className="flex items-center">
                    {item?.percent === 100 ? (
                      <CheckCircleIcon className="text-success" />
                    ) : (
                      <CheckCircleUncheckIcon className="text-24" />
                    )}
                    <span className="ml-12">
                      <strong>{`${i18next.t(item?.title)}: `}</strong>
                      <span>{item?.description}</span>
                    </span>
                  </Col>
                  <Col span={2}>
                    <EditButton
                      isSkipSubscription={
                        item?.step === SKIP_CHECK_SUBSCRIPTION
                      }
                      disabled={disabled && item?.step === 4}
                      packageName={record?.CRMPackage?.name}
                      permissionKey={`${
                        item?.step === 11
                          ? 'automatedResponses'
                          : 'centreListingStrength'
                      }`}
                      action="isEdit"
                      onClickCustom={async () => {
                        await setCurrentIndex(index);
                        onClickUpdateButton(index);
                      }}
                    />
                  </Col>
                </Row>
                <Divider />
              </div>
            ))}
          </ScrollCentreBoxStyles>
        </ListingStrengthStyles>
      </ModalCustom>
      {!key && <Component ref={modalRef} record={record} />}
    </>
  );
};

export default ViewListModal;

import styled from 'styled-components';
import { Modal } from 'antd';

const ListFileMessageStyles = styled.div`
  .list-file-view {
    display: flex;
    flex-direction: column;
    margin-left: -7.5px;
    margin-right: -7.5px;
  }
`;

export default ListFileMessageStyles;

export const FileItemViewStyles = styled.div`
  position: relative;
  margin-right: 7.5px;
  margin-left: 7.5px;
  &:not(:last-child) {
    margin-bottom: 15px;
  }
  .image-file {
    object-fit: cover;
    cursor: pointer;
  }
  .image-file {
    width: 100px;
    height: 100px;
    min-height: 100%;
  }
  .icon-a-item {
    display: flex;
    align-items: center;
    color: inherit;
    .anticon {
      color: #fff;
      font-size: 20px;
      background: #ed6fb3;
      padding: 13px;
      border-radius: 50%;
    }
    .file-name {
      margin-left: 10px;
    }
  }

  audio,
  video {
    outline: none;
  }
`;

export const ModalViewStyles = styled(Modal)`
  .ant-modal-close-x {
    width: 40px;
    height: 40px;
    line-height: 40px;
  }
  .ant-modal-body {
    padding-top: 40px;
  }
`;

import styled from 'styled-components';

export const AdCreativeContainerStyle = styled.div`
  .ant-form-item-label > label {
    color: #000;
    font-size: 16px;
    font-weight: bold;
  }
`;

export const ModalContainerStyle = styled.div`
  .ant-form-item-label > label {
    color: #000;
    font-size: 16px;
    font-weight: bold;
  }
`;

export const LeftContainerStyle = styled.div`
  padding: 0;
  border-right: none;
  @media only screen and (min-width: 765px) {
    padding-right: 64px;
    border-right: 1px solid #e9e9e9;
  }
`;

export const RightContainerStyle = styled.div`
  padding: 32px 0 0 0;
  border-top: 1px solid #e9e9e9;
  @media only screen and (min-width: 765px) {
    padding-left: 64px;
    border-top: none;
  }
`;

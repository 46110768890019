import React from 'react';
import { CrownFilled, PlusOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import i18next from 'i18next';
import PropTypes from 'prop-types';

import { upgradeModal } from 'components/common/CheckPermissions/UpgradeSubscriptionModal';

const CreateBtnFallback = ({ header }) => {
  const onClickButton = () => {
    upgradeModal.open();
  };

  return (
    <div className="create-action-wrapper">
      <Button onClick={onClickButton} icon={<PlusOutlined />}>
        {i18next.t(header)}
      </Button>
      <CrownFilled className="action-feature-icon" />
    </div>
  );
};

CreateBtnFallback.propTypes = {
  header: PropTypes.string,
};

export default CreateBtnFallback;

import { Checkbox, List, ListProps, Select } from 'antd';
import styled from 'styled-components';

export const SelectCheckboxStyles = styled(Select)`
  .ant-select-selection-overflow-item .ant-checkbox {
    display: none;
  }

  .ant-select-selection-item-content {
    font-size: 14px;
    line-height: 20px;
    color: #101828;
  }
  .ant-select-selection-item-remove {
    color: #101828;
    font-size: 8px;
    display: flex;
    align-items: center;
  }

  .ant-select-selection-search-input {
    height: 30px;
  }
`;

export const ListSelectedOptionHidden = styled(List)<ListProps<unknown>>`
  max-height: 240px;
  overflow-y: auto;
  margin: 0 4px;

  ::-webkit-scrollbar {
    width: 4px;
  }

  ::-webkit-scrollbar-thumb {
    background: #ebecf0;
    border-radius: 100px;
  }

  .ant-list-item {
    padding: 8px 12px;
  }
`;

export const StyledCheckbox = styled(Checkbox)`
  .ant-checkbox-inner {
    width: 16px;
    height: 16px;
  }

  .ant-checkbox-indeterminate .ant-checkbox-inner::after {
    width: 8px;
    height: 8px;
  }

  .ant-checkbox-checked .ant-checkbox-inner::after {
    transform: rotate(45deg) scale(1) translate(-50%, -50%);
    width: 5.71428571px;
    height: 9.14285714px;
  }
`;

import { get } from './utils';

export const getSummaryDetailContactApi = (id) =>
  get(`/admin/contacts/${id}/summary`);

export const getAllApplicationsOfContactApi = (id, data) =>
  get(`/admin/contacts/${id}/applications`, data);

export const getAllEnquiriesOfContactApi = (id, data) =>
  get(`/admin/contacts/${id}/enquiries`, data);

export const getContactsBoardApi = (data) => get(`/admin/contacts/board`, data);

import React, { useState, useRef, useCallback } from 'react';
import { formatNightDayTable } from 'utils/formatFieldUtils';
import { Space, Tooltip, Modal, Checkbox } from 'antd';
import { useHistory, useLocation } from 'react-router';
import { DeleteIcon, MoveIcon } from 'components/common/SVGIcon';
import i18next from 'i18next';
import TableCustom from 'components/common/TableCustom';
import EditButton from 'components/RestActions/EditButton';
import DeleteButton from 'components/RestActions/DeleteButton';
import PropTypes from 'prop-types';
import { getFilterFromUrl } from 'utils/tools';
import {
  DEFAULT_CURRENT_PAGE,
  DEFAULT_SORT_INDIVIDUAL,
  JOB_LISTING_DETAIL_TABS,
  LIMIT_ITEM,
  VIEW_TYPE_JOB_LISTINGS,
} from 'containers/JobListings/constants';
import { useDispatch, useSelector } from 'react-redux';
import { getJobListingViewByJobIndividual } from 'redux/jobs/jobListings/selectors';
import {
  SORT_ASCEND_VALUE,
  SORT_DESCEND_VALUE,
} from 'configs/localData/constant';
import useCheckJobListingPermission from 'containers/JobListings/hooks/useCheckJobListingPermission';
import { formatDateApplication } from 'utils/textUtils';
import {
  delJobListing,
  getAllJobListings,
  getSummaryJobListing,
} from 'redux/jobs/jobListings/actions';
import { unionBy, isEmpty } from 'lodash-es';
import useFormatFnc from '../../useFormat';
import TableViewStyles from './styles';
import MoveCampaignModal from '../MoveCampaignModal';
import DeleteMultipleJobModal from '../DeleteMultipleJobModal';

const TableView = ({ jobIndividualData, retrieveList, pushQuery, loading }) => {
  const { push } = useHistory();
  const dispatch = useDispatch();
  const { search } = useLocation();
  const { filter } = getFilterFromUrl(search);
  const useFormat = useFormatFnc();
  const { resourceData } = useSelector(getJobListingViewByJobIndividual) || {};
  const moveCampaignModalRef = useRef();
  const deleteMultipleJobRef = useRef();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedRowKeyNoApply, setSelectedRowKeyNoApply] = useState([]);
  const [selectedRowKeyHaveApply, setSelectedRowKeyHaveApply] = useState([]);

  const { confirm, warning } = Modal;
  const { canModifyJobListing } = useCheckJobListingPermission();

  const openMoveCampaignModal = () => {
    moveCampaignModalRef?.current && moveCampaignModalRef.current.open();
  };

  const openDeleteMultipleJobModal = () => {
    deleteMultipleJobRef?.current && deleteMultipleJobRef.current.open();
  };

  const handleDelete = (id, record) => {
    if (record?.applications > 0) {
      return warning({
        content: (
          <div className="ml-40">
            {i18next.t('jobListings.contentWarningDelete')}
          </div>
        ),
      });
    }
    return confirm({
      content: (
        <div className="ml-40">
          {i18next.t('jobListings.contentConfirmDelete')}
        </div>
      ),
      okText: i18next.t('button.delete'),
      cancelText: i18next.t('button.cancel'),
      onOk: () => onDelete(id),
    });
  };

  const onDelete = async (id) => {
    const res = await dispatch(delJobListing({ postIds: id }));
    const defaultParams = {
      sorts: filter.sorts || [DEFAULT_SORT_INDIVIDUAL],
      page: filter.page || DEFAULT_CURRENT_PAGE,
      size: filter.size || LIMIT_ITEM,
      filter: filter.filter,
      viewType: VIEW_TYPE_JOB_LISTINGS.INDIVIDUAL,
    };
    if (res?.meta?.requestStatus === 'fulfilled') {
      setSelectedRowKeys([]);
      setSelectedRowKeyHaveApply([]);
      setSelectedRowKeyNoApply([]);
      dispatch(
        getSummaryJobListing({
          ...filter,
          viewType: VIEW_TYPE_JOB_LISTINGS.INDIVIDUAL,
        }),
      );
      dispatch(
        getAllJobListings({
          data: defaultParams,
          options: {
            keepFilter: true,
            isRefresh: true,
          },
        }),
      );
    }
  };

  const getSorterOrder = useCallback(
    (source) => {
      if (filter.sorts?.[0] === source) return SORT_ASCEND_VALUE;
      if (filter.sorts?.[0] === `-${source}`) return SORT_DESCEND_VALUE;
      return '';
    },
    [filter],
  );

  const onChangeSelectAll = (e) => {
    const { checked } = e.target;
    const jobIndividualValue = jobIndividualData?.map((item) => item?.id);
    const rowKeysData = selectedRowKeys.concat(
      jobIndividualValue.filter((item) => selectedRowKeys.indexOf(item) < 0),
    );

    const jobNoApply = jobIndividualData?.filter(
      (item) => item?.applications === String(0),
    );
    const jobHaveApply = jobIndividualData?.filter(
      (item) => item?.applications > 0,
    );

    if (checked) {
      setSelectedRowKeys(rowKeysData);
      setSelectedRowKeyNoApply(
        unionBy([...selectedRowKeyNoApply, ...jobNoApply], 'id'),
      );
      setSelectedRowKeyHaveApply(
        unionBy([...selectedRowKeyHaveApply, ...jobHaveApply], 'id'),
      );
    } else {
      const selectedRowValues = selectedRowKeys?.filter(
        (item) => !jobIndividualValue?.includes(item),
      );

      const selectedRowNoApplyValues = selectedRowKeyNoApply?.filter(
        (item) => !jobNoApply?.some((el) => el?.id === item?.id),
      );

      const selectedRowHaveApplyValues = selectedRowKeyHaveApply?.filter(
        (item) => !jobHaveApply?.some((el) => el?.id === item?.id),
      );

      setSelectedRowKeys(selectedRowValues);
      setSelectedRowKeyNoApply(selectedRowNoApplyValues);
      setSelectedRowKeyHaveApply(selectedRowHaveApplyValues);
    }
  };

  const onChangeSingleSelect = (e, record) => {
    const { checked } = e.target;
    if (checked) {
      setSelectedRowKeys([...selectedRowKeys, record.id]);
      if (record?.applications > 0) {
        setSelectedRowKeyHaveApply([...selectedRowKeyHaveApply, record]);
      } else {
        setSelectedRowKeyNoApply([...selectedRowKeyNoApply, record]);
      }
    } else {
      const selectedRowHaveApplyValues = selectedRowKeyHaveApply.filter(
        (item) => item?.id !== record?.id,
      );
      const selectedRowNoApplyValues = selectedRowKeyNoApply.filter(
        (item) => item?.id !== record?.id,
      );
      const selectedRowValues = selectedRowKeys.filter(
        (item) => item !== record.id,
      );
      setSelectedRowKeyNoApply(selectedRowNoApplyValues);
      setSelectedRowKeyHaveApply(selectedRowHaveApplyValues);
      setSelectedRowKeys(selectedRowValues);
    }
  };

  const isCheckedAll =
    !isEmpty(jobIndividualData) &&
    jobIndividualData?.every((item) => selectedRowKeys.includes(item?.id));

  const checkboxColumn = [
    {
      fixed: 'left',
      width: 50,
      title: () => (
        <Checkbox checked={isCheckedAll} onChange={onChangeSelectAll} />
      ),
      dataIndex: 'id',
      render: (data, record) => (
        <Checkbox
          checked={selectedRowKeys?.some((item) => item === data)}
          onChange={(e) => onChangeSingleSelect(e, record)}
        />
      ),
    },
  ];

  const renderCheckobxColumn = () => {
    if (canModifyJobListing) {
      return checkboxColumn;
    }
    return '';
  };

  const columns = [
    ...renderCheckobxColumn(),
    {
      fixed: 'left',
      title: i18next.t('jobListings.jobReference'),
      dataIndex: 'jobReference',
      render: (data, record) => useFormat.formatReference(data, record),
      defaultSortOrder: getSorterOrder('jobReference'),
    },
    {
      title: i18next.t('jobListings.campaign'),
      dataIndex: 'campaign',
      sorter: true,
      render: (data) => (
        <Tooltip className="line-clamp-2" title={data}>
          {data}
        </Tooltip>
      ),
      defaultSortOrder: getSorterOrder('campaign'),
    },
    {
      title: i18next.t('jobListings.jobRole'),
      dataIndex: 'jobRole',
      sorter: true,
      render: (data) => (
        <Tooltip className="line-clamp-2" title={data}>
          {data}
        </Tooltip>
      ),
      defaultSortOrder: getSorterOrder('jobRole'),
    },
    {
      title: i18next.t('jobListings.jobTemplate'),
      dataIndex: 'jobTemplate',
      sorter: true,
      render: (data) => (
        <Tooltip className="line-clamp-2" title={data}>
          {data}
        </Tooltip>
      ),
      defaultSortOrder: getSorterOrder('jobTemplate'),
    },
    {
      title: i18next.t('jobListings.jobTitle'),
      dataIndex: 'jobTitle',
      sorter: true,
      render: (data) => (
        <Tooltip className="line-clamp-2" title={data}>
          {data}
        </Tooltip>
      ),
      defaultSortOrder: getSorterOrder('jobTitle'),
    },
    {
      title: i18next.t('jobListings.workplace'),
      dataIndex: 'workplace',
      sorter: true,
      render: (data) => (
        <Tooltip className="line-clamp-2" title={data}>
          {data}
        </Tooltip>
      ),
      defaultSortOrder: getSorterOrder('workplace'),
    },
    {
      title: i18next.t('jobListings.jobType'),
      dataIndex: 'jobType',
      sorter: true,
      render: useFormat.formatType,
      defaultSortOrder: getSorterOrder('jobType'),
    },
    {
      title: i18next.t('jobListings.salary'),
      dataIndex: 'salary',
      sorter: true,
      render: (data) => (
        <Tooltip className="line-clamp-2" title={data}>
          {data}
        </Tooltip>
      ),
      defaultSortOrder: getSorterOrder('salary'),
    },
    {
      title: i18next.t('jobListings.startDate'),
      dataIndex: 'startDate',
      sorter: true,
      defaultSortOrder: getSorterOrder('startDate'),
      render: (data, record) => useFormat.formatStartDate(data, record),
    },
    {
      title: i18next.t('jobListings.jobListingStatus'),
      dataIndex: 'jobListingStatus',
      sorter: true,
      render: (data) => useFormat.formatStatus(data),
      defaultSortOrder: getSorterOrder('jobListingStatus'),
    },
    {
      title: i18next.t('jobListings.applicationDeadline'),
      dataIndex: 'applicationEndDate',
      sorter: true,
      defaultSortOrder: getSorterOrder('applicationEndDate'),
      render: (data) => formatDateApplication(data),
    },
    {
      title: `# ${i18next.t('jobListings.applications')}`,
      dataIndex: 'applications',
      render: (data, record) => useFormat.formatApplicationAction(data, record),
      sorter: true,
      defaultSortOrder: getSorterOrder('applications'),
    },
    {
      title: i18next.t('jobListings.createdAt'),
      dataIndex: 'createdAt',
      sorter: true,
      render: (data) => formatNightDayTable(data),
    },
    {
      title: i18next.t('jobListings.lastUpdated'),
      dataIndex: 'updatedAt',
      sorter: true,
      render: (data, record) => formatNightDayTable(data, record),
      defaultSortOrder: getSorterOrder('updatedAt'),
    },
    {
      title: '',
      dataIndex: 'id',
      fixed: 'right',
      width: 100,
      render: (id, record) => (
        <Space>
          <EditButton
            isView
            action="isView"
            onClickCustom={() => {
              push(
                `/job-listings/${id}/show/${JOB_LISTING_DETAIL_TABS.JOB_DETAILS}`,
              );
            }}
          />
          <DeleteButton
            disabled={!canModifyJobListing}
            action="isEdit"
            resource="jobListings"
            record={record}
            onClickCustom={() => handleDelete([id], record)}
          />
        </Space>
      ),
    },
  ];

  const onChange = (e, filters, sorter) => {
    const formatSort =
      sorter && sorter.field && sorter.order
        ? `${sorter.order === SORT_DESCEND_VALUE ? '-' : ''}${sorter.field}`
        : null;

    const restFilter = {
      size: e.pageSize,
      page: e.current,
      sorts: [formatSort || DEFAULT_SORT_INDIVIDUAL],
      filter: { ...filter.filter },
    };

    retrieveList({
      filter: restFilter,
      isRefresh: true,
      keepFilter: true,
    });
    pushQuery({
      'sorts[]': restFilter.sorts,
      ...restFilter,
    });
  };

  return (
    <TableViewStyles>
      {selectedRowKeys?.length > 0 && (
        <div className="total-Wrapper flex">
          <span className="total__number mr-10">{selectedRowKeys?.length}</span>
          <span className="text-gray-500">
            {i18next.t(
              selectedRowKeys?.length > 1
                ? 'jobListings.jobListingsSelected'
                : 'jobListings.jobListingSelected',
            )}
          </span>
          <div className="ml-24 flex">
            <Tooltip
              title={i18next.t('button.delete')}
              className="mr-24"
              onClick={openDeleteMultipleJobModal}
            >
              <DeleteIcon />
            </Tooltip>
            <Tooltip
              title={i18next.t('jobListings.moveToCampaign')}
              onClick={openMoveCampaignModal}
            >
              <MoveIcon style={{ fontSize: 36 }} />
            </Tooltip>
          </div>
        </div>
      )}
      <TableCustom
        xScroll={2900}
        columns={columns}
        data={jobIndividualData}
        loading={loading}
        rowKey={(record) => record.id}
        onChange={onChange}
        pagination={{
          pageSize: filter?.size || LIMIT_ITEM,
          current: filter?.page || DEFAULT_CURRENT_PAGE,
          total: resourceData?.total,
        }}
      />
      <MoveCampaignModal
        itemId={selectedRowKeys}
        ref={moveCampaignModalRef}
        retrieveList={retrieveList}
        setSelectedRowKeys={setSelectedRowKeys}
        isFixedCampaignSearchBar
      />
      <DeleteMultipleJobModal
        selectedRowKeyNoApply={selectedRowKeyNoApply}
        selectedRowKeyHaveApply={selectedRowKeyHaveApply}
        ref={deleteMultipleJobRef}
        onDelete={onDelete}
      />
    </TableViewStyles>
  );
};

TableView.propTypes = {
  jobIndividualData: PropTypes.array,
  retrieveList: PropTypes.func,
  pushQuery: PropTypes.func,
  loading: PropTypes.bool,
};

TableView.defaultProps = {
  retrieveList: () => null,
  pushQuery: () => null,
};

export default TableView;

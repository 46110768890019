import { QuestionCircleFilled } from '@ant-design/icons';
import { Tooltip } from 'antd';
import theme from 'configs/theme';
import React from 'react';
import { SummaryMetricItem } from 'redux/@rtkQuery/marketingMetris/type';
import ComparePercentage from './ComparePercentage';
import MetricTooltipContent from './MetricTooltipContent';
import { SummaryMetricCardContainer } from './styles';

interface Props extends SummaryMetricItem {
  onClick?: () => void;
  isSelected: boolean;
}

const SummaryMetricCard = ({
  color,
  percentChange,
  icon,
  name,
  tooltips,
  value,
  onClick,
  isSelected,
}: Props) => (
  <SummaryMetricCardContainer
    color={color}
    isSelected={isSelected}
    onClick={onClick}
  >
    <div>
      <img width={48} height={48} src={icon} alt="metric icon" />
    </div>
    <div className="ml-8">
      <div className="flex-center-start">
        <strong className="text-12 line-clamp-1">{name}</strong>
        <Tooltip
          title={tooltips.description}
          color={theme.text.blackNeutral800}
        >
          <QuestionCircleFilled className="text-gray-400 ml-6" />
        </Tooltip>
      </div>
      <Tooltip
        placement="topLeft"
        overlayStyle={{ maxWidth: 'none' }}
        color="#fff"
        title={
          <MetricTooltipContent
            name={name}
            tooltips={tooltips}
            percentChange={percentChange}
          />
        }
      >
        <div className="flex-center-start">
          <strong className="text-20">{value}</strong>
          <ComparePercentage {...percentChange} className="ml-8" />
        </div>
      </Tooltip>
    </div>
  </SummaryMetricCardContainer>
);

export default SummaryMetricCard;

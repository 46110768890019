import React from 'react';
import { JOB_DISCOUNT_TYPES, JOB_STATUS } from 'configs/localData/constant';
import { Typography, Image, Tooltip, Avatar, Tag } from 'antd';
import i18next from 'i18next';
import { Link } from 'react-router-dom';
import TitleTooltip from 'components/common/TitleTooltip';
import {
  CopyFillIcon,
  LocationGreenIcon,
  PriceGreenIcon,
  EducatorDiscountFillIcon,
  BriefcaseIcon,
  DiscountPercentageIcon,
} from 'components/common/SVGIcon';
import PropTypes from 'prop-types';
import { formatDateUpdatePrice } from 'utils/textUtils';
import { UserOutlined } from '@ant-design/icons';
import { Wrapper, TopContainer, BottomContainer } from './styles';
import DotCircle from '../DotCircle';

const { Paragraph } = Typography;

const IndividualJob = ({ job }) => {
  const {
    id,
    jobListingStatus,
    jobTitle,
    workplace,
    salary,
    applications,
    newApplications,
    jobType,
    jobReference,
    address,
    createdAt,
    discount,
    discountType,
  } = job;

  const getStatus = (status) => {
    switch (status) {
      case JOB_STATUS.open:
        return (
          <div className="status-container__open">
            {i18next.t('jobListings.jobItem.status.open')}
          </div>
        );
      case JOB_STATUS.closed:
        return (
          <div className="status-container__closed">
            {i18next.t('jobListings.jobItem.status.closed')}
          </div>
        );

      default:
        return <div />;
    }
  };

  return (
    <Link to={`/job-listings/${id}/show/job-details`}>
      <Wrapper>
        <TopContainer>
          <div className="flex justify-between w-full h-50">
            <Tooltip
              placement="top"
              title={i18next.t('jobListings.jobListingStatus')}
              onClick={(event) => event.stopPropagation()}
            >
              {getStatus(jobListingStatus)}
            </Tooltip>
            <div className="id-container">
              {jobReference && (
                <Paragraph
                  copyable={{
                    icon: [<CopyFillIcon />],
                    tooltips: ['Copy', 'Copied'],
                    text: jobReference,
                  }}
                >
                  <span className="text-gray-neutral-500 text-decoration-line fw-600">
                    <Tooltip
                      placement="top"
                      title={i18next.t('jobListings.copyJobReference')}
                    >
                      {jobReference}
                    </Tooltip>
                  </span>
                </Paragraph>
              )}
            </div>
          </div>
          <div className="flex w-full">
            {job?.defaultImage ? (
              <Image
                className="avatar"
                preview={false}
                src={job?.defaultImage}
              />
            ) : (
              <Avatar
                size={80}
                className="avatar"
                shape="square"
                icon={<UserOutlined />}
              />
            )}

            <div className="right-title">
              <TitleTooltip
                title={jobTitle}
                rows={1}
                className="title w-full"
              />
              <TitleTooltip
                title={workplace}
                rows={1}
                className="w-full description"
              />
            </div>
          </div>

          <div className="flex mt-12">
            <LocationGreenIcon />
            <TitleTooltip title={address} rows={1} className="address w-full" />
          </div>

          <div className="flex mt-12">
            <PriceGreenIcon />
            <TitleTooltip title={salary} rows={2} className="price w-full" />
          </div>

          <div className="job-card__info-item mt-12 ellipsis-t flex-center-start">
            {discountType && !!discount && (
              <Tooltip title={discountType} className="flex-center-start">
                {discountType === JOB_DISCOUNT_TYPES?.educatorDiscount ? (
                  <EducatorDiscountFillIcon className="text-18 mr-10" />
                ) : (
                  <DiscountPercentageIcon className="text-18 mr-10" />
                )}

                <span className="text-14 text-black-base">
                  {`${discount}%`}
                </span>
                <DotCircle color="#D0D5DD" size={5} />
              </Tooltip>
            )}
            {jobType && (
              <>
                <Tag icon={<BriefcaseIcon />} className="text-14 fw-600">
                  {jobType}
                </Tag>
                <DotCircle color="#D0D5DD" size={5} />
              </>
            )}
            {createdAt && (
              <Tooltip title={formatDateUpdatePrice(createdAt)}>
                <span className="posted-time text-14 text-black-base">
                  {formatDateUpdatePrice(createdAt)}
                </span>
              </Tooltip>
            )}
          </div>
        </TopContainer>
        <BottomContainer>
          <div className="application-left">
            <div className="application-line-left" />
            <div className="application-center">
              <div className="application-title">
                {i18next.t('jobListings.jobListingDetails.totalApplications')}
              </div>
              <div className="count">{applications ?? 0}</div>
            </div>
          </div>
          <div className="application-right">
            <div className="application-line-right" />
            <div className="application-center">
              <div className="application-title">
                {i18next.t('jobListings.jobListingDetails.newApplications')}
              </div>
              <div className="count">{newApplications ?? 0}</div>
            </div>
          </div>
        </BottomContainer>
      </Wrapper>
    </Link>
  );
};

IndividualJob.propTypes = {
  job: PropTypes.shape({
    applications: PropTypes.number,
    campaign: PropTypes.string,
    createdAt: PropTypes.string,
    newApplications: PropTypes.string,
    id: PropTypes.string,
    jobListingStatus: PropTypes.string,
    jobReference: PropTypes.string,
    jobRole: PropTypes.string,
    jobTemplate: PropTypes.string,
    jobTitle: PropTypes.string,
    jobType: PropTypes.string,
    salary: PropTypes.string,
    startDate: PropTypes.string,
    status: PropTypes.string,
    updatedAt: PropTypes.string,
    workplace: PropTypes.string,
    defaultImage: PropTypes.string,
    address: PropTypes.string,
    discount: PropTypes.string,
    discountType: PropTypes.string,
  }),
};

export default IndividualJob;

import React from 'react';
import PropTypes from 'prop-types';
import ReferenceInput from 'containers/rest/ReferenceInput';
import RestSelect from 'components/RestInput/RestSelect';
import CentreModal from '.';

const AdditionalDetailModal = ({ visible, record, onCancel }) => {
  const formatOnSubmit = ({ LGA, region, ...values }) => ({
    ...values,
    LGA: LGA || null,
    region: region || null,
  });
  return (
    <CentreModal
      title="centreDetail.titleAdditionalDetailModal"
      visible={visible}
      onCancel={onCancel}
      record={record}
      formatOnSubmit={formatOnSubmit}
    >
      <ReferenceInput
        source="region"
        resource="regions"
        searchKey="name"
        filterKey="name"
        initialFilter={{ orderBy: 'name' }}
      >
        <RestSelect
          header="input.region.name"
          titleProp="name"
          valueProp="name"
          placeholder="input.region.placeholder"
          isFilterOption={false}
        />
      </ReferenceInput>

      <ReferenceInput
        source="LGA"
        resource="LGA"
        searchKey="name"
        filterKey="name"
        initialFilter={{ orderBy: 'name' }}
      >
        <RestSelect
          header="input.LGA.name"
          titleProp="name"
          valueProp="name"
          placeholder="input.LGA.placeholder"
          isFilterOption={false}
        />
      </ReferenceInput>
    </CentreModal>
  );
};

AdditionalDetailModal.propTypes = {
  visible: PropTypes.bool,
  onCancel: PropTypes.func,
  record: PropTypes.object,
};

AdditionalDetailModal.defaultProps = {};

export default AdditionalDetailModal;

import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import i18next from 'i18next';
import { template } from 'lodash-es';

import { ArrowLeftOutlined, UserOutlined } from '@ant-design/icons';
import Avatar from 'antd/lib/avatar/avatar';
import { getFeaturedImage, getUrlIfyMessage } from 'utils/dataUtils';
import { useAutomatedMessage } from './context';

const AutomatedPreview = ({ currentCentre }) => {
  const { message } = useAutomatedMessage();

  const contentTemplate = useMemo(() => {
    try {
      if (message && currentCentre?.name) {
        return template(message)({
          centre: {
            name: currentCentre.name,
          },
        });
      }

      return message;
    } catch (error) {
      return message;
    }
  }, [message, currentCentre]);

  return (
    <div className="automated-preview">
      <div className="automated__title">{i18next.t('common.preview')}</div>

      <div align="center">
        <div className="automated-preview__phone">
          <img
            alt="kindi"
            src="/static/images/iphone-background.png"
            className="automated-preview__phone-img"
          />

          <div className="automated-preview__phone_info">
            <div className="automated-preview__phone-header">
              <ArrowLeftOutlined />
              <span className="centre-name">{currentCentre?.name}</span>
            </div>

            <div className="automated-preview__phone-body">
              <div className="automated-preview__message-item">
                <Avatar
                  icon={<UserOutlined />}
                  src={getFeaturedImage(currentCentre?.images)}
                />
                <div
                  dangerouslySetInnerHTML={{
                    __html: getUrlIfyMessage(contentTemplate),
                  }}
                  className="message-content"
                />
              </div>
            </div>

            <div className="automated-preview__phone-footer">
              <div className="automated-preview__input">Aa</div>
              <div className="automated-preview__send">
                {i18next.t('button.send')}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

AutomatedPreview.propTypes = {
  currentCentre: PropTypes.object,
};

export default AutomatedPreview;

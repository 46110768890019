import React from 'react';
import i18next from 'i18next';
import { Button } from 'antd';
import PropTypes from 'prop-types';
import { downloadFileByURL } from 'utils/fileUtils';

const DownloadButton = ({ file }) => {
  const handleDownload = () => {
    downloadFileByURL(file);
  };
  return (
    <Button onClick={handleDownload}>{i18next.t('button.download')}</Button>
  );
};

DownloadButton.propTypes = {
  file: PropTypes.object,
};

export default DownloadButton;

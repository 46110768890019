import PageTitle from 'components/common/PageTitle';
import i18next from 'i18next';
import React, {
  useState,
  useCallback,
  useEffect,
  useMemo,
  useRef,
} from 'react';
import { SelectWorkplaceStepWrapperStyles } from 'containers/JobListings/components/Steps/SelectWorkplaceStep/styles';
import {
  Checkbox,
  Col,
  Divider,
  Form,
  Row,
  Skeleton,
  Spin,
  Tooltip,
} from 'antd';
import WorkplaceFilter from 'containers/JobListings/components/filters/WorkplaceFilter';
import RestInputItem from 'components/RestInput/RestInputItem';
import PropTypes from 'prop-types';
import { InfoCircleOutlined } from '@ant-design/icons';
import _ from 'lodash-es';
import {
  checkJobReferencesExistent,
  getWorkplaces,
} from 'redux/jobs/jobListings/actions';
import { useDispatch, useSelector } from 'react-redux';
import {
  getSelectedWorkplacesSelector,
  getWorkplacesSelector,
} from 'redux/jobs/jobListings/selectors';
import { LIMIT_WORKPLACES_DEFAULT } from 'containers/JobListings/constants';
import { Waypoint } from 'react-waypoint';
import {
  goToNextStep,
  goToStepJobListing,
  removeSelectedWorkplaces,
  setJobListingPayload,
  setSelectedWorkplaces,
} from 'redux/jobs/jobListings/slice';
import { useHistory } from 'react-router';
import qs from 'qs';
import { cleanObject } from 'utils/dataUtils';

const CheckboxGroup = Checkbox.Group;

function SelectWorkplaceStep({ form, initialValues, jumpStep }) {
  const dispatch = useDispatch();
  const { location } = useHistory();

  const filter = useMemo(() => {
    const { filter } = qs.parse(location.search, {
      ignoreQueryPrefix: true,
    });

    return _.pickBy(filter);
  }, [location.search]);

  const filterRef = useRef(filter);

  const [selectedOrgs, setSelectedOrgs] = useState([]);

  const [checkAll, setCheckAll] = useState(!!initialValues?.isSelectAll);
  const [indeterminate, setIndeterminate] = useState(false);
  const [selectAllLoading, setSelectAllLoading] = useState(false);

  const { data, loading, resourceParams } = useSelector(getWorkplacesSelector);
  const selectedWorkplaces = useSelector(getSelectedWorkplacesSelector);

  const isEnableLoadMore =
    !_.isEmpty(data) &&
    !loading &&
    resourceParams.page < Math.ceil(resourceParams.total / resourceParams.size);

  const onSelectedCheckboxChange = useCallback(
    (event, id) => {
      const { checked } = event.target;

      if (!checked) {
        setSelectedOrgs((prev) => _.filter(prev, (item) => item !== id));
        dispatch(removeSelectedWorkplaces(id));

        if (checkAll) {
          setCheckAll(false);
          setSelectedOrgs([]);
        }
      }
    },
    [dispatch, checkAll],
  );

  const onUnselectCheckboxChange = useCallback(
    (event, item) => {
      const { checked } = event.target;

      if (checked) {
        setSelectedOrgs((prev) => [...prev, item?.organizationId]);
        dispatch(setSelectedWorkplaces(item));
      }
    },
    [dispatch],
  );

  const getCheckboxItems = (source) =>
    source?.map((item) => ({
      label: `${item?.centreName} - ${_.capitalize(item?.workplaceType)}`,
      value: item?.organizationId,
      onChange: (event) => onUnselectCheckboxChange(event, item),
    }));

  const organizations = getCheckboxItems(data);
  const selectedOrganizations = initialValues?.isSelectAll
    ? organizations
    : getCheckboxItems(selectedWorkplaces);

  const sourceOrganizations = _.unionBy(
    [...selectedOrganizations, ...organizations],
    'value',
  );

  const unSelectedOptions = organizations?.filter(
    (item) => !selectedOrgs.includes(item?.value),
  );
  const isShowDivider = !!(selectedOrgs?.length && unSelectedOptions?.length);

  const selectedOptions = () =>
    _.map(selectedOrgs, (id) => {
      const organizationName = _.find(sourceOrganizations, {
        value: id,
      })?.label;

      return {
        label: (
          <Row className="items-center w-full">
            <Col md={18} xs={24}>
              {organizationName}
            </Col>
            <Col md={6} xs={24}>
              <RestInputItem
                source={['jobReferences', id]}
                className="reference-input"
                placeholder="input.jobReference.placeholder"
              />
            </Col>
          </Row>
        ),
        value: id,
        onChange: (event) => onSelectedCheckboxChange(event, id),
      };
    });

  const onCheckAllChange = (e) => {
    const { checked } = e.target;

    const allIds = _.uniq([
      ...selectedOrgs,
      ...organizations.map((item) => item.value),
    ]);

    setIndeterminate(false);
    setCheckAll(checked);

    if (checked) {
      setSelectedOrgs(allIds);
      return;
    }
    setSelectedOrgs([]);
  };

  const onFinish = ({ selectedOrganizations, ...values }) => {
    const jobReferences = cleanObject(values?.jobReferences);
    const savePayload = () => {
      dispatch(
        setJobListingPayload({
          selectedOrganizations,
          jobReferences,
          isSelectAll: checkAll,
        }),
      );
      jumpStep === null
        ? dispatch(goToNextStep())
        : dispatch(goToStepJobListing(jumpStep));
    };

    const organizations = selectedOrganizations
      ?.map((id) => ({
        organizationId: id,
        jobReference: jobReferences?.[id],
      }))
      ?.filter((item) => item?.jobReference);

    if (_.isEmpty(organizations)) {
      savePayload();
      return;
    }

    dispatch(
      checkJobReferencesExistent({
        organizations,
      }),
    ).then(({ payload }) => {
      const errors = _(payload?.result)
        .filter({ result: true })
        .map((item) => ({
          name: ['jobReferences', item?.organizationId],
          errors: [i18next.t('input.jobReference.validateMsg.invalid')],
        }))
        .value();

      if (_.isEmpty(errors)) {
        savePayload();

        return;
      }

      form.setFields(errors);
      form.scrollToField(errors?.[0]?.name);
    });
  };

  const retrieveList = useCallback(
    ({ data, isRefresh } = {}) =>
      dispatch(
        getWorkplaces({
          data: {
            page: 1,
            size: LIMIT_WORKPLACES_DEFAULT,
            ...data,
          },
          options: {
            isRefresh,
          },
        }),
      ),
    [dispatch],
  );

  const onEnterWaypoint = async () => {
    const { payload } = await retrieveList({
      data: {
        page: +resourceParams.page + 1,
        size: resourceParams.size,
        filter,
      },
      isRefresh: false,
    });

    if (checkAll) {
      const ids = _.uniq([
        ...selectedOrgs,
        ..._.map(payload?.results, 'organizationId'),
      ]);

      setSelectedOrgs(ids);
      form.setFieldsValue({
        selectedOrganizations: ids,
        organizations: [],
      });
    }
  };

  useEffect(() => {
    form.resetFields();
    if (!_.isEmpty(initialValues)) {
      if (initialValues?.selectedOrganizations) {
        setSelectedOrgs(initialValues.selectedOrganizations);
      }

      form.setFieldsValue(initialValues);
    }
  }, [form, initialValues]);

  useEffect(() => {
    if (checkAll) {
      setSelectAllLoading(true);
      retrieveList({ data: { filter }, isRefresh: true })
        .then(({ payload }) => {
          const ids = _.map(payload?.results, 'organizationId');

          setSelectedOrgs(ids);
          form.setFieldsValue({
            selectedOrganizations: ids,
            organizations: [],
          });
        })
        .finally(() => {
          setSelectAllLoading(false);
        });
    }
  }, [form, retrieveList, checkAll, filter]);

  useEffect(() => {
    retrieveList({ data: { filter: filterRef.current }, isRefresh: true });
  }, [retrieveList]);

  useEffect(() => {
    form.setFieldsValue({
      selectedOrganizations: selectedOrgs,
      organizations: [],
    });
  }, [form, selectedOrgs, data]);

  return (
    <SelectWorkplaceStepWrapperStyles>
      <PageTitle className="step-title">
        {i18next.t('jobListings.steps.step2')}
      </PageTitle>

      <WorkplaceFilter retrieveList={retrieveList} />

      <Form
        form={form}
        onFinish={onFinish}
        className="mt-32"
        initialValues={initialValues}
      >
        <Row className="divider">
          <Col span={18}>
            <Checkbox
              indeterminate={indeterminate}
              onChange={onCheckAllChange}
              checked={checkAll}
              defaultChecked={!!initialValues?.isSelectAll}
              className="check-all"
            >
              {i18next.t('text.selectAll')}
            </Checkbox>
          </Col>
          {!!selectedOrgs?.length && (
            <Col span={6}>
              <Tooltip
                placement="topRight"
                title={i18next.t('jobListings.jobReferenceDesc')}
                getPopupContainer={(trigger) => trigger.parentNode}
                overlayClassName="overlay-reference"
                className="tooltip-reference"
              >
                {i18next.t('jobListings.yourJobReference')}
                <InfoCircleOutlined />
              </Tooltip>
            </Col>
          )}
        </Row>

        {!!selectedOrgs?.length && (
          <div className="selected-workspace-panel">
            <span>
              {(() => {
                if (selectedOrgs.length === 1) {
                  return i18next.t('jobListings.singleWorkplaceSelected');
                }
                return i18next.t('jobListings.multipleWorkplaceSelected', {
                  count: checkAll ? resourceParams.total : selectedOrgs.length,
                });
              })()}
            </span>
          </div>
        )}

        <Skeleton paragraph={{ rows: 12 }} active loading={selectAllLoading}>
          <Form.Item
            name="selectedOrganizations"
            className={`selected-group ${
              !selectedOrgs?.length ? 'hidden-item' : ''
            }`}
            rules={[
              {
                required: true,
                message: i18next.t('jobListings.requiredSelectWorkplace'),
              },
            ]}
          >
            <CheckboxGroup options={selectedOptions()} />
          </Form.Item>
        </Skeleton>

        {isShowDivider && <Divider />}

        <Form.Item name="organizations">
          <CheckboxGroup options={unSelectedOptions} />
        </Form.Item>

        {isEnableLoadMore && (
          <div style={{ height: 1 }}>
            <Waypoint onEnter={onEnterWaypoint} />
          </div>
        )}
        {loading && !selectAllLoading && <Spin className="mt-12 flex-center" />}
      </Form>
    </SelectWorkplaceStepWrapperStyles>
  );
}

SelectWorkplaceStep.propTypes = {
  form: PropTypes.object,
  initialValues: PropTypes.object,
  jumpStep: PropTypes.any,
};

export default SelectWorkplaceStep;

import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  getProvidersOfUserApi,
  postProvidersOfUserApi,
  delProvidersOfUserApi,
} from 'api/user';
import { apiWrapper } from 'utils/reduxUtils';

import { getAllCustom } from 'redux/crudCreatorCustom/actions';

export const MODEL_NAME = 'providersOfUser';

export const getProvidersOfUser = getAllCustom(
  MODEL_NAME,
  getProvidersOfUserApi,
);

export const postProvidersOfUser = createAsyncThunk(
  'providersOfUser/postProvidersOfUser',
  async (payload = {}, thunkApi) => {
    try {
      const { id, data = {}, options } = payload;

      const response = await apiWrapper({}, postProvidersOfUserApi, id, data);

      return { data: response, options, success: true };
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  },
);

export const delProvidersOfUser = createAsyncThunk(
  'providersOfUser/delProvidersOfUser',
  async (payload = {}, thunkApi) => {
    try {
      const { id, providerId, options = {} } = payload;

      const { total, offset, limit } =
        thunkApi.getState().providersOfUser?.resourceData || {};

      await apiWrapper({}, delProvidersOfUserApi, id, providerId);

      thunkApi.dispatch(
        getProvidersOfUser({
          id,
          data: {
            offset: offset > 0 && total % offset <= 1 ? offset - limit : offset,
            orderBy: 'name',
          },
          options: { isRefresh: true, keepFilter: true },
        }),
      );

      return { providerId, id, options };
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  },
);

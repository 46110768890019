import React, { forwardRef, useImperativeHandle, useState } from 'react';
import ModalCustom from 'components/common/ModalCustom';
import { Form, Radio, Typography } from 'antd';
import RestInputItem from 'components/RestInput/RestInputItem';
import i18next from 'i18next';
import { ICallingButtonType } from 'api/advertisement/type';
import { useDispatch } from 'react-redux';
import { addCallingButton } from 'redux/advertisement/actions';
import LabelWithTooltip from 'components/form/Label/LabelWithTooltip';
import { ModalContainerStyle } from './styles';

interface AddNewButtonModalProps {
  destinationType: ICallingButtonType[];
}

const AddNewCallingButtonModal = forwardRef(
  ({ destinationType }: AddNewButtonModalProps, ref) => {
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const [visible, setVisible] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const handleCancel = () => {
      setVisible(false);
    };

    const onOk = async () => {
      form.validateFields().then(async (res) => {
        setIsLoading(true);
        await dispatch(addCallingButton(res));
        setIsLoading(false);
        setVisible(false);
        form.resetFields();
      });
    };

    useImperativeHandle(
      ref,
      () => ({
        toggleModal: () => {
          setVisible(true);
        },
      }),
      [],
    );

    return (
      <ModalCustom
        title={i18next.t('advertisement.callingButton')}
        onCancel={handleCancel}
        onOk={onOk}
        loading={isLoading}
        isShowCancel
        okText={'Save'}
        cancelText={'Cancel'}
        isScrollY={false}
        className={undefined}
        resetPaddingTopBody={undefined}
        resetPaddingBottomBody={undefined}
        isDisabled={undefined}
        width={560}
        visible={visible}
      >
        <ModalContainerStyle>
          <Form
            form={form}
            initialValues={{
              name: '',
              destinationType: '',
            }}
          >
            <LabelWithTooltip
              label={i18next.t('advertisement.callingButtonName')}
              tooltip={i18next.t('advertisement.tooltipMessage.maximum', {
                max: 18,
              })}
            />
            <RestInputItem
              required
              source="name"
              placeholder="advertisement.input.callingButton"
              rules={[
                {
                  max: 18,
                  message: i18next.t('advertisement.tooltipMessage.maximum', {
                    max: 18,
                  }),
                },
              ]}
            />
            <Form.Item
              label={i18next.t('advertisement.destinationType')}
              name="destinationType"
              rules={[{ required: true, message: i18next.t('error.required') }]}
            >
              <Radio.Group value={'callingButton'} className="w-full">
                <div className="column-items">
                  {destinationType?.map((item) => (
                    <div key={item?.value} className="break-inside-avoid my-6">
                      <Radio value={item?.name}>
                        <Typography.Text>{item?.name}</Typography.Text>
                      </Radio>
                    </div>
                  ))}
                </div>
              </Radio.Group>
            </Form.Item>
          </Form>
        </ModalContainerStyle>
      </ModalCustom>
    );
  },
);

export default AddNewCallingButtonModal;

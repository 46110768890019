import React from 'react';
import { Avatar, Progress, Rate, Space } from 'antd';
import KindiRating from 'components/common/KindiRating';
import { roundHalf } from 'utils/textUtils';
import i18next from 'i18next';
import { EMPTY_STRING, CHARACTER_DASH } from 'configs/localData/constant';
import { formatMoney } from 'utils/tools';

const useFormat = () => {
  const formatName = (data) => (
    <Space>
      {data?.logo && <Avatar src={data?.logo} size={48} />}
      <Space className={data?.logo ? EMPTY_STRING : 'ml-55'}>
        {data?.name}
      </Space>
    </Space>
  );

  const formatMarketShare = (data) =>
    data ? (
      <Progress percent={data} format={(data) => `${data}%`} />
    ) : (
      CHARACTER_DASH
    );

  const formatKindiRating = (data) =>
    data ? <KindiRating value={data} /> : CHARACTER_DASH;

  const formatReviewScore = (data) =>
    data ? (
      <>
        <Rate allowHalf disabled value={roundHalf(data)} />
        <span className="fw-bold text-primary ml-6">{data}</span>
      </>
    ) : (
      CHARACTER_DASH
    );

  const formatAverageNumberOfReviews = (data) =>
    data ? (
      <>
        {data}
        <span className="ml-6">
          {Number(data) === 1
            ? i18next.t('companyBenchmarking.review')
            : i18next.t('companyBenchmarking.reviews')}
        </span>
      </>
    ) : (
      CHARACTER_DASH
    );

  const formatColumnData = (data) => data || CHARACTER_DASH;

  const formatRevenuePotential = (data) => (data ? `$${data}` : CHARACTER_DASH);

  const formatPercentColumnData = (data) =>
    data ? `${data}%` : CHARACTER_DASH;

  const formatValueColumData = (data) =>
    data ? formatMoney(data) : CHARACTER_DASH;

  const formatRanking = (record, orderBy) => {
    const sortField = orderBy?.split('-')?.join('');
    if (!orderBy || sortField === 'name') {
      return null;
    }
    return record.ranking;
  };

  return {
    formatName,
    formatMarketShare,
    formatKindiRating,
    formatReviewScore,
    formatAverageNumberOfReviews,
    formatRanking,
    formatColumnData,
    formatRevenuePotential,
    formatPercentColumnData,
    formatValueColumData,
  };
};

export default useFormat;

import { keyBy } from 'lodash-es';
import { createSelector } from 'reselect';

const getRawConfigName = (state, name) => state.config?.data?.[name];

const getRawServiceTypes = (state) => state.config?.data?.ServiceType;

const getRawServiceAgeGroups = (state) => state.config?.data?.serviceAgeGroup;

const getRawDocumentTypes = (state) => state.config?.data?.documentType;

const getRawSubscriptions = (state) => state.config?.data?.CRMPackage;

const getRawSubscriptionsConfig = (state) =>
  state.config?.data?.subscriptionsConfig;

const getRawStateOfAustralia = (state) => state.config?.data?.stateOfAustralia;

const getRawLoadingConfig = (state) => state.config?.loading;

const getRawCentreSubscription = (state) =>
  state.auth.centreLevelCRMPackageName;

const getRawMultiCentreLevelCRMPackage = (state) =>
  state.auth.data?.multiCentreLevelCRMPackage;

const getRawRolePermissionsConfig = (state) =>
  state.config.data?.rolePermissionsConfig;

const getRawRolesArr = (state) => state.config.data?.role;

const getRawSessionTypes = (state) => state.config.data?.sessionTypes;

const getRawJobApplicationStatus = (state) =>
  state.config.configV2?.applicationStatus;

const getRawJobRoles = (state) => state.config.configV2?.jobRoles;

const getRawJobTypes = (state) => state.config.configV2?.jobTypes;

const getRawBrands = (state) => state.config.configV2?.brands;

const getRawStates = (state) => state.config.configV2?.states;

const getRawWorkplaceType = (state) => state.config.configV2?.workplaceType;

const getRawQualifications = (state) => state.config.configV2?.qualifications;

const getRawApplicationDeclinedReasons = (state) =>
  state.config.configV2?.applicationDeclinedReasons;

export const getConfigByName = createSelector(
  [getRawConfigName],
  (config) => config || [],
);

export const getServiceTypes = createSelector(
  [getRawServiceTypes],
  (data) => data || [],
);

export const getServiceAgeGroups = createSelector(
  [getRawServiceAgeGroups],
  (data) => data || [],
);

export const getDocumentTypes = createSelector(
  [getRawDocumentTypes],
  (data) => data || [],
);

export const getSubscriptionsArr = createSelector(
  [getRawSubscriptions],
  (data) => data || [],
);

export const getSubscriptionsForCentres = createSelector(
  [
    getRawCentreSubscription,
    getRawMultiCentreLevelCRMPackage,
    getRawSubscriptions,
  ],
  (centreLevelCRMPackageName, multiCentreLevelCRMPackage, data) => {
    if (centreLevelCRMPackageName)
      return data?.map((item) =>
        item.name === centreLevelCRMPackageName
          ? { ...item, isSignup: true }
          : item,
      );

    return data?.map((item) =>
      item.name === multiCentreLevelCRMPackage
        ? { ...item, isSignup: true }
        : item,
    );
  },
);

export const getSubscriptionsConfig = createSelector(
  [getRawSubscriptionsConfig],
  (data) => data || {},
);

export const getStateOfAustralia = createSelector(
  [getRawStateOfAustralia],
  (data) => data || {},
);

export const getLoadingConfig = createSelector(
  [getRawLoadingConfig],
  (data) => data,
);

export const getRolePermissionsConfig = createSelector(
  [getRawRolePermissionsConfig],
  (data) => data,
);

export const getRolesArr = createSelector([getRawRolesArr], (data) => data);

export const getRolesObjKey = createSelector(
  [getRawRolesArr],
  (data) => keyBy(data, 'id') || {},
);

export const getSessionTypes = createSelector(
  [getRawSessionTypes],
  (data) => data || [],
);

export const getJobApplicationStatus = createSelector(
  [getRawJobApplicationStatus],
  (data) => data || [],
);

export const getJobRoles = createSelector(
  [getRawJobRoles],
  (data) => data || [],
);

export const getJobTypes = createSelector(
  [getRawJobTypes],
  (data) => data || [],
);

export const getBrands = createSelector([getRawBrands], (data) => data || []);

export const getStates = createSelector([getRawStates], (data) => data || []);

export const getWorkplaceType = createSelector(
  [getRawWorkplaceType],
  (data) => data || [],
);

export const getQualifications = createSelector(
  [getRawQualifications],
  (data) => data || [],
);

export const getApplicationDeclinedReasons = createSelector(
  [getRawApplicationDeclinedReasons],
  (data) => data || [],
);

import styled from 'styled-components';

export const ExportBtnStyle = styled.div`
  .ant-btn[disabled],
  .ant-btn[disabled]:hover,
  .ant-btn[disabled]:focus,
  .ant-btn[disabled]:active {
    .anticon {
      opacity: 0.5;
    }
    color: #aba7a7;
    background: #fff;
  }
`;

import React from 'react';
import { isEmpty } from 'lodash-es';
import PropTypes from 'prop-types';
import { Avatar, Divider } from 'antd';
import i18next from 'i18next';
import { UserOutlined } from '@ant-design/icons';

import { formatDateFromNow } from 'utils/textUtils';
import { getSunMoonTime } from 'utils/formatFieldUtils';
import { getUrlIfyMessage } from 'utils/dataUtils';
import useIsSenderMessage from 'hooks/useIsSenderMessage';
import ListFileMessage from './ListFileMessage';

const MessageItem = ({ message, isShowAvatar }) => {
  const isSender = useIsSenderMessage(message.senderId);

  return (
    <div
      id={message?.id}
      className={`message-block ${isSender ? 'sender' : 'receiver'}`}
    >
      <div className="time-message-title">
        {formatDateFromNow(message?.createdAt)}
        {getSunMoonTime(message?.createdAt)}
      </div>
      <div className="wrapper-content-message">
        {isShowAvatar && (
          <Avatar
            size={35}
            src={message?.senderAvatar}
            icon={<UserOutlined />}
          />
        )}
        <div className="message-content">
          <div className="bubble">
            {message.informationRequestId && (
              <div className="title-info-request">
                <div className="title-info-request__name">
                  {i18next.t('informationRequests.title')}
                </div>
                <Divider />
              </div>
            )}
            <div className="text">
              <div
                dangerouslySetInnerHTML={{
                  __html: getUrlIfyMessage(message?.message),
                }}
                className="message-text"
              />
              {!isEmpty(message?.files) && (
                <ListFileMessage files={message?.files} />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

MessageItem.propTypes = {
  message: PropTypes.object,
  isShowAvatar: PropTypes.bool,
};

MessageItem.defaultProps = {
  message: {},
};

export default MessageItem;

import React, { useMemo, useCallback } from 'react';
import { Input } from 'antd';
import i18next from 'i18next';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useLocation, useHistory } from 'react-router';
import { SearchOutlined } from '@ant-design/icons';
import { getFilterFromUrl, getSearch } from 'utils/tools';

import { SearchInputStyles } from './styles';

const { Search } = Input;

const SearchInput = ({ searchHeader }) => {
  const { search, pathname } = useLocation();

  const { push } = useHistory();

  const dispatch = useDispatch();

  const filterObj = useMemo(() => getFilterFromUrl(search), [search]);

  const onSearch = useCallback(
    (value) => {
      if (searchHeader?.actionSearch) {
        const restValue = value?.trim();
        dispatch(
          searchHeader.actionSearch({
            data: {
              q: restValue,
            },
          }),
        );
        push(`${pathname}?${getSearch({ ...filterObj, q: restValue })}`);
      }
    },
    [searchHeader], // eslint-disable-line
  );

  return (
    <SearchInputStyles>
      <Search
        className="search-header"
        placeholder={i18next.t(searchHeader.placeholder)}
        onSearch={onSearch}
        style={{ width: 400 }}
        allowClear
        suffix={<SearchOutlined />}
        defaultValue={filterObj?.q}
      />
    </SearchInputStyles>
  );
};

SearchInput.propTypes = {
  searchHeader: PropTypes.object,
};

export default SearchInput;

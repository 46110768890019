import styled from 'styled-components';

const LegendStyles = styled.div`
  display: flex;
  flex-wrap: wrap;
  .custom-extra {
    margin-bottom: 20px;
    width: 100%;
  }
  .legend-item {
    display: flex;
    align-items: center;
    margin-left: 10px;
    margin-bottom: 10px;
  }
  .dot {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    margin-right: 5px;
  }
  .text-legend {
    line-height: normal;
  }
`;

export default LegendStyles;

import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { persistReducer } from 'redux-persist';
import { baseApi } from 'redux/@rtkQuery/baseApi';
import {
  authPersistConfig,
  jobListingPersistConfig,
  jobTemplatesPersistConfig,
  staffProfileConfig,
  advertisementPersistConfig,
} from 'redux/persistConfigs';
import auth from './auth/slice';
import modal from './modal/slice';
import dashboard from './dashboard/slice';
import notificationsAndAlerts from './notificationsAndAlerts/slice';
import LGA from './LGA/slice';
import brands from './brands/slice';
import regions from './regions/slice';
import contacts from './contacts/slice';
import transactions from './transactions/slice';
import customers from './customers/slice';
import applications from './applications/slice';
import centreServices from './centreServices/slice';
import enquiries from './enquiries/slice';
import centres from './centres/slice';
import users from './users/slice';
import notifications from './notifications/slice';
import reference from './referenceData/slice';
import config from './config/slice';
import roles from './roles/slice';
import searchHeader from './searchHeader/slice';
import subscriptions from './subscriptions/slice';
import resources from './resources/slice';
import automatedResponses from './automatedResponses/slice';
import appSettings from './appSettings/slice';
import centresOfMeBasic from './centresOfMeBasic/slice';
import providersOfMeBasic from './providersOfMeBasic/slice';
import companiesOfMeBasic from './companiesOfMeBasic/slice';
import centresOfUser from './centresOfUser/slice';
import providersOfUser from './providersOfUser/slice';
import companiesOfUser from './companiesOfUser/slice';
import unownedCentresOfUser from './unownedCentresOfUser/slice';
import unownedProvidersOfUser from './unownedProvidersOfUser/slice';
import unownedCompaniesOfUser from './unownedCompaniesOfUser/slice';
import providersOfCompany from './providersOfCompany/slice';
import priceBenchmarking from './priceBenchmarking/slice';
import companyBenchmarking from './companyBenchmarking/slice';
import brandBenchmarking from './brandBenchmarking/slice';
import companyBrands from './companyBrands/slice';
import jobTemplates from './jobs/jobTemplates/slice';
import companyProfile from './jobs/companyProfile/slice';
import jobApplications from './jobs/jobApplications/slice';
import jobListings from './jobs/jobListings/slice';
import jobApplicationMailbox from './jobs/jobApplications/mailbox/slice';
import legalDocuments from './legalDocuments/slice';
import educatorStaffProfile from './staffProfile/slice';
import marketingMetrics from './marketingMetrics/slice';
import exportProgress from './exportProgress/slice';
import advertisement from './advertisement/slice';
import './@rtkQuery/enhanceGeneratedApi';

export default (history) =>
  combineReducers({
    [baseApi.reducerPath]: baseApi.reducer,
    router: connectRouter(history),
    auth: auth ? persistReducer(authPersistConfig, auth) : auth,
    jobTemplates: persistReducer(jobTemplatesPersistConfig, jobTemplates),
    jobListings: persistReducer(jobListingPersistConfig, jobListings),
    staffProfile: persistReducer(staffProfileConfig, educatorStaffProfile),
    advertisement: persistReducer(advertisementPersistConfig, advertisement),
    automatedResponses,
    appSettings,
    dashboard,
    modal,
    config,
    reference,
    brands,
    LGA,
    regions,
    contacts,
    transactions,
    customers,
    applications,
    centreServices,
    enquiries,
    centres,
    users,
    roles,
    searchHeader,
    subscriptions,
    resources,
    notifications,
    notificationsAndAlerts,
    centresOfMeBasic,
    providersOfMeBasic,
    centresOfUser,
    providersOfUser,
    companiesOfUser,
    unownedCentresOfUser,
    unownedProvidersOfUser,
    companiesOfMeBasic,
    unownedCompaniesOfUser,
    providersOfCompany,
    priceBenchmarking,
    companyBenchmarking,
    brandBenchmarking,
    companyBrands,
    companyProfile,
    jobApplications,
    jobApplicationMailbox,
    legalDocuments,
    exportProgress,
    marketingMetrics,
    educatorStaffProfile,
  });

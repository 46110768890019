import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import i18next from 'i18next';
import { Avatar } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import { getCentresObjKeyById } from 'redux/auth/selectors';

import { GENDERS } from 'configs/localData';
import { formatDateApplication } from 'utils/textUtils';
import { formatMoney } from 'utils/tools';
import { BlockApplication } from './styles';

const BlockInfo = ({ application }) => {
  const child = application?.child || {}; // eslint-disable-line

  const genderItem = useMemo(
    () =>
      GENDERS.find((gender) => gender.value === child?.gender?.toLowerCase()),
    [child],
  );

  const centreName =
    useSelector(getCentresObjKeyById)?.[application?.centreId]?.name;

  return (
    <BlockApplication>
      <div className="child-info">
        <div className="avatar-wrapper">
          <Avatar size={45} src={child.avatar} alt="" icon={<UserOutlined />} />
          {genderItem?.icon && (
            <genderItem.icon
              className="gender-icon"
              style={{
                color: genderItem.color,
                backgroundColor: genderItem.background,
                transform: genderItem.transform,
              }}
            />
          )}
        </div>

        <div className="name-user">
          {`${child.firstName || ''} ${child.lastName || ''}`}
        </div>
      </div>
      <div className="item-info">
        <span className="title-item">
          {`${i18next.t('applications.applied')}:`}
        </span>
        <span>{formatDateApplication(application.submittedAt)}</span>
      </div>
      <div className="item-info">
        <span className="title-item">
          {`${i18next.t('applications.value')}:`}
        </span>
        <span>
          {`${i18next.t('currencyUnit.sign')}${formatMoney(
            application?.careOption?.value,
          )} ${i18next.t('text.perAnnum')}`}
        </span>
      </div>
      <div className="item-info">
        <span className="title-item">
          {`${i18next.t('applications.start')}:`}
        </span>
        <span>{formatDateApplication(application?.careOption?.startDay)}</span>
      </div>
      <div className="item-info">
        <span className="title-item">
          {`${i18next.t('centres.centreName')}:`}
        </span>
        <span>{centreName}</span>
      </div>
    </BlockApplication>
  );
};

BlockInfo.propTypes = {
  application: PropTypes.object,
};

BlockInfo.defaultProps = {
  application: {},
};

export default BlockInfo;

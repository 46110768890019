import { createAsyncThunk } from '@reduxjs/toolkit';
import { apiWrapper } from 'utils/reduxUtils';
import {
  getAllAutomatedResponsesAllCentresApi,
  getAllAutomatedResponsesApi,
  updateAppSettingsAllCentresApi,
  updateAppSettingsApi,
} from 'api/appSettings';

export const MODEL_NAME = 'centres';

export const getAllAutomatedResponses = createAsyncThunk<any, any>(
  'automatedResponses/getAllAutomatedResponses',
  async (payload, thunkAPI) => {
    try {
      const response = await apiWrapper(
        {
          isShowProgress: true,
          isShowSuccessNoti: false,
          isCheckError: false,
        },
        getAllAutomatedResponsesApi,
        payload.id,
      );
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  },
);

export const updateAutomatedResponses = createAsyncThunk<any, any>(
  'automatedResponses/updateAutomatedResponses',
  async (payload, thunkAPI) => {
    try {
      const response = await apiWrapper(
        {
          isShowSuccessNoti: true,
          isShowProgress: true,
          isCheckError: false,
        },
        updateAppSettingsApi,
        payload.centreId,
        payload.key,
        payload.data,
      );
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  },
);

export const getAllAutomatedResponsesAllCentres = createAsyncThunk<any, any>(
  'automatedResponses/getAllAutomatedResponsesAllCentres',
  async (payload, thunkAPI) => {
    try {
      const response = await apiWrapper(
        {
          isShowProgress: true,
          isShowSuccessNoti: false,
          isCheckError: false,
        },
        getAllAutomatedResponsesAllCentresApi,
      );
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  },
);

export const updateAutomatedResponsesAllCentres = createAsyncThunk<any, any>(
  'automatedResponses/updateAutomatedResponsesAllCentres',
  async (payload, thunkAPI) => {
    try {
      const response = await apiWrapper(
        {
          isShowProgress: true,
          isShowSuccessNoti: true,
          isCheckError: false,
        },
        updateAppSettingsAllCentresApi,
        payload.key,
        payload.data,
      );
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  },
);

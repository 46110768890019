import React, { useState } from 'react';
import i18next from 'i18next';
import FormNormalStyles from 'containers/Centres/components/Modals/styles';
import { Form, Input } from 'antd';
import ModalCustom from 'components/common/ModalCustom';
import { useDispatch } from 'react-redux';
import { editAboutUsCard } from 'redux/jobs/companyProfile/actions';
import PropType from 'prop-types';

const AboutUsEditModal = ({ aboutUsCardData, onCancel, ...props }) => {
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const onOk = () => {
    form
      .validateFields()
      .then((formValues) => {
        setLoading(true);
        dispatch(editAboutUsCard(formValues)).finally(() => {
          setLoading(false);
          handleCancel();
        });
      })
      .catch((error) => {
        form.scrollToField(error.errorFields?.[0]?.name?.[0]);
      });
  };
  const handleCancel = () => {
    onCancel();
    form.resetFields();
  };
  return (
    <ModalCustom
      title={i18next.t('jobs.companyProfile.modal.aboutUs.title')}
      onCancel={handleCancel}
      onOk={onOk}
      loading={loading}
      {...props}
    >
      <FormNormalStyles>
        <Form form={form} layout="vertical" scrollToFirstError>
          <Form.Item
            name="description"
            label={i18next.t('jobs.companyProfile.modal.aboutUs.description')}
            initialValue={aboutUsCardData}
          >
            <Input.TextArea
              placeholder={i18next.t(
                'jobs.companyProfile.modal.aboutUs.description',
              )}
              rows={10}
            />
          </Form.Item>
        </Form>
      </FormNormalStyles>
    </ModalCustom>
  );
};

AboutUsEditModal.propTypes = {
  aboutUsCardData: PropType.string,
  onCancel: PropType.func,
};

export default AboutUsEditModal;

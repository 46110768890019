import React from 'react';
import i18next from 'i18next';
import { Tooltip } from 'antd';
import { getRecordData } from 'utils/tools';
import FormSelect, { FormSelectProps } from 'components/form/FormSelect';
import {
  InputContext,
  RestInputContext,
} from 'components/RestInput/RestInputContext';
import RestSelectStyles from './styles';

interface RestSelectProps extends FormSelectProps {
  isShowTooltip?: boolean;
  customDefaultValue?: (value: string) => void;
  defaultValue?: unknown;
}

const RestSelect = ({
  isShowTooltip,
  customDefaultValue,
  defaultValue,
  ...props
}: RestSelectProps) => {
  const getDefaultValue = (record) => {
    if (customDefaultValue) {
      return customDefaultValue(record);
    }

    const value = getRecordData(record, props.source);
    return value !== undefined && value !== null ? value : defaultValue;
  };

  return (
    <RestInputContext.Consumer>
      {({ record, form }: InputContext) => (
        <Tooltip
          placement="top"
          title={isShowTooltip ? i18next.t(props?.placeholder) : ''}
        >
          <RestSelectStyles className="select-form-wrapper">
            <FormSelect
              {...props}
              form={form}
              defaultValue={getDefaultValue(record)}
            />
          </RestSelectStyles>
        </Tooltip>
      )}
    </RestInputContext.Consumer>
  );
};

export default RestSelect;

import React, { useEffect, useCallback } from 'react';
import { PlusOutlined } from '@ant-design/icons';
import i18next from 'i18next';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { getProvidersOfUser } from 'redux/providersOfUser/actions';

import CustomButton from 'components/RestActions/CustomButton';
import ProvidersOfUserTable from './ProvidersOfUserTable';
import ProviderFilter from '../Form/ProviderFilter';

const ProvidersOfUserList = () => {
  const { push, location } = useHistory();

  const { id } = useParams();

  const dispatch = useDispatch();

  const retrieveList = useCallback(
    ({ filter = {}, isRefresh, keepFilter }) => {
      dispatch(
        getProvidersOfUser({
          id,
          data: {
            ...filter,
            filter: {
              ...filter.filter,
            },
          },
          options: {
            isRefresh,
            isOrderBy: false,
            keepFilter,
          },
        }),
      );
    },
    [], // eslint-disable-line
  );

  useEffect(() => {
    retrieveList({
      filter: {
        limit: 10,
        offset: 0,
        orderBy: 'name',
      },
      isRefresh: true,
    });
  }, []); // eslint-disable-line

  const redirectAddMoreProviders = () => {
    push(`${location.pathname}/addMoreProviders`);
  };

  return (
    <div>
      <ProviderFilter greyInput={false} retrieveList={retrieveList} />
      <div className="box-wrapper">
        <div className="flex-center-between mb-20">
          <div className="title-table">{i18next.t('providers.title')}</div>
          <CustomButton
            permissionKey="abilityChangeUserPermissions"
            isSkipSubscription
            action="isEdit"
            title="users.addMoreProviders"
            buttonProps={{
              icon: <PlusOutlined />,
              className: 'btn-second-primary',
            }}
            handleClick={redirectAddMoreProviders}
          />
        </div>
        <ProvidersOfUserTable retrieveList={retrieveList} />
      </div>
    </div>
  );
};

export default ProvidersOfUserList;

import React, { useMemo } from 'react';
import { PropTypes } from 'prop-types';
import TitleWithTooltip from 'components/common/TableTitleTooltip';
import TableWrapper from './styles';
import TitleSorter from '../TableTitleSorter';

const TableScrollCustom = ({
  isSorter,
  columns,
  dataSource,
  isSetRowKeyId,
  yScroll,
  isResetStyle,
  className,
  isShowingTooltip,
  ...props
}) => {
  const rowKey = (data = {}, index) => (isSetRowKeyId ? data.id : index);

  const columnsFormat = useMemo(() => {
    if (isSorter)
      return columns.map(({ title, ...column }) => ({
        ...column,
        title: column.sorter
          ? (sortOrder, sortColumn) => (
              <TitleSorter
                title={title}
                sortOrder={sortOrder}
                sortColumn={sortColumn}
                sortDesc={
                  sortOrder.sortColumn?.dataIndex === column.dataIndex
                    ? sortOrder.sortOrder
                    : column.defaultSortOrder
                }
              />
            )
          : title,
      }));

    if (isShowingTooltip) {
      return columns.map(({ title, tooltip, ...column }) => ({
        ...column,
        title: tooltip
          ? (sortOrder, sortColumn) => (
              <TitleWithTooltip
                title={title}
                sortOrder={sortOrder}
                sortColumn={sortColumn}
                sortDesc={
                  sortOrder.sortColumn?.dataIndex === column.dataIndex
                    ? sortOrder.sortOrder
                    : column.defaultSortOrder
                }
                tooltip={tooltip}
              />
            )
          : title,
      }));
    }
    return columns;
  }, [columns, isShowingTooltip, isSorter]);

  return (
    <TableWrapper
      showSorterTooltip={null}
      isResetStyle={isResetStyle}
      columns={columnsFormat}
      dataSource={dataSource}
      rowKey={rowKey}
      scroll={{ y: yScroll || 600 }}
      isSorter={isSorter}
      className={`${className || ''} ${isResetStyle ? '' : 'table-wrapper'}`}
      pagination={false}
      {...props}
    />
  );
};

TableScrollCustom.propTypes = {
  columns: PropTypes.array,
  dataSource: PropTypes.array,
  isSetRowKeyId: PropTypes.bool,
  yScroll: PropTypes.any,
  isSorter: PropTypes.bool,
  isResetStyle: PropTypes.bool,
  className: PropTypes.string,
  isShowingTooltip: PropTypes.bool,
};

TableScrollCustom.defaultProps = {};

export default TableScrollCustom;

import { RootState } from 'redux/store';
import { createSelector } from 'reselect';

const getExportList = (state: RootState) => state.exportProgress.exportList;
const getVisibleStateDownloadPopup = (state: RootState) =>
  state.exportProgress.visible;

export const selectExportList = createSelector(
  [getExportList],
  (data) => data || [],
);

export const selectVisibleStateDownloadPopup = createSelector(
  [getVisibleStateDownloadPopup],
  (data) => data,
);

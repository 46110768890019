import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from 'antd';
import i18next from 'i18next';

const JobApplicationGeneralInfoItem = ({
  icon,
  content,
  titleTooltip,
  className,
}) => (
  <div className="text-16 mt-16 flex items-start">
    <span className="mr-10 text-18">{icon}</span>
    <Tooltip title={i18next.t(titleTooltip)}>
      <span className={className}>{content}</span>
    </Tooltip>
  </div>
);

JobApplicationGeneralInfoItem.propTypes = {
  icon: PropTypes.node,
  content: PropTypes.string,
  titleTooltip: PropTypes.string,
  className: PropTypes.object,
};

export default JobApplicationGeneralInfoItem;

import React, { useState, forwardRef, useImperativeHandle } from 'react';
import i18next from 'i18next';
import Radio from 'antd/lib/radio';
import Space from 'antd/lib/space';
import Button from 'antd/lib/button';
import ModalCustom from 'components/common/ModalCustom';
import { Form, Skeleton } from 'antd';
import { useParentApplicationConfigControllerGetArchivedReasonConfigQuery } from 'generated/apis';
import { useAppDispatch } from 'redux/hooks';
import { updateApplicationArchiveStatus } from 'redux/applications/actions';
import { useParams, useHistory } from 'react-router';

const ArchiveApplicationReasonModal = forwardRef((props, ref) => {
  const { push } = useHistory();
  const params = useParams();
  const dispatch = useAppDispatch();
  const [visible, setVisible] = useState<boolean>(false);
  const [applicationId, setApplicationId] = useState<string>();
  const [loading, setLoading] = useState(false);

  const [form] = Form.useForm();

  const { data, isLoading } =
    useParentApplicationConfigControllerGetArchivedReasonConfigQuery(null, {
      skip: !visible,
    });

  useImperativeHandle(
    ref,
    () => ({
      open: (id: string) => {
        setVisible(true);
        setApplicationId(id);
      },
    }),
    [],
  );

  const onSubmit = ({ reason }) => {
    if (!reason || !applicationId) {
      return;
    }
    setLoading(true);

    dispatch(
      updateApplicationArchiveStatus({
        id: applicationId,
        body: {
          archivedReason: reason,
        },
        refreshList: !params?.id,
      }),
    )
      .then((response) => {
        if (response?.payload?.data?.id && params?.id) {
          push('/applications/board');
        }
      })
      .finally(() => {
        setLoading(false);
        onCancel();
      });
  };

  const onCancel = () => {
    setVisible(false);
    form.resetFields();
  };

  return (
    <ModalCustom
      visible={visible}
      title={i18next.t('applications.archiveReason')}
      onCancel={onCancel}
      footer={null}
      resetPaddingBottomBody={false}
      width={600}
    >
      <div>
        <div className="text-gray-700 mb-20">
          {i18next.t('applications.reasonArchiveDesc')}
        </div>
        <Form form={form} onFinish={onSubmit}>
          <Skeleton active loading={isLoading}>
            <Form.Item
              name="reason"
              rules={[
                {
                  required: true,
                  message: i18next.t('applications.requiredArchiveReason'),
                },
              ]}
            >
              <Radio.Group>
                <Space direction="vertical" size={10}>
                  {data?.archivedReason?.map((item) => (
                    <Radio key={item.value} value={item.value}>
                      {item.text}
                    </Radio>
                  ))}
                </Space>
              </Radio.Group>
            </Form.Item>
          </Skeleton>
        </Form>

        <Button
          onClick={form.submit}
          type="primary"
          className="w-full mt-20 fw-600"
          loading={loading}
        >
          {i18next.t('button.archive')}
        </Button>
      </div>
    </ModalCustom>
  );
});

export default ArchiveApplicationReasonModal;

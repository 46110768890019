import React, { useMemo } from 'react';
import { Avatar } from 'antd';
import PropTypes from 'prop-types';
import { UserOutlined } from '@ant-design/icons';
import { GENDERS } from 'configs/localData';
import { ChildAvatarStyles } from './styles';

const ChildAvatar = ({ child }) => {
  const genderItem = useMemo(
    () =>
      GENDERS.find((gender) => gender.value === child?.gender?.toLowerCase()),
    [child],
  );

  return (
    <ChildAvatarStyles className="avatar-name-child">
      <div className="avatar-wrapper">
        <Avatar size={100} src={child.avatar} icon={<UserOutlined />} />
        {genderItem?.icon && (
          <genderItem.icon
            className="gender-icon"
            style={{
              color: genderItem.color,
              backgroundColor: genderItem.background,
              transform: genderItem.transform,
            }}
          />
        )}
      </div>
      <div className="name-user">
        {`${child.firstName || ''} ${child.lastName || ''}`}
      </div>
    </ChildAvatarStyles>
  );
};

ChildAvatar.propTypes = {
  child: PropTypes.object,
};

export default ChildAvatar;

import React, { useState } from 'react';
import {
  BookCircleIcon,
  EducationIcon,
  TeacherFilledIcon,
} from 'components/common/SVGIcon';
import { StaffProfileModel } from 'types/staffProfiles';
import EducationItem from 'containers/Centres/components/CentreTabs/EducatorStaffProfilesTab/Create/Steps/EducationAndQualification/EducationItem';
import ContentCard from 'components/CardItemProfile';
import i18next from 'i18next';
import { Form, Modal } from 'antd';
import { useEditStaffProfilesMutation } from 'redux/@rtkQuery/educatorStaffProfile';
import { isNumber, omit, sortBy } from 'lodash-es';
import EmptyContainer from 'containers/Centres/components/CentreTabs/EducatorStaffProfilesTab/components/EmptyContainer';
import { CloseCircleFilled } from '@ant-design/icons';
import EducationFormModal from 'containers/Centres/components/CentreTabs/EducatorStaffProfilesTab/Create/Steps/EducationAndQualification/EducationFormModal';
import {
  ITEM_EDUCATION_AT_LEAST,
  OTHER,
} from 'containers/Centres/components/CentreTabs/EducatorStaffProfilesTab/constants';
import ProfileDetailSection from './ProfileDetailSection';

const { error } = Modal;

interface Props {
  staffProfile?: StaffProfileModel;
  disable?: boolean;
}

const EducationQualificationDetail = ({ staffProfile, disable }: Props) => {
  const [visible, setVisible] = useState(false);
  const [formModalStep3] = Form.useForm();
  const [editIndexCurrent, setEditIndexCurrent] = useState(null);
  const [editStaffProfile] = useEditStaffProfilesMutation();
  const educations = staffProfile?.educations ?? [];
  const [isOther, setIsOther] = useState(false);

  const defaultDataUpdate = {
    id: staffProfile?.id,
    centreId: staffProfile?.centreId,
    isDraft: staffProfile?.isDraft,
    currentStep: staffProfile?.currentStep,
  };

  const openEditModal = (data, index) => {
    formModalStep3.resetFields();
    setVisible(true);
    setEditIndexCurrent(index);
    formModalStep3.setFieldsValue(data);
  };

  const onDelete = (index) => {
    if (educations?.length === ITEM_EDUCATION_AT_LEAST) {
      error({
        title: i18next.t('educatorStaffProfile.messageDeleteAllQualification'),
        icon: <CloseCircleFilled className="text-error" />,
      });
      return;
    }
    const payloadList = [...educations];
    payloadList.splice(index, 1);

    editStaffProfile({
      ...defaultDataUpdate,
      educations: payloadList,
    });
  };

  const onSave = () => {
    formModalStep3.validateFields().then((values) => {
      const [name, rank] = values.name?.split('-') ?? [];

      let formNewValue = {
        ...values,
        name,
        rank: Number(rank ?? educations[editIndexCurrent]?.rank),
      };

      if (formNewValue?.fieldOfStudy !== OTHER)
        formNewValue = omit(formNewValue, ['otherFieldOfStudy']);

      let payloadList = [...educations];

      if (isNumber(editIndexCurrent)) {
        if (payloadList[editIndexCurrent]?.rank === formNewValue?.rank) {
          payloadList[editIndexCurrent] = formNewValue;
        } else {
          payloadList.splice(editIndexCurrent, 1);
          payloadList = [...payloadList, formNewValue];
          payloadList = sortBy(payloadList, 'rank').reverse();
        }
      } else {
        payloadList = [...payloadList, formNewValue];
        payloadList = sortBy(payloadList, 'rank').reverse();
      }

      editStaffProfile({
        ...defaultDataUpdate,
        educations: payloadList,
      });
      setVisible(false);
    });
  };

  const onSaveAddAnother = () => {
    formModalStep3.validateFields().then((values) => {
      const [name, rank] = values.name?.split('-') ?? [];
      let formNewValue = {
        ...values,
        name,
        rank: Number(rank),
      };

      if (formNewValue?.fieldOfStudy !== OTHER) {
        formNewValue = omit(formNewValue, ['otherFieldOfStudy']);
      }

      let payloadList = [...educations, formNewValue];
      payloadList = sortBy(payloadList, 'rank').reverse();
      editStaffProfile({
        ...defaultDataUpdate,
        educations: payloadList,
      });
      formModalStep3.resetFields();
      setIsOther(false);
    });
  };

  const openAddModal = () => {
    formModalStep3.resetFields();
    setVisible(true);
    setEditIndexCurrent(null);
  };

  return (
    <ProfileDetailSection
      icon={<TeacherFilledIcon className="text-20 text-primary" />}
      title={i18next.t('educatorStaffProfile.educationAndQualification')}
      onAdd={openAddModal}
      disabled={disable}
    >
      <div className="flex gap-24 flex-col">
        {staffProfile.educations?.length ? (
          staffProfile.educations.map((item, index) => (
            <ContentCard
              key={`${staffProfile.id}-educations-${index}`}
              Icon={<BookCircleIcon />}
              onEdit={() => openEditModal(item, index)}
              onDelete={() => onDelete(index)}
              deleteConfirmTitle="educatorStaffProfile.deleteEducationTitle"
              deleteConfirmContent="educatorStaffProfile.deleteEducationContent"
              isWarningDelete={
                !(educations?.length === ITEM_EDUCATION_AT_LEAST)
              }
              disabled={disable}
            >
              <EducationItem {...item} />
            </ContentCard>
          ))
        ) : (
          <EmptyContainer
            Icon={EducationIcon}
            description="educatorStaffProfile.noDataDescriptionEducation"
            buttonTitle="educatorStaffProfile.addEducation"
            onAdd={openAddModal}
            disabled={disable}
          />
        )}
      </div>
      <EducationFormModal
        formModalStep3={formModalStep3}
        visible={visible}
        title="educatorStaffProfile.educationAndQualification"
        editIndexCurrent={editIndexCurrent}
        setVisible={setVisible}
        onSave={onSave}
        onSaveAddAnother={onSaveAddAnother}
        currentEducation={educations?.[editIndexCurrent]}
        isOther={isOther}
        setIsOther={setIsOther}
      />
    </ProfileDetailSection>
  );
};

export default EducationQualificationDetail;

import { Steps } from 'antd';
import styled, { css } from 'styled-components';
import { ReactNode } from 'react';

interface StepsProps {
  children: ReactNode;
  isDispute?: boolean;
}

export const StepsStyled = styled(Steps)<StepsProps>`
  max-width: 750px;
  margin-top: 35px;

  @media only screen and (min-width: 2000px) {
    .ant-steps-item-tail {
      padding: 11.5px 20px 11.5px 40px;
    }
    .ant-steps-item-container {
      .ant-steps-item-icon {
        width: 50px;
        height: 50px;
        font-size: 26px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .ant-steps-item-content {
        .ant-steps-item-title {
          font-size: 26px;
          padding-left: 16px;
        }
      }
    }
  }

  @media only screen and (min-width: 3000px) {
    .ant-steps-item-tail {
      padding: 11.5px 20px 11.5px 50px;
    }
    .ant-steps-item-container {
      .ant-steps-item-icon {
        width: 60px;
        height: 60px;
        font-size: 30px;
      }
      .ant-steps-item-content {
        .ant-steps-item-title {
          padding-left: 22px;
          font-size: 30px;
        }
      }
    }
  }

  @media only screen and (min-width: 2400px) {
    --font-size: 25px;
  }

  @media only screen and (min-width: 3000px) {
    --font-size: 30px;
  }

  @media only screen and (min-width: 3400px) {
    --font-size: calc(22px + 0.390625vw);
  }

  .ant-steps-item-title {
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
  }

  .ant-steps-item-wait,
  .ant-steps-item-finish {
    .ant-steps-item-title {
      font-weight: 400;
      color: #98a2b3 !important;
    }
  }

  .ant-steps-item-wait .ant-steps-item-container {
    cursor: default !important;
  }

  .ant-steps-item-wait .ant-steps-item-icon {
    background-color: #98a2b3;
    border-color: #98a2b3 !important;

    .ant-steps-icon {
      color: #fff !important;
    }
  }

  @media (max-width: 585px) {
    max-width: 400px;
  }

  ${({ isDispute }) =>
    isDispute &&
    css`
      .ant-steps-item-process
        > .ant-steps-item-container
        > .ant-steps-item-icon {
        background: #32a4fc;
        border-color: #32a4fc;
      }

      .ant-steps-item-finish
        > .ant-steps-item-container
        > .ant-steps-item-tail::after {
        background-color: #32a4fc;
      }

      .ant-steps-item-finish .ant-steps-item-icon {
        border-color: #32a4fc !important;
      }

      .ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
        color: #32a4fc !important;
      }

      .ant-steps-item:not(.ant-steps-item-wait)
        .ant-steps-item-container:hover
        .ant-steps-item-title {
        color: #32a4fc !important;
      }
    `}
`;

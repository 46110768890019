import React from 'react';
import i18next from 'i18next';
import {
  APPLICATION_STATUS,
  BILLING_PERIOD,
  ENQUIRIES_STATUS_CONST,
  ENQUIRY_TYPES_CONST,
} from 'configs/localData';
import Space from 'antd/lib/space';

import { MoonIcon, SunIcon } from 'components/common/SVGIcon';
import TagCustom from 'components/common/TagCustom';
import { checkSunTime, formatMoney } from './tools';
import { formatDateTimeTable } from './textUtils';

export const formatKindiCareFee = (data, record) => {
  const restText = BILLING_PERIOD.find(
    (item) => item.value === record.billingPeriod,
  )?.text;

  return `$${formatMoney(data)} / ${
    record?.quantity > 1 ? `${record?.quantity} ` : ''
  }${restText ? i18next.t(restText) : record.billingPeriod}`;
};

export const getSunMoonTime = (time, { sunColor, moonColor } = {}) => {
  if (!time) return null;
  return checkSunTime(time) ? (
    <SunIcon
      style={{ fontSize: 20, color: sunColor ?? 'orange', marginLeft: 5 }}
    />
  ) : (
    <MoonIcon
      style={{ fontSize: 20, color: moonColor ?? '#32A4FC', marginLeft: 5 }}
    />
  );
};

export const formatApplicationStatus = (data) => {
  const restItem =
    APPLICATION_STATUS?.find((item) => item.value === data) || {};

  return restItem ? (
    <TagCustom
      backgroundColor={restItem.backgroundColor}
      color={restItem.color}
      text={restItem.text}
    />
  ) : (
    i18next.t('error.waitingUpdate')
  );
};

export const formatEnquiryType = (type) => {
  const restItem = ENQUIRY_TYPES_CONST[type];

  return restItem ? (
    <TagCustom
      backgroundColor={restItem.backgroundColor}
      color={restItem.dotColor}
      text={restItem.text}
      IconCPN={restItem.IconCPN}
    />
  ) : (
    i18next.t('error.waitingUpdate')
  );
};

const getTagCustom = (item) => (
  <TagCustom
    backgroundColor={item.backgroundColor}
    color={item.fillColor}
    text={item.text}
    IconCPN={item.IconCPN}
  />
);

export const formatEnquiryStatus = (
  centreHasNewMessage,
  centreResponded,
  centreEnquiryStatus,
) => {
  const displayNotAchivedStatus = () => (
    <>
      {centreHasNewMessage
        ? getTagCustom(ENQUIRIES_STATUS_CONST.unread)
        : getTagCustom(ENQUIRIES_STATUS_CONST.read)}
      {centreResponded
        ? getTagCustom(ENQUIRIES_STATUS_CONST.responded)
        : getTagCustom(ENQUIRIES_STATUS_CONST.notResponded)}
    </>
  );

  return (
    <Space direction="vertical">
      {centreEnquiryStatus === ENQUIRIES_STATUS_CONST.archived.value
        ? getTagCustom(ENQUIRIES_STATUS_CONST.archived)
        : displayNotAchivedStatus()}
    </Space>
  );
};

export const formatSubscription = (data) =>
  data ? (
    <TagCustom color="#32A4FC" backgroundColor="#E9F4FF" name={data} />
  ) : null;

export const formatNightDayTable = (time) => (
  <div>
    {formatDateTimeTable(time)}
    {getSunMoonTime(time, { sunColor: '#fdb374', moonColor: '#64c9fd' })}
  </div>
);

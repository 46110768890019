import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import {
  listMessagesSelector,
  resourceDataMessagesSelector,
} from 'redux/enquiries/selectors';
import MessageBody from 'containers/Enquiries/components/MessageDetail/MessageBody';

const BodyComment = ({ retrieveList }) => {
  const messages = useSelector(listMessagesSelector);
  const resourceData = useSelector(resourceDataMessagesSelector);

  return (
    <MessageBody
      listMessages={messages}
      retrieveList={retrieveList}
      resourceData={resourceData}
      isShowAvatar={false}
    />
  );
};

BodyComment.propTypes = {
  retrieveList: PropTypes.func,
};

export default BodyComment;

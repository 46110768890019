import React from 'react';
import { Form, Spin } from 'antd';
import i18next from 'i18next';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import {
  goToPrevStep,
  resetCreateJobTemplateStep,
  resetJobTemplatePayload,
} from 'redux/jobs/jobTemplates/slice';
import {
  getJobTemplateConfigSelector,
  getJobTemplatePayloadSelector,
  getLoadingCreateJobTemplate,
} from 'redux/jobs/jobTemplates/selectors';
import { isEmpty } from 'lodash-es';
import {
  IS_PREFERRED,
  IS_REQUIRED,
  NO_EXPERIENCE_REQUIRED_NAME,
} from 'containers/JobTemplates/constants';
import useWindowAutoScroll from 'hooks/useWindowAutoScroll';
import {
  getIDFromRawString,
  getRowsApplicationSettingsStep,
  getValueFormSubmit,
} from 'containers/JobTemplates/utils';
import { createJobTemplate } from 'redux/jobs/jobTemplates/actions';
import { useHistory } from 'react-router';
import { setJobListingPayload } from 'redux/jobs/jobListings/slice';
import TableRadio from '../../TableRadio';
import FinalStepFooter from './FinalStepFooter';

const JobApplicationForm = ({ form }) => {
  useWindowAutoScroll();
  const dispatch = useDispatch();

  const config = useSelector(getJobTemplateConfigSelector);
  const payload = useSelector(getJobTemplatePayloadSelector);
  const { push } = useHistory();
  const createLoading = useSelector(getLoadingCreateJobTemplate);

  const dataSource = getRowsApplicationSettingsStep(payload, config);

  const onFinish = (
    { eligibility, experience, ...values },
    isPublishAndCreateJobListing,
  ) => {
    const noExperienceId = config?.experiences?.find(
      (item) => item?.name === NO_EXPERIENCE_REQUIRED_NAME,
    )?.id;

    const transformedData = {
      ...payload,
      ...getValueFormSubmit('qualification', values),
      ...getValueFormSubmit('certifications', values),
      ...getValueFormSubmit('immunisations', values),
      ...getValueFormSubmit('languages', values),
      ...getValueFormSubmit('skills', values),
      ...(!isEmpty(payload?.eligibility) && {
        eligibility: {
          ids: payload?.eligibility,
          isPreferred: eligibility === IS_PREFERRED,
          isRequired: eligibility === IS_REQUIRED,
        },
      }),
      ...(!isEmpty(payload?.features) && {
        features: Object.entries(payload?.features || {})
          ?.filter(([, value]) => value)
          ?.map(([rawId]) => ({ id: getIDFromRawString(rawId) })),
      }),
      ...(!isEmpty(payload?.experience) && {
        experience:
          payload?.experience !== noExperienceId
            ? {
                id: payload?.experience,
                isPreferred: experience[payload?.experience] === IS_PREFERRED,
                isRequired: experience[payload?.experience] === IS_REQUIRED,
              }
            : null,
      }),
    };

    dispatch(createJobTemplate(transformedData)).then(({ payload }) => {
      if (isEmpty(payload?.errors)) {
        dispatch(resetJobTemplatePayload());
        dispatch(resetCreateJobTemplateStep());
        if (isPublishAndCreateJobListing) {
          dispatch(setJobListingPayload({ templateId: payload?.result?.id }));
          push(`/job-listings/create/steps?id=${payload?.result?.id}`);
        } else {
          push(`/jobTemplates/${payload?.result?.id}/show/generalInformation`);
        }
      }
    });
  };

  const handleSubmitForm = ({ isPublishAndCreateJobListing }) => {
    form
      .validateFields()
      .then((values) => {
        onFinish(values, isPublishAndCreateJobListing);
      })
      .catch(() => {});
  };

  const handleGoBack = () => {
    dispatch(goToPrevStep());
  };

  return (
    <div>
      <Spin spinning={createLoading}>
        <p className="text-16">
          {i18next.t('jobs.createJobTemplateStep.jobApplicationSettingsDesc')}
        </p>
        <Form form={form}>
          <TableRadio
            dataSource={dataSource}
            rowKey={({ category, id }) => `${category}:${id}`}
          />
        </Form>
      </Spin>
      <FinalStepFooter
        handleSubmitForm={handleSubmitForm}
        handleGoToPrevStep={handleGoBack}
      />
    </div>
  );
};

JobApplicationForm.propTypes = {
  form: PropTypes.object,
};

export default JobApplicationForm;

import styled from 'styled-components';

const FormNormalStyles = styled.div`
  .ant-form-item-label {
    font-weight: bold;
    margin-top: 5px;
    label:after {
      content: '';
    }
  }
`;

export default FormNormalStyles;

export const FormCentrePhotosStyles = styled.div`
  .file-list-view {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  .form-item-upload-image {
    margin-bottom: 30px;
    label {
      font-weight: bold;
      font-size: 16px;
      line-height: 20px;
      color: ${({ theme }) => theme.text.mainL1};
    }
    label:after {
      content: '';
    }
    .ant-form-item-control-input {
      min-height: 150px;
    }
    .ant-upload.ant-upload-drag {
      background: ${({ theme }) => theme.background.content} !important;
      height: 150px;
      border-width: 2px;
      p.ant-upload-drag-icon .anticon {
        font-size: 28px !important;
        color: ${({ theme }) => theme.text.mainL3} !important;
      }
      p.ant-upload-text {
        font-size: 16px !important;
        line-height: 26px;
        color: ${({ theme }) => theme.text.mainL3} !important;
      }
      p.ant-upload-drag-icon {
        margin-bottom: 5px !important;
      }
    }
  }
`;

export const UploadImageItemStyles = styled.div`
  width: 150px;
  height: 150px;
  position: relative;
  margin: 10px;
  justify-content: center;
  .image-upload-view {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 10px;
  }
  .delete-icon-image {
    position: absolute;
    color: ${({ theme }) => theme.text.mainL2};
    font-size: 26px;
    right: -10px;
    top: -10px;
  }
  .wrapper-loading,
  .icon-error {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .icon-loading,
  .icon-error {
    font-size: 24px;
  }
  .icon-error {
    color: red;
  }
  img {
    display: none;
  }
  img[src] {
    display: block;
  }
`;

export const MoreInfoCentreModalStyles = styled.div`
  position: relative;
  .mobile-view-mode-btn {
    position: absolute;
    right: 0;
    top: 0;
    z-index: 2;
  }
  .form-item-editor .ant-form-item-control {
    margin-top: 30px;
  }
`;

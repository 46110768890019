import {
  IMAGE_TYPES,
  VIDEO_TYPES,
  AUDIO_TYPES,
  MAX_FILE_SIZE_IN_MB,
  MAX_FILE_DIMENSIONS,
} from 'configs/localData/constant';
import { notification } from 'antd';
import i18next from 'i18next';
import axios from 'axios';
import imageCompression from 'browser-image-compression';

export const isImageFile = (fileName) => {
  const extension = getExtensionFile(fileName);
  if (!extension) return false;
  return IMAGE_TYPES.includes(extension.toLocaleLowerCase());
};

export const isVideoFile = (fileName) => {
  const extension = getExtensionFile(fileName);
  if (!extension) return false;
  return VIDEO_TYPES.includes(extension.toLocaleLowerCase());
};

export const isAudioFile = (fileName) => {
  const extension = getExtensionFile(fileName);
  if (!extension) return false;
  return AUDIO_TYPES.includes(extension.toLocaleLowerCase());
};

export const getExtensionFile = (fileName) => {
  if (typeof fileName !== 'string' || fileName.indexOf('.') === -1) return '';
  return fileName.split('.').pop();
};

export const handleViewFile = async (record, callback = () => null) => {
  if (record?.url) {
    if (
      record.name &&
      /\.(xlsx|xls|doc|docx|ppt|pptx|pdf)$/i.test(record.name)
    ) {
      const data = {
        name: record.name,
        url: `//view.officeapps.live.com/op/embed.aspx?src=${encodeURIComponent(
          record.url,
        )}`,
      };
      if (record.name && /\.(pdf)$/i.test(record.name)) {
        data.url = `${
          process.env.REACT_APP_PDF_VIEWER_HOST
        }?file=${encodeURIComponent(record.url)}`;
        data.isPDF = true;
      }

      callback({ data, visible: true });
    } else if (isImageFile(record.name)) {
      callback({
        data: { name: record.name, url: record.url, isImage: true },
        visible: true,
      });
    } else {
      window.location.assign(record.url);
    }
  } else {
    notification.error({
      message: i18next.t('error.title'),
      description: i18next.t('error.description'),
      position: 'tr',
      duration: 2,
    });
  }
};

const showErrorDownloadFile = () => {
  notification.error({
    message: i18next.t('error.title'),
    description: i18next.t('error.errorDownloadFile'),
    position: 'tr',
    duration: 2,
  });
};

export const downloadFileByURL = async (file) => {
  if (file?.url) {
    try {
      fetch(file.url, {
        cache: 'no-cache',
        mode: 'cors',
        credentials: 'omit',
      })
        .then((res) => {
          if (res.status === 200) return res.blob();
          showErrorDownloadFile();
          return false;
        })
        .then((blob) => {
          if (!blob) return;
          const a = document.createElement('a');
          const url = window.URL.createObjectURL(blob);
          a.href = url;
          a.download = file.name;
          a.click();
          window.URL.revokeObjectURL(url);
        });
    } catch (error) {
      showErrorDownloadFile();
    }
  } else {
    showErrorDownloadFile();
  }
};

export const downloadFileByURLWithAxios = (file) => {
  if (file?.url) {
    axios
      .get(file.url, {
        responseType: 'blob',
        headers: { 'Cache-Control': 'no-cache' },
        cache: 'no-cache',
        mode: 'cors',
        credentials: 'omit',
      })
      .then((response) => {
        if (response.status === 200) {
          downloadFileFunc({ response, fileName: file.name });
        } else {
          showErrorDownloadFile();
        }
      })
      .catch(() => {
        showErrorDownloadFile();
      });
  } else {
    showErrorDownloadFile();
  }
};

export const downloadFileFunc = ({ response, fileName }) => {
  const blob = response.data;
  if (!blob) return;

  const a = document.createElement('a');
  const url = window.URL.createObjectURL(blob);
  a.href = url;
  a.download = fileName || 'file';
  a.click();
  window.URL.revokeObjectURL(url);
};

export const getBase64 = async (url) => {
  try {
    const controller = new AbortController();

    const timeoutId = setTimeout(() => controller.abort(), 40000);

    const response = await fetch(url, {
      cache: 'no-cache',
      mode: 'cors',
      credentials: 'omit',
      signal: controller.signal,
    });

    clearTimeout(timeoutId);

    const image = await response.blob();
    const objectURL = URL.createObjectURL(image);

    const link = document.createElement('a');
    link.href = objectURL;
    link.download = 'download.png';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    return objectURL;
  } catch (error) {
    return '';
  }
};

export const getPrefixKeyUploadCentre = (id) => `uploads/centres/${id}/`;

export const getPrefixKeyUploadCompanyProfile = (id) =>
  `uploads/photos/companies/${id}/profile/`;

export const resizeImageFile = async (file) => {
  const options = {
    maxSizeMB: MAX_FILE_SIZE_IN_MB,
    maxWidthOrHeight: MAX_FILE_DIMENSIONS,
    useWebWorker: true,
  };
  let compressedFile = file;

  if (
    file.type?.includes('image') &&
    file.size / 1024 / 1024 > MAX_FILE_SIZE_IN_MB
  ) {
    try {
      compressedFile = await imageCompression(file, options);
      compressedFile.lastModifiedDate = new Date();
      compressedFile.name = file.name;
      return compressedFile;
    } catch (err) {
      notification.error({
        message: i18next.t('error.title'),
        description: i18next.t('error.description'),
      });
      return null;
    }
  }
  return file;
};

export const getFileName = (link) => {
  if (!link) {
    return null;
  }
  const fileType = link?.split('.')?.pop();
  const index = link?.lastIndexOf('-');
  const fileName = `${link.slice(0, index).split('/')?.pop()}.${fileType}`;

  return fileName;
};

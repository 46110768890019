import React from 'react';

import { Col, Row } from 'antd';
import RestInputItem from 'components/RestInput/RestInputItem';

const CompanyBrandForm = () => (
  <div>
    <Row gutter={16}>
      <Col md={12} xs={24}>
        <RestInputItem
          header="centres.company"
          placeholder="input.company.placeholder"
          source={['company', 'name']}
          disabled
        />
      </Col>
      <Col md={12} xs={24}>
        <RestInputItem
          header="centres.brand"
          placeholder="input.brand.placeholder"
          source={['brand', 'name']}
          disabled
        />
      </Col>
    </Row>
  </div>
);

export default CompanyBrandForm;

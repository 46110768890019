import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const CardItem = ({ fullWidth = true, isExistOnSlide = true, ...props }) => (
  <CardItemWrapper
    fullWidth={fullWidth}
    isExistOnSlide={isExistOnSlide}
    {...props}
  />
);

CardItem.propTypes = {
  fullWidth: PropTypes.bool,
  isExistOnSlide: PropTypes.bool,
};

export default CardItem;

export const CardItemWrapper = styled.div`
  width: ${({ fullWidth }) => (fullWidth ? '100%' : '390px')};
  max-width: ${({ fullWidth }) => (fullWidth ? '100%' : '82vw')};
  padding: ${({ isExistOnSlide }) => (isExistOnSlide ? '20px 12px' : '')};
`;

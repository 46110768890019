const theme = {
  palette: {
    primary: '#DB147F',
    lightPrimary: '#4cb1e8',
    secondary: '#82D4D9',
    loadingBackgroundColor: '#2c3e51cc',
    color: ['#e64c38', '#DB147F', '#f8b51d'],
    dark: '#000444',
    blueLink: '#1197FF',
    green: '#2bada7',
    pink: '#fd82c3',
  },
  fonts: {
    primary: 'Mulish',
  },
  fontWeight: {
    thin: 100, // Thin
    utraLight: 200, // Ultra Light
    light: 300, // Light
    regular: 400, // Regular
    medium: 500, // Medium
    semibold: 600, // Semibold
    bold: 700, // Bold
    heavy: 800, // Heavy
    black: 900, // Black
  },
  background: {
    primary: '#DB147F',
    warning: '#f8b51d',
    error: '#ed1558',
    content: '#fff',
    // container: '#f4f7f9',
    container: '#F7F8F9',
    input: '#efeff0',
    disabled: '#969696',
    headerTable: '#ffff',
    gray: '#f0f0f0',
    clearBtn: '#f7e8f0',
    disabledBtn: '#ABA7A7',
    lightBlue: '#eff7ff',
    lightGreen: '#F0FBFB',
    gray200: '#EAECF0',
    gray300: '#d0d5dd',
    successSoft: '#EDF9F0',
    errorSoft: '#FEEFEF',
    neutral100: '#F7F8F9',
  },
  text: {
    primary: '#2D1F21',
    text: '#2D1F21',
    lightPrimary: '#3e4c59',
    secondary: '#a4a4a4',
    tabTitle: '#7f817c',
    empty: '#969696',
    highlight: '#5d4ec2',
    disabled: '#969696',
    // formLabel: '#9c9d9b',
    formLabel: '#857E7F',
    headerTable: '#0f100d',
    note: '#878787',
    formIcon: '#857E7F',
    disabledBtn: '#FFFFFF',
    mainL2: '#857E7F',
    mainL3: '#ABA7A7',
    mainL6: '#F2F2F2',
    mainL1: '#000000',
    mainL5: '#5C595A',
    mainAC: '#ACB2B8',
    grayNeutral500: '#758098',
    darkGreen: '#105B5C',
    blackNeutral800: '#141533',
    black: '#101828',
    successDefault: '#3EBD5C',
    blue: '#459EFF',
    gray300: '#d0d5dd',
    errorDefault: '#E22B30',
    neutral500: '#6E6B7F',
    gray700: '#38465C',
    neutral400: '#98A2B3',
    success: '#36BF57',
  },
  border: {
    default: '#E7E7E7',
    light: '#f2f2f2',
    primary: '#DB147F',
    lightGray: '#e9ecf7',
    gray: '#d9d9d9',
  },
  borderRadius: {
    default: '8px',
    button: '8px',
    input: '8px',
    checkbox: '4px',
    modal: '10px',
  },
  scrollbar: {
    thumb: '#b7b6c2',
    track: '#f0f3fa',
  },
  color: {
    gray: '#a3a3a3',
    green: '#4fcea2',
    brightGreen: '#52c41a',
    red: '#e64c38',
    blue: '#0992d0',
    orange: '#f5a623',
    darkOrange: '#f67800',
    pink: '#F75D81',
    limeGreen: '#4fcea2',
    lightGreen: '#3ebac2',
    blueShade: '#2d7fd3',
    yellow: '#FFCA28',
    violet: '#665ca7',
    purple: '#ac009f',
    blueSecondary: '#32A4FC',
    lightBlue: '#E9F4FF',
    warning: '#FB8429',
    info: '#316DDE',
    cyan: '#00DAF7',
    redDark: '#E72B2B',
    lightPink: '#fff0fb',
    lightGray: '#ccd2d9',
    pastelPink: '#FF80C4',
    blueInformationDefault: '#3570DB',
    grayNeutral400: '#98a2b3',
  },
  alert: {
    error: 'red',
  },
  card: {
    header: '#d4d2f450',
    padding: '20px',
  },
  sidebar: {
    activeText: 'white',
    activeBG: '#DB147F',
  },
  drawer: {
    headerTitle: '#0f100d',
    closeIcon: '#91938e',
  },
  table: {
    headerBackground: '#EEF2FA',
    headerColor: '#50649c',
    color: '#7286a2',
  },
  boardColors: {
    Y50: '#FFFAE6',
    N400A: 'rgba(9, 30, 66, 0.71)',
    G50: '#E3FCEF',
    B50: '#DEEBFF',
    P50: '#EAE6FF',
    N30: '#EBECF0',
    N0: '#FFFFFF',
    G100: '#79F2C0',
    G200: '#57D9A3',
    N70: '#A5ADBA',
    N900: '#091E42',
  },
  boxShadow: '0px 1px 16px rgba(0, 0, 0, 0.06)',
  subscriptions: {
    colorIcon: '#faad14',
  },
  contentWrapper: {
    paddingPx: '24px',
    paddingNumber: 24,
  },
  footer: {
    background: '#fffbfd',
  },
  icons: {
    pastelGreen: '#1FADA8',
  },
};
module.exports = theme;

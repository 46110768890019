import i18next from 'i18next';
import { isEmpty } from 'lodash-es';

export const validateRegex = {
  phone: /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s./0-9]*$/gs,
  password: /^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*\d).{6,}$/g,
  username: /^(?=.{3,20}$)(?![_.])(?!.*[_.]{2})[a-zA-Z0-9._]+([_.])$/g,
  editBookingId: '#bookings/(.*)/edit',
  fullName: /^[a-z0-9 ]{3,100}$/iu,
  phoneNumber: /^\+{0,1}[0-9]{10,15}$/,
  postCode: /^[0-9]{4}$/,
  url: /((ft|htt)ps?:\/\/)?[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}/g,
  website:
    /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/,
  youtube:
    /(?:http(?:s)?:\/\/)?(?:www\.)?(?:youtu\.be\/|youtube\.com\/(?:(?:watch)?\?(?:.*&)?v(?:i)?=|(?:embed|v|vi|user)\/))([^\\?&\\"'<> #]+)/,
  link: /^(?:http(?:s)?:\/\/)?(?:www\.)?(?:\S+(?::\S*)?@)?(?:(?!10(?:\.\d{1,3}){3})(?!127(?:\.\d{1,3}){3})(?!169\.254(?:\.\d{1,3}){2})(?!192\.168(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]+-?)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]+-?)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/[^\s]*)?$/,
  vimeo:
    /(?:http|https)?:?\/?\/?(?:www\.)?(?:player\.)?vimeo\.com\/(?:channels\/(?:\w+\/)?|groups\/([^/]*)\/videos\/|video\/|)([a-zA-Z0-9]+(\/\w+)?)/,
  matterport:
    /((https?:\/\/)?(my.)?matterport\.com\/(discover|show|models)?\/(space\/)?\??[a-zA-Z0-9=?_&]+)/,
  roundme:
    /(?:http(s)?:\/\/)?(?:www\.)?roundme\.com\/(?:tour|embed)\/([a-zA-Z0-9-_]+)/,
  facebook:
    /(?:https?:\/\/)?(?:www.|web.|m.)?(facebook|fb).(com|watch)\/(?:video.php\?v=\d+|(\S+)|photo.php\?v=\d+|\?v=\d+)|\S+\/videos\/((\S+)\/(\d+)|(\d+))\/?/,
  latinLetters: /^[a-zA-Z0-9]+$/,
  spaceCharacter: /[\s\uFEFF\xA0]+$/,
  wordCharacter: /\b|(?=\W)/,
  noSpecialChars: /^([a-zA-Z])+(\s[a-zA_Z]+)*$/gi,
};

export const handleApiError = (error, form) => {
  if (!isEmpty(error?.errors)) {
    const fieldData = Object.entries(error?.errors).map(([key, value]) => ({
      name: key,
      errors: [value],
    }));
    form.setFields(fieldData);
    form.scrollToField(fieldData[0]?.name);
    form.getFieldInstance(fieldData[0]?.name).focus();
  }
};

export const getConfirmVideoURLRules = () => [
  () => ({
    validator(_, value) {
      if (!value || validateRegex.youtube.test(value)) {
        return Promise.resolve();
      }
      return Promise.reject(new Error(i18next.t('urlVideo.validateMsg')));
    },
  }),
];

export const getRuleOnlyIntegerNumber = (errorMessage) => [
  {
    message: i18next.t(errorMessage),

    validator(_, value) {
      return Number.isInteger(value) || !value
        ? Promise.resolve()
        : Promise.reject();
    },
  },
];

export const getRuleMinNumber = (errorMessage, minNumber) => [
  {
    message: i18next.t(errorMessage),
    validator(_, value) {
      return value <= minNumber ? Promise.reject() : Promise.resolve();
    },
  },
];

export const getDiscountNumberRules = (
  integerValidateMsg,
  inputValidateMsg,
  title,
) => [
  () => ({
    validator(_, value) {
      if (value === null) {
        return Promise.resolve();
      }
      if (!Number?.isInteger(value)) {
        return Promise.reject(
          new Error(i18next.t(integerValidateMsg, { title: i18next.t(title) })),
        );
      }
      if (value < 0 || value > 100) {
        return Promise.reject(
          new Error(i18next.t(inputValidateMsg, { title: i18next.t(title) })),
        );
      }
      return Promise.resolve();
    },
  }),
];

export const getConfirmVirtualTourURLRules = () => [
  () => ({
    validator(_, value) {
      if (
        !value ||
        validateRegex.matterport.test(value) ||
        validateRegex.youtube.test(value) ||
        validateRegex.roundme.test(value) ||
        validateRegex.vimeo.test(value) ||
        validateRegex.facebook.test(value)
      ) {
        return Promise.resolve();
      }
      return Promise.reject(new Error(i18next.t('input.videoUrl.validateMsg')));
    },
  }),
];

import React from 'react';
import { Button, ModalProps } from 'antd';
import i18next from 'i18next';
import { ButtonType } from 'types';
import { ModalWrapper } from './styles';
import { CloseSquareOutlineIcon } from '../SVGIcon';

interface ModalCustomProps extends ModalProps {
  onCancel?: () => void;
  loading?: boolean;
  onOk?: () => void;
  isDisabled?: boolean;
  isShowCancel?: boolean;
  okText?: string;
  cancelText?: string;
  isScrollY?: boolean;
  className?: string;
  resetPaddingTopBody?: boolean;
  resetPaddingBottomBody?: boolean;
  width?: number;
  children: React.ReactNode;
  okType?: ButtonType;
}

const ModalCustom = ({
  isShowCancel = true,
  okText = 'button.save',
  cancelText = 'button.cancel',
  isScrollY,
  className,
  resetPaddingTopBody = true,
  resetPaddingBottomBody = true,
  loading = false,
  isDisabled = false,
  width = 600,
  okType = ButtonType.primary,
  ...props
}: ModalCustomProps) => (
  <ModalWrapper
    width={width}
    footer={[
      isShowCancel && (
        <Button key="back" onClick={props.onCancel} className="cancel-button">
          {i18next.t(cancelText)}
        </Button>
      ),
      <Button
        key="submit"
        type={okType}
        loading={loading}
        onClick={props.onOk}
        disabled={isDisabled}
        className="submit-button"
      >
        {i18next.t(okText)}
      </Button>,
    ]}
    {...props}
    destroyOnClose
    maskClosable={false}
    className={`${className || ''} ${isScrollY ? 'modal-scroll-y' : ''} ${
      resetPaddingBottomBody ? 'reset-padding-bottom-body' : ''
    } ${resetPaddingTopBody ? 'reset-padding-top-body' : ''} top-0 m-auto py-0`}
    closeIcon={<CloseSquareOutlineIcon />}
    wrapClassName="flex"
  />
);

export default ModalCustom;

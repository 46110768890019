import React from 'react';
import i18next from 'i18next';
import CreateProfileButton from 'containers/Centres/components/CentreTabs/EducatorStaffProfilesTab/components/CreateProfileButton';

const HeaderTitle = () => (
  <div className="flex flex-row flex-center-between">
    <div className="fw-bold text-20 text-black-base">
      {i18next.t('centreDetail.tabs.educatorStaffProfile')}
    </div>
    <CreateProfileButton />
  </div>
);

export default HeaderTitle;

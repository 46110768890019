import React from 'react';
import i18next from 'i18next';
import { Button } from 'antd';
import { CrownFilled } from '@ant-design/icons';

import { upgradeModal } from 'components/common/CheckPermissions/UpgradeSubscriptionModal';

const FallbackDecline = () => {
  const handleClick = () => {
    upgradeModal.open();
  };

  return (
    <div className="fallback-decline-btn">
      <Button onClick={handleClick}>
        {i18next.t('button.declineApplication')}
      </Button>
      <CrownFilled className="action-feature-icon" />
    </div>
  );
};

export default FallbackDecline;

import i18next from 'i18next';
import store from 'redux/store';
import {
  PREFIX_API_VER_1,
  TIME_ZONE_HEADER_KEY,
} from 'configs/localData/constant';
import qs from 'qs';
import { getClientTimeZone } from 'utils/tools';
import { getSessionToken, getImpersonateSessionToken } from 'utils/token';
import { refreshAccessToken } from 'utils/apiUtils';
import { handleRequestError } from './sentry';

const checkIfErrorOccurs = (res) => ({
  code: res.status,
  res,
});

interface Config {
  method: string;
  headers: {
    Authorization?: string;
    'Content-Type'?: string;
    [TIME_ZONE_HEADER_KEY]?: string;
    Accept?: string;
  };
  body?: any;
}

const TIME_OUT = 30000;

async function customFetch(path, header) {
  const headerOptions = header;
  const normalFetch = fetch(path, headerOptions);
  let res: {
    code?: number;
    res?: Response;
  } = await timeoutPromise(
    TIME_OUT,
    normalFetch.then(checkIfErrorOccurs).catch(checkIfErrorOccurs),
  );

  if (res.code === 401) {
    const token = await refreshAccessToken();
    headerOptions.headers.Authorization = `Bearer ${token}`;

    const fetchRetry = fetch(path, headerOptions);
    res = await timeoutPromise(
      TIME_OUT,
      fetchRetry.then(checkIfErrorOccurs).catch(checkIfErrorOccurs),
    );
  }

  if (!res.code) {
    const error = {
      code: 503,
      message: i18next.t('error.error503'),
    };
    throw error;
  }

  if (res.code < 300) {
    const response = await res.res.json();
    return response;
  }
  try {
    const response = await res.res.json();
    const error = {
      code: res.code,
      ...response,
    };
    throw error;
  } catch (e) {
    if (res.code === 426) {
      const error = {
        code: res.code,
        message:
          'We have had some significant upgrades for the app. Please click below to upgrade your app!',
        ...e,
      };
      throw error;
    } else {
      const error = {
        code: res.code,
        message: 'Something wrong. Please try again.',
        ...e,
      };

      handleRequestError({ error, apiUrl: path, payload: headerOptions.body });

      throw error;
    }
  }
}

export const timeoutPromise = (ms, promise) =>
  new Promise((resolve, reject) => {
    const timeoutId = setTimeout(() => {
      reject(new Error('Request time out! Please try again.'));
    }, ms);
    promise.then(
      (res) => {
        clearTimeout(timeoutId);
        resolve(res);
      },
      (err) => {
        clearTimeout(timeoutId);
        reject(err);
      },
    );
  });

export default customFetch;

export const getCentreHeader = () => {
  const { centreId } = store.getState().auth;
  if (centreId) return { centreId };
  return { centreIds: 'all' };
};

function requestWrapper(method) {
  const request = async (
    url,
    data = null,
    apiVersion = PREFIX_API_VER_1,
    params = {},
  ) => {
    let convertUrl = `${process.env.REACT_APP_BASE_API_URL}/${
      apiVersion ?? ''
    }${url}`;
    let convertParams = {};
    let convertData = data;
    if (method === 'GET') {
      convertParams = convertData;
      if (convertParams !== null) {
        convertUrl = `${convertUrl}?${
          apiVersion === PREFIX_API_VER_1
            ? getQueryString(convertParams)
            : qs.stringify(convertParams)
        }`;
      }
      convertData = null;
    } else if (convertData === Object(convertData)) {
      convertData = JSON.stringify(convertData);
    }

    const config: Config = {
      method,
      headers: {
        'Content-Type': 'application/json; charset=UTF-8',
        [TIME_ZONE_HEADER_KEY]: getClientTimeZone(),
        ...getCentreHeader(),
      },
    };

    const token = getImpersonateSessionToken() || getSessionToken();
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    if (method === 'POST' || method === 'PUT') {
      config.headers.Accept = 'application/json';
      config.headers['Content-Type'] = 'application/json';
    }

    if (convertData) {
      config.body = convertData;
    }

    const paramsObj = {
      ...config,
      headers: { ...config.headers, ...params },
    };
    return customFetch(convertUrl, paramsObj);
  };
  return request;
}

export function getQueryString(params) {
  const esc = encodeURIComponent;
  return Object.keys(params)
    .filter((k) => params[k] || params[k] === 0)
    .map((k) => `${esc(k)}=${esc(params[k])}`)
    .join('&');
}

export const stringifyObjectWithBrackets: typeof qs.stringify = (
  object,
  options,
) =>
  qs.stringify(object, {
    arrayFormat: 'brackets',
    skipNulls: true,
    ...options,
  });

export const get = requestWrapper('GET');
export const post = requestWrapper('POST');
export const put = requestWrapper('PUT');
export const patch = requestWrapper('PATCH');
export const del = requestWrapper('DELETE');

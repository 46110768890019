import { Col, Divider, Empty, Row, Space } from 'antd';
import ReadMore from 'components/common/ReadMore';
import { ACTIVITY_TYPES } from 'configs/localData';
import i18next from 'i18next';
import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import { getActivityById } from 'redux/applications/selectors';
import { formatDateTime } from 'utils/textUtils';
import HeaderActivityDetail from './Header';
import { ActivityDetailWrapper, ActivityContentWrapper } from './styles';

const ActivityDetail = ({ activityId }) => {
  const activity = useSelector(getActivityById(activityId));
  const type = ACTIVITY_TYPES.find((ele) => ele.value === activity?.type);

  const renderActivityContent = () => (
    <ActivityContentWrapper>
      <div className="icon-type">
        {type?.IconCPN()}
        <p style={{ color: type?.color }}>{i18next.t(type?.text)}</p>
      </div>
      <div className="field-item">
        <Row gutter={16}>
          <Col xl={24}>{i18next.t('activity.subject')}:</Col>
          <Col xl={24}>{activity.subject}</Col>
        </Row>
      </div>
      <Divider />
      <div className="field-item">
        <Row gutter={16}>
          <Col xl={6} lg={6} md={24} xs={24}>
            {i18next.t('activity.date')}:
          </Col>
          <Col xl={18} lg={18} md={24} xs={24}>
            {formatDateTime(activity.date)}
          </Col>
        </Row>
      </div>
      <Divider />
      <div className="field-item">
        <Row gutter={16}>
          <Col xl={24} lg={24} md={24} xs={24}>
            {i18next.t('activity.note')}:
          </Col>
          <Col xl={24} lg={24} md={24} xs={24}>
            <ReadMore
              text={activity.note}
              readMoreCharacterLimit={500}
              showLessButton
            />
          </Col>
        </Row>
      </div>
    </ActivityContentWrapper>
  );

  return (
    <ActivityDetailWrapper>
      <HeaderActivityDetail record={activity} />
      <Divider />
      <Space className="list-card-info" direction="vertical" size={16}>
        {activity.id ? (
          renderActivityContent()
        ) : (
          <Empty description={i18next.t('activity.noActivity')} />
        )}
      </Space>
    </ActivityDetailWrapper>
  );
};
ActivityDetail.propTypes = {
  activityId: PropTypes.string,
};
export default ActivityDetail;

import PageTitle from 'components/common/PageTitle';
import i18next from 'i18next';
import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router';
import { getJobListingPayloadSelector } from 'redux/jobs/jobListings/selectors';
import { useDispatch, useSelector } from 'react-redux';
import { transformDataCreateOnSubmit } from 'containers/JobListings/utils';
import {
  resetJobListingPayload,
  setJobListingPayload,
} from 'redux/jobs/jobListings/slice';
import { createJobListing } from 'redux/jobs/jobListings/actions';
import qs from 'qs';
import FormChooseCampaign from '../../Form/FormChooseCampaign';
import CreateCampaignModal from '../../Modals/CreateCampaignModal';

const CampaignStep = ({ form, initialValues }) => {
  const dispatch = useDispatch();
  const { push, location } = useHistory();
  const campaignModalRef = useRef();

  const { filter, id: templateId } = qs.parse(location.search, {
    ignoreQueryPrefix: true,
  });

  const payload = useSelector(getJobListingPayloadSelector);

  const onFinish = ({ campaignId }) => {
    dispatch(setJobListingPayload({ campaignId }));

    const data = transformDataCreateOnSubmit({
      ...payload,
      campaignId,
      filter,
      templateId,
    });

    dispatch(createJobListing(data)).then(() => {
      dispatch(resetJobListingPayload());
      push(`/job-listings/individual/list`);
    });
  };

  return (
    <div>
      <PageTitle className="step-title">
        {i18next.t('jobListings.steps.step5')}
      </PageTitle>
      <p>{i18next.t('jobListings.steps.campaignDesc')}</p>

      <FormChooseCampaign
        showCreate
        form={form}
        onFinish={onFinish}
        initialValues={initialValues}
      />

      <CreateCampaignModal ref={campaignModalRef} />
    </div>
  );
};

CampaignStep.propTypes = {
  form: PropTypes.object,
  initialValues: PropTypes.object,
};

export default CampaignStep;

import React from 'react';
import { APPLICATION_STATUS_BOARD } from 'configs/localData';
import { useApplicationList } from '../../List/context';

import Board from '../Board';

const BoardList = () => {
  const { boardData, summaryStatus } = useApplicationList();
  return (
    <Board
      initial={boardData}
      summaryStatus={summaryStatus}
      applicationStatus={APPLICATION_STATUS_BOARD}
      withScrollableColumns
      isDragDisabledColumn
    />
  );
};

export default BoardList;

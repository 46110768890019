import { createDraftSafeSelector, createSelector } from '@reduxjs/toolkit';

const getRawJobListingCurrentStep = (state) => state.jobListings.currentStep;

const getRawJobListingPayload = (state) => state.jobListings.jobListingPayload;

const getRawJobListingLoading = (state) => state.jobListings?.loading;

const getRawLoadingCreateJobListing = (state) =>
  state?.jobListings?.createLoading;

const getRawDataJobListings = (state) => state?.jobListings?.data;

const getDataJobListingViewByCampaign = (state) =>
  state?.jobListings?.jobCampaign;

const getDataJobListingViewByJobRole = (state) => state?.jobListings?.jobRole;

const getDataJobListingViewByJobTemplate = (state) =>
  state?.jobListings?.jobTemplate;

const getDataJobListingViewByJobIndividual = (state) =>
  state?.jobListings?.jobIndividual;

const getRawResourceDataJobListings = (state) =>
  state.jobListings?.resourceData;

const getSummaryJobListing = (state) => state.jobListings?.summary?.results;

const getRawCampaigns = (state) => state.jobListings.campaigns;

const getRawWorkplaces = (state) => state.jobListings.workplaces;

const getRawSelectedWorkplaces = (state) =>
  state.jobListings.selectedWorkplaces;

const getRawDataJobListingDetail = (state) => state.jobListings?.currentData;

const getRawJobListingDetailLoading = (state) =>
  state.jobListings?.getJobListingsDetailLoading;

export const getSummaryJobListingSelector = createSelector(
  [getSummaryJobListing],
  (data) => data || {},
);

export const getJobListingCurrentStepSelector = createDraftSafeSelector(
  [getRawJobListingCurrentStep],
  (data) => data,
);

export const getJobListingPayloadSelector = createDraftSafeSelector(
  [getRawJobListingPayload],
  (data) => data,
);

export const getLoadingJobListing = createSelector(
  [getRawJobListingLoading],
  (data) => data,
);

export const getLoadingCreateJobListing = createSelector(
  [getRawLoadingCreateJobListing],
  (data) => data,
);

export const getDataJobListings = createSelector(
  [getRawDataJobListings],
  (data) => data,
);

export const getDataJobListingDetail = createSelector(
  [getRawDataJobListingDetail],
  (data) => data,
);

export const getCampaignsSelector = createSelector(
  [getRawCampaigns],
  (data) => data,
);

export const getWorkplacesSelector = createSelector(
  [getRawWorkplaces],
  (data) => data,
);

export const getSelectedWorkplacesSelector = createSelector(
  [getRawSelectedWorkplaces],
  (data) => data,
);

export const getLoadingJobListingDetail = createSelector(
  [getRawJobListingDetailLoading],
  (data) => data,
);

export const enabledLoadMoreBoardView = createSelector(
  [getRawResourceDataJobListings, getRawJobListingLoading],
  (resource, loading) => {
    const {
      page: currentPage = 1,
      size = 10,
      total: totalRecords = 0,
    } = resource || {};
    const totalPage = Math.ceil(totalRecords / size);
    return !loading && currentPage < totalPage;
  },
);

export const getJobListingViewByCampaign = createSelector(
  [getDataJobListingViewByCampaign],
  (data) => data,
);

export const getJobListingViewByJobRole = createSelector(
  [getDataJobListingViewByJobRole],
  (data) => data,
);

export const getJobListingViewByJobTemplate = createSelector(
  [getDataJobListingViewByJobTemplate],
  (data) => data,
);

export const getJobListingViewByJobIndividual = createSelector(
  [getDataJobListingViewByJobIndividual],
  (data) => data,
);

import React, { forwardRef, useImperativeHandle, useState } from 'react';
import ModalCustom from 'components/common/ModalCustom';
import { Form, Input } from 'antd';
import RestSelect from 'components/RestInput/RestSelect';
import RestInputItem from 'components/RestInput/RestInputItem';
import i18next from 'i18next';
import { useDispatch } from 'react-redux';
import { disputeReview } from 'redux/centres/actions';

const reasonDisputes = [
  'Not genuine',
  'Not related to my Centre',
  'Violates Privacy Policy',
  'Other',
];

const DisputeModal = forwardRef((props, ref) => {
  const dispatch = useDispatch();

  const [visible, setVisible] = useState();

  const [loading, setLoading] = useState();

  const [reviewId, setReviewId] = useState();

  const [form] = Form.useForm();

  useImperativeHandle(
    ref,
    () => ({
      open: (id) => {
        setVisible(true);
        setReviewId(id);
      },
    }),
    [],
  );

  const onCancel = () => {
    form.resetFields();
    setVisible(false);
  };

  const handleSubmit = () => {
    form
      .validateFields()
      .then(({ comment, ...values }) => {
        setLoading(true);
        return dispatch(
          disputeReview({
            id: reviewId,
            data: {
              ...values,
              comment: comment?.trim(),
            },
          }),
        );
      })
      .then((res) => {
        setLoading(false);
        if (res?.payload?.id) {
          onCancel();
        }
      })
      .catch(() => {
        setLoading(false);
      });
  };

  return (
    <ModalCustom
      visible={visible}
      okText={i18next.t('button.submit')}
      onCancel={onCancel}
      onOk={handleSubmit}
      title={i18next.t('reviews.reviewDispute')}
      loading={loading}
    >
      <Form form={form} layout="vertical">
        <RestSelect
          header="reviews.reasonDispute"
          required
          source="reason"
          resourceData={reasonDisputes}
        />
        <RestInputItem
          required
          header="reviews.comment"
          source="comment"
          placeholder="input.enterHere"
          rows={6}
          ContentComponent={Input.TextArea}
        />
      </Form>
    </ModalCustom>
  );
});

export default DisputeModal;

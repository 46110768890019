import React from 'react';
import SignUpButton from '../../../PackageCard/SignUpButton';

const INCORRECT_BUTTON_NAME = '30 Day Free Trial';
const CORRECT_BUTTON_NAME = '30-Day Free Trial';

export const FeatureItem = ({
  priceDetail,
  subscriptionItem,
  onClickSignup,
}) => {
  const handleText30daysTrial = (subscription) => {
    if (subscription.signupButtonText === INCORRECT_BUTTON_NAME) {
      return {
        ...subscription,
        signupButtonText: CORRECT_BUTTON_NAME,
      };
    }
    return subscription;
  };
  return (
    <div className="block-body">
      <div className="accessible-list">
        {priceDetail.accessibleList.map((item) => (
          <div className="accessible-item">
            <div className="accessible-icon">
              {React.createElement(item.icon, { fill: priceDetail.mainColor })}
            </div>
            <p className="accessible-content">
              {item.content.subTitle ? (
                <>
                  <p>{item.content.subTitle}</p>
                  <ul className="sub-list">
                    {item.content.subItem.map((item) => (
                      <li>{item}</li>
                    ))}
                  </ul>
                </>
              ) : (
                item.content
              )}
            </p>
          </div>
        ))}
      </div>
      <div
        className={`btn-container action-button recommend-button ${
          priceDetail.isRecommend ? 'recommend-btn-bg' : ''
        }`}
      >
        <SignUpButton
          item={handleText30daysTrial(subscriptionItem)}
          onClickSignup={onClickSignup}
        />
      </div>
    </div>
  );
};

export default FeatureItem;

import styled from 'styled-components';

const TasksTabWrapper = styled.div`
  .header-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    .title-name {
      font-weight: bold;
      font-size: 20px;
      line-height: 25px;
    }
    .add-button {
      background: ${({ theme }) => theme.color.blueSecondary};
      border: none;
      color: #fff;
      font-weight: bold;
    }
  }
  .selected-row, .selected-row:hover > * {
    background-color #f2f2f2 !important;
  }
  tr {
    cursor: pointer;
  }
`;

export default TasksTabWrapper;

import React, { useState, useEffect, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Form, Input, Checkbox, Select, Row, Col } from 'antd';
import i18next from 'i18next';
import { useDispatch, useSelector } from 'react-redux';
import { validateRegex } from 'utils/validateUtils';
import { CLAIM_STATUSES, DISPUTE_REASONS } from 'configs/localData';
import { claimCentre, disputeCentre } from 'redux/centres/actions';
import Recaptcha from 'components/common/Recaptcha';
import { centresSelectors } from 'redux/centres/selectors';
import { useParams } from 'react-router';
import useWindowSize from 'hooks/useWindowSize';
import { CentreClaimFormWrapper } from './styles';
import ClaimFooter from '../ClaimFooter';
import StepTitle from '../StepTitle';
import SuccessClaim from '../SuccessClaim';
import CentreClaimInfo from '../Details/CentreInfo';
import ResendVerificationEmail from '../ResendVerificationEmail';

const CentreModal = ({ onBack }) => {
  const { width } = useWindowSize() || {};
  const { id } = useParams();

  const dispatch = useDispatch();

  const currentCentre = useSelector(centresSelectors.getCurrentData);

  const { adminEmail, claimStatus } = currentCentre || {};

  const options = useMemo(
    () =>
      CLAIM_STATUSES.find((status) => status.value?.includes(claimStatus)) ||
      CLAIM_STATUSES[0],
    [claimStatus],
  );

  const [form] = Form.useForm();

  const { isClaim } = options || {};

  const title = isClaim
    ? 'centresClaim.claimTitle'
    : 'centresClaim.disputeTitle';

  const hasNotRegisteredEmail = !!(isClaim && !adminEmail);
  const [isUseOtherEmail, setIsUseOtherEmail] = useState(
    hasNotRegisteredEmail ?? false,
  );

  const [disabled, setDisabled] = useState(true);

  const [verified, setVerified] = useState(false);
  const [showSuccessScreen, setShowSuccessScreen] = useState(false);

  const callback = () => setVerified(true);

  const expiredCallback = () => setVerified(false);

  const handleCheckbox = useCallback((e) => {
    setIsUseOtherEmail(e.target.checked);
  }, []);

  const handleCancel = useCallback(() => {
    setDisabled(true);
    setIsUseOtherEmail(false);
    form.resetFields();
  }, [form]);

  const handleCentreActions = useCallback(
    (values) => {
      const action = isClaim ? claimCentre : disputeCentre;
      dispatch(
        action({
          id,
          values,
        }),
      ).then(({ payload }) => {
        if (payload?.success) {
          handleCancel();
          setShowSuccessScreen(isClaim ? 'claim' : 'dispute');
        }
      });
    },
    [isClaim, dispatch, id, handleCancel],
  );

  const handleSubmit = () => {
    form.validateFields().then(({ newAdminEmail, sender, ...values }) => {
      handleCentreActions({
        ...values,
        sender: {
          ...sender,
          email: isClaim && adminEmail ? adminEmail : sender?.email,
          ...(newAdminEmail && { email: newAdminEmail }),
        },
      });
    });
  };

  useEffect(() => {
    adminEmail &&
      isClaim &&
      form.setFieldsValue({
        sender: {
          email: adminEmail ? `xxx${adminEmail.slice(3)}` : null,
        },
      });
  }, [adminEmail, isClaim, form]);

  const handleVerify = useCallback((e) => {
    setDisabled(!e.target.checked);
  }, []);

  const commonPlaceholder = i18next.t('input.enterHere');

  const isShowResendEmail =
    claimStatus === 'PENDING' &&
    currentCentre.latestClaimCentre?.isAdminEmailUsed;

  if (showSuccessScreen) {
    const isClaim = showSuccessScreen === 'claim';

    const text = {
      title: isClaim
        ? 'centresClaim.successClaimTitle'
        : 'centresClaim.disputeSuccessTitle',
      description: isClaim
        ? 'centresClaim.successClaimMessage'
        : 'centresClaim.disputeSuccessMessage',
    };

    return <SuccessClaim isClaim={isClaim} {...text} />;
  }

  return (
    <CentreClaimFormWrapper>
      <StepTitle
        stepNumber={2}
        title={title}
        color={!isClaim ? '#32A4FC' : null}
        className="mb-32"
      />
      {width < 985 && (
        <div className="mb-32">
          <CentreClaimInfo record={currentCentre} />
        </div>
      )}
      {!!isShowResendEmail && <ResendVerificationEmail />}
      <Form form={form} layout="vertical">
        <Row gutter={24}>
          <Col xs={24} md={12}>
            <Form.Item
              rules={[
                {
                  required: true,
                  message: i18next.t('input.firstName.validateMsg.required'),
                },
                {
                  min: 2,
                  message: i18next.t('error.minLength', { min: 2 }),
                },
                {
                  max: 100,
                  message: i18next.t('error.maxLength', { max: 100 }),
                },
              ]}
              label={i18next.t('users.firstName')}
              name={['sender', 'firstName']}
            >
              <Input placeholder={commonPlaceholder} />
            </Form.Item>
          </Col>
          <Col xs={24} md={12}>
            <Form.Item
              rules={[
                {
                  required: true,
                  message: i18next.t('input.lastName.validateMsg.required'),
                },
                {
                  min: 2,
                  message: i18next.t('error.minLength', { min: 2 }),
                },
                {
                  max: 100,
                  message: i18next.t('error.maxLength', { max: 100 }),
                },
              ]}
              label={i18next.t('users.lastName')}
              name={['sender', 'lastName']}
            >
              <Input placeholder={commonPlaceholder} />
            </Form.Item>
          </Col>
          <Col xs={24} {...(!isClaim ? { md: 12 } : null)}>
            <Form.Item
              {...(isClaim
                ? {
                    style: { marginBottom: '5px' },
                  }
                : {
                    rules: [
                      {
                        required: true,
                        message: i18next.t('input.email.validateMsg.required'),
                      },
                      {
                        type: 'email',
                        message: i18next.t('input.email.validateMsg.invalid'),
                      },
                    ],
                  })}
              label={i18next.t(
                isClaim ? 'input.registeredEmail.name' : 'centres.email',
              )}
              name={['sender', 'email']}
            >
              <Input disabled={isClaim} placeholder={commonPlaceholder} />
            </Form.Item>
          </Col>
          {isClaim && (
            <Col xs={24}>
              <Checkbox
                checked={isUseOtherEmail}
                onChange={handleCheckbox}
                className="use-new-email"
                disabled={hasNotRegisteredEmail}
              >
                {i18next.t('centres.changeEmailForm.useNewEmail')}
              </Checkbox>
            </Col>
          )}
          {isUseOtherEmail && (
            <Col xs={24}>
              <Form.Item
                rules={[
                  {
                    required: true,
                    message: i18next.t('input.email.validateMsg.required'),
                  },
                  {
                    type: 'email',
                    message: i18next.t('input.email.validateMsg.invalid'),
                  },
                ]}
                label={i18next.t('centres.centreCorrectEmail')}
                name="newAdminEmail"
                className="new-admin-email"
              >
                <Input placeholder={commonPlaceholder} />
              </Form.Item>
            </Col>
          )}
          <Col xs={24} {...(!isClaim ? { md: 12 } : null)}>
            <Form.Item
              rules={[
                {
                  pattern: validateRegex.phone,
                  message: i18next.t('input.phoneNumber.validateMsg.invalid'),
                },
                {
                  required: true,
                  message: i18next.t('input.phoneNumber.validateMsg.required'),
                },
              ]}
              label={i18next.t('input.phoneNumber.name')}
              name={['sender', 'phoneNumber']}
            >
              <Input placeholder={commonPlaceholder} />
            </Form.Item>
          </Col>
        </Row>

        {!isClaim && (
          <Form.Item
            label={i18next.t('centres.changeEmailForm.reason')}
            name="reason"
            rules={[
              {
                required: true,
                message: i18next.t('input.reason.validateMsg.required'),
              },
            ]}
          >
            <Select
              placeholder={i18next.t('input.reason.placeholder')}
              getPopupContainer={(trigger) => trigger.parentNode}
            >
              {DISPUTE_REASONS.map((reason, index) => (
                <Select.Option value={reason} key={String(index)}>
                  {reason}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        )}
        {!isClaim && (
          <Form.Item
            label={i18next.t('centres.changeEmailForm.message')}
            name="message"
          >
            <Input.TextArea rows={4} placeholder={commonPlaceholder} />
          </Form.Item>
        )}
        <div className="verify-div">
          <Checkbox onChange={handleVerify} />
          <span className="verify-message">
            {i18next.t('centresClaim.verifyMessage')}
          </span>
        </div>
        <div className="captcha-section">
          <Recaptcha callback={callback} expiredCallback={expiredCallback} />
        </div>
      </Form>
      <ClaimFooter
        onBack={onBack}
        onNext={handleSubmit}
        disabledNext={!verified || disabled}
        isClaim={isClaim}
      />
    </CentreClaimFormWrapper>
  );
};

CentreModal.propTypes = {
  adminEmail: PropTypes.string,
  id: PropTypes.string,
};

export default CentreModal;

import React from 'react';
import PropTypes from 'prop-types';
import CardCustom from 'components/common/CardCustom';
import {
  BookMarkIcon,
  DocumentFilIcon,
  DocumentTextFillIcon,
  VideoFilIcon,
} from 'components/common/SVGIcon';
import { getYoutubeEmbedURL } from 'utils/tools';
import i18next from 'i18next';
import { YOUTUBE_VALUE } from 'containers/JobApplications/constants';
import { getFileName } from 'utils/fileUtils';
import CardCustomContent from '../../CardContentCustom';

const MyDocuments = ({ document }) => (
  <div className="mb-32">
    <CardCustom
      title="jobApplications.generalInfo.myDocuments"
      Icon={BookMarkIcon}
    >
      <div>
        {document?.videoUrl && (
          <CardCustomContent Icon={VideoFilIcon}>
            <div className="text-16 flex flex-col	justify-between">
              <div className="fw-bold">
                {i18next.t('jobApplications.generalInfo.videoAboutMe')}
              </div>
              <div className="mt-20">
                {document?.videoUrl?.includes(YOUTUBE_VALUE) ? (
                  <iframe
                    className="rounded-8"
                    title={document?.videoUrl || ''}
                    frameBorder="0"
                    allowFullScreen="allowfullscreen"
                    src={
                      getYoutubeEmbedURL(document?.videoUrl) ||
                      document?.videoUrl
                    }
                  />
                ) : (
                  <video height="130" controls>
                    <source src={document?.videoUrl} type="video/mp4" />
                    <track
                      src={document?.videoUrl}
                      kind="captions"
                      srcLang="en"
                      label="english_captions"
                    />
                  </video>
                )}
              </div>
            </div>
          </CardCustomContent>
        )}
        {document?.resumeUrl && (
          <CardCustomContent Icon={DocumentTextFillIcon}>
            <div className="text-16 flex flex-col	justify-between">
              <div className="fw-bold">
                {i18next.t('jobApplications.generalInfo.resume')}
              </div>
              <div>
                {document?.resumeUrl && (
                  <a
                    href={document?.resumeUrl || ''}
                    target="_blank"
                    rel="noopener noreferrer"
                    download
                    className="text-blue-info-default"
                  >
                    {getFileName(document?.resumeUrl)}
                  </a>
                )}
              </div>
            </div>
          </CardCustomContent>
        )}
        {document?.coverLetterUrl && (
          <CardCustomContent Icon={DocumentFilIcon}>
            <div className="text-16 flex flex-col	justify-between">
              <div className="fw-bold">
                {i18next.t('jobApplications.generalInfo.coverLetter')}
              </div>
              <div>
                {document?.coverLetterUrl && (
                  <a
                    href={document?.coverLetterUrl || ''}
                    download
                    className="text-blue-info-default"
                  >
                    {getFileName(document?.coverLetterUrl)}
                  </a>
                )}
              </div>
            </div>
          </CardCustomContent>
        )}
      </div>
    </CardCustom>
  </div>
);

MyDocuments.propTypes = {
  document: PropTypes.object,
};

export default MyDocuments;

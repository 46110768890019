import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash-es';
import moment from 'moment';
import I18n from 'i18next';
import { DatePicker, Button, Tooltip } from 'antd';
import { getRecordData } from 'utils/tools';
import FormDateTimePicker from '../../form/FormDateTimePicker';
import { RestInputContext } from '../RestInputContext';

const { RangePicker } = DatePicker;

const RestFormDateTimePicker = ({ isShowTooltip, ...props }) => (
  <RestInputContext.Consumer>
    {({ record, form }) => (
      <Tooltip
        placement="top"
        title={isShowTooltip ? I18n.t(props?.placeholder) : ''}
      >
        <div>
          <FormDateTimePicker
            {...props}
            form={form}
            defaultValue={
              getRecordData(record, props.source) === null
                ? undefined
                : getRecordData(record, props.source)
            }
          />
        </div>
      </Tooltip>
    )}
  </RestInputContext.Consumer>
);

RestFormDateTimePicker.propTypes = {
  isShowTooltip: PropTypes.bool,
  placeholder: PropTypes.string,
};
/* eslint-disable */
export const dateFilterDropdown =
  (source, resourceFilter, handleReset) =>
  ({ setSelectedKeys, confirm }) => {
    const defaultValue = get(resourceFilter.filter, `${source}`);
    return (
      <FilterUI
        setSelectedKeys={setSelectedKeys}
        confirm={confirm}
        source={source}
        resourceFilter={resourceFilter}
        handleReset={handleReset}
      />
    );
  };

const FilterUI = ({
  source,
  resourceFilter,
  handleReset,
  setSelectedKeys,
  confirm,
}) => {
  const defaultValue = get(resourceFilter.filter, `${source}`);
  const [value, setValue] = useState(
    defaultValue && [moment(defaultValue.$gte), moment(defaultValue.$lte)],
  );
  return (
    <div style={{ padding: 8 }}>
      <RangePicker
        value={value}
        onChange={(e) => {
          setValue(e[0] && [e[0].startOf('day'), e[1] && e[1].endOf('day')]);
          setSelectedKeys([
            {
              $gte: e[0] && e[0].startOf('day').toISOString(),
              $lte: e[1] && e[1].endOf('day').toISOString(),
            },
          ]);
        }}
      />
      <div style={{ marginTop: 8, textAlign: 'right' }}>
        <Button
          type="primary"
          onClick={() => confirm()}
          icon="search"
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          {I18n.t('button.search')}
        </Button>
        <Button
          onClick={() => {
            setValue(undefined);
            handleReset(source);
          }}
          size="small"
          style={{ width: 90 }}
        >
          {I18n.t('button.reset')}
        </Button>
      </div>
    </div>
  );
};

dateFilterDropdown.propTypes = {
  setSelectedKeys: PropTypes.func,
  confirm: PropTypes.func,
  resourceFilter: PropTypes.object,
  source: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
};

RestFormDateTimePicker.propTypes = {
  source: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  record: PropTypes.object,
};

export default RestFormDateTimePicker;

import { Col, Divider, Empty, Row, Space } from 'antd';
import ReadMore from 'components/common/ReadMore';
import { ACTIVITY_TYPES } from 'configs/localData';
import i18next from 'i18next';
import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import { getTaskById } from 'redux/applications/selectors';
import { formatDateTime } from 'utils/textUtils';
import DoneButton from './DoneTaskButton';
import HeaderTaskDetail from './Header';
import { TaskDetailWrapper, TaskContentWrapper } from './styles';

const ActivitiesDetail = ({ taskId }) => {
  const task = useSelector(getTaskById(taskId));
  const type = ACTIVITY_TYPES.find((ele) => ele.value === task?.type);

  const renderTaskContent = () => (
    <TaskContentWrapper>
      <div className="icon-type">
        {type?.IconCPN()}
        <p style={{ color: type?.color }}>{i18next.t(type?.text)}</p>
      </div>
      <div className="field-item">
        <Row gutter={16}>
          <Col xl={24}>{i18next.t('task.subject')}:</Col>
          <Col xl={24}>{task.subject}</Col>
        </Row>
      </div>
      <Divider />
      <div className="field-item">
        <Row gutter={16}>
          <Col xl={6} lg={6} md={24} xs={24}>
            {i18next.t('task.date')}:
          </Col>
          <Col xl={18} lg={18} md={24} xs={24}>
            {formatDateTime(task.date)}
          </Col>
        </Row>
      </div>
      <Divider />
      <div className="field-item">
        <Row gutter={16}>
          <Col xl={24} lg={24} md={24} xs={24}>
            {i18next.t('task.note')}:
          </Col>
          <Col xl={24} lg={24} md={24} xs={24}>
            <ReadMore text={task.note} readMoreCharacterLimit={500} />
          </Col>
        </Row>
      </div>
      <Divider />
      <div className="icon-type">
        <DoneButton taskId={task.id} />
      </div>
    </TaskContentWrapper>
  );

  return (
    <TaskDetailWrapper>
      <HeaderTaskDetail record={task} />
      <Divider />
      <Space className="list-card-info" direction="vertical" size={16}>
        {task.id ? (
          renderTaskContent()
        ) : (
          <Empty description={i18next.t('task.noTask')} />
        )}
      </Space>
    </TaskDetailWrapper>
  );
};
ActivitiesDetail.propTypes = {
  taskId: PropTypes.string,
};
export default ActivitiesDetail;

import { Col, Divider, Row, Tag, Tooltip, Typography } from 'antd';
import {
  BriefcaseIcon,
  DollarSquareIcon,
  EducatorDiscountFillIcon,
  DiscountPercentageIcon,
} from 'components/common/SVGIcon';
import i18next from 'i18next';
import React from 'react';
import PropTypes from 'prop-types';
import DotCircle from 'components/common/DotCircle';
import { JOB_DISCOUNT_TYPES } from 'configs/localData/constant';
import { JobTemplateCardStyles } from './styles';

const JobTemplateCard = ({ data, handleRedirectDetail }) => {
  const {
    templateName,
    applications,
    jobType,
    newApplications,
    jobListings,
    workplaces,
    discount,
    salary,
    discountType,
  } = data;

  return (
    <JobTemplateCardStyles onClick={handleRedirectDetail}>
      <Tooltip title={templateName}>
        <Typography.Title className="title fw-bold" ellipsis={{ rows: 2 }}>
          {templateName}
        </Typography.Title>
      </Tooltip>

      <Row justify="space-between" gutter={20}>
        <Col span={12} className="flex gap-8">
          <div className="tag">{jobListings?.toLocaleString('en-US')}</div>
          <Typography.Text text-black-800>
            {i18next.t('jobListings.jobListings')}
          </Typography.Text>
        </Col>
        <Col span={12} className="flex gap-8">
          <div className="tag">{workplaces?.toLocaleString('en-US')}</div>
          <Typography.Text className="text-black-800">
            {i18next.t('jobListings.workplaces')}
          </Typography.Text>
        </Col>
      </Row>

      <Divider />

      <div className="flex-center-start gap-8">
        <DollarSquareIcon className="text-16" />
        <Tooltip title={salary}>
          <Typography.Text className="text-dark-green fw-bold" ellipsis>
            {salary}
          </Typography.Text>
        </Tooltip>
      </div>

      <div className="job-card__info-item mt-12 ellipsis-t flex-center-start">
        {discountType && !!discount && (
          <Tooltip title={discountType} className="flex-center-start">
            {discountType === JOB_DISCOUNT_TYPES?.educatorDiscount ? (
              <EducatorDiscountFillIcon className="text-18 mr-10" />
            ) : (
              <DiscountPercentageIcon className="text-18 mr-10" />
            )}

            <span className="text-14 text-black-base">{`${discount}%`}</span>
            <DotCircle color="#D0D5DD" size={5} />
          </Tooltip>
        )}
        {jobType && (
          <Tag icon={<BriefcaseIcon />} className="text-14 fw-600">
            {jobType}
          </Tag>
        )}
      </div>

      <Divider />

      <Row gutter={20}>
        <Col span={12} className="statistic">
          <div className="decor-line decor-line--green" />
          <div className="flex flex-col gap-10">
            <Typography.Text className="text-12 fw-bold text-gray-500">
              {i18next.t('jobListings.jobListingDetails.totalApplications')}
            </Typography.Text>
            <Typography.Text className="text-20 fw-bold text-neutral-800">
              {applications?.toLocaleString('en-US')}
            </Typography.Text>
          </div>
        </Col>
        <Col span={12} className="statistic">
          <div className="decor-line decor-line--pink" />
          <div className="flex flex-col gap-10">
            <Typography.Text className="text-12 fw-bold text-gray-500">
              {i18next.t('jobListings.jobListingDetails.newApplications')}
            </Typography.Text>
            <Typography.Text className="text-20 fw-bold text-neutral-800">
              {newApplications?.toLocaleString('en-US')}
            </Typography.Text>
          </div>
        </Col>
      </Row>
    </JobTemplateCardStyles>
  );
};

export default JobTemplateCard;

JobTemplateCard.propTypes = {
  data: PropTypes.object,
  handleRedirectDetail: PropTypes.func,
  discount: PropTypes.string,
  discountType: PropTypes.string,
};

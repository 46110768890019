import { baseApi } from 'redux/@rtkQuery/baseApi';
import { PREFIX_API_VER_2 } from 'configs/localData/constant';
import { EDUCATOR_STAFF_PROFILE_TAG } from 'redux/@rtkQuery/tagTypes';
import {
  CreateStaffResponse,
  DeleteStaffProfilePayload,
  EditStaffProfileResponse,
  ExistingUserListResponse,
  ExistingUserPayload,
  StaffProfileConfigResponse,
  StaffProfileListPayload,
  StaffProfileListResponse,
  StaffProfileModel,
  SuggestionPayload,
  SuggestionResponse,
} from 'types/staffProfiles';
import { stringifyObjectWithBrackets } from 'api/utils';
import { DEFAULT_CURRENT_PAGE } from 'containers/MarketingMetrics/constants';

export const educatorStaffProfileApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getStaffProfileConfigs: builder.query<StaffProfileConfigResponse, void>({
      query: () => `/${PREFIX_API_VER_2}/crm/centre/educator-profiles/configs`,
    }),

    getStaffProfiles: builder.query<
      StaffProfileListResponse,
      StaffProfileListPayload
    >({
      query: (params) => ({
        url: `/${PREFIX_API_VER_2}/crm/centre/educator-profiles?`,
        params,
      }),
      providesTags: [EDUCATOR_STAFF_PROFILE_TAG],
    }),

    editStaffProfiles: builder.mutation<
      EditStaffProfileResponse,
      StaffProfileModel
    >({
      query: ({ id, ...restPayload }) => ({
        url: `/${PREFIX_API_VER_2}/crm/centre/educator-profiles?id=${id}`,
        method: 'PUT',
        body: restPayload,
      }),
      extraOptions: { isShowSuccess: true },
      invalidatesTags: [EDUCATOR_STAFF_PROFILE_TAG],
    }),

    getSuggestions: builder.query<SuggestionResponse, SuggestionPayload>({
      query: (payload) =>
        `/${PREFIX_API_VER_2}/crm/centre/educator-profiles/suggestion?${stringifyObjectWithBrackets(
          payload,
        )}`,
    }),

    createStaffProfile: builder.mutation<
      CreateStaffResponse,
      StaffProfileModel
    >({
      query: (payload) => ({
        url: `/${PREFIX_API_VER_2}/crm/centre/educator-profiles`,
        method: 'POST',
        body: payload,
      }),
      extraOptions: { isShowSuccess: true },
      invalidatesTags: [EDUCATOR_STAFF_PROFILE_TAG],
    }),

    deleteStaffProfile: builder.mutation<null, DeleteStaffProfilePayload>({
      query: ({ id }) => ({
        url: `/${PREFIX_API_VER_2}/crm/centre/educator-profiles?id=${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: [EDUCATOR_STAFF_PROFILE_TAG],
    }),

    getExistingUsers: builder.query<
      ExistingUserListResponse,
      ExistingUserPayload
    >({
      query: (params) => ({
        url: `/${PREFIX_API_VER_2}/crm/centre/educator-profiles/users`,
        params,
      }),
      serializeQueryArgs: ({ endpointName }) => endpointName,
      merge: (currentCache, newItems, otherArgs) => {
        if (
          otherArgs.arg.filter?.name &&
          otherArgs.arg.page === DEFAULT_CURRENT_PAGE
        ) {
          return newItems;
        }
        if (otherArgs.arg.page === DEFAULT_CURRENT_PAGE) {
          return newItems;
        }
        currentCache.results.push(...newItems.results);
      },
    }),
  }),
});

export const {
  useGetStaffProfilesQuery,
  useGetStaffProfileConfigsQuery,
  useEditStaffProfilesMutation,
  useGetSuggestionsQuery,
  useCreateStaffProfileMutation,
  useDeleteStaffProfileMutation,
  useGetExistingUsersQuery,
} = educatorStaffProfileApi;

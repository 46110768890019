export const getBackgroundColorApplicationList = (
  isDraggingOver,
  isDraggingFrom,
) => {
  if (isDraggingOver) {
    return '#FFEBE6';
  }
  if (isDraggingFrom) {
    return '#E6FCFF';
  }
  return 'transparent';
};

export const getBackgroundColorApplicationItem = (
  isDragging,
  isGroupedOver,
  authorColors,
) => {
  if (isDragging) {
    return authorColors.soft;
  }

  if (isGroupedOver) {
    return '#EBECF0';
  }

  return '#FFFFFF';
};

export const getBorderColorApplicationItem = (isDragging, authorColors) =>
  isDragging ? authorColors.hard : 'transparent';

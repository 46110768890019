import React from 'react';
import PropTypes from 'prop-types';
import { Divider } from 'antd';
import i18next from 'i18next';

const CardCustom = ({ title, children }) => (
  <div className="bg-white rounded-20 h-full mb-20">
    <div className="text-center flex-center fw-bold min-h-64 text-16 mx-12">
      {i18next.t(title)}
    </div>
    <Divider className="m-0 p-0" />
    <div className="mr-24 h-5/6">{children}</div>
  </div>
);

CardCustom.propTypes = {
  title: PropTypes.string,
  children: PropTypes.any,
};

export default CardCustom;

import React, { useState, forwardRef, useImperativeHandle } from 'react';
import ModalCustom from 'components/common/ModalCustom';
import i18next from 'i18next';

const MinimumJobMatchModal = forwardRef((props, ref) => {
  const [visible, setVisible] = useState(false);

  useImperativeHandle(
    ref,
    () => ({
      openModal: () => setVisible(true),
    }),
    [],
  );

  const onCancel = () => setVisible(false);

  return (
    <ModalCustom
      title={i18next.t('jobs.basicInformation.minimumJobMatch')}
      visible={visible}
      onCancel={onCancel}
      footer={null}
      {...props}
    >
      <div className="whitespace-pre-line pb-24">
        {i18next.t('jobs.basicInformation.minJobMatchDesc')}
      </div>
    </ModalCustom>
  );
});

export default MinimumJobMatchModal;

import styled from 'styled-components';

export const CustomTooltipWrapper = styled.div`
  background: white;
  width: 375px;
  padding: 10px 15px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.15);
  border-radius: 12px;

  .tooltip-header {
    text-align: left;
    color: #aba7a7;
    margin-bottom: 10px;
  }

  .square-tooltip {
    width: 10px;
    height: 10px;
    border-radius: 3px;
    margin-right: 5px;
  }

  .content-tooltip {
    list-style-type: none;
    & > .value-item {
      display: flex;
      align-items: center;
      &:not(:first-child) {
        margin-top: 10px;
      }
    }
  }

  .title-tooltip {
    margin-bottom: 10px;
    text-align: center;
  }
  .strong-value {
    margin-left: 5px;
  }

  .value-item {
    display: flex;
    justify-content: space-between;

    .text-value {
      text-align: left;
      margin-right: auto;
      white-space: nowrap;
      width: 250px;
      max-width: 250px;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
`;

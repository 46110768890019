import React from 'react';

import { Col, Row } from 'antd';

import RestSelect from 'components/RestInput/RestSelect';
import ReferenceInput from 'containers/rest/ReferenceInput';
import COUNTRIES from 'configs/localData/countries';

const AddressAdditionalForm = () => (
  <div>
    <Row gutter={16}>
      <Col md={12} xs={24}>
        <ReferenceInput
          source="LGA"
          resource="LGA"
          searchKey="name"
          filterKey="name"
          initialFilter={{ orderBy: 'name' }}
        >
          <RestSelect
            header="input.LGA.name"
            titleProp="name"
            valueProp="name"
            placeholder="input.LGA.placeholder"
            isFilterOption={false}
          />
        </ReferenceInput>
      </Col>

      <Col md={12} xs={24}>
        <ReferenceInput
          source="region"
          resource="regions"
          searchKey="name"
          filterKey="name"
          initialFilter={{ orderBy: 'name' }}
        >
          <RestSelect
            header="input.region.name"
            titleProp="name"
            valueProp="name"
            placeholder="input.region.placeholder"
            isFilterOption={false}
          />
        </ReferenceInput>
      </Col>

      <Col md={12} xs={24}>
        <RestSelect
          required
          titleProp="name"
          valueProp="name"
          source="country"
          header="centres.country"
          placeholder="input.country.placeholder"
          resourceData={COUNTRIES}
          defaultValue={COUNTRIES[0].name}
        />
      </Col>
    </Row>
  </div>
);

export default AddressAdditionalForm;

import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import I18n from 'i18next';
import { Menu, Dropdown, Avatar } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import { logout } from 'redux/auth/actions';

const UserInfoHeader = () => {
  const profileMenu = [
    {
      key: 'profile',
      text: 'header.profile',
      url: '/profile',
    },
  ];

  const dispatch = useDispatch();

  const currentUser = useSelector((state) => state.auth.data);

  const roleDescription = useSelector(
    (state) => state.auth.data?.role?.description,
  );

  return (
    <Dropdown
      overlay={() => (
        <Menu style={{ minWidth: '120px' }}>
          {profileMenu.map((menu) => (
            <Menu.Item key={menu.key}>
              <Link to={menu.url}>{I18n.t(menu.text)}</Link>
            </Menu.Item>
          ))}
          <Menu.Divider />
          <Menu.Item onClick={() => dispatch(logout())} key="logout">
            {I18n.t('header.logout')}
          </Menu.Item>
        </Menu>
      )}
      trigger={['click']}
    >
      <div className="div-user-info">
        <span className="userInfo">
          <strong>
            {`${currentUser.firstName || ''} ${currentUser.lastName || ''}`}
          </strong>
          <span className="role">{roleDescription}</span>
        </span>
        <Avatar
          src={currentUser?.avatar}
          size="large"
          icon={<UserOutlined />}
        />
      </div>
    </Dropdown>
  );
};

export default UserInfoHeader;

export const QUERY_ALL_ID = 'all';

export const MAX_LIMIT = 100;

export const DEFAULT_ORDER_BY = 'name';

export const CHART_DATE_FORMAT = 'DD MMM YYYY';

export const ALL_COMPANIES_COLOR = '#26C1FC';

export const ALL_COMPANIES_DATA_KEY = 'allCompaniesProportion';

export const SELECTED_COMPANY_COLOR = '#FF73C3';

export const SELECTED_COMPANY_DATA_KEY = 'userCompaniesProportion';

export const DEFAULT_COMPARISON_WIDTH_CHART = 1300;

export const DEFAULT_RADIUS_STYLE = [3, 3, 0, 0];

export const DEFAULT_LEGEND_TYPE = 'square';

export const DEFAULT_BAR_SIZE = 10;

export const DEFAULT_HOVER_COLOR = '#F0F0F0';

export const TICK_COLOR = '#ABA7A7';

export const AVG_POSITION_LINE = {
  MIDDLE: 'middle',
  START: 'start',
  END: 'end',
};

export const DEFAULT_MARGIN_CHART = {
  top: 30,
  bottom: 5,
  left: 8,
};

export const LEGEND_LABEL_TYPE = {
  SQUARE: 'square',
  DASHED: 'dashed',
};

export const MAX_LABEL_LENGTH = 40;

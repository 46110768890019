import styled from 'styled-components';

const InfoRequestStyles = styled.div`
  background: ${({ theme }) => theme.background.content};
  padding: 10px;
  border-radius: 8px;
  .list-card-info {
    width: 100%;
  }
`;

export default InfoRequestStyles;

export const HeaderInfoRequestStyles = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 10px;
  align-items: center;
  margin-bottom: 10px;
  .title-card {
    font-weight: bold;
    font-size: 20px;
    line-height: 25px;
    color: ${({ theme }) => theme.palette.primary};
  }
`;

export const CardInfoRequestStyles = styled.div`
  box-shadow: 0px 2px 10px rgba(39, 40, 51, 0.08);
  border-radius: 8px;
  padding: 10px 20px;
  .title-info {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    font-size: 18px;
    > span {
      font-weight: bold;
    }
    .delete-request-btn {
      width: 32px;
      height: 32px;
      padding: 0;
      min-width: 32px !important;
      border: none;
      .anticon {
        font-size: 18px;
      }
    }
  }
  .card-info {
    font-size: 16px;
    width: 100%;
    &__item {
      &__name {
        line-height: 20px;
        margin-right: 10px;
        &:after {
          content: ':';
        }
      }
      &__value {
        line-height: 20px;
        font-weight: 600;
        min-width: 0;
      }
      .message-value {
        white-space: pre-line;
      }
    }
    .item-status {
      display: flex;
      align-items: center;
      .value-status {
        flex-grow: 1;
      }
    }
  }
`;

import React, { useEffect } from 'react';
import { Button, Modal, Space, Spin, Tooltip, Typography } from 'antd';
import i18next from 'i18next';
import CustomBreadcrumb from 'components/common/Breadcrumb';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import {
  getDataJobListingDetail,
  getLoadingJobListingDetail,
} from 'redux/jobs/jobListings/selectors';
import {
  closeJobListing,
  getJobListingsDetail,
} from 'redux/jobs/jobListings/actions';
import { isEmpty } from 'lodash-es';
import useRouter from 'hooks/useRouter';
import {
  OpenJobNewTabIcon,
  ContentNotAvailableIcon,
} from 'components/common/SVGIcon';
import { getLinkJobDetailRichWeb } from 'utils/dataUtils';
import JobListingDetail from '../components/JobListingDetail';
import { CLOSED_JOB_LISTING } from '../constants';

const JobListingShow = () => {
  const { id } = useParams();
  const { history } = useRouter();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getJobListingsDetail({ id }));
  }, [id, dispatch]);

  const currentData = useSelector(getDataJobListingDetail) || {};
  const loading = useSelector(getLoadingJobListingDetail);
  const currentJobListingID = currentData?.id;
  const isDisabled = currentData?.jobStatus === CLOSED_JOB_LISTING;

  const customBreadcrumb = [
    {
      title: 'Job Listings',
      path: '/job-listings/individual/board',
    },
    {
      title: currentData?.campaignName || i18next.t('error.waitingUpdate'),
      path: '#',
    },
    {
      title: currentData?.templateName || i18next.t('error.waitingUpdate'),
      path: '#',
    },
  ];

  const handleViewAllJobListing = () => {
    history.push('/job-listings/campaign/board');
  };

  const showConfirm = () => {
    Modal.confirm({
      title: i18next.t('jobListings.closeJob.confirmMessage'),
      okText: i18next.t('jobListings.buttons.closeJob'),
      onOk: () => {
        currentJobListingID && dispatch(closeJobListing(currentJobListingID));
      },
    });
  };

  return (
    <div>
      {loading ? (
        <Spin className="flex-center" />
      ) : (
        <>
          {!isEmpty(currentData) && (
            <CustomBreadcrumb data={customBreadcrumb} />
          )}
          <div className="flex flex-row flex-center-between">
            <Typography.Text className="text-32 fw-bold">
              {i18next.t('jobListings.jobListingDetails.title')}
            </Typography.Text>
            {!isEmpty(currentData) && (
              <Space>
                <Tooltip
                  title={
                    isDisabled
                      ? i18next.t('jobListings.closeJob.waitingInfo')
                      : undefined
                  }
                  placement="top"
                >
                  <Button
                    className="btn-line-primary fw-bold"
                    disabled={isDisabled}
                  >
                    <a
                      href={getLinkJobDetailRichWeb(currentData?.previewJob)}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Space>
                        <OpenJobNewTabIcon />
                        {i18next.t('jobListings.buttons.viewAsJobSeeker')}
                      </Space>
                    </a>
                  </Button>
                </Tooltip>
                <Tooltip
                  title={
                    isDisabled
                      ? i18next.t('jobListings.closeJob.waitingInfo')
                      : undefined
                  }
                  placement="left"
                >
                  <Button
                    className="btn-line-primary fw-bold"
                    onClick={showConfirm}
                    disabled={isDisabled}
                  >
                    {i18next.t('jobListings.buttons.closeJob')}
                  </Button>
                </Tooltip>
              </Space>
            )}
          </div>

          {!isEmpty(currentData) ? (
            <JobListingDetail />
          ) : (
            <div
              className={'flex-center w-full h-60-screen flex-direction-column'}
            >
              <ContentNotAvailableIcon />
              <Typography.Text className="text-16">
                {i18next.t('error.contentIsNotAvailable')}
              </Typography.Text>
              <Button
                type="primary"
                htmlType="submit"
                onClick={handleViewAllJobListing}
                className={'mt-24'}
              >
                {i18next.t('button.viewAllJobListings')}
              </Button>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default JobListingShow;

import React from 'react';
import PropTypes from 'prop-types';
import { Input, Tooltip } from 'antd';
import i18next from 'i18next';
import FormItem from 'components/form/FormItem';
import { getRecordData } from 'utils/tools';
import { RestInputContext } from '../RestInputContext';

const RestInputItem = ({ ...props }) => {
  const {
    ContentComponent,
    isReference,
    children,
    format,
    placeholder,
    isShowTooltip,
    rules,
    isValidatorEmpty,
    defaultValue,
    minLength,
    maxLength,
    ruleType,
    valuePropName,
    required,
    onChange,
    inputProps,
    ...rest
  } = props;
  const getDefaultValue = (record) =>
    defaultValue || format(getRecordData(record, rest.source));

  const validatorEmpty = (rule, value) => {
    if (value && typeof value === 'string') {
      const restLength = value.trim().length;
      if (required && restLength === 0) {
        return Promise.reject(i18next.t('error.empty'));
      }
      if (required && minLength && restLength < minLength) {
        return Promise.reject(i18next.t('error.minLength', { min: minLength }));
      }
      if (maxLength && restLength > maxLength) {
        return Promise.reject(i18next.t('error.maxLength', { max: maxLength }));
      }
    }
    return Promise.resolve();
  };

  const customRules =
    isValidatorEmpty &&
    required &&
    (ruleType === undefined || ruleType === 'string')
      ? [
          {
            validator: validatorEmpty,
          },
          ...rules,
        ]
      : [...rules];

  return (
    <RestInputContext.Consumer>
      {
        ({ record, form, handleSubmit, allowPressEnter }) =>
          isReference ? (
            React.cloneElement(children, {
              record,
            })
          ) : (
            <Tooltip
              placement="top"
              title={isShowTooltip ? i18next.t(placeholder) : ''}
            >
              <div>
                <FormItem
                  {...rest}
                  required={required}
                  rules={customRules}
                  form={form}
                  name={props.source}
                  defaultValue={getDefaultValue(record)}
                  ruleType={ruleType}
                  valuePropName={valuePropName}
                >
                  <ContentComponent
                    {...rest}
                    onChange={onChange}
                    {...inputProps}
                    {...(allowPressEnter &&
                      handleSubmit && { onPressEnter: handleSubmit })}
                    placeholder={i18next.t(placeholder)}
                  />
                </FormItem>
              </div>
            </Tooltip>
          )
        // eslint-disable-next-line react/jsx-curly-newline
      }
    </RestInputContext.Consumer>
  );
};

RestInputItem.propTypes = {
  source: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  record: PropTypes.object,
  defaultValue: PropTypes.any,
  ContentComponent: PropTypes.any,
  isReference: PropTypes.bool,
  children: PropTypes.any,
  format: PropTypes.func,
  placeholder: PropTypes.string,
  isShowTooltip: PropTypes.bool,
  isValidatorEmpty: PropTypes.bool,
  rules: PropTypes.array,
  required: PropTypes.bool,
  ruleType: PropTypes.string,
  minLength: PropTypes.number,
  maxLength: PropTypes.number,
  valuePropName: PropTypes.string,
  onChange: PropTypes.func,
  inputProps: PropTypes.object,
};

RestInputItem.defaultProps = {
  ContentComponent: Input,
  format: (data) => data,
  isValidatorEmpty: false,
  rules: [],
};

export default RestInputItem;

import React from 'react';
import { Button, Input, Form } from 'antd';
import i18next from 'i18next';
import PropTypes from 'prop-types';

import { getConfirmVideoURLRules } from 'utils/validateUtils';
import { URLVideoFormStyles } from './styles';

const URLVideoForm = ({ addURLVideo, form, label, placeholder }) => {
  const handleAddUrlVideo = () => {
    form.validateFields(['urlVideo']).then((values) => {
      if (values.urlVideo?.trim()) {
        addURLVideo(values.urlVideo);

        form.setFieldsValue({
          urlVideo: null,
        });
      }
    });
  };

  return (
    <URLVideoFormStyles>
      <b className="url-video__title">
        {label ?? i18next.t('text.orUrlVideo')}
      </b>
      <div className="flex">
        <Form.Item
          className="url-video__form-item"
          name="urlVideo"
          rules={getConfirmVideoURLRules()}
        >
          <Input placeholder={placeholder ?? i18next.t('text.urlVideo')} />
        </Form.Item>
        <Button
          type="primary"
          className="url-video__add-btn"
          onClick={handleAddUrlVideo}
        >
          {i18next.t('button.add')}
        </Button>
      </div>
    </URLVideoFormStyles>
  );
};

URLVideoForm.propTypes = {
  addURLVideo: PropTypes.func,
  form: PropTypes.object,
  label: PropTypes.string,
  placeholder: PropTypes.string,
};

export default URLVideoForm;

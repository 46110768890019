import React, { useCallback } from 'react';
import { FileOutlined } from '@ant-design/icons';
import { formatDateApplication } from 'utils/textUtils';
import { isImageFile, getExtensionFile } from 'utils/fileUtils';
import { FILE_TYPES, IMAGE_TYPE } from 'configs/localData';

import GroupActions from './GroupActions';

const useFormat = () => {
  const getFileItem = useCallback(
    (fileName) =>
      isImageFile(fileName)
        ? IMAGE_TYPE
        : FILE_TYPES.find(
            (file) =>
              // typeFile?.toLocaleLowerCase()?.includes(file.value.toLocaleLowerCase()),
              file.value.toLocaleLowerCase() ===
              getExtensionFile(fileName)?.toLocaleLowerCase(),
          ),
    [],
  );

  const formatTime = useCallback((data) => formatDateApplication(data), []);

  const formatDocumentName = useCallback(
    (data, record) => data || record.file?.name,
    [],
  );

  const formatAction = useCallback(
    (id, record) => <GroupActions id={id} file={record.file} />,
    [],
  );

  const formatFileType = useCallback(
    (data) => {
      const fileType = getFileItem(data);
      if (fileType?.icon)
        return (
          <fileType.icon style={{ color: fileType.color, fontSize: 30 }} />
        );
      return <FileOutlined style={{ fontSize: 30 }} />;
    },
    [getFileItem],
  );

  return {
    formatTime,
    formatAction,
    formatFileType,
    formatDocumentName,
  };
};

export default useFormat;

import React from 'react';
import CardSection from 'components/common/CardSection';
import i18next from 'i18next';
import { isEmpty } from 'lodash-es';
import { formatDateTimeTable } from 'utils/textUtils';
import useFormatFnc from '../AdvertisementList/useFormat';
import { TableStyled } from './styles';
import EmptyData from './EmptyData';

const EngagementInfo = ({ engagement }) => {
  const useFormat = useFormatFnc();

  const columns = [
    {
      title: i18next.t('advertisement.start'),
      dataIndex: 'from',
      render: (value) => useFormat.formatAvailableStart(value),
    },
    {
      title: i18next.t('advertisement.end'),
      dataIndex: 'to',
      render: (value) => useFormat.formatAvailableStart(value),
    },
    {
      title: i18next.t('advertisement.impressions'),
      dataIndex: 'impressions',
      width: '15%',
      render: (value) => <span className="fw-bold">{value}</span>,
    },
    {
      title: i18next.t('advertisement.clicks'),
      dataIndex: 'clicks',
      width: '15%',
      render: (value) => <span className="fw-bold">{value}</span>,
    },
    {
      title: i18next.t('advertisement.shares'),
      dataIndex: 'shares',
      width: '15%',
      render: (value) => <span className="fw-bold">{value}</span>,
    },
  ];

  return (
    <CardSection title="advertisement.engagement">
      {isEmpty(engagement) ? (
        <EmptyData description="advertisement.engagementsEmpty" />
      ) : (
        <>
          <TableStyled
            columns={columns}
            dataSource={[engagement]}
            pagination={false}
            rowKey="id"
            scroll={{ x: 800 }}
            className="p-12"
          />
          <div className="pl-12 text-14 text-gray-500">
            {`${i18next.t('advertisement.updatedUntil')} ${formatDateTimeTable(
              engagement?.to,
            )}.`}
          </div>
        </>
      )}
    </CardSection>
  );
};

export default EngagementInfo;

import React, { useState } from 'react';
import i18next from 'i18next';
import { Form, FormInstance, Modal, Radio, Spin, Typography } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { getCallingButtonListSelector } from 'redux/advertisement/selectors';
import { CallingButtonResult } from 'api/advertisement/type';
import {
  ArrowDownIcon,
  ArrowUpIcon,
  DeleteLightIcon,
  EditLightIcon,
} from 'components/common/SVGIcon';
import EditCallingButtonModal from 'containers/Advertisement/Create/Steps/AdCreative/EditCallingButtonModal';
import {
  deleteCallingsButton,
  getUsedCallingButtonCount,
} from 'redux/advertisement/actions';
import { InfoCircleFilled } from '@ant-design/icons';
import renderHTML from 'react-render-html';

interface CallingButtonProps {
  form: FormInstance;
  selectedCallingButton: CallingButtonResult;
  setSelectedCallingButton: (item: CallingButtonResult) => void;
}

const CallingButtonField: React.FC<CallingButtonProps> = ({
  form,
  selectedCallingButton,
  setSelectedCallingButton,
}) => {
  const dispatch = useDispatch();
  const callingButtonsList = useSelector(getCallingButtonListSelector);
  const [hoveringItem, setHoveringItem] = useState<string | null>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isCheckCountLoading, setIsCheckCountLoading] =
    useState<boolean>(false);
  const [isSeeMore, setIsSeeMore] = useState<boolean>(false);
  const [editingItem, setEditingItem] = useState<CallingButtonResult>();
  const [isDisabled, setIsDisabled] = useState<boolean>(false);
  const customCallingButtons = isSeeMore
    ? callingButtonsList
    : {
        ...callingButtonsList,
        results: callingButtonsList.results?.slice(0, 9),
      };

  const getUsedThisCallingButton = async (
    callingButtonId: string,
  ): Promise<number> => {
    setIsCheckCountLoading(true);
    const response: any = await dispatch(
      getUsedCallingButtonCount({ callingButtonId }),
    );
    return response.payload.result;
  };

  const handleDeleteCallingButton = async (callingButtonId: string) => {
    const count = await getUsedThisCallingButton(callingButtonId);
    if (count > 0) {
      Modal.confirm({
        title: (
          <div>
            {renderHTML(
              i18next.t('advertisement.notDeleteCallingButtonNotification', {
                numberOfAds: count,
                plurals: count > 1 ? 'ads are' : 'ad is',
              }),
            )}
          </div>
        ),
        cancelButtonProps: { className: 'd-none' },
        icon: <InfoCircleFilled />,
      });
    } else {
      Modal.confirm({
        title: i18next.t('advertisement.deleteCallingButtonConfirm'),
        onOk() {
          setIsLoading(true);
          dispatch(deleteCallingsButton({ callingButtonId }));
          if (selectedCallingButton.id === callingButtonId) {
            const defaultValue = customCallingButtons?.results[0];
            setSelectedCallingButton(defaultValue);
            form.setFieldsValue({ callingButton: defaultValue.id });
          }
          setIsLoading(false);
        },
        okText: i18next.t('button.delete'),
        okButtonProps: { loading: isLoading },
        icon: <InfoCircleFilled />,
      });
    }
    setIsCheckCountLoading(false);
  };

  const handleEditCallingButton = async (item: CallingButtonResult) => {
    const count = await getUsedThisCallingButton(item.id);
    if (count > 0) {
      Modal.confirm({
        title: (
          <div>
            {renderHTML(
              i18next.t('advertisement.confirmEditCallingButton', {
                numberOfAds: count,
                plurals: count > 1 ? 'ads are' : 'ad is',
              }),
            )}
          </div>
        ),
        icon: <InfoCircleFilled />,
        okText: i18next.t('advertisement.button.continue'),
        onOk: () => {
          setIsDisabled(true);
          setEditingItem(item);
        },
      });
    } else {
      setEditingItem(item);
    }
    setIsCheckCountLoading(false);
  };

  return (
    <Form.Item
      label={i18next.t('advertisement.callingButton')}
      name="callingButton"
      rules={[{ required: true, message: i18next.t('error.required') }]}
      className={'w-full'}
    >
      <Radio.Group value={'callingButton'} className="w-full">
        <div className="column-items flex-col w-full">
          {customCallingButtons.results?.map((item) => (
            <div
              key={item?.id}
              className="break-inside-avoid my-6 flex-center-between w-full"
              onMouseEnter={() => setHoveringItem(item.id)}
              onMouseLeave={() => setHoveringItem(null)}
            >
              <Radio
                value={item?.id}
                onClick={() => setSelectedCallingButton(item)}
              >
                {item?.name}
              </Radio>
              {!item.isSystem &&
                hoveringItem === item.id &&
                (isCheckCountLoading ? (
                  <Spin />
                ) : (
                  <div className="flex gap-4">
                    <EditLightIcon
                      className="text-gray-500"
                      onClick={() => handleEditCallingButton(item)}
                    />

                    <DeleteLightIcon
                      onClick={() => handleDeleteCallingButton(item.id)}
                    />
                  </div>
                ))}
            </div>
          ))}
          {callingButtonsList?.results?.length > 9 && (
            <div className={'w-fit pl-28'}>
              {isSeeMore ? (
                <div
                  className={'flex-center-between gap-4 cursor-pointer'}
                  onClick={() => setIsSeeMore(false)}
                  role={'presentation'}
                >
                  <Typography.Text className={'text-primary text-16'}>
                    {i18next.t('advertisement.button.seeLess')}
                  </Typography.Text>
                  <ArrowUpIcon className={'text-9 text-primary'} />
                </div>
              ) : (
                <div
                  className={'flex-center-between gap-4 cursor-pointer'}
                  onClick={() => setIsSeeMore(true)}
                  role={'presentation'}
                >
                  <Typography.Text className={'text-primary text-16'}>
                    {i18next.t('advertisement.button.seeMore')}
                  </Typography.Text>
                  <ArrowDownIcon className={'text-9 text-primary'} />
                </div>
              )}
            </div>
          )}
          <EditCallingButtonModal
            key={editingItem?.id}
            defaultValue={editingItem}
            visible={!!editingItem}
            onClose={(values) => {
              setEditingItem(null);
              setIsDisabled(false);
              if (values) {
                setSelectedCallingButton({
                  ...selectedCallingButton,
                  ...values,
                });
              }
            }}
            isDisabled={isDisabled}
          />
        </div>
      </Radio.Group>
    </Form.Item>
  );
};

export default CallingButtonField;

import styled from 'styled-components';

export const JobListingCardStyles = styled.div`
  background: white;
  height: 100%;
  border-radius: 8px;
  padding: 8px 24px 0px 24px;
  margin-top: 8px;
  color: ${({ theme }) => theme.text.blackNeutral800} !important;
  cursor: pointer;

  .listing-status {
    padding: 16px 0px;
  }

  .status {
    &__open {
      padding: 4px 10px;
      border-radius: 6px;
      background-color: ${({ theme }) => theme.background.successSoft};
      color: ${({ theme }) => theme.text.successDefault};
    }
    &__closed {
      padding: 4px 10px;
      border-radius: 6px;
      background-color: ${({ theme }) => theme.background.gray200};
      color: ${({ theme }) => theme.text.black};
    }
    &__deleted {
      padding: 4px 10px;
      border-radius: 6px;
      background-color: ${({ theme }) => theme.background.errorSoft};
      color: ${({ theme }) => theme.text.errorDefault};
    }
  }

  .salary-text {
    color: #105b5c;
    font-weight: 700;
  }

  .job-card {
    &__info-item {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      display: flex;
      align-items: center;
      line-height: 16px;
      color: #141533;
      flex-wrap: wrap;
      gap: 10px 0;

      &:first-child {
        margin-top: 20px;
      }
      &:not(:first-child) {
        padding: 15px 0 0;
      }
      .text-highlight {
        color: var(--color-pastel-green);
      }
      .centre-address-distance__address {
        color: #141533;
      }
      .centre-address-distance__value > span {
        font-size: 10px;
        color: var(--color-gray-neutral) !important;
        padding: 0 2px;
      }
      .ant-tag {
        border-radius: 7px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0;
        .anticon {
          margin-right: 7px;
          color: var(--black-neutral);
        }
      }
      .salary {
        font-weight: bold;
        color: var(--color-pastel-green);
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }
  }

  @media screen and (max-width: 1180px) {
    .job-card {
      &__info-item {
        &:last-child > .posted-time {
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }
      }
    }
  }
`;

export const StatisticsInfoStyles = styled.div`
  padding-left: 5px;
  position: relative;
  line-height: 35px;
  &::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0px;
    bottom: 0px;
    width: 5px;
    background-color: ${({ isTotalApplications }) =>
      isTotalApplications ? '#2BADA7' : '#FD82C3'};
    border-radius: 8px;
  }
`;

import CreateButton from 'containers/Centres/components/CentreTabs/ServicesTab/components/CreateButton';
import ServicesTable from 'containers/Centres/components/CentreTabs/ServicesTab/components/TableServices';
import ServicesTabStyles from 'containers/Centres/components/CentreTabs/ServicesTab/styles';
import { useProgramPermissionControllerGetProgramPermissionQuery } from 'generated/apis';
import i18next from 'i18next';
import React from 'react';
import { CentreModel } from 'types/centre';

interface Props {
  record: CentreModel;
}

const ServicesCentreTab = ({ record }: Props) => {
  const { data: permission } =
    useProgramPermissionControllerGetProgramPermissionQuery();
  const isCreate = permission?.result?.isCreate;
  const isEdit = permission?.result?.isEdit;

  return (
    <ServicesTabStyles>
      <div className="header-title">
        <div className="title-name">{i18next.t('centres.services')}</div>
        <CreateButton
          record={record}
          isProgramCreate={isCreate}
          isProgramEdit={isEdit}
        />
      </div>
      <ServicesTable isProgramEdit={isEdit} isProgramCreate={isCreate} />
    </ServicesTabStyles>
  );
};

export default ServicesCentreTab;

import styled from 'styled-components';

export const UserTagWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  .name-user {
    font-weight: 600;
  }
  .ant-avatar {
    min-width: 32px;
    min-height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .link-class {
    word-break: break-word;
    color: ${({ theme }) => theme.text.primary};
    &:hover,
    &:active {
      color: ${({ theme }) => theme.palette.primary} !important;
    }
  }
  .non-link-class {
    word-break: break-word;
    color: ${({ theme }) => theme.text.primary};
    cursor: auto;
  }
  .avatar-item {
    display: inline-flex;
  }
  .small-text {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    word-break: break-all;
    color: '#8d8a8a';
  }

  .avatar-wrapper {
    position: relative;
    margin-right: 10px;
    .gender-icon {
      font-size: 12px;
      position: absolute;
      padding: 3px;
      border-radius: 50%;
      bottom: 0;
      right: -5px;
    }
  }
`;

import styled from 'styled-components';

const CardCommonStyles = styled.div`
  padding: 20px;
  border-radius: 16px;
  height: 100%;
  background: ${({ isLightBackground }) =>
    isLightBackground ? '#fff' : '#EEF4FF'};
  .title-card {
    font-weight: bold;
    font-size: 20px;
    line-height: 25px;
    word-break: break-word;
    margin-bottom: 20px;
  }
  .content-card-info {
    background: #fff;
    border-radius: ${({ isLightBackground }) =>
      isLightBackground ? '0' : '16px'};
    padding: ${({ isLightBackground }) => (isLightBackground ? '0' : '15px')};
    max-height: 50vh;
    overflow-y: auto;
    &::-webkit-scrollbar {
      border-radius: 10px;
      width: 4px;
    }
    ::-webkit-scrollbar-track {
      background: transparent !important;
    }
    ::-webkit-scrollbar-thumb {
      background: transparent !important;
    }

    &:hover {
      ::-webkit-scrollbar-thumb {
        background: ${({ theme }) => theme.scrollbar.thumb} !important;
      }
    }
  }
`;

export default CardCommonStyles;

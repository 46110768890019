import { ENQUIRIES_STATUS_CONST } from 'configs/localData';
import { uniqBy, remove, xor } from 'lodash-es';
import { makeCRUDSlice } from 'redux/crudCreator';
import {
  MODEL_NAME,
  enquiriesActions,
  sendMessage,
  getMessages,
  readMessage,
  clearMessages,
  addNewMessageAfterCreateInfoRequest,
  delMessageAfterDelInfoRequest,
  getTotalUnreadEnquires,
  archiveEnquiry,
  getSummariesEnquiry,
} from './actions';

const INITIAL_STATE = {
  filter: {},
  offset: 0,
  limit: 10,
  total: 0,
  numberOfPages: 1,
  sort: '',
};

const readMessageSuccess = (state, { meta: { arg } }) => {
  if (state.data[arg?.id]?.id) {
    if (arg.isRemoveItem) {
      delete state.data[arg.id];
      state.ids = xor(state.ids, [arg.id]);
    } else {
      state.data[arg.id] = {
        ...state.data[arg.id],
        centreHasNewMessage: false,
      };
    }
  }

  state.currentData = {
    ...state.currentData,
    centreHasNewMessage: false,
  };

  state.totalUnread = (state.totalUnread || 1) - 1;
  if (state.summaries?.[ENQUIRIES_STATUS_CONST.unread.keySummary]) {
    state.summaries[ENQUIRIES_STATUS_CONST.unread.keySummary] -= 1;
    state.summaries[ENQUIRIES_STATUS_CONST.read.keySummary] += 1;
  }
};

const sendMessagePending = (state) => {
  state.sendMessageLoading = true;
};

const sendMessageSuccess = (
  state,
  { payload: { data, inboxId }, meta: { arg } },
) => {
  state.error = null;

  const resourceData = state.currentData?.resourceData || {};
  state.currentData = {
    ...state.currentData,
    resourceData: {
      ...resourceData,
      total: (resourceData?.total || 0) + 1,
    },
    listMessages: [data, ...state.currentData?.listMessages],
    latestMessage: data,
    centreResponded: true,
  };

  if (state.data[inboxId]?.id) {
    if (arg?.isRemoveItem) {
      delete state.data[inboxId];
      state.ids = xor(state.ids, [inboxId]);
    } else {
      state.data[inboxId] = {
        ...state.data[inboxId],
        latestMessage: data,
        centreResponded: true,
      };
    }
  }
  if (
    !arg?.centreResponded &&
    state.summaries?.[ENQUIRIES_STATUS_CONST.notResponded.keySummary]
  ) {
    state.summaries[ENQUIRIES_STATUS_CONST.notResponded.keySummary] -= 1;
    state.summaries[ENQUIRIES_STATUS_CONST.responded.keySummary] += 1;
  }

  state.sendMessageLoading = false;
};

const sendMessageFailure = (state, { payload: { data } }) => {
  state.error = data;
  state.sendMessageLoading = false;
};

const getMessagesPending = (
  state,
  {
    meta: {
      arg: { data, options = {} },
    },
  },
) => {
  state.getMessagesLoading = true;
  const resourceData = state.currentData?.resourceData || {};

  if (options.isRefresh) {
    // eslint-disable-next-line
    state.currentData.resourceData = {
      ...INITIAL_STATE,
      loading: true,
      ...data,
    };

    state.currentData.listMessages = [];
  } else {
    // eslint-disable-next-line
    state.currentData.resourceData = {
      ...resourceData,
      offset: resourceData.offset + resourceData.limit || 0,
      ...data,
    };
  }
};

const getMessagesSuccess = (state, { payload: { data } }) => {
  const resourceData = state.currentData?.resourceData || {};
  const listMessages = state.currentData?.listMessages || [];
  state.error = null;
  state.currentData = {
    ...state.currentData,
    listMessages: uniqBy([...listMessages, ...data?.results], 'id'),
    resourceData: {
      ...resourceData,
      total: data?.total || 0,
      numberOfPages: data.numberOfPages,
    },
  };
  state.getMessagesLoading = false;
};

const getMessagesFailure = (state, { payload: { data } = {} }) => {
  state.error = data;
  state.getMessagesLoading = false;
};

const addMessageAfterCreateInfoRequestSuccess = (state, { payload }) => {
  const listMessages = state.currentData?.listMessages || [];

  const total = state.currentData?.resourceData?.total || 0;

  state.currentData.listMessages = [payload, ...listMessages];

  if (state.currentData.resourceData) {
    state.currentData.resourceData.total = total + 1;
  }

  state.currentData.latestMessage = payload;
};

const delMessageAfterDelInfoRequestSuccess = (state, { payload }) => {
  const listMessages = state.currentData?.listMessages || [];

  const total = state.currentData?.resourceData?.total || 1;

  state.currentData.listMessages = remove(
    listMessages,
    (item) => item.id !== payload,
  );

  if (state.currentData.resourceData) {
    state.currentData.resourceData.total = total - 1;
  }
};

const getTotalUnreadEnquiresSuccess = (state, { payload }) => {
  state.totalUnread = Number(payload?.totalUnreadEnquiries) || 0;
};

const archiveEnquirySuccess = (state, { payload }) => {
  state.currentData = { ...state.currentData, ...payload };
  if (state.data[payload?.id]?.id) {
    delete state.data[payload.id];
    state.ids = xor(state.ids, [payload.id]);
  }
};

const getSummariesEnquirySuccess = (state, { payload }) => {
  state.summaries = payload;
};

const slice = makeCRUDSlice(MODEL_NAME, enquiriesActions, {
  [sendMessage.pending]: sendMessagePending,
  [sendMessage.fulfilled]: sendMessageSuccess,
  [sendMessage.rejected]: sendMessageFailure,
  [getMessages.pending]: getMessagesPending,
  [getMessages.fulfilled]: getMessagesSuccess,
  [getMessages.rejected]: getMessagesFailure,
  [readMessage.fulfilled]: readMessageSuccess,
  [readMessage.rejected]: (state, { payload: { data } }) => {
    state.error = data;
  },
  [clearMessages]: (state) => {
    state.currentData = {
      ...state.currentData,
      listMessages: [],
    };
  },
  [addNewMessageAfterCreateInfoRequest]:
    addMessageAfterCreateInfoRequestSuccess,
  [delMessageAfterDelInfoRequest]: delMessageAfterDelInfoRequestSuccess,

  [getTotalUnreadEnquires.fulfilled]: getTotalUnreadEnquiresSuccess,

  [archiveEnquiry.fulfilled]: archiveEnquirySuccess,

  [getSummariesEnquiry.fulfilled]: getSummariesEnquirySuccess,
});

export default slice.reducer;

import React, { useRef } from 'react';
import List from 'containers/rest/List';
import RestFieldItem from 'components/RestField/RestFieldItem';
import i18next from 'i18next';
import { useSelector } from 'react-redux';
import Filter from '../components/Filter';
import CentresNotifyField from '../components/CentresNotifyField';
import CentresNotifyModal from '../components/CentresNotifyModal';
import SwitchOneCentre from '../components/SwitchOneCentre';
import SwitchAllCentre from '../components/SwitchAllCentre';

const NotificationsAndAlertsList = (props) => {
  const breadcrumbCustom = [
    {
      title: i18next.t('sideBar.settings'),
      path: '/config',
    },
    {
      title: i18next.t('notificationsAndAlerts.header'),
    },
  ];

  const centresNotifyModalRef = useRef();

  const centreId = useSelector((state) => state.auth.centreId);

  const openCentresNotifyModal = (record) => {
    centresNotifyModalRef.current.open(record?.id);
  };

  const formatCentresNotify = (centre, record) => (
    <CentresNotifyField
      notifyId={record?.key}
      centre={centre}
      handleClickViewAll={openCentresNotifyModal}
    />
  );

  return (
    <div>
      <List
        {...props}
        resource="notificationsAndAlerts"
        filter={<Filter centreId={centreId} />}
        hasSearch={false}
        hasCreate={false}
        widthTable={800}
        isShowBreadcrumb
        breadcrumbCustom={breadcrumbCustom}
        defaultOptions={{
          customApiResource: centreId
            ? 'centre-app-settings/notifications-alerts/single-centre'
            : 'centre-app-settings/notifications-alerts/multi-centre',
          primaryKey: 'key',
        }}
      >
        <RestFieldItem
          source="description"
          header="notificationsAndAlerts.notifications"
        />

        {centreId && (
          <RestFieldItem
            source="data.status"
            header="common.isActive"
            width="20%"
            format={(status, record) => (
              <SwitchOneCentre isSelectedCentre item={record} />
            )}
          />
        )}

        {!centreId && (
          <RestFieldItem
            source="centreAppSettings[0].centre"
            header="centres.centres"
            format={formatCentresNotify}
          />
        )}

        {!centreId && (
          <RestFieldItem
            source="data.status"
            header="notificationsAndAlerts.enableAllCentres"
            width="20%"
            format={(status, record) => <SwitchAllCentre item={record} />}
          />
        )}
      </List>

      <CentresNotifyModal ref={centresNotifyModalRef} />
    </div>
  );
};

export default NotificationsAndAlertsList;

import React from 'react';
import { TableActionStyled } from 'containers/JobTemplates/styles';
import useFormat from 'containers/JobTemplates/useFormat';
import i18next from 'i18next';
import PropTypes from 'prop-types';

const TableRadio = ({ dataSource, ...props }) => {
  const useFormatFnc = useFormat();

  const columns = [
    {
      title: i18next.t('common.item'),
      dataIndex: 'name',
      key: 'name',
      render: useFormatFnc.formatCategoryName,
    },
    {
      title: useFormatFnc.formatHeaderTitle,
      dataIndex: 'id',
      key: 'id',
      align: 'center',
      width: '45%',
      render: useFormatFnc.formatRadioGroup,
    },
  ];

  return (
    <TableActionStyled
      columns={columns}
      dataSource={dataSource}
      pagination={false}
      isRadioTable
      rowKey="id"
      {...props}
    />
  );
};

TableRadio.propTypes = {
  dataSource: PropTypes.array,
};

export default TableRadio;

import React, { useRef } from 'react';
import CardSection from 'components/common/CardSection';
import { Space } from 'antd';
import { formatNightDayTable } from 'utils/formatFieldUtils';
import useModifyAdsPermission from 'containers/Advertisement/hooks/useModifyAdsPermission';
import { isEmpty } from 'lodash-es';
import moment from 'moment';
import {
  DEFAULT_DEADLINE_DAYS_DURATION,
  DEFAULT_DEADLINE_HOURS_DURATION,
} from 'configs/localData/constant';
import i18next from 'i18next';
import { Duration } from 'api/advertisement/type';
import EmptyData from './EmptyData';
import DurationModal from '../Modals/DurationModal';

interface Props {
  duration: Duration;
}

const DurationInfo = ({ duration }: Props) => {
  const { canModifyAds, canModifyAdStatus } = useModifyAdsPermission();
  const durationRef = useRef<ModalRef>();

  const openEditDurationModal = () => {
    durationRef.current && durationRef.current.toggleModal();
  };

  const handleDurationValue = () => {
    const today = moment().startOf('day');
    const initialDuration = {
      durationDays: DEFAULT_DEADLINE_DAYS_DURATION,
      durationHours: DEFAULT_DEADLINE_HOURS_DURATION,
      startTime: moment(),
      endTime: moment(),
      from: moment(),
      to: moment(today).add(DEFAULT_DEADLINE_DAYS_DURATION, 'days'),
    };
    return duration || initialDuration;
  };

  return (
    <>
      <CardSection
        isEdit
        title="advertisement.duration"
        action="isEdit"
        disabled={!canModifyAds || !canModifyAdStatus}
        onClickButton={openEditDurationModal}
      >
        {isEmpty(duration) ? (
          <EmptyData description="advertisement.durationsEmpty" />
        ) : (
          <div className="text-16">
            <span>{duration?.durationTime}</span>
            <Space size={12}>
              <span className="flex items-center">
                {formatNightDayTable(duration?.startTime)}
              </span>
              <span>{i18next.t('jobListings.labels.to')}</span>
              <span className="flex items-center">
                {formatNightDayTable(duration?.endTime)}
              </span>
            </Space>
          </div>
        )}
      </CardSection>
      <DurationModal ref={durationRef} initialValues={handleDurationValue()} />
    </>
  );
};

export default DurationInfo;

import styled from 'styled-components';

export const TableStyle = styled.div`
  .summary-row,
  .summary-row > .ant-table-cell-fix-right {
    font-size: 14px;
    font-weight: bold;
    background: ${({ theme }) => theme.color.lightBlue} !important;
  }

  .selected-row,
  .selected-row:hover > *,
  .selected-row > .ant-table-cell-fix-right,
  .selected-row > .ant-table-column-sort {
    background: ${({ theme }) => theme.color.lightPink} !important;
  }

  .ant-progress-status-success .ant-progress-text {
    color: black;
    padding-right: 10px;
  }
  .ant-progress-status-success .ant-progress-bg {
    background: ${({ theme }) => theme.color.blueSecondary};
  }
  .ant-progress-line {
    width: 90%;
  }
`;

export const ViewButtonStyles = styled.div`
  .anticon {
    font-size: 18px;
  }

  a {
    margin-left: 8px;
    color: ${({ theme }) => theme.text.mainL2};
    &:hover,
    &:focus {
      color: ${({ theme }) => theme.palette.primary};
    }
    display: inline-block;
  }
`;

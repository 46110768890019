import React, { useRef } from 'react';
import CardSection from 'components/common/CardSection';
import TagCustom from 'components/common/TagCustom';
import useModifyAdsPermission from 'containers/Advertisement/hooks/useModifyAdsPermission';
import { isEmpty } from 'lodash-es';
import EmptyData from './EmptyData';
import IdentityModal from '../Modals/IdentityModal';

const IdentityInfo = ({ identities, isChildcareRelated, isSocialRelated }) => {
  const { canModifyAds, canModifyAdStatus } = useModifyAdsPermission();
  const identityRef = useRef<ModalRef>();

  const openEditIdentity = () => {
    identityRef.current && identityRef.current.toggleModal();
  };
  return (
    <CardSection
      isEdit
      title="advertisement.identity"
      action="isEdit"
      disabled={!canModifyAds || !canModifyAdStatus}
      onClickButton={openEditIdentity}
    >
      {isEmpty(identities) ? (
        <EmptyData description="advertisement.identitiesEmpty" />
      ) : (
        <>
          {identities?.map((item, index) => (
            <TagCustom
              key={index}
              className="fw-bold mr-10"
              color={item?.color}
              backgroundColor={item?.backgroundColor}
              text={item?.value}
              isBorder={false}
            />
          ))}
        </>
      )}
      <IdentityModal
        ref={identityRef}
        identityValues={{ isChildcareRelated, isSocialRelated }}
      />
    </CardSection>
  );
};

export default IdentityInfo;

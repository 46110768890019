import i18next from 'i18next';
import React from 'react';
import PropTypes from 'prop-types';
import { replaceAt } from 'utils/textUtils';
import { SIGN_MASK_EMAIL } from 'configs/localData/constant';
import { GuideContentStyles } from './styles';

const GuideContent = ({ email }) => {
  const maskedEmail =
    replaceAt(email, 1, SIGN_MASK_EMAIL) || i18next.t('auth.yourEmail');
  return (
    <GuideContentStyles className="guide-content">
      {i18next.t('resetPassword.guideContent', { email: maskedEmail })}
    </GuideContentStyles>
  );
};

GuideContent.propTypes = {
  email: PropTypes.string,
};

export default GuideContent;

import { Collapse } from 'antd';
import styled from 'styled-components';

interface Props {
  children?: React.ReactNode;
  isPanelOpen?: boolean;
}

export const CollapseDownloadPopup = styled(Collapse)<Props>`
  position: fixed;
  bottom: 20px;
  right: 40px;
  z-index: 1000;

  min-width: 400px;
  filter: drop-shadow(0px 0px 1px rgba(12, 26, 75, 0.1))
    drop-shadow(0px 30px 40px rgba(20, 37, 63, 0.08));
  border-radius: 8px;
`;

export const CollapsePanel = styled(Collapse.Panel)<Props>`
  border-bottom: none !important;

  .ant-collapse-header {
    border-radius: ${({ isPanelOpen }) =>
      isPanelOpen ? '8px 8px 0 0' : '8px'} !important;
    background: #db147f;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: #ffffff !important;
    transition: border-radius 0.2s;
  }

  .ant-tooltip-inner {
    font-weight: 400;
    font-size: 14px;
  }

  .ant-collapse-content-box {
    padding: 12px !important;
  }
`;

import styled from 'styled-components';

export const ReplyActionStyles = styled.div`
  .fallback-btn {
    position: relative;
    .action-feature-icon {
      position: absolute;
      top: -3px;
      right: -14px;
      font-size: 16px;
      color: ${({ theme }) => theme.subscriptions.colorIcon};
    }
  }
`;

import { values } from 'lodash-es';
import { CRUDSelectors } from 'redux/crudCreator';
import { createSelector } from 'reselect';
import { MODEL_NAME } from './actions';

const getRawData = (state) => state.priceBenchmarking.data;

const getPriceBenchmarkingLoading = (state) => state.priceBenchmarking.loading;

const getResourceDataPriceBenchmarking = (state) =>
  state.priceBenchmarking.resourceData;

const getRawAverageData = (state) => state.priceBenchmarking?.averageAllCentre;

const getSummaryData = (state) => state.priceBenchmarking.summary;

export const getDataPriceBenchmarking = createSelector([getRawData], (data) =>
  values(data),
);

export const getDataPriceBenchmarkingSummary = createSelector(
  getSummaryData,
  (data) => data,
);

export const getAverageDataPriceBenchmarking = createSelector(
  [getRawAverageData],
  (data) => data,
);

export const getLoadingData = createSelector(
  [getPriceBenchmarkingLoading],
  (data) => data,
);

export const getResourceDataSelector = createSelector(
  [getResourceDataPriceBenchmarking],
  (data) => data,
);

export const enabledLoadMoreSelector = createSelector(
  [getPriceBenchmarkingLoading, getResourceDataPriceBenchmarking],
  (loading, resources) => {
    const { offset, limit, numberOfPages } = resources || {};
    return !loading && offset / limit + 1 < numberOfPages;
  },
);

export const priceBenchmarkingSelectors = new CRUDSelectors(MODEL_NAME);

import moment from 'moment';

export const RANGE_PICKER_OPTION_CUSTOM = {
  today: {
    key: 'today',
    label: 'marketingMetrics.today',
    value: [moment(), moment()],
    compareWithValue: [
      moment().subtract(1, 'days'),
      moment().subtract(1, 'days'),
    ],
  },
  yesterday: {
    key: 'yesterday',
    label: 'marketingMetrics.yesterday',
    value: [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
    compareWithValue: [
      moment().subtract(2, 'days'),
      moment().subtract(2, 'days'),
    ],
  },
  last7Days: {
    key: 'last7Days',
    label: 'marketingMetrics.last7Days',
    value: [moment().subtract(7, 'days'), moment().subtract(1, 'days')],
    compareWithValue: [
      moment().subtract(14, 'days'),
      moment().subtract(8, 'days'),
    ],
  },
  last30Days: {
    key: 'last30Days',
    label: 'marketingMetrics.last30Days',
    value: [moment().subtract(30, 'days'), moment().subtract(1, 'days')],
    compareWithValue: [
      moment().subtract(60, 'days'),
      moment().subtract(31, 'days'),
    ],
  },
  last12Months: {
    key: 'last12Months',
    label: 'marketingMetrics.last12Months',
    value: [moment().subtract(12, 'months'), moment().subtract(1, 'days')],
    compareWithValue: [
      moment().subtract(2, 'years'),
      moment().subtract(1, 'years').subtract(1, 'days'),
    ],
  },
  custom: {
    key: 'custom',
    label: 'marketingMetrics.custom',
  },
};

export const DEFAULT_COMPARE_LAST_7_DAYS = [
  moment().subtract(14, 'days'),
  moment().subtract(8, 'days'),
];

export const DEFAULT_LAST_7_DAYS = [
  moment().subtract(7, 'days'),
  moment().subtract(1, 'days'),
];

export const LIMIT_CENTRES = 50;

export const RANGE_FORMAT = ['DD MMM YYYY', 'DD MMM YYYY'];

export const DEFAULT_CHART_LINES_SELECTED = [
  'listViews',
  'enquiries',
  'applications',
];

export const DEFAULT_CURRENT_PAGE = 1;

export const LIMIT_ITEM_EXPORT_LIBRARY = 5;

export const DEFAULT_SORT_EXPORT_LIBRARY = 'exportTime';

export const COMPARE_OPTIONS = {
  precedingPeriod: 'precedingPeriod',
  samePeriodLastYear: 'samePeriodLastYear',
};

export const CHART_MAXIMUM_POINT = 90;

export const IGNORE_SEARCH_URL_PARAMS = [
  'isCompare',
  'dateOption',
  'compareOption',
];

export const IS_COMPARE = {
  true: 'true',
  false: 'false',
};

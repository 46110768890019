import React from 'react';

import PropType from 'prop-types';
import { Avatar, Col, Typography } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import { isEmpty } from 'lodash-es';
import ListFileMessage from 'containers/Enquiries/components/MessageDetail/ListFileMessage';
import { MessageItemStyles } from './styles';
import MessageTime from './MessageTime';

const MessageItem = ({ content, note, isSender, avatar, files, ...item }) => (
  <>
    <MessageItemStyles
      gutter={12}
      className="w-full text-16"
      justify={isSender ? 'end' : 'start'}
      align="bottom"
      issender={isSender}
    >
      {!isSender && (
        <Col>
          <Avatar size={40} src={avatar} icon={<UserOutlined />} />
        </Col>
      )}

      <Col className="message-item__content rounded-24">
        <div className="flex flex-col p-16">
          {note && (
            <Typography.Text className="text-14 fw-bold">
              {note}
            </Typography.Text>
          )}
          <Typography className="whitespace-pre-line">{content}</Typography>
          <div className={`${isSender ? 'text-white' : 'text-black-base'}`}>
            {!isEmpty(files) && <ListFileMessage files={files} />}
          </div>
        </div>
      </Col>
    </MessageItemStyles>
    <div className="flex-center text-14 fw-400">
      <MessageTime datetime={item?.createdAt} />
    </div>
  </>
);

export default MessageItem;

MessageItem.propTypes = {
  note: PropType.string,
  content: PropType.string,
  isSender: PropType.bool,
  avatar: PropType.string,
  files: PropType.array,
};

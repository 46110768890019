import { Col, Row, Space } from 'antd';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getIDCompanyProfileSelector } from 'redux/jobs/companyProfile/selectors';
import { getActiveOnJobModulePermission } from 'redux/auth/selectors';
import { getCompanyVirtualTour } from 'redux/jobs/companyProfile/actions';
import { isEmpty } from 'lodash-es';
import AboutUsCard from './AboutUsCard';
import CompanyPhotos from './CompanyPhotos';
import RecruitmentVideo from './RecruitmentVideo';
import DiscountsCard from './DiscountsCard';
import SocialMediaChannels from './SocialMediaChannels';
import { CompanyRestInfoStyles } from './styles';
import CompanyVirtualTour from './CompanyVirtualTour';

const CompanyRestInfo = () => {
  const companyId = useSelector(getIDCompanyProfileSelector);
  const activeOnJobModulePermission = useSelector(
    getActiveOnJobModulePermission,
  );
  const dispatch = useDispatch();

  useEffect(() => {
    if (activeOnJobModulePermission && !isEmpty(companyId)) {
      dispatch(getCompanyVirtualTour({ companyId }));
    }
  }, [dispatch, activeOnJobModulePermission, companyId]);

  return (
    <CompanyRestInfoStyles>
      <Row gutter={[24, 24]}>
        <Col xl={16} lg={24} md={24} xs={24}>
          <Space size={[24, 24]} direction="vertical" className="wrapper-info">
            <AboutUsCard />
            <DiscountsCard />
            <SocialMediaChannels />
          </Space>
        </Col>
        <Col xl={8} lg={24} md={24} xs={24}>
          <Space size={[24, 24]} direction="vertical" className="wrapper-info">
            <CompanyPhotos />
            <RecruitmentVideo />
            <CompanyVirtualTour />
          </Space>
        </Col>
      </Row>
    </CompanyRestInfoStyles>
  );
};

export default CompanyRestInfo;

import styled from 'styled-components';

export const RegisterWrapper = styled.div`
  --font-size: 16px;

  .ant-form-item-label label {
    font-weight: 700;
    font-size: var(--font-size);
    line-height: 1.5;
    color: #141533;
  }

  @media (max-width: 768px) {
    input,
    textarea {
      font-size: 16px;
    }
  }

  .ant-checkbox-wrapper {
    font-size: 16px;
    line-height: 1.5;
    color: #141533;

    @media only screen and (min-width: 2000px) {
      font-size: 20px;
    }

    @media only screen and (min-width: 2400px) {
      font-size: 25px;
    }

    @media only screen and (min-width: 3000px) {
      font-size: 30px;
    }

    @media only screen and (min-width: 3400px) {
      font-size: calc(16px + 0.390625vw);
      line-height: 1.7;
    }
  }

  @media (min-width: 2000px) {
    --font-size: 20px;

    input,
    textarea,
    .ant-select-selection-placeholder,
    .ant-select-item,
    .ant-select-selection-item,
    .ant-form-item-explain-error,
    .ant-form-item-required::after {
      font-size: var(--font-size) !important;
    }
  }

  @media (min-width: 2600px) {
    --font-size: 28px;
    .ant-select-selector {
      height: 62px !important;
    }

    .ant-select-selection-item {
      line-height: 55px !important;
    }

    .ant-select-item {
      padding: 18px 24px !important;
    }

    .ant-checkbox-wrapper {
      margin-top: 24px;
      margin-bottom: 24px;
    }
    .ant-radio-inner {
      width: 40px;
      height: 40px;

      &::after {
        width: 20px;
        height: 20px;
      }
    }

    .ant-checkbox-inner {
      width: 40px;
      height: 40px;

      &::after {
        width: 12px;
        height: 20px;
      }
    }
    .ant-radio,
    .ant-checkbox {
      font-size: 40px;
    }

    .ant-checkbox {
      margin-right: 18px;
    }

    .ant-row.ant-form-item {
      margin-bottom: 50px;
    }

    .ant-form-item-label {
      padding-bottom: 16px;
    }
    .ant-input-suffix {
      svg {
        font-size: 22px;
      }
    }
  }

  @media only screen and (min-width: 3400px) {
    --font-size: calc(16px + 0.390625vw);

    .ant-input-suffix {
      svg {
        font-size: 30px;
      }
    }

    input,
    textarea,
    .ant-select-selection-placeholder,
    .ant-select-item,
    .ant-select-selection-item,
    .ant-form-item-explain-error {
      padding-left: 28px;
    }

    .ant-select-selector {
      padding-left: 25px !important;
    }

    .ant-select-single .ant-select-selector .ant-select-selection-placeholder {
      line-height: 51px;
    }
  }

  @media (max-width: 786px) {
    position: relative;
    .title {
      font-size: 18px;
    }
  }
`;

export const Description = styled.div`
  --font-size: 16px;
  font-size: var(--font-size);
  margin-bottom: 25px;
  div {
    padding: 8px 0;
  }

  @media only screen and (min-width: 2000px) {
    --font-size: 20px;
  }

  @media only screen and (min-width: 2400px) {
    --font-size: 25px;
    margin-bottom: 35px;
  }

  @media only screen and (min-width: 3000px) {
    --font-size: 30px;
  }

  @media only screen and (min-width: 3400px) {
    margin-bottom: 50px;
    --font-size: calc(22px + 0.390625vw);
  }
`;

export const LoginNowStyle = styled.div`
  @media (max-width: 786px) {
    flex-direction: column;
    span {
      margin: 0;
    }
  }
`;

export const FooterRegisterStyle = styled.div`
  border-top: 1px solid #d8e0e5;
`;

export const FirstNameLastNameWrapper = styled.div`
  @media (max-width: 640px) {
    .ant-form-item {
      flex-direction: row;
    }
  }
`;

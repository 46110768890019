import styled from 'styled-components';

export const SideBarStyles = styled.div`
  .ant-menu-title-content {
    margin-left: 10px;
    max-width: 180px;
  }

  .ant-menu-submenu-selected
    > .ant-menu-submenu-title
    > .ant-menu-item-icon
    > svg {
    color: ${({ theme }) => theme.palette.primary};
  }

  .ant-menu-submenu-selected
    > .ant-menu-submenu-title
    > .ant-menu-title-content,
  .ant-menu-submenu-arrow {
    color: ${({ theme }) => theme.palette.primary};
  }

  .ant-menu-sub.ant-menu-inline {
    background: transparent;
  }

  .ant-menu-submenu > .ant-menu-sub {
    width: calc(100% + 1px);
    padding: 0 17px 0 22px !important;
    position: relative;
  }

  .ant-menu-submenu > .ant-menu-sub::before {
    position: absolute;
    width: 1px;
    background-color: #ebecf0;
    height: 100%;
    left: 16px;
    top: -12px;
    margin-top: 3px;
    display: inline-block;
  }

  .ant-menu-submenu > .ant-menu-sub .ant-menu-item {
    padding-left: 3px !important;
  }

  .ant-menu-title-content .ant-tag {
    position: absolute;
    right: 32px;
    top: 2px;
    font-size: 10px;
    line-height: 16px;
  }

  .ant-menu-submenu-open {
    border-radius: 8px;
    background: #f7f8f9;
  }

  .ant-menu-submenu-vertical {
    background: unset;
  }

  .ant-menu-inline > .ant-menu-item > .ant-menu-title-content > span {
    padding-left: 8px;
  }

  .ant-menu-submenu-title .ant-menu-title-content {
    padding-left: 10px;
  }

  .ant-menu-item {
    padding-left: 48px;
  }

  .sidebar {
    z-index: 99;
    height: 100vh;
    position: fixed;
    background: ${({ theme }) => theme.background.content};
    border-right: 1px solid ${({ theme }) => theme.background.content};
    transition: unset;

    .collapse-sidebar-button {
      position: absolute;
      right: -21px;
      top: 13px;
      transition: 300ms ease all;

      &:hover,
      &:focus {
        background: unset;
      }

      .anticon {
        font-size: 20px;
      }
    }

    .collapse-sidebar-reverse {
      transform: rotate(180deg);
    }

    .ant-layout-sider-children {
      overflow-y: auto;
      overflow-x: hidden;

      &::-webkit-scrollbar {
        border-radius: 10px;
        width: 4px;
      }

      ::-webkit-scrollbar-track {
        background: #fff !important;
      }

      ::-webkit-scrollbar-thumb {
        background: #fff !important;
      }

      &:hover {
        ::-webkit-scrollbar-thumb {
          background: ${({ theme }) => theme.scrollbar.thumb} !important;
        }
      }
    }

    .ant-menu {
      border-right: 1px solid ${({ theme }) => theme.background.content};
      padding: 0 10px;

      .ant-menu-submenu {
        margin-top: 8px;
      }
    }

    .ant-menu-item,
    .ant-menu-submenu-title {
      color: #38465c;
      margin-top: 0;
      font-weight: ${({ theme }) => theme.fontWeight.semibold};
      display: flex;
      align-items: center;
      height: 45px;
      border-radius: ${({ theme }) => theme.borderRadius.default};
      padding: 0 10px !important;

      .anticon {
        color: #38465c;
      }

      & .menu-label {
        margin-top: 0;
        padding-left: 10px;
      }

      &.ant-menu-item-selected {
        background: ${({ theme }) => theme.palette.primary};
        color: ${({ theme }) => theme.background.content};

        & > span {
          color: ${({ theme }) => theme.background.content};
          font-weight: ${({ theme }) => theme.fontWeight.bold};
        }

        .anticon {
          color: ${({ theme }) => theme.background.content};
        }
      }

      .anticon {
        font-size: 16px;
      }

      .ant-badge {
        .ant-badge-count {
          top: -3px;
          left: -1px;
          right: auto;
          width: 18px;
          height: 18px;
          line-height: 18px;
          border-radius: 50%;
          padding: 0;
          font-size: 10px;
        }

        &.ant-menu-item-icon {
          margin-right: 0;
        }

        .feature-icon {
          opacity: 1;
        }

        .anticon-crown {
          top: -4px;
          right: -2px;
          color: #f98437;
        }
      }
    }

    .ant-menu-submenu-title .ant-badge .ant-badge-count {
      top: 0;
      left: -2px;
    }

    .ant-menu.ant-menu-inline-collapsed .ant-menu-submenu .anticon {
      font-size: 20px;
    }

    .ant-menu-submenu-selected
      > .ant-menu-submenu-title
      > .ant-menu-item-icon
      > svg {
      color: ${({ theme }) => theme.palette.primary};
    }

    .ant-menu-submenu-selected .ant-menu-submenu-title .ant-menu-submenu-arrow,
    .ant-menu-submenu-selected .ant-menu-submenu-title .ant-menu-title-content {
      color: ${({ theme }) => theme.palette.primary};
    }

    .ant-menu-submenu .ant-menu-submenu-expand-icon,
    .ant-menu-submenu .ant-menu-submenu-arrow {
      color: rgb(164, 164, 164);
    }

    .logo {
      display: flex;
      position: relative;
      justify-content: flex-start;
      padding: 7px 4px;

      img {
        height: 100%;
        width: auto;
        object-fit: contain;
      }

      .fullLogo {
        position: absolute;
        opacity: 0;
        transition: all 0.2s;
      }
    }
  }

  .ant-layout-sider-collapsed {
    .ant-menu-item {
      justify-content: center;

      .ant-badge {
        .ant-badge-count {
          top: 8px;
          left: 0;
        }

        .feature-icon {
          top: 9px;
          left: -16px;
        }
      }

      .ant-menu-title-content {
        margin-left: 8px !important;
      }
    }

    .ant-menu-submenu {
      .ant-badge {
        .ant-badge-count {
          top: 7px;
          left: 3px;
        }
      }
    }

    .logo {
      padding: 7px 14px;
    }
  }

  .ant-menu-inline
    .ant-menu-submenu
    .ant-menu-sub
    .ant-menu-item
    .ant-menu-title-content
    .ant-badge {
    position: absolute;
    right: 40px;
    top: 18px;
  }
`;

export const BadgeStyles = styled.div`
  .ant-badge .ant-badge-count {
    width: 18px;
    height: 18px;
    line-height: 18px;
    border-radius: 50%;
    padding: 0;
    font-size: 10px;
  }
`;

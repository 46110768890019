import { Button, Col, Image, Row, Tag, Tooltip, Typography } from 'antd';
import {
  DollarSquareIcon,
  FormatCircleIcon,
  LanguageIcon,
  PersonalCardIcon,
  TeacherIcon,
} from 'components/common/SVGIcon';
import JobTemplateDetailItem from 'containers/JobTemplates/components/JobTemplateInfo/JobTemplateDetailItem';
import i18next from 'i18next';
import { isEmpty } from 'lodash-es';
import React from 'react';
import PropTypes from 'prop-types';
import useFormatFnc from 'containers/JobTemplates/components/JobTemplateList/useFormat';
import { useHistory } from 'react-router';
import useRolesJobTemplates from 'containers/Users/hooks/useRoleJobTemplates';

const SnapShootJobTemplate = ({ currentJobTemplate, modelDetail }) => {
  const useFormat = useFormatFnc();
  const { push } = useHistory();
  const disabledAction = useRolesJobTemplates();

  const onEditJobTemplate = () => {
    push(
      `/jobTemplates/${
        currentJobTemplate?.templateId || currentJobTemplate?.id
      }/show/generalInformation?${
        modelDetail ? 'isJobListingDetail=true' : 'isCreateJobListing=true'
      }`,
    );
  };

  return (
    <div>
      <Row justify="space-between">
        <Col md={18} sm={16}>
          {currentJobTemplate?.jobTitle && (
            <Typography.Text className="fw-bold text-20 mr-10">
              {currentJobTemplate?.jobTitle}
            </Typography.Text>
          )}
        </Col>
        <Col>
          <Button
            className="btn-line-primary fw-bold"
            onClick={onEditJobTemplate}
            disabled={disabledAction}
          >
            {i18next.t('jobListings.buttons.editTemplate')}
          </Button>
        </Col>
      </Row>

      {currentJobTemplate?.jobType && (
        <Tooltip title={i18next.t('jobs.jobTemplates.jobType')}>
          <div className="mt-12 mb-12">
            {useFormat.formatJobType(currentJobTemplate?.jobType)}
          </div>
        </Tooltip>
      )}

      {currentJobTemplate?.salary?.displayText && (
        <JobTemplateDetailItem
          title="jobs.jobTemplates.salary"
          icon={<DollarSquareIcon />}
          value={currentJobTemplate?.salary?.displayText}
          className="text-16 text-dark-green fw-bold"
        />
      )}

      {currentJobTemplate?.discount?.value ? (
        <JobTemplateDetailItem
          title="jobs.jobTemplates.discount"
          icon={
            <Image
              className="flex-center"
              preview={false}
              src={currentJobTemplate?.discount?.type?.iconUrl}
              width={18}
              alt=""
            />
          }
          value={`${currentJobTemplate?.discount?.value}% ${currentJobTemplate?.discount?.type?.name}`}
          className="text-16"
        />
      ) : (
        ''
      )}

      {currentJobTemplate?.qualification?.name && (
        <JobTemplateDetailItem
          title="jobs.basicInformation.qualification"
          icon={<TeacherIcon />}
          value={currentJobTemplate?.qualification?.name}
          className="text-16"
        />
      )}

      {currentJobTemplate?.experience?.name && (
        <JobTemplateDetailItem
          title="jobs.basicInformation.experience"
          icon={<FormatCircleIcon />}
          value={currentJobTemplate?.experience?.name}
          className="text-16"
        />
      )}

      {!isEmpty(currentJobTemplate?.languages) && (
        <JobTemplateDetailItem
          title="jobs.basicInformation.languages"
          icon={<LanguageIcon />}
          value={currentJobTemplate?.languages?.map((item, index) => (
            <Tag key={String(index)} className="fw-600 text-14">
              {item?.name}
            </Tag>
          ))}
          className="text-16"
        />
      )}

      {currentJobTemplate?.eligibility?.displayText && (
        <JobTemplateDetailItem
          title="jobs.basicInformation.workEligibility"
          icon={<PersonalCardIcon />}
          value={currentJobTemplate?.eligibility?.displayText}
          className="text-16"
        />
      )}
    </div>
  );
};

SnapShootJobTemplate.propTypes = {
  currentJobTemplate: PropTypes.object,
  modelDetail: PropTypes.string,
};

export default SnapShootJobTemplate;

import React, { useState } from 'react';
import PropTypes from 'prop-types';

import GridPhotosCustom from 'components/common/GridPhotosCustom';
import CardSection from 'components/common/CardSection';
import { isEmpty } from 'lodash-es';
import i18next from 'i18next';
import NoImageData from 'assets/images/no-data.png';
import CentrePhotoModal from '../../Modals/CentrePhotoModal';

const CentrePhoto = ({ images, picsShowing }) => {
  const [visibleModal, setVisibleModal] = useState(false);

  const onClickButton = () => {
    setVisibleModal(true);
  };

  const onCancel = () => {
    setVisibleModal(false);
  };

  return (
    <>
      <CardSection
        isEdit
        onClickButton={onClickButton}
        title="centreDetail.centrePhotos"
        permissionKey="myCentres"
        action="isEdit"
      >
        <div>
          {isEmpty(images) ? (
            <div className="flex-col flex-center">
              <img src={NoImageData} alt="no-data" />
              <div className="text-center">
                {i18next.t('centreDetail.emptyPhotosAndVideos')}
              </div>
            </div>
          ) : (
            <div className="centre-photo">
              <GridPhotosCustom images={images} picsShowing={picsShowing} />
            </div>
          )}
        </div>
      </CardSection>
      <CentrePhotoModal
        record={{ images }}
        onCancel={onCancel}
        visible={visibleModal}
      />
    </>
  );
};

CentrePhoto.propTypes = {
  images: PropTypes.array,
  picsShowing: PropTypes.number,
};

export default CentrePhoto;

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { Row, Col } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { getKindiCareRatingSummarySelector } from 'redux/centres/selectors';
import { getKindiCareRatingSummary } from 'redux/centres/actions';
import ReviewsSection from './ReviewsSection';
import KindiCareRating from './KindiCareRating';
import NQSRating from './NQSRating';
import { ReviewRatingStyles } from './styles';

const RatingsReviewsTab = ({ record }) => {
  const dispatch = useDispatch();
  const id = record?.id;
  const ratingData = useSelector(getKindiCareRatingSummarySelector);
  useEffect(() => {
    dispatch(getKindiCareRatingSummary({ id }));
  }, [id, dispatch]);
  return (
    <ReviewRatingStyles>
      <Row gutter={[24, 24]}>
        <Col xxl={16} xl={14} lg={24} md={24} xs={24}>
          <ReviewsSection />
        </Col>
        <Col xxl={8} xl={10} lg={24} md={24} xs={24}>
          <Row gutter={[24, 24]} className="row-right-rating">
            <Col span={24}>
              <KindiCareRating
                kindiRatingSummary={ratingData}
                kindiCareRating={Number(ratingData?.kindicareRating)}
              />
            </Col>
            <Col span={24}>
              <NQSRating
                NQSRType={record.NQSRType}
                centreNQSRating={record.centreNQSRating}
                currentOverallNQSRatingDate={record.currentOverallNQSRatingDate}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </ReviewRatingStyles>
  );
};

RatingsReviewsTab.propTypes = {
  record: PropTypes.object,
};

RatingsReviewsTab.defaultProps = {
  record: {},
};

export default RatingsReviewsTab;

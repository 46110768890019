import React from 'react';
import { Button, Col, Modal, Row } from 'antd';
import {
  DeleteWithoutCircleIcon,
  EditFilledIcon,
  WarningIcon,
} from 'components/common/SVGIcon';
import i18next from 'i18next';
import styled from 'styled-components';

interface Props {
  Icon: React.ReactNode;
  onEdit: () => void;
  onDelete: () => void;
  deleteConfirmTitle: string;
  deleteConfirmContent: string;
  children?: React.ReactNode;
  isWarningDelete?: boolean;
  disabled?: boolean;
}

const ContentCardWrapper = styled.div`
  .ant-btn-icon-only {
    svg {
      height: 24px;
    }
  }
`;
const ContentCard = ({
  Icon,
  onEdit,
  children,
  onDelete,
  deleteConfirmTitle,
  deleteConfirmContent,
  isWarningDelete = true,
  disabled = false,
}: Props) => {
  const handleDelete = () => {
    if (isWarningDelete) {
      Modal.confirm({
        title: i18next.t(deleteConfirmTitle),
        content: i18next.t(deleteConfirmContent),
        okText: i18next.t('button.delete'),
        onOk: onDelete,
        icon: <WarningIcon className="text-24" />,
      });
    } else onDelete();
  };

  return (
    <ContentCardWrapper>
      <Row gutter={20} justify="space-between">
        <Col xs={16} sm={18} md={20}>
          <div className="flex">
            <div className="mr-12 text-blue-300">{Icon}</div>
            {children}
          </div>
        </Col>
        <Col className=" mt-10 flex items-center d-block">
          <Button
            type="link"
            onClick={onEdit}
            className="text-gray-500 mr-24"
            icon={<EditFilledIcon className="text-20 text-gray-500" />}
            disabled={disabled}
          />

          <Button
            type="link"
            onClick={handleDelete}
            className="text-gray-500"
            icon={<DeleteWithoutCircleIcon className="text-24 text-gray-500" />}
            disabled={disabled}
          />
        </Col>
      </Row>
    </ContentCardWrapper>
  );
};

export default ContentCard;

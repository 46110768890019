import React from 'react';
import PropTypes from 'prop-types';
import i18next from 'i18next';
import { Link } from 'react-router-dom';
import { Tooltip, Card } from 'antd';
import CardConfigStyles from './styles';

const { Meta } = Card;

const CardConfig = ({ item }) => (
  <CardConfigStyles>
    <Tooltip title={i18next.t(item.description)} placement="right">
      <Link to={item.link}>
        <Card
          hoverable
          cover={<img alt="kindicare" src={item.cover} />}
          bordered={false}
        >
          <Meta
            title={i18next.t(item.title)}
            description={i18next.t(item.description)}
          />
        </Card>
      </Link>
    </Tooltip>
  </CardConfigStyles>
);

CardConfig.propTypes = {
  item: PropTypes.object,
};

export default CardConfig;

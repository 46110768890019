import { Form, Modal } from 'antd';
import { CloseSquareOutlineIcon, WarningIcon } from 'components/common/SVGIcon';
import FormCheckbox from 'components/form/FormCheckbox';
import { CustomLabelStyle } from 'components/form/Label/styles';
import RestEditor from 'components/RestInput/RestEditor';
import { RestInputContext } from 'components/RestInput/RestInputContext';
import RestInputItem from 'components/RestInput/RestInputItem';
import RestSelect from 'components/RestInput/RestSelect';
import RestUploadFile from 'components/RestInput/RestUploadFile';
import { PROGRAM_NAME_LIMIT } from 'containers/ProgramsAndLearning/constants';
import {
  CreateProgramModalStyle,
  CreateProgramStyle,
} from 'containers/ProgramsAndLearning/styles';
import {
  IProgramResponse,
  Program,
  UpdateProgramDto,
  useProgramsControllerUpdateOneProgramMutation,
} from 'generated/apis';
import i18next from 'i18next';
import { isEqual, map, pick } from 'lodash-es';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import renderHTML from 'react-render-html';
import { useCreateProgramsMutation } from 'redux/@rtkQuery/programsLearning';
import {
  getServiceAgeGroups,
  getServiceTypes,
  getSessionTypes,
} from 'redux/config/selectors';

interface Props {
  visible: boolean;
  onClose?: () => void;
  inEditMode?: boolean;
  initialValues?: Program;
}

interface SubmitError {
  status: number;
}

interface SubmitState {
  data: IProgramResponse;
  error: SubmitError;
}

const CreateProgramModal = ({
  visible,
  onClose,
  inEditMode,
  initialValues,
}: Props) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [create, { isLoading: createLoading }] = useCreateProgramsMutation();
  const [edit, { isLoading: editLoading }] =
    useProgramsControllerUpdateOneProgramMutation();
  const serviceTypes = useSelector(getServiceTypes);
  const sessionTypes = useSelector(getSessionTypes);
  const serviceAgeGroups = useSelector(getServiceAgeGroups);

  const modalTitle = inEditMode
    ? 'programLearning.editProgram'
    : 'programLearning.createProgram';
  const submitBtnText = inEditMode
    ? 'programLearning.confirm.edit'
    : 'programLearning.confirm.create';

  const ageGroupIds = map(initialValues?.ageGroup, 'id');
  const sessionTypeIds = map(initialValues?.sessionType, 'id');

  const serviceAgeGroupsActive = serviceAgeGroups.filter(
    (item) => item?.isActive === true,
  );

  const handleEditProgram = async (payload: UpdateProgramDto) => {
    const editState = (await edit({
      programId: initialValues?.id,
      updateProgramDto: payload,
    })) as SubmitState;

    if (editState?.error?.status !== 400) {
      onClose();
    }
  };

  const handleConfirmUpdate = async (payload: UpdateProgramDto) => {
    const comparingInitialValue = {
      ...pick(initialValues, ['serviceTypeId']),
      ageGroupIds: ageGroupIds ?? [],
      sessionTypeIds: sessionTypeIds ?? [],
    };
    const comparingPayload = pick(payload, [
      'serviceTypeId',
      'ageGroupIds',
      'sessionTypeIds',
    ]);
    const hasChanged = !isEqual(comparingInitialValue, comparingPayload);

    if (hasChanged) {
      Modal.confirm({
        title: t('programLearning.updateProgram'),
        content: renderHTML(t('programLearning.confirm.modifiedFields')),
        onOk: async () => {
          await handleEditProgram(payload);
        },
        width: 646,
        okText: t('programLearning.updateProgram'),
        icon: <WarningIcon />,
        wrapClassName: 'confirm-update-program',
        okButtonProps: {
          loading: editLoading,
        },
      });
    } else {
      await handleEditProgram(payload);
    }
  };

  const handleSubmit = async () => {
    await form.validateFields().then(async (res) => {
      const payload: UpdateProgramDto = {
        ...res,
        image: res.image[0].url,
      };

      try {
        if (inEditMode) {
          await handleConfirmUpdate(payload);
        } else {
          await create(payload).unwrap();
          form.resetFields();
          onClose();
        }
      } catch (e) {
        if (e.status === 400) {
          form.setFields([
            {
              name: 'name',
              errors: [e.data.message],
            },
          ]);
        }
      }
    });
  };

  useEffect(() => {
    const currentAgeGroupIds = form.getFieldValue('ageGroupIds');
    const currentSessionTypeIds = form.getFieldValue('sessionTypeIds');

    if (initialValues && (!currentAgeGroupIds || !currentSessionTypeIds)) {
      form.setFieldsValue({
        ...initialValues,
        ageGroupIds: ageGroupIds ?? [],
        sessionTypeIds: sessionTypeIds ?? [],
      });
    }
  }, [ageGroupIds, form, initialValues, sessionTypeIds]);

  const initialImages = initialValues?.image
    ? [{ url: initialValues?.image, type: 'image/png' }]
    : [];

  return (
    <CreateProgramModalStyle
      visible={visible}
      title={i18next.t(modalTitle)}
      onCancel={() => {
        form.resetFields();
        onClose();
      }}
      onOk={handleSubmit}
      loading={createLoading || editLoading}
      width={850}
      isScrollY
      closeIcon={<CloseSquareOutlineIcon />}
      okText={i18next.t(submitBtnText)}
    >
      <CreateProgramStyle>
        <Form
          layout="vertical"
          form={form}
          initialValues={initialValues}
          onFinish={handleSubmit}
          scrollToFirstError
        >
          <div className="relative">
            <Form.Item shouldUpdate className="absolute right-0">
              {({ getFieldValue }) => (
                <div
                  className={`${
                    getFieldValue('name')?.length > PROGRAM_NAME_LIMIT
                      ? 'ant-form-item-explain-error'
                      : 'text-gray-neutral-500'
                  } text-12`}
                >
                  {`${
                    getFieldValue('name') ? getFieldValue('name').length : 0
                  }/${PROGRAM_NAME_LIMIT}`}
                </div>
              )}
            </Form.Item>
            <RestInputItem
              htmlFor={i18next.t('programLearning.title.programName')}
              label="programLearning.title.programName"
              required
              source="name"
              placeholder="programLearning.placeholder.programName"
              rules={[
                {
                  max: PROGRAM_NAME_LIMIT,
                  message: i18next.t('error.maxLength', {
                    max: PROGRAM_NAME_LIMIT,
                  }),
                },
              ]}
            />
          </div>
          <RestSelect
            isShowSearch
            isFilterOption
            source="serviceTypeId"
            resourceData={serviceTypes || []}
            valueProp="id"
            titleProp="name"
            header="programLearning.title.serviceType"
            placeholder="programLearning.placeholder.serviceType"
            required
          />
          <FormCheckbox
            label="programLearning.title.ageGroup"
            required
            resourceData={serviceAgeGroupsActive || []}
            valueProp="id"
            titleProp="name"
            source="ageGroupIds"
            checkboxGroupProps={{
              className: 'flex flex-col gap-10',
            }}
          />
          <FormCheckbox
            label={i18next.t('programLearning.title.sessionType')}
            required
            resourceData={sessionTypes || []}
            valueProp="id"
            titleProp="name"
            source="sessionTypeIds"
            checkboxGroupProps={{
              className: 'flex flex-col gap-10',
            }}
          />
          <RestInputContext.Provider
            value={{
              form,
            }}
          >
            <CustomLabelStyle className={'text-16 fw-bold'}>
              {i18next.t('text.uploadImage')}
            </CustomLabelStyle>
            <RestUploadFile
              placeholder={i18next.t('programLearning.placeholder.uploadImage')}
              source="image"
              record={{
                image: initialImages,
              }}
              required
              onlyShowImg
              isAttachURLVideo={false}
              accept="image/jpg, image/jpeg, image/png"
              isNoneBorderRadius
              isDisableLabel
              maxCount={1}
              itemScale={9 / 16}
              isShowConfirm
              replaceConfirmMessage={i18next.t(
                'programLearning.confirm.replace',
              )}
            />
            <RestEditor
              source="about"
              header="programLearning.title.aboutTheProgram"
              height={312}
              required
            />
          </RestInputContext.Provider>
        </Form>
      </CreateProgramStyle>
    </CreateProgramModalStyle>
  );
};

export default CreateProgramModal;

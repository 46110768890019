import React from 'react';
import i18next from 'i18next';
import PropTypes from 'prop-types';
import { AwardIcon } from 'components/common/SVGIcon';

const CardHeader = ({ year }) => (
  <div className="card-item__header flex items-center">
    <AwardIcon className="text-24" />
    <span>{i18next.t('resources.award', { year: year || '2021' })}</span>
  </div>
);

CardHeader.propTypes = {
  year: PropTypes.number,
};

export default CardHeader;

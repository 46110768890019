import styled from 'styled-components';

export const CompanyFormStyles = styled.div`
  .ant-form-item-label {
    font-weight: 700;
    color: #9c9d9b;
    font-size: 14px;
  }

  .input-filed-style {
    margin-bottom: 10px !important;
    margin-top: 5px !important;
  }
`;

const FormStyles = styled.div`
  .address-full-form-button {
    padding-left: 5px !important;
    > span {
      text-decoration: underline;
    }
  }

  .centre-form__title-section {
    font-size: 18px;
    font-weight: bold;
  }
`;

export default FormStyles;

export const AddressFullFormStyles = styled.div`
  .address-full-form-button {
    padding-left: 5px !important;
    margin-top: -5px !important;
    > span {
      text-decoration: underline;
    }
  }
`;

import PropTypes from 'prop-types';
import React, { forwardRef, useImperativeHandle, useState } from 'react';
import i18next from 'i18next';
import { useDispatch } from 'react-redux';
import { editCompanyProfile } from 'redux/jobs/companyProfile/actions';
import ModalCustom from 'components/common/ModalCustom';
import { Form } from 'antd';
import { RestInputContext } from 'components/RestInput/RestInputContext';
import { getFullDisplayAddress } from 'utils/dataUtils';
import CompaniesForm from '../../Form';

const CompanyProfileGeneralInfoModal = forwardRef(({ record }, ref) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState();

  useImperativeHandle(
    ref,
    () => ({
      open: () => {
        setVisible(true);
      },
    }),
    [],
  );

  const handleCancel = () => {
    setVisible(false);
    form.resetFields();
  };

  const onOk = () => {
    form.validateFields().then((values) => {
      setLoading(true);
      dispatch(
        editCompanyProfile({
          data: {
            ...values,
            address: values?.address || getFullDisplayAddress(values),
          },
        }),
      )
        .then(() => {
          handleCancel();
        })
        .finally(() => {
          setLoading(false);
        });
    });
  };

  return (
    <ModalCustom
      title={i18next.t(
        'jobs.companyProfile.modal.generalInfo.editCompanyInformation',
      )}
      visible={visible}
      onCancel={handleCancel}
      onOk={onOk}
      loading={loading}
      width={1000}
    >
      <Form form={form} layout="vertical" scrollToFirstError>
        <RestInputContext.Provider value={{ form, record }}>
          <CompaniesForm record={record} />
        </RestInputContext.Provider>
      </Form>
    </ModalCustom>
  );
});

CompanyProfileGeneralInfoModal.propTypes = {
  record: PropTypes.object,
};

export default CompanyProfileGeneralInfoModal;

import { Checkbox } from 'antd';
import React from 'react';
import { getBlurOpacity } from 'utils/tools';
import MetricLegendItem from '../MetricLegendItem';
import { MetricCheckboxGroup } from './styles';
import { LineType } from '..';

interface Props {
  payload: LineType[];
  activeLine?: string;
  selectedLine: string[];
  onSelectedLegendChange: (checkedValues: string[]) => void;
  className?: string;
}

const LegendContent = ({
  payload,
  activeLine,
  selectedLine,
  onSelectedLegendChange,
  className,
}: Props) => (
  <MetricCheckboxGroup
    value={selectedLine}
    onChange={onSelectedLegendChange}
    className={className}
  >
    {payload?.map(({ value, fillColor, label }) => (
      <Checkbox
        value={value}
        key={value}
        style={{ opacity: getBlurOpacity(activeLine, value) }}
      >
        <MetricLegendItem
          color={fillColor}
          name={label}
          tagWidth={36}
          className="text-14"
        />
      </Checkbox>
    ))}
  </MetricCheckboxGroup>
);
export default LegendContent;

import React from 'react';
import EULALayout from 'layout/EULALayout';

import EULAStyles from './styles';
import EULAContent from './EULAContent';

const EULAPage = () => (
  <EULALayout>
    <EULAStyles>
      <EULAContent />
    </EULAStyles>
  </EULALayout>
);

export default EULAPage;

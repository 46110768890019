import { get } from 'lodash-es';
import { getStatusOrder } from './dataUtils';

export const sortString = (item1, item2, key) => {
  const value1 = get(item1, key);

  const value2 = get(item2, key);

  if (!value2) return -1;

  if (!value1) return 1;

  if (value1 < value2) return -1;

  if (value1 > value2) return 1;

  return 0;
};

export const sortNumber = (item1, item2, key) =>
  Number(get(item1, key)) - Number(get(item2, key));

export const sortTime = (a, b, key) => {
  const dateA = new Date(get(a, key));

  const dateB = new Date(get(b, key));

  return dateA - dateB;
};

export const sortBoolean = (item1, item2, key) => {
  const value1 = get(item1, key);

  const value2 = get(item2, key);

  if (value1 === value2) return 0;

  if (value1) return -1;

  return 1;
};

export const sortByStatus = (item1, item2) => {
  const value1 = getStatusOrder(item1?.status);

  const value2 = getStatusOrder(item2?.status);

  return value1 - value2;
};

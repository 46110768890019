import React from 'react';
import PropTypes from 'prop-types';
import TitleTabCount from './TitleTabCount';

const ApplicationsTitleTab = ({ count }) => (
  <TitleTabCount
    title="jobListings.jobListingDetails.applications"
    count={count}
  />
);

ApplicationsTitleTab.propTypes = {
  count: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};
export default ApplicationsTitleTab;

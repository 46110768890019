import React from 'react';
import { Avatar } from 'antd';
import { QuoteIcon } from 'components/common/SVGIcon';
import useWindowSize from 'hooks/useWindowSize';

interface QuotesInfo {
  avatar: string;
  companyLogo: string;
  content: string;
  name: string;
  jobTitle: string;
  companyName: string;
}

const KindiCareQuotes = () => {
  const quotesInfo: QuotesInfo = {
    avatar:
      'https://s3.ap-southeast-2.amazonaws.com/files.kindicare.com/uploads/static/assets/rich-web/b2b/jeromie-hill-avatar.png',
    companyLogo:
      'https://s3.ap-southeast-2.amazonaws.com/files.kindicare.com/uploads/static/assets/rich-web/b2b/little-zak-academy.png',
    content:
      'KindiCare is an excellent product that truly puts the customer first.',
    name: 'Jeromie Hill',
    jobTitle: 'Head of Marketing',
    companyName: `Little Zak's Academy`,
  };

  const { width } = useWindowSize();
  return (
    <div className="flex flex-row gap-12 justify-end relative pt-20">
      <div className="relative quote-avatar ">
        <Avatar src={quotesInfo.avatar} className="quote-user-avatar" />
        <Avatar
          className="absolute right-0 bottom-0 quote-logo"
          src={quotesInfo.companyLogo}
        />
      </div>
      <div className="flex flex-col gap-8 justify-center content">
        <div className="title">{quotesInfo.content}</div>
        <div>
          {width > 576 ? (
            <>
              <div className="flex flex-row items-center fw-700 position">
                <div>{quotesInfo?.name}</div>
                <div className="bg-blue-300 w-2 h-2 rounded-full ml-12" />
                <div className="ml-10">{quotesInfo?.jobTitle}</div>
                <div className="bg-blue-300 w-2 h-2 rounded-full ml-12" />
                <div className="ml-10">{quotesInfo?.companyName}</div>
              </div>
            </>
          ) : (
            <div className="fw-bold position">
              <div className="flex flex-row items-center fw-700">
                <div>{quotesInfo?.name}</div>
                <div className="bg-blue-300 w-2 h-2 rounded-full ml-12" />
                <div className="ml-10">{quotesInfo?.jobTitle}</div>
              </div>
              <div>{quotesInfo?.companyName}</div>
            </div>
          )}
        </div>
      </div>

      <QuoteIcon
        className={`quote-icon text-pastel-pink ${
          width > 576 ? ' top-0 ' : ' top-76 '
        }`}
      />
    </div>
  );
};

export default KindiCareQuotes;

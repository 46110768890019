import React from 'react';
import { Card, Space, Table, Tooltip } from 'antd';
import PropTypes from 'prop-types';
import { DollarCircleOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import i18next from 'i18next';
import { orderBy } from 'lodash-es';
import { generateCentreDetailLink } from 'utils/dataUtils';
import { getAverageDataPriceBenchmarking } from 'redux/priceBenchmarking/selectors';
import {
  LIMIT_COST_LENGTH,
  LIMIT_SERVICE_LENGTH,
  LIMIT_VALUE_FOR_MONEY_LENGTH,
} from 'configs/localData/constant';
import { formatMoney } from 'utils/tools';
import { CentreCardInfoStyles } from './style';
import CardItem from './CardItemWrapper';
import BannerCentreItem from './BannerCentreItem';
import CentreAddressDistance from './CentreAddressDistance';
import PricesLastUpdated from './PricesLastUpdated';
import LinkExternal from './Link';

const { Meta } = Card;

const calculateAvgPriceAllAgeGroup = (summaryValue, summaryTotalValue) => {
  const difference = summaryValue - summaryTotalValue;
  const percentage = `${Math.abs(
    (difference / summaryTotalValue) * 100,
  ).toFixed(2)}% `;
  return {
    result: percentage,
    status: difference <= 0 ? 'lower' : 'higher',
  };
};

const CentreCardInfo = ({
  item = {},
  fullWidth = false,
  logoSize,
  isExistOnSlide = true,
}) => {
  const dataSource = [];
  const { averageAllAgeGroup } = useSelector(getAverageDataPriceBenchmarking);
  const services = orderBy(item?.services, ['serviceAgeGroup.order'], ['asc']);
  services.forEach((service, index) => {
    dataSource.push({
      key: index,
      costPerDay: service?.costPerDay,
      ageGroupName: service?.serviceAgeGroup?.name,
      centreValueForMoney: service?.centreValueForMoney,
    });
  });

  dataSource.push({
    key: 'averageAllAgeGroup',
    costPerDay: item?.averageAllAgeGroup,
    ageGroupName: i18next.t('priceBenchmarking.averageAllAgeGroups'),
    centreValueForMoney: i18next.t('priceBenchmarking.averageAllAgeGroups'),
  });

  const differenceValue = calculateAvgPriceAllAgeGroup(
    item?.averageAllAgeGroup,
    averageAllAgeGroup,
  );

  const columns = [
    {
      key: 'costPerDay',
      dataIndex: 'costPerDay',
      render: (costPerDayValue) => {
        const decimalCostPerDayValue = formatMoney(costPerDayValue, 3, true);
        return decimalCostPerDayValue.length > LIMIT_COST_LENGTH ? (
          <Tooltip placement="top" title={`$${decimalCostPerDayValue}`}>
            <span className="cost">{`$${decimalCostPerDayValue}`}</span>
          </Tooltip>
        ) : (
          <span className="cost">{`$${decimalCostPerDayValue}`}</span>
        );
      },
    },
    {
      key: 'ageGroupName',
      dataIndex: 'ageGroupName',
      render: (ageGroupName) =>
        ageGroupName.length > LIMIT_SERVICE_LENGTH ? (
          <Tooltip placement="top" title={ageGroupName}>
            <span>{ageGroupName}</span>
          </Tooltip>
        ) : (
          <span>{ageGroupName}</span>
        ),
    },
    {
      key: 'centreValueForMoney',
      dataIndex: 'centreValueForMoney',
      render: (valueForMoney) => {
        if (
          valueForMoney === i18next.t('priceBenchmarking.averageAllAgeGroups')
        ) {
          return '';
        }
        return valueForMoney?.length > LIMIT_VALUE_FOR_MONEY_LENGTH ? (
          <Tooltip placement="top" title={valueForMoney}>
            <span className="width-short-value">
              <DollarCircleOutlined className="dollar-sign" />
              {valueForMoney ?? '-'}
            </span>
          </Tooltip>
        ) : (
          <span className="width-short-value">
            <DollarCircleOutlined className="dollar-sign" />
            {valueForMoney ?? '-'}
          </span>
        );
      },
    },
  ];
  return (
    <CardItem fullWidth={fullWidth} isExistOnSlide={isExistOnSlide}>
      <CentreCardInfoStyles>
        <Card
          differencevaluestatus={differenceValue.status}
          actions={[
            ...(differenceValue.status === 'higher'
              ? [
                  <Space className="average-value-higher">
                    {`${differenceValue.result} higher`}
                  </Space>,
                ]
              : [
                  <Space className="average-value-lower">
                    {`${differenceValue.result} lower`}
                  </Space>,
                ]),
          ]}
          hoverable
          cover={
            <BannerCentreItem
              fullWidth={fullWidth}
              item={item}
              showLogo
              logoSize={logoSize}
            />
          }
        >
          {item?.isOwnedCentre ? (
            <Link to={`/centres/${item?.id}/show/summary`}>
              <Meta className="centre-card-title" title={item?.name} />
            </Link>
          ) : (
            <LinkExternal
              externalUrl={generateCentreDetailLink(item)}
              target="_blank"
            >
              <Meta className="centre-card-title" title={item?.name} />
            </LinkExternal>
          )}

          <div className="centre-distance">
            <CentreAddressDistance item={item} isEllipsis />
          </div>
          <Table
            dataSource={dataSource}
            columns={columns}
            pagination={false}
            bordered={false}
            showHeader={false}
          />
          <Space className="last-updated">
            <PricesLastUpdated date={item?.pricesLastUpdated} />
          </Space>
        </Card>
      </CentreCardInfoStyles>
    </CardItem>
  );
};

CentreCardInfo.propTypes = {
  item: PropTypes.object,
  fullWidth: PropTypes.bool,
  logoSize: PropTypes.number,
  isExistOnSlide: PropTypes.bool,
};

export default CentreCardInfo;

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Pagination, DatePicker } from 'antd';
import I18n from 'i18next';
import { zipObjectDeep } from 'lodash-es';
import moment from 'moment';
import { makeBreadCrumbFromPath, getSearch, getRecordData } from 'utils/tools';
import { useLocation } from 'react-router';
import CustomBreadcrumb from '../../common/Breadcrumb';
import CreateButton from '../../RestActions/CreateButton';
import RestTableLayout, { showTotal } from '../TableLayout';
import Box from '../../common/Box';
import RestFilterForm from '../FilterLayout';
import RestListLayout from '../ListLayout';
import { ListWrapper } from './styles';
import SearchInput from '../../RestActions/SearchInput';
import PageTitle from '../../common/PageTitle';

const { RangePicker } = DatePicker;

const RestListComponent = (props) => {
  const {
    retrieveList,
    noCardWrapper,
    resourceData,
    resource,
    hasCreate,
    layoutButtonCreate,
    gotoCreatePage,
    filter,
    header,
    isList,
    hasSearch,
    createHeader,
    resourceFilter,
    customActions,
    placeholderSearch,
    customLayout,
    summaryRow,
    noSummaries,
    isShowPagination,
    breadcrumbCustom,
    extraAction,
    hasRange,
    formatRangePicker,
    placeholderRangePicker,
    allowClearRangePicker,
    isUpdateRoute,
    sourceFieldGt,
    sourceFieldLt,
    pushQuery,
    initialFilter,
    isShowPaginationCustomLayout,
    className,
    isShowBreadcrumb,
    permissionKey,
    isSkipSubscription,
  } = props;

  const location = useLocation();
  const valueFieldGt =
    getRecordData(resourceFilter, sourceFieldGt) ||
    getRecordData(initialFilter, sourceFieldGt);
  const valueFieldLt =
    getRecordData(resourceFilter, sourceFieldLt) ||
    getRecordData(initialFilter, sourceFieldLt);

  const [rangeValue, setRangeValue] = useState([
    valueFieldGt ? moment(valueFieldGt) : null,
    valueFieldLt ? moment(valueFieldLt) : null,
  ]);

  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    if (!isMounted) {
      setRangeValue([
        valueFieldGt ? moment(valueFieldGt) : null,
        valueFieldLt ? moment(valueFieldLt) : null,
      ]);
      setIsMounted(true);
    }
  }, [valueFieldGt, valueFieldLt, isMounted]);

  const onTextSearch = (text) => {
    retrieveList({
      q: text,
    });
  };

  const onChangePagination = (page, pageSize) => {
    retrieveList({
      ...resourceFilter,
      orderBy: resourceFilter.orderBy,
      offset: (page - 1) * pageSize,
      limit: pageSize,
      filter: resourceFilter.filter,
    });
  };

  const onRange = (value) => {
    setRangeValue(value);

    const objFiledGt = zipObjectDeep(
      [sourceFieldGt, sourceFieldLt],
      [
        value?.[0]?.startOf('date').toISOString(),
        value?.[1]?.endOf('date').toISOString(),
      ],
    );

    const isExistFilterKey = 'filter' in objFiledGt;

    const filter = {
      ...resourceFilter,
      ...(isExistFilterKey
        ? {
            filter: {
              ...resourceFilter.filter,
              ...objFiledGt.filter,
            },
          }
        : objFiledGt),
    };

    isUpdateRoute && pushQuery(getSearch(filter));
    retrieveList(filter, true);
  };

  let BREADCRUMB_LIST = location ? makeBreadCrumbFromPath(location) : [];
  if (BREADCRUMB_LIST.length > 0) {
    BREADCRUMB_LIST = BREADCRUMB_LIST.slice(-1);
    BREADCRUMB_LIST[0].title =
      typeof header === 'string'
        ? I18n.t(header)
        : header || BREADCRUMB_LIST[BREADCRUMB_LIST.length - 1].title;
  }
  const actions = (
    <div className="vActions">
      {hasSearch && (
        <SearchInput
          defaultValue={resourceFilter && resourceFilter.q}
          onTextSearch={onTextSearch}
          placeholder={placeholderSearch}
          style={{ marginRight: 14 }}
        />
      )}
      {customActions}
      {hasRange && (
        <RangePicker
          value={rangeValue}
          format={formatRangePicker}
          placeholder={placeholderRangePicker}
          onChange={onRange}
          allowClear={allowClearRangePicker}
        />
      )}

      {hasCreate && layoutButtonCreate !== 'inline' && (
        <CreateButton
          header={createHeader}
          resource={resource}
          gotoCreatePage={gotoCreatePage}
          isSkipSubscription={isSkipSubscription}
          permissionKey={permissionKey}
          action="isEdit"
        />
      )}
    </div>
  );

  if (!resourceData) return null;
  const filterForm = filter ? (
    <RestFilterForm
      format={filter.props.format}
      resourceFilter={resourceFilter}
      retrieveList={retrieveList}
      {...props}
    >
      {filter}
    </RestFilterForm>
  ) : null;

  const paginationView = (
    <Pagination
      showSizeChanger
      showQuickJumper
      total={resourceFilter.count}
      defaultCurrent={resourceFilter.offset / resourceFilter.limit + 1 || 1}
      current={resourceFilter.offset / resourceFilter.limit + 1 || 1}
      showTotal={showTotal}
      pageSize={resourceFilter.limit || 10}
      onChange={onChangePagination}
    />
  );

  const paginationBottomView = isShowPagination ? (
    <Row
      key="paginationBottom"
      className="paginationRow pagination-bottom-row"
      justify="end"
      align="middle"
      type="flex"
    >
      <Col>{paginationView}</Col>
    </Row>
  ) : null;

  const tableContent = [
    <Box key="table" className="box">
      <RestTableLayout {...props} />
      {paginationBottomView}
    </Box>,
  ];
  const listContent = customLayout ? (
    React.cloneElement(customLayout, {
      retrieveList,
      resource,
      resourceData,
      resourceFilter,
      gotoCreatePage: props.gotoCreatePage,
      gotoShowPage: props.gotoShowPage,
      gotoEditPage: props.gotoEditPage,
      deleteItem: props.deleteItem,
    })
  ) : (
    <RestListLayout {...props} />
  );

  const listContentCustomLayout = (
    <>
      {listContent}
      {paginationBottomView}
    </>
  );

  const content =
    isList || customLayout ? (
      <>
        {isShowPaginationCustomLayout ? listContentCustomLayout : listContent}
      </>
    ) : (
      <Row className="viewContent">
        <Col md={0} xs={24}>
          {listContent}
          {paginationBottomView}
        </Col>
        <Col md={24} xs={0}>
          {tableContent}
        </Col>
      </Row>
    );
  const extraActionHeader = (
    <div>
      {extraAction}
      {hasCreate && layoutButtonCreate === 'inline' && (
        <CreateButton
          header={createHeader}
          resource={resource}
          gotoCreatePage={gotoCreatePage}
          isSkipSubscription={isSkipSubscription}
          permissionKey={permissionKey}
          action="isEdit"
        />
      )}
    </div>
  );

  return (
    <ListWrapper className={className}>
      <div className="viewContent">
        {!noCardWrapper && (
          <PageTitle extraAction={extraActionHeader}>
            {isShowBreadcrumb && (
              <CustomBreadcrumb data={breadcrumbCustom || BREADCRUMB_LIST} />
            )}
            {header && <div className="header-title">{I18n.t(header)}</div>}
          </PageTitle>
        )}
        {!noCardWrapper && !noSummaries && <div>{summaryRow}</div>}
        {filterForm}
        {actions}
        {content}
      </div>
    </ListWrapper>
  );
};

RestListComponent.propTypes = {
  resource: PropTypes.string,
  noCardWrapper: PropTypes.bool,
  retrieveList: PropTypes.func,
  resourceData: PropTypes.array,
  hasCreate: PropTypes.bool,
  gotoCreatePage: PropTypes.func,
  filter: PropTypes.object,
  header: PropTypes.any,
  children: PropTypes.any,
  isList: PropTypes.bool,
  hasSearch: PropTypes.bool,
  hasExport: PropTypes.bool,
  location: PropTypes.object,
  createHeader: PropTypes.string,
  resourceFilter: PropTypes.object,
  layoutButtonCreate: PropTypes.string,
  placeholderSearch: PropTypes.string,
  exportExcel: PropTypes.func,
  customActions: PropTypes.any,
  customLayout: PropTypes.any,
  summaryRow: PropTypes.node,
  noSummaries: PropTypes.bool,
  isShowPagination: PropTypes.bool,
  breadcrumbCustom: PropTypes.any,
  gotoShowPage: PropTypes.func,
  gotoEditPage: PropTypes.func,
  deleteItem: PropTypes.func,
  extraAction: PropTypes.any,
  pushQuery: PropTypes.func,
  placeholderRangePicker: PropTypes.array,
  formatRangePicker: PropTypes.string,
  allowClearRangePicker: PropTypes.bool,
  sourceFieldGt: PropTypes.string,
  sourceFieldLt: PropTypes.string,
  hasRange: PropTypes.bool,
  isUpdateRoute: PropTypes.bool,
  initialFilter: PropTypes.object,
  isShowPaginationCustomLayout: PropTypes.bool,
  className: PropTypes.string,
  createPermissions: PropTypes.array,
  isShowBreadcrumb: PropTypes.bool,
  permissionKey: PropTypes.string, // check role permission
  isSkipSubscription: PropTypes.bool, // check role permission
};

RestListComponent.defaultProps = {
  noCardWrapper: false,
  isList: false,
  hasExport: true,
  hasSearch: true,
  hasCreate: true,
  layoutButtonCreate: 'non-inline',
  isShowPagination: true,
  formatRangePicker: 'MMM DD YYYY',
  allowClearRangePicker: true,
  sourceFieldGt: 'filter.createdAt.$gt',
  sourceFieldLt: 'filter.createdAt.$lt',
};
export default RestListComponent;

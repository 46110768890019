import React from 'react';
import i18next from 'i18next';
import { Button } from 'antd';
import { useHistory, useParams } from 'react-router-dom';
import useModifyStaffProfile from 'containers/Centres/components/CentreTabs/EducatorStaffProfilesTab/hooks/useModifyStaffProfile';

const CreateProfileButton = () => {
  const { push } = useHistory();
  const { id } = useParams();
  const { isModifyStaffProfile } = useModifyStaffProfile();

  const handleCreateProfile = () => {
    push(`/educator-staff-profile/${id}/create/start`);
  };

  return (
    <Button
      disabled={!isModifyStaffProfile}
      className="btn-second-primary text-white fw-bold w-auto"
      onClick={handleCreateProfile}
    >
      {i18next.t('educatorStaffProfile.creatProfileButton')}
    </Button>
  );
};

export default CreateProfileButton;

import React from 'react';
import { Checkbox } from 'antd';
import FormItem from 'components/form/FormItem';
import { NamePath } from 'rc-field-form/lib/interface';
import i18next from 'i18next';

interface ResourceData {
  [key: string]: string;
}

interface Props {
  label?: string;
  required?: boolean;
  resourceData: ResourceData[];
  valueProp?: string;
  titleProp?: string;
  source?: NamePath;
  checkboxGroupProps?: {
    [key: string]: unknown;
  };
  [key: string]: unknown;
}

const CheckboxGroup = Checkbox.Group;
const FormCheckbox = ({
  label = '',
  required = false,
  resourceData = [],
  valueProp = 'value',
  titleProp = 'name',
  source,
  checkboxGroupProps,
  ...props
}: Props) => (
  <FormItem label={label} required={required} name={source} {...props}>
    <CheckboxGroup {...checkboxGroupProps}>
      {resourceData.map((data) => (
        <Checkbox key={data[valueProp]} value={data[valueProp]} {...props}>
          {i18next.t(data[titleProp])}
        </Checkbox>
      ))}
    </CheckboxGroup>
  </FormItem>
);

export default FormCheckbox;

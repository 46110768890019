import { createSlice } from '@reduxjs/toolkit';
import {
  getByKeyAppSettingsOfCentre,
  updateAppSettingsOfCentre,
} from './actions';

export const initialState = {};

const { reducer } = createSlice({
  name: 'appSettings',
  initialState,
  extraReducers: {
    [getByKeyAppSettingsOfCentre.fulfilled]: (state, { payload }) => {
      if (payload?.key) {
        state[payload.key] = payload;
      }
    },

    [updateAppSettingsOfCentre.fulfilled]: (state, { payload }) => {
      const res = payload?.data;
      if (res?.key) {
        state[res.key] = res;
      }
    },
  },
});

export default reducer;

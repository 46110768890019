import { LeftOutlined } from '@ant-design/icons';
import i18next from 'i18next';
import React, { MouseEventHandler } from 'react';
import CheckPermission from 'components/common/CheckPermissions';
import PreviousStepButtonFallBack from './PreviousStepButtonFallBack';
import { LinkStyle } from '../styles';

interface Props {
  onClickPrev: MouseEventHandler<HTMLAnchorElement> | undefined;
  currentIndex: number;
  packageName: string;
  permissionKey: string;
  action: string;
  isSkipSubscription: boolean;
}

const PreviousStepButton = ({
  onClickPrev,
  currentIndex,
  packageName,
  permissionKey,
  action,
  isSkipSubscription,
}: Props) => {
  const btnClassName =
    currentIndex === 0 ? 'show-hide-btn__previous' : 'show-hide-btn__next';
  return (
    <CheckPermission
      packageName={packageName}
      permissionKey={permissionKey}
      action={action}
      isDisabled
      isSkipSubscription={isSkipSubscription}
      fallback={<PreviousStepButtonFallBack currentIndex={currentIndex} />}
    >
      <LinkStyle
        onClick={onClickPrev}
        disabled={currentIndex === 0}
        className="show-hide-btn"
      >
        <LeftOutlined className={`ml-12 ${btnClassName}`} />
        <span className={`ml-6 ${btnClassName}`}>
          {i18next.t('button.previous')}
        </span>
      </LinkStyle>
    </CheckPermission>
  );
};
export default PreviousStepButton;

import React from 'react';
import i18next from 'i18next';
import PropTypes from 'prop-types';

import { Button } from 'antd';

const CentresNotifyField = ({ notifyId, centre, handleClickViewAll }) => {
  const onClick = () => {
    handleClickViewAll({ id: notifyId });
  };

  return (
    <div>
      <div>{centre?.name}</div>
      <Button
        size="small"
        type="dashed"
        onClick={onClick}
        role="presentation"
        className="mt-10"
      >
        {i18next.t('button.viewAll')}
      </Button>
    </div>
  );
};

CentresNotifyField.propTypes = {
  centre: PropTypes.object,
  notifyId: PropTypes.string,
  handleClickViewAll: PropTypes.func,
};

export default CentresNotifyField;

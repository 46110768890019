import React, { useState } from 'react';
import CardSection from 'components/common/CardSection';
import { isEmpty } from 'lodash-es';
import { NoDataIcon } from 'components/common/SVGIcon';
import i18next from 'i18next';
import renderHTML from 'react-render-html';
import { getCentreVirtualTourData } from 'redux/centres/selectors';
import { useAppSelector } from 'redux/hooks';
import VirtualTourModal, { ResponseData } from '../../Modals/VirtualTourModal';
import { VirtualTourStyles } from './styles';

const CentreVirtualTour = () => {
  const [visibleModal, setVisibleModal] = useState(false);
  const centreVirtualTourData: ResponseData[] = useAppSelector(
    getCentreVirtualTourData,
  );

  const onClickButton = () => {
    setVisibleModal(true);
  };

  const onCancel = () => {
    setVisibleModal(false);
  };

  return (
    <>
      <CardSection
        isEdit
        onClickButton={onClickButton}
        title="centreDetail.centreVirtualTour"
        permissionKey="myCentres"
        action="isEdit"
      >
        <div>
          {!isEmpty(centreVirtualTourData) ? (
            centreVirtualTourData?.map((item, index) => (
              <VirtualTourStyles className="flex-center mb-12">
                <div key={index}>{renderHTML(item?.value)}</div>
              </VirtualTourStyles>
            ))
          ) : (
            <div className="flex-col flex-center">
              <NoDataIcon />
              <div>{i18next.t('centreDetail.emptyVirtualTour')}</div>
            </div>
          )}
        </div>
      </CardSection>
      <VirtualTourModal
        onCancel={onCancel}
        visible={visibleModal}
        record={{ data: centreVirtualTourData }}
      />
    </>
  );
};

export default CentreVirtualTour;

import React, { useCallback } from 'react';

import i18next from 'i18next';
import { Button } from 'antd';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { getCentreLevelCRMPackageSelected } from 'redux/auth/selectors';

import SignUpButtonCheckPermission from './SignUpButtonCheckPermission';

const SignUpButton = ({ item, onClickSignup }) => {
  const packageSelected = useSelector(getCentreLevelCRMPackageSelected) || {};

  const handleClickSignup = useCallback(() => {
    onClickSignup(item);
  }, [item, onClickSignup]);

  if (!item.rank || item.rank > packageSelected.rank) return null;

  if (item.id === packageSelected.id)
    return <Button disabled>{i18next.t('button.signedUp')}</Button>;

  return (
    <SignUpButtonCheckPermission
      onClickSignup={handleClickSignup}
      textBtn={item.signupButtonText || i18next.t('button.signUp')}
    />
  );
};

SignUpButton.propTypes = {
  item: PropTypes.object,
  onClickSignup: PropTypes.func,
};

SignUpButton.defaultProps = {
  item: {},
};

export default SignUpButton;

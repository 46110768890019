import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'antd';
import {
  BirthdayCircleIcon,
  CalendarCircleIcon,
  FaceCircleIcon,
  GenderCircleIcon,
} from 'components/common/SVGIcon';
import i18next from 'i18next';
import { GENDERS } from 'configs/localData';
import { formatBirthday, getAge } from 'utils/textUtils';
import { withTheme } from 'styled-components';

const ChildrenItem = ({ child, theme }) => {
  const gender = useMemo(() => {
    const restItem = GENDERS.find(
      (item) => item.value === child.gender?.toLowerCase(),
    )?.textChild;

    return i18next.t(restItem || 'error.waitingUpdate');
  }, [child]);

  const data = [
    {
      IconCPN: FaceCircleIcon,
      color: theme.palette.primary,
      title: 'users.name',
      value: `${child.firstName || ''} ${child.lastName || ''}`,
      className: 'text-primary',
    },
    {
      IconCPN: GenderCircleIcon,
      color: theme.color.cyan,
      title: 'profile.gender',
      value: gender,
    },
    {
      IconCPN: CalendarCircleIcon,
      color: theme.color.warning,
      title: 'customers.birthday',
      value: formatBirthday(child.dateOfBirth),
    },
    {
      IconCPN: BirthdayCircleIcon,
      color: theme.color.redDark,
      title: 'users.age',
      value: getAge(child.dateOfBirth),
    },
  ];

  return (
    <div className="children-item">
      <Row gutter={[10, 20]}>
        {data.map((item, idx) => (
          <React.Fragment key={String(idx)}>
            <Col span={12} className="children-item__label">
              <item.IconCPN style={{ color: item.color }} />
              <span className="children-item__title">
                {i18next.t(item.title)}
              </span>
            </Col>
            <Col
              span={12}
              className={`children-item__value ${item.className || ''}`}
            >
              {item.value}
            </Col>
          </React.Fragment>
        ))}
      </Row>
    </div>
  );
};

ChildrenItem.propTypes = {
  child: PropTypes.object,
  theme: PropTypes.object,
};

export default withTheme(ChildrenItem);

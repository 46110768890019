import React from 'react';
import i18next from 'i18next';
import { Row, Col } from 'antd';
import DocumentsTable from './DocumentsTable';
import DocumentsTabStyles from './styles';
import InfoRequests from '../InfoRequests';
import CreateButton from './CreateButton';

const DocumentsTab = () => (
  <DocumentsTabStyles>
    <Row gutter={[20, 20]}>
      <Col xl={16} lg={24} md={24} xs={24}>
        <div className="header-title">
          <div className="title-name">{i18next.t('documents.title')}</div>
          <CreateButton />
        </div>
        <DocumentsTable />
      </Col>
      <Col xl={8} lg={24} md={24} xs={24}>
        <InfoRequests />
      </Col>
    </Row>
  </DocumentsTabStyles>
);

export default DocumentsTab;

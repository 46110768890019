import React, { useRef } from 'react';
import Avatar from 'antd/lib/avatar/avatar';
import { EnvironmentOutlined, UserOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import { usersSelectors } from 'redux/users/selectors';

import InfoItem from 'components/common/InfoItem';
import {
  JobTitleIcon,
  MailIcon,
  RoleIcon,
  PhoneIcon,
} from 'components/common/SVGIcon';
import EditCustomBtn from 'components/RestActions/EditCustomBtn';

import { UserInfoStyles } from './styles';
import UserEditModal from './UserEditModal';
import DropdownUserRole from '../DropdownUserRole';

const UserInfo = () => {
  const currentData = useSelector(usersSelectors.getCurrentData);

  const userEditModalRef = useRef();

  const openUserEditModal = () => {
    userEditModalRef.current && userEditModalRef.current.open();
  };

  return (
    <UserInfoStyles>
      <EditCustomBtn
        permissionKey="abilityChangeUserPermissions"
        isSkipSubscription
        action="isEdit"
        isView
        onClickButton={openUserEditModal}
      />
      <div className="flex-center mb-20">
        <Avatar size={120} icon={<UserOutlined />} src={currentData?.avatar} />
      </div>

      <div className="user-info__name mb-20">
        {`${currentData?.firstName || ''} ${currentData?.lastName || ''}`}
      </div>

      <div className="user-info__content">
        <InfoItem
          className="user-info__user-role"
          IconCPN={RoleIcon}
          value={<DropdownUserRole roleId={currentData?.roleId} />}
        />
        <InfoItem IconCPN={JobTitleIcon} value={currentData?.jobTitle} />
        <InfoItem IconCPN={MailIcon} value={currentData?.email} />
        <InfoItem IconCPN={PhoneIcon} value={currentData?.phoneNumber} />
        <InfoItem IconCPN={EnvironmentOutlined} value={currentData?.address} />
      </div>
      <UserEditModal ref={userEditModalRef} record={currentData} />
    </UserInfoStyles>
  );
};

export default UserInfo;

import styled from 'styled-components';

const ReadMoreStyles = styled.div`
  .text-pre-wrap {
    a {
      text-decoration: underline;
    }
    p,
    div,
    h1,
    h2,
    h3,
    h4,
    h5 {
      display: inline;
    }
    ul {
      display: grid;
    }
  }
  .read-more__button {
    display: inline;
    background-color: transparent;
    outline: none;
    border: 0;
    padding: 0;
    margin: 0;
    font-size: 14px;
    cursor: pointer;
    color: ${({ theme }) => theme.palette.primary};
  }

  .read-more__button:hover {
    text-decoration: underline;
  }

  .read-more__text--hide {
    max-height: 0;
    font-size: 0;
    display: none;
  }

  .read-more__text--show {
    max-height: 10em;
    opacity: 1;
    font-size: inherit;
    display: inline;
    word-wrap: break-word;
  }
  .teaser-text {
    word-wrap: break-word;
  }
  .read-more__text--remaining {
    transition: opacity 240ms ease;
  }
`;

export default ReadMoreStyles;

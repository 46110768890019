import React from 'react';
import Create from 'containers/rest/Create';
import Form from '../components/Form';

const ContactsCreate = (props) => (
  <Create {...props} resource="contacts">
    <Form />
  </Create>
);

export default ContactsCreate;

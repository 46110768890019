import React from 'react';
import PropTypes from 'prop-types';
import Edit from 'containers/rest/Edit';
import MessageDetail from '../components/MessageDetail';

const EnquiriesEdit = ({
  inboxId,
  canGetListMessages,
  canSendMessage,
  canReadMessage,
  ...props
}) => (
  <Edit
    {...props}
    resource="enquiries"
    defaultOptions={{ customApiResource: 'inboxes' }}
  >
    <MessageDetail
      inboxId={inboxId}
      {...{ canGetListMessages, canReadMessage, canSendMessage }}
    />
  </Edit>
);

EnquiriesEdit.propTypes = {
  inboxId: PropTypes.string,
  canGetListMessages: PropTypes.bool,
  canReadMessage: PropTypes.bool,
  canSendMessage: PropTypes.bool,
};

export default EnquiriesEdit;

import React from 'react';
import PropTypes from 'prop-types';
import i18next from 'i18next';
import { formatDateTimeWorking } from 'utils/textUtils';

const ImmunisationsContent = ({ item }) => (
  <div>
    <div className="ml-20 mb-10 text-16 text-style">
      <strong>{item?.name}</strong>
      {item?.vaccinationDate && (
        <p className="d-flex align-items-center mt-10 text-gray-500">
          {`${i18next.t(
            'jobApplications.generalInfo.vaccinationDate',
          )}: ${formatDateTimeWorking(item?.vaccinationDate)}`}
        </p>
      )}
    </div>
  </div>
);

ImmunisationsContent.propTypes = {
  item: PropTypes.object,
};

export default ImmunisationsContent;

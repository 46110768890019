import { baseApi } from 'redux/@rtkQuery/baseApi';
import { PREFIX_API_VER_2 } from 'configs/localData/constant';
import { LEAD_ACTIVITIES_TAG } from 'redux/@rtkQuery/tagTypes';
import {
  FilterLeadActivityParams,
  LeadActivitiesFilterConfig,
  LeadActivitiesPayload,
  LeadActivitiesResponse,
} from 'types/leadActivities';
import { ResponseAPI } from 'types';

export const leadActivitiesApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getLeadActivities: builder.query<
      LeadActivitiesResponse,
      LeadActivitiesPayload
    >({
      query: (params) => ({
        url: `/${PREFIX_API_VER_2}/crm/listing-performance/lead-activities/centres`,
        params,
      }),

      providesTags: [LEAD_ACTIVITIES_TAG],
    }),
    exportLeadActivities: builder.mutation({
      query: (data) => ({
        url: `/${PREFIX_API_VER_2}/crm/listing-performance/lead-activities/report`,
        method: 'POST',
        body: data,
      }),
    }),
    getLeadActivitiesConfig: builder.query<LeadActivitiesFilterConfig, unknown>(
      {
        query: () => ({
          url: `/${PREFIX_API_VER_2}/crm/listing-performance/lead-activities/config`,
        }),
      },
    ),
    getLeadActivitiesPRNumber: builder.query<
      ResponseAPI<{ PRNumber: string }>,
      FilterLeadActivityParams
    >({
      query: (params) => ({
        url: `/${PREFIX_API_VER_2}/crm/listing-performance/lead-activities/config/pr-number`,
        params,
      }),
    }),
    getLeadActivitiesSENumber: builder.query<
      ResponseAPI<{ SENumber: string }>,
      FilterLeadActivityParams
    >({
      query: (params) => ({
        url: `/${PREFIX_API_VER_2}/crm/listing-performance/lead-activities/config/se-number`,
        params,
      }),
    }),
    getLeadActivitiesUsers: builder.query<
      ResponseAPI<{ userName: string }>,
      FilterLeadActivityParams
    >({
      query: (params) => ({
        url: `/${PREFIX_API_VER_2}/crm/listing-performance/lead-activities/config/user-name`,
        params,
      }),
    }),
    getLeadActivitiesPhones: builder.query<
      ResponseAPI<{ userPhone: string }>,
      FilterLeadActivityParams
    >({
      query: (params) => ({
        url: `/${PREFIX_API_VER_2}/crm/listing-performance/lead-activities/config/user-phone`,
        params,
      }),
    }),
    getLeadActivitiesEmails: builder.query<
      ResponseAPI<{ userEmail: string }>,
      FilterLeadActivityParams
    >({
      query: (params) => ({
        url: `/${PREFIX_API_VER_2}/crm/listing-performance/lead-activities/config/user-email`,
        params,
      }),
    }),
  }),
});

export const {
  useGetLeadActivitiesQuery,
  useExportLeadActivitiesMutation,
  useGetLeadActivitiesConfigQuery,
  useGetLeadActivitiesEmailsQuery,
  useGetLeadActivitiesPRNumberQuery,
  useGetLeadActivitiesSENumberQuery,
  useGetLeadActivitiesPhonesQuery,
  useGetLeadActivitiesUsersQuery,
} = leadActivitiesApi;

import styled from 'styled-components';
import backgroundUrl from 'assets/icons/wave.svg';

export default styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 0 24px;
  flex: 1;
  text-align: center;
  background-position: bottom;
  background-repeat: no-repeat;
  background-image: url(${backgroundUrl});

  .search-auto-complete {
    width: 100%;
  }

  .search-centres-content {
    flex-grow: 2;
    display: flex;
    justify-content: space-between;
    padding: 0 50px;
    width: 100%;
  }

  .left-content,
  .right-content {
    padding: 24px;
  }

  .right-content {
    .background {
      width: 600px;

      img {
        height: 100%;
        object-fit: contain;
      }
    }
  }

  .left-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 24px;

    & > div {
      margin-bottom: 24px;
    }

    .claim-centres__sub-title,
    .search-auto-complete {
      max-width: 600px;
    }
    .claim-centres__sub-title {
      color: ${({ theme }) => theme.text.mainL5};
    }

    .text {
      max-width: 400px;
      text-align: left;
      line-height: normal;
      font-weight: 400;
      font-size: 18px;
    }

    .ant-checkbox-group {
      .ant-checkbox-wrapper {
        margin-bottom: 14px;

        .ant-checkbox {
          &:after {
            border-radius: 50%;
            border: none;
          }

          .ant-checkbox-inner {
            width: 20px;
            height: 20px;
            border-radius: 50%;
            border: none;
            background: ${({ theme }) => `${theme.palette.primary}20`};

            &:after {
              left: 30%;
              border-color: ${({ theme }) => theme.palette.primary};
            }
          }
        }
      }
    }

    .check-box-text {
      font-size: 18px;
      font-weight: 600;
    }

    .ant-select-selector {
      border: none;
      box-shadow: 0px 5px 18px -10px rgba(0, 0, 0, 0.73);
    }

    .footer-description {
      font-size: 15px;
      font-weight: 400;
      color: #fff;
    }

    .description {
      font-size: 16px;
      font-weight: 600;
      color: #fff;
      line-height: 1.5;
    }
    .search-auto-complete {
      .ant-select-selection-placeholder,
      .ant-select-selector {
        height: 60px !important;
        line-height: 60px !important;
      }
      .button-suffix {
        height: 48px !important;
        width: 48px !important;
      }
    }

    h1 {
      position: relative;
      line-height: 1.5;
      font-weight: 700;
      font-size: 42px;
      color: #000;
      margin: 0;
      width: 65%;
      text-align: left;
      line-height: normal;
      margin-bottom: 24px;

      .primary {
        color: ${({ theme }) => theme.palette.primary};
      }
    }
    h1:before {
      position: absolute;
      left: 2px;
      top: 36px;
      height: 0;
      width: 210px;
      content: '';
      border-top: 12px solid #db147f50;
    }
  }

  @media only screen and (max-width: 1250px) {
    padding: 0;

    .search-centres-content {
      padding: 0 26px;
    }
  }

  @media only screen and (max-width: 1000px) {
    .search-centres-content {
      .left-content {
        h1,
        .text {
          width: 80%;
        }
      }

      .right-content {
        .background {
          width: 400px;
        }
      }
    }
  }

  @media only screen and (max-width: 768px) {
    .search-centres-content {
      .right-content {
        .background {
          width: 200px;
        }
      }
    }
  }

  @media only screen and (max-width: 500px) {
    padding: 0;

    .search-centres-content {
      padding: 0px;

      .left-content {
        h1,
        .text {
          width: 100%;
        }
      }

      .right-content {
        display: none;
      }
    }
  }

  @media only screen and (max-width: 375px) {
    .left-content {
      padding: 20px;
    }
    .search-auto-complete {
      width: calc(100vw - 40px);
    }
  }

  @media only screen and (min-width: 1490px) {
    .search-centres-content {
      padding: 0 90px;
    }
  }
`;

export const SearchBarStyles = styled.div`
  --search-height: 75px;
  --search-padding: 16px 64px 16px 24px;
  --selector-font-size: 18px;
  --placeholder-font-size: 18px;
  --placeholder-height: 75px;
  --search-margin: 16px 0 16px 12px;
  --icon-size: 55px;
  --icon-top: -22px;
  --icon-left: -42px;

  .ant-select-arrow {
    width: 12px;
  }

  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    height: var(--search-height) !important;
    border-radius: 120px;
    padding: var(--search-padding);
    font-size: var(--selector-font-size);
    font-weight: 400;
    //line-height: 32px;
    box-shadow: 0px 0px 1px rgba(12, 26, 75, 0.1),
      0px 4px 20px -2px rgba(48, 48, 116, 0.08);
    border: 1px solid ${({ theme }) => theme.boardColors.N30};
    &:hover {
      border: 1px solid ${({ theme }) => theme.border.primary};
    }
  }
  .ant-select-single.ant-select-show-arrow .ant-select-selection-search {
    margin: var(--search-margin);
    font-size: var(--selector-font-size);
  }
  .ant-btn-icon-only {
    position: absolute;
    top: var(--icon-top);
    left: var(--icon-left);
    width: var(--icon-size);
    height: var(--icon-size);
  }
  .ant-select-selection-placeholder {
    font-size: var(--placeholder-font-size);
    height: var(--placeholder-height);
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .ant-select-item.ant-select-item-option {
    font-size: 30px !important;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .ant-select-dropdown-hidden {
    background-color: #ff4d4f;
    display: block;
  }

  @media only screen and (max-width: 768px) {
    .ant-select-selection-search-input {
      --selector-font-size: 16px;
    }
    .ant-select-selection-search-input {
      font-size: 16px;
    }
    .ant-form .ant-form-item {
      margin-bottom: 0;
    }
  }

  @media only screen and (max-width: 425px) {
    .ant-select:not(.ant-select-customize-input) .ant-select-selector {
      --search-height: 60px !important;
      --search-padding: 10px 0px 10px 24px;
      --selector-font-size: 14px !important;
    }
    .ant-select-single.ant-select-show-arrow .ant-select-selection-search {
      margin: 10px 0 10px 12px;
      --selector-font-size: 14px !important;
    }
    .ant-btn-icon-only {
      position: absolute;
      --icon-top: -14px;
      --icon-left: -25px;
      --icon-size: 40px;
    }
    .ant-select-selection-placeholder {
      --placeholder-font-size: 14px !important;
      --placeholder-height: 60px;
    }
  }
  @media only screen and (min-width: 2000px) {
    .ant-select:not(.ant-select-customize-input) .ant-select-selector {
      --search-height: 80px !important;
      --search-padding: 20px 0px 20px 20px;
      --selector-font-size: 20px !important;
    }
    .ant-select-single.ant-select-show-arrow .ant-select-selection-search {
      --search-margin: 20px 0px 20px 12px;
      --selector-font-size: 20px !important;
    }
    .ant-btn-icon-only {
      --icon-top: -24px;
      --icon-left: -40px;
      --icon-size: 55px;
      * {
        font-size: 19px;
      }
    }
    .ant-select-selection-placeholder {
      --placeholder-font-size: 20px !important;
      --placeholder-height: 80px;
    }
  }

  @media only screen and (min-width: 2400px) {
    .ant-select:not(.ant-select-customize-input) .ant-select-selector {
      --search-height: 85px !important;
      --search-padding: 20px 0px 20px 20px;
      --selector-font-size: 28px !important;
    }
    .ant-select-single.ant-select-show-arrow .ant-select-selection-search {
      --search-margin: 20px 0px 20px 12px;
      --selector-font-size: 28px !important;
    }

    .ant-btn-icon-only {
      --icon-top: -24px;
      --icon-left: -50px;
      --icon-size: 60px;
      * {
        font-size: 23px;
      }
    }

    .ant-select-selection-placeholder {
      --placeholder-font-size: 28px !important;
      --placeholder-height: 85px;
    }
  }

  @media only screen and (min-width: 3400px) {
    .ant-select:not(.ant-select-customize-input) .ant-select-selector {
      --search-height: 105px !important;
      --search-padding: 26px 0 26px 26px;

      --selector-font-size: 34px !important;
    }
    .ant-select-single.ant-select-show-arrow .ant-select-selection-search {
      --search-margin: 24px 0px 24px 15px;
      --selector-font-size: 34px !important;
    }

    .ant-btn-icon-only {
      --icon-top: -30px;
      --icon-left: -65px;
      --icon-size: 75px;
      * {
        font-size: 30px;
      }
    }

    .ant-select-selection-placeholder {
      --placeholder-font-size: 34px !important;
      --placeholder-height: 105px;
    }
  }

  @media only screen and (min-width: 3800px) {
    .ant-select:not(.ant-select-customize-input) .ant-select-selector {
      --search-height: 115px !important;
      --search-padding: 30px 0 30px 30px;

      --selector-font-size: 36px !important;
    }
    .ant-select-single.ant-select-show-arrow .ant-select-selection-search {
      --search-margin: 24px 0px 24px 15px;
      --selector-font-size: 36px !important;
    }

    .ant-btn-icon-only {
      --icon-top: -30px;
      --icon-left: -65px;
      --icon-size: 75px;
      * {
        font-size: 30px;
      }
    }

    .ant-select-selection-placeholder {
      --placeholder-font-size: 36px !important;
      --placeholder-height: 115px;
    }
  }
`;

import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { Avatar, Col, Form, Input, Row } from 'antd';
import PropTypes from 'prop-types';
import i18next from 'i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  editCompanyProfileSocialMediaChannels,
  getCompanyProfileSocialMediaChannels,
} from 'redux/jobs/companyProfile/actions';
import { SOCIAL_MEDIA_CHANNEL_TYPE } from 'configs/localData/constant';
import { validateRegex } from 'utils/validateUtils';
import ModalCustom from 'components/common/ModalCustom';
import { getIDCompanyProfileSelector } from 'redux/jobs/companyProfile/selectors';

const SocialMediaChannelsModal = forwardRef(
  ({ socialMedialChannelsList }, ref) => {
    const [form] = Form.useForm();
    const dispatch = useDispatch();

    const [loading, setLoading] = useState(false);
    const [visible, setVisible] = useState();
    const companyId = useSelector(getIDCompanyProfileSelector);

    useImperativeHandle(
      ref,
      () => ({
        open: (initialValue) => {
          setVisible(true);
          form.setFieldsValue(initialValue);
        },
      }),
      [form],
    );

    const handleCancel = () => {
      setVisible(false);
      form.resetFields();
    };

    const onOk = () => {
      form.validateFields().then((values) => {
        dispatch(
          editCompanyProfileSocialMediaChannels({
            companyId,
            data: values,
          }),
        )
          .then(({ payload }) => {
            if (payload?.isSuccess) {
              dispatch(
                getCompanyProfileSocialMediaChannels({
                  companyId,
                  type: SOCIAL_MEDIA_CHANNEL_TYPE,
                }),
              );
              handleCancel();
            }
          })
          .finally(() => {
            setLoading(false);
          });
      });
    };

    return (
      <ModalCustom
        title={i18next.t('jobs.companyProfile.modal.socialMediaChannels.title')}
        visible={visible}
        onCancel={handleCancel}
        onOk={onOk}
        loading={loading}
      >
        <Form form={form}>
          {socialMedialChannelsList?.map((item, index) => (
            <Row align="middle" className="p-0 m-0" key={Number(index)}>
              <Col span={5} className="flex items-center">
                <Avatar src={item?.icon} size={20} />
                <span className="ml-6">{item?.name}</span>
              </Col>
              <Col span={19} className="mt-16">
                <Form.Item
                  name={item?.id}
                  initialValue={item?.externalLink}
                  rules={[
                    {
                      pattern: validateRegex.link,
                      message: i18next.t('error.link'),
                    },
                  ]}
                >
                  <Input
                    placeholder={i18next.t(
                      'jobs.companyProfile.modal.socialMediaChannels.insertLink',
                    )}
                    allowClear
                  />
                </Form.Item>
              </Col>
            </Row>
          ))}
        </Form>
      </ModalCustom>
    );
  },
);

SocialMediaChannelsModal.propTypes = {
  socialMedialChannelsList: PropTypes.array,
};

export default SocialMediaChannelsModal;

import React, { useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import i18next from 'i18next';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import { useDispatch, useSelector } from 'react-redux';
import { updateJobApplicationStatus } from 'redux/jobs/jobApplications/actions';
import { Modal } from 'antd';
import { JOB_APPLICATION_STATUS_CONSTANT } from 'configs/localData';
import { isEmpty } from 'lodash-es';
import { getDataJobApplications } from 'redux/jobs/jobApplications/selectors';
import Column from './Column';
import BoardStyles, { ContainerScroll, ContainerBoard } from './styles';
import DeclineReasonModal from '../../JobApplicationInfo/DropdownStatusApplication/DeclineModal';

const Board = ({
  getAllDataAction,
  applicationStatus,
  containerHeight,
  useClone,
  isCombineEnabled,
  withScrollableColumns,
  isDragDisabledColumn,
  isDragDisabledItem,
  isApplicationOfAJob,
  jobPostId,
  filterData,
}) => {
  const jobApplications = useSelector(getDataJobApplications);
  const getItemStatus = (value) =>
    applicationStatus.find((item) => item.application_id === value);

  const declineReasonModalRef = useRef();

  const [changeStatusValues, setChangeStatusValues] = useState({});
  const [updateNewStatus, setUpdateNewStatus] = useState('');

  const dispatch = useDispatch();
  const numberColumn = useMemo(
    () => applicationStatus?.length || 1,
    [applicationStatus],
  );

  const updateStatus = ({ applicationId, status, declinedReasonId }) => {
    status?.id &&
      dispatch(
        updateJobApplicationStatus({
          applicationId,
          statusId: status.id,
          declinedReasonId,
          currentStatus: status?.beforeStatus,
        }),
      ).then(() => {
        setUpdateNewStatus(status);
      });
  };

  const confirmChangeStatus = ({ status, applicationId }) => {
    const getJobApplicationItem = () =>
      jobApplications?.find(
        (item) => item?.id === applicationId && !isEmpty(item?.post?.deletedAt),
      );
    const jobDeletedItem = getJobApplicationItem() || {};

    if (!isEmpty(jobDeletedItem)) {
      return Modal.warning({
        title: i18next.t('jobApplications.warningChangeStatus'),
      });
    }
    return Modal.confirm({
      title: <strong>{i18next.t('applications.confirmTitle')}</strong>,
      content: (
        <div
          dangerouslySetInnerHTML={{
            __html: i18next.t('jobApplications.confirmContent', {
              beforeStatus: status?.beforeStatus,
              endStatus: status?.endStatus,
            }),
          }}
        />
      ),
      okText: i18next.t('button.confirm'),
      onOk: () =>
        updateStatus({
          applicationId,
          status,
        }),
    });
  };

  const openDeclineReasonModal = () => {
    declineReasonModalRef.current && declineReasonModalRef.current.open();
  };

  const handleChangeStatus = ({
    beforeStatusId,
    afterStatusId,
    applicationId,
  }) => {
    const beforeItem = getItemStatus(beforeStatusId) || {};
    const endItem = getItemStatus(afterStatusId) || {};

    const status = {
      id: afterStatusId,
      beforeStatus: beforeItem.application_name
        ? i18next.t(beforeItem.application_name)
        : beforeStatusId,
      endStatus: endItem.application_name
        ? i18next.t(endItem.application_name)
        : afterStatusId,
    };
    if (
      status?.endStatus === JOB_APPLICATION_STATUS_CONSTANT.declined.value &&
      status?.beforeStatus !== JOB_APPLICATION_STATUS_CONSTANT.cancelled.value
    ) {
      openDeclineReasonModal();
      setChangeStatusValues({ status, applicationId });
    } else {
      confirmChangeStatus({
        status,
        applicationId,
      });
    }
  };

  const onDragEnd = (result = {}) => {
    const beforeStatusId = result?.source?.droppableId;
    const afterStatusId = result?.destination?.droppableId;
    const applicationId = result?.draggableId;

    if (beforeStatusId !== afterStatusId && !!afterStatusId) {
      handleChangeStatus({
        beforeStatusId,
        afterStatusId,
        applicationId,
      });
    }
  };

  const board = (
    <Droppable
      droppableId="board"
      type="COLUMN"
      direction="horizontal"
      ignoreContainerClipping={Boolean(containerHeight)}
      isCombineEnabled={isCombineEnabled}
    >
      {(provided) => (
        <ContainerBoard ref={provided.innerRef} {...provided.droppableProps}>
          {applicationStatus?.map((item, index) => (
            <Column
              item={item}
              key={item.application_id}
              listId={item.application_id}
              index={index}
              title={i18next.t(item.application_name)}
              lineColor={item.application_textColor}
              colors={item.colors}
              isScrollable={withScrollableColumns}
              isCombineEnabled={isCombineEnabled}
              useClone={useClone}
              numberColumn={numberColumn}
              isDragDisabledColumn={isDragDisabledColumn}
              isDragDisabledItem={isDragDisabledItem}
              getAllDataAction={getAllDataAction}
              isApplicationOfAJob={isApplicationOfAJob}
              jobPostId={jobPostId}
              updateNewStatus={updateNewStatus}
              filterData={filterData}
            />
          ))}
          {provided.placeholder}
        </ContainerBoard>
      )}
    </Droppable>
  );

  return (
    <BoardStyles className="board-canvas" containerHeight={containerHeight}>
      <DragDropContext onDragEnd={onDragEnd}>
        {withScrollableColumns ? (
          <ContainerScroll>{board}</ContainerScroll>
        ) : (
          <div className="container-scroll-x">{board}</div>
        )}
      </DragDropContext>
      <DeclineReasonModal
        ref={declineReasonModalRef}
        updateStatus={updateStatus}
        changeStatusValues={changeStatusValues}
      />
    </BoardStyles>
  );
};

Board.propTypes = {
  initial: PropTypes.object,
  applicationStatus: PropTypes.array,
  containerHeight: PropTypes.string,
  useClone: PropTypes.bool,
  isCombineEnabled: PropTypes.bool,
  withScrollableColumns: PropTypes.bool,
  isDragDisabledColumn: PropTypes.bool,
  isDragDisabledItem: PropTypes.bool,
  summaryStatus: PropTypes.object,
  getAllDataAction: PropTypes.func,
  retrieveList: PropTypes.func,
  jobPostId: PropTypes.string,
  isApplicationOfAJob: PropTypes.bool,
  filterData: PropTypes.object,
};

Board.defaultProps = {
  initial: {},
  summaryStatus: {},
  applicationStatus: [],
};

export default Board;

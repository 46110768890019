import React from 'react';
import i18next from 'i18next';
import { CloseOutlined } from '@ant-design/icons';
import Button from 'antd/lib/button';
import Modal from 'antd/lib/modal';
import { useDispatch, useSelector } from 'react-redux';

import { logout } from 'redux/auth/actions';
import { getImpersonateSessionToken } from 'utils/token';
import { ImpersonationStyles, WarningEnvironmentStyles } from './styles';

const Impersonation = () => {
  const currentUser = useSelector((state) => state.auth.data);
  const impersonateSessionToken = getImpersonateSessionToken();

  const roleDescription = useSelector(
    (state) => state.auth.data?.role?.description,
  );
  const dispatch = useDispatch();

  const handleLogout = () => {
    Modal.confirm({
      title: i18next.t('header.logout'),
      content: i18next.t('users.logoutConfirm'),
      onOk() {
        dispatch(logout());
      },
    });
  };

  const env = window?.location.hostname.split('.')?.[0];

  let checkOnEnvironment;
  if (env?.includes('uat')) {
    checkOnEnvironment = {
      message: i18next.t('impersonation.onUat'),
      background: '#36BF57',
    };
  } else if (env?.includes('sit')) {
    checkOnEnvironment = {
      message: i18next.t('impersonation.onSit'),
      background: '#FB8429',
    };
  } else {
    checkOnEnvironment = {
      message: i18next.t('impersonation.onProduction'),
      background: '#E62828',
    };
  }

  return impersonateSessionToken ? (
    <>
      <WarningEnvironmentStyles
        className="flex-center-between"
        style={{
          backgroundColor: checkOnEnvironment.background,
        }}
      >
        {checkOnEnvironment.message}
      </WarningEnvironmentStyles>
      <ImpersonationStyles className="flex-center-between">
        {i18next.t('users.alertImpersonate', {
          name: `${currentUser.firstName || ''} ${
            currentUser.lastName || ''
          } - ${roleDescription || ''}`,
        })}
        <Button
          className="btn-transparent close-btn"
          size="small"
          icon={<CloseOutlined />}
          onClick={handleLogout}
        />
      </ImpersonationStyles>
    </>
  ) : null;
};

export default Impersonation;

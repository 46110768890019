import React, { useContext, useEffect } from 'react';
import i18next from 'i18next';
import { DatePicker, FormInstance, Tooltip, Typography } from 'antd';
import { RestInputContext } from 'components/RestInput/RestInputContext';
import { formatDate, getFilterFromUrl, rangeLastYear } from 'utils/tools';
import moment from 'moment';
import {
  COMPARE_OPTIONS,
  IS_COMPARE,
  RANGE_FORMAT,
  RANGE_PICKER_OPTION_CUSTOM,
} from 'containers/MarketingMetrics/constants';
import { useLocation } from 'react-router-dom';
import {
  setCompareOption,
  setDateOption,
  setIsCompare,
} from 'redux/marketingMetrics/slice';
import { useDispatch, useSelector } from 'react-redux';
import {
  compareOptionSelector,
  isCompareSelector,
} from 'redux/marketingMetrics/selector';
import TopDatePicker from './components/TopDatePicker';
import LeftDatePicker from './components/LeftDatePicker';
import { FormItemCustom } from './style';

const { RangePicker } = DatePicker;
const { Text } = Typography;

interface Props {
  isShowTooltip?: boolean;
  placeholder?: string;
  source: string;
  disabledDate?: (current: moment.Moment) => boolean;
}

const RangePickerCompare = ({
  isShowTooltip,
  placeholder,
  disabledDate,
  source,
  ...props
}: Props) => {
  const dispatch = useDispatch();
  const { form }: { form?: FormInstance } = useContext(RestInputContext);
  const { search } = useLocation();
  const { filter } = getFilterFromUrl(search).filter;
  const compareOption = useSelector(compareOptionSelector);
  const isCompare = useSelector(isCompareSelector);
  const isCompareUrl = String(filter?.isCompare) === IS_COMPARE.true;
  const dateOption = filter?.dateOption;
  const compareOptionUrl = filter?.compareOption;

  useEffect(() => {
    dispatch(setIsCompare(isCompareUrl));
    dispatch(setDateOption(dateOption));
    dispatch(setCompareOption(compareOptionUrl));
    localStorage.setItem('isCompareMarketingMetric', String(isCompareUrl));
    localStorage.setItem('dateOptionMarketingMetric', String(dateOption));
    localStorage.setItem(
      'compareOptionMarketingMetric',
      String(compareOptionUrl),
    );
  }, [dispatch, isCompareUrl, dateOption, compareOptionUrl]);

  const onChangeDate = (value) => {
    const customKey = RANGE_PICKER_OPTION_CUSTOM.custom.key;
    localStorage.setItem('dateOptionMarketingMetric', customKey);

    if (value[0] && value[1]) {
      dispatch(setDateOption(RANGE_PICKER_OPTION_CUSTOM.custom.key));

      if (compareOption === COMPARE_OPTIONS.precedingPeriod) {
        const differenceDate = Math.abs(value[0].diff(value[1], 'days')) + 1;
        const compareWithValue = [
          moment(value[0]).subtract(differenceDate, 'days'),
          moment(value[0]).subtract(1, 'days'),
        ];
        isCompare
          ? form.setFieldsValue({ compareDate: compareWithValue })
          : form.setFieldsValue({ compareDate: null });
      } else {
        isCompare
          ? form.setFieldsValue({ compareDate: rangeLastYear(value) })
          : form.setFieldsValue({ compareDate: null });
      }
    }
  };

  const renderCustomDateCompare = (current) => {
    const styleCellView: React.CSSProperties = {};
    const style2CellInner: React.CSSProperties = {};
    const compareDate = form?.getFieldValue('compareDate');
    const isOnlyOneDate =
      formatDate(compareDate?.[0]) === formatDate(compareDate?.[1]) &&
      formatDate(current) === formatDate(compareDate?.[0]);
    const betweenRanges =
      formatDate(current) > formatDate(compareDate?.[0]) &&
      formatDate(current) < formatDate(compareDate?.[1]);

    if (isCompare) {
      if (isOnlyOneDate) {
        style2CellInner.backgroundColor = '#32A4FC';
        style2CellInner.borderRadius = '8px';
        style2CellInner.color = '#fff';
      } else {
        if (formatDate(current) === formatDate(compareDate?.[0])) {
          style2CellInner.backgroundColor = '#32A4FC';
          style2CellInner.borderRadius = '8px 0px 0px 8px';
          style2CellInner.color = '#fff';
        }
        if (formatDate(current) === formatDate(compareDate?.[1])) {
          style2CellInner.backgroundColor = '#32A4FC';
          style2CellInner.borderRadius = '0px 8px 8px 0px';
          style2CellInner.color = '#fff';
        }
        if (betweenRanges) {
          styleCellView.backgroundColor = '#E9F4FF';
        }
      }
    }

    return (
      <div className="ant-picker-cell-in-view" style={styleCellView}>
        <div className="ant-picker-cell-inner" style={style2CellInner}>
          {current.date()}
        </div>
      </div>
    );
  };

  return (
    <Tooltip
      placement="top"
      title={isShowTooltip ? i18next.t(placeholder) : ''}
    >
      <FormItemCustom
        {...props}
        name={source}
        isCompare={isCompare}
        extra={
          <>
            {isCompare && (
              <div className="compare-message-custom">
                <Text className="text-gray-500">
                  {i18next.t('marketingMetrics.compareTo')}:{' '}
                </Text>
                {compareOption === COMPARE_OPTIONS.precedingPeriod ? (
                  <Text className="text-primary">
                    {i18next.t('marketingMetrics.precedingPeriod')}
                  </Text>
                ) : (
                  <Text className="text-primary">
                    {i18next.t('marketingMetrics.samePeriodLastYear')}
                  </Text>
                )}
              </div>
            )}
          </>
        }
      >
        <RangePicker
          disabledDate={disabledDate}
          panelRender={(panelNode) => (
            <div className="flex">
              <LeftDatePicker
                form={form}
                rangeDate={form?.getFieldValue('rangeDate')}
              />
              <div>
                <TopDatePicker
                  form={form}
                  rangeDate={form?.getFieldValue('rangeDate')}
                />
                {panelNode}
              </div>
            </div>
          )}
          dateRender={renderCustomDateCompare}
          onChange={onChangeDate}
          allowClear={false}
          format={RANGE_FORMAT}
        />
      </FormItemCustom>
    </Tooltip>
  );
};

export default RangePickerCompare;

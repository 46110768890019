import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Button, Col, Row, Skeleton, Spin } from 'antd';
import JobTemplateItem from 'containers/JobTemplates/components/ExistingJobTemplateGrid/JobTemplateItem';
import { ExistingJobTemplateGridStyles } from 'containers/JobTemplates/components/ExistingJobTemplateGrid/styles';
import {
  enabledLoadMoreExistingJobTemplates,
  getDataJobTemplates,
  getLoadingJobTemplates,
  getResourceDataJobTemplate,
  hasSelectedTemplateSelector,
} from 'redux/jobs/jobTemplates/selectors';
import { useDispatch, useSelector } from 'react-redux';
import { NoDataIcon } from 'components/common/SVGIcon';
import i18next from 'i18next';
import { useHistory } from 'react-router';
import {
  resetCreateJobTemplateStep,
  setSelectedTemplate,
} from 'redux/jobs/jobTemplates/slice';
import { getAllJobTemplates } from 'redux/jobs/jobTemplates/actions';
import {
  DEFAULT_CURRENT_PAGE,
  DEFAULT_SORT_JOB_TEMPLATE,
  LIMIT_LIST_EXISTING_TEMPLATE,
} from 'containers/JobTemplates/components/LocalData/constant';
import { convertSearchUrl, getFilterFromUrl } from 'utils/tools';
import { useLocation } from 'react-router-dom';
import { Waypoint } from 'react-waypoint';
import { isEmpty } from 'lodash-es';
import useRolesJobTemplates from 'containers/Users/hooks/useRoleJobTemplates';
import SearchTemplateInput from './SearchTemplateInput';

const ExistingJobTemplateGrid = () => {
  const { push } = useHistory();
  const dispatch = useDispatch();
  const { search, pathname } = useLocation();
  const { filter } = getFilterFromUrl(search);
  const filterRef = useRef(filter.filter);
  const [selectingTemplateId, setSelectingTemplateId] = useState('');
  const pageRef = useRef(DEFAULT_CURRENT_PAGE);
  const existingTemplateData = useSelector(getDataJobTemplates);
  const loading = useSelector(getLoadingJobTemplates);
  const resourceData = useSelector(getResourceDataJobTemplate);
  const isEnableLoadMoreExistingJobTemplate = useSelector(
    enabledLoadMoreExistingJobTemplates,
  );
  const disabledAction = useRolesJobTemplates();

  const hasSelectedTemplate = useSelector(hasSelectedTemplateSelector);

  const retrieveExistingTemplateList = useCallback(
    ({ filter = {}, isRefresh }) =>
      dispatch(
        getAllJobTemplates({
          data: {
            sorts: [DEFAULT_SORT_JOB_TEMPLATE],
            page: DEFAULT_CURRENT_PAGE,
            size: LIMIT_LIST_EXISTING_TEMPLATE,
            filter: isEmpty(filter.filter) ? undefined : filter.filter,
            ...filter,
          },
          options: {
            isRefresh,
          },
        }),
      ),
    [dispatch],
  );

  useEffect(() => {
    retrieveExistingTemplateList({
      filter: {
        filter: isEmpty(filterRef.current) ? undefined : filterRef.current,
      },
      isRefresh: true,
    });
  }, [retrieveExistingTemplateList, dispatch]);

  const handleEnterWaypoint = () => {
    retrieveExistingTemplateList({
      filter: {
        sorts: filter.sorts || [DEFAULT_SORT_JOB_TEMPLATE],
        page: (pageRef.current += 1),
        size: filter.size || LIMIT_LIST_EXISTING_TEMPLATE,
        filter: isEmpty(filter.filter) ? undefined : filter.filter,
      },
      isRefresh: false,
    });
  };

  const resetPage = () => {
    pageRef.current = DEFAULT_CURRENT_PAGE;
  };

  const handleCreateNewTemplate = () => {
    dispatch(resetCreateJobTemplateStep());
    push(`/jobTemplates/create/step`);
  };

  useEffect(() => {
    if (selectingTemplateId) {
      dispatch(setSelectedTemplate(true));
      push({
        pathname,
        search: `?${convertSearchUrl({
          ...filter,
          id: selectingTemplateId,
        })}`,
      });
    }
  }, [dispatch, push, selectingTemplateId]); // eslint-disable-line

  return (
    <ExistingJobTemplateGridStyles>
      {existingTemplateData?.length || !isEmpty(filter?.filter) ? (
        <div className="search-input">
          <SearchTemplateInput
            retrieveList={retrieveExistingTemplateList}
            resetPage={resetPage}
          />
          {hasSelectedTemplate !== null && !hasSelectedTemplate && (
            <span className="select-one-error">
              {i18next.t('jobs.jobTemplates.requireSelectOne')}
            </span>
          )}
        </div>
      ) : null}

      <div className="mt-70">
        <Skeleton
          active
          paragraph={{ rows: 6 }}
          loading={loading && !resourceData?.total}
        >
          {existingTemplateData?.length ? (
            <>
              <Row gutter={[12, 12]} className="template-row mt-80">
                {existingTemplateData.map((template) => (
                  <Col xxl={8} md={12} xs={24} key={template?.id}>
                    <JobTemplateItem
                      template={template}
                      selectingTemplateId={selectingTemplateId}
                      setSelectingTemplateId={setSelectingTemplateId}
                    />
                  </Col>
                ))}
              </Row>
              {loading && (
                <div className="loading-template">
                  <Spin tip={i18next.t('jobs.jobTemplates.loadingMessage')} />
                </div>
              )}
              {isEnableLoadMoreExistingJobTemplate && (
                <Waypoint onEnter={handleEnterWaypoint} />
              )}
            </>
          ) : (
            <div className="no-template">
              <NoDataIcon />
              <div className="no-template-item">
                <h3>{i18next.t('jobs.jobTemplates.noTemplates')}</h3>
                <span>
                  {i18next.t('jobs.jobTemplates.noTemplatesDescription')}
                </span>
              </div>
              {isEmpty(filter) ? (
                <div className="no-template-item">
                  <Button
                    type="primary"
                    onClick={handleCreateNewTemplate}
                    disabled={disabledAction}
                  >
                    {i18next.t('jobs.jobTemplates.createTemplate')}
                  </Button>
                </div>
              ) : null}
            </div>
          )}
        </Skeleton>
      </div>
    </ExistingJobTemplateGridStyles>
  );
};

export default ExistingJobTemplateGrid;

import React, { useEffect, useCallback } from 'react';

import i18next from 'i18next';
import { getCentresOfMeBasic } from 'redux/centresOfMeBasic/actions';
import { useDispatch } from 'react-redux';
import CentreFilter from './CentreFilter';
import CentreListMultiSelect from './CentreListMultiSelect';

const CentreForm = () => {
  const dispatch = useDispatch();

  const retrieveList = useCallback(
    ({ filter = {}, isRefresh, keepFilter }) => {
      dispatch(
        getCentresOfMeBasic({
          data: {
            orderBy: 'name',
            ...filter,
            filter: filter.filter,
          },
          options: {
            keepFilter,
            isRefresh,
          },
        }),
      );
    },
    [], // eslint-disable-line
  );

  useEffect(() => {
    retrieveList({
      filter: {
        limit: 10,
        offset: 0,
      },
      isRefresh: true,
    });
  }, []); // eslint-disable-line

  return (
    <div>
      <div className="title-session">{i18next.t('users.selectCentres')}</div>
      <CentreFilter retrieveList={retrieveList} />
      <CentreListMultiSelect retrieveList={retrieveList} />
    </div>
  );
};

export default CentreForm;

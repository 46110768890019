export const TAB_VIEW_DEFAULT = 'campaign';
export const DEFAULT_CURRENT_PAGE = 1;
export const SORT_ARRAY_VALUE = 'sorts[]';
export const LIMIT_ITEM = 10;
export const LIMIT_LIST_EXISTING_TEMPLATE = 12;
export const DEFAULT_SORT_CAMPAIGN = '-lastUpdatedAt';
export const DEFAULT_SORT_ROLE = 'name';
export const DEFAULT_SORT_JOB_TEMPLATE = 'templateName';
export const DEFAULT_SORT_INDIVIDUAL = '-updatedAt';
export const CLOSED_JOB_LISTING = 'Closed';

export const START_CREATE_PARAM = 'choose';

export const CHOOSE_EXISTING_TEMPLATE_PARAM = 'choose-existing-job-template';

export const CREATE_JOB_LISTINGS_STEPS_PARAM = 'steps';

export const CREATE_JOB_LISTING_FINAL_STEP = 4;

export const CREATE_JOB_LISTING_STEPS = [
  {
    title: 'jobListings.steps.preview',
  },
  {
    title: 'jobListings.steps.selectWorkplace',
  },
  {
    title: 'jobListings.steps.startDate',
  },
  {
    title: 'jobListings.steps.applicationDeadline',
  },
  {
    title: 'jobListings.steps.campaign',
  },
];

export const DEFAULT_SORT = '-lastUpdated';

export const JOB_LISTING_DETAIL_TABS = {
  JOB_DETAILS: 'job-details',
  APPLICATIONS: 'applications',
};

export const JOB_APPLICATION_STATUS_CONST = {
  submitted: {
    value: 'submitted',
    text: 'applicationStatus.submitted',
  },
  inProgress: {
    value: 'inProgress',
    text: 'applicationStatus.inProgress',
  },
  interview: {
    value: 'interview',
    text: 'applicationStatus.interview',
  },
  verified: {
    value: 'verified',
    text: 'applicationStatus.verified',
  },
  offered: {
    value: 'offered',
    text: 'applicationStatus.offered',
  },
  employed: {
    value: 'employed',
    text: 'applicationStatus.employed',
  },
  declined: {
    value: 'declined',
    text: 'applicationStatus.declined',
  },
  cancelled: {
    value: 'cancelled',
    text: 'applicationStatus.cancelled',
  },
};

export const JOB_APPLICATION_STATUS = Object.values(
  JOB_APPLICATION_STATUS_CONST,
);

export const START_A_NEW_JOB_CONST = {
  immediateStart: {
    value: 'isImmediateStart',
    text: 'jobListings.immediateStart',
  },
  selectStartDate: {
    value: 'selectStartDate',
    text: 'jobListings.selectStartDate',
  },
};

export const START_A_NEW_JOB = Object.values(START_A_NEW_JOB_CONST);

export const JOB_LISTING_STATUS_CONST = {
  all: {
    value: 'all',
    text: 'common.all',
  },
  open: {
    value: 'open',
    text: 'common.open',
  },
  closed: {
    value: 'closed',
    text: 'common.closed',
  },
};

export const JOB_LISTING_STATUS = Object.values(JOB_LISTING_STATUS_CONST);

export const VIEW_TYPE_JOB_LISTINGS = {
  CAMPAIGN: 'campaign',
  ROLE: 'role',
  TEMPLATE: 'template',
  INDIVIDUAL: 'individual',
};

export const LIMIT_CAMPAIGN_DEFAULT = 12;

export const LIMIT_WORKPLACES_DEFAULT = 20;

export const OTHER_VALUE = 'other';

export const WORKPLACE_TYPES_CONST = {
  centre: {
    value: 'centre',
    text: 'centres.centre',
  },
  company: {
    value: 'company',
    text: 'centres.companyTitle',
  },
};

export const WORKPLACE_TYPES = Object.values(WORKPLACE_TYPES_CONST);

export const SELECT_WORKPLACE_STEP_INDEX = 1;

export const OTHER_CAPITALIZE = 'Other';

import { createSlice } from '@reduxjs/toolkit';
import { findIndex } from 'lodash-es';
import {
  getAllAutomatedResponses,
  getAllAutomatedResponsesAllCentres,
  updateAutomatedResponses,
  updateAutomatedResponsesAllCentres,
} from './actions';

const initialState = {
  data: [],
  currentData: {},
};

const getAllAutomatedResponsesPending = (state) => {
  state.loading = true;
};

const getAllAutomatedResponsesSuccess = (state, { payload }) => {
  state.loading = false;
  state.data = payload;
  state.currentData = payload?.[0];
};

const getAllAutomatedResponsesFail = (state) => {
  state.loading = false;
};

const updateAutomatedResponsesSuccess = (state, { payload, meta }) => {
  if (meta?.arg?.key === state.currentData.key) {
    state.currentData = payload;
  }
  const matchedIdx = findIndex(state.data, ['key', payload.key]);

  state.data[matchedIdx] = payload;
};

const { actions, reducer } = createSlice({
  name: 'automatedResponse',
  initialState,
  reducers: {
    setCurrentDataAutomatedResponse: (state, { payload }) => {
      state.currentData = payload;
    },
  },
  extraReducers: {
    [getAllAutomatedResponses.pending]: getAllAutomatedResponsesPending,
    [getAllAutomatedResponses.fulfilled]: getAllAutomatedResponsesSuccess,
    [getAllAutomatedResponses.rejected]: getAllAutomatedResponsesFail,

    [updateAutomatedResponses.fulfilled]: updateAutomatedResponsesSuccess,

    [getAllAutomatedResponsesAllCentres.pending]:
      getAllAutomatedResponsesPending,
    [getAllAutomatedResponsesAllCentres.fulfilled]:
      getAllAutomatedResponsesSuccess,
    [getAllAutomatedResponsesAllCentres.rejected]: getAllAutomatedResponsesFail,

    [updateAutomatedResponsesAllCentres.fulfilled]:
      updateAutomatedResponsesSuccess,
  },
});

export const { setCurrentDataAutomatedResponse } = actions;

export default reducer;

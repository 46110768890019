import React from 'react';
import PropTypes from 'prop-types';
import { Divider } from 'antd';
import { formatMoney } from 'utils/tools';
import TooltipStyles from './styles';

const TooltipChart = ({
  active,
  payload,
  dataChart,
  isCircleLegend,
  isShowTitle,
  formatValue,
}) =>
  active ? (
    <TooltipStyles>
      {isShowTitle && (
        <>
          <div className="title-tooltip">
            {payload?.[0]?.payload?.nameNote || payload?.[0]?.payload?.name}
          </div>
          <Divider />
        </>
      )}
      <div className="content-tooltip">
        {payload?.map((item, i) => (
          <div className="value-item" key={String(i)}>
            <div
              className={
                isCircleLegend ? 'circle-tooltip' : 'rectangle-tooltip'
              }
              style={{
                backgroundColor:
                  item?.payload?.fill ||
                  item?.color ||
                  dataChart?.find((chart) => chart.value === item.dataKey)
                    ?.fillColor,
              }}
            />
            <div className="text-value">
              {`${item.name}: `}
              <strong className="strong-value">
                {formatValue(item.value, item)}
              </strong>
            </div>
          </div>
        ))}
      </div>
    </TooltipStyles>
  ) : null;

TooltipChart.propTypes = {
  active: PropTypes.bool,
  payload: PropTypes.array,
  dataChart: PropTypes.array,
  isCircleLegend: PropTypes.bool,
  isShowTitle: PropTypes.bool,
  formatValue: PropTypes.func,
};

TooltipChart.defaultProps = {
  isCircleLegend: false,
  isShowTitle: true,
  formatValue: (value) => formatMoney(value),
};

export default TooltipChart;

import React, { useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Space, Skeleton } from 'antd';
import { isEmpty } from 'lodash-es';
import { useParams } from 'react-router';
import {
  getInfoRequestSelector,
  getInfoRequestLoadingSelector,
  enabledLoadMoreInfoRequestSelector,
} from 'redux/applications/selectors';
import { getAllInformationRequests } from 'redux/applications/actions';
import { Waypoint } from 'react-waypoint';

import EmptyData from 'components/common/EmptyData';
import InfoRequestStyles from './styles';
import HeaderInfoRequest from './Header';
import CardInfoRequest from './CardInfoRequest';

const InfoRequests = () => {
  const { id } = useParams();

  const dispatch = useDispatch();

  const infoRequests = useSelector(getInfoRequestSelector);

  const loading = useSelector(getInfoRequestLoadingSelector);

  const enabledLoadMore = useSelector(enabledLoadMoreInfoRequestSelector);

  const retrieveList = useCallback(
    (data, isRefresh) => {
      id &&
        dispatch(
          getAllInformationRequests({
            data: { id, orderBy: '-createdAt', ...data },
            options: { isRefresh },
          }),
        );
    },
    [id], // eslint-disable-line
  );

  useEffect(() => {
    retrieveList({}, true);
  }, []); // eslint-disable-line

  const handleWaypoint = useCallback(() => {
    retrieveList({}, false);
  }, [retrieveList]);

  return (
    <InfoRequestStyles>
      <HeaderInfoRequest applicationId={id} />
      <Space className="list-card-info" direction="vertical" size={16}>
        {!loading && isEmpty(infoRequests) ? (
          <EmptyData />
        ) : (
          infoRequests.map((item, index) => (
            <CardInfoRequest key={String(index)} item={item} index={index} />
          ))
        )}
        {loading && <Skeleton active avatar />}
        {enabledLoadMore && <Waypoint onEnter={handleWaypoint} />}
      </Space>
    </InfoRequestStyles>
  );
};

export default InfoRequests;

import React from 'react';
import { Row, Col } from 'antd';
import { useSelector } from 'react-redux';
import { getSummarySelector } from 'redux/centres/selectors';

import {
  TotalCentresIcon,
  TotalPlacesIcon,
  TotalEarningIcon,
  TotalWaitlistValueIcon,
} from 'components/common/SVGIcon';

import SummaryCard from 'components/common/SummaryCardCustom';

const Summaries = () => {
  const summaries = useSelector(getSummarySelector) || {};

  const summariesMap = [
    {
      title: 'centres.summaries.totalCentres',
      count: summaries.totalCentre,
      IconComponent: TotalCentresIcon,
    },
    {
      title: 'centres.summaries.totalPlaces',
      count: summaries.totalPlaces,
      IconComponent: TotalPlacesIcon,
    },
    {
      title: 'centres.summaries.estFee',
      count: summaries.estimatedEarningPotentialValue,
      IconComponent: TotalEarningIcon,
      isMoney: true,
    },
    {
      title: 'centres.summaries.waitlistValue',
      count: summaries.waitlistedValue,
      IconComponent: TotalWaitlistValueIcon,
      isMoney: true,
    },
  ];

  return (
    <Row gutter={[25, 25]} style={{ marginBottom: 24 }}>
      {summariesMap.map((item, index) => (
        <Col lg={6} md={12} xs={24} key={String(index)}>
          <SummaryCard {...item} />
        </Col>
      ))}
    </Row>
  );
};

export default Summaries;

import styled from 'styled-components';

export const BannerCentreItemStyles = styled.div`
  .centre-item {
    &__cover-wrapper {
      position: relative;
    }
    &__rating-btn {
      background: var(--primary);
      font-size: 16px !important;
    }
    &__rating-img {
      width: 100%;
    }

    &__favorite {
      position: absolute;
      top: 10px;
      right: 10px;
      border: none;
      background-color: #2d1f2120;
    }
    &__NQSRType-img {
      width: 64px;
      height: 32px;
      object-fit: contain;
      background: #ffffff;
      border-radius: 6px;
      position: absolute;
      bottom: 10px;
      right: 10px;
    }
    &__logo {
      background: #ffffff;
      position: absolute;
      bottom: -30px;
      left: 30px;
      border: 4px solid var(--border-color-light);
    }
    &__group-tag {
      position: absolute;
      top: 10px;
      left: 10px;
    }
  }

  .tag-custom {
    height: 36px !important;
  }

  .block-btn {
    color: #fff;
    border-radius: 8px;
    width: 36px;
    height: 36px;
    display: flex;
    justify-content: center;
    align-items: center;

    .anticon {
      font-size: 18px !important;
      line-height: 0;
    }
  }
`;

import React, { useState } from 'react';
import i18next from 'i18next';
import { StaffProfileModel } from 'types/staffProfiles';
import RowItem from 'containers/Centres/components/CentreTabs/EducatorStaffProfilesTab/components/BoardView/RowItem';
import {
  AvatarPlaceholderIcon,
  BriefCaseOutLineIcon,
  PersonFillIcon,
} from 'components/common/SVGIcon';
import { Form } from 'antd';
import EditCustomBtn from 'components/RestActions/EditCustomBtn';
import ModalCustom from 'components/common/ModalCustom';
import { useEditStaffProfilesMutation } from 'redux/@rtkQuery/educatorStaffProfile';
import FormUploadAvatar from 'components/form/FormUploadAvatar';
import BasicInformationForm from 'containers/Centres/components/CentreTabs/EducatorStaffProfilesTab/Create/Steps/BasicInformation/BasicInformationForm';
import { isNil, omitBy } from 'lodash-es';

interface Props {
  staffProfile: StaffProfileModel;
  disable?: boolean;
}

const defaultUploadAvatarStyles = {
  marginBottom: 20,
  width: 100,
  height: 100,
  borderRadius: '100%',
  textAlign: 'center',
};

const BasicInfoDetail = ({ staffProfile, disable }: Props) => {
  const [form] = Form.useForm();
  const { basicInfo, id, centreId } = staffProfile;
  const staffProfileName = basicInfo.lastName
    ? `${basicInfo.firstName} ${basicInfo.lastName}`
    : basicInfo.firstName;
  const [isVisibleEditModal, setIsVisibleEditModal] = useState(false);
  const [editStaffProfiles] = useEditStaffProfilesMutation();

  const handleEditBasicInformation = () => {
    setIsVisibleEditModal(true);
  };

  const handleCloseEditModal = () => {
    setIsVisibleEditModal(false);
    form.resetFields();
  };

  const handleChangeAvatar = (field, value) => {
    const staffPayload = {
      id,
      centreId,
      basicInfo: omitBy(
        {
          ...basicInfo,
          avatar: value,
          userId: basicInfo.userId ?? null,
        },
        isNil,
      ),
    };
    editStaffProfiles(staffPayload)
      .unwrap()
      .then((response) => {
        form.setFieldsValue(response.raw?.[0]?.basicInfo);
      });
  };

  const handleSubmitEditModal = () => {
    form.validateFields().then((values) => {
      const [jobRole, jobRankId] = values?.jobRole?.split('-');
      const staffPayload = {
        id,
        centreId,
        basicInfo: omitBy(
          {
            ...values,
            jobRole,
            jobRankId: jobRankId ? Number(jobRankId) : values?.jobRankId,
            userId: basicInfo.userId ?? null,
          },
          isNil,
        ),
      };

      editStaffProfiles(staffPayload)
        .unwrap()
        .then((response) => {
          setIsVisibleEditModal(false);
          form.setFieldsValue(response.raw?.[0]?.basicInfo);
        });
    });
  };

  return (
    <div className="bg-white p-24 shadow rounded-16 relative flex justify-between flex-row gap-24">
      <div className="flex-center">
        <div className="w-100 h-100">
          <Form form={form}>
            <Form.Item shouldUpdate>
              {({ getFieldValue }) => (
                <FormUploadAvatar
                  disabled={disable}
                  source="avatar"
                  defaultValue={
                    basicInfo.avatar ||
                    getFieldValue('avatar') || (
                      <div className="bg-neutral-100 w-full h-full rounded-full flex-center text-32 text-neutral-400">
                        <AvatarPlaceholderIcon />
                      </div>
                    )
                  }
                  cropDimension={{ width: 300, height: 300 }}
                  hasCrop
                  style={defaultUploadAvatarStyles}
                  onChange={handleChangeAvatar}
                />
              )}
            </Form.Item>
          </Form>
        </div>

        <div className="flex flex-col gap-10 justify-start ml-20">
          <div className="text-24 text-primary fw-bold h-32">
            {staffProfileName}
          </div>
          <RowItem
            Icon={PersonFillIcon}
            value={basicInfo.jobTitle}
            tooltip={i18next.t('educatorStaffProfile.tooltip.jobTitle')}
          />
          <RowItem
            Icon={BriefCaseOutLineIcon}
            value={i18next.t('educatorStaffProfile.yearOfExp', {
              year: basicInfo.experience,
            })}
            tooltip={i18next.t('educatorStaffProfile.tooltip.yearOfExp')}
          />
        </div>
      </div>

      <EditCustomBtn
        disabled={disable}
        onClickButton={handleEditBasicInformation}
      />

      <ModalCustom
        title={i18next.t('educatorStaffProfile.profile')}
        visible={isVisibleEditModal}
        onCancel={handleCloseEditModal}
        onOk={handleSubmitEditModal}
        width={1000}
      >
        <BasicInformationForm form={form} initialValues={basicInfo || {}} />
      </ModalCustom>
    </div>
  );
};

export default BasicInfoDetail;

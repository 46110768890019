import React from 'react';
import { Button } from 'antd';
import { PlusOutlined, CrownFilled } from '@ant-design/icons';

import { upgradeModal } from 'components/common/CheckPermissions/UpgradeSubscriptionModal';

const FallbackBtn = () => {
  const onClickButton = () => {
    upgradeModal.open();
  };

  return (
    <div className="create-action-wrapper">
      <Button
        className="add-button"
        type="primary"
        shape="circle"
        icon=<PlusOutlined />
        onClick={onClickButton}
      />
      <CrownFilled className="action-feature-icon" />
    </div>
  );
};

export default FallbackBtn;

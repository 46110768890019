import styled from 'styled-components';
import { Space } from 'antd';
import { Responsive } from 'types';

interface CardSpaceStyleProps {
  gap?: string;
  columns?: Responsive;
}

export const CardSpaceStyle = styled(Space)<CardSpaceStyleProps>`
  --gap: ${({ gap }) => gap || '18px'};
  --columns: ${({ columns }) => columns.xs || 1};
  --width: calc(
    (100% / var(--columns)) - var(--gap) + (var(--gap) / var(--columns))
  );

  .ant-space-item {
    flex-grow: 1;
    flex-basis: var(--width);
    max-width: var(--width);
  }

  .card-item-container {
    min-width: var(--width);
  }

  @media only screen and (min-width: 481px) {
    --columns: ${({ columns }) => columns.sm || 1};
  }
  @media only screen and (min-width: 577px) {
    --columns: ${({ columns }) => columns.md || 2};
  }
  @media only screen and (min-width: 769px) {
    --columns: ${({ columns }) => columns.lg || 3};
  }
  @media only screen and (min-width: 993px) {
    --columns: ${({ columns }) => columns.xl || 4};
  }
  @media only screen and (min-width: 1201px) {
    --columns: ${({ columns }) => columns.xxl || 5};
  }
`;

import React from 'react';
import { CrownFilled } from '@ant-design/icons';
import { Button } from 'antd';

import { EditIcon } from 'components/common/SVGIcon';
import { upgradeModal } from 'components/common/CheckPermissions/UpgradeSubscriptionModal';

const EditBtnFallback = () => {
  const onClickButton = () => {
    upgradeModal.open();
  };
  return (
    <div className="edit-action-wrapper">
      <Button
        onClick={onClickButton}
        type="primary"
        shape="circle"
        icon={<EditIcon />}
      />
      <CrownFilled className="action-feature-icon" />
    </div>
  );
};

export default EditBtnFallback;

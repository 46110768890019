import { createSelector } from '@reduxjs/toolkit';

const getRawJobApplicationsMailbox = (state) => state.jobApplicationMailbox;

const getRawJobApplicationMailboxLoading = (state) =>
  state.jobApplicationMailbox?.loading;

export const getDataJobApplicationMailbox = createSelector(
  [getRawJobApplicationsMailbox],
  ({ data } = {}) => data,
);

export const getDataJobApplicationMailboxPaging = createSelector(
  [getRawJobApplicationsMailbox],
  ({ paging } = {}) => paging,
);

export const getLoadingJobApplicationMailbox = createSelector(
  [getRawJobApplicationMailboxLoading],
  (data) => data,
);

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash-es';
import { Upload, Avatar, Form, Input, notification, Spin } from 'antd';
import I18n from 'i18next';
import { CameraOutlined } from '@ant-design/icons';
import { connect } from 'react-redux';
import { logoutSuccess } from 'redux/auth/slice';

import AvatarCropperModal from './AvatarCropperModal';
import UploadImageWrapper from './style';
import user from '../../../assets/images/user.png';
import { getUrl, uploadMedia } from '../../../api/uploadMedia';

const uploadUrl = `${process.env.REACT_APP_BASE_API_URL}/api/v1/uploadFile`;
const FormItem = Form.Item;
class UploadImage extends Component {
  static getDerivedStateFromProps(nextProps, prevState) {
    const restSource = nextProps.customSource || nextProps.source;
    if (get(nextProps.record, restSource) !== prevState.prevRecordImgSource) {
      return {
        prevRecordImgSource: get(nextProps.record, restSource),
        imgDisplay: get(nextProps.record, restSource) || nextProps.defaultValue,
        defaultValueAvatar:
          get(nextProps.record, nextProps.source) || nextProps.defaultValue,
      };
    }
    return {};
  }

  constructor(props) {
    super(props);
    const restSource = props.customSource || props.source;
    this.state = {
      file: null,
      prevRecordImgSource: get(this.props.record, restSource) || undefined,
      imgDisplay: get(this.props.record, restSource) || undefined,
      loading: false,
      isShowCropperModal: false,
      hasErr: false,
    };
  }

  onUnload = (e) => {
    if (this.state.imgDisplay && this.props.form) {
      localStorage.setItem('url', this.state.imgDisplay);
      // eslint-disable-next-line
      e.returnValue = '';
    }
  };

  onHideCropperModal = () => {
    this.setState({
      isShowCropperModal: false,
    });
  };

  showNotiError = (description) => {
    notification.error({
      message: I18n.t('error.title'),
      description: I18n.t(description),
      position: 'tr',
    });
  };

  onChangePreview = async ({ croppedFile }) => {
    try {
      const { onChange, source, form, isValueObject } = this.props;
      if (this.state.imgDisplay) {
        this.onRemove(this.state.imgDisplay);
      }
      this.setState({
        isShowCropperModal: false,
        loading: true,
      });

      const responseS3 = await getUrl(croppedFile.name, croppedFile.type);
      if (responseS3) {
        try {
          const rest = await uploadMedia(responseS3.uploadUrl, croppedFile);
          if (rest?.status === 200) {
            this.setState({
              imgDisplay: responseS3.url,
              loading: false,
              hasErr: false,
            });
            onChange && onChange(source, responseS3.url);
            form &&
              form.setFieldsValue({
                [source]: !isValueObject
                  ? responseS3.url
                  : { url: responseS3.url, key: croppedFile.name },
              });
          } else {
            this.setState({
              loading: false,
              hasErr: true,
            });
            this.showNotiError('error.errorUploadFile');
          }
          return responseS3;
        } catch (error) {
          this.showNotiError('error.errorUploadFile');
          return error;
        }
      }
      return responseS3;
    } catch (error) {
      if (error.code === 401) {
        this.props.logoutSuccess();
        this.showNotiError('error.error401');
      } else {
        this.showNotiError('error.description');
      }
      this.setState({
        file: null,
        imgDisplay: null,
        loading: false,
        hasErr: true,
      });
      return error;
    }
  };

  onRemove = () => {
    this.setState({
      file: null,
      imgDisplay: null,
      loading: false,
    });
    localStorage.removeItem('url');
  };

  renderImage() {
    const { style, defaultText, defaultIcon, defaultValue } = this.props;
    const { loading, imgDisplay, hasErr } = this.state;
    if (loading) {
      return (
        <Avatar style={style}>
          <Spin />
          <div className="ant-upload-text">Uploading....</div>
        </Avatar>
      );
    }
    if (!imgDisplay) {
      return (
        <Avatar icon={defaultIcon} src={defaultValue || user} style={style}>
          <span className="default-image">{defaultText}</span>
        </Avatar>
      );
    }

    if (!hasErr) {
      return <Avatar src={imgDisplay} style={style} />;
    }

    return (
      <Avatar src={imgDisplay} style={style}>
        <div className="ant-upload-text">Upload Failed</div>
      </Avatar>
    );
  }

  render() {
    const { hasCrop, source, style, className, label, cropDimension, header } =
      this.props;
    const props = {
      showUploadList: false,
      action: uploadUrl,
      disabled: this.props.disabled,
      beforeUpload: (file) => {
        this.setState(() => ({
          file,
          isShowCropperModal: hasCrop,
        }));
        if (!hasCrop) {
          this.onChangePreview({ croppedFile: file });
        }
        return false;
      },
    };

    return (
      <UploadImageWrapper className={className}>
        <FormItem label={label}>
          <Form.Item name={source}>
            <Input style={{ display: 'none' }} />
          </Form.Item>
          <Upload {...props} accept="image/*" id={this.props?.idFor ?? ''}>
            <div className="image-uploader">
              {this.renderImage()}
              <div className="image-hover" style={style}>
                <CameraOutlined className="image-hover-icon" />
              </div>
            </div>
          </Upload>
          <AvatarCropperModal
            cropDimension={cropDimension}
            isShowModal={this.state.isShowCropperModal}
            onHideModal={this.onHideCropperModal}
            onChangePreview={this.onChangePreview}
            image={this.state.file}
          />
        </FormItem>
        {header && <div className="header">{I18n.t(header)}</div>}
      </UploadImageWrapper>
    );
  }
}

UploadImage.propTypes = {
  showErrorMsg: PropTypes.func,
  form: PropTypes.object,
  source: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  record: PropTypes.object,
  style: PropTypes.object,
  defaultText: PropTypes.string,
  defaultIcon: PropTypes.string,
  onUploadImage: PropTypes.func,
  className: PropTypes.string,
  cropDimension: PropTypes.object,
  hasCrop: PropTypes.bool,
  label: PropTypes.string,
  defaultValue: PropTypes.any,
  onChange: PropTypes.func,
  header: PropTypes.string,
  logoutSuccess: PropTypes.func,
  isValueObject: PropTypes.bool,
  customSource: PropTypes.string,
  idFor: PropTypes.string,
  disabled: PropTypes.bool,
};

UploadImage.defaultProps = {
  hasCrop: true,
  disabled: false,
};

export default connect(null, (dispatch) => ({
  logoutSuccess: (error) => {
    dispatch(logoutSuccess(error));
  },
}))(UploadImage);

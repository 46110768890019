import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Avatar, Divider } from 'antd';
import i18next from 'i18next';
import { getDisplayAddress } from 'utils/dataUtils';
import { formatDate } from 'utils/textUtils';

import { EnvironmentOutlined, UserOutlined } from '@ant-design/icons';
import { PhoneIcon } from 'components/common/SVGIcon';

import ChildrenItem from './ChildrenItem';
import { ContactItemStyles } from './styles';

const ContactItem = ({ item, pathname }) => (
  <ContactItemStyles className="contact-item">
    <Link to={`${pathname}/${item.id}/show`}>
      <div>
        <div className="contact-item__activity-time">
          {`${i18next.t('common.lastActive')} ${
            formatDate(item.lastActiveAt, 'DD MMM, YYYY [at] HH:mm') || ''
          }`}
        </div>
        <div className="contact-item__user">
          <Avatar size={45} src={item.avatar} alt="" icon={<UserOutlined />} />

          <div className="contact-item__user-info">
            <span className="name-user">{item?.fullName}</span>
            <div className="note">
              {`${item?.additionalParentInfo?.firstName || ''} ${
                item?.additionalParentInfo?.lastName || ''
              }`}
            </div>
          </div>
        </div>

        <div className="contact-item__info">
          <div className="item-info">
            <PhoneIcon />
            <span className="item-info__value">
              {item?.phone || i18next.t('error.waitingUpdate')}
            </span>
          </div>

          <div className="item-info">
            <EnvironmentOutlined />
            <span className="item-info__value">
              {getDisplayAddress(item) || i18next.t('error.waitingUpdate')}
            </span>
          </div>
        </div>

        <Divider />

        <div className="contact-item__children">
          {item?.children?.map((item, idx) => (
            <ChildrenItem key={String(idx)} child={item} />
          ))}
        </div>
      </div>
    </Link>
  </ContactItemStyles>
);

ContactItem.propTypes = {
  item: PropTypes.object,
  pathname: PropTypes.string,
};

export default ContactItem;

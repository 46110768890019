import React from 'react';
import { Radio, RadioChangeEvent } from 'antd';
import i18next from 'i18next';
import { formatMoney } from 'utils/tools';
import { VIEW_MODES } from 'configs/localData';
import { isNumber } from 'lodash-es';
import ViewModeSwitcherStyles from './styles';

interface Props {
  viewModes: Record<
    string,
    {
      title: string;
      key: string;
      Icon: (props: any) => JSX.Element;
    }
  >;
  onChange: (e: RadioChangeEvent) => void;
  activeTab: string;
  countPerTab?: Record<string, number>;
}

const ViewModeSwitcher = ({
  viewModes = VIEW_MODES,
  onChange,
  activeTab,
  countPerTab,
}: Props) => (
  <ViewModeSwitcherStyles>
    <Radio.Group
      onChange={onChange}
      value={activeTab}
      className="group-tab mb-16"
    >
      {Object.values(viewModes).map((tab) => (
        <Radio.Button value={tab.key} key={tab.key}>
          <div className="title-tab">
            <tab.Icon className="icon-tab" />
            {i18next.t(tab.title)}
            {isNumber(countPerTab?.[tab.key])
              ? ` (${formatMoney(countPerTab[tab.key])})`
              : null}
          </div>
        </Radio.Button>
      ))}
    </Radio.Group>
  </ViewModeSwitcherStyles>
);

export default ViewModeSwitcher;

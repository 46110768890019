import React, { useEffect, useMemo, useState } from 'react';
import i18next from 'i18next';
import { useSelector } from 'react-redux';
import { formatDateApplication } from 'utils/textUtils';
import { formatApplicationStatus } from 'utils/formatFieldUtils';

import CardSection from 'components/common/CardSection';
import TableCustom from 'components/common/TableCustom';
import { Link } from 'react-router-dom';
import { useGetApplicationsQuery } from 'redux/@rtkQuery/contacts';
import { getCentreId } from 'redux/auth/selectors';
import { DEFAULT_CURRENT_PAGE } from 'containers/MarketingMetrics/constants';
import { SORT_DESCEND_VALUE } from 'configs/localData/constant';
import { DefaultFilter } from 'redux/@rtkQuery/contacts/types';
import { useParams } from 'react-router';
import {
  DEFAULT_LIMIT_ITEM,
  PAGE_SIZE_OPTIONS,
} from 'containers/Contacts/constants';
import GroupActionApplication from './GroupActionApplication';

const DEFAULT_SORT_VALUE = '-submittedAt';

const DEFAULT_FILTER: DefaultFilter = {
  sorts: [DEFAULT_SORT_VALUE],
  size: DEFAULT_LIMIT_ITEM,
  page: DEFAULT_CURRENT_PAGE,
};
const ApplicationsTable = () => {
  const centreId = useSelector(getCentreId);
  const [filterValue, setFilterValue] = useState(DEFAULT_FILTER);

  const { id } = useParams();

  const { data, isLoading, refetch, isFetching } = useGetApplicationsQuery({
    contactId: id,
    ...filterValue,
  });

  const columns = useMemo(
    () => [
      {
        title: i18next.t('centres.centreName'),
        dataIndex: 'centreName',
        key: 'centreName',
        sorter: true,
        render: (data, record) => (
          <Link
            className="text-black"
            to={`/centres/${record.centreId}/show/summary`}
          >
            {data || ''}
          </Link>
        ),
      },
      {
        title: i18next.t('applications.application'),
        dataIndex: 'childFirstName',
        render: (data, record) => (
          <Link to={`/applications/${record.id}/show`}>
            {`${data || ''} ${record.childLastName || ''}`}
          </Link>
        ),
      },
      {
        title: i18next.t('applications.appliedAt'),
        dataIndex: 'submittedAt',
        key: 'submittedAt',
        render: formatDateApplication,
        width: 140,
        sorter: true,
      },
      {
        title: i18next.t('applications.status'),
        dataIndex: 'status',
        key: 'status',
        render: formatApplicationStatus,
        width: 130,
      },
      {
        fixed: 'right',
        width: 80,
        dataIndex: 'id',
        key: 'id',
        render: (id) => <GroupActionApplication id={id} />,
      },
    ],
    [],
  );

  useEffect(() => {
    refetch();
  }, [centreId, refetch]);

  const onChange = (pagination, filters, sorter) => {
    const formatSort =
      sorter && sorter.columnKey && sorter.order
        ? `${sorter.order === SORT_DESCEND_VALUE ? '-' : ''}${sorter.columnKey}`
        : null;

    setFilterValue({
      sorts: [formatSort || DEFAULT_SORT_VALUE],
      size: pagination?.pageSize,
      page: pagination?.current,
    });
  };

  return (
    <CardSection title="applications.header">
      <TableCustom
        pagination={{
          pageSize: data?.paging?.size || DEFAULT_LIMIT_ITEM,
          current: data?.paging?.page || DEFAULT_CURRENT_PAGE,
          total: data?.paging?.total || 0,
          pageSizeOptions: PAGE_SIZE_OPTIONS,
        }}
        isResetStyle
        xScroll={600}
        data={data?.results || []}
        columns={columns}
        loading={isLoading || isFetching}
        onChange={onChange}
      />
    </CardSection>
  );
};

export default ApplicationsTable;

import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import i18next from 'i18next';
import { useSelector, useDispatch } from 'react-redux';
import { centresSelectors } from 'redux/centres/selectors';
import { getAllCentres } from 'redux/centres/actions';
import { getSearch, getFilterFromUrl } from 'utils/tools';
import { getCentreId } from 'redux/auth/selectors';

import PageTitle from 'components/common/PageTitle';
import Summaries from '../components/Summaries';
import HeaderFilter from './HeaderFilter';
import CentresListCustom from '../components/CentresListCustom';
import CentresListTable from '../components/CentresListTable';
import ListStyles from './styles';

const CentresList = (props) => {
  const { match, history, location } = props;

  const activeTab = match?.params?.model || 'board';

  const dispatch = useDispatch();

  const centreId = useSelector(getCentreId);

  const retrieveList = useCallback(({ filter = {}, isRefresh }) => {
    dispatch(
      getAllCentres({
        data: {
          ...filter,
          filter: {
            ...filter.filter,
          },
          // orderBy: '-CRMPackage_join.updatedAt',
        },
        options: { isRefresh, customApiResource: 'users/me/centres' },
      }),
    );
  }, []); // eslint-disable-line

  useEffect(() => {
    const defaultFilter = getFilterFromUrl(location.search) || {};

    retrieveList({
      filter: {
        ...defaultFilter,
        limit: 10,
        offset: 0,
        orderBy: '-updatedAt',
      },
      isRefresh: true,
    });
  }, [centreId, activeTab]); // eslint-disable-line

  const resourceData = useSelector(centresSelectors.getDataArr);

  const pushQuery = useCallback(
    (searchStr) => {
      history.push(`${location.pathname}?${getSearch(searchStr)}`);
    },
    [location.pathname], // eslint-disable-line
  );

  return (
    <ListStyles>
      <PageTitle>{i18next.t('centres.header')}</PageTitle>
      <Summaries />
      <HeaderFilter
        activeTab={activeTab}
        history={history}
        pushQuery={pushQuery}
        retrieveList={retrieveList}
      />
      <div className="container-wrapper-centre">
        <div className={`${activeTab === 'board' ? '' : 'hidden-tab'} `}>
          <CentresListCustom
            resourceData={resourceData}
            retrieveList={retrieveList}
          />
        </div>
        <div className={`${activeTab === 'list' ? '' : 'hidden-tab'} `}>
          <CentresListTable
            resourceData={resourceData}
            retrieveList={retrieveList}
            pushQuery={pushQuery}
          />
        </div>
      </div>
    </ListStyles>
  );
};

CentresList.propTypes = {
  match: PropTypes.object,
  history: PropTypes.object,
  location: PropTypes.object,
};

export default CentresList;

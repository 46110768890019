import styled from 'styled-components';

const FilterCustomStyles = styled.div`
  margin-bottom: 10px;
  .row-filter {
    display: flex;
    align-items: stretch;
    flex-wrap: wrap;
    .ant-form-item-control-input-content > input,
    .ant-select-selector,
    .ant-picker,
    .ant-input-affix-wrapper {
      border: 1px solid transparent;
      :hover,
      :focus {
        border: 1px solid ${({ theme }) => theme.palette.primary};
      }
    }
    .ant-input-prefix .anticon {
      color: ${({ theme }) => theme.text.mainL3};
    }
    .ant-form-item-label {
      display: none;
    }
    .ant-input-number,
    .ant-picker {
      width: 100%;
    }
    .ant-select-selection__rendered {
      height: 32px;
    }
    .ant-form-item-control {
      line-height: 32px;
    }
  }

  &.grey-input-filter {
    .ant-form-item-control-input-content > input,
    .ant-select-selector,
    .ant-picker,
    .ant-input-affix-wrapper,
    .ant-input-affix-wrapper input {
      background: #edf1f6;
    }
    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
      -webkit-box-shadow: 0 0 0 30px #edf1f6 inset !important;
    }
  }
  .clearButton:not(:disabled) {
    background: ${({ theme }) => theme.background.content};
    color: ${({ theme }) => theme.palette.primary};
    border: 1px solid ${({ theme }) => theme.palette.primary};
    box-sizing: border-box;
  }
  .row-action-bottom {
    display: flex;
    button {
      width: 50%;
    }
    .filterButton {
      margin-right: 16px;
    }
  }
  .ant-btn {
    border-radius: 12px;
  }
`;

export default FilterCustomStyles;

import { uniqBy } from 'lodash-es';
import { Link } from 'react-router-dom';
import {
  FallOutlined,
  RiseOutlined,
  WomanOutlined,
  ManOutlined,
  KeyOutlined,
  FilePdfOutlined,
  FilePptOutlined,
  FileWordOutlined,
  FileExcelOutlined,
  FileZipOutlined,
  FileTextOutlined,
  VideoCameraOutlined,
  FileDoneOutlined,
  StopOutlined,
  AudioOutlined,
  CheckCircleFilled,
  CloseCircleFilled,
  SyncOutlined,
  FileImageOutlined,
  InstagramFilled,
} from '@ant-design/icons';
import {
  FacebookIcon,
  PharmacyIcon,
  PreparingIcon,
  SendingIcon,
  DeliveryIcon,
  IncomingCallIcon,
  OutgoingCallIcon,
  EmailIcon,
  NoteIcon,
  VisitTourIcon,
  InterviewIcon,
  OtherIcon,
  LinkedinIcon,
  FileFillIcon,
  TotalMoreInfoIcon,
  TotalCheckVacanciesIcon,
  FeeInformationIcon,
  BookTourIcon,
  YoutubeFillIcon,
  LayoutBoardIcon,
  LayoutListIcon,
} from 'components/common/SVGIcon';
import CentreInformationModal from 'containers/Centres/components/Modals/CentreDetailInfoModal';
import CentrePhotoModal from 'containers/Centres/components/Modals/CentrePhotoModal';
import HoursTab from 'containers/Centres/components/CentreTabs/HoursTab';
import ServicesTab from 'containers/Centres/components/CentreTabs/ServicesTab/index-1';
import FeaturesTab from 'containers/Centres/components/CentreTabs/FeaturesTab';
import RatingsReviewsTab from 'containers/Centres/components/CentreTabs/RatingsReviewsTab';
import UploadBusinessLogoModal from 'containers/Centres/components/Modals/UploadBusinessLogoModal';
import MoreCentreInfoModal from 'containers/Centres/components/Modals/MoreCentreInfoModal';
import DescriptionModal from 'containers/Centres/components/Modals/DescriptionModal';
import theme from '../theme/index';
import commonJobs from './commonJobs';
import nationalities from './nationalities';

export const FORMAT_DATE = 'MMM DD, YYYY';

export const SOCIAL_LINKS = [
  {
    key: '1',
    Icon: FacebookIcon,
    href: 'https://www.facebook.com/KindiCare/',
    className: 'primary-social-icon',
  },
  {
    key: '2',
    Icon: InstagramFilled,
    href: 'https://instagram.com/kindicare?igshid=1vm6dap165ubv',
  },
  {
    key: '3',
    Icon: LinkedinIcon,
    href: 'https://www.linkedin.com/company/kindicare',
  },
  {
    key: '4',
    Icon: YoutubeFillIcon,
    href: 'https://www.youtube.com/channel/UC3VIhG7Gp_njmCPW6CVOavA',
  },
];

export const FOOTERS = [
  [
    {
      text: 'centres.footer.contact',
      Component: 'div',
    },
    {
      text: 'centres.footer.email',
      className: 'active-link',
      href: 'mailto:enquiries@kindicare.com',
      Component: 'a',
    },
  ],
  [
    {
      text: 'centres.footer.home',
      to: '/',
      Component: Link,
    },
    {
      text: 'centres.footer.aboutUs',
      to: '/claim-centres',
      Component: Link,
    },
  ],
  [
    {
      text: 'centres.footer.privacyPolicy',
      to: '/claim-centres',
      Component: Link,
    },
    {
      text: 'centres.footer.termCondition',
      to: '/claim-centres',
      Component: Link,
    },
  ],
  [
    {
      text: 'centres.footer.forChildcareProvider',
      to: '/claim-centres',
      Component: Link,
    },
    {
      text: 'centres.footer.forParentGuardian',
      to: '/claim-centres',
      Component: Link,
    },
  ],
];

export const CLAIM_STATUSES = [
  {
    value: [null],
    buttonText: 'button.claim',
    isClaim: true,
    titleModal: 'centres.claimModalTitle',
    successText: 'centres.successClaimMessage',
  },
  {
    value: ['PENDING', 'CLAIMED'],
    buttonText: 'button.dispute',
    titleModal: 'centres.disputeModalTitle',
    isClaim: false,
    successText: 'centres.successDisputeMessage',
  },
];

export const FEATURES = [
  'centres.dashboard',
  'centres.contacts',
  'centres.centres',
  'centres.transactions',
  'centres.enquiries',
  'centres.reports',
  'centres.visitsTours',
  'centres.marketing',
  'centres.applications',
];

export const PERMISSION = [
  {
    value: 'PUT',
    text: 'permission.put',
  },
  {
    value: 'CREATE',
    text: 'permission.create',
  },
  {
    value: 'READ',
    text: 'permission.read',
  },
  {
    value: 'DELETE',
    text: 'permission.delete',
  },
  {
    value: 'ADDCLASS',
    text: 'permission.addClass',
  },
  {
    value: 'REMOVECLASS',
    text: 'permission.removeClass',
  },
  {
    value: 'GETLIST',
    text: 'permission.getList',
  },
  {
    value: 'LIST_PERMISSION',
    text: 'permission.listPermission',
  },
];

export const SEMESTERS = [
  {
    value: true,
    text: 'semesters.isDone',
    color: 'gray',
  },
  {
    value: false,
    text: 'semesters.notDone',
    color: 'green',
  },
];

export const CHART_LINES = [
  {
    value: 'sales',
    dataKey: 'sales',
    text: 'home.chart.sales',
    stroke: '#f83995',
    fillId: 'salesFillColor',
    fillColor: '#f83995',
  },
  {
    value: 'profit',
    dataKey: 'profit',
    text: 'home.chart.profit',
    stroke: '#4d79f6',
    fillId: 'profitFillColor',
    fillColor: '#4d79f6',
  },
];

export const COUNTRY = [
  {
    value: 'buenosAires',
    name: 'home.country.buenosAires',
    color: '#F93B7A',
  },
  {
    value: 'brasilia',
    name: 'home.country.brasilia',
    color: '#0AAFFF',
  },
  {
    value: 'santiago',
    name: 'home.country.santiago',
    color: '#FFC212',
  },
  {
    value: 'bogota',
    name: 'home.country.bogota',
    color: '#7551E9',
  },
];

export const FINANCES = [
  {
    value: 'tuition',
    text: 'finances.tuition',
  },
  {
    value: 'mealFee',
    text: 'finances.mealFee',
  },
  {
    value: 'subClass',
    text: 'finances.subClass',
  },
];

export const GENDER = [
  {
    value: 'male',
    text: 'gender.male',
  },
  {
    value: 'female',
    text: 'gender.female',
  },
];

export const SUBJECTS = [
  {
    value: 'main',
    text: 'subjects.typeMain',
    color: '#56ac00',
  },
  {
    value: 'sub',
    text: 'subjects.typeSub',
    color: '#f8961d',
  },
];

export const STATUS = [
  {
    value: 1,
    text: 'status.active',
    color: theme.color.blueSecondary,
  },
  {
    value: 0,
    text: 'status.inactive',
    color: theme.color.red,
  },
];

export const PAYMENT_STATUS = [
  {
    value: false,
    text: 'Not paid',
    textColor: theme.color.red,
    icon: 'close-circle',
  },
  {
    value: true,
    text: 'Paid',
    textColor: theme.color.green,
    icon: 'check-circle',
  },
];

export const QUOTE_STATUS = [
  {
    value: 'REJECTED',
    text: 'Rejected',
    color: theme.color.red,
    textColor: '#fff',
  },
  {
    value: 'ACCEPTED',
    text: 'Accepted',
    color: theme.color.green,
    textColor: '#fff',
  },
  {
    value: 'CANCELLED',
    text: 'Cancelled',
    color: theme.color.red,
    textColor: '#fff',
  },
];

export const ORDER_STATUS = [
  {
    value: 'OPEN',
    text: 'Open',
    color: theme.color.yellow,
    icon: 'folder-open',
    textColor: 'white',
  },
  {
    value: 'PROCESSING',
    text: 'Processing',
    color: theme.color.green,
    icon: 'interaction',
    textColor: 'white',
  },
  {
    value: 'ONDELIVERY',
    text: 'On Delivery',
    color: theme.color.green,
    icon: 'car',
    textColor: 'white',
  },
  {
    value: 'DELIVERED',
    text: 'Delivered',
    color: theme.color.blue,
    icon: 'home',
    textColor: 'white',
  },
  {
    value: 'COMPLETE',
    text: 'Complete',
    color: theme.color.blue,
    icon: 'check-circle',
    textColor: 'white',
  },
  {
    value: 'CANCELLED',
    text: 'Cancelled',
    color: theme.color.red,
    icon: 'close-circle',
    textColor: 'white',
  },
  {
    value: 'WAITING_FOR_QUOTE',
    text: 'Waiting for Quote',
    color: theme.color.orange,
    icon: 'check-circle',
    textColor: 'white',
  },
];

export const PRODUCT_STATUS = [
  {
    value: 'inprogress',
    text: 'status.inprogress',
    color: theme.color.green,
  },
  {
    value: 'pending',
    text: 'status.pending',
    color: theme.color.orange,
  },
  {
    value: 'completed',
    text: 'status.completed',
    color: theme.color.blue,
  },
  {
    value: 'developing',
    text: 'status.developing',
    color: theme.color.green,
  },
];

export const ACTIVITIES_LOG = [
  {
    message:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Convallis vulputate justo ornare feugiat.',
  },
  {
    message:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Convallis vulputate justo ornare feugiat.',
  },
  {
    message:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Convallis vulputate justo ornare feugiat.',
  },
  {
    message:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Convallis vulputate justo ornare feugiat.',
  },
];

export const USER_STATUS = [
  {
    id: 1,
    data: 'true',
    text: {
      en: 'Locked',
      vi: 'Khoá',
    },
  },
  {
    id: 1,
    data: 'false',
    text: {
      en: 'Unlock',
      vi: 'Không Khoá',
    },
  },
];

export const MEMBER_STATUS = [
  {
    id: 1,
    data: 'false',
    text: {
      en: 'Locked',
      vi: 'Khoá',
    },
  },
  {
    id: 1,
    data: 'true',
    text: {
      en: 'Unlock',
      vi: 'Không Khoá',
    },
  },
];

export const ACTIVE_TYPES = [
  {
    id: 1,
    value: true,
    text: 'isActive.active',
    color: theme.color.blueSecondary,
  },
  {
    id: 2,
    value: false,
    text: 'isActive.inactive',
    color: theme.color.red,
  },
];

export const ACTIVE_USER = [
  {
    id: 1,
    value: 'false',
    isDisabled: false,
    isActive: true,
    text: 'isActive.active',
    color: theme.color.blueSecondary,
  },
  {
    id: 2,
    value: 'true',
    isDisabled: true,
    isActive: false,
    text: 'isActive.inactive',
    color: theme.color.red,
  },
];

export const BLOCK_USER_TYPES = [
  {
    value: true,
    text: 'blockUserTypes.blocked',
    color: theme.color.red,
  },
  {
    value: false,
    text: 'blockUserTypes.unblock',
    color: theme.color.blueSecondary,
  },
];

export const DISABLE_TYPES = [
  {
    value: false,
    text: 'isDisabled.notDisabled',
  },
  {
    value: true,
    text: 'isDisabled.disabled',
  },
];

export const MEMBER_ROLES = [
  {
    id: 1,
    text: {
      en: 'Admin',
      vi: 'Admin',
    },
  },
  {
    id: 2,
    text: {
      en: 'Staff',
      vi: 'Nhân viên',
    },
  },
  {
    id: 3,
    text: {
      en: 'Member',
      vi: 'Khách hàng',
    },
  },
];

export const LANGUAGES = [
  {
    id: 'en',
    text: {
      en: 'English',
      vi: 'Tiếng Anh',
    },
  },
  {
    id: 'vi',
    text: {
      en: 'Vietnamese',
      vi: 'Tiếng Việt',
    },
  },
];

export const GENDERS = [
  {
    value: 'male',
    text: 'gender.male',
    textChild: 'genderChild.male',
    color: '#32A4FC',
    icon: ManOutlined,
    background: '#E4EDFF',
    transform: 'none',
  },
  {
    value: 'female',
    text: 'gender.female',
    textChild: 'genderChild.female',
    color: '#DB147F',
    icon: WomanOutlined,
    background: '#FFE4F9',
    transform: 'rotate(-27.29deg)',
  },
  {
    value: 'other',
    text: 'gender.other',
    textChild: 'genderChild.other',
    color: '#a217f5',
    icon: KeyOutlined,
    background: '#f1e4f9',
  },
];

export const BOOKING_STATUS = [
  {
    id: 'INPROGRESS',
    value: 'INPROGRESS',
    text: 'status.inprogress',
    requestSuffixUrl: 'inprogress',
  },
  {
    id: 'COMPLETED',
    value: 'COMPLETED',
    text: 'status.completed',
    requestSuffixUrl: 'complete',
  },
  {
    id: 'CANCELLED',
    value: 'CANCELLED',
    text: 'status.cancelled',
    requestSuffixUrl: 'cancel',
  },
  { id: 'PENDING', value: 'PENDING', text: 'status.pending' },
];

export const HOME_TAB = [
  { id: 'todayBooking', title: 'Today' },
  { id: 'pendingBooking', title: 'Upcoming' },
];

export const MOMENT_CODE = {
  daily: 'd',
  hourly: 'h',
  weekly: 'w',
  monthly: 'M',
};

export const TRANSACTION_TYPE = [
  { value: 'INCOME', text: 'button.income' },
  { value: 'EXPENSE', text: 'button.expense' },
];

export const PACKAGE_TYPES_TIME_UNIT = {
  hourly: 'hour',
  daily: 'day',
  weekly: 'week',
  monthly: 'month',
};
export const BOOKINGS_TYPES = ['todayBooking', 'pendingBooking'];
export const CHECKIN_STATUS = [
  { text: 'checkinStatus.waitingCheckin', value: 'waitingCheckin' },
  { text: 'checkinStatus.waitingCheckout', value: 'waitingCheckout' },
  { text: 'checkinStatus.completed', value: 'completed' },
  { text: 'checkinStatus.all', value: 'all' },
  { text: 'checkinStatus.upcoming', value: 'upcoming' },
];
export const DISCOUNT_UNIT = [
  { text: '%', value: 'percent' },
  { text: 'VND', value: 'number' },
];
export const PAYMENT_METHOD = [
  { text: 'payType.paymentByCash', value: 'cash' },
  { text: 'payType.paymentByBank', value: 'bank' },
];

export const NOTIFICATIONS = [
  {
    value: 'pharmacy',
    text: 'notifications.pharmacy',
    icon: PharmacyIcon,
  },
  {
    value: 'delivery',
    text: 'notifications.delivery',
    icon: DeliveryIcon,
  },
  {
    value: 'prepare',
    text: 'notifications.prepare',
    icon: PreparingIcon,
  },
  {
    value: 'send',
    text: 'notifications.send',
    icon: SendingIcon,
  },
];

export const BILLING_UNIT = [
  { text: 'packages.hour', value: 'Hour' },
  { text: 'packages.month', value: 'Month' },
  { text: 'packages.week', value: 'Week' },
  { text: 'packages.day', value: 'Day' },
];

export const ROLE = [
  { text: 'role.superadmin', value: 'superadmin' },
  { text: 'role.admin', value: 'admin' },
  { text: 'role.user', value: 'user' },
];

export const JOBS = commonJobs;
export const NATIONALITIES = uniqBy(nationalities, 'nationality');

export const QUALIFICATIONS = [
  { text: 'qualifications.university', value: 'university' },
  { text: 'qualifications.colleges', value: 'colleges' },
];

export const OPEN_TIME = [
  {
    value: true,
    text: 'time.open',
    color: '#52c41a',
  },
  {
    value: false,
    text: 'time.closed',
    color: '#e64c38',
  },
];

export const SUMMARY_CARD_TYPES = [
  {
    value: 'up',
    text: 'summaryCard.up',
    icon: RiseOutlined,
    color: theme.color.green,
  },
  {
    value: 'down',
    text: 'summaryCard.down',
    icon: FallOutlined,
    color: theme.color.red,
  },
];

export const DETAIL_SUMMARY_CARD_TYPES = [
  {
    value: 'up',
    text: 'detailSummaryCard.up',
    icon: 'arrow-up',
    color: theme.color.green,
  },
  {
    value: 'down',
    text: 'detailSummaryCard.down',
    icon: 'arrow-down',
    color: theme.color.red,
  },
];

export const SUMMARIES_CUSTOMER = [
  {
    dataKey: 'newCustomer',
    color: '#0088FE',
    text: 'customers.newCustomer',
  },
  {
    dataKey: 'repeatedCustomer',
    color: '#00C49F',
    text: 'customers.repeatedCustomer',
  },
];

export const CONTRACT_TYPES = [
  { text: 'contractTypes.official', value: 'official' },
  { text: 'contractTypes.partTime', value: 'partTime' },
];

export const RATINGS = [
  {
    value: '1',
    text: '1 Star',
  },
  {
    value: '2',
    text: '2 Star',
  },
  {
    value: '3',
    text: '3 Star',
  },
  {
    value: '4',
    text: '4 Star',
  },
  {
    value: '5',
    text: '5 Star',
  },
];

export const SEASON_TYPE = [
  { value: 'schoolTerm', text: 'seasonType.schoolTerm' },
  { value: 'normal', text: 'seasonType.normal' },
  { value: 'holiday', text: 'seasonType.holiday' },
];

export const SCHOOL_TERM_MON_TO_FRI = {
  text: 'daysOfWeek.monToFri',
  value: 'monToFri_schoolTerm',
  data: [
    {
      value: 'monToFri_morning_schoolTerm',
      text: 'daysOfWeek.morning',
      valueMap: 'mon_morning_schoolTerm',
    },
    {
      value: 'monToFri_afternoon_schoolTerm',
      text: 'daysOfWeek.afternoon',
      valueMap: 'mon_afternoon_schoolTerm',
    },
  ],
};

export const SCHOOL_TERM_DAYS_OF_WEEKEND = [
  {
    value: 'sat_schoolTerm',
    text: 'daysOfWeek.sat',
    data: [
      {
        value: 'sat_morning_schoolTerm',
        text: 'daysOfWeek.morning',
      },
      {
        value: 'sat_afternoon_schoolTerm',
        text: 'daysOfWeek.afternoon',
      },
    ],
  },
  {
    value: 'sun_schoolTerm',
    text: 'daysOfWeek.sun',
    data: [
      {
        value: 'sun_morning_schoolTerm',
        text: 'daysOfWeek.morning',
      },
      {
        value: 'sun_afternoon_schoolTerm',
        text: 'daysOfWeek.afternoon',
      },
    ],
  },
];

export const SCHOOL_TERM_EXCEPT_WEEKEND = [
  {
    value: 'mon_schoolTerm',
    text: 'daysOfWeek.mon',
    data: [
      {
        value: 'mon_morning_schoolTerm',
        text: 'daysOfWeek.morning',
      },
      {
        value: 'mon_afternoon_schoolTerm',
        text: 'daysOfWeek.afternoon',
      },
    ],
  },
  {
    value: 'tue_schoolTerm',
    text: 'daysOfWeek.tue',
    data: [
      {
        value: 'tue_morning_schoolTerm',
        text: 'daysOfWeek.morning',
      },
      {
        value: 'tue_afternoon_schoolTerm',
        text: 'daysOfWeek.afternoon',
      },
    ],
  },
  {
    value: 'wed_schoolTerm',
    text: 'daysOfWeek.wed',
    data: [
      {
        value: 'wed_morning_schoolTerm',
        text: 'daysOfWeek.morning',
      },
      {
        value: 'wed_afternoon_schoolTerm',
        text: 'daysOfWeek.afternoon',
      },
    ],
  },
  {
    value: 'thu_schoolTerm',
    text: 'daysOfWeek.thu',
    data: [
      {
        value: 'thu_morning_schoolTerm',
        text: 'daysOfWeek.morning',
      },
      {
        value: 'thu_afternoon_schoolTerm',
        text: 'daysOfWeek.afternoon',
      },
    ],
  },
  {
    value: 'fri_schoolTerm',
    text: 'daysOfWeek.fri',
    data: [
      {
        value: 'fri_morning_schoolTerm',
        text: 'daysOfWeek.morning',
      },
      {
        value: 'fri_afternoon_schoolTerm',
        text: 'daysOfWeek.afternoon',
      },
    ],
  },
];

export const NORMAL_MON_TO_FRI = {
  value: 'monToFri_normal',
  text: 'daysOfWeek.monToFri',
  valueMap: 'mon_normal',
};

export const NORMAL_DAYS_OF_WEEKEND = [
  {
    value: 'sat_normal',
    text: 'daysOfWeek.sat',
  },
  {
    value: 'sun_normal',
    text: 'daysOfWeek.sun',
  },
];

export const NORMAL_EXCEPT_WEEKEND = [
  {
    value: 'mon_normal',
    text: 'daysOfWeek.mon',
  },
  {
    value: 'tue_normal',
    text: 'daysOfWeek.tue',
  },
  {
    value: 'wed_normal',
    text: 'daysOfWeek.wed',
  },
  {
    value: 'thu_normal',
    text: 'daysOfWeek.thu',
  },
  {
    value: 'fri_normal',
    text: 'daysOfWeek.fri',
  },
];

export const HOLIDAY_MON_TO_FRI = {
  value: 'monToFri_holiday',
  text: 'daysOfWeek.monToFri',
  valueMap: 'mon_holiday',
};

export const HOLIDAY_DAYS_OF_WEEKEND = [
  {
    value: 'sat_holiday',
    text: 'daysOfWeek.sat',
  },
  {
    value: 'sun_holiday',
    text: 'daysOfWeek.sun',
  },
];

export const HOLIDAY_EXCEPT_WEEKEND = [
  {
    value: 'mon_holiday',
    text: 'daysOfWeek.mon',
  },
  {
    value: 'tue_holiday',
    text: 'daysOfWeek.tue',
  },
  {
    value: 'wed_holiday',
    text: 'daysOfWeek.wed',
  },
  {
    value: 'thu_holiday',
    text: 'daysOfWeek.thu',
  },
  {
    value: 'fri_holiday',
    text: 'daysOfWeek.fri',
  },
];

export const KEYS_MON_TO_FRI = [
  'monToFri_morning_schoolTerm',
  'monToFri_afternoon_schoolTerm',
  'monToFri_normal',
  'monToFri_holiday',
];

export const KEYS_DAYS_EXCEPT_WEEKEND = ['mon', 'tue', 'wed', 'thu', 'fri'];

export const KEYS_DAYS_WEEKEND = ['sat', 'sun'];

export const KEYS_DAYS_IN_WEEK = [
  {
    value: 'mon',
    text: 'requiredDays.mon',
  },
  {
    value: 'tue',
    text: 'requiredDays.tue',
  },
  {
    value: 'wed',
    text: 'requiredDays.wed',
  },
  {
    value: 'thu',
    text: 'requiredDays.thu',
  },
  {
    value: 'fri',
    text: 'requiredDays.fri',
  },
  {
    value: 'sat',
    text: 'requiredDays.sat',
  },
  {
    value: 'sun',
    text: 'requiredDays.sun',
  },
];

export const ENQUIRY_TYPES_CONST = {
  APPLICATION: {
    value: 'APPLICATION',
    text: 'enquiryTypes.application',
    color: 'magenta',
    dotColor: '#DB147F',
    backgroundColor: '#FFF0FB',
    IconCPN: FileFillIcon,
  },
  CHECK_FOR_VACANCIES: {
    value: 'CHECK_FOR_VACANCIES',
    text: 'enquiryTypes.checkForVacancies',
    color: 'green',
    dotColor: '#36BF57',
    backgroundColor: '#EDF9F0',
    IconCPN: TotalCheckVacanciesIcon,
  },
  BOOK_A_TOUR_OR_VISIT: {
    value: 'BOOK_A_TOUR_OR_VISIT',
    text: 'enquiryTypes.bookATourOrVisit',
    color: 'purple',
    dotColor: '#BF2CF3',
    backgroundColor: '#F3EAFF',
    IconCPN: BookTourIcon,
  },
  MORE_INFORMATION: {
    value: 'MORE_INFORMATION',
    text: 'enquiryTypes.moreInformation',
    color: 'blue',
    dotColor: '#32A4FC',
    backgroundColor: '#E9F4FF',
    IconCPN: TotalMoreInfoIcon,
  },
  FEE_INFORMATION: {
    value: 'FEE_INFORMATION',
    text: 'enquiryTypes.feeInformation',
    color: 'orange',
    dotColor: '#FB8429',
    backgroundColor: '#FFF4EC',
    IconCPN: FeeInformationIcon,
  },
};

export const ENQUIRY_TYPES = Object.values(ENQUIRY_TYPES_CONST);

export const ENQUIRIES_STATUS_CONST = {
  read: {
    key: 'read',
    value: 'Read',
    text: 'enquiriesStatus.read',
    fillColor: '#32A4FC',
    backgroundColor: '#EEF2FA',
    keySummary: 'read',
  },
  unread: {
    key: 'unread',
    value: 'Unread',
    text: 'enquiriesStatus.unread',
    fillColor: '#FB8429',
    backgroundColor: '#FFF4EC',
    keySummary: 'unRead',
  },
  responded: {
    key: 'responded',
    value: 'Responded',
    text: 'enquiriesStatus.responded',
    fillColor: '#36BF57',
    backgroundColor: '#EDF9F0',
    keySummary: 'responded',
  },
  notResponded: {
    key: 'notResponded',
    value: 'Not Responded',
    text: 'enquiriesStatus.notResponded',
    fillColor: '#857E7F',
    backgroundColor: '#F2F2F2',
    keySummary: 'unResponded',
  },
  archived: {
    key: 'archived',
    value: 'Archived',
    text: 'enquiriesStatus.archived',
    fillColor: '#DB147F',
    backgroundColor: '#FFF3FC',
    keySummary: 'archived',
  },
};

export const FILE_TYPES = [
  { value: 'pdf', icon: FilePdfOutlined, color: theme.color.red },
  { value: 'ppt', icon: FilePptOutlined, color: theme.color.pink },
  { value: 'pptx', icon: FilePptOutlined, color: theme.color.pink },
  { value: 'doc', icon: FileWordOutlined, color: theme.color.blue },
  { value: 'docx', icon: FileWordOutlined, color: theme.color.blue },
  { value: 'xlsx', icon: FileExcelOutlined, color: theme.color.green },
  { value: 'xls', icon: FileExcelOutlined, color: theme.color.green },
  { value: 'csv', icon: FileExcelOutlined, color: theme.color.green },
  { value: 'zip', icon: FileZipOutlined, color: theme.color.violet },
  { value: 'zar', icon: FileZipOutlined, color: theme.color.violet },
  { value: 'txt', icon: FileTextOutlined, color: 'currentColor' },
  { value: 'mov', icon: VideoCameraOutlined, color: 'currentColor' },
  { value: 'mp4', icon: VideoCameraOutlined, color: 'currentColor' },
  { value: 'avi', icon: VideoCameraOutlined, color: 'currentColor' },
  { value: 'flv', icon: VideoCameraOutlined, color: 'currentColor' },
  { value: 'wmv', icon: VideoCameraOutlined, color: 'currentColor' },
  { value: 'mp3', icon: AudioOutlined, color: theme.color.lightGreen },
  { value: 'image', icon: FileImageOutlined, color: theme.color.blueSecondary },
];

export const IMAGE_TYPE = {
  icon: FileImageOutlined,
  color: theme.color.blueSecondary,
};

export const VALUE_ROOT_OPEN_TIME = {
  arrRootMorning1: ['mon_morning_schoolTerm'],
  arrRootMorning2: [
    'tue_morning_schoolTerm',
    'wed_morning_schoolTerm',
    'thu_morning_schoolTerm',
    'fri_morning_schoolTerm',
  ],
  arrRootAfternoon1: ['mon_afternoon_schoolTerm'],
  arrRootAfternoon2: [
    'tue_afternoon_schoolTerm',
    'wed_afternoon_schoolTerm',
    'thu_afternoon_schoolTerm',
    'fri_afternoon_schoolTerm',
  ],
  arrRootNormal1: ['mon_normal'],
  arrRootNormal2: ['tue_normal', 'wed_normal', 'thu_normal', 'fri_normal'],
  arrRootHoliday1: ['mon_holiday'],
  arrRootHoliday2: ['tue_holiday', 'wed_holiday', 'thu_holiday', 'fri_holiday'],
};

export const SERVICE_AGE_GROUPS = [
  { value: '0to12months', text: 'serviceAgeGroup.0to12months' },
  { value: '13to24months', text: 'serviceAgeGroup.13to24months' },
  { value: '24to36months', text: 'serviceAgeGroup.24to36months' },
  { value: '36monthsplus', text: 'serviceAgeGroup.36monthsplus' },
];

export const VACANCY_COD = [
  {
    value: true,
    text: 'vacancyCod.available',
    color: 'green',
    colorText: theme.color.green,
  },
  {
    value: false,
    text: 'vacancyCod.unavailable',
    color: 'red',
    colorText: theme.color.red,
  },
];

export const CENTRE_NQS_RATING = [
  { value: 'currentEPM', text: 'centreNQSRating.currentEPM' },
  { value: 'currentCHS', text: 'centreNQSRating.currentCHS' },
  { value: 'currentPE', text: 'centreNQSRating.currentPE' },
  { value: 'currentSA', text: 'centreNQSRating.currentSA' },
  { value: 'currentRWC', text: 'centreNQSRating.currentRWC' },
  { value: 'currentCPWFAC', text: 'centreNQSRating.currentCPWFAC' },
  { value: 'currentGAL', text: 'centreNQSRating.currentGAL' },
];

export const APPLICATION_STATUS_CONSTANT = {
  submitted: 'SUBMITTED',
  opened: 'OPENED',
  waitingResponse: 'AWAITING_RESPONSE',
  inProgress: 'IN_PROGRESS',
  informationRequested: 'INFORMATION_REQUESTED',
  responseReceived: 'RESPONSE_RECEIVED',
  waitListed: 'WAIT_LISTED',
  approved: 'APPROVED',
  accepted: 'ACCEPTED',
  enrolled: 'ENROLLED',
  unsuccessful: 'UNSUCCESSFUL',
  declined: 'DECLINED',
  cancelled: 'CANCELLED',
  visitOrTour: 'VISIT_OR_TOUR',
  interview: 'INTERVIEW',
  offered: 'OFFERED',
  employed: 'EMPLOYED',
  verified: 'VERIFIED',
};

export const JOB_APPLICATION_STATUS_CONST_ITEM = {
  submitted: {
    value: APPLICATION_STATUS_CONSTANT.submitted,
    text: 'applicationStatus.submitted',
    icon: FileDoneOutlined,
    color: '#758098',
    backgroundColor: '#F7F8F9',
    keyEnrolmentPipelineValue: 'submittedApplicationsValue',
    indexOrder: 1,
  },
  inProgress: {
    value: APPLICATION_STATUS_CONSTANT.inProgress,
    text: 'applicationStatus.inProgress',
    icon: FileDoneOutlined,
    color: '#3EBD5C',
    backgroundColor: '#EDF9F0',
    keyEnrolmentPipelineValue: 'inProgressApplicationsValue',
    indexOrder: 2,
  },
  interview: {
    value: APPLICATION_STATUS_CONSTANT.interview,
    text: 'applicationStatus.interview',
    icon: FileDoneOutlined,
    color: '#BF2CF3',
    backgroundColor: '#F3EAFF',
    keyEnrolmentPipelineValue: 'interviewApplicationsValue',
    indexOrder: 3,
  },
  verified: {
    value: APPLICATION_STATUS_CONSTANT.verified,
    text: 'applicationStatus.verified',
    icon: FileDoneOutlined,
    color: '#29DCE8',
    backgroundColor: '#F1FDFB',
    keyEnrolmentPipelineValue: 'verifieddApplicationsValue',
    indexOrder: 4,
  },
  offered: {
    value: APPLICATION_STATUS_CONSTANT.offered,
    text: 'applicationStatus.offered',
    icon: FileDoneOutlined,
    color: '#3570DB',
    backgroundColor: '#EEF2FA',
    keyEnrolmentPipelineValue: 'offeredApplicationsValue',
    indexOrder: 5,
  },
  employed: {
    value: APPLICATION_STATUS_CONSTANT.employed,
    text: 'applicationStatus.employed',
    icon: FileDoneOutlined,
    color: '#2BADA7',
    backgroundColor: '#F0FBFB',
    keyEnrolmentPipelineValue: 'employedApplicationsValue',
    indexOrder: 6,
  },
  declined: {
    value: APPLICATION_STATUS_CONSTANT.declined,
    text: 'applicationStatus.declined',
    icon: FileDoneOutlined,
    color: '#FB8429',
    backgroundColor: '#FFF4EC',
    keyEnrolmentPipelineValue: 'declinedApplicationsValue',
    indexOrder: 7,
  },
  cancelled: {
    value: APPLICATION_STATUS_CONSTANT.cancelled,
    text: 'applicationStatus.cancelled',
    icon: FileDoneOutlined,
    color: '#E22B30',
    backgroundColor: '#FEEFEF',
    keyEnrolmentPipelineValue: 'cancelledApplicationsValue',
    indexOrder: 8,
  },
};

export const JOB_APPLICATION_STATUS = Object.values(
  JOB_APPLICATION_STATUS_CONST_ITEM,
);

export const APPLICATION_STATUS_CONST_ITEM = {
  submitted: {
    value: APPLICATION_STATUS_CONSTANT.submitted,
    text: 'applicationStatus.submitted',
    icon: FileDoneOutlined,
    color: '#ABA7A7',
    backgroundColor: '#EEF2FA',
    colors: {
      soft: theme.boardColors.B50,
      hard: theme.boardColors.N400A,
    },
    keyEnrolmentPipelineValue: 'submittedApplicationsValue',
    indexOrder: 1,
  },
  inProgress: {
    value: APPLICATION_STATUS_CONSTANT.inProgress,
    text: 'applicationStatus.inProgress',
    icon: SyncOutlined,
    color: '#36BF57',
    backgroundColor: '#EDF9F0',
    colors: {
      soft: theme.boardColors.G50,
      hard: theme.boardColors.N400A,
    },
    keyEnrolmentPipelineValue: 'inProgressApplicationsValue',
    indexOrder: 2,
  },
  visitOrTour: {
    value: APPLICATION_STATUS_CONSTANT.visitOrTour,
    text: 'applicationStatus.visitOrTour',
    color: '#BF2CF3',
    backgroundColor: '#EDE0FC',
    icon: SyncOutlined,
    colors: {
      soft: theme.boardColors.G50,
      hard: theme.boardColors.N400A,
    },
    keyEnrolmentPipelineValue: 'VisitOrTourApplicationsValue',
    indexOrder: 3,
  },
  waitListed: {
    value: APPLICATION_STATUS_CONSTANT.waitListed,
    text: 'applicationStatus.waitListed',
    icon: CloseCircleFilled,
    color: '#37C5E5',
    backgroundColor: '#E8FBFF',
    colors: {
      soft: theme.boardColors.B50,
      hard: theme.boardColors.N400A,
    },
    keyEnrolmentPipelineValue: 'waitlistedApplicationsValue',
    indexOrder: 4,
  },
  accepted: {
    value: APPLICATION_STATUS_CONSTANT.accepted,
    text: 'applicationStatus.accepted',
    icon: CheckCircleFilled,
    color: '#DB147F',
    backgroundColor: '#FFF3FC',
    // accepts: ['ENROLLED', 'DECLINED'],
    colors: {
      soft: theme.boardColors.B50,
      hard: theme.boardColors.N400A,
    },
    keyEnrolmentPipelineValue: 'acceptedApplicationsValue',
    indexOrder: 5,
  },
  enrolled: {
    value: APPLICATION_STATUS_CONSTANT.enrolled,
    text: 'applicationStatus.enrolled',
    icon: StopOutlined,
    color: '#316DDE',
    backgroundColor: '#F0F7FF',
    // color: '#32A4FC',
    colors: {
      soft: theme.boardColors.B50,
      hard: theme.boardColors.N400A,
    },
    keyEnrolmentPipelineValue: 'enrolledApplicationsValue',
    indexOrder: 6,
  },
  declined: {
    value: APPLICATION_STATUS_CONSTANT.declined,
    text: 'applicationStatus.declined',
    icon: CloseCircleFilled,
    color: '#857E7F',
    backgroundColor: '#F2F2F2',
    indexOrder: 7,
  },
  cancelled: {
    value: APPLICATION_STATUS_CONSTANT.cancelled,
    text: 'applicationStatus.cancelled',
    icon: CloseCircleFilled,
    color: '#FF0E0E',
    backgroundColor: '#FFE9E9',
    indexOrder: 8,
  },
};

export const APPLICATION_STATUS = Object.values(APPLICATION_STATUS_CONST_ITEM);

export const APPLICATION_STATUS_BOARD = [
  APPLICATION_STATUS_CONST_ITEM.submitted,
  APPLICATION_STATUS_CONST_ITEM.inProgress,
  APPLICATION_STATUS_CONST_ITEM.visitOrTour,
  APPLICATION_STATUS_CONST_ITEM.waitListed,
  APPLICATION_STATUS_CONST_ITEM.accepted,
  APPLICATION_STATUS_CONST_ITEM.enrolled,
];

export const DISPUTE_REASONS = [
  'Broken link',
  'Inaccurate information',
  'Can not claim the listing',
  'Other (Please specify)',
];

export const SUBSCRIPTION_CENTRE = [
  {
    value: 'BASIC',
    text: 'subscriptionCentres.basic',
    color: '#32A4FC',
    backgroundColor: '#E9F4FF',
  },
  {
    value: 'ESSENTIALS',
    text: 'subscriptionCentres.essentials',
    color: '#FC9215',
    backgroundColor: '#FFEDD2',
  },
  {
    value: 'PREMIUM',
    text: 'subscriptionCentres.premium',
    color: '#DB147F',
    backgroundColor: '#FDF2FF',
  },
  {
    value: 'ENTERPRISE',
    text: 'subscriptionCentres.enterprise',
    color: '#36BF57',
    backgroundColor: '#EDF9F0',
  },
];

export const INFO_REQUEST_STATUS = [
  {
    value: 'AWAITING_RESPONSE',
    text: 'infoRequestStatus.awaitingResponse',
    color: '#FC9215',
  },
  {
    value: 'RECEIVED',
    text: 'infoRequestStatus.received',
    color: '#32A4FC',
  },
];

export const DEFAULT_INBOX_ID = 'inboxes';

export const ACTIVITY_TYPES = [
  {
    value: 'incomingCall',
    text: 'types.incomingCall',
    color: '#36BF57',
    IconCPN: IncomingCallIcon,
  },
  {
    value: 'outgoingCall',
    text: 'types.outgoingCall',
    color: '#FB8429',
    IconCPN: OutgoingCallIcon,
  },
  {
    value: 'email',
    text: 'types.email',
    color: '#32A4FC',
    IconCPN: EmailIcon,
  },
  {
    value: 'note',
    text: 'types.note',
    color: '#DB147F',
    IconCPN: NoteIcon,
  },
  {
    value: 'other',
    text: 'types.other',
    color: '#00DAF7',
    IconCPN: OtherIcon,
  },
  {
    value: 'interview',
    text: 'types.interview',
    color: '#DB147F',
    IconCPN: InterviewIcon,
  },
  {
    value: 'visit',
    text: 'types.visit',
    color: '#2964FB',
    IconCPN: VisitTourIcon,
  },
];

export const CONTACT_SOURCE_TYPES = [
  {
    value: 'ENQUIRY',
    text: 'contacts.enquiry',
    color: '#316DDE',
    backgroundColor: '#F0F7FF',
  },
  {
    value: 'APPLICATION',
    text: 'contacts.application',
    color: '#DB147F',
    backgroundColor: '#FFF3FC',
  },
  {
    value: null,
    text: 'contacts.manual',
    color: '#36BF57',
    backgroundColor: '#EDF9F0',
  },
];

export const CONTACT_CUSTOMER_TYPES = [
  {
    value: 'AUTO',
    text: 'contacts.auto',
    color: '#f50',
  },
  {
    value: 'MANUAL',
    text: 'contacts.manual',
    color: '#108ee9',
  },
];

export const BILLING_PERIOD = [
  {
    value: 'daily',
    text: 'billingPeriod.day',
  },
  {
    value: 'weekly',
    text: 'billingPeriod.week',
  },
  {
    value: 'monthly',
    text: 'billingPeriod.month',
  },
];

export const ALPHABET = [
  'A',
  'B',
  'C',
  'D',
  'E',
  'F',
  'G',
  'H',
  'I',
  'J',
  'K',
  'L',
  'M',
  'N',
  'O',
  'P',
  'Q',
  'R',
  'S',
  'T',
  'U',
  'V',
  'W',
  'X',
  'Y',
  'Z',
];

export const ORDER_BY_CONTACTS = [
  {
    value: '-createdAt',
    text: 'sorter.newest',
  },
  {
    value: 'createdAt',
    text: 'sorter.oldest',
  },
];

export const REASON_ARCHIVE_ENQUIRY = [
  {
    value: 'VISIT_OR_TOUR_BOOKED',
    text: 'reasonArchiveEnquiry.visitOrTourBooked',
  },
  {
    value: 'WAITLISTED',
    text: 'reasonArchiveEnquiry.waitlisted',
  },
  {
    value: 'ENROLLED',
    text: 'reasonArchiveEnquiry.enrolled',
  },
  {
    value: 'NO_AVAILABILITY',
    text: 'reasonArchiveEnquiry.noAvailability',
  },
  {
    value: 'NOT_A_GENUINE_ENQUIRY',
    text: 'reasonArchiveEnquiry.notAGenuineEnquiry',
  },
  {
    value: 'DUPLICATE_ENQUIRY',
    text: 'reasonArchiveEnquiry.duplicateEnquiry',
  },
  {
    value: 'OTHER',
    text: 'reasonArchiveEnquiry.other',
  },
];

export const REVIEW_DISPUTE_STATUS_CONST = {
  pending: {
    value: 'pending',
    text: 'status.pending',
  },
  denied: {
    value: 'denied',
    text: 'status.denied',
  },
  approved: {
    value: 'approved',
    text: 'status.approved',
  },
};

export const ENQUIRIES_SETTINGS = [
  {
    value: 'simple',
    text: 'enquiriesSettings.simpleEnquiries',
    description: 'enquiriesSettings.simpleEnquiriesDesc',
  },
  {
    value: 'complex',
    text: 'enquiriesSettings.complexEnquiries',
    description: 'enquiriesSettings.complexEnquiriesDesc',
  },
];
export const LISTING_STRENGTH_CONTENT = [
  {
    step: 1,
    key: null,
    title: 'listingStrength.listStep.step1',
    Component: CentreInformationModal,
  },
  {
    step: 2,
    key: null,
    title: 'listingStrength.listStep.step2',
    Component: DescriptionModal,
  },
  {
    step: 3,
    key: null,
    title: 'listingStrength.listStep.step3',
    Component: MoreCentreInfoModal,
  },
  {
    step: 4,
    key: null,
    title: 'listingStrength.listStep.step4',
    Component: UploadBusinessLogoModal,
  },
  {
    step: 5,
    key: null,
    title: 'listingStrength.listStep.step5',
    Component: CentrePhotoModal,
  },
  {
    step: 6,
    key: null,
    title: 'listingStrength.listStep.step6',
    Component: CentrePhotoModal,
  },
  {
    step: 7,
    key: 'hours',
    title: 'listingStrength.listStep.step7',
    Component: HoursTab,
  },
  {
    step: 8,
    key: 'services',
    title: 'listingStrength.listStep.step8',
    Component: ServicesTab,
  },
  {
    step: 9,
    key: 'features',
    title: 'listingStrength.listStep.step9',
    Component: FeaturesTab,
  },
  {
    step: 10,
    key: 'nqsRating',
    title: 'listingStrength.listStep.step10',
    Component: RatingsReviewsTab,
  },
  {
    step: 11,
    key: 'automatedResponses',
    title: 'listingStrength.listStep.step11',
  },
];

export const CRM_PACKAGES = {
  KINDICARE_ENTERPRISE: 'KindiCare Enterprise',
  KINDICARE_ESSENTIALS: 'KindiCare Essentials',
  KINDICARE_PREMIUM: 'KindiCare Premium',
  KINDICARE_BASIC: 'KindiCare Basic',
};

export const CENTRE_STATUS_VALUE = {
  pending: 'PENDING',
  inProcessing: 'IN_PROCESSING',
  approved: 'APPROVED',
  declined: 'DECLINED',
  claimed: 'CLAIMED',
};

export const STEPS_MODAL_CONSTANT = {
  centreDescription: {
    current: 0,
    title: 'centreDetail.titleCentreDescriptionModal',
  },
  additionalDetail: {
    current: 1,
    title: 'centreDetail.titleCentreAddDetailModal',
  },
  moreCentreInfo: {
    current: 2,
    title: 'centreDetail.titleMoreCentreInfoModal',
  },
  customiseButton: {
    current: 3,
    title: 'centreDetail.titleCustomiseButtonModal',
  },
};
export const STEPS_MODAL = Object.values(STEPS_MODAL_CONSTANT);

export const REPORT_TYPES = [
  { value: 'suburb', text: 'priceBenchmarking.reportTypes.suburb' },
  { value: 'postcode', text: 'priceBenchmarking.reportTypes.postCode' },
  { value: 'radius1000', text: 'priceBenchmarking.reportTypes.radius1000' },
  { value: 'radius2500', text: 'priceBenchmarking.reportTypes.radius2500' },
  { value: 'radius5000', text: 'priceBenchmarking.reportTypes.radius5000' },
];

export const JOB_TEMPLATE_STATUS_CONSTANT = {
  active: {
    value: 'Active',
    text: 'jobs.jobTemplates.active',
    color: theme.text.successDefault,
    backgroundColor: theme.background.successSoft,
  },
  draft: {
    value: 'Draft',
    text: 'jobs.jobTemplates.draft',
    color: theme.text.black,
    backgroundColor: theme.background.gray200,
  },
};

export const JOB_TEMPLATE_STATUS = Object.values(JOB_TEMPLATE_STATUS_CONSTANT);

export const JOB_APPLICATION_STATUS_CONSTANT = {
  submitted: {
    value: 'Submitted',
    text: 'jobApplications.statusItem.submitted',
  },
  inProgress: {
    value: 'In Progress',
    text: 'jobApplications.statusItem.inProgress',
  },
  interview: {
    value: 'Interview',
    text: 'jobApplications.statusItem.interview',
  },
  verified: {
    value: 'Verified',
    text: 'jobApplications.statusItem.verified',
  },
  offered: {
    value: 'Offered',
    text: 'jobApplications.statusItem.offered',
  },
  employed: {
    value: 'Employed',
    text: 'jobApplications.statusItem.employed',
  },
  declined: {
    value: 'Declined',
    text: 'jobApplications.statusItem.declined',
  },
  cancelled: {
    value: 'Cancelled',
    text: 'jobApplications.statusItem.cancelled',
  },
};

export const INDIVIDUAL_JOB_LISTING_STATUS = [
  {
    value: 'Open',
    text: 'jobListings.open',
    color: '#3EBD5C',
    backgroundColor: '#EDF9F0',
  },
  {
    value: 'Closed',
    text: 'jobListings.closed',
    color: '#101828',
    backgroundColor: '#EAECF0',
  },
];

export const REASON_DELETE_INDIVIDUAL = [
  {
    value: 'Job Role no longer required',
    text: 'jobListings.deleteIndividual.noLongerRequired',
  },
  {
    value: 'Another candidate was appointed',
    text: 'jobListings.deleteIndividual.anotherCandidate',
  },
  {
    value: 'References not valid',
    text: 'jobListings.deleteIndividual.referencesNotValid',
  },
  {
    value: 'Identity check failed',
    text: 'jobListings.deleteIndividual.IdentityCheckFailed',
  },
  {
    value: 'Criminal check failed',
    text: 'jobListings.deleteIndividual.criminalCheckFailed',
  },
  {
    value: 'Working with children check not valid',
    text: 'jobListings.deleteIndividual.workingWithChildren',
  },
  {
    value: 'Minimum immunisation requirements not met',
    text: 'jobListings.deleteIndividual.minimumImmunisation',
  },
  {
    value: 'Qualifications not validated',
    text: 'jobListings.deleteIndividual.qualificationsNotValidated',
  },
];

export const CHANNEL_OPTIONS = {
  all: { label: 'All', value: 'All' },
  app: { label: 'KindiCare App', value: 'App' },
  web: { label: 'KindiCare Web', value: 'Web' },
};

export const EXPORT_STATUS = {
  running: 'Running',
  complete: 'Complete',
};

export const PERMISSION_KEY = {
  leadActivities: 'leadActivities',
};

export const PUBLISH_STATUS = {
  published: { label: 'Completed', value: false },
  draft: { label: 'Draft', value: true },
};

export const SHOW_HIDE_STATUS = {
  show: { label: 'Show', value: true },
  hide: { label: 'Hide', value: false },
};

export const VIEW_MODES = {
  board: {
    title: 'text.board',
    key: 'board',
    Icon: LayoutBoardIcon,
  },
  list: {
    title: 'text.list',
    key: 'list',
    Icon: LayoutListIcon,
  },
};

import styled from 'styled-components';

export const CardCustomWrapper = styled.div`
  .ic-style {
    width: 56px;
    height: 56px;
    border: 1px solid ${({ theme }) => theme.boardColors.N30};
  }

  .steps-item-tail {
    background: ${({ theme }) => theme.boardColors.N30};
    height: 75%;
    width: 2px;
    margin: auto;
  }

  .item-style:hover {
    background-color: ${({ theme }) => theme.background.container};
    border-radius: 8px;
  }
`;

import { createAsyncThunk } from '@reduxjs/toolkit';
import { getResourcesApi } from 'api/resources';
import { apiWrapper } from 'utils/reduxUtils';

export const MODEL_NAME = 'categories';

export const getAllResources = createAsyncThunk(
  'resources/getAllResources',
  async (payload = {}, thunkAPI) => {
    try {
      const { data } = payload;
      const response = await apiWrapper(
        { isShowSuccessNoti: false },
        getResourcesApi,
        data,
      );
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  },
);

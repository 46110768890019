import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from 'antd';
import i18next from 'i18next';

const JobListingGeneralInfoItem = ({ icon, content, titleTooltip }) => (
  <div className="text-16 mt-16 flex items-center">
    <span className="text-green mr-10">{icon}</span>
    <Tooltip title={i18next.t(titleTooltip)}>
      <span>{i18next.t(content)}</span>
    </Tooltip>
  </div>
);

JobListingGeneralInfoItem.propTypes = {
  icon: PropTypes.node,
  content: PropTypes.string,
  titleTooltip: PropTypes.string,
};

export default JobListingGeneralInfoItem;

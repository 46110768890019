import { useCallback, useEffect, useRef, useState } from 'react';
import { MAX_LIMIT } from 'containers/CompanyBenchmarking/localData/constants';
import { isEmpty } from 'lodash-es';
import { useHistory, useLocation } from 'react-router';
import { convertSearchUrl, getFilterFromUrl } from 'utils/tools';

const useTableInfinity = (
  data,
  loading,
  resourceFilter,
  retrieveList,
  options,
) => {
  const [shouldFetchMore, setShouldFetchMore] = useState(false);
  const [isFetchingMore, setIsFetchingMore] = useState(false);
  const { push } = useHistory();

  const { search } = useLocation();
  const { offset, filter, orderBy } = getFilterFromUrl(search);

  const allowLoadmore = useRef(true);

  const handleFetch = () => {
    const newOffset = Number(offset) + MAX_LIMIT;
    if (
      !isEmpty(filter) &&
      data?.length < resourceFilter?.total &&
      allowLoadmore.current
    ) {
      allowLoadmore.current = false;

      setIsFetchingMore(true);

      retrieveList &&
        retrieveList({
          filter: {
            outsideFilter: filter,
            limit: MAX_LIMIT,
            offset: newOffset,
            orderBy: orderBy || options?.defaultOrderBy,
          },
          isRefresh: false,
        })
          .then(({ payload }) => {
            if (payload?.data?.ids) {
              setShouldFetchMore(false);
              allowLoadmore.current = true;
            }
          })
          .finally(() => {
            setIsFetchingMore(false);
          });

      push({
        search: `?${convertSearchUrl({
          ...filter,
          limit: MAX_LIMIT,
          offset: newOffset,
          orderBy,
        })}`,
      });
    }
  };

  useEffect(() => {
    if (shouldFetchMore) {
      handleFetch();
      setShouldFetchMore(false);
    }
  }, [shouldFetchMore]); // eslint-disable-line

  const handleScroll = useCallback((event) => {
    const element = event.target;
    const maxScroll = element.scrollHeight - element.clientHeight;
    const currentScroll = element.scrollTop;

    if (
      Math.round(currentScroll) <= Math.round(maxScroll) &&
      Math.round(currentScroll) >= Math.round(maxScroll) - 10
    ) {
      if (
        !isEmpty(filter) &&
        data?.length <= resourceFilter?.total &&
        !shouldFetchMore
      ) {
        if (!loading) {
          setShouldFetchMore(true);
        }
      }
    }
  }, []); // eslint-disable-line

  useEffect(() => {
    const tableContent = document.querySelector('.ant-table-body');
    tableContent.addEventListener('scroll', handleScroll);
    return () => tableContent.removeEventListener('scroll', handleScroll);
  }, [handleScroll]);

  return { isFetchingMore };
};

export default useTableInfinity;

import { ROLES_CONST } from 'configs/localData/permissions';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { getAdDetailData } from 'redux/advertisement/selectors';
import { getCurrentRole } from 'redux/auth/selectors';
import { checkRole } from 'utils/tools';
import { DELIVERY_STATUS } from '../constants';

const useModifyAdsPermission = () => {
  const currentRole = useSelector(getCurrentRole);
  const currentStatus = useSelector(getAdDetailData)?.deliveryStatus?.value;

  const canModifyAds = useMemo(
    () =>
      checkRole(
        [ROLES_CONST.companyAdmin, ROLES_CONST.companyStaff],
        currentRole,
      ),
    [currentRole],
  );

  const canModifyAdStatus = useMemo(
    () =>
      checkRole(
        [
          DELIVERY_STATUS.active,
          DELIVERY_STATUS.schedule,
          DELIVERY_STATUS.draft,
        ],
        currentStatus,
      ),
    [currentStatus],
  );

  return {
    canModifyAds,
    canModifyAdStatus,
  };
};

export default useModifyAdsPermission;

import React from 'react';
import UserInfo from 'components/RestField/UserInfo';
import SwitchToggle from './SwitchToggle';

export const formatUserName = (data, record) => {
  const recordInfo = {
    id: record.id,
    avatar: record.avatar,
    email: record.email,
    fullName: `${record?.firstName || ''} ${record?.lastName || ''}`,
  };

  return <UserInfo record={recordInfo} isLink={false} roleProp="email" />;
};

export const formatIsActive = (data, record) => (
  <SwitchToggle checked={!data} source="isDisabled" id={record.id} />
);

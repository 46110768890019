import { DatePicker, Form, Radio, Space } from 'antd';
import PageTitle from 'components/common/PageTitle';
import {
  START_A_NEW_JOB,
  START_A_NEW_JOB_CONST,
} from 'containers/JobListings/constants';
import i18next from 'i18next';
import React from 'react';
import PropTypes from 'prop-types';
import { disabledSelectBeforeToday } from 'utils/tools';
import { useDispatch } from 'react-redux';
import { setJobListingPayload } from 'redux/jobs/jobListings/slice';
import moment from 'moment';
import { StartDateWrapper } from './styles';

const DATE_FORMAT = 'DD MMM YYYY';

const SelectStartDateStep = ({ form, initialValues }) => {
  const dispatch = useDispatch();

  const onFinish = ({ startDate, ...values }) => {
    dispatch(
      setJobListingPayload({
        ...values,
        startDate: startDate && moment(startDate).format(DATE_FORMAT),
      }),
    );
  };

  return (
    <StartDateWrapper>
      <PageTitle className="step-title">
        {i18next.t('jobListings.steps.step3')}
      </PageTitle>
      <Form
        form={form}
        onFinish={onFinish}
        initialValues={{
          ...initialValues,
          startDate: initialValues?.startDate
            ? moment(initialValues?.startDate)
            : null,
        }}
      >
        <Form.Item
          name="timeOption"
          rules={[
            {
              required: true,
              message: i18next.t('input.startDate.validateMsg.required'),
            },
          ]}
          className="valid-message"
        >
          <Radio.Group>
            <Space direction="vertical">
              {START_A_NEW_JOB.map((item, index) => (
                <Radio value={item?.value} key={Number(index)}>
                  {i18next.t(item?.text)}
                </Radio>
              ))}
            </Space>
          </Radio.Group>
        </Form.Item>
        <Form.Item
          noStyle
          shouldUpdate={(prevValues, currentValues) =>
            prevValues.timeOption !== currentValues.timeOption
          }
        >
          {({ getFieldValue }) =>
            getFieldValue('timeOption') ===
              START_A_NEW_JOB_CONST.selectStartDate.value && (
              <div className="ml-24">
                <Form.Item
                  name="startDate"
                  rules={[
                    {
                      required: true,
                      message: i18next.t(
                        'input.specificDate.validateMsg.required',
                      ),
                    },
                  ]}
                >
                  <DatePicker
                    className="w-half"
                    format={DATE_FORMAT}
                    disabledDate={disabledSelectBeforeToday}
                  />
                </Form.Item>
              </div>
            )
          }
        </Form.Item>
      </Form>
    </StartDateWrapper>
  );
};

SelectStartDateStep.propTypes = {
  form: PropTypes.object,
  initialValues: PropTypes.object,
};

export default SelectStartDateStep;

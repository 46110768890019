import React, { useEffect, useMemo, useRef, useState } from 'react';
import StepsCreateLayout from 'components/RestLayout/StepsCreateLayout';
import kindiCareLogo from 'assets/images/logo_plus.svg';
import theme from 'configs/theme';
import { useDispatch, useSelector } from 'react-redux';
import {
  getCreateAdPayload,
  getCurrentStepCreateAd,
} from 'redux/advertisement/selectors';
import { goToStep, resetCreateAdPayload } from 'redux/advertisement/slice';
import { Form } from 'antd';
import { FormInstance } from 'rc-field-form';
import { getAdsFormConfig } from 'redux/advertisement/actions';
import { RootState } from 'redux/store';
import { isEmpty } from 'lodash-es';
import ExitConfirmModal from 'containers/Advertisement/ExitConfirmModal';
import SiderCreateAd from './SiderCreateAd';
import CreateAdOptions from './CreateAdOptions';
import CreateAdSteps from './Steps';
import AdCreative from './Steps/AdCreative';
import {
  AD_STEP,
  NO_ASK_EXIT_CONFIRM,
  PARAM_ADS_EXISTING_SCREEN,
  PARAM_START_SCREEN,
} from '../constants';
import TypeOfAd from './Steps/TypeOfAd';
import Identity from './Steps/Identity';
import TargetedAudience from './Steps/TargetedAudience';
import Duration from './Steps/Duration';
import ExistingAdsGrid from './ExistingAdsGrid';

export interface StepRef {
  fieldsNeedValidate?: string[];
}

interface CurrentStepData {
  form?: FormInstance;
  ContentComponent?: React.FC<
    { form?: FormInstance } & React.RefAttributes<unknown>
  >;
  ref?: React.MutableRefObject<StepRef>;
}

const CreateAdvertisement = ({ match, history }) => {
  const dispatch = useDispatch();
  const currentStep = useSelector(getCurrentStepCreateAd);
  const [isOnlyExitConfirm, setIsOnlyExitConfirm] = useState(false);
  const targetedAudienceRef = useRef<StepRef>();
  const createAdFormValues = useSelector(getCreateAdPayload);

  const currentUserId = useSelector(
    (state: RootState) => state?.auth?.data?.id,
  );

  const { screen } = match.params;

  const isStartScreen =
    screen === PARAM_START_SCREEN || screen === PARAM_ADS_EXISTING_SCREEN;

  const [formStep1] = Form.useForm();
  const [formStep2] = Form.useForm();
  const [formStep3] = Form.useForm();
  const [formStep4] = Form.useForm();
  const [formStep5] = Form.useForm();

  const { form, ContentComponent, ref }: CurrentStepData = useMemo(() => {
    if (screen === PARAM_START_SCREEN) {
      return {
        ContentComponent: CreateAdOptions,
      };
    }
    if (screen === PARAM_ADS_EXISTING_SCREEN) {
      return {
        ContentComponent: ExistingAdsGrid,
      };
    }
    switch (currentStep) {
      case AD_STEP.TYPE_OF_AD:
        return {
          form: formStep1,
          ContentComponent: TypeOfAd,
        };
      case AD_STEP.AD_CREATIVE:
        return {
          form: formStep2,
          ContentComponent: AdCreative,
        };
      case AD_STEP.IDENTITY:
        return {
          form: formStep3,
          ContentComponent: Identity,
        };
      case AD_STEP.TARGETED_AUDIENCE:
        return {
          form: formStep4,
          ContentComponent: TargetedAudience,
          ref: targetedAudienceRef,
        };
      case AD_STEP.DURATION:
        return {
          form: formStep5,
          ContentComponent: Duration,
        };
      default:
        return {};
    }
  }, [
    currentStep,
    formStep1,
    formStep2,
    formStep3,
    formStep4,
    formStep5,
    screen,
  ]);

  const onJumpStep = async (newStep: number) => {
    const requiredFields = [
      'description',
      'callingButton',
      'destination',
      'mediaList',
      'headline',
    ];

    const payload = {
      ...createAdFormValues,
      ...form.getFieldsValue(),
    };
    const isNotSatisfyJump = requiredFields.some((field) =>
      isEmpty(payload[field]),
    );
    if (form) {
      form.validateFields().then(() => {
        if (newStep > AD_STEP.AD_CREATIVE && isNotSatisfyJump) {
          return;
        }
        form.submit();
        dispatch(goToStep(newStep));
      });
    }
  };

  const onOpenExitConfirmModal = () => {
    const isShowConfirm =
      localStorage.getItem(NO_ASK_EXIT_CONFIRM) !== currentUserId;
    if (isShowConfirm) {
      setIsOnlyExitConfirm(true);
    } else {
      onExit();
    }
  };

  const onExit = () => {
    dispatch(resetCreateAdPayload());
    history.push('/advertisement');
  };

  const SiderComponent = isStartScreen ? (
    <SiderCreateAd />
  ) : (
    <CreateAdSteps currentStep={currentStep} onChange={onJumpStep} />
  );

  useEffect(() => {
    dispatch(getAdsFormConfig());
  }, [dispatch]);

  return (
    <StepsCreateLayout
      SiderComponent={SiderComponent}
      backgroundSider={theme.color.lightPink}
      logo={kindiCareLogo}
      onExit={() =>
        currentStep === AD_STEP.TYPE_OF_AD ? onExit() : onOpenExitConfirmModal()
      }
      textHeaderAction={'button.exit'}
    >
      <ContentComponent ref={ref} form={form} />
      <ExitConfirmModal
        isVisible={isOnlyExitConfirm}
        onExit={onExit}
        onCancel={() => setIsOnlyExitConfirm(false)}
      />
    </StepsCreateLayout>
  );
};

export default CreateAdvertisement;

import React from 'react';
import { DatePicker } from 'antd';
import i18next from 'i18next';
import moment from 'moment';
import FormItemUI from 'components/form/FormItem';
import { ArrowRightIcon, CalendarIcon } from 'components/common/SVGIcon';
import { RangePickerWrapper } from './styles';

const { RangePicker } = DatePicker;

interface Props {
  formatDate?: string;
  placeholder?: string | string[];
  isShowRangesFooter?: boolean;
  rangesFooterCustom?: any;
  source: string;
  [valueProp: string]: unknown;
}

const FormRangePicker = ({
  formatDate = 'MMM DD YYYY',
  placeholder = ['text.from', 'text.to'],
  isShowRangesFooter,
  rangesFooterCustom,
  ...valueProp
}: Props) => {
  const { source } = valueProp;

  const rangesFooter = {
    [i18next.t('dateTimePickerText.today')]: [
      moment().startOf('day'),
      moment().endOf('day'),
    ],
    [i18next.t('dateTimePickerText.yesterday')]: [
      moment().subtract(1, 'days').startOf('day'),
      moment().subtract(1, 'days').endOf('day'),
    ],
    [i18next.t('dateTimePickerText.last7Days')]: [
      moment().subtract(7, 'days').startOf('day'),
      moment().subtract(1, 'days').endOf('day'),
    ],
    [i18next.t('dateTimePickerText.lastMonth')]: [
      moment().subtract(1, 'months').startOf('month'),
      moment().subtract(1, 'months').endOf('month'),
    ],
  };

  return (
    <RangePickerWrapper>
      <FormItemUI {...valueProp} ruleType="array" name={source}>
        <RangePicker
          format={formatDate}
          placeholder={[i18next.t(placeholder[0]), i18next.t(placeholder[1])]}
          ranges={isShowRangesFooter && (rangesFooterCustom || rangesFooter)}
          suffixIcon={<CalendarIcon className="text-16 text-gray-500" />}
          separator={<ArrowRightIcon className="text-gray-500" />}
        />
      </FormItemUI>
    </RangePickerWrapper>
  );
};

export default FormRangePicker;

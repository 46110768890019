import React from 'react';
import addToInboxImage from 'assets/images/add-to-inbox.png';
import Meta from 'antd/lib/card/Meta';
import CreateButton from 'components/RestActions/CreateButton';
import i18next from 'i18next';
import { NoTemplatesStyles } from './styles';

interface Props {
  title: string;
  description: string;
  gotoCreatePage?: () => void;
  disabled?: boolean;
  isShowCreateButton?: boolean;
}

const NoTemplates = ({
  title,
  description,
  gotoCreatePage,
  disabled,
  isShowCreateButton = true,
}: Props) => (
  <NoTemplatesStyles className="flex-center flex-col mt-32">
    <div>
      <img width={135} src={addToInboxImage} alt="" />
    </div>
    <Meta
      title={i18next.t(title)}
      description={i18next.t(description)}
      className="mb-20 text-center"
    />
    {isShowCreateButton && (
      <CreateButton
        disabled={disabled}
        header="jobs.jobTemplates.createTemplate"
        isShowIcon={false}
        isPrimaryColor
        gotoCreatePage={gotoCreatePage}
      />
    )}
  </NoTemplatesStyles>
);

export default NoTemplates;

import React, { useEffect, useState } from 'react';
import { Button, Image } from 'antd';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { RootState } from 'redux/store';
import { CloseSquareOutlineIcon, SparkleIcon } from 'components/common/SVGIcon';
import useGetCurrentLocation from 'hooks/useGetCurrentLocation';
import { EVENT_TRACKING } from 'configs/localData/eventTrackingName';
import { eventTrackingApi } from 'redux/@rtkQuery/eventTracking';
import {
  useNewFeatureControllerGetNewFeatureForCrmQuery,
  useNewFeatureControllerSetNewFeatureForCrmMutation,
} from 'generated/apis';
import { useAppSelector } from 'redux/hooks';
import { NewFeatureAnnouncementModalStyled } from './styles';

interface NewFeature {
  title: string;
  content: string;
  img: string;
  linkToRedirect: string;
}

interface Props {
  newFeature: NewFeature;
}

const DELAY_TO_SHOW_MODAL = 1000;

const IMPERSONATE_KEY = 'impersonateSession';

const NewFeatureAnnouncementModal = ({ newFeature }: Props) => {
  const { title, content, img, linkToRedirect } = newFeature;
  const { t } = useTranslation();
  const [showAnnounByUser, setShowAnnounByUser] = useState(false);
  const geolocation = useGetCurrentLocation();
  const { id: userId, email: userEmail } = useAppSelector(
    (state: RootState) => state.auth.data,
  );
  const [editFeatureAnnouncementConfig] =
    useNewFeatureControllerSetNewFeatureForCrmMutation();

  const isAuthenticated = useAppSelector(
    (state: RootState) => state.auth.isAuthenticated,
  );

  const isImpersonate = !!sessionStorage.getItem(IMPERSONATE_KEY) || false;

  const { data } = useNewFeatureControllerGetNewFeatureForCrmQuery(
    {
      isImpersonate,
    },
    {
      skip: !isAuthenticated,
    },
  );

  useEffect(() => {
    const isShowModal = data?.programsAndLearning?.showModal;
    if (isShowModal && userId) {
      const timer = setTimeout(() => {
        setShowAnnounByUser(true);
        eventTrackingApi({
          eventName:
            EVENT_TRACKING.NEW_FEATURE_ANNOUNCEMENT
              .programFeatureReleaseModalViewed,
          eventData: { userId, userEmail },
          geolocation,
        });
      }, DELAY_TO_SHOW_MODAL);
      return () => clearTimeout(timer);
    }
  }, [data, geolocation, userEmail, userId]);

  const handleClick = () => {
    eventTrackingApi({
      eventName:
        EVENT_TRACKING.NEW_FEATURE_ANNOUNCEMENT
          .programFeatureReleaseModalGetStartedClick,
      eventData: { userId, userEmail },
      geolocation,
    });
    handleCancel();
  };

  const handleCancel = () => {
    editFeatureAnnouncementConfig({
      showNewFeatureBodyDto: {
        name: 'programsAndLearning',
        isImpersonate,
        showModal: false,
      },
    });
    setShowAnnounByUser(false);
  };
  return (
    <NewFeatureAnnouncementModalStyled
      centered
      title={t('featureAnnouncement.title')}
      visible={showAnnounByUser}
      closeIcon={<CloseSquareOutlineIcon onClick={handleCancel} />}
      footer={
        <Button type="primary" size="large" onClick={handleClick}>
          <Link to={linkToRedirect}>{t('button.getStarted')}</Link>
        </Button>
      }
    >
      <Image preview={false} src={img} />
      <div className="body-content">
        <h1 className="body-title">
          {title} <SparkleIcon />
        </h1>
        <span className="body-description">{content}</span>
      </div>
    </NewFeatureAnnouncementModalStyled>
  );
};

export default NewFeatureAnnouncementModal;

import styled from 'styled-components';

export const JobTemplateItemWrapperStyles = styled.div`
  border-radius: 8px;
  cursor: pointer;
  .ant-tag:last-child {
    font-weight: bold;
  }
  .dot-circle {
    color: ${({ theme }) => theme.color.lightGray};
    margin: 0 10px;
    font-size: 11px;
  }
  
  .ant-card-body {
    min-height: 225px;
  }
}

  .ant-card-meta {
    &-title {
      font-weight: bold;
      overflow: hidden;
      white-space: normal;
      text-overflow: ellipsis;
      width: 295px;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }

    &-description {
      margin-top: 15px;
      color: ${({ theme }) => theme.text.primary};
      width: 100%;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }

  .template-card {
    &-discount {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      & > span:first-child {
        display: flex;
        justify-content: center;
        align-items: center;
        & > img {
          margin-right: 7px;
        }
      }
    }
    &-selected {
      border: 1px solid ${({ theme }) => theme.border.primary};
      position: relative;
      &::after {
        content: '\u2714';
        font-weight: bold;
        color: #fff;
        font-size: 16px;
        position: absolute;
        top: -1px;
        right: -1px;
        background: ${({ theme }) => theme.background.primary};
        transform: translate(-30%, 5%);
      }
      &::before {
        content: '';
        background: transparent;
        border-style: solid;
        border-width: 22px 22px;
        border-color: #db147f #db147f transparent transparent;
        position: absolute;
        top: -1px;
        right: -1px;
        border-radius: 0 6px 0 0;
      }
    }
    &-last-updated {
      font-size: 12px;
    }
    &-content {
      .anticon {
        margin-right: 7px;
        color: var(--color-green);
      }
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      display: flex;
      align-items: center;
      line-height: 17px;
      &:first-child {
        margin-top: 20px;
        margin-right: 5px;
      }
      &:not(:first-child) {
        padding: 15px 0 0;
      }
    }
    &-salary-text {
      font-weight: bold;
      color: ${({ theme }) => theme.text.darkGreen};
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }

  @media screen and (max-width: 1800px) {
    .template-card {
      &-discount {
        & > span {
          margin-top: 3px;
          margin-bottom: 3px;
        }
    }
  }
`;

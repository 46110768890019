import styled from 'styled-components';
import {
  getBackgroundColorApplicationItem,
  getBorderColorApplicationItem,
} from '../utils';

export const ApplicationItemWrapper = styled.div`
  border-radius: 8px;
  border: 1px solid transparent;

  border-color: ${(props) =>
    getBorderColorApplicationItem(props.isDragging, props.colors)};

  background-color: ${(props) =>
    getBackgroundColorApplicationItem(
      props.isDragging,
      props.isGroupedOver,
      props.colors,
    )};

  box-shadow: ${({ isDragging }) =>
    isDragging
      ? `2px 2px 1px ${({ theme }) => theme.boardColors.N70}`
      : '0px 1px 16px rgba(0, 0, 0, 0.06)'};

  padding: ${({ grid }) => `${grid}px`};
  &:not(:last-child) {
    margin-bottom: ${({ grid }) => `${grid}px`};
  }
  user-select: none;

  color: ${({ theme }) => theme.text.primary};

  display: flex;
  flex-direction: column;
  & > a {
    color: ${({ theme }) => theme.text.primary};
    &:hover,
    &:active {
      color: ${({ theme }) => theme.text.primary};
      text-decoration: none;
    }

    &:focus {
      outline: none;
      box-shadow: none;
    }
  }
`;

export const CloneBadge = styled.div`
  background: ${({ theme }) => theme.boardColors.G100};
  bottom: ${({ grid }) => `${grid / 2}px`};
  border: 2px solid ${({ theme }) => theme.boardColors.G200};
  border-radius: 50%;
  box-sizing: border-box;
  font-size: 10px;
  position: absolute;
  right: ${({ imageSize }) => `-${imageSize / 3}px`};
  top: ${({ imageSize }) => `-${imageSize / 3}px`};
  transform: rotate(40deg);

  height: ${({ imageSize }) => `${imageSize}px`};
  width: ${({ imageSize }) => `${imageSize}px`};

  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Content = styled.div`
  flex-grow: 1;
  flex-basis: 100%;
  display: flex;
  flex-direction: column;
  white-space: normal;
`;

export const BlockApplication = styled.div`
  .child-info {
    display: flex;
    align-items: center;
    margin-bottom: 15px;

    .avatar-wrapper {
      position: relative;
      margin-right: 15px;
      .gender-icon {
        position: absolute;
        padding: 3px;
        border-radius: 50%;
        bottom: 0;
        right: -5px;
      }
    }
  }
  padding: 10px 0 5px 0;
  .name-user {
    font-weight: bold;
  }
  .item-info {
    margin-bottom: 5px;
    .title-item {
      margin-right: 5px;
      font-weight: bold;
    }
  }
`;

export const FooterWrapper = styled.div`
  .service-item {
    margin-top: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .service-name {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      color: #316dde;
      background: rgba(50, 164, 252, 0.2);
      border-radius: 8px;
      padding: 3px 10px;
    }
    .fee-value {
      white-space: nowrap;
      font-weight: bold;
      color: #2d1f21;
      margin-left: 5px;
    }
  }
  .age-group-service {
    margin-top: 5px;
    font-size: 12px;
    color: ${({ theme }) => theme.text.secondary};
    margin-left: 5px;
  }
`;

export const RequiredDaysStyles = styled.div`
  display: flex;
  justify-content: center;
  font-weight: 600;
  .ant-space {
    display: flex;
    justify-content: space-around;
    width: 100%;
  }
  .avatar-active {
    background: ${({ theme, isCheckColor }) =>
      isCheckColor ? theme.palette.green : theme.palette.primary};
    color: #fff;
  }
  .avatar-disabled {
    color: ${({ theme, isCheckColor }) =>
      isCheckColor ? theme.palette.green : '#aba7a7'};
    background: ${({ isCheckColor }) => (isCheckColor ? '#FFFFFF' : '#f2f2f2')};
    border: ${({ theme, isCheckColor }) =>
      isCheckColor ? `1px solid ${theme.palette.green}` : 'none'};
  }
  .ant-avatar-string {
    font-size: 12px;
  }
`;

import { createAsyncThunk } from '@reduxjs/toolkit';
import { getAllCentresOfUserApi } from 'api/centres';
import { handlerUserLogged } from 'api/sentry';
import {
  loginApi,
  updateCurrentUserApi,
  getCurrentUserApi,
  getCurrentUserPermissionsApi,
  resetPasswordApi,
  forgotPasswordApi,
  registerApi,
  registerWithTokenApi,
  getPermissionsApi,
  subscribeUserApi,
  changePasswordApi,
  registerClientWithTokenApi,
  verifyRegisterTokenApi,
  refreshTokenApi,
  loginClaimCentreApi,
  verifyResetPasswordTokenApi,
} from 'api/user';
import { logoutAction } from 'utils/apiUtils';
import { apiWrapper } from 'utils/reduxUtils';

export const login = createAsyncThunk(
  'auth/login',
  async (payload, thunkAPI) => {
    try {
      const response = await apiWrapper(
        {
          isShowLoading: true,
          isShowSuccessNoti: false,
          isShowProgress: false,
          isCheckError: false,
        },
        loginApi,
        payload,
      );
      if (response) {
        localStorage.setItem('sessionToken', response.token);

        localStorage.setItem('refreshToken', response.refreshToken);

        if (response.data) {
          localStorage.setItem('id', response.data.id);
          handlerUserLogged(response.data);
        } else {
          thunkAPI.dispatch(getCurrentUser());
        }

        return response;
      }
      return thunkAPI.rejectWithValue(response);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  },
);

export const register = createAsyncThunk(
  'auth/register',
  async (payload, thunkAPI) => {
    try {
      const response = await apiWrapper(
        {
          isShowLoading: true,
          isShowSuccessNoti: false,
          isShowProgress: false,
          isCheckError: false,
        },
        registerApi,
        payload,
      );

      if (response.token) {
        localStorage.setItem('sessionToken', response.token);

        localStorage.setItem('refreshToken', response.refreshToken);

        thunkAPI.dispatch(getCurrentUser());

        return response;
      }
      return thunkAPI.rejectWithValue(response);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  },
);

export const registerWithToken = createAsyncThunk(
  'auth/registerWithToken',
  async (payload, thunkAPI) => {
    try {
      const response = await apiWrapper(
        {
          isShowLoading: true,
          isShowSuccessNoti: false,
          isShowProgress: false,
          isCheckError: false,
        },
        registerWithTokenApi,
        payload,
      );

      if (response.token) {
        localStorage.setItem('sessionToken', response.token);
        localStorage.setItem('refreshToken', response.refreshToken);
        thunkAPI.dispatch(getCurrentUser());
        return response;
      }
      return thunkAPI.rejectWithValue(response);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  },
);

export const getCurrentUser = createAsyncThunk(
  'auth/getCurrentUser',
  async (payload, thunkAPI) => {
    try {
      const response = await apiWrapper(
        {
          isShowProgress: false,
          isShowSuccessNoti: false,
          isCheckError: false,
        },
        getCurrentUserApi,
      );
      localStorage.setItem('id', response?.data?.id);
      handlerUserLogged(response?.data);
      return response?.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  },
);

export const getCurrentUserPermissions = createAsyncThunk(
  'auth/getCurrentUserPermissions',
  async (payload, thunkAPI) => {
    try {
      const response = await apiWrapper(
        {
          isShowProgress: false,
          isShowSuccessNoti: false,
          isCheckError: false,
        },
        getCurrentUserPermissionsApi,
      );
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  },
);

export const logout = createAsyncThunk('auth/logout', () => logoutAction());

export const updateCurrentUser = createAsyncThunk(
  'auth/updateCurrentUser',
  async (payload, thunkAPI) => {
    try {
      const response = await apiWrapper(
        {
          isShowLoading: true,
          isShowSuccessNoti: true,
          isShowProgress: false,
          isCheckError: false,
        },
        updateCurrentUserApi,
        payload,
      );
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  },
);

export const forgotPassword = createAsyncThunk(
  'auth/forgotPassword',
  async (payload, thunkAPI) => {
    try {
      const response = await apiWrapper(
        {
          isShowLoading: true,
          isShowSuccessNoti: true,
          isShowProgress: false,
          isCheckError: false,
        },
        forgotPasswordApi,
        payload,
      );
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  },
);

export const resetPassword = createAsyncThunk(
  'auth/resetPassword',
  async (payload, thunkAPI) => {
    try {
      const response = await apiWrapper(
        {
          isShowLoading: true,
          isShowSuccessNoti: true,
          isShowProgress: false,
          isCheckError: false,
        },
        resetPasswordApi,
        payload,
      );
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  },
);

export const changePassword = createAsyncThunk(
  'auth/changePassword',
  async (payload, thunkAPI) => {
    try {
      const response = await apiWrapper(
        {
          isShowLoading: true,
          isShowSuccessNoti: true,
          isShowProgress: false,
          isCheckError: false,
        },
        changePasswordApi,
        payload,
      );
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  },
);

export const subscribeUser = createAsyncThunk(
  'auth/subscribeUser',
  async (payload, thunkAPI) => {
    try {
      const response = await apiWrapper(null, subscribeUserApi, payload);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  },
);

export const getPermissions = createAsyncThunk(
  'auth/getPermissions',
  async (payload, thunkAPI) => {
    try {
      const response = await apiWrapper(null, getPermissionsApi, payload);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  },
);

export const registerClientWithToken = createAsyncThunk(
  'auth/registerClientWithToken',
  async (payload, thunkAPI) => {
    try {
      const response = await apiWrapper(
        {
          isShowProgress: false,
          isShowSuccessNoti: false,
          isCheckError: false,
        },
        registerClientWithTokenApi,
        payload,
      );
      if (response.token) {
        localStorage.setItem('sessionToken', response.token);
        localStorage.setItem('refreshToken', response.refreshToken);
        thunkAPI.dispatch(getCurrentUser());
        return response;
      }
      return thunkAPI.rejectWithValue(response);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  },
);

export const verifyRegisterToken = createAsyncThunk(
  'auth/verifyRegisterToken',
  async (payload, thunkAPI) => {
    try {
      const response = await apiWrapper(
        {
          isShowProgress: false,
          isShowSuccessNoti: false,
          isCheckError: false,
        },
        verifyRegisterTokenApi,
        payload,
      );
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  },
);

export const refreshToken = createAsyncThunk(
  'auth/refreshToken',
  async (payload, thunkAPI) => {
    try {
      const response = await apiWrapper(
        {
          isShowLoading: true,
          isShowSuccessNoti: false,
          isShowProgress: false,
          isCheckError: false,
        },
        refreshTokenApi,
        payload,
      );
      if (response) {
        localStorage.setItem('sessionToken', response.token);
        return response;
      }
      return thunkAPI.rejectWithValue(response);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  },
);

export const loginClaimCentre = createAsyncThunk(
  'auth/loginClaimCentre',
  async (payload, thunkAPI) => {
    try {
      const response = await apiWrapper(
        {
          isShowLoading: true,
          isShowSuccessNoti: false,
          isShowProgress: false,
          isCheckError: false,
        },
        loginClaimCentreApi,
        payload,
      );
      if (response) {
        localStorage.setItem('sessionToken', response.token);

        localStorage.setItem('refreshToken', response.refreshToken);

        if (response.data) {
          localStorage.setItem('id', response.data.id);
        } else {
          thunkAPI.dispatch(getCurrentUser());
        }

        return response;
      }
      return thunkAPI.rejectWithValue(response);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  },
);

export const verifyResetPasswordToken = createAsyncThunk(
  'auth/verifyResetPasswordToken',
  async (payload, thunkAPI) => {
    try {
      const response = await apiWrapper(
        {
          isShowSuccessNoti: false,
          isShowProgress: false,
          isCheckError: false,
        },
        verifyResetPasswordTokenApi,
        payload,
      );
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  },
);

export const getAllCentresOfUser = createAsyncThunk(
  'auth/getAllCentresOfUser',
  async (payload, thunkAPI) => {
    try {
      const response = await apiWrapper(
        {
          isShowSuccessNoti: false,
          isShowProgress: false,
          isCheckError: false,
        },
        getAllCentresOfUserApi,
      );
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  },
);

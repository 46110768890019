import React, { useEffect, useCallback, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getAllJobListings,
  getSummaryJobListing,
} from 'redux/jobs/jobListings/actions';
import { getFilterFromUrl, getSearchUrl } from 'utils/tools';
import { useHistory, useLocation, useParams } from 'react-router';
import { getJobListingViewByJobRole } from 'redux/jobs/jobListings/selectors';
import {
  DEFAULT_CURRENT_PAGE,
  DEFAULT_SORT_INDIVIDUAL,
  DEFAULT_SORT_ROLE,
  LIMIT_ITEM,
  VIEW_TYPE_JOB_LISTINGS,
} from 'containers/JobListings/constants';
import { isEmpty } from 'lodash-es';
import { DISPLAY_TYPES } from 'configs/localData/constant';
import JobRole from 'components/common/JobRole';
import { Spin } from 'antd';
import { Waypoint } from 'react-waypoint';
import ListingFilterCustom from './components/Filter';
import Summaries from './components/Summaries';
import NoJobListings from '../NoJobListings';
import TableView from './components/TableView';
import { JobList } from './styles';

const JobRoleList = () => {
  const [type, setType] = useState(DISPLAY_TYPES.board);
  const { tabMode } = useParams();
  const { search } = useLocation();
  const { location, push } = useHistory();
  const { filter } = getFilterFromUrl(search);
  const dispatch = useDispatch();
  const filterRef = useRef(filter);
  const {
    data: jobRolesData,
    resourceData,
    loading,
  } = useSelector(getJobListingViewByJobRole) || {};

  const retrieveList = useCallback(
    ({ filter = {}, isRefresh, keepFilter }) => {
      dispatch(
        getAllJobListings({
          data: {
            ...filter,
            viewType: VIEW_TYPE_JOB_LISTINGS.ROLE,
          },
          options: {
            keepFilter,
            isRefresh,
          },
        }),
      );
      dispatch(
        getSummaryJobListing({
          ...filter,
          viewType: VIEW_TYPE_JOB_LISTINGS.ROLE,
        }),
      );
    },
    [dispatch],
  );
  useEffect(() => {
    if (tabMode === VIEW_TYPE_JOB_LISTINGS.ROLE) {
      retrieveList({
        filter: {
          sorts: filterRef.current.sorts || [DEFAULT_SORT_ROLE],
          page: filterRef.current.page || DEFAULT_CURRENT_PAGE,
          size: filterRef.current.size || LIMIT_ITEM,
          filter: filterRef.current.filter,
        },
        isRefresh: true,
        keepFilter: true,
      });
    }
  }, [retrieveList, tabMode]);
  const pushQuery = useCallback(
    (searchStr) => {
      push(`${location.pathname}?${getSearchUrl(searchStr)}`);
    },
    [location.pathname, push],
  );

  const handleEnterWaypoint = () => {
    const restFilter = {
      page: resourceData.page ? resourceData.page + 1 : DEFAULT_CURRENT_PAGE,
      size: LIMIT_ITEM,
      sorts: [DEFAULT_SORT_ROLE],
      filter: { ...filter.filter },
    };

    if (resourceData.total / resourceData.page >= LIMIT_ITEM && !loading) {
      retrieveList({
        filter: restFilter,
        isRefresh: false,
        keepFilter: false,
      });
    }
  };

  const handleUpdateViewType = (mode) => {
    setType(mode);
    push(`/job-listings/role/${mode}`);
  };

  return (
    <div className="full-content">
      <Summaries />
      <div className="mt-32">
        <ListingFilterCustom
          retrieveList={retrieveList}
          pushQuery={pushQuery}
          type={type}
          handleUpdateViewType={handleUpdateViewType}
        />
      </div>
      {jobRolesData?.length || loading || !isEmpty(filter?.filter) ? (
        <>
          {type === DISPLAY_TYPES.list ? (
            <TableView
              retrieveList={retrieveList}
              pushQuery={pushQuery}
              jobRoles={jobRolesData}
              loading={loading}
            />
          ) : (
            <>
              <JobList>
                {jobRolesData?.map((job) => (
                  <JobRole
                    job={job}
                    key={job.id}
                    handleRedirectDetail={() => {
                      push(
                        `/job-listings/individual/list?${getSearchUrl({
                          page: DEFAULT_CURRENT_PAGE,
                          sorts: [DEFAULT_SORT_INDIVIDUAL],
                          filter: {
                            jobRoleId: job?.id,
                          },
                        })}`,
                      );
                    }}
                  />
                ))}
              </JobList>

              {loading && (
                <div className="flex justify-center pt-40">
                  <Spin />
                </div>
              )}
              <Waypoint onEnter={handleEnterWaypoint} />
            </>
          )}
        </>
      ) : (
        <NoJobListings
          title="jobListings.noJobListings"
          description="jobListings.noJobListingsDesc"
          isShowCreateJobListing
        />
      )}
    </div>
  );
};
export default JobRoleList;

import { DatePicker, Form } from 'antd';
import styled from 'styled-components';

const { RangePicker } = DatePicker;

export const LeftDatePickerWrapper = styled.div`
  width: 164px;
  border-right: 1px solid #ebecf0;
`;

export const OptionItem = styled.div`
  justify-content: space-between;
  align-items: center;
  font-weight: 500;
  font-size: 12px;
  padding: 8px 0;
  &:hover {
    color: ${({ theme }) => theme.palette.primary};
  }
`;

export const RangePickerCustom = styled(RangePicker)`
  .date-picker {
    display: flex !important;
  }
`;

interface FormItemCustomProps {
  isCompare: boolean;
}

export const FormItemCustom = styled(Form.Item)`
  .ant-form-item-extra {
    position: absolute;
    width: 100%;
    bottom: -1px;
    padding-left: 12px;
    font-size: 12px;
    pointer-events: none;
  }
  .ant-picker-range {
    height: 40px;
  }
  .ant-picker-range-separator {
    position: absolute;
    top: ${({ isCompare }: FormItemCustomProps) =>
      isCompare ? '4px' : '10px'};
    left: 34%;
  }
  .ant-picker-input input {
    top: ${({ isCompare }: FormItemCustomProps) =>
      isCompare ? '-7px' : '0px'};
    font-size: ${({ isCompare }: FormItemCustomProps) =>
      isCompare ? '12px' : '13px'};
  }
`;

import { Col, Row } from 'antd';
import PageTitle from 'components/common/PageTitle';
import CreateButton from 'components/RestActions/CreateButton';
import i18next from 'i18next';
import React, { useCallback, useEffect, useRef } from 'react';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { getActiveOnJobModulePermission } from 'redux/auth/selectors';
import {
  getAllJobTemplates,
  getJobTemplateConfig,
} from 'redux/jobs/jobTemplates/actions';
import {
  getDataJobTemplates,
  getLoadingJobTemplates,
} from 'redux/jobs/jobTemplates/selectors';
import { useLocation } from 'react-router-dom';
import { getFilterFromUrl, getSearchUrl } from 'utils/tools';
import { isEmpty } from 'lodash-es';
import useRolesJobTemplates from 'containers/Users/hooks/useRoleJobTemplates';
import JobModuleAccessWarning from 'components/common/JobModuleAccessWarning';
import Filter from './components/Filter';
import JobTemplateList from './components/JobTemplateList';
import NoTemplates from './components/NoTemplates';
import {
  DEFAULT_CURRENT_PAGE,
  DEFAULT_SORT_JOB_TEMPLATE,
  LIMIT_ITEM,
} from './components/LocalData/constant';

const JobTemplatesContainer = () => {
  const { location, push } = useHistory();
  const activeOnJobModulePermission = useSelector(
    getActiveOnJobModulePermission,
  );
  const jobTemplateData = useSelector(getDataJobTemplates);
  const loading = useSelector(getLoadingJobTemplates);
  const disabledAction = useRolesJobTemplates();

  const { search } = useLocation();
  const { filter } = getFilterFromUrl(search);
  const filterRef = useRef(filter);

  const gotoCreatePage = () => {
    push('/jobTemplates/create/jobTemplateBanner');
  };

  const dispatch = useDispatch();

  const retrieveList = useCallback(
    ({ filter = {}, isRefresh, keepFilter }) => {
      dispatch(
        getAllJobTemplates({
          data: {
            ...filter,
          },
          options: {
            keepFilter,
            isRefresh,
          },
        }),
      );
    },
    [dispatch],
  );

  useEffect(() => {
    activeOnJobModulePermission &&
      retrieveList({
        filter: {
          sorts: filterRef.current.sorts || [DEFAULT_SORT_JOB_TEMPLATE],
          page: filterRef.current.page || DEFAULT_CURRENT_PAGE,
          size: filterRef.current.size || LIMIT_ITEM,
          filter: filterRef.current.filter,
        },
        isRefresh: true,
        keepFilter: true,
      });

    dispatch(getJobTemplateConfig());
  }, [retrieveList, dispatch, activeOnJobModulePermission]);

  const pushQuery = useCallback(
    (searchStr) => {
      push(`${location.pathname}?${getSearchUrl(searchStr)}`);
    },
    [location.pathname, push],
  );

  return (
    <div>
      {activeOnJobModulePermission ? (
        <>
          <Row justify="space-between">
            <Col>
              <PageTitle>{i18next.t('jobs.sideBar.jobTemplates')}</PageTitle>
            </Col>
            <Col>
              <CreateButton
                disabled={disabledAction}
                header="jobs.jobTemplates.createTemplate"
                isShowIcon={false}
                isPrimaryColor
                gotoCreatePage={gotoCreatePage}
              />
            </Col>
          </Row>
          <Filter retrieveList={retrieveList} pushQuery={pushQuery} />
          {jobTemplateData?.length || loading || !isEmpty(filter?.filter) ? (
            <JobTemplateList
              jobTemplateData={jobTemplateData}
              loading={loading}
              retrieveList={retrieveList}
              pushQuery={pushQuery}
            />
          ) : (
            <NoTemplates
              title="jobs.jobTemplates.noTemplates"
              description="jobs.jobTemplates.noTemplatesDescription"
              gotoCreatePage={gotoCreatePage}
              disabled={disabledAction}
            />
          )}
        </>
      ) : (
        <JobModuleAccessWarning title="jobs.sideBar.jobTemplates" />
      )}
    </div>
  );
};

export default JobTemplatesContainer;
